import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import moment from 'moment';

import { ProjectStatus, ProjectTypeEnum } from '@statera/sdk/common';

import * as models from './lease.model';

import { LeaseRepository } from './lease.repository';

@Injectable()
export class LeaseManager {
  private readonly _leaseRepository: LeaseRepository;

  constructor(leaseRepository: LeaseRepository) {
    this._leaseRepository = leaseRepository;
  }

  getLeaseTerms(lease: models.Lease): Array<models.LeaseTerm> {
    const leaseTerms = [];

    if (!lease) {
      return leaseTerms;
    }

    const keys = Object.keys(lease);
    for (let i = 0, num = keys.length; i < num; i++) {
      const key = keys[i];
      const property = lease[key];

      if (property && <models.LeaseTerm>property.leaseTermType) {
        leaseTerms.push(property);
      }
    }

    return leaseTerms;
  }

  getLeaseTerm<T extends models.LeaseTerm>(lease: models.Lease, leaseTermType: models.LeaseTermType): T {
    if (!lease) {
      return;
    }

    let term: models.LeaseTerm = null;

    switch (leaseTermType) {
      case models.LeaseTermType.Commencement:
        term = lease.commencementTerm;
        break;

      case models.LeaseTermType.Term:
        term = lease.term;
        break;

      case models.LeaseTermType.TenantSquareFootage:
        term = lease.tenantSquareFootageTerm;
        break;

      case models.LeaseTermType.BaseRentalRate:
        term = lease.baseRentalRateTerm;
        break;

      case models.LeaseTermType.RealEstateTaxesCamExpenses:
        term = lease.realEstateTaxesCamExpensesTerm;
        break;

      case models.LeaseTermType.RentalRateAnnualEscalation:
        term = lease.rentalRateAnnualEscalationTerm;
        break;

      case models.LeaseTermType.FreeRent:
        term = lease.freeRentTerm;
        break;

      case models.LeaseTermType.SecurityDeposit:
        term = lease.securityDepositTerm;
        break;

      case models.LeaseTermType.TenantImprovements:
        term = lease.tenantImprovementsTerm;
        break;

      case models.LeaseTermType.LandlordMaintenance:
        term = lease.landlordMaintenanceTerm;
        break;

      case models.LeaseTermType.TenantMaintenance:
        term = lease.tenantMaintenanceTerm;
        break;

      case models.LeaseTermType.SelfHelp:
        term = lease.selfHelpTerm;
        break;

      case models.LeaseTermType.Assignment:
        term = lease.assignmentTerm;
        break;

      case models.LeaseTermType.RenewalOption:
        term = lease.renewalOptionTerm;
        break;

      case models.LeaseTermType.EstoppelCertificate:
        term = lease.estoppelCertificateTerm;
        break;

      case models.LeaseTermType.TerminationOption:
        term = lease.terminationOptionTerm;
        break;

      case models.LeaseTermType.ExpansionOption:
        term = lease.expansionOptionTerm;
        break;

      case models.LeaseTermType.Hvac:
        term = lease.hvacTerm;
        break;

      case models.LeaseTermType.HoldoverProvision:
        term = lease.holdoverProvisionTerm;
        break;

      case models.LeaseTermType.SpaceUse:
        term = lease.spaceUseTerm;
        break;

      case models.LeaseTermType.ConditionOfPremises:
        term = lease.conditionOfPremisesTerm;
        break;

      case models.LeaseTermType.CodeCompliance:
        term = lease.codeComplianceTerm;
        break;

      case models.LeaseTermType.Utilities:
        term = lease.utilitiesTerm;
        break;

      case models.LeaseTermType.Parking:
        term = lease.parkingTerm;
        break;

      case models.LeaseTermType.Signage:
        term = lease.signageTerm;
        break;

      case models.LeaseTermType.NonDisturbance:
        term = lease.nonDisturbanceTerm;
        break;

      case models.LeaseTermType.HazardousMaterials:
        term = lease.hazardousMaterialsTerm;
        break;

      case models.LeaseTermType.Rail:
        term = lease.railTerm;
        break;
    }

    return <T>term;
  }

  getElectedTermsForNegotiation(leaseId: number): Observable<Array<models.ElectedTermForNegotiation>> {
    return this._leaseRepository
      .getElectedTermsForNegotiation(leaseId);
  }

  getLetterOfIntent(leaseId: number): Observable<models.File> {
    return this._leaseRepository
      .getLetterOfIntent(leaseId);
  }

  electTermsForNegotiation(leaseId: number, electedTerms: Array<models.ElectedTermForNegotiation>): Observable<void> {
    return this._leaseRepository
      .electTermsForNegotiation(leaseId, electedTerms);
  }

  createNewDeal(model: models.BuildingUnitViewModel): Observable<models.Lease> {
    return this._leaseRepository
      .createNewDeal(model);
  }

  createNewDealInquiryByBuildingUnit(model: models.BuildingUnitViewModel): Observable<models.Lease> {
    return this._leaseRepository.createNewDealInquiryByBuildingUnit(model);
  }

  canRestructureLease(
    lease: models.Lease,
    startupInfo: models.StartupInfo,
    allUserLeases: Array<models.Lease | models.TenantDashboard>,
    allUserProjects: Array<models.Project>,
  ): boolean {
    if (!lease || !lease.expiration || !startupInfo || !startupInfo.monthsCountToDetermineRestructureOrRenewalProject) {
      return false;
    }

    if (startupInfo.role !== models.Role.Tenant) {
      return false;
    }

    const isActiveProjectExist = this._doesLeaseOrItsAbstractHaveAnActiveProject(lease, allUserLeases, allUserProjects);
    if (!isActiveProjectExist) {
      return false;
    }

    return !this.hasRenewalPeriodBegun(lease, startupInfo);
  }

  canRenewLease(
    lease: models.Lease,
    startupInfo: models.StartupInfo,
    allUserLeases: Array<models.Lease | models.TenantDashboard>,
    allUserProjects: Array<models.Project>,
  ): boolean {
    if (!lease || !lease.expiration || !startupInfo || !startupInfo.monthsCountToDetermineRestructureOrRenewalProject) {
      return false;
    }

    if (startupInfo.role !== models.Role.Tenant) {
      return false;
    }

    const isActiveProjectExist = this._doesLeaseOrItsAbstractHaveAnActiveProject(lease, allUserLeases, allUserProjects);
    if (!isActiveProjectExist) {
      return false;
    }

    return this.hasRenewalPeriodBegun(lease, startupInfo);
  }

  canConvertToNewDeal(
    lease: models.Lease,
    startupInfo: models.StartupInfo,
    allUserProjects: Array<models.Project>,
  ): boolean {
    if (!lease || !startupInfo) {
      return false;
    }
    if (
      startupInfo.role !== models.Role.Tenant &&
      startupInfo.role !== models.Role.Broker &&
      startupInfo.role !== models.Role.CoBroker
    ) {
      return false;
    }
    if (
      allUserProjects.find(x =>
        x.lease?.buildingUnitId === lease.buildingUnitId &&
        x.projectTypeId === models.ProjectTypeEnum.NewDeal &&
        x.projectStatus === models.ProjectStatus.Active)
    ) {
      return false;
    }

    const project = allUserProjects.find(p => p.leaseId === lease.id);
    if (!project) {
      return false;
    }

    return project.projectTypeId === models.ProjectTypeEnum.NewDealInquiry
      && project.projectState?.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp;
  }

  hasRenewalPeriodBegun(lease: models.Lease, startupInfo: models.StartupInfo): boolean {
    const numberOfMonthsPriorLeaseRenewal = startupInfo.monthsCountToDetermineRestructureOrRenewalProject;

    const restructureUntil = moment(lease.calculatedExpirationDate)
      .startOf('day')
      .add(-numberOfMonthsPriorLeaseRenewal, 'months');

    const now = moment().startOf('day');

    return restructureUntil.isSameOrBefore(now);
  }

  private _doesLeaseOrItsAbstractHaveAnActiveProject(
    lease: models.Lease,
    allUserLeases: Array<models.Lease | models.TenantDashboard>,
    allUserProjects: Array<models.Project>
  ): boolean {
    if (!lease || !allUserLeases || !allUserProjects) {
      return false;
    }

    if (lease.leaseStatus === models.LeaseStatus.InProcess) {
      return false;
    }

    const currentLeaseProjects = allUserProjects.filter(x => x.leaseId === lease.id);
    if (currentLeaseProjects && currentLeaseProjects.length) {
      return !currentLeaseProjects.some(project => project.projectStatus === ProjectStatus.Active);
    }

    const abstractLeases = this._getAbstractLeases(lease, allUserLeases);
    if (!abstractLeases || !abstractLeases.length) {
      return true;
    }

    return !abstractLeases.some(abstractLease =>
      allUserProjects.some(project =>
        abstractLease.id === project.leaseId &&
        project.projectStatus === ProjectStatus.Active
      )
    );
  }

  private _getAbstractLeases(
    lease: models.Lease,
    leases: Array<models.Lease | models.TenantDashboard>
  ): Array<models.Lease | models.TenantDashboard> {
    if (!leases || lease.isAbstractLease) {
      return [];
    }

    return leases.filter(x => lease && x.abstractLeaseId === lease.id);
  }

  private _getProcessName(project: models.Project): string {
    if (!project) {
      return null;
    }

    let processName = 'Renewal';

    if (project.projectTypeId === models.ProjectTypeEnum.Restructure) {
      processName = 'Restructure';
    }

    if (project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      processName = 'New Deal';
    }

    return processName;
  }

  // TODO: Tooltips should be part of the client code, not the SDK
  getRenewalButtonTooltip(
    lease: models.Lease,
    currentProject: models.Project,
    allUserLeases: Array<models.Lease | models.TenantDashboard>,
    allUserProjects: Array<models.Project>,
    startupInfo: models.StartupInfo,
  ): string {
    if (!lease) {
      return 'Feature coming soon';
    }

    if (startupInfo.role === models.Role.Broker || startupInfo.role === models.Role.CoBroker) {
      return 'Unavailable';
    }

    // Process already started
    if (currentProject && currentProject.projectStatus === models.ProjectStatus.Active) {
      const otherPartyRole = startupInfo.role === models.Role.Tenant
        ? models.Role.Landlord
        : models.Role.Tenant;
      // Renewal by Landlord started
      if (
        currentProject.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord
      ) {
        return (
          'The Landlord has already initiated the Renewal process.\n' +
          `Please wait for their initial offer. Continue to use the messenger to communicate with the ${otherPartyRole}.`
        );
      }

      // Renewal or Restructure started
      const processName = this._getProcessName(currentProject);
      return (
        `${processName} process has already been initiated.\nContinue to use Colábo to communicate with the ${otherPartyRole} and their team.`
      );
    }

    return 'Current Lease has less than 18 months remaining.  You can initiate the Renewal process by clicking here.';
  }

  // TODO: Tooltips should be part of the client code, not the SDK
  getSendRfpButtonTooltip(
    currentProject: models.Project,
    startupInfo: models.StartupInfo,
  ): string {
    // Process already started
    if (currentProject && currentProject.projectStatus === models.ProjectStatus.Active) {
      if (
        currentProject.projectTypeId === models.ProjectTypeEnum.NewDealInquiry &&
        currentProject.projectState?.renewalProjectTemplateItemType < models.RenewalProjectTemplateItemType.SendRfp
      ) {
        return 'Inquiry has already been submitted.  Please wait for a response from the Landlord to continue.';
      }

      const otherPartyRole = startupInfo.role === models.Role.Tenant
        ? models.Role.Landlord
        : models.Role.Tenant;

      return (
        `New deal process has already been initiated.\nContinue to use Colábo to communicate with the ${otherPartyRole} and their team.`
      );
    }

    return 'New Lease negotiation has already been initiated.  Please continue to use Colábo to communicate with the landlord and their team.';
  }

  // TODO: Tooltips should be part of the client code, not the SDK
  getInitialDealTooltip(
    lease: models.Lease,
    currentProject: models.Project,
    startupInfo: models.StartupInfo,
  ): string {
    // Process already started
    if (currentProject && currentProject.projectStatus === models.ProjectStatus.Active) {
      const processName = this._getProcessName(currentProject);
      return (
        `${processName} process has already been initiated.\nContinue to use Colábo to communicate with the Tenant and their team.`
      );
    }

    if (lease && lease.leaseStatus === models.LeaseStatus.Active && !this.isLeaseExpired(lease)) {
      // Restructure
      const hasRenewalPeriodBegun = this.hasRenewalPeriodBegun(lease, startupInfo);
      if (!hasRenewalPeriodBegun) {
        return `Restructure process coming soon.`;
      }

      // Renewal
      return 'FYI, there is less than 18 months remaining.\nThis will start a renewal process.';
    }

    // New deal
    return 'New deal process coming soon.';
  }

  // TODO: Tooltips should be part of the client code, not the SDK
  getRestructureButtonTooltip(
    lease: models.Lease,
    currentProject: models.Project,
    allUserLeases: Array<models.Lease | models.TenantDashboard>,
    allUserProjects: Array<models.Project>,
    startupInfo: models.StartupInfo,
  ): string {
    if (!lease) {
      return 'Feature coming soon';
    }

    if (startupInfo.role === models.Role.Broker || startupInfo.role === models.Role.CoBroker) {
      return 'Unavailable';
    }

    // Process already started
    if (currentProject && currentProject.projectStatus === models.ProjectStatus.Active) {
      const otherPartyRole = startupInfo.role === models.Role.Tenant
        ? models.Role.Landlord
        : models.Role.Tenant;

      // Renewal by Landlord started
      if (
        currentProject.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord
      ) {
        return (
          'The Landlord has already initiated the Renewal process.\n' +
          `Please wait for their initial offer. Continue to use the messenger to communicate with the ${otherPartyRole}.`
        );
      }

      // Renewal or Restructure started
      const processName = this._getProcessName(currentProject);
      return (
        `${processName} process has already been initiated.\nContinue to use Colábo to communicate with the ${otherPartyRole} and their team.`
      );
    }

    return 'Current Lease has more than 18 months remaining.  You can submit a request to expand, downsize or renegotiate specific terms by clicking here.';
  }

  getUserLeaseTeam(lease: models.Lease, userId: number, role: string) {
    if (!lease || !userId || !role) {
      return;
    }

    if (role === 'Landlord') {
      return models.LeaseTeam.LandlordTeam;
    }

    if (role === 'Tenant') {
      return models.LeaseTeam.TenantTeam;
    }

    if (lease.leaseUsers) {
      const leaseUser = lease.leaseUsers.find(x => x.userId === userId);
      if (!leaseUser) {
        return null;
      }

      if (lease.landlordCompany &&
        leaseUser.relatedToCompanyId === lease.landlordCompany.id) {
        return models.LeaseTeam.LandlordTeam;
      }

      if (lease.tenantCompany &&
        leaseUser.relatedToCompanyId === lease.tenantCompany.id) {
        return models.LeaseTeam.TenantTeam;
      }
    }
  }

  isLeaseExpired(lease: models.Lease): boolean {
    if (!lease || !lease.isAbstractLease) {
      return false;
    }

    const now = moment().startOf('day');
    const expirationDate = moment(lease.expirationAbstractValue).startOf('day');

    return now.isAfter(expirationDate);
  }

  isLeaseRejected(lease: models.Lease): boolean {
    if (!lease || lease.isAbstractLease) {
      return false;
    }

    return lease.leaseStatus === models.LeaseStatus.Rejected;
  }

  isLeaseSigned(lease: models.Lease): boolean {
    if (!lease || lease.isAbstractLease) {
      return false;
    }

    return lease.leaseStatus === models.LeaseStatus.Active;
  }

  isLeaseCancelled(lease: models.Lease): boolean {
    if (!lease || lease.isAbstractLease) {
      return false;
    }

    return lease.leaseStatus === models.LeaseStatus.Cancelled;
  }


  getLease(leaseId: number): Observable<models.Lease> {
    return this._leaseRepository.getLease(leaseId);
  }

  cancelRequest(lease: models.Lease): Observable<models.CancelledRequest> {
    return this
      ._leaseRepository
      .cancelRequest(lease.id);
  }

  isRenewalOptionAvailable(lease: models.Lease): boolean {
    if (!lease) {
      return false;
    }

    return (
      lease.renewalOptionTerm &&
      lease.renewalOptionTerm.renewalDate !== null
    );
  }

  isExpansionOptionAvailable(lease: models.Lease): boolean {
    if (!lease) {
      return false;
    }

    return (
      lease.expansionOptionTerm &&
      lease.expansionOptionTerm.expansionDate !== null
    );
  }

  isExpirationInLessThanAYear(lease: models.Lease): boolean {
    if (!lease) {
      return false;
    }

    const expirationDate = moment(lease.expiration).startOf('day');
    const yearLater = moment().add(1, 'year').startOf('day');

    return expirationDate.isBefore(yearLater);
  }

  showCurrentLeaseLanguage(lease: models.Lease): boolean {
    return !(lease && lease.hasThirdPartyLease);
  }

  showThirdPartyLease(lease: models.Lease): boolean {
    return lease && lease.hasThirdPartyLease;
  }

  getLeaseActivities(leaseId: number, projectTemplateItemId: number): Observable<Array<models.LeaseActivity>> {
    return this._leaseRepository.getLeaseActivities(leaseId, projectTemplateItemId);
  }

  isShowAwaitingLeaseUploadBadge(project: models.Project, role: string) {
    const { LeaseDocumentsFirstStepByLandlord, CleanCopySubmittalByLandlord } = models.RenewalProjectTemplateItemType;
    return (
      (role === models.Role.Broker || role === models.Role.CoBroker) &&
      (
        project?.projectState?.renewalProjectTemplateItemType === LeaseDocumentsFirstStepByLandlord ||
        project?.projectState?.renewalProjectTemplateItemType === CleanCopySubmittalByLandlord
      )
    );
  }

  saveLeaseSettings(leaseId: number, leaseSettings: models.LeaseSettings): Observable<models.LeaseSettings> {
    return this._leaseRepository.saveLeaseSettings(leaseId, leaseSettings);
  }

  getLeaseTeam(leaseId: number): Observable<models.LeaseTeamInfo> {
    return this._leaseRepository.getLeaseTeam(leaseId);
  }
}
