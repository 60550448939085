import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { ProfileService } from '../../../user/services/profile.service';

import * as models from '../../../infrastructure/models/generated';

import { BaseDemoComponent } from '../../../shared/components/base-demo/base-demo.component';

// TODO: [FIXME] We must stop use BaseComponent, use service instead
//       If logic requires a template please refers to ComponentLoaderService
@Component({
  selector: 'app-colabo-demo',
  templateUrl: '../../../shared/components/base-demo/base-demo.component.html',
  styleUrls: ['./colabo-demo.component.scss']
})
export class ColaboDemoComponent extends BaseDemoComponent implements OnInit, OnDestroy {
  steps = [
    {
      element: '.w-col-channels',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Select the appropriate building and applicable lease to begin negotiations. ' +
          'If you have multiple leases in your dashboard, you can switch to each deal, as necessary.'
        ),
        position: 'right'
      }
    },
    {
      element: '.term-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Press the plus sign to open the detail of each term. ' +
          'The view will display the current lease language from your existing lease. ' +
          'In later stages you will also see the most current negotiated terms. ' +
          'Notice the tool tip icon to be used as a guide for each individual term.'
        ),
        position: 'right'
      }
    },
    {
      element: '.col-chat',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Real time direct messenger between both parties. ' +
          'Discuss any pertinent questions you may have as part of the overall negotiation at any time.'
        ),
        position: 'left'
      }
    },
    {
      element: '.demo-alerts',
      popover: {
        title: ' ',
        description: this.getMessage(
          'This will be a log of all significant milestones and status changes through out the duration of the negotiation.'
        ),
        position: 'left'
      }
    },
    {
      element: 'app-timeline',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Use this timeline to see where you are at all times within the process. ' +
          'Hover over each stage for a tool tip to see a description and approximate dates. ' +
          'The highlighted green stage shows your current position.'
        ),
        position: 'bottom',
        padding: 10,
      }
    }
  ];

  constructor(profileService: ProfileService, authService: AuthService, alertService: AlertService) {
    super(profileService, authService, alertService);
  }

  ngOnInit() {
    this.introType = models.IntroType.ColaboPage1;
  }

  show(component: any) {
    if (!this.elementExist('app-timeline')) {
      const idx = this.steps.findIndex(s => s.element === 'app-timeline');
      if (idx >= 0) {
        this.steps.splice(idx, 1);
      }
    }
    super.show(component);
  }

  goto(stepNumber: number) {
    this.current = stepNumber;
    this.driver.highlight(this.steps[stepNumber]);
    if (this.current === 2) {
      this.component.selectedTabIndex = 0;
    }
    if (this.current === 3) {
      this.component.selectedTabIndex = 2;
    }
  }
}
