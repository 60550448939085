import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { AnalyticsRepository } from './analytics.repository';
import { AnalyticsManager } from './analytics.manager';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    AnalyticsRepository,
    AnalyticsManager,
  ],
})
export class AnalyticsModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AnalyticsRepository,
        AnalyticsManager,
      ],
    };
  }
}
