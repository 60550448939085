<video preload="auto"
       [controls]="false" [muted]="muted"
       #videoElementRef>
  <source [src]="source" [type]="sourceType" />
</video>

<div class="video-player-html5-controls"
     *ngIf="controls"
     [class.metadata-loading]="isMetadataLoading"
     [class.hidden]="!isControlsVisible">
  <div class="video-metadata">
    <div class="video-title">
    </div>
    <div class="video-timing">
      {{getCurrentTime()}} / {{getDuration()}}
    </div>
  </div>
  <div class="video-progress">
    <ng-container [ngTemplateOutlet]="sliderTemplate"
                  [ngTemplateOutletContext]="{
                    position: getProgressRangePosition(),
                    positionChange: handleProgressRangePositionChange,
                    showThumbOnHoverMode: true
                  }">
    </ng-container>
  </div>
  <div class="video-actions">
    <div class="video-actions-left">
      <div class="video-actions-play"
           *ngIf="playerState === VideoPlayerState.Paused">
        <button type="button"
                (click)="handlePlayButtonClick($event)">
          <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-play"></i>
        </button>
      </div>
      <div class="video-actions-pause"
           *ngIf="playerState === VideoPlayerState.Playing">
        <button type="button"
                (click)="handlePauseButtonClick($event)">
          <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-pause"></i>
        </button>
      </div>
      <div class="video-actions-replay"
           *ngIf="playerState === VideoPlayerState.Played">
        <button type="button"
                (click)="handleReplayButtonClick($event)">
          <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-replay"></i>
        </button>
      </div>
    </div>
    <div class="video-actions-right">
      <div class="video-actions-volume">
        <button type="button"
                (click)="handleVolumeButtonClick($event)">
          <ng-container *ngIf="!muted">
            <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-volume"></i>
          </ng-container>
          <ng-container *ngIf="muted">
            <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-volume-muted"></i>
          </ng-container>
        </button>
        <div class="video-actions-volume-slider">
          <div class="video-actions-volume-slider-inner">
            <ng-container [ngTemplateOutlet]="sliderTemplate"
                          [ngTemplateOutletContext]="{
                            position: muted ? 0 : volume * 100,
                            positionChange: handleVolumeRangePositionChange
                          }">
            </ng-container>
          </div>
        </div>
      </div>
      <div class="video-actions-fullscreen">
        <button type="button"
                (click)="handleFullscreenButtonClick($event)">
          <ng-container *ngIf="!isFullscreenMode">
            <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-fullscreen"></i>
          </ng-container>
          <ng-container *ngIf="isFullscreenMode">
            <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-fullscreen-exit"></i>
          </ng-container>
        </button>
      </div>
      <div class="video-actions-more">
        <button type="button"
                [popover]="morePopoverTemplate" placement="top right"
                [adaptivePosition]="false" triggers=""
                (onHidden)="morePopoverMenuIndex = -1"
                (click)="handleMoreButtonClick($event)"
                #morePopover="bs-popover">
          <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-more"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template let-position="position"
             let-positionChange="positionChange"
             let-showThumbOnHoverMode="showThumbOnHoverMode"
             #sliderTemplate>
  <div class="slider"
       [class.show-thumb-on-hover]="showThumbOnHoverMode"
       (click)="handleSliderClick($event)">
    <div class="slider-track"></div>
    <div class="slider-range"
         [style.width]="position + '%'">
    </div>
    <input type="range"
           class="slider-thumb"
           [min]="0" [max]="100"
           [step]="0.1"
           [ngModel]="position"
           (ngModelChange)="positionChange($event)" />
  </div>
</ng-template>

<ng-template #morePopoverTemplate>
  <div [style.max-height]="maxMorePopoverContentHeight + 'px'"
       (click)="handleMorePopoverClick($event)">
    <ng-container *ngIf="morePopoverMenuIndex < 0">
      <button type="button"
              class="download-action"
              (click)="download()">
        <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-download"></i>
        Download
      </button>
      <button type="button"
              class="menu-action playback-rate"
              (click)="morePopoverMenuIndex = 0">
        <i inlineSVG="assets/v2/icons/video-player-icons.svg#icon-speed"></i>
        Playback speed
        <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-chevron-right-8"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="morePopoverMenuIndex === 0">
      <button type="button"
              class="menu-action back"
              (click)="morePopoverMenuIndex = -1">
        <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-chevron-left-16"></i>
        PLAYBACK SPEED
      </button>
      <button type="button"
              class="menu-action select"
              [class.selected]="playbackRate === value"
              *ngFor="let value of [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]"
              (click)="morePopoverMenuIndex = -1; handlePlaybackRateChange(value)">
        {{value === 1 ? 'Normal' : value}}
      </button>
    </ng-container>
  </div>
</ng-template>
