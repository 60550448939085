import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { CommonTransformer } from '@statera/sdk/common';

@Pipe({
  name: 'safeCurrency',
})
@Injectable()
export class SafeCurrencyPipe implements PipeTransform {
  private readonly _commonTransformer: CommonTransformer;

  constructor(commonTransformer: CommonTransformer) {
    this._commonTransformer = commonTransformer;
  }

  transform(value: any, ...args: Array<any>): any {
    return this._commonTransformer.transformCurrencySafe(value, ...args);
  }
}

