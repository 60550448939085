import { Injectable } from '@angular/core';
import { Configuration } from '@statera/sdk/common';

import { LoggerDriver } from './logger.driver';
import { LoggerLevel, LoggerTopic } from './logger.model';

@Injectable()
export class LoggerService {
  private readonly _configuration: Configuration;
  private readonly _loggerDriver: LoggerDriver;

  constructor(configuration: Configuration, loggerDriver: LoggerDriver) {
    this._configuration = configuration;
    this._loggerDriver = loggerDriver;
  }

  debug(topic: LoggerTopic, message: string, payload?: any): void {
    this.log(LoggerLevel.Debug, topic, message, payload);
  }

  warning(topic: LoggerTopic, message: string, payload?: any): void {
    this.log(LoggerLevel.Warning, topic, message, payload);
  }

  info(topic: LoggerTopic, message: string, payload?: any): void {
    this.log(LoggerLevel.Info, topic, message, payload);
  }

  error(topic: LoggerTopic, message: string, payload?: any): void {
    this.log(LoggerLevel.Error, topic, message, payload);
  }

  log(level: LoggerLevel, topic: LoggerTopic, message: string, payload?: any): void {
    if (!this._configuration.loggingEnabled && level !== LoggerLevel.Error) {
      return;
    }

    this._loggerDriver.write(level, topic, message, payload);
  }
}
