import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import * as models from '../../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-tenant-square-footage-phase-in-result',
  templateUrl: 'tenant-square-footage-phase-in-result.component.html',
  styleUrls: ['tenant-square-footage-phase-in-result.component.scss'],
})
export class TenantSquareFootagePhaseInResultComponent implements OnInit {
  @Input() phaseInResults: Array<models.ITenantSquareFootagePhaseInResultViewModel>;
  @Input() userRole: string;
  @Input() isAccepted: boolean;
  @Input() isLineThrough: boolean;

  ngOnInit(): void {
    this.phaseInResults = this.phaseInResults || <Array<models.ITenantSquareFootagePhaseInResultViewModel>>[];
  }

  safeDate(input: Date): Date {
    if (!moment(input).isValid()) {
      return null;
    }

    return input;
  }
}
