<form name="form" class="h-100" novalidate
      (ngSubmit)="submit()" #form="ngForm">
  <div class="d-flex flex-column h-100">
    <!-- Start: Popup content -->
    <div class="flex-grow-1 flex-shrink-1 overflow-hidden mh-0">
      <dx-scroll-view width="100%" height="100%">
        <div class="popup-content">
          <!-- Start: Burn Down Schedule Table -->
          <div class="table-responsive mb-2">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    Expense Item
                  </th>
                  <th>
                    PSF
                  </th>
                </tr>
              </thead>
              <tbody>
              <!-- Start: Insurance -->
              <tr>
                <td>
                  Insurance
                </td>
                <td>
                  <dx-number-box name="insurance" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [value]="realEstateTaxesCamExpensesBreakdownViewModel.insurance"
                                 (valueChange)="handleInsuranceChange($event)"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Insurance -->
              <!-- Start: Repairs & Maintenance -->
              <tr>
                <td>
                  Repairs & Maintenance
                </td>
                <td>
                  <dx-number-box name="repairsAndMaintenance" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.repairsAndMaintenance"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Repairs & Maintenance -->
              <!-- Start: Fire & Life Safety -->
              <tr>
                <td>
                  Fire & Life Safety
                </td>
                <td>
                  <dx-number-box name="fireAndLifeSafety" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.fireAndLifeSafety"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Fire & Life Safety -->
              <!-- Start: Parking Lot Maintenance -->
              <tr>
                <td>
                  Parking Lot Maintenance
                </td>
                <td>
                  <dx-number-box name="parkingLotMaintenance" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.parkingLotMaintenance"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Parking Lot Maintenance -->
              <!-- Start: Roof Repairs -->
              <tr>
                <td>
                  Roof Repairs
                </td>
                <td>
                  <dx-number-box name="roofRepairs" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.roofRepairs"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Roof Repairs -->
              <!-- Start: Landscaping -->
              <tr>
                <td>
                  Landscaping
                </td>
                <td>
                  <dx-number-box name="landscaping" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.landscaping"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Landscaping -->
              <!-- Start: Snow Removal -->
              <tr>
                <td>
                  Snow Removal
                </td>
                <td>
                  <dx-number-box name="landscaping" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [(value)]="realEstateTaxesCamExpensesBreakdownViewModel.snowRemoval"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Snow Removal -->
              <!-- Start: Management Fee -->
              <tr>
                <td>
                  Management Fee
                </td>
                <td>
                  <dx-number-box name="managementFee" min="0" step="0"
                                 placeholder="$ (e.g. 5)"
                                 [format]="getDevExpressPSFMetricsFormat"
                                 [value]="realEstateTaxesCamExpensesBreakdownViewModel.managementFee"
                                 (valueChange)="handleManagementFeeChange($event)"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                  </dx-number-box>
                </td>
              </tr>
              <!-- End: Management Fee -->
              <!-- Start: Management Fee -->
              <tr [class.text-danger]="!isTotalMatchesEstimatedOpEx()"
                  [class.text-success]="isTotalMatchesEstimatedOpEx()">
                <td>
                  Total
                </td>
                <td>
                  {{getTotal()}}
                </td>
              </tr>
              <!-- End: Management Fee -->
              </tbody>
            </table>
          </div>
          <!-- End: Burn Down Schedule Table -->

          <p class="total-not-matched-error text-danger m-0"
             *ngIf="!isTotalMatchesEstimatedOpEx()">
            Your total does not match your estimate.
          </p>

        </div>
      </dx-scroll-view>
    </div>
    <!-- End: Popup content -->
  </div>
</form>

