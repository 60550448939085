import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthModule } from './auth.module';

import { CanActivateGuardFactory } from '../access/guards/factory.guard';
import { NoAuthGuard } from './guards/no-auth.guard';

import { AuthenticationComponent } from './components/authentication/authentication.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordRequestComponent } from './components/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TwoFactorAuthComponent } from './components/two-factor-auth/two-factor-auth.component';

const factoryCallback = (path: string) => {
  const {origin, search} = location;

  location.assign([origin, path, search].filter(Boolean).join(''));

  return false;
};

const [signInGuardToken, signInGuardProvider] = CanActivateGuardFactory
  .make(() => factoryCallback('/v2/auth/sign-in'));

const [signUpGuardToken, signUpGuardProvider] = CanActivateGuardFactory
  .make(() => factoryCallback('/v2/auth/sign-up'));

const [forgotPasswordGuardToken, forgotPasswordGuardProvider] = CanActivateGuardFactory
  .make(() => factoryCallback('/v2/auth/forgot-password'));

const [resetPasswordGuardToken, resetPasswordGuardProvider] = CanActivateGuardFactory
  .make(() => factoryCallback('/v2/auth/reset-password'));

const authRoutes: Routes = [
  {
    path: 'signin-user',
    canActivate: [
      NoAuthGuard,
      signInGuardToken,
    ],
    component: AuthenticationComponent,
  },
  {
    path: 'reset-password',
    canActivate: [
      NoAuthGuard,
      resetPasswordGuardToken,
    ],
    component: ResetPasswordComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [
      NoAuthGuard,
      forgotPasswordGuardToken,
    ],
    component: ResetPasswordRequestComponent,
  },
  {
    path: 'signup',
    canActivate: [
      NoAuthGuard,
      signUpGuardToken,
    ],
    component: RegisterComponent,
  },
  {
    path: '2fa',
    canActivate: [
      NoAuthGuard,
    ],
    component: TwoFactorAuthComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes),
  ],
  providers: [
    signInGuardProvider,
    signUpGuardProvider,
    forgotPasswordGuardProvider,
    resetPasswordGuardProvider,
  ],
  exports: [
    RouterModule,
  ],
})
export class AuthRouterModule {}
