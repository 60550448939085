import { Injectable } from '@angular/core';
import * as ng from '@angular/core';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';
import { ComponentLoaderService } from '../../infrastructure/services/component-loader.service';

import { DocumentViewerRefService } from './document-viewer-ref.service';

import { DocumentViewerDocument, DocumentViewerOptions } from '../models/document-viewer.model';

import { DocumentViewerDialogComponent } from '../components/document-viewer-dialog/document-viewer-dialog.component';

@Injectable()
export class DocumentViewerService {
  private readonly _renderer: ng.Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;
  private readonly _config: DocumentViewerOptions;

  private _loader: ComponentLoaderService<DocumentViewerDialogComponent>;

  constructor(rendererFactory: ng.RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
    this._config = new DocumentViewerOptions();
  }

  show(documents: Array<DocumentViewerDocument>, config?: DocumentViewerOptions): DocumentViewerRefService {
    return this._showDocumentViewer(documents, {...this._config, ...config});
  }

  hide(): void {
    this._hideImageViewer();
  }

  private _showDocumentViewer(documents: Array<DocumentViewerDocument>, config: DocumentViewerOptions): DocumentViewerRefService {
    this._loader = this._componentLoaderFactory.createLoader<DocumentViewerDialogComponent>(null, null, null);

    const documentViewerRefService = new DocumentViewerRefService();

    const documentViewerDialogRef = this._loader
      .provide({provide: DocumentViewerRefService, useValue: documentViewerRefService})
      .provide({provide: DocumentViewerOptions, useValue: {...config, documentViewerService: this}})
      .attach(DocumentViewerDialogComponent);

    documentViewerDialogRef.show(null, null, { documents, activeIndex: config.activeIndex });

    documentViewerRefService.hide = () => documentViewerDialogRef.instance.hide();

    return documentViewerRefService;
  }

  private _hideImageViewer(): void {
    this._loader.hide();
    this._loader = null;
  }
}
