import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { PortfolioInvitationStore } from './portfolio-invitation.store';

import * as models from './portfolio-invitation.model';

@Injectable()
export class PortfolioInvitationRepository {
  private readonly _portfolioInvitationStore: PortfolioInvitationStore;
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(
    portfolioInvitationStore: PortfolioInvitationStore,
    configuration: Configuration,
    httpClient: HttpClient
  ) {
    this._portfolioInvitationStore = portfolioInvitationStore;
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  createPortfolioInvitation(portfolioInvitation: models.PortfolioInvitation): Observable<string | void> {
    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/portfolio/invitation`,
        portfolioInvitation,
        {
          responseType: 'text',
        },
      );
  }

  getPortfolioInvitationInfo(protectedToken: string): Observable<models.PortfolioInvitationInfo> {
    return this._httpClient
      .get<models.PortfolioInvitationInfo>(
        `${this._configuration.apiEndpoint}/portfolio/invitation/info?token=${protectedToken}`,
      );
  }

  acceptPortfolioInvitation(protectedToken: string): Observable<models.PortfolioInvitationUser> {
    return this._httpClient
      .get<models.PortfolioInvitationUser>(
        `${this._configuration.apiEndpoint}/portfolio/invitation/accept?token=${protectedToken}`,
      );
  }

  setStoredPortfolioInvitationInfo(invitationInfo: models.PortfolioInvitationInfo): void {
    this._portfolioInvitationStore.invitationInfo.next(invitationInfo);
  }

  getStoredPortfolioInvitationInfo(): Observable<models.PortfolioInvitationInfo> {
    return this._portfolioInvitationStore.invitationInfo.asObservable();
  }
}
