import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { DashboardManager } from './dashboard.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    DashboardManager,
  ],
})
export class DashboardModule {
  static forChild(configuration: Configuration): ModuleWithProviders<DashboardModule> {
    return {
      ngModule: DashboardModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        DashboardManager,
      ],
    };
  }
}
