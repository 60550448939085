import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';
import { LoggerService } from '@statera/sdk/logger';

import { WebsocketClient } from './websocket.client';

import { AlertGroup, ChatMessage, WebsocketResponse, RefreshColabo, StageChanged } from './websocket.model';

@Injectable()
export class WebsocketRepository {
  private readonly _configuration: Configuration;
  private readonly _loggerService: LoggerService;
  private readonly _websocketClient: WebsocketClient;

  constructor(configuration: Configuration, loggerService: LoggerService, websocketClient: WebsocketClient) {
    this._configuration = configuration;
    this._loggerService = loggerService;
    this._websocketClient = websocketClient;
  }

  getRealtimeChatMessage(): Observable<WebsocketResponse<ChatMessage>> {
    return this._websocketClient
      .listen<ChatMessage>(this._configuration.websocketEndpoint, 'notify');
  }

  getRealtimeAlertGroup(): Observable<WebsocketResponse<AlertGroup>> {
    return this._websocketClient
      .listen<AlertGroup>(this._configuration.websocketEndpoint, 'alert');
  }

  getRealtimeStageChangedMessage(): Observable<WebsocketResponse<StageChanged>> {
    return this._websocketClient
      .listen<StageChanged>(this._configuration.websocketEndpoint, 'stage-changed');
  }

  getRealtimeRefreshColaboMessage(): Observable<WebsocketResponse<RefreshColabo>> {
    return this._websocketClient
      .listen<RefreshColabo>(this._configuration.websocketEndpoint, 'refresh-colabo');
  }
}
