<button type="button" class="b-btn-icon-info position-absolute"
        [class.edit-mode-btn]="editMode"
        (click)="$event.preventDefault()" #burndownTooltip>
  <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
</button>
<dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
            [width]="250" [target]="burndownTooltip">
  <div>
    Building Level MLA
  </div>
</dx-tooltip>
<dx-scroll-view>
  <form name="form" class="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-content">
      <div class="form-content-column">
        <div class="building-cart">
          <div>
            <!-- Start: Name -->
            <div class="building-cart-title">
              <i inlineSVG="assets/img/sign-icons.svg#unit-icon"></i>
              <p>
                {{building.name}}
              </p>
            </div>
            <!-- End: Name -->
            <!-- Start: Size -->
            <ng-container *ngIf="building.totalBuildingSize; else noBuildingUnitSizeTemplate">
              <p class="building-cart-size">
                Unit size: {{building.totalBuildingSize | safeNumber}} SF
              </p>
            </ng-container>
            <ng-template #noBuildingUnitSizeTemplate>
              <p class="building-cart-size">
                Unit size: N/A
              </p>
            </ng-template>
            <!-- End: Size -->
          </div>
        </div>
        <p class="form-title">Investment/Return</p>
        <!-- Start: Investment Basis -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Investment Basis
            </p>
            <div class="dx-field-value">
              <div class="test">
                <app-input-field [placeholder]="'(e.g. 50)'" [name]="'investmentBasis'"
                                 [min]="0" [step]="0" [width]="'75px'"
                                 [format]="'#,###.##'"  [className]="'no-invalid-message'"
                                 [errorMessage]="'Investment Basis is required'"
                                 [value]="buildingBudget.investmentBasis" [prefix]="'$'" [suffix]="'PSF'"
                                 (valueChange)="buildingBudget.investmentBasis = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Investment Basis -->

        <!-- Start: Investment Date -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Investment Date
            </p>
            <div class="dx-field-value">
              <div>
                <dx-date-box class="real-estate-input" type="date" dateSerializationFormat ="yyyy-MM-dd"
                             pickerType="calendar" placeholder="Insert investment date" name="investmentDate"
                             [(ngModel)]="buildingBudget.investmentDate" width="250px">
                  <dx-validator>
                    <dxi-validation-rule type="required" class="error-message" message="INVESTMENT DATE IS REQUIRED">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Investment Date -->

        <!-- Start: Budgeted CapEx -->
        <div>
          <div class="dx-field">
            <p class="form-text pt-2">
              Budgeted CapEx
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.00)'" [name]="'budgetedCapex'"
                                 [min]="0" [step]="0" [width]="'87px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Budgeted CapEx is required'"
                                 [value]="buildingBudget.budgetedCapex" [prefix]="'$'" [suffix]="'PSF'"
                                 (valueChange)="buildingBudget.budgetedCapex = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Budgeted CapEx -->

        <div *ngIf="buildingBudget.investmentBasis && buildingBudget.budgetedCapex">
          <div class="dx-field">
            <p class="form-text">
              Total Cost: {{(buildingBudget.investmentBasis + buildingBudget.budgetedCapex) | safeCurrency}}
            </p>
          </div>
        </div>

        <div>
          <div class="dx-field">
            <p class="form-text">
              <dx-check-box id="addCapitalItemsCheckBox" class="mb-2" name="hasCapitalItems"
                            [(ngModel)]="buildingBudget.hasCapitalItems">
              </dx-check-box>
              <label for="addCapitalItemsCheckBox">Add Capital Items</label>
            </p>
            <div *ngIf="buildingBudget.hasCapitalItems" class="dx-field-value">
              <div >
                <dx-tag-box class="no-invalid-message real-estate-input mb-0 " placeholder="Type in Capital Items" name="capitalItems" required
                            [acceptCustomValue]="true" [openOnFieldClick]="false" [multiline]="true" [(ngModel)]="buildingBudget.capitalItems">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Capital Items is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-tag-box>
              </div>
            </div>
          </div>
        </div>

        <!-- Start: Going In Cap Rate -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Going In Cap Rate
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 1)'" [name]="'goingInCapRate'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Going In Cap Rate is required'"
                                 [value]="buildingBudget.goingInCapRate" [suffix]="'%'"
                                 (valueChange)="buildingBudget.goingInCapRate = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Going In Cap Rate -->

        <!-- Start: Exit Cap Rate -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Exit Cap Rate
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 1)'" [name]="'exitCapRate'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Exit Cap Rate is required'"
                                 [value]="buildingBudget.exitCapRate" [suffix]="'%'"
                                 (valueChange)="buildingBudget.exitCapRate = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Exit Cap Rate -->

        <!-- Start: Yield-On-Cash -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Yield-On-Cash
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 1)'" [name]="'yieldOnCash'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Yield-On-Cash is required'"
                                 [value]="buildingBudget.yieldOnCash" [suffix]="'%'"
                                 (valueChange)="buildingBudget.yieldOnCash = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Yield-On-Cash -->
      </div>

      <div class="form-content-column">
        <p class="form-title">MLA</p>
        <!-- Start: Market Rent -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Market Rent
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.50)'" [name]="'marketRate'"
                                 [min]="0" [step]="0" [width]="'87px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Market rate is required'"
                                 [value]="buildingBudget.marketRate" [prefix]="'$'" [suffix]="'PSF/Y'"
                                 (valueChange)="buildingBudget.marketRate = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Market Rent -->

        <!-- Start: Market Rent Growth -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Market Rent Growth
            </p>
            <div class="dx-field-value">
              <div>
                <table class="form-table">
                  <thead>
                  <tr>
                    <th>Year</th>
                    <th>%</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let marketRateGrowth of buildingBudget.marketRateGrowths; let index = index;">
                    <td>{{marketRateGrowth.year}}</td>
                    <td>
                      <app-input-field [placeholder]="'(e.g. 1)'" [name]="'marketRateGrowth_' + index"
                                       [min]="0" [step]="0" [width]="'68px'" [rounded]="true"
                                       [format]="'##.##'" [className]="'no-invalid-message py-0'"
                                       [errorMessage]="'Market Rate Growth is required'"
                                       [value]="marketRateGrowth.value" [suffix]="'%'"
                                       (valueChange)="marketRateGrowth.value = $event;"
                      ></app-input-field>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>Average</td>
                    <td>
                      {{getAverageMarketRateGrowth() | safeNumber: '1.2-2'}}%
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Market Rent Growth -->

        <!-- Start: Lease Term -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Lease Term
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5)'" [name]="'leaseTerm'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Lease Term is required'"
                                 [value]="buildingBudget.leaseTerm" [suffix]="'months'"
                                 (valueChange)="buildingBudget.leaseTerm = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Lease Term -->

        <!-- Start: Escalations -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Escalations
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 2.5)'" [name]="'escalations'"
                                 [min]="0" [step]="0" [width]="'80px'"
                                 [format]="'#0.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Escalations is required'"
                                 [value]="buildingBudget.escalations" [suffix]="'%'"
                                 (valueChange)="buildingBudget.escalations = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Escalations -->

        <!-- Start: Free Rent (Renewal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Free Rent (Renewal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 1)'" [name]="'freeRentRenewal'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Free Rent is required'"
                                 [value]="buildingBudget.freeRentRenewal" [suffix]="'months'"
                                 (valueChange)="buildingBudget.freeRentRenewal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Free Rent (Renewal) -->

        <!-- Start: Free Rent (New Deal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Free Rent (New Deal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 1)'" [name]="'freeRentNewDeal'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Free Rent is required'"
                                 [value]="buildingBudget.freeRentNewDeal" [suffix]="'months'"
                                 (valueChange)="buildingBudget.freeRentNewDeal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Free Rent (New Deal) -->
      </div>

      <div class="form-content-column">
        <!-- Start: Tenant Improvements (Renewal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Improvements (Renewal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 2.45)'" [name]="'tenantImprovementsRenewal'"
                                 [min]="0" [step]="0" [width]="'87px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Tenant Improvements is required'"
                                 [value]="buildingBudget.tenantImprovementsRenewal" [prefix]="'$'" [suffix]="'PSF'"
                                 (valueChange)="buildingBudget.tenantImprovementsRenewal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Improvements (Renewal) -->

        <!-- Start: Tenant Improvements (New Deal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Improvements (New Deal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 2.45)'" [name]="'tenantImprovementsNewDeal'"
                                 [min]="0" [step]="0" [width]="'87px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Tenant Improvements is required'"
                                 [value]="buildingBudget.tenantImprovementsNewDeal" [prefix]="'$'" [suffix]="'PSF'"
                                 (valueChange)="buildingBudget.tenantImprovementsNewDeal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Improvements (New Deal) -->

        <!-- Start: Downtime -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Downtime
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 2)'" [name]="'downtime'"
                                 [min]="0" [step]="0" [width]="'68px'"
                                 [format]="'#0'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Downtime is required'"
                                 [value]="buildingBudget.downtime" [suffix]="'months'"
                                 (valueChange)="buildingBudget.downtime = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Downtime -->

        <!-- Start: OpEx -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              OpEx
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 2)'" [name]="'opEx'"
                                 [min]="0" [step]="0" [width]="'87px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'OpEx is required'"
                                 [value]="buildingBudget.opEx" [prefix]="'$'" [suffix]="'PSF'"
                                 (valueChange)="buildingBudget.opEx = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: OpEx -->

        <!-- Start: Renewal Probability -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Renewal Probability
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 50)'" [name]="'renewalProbability'"
                                 [min]="0" [step]="0" [width]="'75px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Renewal Probability is required'"
                                 [value]="buildingBudget.renewalProbability" [suffix]="'%'"
                                 (valueChange)="buildingBudget.renewalProbability = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Renewal Probability -->

        <p class="form-title">LEASING COMMISSIONS (RENEWAL)</p>
        <!-- Start: Landlord Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Landlord Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.0)'" [name]="'landlordRepRenewal'"
                                 [min]="0" [step]="0" [width]="'80px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Landlord Rep is required'"
                                 [value]="buildingBudget.landlordRepRenewal" [suffix]="'%'"
                                 (valueChange)="buildingBudget.landlordRepRenewal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Landlord Rep -->

        <!-- Start: Tenant Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.0)'" [name]="'tenantRepRenewal'"
                                 [min]="0" [step]="0" [width]="'80px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Tenant Rep is required'"
                                 [value]="buildingBudget.tenantRepRenewal" [suffix]="'%'"
                                 (valueChange)="buildingBudget.tenantRepRenewal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Rep -->

        <p class="form-title">LEASING COMMISSIONS (NEW DEAL)</p>

        <!-- Start: Landlord Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Landlord Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.0)'" [name]="'landlordRepNewDeal'"
                                 [min]="0" [step]="0" [width]="'80px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Landlord Rep is required'"
                                 [value]="buildingBudget.landlordRepNewDeal" [suffix]="'%'"
                                 (valueChange)="buildingBudget.landlordRepNewDeal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Landlord Rep -->

        <!-- Start: Tenant Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field [placeholder]="'(e.g. 5.0)'" [name]="'tenantRepNewDeal'"
                                 [min]="0" [step]="0" [width]="'80px'"
                                 [format]="'#,###.##'" [className]="'no-invalid-message'"
                                 [errorMessage]="'Tenant Rep is required'"
                                 [value]="buildingBudget.tenantRepNewDeal" [suffix]="'%'"
                                 (valueChange)="buildingBudget.tenantRepNewDeal = $event;"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Rep -->
      </div>

    </div>

    <!-- Start: Action Buttons -->
    <div class="form-actions main-actions">
      
        <div></div>
        <div class="form-action-button">
          <dx-button type="normal" text="Cancel" (click)="close()">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="success" text="Save"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
     
    </div>
    <!-- End: Action Buttons -->

  </form>
</dx-scroll-view>
