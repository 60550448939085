import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {Configuration, ICompanyPredictionViewModel} from '@statera/sdk/common';
import { Company, CompanyType } from './company.model';

@Injectable()
export class CompanyRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient, configuration: Configuration) {
    this._httpClient = httpClient;
    this._configuration = configuration;
  }

  getCompanies(companyType: CompanyType): Observable<Array<Company>> {
    let params = new HttpParams();

    params = params.set('companyType', companyType.toString());

    return this._httpClient.get<Array<Company>>(`${this._configuration.apiEndpoint}/companies`, {params});
  }

  searchCompanies(text: string, companyType: CompanyType): Observable<Array<ICompanyPredictionViewModel>> {
    let params = new HttpParams();

    params = params.set('companyType', companyType.toString());
    params = params.set('searchText', text);

    return this._httpClient.get<Array<Company>>(`${this._configuration.apiEndpoint}/companies/predictions`, {params});
  }

  updateCompany(company: Company): Observable<Company> {
    return this._httpClient.post<Company>(`${this._configuration.apiEndpoint}/companies`, company);
  }

  getCompany(companyId: number): Observable<Company> {
    return this._httpClient.get<Company>(`${this._configuration.apiEndpoint}/companies/${companyId}`);
  }
}
