import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { TermHoldoverProvisionManager } from './term-holdover-provision.manager';

import { TermHoldoverProvisionRepository } from './term-holdover-provision.repository';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    TermHoldoverProvisionRepository,
    TermHoldoverProvisionManager,
  ],
})
export class TermHoldoverProvisionModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TermHoldoverProvisionModule> {
    return {
      ngModule: TermHoldoverProvisionModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TermHoldoverProvisionRepository,
        TermHoldoverProvisionManager
      ],
    };
  }
}
