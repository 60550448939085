import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { FeatureTogglingStore } from './feature-toggling.store';

import { CompanyFeatureToggles } from './feature-toggling.model';

@Injectable()
export class FeatureTogglingRepository {
  private readonly _featureTogglingStore: FeatureTogglingStore;
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(
    featureTogglingStore: FeatureTogglingStore,
    configuration: Configuration,
    httpClient: HttpClient
  ) {
    this._featureTogglingStore = featureTogglingStore;
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  requestCompanyFeatureToggles(): Observable<CompanyFeatureToggles> {
    return this._httpClient
      .get<CompanyFeatureToggles>(
        `${this._configuration.apiEndpoint}/feature-toggle/company`
      );
  }

  getStoredCompanyFeatureToggles(): Observable<CompanyFeatureToggles> {
    return this._featureTogglingStore.companyFeatureToggles;
  }

  getStoredCompanyFeatureTogglesSync(): CompanyFeatureToggles {
    return this._featureTogglingStore.companyFeatureToggles?.value;
  }

  setStoredCompanyFeatureToggles(companyFeatureToggles: CompanyFeatureToggles): void {
    this._featureTogglingStore.companyFeatureToggles.next(companyFeatureToggles);
  }
}
