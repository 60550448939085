<div class="row"
     *ngIf="isAccepted || isLineThrough || userRole">
  <p class="font-weight-bold">
    Phase-in Option:
  </p>
</div>
<div class="table-responsive"
     [class.with-margin]="isAccepted || isLineThrough || userRole"
     [class.is-accepted]="isAccepted">
  <table class="table">
    <thead>
    <tr>
      <th class="align-middle w-25" colspan="3">
        results preview:
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="align-middle w-25">Start Date</td>
      <td class="align-middle w-25">End Date</td>
      <td class="align-middle w-25">Total SF</td>
    </tr>
    <tr *ngFor="let phaseInResult of phaseInResults; let index = index;"
        [class.changes-accepted]="isAccepted" [class.line-through]="isLineThrough"
        [class.landlord-changes]="userRole === 'Landlord'" [class.tenant-changes]="userRole === 'Tenant'">
      <!-- Start: Start Date -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{safeDate(phaseInResult.startDate) | date: 'MM.dd.yy'}}
        </p>
      </td>
      <!-- End: Start Date -->
      <!-- Start: End Date -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{safeDate(phaseInResult.endDate) | date: 'MM.dd.yy'}}
        </p>
      </td>
      <!-- End: End Date -->
      <!-- Start: Total SF -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{phaseInResult.totalSf | number}}
        </p>
      </td>
      <!-- End: Total SF -->
    </tr>
    </tbody>
  </table>
</div>
