import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';

import { ImageViewerModule } from '../image-viewer/image-viewer.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { VideoPlayerModule } from '../video-player/video-player.module';

import { DocumentViewerService } from './services/document-viewer.service';

import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { DocumentViewerDocumentComponent } from './components/document-viewer-document/document-viewer-document.component';
import { DocumentViewerDialogComponent } from './components/document-viewer-dialog/document-viewer-dialog.component';
import { DocumentViewerPreloaderComponent } from './components/document-viewer-preloader/document-viewer-preloader.component';

@NgModule({
  imports: [
    CommonModule,

    PdfViewerModule,

    DxPopupModule,
    DxScrollViewModule,

    ImageViewerModule,
    InfrastructureModule,
    VideoPlayerModule,
  ],
  declarations: [
    DocumentViewerComponent,
    DocumentViewerDocumentComponent,
    DocumentViewerDialogComponent,
    DocumentViewerPreloaderComponent,
  ],
  providers: [
    DocumentViewerService,
  ],
  entryComponents: [
    DocumentViewerDialogComponent,
  ],
  exports: [
    DocumentViewerComponent,
  ],
})
export class DocumentViewerModule {
  static forRoot(): ModuleWithProviders<DocumentViewerModule> {
    return {
      ngModule: DocumentViewerModule,
      providers: [
      ],
    };
  }
}
