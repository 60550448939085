<div class="input-field"
     [ngClass]="{
     'input-field-with-suffix': !!suffix,
     'input-field-with-prefix': !!prefix,
     'disabled': disabled,
     'no-suffix': !suffix,
     'number-box-invalid': numberBox.validationStatus === 'invalid',
     'rounded-10': rounded,
     'pl-0': !prefix
     }">
  <span class="input-field-prefix" *ngIf="prefix">
    {{ prefix }}
  </span>
  <dx-number-box class="real-estate-input"
                 appIgnoreScrollwheel
                 [ngClass]="{'prefix': prefix, 'suffix': suffix}"
                 [name]="name"
                 [id]="id"
                 [placeholder]="placeholder"
                 [class]="className"
                 [min]="min" [max]="max" [step]="step" [format]="format"
                 [disabled]="disabled"
                 [width]="width"
                 [height]="height"
                 [stylingMode]="stylingMode"
                 [value]="value"
                 (valueChange)="handleValueChange($event)"
                 (onFocusIn)="focusIn.emit($event)"
                 (onFocusOut)="focusOut.emit($event)"
                 #numberBox>
    <dx-validator>
      <dxi-validation-rule *ngIf="errorCondition"
                           [type]="errorType"
                           [message]="errorMessage">
      </dxi-validation-rule>
      <dxi-validation-rule *ngIf="validationCallback"
                           [type]="errorType"
                           [message]="errorMessage"
                           [validationCallback]="validationCallback">
      </dxi-validation-rule>
      <ng-container *ngIf="validationRules && validationRules.length">
        <ng-container *ngFor="let rule of validationRules">
          <dxi-validation-rule [type]="rule.type"
                               [message]="rule.message"
                               [validationCallback]="rule.validationCallback">
          </dxi-validation-rule>
        </ng-container>
      </ng-container>
    </dx-validator>
  </dx-number-box>
  <span class="input-field-suffix" *ngIf="suffix">
    {{ suffix }}
  </span>
</div>
