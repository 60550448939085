import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../auth/services/auth.service';
import { InvitationManager } from '@statera/sdk/invitation';

@Injectable()
export class AcceptInvitationGuard implements CanActivate {
  private readonly _invitationManager: InvitationManager;
  private readonly _authService: AuthService;
  private readonly _router: Router;

  constructor(invitationManager: InvitationManager, authService: AuthService, router: Router) {
    this._invitationManager = invitationManager;
    this._authService = authService;
    this._router = router;
  }

  canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const token = snapshot.queryParamMap.get('token');
    if (!token) {
      return of(this._router.parseUrl('/signin-user'));
    }

    return this._invitationManager
      .getUserInvitationInfo(token)
      .pipe(
        switchMap((userInvitationInfo) => this._authService
          .isAuthenticatedUser
          .pipe(
            map(isAuthenticated => {
              if (!userInvitationInfo || userInvitationInfo.isInvitationAccepted) {
                if (isAuthenticated) {
                  this._router.navigate(['/']);
                  return false;
                }

                this._router.navigate(['/signin-user']);
                return false;
              }

              return true;
            }),
          ),
        ),
      );
  }
}
