<ng-container *ngIf="leaseTermCustomTable">
  <div *ngIf="isAccepted || isLineThrough || leaseTeam">
    <p>
      {{leaseTermCustomTable.tableName}}
    </p>
  </div>
  <div class="phase-in__table" [class.with-margin]="isAccepted || isLineThrough || leaseTeam">
    <table >
      <thead>
      <tr *ngIf="leaseTermCustomTable.headerRow">
        <th  *ngFor="let cell of leaseTermCustomTable.headerRow.cells">{{cell.displayValue}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of leaseTermCustomTable.rows; let index = index;"
          [class.changes-accepted]="isAccepted" [class.line-through]="isLineThrough"
          [class.landlord-changes]="leaseTeam === LeaseTeam.LandlordTeam" [class.tenant-changes]="leaseTeam === LeaseTeam.TenantTeam">
        <td *ngFor="let cell of row.cells; let i = index;">
          <div>
            <div *ngIf="row.current && i == 0">Current</div>
            <span>{{cell.displayValue}}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>

