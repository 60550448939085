import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { FloorPlanManager } from './floor-plan.manager';
import { FloorPlanRepository } from './floor-plan.repository';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    FloorPlanRepository,
    FloorPlanManager,
  ],
})
export class FloorPlanModule {
  static forChild(configuration: Configuration): ModuleWithProviders<FloorPlanModule> {
    return {
      ngModule: FloorPlanModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        FloorPlanRepository,
        FloorPlanManager,
      ],
    };
  }
}
