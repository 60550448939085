import * as ng from '@angular/core';
import { Subscription } from 'rxjs';

import { ContentRefService } from './content-ref.service';

@ng.Injectable()
export class ComponentLoaderService<T> {
  private readonly _elementRef: ng.ElementRef;
  private readonly _viewContainerRef: ng.ViewContainerRef;
  private readonly _renderer: ng.Renderer2;
  private readonly _componentFactoryResolver: ng.ComponentFactoryResolver;
  private readonly _ngZone: ng.NgZone;
  private readonly _injector: ng.Injector;
  private readonly _applicationRef: ng.ApplicationRef;

  private readonly _providers: Array<ng.StaticProvider>;

  private _subscriptions: Array<Subscription>;

  private _componentFactory: ng.ComponentFactory<T>;
  private _componentRef: ng.ComponentRef<T>;
  private _contentRef: ContentRefService;

  instance: T;

  constructor(
    elementRef: ng.ElementRef,
    viewContainerRef: ng.ViewContainerRef,
    renderer: ng.Renderer2,
    componentFactoryResolver: ng.ComponentFactoryResolver,
    ngZone: ng.NgZone,
    injector: ng.Injector,
    applicationRef: ng.ApplicationRef,
  ) {
    this._elementRef = elementRef;
    this._viewContainerRef = viewContainerRef;
    this._renderer = renderer;
    this._componentFactoryResolver = componentFactoryResolver;
    this._ngZone = ngZone;
    this._injector = injector;
    this._applicationRef = applicationRef;

    this._providers = [];
    this._subscriptions = [];
  }

  provide(provider: ng.StaticProvider): ComponentLoaderService<T> {
    this._providers.push(provider);

    return this;
  }

  attach(component: ng.Type<T>): ComponentLoaderService<T> {
    this._componentFactory = this._componentFactoryResolver.resolveComponentFactory<T>(component);

    return this;
  }

  show(content?: ng.TemplateRef<T> | ng.Type<T> | string, context?: any, injectableData?: any): ng.ComponentRef<T> {
    if (!this._componentRef) {
      this._contentRef = this._getContentRef(content, context, injectableData);

      const injector = ng.Injector.create({
        providers: this._providers,
        parent: this._injector,
      });

      this._componentRef = this._componentFactory.create(injector, this._contentRef.nodes);
      this._applicationRef.attachView(this._componentRef.hostView);

      this.instance = this._componentRef.instance;

      Object.assign(this._componentRef.instance, {content, context, injectableData});

      const bodyElement = document && (document.body || document.querySelector('body'));
      if (bodyElement) {
        bodyElement.appendChild(this._componentRef.location.nativeElement);
      }

      if (this._contentRef.componentRef) {
        this._contentRef.componentRef.changeDetectorRef.markForCheck();
        this._contentRef.componentRef.changeDetectorRef.detectChanges();
      }

      this._componentRef.changeDetectorRef.markForCheck();
      this._componentRef.changeDetectorRef.detectChanges();
    }

    return this._componentRef;
  }

  hide(id?: number): ComponentLoaderService<T> {
    if (!this._componentRef) {
      return this;
    }

    const componentElement = this._componentRef.location.nativeElement;
    if (componentElement) {
      componentElement.parentNode.removeChild(componentElement);
    }

    if (this._contentRef.componentRef) {
      this._contentRef.componentRef.destroy();
    }

    if (this._viewContainerRef && this._contentRef.viewRef) {
      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._contentRef.viewRef));
    }

    if (this._contentRef.viewRef) {
      this._contentRef.viewRef.destroy();
    }

    this._contentRef = null;
    this._componentRef = null;

    if (this._subscriptions && this._subscriptions.length) {
      for (let i = 0, len = this._subscriptions.length; i < len; i++) {
        this._subscriptions[i].unsubscribe();
      }

      this._subscriptions = [];
    }

    return this;
  }

  private _getContentRef(content?: ng.TemplateRef<T> | ng.Type<T> | string, context?: any, injectableData?: any): ContentRefService {
    if (!content) {
      return new ContentRefService([]);
    }

    if (content instanceof ng.TemplateRef) {
      if (this._viewContainerRef) {
        const embeddedViewRef = this._viewContainerRef.createEmbeddedView(content, context);

        embeddedViewRef.markForCheck();

        return new ContentRefService([embeddedViewRef.rootNodes], embeddedViewRef);
      }

      const embeddedContentViewRef = content.createEmbeddedView(context);

      this._applicationRef.attachView(embeddedContentViewRef);

      return new ContentRefService([embeddedContentViewRef.rootNodes], embeddedContentViewRef);
    }

    if (typeof content === 'function') {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(content);

      const popupInjector = ng.Injector.create({
        providers: this._providers,
        parent: this._injector,
      });

      const componentRef = componentFactory.create(popupInjector);

      Object.assign(componentRef.instance, injectableData);

      this._applicationRef.attachView(componentRef.hostView);

      return new ContentRefService(
        [[componentRef.location.nativeElement]],
        componentRef.hostView,
        componentRef,
      );
    }

    return new ContentRefService([[this._renderer.createText(`${content}`)]]);
  }

  private _fireSubEvent(from: ng.EventEmitter<any>, to: ng.EventEmitter<any>): void {
    const subscription = from.subscribe((event: any) => {
      to.emit(event);
      subscription.unsubscribe();
    });
  }
}
