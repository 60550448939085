<div class="term-custom-value-template-textarea">
  <!-- Unfortunately, dx-text-area does not accept "required" input as boolean value.
       We have to use angular templates to achieve similar behavior. -->
  <ng-container *ngTemplateOutlet="required ? requiredTextAreaTemplate : notRequiredTextAreaTemplate;">
  </ng-container>
  <div class="term-custom-value-template-textarea-buttons">
    <button type="button"
            placement="bottom"
            [tooltip]="value ? 'Save as a template' : 'Choose from a template'"
            (click)="handleTemplateButtonClick()">
      <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-template"></i>
    </button>
    <dx-drop-down-button class="custom-value-textarea-more-dropdown-button"
                         icon="more" styleMode="text" keyExpr="id" displayExpr="text"
                         [items]="moreDropdownButtons" [dropDownOptions]="moreDropdownOptions" [showArrowIcon]="false"
                         (onItemClick)="handleMoreDropDownButtonClick($event)">
    </dx-drop-down-button>
  </div>
</div>

<ng-template #requiredTextAreaTemplate>
  <dx-text-area name="custom-value-textarea"
                class="mb-4"
                required
                valueChangeEvent="keyup"
                class="real-estate-input"
                [placeholder]="placeholder"
                [(value)]="value"
                (valueChange)="handleValueChange($event)">
    <dx-validator>
      <dxi-validation-rule type="required" [message]="requiredMessage">
      </dxi-validation-rule>
    </dx-validator>
  </dx-text-area>
</ng-template>

<ng-template #notRequiredTextAreaTemplate>
  <dx-text-area name="custom-value-textarea"
                class="mb-4"
                valueChangeEvent="keyup"
                class="real-estate-input"
                [placeholder]="placeholder"
                [(value)]="value"
                (valueChange)="handleValueChange($event)">
  </dx-text-area>
</ng-template>
