import { Injectable } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertType } from '@statera/sdk/alert';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

@Injectable()
export class WebsocketConnectionTerminatedInterceptor implements NotificationInterceptor<AlertNotification> {
  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert) {
      return notification;
    }

    if (alert.alertType !== AlertType.WebSocketConnectionTerminated) {
      return notification;
    }

    alert
      .reference
      .confirmed
      .pipe(
        tap(() => window.location.reload()),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    return notification;
  }
}
