import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AnalyticsFiltersRepository } from './analytics-filters.repository';

import * as models from './analytics-filters.model';

@Injectable()
export class AnalyticsFiltersManager {
  private readonly _analyticsFiltersRepository: AnalyticsFiltersRepository;

  constructor(analyticsFiltersRepository: AnalyticsFiltersRepository) {
    this._analyticsFiltersRepository = analyticsFiltersRepository;
  }

  getAnalyticsFilters(): Observable<models.AnalyticsFilters> {
    return this._analyticsFiltersRepository
      .getAnalyticsFilters()
      .pipe(
        map(analyticsFilters => {
          if (!analyticsFilters) {
            return <models.AnalyticsFilters>{};
          }

          const filters = <models.AnalyticsFilters>analyticsFilters;

          return <models.AnalyticsFilters>{
            ...filters,
            portfolios: this._initFilters(filters.portfolios, models.FilterType.Portfolio),
            buildings: this._initFilters(filters.buildings, models.FilterType.Building),
            locationStates: this._initFilters(filters.locationStates, models.FilterType.LocationState),
            locationCities: this._initFilters(filters.locationCities, models.FilterType.LocationCity),
            locationMarkets: this._initFilters(filters.locationMarkets, models.FilterType.LocationMarket),
            locationSubMarkets: this._initFilters(filters.locationSubMarkets, models.FilterType.LocationSubMarket),
            locationZipCodes: this._initFilters(filters.locationZipCodes, models.FilterType.LocationZipCode),
            locationAddresses: this._initFilters(filters.locationAddresses, models.FilterType.LocationAddress),
            dealTypes: this._initFilters(filters.dealTypes, models.FilterType.DealType),
            tenantCompanies: this._initFilters(filters.tenantCompanies, models.FilterType.TenantCompany),
            industries: this._initFilters(filters.industries, models.FilterType.Industry),
            spaceUses: this._initFilters(filters.spaceUses, models.FilterType.SpaceUse),
            leaseExpirations: this._initFilters(filters.leaseExpirations, models.FilterType.LeaseExpiration),
            squareFeetRange: this._initConsistentFilter(
              {
                ...filters.squareFeetRange,
                boundaries: { ...filters.squareFeetRange },
              },
              models.FilterType.Size,
            ),
          };
        }),
      );
  }

  getVisibleAnalyticsFilters(analyticsFilters: models.AnalyticsFilters): models.AnalyticsFilters {
    if (!analyticsFilters) {
      return <models.AnalyticsFilters>{};
    }

    const visibleAnalyticsFilters = <models.AnalyticsFilters>{
      ...analyticsFilters,
      portfolios: this._getVisibleFilters(analyticsFilters.portfolios),
      buildings: this._getVisibleFilters(analyticsFilters.buildings),
      locationStates: this._getVisibleFilters(analyticsFilters.locationStates),
      locationCities: this._getVisibleFilters(analyticsFilters.locationCities),
      locationMarkets: this._getVisibleFilters(analyticsFilters.locationMarkets),
      locationSubMarkets: this._getVisibleFilters(analyticsFilters.locationSubMarkets),
      locationZipCodes: this._getVisibleFilters(analyticsFilters.locationZipCodes),
      locationAddresses: this._getVisibleFilters(analyticsFilters.locationAddresses),
      dealTypes: this._getVisibleFilters(analyticsFilters.dealTypes),
      tenantCompanies: this._getVisibleFilters(analyticsFilters.tenantCompanies),
      industries: this._getVisibleFilters(analyticsFilters.industries),
      spaceUses: this._getVisibleFilters(analyticsFilters.spaceUses),
      leaseExpirations: this._getVisibleFilters(analyticsFilters.leaseExpirations),
    };

    if (this._isFilterVisible(analyticsFilters.squareFeetRange)) {
      visibleAnalyticsFilters.squareFeetRange = analyticsFilters.squareFeetRange;
    }

    return visibleAnalyticsFilters;
  }

  getSelectedAnalyticsFilters(analyticsFilters: models.AnalyticsFilters): models.AnalyticsFilters {
    if (!analyticsFilters) {
      return <models.AnalyticsFilters>{};
    }

    const selectedAnalyticsFilters = <models.AnalyticsFilters>{};

    const portfolios = this._getSelectedFilters(analyticsFilters.portfolios);
    if (portfolios?.length) {
      selectedAnalyticsFilters.portfolios = portfolios;
    }

    const buildings = this._getSelectedFilters(analyticsFilters.buildings);
    if (buildings?.length) {
      selectedAnalyticsFilters.buildings = buildings;
    }

    const locationStates = this._getSelectedFilters(analyticsFilters.locationStates);
    if (locationStates?.length) {
      selectedAnalyticsFilters.locationStates = locationStates;
    }

    const locationCities = this._getSelectedFilters(analyticsFilters.locationCities);
    if (locationCities?.length) {
      selectedAnalyticsFilters.locationCities = locationCities;
    }

    const locationMarkets = this._getSelectedFilters(analyticsFilters.locationMarkets);
    if (locationMarkets?.length) {
      selectedAnalyticsFilters.locationMarkets = locationMarkets;
    }

    const locationSubMarkets = this._getSelectedFilters(analyticsFilters.locationSubMarkets);
    if (locationSubMarkets?.length) {
      selectedAnalyticsFilters.locationSubMarkets = locationSubMarkets;
    }

    const locationZipCodes = this._getSelectedFilters(analyticsFilters.locationZipCodes);
    if (locationZipCodes?.length) {
      selectedAnalyticsFilters.locationZipCodes = locationZipCodes;
    }

    const locationAddresses = this._getSelectedFilters(analyticsFilters.locationAddresses);
    if (locationAddresses?.length) {
      selectedAnalyticsFilters.locationAddresses = locationAddresses;
    }

    const dealTypes = this._getSelectedFilters(analyticsFilters.dealTypes);
    if (dealTypes?.length) {
      selectedAnalyticsFilters.dealTypes = dealTypes;
    }

    const tenantCompanies = this._getSelectedFilters(analyticsFilters.tenantCompanies);
    if (tenantCompanies?.length) {
      selectedAnalyticsFilters.tenantCompanies = tenantCompanies;
    }

    const industries = this._getSelectedFilters(analyticsFilters.industries);
    if (industries?.length) {
      selectedAnalyticsFilters.industries = industries;
    }

    const spaceUses = this._getSelectedFilters(analyticsFilters.spaceUses);
    if (spaceUses?.length) {
      selectedAnalyticsFilters.spaceUses = spaceUses;
    }

    const leaseExpirations = this._getSelectedFilters(analyticsFilters.leaseExpirations);
    if (leaseExpirations?.length) {
      selectedAnalyticsFilters.leaseExpirations = leaseExpirations;
    }

    if (this._isFilterSelected(analyticsFilters.squareFeetRange)) {
      selectedAnalyticsFilters.squareFeetRange = analyticsFilters.squareFeetRange;
    }

    return selectedAnalyticsFilters;
  }

  getVisibleFilters(analyticsFilters: models.AnalyticsFilters): Array<models.AnyFilter> {
    if (!analyticsFilters) {
      return [];
    }

    return this._getVisibleFilters([
      ...analyticsFilters.portfolios,
      ...analyticsFilters.buildings,
      ...analyticsFilters.locationStates,
      ...analyticsFilters.locationCities,
      ...analyticsFilters.locationMarkets,
      ...analyticsFilters.locationSubMarkets,
      ...analyticsFilters.locationZipCodes,
      ...analyticsFilters.locationAddresses,
      ...analyticsFilters.dealTypes,
      ...analyticsFilters.tenantCompanies,
      ...analyticsFilters.industries,
      ...analyticsFilters.spaceUses,
      ...analyticsFilters.leaseExpirations,
      analyticsFilters.squareFeetRange,
    ]);
  }

  getSelectedFilters(analyticsFilters: models.AnalyticsFilters): Array<models.AnyFilter> {
    if (!analyticsFilters) {
      return [];
    }

    return this._getSelectedFilters([
      ...analyticsFilters.portfolios,
      ...analyticsFilters.buildings,
      ...analyticsFilters.locationStates,
      ...analyticsFilters.locationCities,
      ...analyticsFilters.locationMarkets,
      ...analyticsFilters.locationSubMarkets,
      ...analyticsFilters.locationZipCodes,
      ...analyticsFilters.locationAddresses,
      ...analyticsFilters.dealTypes,
      ...analyticsFilters.tenantCompanies,
      ...analyticsFilters.industries,
      ...analyticsFilters.spaceUses,
      ...analyticsFilters.leaseExpirations,
      analyticsFilters.squareFeetRange,
    ]);
  }

  getFilterName<T extends models.AnyFilter>(filter: T): string {
    if (!filter) {
      return '';
    }

    switch (filter.type) {
      case models.FilterType.Portfolio:
        return (<models.PortfolioFilter>filter).name;
      case models.FilterType.Building:
        return (<models.BuildingFilter>filter).name;
      case models.FilterType.LocationState:
        return (<models.LocationStateFilter>filter).name;
      case models.FilterType.LocationCity:
        return (<models.LocationCityFilter>filter).name;
      case models.FilterType.LocationMarket:
        return (<models.LocationMarketFilter>filter).name;
      case models.FilterType.LocationSubMarket:
        return (<models.LocationSubMarketFilter>filter).name;
      case models.FilterType.LocationZipCode:
        return (<models.LocationZipCodeFilter>filter).zipCode;
      case models.FilterType.LocationAddress:
        return (<models.LocationAddressFilter>filter).name;
      case models.FilterType.DealType:
        return (<models.DealTypeFilter>filter).name;
      case models.FilterType.Size:
        return `${(<models.SquareFeetRangeFilter>filter).min} to ${(<models.SquareFeetRangeFilter>filter).max} SF`;
      case models.FilterType.LeaseExpiration:
        return (<models.LeaseExpirationFilter>filter).name;
      case models.FilterType.TenantCompany:
        return (<models.TenantCompanyFilter>filter).companyName;
      case models.FilterType.Industry:
        return (<models.IndustryFilter>filter).name;
      case models.FilterType.SpaceUse:
        return (<models.SpaceUseFilter>filter).name;
      default:
        return 'Unknown filter';
    }
  }

  getSelectedFiltersCount<T extends models.AnyFilter>(filters: Array<T>): number {
    if (!filters || !filters.length) {
      return 0;
    }

    const selectedFilters = this._getSelectedFilters(filters);

    return selectedFilters.length;
  }

  getSelectedLocationFiltersCount(locationFilters: models.LocationFilters): number {
    if (!locationFilters) {
      return 0;
    }

    const selectedLocationStateFiltersCount = this.getSelectedFiltersCount(locationFilters.locationStates);
    const selectedLocationCityFiltersCount = this.getSelectedFiltersCount(locationFilters.locationCities);
    const selectedLocationMarketFiltersCount = this.getSelectedFiltersCount(locationFilters.locationMarkets);
    const selectedLocationSubMarketFiltersCount = this.getSelectedFiltersCount(locationFilters.locationSubMarkets);
    const selectedLocationZipCodeFiltersCount = this.getSelectedFiltersCount(locationFilters.locationZipCodes);
    const selectedLocationAddressFiltersCount = this.getSelectedFiltersCount(locationFilters.locationAddresses);

    return (
      selectedLocationStateFiltersCount +
      selectedLocationCityFiltersCount +
      selectedLocationMarketFiltersCount +
      selectedLocationSubMarketFiltersCount +
      selectedLocationZipCodeFiltersCount +
      selectedLocationAddressFiltersCount
    );
  }

  isLocationFiltersAvailable(locationFilters: models.LocationFilters): boolean {
    return (
      locationFilters &&
      (
        (locationFilters.locationStates && 0 < locationFilters.locationStates.length) ||
        (locationFilters.locationCities && 0 < locationFilters.locationCities.length) ||
        (locationFilters.locationMarkets && 0 < locationFilters.locationMarkets.length) ||
        (locationFilters.locationSubMarkets && 0 < locationFilters.locationSubMarkets.length) ||
        (locationFilters.locationZipCodes && 0 < locationFilters.locationZipCodes.length) ||
        (locationFilters.locationAddresses && 0 < locationFilters.locationAddresses.length)
      )
    );
  }

  //
  // Cleaning logic
  //

  clearFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters) {
      return;
    }

    let updatedAnalyticsFilters = analyticsFilters;

    updatedAnalyticsFilters = this.clearPortfolioFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearBuildingFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearLocationFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearDealTypeFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearTenantCompanyFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearIndustryFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearSpaceUseFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearLeaseExpirationFilters(updatedAnalyticsFilters);
    updatedAnalyticsFilters = this.clearSquareFeetRangeFilter(updatedAnalyticsFilters);

    return this.handleFiltersChange(updatedAnalyticsFilters);
  }

  clearFilter<T extends models.AnyFilter>(
    analyticsFilters: models.AnalyticsFilters,
    filter: T,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !filter) {
      return;
    }

    switch (filter.type) {
      case models.FilterType.Portfolio:
        return this.handlePortfolioFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.portfolios,
            <models.PortfolioFilter>filter,
            false,
          ),
        );
      case models.FilterType.Building:
        return this.handleBuildingFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.buildings,
            <models.BuildingFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationState:
        return this.handleLocationStateFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationStates,
            <models.LocationStateFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationCity:
        return this.handleLocationCityFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationCities,
            <models.LocationCityFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationMarket:
        return this.handleLocationMarketFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationMarkets,
            <models.LocationMarketFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationSubMarket:
        return this.handleLocationSubMarketFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationSubMarkets,
            <models.LocationSubMarketFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationZipCode:
        return this.handleLocationZipCodeFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationZipCodes,
            <models.LocationZipCodeFilter>filter,
            false,
          ),
        );
      case models.FilterType.LocationAddress:
        return this.handleLocationAddressFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.locationAddresses,
            <models.LocationAddressFilter>filter,
            false,
          ),
        );
      case models.FilterType.DealType:
        return this.handleDealTypeFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.dealTypes,
            <models.DealTypeFilter>filter,
            false,
          ),
      );
      case models.FilterType.Size:
        return this.handleSquareFeetRangeFilterChange(
          analyticsFilters,
          {
            ...analyticsFilters.squareFeetRange,
            isSelected: false,
          },
        );
      case models.FilterType.LeaseExpiration:
        return this.handleLeaseExpirationFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.leaseExpirations,
            <models.LeaseExpirationFilter>filter,
            false,
          ),
        );
      case models.FilterType.TenantCompany:
        return this.handleTenantCompanyFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.tenantCompanies,
            <models.TenantCompanyFilter>filter,
            false,
          ),
        );
      case models.FilterType.Industry:
        return this.handleIndustryFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.industries,
            <models.IndustryFilter>filter,
            false,
          ),
        );
      case models.FilterType.SpaceUse:
        return this.handleSpaceUseFilterChange(
          analyticsFilters,
          this._setFilterSelection(
            analyticsFilters.spaceUses,
            <models.SpaceUseFilter>filter,
            false,
          ),
        );
      default:
        return analyticsFilters;
    }
  }

  clearPortfolioFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.portfolios) {
      return;
    }

    const portfolioFilters = this._setFiltersSelection(analyticsFilters.portfolios, false);

    return this.handlePortfolioFilterChange(analyticsFilters, portfolioFilters);
  }

  clearBuildingFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.buildings) {
      return;
    }

    const buildingFilters = this._setFiltersSelection(analyticsFilters.buildings, false);

    return this.handleBuildingFilterChange(analyticsFilters, buildingFilters);
  }

  clearLocationFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !this.isLocationFiltersAvailable(analyticsFilters)) {
      return;
    }

    return this.handleLocationFilterChange(analyticsFilters, {
      locationStates: this._setFiltersSelection(analyticsFilters.locationStates, false),
      locationCities: this._setFiltersSelection(analyticsFilters.locationCities, false),
      locationMarkets: this._setFiltersSelection(analyticsFilters.locationMarkets, false),
      locationSubMarkets: this._setFiltersSelection(analyticsFilters.locationSubMarkets, false),
      locationZipCodes: this._setFiltersSelection(analyticsFilters.locationZipCodes, false),
      locationAddresses: this._setFiltersSelection(analyticsFilters.locationAddresses, false),
    });
  }

  clearLeaseExpirationFilters(
    analyticsFilters: models.AnalyticsFilters
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.leaseExpirations) {
      return;
    }

    const leaseExpirationFilters = this._setFiltersSelection(analyticsFilters.leaseExpirations, false);

    return this.handleLeaseExpirationFilterChange(analyticsFilters, leaseExpirationFilters);
  }

  clearDealTypeFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.dealTypes) {
      return;
    }

    const dealTypeFilters = this._setFiltersSelection(analyticsFilters.dealTypes, false);

    return this.handleDealTypeFilterChange(analyticsFilters, dealTypeFilters);
  }

  clearTenantCompanyFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.tenantCompanies) {
      return;
    }

    const tenantCompanyFilters = this._setFiltersSelection(analyticsFilters.tenantCompanies, false);

    return this.handleTenantCompanyFilterChange(analyticsFilters, tenantCompanyFilters);
  }

  clearIndustryFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.industries) {
      return;
    }

    const industryFilters = this._setFiltersSelection(analyticsFilters.industries, false);

    return this.handleIndustryFilterChange(analyticsFilters, industryFilters);
  }

  clearSpaceUseFilters(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.spaceUses) {
      return;
    }

    const spaceUseFilters = this._setFiltersSelection(analyticsFilters.spaceUses, false);

    return this.handleSpaceUseFilterChange(analyticsFilters, spaceUseFilters);
  }

  clearSquareFeetRangeFilter(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    if (!analyticsFilters || !analyticsFilters.spaceUses) {
      return;
    }

    const squareFeetRangeFilter = {
      ...analyticsFilters.squareFeetRange,
      min: analyticsFilters.squareFeetRange.boundaries.min,
      max: analyticsFilters.squareFeetRange.boundaries.max,
      boundaries: analyticsFilters.squareFeetRange.boundaries,
      isSelected: false,
    };

    return this.handleSquareFeetRangeFilterChange(analyticsFilters, squareFeetRangeFilter);
  }

  //
  // Change handlers
  //

  handleFiltersChange(
    analyticsFilters: models.AnalyticsFilters,
  ): models.AnalyticsFilters {
    return {
      ...analyticsFilters,
      squareFeetRange: {
        ...analyticsFilters.squareFeetRange,
        isSelected: (
          analyticsFilters.squareFeetRange.min !== analyticsFilters.squareFeetRange.boundaries.min ||
          analyticsFilters.squareFeetRange.max !== analyticsFilters.squareFeetRange.boundaries.max
        ),
      },
    };
  }

  handlePortfolioFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    portfolios: Array<models.PortfolioFilter>,
  ): models.AnalyticsFilters {
    const selectedPortfolios = portfolios.filter(x => x.isSelected);

    return this.handleFiltersChange({
      ...analyticsFilters,
      portfolios: portfolios,
      buildings: this._hideFiltersBySelectedPortfolios(analyticsFilters.buildings, selectedPortfolios),
      locationStates: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationStates, selectedPortfolios),
      locationCities: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationCities, selectedPortfolios),
      locationMarkets: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationMarkets, selectedPortfolios),
      locationSubMarkets: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationSubMarkets, selectedPortfolios),
      locationZipCodes: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationZipCodes, selectedPortfolios),
      locationAddresses: this._hideFiltersBySelectedPortfolios(analyticsFilters.locationAddresses, selectedPortfolios),
      dealTypes: this._hideFiltersBySelectedPortfolios(analyticsFilters.dealTypes, selectedPortfolios),
      tenantCompanies: this._hideFiltersBySelectedPortfolios(analyticsFilters.tenantCompanies, selectedPortfolios),
      industries: this._hideFiltersBySelectedPortfolios(analyticsFilters.industries, selectedPortfolios),
      spaceUses: this._hideFiltersBySelectedPortfolios(analyticsFilters.spaceUses, selectedPortfolios),
    });
  }

  handleBuildingFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    buildings: Array<models.BuildingFilter>,
  ): models.AnalyticsFilters {
    const selectedBuildings = buildings.filter(x => x.isSelected);

    return this.handleFiltersChange({
      ...analyticsFilters,
      buildings: buildings,
      locationStates: this._hideFiltersBySelectedBuildings(analyticsFilters.locationStates, selectedBuildings),
      locationCities: this._hideFiltersBySelectedBuildings(analyticsFilters.locationCities, selectedBuildings),
      locationMarkets: this._hideFiltersBySelectedBuildings(analyticsFilters.locationMarkets, selectedBuildings),
      locationSubMarkets: this._hideFiltersBySelectedBuildings(analyticsFilters.locationSubMarkets, selectedBuildings),
      locationZipCodes: this._hideFiltersBySelectedBuildings(analyticsFilters.locationZipCodes, selectedBuildings),
      locationAddresses: this._hideFiltersBySelectedBuildings(analyticsFilters.locationAddresses, selectedBuildings),
      dealTypes: this._hideFiltersBySelectedBuildings(analyticsFilters.dealTypes, selectedBuildings),
      tenantCompanies: this._hideFiltersBySelectedBuildings(analyticsFilters.tenantCompanies, selectedBuildings),
      industries: this._hideFiltersBySelectedBuildings(analyticsFilters.industries, selectedBuildings),
      spaceUses: this._hideFiltersBySelectedBuildings(analyticsFilters.spaceUses, selectedBuildings),
    });
  }

  handleLocationFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationFilters: models.LocationFilters,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, ...locationFilters});
  }

  handleLocationStateFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationStates: Array<models.LocationStateFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationStates});
  }

  handleLocationCityFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationCities: Array<models.LocationCityFilter>): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationCities});
  }

  handleLocationMarketFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationMarkets: Array<models.LocationMarketFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationMarkets});
  }

  handleLocationSubMarketFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationSubMarkets: Array<models.LocationSubMarketFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationSubMarkets});
  }

  handleLocationZipCodeFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationZipCodes: Array<models.LocationZipCodeFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationZipCodes});
  }

  handleLocationAddressFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    locationAddresses: Array<models.LocationAddressFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, locationAddresses});
  }

  handleDealTypeFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    dealTypes: Array<models.DealTypeFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, dealTypes});
  }

  handleLeaseExpirationFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    leaseExpirations: Array<models.LeaseExpirationFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, leaseExpirations});
  }

  handleTenantCompanyFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    tenantCompanies: Array<models.TenantCompanyFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, tenantCompanies});
  }

  handleIndustryFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    industries: Array<models.IndustryFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, industries});
  }

  handleSpaceUseFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    spaceUses: Array<models.SpaceUseFilter>,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, spaceUses});
  }

  handleSquareFeetRangeFilterChange(
    analyticsFilters: models.AnalyticsFilters,
    squareFeetRange: models.SquareFeetRangeFilter,
  ): models.AnalyticsFilters {
    return this.handleFiltersChange({...analyticsFilters, squareFeetRange});
  }

  //
  // Helpers
  //

  private _initFilters<T extends models.AnyFilter>(filters: Array<T>, type: models.FilterType): Array<T> {
    if (!filters) {
      return [];
    }

    return filters
      .map(x => ({
        ...x,
        id: uuidv4(),
        type: type,
        isVisible: true,
        isSelected: false,
      }));
  }

  private _initConsistentFilter<T extends models.AnyFilter>(filter: T, type: models.FilterType): T {
    if (!filter) {
      return null;
    }

    return {
      ...filter,
      id: uuidv4(),
      type: type,
      isVisible: true,
      isSelected: false,
    };
  }

  private _isFilterVisible<T extends models.AnyFilter>(filter: T): boolean {
    return filter?.isVisible;
  }

  private _isFilterSelected<T extends models.AnyFilter>(filter: T): boolean {
    return filter?.isVisible && filter?.isSelected;
  }

  private _getVisibleFilters<T extends models.AnyFilter>(filters: Array<T>): Array<T> {
    if (!filters) {
      return [];
    }

    return filters.filter(x => this._isFilterVisible(x));
  }

  private _getSelectedFilters<T extends models.AnyFilter>(filters: Array<T>): Array<T> {
    if (!filters) {
      return [];
    }

    return filters.filter(x => this._isFilterSelected(x));
  }

  private _setFiltersSelection<T extends models.AnyFilter>(
    filters: Array<T>,
    isSelected: boolean = false,
  ): Array<T> {
    if (!filters) {
      return [];
    }

    return filters.map(x => ({...x, isSelected}));
  }

  private _setFilterSelection<T extends models.AnyFilter>(
    filters: Array<T>,
    filter: T,
    isSelected: boolean = false,
  ): Array<T> {
    if (!filters) {
      return [];
    }

    return filters
      .map(x => {
        if (x.id === filter.id) {
          return {
            ...x,
            isSelected: isSelected,
          };
        }

        return x;
      });
  }

  private _hideFiltersBySelectedPortfolios<T extends models.AnalyticsFilterWithStateAndPortfolioIds>(
    filters: Array<T>,
    selectedPortfolios: Array<models.PortfolioFilter>
  ): Array<T> {
    if (!filters) {
      return [];
    }

    if (!selectedPortfolios || !selectedPortfolios.length) {
      return filters.map(x => ({...x, isVisible: true}));
    }

    return filters
      .map(x => {
        let isVisible = false;

        if (x.portfolioIds && x.portfolioIds.length) {
          isVisible = x.portfolioIds
            .some(id => selectedPortfolios
              .some(p => p.portfolioId === id)
            );
        } else if (x.portfolioId) {
          isVisible = selectedPortfolios
            .some(p => p.portfolioId === x.portfolioId);
        }

        return {...x, isVisible};
      });
  }

  private _hideFiltersBySelectedBuildings<T extends models.AnalyticsFilterWithStateAndBuildingIds>(
    filters: Array<T>,
    selectedBuildings: Array<models.BuildingFilter>
  ): Array<T> {
    if (!filters) {
      return [];
    }

    if (!selectedBuildings || !selectedBuildings.length) {
      return filters.map(x => ({...x, isVisible: true}));
    }

    return filters
      .map(x => {
        let isVisible = false;

        if (x.buildingIds && x.buildingIds.length) {
          isVisible = x.buildingIds
            .some(id => selectedBuildings
              .some(p => p.buildingId === id)
            );
        } else if (x.buildingId) {
          isVisible = selectedBuildings
            .some(p => p.buildingId === x.buildingId);
        }

        return {...x, isVisible};
      });
  }
}
