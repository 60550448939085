<dx-scroll-view width="100%" height="100%">
  <div class="header">
    <h1 class="broker-invitation-title">
      <span>Step 2. Approval Process: Broker invitation</span>
      <img src="/assets/img/add-member.png" alt="Add member icon"/>
    </h1>

    <div class="close">
      <a role="button" href="javascript:void(0)"
         (click)="close()">
        <i class="dx-icon dx-icon-close"></i>
      </a>
    </div>
  </div>

  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <ng-container [ngSwitch]="brokerInvitationType">
      <ng-container *ngSwitchCase="BrokerInvitationType.RenewalInitiatedByTenant">
        <ng-container [ngSwitch]="projectType">
          <ng-container *ngSwitchCase="ProjectTypes.Renewal">
            <div class="wrapper">

              <p>
                Thanks for approving the Renewal Request. Copies of all financial info, if provided, can be viewed in the Financials folder.
                You may proceed to negotiate directly with the Tenant or you can choose to include a Broker as part of your team.
                To include a Broker, please provide the required info below, so they can receive an invite link.
              </p>

            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ProjectTypes.Restructure">
            <div class="wrapper">

              <p>
                Thanks for approving the Restructure Request. Copies of all financial info, if provided, can be viewed in the Financials folder.
                You may proceed to negotiate directly with the Tenant or you can choose to include a Broker as part of your team.
                To include a Broker, please provide the required info below, so they can receive an invite link.
              </p>

            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ProjectTypes.NewDealInquiry">
            <div class="wrapper">

              <p>
                Thanks for approving the New Deal Request. Copies of all financial info, if provided, can be viewed in the Financials folder.
                You may proceed to negotiate directly with the Tenant or you can choose to include a Broker as part of your team.
                To include a Broker, please provide the required info below, so they can receive an invite link.
              </p>

            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="BrokerInvitationType.RenewalInitiatedByLandlord">
        <ng-container [ngSwitch]="projectType">
          <ng-container *ngSwitchCase="ProjectTypes.RenewalInitiatedByLandlord">
            <div class="wrapper">
              <p>
                Fantastic! You may proceed to negotiate directly with the {{tenantCompany?.name || 'Tenant'}} or you can choose to include a Broker as part of your team.
              </p>

              <p>
                To include a Broker, type their email and information below to invite them.
                We will send them a unique invite link allowing them to sign up and participate in this Renewal process.
              </p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ProjectTypes.Restructure">
            <div class="wrapper">
              <p>
                Fantastic! You may proceed to negotiate directly with the {{tenantCompany?.name || 'Tenant'}} or you can choose to include a Broker as part of your team.
              </p>

              <p>
                To include a Broker, type their email and information below to invite them.
                We will send them a unique invite link allowing them to sign up and participate in this Restructure process.
              </p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>


    <div class="dx-fieldset">
      <div class="row">
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              First name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="firstName" id="firstName" required
                           [isValid]="form.submitted ? firstName.valid : true"
                           [(ngModel)]="userInvitationRequest.firstName"
                           #firstName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && firstName.hasError('required')">
                First name is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              Last name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="lastName" id="lastName" required
                           [isValid]="form.submitted ? lastName.valid : true"
                           [(ngModel)]="userInvitationRequest.lastName"
                           #lastName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && lastName.hasError('required')">
                Last name is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Company name
        </div>
        <div class="dx-field-value">
          <dx-select-box name="company" id="company" displayExpr="name" required
                         placeholder="" itemTemplate="listItem"
                         noDataText="No companies found matching your criteria"
                         [dataSource]="companiesDataSource" [acceptCustomValue]="true"
                         [searchEnabled]="true" [showDropDownButton]="false"
                         [isValid]="form.submitted ? company.valid : true"
                         [ngModel]="userInvitationRequest.company"
                         (ngModelChange)="handleCompanyChange($event)"
                         (onCustomItemCreating)="handleCustomCompanyCreating($event)"
                         #company="ngModel">
            <ng-container *dxTemplate="let company of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img width="40" height="40" style="object-fit: cover"
                       [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                       [alt]="company.name" />
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && company.hasError('required')">
            Company name is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Email address
        </div>
        <div class="dx-field-value">
          <dx-text-box name="emailAddress" id="emailAddress" required
                       [isValid]="form.submitted ? emailAddress.valid : true"
                       [(ngModel)]="userInvitationRequest.emailAddress"
                       #emailAddress="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('required')">
            Email address is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset form-buttons">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" icon="plus" text="Invite" class="btn btn-primary broker-invitation-submit-button"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="success" text="Proceed Direct" width="100%" class="btn btn-primary broker-invitation-submit-button"
                     (onClick)="proceedWithoutBroker()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
