import * as models from '@statera/sdk/common';

export type QuizType = models.QuizType;
export const QuizType = models.QuizType;

export type ProjectType = models.ProjectTypeEnum;
export const ProjectType = models.ProjectTypeEnum;

export type ProjectStatus = models.ProjectStatus;
export const ProjectStatus = models.ProjectStatus;

export type RenewalProjectTriggerType = models.RenewalProjectTriggerType;
export const RenewalProjectTriggerType = models.RenewalProjectTriggerType;

export type QuizQuestion = models.IQuizQuestionViewModel;
export type QuizAnswer = models.IQuizAnswerViewModel;
export type Lease = models.ILeaseViewModel;
export type Project = models.IProjectViewModel;
export type Address = models.IAddressViewModel;
export type ApprovingAppointment = models.IApprovingAppointmentViewModel;
export type IRejectionLeaseViewModel = models.IRejectionLeaseViewModel;
export type RenewalProjectParams = models.IRenewalProjectParamsViewModel;
export type CancelledRequest = models.ICancelledRequestViewModel;
