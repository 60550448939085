import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermHoldoverProvisionManager } from '@statera/sdk/term-holdover-provision';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { HoldoverProvisionType } from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-holdover-provision',
  templateUrl: './holdover-provision.component.html',
  styleUrls: ['./holdover-provision.component.scss']
})
export class HoldoverProvisionComponent extends BaseTermDirective<models.IHoldoverProvisionTermViewModel> implements OnInit {

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  private _holdoverProvisionTermManager: TermHoldoverProvisionManager;
  options: Array<{name: string, value: models.HoldoverProvisionType}>;

  HoldoverProvisionType = HoldoverProvisionType;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,

    termHoldoverProvisionManager: TermHoldoverProvisionManager
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._holdoverProvisionTermManager = termHoldoverProvisionManager;
  }

  ngOnInit() {
    super.ngOnInit();
    this.options = this._holdoverProvisionTermManager.getOptions(this.project);
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }
}
