import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { AuthManager } from './auth.manager';
import { AuthRepository } from './auth.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    AuthManager,
    AuthRepository,
  ],
})
export class AuthModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AuthManager,
        AuthRepository,
      ],
    };
  }
}
