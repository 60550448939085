import { Injectable, RendererFactory2 } from '@angular/core';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';

import { AnalyticsTooltipService } from './analytics-tooltip.service';

@Injectable()
export class AnalyticsTooltipFactoryService {
  private readonly _rendererFactory: RendererFactory2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;

  constructor(rendererFactory: RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._rendererFactory = rendererFactory;
    this._componentLoaderFactory = componentLoaderFactory;
  }

  create<TComponent, TModel>(): AnalyticsTooltipService<TComponent, TModel> {
    return new AnalyticsTooltipService<TComponent, TModel>(this._rendererFactory, this._componentLoaderFactory);
  }
}
