<div class="spinner">
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
  <div class="spinner-blade"></div>
</div>
