import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-industry',
  templateUrl: 'analytics-filters-industry.component.html',
  styleUrls: ['analytics-filters-industry.component.scss'],
})
export class AnalyticsFiltersIndustryComponent {
  @Input() industries: Array<analyticsFiltersModels.IndustryFilter>;
  @Output() industriesChange: EventEmitter<Array<analyticsFiltersModels.IndustryFilter>>;

  constructor() {
    this.industriesChange = new EventEmitter<Array<analyticsFiltersModels.IndustryFilter>>();
  }

  handleIndustrySelection(): void {
    this.industriesChange.next([...this.industries]);
  }
}
