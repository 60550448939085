import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAbbreviation'
})
export class NumberAbbreviationPipe implements PipeTransform {
  transform(value: number): string | number {
    return this.formatAsNumberAbbreviation(value);
  }

  formatAsNumberAbbreviation(value: number): string | number {
    if (1000000 <= value) {
      return `${(value / 1000000).toFixed(0)}M`;
    }

    if (1000 <= value) {
      return `${(value / 1000).toFixed(0)}K`;
    }

    return value;
  }
}
