<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">

    <div class="wrapper">
      <h1 class="broker-invitation-title">
        <span>
          Invite 3rd party to sign
        </span>
        <img src="/assets/img/add-member.png" alt="Add member icon"/>
      </h1>
    </div>

    <div class="dx-fieldset">
      <div class="row">
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              First name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="firstName" id="firstName" required
                           [isValid]="form.submitted ? firstName.valid : true"
                           [(ngModel)]="inviteExternalSignerToSignaturesModel.firstName"
                           #firstName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && firstName.hasError('required')">
                First name is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              Last name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="lastName" id="lastName" required
                           [isValid]="form.submitted ? lastName.valid : true"
                           [(ngModel)]="inviteExternalSignerToSignaturesModel.lastName"
                           #lastName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && lastName.hasError('required')">
                Last name is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="col-12">
        <div class="dx-field">
          <div class="dx-field-label">
            Email address
          </div>
          <div class="dx-field-value">
            <dx-text-box name="emailAddress" id="emailAddress" required
                         [isValid]="form.submitted ? emailAddress.valid : true"
                         [(ngModel)]="inviteExternalSignerToSignaturesModel.email"
                         #emailAddress="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && emailAddress.hasError('required')">
              Email address is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="col-12">
        <div class="d-flex flex-row mx-n2">
          <div class="px-2 w-50">
            <dx-button type="success" class="btn btn-primary broker-invitation-submit-button"
                       width="100%" icon="plus" text="Invite"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-2 w-50">
            <dx-button type="normal" class="btn btn-outline-secondary broker-invitation-submit-button"
                       text="Cancel" width="100%"
                       (onClick)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
