import { Component, HostListener, Input, Output } from '@angular/core';
import * as ng from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CommonTools } from '@statera/sdk/common';

import * as models from '../../models/document-viewer.model';

@Component({
  selector: 'app-document-viewer',
  templateUrl: 'document-viewer.component.html',
  styleUrls: ['document-viewer.component.scss'],
})
export class DocumentViewerComponent implements ng.OnInit, ng.AfterViewInit, ng.OnDestroy {
  @Input() documents: Array<models.DocumentViewerDocument>;
  @Input() activeDocumentIndex: number;

  @Input() container: Element;

  @Output() documentSelected: ng.EventEmitter<models.DocumentViewerDocumentRef>;

  containerSize: { width: number, height: number };

  isDocumentLoaded: boolean;

  private readonly _changeDetectorRef: ng.ChangeDetectorRef;

  private readonly _destroy$: Subject<void>;
  private readonly _isRendered$: ReplaySubject<boolean>;

  constructor(changeDetectorRef: ng.ChangeDetectorRef) {
    this._changeDetectorRef = changeDetectorRef;

    this._destroy$ = new Subject<void>();
    this._isRendered$ = new ReplaySubject<boolean>();

    this.documentSelected = new ng.EventEmitter<models.DocumentViewerDocumentRef>();
  }

  ngOnInit(): void {
    this.documents = this.documents || [];
    this.activeDocumentIndex = this.activeDocumentIndex || 0;
    this.containerSize = {width: 0, height: 0};

    this.documentSelected.emit(this.getActiveDocumentRef());

    this._isRendered$
      .pipe(
        tap(() => this.setupContainerSize()),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this._isRendered$.next(true);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();
  }

  hasNextDocument(): boolean {
    return this.activeDocumentIndex + 1 < this.documents.length;
  }

  hasPreviousDocument(): boolean {
    return 0 <= this.activeDocumentIndex - 1;
  }

  nextDocument(): void {
    if (this.hasNextDocument()) {
      this.activeDocumentIndex++;
      this.documentSelected.emit(this.getActiveDocumentRef());

      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  previousDocument(): void {
    if (this.hasPreviousDocument()) {
      this.activeDocumentIndex--;
      this.documentSelected.emit(this.getActiveDocumentRef());

      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  getActiveDocumentRef(): models.DocumentViewerDocumentRef {
    const activeDocument = this.documents[this.activeDocumentIndex];
    if (!activeDocument) {
      return null;
    }

    return {
      url: activeDocument.url,
      name: activeDocument.name,
      viewerType: this.getDocumentViewerType(activeDocument),
    };
  }

  isFullscreenSupported(documentRef: models.DocumentViewerDocumentRef): boolean {
    if (!documentRef) {
      return false;
    }

    return this.container && !!this.container.requestFullscreen;
  }

  isInFullscreenMode(): boolean {
    return !!document.fullscreenElement;
  }

  async enterFullscreenMode(): Promise<void> {
    if (!this.container || this.isInFullscreenMode()) {
      return;
    }

    // tslint:disable-next-line:no-unbound-method
    const requestMethod = this.container.requestFullscreen;
    if (!requestMethod) {
      return;
    }

    await requestMethod.call(this.container);
  }

  async exitFullscreenMode(): Promise<void> {
    if (!this.container || !this.isInFullscreenMode()) {
      return;
    }

    await document.exitFullscreen();
  }

  download(documentRef: models.DocumentViewerDocumentRef): void {
    if (!documentRef || !documentRef.url) {
      return;
    }

    CommonTools.downloadFile(documentRef.url);
  }

  getDocumentViewerType(document: models.DocumentViewerDocument): models.DocumentViewerViewerType {
    if (!document) {
      return models.DocumentViewerViewerType.Unknown;
    }

    const extension = this._getDocumentExtension(document);
    if (!extension) {
      return models.DocumentViewerViewerType.Unknown;
    }

    switch (extension) {
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
      case 'webp':
        return models.DocumentViewerViewerType.Image;

      case 'mp4':
      case 'webm':
        return models.DocumentViewerViewerType.Video;

      case 'pdf':
        return models.DocumentViewerViewerType.Pdf;

      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'odt':
        return models.DocumentViewerViewerType.OfficeDocument;

      default:
        return models.DocumentViewerViewerType.Unknown;
    }
  }

  setupContainerSize(): void {
    if (!this.container) {
      return;
    }

    const clientRect = this.container.getBoundingClientRect();
    if (!clientRect) {
      return;
    }

    this.containerSize = {
      width: clientRect.width,
      height: clientRect.height,
    };

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  private _handleResize(event?: Event): void {
    this.setupContainerSize();
  }

  handleDocumentLoaded(isLoaded: boolean): void {
    this.isDocumentLoaded = isLoaded;
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  private _getDocumentExtension(document: models.DocumentViewerDocument): string {
    if (!document || !document.url) {
      return null;
    }

    const fragments = document.url.split('.');
    if (fragments.length <= 1) {
      return null;
    }

    return fragments[fragments.length - 1];
  }
}
