import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { TeamRepository } from './team.repository';
import { TeamManager } from './team.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    TeamRepository,
    TeamManager,
  ],
})
export class TeamModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TeamModule> {
    return {
      ngModule: TeamModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TeamRepository,
        TeamManager,
      ],
    };
  }
}
