import * as models from './message.model';
import { Observable } from 'rxjs';
import { Message } from './message.model';

export abstract class MessageManager<T> {
  abstract addMessage(message: Message);
  abstract getMessages(skip: number, take: number): Observable<Array<Message>>;
  abstract updateMessage(message: Message): Observable<Message>;
  abstract deleteMessage(message: Message);
  abstract toMessage(x: T): Message;
  abstract fromMessage(message: Message): T;

  getChatBadgeText(lease: models.Lease, userId: number) {
    if (lease && lease.leaseUsers) {
      const leaseUser = lease.leaseUsers.find(lu => lu.userId === userId);
      if (leaseUser) {
        if (leaseUser.relatedToCompanyId === lease.landlordCompany.id) {
          return 'Landlord Broker';
        }

        if (leaseUser.relatedToCompanyId === lease.tenantCompany.id) {
          return 'Tenant Broker';
        }
      }
    }
  }

  isBrokerMessage(lease: models.Lease, userId: number): boolean {
    if (lease && lease.leaseUsers) {
      const leaseUser = lease.leaseUsers.find(lu => lu.userId === userId);
      if (leaseUser) {
        if (leaseUser.relatedToCompanyId === lease.landlordCompany.id) {
          return true;
        }

        if (leaseUser.relatedToCompanyId === lease.tenantCompany.id) {
          return true;
        }
      }
    }
  }

  isDisabled(lease: models.Lease | undefined): boolean {
    return lease && (lease.leaseStatus === models.LeaseStatus.Cancelled || lease.leaseStatus === models.LeaseStatus.Rejected);
  }
}
