import { Injectable } from '@angular/core';
import * as models from '@statera/sdk/common';
import { Observable } from 'rxjs';

import { FinancialsRequestRepository } from './financials-request.repository';

@Injectable()
export class FinancialsRequestManager {
  private readonly _financialsRequestRepository: FinancialsRequestRepository;

  constructor(financialsRequestRepository: FinancialsRequestRepository) {
    this._financialsRequestRepository = financialsRequestRepository;
  }

  uploadFinancialsFiles(alertId: number, leaseRequestId: number, files: FileList): Observable<void> {
    return this._financialsRequestRepository
      .uploadFinancialsFiles(alertId, leaseRequestId, files);
  }

  uploadFinancialsFilesByLeaseId(leaseId: number, files: FileList): Observable<void> {
    return this._financialsRequestRepository
      .uploadFinancialsFilesByLeaseId(leaseId, files);
  }


  getFinancialRequests(leaseId: number): Observable<Array<models.ILeaseRequestViewModel>> {
    return this._financialsRequestRepository.getFinancialRequests(leaseId);
  }

  createFinancialRequest(leaseId: number, request: models.IFinancialRequestViewModel): Observable<models.ILeaseRequestViewModel> {
    return this._financialsRequestRepository.createFinancialRequest(leaseId, request);
  }

  private _canUploadOrRequestFinancialDocumentInColabo(
    project: models.IProjectViewModel,
    financialRequests: Array<models.ILeaseRequestViewModel>,
  ): boolean {
    if (!project || !financialRequests || project.projectStatus !== models.ProjectStatus.Active) {
      return false;
    }

    const parentProjectTemplateItem = project.projectState.parentProjectTemplateItem ?
      project.projectState.parentProjectTemplateItem : project.projectState;

    if (parentProjectTemplateItem &&
        parentProjectTemplateItem.renewalProjectTemplateItemType >= models.RenewalProjectTemplateItemType.LetterOfIntent &&
        parentProjectTemplateItem.renewalProjectTemplateItemType !== models.RenewalProjectTemplateItemType.UnsolicitedOffer) {
      return false;
    }

    return true;
  }

  shouldApproveByLandlordTeam(project: models.IProjectViewModel,
                              financialRequests: Array<models.ILeaseRequestViewModel>) {
    const _canUploadOrRequestFinancialDocumentInColabo =
      this._canUploadOrRequestFinancialDocumentInColabo(project, financialRequests);

    if (_canUploadOrRequestFinancialDocumentInColabo) {
      const { renewalProjectTemplateItemType } = project.projectState;
      if (renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.EstablishCriteriaByLandlord ||
          renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferLandlordReviewingFinancialDocuments ||
          renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.Restructure_EstablishCriteriaByLandlord) {
        return true;
      }

      if (financialRequests.length > 0 &&
          financialRequests.some(x => (
              x.requestStatus === models.LeaseRequestStatus.ReviewRequired) &&
            x.requestType === models.LeaseRequestType.FinancialRequest)) {
        return true;
      }
    }

    return false;
  }

  hasAcceptedFinancialRequests(financialRequests: Array<models.ILeaseRequestViewModel>) {
    if (
      financialRequests.some(x =>
        x.requestStatus === models.LeaseRequestStatus.Accepted &&
        x.requestType === models.LeaseRequestType.FinancialRequest
      )
    ) {
      return true;
    }
  }

  hasUnapprovedFinancialRequests(financialRequests: Array<models.ILeaseRequestViewModel>) {
    if (
      financialRequests.some(x =>
        (x.requestStatus === models.LeaseRequestStatus.Requested ||
         x.requestStatus === models.LeaseRequestStatus.ReviewRequired) &&
        x.requestType === models.LeaseRequestType.FinancialRequest
      )
    ) {
      return true;
    }
  }

  shouldUploadByTenantTeam(project: models.IProjectViewModel,
                         financialRequests: Array<models.ILeaseRequestViewModel>) {
    const _canUploadOrRequestFinancialDocumentInColabo =
      this._canUploadOrRequestFinancialDocumentInColabo(project, financialRequests);

    if (_canUploadOrRequestFinancialDocumentInColabo) {
      return (
        financialRequests.length === 0 ||
        !financialRequests.some(x =>
          x.requestStatus === models.LeaseRequestStatus.Accepted &&
          x.requestType === models.LeaseRequestType.FinancialRequest
        )
      );

    }
    return false;
  }

  canRequestFinancialDocumentsInColabo(project: models.IProjectViewModel,
                                              financialRequests: Array<models.ILeaseRequestViewModel>, role: string) {
    if (!this._canUploadOrRequestFinancialDocumentInColabo(project, financialRequests)) {
      return false;
    }

    if (role !== 'Landlord') {
      return false;
    }

    const parentProjectTemplateItem = project.projectState.parentProjectTemplateItem ?
      project.projectState.parentProjectTemplateItem : project.projectState;
    if (parentProjectTemplateItem.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOffer) {
      return false;
    }

    return !financialRequests || financialRequests.length === 0 ||
      !financialRequests.some(fr => fr.requestStatus === models.LeaseRequestStatus.Requested ||
      fr.requestStatus === models.LeaseRequestStatus.ReviewRequired);
  }

  canUploadFinancialDocumentsInColabo(project: models.IProjectViewModel,
                                       financialRequests: Array<models.ILeaseRequestViewModel>, role: string) {
    if (!this._canUploadOrRequestFinancialDocumentInColabo(project, financialRequests)) {
      return false;
    }

    if (role !== 'Tenant') {
      return false;
    }

    const parentProjectTemplateItem = project.projectState.parentProjectTemplateItem ?
      project.projectState.parentProjectTemplateItem : project.projectState;
    if (parentProjectTemplateItem.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOffer) {
      return false;
    }

    return !financialRequests || financialRequests.length === 0 ||
      financialRequests.some(x => x.requestType === models.LeaseRequestType.FinancialRequest &&
        (x.requestStatus === models.LeaseRequestStatus.Requested || x.requestStatus === models.LeaseRequestStatus.Skipped));
  }

  approveFinancialRequest(requestId: number): Observable<models.ILeaseRequestViewModel> {
    return this._financialsRequestRepository.approveFinancialRequest(requestId);
  }
}
