import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TermCustomValueTemplateManager } from '@statera/sdk/term-custom-value-template';

import { DialogRefService } from '../../../../dialog/services/dialog-ref.service';
import { DialogService } from '../../../../dialog/services/dialog.service';

import { TermCustomValueTemplateUpsertDialogComponent } from '../term-custom-value-template-upsert-dialog/term-custom-value-template-upsert-dialog.component';
import { TermCustomValueTemplateDeleteDialogComponent } from '../term-custom-value-template-delete-dialog/term-custom-value-template-delete-dialog.component';

import * as models from '../../../../infrastructure/models/generated';

export enum TermCustomValueTemplateDialogMode {
  ChooseTemplate = 1,
  EditTemplateList = 2,
}

@Component({
  selector: 'app-term-custom-value-template-dialog',
  templateUrl: './term-custom-value-template-dialog.component.html',
  styleUrls: ['./term-custom-value-template-dialog.component.scss']
})
export class TermCustomValueTemplateDialogComponent implements OnInit, OnDestroy {
  @Input() mode: TermCustomValueTemplateDialogMode;
  @Input() leaseTermType: models.LeaseTermType;

  templates: Array<models.ITermCustomValueTemplateViewModel>;

  selectedTemplate: models.ITermCustomValueTemplateViewModel;

  isLoading: boolean;

  Modes: typeof TermCustomValueTemplateDialogMode;

  private readonly _termCustomValueTemplateManager: TermCustomValueTemplateManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _dialogService: DialogService;
  private readonly _ngZone: NgZone;

  private readonly _destroy: Subject<void>;

  constructor(
    termCustomValueTemplateManager: TermCustomValueTemplateManager,
    dialogRefService: DialogRefService,
    dialogService: DialogService,
    ngZone: NgZone,
  ) {
    this._termCustomValueTemplateManager = termCustomValueTemplateManager;
    this._dialogRefService = dialogRefService;
    this._dialogService = dialogService;
    this._ngZone = ngZone;

    this._destroy = new Subject<void>();

    this.Modes = TermCustomValueTemplateDialogMode;
  }

  ngOnInit(): void {
    this.templates = [];

    this.isLoading = true;

    this._termCustomValueTemplateManager
      .getStoredList()
      .pipe(
        switchMap((list) => {
          if (!list) {
            return this._termCustomValueTemplateManager
              .getList();
          }

          return of(list);
        }),
        map((list) => {
          if (!this.leaseTermType) {
            return list;
          }

          return list
            .filter(item => (
              item.leaseTermType === this.leaseTermType
            ));
        }),
        tap((list) => {
          this.templates = list;

          this.isLoading = false;
        }),
        tap(() => {
          setTimeout(() =>
            this._ngZone.run(() =>
              this._dialogRefService.repaint()
            )
          );
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  handleTemplateListItemClick(template: models.ITermCustomValueTemplateViewModel): void {
    this.selectedTemplate = template;
  }

  handleTemplateListItemEditClick(template: models.ITermCustomValueTemplateViewModel): void {
    const dialogRef = this._dialogService
      .show(TermCustomValueTemplateUpsertDialogComponent, {
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 556,
        height: 'auto',
        maxHeight: '90%',
        injectableData: {
          template: template,
        },
      });

    dialogRef
      .onHiding
      .pipe(
        switchMap(() => {
          if (dialogRef.outputData?.template) {
            return this._termCustomValueTemplateManager
              .update(dialogRef.outputData.template)
              .pipe(
                switchMap(() => (
                  this._termCustomValueTemplateManager.getList()
                )),
              );
          }

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  handleTemplateListItemDeleteClick(template: models.ITermCustomValueTemplateViewModel): void {
    const dialogRef = this._dialogService
      .show(TermCustomValueTemplateDeleteDialogComponent, {
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 556,
        height: 'auto',
        maxHeight: '90%',
      });

    dialogRef
      .onHiding
      .pipe(
        switchMap(() => {
          if (dialogRef.outputData?.isConfirmed) {
            return this._termCustomValueTemplateManager
              .delete(template.id)
              .pipe(
                switchMap(() => (
                  this._termCustomValueTemplateManager.getList()
                )),
              );
          }

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  handleCancelClick(): void {
    this.close();
  }

  handleChooseClick(): void {
    this._dialogRefService.outputData = {
      selectedTemplate: this.selectedTemplate,
    };

    this.close();
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
