<div class="person-desktop">
  <p class="heading">
    <a [routerLink]="['../../building-desktop/' + tenant?.buildingId]" title="{{ tenant?.buildingName }}">
      <span class="icon-backward"></span>
      <span>{{ tenant?.buildingName }}</span>&nbsp;<span class="unit-name">/ {{ (lease$ | async)?.buildingUnit?.name }}</span>
    </a>
  </p>

  <div class="b-card">
    <div class="tenant-block">
      <div class="tenant-block-img">
        <app-team-members-avatars *ngIf="tenant"  [leaseId]="tenant?.leaseId" [teamMembers]="tenant?.tenantTeamMembers" [projects]="projects"></app-team-members-avatars>
        <h1 class="b-title">{{ tenant?.companyName }}</h1>
        <p><a *ngIf="tenant?.companyWebsite?.url" href="{{tenant?.companyWebsite?.url}}" target="_blank">{{ tenant?.companyWebsite?.text }}</a></p>
      </div>

      <div class="tenant-info">
        <h2 class="b-title">Information</h2>

        <div class="main-info">
          <div class="info-block">
            <svg viewBox="0 0 9.5 13">
              <path d="M4.8,2.5c-1.2,0-2.2,1-2.2,2.2C2.6,6,3.6,7,4.8,7S7,6,7,4.8C7,3.5,6,2.5,4.8,2.5z M4.8,3.3c0.8,0,1.4,0.6,1.4,1.4	c0,0.8-0.6,1.4-1.4,1.4S3.4,5.5,3.4,4.8C3.4,4,4,3.3,4.8,3.3L4.8,3.3z" />
              <path d="M4.8,1.1c-2,0-3.7,1.7-3.7,3.7c0,0.7,0.2,1.4,0.6,2l3.1,4.7l3.1-4.7c0.4-0.6,0.6-1.3,0.6-2C8.5,2.7,6.8,1.1,4.8,1.1z	 M4.8,13c-0.2,0-0.4-0.1-0.5-0.2L0.8,7.3C0.3,6.6,0,5.7,0,4.8C0,2.1,2.2,0,4.8,0s4.8,2.1,4.8,4.8c0,0.9-0.3,1.8-0.7,2.6c0,0,0,0,0,0	l-3.6,5.4C5.1,12.9,5,13,4.8,13L4.8,13z" />
            </svg>

            <div>
              <span>Corporate Address</span>
              <span>{{ tenant?.companyAddress }}</span>
            </div>
          </div>

          <div class="info-block">

            <span class="icon-settings green-icon"></span>

            <div>
              <span>Industry</span>
              <span>{{ tenant?.industryName }}</span>
            </div>
          </div>

          <div class="info-block">
            <span class="icon-buildings"></span>

            <div>
              <span>Other Markets</span>
              <span>{{ tenant?.otherMarkets }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(lease$ |async) as lease">
    <dx-tab-panel itemTitleTemplate="itemTitleTemplate"
                  [focusStateEnabled]="false" [items]="tabTitles" [(selectedIndex)]="selectedTabIndex">
      <div class="dx-item-content dx-tab-content"
           *dxTemplate="let tab of 'itemTitleTemplate'">
        <span class="dx-tab-text">
          {{tab.name}}
        </span>
      </div>
      <div *dxTemplate="let tab of 'item'"></div>
    </dx-tab-panel>

    <div class="b-tab-content documents"
         *ngIf="selectedTabIndex === 0">
      <app-attachments [files]="tenant?.documents" [mode]="documentAttachmentPlaces?.BuildingTenant" [entityId]="leaseId">
      </app-attachments>
    </div>

    <div class="b-tab-content project-schedule"
         *ngIf="selectedTabIndex === 1">
      <ng-container *ngIf="safeEmbeddedProjectScheduleURL">
        <iframe id="embedded-project-schedule-iframe"
                [src]="safeEmbeddedProjectScheduleURL"
                (load)="handleProjectScheduleIframeLoad($event.target)">
        </iframe>
      </ng-container>
      <div class="project-schedule-preloader"
           *ngIf="!isProjectScheduleLoaded">
        <span>
        </span>
      </div>
    </div>

    <div class="b-tab-content lease-abstract"
         *ngIf="selectedTabIndex === 2">
      <app-lease-term-dashboard [lease]="lease" [leaseTermConfigurations]="leaseTermConfigurations"
                                (afterViewInit)="handleLeaseTermDashboardViewInit($event)" [project]="project">
      </app-lease-term-dashboard>
    </div>
  </div>
</div>
