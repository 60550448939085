import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as ng from '@angular/core';
import * as moment from 'moment';

import { CommonTools } from '@statera/sdk/common';

import * as models from '../../../../../../infrastructure/models/generated';

import { LinkedItem, LinkedList } from '../../../../../../infrastructure/models/linked-item.model';
import { toLocalDate } from '../../../../../../infrastructure/models/local-date.model';

interface DropDownOption<T> {
  name: string;
  value: T;
  disabled?: boolean;
}

@Component({
  selector: 'app-tenant-square-footage-phase-in-table',
  templateUrl: 'tenant-square-footage-phase-in-table.component.html',
  styleUrls: ['tenant-square-footage-phase-in-table.component.scss'],
})
export class TenantSquareFootagePhaseInTableComponent implements ng.OnInit, ng.OnDestroy {
  private static readonly _daysInMonth = 365 / 12;

  private static readonly _repeatToEndOptions: Array<DropDownOption<models.TenantSquareFootagePhaseInRepeatType>> = [
    {
      name: 'No',
      value: models.TenantSquareFootagePhaseInRepeatType.No,
    },
    {
      name: 'End of Year',
      value: models.TenantSquareFootagePhaseInRepeatType.EndOfYear,
    },
    {
      name: 'End of Term',
      value: models.TenantSquareFootagePhaseInRepeatType.EndOfTerm,
    },
  ];

  @ViewChild(NgForm, { read: NgForm }) form: NgForm;

  @Input() lease: models.ILeaseViewModel;
  @Input() tenantSquareFootage: models.ITenantSquareFootageTermViewModel;
  @Output() tenantSquareFootageChange: EventEmitter<models.ITenantSquareFootageTermViewModel>;

  @Output() phaseInValueChange: EventEmitter<void>;

  phaseInValues: LinkedList<models.ITenantSquareFootagePhaseInValueViewModel>;
  phaseInResults: LinkedList<models.ITenantSquareFootagePhaseInResultViewModel>;

  repeatToEndOptions: Array<DropDownOption<models.TenantSquareFootagePhaseInRepeatType>>;

  tenantSquareFootagePhaseInRepeatType: typeof models.TenantSquareFootagePhaseInRepeatType;

  expirationLeaseMonth: number;

  private readonly _changeDetectorRef: ng.ChangeDetectorRef;

  constructor(changeDetectorRef: ng.ChangeDetectorRef) {
    this._changeDetectorRef = changeDetectorRef;

    this.tenantSquareFootageChange = new EventEmitter<models.ITenantSquareFootageTermViewModel>();
    this.phaseInValueChange = new EventEmitter<void>();
    this.phaseInValues = new LinkedList<models.ITenantSquareFootagePhaseInValueViewModel>();
    this.phaseInResults = new LinkedList<models.ITenantSquareFootagePhaseInResultViewModel>();
    this.repeatToEndOptions = TenantSquareFootagePhaseInTableComponent._repeatToEndOptions;
    this.tenantSquareFootagePhaseInRepeatType = models.TenantSquareFootagePhaseInRepeatType;
  }

  ngOnInit(): void {
    this.lease = this.lease || <models.ILeaseViewModel>{};
    this.tenantSquareFootage = this.tenantSquareFootage || <models.ITenantSquareFootageTermViewModel>{};

    if (this.lease && this.lease.commencementTerm && this.lease.term) {
      const commencementDate = moment(this.lease.commencementTerm.commencement).startOf('day');
      const expirationDate = commencementDate.clone().add(this.lease.term.termValue, 'months');

      this.expirationLeaseMonth = expirationDate.diff(commencementDate, 'months');
    }

    this.initializeTable();
  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  noop(): void {
    return;
  }

  trySavePhaseInValues(): boolean {
    if (!this.form || this.form.invalid) {
      return false;
    }

    if (!this.isPhaseInValuesValid()) {
      return false;
    }

    const tenantSquareFootageTerm = {
      ...this.tenantSquareFootage,
      tenantSquareFootagePhaseInValues: this.phaseInValues.toArray(),
      tenantSquareFootagePhaseInResults: this.phaseInResults.toArray(),
    };

    this.tenantSquareFootageChange.emit(tenantSquareFootageTerm);
    return true;
  }

  getTerm(): number {
    if (!this.hasTermValue()) {
      return 0;
    }

    return this.lease.term.termValue;
  }

  hasTermValue(): boolean {
    return this.lease && this.lease.term && !!this.lease.term.termValue;
  }

  getCurrentUnitSize(): number {
    if (!this.lease) {
      return 0;
    }

    if (this.lease.buildingUnit && this.lease.buildingUnit.size) {
      return this.lease.buildingUnit.size;
    }

    if (this.lease.building && this.lease.building.totalBuildingSize) {
      return this.lease.building.totalBuildingSize;
    }

    if (this.lease.tenantSquareFootageTerm) {
      return this.getTenantSquareFootageAbstractValue(this.lease.tenantSquareFootageTerm);
    }

    return this.tenantSquareFootage?.buildingUnitSize ?? 0;
  }

  getTenantSquareFootageAbstractValue(tenantSquareFootageTerm: models.ITenantSquareFootageTermViewModel): number {
    const abstract = tenantSquareFootageTerm.abstract;
    if (!abstract) {
      return this.tenantSquareFootage?.buildingUnitSize ?? 0;
    }

    switch (abstract.tenantSquareFootageTermType) {
      case models.TenantSquareFootageTermType.ExistingSquareFootage:
        return this.getTenantSquareFootageAbstractValue(abstract.abstract);

      case models.TenantSquareFootageTermType.Custom:
        return abstract.tenantSquareFootageCustomValue;

      case models.TenantSquareFootageTermType.PhaseIn:
        if (
          !abstract.tenantSquareFootagePhaseInResults ||
          0 === abstract.tenantSquareFootagePhaseInResults.length
        ) {
          return 0;
        }

        const count = abstract.tenantSquareFootagePhaseInResults.length;

        return abstract.tenantSquareFootagePhaseInResults[count - 1].totalSf;

      default:
        return 0;
    }
  }

  addPhaseInValue(): void {
    const phaseInItem = new LinkedItem<models.ITenantSquareFootagePhaseInValueViewModel>({
      leaseMonth: null,
      totalSfOccupied: null,
      repeatType: models.TenantSquareFootagePhaseInRepeatType.No,
      clientUtcOffset: ((new Date()).getTimezoneOffset() / 60) * -1,
    });

    this.phaseInValues.addItem(phaseInItem);

    let leaseMonth = this._calculatePhaseInLeaseMonth(phaseInItem);
    if (!leaseMonth) {
      return;
    }

    if (this.expirationLeaseMonth <= leaseMonth) {
      if (phaseInItem.previous && phaseInItem.previous.value) {
        phaseInItem.previous.value.repeatType = models.TenantSquareFootagePhaseInRepeatType.No;
      }

      phaseInItem.value.repeatType = models.TenantSquareFootagePhaseInRepeatType.EndOfTerm;

      leaseMonth = this.expirationLeaseMonth;
    }

    phaseInItem.value.leaseMonth = leaseMonth;

    this.calculatePhaseInResults();
  }

  removePhaseInValue(index: number = -1): void {
    if (index < 0) {
      this.phaseInValues = new LinkedList<models.ITenantSquareFootagePhaseInValueViewModel>();
      this.addPhaseInValue();
      return;
    }

    this.phaseInValues.remove(index);

    if (!this.phaseInValues.length) {
      this.addPhaseInValue();
    }

    this.calculatePhaseInResults();
  }

  calculatePhaseInValueProperties(index: number): void {
    if (
      !this.phaseInValues || !this.phaseInValues.length || !this.lease ||
      !this.lease.commencementTerm || !this.lease.commencementTerm.commencement ||
      !this.lease.term || !this.lease.term.termValue
    ) {
      return;
    }

    const phaseInItem = this.phaseInValues.getItem(index);
    if (!phaseInItem || !phaseInItem.value) {
      return;
    }

    // Calculate properties for the current point base on the previous point
    if (phaseInItem.previous && phaseInItem.previous.value) {
      let leaseMonth = this._calculatePhaseInLeaseMonth(phaseInItem);
      if (!leaseMonth) {
        return;
      }

      if (this.expirationLeaseMonth <= leaseMonth) {
        phaseInItem.previous.value.repeatType = models.TenantSquareFootagePhaseInRepeatType.No;
        phaseInItem.value.repeatType = models.TenantSquareFootagePhaseInRepeatType.EndOfTerm;

        leaseMonth = this.expirationLeaseMonth;
      }

      phaseInItem.value.leaseMonth = leaseMonth;
    }

    // Prevent calculation after the end of term phase-in point
    if (phaseInItem.value.repeatType === models.TenantSquareFootagePhaseInRepeatType.EndOfTerm) {
      return;
    }

    // Recalculate properties for each point after the current
    if (phaseInItem.next && phaseInItem.next.value) {
      this.calculatePhaseInValueProperties(index + 1);
    }
  }

  sortPhaseInValues(): void {
    this.phaseInValues.sort((x, y) => {
      if (!x.leaseMonth || !y.leaseMonth) {
        return 0;
      }

      return y.leaseMonth - x.leaseMonth;
    });
  }

  private _calculatePhaseInLeaseMonth(phaseInItem: LinkedItem<models.ITenantSquareFootagePhaseInValueViewModel>): number {
    if (
      !this.phaseInValues || !this.phaseInValues.length || !this.lease ||
      !this.lease.commencementTerm || !this.lease.commencementTerm.commencement ||
      !this.lease.term || !this.lease.term.termValue
    ) {
      return null;
    }

    const commencementDate = moment(this.lease.commencementTerm.commencement).startOf('day');
    const expirationDate = commencementDate.clone().add(this.lease.term.termValue, 'months');

    if (
      !phaseInItem.previous || !phaseInItem.previous.value ||
      !phaseInItem.previous.value.repeatType
    ) {
      return null;
    }

    let increaseDateSf = moment(this.getDate(phaseInItem.previous.value.leaseMonth)).startOf('day');
    let comparisonDate = commencementDate;

    switch (phaseInItem.previous.value.repeatType) {
      case models.TenantSquareFootagePhaseInRepeatType.No:
        increaseDateSf = null;
        break;

      case models.TenantSquareFootagePhaseInRepeatType.EndOfYear:
        increaseDateSf = moment(increaseDateSf).add(1, 'year').startOf('year');
        comparisonDate = commencementDate.clone().startOf('month');
        break;

      case models.TenantSquareFootagePhaseInRepeatType.EndOfTerm:
        increaseDateSf = moment(expirationDate);
        break;
    }

    if (!increaseDateSf || !increaseDateSf.isValid()) {
      return null;
    }

    const daysBetween = increaseDateSf.diff(comparisonDate, 'days');
    return CommonTools.round(daysBetween / TenantSquareFootagePhaseInTableComponent._daysInMonth) + 1;
  }

  calculatePhaseInResults(): void {
    const phaseInResults = new LinkedList<models.ITenantSquareFootagePhaseInResultViewModel>();
    if (
      !this.phaseInValues || !this.phaseInValues.length || !this.lease ||
      !this.lease.commencementTerm || !this.lease.commencementTerm.commencement ||
      !this.lease.term || !this.lease.term.termValue
    ) {
      return;
    }

    const commencementDate = moment(this.lease.commencementTerm.commencement).startOf('day');

    let phaseInItem = this.phaseInValues.firstItem();
    if (!phaseInItem) {
      return;
    }

    let isStopped = false;
    while (phaseInItem && !isStopped) {
      const startDate = this.getDate(phaseInItem.value.leaseMonth);

      let endDate: moment.Moment;
      switch (phaseInItem.value.repeatType) {
        case models.TenantSquareFootagePhaseInRepeatType.No: {
          if (phaseInItem.next && phaseInItem.next.value) {
            endDate = moment(this.getDate(phaseInItem.next.value.leaseMonth)).add(-1, 'day');
          }
          break;
        }

        case models.TenantSquareFootagePhaseInRepeatType.EndOfYear: {
          endDate = moment(startDate).endOf('year').endOf('month');
          break;
        }

        case models.TenantSquareFootagePhaseInRepeatType.EndOfTerm: {
          endDate = moment(commencementDate).add(this.lease.term.termValue, 'months').add(-1, 'day');
          isStopped = true;
          break;
        }
      }

      if (!endDate || !endDate.isValid()) {
        break;
      }

      phaseInResults.add({
        startDate: toLocalDate(moment(startDate).startOf('day')),
        endDate: toLocalDate(moment(endDate).startOf('day')),
        totalSf: phaseInItem.value.totalSfOccupied,
      });

      phaseInItem = phaseInItem.next;
    }

    this.phaseInResults = phaseInResults;

    if (!(<ng.ViewRef>this._changeDetectorRef).destroyed) {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  isDisabled(index: number): boolean {
    const phaseInItem = this.phaseInValues.getItem(index);
    if (0 <= index && phaseInItem.previous && phaseInItem.previous.value) {
      const isPreviousItemDisabled = this.isDisabled(index - 1);
      if (isPreviousItemDisabled) {
        return isPreviousItemDisabled;
      }

      return phaseInItem.previous.value.repeatType === models.TenantSquareFootagePhaseInRepeatType.EndOfTerm;
    }

    return false;
  }

  isAddButtonDisabled(): boolean {
    let phaseInItem = this.phaseInValues.lastItem();
    while (phaseInItem) {
      if (phaseInItem.value && phaseInItem.value.repeatType === models.TenantSquareFootagePhaseInRepeatType.EndOfTerm) {
        return true;
      }

      phaseInItem = phaseInItem.previous;
    }

    return false;
  }

  isPhaseInValuesValid(): boolean {
    return !this.getPhaseInValueErrorMessages().length;
  }

  getPhaseInValueErrorMessages(): Array<string> {
    const errors = [];

    if (
      !this.lease.commencementTerm || !this.lease.commencementTerm.commencement ||
      !this.lease.term || !this.lease.term.termValue
    ) {
      errors.push('Please fill Lease Commencement and Term');
      return errors;
    }

    if (!this.phaseInValues || !this.phaseInValues.length || !this.arePhaseInValuesValid()) {
      errors.push('Please fill the table');
      return errors;
    }

    if (!this.phaseInResults || !this.phaseInResults.length || !this.arePhaseInResultsValid()) {
      errors.push('Please check your SF Schedule, something is wrong');
      return errors;
    }

    const lastPhaseInResultItem = this.phaseInResults.lastItem();
    if (!lastPhaseInResultItem || !lastPhaseInResultItem.value) {
      errors.push('Please check your SF Schedule, something is wrong');
      return errors;
    }

    let phaseInValueItem = this.phaseInValues.firstItem();

    let previousPhaseInValueItemTotalSFOccupied = 0;

    while (phaseInValueItem !== null) {
      const phaseInValue = phaseInValueItem.value;
      if (phaseInValue && phaseInValue.totalSfOccupied < previousPhaseInValueItemTotalSFOccupied) {
        errors.push('Please check your SF Schedule, SF reduction is not allowed');
        return errors;
      }

      previousPhaseInValueItemTotalSFOccupied = phaseInValue.totalSfOccupied;
      phaseInValueItem = phaseInValueItem.next;
    }

    const commencementDate = moment(this.lease.commencementTerm.commencement).startOf('day');
    const expirationDate = commencementDate.clone().add(this.lease.term.termValue, 'months').add(-1, 'day');

    if (!moment(lastPhaseInResultItem.value.endDate).isSame(expirationDate)) {
      errors.push('Please complete the SF Schedule before the lease expiration date.');
    }

    const firstPhaseInResultItem = this.phaseInResults.firstItem();
    if (!moment(firstPhaseInResultItem.value.startDate).isSame(moment(this.lease.commencementTerm.commencement))) {
      errors.push('Please complete the SF Schedule from first to last month of the lease.');
    }

    return errors;
  }

  arePhaseInResultsValid(): boolean {
    if (!this.phaseInResults || !this.phaseInResults.length) {
      return true;
    }

    let phaseInResultNode = this.phaseInResults.firstItem();
    while (phaseInResultNode != null) {
      const phaseInResultValue = phaseInResultNode.value;

      const startDate = moment(phaseInResultValue.startDate);
      const endDate = moment(phaseInResultValue.endDate);

      if (endDate.isBefore(startDate)) {
        return false;
      }

      if (!phaseInResultValue.totalSf && phaseInResultValue.totalSf !== 0) {
        return false;
      }

      phaseInResultNode = phaseInResultNode.next;
    }

    return true;
  }

  arePhaseInValuesValid(): boolean {
    if (!this.phaseInValues || !this.phaseInValues.length) {
      return false;
    }

    let phaseInValueNode = this.phaseInValues.firstItem();
    while (phaseInValueNode != null) {
      const phaseInValueNodeValue = phaseInValueNode.value;
      if (!phaseInValueNodeValue.leaseMonth) {
        return false;
      }

      phaseInValueNode = phaseInValueNode.next;
    }

    return true;
  }

  initializeTable(): void {
    if (this.tenantSquareFootage && this.tenantSquareFootage.tenantSquareFootagePhaseInValues &&
      this.tenantSquareFootage.tenantSquareFootagePhaseInValues.length) {
      this.phaseInValues = new LinkedList<models.ITenantSquareFootagePhaseInValueViewModel>()
        .fromArray(this.tenantSquareFootage.tenantSquareFootagePhaseInValues);
    }

    if (!this.phaseInValues.length) {
      this.addPhaseInValue();
    }

    this.calculatePhaseInResults();
  }

  handleRepeatTypeInitialization(event: { component: any }): void {
    if (!event || !event.component) {
      return;
    }

    event.component.dropDownOptionsCache = {
      ...event.component.dropDownOptionsCache,
      resizeEnabled: true,
      minWidth: 140
    };
  }

  getDate(leaseMonth: number): Date {
    if (!this.lease || !this.lease.commencementTerm || !this.lease.commencementTerm.commencement || !leaseMonth) {
      return null;
    }

    return moment(this.lease.commencementTerm.commencement)
      .add(leaseMonth - 1, 'months')
      .toDate();
  }

  handlePhaseInValueChange(): void {
    this.phaseInValueChange.next();
  }
}
