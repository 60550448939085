import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { RefreshColabo, WebsocketRepository } from '@statera/sdk/websocket';
import { StorageRepository } from '@statera/sdk/storage';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { ColaboRepository } from './colabo.repository';

import * as models from './colabo.model';

@Injectable()
export class ColaboManager {
  private static readonly _lastSelectedColaboTreeItemStorageKey: string = 'statera-last-colabo-tree-item';

  private readonly _colaboRepository: ColaboRepository;
  private readonly _websocketRepository: WebsocketRepository;
  private readonly _storageRepository: StorageRepository;
  private readonly _loggerService: LoggerService;

  constructor(
    colaboRepository: ColaboRepository,
    websocketRepository: WebsocketRepository,
    storageRepository: StorageRepository,
    loggerService: LoggerService
  ) {
    this._colaboRepository = colaboRepository;
    this._websocketRepository = websocketRepository;
    this._storageRepository = storageRepository;
    this._loggerService = loggerService;
  }

  requestColaboTreeItems(): Observable<Array<models.ColaboTreeItem>> {
    return this._colaboRepository
      .getColaboTreeItems()
      .pipe(
        tap(chatTreeItems => {
          this._colaboRepository.setStoredColaboTreeItems(chatTreeItems);

          this._loggerService.debug(LoggerTopic.Colabo, `Colábo tree items received and stored`, chatTreeItems);
        }),
      );
  }

  getColaboTreeItems(): Observable<Array<models.ColaboTreeItem>> {
    return this._colaboRepository.getStoredColaboTreeItems();
  }

  setSelectedColaboTreeItem(colaboTreeItem: models.ColaboTreeItem): void {
    this._colaboRepository.setStoredSelectedColaboTreeItem(colaboTreeItem);

    this._loggerService.debug(LoggerTopic.Colabo, `Selected colábo tree item stored successfully`, colaboTreeItem);
  }

  getSelectedColaboTreeItem(): Observable<models.ColaboTreeItem> {
    return this._colaboRepository
      .getStoredSelectedColaboTreeItem()
      .pipe(
        tap(colaboTreeItem => {
          this._loggerService.debug(LoggerTopic.Colabo, `Last selected colábo tree item restored successfully`, colaboTreeItem);
        }),
      );
  }

  setLastSelectedColaboTreeItemId(leaseId: number): void {
    this._storageRepository.set<number>(ColaboManager._lastSelectedColaboTreeItemStorageKey, leaseId);

    this._loggerService.debug(LoggerTopic.Colabo, `Last selected colábo tree item id stored successfully`, leaseId);
  }

  hasLastSelectedColaboTreeItemId(): boolean {
    return this._storageRepository.has(ColaboManager._lastSelectedColaboTreeItemStorageKey);
  }

  getLastSelectedColaboTreeItemId(): number {
    const lastSelectedColaboTreeItemId = this._storageRepository.get<number>(ColaboManager._lastSelectedColaboTreeItemStorageKey);

    this._loggerService.debug(LoggerTopic.Colabo, `Last selected colábo tree item id restored successfully`, lastSelectedColaboTreeItemId);

    return lastSelectedColaboTreeItemId;
  }

  getRealtimeRefreshColaboMessage(): Observable<RefreshColabo> {
    return this._websocketRepository
      .getRealtimeRefreshColaboMessage()
      .pipe(
        map(response => response.model),
        filter(message => !!message),
      );
  }
}
