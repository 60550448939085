<section class="w-desktop"
         [ngClass]="{'collapsed' : isSidebarCollapsed}">
  <button class="w-toggle"
          (click)="isSidebarCollapsed = !isSidebarCollapsed">
    <span class="b-dropdown-icon"></span>
  </button>

  <aside class="w-aside">
    <div class="w-aside-container">
      <!--this is disabled SMVP-3865-->
      <button id="btnSettings" class="b-btn-icon" (click)="tooltipVisible = true" *ngIf="false">
        <span class="icon-settings green-icon"></span>
      </button>

      <div class="portfolio">
        <h2 class="b-title">My Portfolios</h2>
        <app-aside-dropdown *ngFor="let item of portfolios"
                            [portfolio]="item"
                            (collapsed)="onSidebarCollapsed($event, item)">
        </app-aside-dropdown>
      </div>

      <div class="search">
        <h2 class="b-title">Search</h2>

        <dx-text-box mode="search" placeholder="Search"
                     [(value)]="searchFilterText"
                     (onValueChanged)="onSearchFilterTextChanged($event)">
        </dx-text-box>

        <div class="search-filter">
          <h3>Filter by:</h3>

          <dx-accordion #accordion
                        [dataSource]="filterLinks" [collapsible]="true" [multiple]="true" [disabled]="filterDisabled">

            <div *dxTemplate="let filter of 'title'">
              <span class="{{filter.icon}}"></span>
              <span>{{filter.text}}</span>
            </div>

            <div *dxTemplate="let filter of 'item'">
              <!--Assets collapse-->
              <div *ngIf="filter.text === FilterType.Assets">
                <!--Assets collapse-->
                <div class="search-scroll">
                  <p class="search-heading">Portfolio</p>
                  <ng-container *ngFor="let portfolioCheckbox of portfolioCheckboxes">
                    <dx-check-box class="d-block"
                                  [(value)]="portfolioCheckbox.checked"
                                  text="{{portfolioCheckbox.portfolio.name}}"
                                  (onOptionChanged)="onPortfolioCheckValueChanged($event, portfolioCheckbox.portfolio)"
                    >
                    </dx-check-box>
                  </ng-container>
                  <p class="search-heading">Asset</p>

                  <ng-container *ngFor="let assetCheckbox of assetsCheckboxes;">
                    <div class="search-asset">
                      <img src="assets/img/landlord/dashboard/image-placeholder.svg"
                          height="32"
                          width="32"
                          alt=""
                          *ngIf="!assetCheckbox?.building?.pictures || !assetCheckbox.building.pictures[0]">
                      <img height="32"
                          width="32"
                          alt=""
                          *ngIf="assetCheckbox?.building?.pictures && assetCheckbox.building.pictures[0]"
                          [src]="buildingHelper.buildingPictureThumbnail(assetCheckbox.building)">

                      <dx-check-box class="checkbox-asset"
                                    [(value)]="assetCheckbox.checked"
                                    text="{{getAssetFilterName(assetCheckbox.building)}}"
                                    (onValueChanged)="onAssetValueChanged($event, assetCheckbox.building)"></dx-check-box>
                    </div>
                  </ng-container>
                </div>
                <p class="text-right"><a role="button" class="link-clear" (click)="onClearAssetsFilter($event)">Clear</a></p>
              </div>
              <!--Deals collapse-->
              <div *ngIf="filter.text === FilterType.Deals">
                <div class="search-scroll">
                  <p class="search-heading">Deal Type</p>

                  <ng-container *ngFor="let projectTypeCheckbox of projectTypeCheckboxes">
                    <dx-check-box class="d-block"
                                  [text]="projectTypeCheckbox.dealType" [(value)]="projectTypeCheckbox.checked"
                                  (onValueChanged)="onProjectTypeCheckboxChecked($event, projectTypeCheckbox)">
                    </dx-check-box>
                  </ng-container>

                  <ng-container *ngIf="dealStageGroups && dealStageGroups.length">
                    <p class="search-heading">Stage</p>

                    <div *ngFor="let stageGroup of dealStageGroups">
                      <p class="search-group-heading"
                         *ngIf="stageGroup.groupName">
                        {{stageGroup.groupName}}
                      </p>
                      <ng-container *ngFor="let checkbox of stageGroup.checkboxes">
                        <dx-check-box class="d-block"
                                      [text]="checkbox.projectTemplateItem.name"
                                      [value]="checkbox.checked"
                                      (onValueChanged)="onProjectTemplateItemCheckboxValueChanged($event, checkbox.projectTemplateItem)"
                                      (onContentReady)="handleStageCheckboxContentReady($event, checkbox)">
                        </dx-check-box>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
                <p class="text-right"><a role="button" class="link-clear" (click)="onClearDealsFilter($event)">Clear</a></p>
              </div>
              <!--Inquiries collapse-->
              <div *ngIf="filter.text === FilterType.Inquiries">
                <p class="search-heading">Coming soon</p>

                <!--                    <p class="search-heading">Inquiry Date</p>-->
                <!--                    <dx-check-box class="checkbox-lease" text="Last 7 Days"></dx-check-box>-->
                <!--                    <dx-check-box class="checkbox-lease" text="Last 14 Days"></dx-check-box>-->
                <!--                    <dx-check-box class="checkbox-lease" text="Last 30 Days"></dx-check-box>-->
                <!--                    <dx-check-box class="checkbox-lease" text="Month to Date"></dx-check-box>-->
                <!--                    <div class="search-date">-->
                <!--                      <dx-date-box text="From" type="date"></dx-date-box>-->
                <!--                      <dx-date-box text="To" type="date"></dx-date-box>-->
                <!--                    </div>-->
                <!--                    <p class="text-right"><a role="button" class="link-clear">Clear</a></p>-->
              </div>

              <!--Leases collapse-->
              <div *ngIf="filter.text === FilterType.Leases">
                <p class="search-heading">Lease Expiration</p>
                <ng-container *ngFor="let leaseExpirationCheckbox of leaseExpirationCheckboxes">
                  <dx-check-box class="checkbox-lease"
                                [(value)]="leaseExpirationCheckbox.checked"
                                text="{{leaseExpirationCheckbox.leaseExpirationFilterEnum}}"
                                (onValueChanged)="onLeaseExpirationCheckboxValueChanged($event, leaseExpirationCheckbox.leaseExpirationFilterEnum)"
                  >

                  </dx-check-box>
                </ng-container>
                <p class="text-right"><a role="button" class="link-clear" (click)="onClearLeaseExpirationDates($event)">Clear</a></p>
              </div>
              <!--Requests collapse-->
              <div *ngIf="filter.text === FilterType.Requests">
                <p class="search-heading">Coming soon</p>
                <!--                    <p class="search-heading">Type</p>-->
                <!--                    <dx-check-box text="Restructure"></dx-check-box>-->
                <!--                    <dx-check-box text="Renewal"></dx-check-box>-->
                <!--                    <p class="text-right"><a role="button" class="link-clear">Clear</a></p>-->
              </div>

              <!--Funds collapse-->
              <div *ngIf="filter.text === FilterType.Funds">
                <!--Funds collapse-->
                <div class="search-scroll">
                  <p class="search-heading">Funds</p>
                  <ng-container *ngFor="let fundsCheckbox of fundsCheckboxes">
                    <dx-check-box class="d-block"
                                  [(value)]="fundsCheckbox.checked"
                                  text="{{fundsCheckbox.funds}}"
                                  (onValueChanged)="onFundsFilterCheckboxChanged($event)"
                    >
                    </dx-check-box>
                  </ng-container>
                </div>
                <p class="text-right"><a role="button" class="link-clear" (click)="onClearFundsFilter($event)">Clear</a></p>
              </div>
            </div>

          </dx-accordion>

          <ng-container *ngIf="false">
            <!--Assets collapse-->
            <div class="search-scroll d-none">
              <p class="search-heading">Portfolio</p>
              <dx-check-box class="d-block" text="Big Box I"></dx-check-box>
              <dx-check-box class="d-block" text="Big Box II"></dx-check-box>
              <p class="search-heading">Asset</p>
              <div class="search-asset">
                <img src="assets/img/landlord/dashboard/image-placeholder.svg" height="32" width="32">
                <dx-check-box class="checkbox-asset" text="Federer Gate"></dx-check-box>
              </div>
              <div class="search-asset">
                <img src="assets/img/landlord/dashboard/image-placeholder.svg" height="32" width="32">
                <dx-check-box class="checkbox-asset" text="CBOT1"></dx-check-box>
              </div>
              <div class="search-asset">
                <img src="assets/img/landlord/dashboard/image-placeholder.svg" height="32" width="32">
                <dx-check-box class="checkbox-asset" text="Fullerton Campus 1"></dx-check-box>
              </div>
              <div class="search-asset">
                <img src="assets/img/landlord/dashboard/image-placeholder.svg" height="32" width="32">
                <dx-check-box class="checkbox-asset" text="800 W. North Ave"></dx-check-box>
              </div>
              <div class="search-asset">
                <img src="assets/img/landlord/dashboard/image-placeholder.svg" height="32" width="32">
                <dx-check-box class="checkbox-asset" text="950 N. Raddant"></dx-check-box>
              </div>
            </div>
            <p class="text-right"><a role="button" class="link-clear">Clear</a></p>
            <!--Deals collapse-->
            <div class="search-scroll d-none">
              <p class="search-heading">Stage</p>
              <dx-check-box class="d-block" text="Establish Criteria"></dx-check-box>
              <dx-check-box class="d-block" text="Send RFP"></dx-check-box>
              <dx-check-box class="d-block" text="Analyze Response"></dx-check-box>
              <dx-check-box class="d-block" text="Negotiate T.I."></dx-check-box>
              <dx-check-box class="d-block" text="Proposal Negotiation"></dx-check-box>
              <dx-check-box class="d-block" text="Letter Of Intent"></dx-check-box>
              <dx-check-box class="d-block" text="Finalize Terms"></dx-check-box>
              <dx-check-box class="d-block" text="Lease Documents"></dx-check-box>
              <dx-check-box class="d-block" text="Lease Execution"></dx-check-box>
              <p class="search-heading">Deal Type</p>
              <dx-check-box class="d-block" text="Restructure"></dx-check-box>
              <dx-check-box class="d-block" text="Renewal"></dx-check-box>
              <dx-check-box class="d-block" text="New Deal"></dx-check-box>
            </div>
            <p class="text-right"><a role="button" class="link-clear">Clear</a></p>
            <!--Inquiries collapse-->
            <p class="search-heading">Inquiry Date</p>
            <dx-check-box class="checkbox-lease" text="Last 7 Days"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Last 14 Days"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Last 30 Days"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Month to Date"></dx-check-box>
            <div class="search-date">
              <dx-date-box text="From" type="date"></dx-date-box>
              <dx-date-box text="To" type="date"></dx-date-box>
            </div>
            <p class="text-right"><a role="button" class="link-clear">Clear</a></p>
            <!--Leases collapse-->
            <p class="search-heading">Lease Expiration</p>
            <dx-check-box class="checkbox-lease" text="In 3+ years"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Within 2-3 years"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Within 1-2 years"></dx-check-box>
            <dx-check-box class="checkbox-lease" text="Less than 1 year"></dx-check-box>
            <p class="text-right"><a role="button" class="link-clear">Clear</a></p>
            <!--Requests collapse-->
            <p class="search-heading">Type</p>
            <dx-check-box text="Restructure"></dx-check-box>
            <dx-check-box text="Renewal"></dx-check-box>
            <p class="text-right"><a role="button" class="link-clear">Clear</a></p>
          </ng-container>
        </div>
      </div>
    </div>
  </aside>

  <div class="w-content">
    <router-outlet></router-outlet>
  </div>
</section>

<dx-tooltip target="#btnSettings" position="right" [width]="150" [showEvent]="'click'" [(visible)]="tooltipVisible">
  <div class="d-flex flex-column tooltip-menu"
       *dxTemplate="let data = data; of: 'content'">
    <a class="nav-link text-uppercase text-13 header-text" (click)="tooltipVisible = false"
       *ngFor="let item of tooltipMenuItems" [routerLink]="[item.path]">
      {{ item.text }}
    </a>
  </div>
</dx-tooltip>
