import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {AlertMessagesManager} from '@statera/sdk/alert';
import {Role} from '@statera/sdk/auth';
import {LeaseManager} from '@statera/sdk/lease';
import {ProjectManager} from '@statera/sdk/project';
import {TermManager} from '@statera/sdk/term';

import {AlertService} from '../../../../../alert/services/alert.service';
import {AuthService} from '../../../../../auth/services/auth.service';
import {LeaseService} from '../../../../../shared/services/lease.service';
import {ProjectService} from '../../../../../shared/services/project.service';
import {ProjectAccessService} from '../../../../../shared/services/project-access.service';
import {TermsPageService} from '../../../../services/terms-page.service';

import {BaseTermDirective} from '../base-term.directive';

import * as models from '../../../../../infrastructure/models/generated';
import {LeaseTermEventModel} from '../lease-term-event-model';
import {FloorPlanManager} from '@statera/sdk/floor-plan';
import { Feature } from '@statera/sdk/feature-toggling';
import {DxPopoverComponent} from 'devextreme-angular';

@Component({
  selector: 'app-tenant-improvements',
  templateUrl: './tenant-improvements.component.html',
  styleUrls: ['./tenant-improvements.component.scss'],
})
export class TenantImprovementsComponent extends BaseTermDirective<models.ITenantImprovementsViewModel> implements OnInit {
  @Input() lease: models.ILeaseViewModel;
  @Input() project: models.IProjectViewModel;
  @Input() rejectButtonClicked: boolean;
  @Input() leaseTerm: models.ITenantImprovementsViewModel;
  @Input() floorPlans: Array<models.ILeaseFloorPlanViewModel>;

  @Output() leaseTermSaved = new EventEmitter<LeaseTermEventModel>();
  @Output() stageChanged = new EventEmitter<string>();
  @Output() leaseTermCanceled = new EventEmitter();
  @Output() refreshColabo = new EventEmitter();


  termValue: any;
  TermType = models.TermType;
  TenantImprovementsType = models.TenantImprovementsType;
  startupInfo: models.IStartupInfoViewModel;
  isFirstOffer = false;
  Role: typeof Role = Role;
  TenantImprovementsAmortizationOption = models.TenantImprovementsAmortizationOption;
  ContractorTypeForTenantImprovementsWork = models.ContractorTypeForTenantImprovementsWork;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  LeaseFloorPlanRequestStatus = models.LeaseFloorPlanRequestStatus;
  Feature: typeof Feature = Feature;

  options: Array<any> = [
    {
      name: 'As-Is, no Tenant Improvements allowance provided',
      value: models.TenantImprovementsType.AsIs,
      disabled: false,
    },
    {
      name: (
        'Landlord shall, at its sole cost and expense, provide Turnkey construction per mutually agreed upon ' +
        'items and plan'
      ),
      value: models.TenantImprovementsType.TurnKey,
      disabled: false,
    },
    {
      name: (
        'Landlord shall provide a Tenant Improvement allowance not to exceed (INSERT $ VALUE) to be used for all ' +
        'costs to design, construct and permit improvements'
      ),
      value: models.TenantImprovementsType.DollarAllowance,
      disabled: false,
    },
    {
      name: (
        'Tenant shall have the right to amortize any mutually agreed upon Tenant Improvements costs up to ' +
        '(INSERT $ VALUE) in total costs over the term of lease or (INSERT TOTAL MONTHS FOR LEASE TERM) months at ' +
        'annual interest rate of (INSERT % VALUE)'
      ),
      value: models.TenantImprovementsType.CustomAmortized,
      disabled: false,
    },
    {
      name: 'Custom',
      value: models.TenantImprovementsType.Custom,
      disabled: false,
    },
  ];

  tenantImprovementsAmortizationOptions: any = [
    {
      name: 'Amortization Option',
      value: models.TenantImprovementsAmortizationOption.AmortizationOption,
    },
    {
      name: 'No Amortization Available',
      value: models.TenantImprovementsAmortizationOption.NoAmortizationAvailable,
    },
  ];

  contractorTypesForTenantImprovementsWork: any = [
    {
      name: 'Use Landlord\'s Contractors',
      value: models.ContractorTypeForTenantImprovementsWork.UseLandlordsContractors,
    },
    {
      name: 'Tenant to have ability to choose their own Contractor to perform work with Landlord\'s approval',
      value: models.ContractorTypeForTenantImprovementsWork.TenantCanChooseTheirOwnContractors,
    },
  ];

  turnkeyTenantImprovements: Array<string> = [];

  protected alertService: AlertService;
  protected alertMessagesManager: AlertMessagesManager;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  protected leaseManager: LeaseManager;

  private readonly _authService: AuthService;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _floorPlanManager: FloorPlanManager;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
    floorPlanManager: FloorPlanManager
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this.alertMessagesManager = alertMessagesManager;
    this._projectAccessService = projectAccessService;
    this._floorPlanManager = floorPlanManager;
  }

  ngOnInit() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ITenantImprovementsViewModel>{};
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.tenantImprovementsOptionDescription &&
      this.leaseTerm.tenantImprovementsOptionDescription.length
    ) {
      this.turnkeyTenantImprovements = this.leaseTerm.tenantImprovementsOptionDescription.split(/;\s*/);
    }

    this._authService.infoLoadComplete.subscribe(x => this.startupInfo = x);

    if (this.project && this.project.projectState) {
      const hasAccessToSendRfp = this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.SendRfp,
        this.project,
        this.lease
      );

      const hasAccessToUnsolicitedOffer = this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
        this.project,
        this.lease
      );

      if (hasAccessToSendRfp || hasAccessToUnsolicitedOffer) {
        this.isFirstOffer = true;
      }

      const hasAccessToProposalNegotiation = this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LandlordCounterOffer,
        this.project,
        this.lease
      ) || this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      );

      if (hasAccessToProposalNegotiation) {
        if (this.leaseTerm && this.leaseTerm.termStatus === models.TermStatus.Pending) {
          let companyName = this.lease.landlordCompany.name;
          if (this.startupInfo.role === this.Role.Landlord) {
            companyName = this.lease.tenantCompany.name;
          }


          this.alertService.pushInfoAlert({
            title: 'Notification',
            message: this.alertMessagesManager.getTenantImprovementsModifiedAlertText(companyName),
          });
        }
      }
    }

    if (
      this.lease?.term.termStatus !== models.TermStatus.Draft &&
      this.lease?.term.termType === models.TermTypeEnum.Custom &&
      this.lease?.term?.termValue &&
      this.leaseTerm?.termStatus === models.TermStatus.Draft &&
      !this.leaseTerm?.tenantImprovementsAmortizationTerm &&
      !this.leaseTerm?.tenantImprovementsAmortizedMonthsCount
    ) {
      this.leaseTerm.tenantImprovementsAmortizationTerm = this.lease.term.termValue;
      this.leaseTerm.tenantImprovementsAmortizedMonthsCount = this.lease.term.termValue;
    }
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      this.leaseTerm.termStatus === models.TermStatus.Ready &&
      (
        this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      )
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease &&
      this.lease.tenantImprovementsTerm &&
      this.lease.tenantImprovementsTerm.tenantImprovementsType === models.TenantImprovementsType.FloorPlanRequested
    ) {
      return false;
    }

    if (
      this.project.projectState.renewalProjectTemplateItemType >= models.RenewalProjectTemplateItemType.LetterOfIntent
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.FloorPlanRequested
    ) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isVisibleTermForm(): boolean {
    if (!this.project || !this.project.projectState || !this.leaseTerm) {
      return false;
    }

    return (
      this.termsPageService.isVisibleEditingTerm(this.project, this.lease, models.LeaseTermType.TenantImprovements) &&
      (
        this.rejectButtonClicked ||
        this.leaseTerm.termStatus === models.TermStatus.Draft ||
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
          this.project,
          this.lease,
        )
      )
    );
  }

  isFloorPlanBypassedByBothSidesAndRejectButtonClicked(): boolean {
    if (!this.project || !this.project.projectState) {
      return false;
    }

    return (
      this.rejectButtonClicked &&
      this.termsPageService.isVisibleEditingTerm(this.project, this.lease, models.LeaseTermType.TenantImprovements) &&
      this.lease.leaseFloorPlanRequestStatus !== models.LeaseFloorPlanRequestStatus.RequestedByLandlord
    );
  }

  handleAcceptButtonClick(): void {
    this.leaseTerm.termStatus = models.TermStatus.Accepted;
    const leaseTermEventModel = new LeaseTermEventModel(this.leaseTerm);

    this.leaseTermSaved.emit(leaseTermEventModel);
    this.acceptClicked.emit();
  }

  handleCounterButtonClick(): void {
    if (this.leaseTerm.termStatus === models.TermStatus.Accepted) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmChangeAcceptedTenantImprovementsTermAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => this.rejectTerm()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    this.rejectTerm();
  }

  rejectTerm(): void {
    if (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LandlordCounterOffer,
        this.project,
        this.lease
      ) || this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      ) &&
      this.leaseTerm.termStatus === models.TermStatus.Accepted
    ) {
      this.rejectTenantImprovementsOnProposalNegotiation();
      return;
    }

    this.isRejectFormVisible = true;
    this.isRejectFormVisibleChange.emit(this.isRejectFormVisible);

    this.rejectClicked.emit();
  }

  rejectTenantImprovementsOnProposalNegotiation() {
    const alertReference = this.alertService.pushConfirmAlert({
      message: this.alertMessagesManager.getConfirmChangeTenantImprovementsAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.isRejectFormVisible = true;
          this.isRejectFormVisibleChange.emit(this.isRejectFormVisible);

          this.rejectClicked.emit();
        }),
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe();
  }

  onClickSaveTerm($event): void {
    this.saveTenantImprovementsTerm();
    $event.preventDefault();
  }

  isShowCancelButton(): boolean {
    return true;
  }

  isVisibleChoosingContractorForTenantImprovementsWork(): boolean {
    return this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.DollarAllowance ||
      this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.CustomAmortized;
  }

  isShowTenantImprovementsOptionDescription(): boolean {
    if (this.leaseTerm.tenantImprovementsType !== this.TenantImprovementsType.TurnKey &&
      this.leaseTerm.tenantImprovementsType !== this.TenantImprovementsType.CustomAmortized &&
      this.leaseTerm.tenantImprovementsType !== this.TenantImprovementsType.DollarAllowance) {
      return false;
    }

    return !this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
      this.project,
      this.lease
    );
  }

  isShowTenantImprovementsAmortizationTermWarning(): boolean {
    if (
      this.lease?.term?.termStatus === models.TermStatus.Draft &&
      (this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.CustomAmortized ||
        (this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.DollarAllowance
          && this.leaseTerm.tenantImprovementsAmortizationOption === models.TenantImprovementsAmortizationOption.AmortizationOption))
    ) {
      return true;
    }

    let tiAmortizationTermValue;
    const tiTerm = this.leaseTerm;
    if (tiTerm.tenantImprovementsType === models.TenantImprovementsType.DollarAllowance) {
      if (tiTerm.tenantImprovementsAmortizationOption !== models.TenantImprovementsAmortizationOption.AmortizationOption) {
        return false;
      }

      tiAmortizationTermValue = tiTerm.tenantImprovementsAmortizationTerm;
    } else if (tiTerm.tenantImprovementsType === models.TenantImprovementsType.CustomAmortized) {
      tiAmortizationTermValue = tiTerm.tenantImprovementsAmortizedMonthsCount;
    } else {
      return false;
    }

    const termTerm = this.lease.term;
    let leaseTermValue;
    if (termTerm.termType === models.TermTypeEnum.Custom) {
      leaseTermValue = termTerm.termValue;
    } else if (termTerm.termType === models.TermTypeEnum.MultiplyOptions) {
      if (termTerm.leaseTermOptions.selectedLeaseTerm) {
        leaseTermValue = termTerm.leaseTermOptions.selectedLeaseTerm.termValue;
      } else {
        const values = termTerm.leaseTermOptions.options.map((x) => {
          // Right now, options are always of type Custom, but this may be changed later.
          if (x.termType === models.TermTypeEnum.Custom) {
            return x.termValue;
          }
          return 0;
        });

        leaseTermValue = Math.min.apply(Math, values);
      }
    }

    // Hide warning if we're dealing with a default value somewhere.
    if (!leaseTermValue || !tiAmortizationTermValue) {
      return false;
    }

    return leaseTermValue < tiAmortizationTermValue;
  }

  onTurnkeyTenantImprovementChanged(event): void {
    if (!event || !event.value || !this.leaseTerm) {
      return;
    }

    this.leaseTerm.tenantImprovementsOptionDescription = event.value
      .map(x => x.trim())
      .join(';');
  }

  handleTenantImprovementsTypeChange(): void {
    this._showNotificationForRestructureProcess();
  }

  handlePreviousButtonClick() {
    this.turnkeyTenantImprovements = [];
    super.handlePreviousButtonClick();
  }

  private _showNotificationForRestructureProcess() {
    if (!this.leaseTerm || typeof this.leaseTerm.tenantImprovementsType !== 'number') {
      return;
    }

    const {Restructure_EstablishCriteriaByTenant} = models.RenewalProjectTemplateItemType;

    if (
      !this.project ||
      !this.project.projectTypeId ||
      this.project.projectTypeId !== models.ProjectTypeEnum.Restructure ||
      !this._projectAccessService.checkAccessToRenewalProject(Restructure_EstablishCriteriaByTenant, this.project, this.lease) ||
      this.leaseTerm.tenantImprovementsType === models.TenantImprovementsType.AsIs
    ) {
      return;
    }

    this.alertService.pushConfirmAlert({
      message: this.alertMessagesManager.getConfirmRequestTenantImprovementsAlertText(),
      confirmButtonText: 'OK',
      shouldShowDeclineButton: false,
    });
  }

  saveTenantImprovementsTerm() {
    let termStatus: models.TermStatus = models.TermStatus.Draft;
    if (this.isFirstOffer || (this.leaseTerm && this.leaseTerm.termStatus === models.TermStatus.Draft)) {
      termStatus = models.TermStatus.Ready;
    } else {
      termStatus = models.TermStatus.Rejected;
    }

    this.saveLeaseTerm(this.leaseTerm, termStatus);
  }

  showRequestFloorPlanButton() {
    return this._floorPlanManager.showRequestFloorPlanButton(this._authService.userId, this._authService.role,
      this.lease, this.floorPlans, models.FloorPlanType.LeaseFloorPlan);
  }

  showCreateFloorPlanButton() {
    return this._floorPlanManager
      .showCreateFloorPlanButton(this._authService.userId, this._authService.role,
        this.lease, this.floorPlans, models.FloorPlanType.LeaseFloorPlan);
  }

  getRequestFloorPlanButtonText() {
    if (this.lease?.leaseFloorPlanRequestStatus !== models.LeaseFloorPlanRequestStatus.RequestedByLandlord) {
      return 'Request Floor Plan'.toUpperCase();
    }
    return 'Cancel Floor Plan Request'.toUpperCase();
  }

  requestFloorPlanButtonClick() {
    if (this.lease?.leaseFloorPlanRequestStatus === models.LeaseFloorPlanRequestStatus.RequestedByLandlord) {
      const alertReference = this.alertService.pushConfirmAlert({
        message: this.alertMessagesManager.getCancelRequestFloorPlanFromTenantAlertText()
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => this.leaseService
            .setFloorPlanRequested(this.lease.id, false)
            .pipe(
              tap(() => {
                this.lease.leaseFloorPlanRequestStatus = models.LeaseFloorPlanRequestStatus.None;
              }),
              takeUntil(this.destroy),
            ),
          ),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();
    } else {
      const alertReference = this.alertService.pushConfirmAlert({
        message: this.alertMessagesManager.getConfirmRequestFloorPlanFromTenantAlertText(),
        confirmButtonText: 'REQUEST FLOOR PLAN',
        declineButtonText: 'GO BACK',
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => this.leaseService
            .setFloorPlanRequested(this.lease.id, true)
            .pipe(
              tap(() => {
                this.lease.leaseFloorPlanRequestStatus = models.LeaseFloorPlanRequestStatus.RequestedByLandlord;
              }),
              takeUntil(this.destroy),
            ),
          ),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();
    }
  }

  createFloorPlanButtonClick() {
    this._floorPlanManager
      .createLeaseFloorPlan(this.lease)
      .pipe(
        tap(() => this.refreshColabo.emit()),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  showPopover(popoverComponent: DxPopoverComponent): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    const popoverInstance = popoverComponent.instance;
    popoverInstance.option('closeOnTargetScroll', () => {
      popoverInstance.repaint();
      return true;
    });

    popoverComponent.visible = true;
  }


  hidePopover(popoverComponent: DxPopoverComponent): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    popoverComponent.visible = false;
  }



  getTotalAnchorAmount(): number {
    const anchors = this.getAnchors();
    return this._floorPlanManager.getTotalAmountCosts(anchors);
  }

  getAnchors(): Array<models.IPlanAnchorViewModel> {
    if (!this.floorPlans) {
      return [];
    }
    return this.floorPlans
      .map(x => x.anchors)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(x => x.status !== models.PlanAnchorStatus.Rejected);
  }
}
