import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { VideoPlayerState } from '../../models/video-player.model';

@Component({
  selector: 'app-video-player-html5',
  templateUrl: 'video-player-html5.component.html',
  styleUrls: ['./video-player-html5.component.scss'],
})
export class VideoPlayerHTML5Component implements OnInit, AfterViewInit, OnDestroy {
  private static readonly _supportedExtensions: Array<string> = ['mp4', 'ogg', 'webm'];

  @ViewChild('videoElementRef', {read: ElementRef, static: true}) videoElementRef: ElementRef<HTMLVideoElement>;
  @ViewChild('morePopover') morePopover: PopoverDirective;

  @Input() source: string;
  @Input() sourceType: string;
  @Input() muted: boolean;
  @Input() controls: boolean;

  @Input() playObserver: Subject<void>;
  @Input() pauseObserver: Subject<void>;

  @Output() loaded: EventEmitter<void>;
  @Output() playing: EventEmitter<void>;
  @Output() played: EventEmitter<void>;
  @Output() paused: EventEmitter<void>;
  @Output() seeked: EventEmitter<number>;

  isControlsVisible: boolean;
  isMetadataLoading: boolean;
  isFullscreenMode: boolean;

  playerState: VideoPlayerState;

  currentTime: number;
  duration: number;
  volume: number;
  playbackRate: number;

  morePopoverMenuIndex: number;
  maxMorePopoverContentHeight: number; // 🙄

  readonly VideoPlayerState: typeof VideoPlayerState;

  private readonly _loadedMetadataHandler: (event: Event & {target: HTMLVideoElement}) => void;
  private readonly _playHandler: (event: Event & {target: HTMLVideoElement}) => void;
  private readonly _endedHandler: (event: Event & {target: HTMLVideoElement}) => void;
  private readonly _pauseHandler: (event: Event & {target: HTMLVideoElement}) => void;
  private readonly _timeUpdateHandler: (event: Event & {target: HTMLVideoElement}) => void;

  private readonly _mouseEnter: Subject<boolean>;
  private readonly _mouseMove: Subject<boolean>;
  private readonly _mouseLeave: Subject<boolean>;

  private readonly _destroy: Subject<void>;

  static canPlaySource(source: string): boolean {
    const sourceExtension = source?.split('.')?.pop();
    if (!sourceExtension) {
      return;
    }

    return VideoPlayerHTML5Component._supportedExtensions.includes(sourceExtension);
  }

  constructor() {
    this.loaded = new EventEmitter<void>();
    this.playing = new EventEmitter<void>();
    this.played = new EventEmitter<void>();
    this.paused = new EventEmitter<void>();
    this.seeked = new EventEmitter<number>();

    this.VideoPlayerState = VideoPlayerState;

    this._loadedMetadataHandler = event => this._handleLoadedMetadata(event);
    this._playHandler = event => this._handlePlay(event);
    this._endedHandler = event => this._handleEnded(event);
    this._pauseHandler = event => this._handlePause(event);
    this._timeUpdateHandler = event => this._handleTimeUpdate(event);

    this._mouseEnter = new Subject<boolean>();
    this._mouseMove = new Subject<boolean>();
    this._mouseLeave = new Subject<boolean>();

    this._destroy = new Subject<void>();

    this.handleProgressRangePositionChange = this.handleProgressRangePositionChange.bind(this);
    this.handleVolumeRangePositionChange = this.handleVolumeRangePositionChange.bind(this);
  }

  ngOnInit(): void {
    this.sourceType ??= this._computeSourceType();
    this.controls ??= true;

    this.isControlsVisible = true;
    this.isMetadataLoading = true;

    this.playerState = VideoPlayerState.Paused;

    this.currentTime = 0;
    this.duration = 0;
    this.volume = 0.5;
    this.playbackRate = 1;

    this.morePopoverMenuIndex = -1;

    this.playObserver
      .pipe(
        tap(() => this.play()),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.pauseObserver
      .pipe(
        tap(() => this.pause()),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.playing
      .pipe(
        tap(() => this.playerState = VideoPlayerState.Playing),
        debounceTime(2400),
        tap(() => {
          if (this.playerState === VideoPlayerState.Playing) {
            this.isControlsVisible = false;
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.paused
      .pipe(
        tap(() => this.playerState = VideoPlayerState.Paused),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.played
      .pipe(
        tap(() => this.playerState = VideoPlayerState.Played),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.seeked
      .pipe(
        debounceTime(250),
        tap((value) => this.seek(value)),
        takeUntil(this._destroy),
      )
      .subscribe();

    merge(
      this._mouseEnter,
      this._mouseMove,
      this._mouseLeave,
    )
      .pipe(
        debounceTime(10),
        tap((isMouseInside) => {
          if (this.playerState === VideoPlayerState.Playing) {
            if (!isMouseInside) {
              this.isControlsVisible = false;
              return;
            }

            this.isControlsVisible = true;
          }
        }),
        debounceTime(2400),
        tap(() => {
          if (this.playerState === VideoPlayerState.Playing) {
            this.isControlsVisible = false;
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const player = this.videoElementRef?.nativeElement;
    if (player) {
      player.addEventListener('play', this._playHandler);
      player.addEventListener('ended', this._endedHandler);
      player.addEventListener('pause', this._pauseHandler);
      player.addEventListener('timeupdate', this._timeUpdateHandler);
      player.addEventListener('loadedmetadata', this._loadedMetadataHandler);
    }

    this.morePopover
      .onShown
      .pipe(
        tap((a) => {
          const videoRect = this.videoElementRef.nativeElement.getBoundingClientRect();
          this.maxMorePopoverContentHeight = videoRect.height - 51 - 12; // - popover bottom margin - popover top margin
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    const player = this.videoElementRef?.nativeElement;
    if (player) {
      player.removeEventListener('play', this._playHandler);
      player.removeEventListener('ended', this._endedHandler);
      player.removeEventListener('pause', this._pauseHandler);
      player.removeEventListener('timeupdate', this._timeUpdateHandler);
      player.removeEventListener('loadedmetadata', this._loadedMetadataHandler);
    }

    this._destroy.next();
    this._destroy.complete();
  }

  getCurrentTime(): string {
    return this._getTimeExpression(this.currentTime);
  }

  getDuration(): string {
    return this._getTimeExpression(this.duration);
  }

  getProgressRangePosition(): number {
    if (!this.duration) {
      return 0;
    }

    return this.currentTime / this.duration * 100;
  }

  @HostListener('click')
  handleMouseClick(): void {
    this.closeMorePopover();

    switch (this.playerState) {
      case VideoPlayerState.Paused:
        this.play();
        break;
      case VideoPlayerState.Playing:
        this.pause();
        break;
      case VideoPlayerState.Played:
        this.replay();
        break;
    }
  }

  @HostListener('mouseenter')
  handleMouseEnter(): void {
    this._mouseEnter.next(true);
  }

  @HostListener('mousemove')
  handleMouseMove(): void {
    this._mouseMove.next(true);
  }

  @HostListener('mouseleave')
  handleMouseLeave(): void {
    this._mouseLeave.next(false);
  }

  handlePlayButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();

    this.play();
  }

  handlePauseButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();

    this.pause();
  }

  handleReplayButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();

    this.replay();
  }

  handleVolumeButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();

    this.toggleMute();
  }

  handleFullscreenButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();

    this.toggleFullscreen();
  }

  handleMoreButtonClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.toggleMorePopover();
  }

  handleSliderClick(event: MouseEvent): void {
    event?.stopPropagation();

    this.closeMorePopover();
  }

  handleMorePopoverClick(event: MouseEvent): void {
    event?.stopPropagation();
  }

  handleProgressRangePositionChange(percentage: number): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    if (!player.paused) {
      player.pause();
    }

    const timeInSeconds = this.duration * (percentage / 100);

    this.currentTime = timeInSeconds;
    this.seeked.next(timeInSeconds);
  }

  handleVolumeRangePositionChange(percentage: number): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.volume = percentage / 100;

    this.volume = player.volume;
    this.muted = player.volume === 0;
  }

  handlePlaybackRateChange(playbackRate: number): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.playbackRate = playbackRate;

    this.playbackRate = playbackRate;
  }

  play(): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.play();
  }

  pause(): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.pause();
  }

  replay(): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.currentTime = 0;

    player.play();
  }

  seek(timeInSeconds: number): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    player.currentTime = timeInSeconds;

    player.play();
  }

  download(): void {
    window.open(this.source, '_blank');
  }

  toggleMute(): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    this.muted = !this.muted;
    player.muted = this.muted;

    if (!this.muted && this.volume === 0) {
      this.volume = 0.2;
      player.volume = this.volume;
    }
  }

  toggleFullscreen(): void {
    const player = this.videoElementRef?.nativeElement;
    if (!player) {
      return;
    }

    if (!document.fullscreenElement) {
      this.isFullscreenMode = true;

      const element = <any>player.parentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      this.isFullscreenMode = false;

      document.exitFullscreen();
    }
  }

  toggleMorePopover(): void {
    if (!this.morePopover?.isOpen) {
      this.morePopover.show();
    } else {
      this.morePopover.hide();
    }
  }

  closeMorePopover(): void {
    if (this.morePopover?.isOpen) {
      this.morePopover.hide();
      this.morePopoverMenuIndex = -1;
    }
  }

  private _computeSourceType(): string {
    const sourceExtension = this.source?.split('.')?.pop();
    if (!sourceExtension) {
      return;
    }

    switch (sourceExtension) {
      case 'mp4':
        return 'video/mp4';

      case 'ogg':
        return 'video/ogg';

      case 'webm':
        return 'video/webm';

      default:
        throw new Error('Unsupported video type');
    }
  }

  private _getTimeExpression(timeInSeconds: number): string {
    if (!timeInSeconds) {
      return '00:00';
    }

    const hours = Math.round(timeInSeconds / 60 / 60);
    const minutes = Math.round(timeInSeconds / 60);
    const seconds = Math.round(timeInSeconds % 60);

    const hoursExpression = hours ? `${hours < 10 ? '0' : ''}${hours}` : '';
    const minutesExpression = minutes ? `${minutes < 10 ? '0' : ''}${minutes}` : '00';
    const secondsExpression = seconds ? `${seconds < 10 ? '0' : ''}${seconds}` : '00';

    const expression = [
      hoursExpression,
      minutesExpression,
      secondsExpression,
    ];

    return expression
      .filter(x => !!x)
      .join(':');
  }

  private _handleLoadedMetadata(event: Event & {target: HTMLVideoElement}): void {
    this.loaded.next();

    this.duration = event.target.duration;
    this.volume = event.target.volume;
    this.playbackRate = event.target.playbackRate;

    this.isMetadataLoading = false;
  }

  private _handlePlay(event: Event & {target: HTMLVideoElement}): void {
    this.playing.next();
  }

  private _handleEnded(event: Event & {target: HTMLVideoElement}): void {
    this.played.next();

    this.currentTime = this.duration;

    this.isControlsVisible = true;
  }

  private _handlePause(event: Event & {target: HTMLVideoElement}): void {
    this.paused.next();

    this.isControlsVisible = true;
  }

  private _handleTimeUpdate(event: Event & {target: HTMLVideoElement}): void {
    if (event.target.paused) {
      return;
    }

    this.currentTime = event.target.currentTime;
  }
}
