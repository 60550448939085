import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import {
  DxTextBoxModule,
  DxSelectBoxModule,
  DxValidatorModule,
  DxDrawerModule,
  DxScrollViewModule,
  DxButtonModule,
  DxFileUploaderModule,
  DxTooltipModule,
  DxProgressBarModule,
  DxListModule,
  DxToolbarModule,
  DxPopupModule,
  DxTextAreaModule,
} from 'devextreme-angular';

import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { FeatureTogglingModule } from '../feature-toggling/feature-toggling.module';

import { ImageViewerModule } from '../image-viewer/image-viewer.module';
import { SharedModule } from '../shared/shared.module';
import { ImageUploaderDirective } from './directives/image-uploader.directive';

import { ComponentLoaderFactoryService } from './services/component-loader-factory.service';
import { ComponentLoaderService } from './services/component-loader.service';
import { ContentRefService } from './services/content-ref.service';
import { ActivityTrackerService } from './services/activity-tracker.service';
import { ChatService } from './services/chat.service';
import { EmojiSupportCacheService } from './services/emoji-support-cache.service';

import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AddressComponent } from './components/address/address.component';
import { EmojiPickerComponent } from './components/emoji-picker/emoji-picker.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MultiRangeSliderComponent } from './components/multi-range-slider/multi-range-slider.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { StopDevexpressScrollTamperingDirective } from './directives/stop-devexpress-scroll-tampering.directive';
import { TrimOnBlurDirective } from './directives/trim-on-blur.directive';

import { AbsPipe } from './pipes/abs.pipe';
import { NumberAbbreviationPipe } from './pipes/number-abbreviation.pipe';
import { OptionalYearDatePipe } from './pipes/optional-year-date.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SafeCurrencyPipe } from './pipes/safe-currency.pipe';
import { SafeDatePipe } from './pipes/safe-date.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeNumberPipe } from './pipes/safe-number.pipe';
import { SafePercentPipe } from './pipes/safe-percent.pipe';
import { SeparatedListPipe } from './pipes/separated-list.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    DxTextBoxModule,
    DxSelectBoxModule,
    DxiValidationRuleModule,
    DxValidatorModule,
    DxDrawerModule,
    DxScrollViewModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxTooltipModule,
    DxProgressBarModule,
    DxListModule,
    DxToolbarModule,
    DxPopupModule,
    DxTextAreaModule,

    PickerModule,

    FeatureTogglingModule,

    ImageViewerModule,
  ],
  declarations: [
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    AddressComponent,
    EmojiPickerComponent,
    NumberAbbreviationPipe,
    OptionalYearDatePipe,
    SafeCurrencyPipe,
    SafeNumberPipe,
    SafePercentPipe,
    SafeDatePipe,
    SafeHtmlPipe,
    AbsPipe,
    SeparatedListPipe,
    OrderByPipe,
    FormControlPipe,
    FilterPipe,
    LoaderComponent,
    MultiRangeSliderComponent,
    RadioButtonComponent,
    SpinnerComponent,
    ImageUploaderDirective,
    StopDevexpressScrollTamperingDirective,
    TrimOnBlurDirective,
    TimeAgoPipe,
  ],
  providers: [
    ActivityTrackerService,
    ChatService,
    ComponentLoaderFactoryService,
    ComponentLoaderService,
    ContentRefService,
    EmojiSupportCacheService,
    NumberAbbreviationPipe,
    OptionalYearDatePipe,
    DecimalPipe,
    PercentPipe,
    CurrencyPipe,
    SafeCurrencyPipe,
    SafeDatePipe,
    SafeHtmlPipe,
    SeparatedListPipe,
    OrderByPipe,
    TimeAgoPipe,
    FilterPipe,
  ],
  exports: [
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    AddressComponent,
    EmojiPickerComponent,
    NumberAbbreviationPipe,
    OptionalYearDatePipe,
    SafeNumberPipe,
    SafeCurrencyPipe,
    SafeDatePipe,
    SafePercentPipe,
    SafeHtmlPipe,
    AbsPipe,
    SeparatedListPipe,
    OrderByPipe,
    FormControlPipe,
    LoaderComponent,
    MultiRangeSliderComponent,
    RadioButtonComponent,
    SpinnerComponent,
    StopDevexpressScrollTamperingDirective,
    TrimOnBlurDirective,
    TimeAgoPipe,
    FilterPipe,
    ImageUploaderDirective,
  ],
})
export class InfrastructureModule {
  static forRoot(): ModuleWithProviders<InfrastructureModule> {
    return {
      ngModule: InfrastructureModule,
      providers: [],
    };
  }
}
