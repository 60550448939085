import * as models from '@statera/sdk/common';

export type AlertIntervalType = models.AlertIntervalType;
export const AlertIntervalType = models.AlertIntervalType;

export type NotificationsDisplay = models.NotificationsDisplay;
export const NotificationsDisplay = models.NotificationsDisplay;

export type EmailNotifications = models.EmailNotifications;
export const EmailNotifications = models.EmailNotifications;

export type MessengerSmsNotifications = models.MessengerSmsNotifications;
export const MessengerSmsNotifications = models.MessengerSmsNotifications;

export type MessengerEmailNotifications = models.MessengerEmailNotifications;
export const MessengerEmailNotifications = models.MessengerEmailNotifications;

export type User = models.IProfileViewModel;
export type AlertPreferences = models.IAlertsPreferenceViewModel;
export type PasswordResetRequest = models.IPasswordResetRequestViewModel;
export type StartupInfo = models.IStartupInfoViewModel;
export type PhoneNumberVerificationInfo = models.IVerifyPhoneNumberViewModel;
export type UserAvatar = models.IAvatarViewModel;
export type TwoFactorAuthSettingValue = models.ITwoFactorAuthSettingViewModel;
export type VerifyPhoneNumberResult = models.IVerifyPhoneNumberResultViewModel;

export enum LeaseCommencementPreferenceType {
  OneMonth = 'One Month',
  TwoMonths = 'Two Months',
  ThreeMonths = 'Three Months',
  SixMonths = 'Six Months'
}

export enum LeaseExpirationPreferenceType {
  SixMonths = 'Six Months',
  OneYear = 'One Year'
}

export enum RentEscalationPreferenceType {
  NineMonths = 'Nine Months',
  TenMonths = 'Ten Months',
  ElevenMonths = 'Eleven Months',
  Off = 'Off',
}

export enum RenewalOptionType {
  SixMonths = 'Six Months',
  OneYear = 'One Year',
  Off = 'Off',
}

export enum TerminationOptionType {
  SixMonths = 'Six Months',
  OneYear = 'One Year',
  Off = 'Off',
}

export enum TwoFactorStep {
  AddNewPhoneNumber,
  VerifyPhoneNumber,
  PhoneNumber
}

export const LEASE_COMMENCEMENT_INTERVAL_VALUES: Record<LeaseCommencementPreferenceType, number> = {
  [LeaseCommencementPreferenceType.OneMonth]: 1,
  [LeaseCommencementPreferenceType.TwoMonths]: 2,
  [LeaseCommencementPreferenceType.ThreeMonths]: 3,
  [LeaseCommencementPreferenceType.SixMonths]: 6,
};

