import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AuthManager } from '@statera/sdk/auth';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  private readonly _authManager: AuthManager;
  private readonly _router: Router;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _destroy$: Subject<void>;

  private _redirectUrl?: string;

  @ViewChild('signInForm') signInForm: any;
  vm = <models.ILoginViewModel>{};
  submitted = false;

  constructor(authManager: AuthManager, router: Router, activatedRoute: ActivatedRoute) {
    this._authManager = authManager;
    this._router = router;
    this._activatedRoute = activatedRoute;
    this._destroy$ = new Subject<void>();
  }


  ngOnInit(): void {
    this._activatedRoute.queryParams
      .pipe(
        takeUntil(this._destroy$),
        tap((params) => this._redirectUrl = params['redirectUrl']),
      )
      .subscribe();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.submitted = true;
    this._authManager.checkUser({...this.vm})
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe((result) => {
        if (result.requiresTwoFactor) {
          this._router.navigate(['/2fa'], { queryParams: { redirectAfter: this._redirectUrl }});
          return;
        }
        if (this._redirectUrl) {
          window.location.assign(this._redirectUrl);
          return;
        }
        window.location.reload();
      })
      .add(() => (this.submitted = null));
  }
}
