import { Injectable } from '@angular/core';
import * as ng from '@angular/core';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';
import { ComponentLoaderService } from '../../infrastructure/services/component-loader.service';

import { ImageViewerRefService } from './image-viewer-ref.service';

import { ImageViewerContainerComponent } from '../components/image-viewer-container/image-viewer-container.component';

import { ImageViewerOptions } from '../models/image-viewer-options.model';
import { ImageViewerImage } from '../models/image-viewer-image.model';

@Injectable()
export class ImageViewerService {
  private readonly _renderer: ng.Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;
  private readonly _config: ImageViewerOptions;

  private _loader: ComponentLoaderService<ImageViewerContainerComponent>;

  constructor(rendererFactory: ng.RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
    this._config = new ImageViewerOptions();
  }

  show(images: Array<ImageViewerImage>, config?: ImageViewerOptions): ImageViewerRefService {
    return this._showImageViewer(images, {...this._config, ...config});
  }

  hide(): void {
    this._hideImageViewer();
  }

  private _showImageViewer(images: Array<ImageViewerImage>, config: ImageViewerOptions): ImageViewerRefService {
    this._loader = this._componentLoaderFactory
      .createLoader<ImageViewerContainerComponent>(null, null, null);

    const imageViewerRefService = new ImageViewerRefService();

    const imageViewerContainerRef = this._loader
      .provide({provide: ImageViewerRefService, useValue: imageViewerRefService})
      .provide({provide: ImageViewerOptions, useValue: {...config, imageViewerService: this}})
      .attach(ImageViewerContainerComponent);

    imageViewerContainerRef.show(null, null, {
      images,
      activeIndex: config.activeIndex,
      allowChangeMarkers: config.allowChangeMarkers,
      enableArrowNavigation: config.enableArrowNavigation,
      enableZoom: config.enableZoom
    });

    imageViewerRefService.hide = () => imageViewerContainerRef.instance.hide();

    return imageViewerRefService;
  }

  private _hideImageViewer(): void {
    this._loader.hide();
    this._loader = null;
  }
}
