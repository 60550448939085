import * as ng from '@angular/core';
import { Component, Input, Output, ViewChild } from '@angular/core';
import { DxAccordionComponent } from 'devextreme-angular';

import { CommonTools } from '@statera/sdk/common';
import { TermDisplayValueType, TermManager, LeaseTerm } from '@statera/sdk/term';

import * as models from '../../../infrastructure/models/generated';

interface IGroupLeaseTerm {
  order: number;
  config: models.ILeaseTermConfiguration;
  value: string;
  history: Array<models.ILeaseTermViewModel>;
  leaseTerm: models.ILeaseTermViewModel;
  lease: models.ILeaseViewModel;
  amendmentNumber: number;
  isCurrentTermValue: boolean;
  thirdPartyLeaseValue: string;
  documentSection: string;
}

interface IGroupLeaseTermTemplate {
  order: number;
  termGroup: string;
  terms: Array<IGroupLeaseTerm>;
}

enum LeaseGroupsOrder {
  'Main Terms' = 1,
  'Building Conditions' = 2,
  'Lease Options' = 3,
  'Other Lease Rights' = 4
}

@Component({
  selector: 'app-lease-term-dashboard',
  templateUrl: './lease-term-dashboard.component.html',
  styleUrls: ['./lease-term-dashboard.component.scss'],
})
export class LeaseTermDashboardComponent implements ng.OnInit, ng.AfterViewInit, ng.OnChanges {
  @ViewChild('dxAccordionComponent') dxAccordionComponent: DxAccordionComponent;

  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTermConfigurations: Array<models.ILeaseTermConfiguration>;

  @Input() isMobileView: boolean;
  @Input() project: models.IProjectViewModel;

  @Output() afterViewInit: ng.EventEmitter<LeaseTermDashboardComponent>;

  leaseTermTemplates: Array<IGroupLeaseTermTemplate>;
  activeDot: string;

  accordionSelectedIndex: number;
  accordionAnimationDuration: number;

  popoverSize: { width?: number };

  private _openedAccordionItems: Array<string>;

  private readonly _termManager: TermManager;
  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _ngZone: ng.NgZone;

  constructor(termManager: TermManager, changeDetectorRef: ng.ChangeDetectorRef, ngZone: ng.NgZone) {
    this._termManager = termManager;
    this._changeDetectorRef = changeDetectorRef;
    this._ngZone = ngZone;

    this._openedAccordionItems = [];
    this.activeDot = null;

    this.afterViewInit = new ng.EventEmitter<LeaseTermDashboardComponent>();
  }

  ngOnInit(): void {
    this.accordionAnimationDuration = 300;
  }

  ngAfterViewInit(): void {
    this.afterViewInit.emit(this);
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    // Triggers re-rendering when lease or leaseTermConfigurations props are changed
    if (
      this._isLeaseTermConfigurationsChanged(changes) ||
      this._isLeaseChanged(changes)
    ) {
      let currentLeaseTermConfigs = this.leaseTermConfigurations;
      if (changes.leaseTermConfigurations && changes.leaseTermConfigurations.currentValue) {
        currentLeaseTermConfigs = changes.leaseTermConfigurations.currentValue;
      }

      let currentLease = this.lease;
      if (changes.lease && changes.lease.currentValue) {
        currentLease = changes.lease.currentValue;
      }

      if (CommonTools.isArray(currentLeaseTermConfigs)) {
        const configs = currentLeaseTermConfigs.filter(x => x.isUsedInDashboardPage);

        this.leaseTermTemplates = this._getGroupLeaseTerm(currentLease, configs);
        this.activeDot = this.leaseTermTemplates[0].termGroup;

        this._openedAccordionItems = [];

        // DxAccordion data source update will set accordion selected item index to -1, so some delay between changing
        // these two is required.
        setTimeout(() => this._expandAccordionItem(0, false /** withAnimation */), 100);
      }
    }
  }

  handleAccordionOptionChange({ value }): void {
    if (!value || !value.length) {
      return;
    }

    // @ts-ignore
    this._openedAccordionItems = [...value.map(x => x.termGroup)];

    this._detectChanges();
  }

  isAccordionItemOpened(title: string): boolean {
    return this._openedAccordionItems.includes(title);
  }

  clickDot(termGroup: string): void {
    this.activeDot = termGroup;
  }

  openAccordionByIndex(index: number, collapseAll: boolean = false): void {
    if (!this.dxAccordionComponent || !this.dxAccordionComponent.instance) {
      return;
    }

    const accordionInstance = this.dxAccordionComponent.instance;
    if (collapseAll && this.leaseTermTemplates) {
      this.leaseTermTemplates.forEach((_, i) => accordionInstance.collapseItem(i));
    }

    this._expandAccordionItem(index, true /** withAnimation */);
  }

  getTermDisplayValue(term: models.ILeaseTermViewModel): string {
    if (!term) {
      return null;
    }
    return this._termManager.getTermDetailedDisplayValue(term);
  }

  getTermDocumentSection(term: IGroupLeaseTerm, lease: models.ILeaseViewModel): string {
    if (!term || !term.leaseTerm || !term.config) {
      return null;
    }

    if (term.config.leaseTermType === models.LeaseTermType.Expiration) {
      return lease?.expirationDocumentSection;
    }

    if (term.config.leaseTermType === models.LeaseTermType.BuildingPercentage) {
      return lease?.buildingPercentageDocumentSection;
    }

    return (<models.ILeaseTermViewModel & {documentSection?: string}>term.leaseTerm).documentSection;
  }

  isBaseRentalRate(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isBaseRentalRate(term);
  }

  isTenantSquareFootagePhaseInTermValue(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isTenantSquareFootagePhaseInTermValue(term);
  }

  isRentalRateEscalationCustomOption(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isRentalRateEscalationCustomOption(term);
  }

  isBurnDownOption(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isBurnDownOption(term);
  }

  isRealEstateTaxesBreakdownTable(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isRealEstateTaxesBreakdownTable(term);
  }

  getRealEstateTaxesBreakdownTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRealEstateTaxesBreakdownTable(term);
  }

  getBaseRentalRateSchedulePreview(term: models.ILeaseTermViewModel): Array<models.IBaseRentalScheduleRowViewModel> {
    return this._termManager.getBaseRentalRateSchedulePreview(term);
  }

  getTenantSquareFootagePhaseInResults(term: models.ILeaseTermViewModel): Array<models.ITenantSquareFootagePhaseInResultViewModel> {
    return this._termManager.getTenantSquareFootagePhaseInResults(term);
  }

  getRentalRateEscalationCustomOption(term: models.ILeaseTermViewModel): Array<models.IRentalRateAnnualEscalationTermCustomResult> {
    return this._termManager.getRentalRateEscalationCustomOption(term);
  }

  getBaseRentalRateScheduleCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getBaseRentalRateScheduleCustomTable(term);
  }

  getTenantSquareFootagePhaseInCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getTenantSquareFootagePhaseInCustomTable(term);
  }

  getRentalRateEscalationCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRentalRateEscalationCustomTable(term);
  }

  getSecurityDepositBurnDownCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getSecurityDepositBurnDownCustomTable(term);
  }

  getBurnDownScheduleTable(term: models.ILeaseTermViewModel): Array<models.IBurnDownScheduleViewModel> {
    const isCustomEscalation = this.isBurnDownOption(term);
    if (!isCustomEscalation) {
      return [];
    }

    return (<models.ISecurityDepositTermViewModel>term).burnDownScheduleValues;
  }

  isShowWithoutHistory() {
    if (!this.project) {
      return true;
    }

    return this.project.projectTypeId !== models.ProjectTypeEnum.NewDeal;
  }

  private _expandAccordionItem(index: number, withAnimation: boolean = true): void {
    this.accordionSelectedIndex = index;

    if (!withAnimation) {
      const prevAccordionAnimationDuration = this.accordionAnimationDuration;

      // Clear animation duration
      this.accordionAnimationDuration = 0;

      // Restore animation duration after rendering
      this._ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.accordionAnimationDuration = prevAccordionAnimationDuration;
          this._detectChanges();
        });
      });
    }
  }

  private _isLeaseTermConfigurationsChanged(changes: ng.SimpleChanges): boolean {
    return (
      changes &&
      changes.leaseTermConfigurations &&
      (
        changes.leaseTermConfigurations.isFirstChange() ||
        (
          (changes.leaseTermConfigurations.previousValue && changes.leaseTermConfigurations.currentValue) &&
          !CommonTools.isEqual(changes.leaseTermConfigurations.previousValue, changes.leaseTermConfigurations.currentValue)
        )
      )
    );
  }

  private _isLeaseChanged(changes: ng.SimpleChanges): boolean {
    return (
      changes &&
      changes.lease &&
      (
        changes.lease.isFirstChange() ||
        (
          (changes.lease.previousValue && changes.lease.currentValue) &&
          changes.lease.previousValue.id !== changes.lease.currentValue.id
        )
      )
    );
  }

  private _getGroupLeaseTerm(
    lease: models.ILeaseViewModel,
    termConfigs: Array<models.ILeaseTermConfiguration>
  ): Array<IGroupLeaseTermTemplate> {
    const groupLeaseTermTemplates = termConfigs
      .reduce(
        (acc, leaseTermConfiguration) => {
          return {
            ...acc,
            [leaseTermConfiguration.termGroup]: [...(acc[leaseTermConfiguration.termGroup] || []), leaseTermConfiguration],
          };
        },
        {}, /** acc */
      );

    return Object
      .keys(groupLeaseTermTemplates)
      .map(groupIndex => {
        const groupLeaseTermTemplate = groupLeaseTermTemplates[groupIndex];
        return <IGroupLeaseTermTemplate>{
          order: LeaseGroupsOrder[groupIndex],
          termGroup: groupIndex,
          terms: groupLeaseTermTemplate
            .map(config => {
              const leaseTerm: models.ILeaseTermViewModel & {documentSection?: string} = this._termManager
                .getLeaseTerm(lease, config.leaseTermType);

              return <IGroupLeaseTerm>{
                config: config,
                order: config.groupSortOrder,
                value: this._getTermAbstractValue(lease, config),
                history: this._getTermAbstractHistory(lease, config),
                leaseTerm: leaseTerm,
                amendmentNumber: leaseTerm && leaseTerm.amendmentNumber,
                isCurrentTermValue: leaseTerm && leaseTerm.isCurrentTermValue,
                thirdPartyLeaseValue: this._getTermAbstractValue(lease.thirdPartyLease, config),
                documentSection: leaseTerm && leaseTerm.documentSection,
              };
            })
            .sort((x, y) => x.order - y.order),
        };
      })
      .sort((x, y) => x.order - y.order);
  }

  private _getTermAbstractValue(lease: models.ILeaseViewModel, termConfig: models.ILeaseTermConfiguration): string {
    if (!lease || !termConfig) {
      return;
    }
    const abstractValue = this._termManager.getTermCurrentAbstractValue(lease, termConfig, TermDisplayValueType.Detailed);

    if (!abstractValue && this.lease && this.lease.leaseActionType === models.LeaseActionType.LeaseShouldBeAbstracted) {
      return 'TBD';
    }

    return abstractValue;
  }

  private _getTermAbstractHistory(lease: models.ILeaseViewModel, termConfig: models.ILeaseTermConfiguration): Array<LeaseTerm> {
    if (!lease || !termConfig) {
      return;
    }

    const currentAbstractTerm = this._termManager.getCurrentAbstractTerm(lease, termConfig.leaseTermType);
    const history = this._termManager.getTermAbstractHistory(lease, termConfig.leaseTermType);

    const isSameTerms = (x: LeaseTerm, y: LeaseTerm): boolean => {
      return (
        this._termManager.isSameTerms(x, y) ||
        (
          !lease.isAbstractLease &&
          this._termManager.isTermTerm(x) &&
          this._termManager.isTermTerm(y) &&
          x.termValue === y.termValue
        )
      );
    };

    if (
      history &&
      history[history.length - 1] && currentAbstractTerm &&
      !isSameTerms(history[history.length - 1], currentAbstractTerm)
    ) {
      history.push(currentAbstractTerm);
    }

    return history;
  }

  private _detectChanges(): void {
    if ((<ng.ViewRef>this._changeDetectorRef).destroyed) {
      return;
    }

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
}
