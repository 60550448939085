import {Directive, ElementRef, OnInit, OnDestroy, Renderer2, HostListener, EventEmitter, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appTestInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDirective,
      multi: true,
    },
  ],
  exportAs: 'testInput',
})
export class InputDirective implements OnInit, OnDestroy, ControlValueAccessor {

  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  private _onChangeHandler: (value: string) => void;
  private _onTouchedHandler: () => void;

  private readonly _valueChange = new BehaviorSubject<string>('');
  private readonly _destroy = new Subject<void>();

  private _offscreenCanvas: OffscreenCanvas;
  private _offscreenContext: OffscreenCanvasRenderingContext2D;
  private _placeholder =  this._elementRef.nativeElement.placeholder || ' ';

  constructor(private readonly _elementRef: ElementRef, private readonly _render: Renderer2) {}

  ngOnInit(): void {
    this._offscreenCanvas = new OffscreenCanvas(0, 0);
    this._offscreenContext = this._offscreenCanvas.getContext('2d');

    this._valueChange
      .pipe(
        tap(value => this._changeInputWidth(value)),
        takeUntil(this._destroy)
      )
      .subscribe();

    // Устанавливаем начальную ширину на основе плейсхолдера
    const placeholder = this._elementRef.nativeElement.placeholder || ' ';
    this._changeInputWidth(placeholder);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  writeValue(value: string): void {
    this._valueChange.next(value);
    this._changeInputWidth(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChangeHandler = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedHandler = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._render.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this._valueChange.next(value);
    this.valueChange.emit(value);
    if (this._onChangeHandler) {
      this._onChangeHandler(value);
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (this._onTouchedHandler) {
      this._onTouchedHandler();
    }
  }

  private _changeInputWidth(value: string): void {
    const input = this._elementRef.nativeElement;

    const inputStyles = getComputedStyle(input);

    const inputFontWeight = inputStyles.getPropertyValue('font-weight');
    const inputFontSize = inputStyles.getPropertyValue('font-size');
    const inputFontFamily = inputStyles.getPropertyValue('font-family');

    this._offscreenContext.font = `${inputFontWeight} ${inputFontSize} ${inputFontFamily}`;

    const textMeasurements = this._offscreenContext.measureText(value || this._placeholder);
    const inputWidth = Math.ceil(textMeasurements.width) + 5; // Добавляем отступ

    this._render.setStyle(input, 'width', `${inputWidth}px`);
  }
}
