import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { FeatureTogglingManager } from './feature-toggling.manager';
import { FeatureTogglingRepository } from './feature-toggling.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    FeatureTogglingManager,
    FeatureTogglingRepository,
  ],
})
export class FeatureTogglingModule {
  static forChild(configuration: Configuration): ModuleWithProviders<FeatureTogglingModule> {
    return {
      ngModule: FeatureTogglingModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        FeatureTogglingManager,
        FeatureTogglingRepository,
      ],
    };
  }
}
