import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import * as dx from 'devextreme-angular';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { InlineSVGModule } from 'ng-inline-svg';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';

import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { FeatureTogglingModule } from '../feature-toggling/feature-toggling.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { SharedModule } from '../shared/shared.module';
import { TenantModule } from '../tenant/tenant.module';
import { PlanModule } from '../plan/plan.module';
import { ColaboRouter } from './colabo.router';

import { ChatService } from '../infrastructure/services/chat.service';
import { ProfileService } from '../user/services/profile.service';
import { ElectTermsForNegotiationComponent } from './components/elect-terms-for-negotiation/elect-terms-for-negotiation.component';
import { TermsPageService } from './services/terms-page.service';

import { ColaboGuard } from './guards/colabo.guard';

import { ColaboDemoTermsComponent } from './components/colabo-demo-terms/colabo-demo-terms.component';
import { AlertListComponent } from './components/alert-list/alert-list.component';
import { ChatTreeComponent } from './components/chat-tree/chat-tree.component';
import { ChatViewComponent } from './components/chat-view/chat-view.component';
import { ColaboDemoComponent } from './components/colabo-demo/colabo-demo.component';
import { ColaboRootComponent } from './components/colabo-root/colabo-root.component';
import { ColaboComponent } from './components/colabo/colabo.component';
import { LeaseDocumentCommentsComponent } from './components/lease-document-comments/lease-document-comments.component';
import { LeaseDocumentsComponent } from './components/lease-documents/lease-documents.component';
import { LeaseExecuteComponent } from './components/lease-execute/lease-execute.component';
import { LetterOfIntentComponent } from './components/letter-of-intent/letter-of-intent.component';
import { SignDocumentComponent } from './components/sign-document/sign-document.component';
import { TermHistoryComponent } from './components/term-history/term-history.component';
import { TermListComponent } from './components/term-list/term-list.component';
import { TermCommentsComponent } from './components/terms/term-comments/term-comments.component';
import { TermCustomValueTemplateDeleteDialogComponent } from './components/terms/term-custom-value-template-delete-dialog/term-custom-value-template-delete-dialog.component';
import { TermCustomValueTemplateDialogComponent } from './components/terms/term-custom-value-template-dialog/term-custom-value-template-dialog.component';
import { TermCustomValueTemplateTextareaComponent } from './components/terms/term-custom-value-template-textarea/term-custom-value-template-textarea.component';
import { TermCustomValueTemplateUpsertDialogComponent } from './components/terms/term-custom-value-template-upsert-dialog/term-custom-value-template-upsert-dialog.component';
import { TermHeaderComponent } from './components/terms/term-header/term-header.component';
import { TermValueDisplayComponent } from './components/terms/term-value-display/term-value-display.component';
import { TermActionButtonsComponent } from './components/terms/term-action-buttons/term-action-buttons.component';
import { TermSecondaryActionButtonsComponent } from './components/terms/term-secondary-action-buttons/term-secondary-action-buttons.component';
import { BaseRentalRateComponent } from './components/terms/term/base-rental-rate/base-rental-rate.component';
import { FreeRentComponent } from './components/terms/term/free-rent/free-rent.component';
import { HvacComponent } from './components/terms/term/hvac/hvac.component';
import { RenewalOptionComponent } from './components/terms/term/renewal-option/renewal-option.component';
import { SecurityDepositComponent } from './components/terms/term/security-deposit/security-deposit.component';
import { TenantImprovementsComponent } from './components/terms/term/tenant-improvements/tenant-improvements.component';
import { TenantSquareFootageComponent } from './components/terms/term/tenant-square-footage/tenant-square-footage.component';
import { TermComponent } from './components/terms/term/term/term.component';
import { TerminationOptionComponent } from './components/terms/term/termination-option/termination-option.component';
import { TermsAccordionHeaderComponent } from './components/terms/terms-accordion/terms-accordion-header/terms-accordion-header.component';
import { TermsComponent } from './components/terms/terms.component';
import { FloorplanDemoComponent } from './components/demos/floorplan-demo-tenant/floorplan-demo.component';
import { LeaseDocumentsDemoComponent } from './components/demos/lease-documents-demo/lease-documents-demo.component';
import { BaseRentalScheduleComponent } from './components/terms/base-rental-schedule/base-rental-schedule.component';
import { CommencementTermComponent } from './components/terms/term/commencement-term/commencement-term.component';
import { LandlordMaintenanceComponent } from './components/terms/term/landlord-maintenance/landlord-maintenance.component';
import { TenantMaintenanceComponent } from './components/terms/term/tenant-maintenance/tenant-maintenance.component';
import { SelfHelpComponent } from './components/terms/term/self-help/self-help.component';
import { AssignmentComponent } from './components/terms/term/assignment/assignment.component';
import { EstoppelCertificateComponent } from './components/terms/term/estoppel-certificate/estoppel-certificate.component';
import { ExpansionOptionComponent } from './components/terms/term/expansion-option/expansion-option.component';
import { InsuranceComponent } from './components/terms/term/insurance/insurance.component';

// tslint:disable:max-line-length
import { RealEstateTaxesCamExpensesComponent } from './components/terms/term/real-estate-taxes-cam-expenses/real-estate-taxes-cam-expenses.component';
import { RentalRateAnnualEscalationComponent } from './components/terms/term/rental-rate-annual-escalation/rental-rate-annual-escalation.component';
import { TenantSquareFootagePhaseInResultComponent } from './components/terms/term/tenant-square-footage/tenant-square-footage-phase-in-result/tenant-square-footage-phase-in-result.component';
import { TenantSquareFootagePhaseInTableComponent } from './components/terms/term/tenant-square-footage/tenant-square-footage-phase-in-table/tenant-square-footage-phase-in-table.component';
import { RentalRateEscalationResultTableComponent } from './components/terms/term/rental-rate-annual-escalation/rental-rate-escalation-result-table/rental-rate-escalation-result-table.component';
import { RentalRateEscalationCustomScheduleComponent } from './components/terms/term/rental-rate-annual-escalation/rental-rate-escalation-custom-schedule/rental-rate-escalation-custom-schedule.component';
import { SecurityDepositBurnDownScheduleTableComponent } from './components/terms/term/security-deposit/security-deposit-burn-down-schedule-table/security-deposit-burn-down-schedule-table.component';
import { SecurityDepositBurnDownScheduleResultComponent } from './components/terms/term/security-deposit/security-deposit-burn-down-schedule-result/security-deposit-burn-down-schedule-result.component';
import { CertificateOfInsuranceComponent } from './components/certificate-of-insurance/certificate-of-insurance.component';
import { RealEstateTaxesCamExpensesBreakdownTableComponent } from './components/terms/term/real-estate-taxes-cam-expenses/real-estate-taxes-cam-expenses-burndown-table/real-estate-taxes-cam-expenses-breakdown-table.component';
import { LandlordModule } from '../landlord/landlord.module';
import { HoldoverProvisionComponent } from './components/terms/term/holdover-provision/holdover-provision.component';
import { ChatViewTeamComponent } from './components/chat-view-team/chat-view-team.component';
import { InviteExternalSignerToSignaturesDialogComponent } from './components/lease-document-external-signer-dialog/invite-external-signer-to-signatures-dialog.component';
import { SpaceUseComponent } from './components/terms/term/space-use/space-use.component';
import { ConditionOfPremisesComponent } from './components/terms/term/condition-of-premises/condition-of-premises.component';
import { CodeComplianceComponent } from './components/terms/term/code-compliance/code-compliance.component';
import { ParkingComponent } from './components/terms/term/parking/parking.component';
import { UtilitiesComponent } from './components/terms/term/utilities/utilities.component';
import { SignageComponent } from './components/terms/term/signage/signage.component';
import { NonDisturbanceComponent } from './components/terms/term/non-disturbance/non-disturbance.component';
import { DxNumberBoxModule } from 'devextreme-angular';
import { HazardousMaterialsComponent } from './components/terms/term/hazardous-materials/hazardous-materials.component';
import { RailComponent } from './components/terms/term/rail/rail.component';
import { TourFormComponent } from './components/tour-form/tour-form.component';
import { TourFormCalendarComponent } from './components/tour-form/calendar/calendar.component';
import { TourInfoComponent } from './components/tour-info/tour-info.component';
import { LeaseSettingsComponent } from './components/lease-settings/lease-settings.component';
// tslint:enable:max-line-length

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ColaboRouter,
    dx.DxTreeViewModule,
    dx.DxAccordionModule,
    dx.DxTabPanelModule,
    dx.DxTabsModule,
    dx.DxTextBoxModule,
    dx.DxSelectBoxModule,
    DxiValidationRuleModule,
    dx.DxValidatorModule,
    dx.DxDrawerModule,
    dx.DxScrollViewModule,
    dx.DxButtonModule,
    dx.DxFileUploaderModule,
    dx.DxTooltipModule,
    BsDropdownModule.forRoot(),
    PopoverModule,
    dx.DxRadioGroupModule,
    dx.DxProgressBarModule,
    dx.DxListModule,
    dx.DxToolbarModule,
    dx.DxHtmlEditorModule,
    dx.DxPopupModule,
    dx.DxCheckBoxModule,
    dx.DxSliderModule,
    dx.DxBoxModule,
    DocumentViewerModule,
    InfrastructureModule,
    SharedModule,
    dx.DxNumberBoxModule,
    InfrastructureModule,
    dx.DxDateBoxModule,
    dx.DxContextMenuModule,
    PdfViewerModule,
    dx.DxLoadPanelModule,
    dx.DxTreeListModule,
    dx.DxDataGridModule,
    dx.DxValidationSummaryModule,
    dx.DxTagBoxModule,
    dx.DxFormModule,
    dx.DxValidationGroupModule,
    dx.DxSwitchModule,
    dx.DxTextAreaModule,
    dx.DxDropDownButtonModule,
    dx.DxPopoverModule,
    dx.DxDropDownBoxModule,
    TooltipModule,
    TenantModule,
    PlanModule,
    FormsModule,
    NgxMaskModule,
    InlineSVGModule,
    LandlordModule,
    dx.DxTabsModule,
    DxNumberBoxModule,

    FeatureTogglingModule,
  ],
  declarations: [
    ColaboComponent,
    TermsComponent,
    ColaboRootComponent,
    ChatTreeComponent,
    TermListComponent,
    TermHistoryComponent,
    LetterOfIntentComponent,
    ChatViewComponent,
    SignDocumentComponent,
    LeaseDocumentsComponent,
    LeaseDocumentCommentsComponent,
    AlertListComponent,
    TermsAccordionHeaderComponent,
    TermHeaderComponent,
    TermValueDisplayComponent,
    TenantImprovementsComponent,
    TermComponent,
    TermCommentsComponent,
    TermCustomValueTemplateDeleteDialogComponent,
    TermCustomValueTemplateDialogComponent,
    TermCustomValueTemplateTextareaComponent,
    TermCustomValueTemplateUpsertDialogComponent,
    BaseRentalRateComponent,
    TermActionButtonsComponent,
    TermSecondaryActionButtonsComponent,
    FreeRentComponent,
    SecurityDepositComponent,
    ColaboDemoComponent,
    RealEstateTaxesCamExpensesComponent,
    HvacComponent,
    TerminationOptionComponent,
    RenewalOptionComponent,
    RentalRateAnnualEscalationComponent,
    LeaseExecuteComponent,
    ColaboDemoTermsComponent,
    FloorplanDemoComponent,
    RentalRateEscalationResultTableComponent,
    RentalRateEscalationCustomScheduleComponent,
    LeaseDocumentsDemoComponent,
    BaseRentalScheduleComponent,
    LeaseDocumentsDemoComponent,
    TenantSquareFootageComponent,
    TenantSquareFootagePhaseInTableComponent,
    TenantSquareFootagePhaseInResultComponent,
    CommencementTermComponent,
    LandlordMaintenanceComponent,
    TenantMaintenanceComponent,
    SelfHelpComponent,
    AssignmentComponent,
    EstoppelCertificateComponent,
    ExpansionOptionComponent,
    SecurityDepositBurnDownScheduleTableComponent,
    SecurityDepositBurnDownScheduleResultComponent,
    InsuranceComponent,
    CertificateOfInsuranceComponent,
    RealEstateTaxesCamExpensesBreakdownTableComponent,
    HoldoverProvisionComponent,
    ChatViewTeamComponent,
    ElectTermsForNegotiationComponent,
    InviteExternalSignerToSignaturesDialogComponent,
    SpaceUseComponent,
    ConditionOfPremisesComponent,
    CodeComplianceComponent,
    UtilitiesComponent,
    ParkingComponent,
    SignageComponent,
    NonDisturbanceComponent,
    HazardousMaterialsComponent,
    RailComponent,
    TourFormComponent,
    TourFormCalendarComponent,
    TourInfoComponent,
    LeaseSettingsComponent,
  ],
  exports: [
    FloorplanDemoComponent,
    BaseRentalScheduleComponent,
    FormsModule,
    InsuranceComponent,
  ],
  providers: [
    ChatService,
    TermsPageService,
    ProfileService,
    ColaboGuard,
  ],
  entryComponents: [
    SecurityDepositBurnDownScheduleTableComponent,
    CertificateOfInsuranceComponent,
    RealEstateTaxesCamExpensesBreakdownTableComponent,
    ElectTermsForNegotiationComponent,
    InviteExternalSignerToSignaturesDialogComponent,
    TermCustomValueTemplateDeleteDialogComponent,
    TermCustomValueTemplateDialogComponent,
    TermCustomValueTemplateUpsertDialogComponent,
  ]
})
export class ColaboModule {
}
