import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './profile.model';

@Injectable()
export class ProfileRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  skipIntro(introType: models.IntroType) {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/profile/SkipIntro?type=${introType}`, null);
  }

  resetIntro(introType: models.IntroType): Observable<void> {
    const params = new HttpParams().set('type', introType.toString(10));

    return this._httpClient
      .post<void>(`${this._configuration.apiEndpoint}/profile/ResetIntro`, null, { params });
  }
}
