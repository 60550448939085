<div class="container">
  <div class="pane left-pane">
    <div class="pane-header">
      Tour scheduled

      <div class="close">
        <a role="button" href="javascript:void(0)"
           (click)="close()">
          <i class="dx-icon dx-icon-close"></i>
        </a>
      </div>
    </div>

    <div class="pane__content">
      <div class="building-info">
        <i inlineSVG="/assets/v2/icons/building-icons.svg#icon-unit-16"></i>
        <span class="building-info__unit-name">
          {{ buildingUnit.name }}
        </span>
      </div>

      <div class="company-info">
        <img width="32" height="32" [src]="getOtherPartyLogoUrl()" />
        <div class="company-info__title">
          <div *ngIf="!isLandlord()">{{ landlordCompany.name }}</div>
          <div *ngIf="isLandlord()">{{ tenantCompany.name }}</div>
        </div>
      </div>

      <div class="tour-info">
        <div class="tour-info__caption">TOUR SCHEDULED FOR</div>
        <div class="tour-info__date">{{ tour.confirmedDateTimeSlot.date | safeDate: 'EEEE, MMMM d' }}</div>
        <div class="tour-info__time-slot">
          <i class="clock-icon"></i><span>{{ tour.confirmedDateTimeSlot.timeSlot }}</span>
        </div>
        <button type="button" class="tour-info__edit-button"
                (click)="edit()">
          <i class="tour-info__edit-button__icon"></i><span>EDIT</span>
        </button>
      </div>
    </div>
  </div>
</div>
