<div class="container">
  <div class="header">
    <span class="header__current-date">{{ getDateString() }}</span>

    <div class="header__buttons">
      <img src="/assets/img/chevron-left.svg" class="header__button" (click)="onPreviousMonthClick()" alt="Previous month button" />
      <img src="/assets/img/chevron-right.svg" class="header__button" (click)="onNextMonthClick()" alt="Next month button" />
    </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th class="table__header" *ngFor="let weekday of weekdays">{{ weekday }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td class="table__cell-container" *ngFor="let cell of row">
          <button type="button"
                  class="table__cell"
                  [class.table__cell--selected]="isSelectedDate(cell)"
                  [class.table__cell--referenced]="isReferencedDate(cell)"
                  [disabled]="cell.isDisabled"
                  (click)="onDateClick(cell)">
            <span class="table__cell-content"
                  [class.table__cell-content--today]="cell.isToday">
              {{ cell.date }}
            </span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="footer">
    <div class="timezone">
      <img src="/assets/img/globe.svg" width="14px" height="14px" alt="Globe" />
      <span class="timezone__title">Chicago time</span>
    </div>
  </div>
</div>
