<div class="view-container">
  <div class="view-container-arrows">
    <button class="left" title="Previous period"
            (click)="goToPreviousPeriod()">
      <span></span>
    </button>
    <span class="view-container-arrows-caption">
      {{ activeDealsByStageReportSingleStageDialogViewModel.groups[activePeriodId].name }}
    </span>
    <button class="right" title="Next period"
            (click)="goToNextPeriod()">
      <span></span>
    </button>
  </div>

  <div class="view-container-table">
    <app-analytics-active-deals-by-stage-report-single-stage-table
      [activeDealsByStageReportStageGroup]="activeDealsByStageReportSingleStageDialogViewModel.groups[activePeriodId]">
    </app-analytics-active-deals-by-stage-report-single-stage-table>
  </div>
</div>
