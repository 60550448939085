import { InjectionToken, Injector } from '@angular/core';

import { Notification } from './notification.model';

export const NOTIFICATION_INTERCEPTORS = new InjectionToken<Array<NotificationInterceptor<unknown>>>('NOTIFICATION_INTERCEPTORS');

export interface NotificationInterceptor<T> {
  intercept(notification: Notification<T>): Notification<T>;
}

export function getInterceptors<T>(injector: Injector): Array<NotificationInterceptor<T>> {
  const interceptors = injector.get<Array<NotificationInterceptor<T>>>(NOTIFICATION_INTERCEPTORS, []);
  return Array.from(new Set(interceptors));
}
