<section class="dashboard"
         [class.collapsed]="isSidebarCollapsed">
  <button class="dashboard-toggle" id="dashboardToggleButton"
          [class.collapsed]="isSidebarCollapsed"
          (click)="isSidebarCollapsed = !isSidebarCollapsed">
    <span class="b-dropdown-icon"></span>
  </button>

  <aside class="dashboard-filter">
    <div class="dashboard-filter-container">
      <app-analytics-filters (selectedAnalyticsFiltersChange)="handleSelectedAnalyticsFiltersChange($event)">
      </app-analytics-filters>
    </div>
  </aside>

  <main class="dashboard-main">
    <h1 class="dashboard-greeting">
      <span>
        {{greetingMessage}}
      </span>
      <span class="dashboard-greeting-highlight">
        {{userFirstName}}
      </span>
    </h1>

    <div class="dashboard-key-statistics">
      <app-analytics-key-statistics-report [analyticsFilters]="selectedAnalyticsFilters">
      </app-analytics-key-statistics-report>
    </div>

    <div class="dashboard-card-container">
      <!-- Start: Occupancy and Vacancy Report -->
      <div class="dashboard-card">
        <div class="dashboard-card-header">
          <div class="dashboard-card-header-title">
            <span>EOY Projected Occupancy</span>
            <span class="icon-info" #tooltipAnchor></span>
            <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" position="top" contentTemplate="content"
                        [target]="tooltipAnchor">
              <div class="dashboard-card-header-tooltip">
                Projections are based on a proprietary index that takes into account
                tenant company profile as well as labor statistics.
              </div>
            </dx-tooltip>
          </div>
          <div class="dashboard-card-header-selects text-right">
            <button class="btn b-btn-primary"
                    (click)="showMoreInfoOfEOYProjectedOccupancy()">
              Expand
            </button>
          </div>
        </div>
        <div class="dashboard-card-body">
          <app-analytics-occupancy-and-vacancy-report [analyticsFilters]="selectedAnalyticsFilters">
          </app-analytics-occupancy-and-vacancy-report>
        </div>
      </div>
      <!-- End: Occupancy and Vacancy Report -->
      <!-- Start: Expirations Report -->
      <div class="dashboard-card">
        <div class="dashboard-card-header">
          <div class="dashboard-card-header-title">
            Expirations
          </div>
          <div class="dashboard-card-header-selects text-right">
            <button class="btn b-btn-primary"
                    (click)="showMoreInfoOfExpirations()">
              Expand
            </button>
          </div>
        </div>
        <div class="dashboard-card-body">
          <app-analytics-expirations-report [analyticsFilters]="selectedAnalyticsFilters">
          </app-analytics-expirations-report>
        </div>
      </div>
      <!-- End: Expirations Report -->
      <!-- Start: Active Deals by Stage Report -->
      <div class="dashboard-card">
        <div class="dashboard-card-header">
          <div class="dashboard-card-header-title">
            Active Deals
          </div>
          <div class="dashboard-card-header-selects">
            <button class="btn b-btn-primary"
                    (click)="showMoreInfoOfActiveDealsByStage()">
              Expand
            </button>
          </div>
        </div>
        <div class="dashboard-card-body">
          <app-analytics-active-deals-by-stage-report [analyticsFilters]="selectedAnalyticsFilters">
          </app-analytics-active-deals-by-stage-report>
        </div>
      </div>
      <!-- End: Active Deals by Stage Report -->
      <!-- Start: Deals by Stage Report -->
      <div class="dashboard-card">
        <div class="dashboard-card-header">
          <div class="dashboard-card-header-title">
            Deals by Stage
          </div>
          <div class="dashboard-card-header-selects">
            <button class="btn b-btn-primary"
                    (click)="showMoreInfoOfDealsByStage()">
              Expand
            </button>
          </div>
        </div>
        <div class="dashboard-card-body">
          <div class="dashboard-card-body-select">
            <div class="dashboard-card-body-select-box">
              <p>Project type:</p>
              <dx-select-box displayExpr="name" valueExpr="id"
                             [items]="dealsByStageReportProjectTypes" [(value)]="dealsByStageReportCurrentProjectTypeId"
                             (valueChange)="handleDealsByStageReportProjectTypesChange($event)">
              </dx-select-box>
            </div>

            <div class="dashboard-card-body-select-box">
              <p>Initiated by:</p>
              <dx-select-box displayExpr="name" valueExpr="role"
                             [items]="dealsByStageReportInitiatedBy" [(value)]="dealsByStageReportCurrentInitiatedBy">
              </dx-select-box>
            </div>

            <div class="dashboard-card-body-select-box">
              <p>For last:</p>
              <dx-select-box displayExpr="name" valueExpr="numberOfYears"
                             [items]="dealsByStageReportNumberOfYears" [(value)]="dealsByStageReportCurrentNumberOfYears">
              </dx-select-box>
            </div>

            <div class="deals-by-stage-report-info">
              <ul class="deals-by-stage-report-info-legends">
                <li>
                  <span class="deals-by-stage-report-info-legends-bg tenant">
                  </span>
                  <span>Tenant</span>
                </li>
                <li>
                  <span class="deals-by-stage-report-info-legends-bg landlord">
                  </span>
                  <span>Landlord</span>
                </li>
              </ul>
            </div>
          </div>
          <app-analytics-deals-by-stage-report [dealType]="getDealsByStageReportDealType()"
                                               [numberOfYears]="dealsByStageReportCurrentNumberOfYears"
                                               [analyticsFilters]="selectedAnalyticsFilters">
          </app-analytics-deals-by-stage-report>
        </div>
      </div>
      <!-- End: Deals by Stage Report -->
    </div>
  </main>
</section>
