<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-12 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" required
                          [dataSource]="options | filter : 'disabled'" [(value)]="leaseTerm.hvacTermType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div><!--col-->
      <div class="col-sm-6"></div>
    </div><!--row-->

    <div class="row" *ngIf="leaseTerm?.hvacTermType === HvacTermType.CustomValue">
      <div class="col-8">
        <app-term-custom-value-template-textarea placeholder="Insert custom HVAC option clause"
                                                 required requiredMessage="Custom HVAC clause is required"
                                                 [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                 [(value)]="leaseTerm.hvacCustomValue"
                                                 (valueChange)="clearErrors()">
        </app-term-custom-value-template-textarea>
      </div>
    </div>

    <div class="row" *ngIf="leaseTerm?.hvacTermType === HvacTermType.MoneyValue">

      <div class="col-sm-6"><label for="hvacMoneyValue">HVAC annual cap:</label></div>
      <div class="col-sm-6 mb-4">
        <dx-number-box id="hvacMoneyValue" placeholder="INSERT DOLLAR VALUE" format="$#,##0.00" required
                       min="0" step="0"
                       [(value)]="leaseTerm.hvacMoneyValue"
                       (valueChange)="clearErrors()">
          <dx-validator>
            <dxi-validation-rule type="required" message="HVAC annual cap is required"></dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <div class="row" *ngIf="leaseTerm?.hvacTermType === HvacTermType.PercentValue">
      <div class="col-sm-6"><label for="hvacPercentValue">Amortization rate for HVAC replacement:</label></div>
      <div class="col-sm-6">
        <dx-number-box appIgnoreScrollwheel id="hvacPercentValue" placeholder="INSERT PERCENTAGE VALUE" format="#0.##'%'" required
                       [(value)]="leaseTerm.hvacPercentValue" (valueChange)="clearErrors()">
          <dx-validator>
            <dxi-validation-rule type="required" message="Amortization rate for HVAC replacement is required">
            </dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <!-- Start: Validation summary -->
    <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
    </div>

    <div class="term-toast term-toast-neutral"
         *ngIf="rejectButtonClicked && isBaseRentalRateGrossType">
      <i inlineSVG="assets/img/information.svg"></i>
      Gross Lease Structure
    </div>
    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>

