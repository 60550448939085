import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {Configuration} from '@statera/sdk/common';
import {BuildingBudget, BuildingBudgetVersion} from './building-budget.model';
@Injectable()
export class BuildingBudgetRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient, configuration: Configuration) {
    this._httpClient = httpClient;
    this._configuration = configuration;
  }

  getBuildingBudgets(buildingId: number): Observable<Array<BuildingBudgetVersion>> {
    return this._httpClient
      .get<Array<BuildingBudgetVersion>>(`${this._configuration.apiEndpoint}/building-budgets/${buildingId}`);
  }

  upsertBuildingBudget(buildingBudget: BuildingBudget): Observable<BuildingBudget> {
    return this._httpClient
      .post<BuildingBudget>(`${this._configuration.apiEndpoint}/building-budgets/${buildingBudget.buildingId}`, buildingBudget);
  }

  upsertBuildingBudgetsBulk(buildingBudgets: Array<BuildingBudget>): Observable<BuildingBudget> {
    return this._httpClient
      .post<BuildingBudget>(`${this._configuration.apiEndpoint}/building-budgets/bulk`, buildingBudgets);
  }
}
