import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './signature.model';

@Injectable()
export class SignatureRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getSignatureInfo(token: string): Observable<models.ExternalSignature> {
    const params = new HttpParams()
      .set('token', token);
    return this._httpClient.get<models.ExternalSignature>(`${this._configuration.apiEndpoint}/signatures`,
      {params});
  }

  sendSignature(token: string) {
    const params = new HttpParams()
      .set('token', token);
    return this._httpClient.post(`${this._configuration.apiEndpoint}/signatures`, null, {params});
  }
}
