import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { LoggerModule } from '@statera/sdk/logger';

import { WebsocketRepository } from './websocket.repository';

@NgModule({
  imports: [
    LoggerModule,
  ],
  providers: [
    WebsocketRepository,
  ],
})
export class WebsocketModule {
  static forChild(configuration: Configuration): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        WebsocketRepository,
      ],
    };
  }
}
