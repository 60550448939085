<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-12 mb-2 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options | filter : 'disabled'" [(value)]="leaseTerm.parkingType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Select appropriate option">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>

    <div class="row term-container"
         *ngIf="leaseTerm.parkingType === ParkingTermType.TenantRequested">
      <div class="col-sm-12">
        <dx-check-box id="carParking" name="car-parking" text="Car Parking"
                      [(value)]="carParkingRequired" (onValueChanged)="clearErrors()"></dx-check-box>
      </div>
      <div class="col-sm-6" *ngIf="carParkingRequired">
        <label for="unreservedCarParkingCount">Insert Number of Unreserved Car Spaces:</label>
        <dx-number-box appIgnoreScrollwheel id="unreservedCarParkingCount" name="unreserved-car-parking-count" min="0" max="999"
                       [(ngModel)]="leaseTerm.unreservedParkingStallsCount"
                       (ngModelChange)="clearErrors()"
                       #unreservedCarParkingCountInput="ngModel">
        </dx-number-box>
      </div>
      <div class="col-sm-6" *ngIf="carParkingRequired">
        <label for="reservedCarParkingCount">Insert Number of Reserved Car Spaces:</label>
        <dx-number-box appIgnoreScrollwheel id="reservedCarParkingCount" name="reserved-car-parking-count" min="0" max="999"
                       [(ngModel)]="leaseTerm.reservedParkingStallsCount"
                       (ngModelChange)="clearErrors()"
                       #reservedCarParkingCountInput="ngModel">
        </dx-number-box>
      </div>

      <div class="col-sm-12">
        <dx-check-box id="trailerParking" name="trailer-parking" text="Trailer Parking"
                      [(value)]="trailerParkingRequired" (onValueChanged)="clearErrors()"></dx-check-box>
      </div>
      <div class="col-sm-6" *ngIf="trailerParkingRequired">
        <label for="unreservedTrailerParkingCount">Insert Number of Unreserved Trailer Spaces:</label>
        <dx-number-box appIgnoreScrollwheel id="unreservedTrailerParkingCount" name="unreserved-trailer-parking-count" min="0" max="999"
                       [(ngModel)]="leaseTerm.unreservedTrailerDropsCount"
                       (ngModelChange)="clearErrors()"
                       #unreservedTrailerParkingCountInput="ngModel">
        </dx-number-box>
      </div>
      <div class="col-sm-6" *ngIf="trailerParkingRequired">
        <label for="reservedTrailerParkingCount">Insert Number of Reserved Trailer Spaces:</label>
        <dx-number-box appIgnoreScrollwheel id="reservedTrailerParkingCount" name="reserved-trailer-parking-count" min="0" max="999"
                       [(ngModel)]="leaseTerm.reservedTrailerDropsCount"
                       (ngModelChange)="clearErrors()"
                       #reservedTrailerParkingCountInput="ngModel">
        </dx-number-box>
      </div>
    </div>

    <div class="row term-container mb-4"
         *ngIf="leaseTerm.parkingType === ParkingTermType.Custom">
      <div class="col-8 pl-0">
        <app-term-custom-value-template-textarea placeholder="Insert custom parking clause"
                                                 required requiredMessage="Custom Parking clause is required"
                                                 [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                 [(value)]="leaseTerm.customDisplayValue"
                                                 (valueChange)="clearErrors()">
        </app-term-custom-value-template-textarea>
      </div>
    </div>

    <!-- Start: Validation summary -->
    <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
    </div>
    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
