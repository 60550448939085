import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule, I18nPluralPipe } from '@angular/common';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';

import { CommonTransformer } from './common.transformer';

@NgModule({
  imports: [
    AngularCommonModule,
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    PercentPipe,
    I18nPluralPipe,

    CommonTransformer,
  ],
})
export class CommonModule {
  static forChild(): ModuleWithProviders<CommonModule> {
    return {
      ngModule: CommonModule,
      providers: [
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        PercentPipe,
        I18nPluralPipe,

        CommonTransformer,
      ],
    };
  }
}
