import { Injectable } from '@angular/core';

import { Role } from '@statera/sdk/auth';

import { AuthService } from '../../auth/services/auth.service';

import * as models from '../../infrastructure/models/generated';

import { ProjectStatus } from '../../infrastructure/models/generated';
import {ProjectManager} from '@statera/sdk/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectAccessService {
  private readonly _authService: AuthService;
  private readonly _projectManager: ProjectManager;

  constructor(authService: AuthService, projectManager: ProjectManager) {
    this._authService = authService;
    this._projectManager = projectManager;
  }

  checkAccessToRenewalProject(
    renewalProjectTemplateItemType: models.RenewalProjectTemplateItemType,
    project: models.IProjectViewModel,
    lease: models.ILeaseViewModel
  ) {
    return this._projectManager
      .checkAccessToRenewalProject(renewalProjectTemplateItemType, project, lease, this._authService.userId, this._authService.role);
  }

  checkAccessToCertificateOfInsurance(
    insuranceCertificateProjectTemplateItemType: models.InsuranceCertificateProjectTemplateItemType,
    project: models.IProjectViewModel,
    lease: models.ILeaseViewModel
  ) {
    if (!insuranceCertificateProjectTemplateItemType || !project || !project.projectState) {
      return false;
    }

    if (project.projectStatus !== ProjectStatus.Active) {
      return false;
    }

    const role = this._projectManager.getTurnRole(lease, this._authService.userId, this._authService.role);
    if (!role) {
      return false;
    }

    if (this._authService.role === Role.Admin) {
      return true;
    }

    return this._checkAccessToCertificateByInsuranceByRole(role, insuranceCertificateProjectTemplateItemType, project.projectState);
  }

  hasAccessToCurrentProjectStageOrSubstage(
    project: models.IProjectViewModel,
    stages: Array<models.RenewalProjectTemplateItemType>,
    lease: models.ILeaseViewModel
  ): boolean {
    if (
      !project ||
      project.projectStatus !== models.ProjectStatus.Active ||
      !lease
    ) {
      return false;
    }

    const currentRoleTurn = this.getTurnRole(lease);

    if (
      !project.projectState ||
      !currentRoleTurn ||
      !project.projectState.writeAccessToTermsByRole ||
      project.projectState.writeAccessToTermsByRole !== currentRoleTurn
    ) {
      return false;
    }

    let currentStage = project.projectState;
    if (currentStage.parentProjectTemplateItem) {
      currentStage = currentStage.parentProjectTemplateItem;
    }

    return stages
      .some(x =>
        x === project.projectState.renewalProjectTemplateItemType ||
        (
          currentStage &&
          x === currentStage.renewalProjectTemplateItemType
        )
      );
  }

  getTurnRole(lease: models.ILeaseViewModel): string {
    if (this._authService.role === Role.Broker && lease && lease.leaseUsers) {
      const userId = this._authService.userId;
      const leaseUser = lease.leaseUsers.find(x => x.userId === userId);
      if (!leaseUser) {
        return null;
      }
      let role = null;

      if (leaseUser.relatedToCompanyId === lease.landlordCompanyId) {
        role = Role.Landlord;
      }

      if (leaseUser.relatedToCompanyId === lease.tenantCompanyId) {
        role = Role.Tenant;
      }

      return role;
    }

    return this._authService.role;
  }

  private _checkAccessToCertificateByInsuranceByRole(
    role: string,
    insuranceCertificateProjectTemplateItemType: models.InsuranceCertificateProjectTemplateItemType,
    currentProjectState: models.IProjectTemplateItemViewModel
  ): boolean {
    if (currentProjectState &&
      insuranceCertificateProjectTemplateItemType === currentProjectState.insuranceCertificateProjectTemplateItemType) {
      return role === currentProjectState.writeAccessToTermsByRole;
    }

    if (
      currentProjectState && currentProjectState.parentProjectTemplateItem &&
      currentProjectState.parentProjectTemplateItem.insuranceCertificateProjectTemplateItemType
      === insuranceCertificateProjectTemplateItemType
    ) {
      return role === currentProjectState.writeAccessToTermsByRole;
    }

    return false;
  }
}
