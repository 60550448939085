import { Component, Input, OnInit } from '@angular/core';
import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-lease-term-custom-table',
  templateUrl: './lease-term-custom-table.component.html',
  styleUrls: ['./lease-term-custom-table.component.scss']
})
export class LeaseTermCustomTableComponent implements OnInit {

  @Input() leaseTermCustomTable: models.ILeaseTermCustomTableViewModel;
  @Input() leaseTeam: models.LeaseTeam;
  @Input() isAccepted: boolean;
  @Input() isLineThrough: boolean;
  LeaseTeam = models.LeaseTeam;
  constructor() {
    this.leaseTermCustomTable = this.leaseTermCustomTable || <models.ILeaseTermCustomTableViewModel> {};
  }

  ngOnInit() {
  }

}
