import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/common';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';
import { TermUseManager } from '@statera/sdk/term-use';

import * as models from '../../../../../infrastructure/models/generated';
import { SpaceUseOption } from '@statera/sdk/term-use/src/term-use.model';

import { BaseTermDirective } from '../base-term.directive';

enum AdditionalInfo {
  Default = 0,
  Custom = 1,
}

const DEFAULT_ADDITIONAL_INFO_TEXT =
  'Tenant will comply with all laws, ordinances, rules and regulations of all ' +
  'government authorities pertaining to the use and occupancy of the Leased Premises.';

@Component({
  selector: 'app-space-use',
  templateUrl: './space-use.component.html',
  styleUrls: ['./space-use.component.scss']
})
export class SpaceUseComponent extends BaseTermDirective<models.ISpaceUseTermViewModel> implements OnInit {
  @ViewChild(NgForm) form: NgForm;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  protected termUseManager: TermUseManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  spaceUseTypes: typeof models.SpaceUseTermType = models.SpaceUseTermType;

  AdditionalInfo: typeof AdditionalInfo = AdditionalInfo;

  additionalInfoOptions: Array<any> = [
    {
      name: DEFAULT_ADDITIONAL_INFO_TEXT,
      value: AdditionalInfo.Default,
    },
    {
      name: 'Custom',
      value: AdditionalInfo.Custom,
    }
  ];
  additionalInfoOption: any;

  private _originalAdditionalInfo?: string;
  private readonly _destroy$: Subject<void>;

  options: any;

  spaceUseOptions: Array<SpaceUseOption>;
  spaceUseItems = [];
  spaceUseProduct = null;
  isNoneOptionDisabled = false;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,

    termUseManager: TermUseManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;
    this.termUseManager = termUseManager;

    this.spaceUseOptions = this.termUseManager.getValues();

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this.options = this.termUseManager.getOptions(this.project).map(x => ({...x, disabled: false}));

    this._initializePage();

    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this._initializePage();
          }),
        )
        .subscribe();
    }
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.form || this.form.invalid) {
      return;
    }

    const term = this.lease.spaceUseTerm || <models.ISpaceUseTermViewModel>{};

    if (this.options && this.options.length) {
      const selectedOption = this.options.find(x => x.value === term.spaceUseType);
      if (selectedOption && selectedOption.disabled) {
        this.addError('You can\'t select this option.');
        return;
      }
    }

    if (this.isLandlordUser() && term.spaceUseType === models.SpaceUseTermType.Custom) {
      term.hasAdditionalInfo = true;
    }

    if (this.spaceUseItems) {
      const items = this.spaceUseItems
        .filter(x => x.id !== 'custom')
        .map(x => x.id);

      let lastCustomItemIdx = -1;
      for (let i = this.spaceUseItems.length - 1; i >= 0; i--) {
        if (this.spaceUseItems[i].id === 'custom') {
          lastCustomItemIdx = i;
        }
      }

      if (lastCustomItemIdx !== -1) {
        items.push('custom');
        term.customUseValue = this.spaceUseItems[lastCustomItemIdx].text.trim();
      } else {
        term.customUseValue = null;
      }

      term.value = items.length ? items.join('|') : null;
      term.product = this.spaceUseProduct;
      this.lease.spaceUseTerm = term;

      super.saveLeaseTerm(this.leaseTerm);
      $event.preventDefault();

      return;
    }

    if (term.spaceUseType !== models.SpaceUseTermType.PerCurrentLease) {
      term.spaceUseType = null;
    }

    term.value = null;
    term.customUseValue = null;
    term.product = null;
    term.customDisplayValue = null;
    this.spaceUseProduct = null;
    this.lease.spaceUseTerm = term;

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  addCustomUse($event: any) {
    const text = $event.text.trim();

    $event.customItem = <SpaceUseOption>{
      id: 'custom',
      text: text,
      hasProduct: true,
    };
  }

  isProductFieldAvailable(): boolean {
    return this.spaceUseItems && this.spaceUseItems.some(x => x.hasProduct);
  }

  handleAdditionalInfoOptionChange($event) {
    if ($event.value === AdditionalInfo.Default) {
      if (!this._originalAdditionalInfo && this.leaseTerm.additionalInfo !== DEFAULT_ADDITIONAL_INFO_TEXT) {
        this._originalAdditionalInfo = this.leaseTerm.additionalInfo;
      }

      this.leaseTerm.additionalInfo = DEFAULT_ADDITIONAL_INFO_TEXT;
      return;
    }

    if ($event.value === AdditionalInfo.Custom && this._originalAdditionalInfo) {
      this.leaseTerm.additionalInfo = this._originalAdditionalInfo;
      return;
    }

    this.leaseTerm.additionalInfo = '';
  }

  private _initializePage() {
    if (this.leaseTerm?.value) {
      const values = this.leaseTerm.value.split('|');

      this.spaceUseProduct = this.leaseTerm.product;
      this.spaceUseItems = values
        .filter(x => x !== 'custom')
        .map(x => this.spaceUseOptions.find(o => o.id === x));

      if (values.includes('custom')) {
        this.spaceUseItems.push(<SpaceUseOption>{
          id: 'custom',
          text: this.leaseTerm.customUseValue,
          hasProduct: true,
        });
      }

      this.additionalInfoOption = this.leaseTerm.additionalInfo === DEFAULT_ADDITIONAL_INFO_TEXT
        ? AdditionalInfo.Default
        : AdditionalInfo.Custom;
    }

    if (this._authService.role !== Role.Landlord) {
      const noneOptionIndex = this.options.findIndex(x => x.value === models.SpaceUseTermType.None);
      if (noneOptionIndex !== -1) {
        this.options.splice(noneOptionIndex, 1);
      }
    }

    if (this._authService.role === Role.Landlord && !this.isFirstOffer) {
      const perCurrentLeaseOptionIndex = this.options.findIndex(x => x.value === models.SpaceUseTermType.PerCurrentLease);
      if (perCurrentLeaseOptionIndex !== -1) {
        this.options.splice(perCurrentLeaseOptionIndex, 1);
      }
    }

    if (
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.SendRfp, this.project, this.lease) &&
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord, this.project, this.lease) &&
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord, this.project, this.lease) &&
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReceiveAndAnalyzeResponse, this.project, this.lease)
    ) {
      const noneOption = this.options.find(x => x.value === models.SpaceUseTermType.None);
      if (noneOption) {
        noneOption.disabled = true;
      }
      this.isNoneOptionDisabled = true;
    }

    if (!this.lease.abstractLeaseId) {
      const perCurrentLeaseOption = this.options.find(x => x.value === models.SpaceUseTermType.PerCurrentLease);
      if (perCurrentLeaseOption) {
        perCurrentLeaseOption.disabled = true;
      }
    }

    if (
      this.isLandlordUser() &&
      this.leaseTerm.spaceUseType === models.SpaceUseTermType.None &&
      this.leaseTerm.customClauseText
    ) {
      this.leaseTerm.spaceUseType = models.SpaceUseTermType.Custom;
    }
  }

  isLandlordUser(): boolean {
    return this._authService.startupInfo.role === Role.Landlord;
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.spaceUseType === models.SpaceUseTermType.None
    ) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isFirstRadioGroupHidden(): boolean {
    if (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord, this.project, this.lease)
      || this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReceiveAndAnalyzeResponse, this.project, this.lease)
    ) {
      return false;
    }
    return this.isLandlordUser() && this.isNoneOptionDisabled;
  }
}
