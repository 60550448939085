import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { LandlordDashboardSortBy } from '../../../infrastructure/models/generated';

import * as models from '../../../infrastructure/models/generated';

export interface Application {
  ui: {
    selectedPortfolioId: number;
    landlordDashboardFilters: models.ILandlordDashboardFilterViewModel;
    filterDisabled: boolean;
  };
  configuration: {
    googleAnalyticsTrackingId: string;
    calendlySettingsViewModel: {
      url: string;
      text: string;
      color: string;
      textColor: string;
      branding: boolean;
    },
    intercomSettingsViewModel: {
      apiBase: string;
      appId: string;
    }
  };
}

const initialState = {
  ui: {
    selectedPortfolioId: null,
    landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
      pageSize: 8,
      landlordDashboardSortBy: LandlordDashboardSortBy.NewestToOldest,
      page: 1,
      leaseExpirationFilters: [],
      assetFilter: null,
      searchText: '',
      dealsFilter: null,
      fundFilters: []
    },
    filterDisabled: false,
  },
  configuration: {},
};

export interface ApplicationState extends EntityState<Application> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'application'})
export class ApplicationStore extends EntityStore<ApplicationState> {

  constructor() {
    super(initialState);
  }

  updateSelectedPortfolio(portfolio: models.IPortfolioViewModel) {
    if (portfolio) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            selectedPortfolioId: portfolio.id,
          },
        };
      });
    }
  }

  updateLandlordDashboardAssetFilter(assetFilters: models.IAssetFilter) {
    if (assetFilters) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
              ...currentValue.ui.landlordDashboardFilters,
              assetFilter: {
                ...assetFilters,
              },
            },
          },
        };
      });
    }
  }

  updateLandlordDashboardDealsFilter(dealsFilter: models.IDealsFilter) {
    if (dealsFilter) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
              ...currentValue.ui.landlordDashboardFilters,
              dealsFilter: {
                ...dealsFilter,
              },
            },
          },
        };
      });
    }
  }

  updateLeaseExpirationFilters(leaseExpirationFilters: Array<models.ILeaseExpirationFilter>) {
    if (leaseExpirationFilters) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
              ...currentValue.ui.landlordDashboardFilters,
              leaseExpirationFilters: leaseExpirationFilters,
            },
          },
        };
      });
    }
  }

  updateFundsFilters(fundFilters: Array<models.IFundFilter>) {
    if (fundFilters) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
              ...currentValue.ui.landlordDashboardFilters,
              fundFilters: fundFilters,
            },
          },
        };
      });
    }
  }

  updateLandlordDashboardPaginationOptions(page: number, pageSize: number) {
    if (page && pageSize) {
      this.update(currentValue => {
        return {
          ...currentValue,
          ui: {
            ...currentValue.ui,
            landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
              ...currentValue.ui.landlordDashboardFilters,
              page: page,
              pageSize: pageSize,
            },
          },
        };
      });
    }
  }

  updateLandlordDashboardSearchText(searchText: string) {
    this.update(currentValue => {
      return {
        ...currentValue,
        ui: {
          ...currentValue.ui,
          landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
            ...currentValue.ui.landlordDashboardFilters,
            searchText: searchText,
          },
        },
      };
    });
  }

  updateLandlordDashboardSortBy(sortBy: models.LandlordDashboardSortBy) {
    this.update(currentValue => {
      return {
        ...currentValue,
        ui: {
          ...currentValue.ui,
          landlordDashboardFilters: <models.ILandlordDashboardFilterViewModel>{
            ...currentValue.ui.landlordDashboardFilters,
            landlordDashboardSortBy: sortBy,
          },
        },
      };
    });
  }

  updateConfiguration(configuration: models.IConfigurationViewModel) {
    if (configuration) {
      this.update(currentValue => {
        return {
          ...currentValue,
          configuration: {
            googleAnalyticsTrackingId: configuration.googleAnalyticsTrackingId,
            calendlySettingsViewModel: configuration.calendlySettingsViewModel == null ? null : <models.ICalendlySettingsViewModel>{
              url: configuration.calendlySettingsViewModel.url,
              text: configuration.calendlySettingsViewModel.text,
              color: configuration.calendlySettingsViewModel.color,
              textColor: configuration.calendlySettingsViewModel.textColor,
              branding: configuration.calendlySettingsViewModel.branding,
            },
            intercomSettingsViewModel: configuration.intercomSettingsViewModel == null ? null : <models.IIntercomSettingsViewModel>{
              apiBase: configuration.intercomSettingsViewModel.apiBase,
              appId: configuration.intercomSettingsViewModel.appId,
            }
          },
        };
      });
    }
  }

  disableLandlordDashboardFilter(disabled: boolean) {
    this.update(currentValue => {
      return {
        ...currentValue,
        ui: {
          ...currentValue.ui,
          filterDisabled: disabled,
        },
      };
    });
  }
}

