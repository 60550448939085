<form name="form"
      (submit)="saveLeaseTerm($event)"
      *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-11 mb-4 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.expansionOptionTermType" (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div *ngIf="leaseTerm.expansionOptionTermType === ExpansionOptionTermType.Custom"
         class="col-8 pl-0 mt-n4 mb-4">
      <app-term-custom-value-template-textarea placeholder="Insert custom expansion option clause"
                                               required requiredMessage="Custom Expansion Option clause is required"
                                               [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                               [(value)]="leaseTerm.expansionOption"
                                               (valueChange)="clearErrors()">
      </app-term-custom-value-template-textarea>
    </div>

    <!-- Start: Validation summary -->

      <div class="validation-summary">
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>

    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>


