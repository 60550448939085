<section class="stepper">
  <div class="text-aling-center d-flex justify-content-between">
    <div class="d-flex flex-column align-items-center step-width"
         *ngFor="let step of steps; let i = index;"
         [ngClass]="{ 'active': step.id <= activeStepId }">
      <div
        class="step d-flex flex-column"
        [ngClass]="{ 'step-bg': step.id <= activeStepId }"
        id="{{ 'point' + i }}"
        (mouseenter)="toggleTooltip(i)"
        (mouseleave)="toggleTooltip(i)"
      >
        <span>
          {{ i + 1 }}
        </span>

        <dx-tooltip *ngIf="showTooltips" [target]="'#point' + i" [(visible)]="tooltipVisible[i]" width="300"
                    [elementAttr]="{style: 'z-index: 10' }">
          <div *dxTemplate="let data = data; of: 'content'">
            <p>{{ steps[i].description }}</p>
            <p>{{ getMilestoneInterval(i) }}</p>
          </div>
        </dx-tooltip>

      </div>
      <p class="step-title" [ngClass]="{ 'active-step': step.id <= activeStepId }">
        {{ step.name }}
      </p>

    </div>
  </div>
</section>
