import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { IExpirationsReportPeriodViewModel } from '../../../infrastructure/models/generated';

export interface IExpirationsReportSinglePeriodDialogViewModel {
  periods: Array<IExpirationsReportPeriodViewModel>;
  initialPeriodId: number;
}

@Component({
  templateUrl: 'expirations-report-single-period-dialog.component.html',
  styleUrls: ['expirations-report-single-period-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationsReportSinglePeriodDialogComponent implements OnInit {

  get activePeriodId() {
    return this._activePeriodId;
  }

  set activePeriodId(value) {
    this._activePeriodId = value;
  }

  constructor(dialogRefService: DialogRefService, changeDetectorRef: ChangeDetectorRef) {
    this._dialogRefService = dialogRefService;
    this._changeDetectorRef = changeDetectorRef;
  }

  readonly expirationsReportSinglePeriodDialogViewModel: IExpirationsReportSinglePeriodDialogViewModel;

  private readonly _dialogRefService: DialogRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private _activePeriodId = 0;
  static prepareTitle(date: Date | string) {
    return `Expirations ${moment(date).format('YYYY')}`;
  }

  ngOnInit(): void {
    if (this.expirationsReportSinglePeriodDialogViewModel) {
      this.activePeriodId = this.expirationsReportSinglePeriodDialogViewModel.initialPeriodId;
    }
  }

  goToNextPeriod() {
    const vm = this.expirationsReportSinglePeriodDialogViewModel;
    const possibleNewId = this.activePeriodId + 1;

    let newId = possibleNewId;
    if (possibleNewId >= vm.periods.length) {
      newId = 0;
    }
    this.activePeriodId = newId;

    const title = ExpirationsReportSinglePeriodDialogComponent.prepareTitle(vm.periods[newId].startDate);
    this._dialogRefService.setTitle(title);

    this._changeDetectorRef.markForCheck();
  }

  goToPreviousPeriod() {
    const vm = this.expirationsReportSinglePeriodDialogViewModel;
    const possibleNewId = this.activePeriodId - 1;

    let newId = possibleNewId;
    if (possibleNewId < 0) {
      newId = vm.periods.length - 1;
    }
    this.activePeriodId = newId;

    const title = ExpirationsReportSinglePeriodDialogComponent.prepareTitle(vm.periods[newId].startDate);
    this._dialogRefService.setTitle(title);

    this._changeDetectorRef.markForCheck();
  }

}
