<span class="indicator"
      [class.indicator-up]="valueDirection === ValueDirection.Up && showColorDirection"
      [class.indicator-down]="valueDirection === ValueDirection.Down && showColorDirection"
>
  <i class="fa"
     [class.fa-arrow-up]="valueDirection === ValueDirection.Up"
     [class.fa-arrow-down]="valueDirection === ValueDirection.Down"
  >
  </i>
  <ng-container *ngIf="valueDifference">
    {{abs(valueDifference) | safeNumber: numberFormat}}
  </ng-container>

  <ng-container *ngIf="valueDifferenceInPercent">
    {{abs(valueDifferenceInPercent) | safePercent}}
  </ng-container>

  <ng-container *ngIf="valueDifferenceInMoney">
    {{abs(valueDifferenceInMoney) | safeCurrency}}
  </ng-container>

  <span class="indicator-unch"
        *ngIf="valueDirection === ValueDirection.Unch">
    UNCH
  </span>
</span>


