import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './building-unit.model';

@Injectable()
export class BuildingUnitRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  updateBuildingUnit(buildingUnit: models.BuildingUnitViewModel): Observable<void> {
    return this._httpClient
      .put<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnit.id}`,
        buildingUnit
      );
  }

  createBuildingUnitFloorPlan(buildingUnitId: number, floorPlanImage: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', floorPlanImage, floorPlanImage.name);
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/floor-plan`,
        formData,
      );
  }

  deleteBuildingUnitFloorPlan(buildingUnitId: number, floorPlanId: number): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/floor-plan/${floorPlanId}`
      );
  }

  createBuildingUnitPicture(buildingUnitId: number, picture: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', picture, picture.name);
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/picture`,
        formData,
      );
  }

  createBuildingUnitVideo(buildingUnitId: number, video: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', video, video.name);
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/video`,
        formData,
      );
  }

  createBuildingUnitLink(buildingUnitId: number, url: string): Observable<void> {
    const params = new HttpParams()
      .set('url', url);
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/link`,
        {},
        { params }
      );
  }

  deleteBuildingUnitAttachment(buildingUnitId: number, attachmentId: number): Observable<void> {
    return this._httpClient
        .delete<void>(
            `${this._configuration.apiEndpoint}/building-unit/${buildingUnitId}/attachment/${attachmentId}`
        );
  }
}
