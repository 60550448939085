import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BuildingBudgetRepository} from './building-budget.repository';
import {BuildingBudget, BuildingBudgetVersion} from './building-budget.model';

@Injectable()
export class BuildingBudgetManager {
  private readonly _buildingBudgetRepository: BuildingBudgetRepository;

  constructor(buildingBudgetRepository: BuildingBudgetRepository) {
    this._buildingBudgetRepository = buildingBudgetRepository;
  }

  getBuildingBudgets(buildingId: number): Observable<Array<BuildingBudgetVersion>> {
    return this._buildingBudgetRepository.getBuildingBudgets(buildingId);
  }

  upsertBuildingBudget(buildingBudget: BuildingBudget): Observable<BuildingBudget> {
    return this._buildingBudgetRepository.upsertBuildingBudget(buildingBudget);
  }

  upsertBuildingBudgetsBulk(buildingBudgets: Array<BuildingBudget>): Observable<BuildingBudget> {
    return this._buildingBudgetRepository.upsertBuildingBudgetsBulk(buildingBudgets);
  }

  getAverageMarketRateGrowth(buildingBudget: BuildingBudget): number {
    const sum = buildingBudget.marketRateGrowths.reduce((a, b) => a + b.value, 0);
    return sum / buildingBudget.marketRateGrowths.length;
  }
}
