import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IChatMessage } from '../models/generated';

import { environment } from '../../../environments/environment';

@Injectable()
export class ChatService {
  constructor(private httpClient: HttpClient) {
  }

  updateMessage(message: IChatMessage): Observable<IChatMessage> {
    return this.httpClient.post<IChatMessage>(`${environment.webApiUrl}/chat/UpdateMessage`, message);
  }

  deleteMessage(message: IChatMessage) {
    return this.httpClient.post(`${environment.webApiUrl}/chat/DeleteMessage`, message);
  }
}
