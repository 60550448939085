import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './analytics.model';

@Injectable()
export class AnalyticsRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getAnalyticsFilters(): Observable<models.AnalyticsFilters> {
    return this._httpClient
      .get<models.AnalyticsFilters>(
        `${this._configuration.apiEndpoint}/analytics/filters`,
      );
  }

  getKeyStatisticsReport(filters: models.AnalyticsFilters): Observable<models.KeyStatisticsReport> {
    let params = new HttpParams();

    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.KeyStatisticsReport>(
        `${this._configuration.apiEndpoint}/analytics/key-statistics-report`,
        {
          params: params,
        },
      );
  }

  getExpirationsReport(
    periods: Array<{startDate: string, endDate: string}>,
    groupingOption: models.ExpirationsReportGroupingOption,
    filters: models.AnalyticsFilters,
  ): Observable<models.ExpirationsReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('grouping-option', groupingOption.toString(10));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.ExpirationsReport>(
        `${this._configuration.apiEndpoint}/analytics/expirations-report`,
        {
          params: params,
        },
      );
  }

  getOccupancyAndVacancyReport(
    periods: Array<{startDate: string, endDate: string}>,
    groupingOption: models.OccupancyAndVacancyReportGroupingOption,
    filters: models.AnalyticsFilters,
  ): Observable<models.OccupancyAndVacancyReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('grouping-option', groupingOption.toString(10));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.OccupancyAndVacancyReport>(
        `${this._configuration.apiEndpoint}/analytics/occupancy-and-vacancy-report`,
        {
          params: params,
        },
      );
  }

  getDealsByStageReport(
    period: {startDate: string, endDate: string},
    dealType: models.DealsByStageReportDealType,
    filters: models.AnalyticsFilters,
  ): Observable<models.DealsByStageReport> {
    let params = new HttpParams();

    params = params.set('period', JSON.stringify(period));
    params = params.set('deal-type', dealType.toString(10));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.DealsByStageReport>(
        `${this._configuration.apiEndpoint}/analytics/deals-by-stage-report`,
        {
          params: params,
        },
      );
  }

  getActiveDealsByStageReport(
    filters: models.AnalyticsFilters,
  ): Observable<models.ActiveDealsByStageReport> {
    let params = new HttpParams();

    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.ActiveDealsByStageReport>(
        `${this._configuration.apiEndpoint}/analytics/active-deals-by-stage-report`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsKeyStatisticsReport(filters: models.AnalyticsFilters): Observable<models.InquiryInsightsKeyStatisticsReport> {
    let params = new HttpParams();

    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsKeyStatisticsReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/key-statistics-report`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsInquiryDynamicsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiryDynamicsReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsInquiryDynamicsReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/inquiry-dynamics`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsInquiriesSubmittedByReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesSubmittedByReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsInquiriesSubmittedByReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/inquiries-submitted-by`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsInquiriesActivityReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesActivityReport> {
    let params = new HttpParams();

    params = params.set('period', JSON.stringify(period));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsInquiriesActivityReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/inquiries-activity`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsAverageSizeRequestedReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsAverageSizeRequestedReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsAverageSizeRequestedReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/average-size-requested`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsInquiriesWithToursConvertedToDealReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesWithToursConvertedToDealReport> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsInquiriesWithToursConvertedToDealReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/inquiries-with-tours-converted-to-deal`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsInquiryDeleteTopReasonsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiryDeleteTopReasonsReport> {
    let params = new HttpParams();

    params = params.set('period', JSON.stringify(period));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsInquiryDeleteTopReasonsReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/inquiry-delete-top-reasons`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsTopBuildingsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsTopBuildingsReport> {
    let params = new HttpParams();

    params = params.set('period', JSON.stringify(period));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsTopBuildingsReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/top-buildings`,
        {
          params: params,
        },
      );
  }

  getInquiryInsightsTopLocationsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsTopLocationsReport> {
    let params = new HttpParams();

    params = params.set('period', JSON.stringify(period));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.InquiryInsightsTopLocationsReport>(
        `${this._configuration.apiEndpoint}/analytics/inquiry-insights/top-locations`,
        {
          params: params,
        },
      );
  }

  getListingSharesAndViewsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.ListingSharesAnsViewsReportViewModel> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.ListingSharesAnsViewsReportViewModel>(
        `${this._configuration.apiEndpoint}/analytics/marketing-analytics/listing-shares-views-report`,
        {
          params: params,
        },
      );
  }

  getListingViewsEngagementReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.ListingViewsEngagementReportViewModel> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.ListingViewsEngagementReportViewModel>(
        `${this._configuration.apiEndpoint}/analytics/marketing-analytics/listing-views-engagement-report`,
        {
          params: params,
        },
      );
  }

  getEngagementWithEmailsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.EngagementWithEmailsViewModel> {
    let params = new HttpParams();

    params = params.set('periods', JSON.stringify(periods));
    params = params.set('filters', JSON.stringify(filters));

    return this._httpClient
      .get<models.EngagementWithEmailsViewModel>(
        `${this._configuration.apiEndpoint}/analytics/marketing-analytics/engagement-with-emails-report`,
        {
          params: params,
        },
      );
  }
}
