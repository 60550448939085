import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Configuration } from '@statera/sdk/common';

import { BuildingUnitRepository } from './building-unit.repository';
import { BuildingUnitManager } from './building-unit.manager';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    BuildingUnitRepository,
    BuildingUnitManager,
  ],
})
export class BuildingUnitModule {
  static forChild(configuration: Configuration): ModuleWithProviders<BuildingUnitModule> {
    return {
      ngModule: BuildingUnitModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        BuildingUnitRepository,
        BuildingUnitManager,
      ],
    };
  }
}
