import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { TermHistoryManager } from './term-history.manager';
import { TermHistoryRepository } from './term-history.repository';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    TermHistoryRepository,
    TermHistoryManager,
  ],
})
export class TermHistoryModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TermHistoryModule> {
    return {
      ngModule: TermHistoryModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TermHistoryRepository,
        TermHistoryManager
      ],
    };
  }
}
