import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxButtonModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';

import { InvitationRouter } from './invitation.router';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { AcceptInvitationComponent } from './components/accept-invitation/accept-invitation.component';
import { InviteBrokerByLandlordDialogComponent } from './components/invite-broker-by-landlord-dialog/invite-broker-by-landlord-dialog.component';
import { InviteBrokerByTenantDialogComponent } from './components/invite-broker-by-tenant-dialog/invite-broker-by-tenant-dialog.component';

import { AcceptInvitationGuard } from './guards/accept-invitation.guard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    InvitationRouter,
    InfrastructureModule,

    DxButtonModule,
    DxTextBoxModule,
    DxScrollViewModule,
    DxSelectBoxModule,
  ],
  declarations: [
    AcceptInvitationComponent,
    InviteBrokerByLandlordDialogComponent,
    InviteBrokerByTenantDialogComponent,
  ],
  providers: [
    AcceptInvitationGuard,
  ],
  entryComponents: [
    InviteBrokerByLandlordDialogComponent,
    InviteBrokerByTenantDialogComponent,
  ],
})
export class InvitationModule {
  static forRoot(): ModuleWithProviders<InvitationModule> {
    return {
      ngModule: InvitationModule,
      providers: [],
    };
  }
}
