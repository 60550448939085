import { Injectable } from '@angular/core';
import {ProfileRepository} from './profile.repository';
import * as models from './profile.model';

@Injectable()
export class ProfileManager {
  private readonly _profileRepository: ProfileRepository;

  constructor(profileRepository: ProfileRepository) {
    this._profileRepository = profileRepository;
  }

  skipIntro(introType: models.IntroType) {
    return this._profileRepository.skipIntro(introType);
  }

  resetIntro(introType: models.IntroType) {
    return this._profileRepository.resetIntro(introType);
  }
}
