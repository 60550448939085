import { BuildingAreaType, IBuildingUnitViewModel, IPlanAnchorCommentViewModel } from '../../infrastructure/models/generated';

import { ImageViewerImage, ImageViewerImageMarkerRef } from '../../image-viewer/models/image-viewer-image.model';
import {PlanAnchorStatus} from '@statera/sdk/common';

export enum PlanViewerMode {
  Unit = 0,
  Marker = 1,
}

export class PlanViewerImage {
  id: number;
  imageUrl: string;
  angle: number;
  markers?: Array<PlanViewerMarkerRef>;
}

export enum PlanViewerMarkerType {
  Circle = 0,
  Rect = 1,
  Cross = 2,
  Polygon = 3,
}

export enum PlanViewerMarkerColor {
  Blue = 0,
  Gray = 1,
  White = 2,
  Green = 3,
  Red = 4,
}

export interface PlanViewerMarkerTypeAttributes {
  type: PlanViewerMarkerType;
}

export interface PlanViewerMarkerSizeAttributes {
  width: number;
  height: number;
}

export interface PlanViewerMarkerPositionAttributes {
  x: number;
  y: number;
}

export interface PlanViewerMarkerPathAttributes {
  points: Array<number>;
  closed: boolean;
}

export interface PlanViewerMarkerColorAttributes {
  color: PlanViewerMarkerColor;
}

export type PlanViewerMarkerAttributes =
  {draggable: boolean; hasInfoIcon?: boolean}
  & PlanViewerMarkerTypeAttributes
  & PlanViewerMarkerSizeAttributes
  & PlanViewerMarkerPositionAttributes
  & PlanViewerMarkerPathAttributes
  & PlanViewerMarkerColorAttributes;

export type PlanViewerMarkerBoundingBox =
  {centerX: number, centerY: number}
  & PlanViewerMarkerSizeAttributes
  & PlanViewerMarkerPositionAttributes;

export class PlanViewerMarkerRef {
  id: number;
  nodeId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  points: Array<number>;
  title: string;
  isOwnSpace?: boolean;
  isAvailableSpace?: boolean;
  buildingUnitId?: number;
  buildingUnit?: IBuildingUnitViewModel;
  area: BuildingAreaType;
  description: string;
  type: PlanViewerMarkerType;
  color: PlanViewerMarkerColor;
  images: Array<PlanViewerMarkerImageRef>;
  hideInfoIcon: boolean;
  amount: number;
  comments: Array<IPlanAnchorCommentViewModel>;
  index: number;
  editable: boolean;
  canChangeStatus: boolean;
  status: PlanAnchorStatus;
  canChangeAmount: boolean;
}

export class PlanViewerMarkerImageRef {
  file?: File;
  imageViewerImage?: ImageViewerImage;
}

export class PlanViewerImageRef {
  id: number;
  imageElement: HTMLImageElement;
  width: number;
  height: number;
  angle: number;
  imageDataUrl: string;
  markers: Array<PlanViewerMarkerRef>;
}

export class PlanViewerImageMarkerRef {
  markerRef: PlanViewerMarkerRef;
  imageMarkerRef: ImageViewerImageMarkerRef;
}

export class PlanAnchorComment {
  id: number;
  content: string;
  createdOn: Date;
  anchorId: number;
}

export class PlanAnchorCost {
  amount: number;
  anchorId: number;
}
