import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertNotificationKind, AlertType, ProjectType } from '@statera/sdk/alert';

import { AlertService } from '../../services/alert.service';

const HUMAN_LEASE_TERMS: {} = {
  'ExpansionOption': 'Expansion Option',
  'Expiration': 'Expiration',
  'Commencement': 'Commencement',
  'Term': 'Lease Term',
  'TenantSquareFootage': 'Size',
  'FreeRent': 'Free Rent',
  'RentalRate': 'Base Rental Rate',
  'SecurityDeposit': 'Security Deposit',
  'RealEstateTaxesCamExpenses': 'Real Estate Taxes & CAM Expenses',
  'RentalRateAnnualEscalation': 'Rental Rate Escalation',
  'TenantImprovements': 'Tenant Improvements',
  'LandlordMaintenance': 'Landlord Maintenance',
  'TenantMaintenance': 'Tenant Maintenance',
  'SelfHelp': 'Self-Help',
  'Assignment': 'Assignment',
  'RenewalOption': 'Renewal Option',
  'EstoppelCertificate': 'Estoppel Certificate',
  'TerminationOption': 'Termination Option',
  'Hvac': 'HVAC',
  'Insurance': 'Insurance',
  'HoldoverProvision': 'Holdover Provision',
  'Parking': 'parking',
  'Utilities': 'Utilities',
  'SpaceUse': 'Use',
  'CodeCompliance': 'Code Compliance',
  'ConditionOfPremises': 'Condition of Premises',
  'Signage': 'Signage',
  'Rail': 'Rail',
  'NonDisturbance': 'Non-Disturbance',
  'HazardousMaterials': 'Environmental/Hazardous Materials',
  'ForceMajeure': 'Force Majeure'
};

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  private static readonly _autocloseTimeout: number = 10_000;

  @Input() alert: AlertNotification;

  AlertType: typeof AlertType = AlertType;
  AlertNotificationKind: typeof AlertNotificationKind = AlertNotificationKind;

  isButtonsDisabled: boolean;

  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _destroy: Subject<void>;

  constructor(router: Router, alertService: AlertService) {
    this._router = router;
    this._alertService = alertService;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.alert.reference.show();

    if (this.alert.autoclose) {
      setTimeout(() => this.hide(), this.alert.autocloseTimeout || AlertComponent._autocloseTimeout);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getAlertClassName(): string {
    if (!this.alert || !this.alert.kind) {
      return null;
    }

    switch (this.alert.kind) {
      case AlertNotificationKind.Success:
        return 'alert-success';

      case AlertNotificationKind.Info:
        return 'alert-info';

      case AlertNotificationKind.Warning:
        return 'alert-warning';

      case AlertNotificationKind.Error:
        return 'alert-error';

      case AlertNotificationKind.Confirm:
        return 'alert-confirm';
    }

    return null;
  }

  getProjectTypeName(): string {
    switch (this.alert.projectType) {
      case ProjectType.NewDeal:
        return 'New deal';
      case ProjectType.NewDealInquiry:
        return 'Inquiry';
      case ProjectType.Restructure:
        return 'Restructure';
      default:
        return 'Renewal';
    }
  }

  confirm(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.isButtonsDisabled = this.alert.shouldDisableButtons;

    this.alert
      .reference
      .confirm()
      .pipe(
        tap(() => {
          if (!this.alert.closeOnButtonClick) {
            return;
          }

          this.isButtonsDisabled = false;

          this.hide();
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  decline(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.isButtonsDisabled = this.alert.shouldDisableButtons;

    this.alert
      .reference
      .decline()
      .pipe(
        tap(() => {
          if (!this.alert.closeOnButtonClick) {
            return;
          }

          this.isButtonsDisabled = false;

          this.hide();
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  hide(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.alert.reference.hide();
  }

  navigate(): void {
    if (!this.alert || !this.alert.navigation) {
      return;
    }

    const { navigation } = this.alert;

    this.alert.reference.hide();

    if (navigation.queryParams && navigation.isRefresh) {
      this._alertService
        .refreshColabo
        .next({
          leaseId: navigation.queryParams.leaseId,
          termName: navigation.queryParams.termName,
        });

      return;
    }

    const navigationExtras = <NavigationExtras>{
      queryParams: navigation.queryParams,
    };

    this._router
      .navigate([navigation.path], navigationExtras);
  }

  isMessageContainsHTMLTags(message: string): boolean {
    return /<[a-z][\s\S]*>/i.test(message);
  }

  getHumanTermName(): string {
    return HUMAN_LEASE_TERMS[this.alert.termName] ?? this.alert.termName;
  }
}
