<div class="b-card">
  <h1 class="b-header fancy text-capitalize">
    add portfolio
  </h1>
  <form name="form" novalidate class="form"
        #form="ngForm" (ngSubmit)="submit(form)">
    <div class="d-flex flex-column">
      <div class="container">
        <div class="row"
             *ngIf="portfolios.length === 0">
          You have no portfolio yet. Please create at least one to save building and leases data
        </div>
        <div class="row mt-2">
          <div class="col-sm-6">
            <label class="b-label text-14 text-capitalize pr-2">
              portfolio name
            </label>
            <span class="error-msg"
                  *ngIf="form.submitted && name.hasError('required')">
              Required
            </span>
            <input
              class="b-input rounded"
              name="name"
              id="name"
              type="text"
              #name="ngModel"
              required
              [(ngModel)]="vm.name"
              [class.is-invalid]="form.submitted && name.hasError('required')"
            />
          </div>
        </div>
        <div class="row pt-5">
          <div class="col d-flex">
            <button class="save-btn btn btn-primary w-25 col-md-3 pr-4">
              <span
                *ngIf="submitted"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="text-uppercase text-13 fw-500">
                create portfolio
              </span>
            </button>
            <button class="btn w-25"
                    [routerLink]="['../']">
              <span class="text-uppercase text-13 fw-500 text-opacity-secondary">
                cancel
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
