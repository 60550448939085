import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './lease-request.model';

@Injectable()
export class LeaseRequestRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getFinancialRequests(leaseId: number): Observable<Array<models.LeaseRequest>> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .get<Array<models.LeaseRequest>>(
        `${this._configuration.apiEndpoint}/lease-requests/financial-requests`,
        { params }
      );
  }

  createFinancialRequest(leaseId: number, request: models.FinancialRequest): Observable<models.LeaseRequest> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .post<models.LeaseRequest>(
        `${this._configuration.apiEndpoint}/lease-requests/financial-requests`,
        request,
        { params }
      );
  }
}
