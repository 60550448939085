import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../../../auth/services/auth.service';
import { AlertService } from '../../../../alert/services/alert.service';
import { ProfileService } from '../../../../user/services/profile.service';

import * as models from '../../../../infrastructure/models/generated';

import { BaseDemoComponent } from '../../../../shared/components/base-demo/base-demo.component';

declare let Driver: any;

@Component({
  selector: 'app-lease-documents-demo',
  templateUrl: '../../../../shared/components/base-demo/base-demo.component.html',
  styleUrls: ['./lease-documents-demo.component.scss']
})
export class LeaseDocumentsDemoComponent extends BaseDemoComponent implements OnInit, OnDestroy {
  steps = [
    {
      element: '.column-file-versions',
      popover: {
        title: ' ',
        description: this.getMessage(
          'In this section you will see the file name, acceptance status by either user, ' +
          'every version that was submitted by each user and date of every version. ' +
          'You may click on any version to preview the document.'
        ),
        position: 'right'
      }
    },
    {
      element: '.column-doc',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Here you will see a preview of the document which will display all the redline changes to the previous version.'
        ),
        position: 'right'
      }
    },
    {
      element: '.column-comments',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Either user can drop in comments at any given time to address any questions or concerns.'
        ),
        position: 'left'
      }
    },
    {
      element: '.download-button',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Please note that there is a download option and a notification regarding what type of document is required to submit.'
        ),
        position: 'right'
      }
    },
    {
      element: '.column-doc-footer',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Please note that there is a download option and a notification regarding what type of document is required to submit.'
        ),
        position: 'top'
      }
    },
    {
      element: '.popup-buttons-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'During each users turn, you have an option to either accept the latest version of the document or submit ' +
          'a redraft with additional changes.'
        ),
        position: 'top'
      }
    },
  ];

  showFooterDemo = false;

  constructor(profileService: ProfileService, authService: AuthService, alertService: AlertService) {
    super(profileService, authService, alertService);
  }

  ngOnInit() {
    this.introType = models.IntroType.LeaseDocumentsPage;
  }

  show(): void {
    if (
      this.isMobile ||
      (this.authService.startupInfo.introsShown && this.authService.startupInfo.introsShown.find(x => x.introType === this.introType))
    ) {
      return;
    }

    if (!this.elementExist('.popup-buttons-demo')) {
      const idx = this.steps.findIndex(s => s.element === '.popup-buttons-demo');
      if (idx >= 0) {
        this.steps.splice(idx, 1);
      }
    }

    this.checkElements(['.lease-documents-demo'])
      .then(() => {
        this.visible = true;
        if (this.driver) {
          this.driver.reset();
          this.driver = null;
        }
        this.driver = new Driver({
          showButtons: false,
          allowClose: false,
          animate: false,
          // hack for devexpress popups
          onHighlighted: (element) => {
            this.removeDriverFixStackingClass();
          }
        });
        this.driver.defineSteps(this.steps);
        this.driver.start();
      });
  }

  goto(stepNumber: number) {
    this.current = stepNumber;
    this.showFooterDemo = this.current === 4;
    if (this.current === 4) {
      this.cssClassesForSteps = ['steps-footer-demo-next'];
      this.cssClassesForSkipButton = ['steps-footer-demo-skip'];
    } else if (this.current === 5) {

    } else {
      this.cssClassesForSteps = [];
      this.cssClassesForSkipButton = [];
    }
    this.driver.highlight(this.steps[stepNumber]);
  }
}
