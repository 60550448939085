<div class="analytics-filters">
  <h2 class="analytics-filters-title">Filter by</h2>

  <ng-template #preloader>
    <div class="analytics-filters-preloader">
      <span>
      </span>
    </div>
  </ng-template>

  <ng-container *ngIf="isLoaded; else preloader">
    <!-- Start: Selected filters -->
    <div class="analytics-filters-active"
         *ngIf="selectedFilters?.length">
      <ul class="analytics-filters-active-list">
        <li *ngFor="let selectedFilter of selectedFilters">
          <span>
            {{getFilterName(selectedFilter)}}
          </span>
          <button (click)="clearFilter(selectedFilter)">
            <span></span>
          </button>
        </li>
      </ul>

      <a role="button" (click)="clearFilters()">
        clear all
      </a>
    </div>
    <!-- End: Selected filters -->

    <!-- Start: Filters accordion -->
    <dx-accordion class="analytics-filters-accordion"
                  *ngIf="analyticsFilters"
                  [collapsible]="true" [multiple]="true" [disabled]="false"
                  [dataSource]="filterLinks">
      <div *dxTemplate="let filter of 'title'">
        <span>{{filter.text}}</span>
      </div>

      <div *dxTemplate="let filter of 'item'">
        <!-- Start: Portfolio section -->
        <ng-container *ngIf="visibleAnalyticsFilters.portfolios as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Portfolio">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearPortfolioFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-portfolio [portfolios]="filters"
                                             (portfoliosChange)="handlePortfolioFilterChange($event)">
            </app-analytics-filters-portfolio>
          </section>
        </ng-container>
        <!-- End: Portfolio section -->
        <!-- Start: Location section -->
        <section *ngIf="isLocationFiltersAvailable() && filter.text === filterType.Location">
          <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedLocationFiltersCount() as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

            <a role="button" class="small cursor-pointer text-primary"
               *ngIf="getSelectedLocationFiltersCount()"
               (click)="clearLocationFilters()">
              clear
            </a>
          </div>

          <!-- Start: State -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationStates as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                State
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-state [locationStates]="filters"
                                                      (locationStatesChange)="handleLocationStateFilterChange($event)">
                </app-analytics-filters-location-state>
              </div>
            </div>
          </ng-container>
          <!-- End: State -->
          <!-- Start: City -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationCities as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                City
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-city [locationCities]="filters"
                                                     (locationCitiesChange)="handleLocationCityFilterChange($event)">
                </app-analytics-filters-location-city>
              </div>
            </div>
          </ng-container>
          <!-- End: City -->
          <!-- Start: Market -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationMarkets as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                Market
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-market [locationMarkets]="filters"
                                                       (locationMarketsChange)="handleLocationMarketFilterChange($event)">
                </app-analytics-filters-location-market>
              </div>
            </div>
          </ng-container>
          <!-- End: Market -->
          <!-- Start: SubMarket -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationSubMarkets as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                SubMarket
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-submarket [locationSubMarkets]="filters"
                                                          (locationSubMarketsChange)="handleLocationSubMarketFilterChange($event)">
                </app-analytics-filters-location-submarket>
              </div>
            </div>
          </ng-container>
          <!-- End: SubMarket -->
          <!-- Start: Zip Code -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationZipCodes as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                Zip Code
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-zip-code [locationZipCodes]="filters"
                                                         (locationZipCodesChange)="handleLocationZipCodeFilterChange($event)">
                </app-analytics-filters-location-zip-code>
              </div>
            </div>
          </ng-container>
          <!-- End: Zip Code -->
          <!-- Start: Building -->
          <ng-container *ngIf="visibleAnalyticsFilters.locationAddresses as filters">
            <div class="analytics-filters-sub-section"
                 *ngIf="filters && filters.length">
              <h4 class="analytics-filters-sub-section-title">
                Building
              </h4>

              <div class="analytics-filters-section-body">
                <app-analytics-filters-location-address [locationAddresses]="filters"
                                                        (locationAddressesChange)="handleLocationAddressFilterChange($event)">
                </app-analytics-filters-location-address>
              </div>
            </div>
          </ng-container>
          <!-- End: Building -->
        </section>
        <!-- End: Location section -->
        <!-- Start: Deal Type section -->
        <ng-container *ngIf="visibleAnalyticsFilters.dealTypes as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Deal">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearDealTypeFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-deal-type [dealTypes]="filters"
                                             (dealTypesChange)="handleDealTypeFilterChange($event)">
            </app-analytics-filters-deal-type>
          </section>
        </ng-container>
        <!-- End: Deal Type section -->
        <!-- Start: Size section -->
        <section *ngIf="visibleAnalyticsFilters.squareFeetRange && filter.text === filterType.Size">
          <app-analytics-filters-square-feet [squareFeetRange]="visibleAnalyticsFilters.squareFeetRange"
                                             (squareFeetRangeChange)="handleSquareFeetRangeFilterChange($event)">
          </app-analytics-filters-square-feet>
        </section>
        <!-- End: Size section -->
        <!-- Start: Lease Expiration section -->
        <ng-container *ngIf="visibleAnalyticsFilters.leaseExpirations as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Lease">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearLeaseExpirationFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-lease-expiration [leaseExpirations]="filters"
                                                    (leaseExpirationsChange)="handleLeaseExpirationFilterChange($event)">
            </app-analytics-filters-lease-expiration>
          </section>
        </ng-container>
        <!-- End: Lease Expiration section -->
        <!-- Start: Tenant Company section -->
        <ng-container *ngIf="visibleAnalyticsFilters.tenantCompanies as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Tenant">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearTenantCompanyFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-tenant-company [tenantCompanies]="filters"
                                                  (tenantCompaniesChange)="handleTenantCompanyFilterChange($event)">
            </app-analytics-filters-tenant-company>
          </section>
        </ng-container>
        <!-- End: Tenant Company section -->
        <!-- Start: Industry section -->
        <ng-container *ngIf="visibleAnalyticsFilters.industries as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Industry">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearIndustryFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-industry [industries]="filters"
                                            (industriesChange)="handleIndustryFilterChange($event)">
            </app-analytics-filters-industry>
          </section>
        </ng-container>
        <!-- End: Industry section -->
        <!-- Start: Space Use section -->
        <ng-container *ngIf="visibleAnalyticsFilters.spaceUses as filters">
          <section *ngIf="filters && filters.length && filter.text === filterType.Space">
            <div class="analytics-filters-accordion-header">
            <span *ngIf="getSelectedFiltersCount(filters) as numberOfSelectedFilters">
              {{numberOfSelectedFilters}} {{numberOfSelectedFilters === 1 ? 'filter' : 'filters'}} selected
            </span>

              <a role="button" class="small cursor-pointer text-primary"
                 *ngIf="getSelectedFiltersCount(filters)"
                 (click)="clearSpaceUseFilters()">
                clear
              </a>
            </div>

            <app-analytics-filters-space-use [spaceUses]="filters"
                                             (spaceUsesChange)="handleSpaceUseFilterChange($event)">
            </app-analytics-filters-space-use>
          </section>
        </ng-container>
        <!-- End: Space Use section -->
      </div>
    </dx-accordion>
    <!-- End: Filters accordion -->
  </ng-container>
</div>
