import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';


@Component({
  selector: 'app-signage',
  templateUrl: './signage.component.html',
  styleUrls: ['./signage.component.scss']
})
export class SignageComponent extends BaseTermDirective<models.ISignageTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  SignageTermType: typeof models.SignageTermType = models.SignageTermType;

  private readonly _destroy$: Subject<void>;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Tenant shall have the right to install building standard signage on any monument, as well as the ' +
            'exterior of the building subject to final approval by Landlord and per applicable city code.',
          value: models.SignageTermType.LandlordApproved,
        },
        {
          name: 'To be addressed in lease document',
          value: models.SignageTermType.ToBeAddressedInLease,
        },
        {
          name: 'Custom',
          value: models.SignageTermType.Custom,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.SignageTermType.PerCurrentLease,
      },
      {
        name:
          'Tenant shall have the right to install building standard signage on any monument, as well as the exterior ' +
          'of the building subject to final approval by Landlord and per applicable city code.',
        value: models.SignageTermType.LandlordApproved,
      },
      {
        name: 'To be addressed in lease document',
        value: models.SignageTermType.ToBeAddressedInLease,
      },
      {
        name: 'Custom',
        value: models.SignageTermType.Custom,
      },
    ];
  }
}
