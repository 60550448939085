import { Component, Input, OnInit } from '@angular/core';
import { CommonTools } from '@statera/sdk/common';

import { DocumentViewerService } from '../../../document-viewer/services/document-viewer.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-onboarding-answer',
  templateUrl: './onboarding-answer.component.html',
  styleUrls: ['./onboarding-answer.component.scss']
})
export class OnboardingAnswerComponent implements OnInit {

  @Input()
  quizItem: models.IQuizItemViewModel;
  QuestionType = models.QuestionType;
  QuizItemType = models.QuizItemTypeAsEnum;
  AnswerType = models.AnswerType;
  private readonly _documentViewerService: DocumentViewerService;
  constructor(documentViewerService: DocumentViewerService) {
    this._documentViewerService = documentViewerService;
  }

  ngOnInit() {
  }

  renderAsHtml(answer: models.IQuizAnswerViewModel): boolean {
    return answer.quizQuestion.questionType !== models.QuestionType.File &&
      answer.quizQuestion.questionType !== models.QuestionType.MultipleChoice &&
      answer.quizQuestion.questionType !== models.QuestionType.MultipleChoiceMaxTwoItems &&
      answer.quizQuestion.questionType !== models.QuestionType.BrokerInvitation &&
      answer.quizQuestion.questionType !== models.QuestionType.LeaseAmendments &&
      answer.quizQuestion.questionType !== models.QuestionType.BuildingSpecs;
  }

  renderAsPreWrap(answer: models.IQuizAnswerViewModel) {
    return answer.quizQuestion.questionType === models.QuestionType.BuildingSpecs ||
      (answer.quizQuestion.questionType === models.QuestionType.LeaseAmendments &&
        answer.leaseAmendmentsAnswer && answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType !== models.LeaseAmendmentsAnswerType.Upload);
  }

  downloadFinancialFiles($event, item: models.IQuizAnswerViewModel) {
    $event.preventDefault();

    if (!item.quizAnswerFiles) {
      return;
    }

    for (let i = 0, num = item.quizAnswerFiles.length; i < num; i++) {
      const file = item.quizAnswerFiles[i];
      if (!file || !file.url) {
        continue;
      }

      CommonTools.downloadFile(file.url);
    }
  }

  previewFile(files: Array<models.IQuizItemAnswer>): void {
    if (!files) {
      return;
    }

    const documents = files.map(file => {
      return {
        url: file.fileUrl,
        name: file.answer
      };
    });

    this._documentViewerService.show(documents, {
      width: '95%',
      height: '95%',
      maxWidth: 1800,
      closeOnOutsideClick: false,
      showCloseButton: true,
      title: 'Preview',
      activeIndex: 0,
    });
  }

}
