<div class="edit-form-mobile-container">
  <div class="d-flex justify-content-between">
    <a class="b-controls-back" [routerLink]="['../../building/' + id]">
      <span class="icon-back"></span>
      {{ buildingForm?.building?.name }}
    </a>
    <div class="text-capitalize fw-500" style="width: 67%">
      edit building
    </div>
  </div>
  <hr />

  <app-building-form #buildingForm isMobile="true"></app-building-form>
</div>
