import { Component, OnDestroy, OnInit } from '@angular/core';

import { Role } from '@statera/sdk/auth';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { ProfileService } from '../../../user/services/profile.service';

import { BaseDemoComponent } from '../base-demo/base-demo.component';

import * as models from '../../../infrastructure/models/generated';

declare let Driver: any;

@Component({
  selector: 'app-project-schedule-demo',
  templateUrl: '../base-demo/base-demo.component.html',
  styleUrls: ['./project-schedule-demo.component.scss'],
})
export class ProjectScheduleDemoComponent extends BaseDemoComponent implements OnInit, OnDestroy {
  constructor(profileService: ProfileService, authService: AuthService, alertService: AlertService) {
    super(profileService, authService, alertService);
  }

  steps = [
    {
      element: '.project-schedule-time-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'The Project Schedule will continue to act as your guide throughout the process, ' +
          'in order to stay on target against a 90 day benchmark.',
        ),
        position: 'bottom',
      },
    },
    {
      element: '.gantt-chart-aside-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'This section highlights each stage of the renewal process, its due date and status.',
        ),
        position: 'right',
      },
    },
    {
      element: '.gantt-chart-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Please note the vertical green and red lines that represent today\'s date & finish date of the project. ' +
          'In addition, each stage has critical date info when hovering over each bar and once a stage is complete.',
        ),
        position: 'top',
      },
    },
    {
      element: '.project-schedule-statistics-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Key statistics are an active countdown of your renewal option, as well as lease expiration date.',
        ),
        position: 'top',
      },
    },

  ];

  ngOnInit() {
    this.introType = models.IntroType.ProjectSchedule;
  }

  ngOnDestroy(): void {
    if (this.driver) {
      this.driver.reset();
    }
  }

  show(component: any): void {
    const startupInfo = this.authService.startupInfo;
    if (startupInfo && startupInfo.role === Role.Landlord) {
      return;
    }
    this.component = component;
    if (
      this.isMobile ||
      (this.authService.startupInfo.introsShown &&
        this.authService.startupInfo.introsShown.find(x => x.introType === this.introType))
    ) {
      return;
    }
    this.visible = true;
    if (!this.driver) {
      this.driver = new Driver({
        showButtons: false,
        allowClose: false,
      });
      this.driver.defineSteps(this.steps);
      this.driver.start();
    } else {
      this.driver.reset(true);
      this.goto(this.current);
    }
  }

  goto(stepNumber: number) {
    this.component.ganttChart.isStatusesVisible = stepNumber === 1;
    this.current = stepNumber;

    setTimeout(() => {
      const animationDurationMs = 300;

      if (this.current === 3) {
        setTimeout(() => this._updateStepsPosition(false), animationDurationMs);
      } else if (this.current === 2) {
        setTimeout(() => this._updateStepsPosition(true), animationDurationMs);
      } else {
        this.setStepsStyle(null);
      }

      this.driver.highlight(this.steps[stepNumber]);
    }, 300);
  }

  private _updateStepsPosition(toBottom: boolean): void {
    const highlightElement = document.querySelector('#driver-highlighted-element-stage');
    if (!highlightElement) {
      return;
    }

    const highlightStyle = getComputedStyle(highlightElement);

    const stepsHeight = 55;
    const stepsOffset = 30;

    let offset = parseInt(highlightStyle.top.replace('px', '') || '0', 10);
    if (toBottom) {
      offset += parseInt(highlightStyle.height.replace('px', '') || '0', 10);
      offset += stepsOffset;
    } else {
      offset -= (stepsHeight + stepsOffset);
    }

    this.setStepsStyle({
      top: `${Math.max(offset, 0)}px`,
      bottom: '0px',
    });
  }
}
