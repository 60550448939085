import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-colabo-root',
  templateUrl: './colabo-root.component.html',
  styleUrls: ['./colabo-root.component.scss']
})
export class ColaboRootComponent implements OnInit, OnDestroy {
  private readonly _router: Router;
  private readonly _renderer: Renderer2;
  private readonly _destroy$: Subject<void>;

  constructor(router: Router, renderer: Renderer2) {
    this._router = router;
    this._renderer = renderer;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._renderer.addClass(document.body, 'body-100');
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(document.body, 'body-100');

    this._destroy$.next();
    this._destroy$.complete();
  }
}
