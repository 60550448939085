import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LeaseDocumentRepository } from './lease-document.repository';

import * as models from './lease-document.model';

@Injectable()
export class LeaseDocumentManager {
  private readonly _leaseDocumentRepository: LeaseDocumentRepository;

  constructor(leaseDocumentRepository: LeaseDocumentRepository) {
    this._leaseDocumentRepository = leaseDocumentRepository;
  }

  getLeaseAmendments(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._leaseDocumentRepository
      .getLeaseAmendments(leaseId);
  }

  getVersionedFiles(leaseId: number): Observable<Array<models.VersionedFile>> {
    return this._leaseDocumentRepository
      .getVersionedFiles(leaseId);
  }

  getCommentProfiles(
    leaseId: number,
    versionedFileCommentType: models.VersionedFileCommentType,
  ): Observable<Array<models.Profile>> {
    return this._leaseDocumentRepository
      .getCommentProfiles(leaseId, versionedFileCommentType);
  }

  accept(leaseId: number): Observable<models.VersionedFile> {
    return this._leaseDocumentRepository
      .acceptLeaseDocument(leaseId);
  }

  getSignDocument(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._leaseDocumentRepository
      .getSignDocument(leaseId);
  }

  setAsSigned(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._leaseDocumentRepository
      .setAsSigned(leaseId);
  }

  setLeaseAmendmentSigner(
    whoSignLeaseAmendment: models.WhoSignLeaseAmendment,
  ): Observable<models.WhoSignLeaseAmendment> {
    return this._leaseDocumentRepository
      .setLeaseAmendmentSigner(whoSignLeaseAmendment);
  }
}
