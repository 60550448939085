<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row"
       *ngIf="!isOption">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <ng-container *ngIf="isRequiredCommencementTerm()">
      <div class="text-danger"
           *ngIf="lease && lease.commencementTerm && !lease.commencementTerm.commencement">
        Please fill out Lease Commencement term prior to choosing new lease Term.
      </div>
    </ng-container>
    <div class="row"
         *ngIf="isShowRemainderValue()">
      <span>
        {{getRemainderValue()}}
      </span>
    </div>
    <div class="row term-container">
      <div class="col-sm-11 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="radioGroupOptions"
                          [(value)]="radioGroupValue"
                          [(visible)]="isVisibleRadioGroup"
                          [disabled] = "isRequiredCommencementTerm() && !lease.commencementTerm.commencement"
                          (onValueChanged)="clearErrors(); termRadioGroupValueChanged($event)">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div *ngIf="radioGroupValue === TermTypeOption.MultiplyOptions">
      <label>
        Please note that some multiple choice options during negotiations will not
        be available until one Lease Term option is finalized in Stage 3.
      </label>
    </div>

    <div class="row"
         *ngIf="canInsertNewOption()">
      <div class="col-6"
           *ngFor="let option of leaseTerm.leaseTermOptions?.options; let index = index">
        <div>
          <label>
            INSERT NUMBER OF MONTHS FOR OPTION {{getOptionName(index)}}
          </label>
        </div>
        <app-term style="width: 100%"
                  [isOption]="true" [project]="project" [lease]="lease" [(leaseTerm)]="leaseTerm.leaseTermOptions?.options[index]">
        </app-term>
      </div>
    </div>

    <ng-container *ngIf="isShowMultiplyOptionsTenantStep()">
      <div class="row term-container mb-2 mt-2">
        <div class="col-sm-11 mb-0 radio-group-container">
          <div class="dx-field-value">
            <dx-radio-group displayExpr="text" valueExpr="value"
                            [dataSource]="radioGroupForTermOptions" [(value)]="radioGroupSelectedIndex"
                            (onOptionChanged)="clearErrors(); radioGroupTermSaved($event)">
              <dx-validator>
                <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
                </dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>
        <!--col-->
      </div>
    </ng-container>

    <div *ngIf="radioGroupValue === TermTypeOption.Custom || radioGroupSelectedIndex === multipleOptionsCustomOptionIndex"
         class="col-6 mb-4 max-w-250">
      <app-input-field appIgnoreScrollwheel
                            [id]="'monthCount'" [placeholder]="'(e.g. 5)'"
                            [className]="'real-estate-input'" [min]="1" [max]="1000"
                            [format]="'#0'" [width]="'70px'"
                            [errorMessage]="'Value for term is required'"
                            [value]="leaseTerm.termValue" [suffix]="'month(s)'"
                            (valueChange)="leaseTerm.termValue = $event; clearErrors(); leaseTermChange.emit(leaseTerm)"
      ></app-input-field>
    </div>

    <!-- Start: Validation summary -->
    <div class="term-toast term-toast-neutral"
         *ngIf="rejectButtonClicked && (isTenantSquareFootageTypePhaseIn() || isRentalRateEscalationTypeCustom())">
      <i inlineSVG="assets/img/information.svg"></i>
      Please note that this change will be reflected in
      <ng-container *ngIf="isTenantSquareFootageTypePhaseIn() && !isRentalRateEscalationTypeCustom()">
        the Phase-In table
      </ng-container>
      <ng-container *ngIf="!isTenantSquareFootageTypePhaseIn() && isRentalRateEscalationTypeCustom()">
        the Escalation table
      </ng-container>
      <ng-container *ngIf="isTenantSquareFootageTypePhaseIn() && isRentalRateEscalationTypeCustom()">
        the Phase-In and Escalation tables
      </ng-container>
      automatically.
    </div>

    <div class="term-toast term-toast-information"
         *ngIf="radioGroupValue === TermTypeOption.MultiplyOptions && isFirstOffer">
      <i inlineSVG="assets/img/information.svg"></i>
      When choosing this option, Size, Base Rental Rate, Free Rent, Rental Rate Escalations and Termination Option will be affected.<br/><br/>
      The Landlord will provide multiple options for all of these terms as part of their counter.
    </div>

    <div class="term-toast term-toast-neutral"
         *ngIf="radioGroupSelectedIndex === multipleOptionsCustomOptionIndex">
      <i inlineSVG="assets/img/information.svg"></i>
      Please note that you are choosing a multiple choice option other than the choices provided by the Landlord.
    </div>

    <div class="term-toast term-toast-error"
         *ngIf="isShowTenantImprovementsAmortizationTermWarning()">
      <i inlineSVG="assets/img/x.svg"></i>
      Lease Term cannot be less than amortization term in Tenant Improvements
    </div>

    <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
    </div>
    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div *ngIf="!isOption && !isShowTenantImprovementsAmortizationTermWarning()">
        <div class="dx-fieldset">
          <dx-button id="button" text="Save" type="success" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isChooseOptionButtonVisible]="isChooseOptionButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (chooseOptionClicked)="handleChooseOptionButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
