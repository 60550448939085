import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { ConsoleDriver } from './driver/console/console.driver';
import { LoggerDriver } from './logger.driver';

import { LoggerService } from './logger.service';

@NgModule({
  providers: [
    LoggerService,
    {
      provide: LoggerDriver,
      useClass: ConsoleDriver,
    },
  ],
})
export class LoggerModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LoggerModule> {
    return {
      ngModule: LoggerModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LoggerService,
        {
          provide: LoggerDriver,
          useClass: ConsoleDriver,
        },
      ],
    };
  }
}
