import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

interface RenewalOptionComponentOption {
  name: string;
  value: models.RenewalOptionTermType;
  leaseTerm?: models.IRenewalOptionTermViewModel;
  insertData?: boolean;
}

@Component({
  selector: 'app-renewal-option',
  templateUrl: './renewal-option.component.html',
  styleUrls: ['./renewal-option.component.scss'],
})
export class RenewalOptionComponent extends BaseTermDirective<models.IRenewalOptionTermViewModel> implements OnInit {
  options: Array<RenewalOptionComponentOption>;

  RenewalOptionTermType = models.RenewalOptionTermType;

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.leaseTerm) {
      this.leaseTerm = <models.IRenewalOptionTermViewModel>{};
    }

    this._initializeOptions();
  }

  getSelectedOption(): RenewalOptionComponentOption {
    const { renewalOptionTermType } = this.leaseTerm;

    return this.options
      .find(option =>
        option.value === renewalOptionTermType
      );
  }

  getOptionForTermType(renewalOptionTermType: models.RenewalOptionTermType): RenewalOptionComponentOption {
    return this.options
      .find(option =>
        option.value === renewalOptionTermType
      );
  }

  getMinRenewalOptionAvailableMonths() {
    if (this.leaseTerm?.renewalOptionHowManyMonthsNotice) {
      return this.leaseTerm?.renewalOptionHowManyMonthsNotice + 1;
    }

    return 0;
  }

  getMaxRenewalOptionHowManyMonthsNotice(leaseTerm: models.IRenewalOptionTermViewModel) {
    if (
      leaseTerm?.renewalOptionAvailableMonths &&
      leaseTerm?.renewalOptionAvailableMonths > 0
    ) {
      return leaseTerm?.renewalOptionAvailableMonths - 1;
    }

    return 0;
  }

  onSaveTerm($event) {
    this.clearErrors();

    const { renewalOptionTermType } = this.leaseTerm;
    if (renewalOptionTermType == null) {
      this.addError('You should choose appropriate options.');

      return;
    }

    this.leaseTerm = {
      ...this.leaseTerm,
      ...(this.getSelectedOption()?.leaseTerm || {}),
    };

    super.saveLeaseTerm(this.leaseTerm);

    $event.preventDefault();
  }

  private _initializeOptions() {
    this.options = [];

    const { renewalOptionTermType } = this.leaseTerm;

    if (this.project?.projectTypeId !== models.ProjectTypeEnum.NewDeal) {
      this.options = [
        ...this.options,
        {
          name: 'Per current lease',
          value: models.RenewalOptionTermType.PerCurrentLease,
        },
      ];
    }

    this.options = [
      ...this.options,
      {
        name: (
          'Tenant shall have (INSERT NUMBER OF OPTIONS), (INSERT LEASE RENEWAL TERM IN YEARS) year renewal option(s), ' +
          'upon (INSERT NUMBER OF MONTHS) months notice at the then Fair Market Value'
        ),
        value: models.RenewalOptionTermType.RenewalOptionValues,
        leaseTerm: (
          renewalOptionTermType === models.RenewalOptionTermType.RenewalOptionValues ?
            <models.IRenewalOptionTermViewModel>{
              renewalOptionsCount: this.leaseTerm.renewalOptionsCount,
              renewalOptionYearsCount: this.leaseTerm.renewalOptionYearsCount,
              renewalOptionHowManyMonthsNotice: this.leaseTerm.renewalOptionHowManyMonthsNotice,
            } :
            <models.IRenewalOptionTermViewModel>{}
        ),
      },
      {
        name: (
          'Tenant shall have (INSERT NUMBER OF OPTIONS), (INSERT LEASE RENEWAL TERM IN YEARS) year renewal option(s), ' +
          'upon (INSERT NUMBER OF MONTHS) months notice at the then Fair Market Value but in no event greater than ' +
          '(INSERT PERCENTAGE)% of the rent in the expiration year.'
        ),
        value: models.RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent,
        leaseTerm: (
          renewalOptionTermType === models.RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent ?
            <models.IRenewalOptionTermViewModel>{
              renewalOptionsCount: this.leaseTerm.renewalOptionsCount,
              renewalOptionYearsCount: this.leaseTerm.renewalOptionYearsCount,
              renewalOptionHowManyMonthsNotice: this.leaseTerm.renewalOptionHowManyMonthsNotice,
              renewalOptionMaxRentPercentage: this.leaseTerm.renewalOptionMaxRentPercentage,
            } :
            <models.IRenewalOptionTermViewModel>{}
        ),
      },
      {
        name: (
          'Tenant shall have (INSERT NUMBER OF OPTIONS), (INSERT LEASE RENEWAL TERM IN YEARS) year renewal option(s), ' +
          'no sooner than (INSERT MAXIMUM NUMBER OF MONTHS) months and no less than (INSERT MINIMUM NUMBER OF MONTHS) ' +
          'months prior written notice. The rent shall be at the then Fair Market Value but in no event less than ' +
          '(INSERT PERCENTAGE)% of the rent in the expiration year.'
        ),
        value: models.RenewalOptionTermType.RenewalOptionValuesExtended,
        leaseTerm: (
          renewalOptionTermType === models.RenewalOptionTermType.RenewalOptionValuesExtended ?
            <models.IRenewalOptionTermViewModel>{
              renewalOptionsCount: this.leaseTerm.renewalOptionsCount,
              renewalOptionYearsCount: this.leaseTerm.renewalOptionYearsCount,
              renewalOptionAvailableMonths: this.leaseTerm.renewalOptionAvailableMonths,
              renewalOptionHowManyMonthsNotice: this.leaseTerm.renewalOptionHowManyMonthsNotice,
              renewalOptionMinRentPercentage: this.leaseTerm.renewalOptionMinRentPercentage,
            } :
            <models.IRenewalOptionTermViewModel>{}
        ),
      },
      {
        name: 'None',
        value: models.RenewalOptionTermType.None,
      },
      {
        name: 'Custom',
        value: models.RenewalOptionTermType.Custom,
        leaseTerm: (
          renewalOptionTermType === models.RenewalOptionTermType.Custom ?
            <models.IRenewalOptionTermViewModel>{
              renewalOptionCustomText: this.leaseTerm.renewalOptionCustomText,
            } :
            <models.IRenewalOptionTermViewModel>{}
        ),
      },
    ];
  }
}
