import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompanyManager } from '@statera/sdk/company';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import DataSource from 'devextreme/data/data_source';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';
import { ILeaseViewModel, ProjectTypeEnum } from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'invite-broker-by-tenant-dialog.component.html',
  styleUrls: ['invite-broker-by-tenant-dialog.component.scss'],
})
export class InviteBrokerByTenantDialogComponent implements OnInit, OnDestroy {
  ProjectTypes: typeof models.ProjectTypeEnum = models.ProjectTypeEnum;

  userInvitationRequest: models.IUserInvitationRequestViewModel & { company: models.ICompanyViewModel };

  companiesDataSource: DataSource;

  readonly lease: ILeaseViewModel;
  readonly brokerInvited$: Subject<models.IUserInvitationRequestViewModel>;
  readonly project: models.IProject;

  private readonly _dialogRefService: DialogRefService;
  private readonly _companyManager: CompanyManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    dialogRefService: DialogRefService,
    companyManager: CompanyManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
  ) {
    this._dialogRefService = dialogRefService;
    this._companyManager = companyManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._destroy$ = new Subject<void>();
  }

  isRenewalProject(): boolean {
    return this.project.projectType.projectTypeEnum === ProjectTypeEnum.Renewal
      || this.project.projectType.projectTypeEnum === ProjectTypeEnum.RenewalInitiatedByLandlord;
  }

  ngOnInit(): void {
    this.userInvitationRequest = <models.IUserInvitationRequestViewModel & { company: models.ICompanyViewModel }>{};
    this._companyManager
      .getCompanies(models.CompanyType.BrokerCompany)
      .pipe(
        tap(companies => {
          this.companiesDataSource = new DataSource({
            store: {
              data: companies,
              type: 'array',
              key: 'name',
            },
          });
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleCompanyChange(company: models.ICompanyViewModel): void {
    if (!this.userInvitationRequest) {
      return;
    }

    delete this.userInvitationRequest.company;

    if (!company) {
      delete this.userInvitationRequest.companyId;
      delete this.userInvitationRequest.companyName;

      return;
    }

    this.userInvitationRequest.companyId = company.id;
    this.userInvitationRequest.companyName = company.name;
  }

  handleCustomCompanyCreating(event): void {
    if (!this.companiesDataSource || !this.userInvitationRequest) {
      return;
    }

    if (!event || !event.text) {
      delete this.userInvitationRequest.companyId;
      delete this.userInvitationRequest.companyName;
      delete this.userInvitationRequest.company;

      return;
    }

    const customCompany = <models.ICompanyViewModel>{
      id: Date.now() * -1,
      name: event.text,
    };

    event.customItem = this.companiesDataSource
      .store()
      .insert(customCompany)
      .then(() => this.companiesDataSource.load())
      .then(() => {
        this.userInvitationRequest.companyId = customCompany.id;
        this.userInvitationRequest.companyName = customCompany.name;
        this.userInvitationRequest.company = customCompany;
      })
      .then(() => customCompany)
      .catch(err => {
        throw err;
      });
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    const request = {
      ...this.userInvitationRequest,
      role: Role.Broker,
      leaseId: this.lease.id,
    };

    if (request.companyId <= 0) {
      delete request.companyId;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmInviteBrokerByTenantAlertText(this.lease.landlordCompany.name),
    });

    alertReference
      .confirmed
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        if (this.brokerInvited$) {
          this.brokerInvited$.next(request);
        }

        this._dialogRefService.hide();
      });
  }

  cancel(): void {
    if (this.brokerInvited$) {
      this.brokerInvited$.next(null);
    }

    this._dialogRefService.hide();
  }
}
