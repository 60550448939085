<div class="d-flex flex-column flex-grow-1 scroll-view-container"
     #scrollViewContainer>
  <dx-scroll-view class="flex-grow-1"
                  (onScroll)="onMessagesScroll($event)" #scrollView>
    <div *ngFor="let item of messages" #messageElements>
      <div class="b-message"
           [class.b-message--self]="item.isMy">
        <div class="b-message-header">
          <div class="b-message-header-avatar"
               *ngIf="!item.isMy">
            <figure class="b-message-ava">
              <img class="img-fluid" alt
                   [src]="item.authorAvatarUrl"/>
            </figure>
          </div>
          <div class="b-message-header-info">
            <div class="b-message-author"
                 *ngIf="!item.isMy">
              {{ item?.authorDisplayName }}
            </div>
            <time class="b-message-date">
              {{item?.createdOn | safeDate: 'MM/dd/yyyy hh:mm a'}}
            </time>
            <div class="b-message-related-to"
                 *ngIf="item.versionedFile">
              Related to
              <ng-template #versionedFileNameLabel>
                {{item.versionedFile?.name}}
              </ng-template>
              <ng-container *ngIf="!item.versionedFile?.isDeleted; else versionedFileNameLabel">
                <a role="button" class="cursor-pointer"
                   (click)="versionedFileSelected.emit(item.versionedFile)">
                  {{item.versionedFile?.name}}
                </a>
              </ng-container>
            </div>
            <div class="b-message-badges">
              <div class="b-message-deleted-badge"
                   *ngIf="item.versionedFile?.isDeleted">
                deleted
              </div>
              <div class="b-message-file-version-badge"
                   *ngIf="getVersionedFileVersion(item) as versionedFileVersion">
                {{versionedFileVersion}}
              </div>
            </div>
          </div>
          <div class="message-menu">
            <div class="message-menu-icon"
                 *ngIf="(allowEdit || allowDelete) && item.isMy"
                 (click)="messageIconClick($event, item.id)">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <div class="message-edited"
                 *ngIf="item.edited">
              (edited)
            </div>
            <span>&nbsp;</span>
          </div>
        </div>

        <div class="b-message-body">
          <div class="b-message-body-content">
            <div class="b-message-body-text"
                 [innerHtml]="item?.innerHtml">
            </div>
          </div>
        </div>
      </div>
    </div>
  </dx-scroll-view>


  <div *ngIf="allowAdd" #editorContainer class="w-colabo-chat-editor d-flex">
    <dx-html-editor class="chat-editor"
                    [placeholder]="placeHolderValue" [(value)]="htmlEditorValue"
                    (onInitialized)="htmlEditorInitialized($event)" (onContentReady)="htmlEditorContentReady()"
                    #htmlEditor>
    </dx-html-editor>

    <button class="b-btn btn btn-colabo-send-message"
            (click)="sendMessage()">
      <span inlineSVG="assets/img/sign-icons.svg#send-icon"></span>
    </button>

    <button id="menuButton" class="b-btn btn btn-attachment d-flex justify-content-center align-items-center"
            (click)="bottomMenuVisible = true">
      <span inlineSVG="assets/img/sign-icons.svg#attachment-icon"></span>
    </button>
  </div>

</div>

<dx-context-menu target="#menuButton"
                 [cssClass]="'html-editor-menu'"
                 [dataSource]="items" [position]="{ my: 'bottom right', at: 'top right' }"
                 [(visible)]="bottomMenuVisible"
                 (onItemClick)="textAreaMenuItemClick($event)" #bottomMenu>
</dx-context-menu>

<dx-tooltip position="left"
            [width]="150" [showEvent]="'click'" [(visible)]="tooltipVisible"
            #messageMenuToolTip>
  <div class="d-flex flex-column tooltip-menu"
       *dxTemplate="let data = data; of: 'content'">
    <a class="nav-link text-uppercase text-13 header-text" href="javascript:;"
       (click)="startEditingMessage()">
      Edit
    </a>
    <a class="nav-link text-uppercase text-13 header-text" href="javascript:;"
       (click)="startDeletingMessage()">
      Delete
    </a>
  </div>
</dx-tooltip>

<dx-file-uploader id="file" name="file"
                  [uploadUrl]="imageUploadUrl" [visible]="false"
                  (onUploaded)="onImageUploaded($event)" (onUploadError)="onImageUploadError($event)"
                  (onUploadAborted)="onImageUploadAborted($event)" #imageUploader>
</dx-file-uploader>

<dx-popup title="Attach file"
          [showTitle]="true" [deferRendering]="false" [maxWidth]="500" [height]="350" [(visible)]="filePopupVisible">
  <dx-box class="popup-box" direction="col" width="100%">
    <dxi-item [ratio]="10">
      <dx-file-uploader id="file" name="file"
                        [uploadUrl]="fileUploadUrl"
                        (onUploaded)="onFileUploaded($event)" (onUploadError)="onFileUploadError($event)"
                        (onUploadAborted)="onFileUploadAborted($event)" #fileUploader>
      </dx-file-uploader>
    </dxi-item>
    <dxi-item [baseSize]="25">
      <div class="d-flex flex-row-reverse">
        <dx-button text="Attach" type="success"
                   (onClick)="filePopupAttachClick()">
        </dx-button>
        <dx-button text="Cancel" class="mr-2"
                   (onClick)="filePopupCancelClick()">
        </dx-button>
      </div>
    </dxi-item>
  </dx-box>
</dx-popup>
