import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { CommonTools } from '@statera/sdk/common';

import * as models from '../../../infrastructure/models/generated';

import {
  ExpirationsReportTooltip,
  ExpirationsReportTooltipChartType
} from '../../models/expirations-report-tooltip.model';
import { AnalyticsTooltipFactoryService } from '../../services/analytics-tooltip-factory.service';
import { AnalyticsTooltipService } from '../../services/analytics-tooltip.service';

import { AnalyticsManager } from '@statera/sdk/analytics';
import { DialogService } from '../../../dialog/services/dialog.service';

import {
  ExpirationsReportSinglePeriodDialogComponent, IExpirationsReportSinglePeriodDialogViewModel
} from '../expirations-report-single-period-dialog/expirations-report-single-period-dialog.component';
import { ExpirationsReportTooltipComponent } from '../expirations-report-tooltip/expirations-report-tooltip.component';

type TooltipComponentType = ExpirationsReportTooltipComponent;
type TooltipModelType = ExpirationsReportTooltip;

@Component({
  selector: 'app-analytics-expirations-report',
  templateUrl: 'expirations-report.component.html',
  styleUrls: ['expirations-report.component.scss'],
})
export class ExpirationsReportComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('canvasElementRef') canvasElementRef: ElementRef;

  @Input() analyticsFilters: models.IAnalyticsFiltersViewModel;

  isLoaded: boolean;

  barChartOptions: ChartOptions<'bar'>;
  barChartData: ChartConfiguration<'bar'>['data'];

  private _expirationsReport: models.IExpirationsReportViewModel;
  private _tooltipComponent: TooltipComponentType;

  private readonly _analyticsManager: AnalyticsManager;
  private readonly _analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>;
  private readonly _dialogService: DialogService;
  private readonly _destroy$: Subject<void>;

  constructor(
    analyticsManager: AnalyticsManager,
    analyticsTooltipFactoryService: AnalyticsTooltipFactoryService,
    dialogService: DialogService,
  ) {
    this._analyticsManager = analyticsManager;
    this._analyticsTooltipService = analyticsTooltipFactoryService.create<TooltipComponentType, TooltipModelType>();
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
  }

  expiringLeasesCount(): number {
    if (this._expirationsReport) {
      return this._expirationsReport.expiringLeasesCount;
    }
    return 0;
  }

  ngOnInit(): void {
    this.analyticsFilters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    this._configureChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    const isChanged = (change: SimpleChange) => {
      return (
        change &&
        (
          change.isFirstChange() ||
          (
            (change.previousValue && change.currentValue) &&
            !CommonTools.isEqual(change.previousValue, change.currentValue)
          )
        )
      );
    };

    if (
      isChanged(changes.analyticsFilters)
    ) {
      const isFirstChange = (change: SimpleChange) => change && change.isFirstChange();

      // Configure chart at first run
      if (
        isFirstChange(changes.analyticsFilters)
      ) {
        this._configureChart();
      }

      this._setupChartData();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleChartClickEvent(event): void {
    if (
      !event || !event.active || !event.active[0] ||
      !this._expirationsReport || !this._expirationsReport.periods || !this._expirationsReport.periods.length
    ) {
      return;
    }

    const activeItemIndex = event.active[0]._index;
    this.showChart(activeItemIndex);
  }

  showChart(activeItemIndex = 0) {
    if (!this._expirationsReport.periods[activeItemIndex]) {
      return;
    }

    const expirationsReportSinglePeriodDialogViewModel: IExpirationsReportSinglePeriodDialogViewModel = {
      periods: this._expirationsReport.periods,
      initialPeriodId: activeItemIndex
    };

    const title = ExpirationsReportSinglePeriodDialogComponent.prepareTitle(this._expirationsReport.periods[activeItemIndex].startDate);

    this._dialogService.show(ExpirationsReportSinglePeriodDialogComponent, {
      title: title,
      showCloseButton: true,
      width: 1024,
      height: '95%',
      containerClassNames: ['overflow-hidden'],
      injectableData: {
        expirationsReportSinglePeriodDialogViewModel: expirationsReportSinglePeriodDialogViewModel,
      }
    });
  }

  private _configureChart(): void {
    this.barChartData = {
      labels: [],
      datasets: [],
    };
    this.barChartOptions = {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 5,
          },
        },
        y: {
          grid: {
            color: '#DCDFE5',
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 10,
            callback: (label: number): string => {
              if (1000 <= label) {
                if (1000000 <= label) {
                  return `${label / 1000000}M`;
                }

                return `${label / 1000}K`;
              }

              return `${label}`;
            }
          },
          suggestedMin: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          external: (x) => this._externalTooltipHandler(x.tooltip),
        },
      },
    };
  }

  private _setupChartData(): void {
    this.isLoaded = false;

    this
      ._requestExpirationsReport()
      .pipe(
        tap(expirationsReport => {
          if (!expirationsReport || !expirationsReport.periods) {
            return;
          }

          this._expirationsReport = expirationsReport;

          const {periods} = expirationsReport;

          this.barChartData.labels = periods.map(x => moment(x.startDate).format('YYYY'));
          this.barChartData.datasets = [
            {
              data: periods?.map(x => x.totalSquareFeet),
              label: 'Expiring SF',
              backgroundColor: 'rgba(240, 156, 148, 1)',
              hoverBackgroundColor: 'rgba(231, 90, 77, 1)',
              borderColor: 'rgba(255, 255, 255, 0)',
              hoverBorderColor: 'rgba(255, 255, 255, 0)',
              borderWidth: 3,
              barThickness: 13,
              borderSkipped: false,
              borderRadius: [
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
              ],
            },
          ];
        }),
        tap(() => this.isLoaded = true),
        catchError(err => {
          this.isLoaded = true;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _requestExpirationsReport(): Observable<models.IExpirationsReportViewModel> {
    const today = moment().startOf('day');

    const periods = new Array(5)
      .fill(null)
      .map((_, index) => {
        const date = today.clone().add(index, 'years');

        return {
          startDate: date.startOf('year').startOf('day').format('YYYY-MM-DD'),
          endDate: date.endOf('year').startOf('day').format('YYYY-MM-DD'),
        };
      });

    const filters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    return this._analyticsManager
      .getExpirationsReport(periods, models.ExpirationsReportGroupingOption.Month, filters);
  }

  private _externalTooltipHandler(tooltipModel: TooltipModel<'bar'>): void {
    if (this.canvasElementRef && this.canvasElementRef.nativeElement) {
      this.canvasElementRef.nativeElement.classList.remove('cursor-pointer');
    }

    if (!tooltipModel.getActiveElements().length) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    if (this._tooltipComponent) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
    }

    if (
      !tooltipModel || !tooltipModel.dataPoints || !tooltipModel.dataPoints.length ||
      !this._expirationsReport || !this._expirationsReport.periods || !this._expirationsReport.periods.length ||
      !this.canvasElementRef || !this.canvasElementRef.nativeElement
    ) {
      return;
    }

    this.canvasElementRef.nativeElement.classList.add('cursor-pointer');

    const activeItemIndex = tooltipModel.dataPoints[0].dataIndex;

    const expirationsReportPeriod = this._expirationsReport.periods[activeItemIndex];
    if (!expirationsReportPeriod) {
      return;
    }

    const {x, y, height, base, horizontal} = <any>tooltipModel.getActiveElements()[0].element;

    if (horizontal) {
      tooltipModel.x = tooltipModel.caretX = <number>base + (<number>x - <number>base) / 2;
      tooltipModel.y = tooltipModel.caretY = <number>y + <number>height / 2;
    } else {
      tooltipModel.x = tooltipModel.caretX = <number>x;
      tooltipModel.y = tooltipModel.caretY = <number>base;
    }

    this._tooltipComponent = this._analyticsTooltipService.show(
      ExpirationsReportTooltipComponent,
      {
        ...expirationsReportPeriod,
        expirationsReportTooltipChartType: ExpirationsReportTooltipChartType.MultiplePeriods,
      },
      {
        tooltipModel: tooltipModel,
        canvasElement: this.canvasElementRef.nativeElement,
      },
    );
  }

  onLegendItemClick(): void {

  }
}
