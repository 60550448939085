import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as models from './term-custom-value-template.model';

@Injectable({
  providedIn: 'root',
})
export class TermCustomValueTemplateStore {
  readonly list: BehaviorSubject<Array<models.TermCustomValueTemplateViewModel>>;

  constructor() {
    this.list = new BehaviorSubject<Array<models.TermCustomValueTemplateViewModel>>(null);
  }
}
