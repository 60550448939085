import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-space-use',
  templateUrl: 'analytics-filters-space-use.component.html',
  styleUrls: ['analytics-filters-space-use.component.scss'],
})
export class AnalyticsFiltersSpaceUseComponent {
  @Input() spaceUses: Array<analyticsFiltersModels.SpaceUseFilter>;
  @Output() spaceUsesChange: EventEmitter<Array<analyticsFiltersModels.SpaceUseFilter>>;

  constructor() {
    this.spaceUsesChange = new EventEmitter<Array<analyticsFiltersModels.SpaceUseFilter>>();
  }

  handleSpaceUseSelection(): void {
    this.spaceUsesChange.next([...this.spaceUses]);
  }
}
