<dx-popup [width]="700"
          [height]="550"
          [showTitle]="false"
          [closeOnOutsideClick]="false"
          [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="p-onboarding">
      <div class="p-onboarding-slider" #slider  [ngStyle]="{'transform': 'translateX(' + shift + 'px)'}">
        <div>
          <div>
            <figure class="p-onboarding-image">
              <img src="assets/img/oxana2.png" alt="Alease image" />
            </figure>
            <p class="p-onboarding-title"><span class="p-onboarding-title-big">Welcome to Statera</span><br>A simplified approach to leasing your industrial facility.</p>
            <hr>
            <p class="p-onboarding-highlight">I'm Aleasa and will be your guide throughout this experience.</p>
          </div>

          <div class="p-onboarding-btn">
            <button class="btn-primary" (click)="sliderNext()">Next</button>
          </div>
        </div>

        <div>
          <div>
            <p class="p-onboarding-title"><span class="p-onboarding-title-big">Welcome to Statera</span><br>A simplified approach to leasing your industrial facility.</p>
            <ul class="p-onboarding-list">
              <li>
                <img src="assets/img/onboarding1.svg" class="img-fluid" />
                <p>Your Dashboard is the central hub for all relevant lease information.</p>
              </li>
              <li>
                <img src="assets/img/onboarding3.svg" class="img-fluid" />
                <p>Track all lease terms, abstracts, relevant documents, tenant improvements, vacant spaces opportunities and eSign documents with ease.</p>
              </li>
              <li>
                <img src="assets/img/onboarding2.jpg" class="img-fluid" />
                <p>Our Colábo tool will guide you through any negotiations or maintenance concerns.</p>
              </li>
            </ul>
          </div>

          <div class="p-onboarding-btn">
            <button class="btn-muted" (click)="sliderPrev()">Back</button>
            <button class="btn-primary" (click)="hidePopup()">Done</button>
          </div>
        </div>
      </div>

      <div class="p-onboarding-dots">
        <span class="active"></span>
        <span [ngClass]="{'active': sliderPosition > 0}"></span>
      </div>
    </div>

    <button class="p-onboarding-close" (click)="hidePopup()">
      <svg width="12" height="12" viewBox="0 0 12 12">
        <path d="M10.292 0h.187c.112.088.234.168.336.267.305.297.604.6.9.906.096.099.174.215.26.323v.218c-.085.109-.16.228-.257.325-1.276 1.265-2.554 2.527-3.833 3.788-.043.043-.103.07-.19.129.096.074.15.108.193.151 1.272 1.276 2.544 2.551 3.813 3.83.104.104.183.232.274.349v.187c-.092.116-.175.24-.277.347-.315.324-.636.642-.955.962-.267.268-.48.268-.747 0L6.124 7.897l-.153-.145c-.057.07-.099.13-.15.18-1.274 1.282-2.55 2.562-3.825 3.843-.28.282-.485.28-.77-.005l-1.01-1.016c-.27-.272-.27-.486.001-.759L4.098 6.1c.042-.042.083-.086.14-.145-.061-.052-.112-.089-.156-.132L.215 2c-.287-.284-.287-.49.002-.781.321-.325.64-.653.968-.971.101-.098.231-.166.349-.248h.155c.118.09.246.169.35.272 1.278 1.256 2.552 2.516 3.827 3.775.04.039.08.076.125.117.053-.05.094-.088.134-.128C7.403 2.772 8.68 1.506 9.96.246c.097-.097.221-.165.333-.246" />
      </svg>
    </button>
  </div>
</dx-popup>
