import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacer',
})
export class ReplacerPipe implements PipeTransform {
  transform(value: string, model: any): any {
    if (value) {
      const modelKeys = Object.keys(model);
      for (const modelKey of modelKeys) {
        value = value.split(modelKey).join(model[modelKey]);
      }
    }
    return value;
  }
}
