export const environment = {
  production: true,
  webApiUrl: '/api',
  hubUrl: '/chathub',
  renewalOnboardingQuizId: 1,
  restructureOnboardingQuizId: 2,
  tenantCancellationQuizId: 3,
  newDealQuizId: 5,
  newDealInquiryQuizId: 6,
  defaultAvatarUrl: 'assets/img/user.png',
  defaultSourceUrl: 'assets/img/avatar.png',
  overrideLocalhost: '',
  loggingEnabled: true,
  applicationInsights: {
    enabled: false,
    instrumentationKey: '46300aec-213b-499c-9ce6-0ddc2c57901d',
  },
};
