import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ColaboManager } from '@statera/sdk/colabo';
import { MessageManagerFactory, ChatMessageManager } from '@statera/sdk/message';
import { WebsocketRepository } from '@statera/sdk/websocket';

import { AuthService } from '../../../auth/services/auth.service';
import { ActivityTrackerService } from '../../../infrastructure/services/activity-tracker.service';

import { AvatarListItem } from '../../../shared/components/avatar-list/avatar-list.component';
import { LeaseQuery } from '../../../shared/states/lease/lease.query';

import * as models from '../../../infrastructure/models/generated';

import { MessagesComponent, MessengerContext } from '../../../shared/components/messages/messages.component';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss']
})
export class ChatViewComponent implements OnInit, OnDestroy {
  @ViewChild('messagesView') messagesView: MessagesComponent;

  @Input() leaseId: number;

  MessageType = models.MessageType;
  chatChannelId = 0;
  profiles: Array<models.IChatProfileViewModel> = [];
  currentChatMessageManager: { chatMessageManager: ChatMessageManager, chatChannelId: number, lease: models.ILeaseViewModel };
  avatarItems: Array<AvatarListItem>;

  MessengerContext: typeof MessengerContext = MessengerContext;

  private readonly _authService: AuthService;
  private readonly _messageManagerFactory: MessageManagerFactory;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _leaseQuery: LeaseQuery;
  private readonly _colaboManager: ColaboManager;
  private readonly _websocketRepository: WebsocketRepository;
  private readonly _activityTrackerService: ActivityTrackerService;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    messageManagerFactory: MessageManagerFactory,
    domSanitizer: DomSanitizer,
    leaseQuery: LeaseQuery,
    colaboManager: ColaboManager,
    websocketRepository: WebsocketRepository,
    activityTrackerService: ActivityTrackerService
  ) {
    this._authService = authService;
    this._messageManagerFactory = messageManagerFactory;
    this._domSanitizer = domSanitizer;
    this._leaseQuery = leaseQuery;
    this._colaboManager = colaboManager;
    this._websocketRepository = websocketRepository;
    this._activityTrackerService = activityTrackerService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this._colaboManager
      .getSelectedColaboTreeItem()
      .pipe(
        filter((colaboTreeItem: models.IColaboTreeItemViewModel) =>
          !!colaboTreeItem &&
          !!colaboTreeItem.chatChannels &&
          0 < colaboTreeItem.chatChannels.length &&
          !!colaboTreeItem.leaseId
        ),
        switchMap((colaboTreeItem: models.IColaboTreeItemViewModel) => {
          if (!colaboTreeItem || !colaboTreeItem.leaseId) {
            return of({
              colaboTreeItem,
              lease: null,
            });
          }

          return this._leaseQuery
            .selectEntity(colaboTreeItem.leaseId)
            .pipe(
              map(lease => {
                return {
                  colaboTreeItem,
                  lease,
                };
              }),
            );
        }),
        switchMap(({colaboTreeItem, lease}) => {
          if (!colaboTreeItem || !colaboTreeItem.chatChannels || !lease) {
            return of(null);
          }

          const chatChannel = colaboTreeItem.chatChannels.find(x =>
            x.channelType === models.ChatChannelType.LeaseLandlordTeam ||
            x.channelType === models.ChatChannelType.LeaseTenantTeam
          );

          if (!chatChannel) {
            return of(null);
          }

          const chatMessageManager = this._messageManagerFactory
            .createChatMessageManager(
              this._authService.startupInfo,
              chatChannel.id,
              lease,
            );

          return chatMessageManager
            .getChatProfiles()
            .pipe(
              tap(profiles => {
                this.profiles = profiles;
                this.avatarItems = this.getAvatarList(profiles);
                this.chatChannelId = chatChannel.id;
                this.currentChatMessageManager = {chatMessageManager, chatChannelId: chatChannel.id, lease};
              }),
            );
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._trackRealtimeEvents();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _trackRealtimeEvents(): void {
    let chatSubscription: Subscription;

    const unsubscribe = () => {
      if (chatSubscription) {
        chatSubscription.unsubscribe();
        chatSubscription = null;
      }
    };

    const subscribe = () => {
      unsubscribe();

      chatSubscription = this._websocketRepository
        .getRealtimeChatMessage()
        .pipe(
          tap(response => {
            if (!response || !response.model) {
              return;
            }

            const chatMessage = response.model;
            if (!this.messagesView || !chatMessage || chatMessage.chatChannelId !== this.chatChannelId) {
              return;
            }

            switch (chatMessage.messageType) {
              case models.MessageType.Chat: {
                const message = this.messagesView.messageManager.toMessage(chatMessage);

                const profile = this.profiles.find(x => x.id === chatMessage.createdById);
                if (profile) {
                  message.authorAvatarUrl = profile.avatarUrl ? profile.avatarUrl : message.authorAvatarUrl;
                  message.authorDisplayName = profile.displayName;
                }

                (<any>message).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(message.content);

                this.messagesView.messages.push(message);

                break;
              }

              case models.MessageType.UpdateMessage: {
                const message = this.messagesView.messages.find(x => x.id === chatMessage.id);
                if (message) {
                  message.content = chatMessage.message;
                  (<any>message).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(chatMessage.message);
                }

                break;
              }

              case models.MessageType.DeleteMessage: {
                const message = this.messagesView.messages.find(x => x.id === chatMessage.id);
                if (message) {
                  const index = this.messagesView.messages.indexOf(message);
                  if (0 <= index) {
                    this.messagesView.messages.splice(index, 1);
                  }
                }

                break;
              }
            }
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();
    };

    this._activityTrackerService
      .track()
      .pipe(
        tap(isUserActive => {
          if (!isUserActive) {
            unsubscribe();
            return;
          }

          subscribe();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  messagesLoaded() {
    if (this.currentChatMessageManager) {
      setTimeout(() =>
        this.currentChatMessageManager
          .chatMessageManager
          .markAllAsRead()
          .pipe(
            takeUntil(this._destroy$)
          )
          .subscribe(),
      1000);
    }
  }

  getAvatarList(members: Array<models.IChatProfileViewModel>): Array<AvatarListItem> {
    return members.map(teamMember =>
      new AvatarListItem(
        teamMember.displayName,
        teamMember.firstName,
        teamMember.lastName,
        teamMember.avatarUrl,
        teamMember.companyName
      )
    );
  }
}
