import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import { Observable } from 'rxjs';

import { CommonTools, Configuration } from '@statera/sdk/common';

import { AvailableUnitStore } from './available-unit.store';

import * as models from './available-unit.model';

@Injectable()
export class AvailableUnitRepository {
  private readonly _configuration: Configuration;
  private readonly _availableUnitStore: AvailableUnitStore;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, availableUnitStore: AvailableUnitStore, httpClient: HttpClient) {
    this._configuration = configuration;
    this._availableUnitStore = availableUnitStore;
    this._httpClient = httpClient;
  }

  /**
   * Requests repository
   */

  checkAccess(companyId: number): Observable<void> {
    let params = new HttpParams();

    if (companyId) {
      params = params.set('companyId', companyId.toString(10));
    }

    return this._httpClient
      .get<void>(
        `${this._configuration.apiEndpoint}/available-units`,
        {
          params,
        },
      );
  }

  requestBuildingWithAvailableUnits(buildingId: number): Observable<Feature<Point>> {
    return this._httpClient
      .get<Feature<Point>>(
        `${this._configuration.apiEndpoint}/available-units/feature-collection/${buildingId}`,
      );
  }

  requestBuildingsWithAvailableUnitsList(
    options: models.AvailableUnitListOptions,
  ): Observable<FeatureCollection<Point>> {
    return this._httpClient
      .post<FeatureCollection<Point>>(
        `${this._configuration.apiEndpoint}/available-units/feature-collection`,
        options,
      );
  }

  requestListFilters(
    options: models.AvailableUnitListOptions,
  ): Observable<models.AvailableUnitListFilters> {
    return this._httpClient
      .post<models.AvailableUnitListFilters>(
        `${this._configuration.apiEndpoint}/available-units/filters`,
        options,
      );
  }

  requestSearchSuggestions(companyId: number, searchQuery: string): Observable<Array<models.AvailableUnitSearchSuggestion>> {
    let params = new HttpParams();

    if (companyId) {
      params = params.set('companyId', companyId.toString(10));
    }

    if (searchQuery) {
      params = params.set('query', searchQuery.toString());
    }

    return this._httpClient
      .get<Array<models.AvailableUnitSearchSuggestion>>(
        `${this._configuration.apiEndpoint}/available-units/search-suggestions`,
        {
          params,
        },
      );
  }

  requestAvailableUnit(buildingId: number, unitId: number): Observable<models.BuildingUnitViewModel> {
    return this._httpClient
      .get<models.BuildingUnitViewModel>(
        `${this._configuration.apiEndpoint}/available-units/building/${buildingId}/units/${unitId}`,
      );
  }

  /**
   * Store repository
   */

  getStoredListOptions(): Observable<models.AvailableUnitListOptions> {
    return this._availableUnitStore.listOptions;
  }

  getStoredListOptionsSync(): models.AvailableUnitListOptions {
    return this._availableUnitStore.listOptions.getValue();
  }

  mergeStoredListOptions(availableUnitListOptions: models.AvailableUnitListOptions): void {
    let previousAvailableUnitListOptions = this._availableUnitStore.listOptions.getValue();
    if (!previousAvailableUnitListOptions) {
      previousAvailableUnitListOptions = <models.AvailableUnitListOptions>{};
    }

    const nextAvailableUnitListOptions = {...previousAvailableUnitListOptions, ...availableUnitListOptions};
    if (!CommonTools.isEqual(previousAvailableUnitListOptions, nextAvailableUnitListOptions)) {
      this._availableUnitStore.listOptions.next(nextAvailableUnitListOptions);
    }
  }

  setStoredListOptions(availableUnitListOptions: models.AvailableUnitListOptions): void {
    this._availableUnitStore.listOptions.next(availableUnitListOptions);
  }

  getStoredListFilters(): Observable<models.AvailableUnitListFilters> {
    return this._availableUnitStore.listFilters;
  }

  setStoredListFilters(availableUnitListFilters: models.AvailableUnitListFilters): void {
    this._availableUnitStore.listFilters.next(availableUnitListFilters);
  }

  setStoredListFiltersError(error: Error): void {
    this._availableUnitStore.listFilters.error(error);
  }

  getStoredList(): Observable<FeatureCollection<Point>> {
    return this._availableUnitStore.list;
  }

  setStoredList(buildings: FeatureCollection<Point>): void {
    this._availableUnitStore.list.next(buildings);
  }

  setStoredListError(error: Error): void {
    this._availableUnitStore.list.error(error);
  }

  getStoredSidebarVisible(): Observable<boolean> {
    return this._availableUnitStore.sidebarVisible;
  }

  setStoredSidebarVisible(visible: boolean): void {
    this._availableUnitStore.sidebarVisible.next(visible);
  }

  getIsListFiltersVisible(): Observable<boolean> {
    return this._availableUnitStore.isListFiltersVisible;
  }

  setIsListFiltersVisible(isFiltersVisible: boolean): void {
    this._availableUnitStore.isListFiltersVisible.next(isFiltersVisible);
  }

  getIsListFiltersLoaded(): Observable<boolean> {
    return this._availableUnitStore.isListFiltersLoaded;
  }

  setIsListFiltersLoaded(isLoaded: boolean): void {
    this._availableUnitStore.isListFiltersLoaded.next(isLoaded);
  }

  getStoredFocusedPoint(): Observable<Feature<Point>> {
    return this._availableUnitStore.focusedPoint;
  }

  setStoredFocusedPoint(point: Feature<Point>): void {
    this._availableUnitStore.focusedPoint.next(point);
  }

  getStoredSelectedPoint(): Observable<Feature<Point>> {
    return this._availableUnitStore.selectedPoint;
  }

  setStoredSelectedPoint(point: Feature<Point>): void {
    this._availableUnitStore.selectedPoint.next(point);
  }

  setStoredSelectedPointError(error: Error): void {
    this._availableUnitStore.selectedPoint.error(error);
  }

  getStoredSelectedPointSelectedAvailableUnit(): Observable<models.BuildingUnitViewModel> {
    return this._availableUnitStore.selectedPointSelectedAvailableUnit;
  }

  setStoredSelectedPointSelectedAvailableUnit(availableUnit: models.BuildingUnitViewModel): void {
    this._availableUnitStore.selectedPointSelectedAvailableUnit.next(availableUnit);
  }

  setStoredSelectedPointSelectedAvailableUnitError(error: Error): void {
    this._availableUnitStore.selectedPointSelectedAvailableUnit.error(error);
  }
}
