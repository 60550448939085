import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { ProjectModule } from '@statera/sdk/project';
import { QuizManager } from './quiz.manager';
import { QuizRepository } from './quiz.repository';

@NgModule({
  imports: [
    HttpClientModule,
    ProjectModule
  ],
  providers: [
    QuizRepository,
    QuizManager,
  ],
})
export class QuizModule {
  static forChild(configuration: Configuration): ModuleWithProviders<QuizModule> {
    return {
      ngModule: QuizModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        QuizRepository,
        QuizManager,
      ],
    };
  }
}
