import {Component, HostListener, Input, Output, ViewChild} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as ng from '@angular/core';

import { DialogService } from '../../../dialog/services/dialog.service';

import * as models from '../../models/image-viewer-image.model';

import { ImageViewerMarkerDialogComponent } from '../image-viewer-marker-dialog/image-viewer-marker-dialog.component';

interface PannellumViewer {
  addHotSpot(hotSpotConfig: PannellumViewerHotSpotConfig);
  removeHotSpot(id: number);
  destroy();
  isLoaded();
  resize();
  getPitchBounds(): [number, number];
  getYawBounds(): [number, number];
  getConfig(): PannellumViewerOptions;
  getRenderer();
}

interface PannellumViewerHotSpotConfig {
  id: number;
  type: string;
  pitch: number;
  yaw: number;
  text?: string;
  cssClass?: string;
  createTooltipFunc?: (markerElement: HTMLElement, args: any) => void;
  createTooltipArgs?: any;
}

interface PannellumViewerOptions {
  type: string;
  panorama: string;
  haov?: number;
  vaov?: number;
  hfov?: number;
  vOffset?: number;
  autoLoad?: boolean;
  pitch?: number;
  yaw?: number;
  roll?: number;
  minPitch?: number;
  maxPitch?: number;
  minYaw?: number;
  maxYaw?: number;
  showControls?: boolean;
  avoidShowingBackground?: boolean;
  hotSpots?: Array<PannellumViewerHotSpotConfig>;
}

interface MarkerArgs {
  markerId: number;
}

@Component({
  selector: 'app-image-viewer-image-panorama',
  templateUrl: 'image-viewer-image-panorama.component.html',
  styleUrls: ['image-viewer-image-panorama.component.scss'],
  changeDetection: ng.ChangeDetectionStrategy.OnPush,
})
export class ImageViewerImagePanoramaComponent implements ng.OnInit, ng.OnChanges, ng.OnDestroy {
  private static readonly _markerTextMaxWidth = 250;
  private static readonly _markerTextPaddingX = 13;
  private static readonly _markerTextMargin = 6;

  @ViewChild('pannellumContainerElementRef', {static: true}) pannellumContainerElementRef: ng.ElementRef;

  @Input() imageRef: models.ImageViewerImageRef;

  @Input() allowChangeMarkers: boolean;

  @Input() markerCreated$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerChanged$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerDeleted$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() addNotePressed: boolean;

  @Output() markerDrawn: ng.EventEmitter<{ markerRef: models.ImageViewerImageMarkerRef, x: number, y: number }>;
  tempMarkerId: number;

  private _pannellumViewer: PannellumViewer;

  private _markersCount: number;
  private _markers: {[key: string]: models.ImageViewerImageMarkerRef};
  private _markerElements: {[key: string]: HTMLElement};

  private _markerDraggablePreview: HTMLElement;
  private _isMarkerHovered: boolean;

  private readonly _renderer: ng.Renderer2;
  private readonly _dialogService: DialogService;

  private readonly _destroy$: Subject<void>;
  innerWidth: number;

  constructor(renderer: ng.Renderer2, dialogService: DialogService) {
    this._renderer = renderer;
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
    this.markerDrawn = new ng.EventEmitter<{ markerRef: models.ImageViewerImageMarkerRef, x: number, y: number }>();
  }

  ngOnInit(): void {
    this._subscribeOnEvents();
    this.innerWidth = window.innerWidth;

    this.markerCreated$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            this.imageRef.markers = [];
          }

          this.imageRef.markers.push(marker);
          this._drawMarker(marker.y, marker.x);
        }),
      )
      .subscribe();

    this.markerChanged$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            this.imageRef.markers = [];
          }

          const markerIndex = this.imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this.imageRef.markers[markerIndex] = marker;
            return;
          }

          this.imageRef.markers.push(marker);
        }),
      )
      .subscribe();

    this.markerDeleted$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            return;
          }

          const markerIndex = this.imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this.imageRef.markers.splice(markerIndex, 1);
          }
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (changes.imageRef && changes.imageRef.isFirstChange()) {
      this._initPannellumViewer();
      return;
    }

    if (
      changes.imageRef &&
      changes.imageRef.currentValue && changes.imageRef.previousValue &&
      changes.imageRef.currentValue.imageDataUrl !== changes.imageRef.previousValue.imageDataUrl) {
      this._initPannellumViewer();
    }

    if (changes.addNotePressed && !changes.addNotePressed.currentValue && changes.addNotePressed.previousValue) {
      if (this.tempMarkerId) {
        const marker = this._markers[this.tempMarkerId];
        if (!marker) {
          this._removeMarker(this.tempMarkerId);
          this.tempMarkerId = null;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyPannellum();
    this._unsubscribeOnEvents();

    this._destroy$.next();
    this._destroy$.complete();
  }

  resize(): void {
    this._resizePannellum();
  }

  private _initPannellumViewer(): void {
    const pannellum = (<any>window).pannellum;
    if (
      !pannellum ||
      !this.pannellumContainerElementRef ||
      !this.pannellumContainerElementRef.nativeElement ||
      !this.imageRef
    ) {
      return;
    }

    if (this._pannellumViewer) {
      this._destroyPannellum();
    }

    const haov = 340;
    const vaov = 75;

    const pitch = vaov / 2;
    const yaw = haov / 2;

    this._pannellumViewer = pannellum.viewer(
      this.pannellumContainerElementRef.nativeElement,
      {
        type: 'equirectangular',
        panorama: this.imageRef.imageDataUrl,
        autoLoad: true,
        vOffset: 0,
        haov: haov,
        vaov: vaov,
        hfov: 90,
        minPitch: -pitch,
        maxPitch: pitch,
        minYaw: -yaw,
        maxYaw: yaw,
        showControls: false,
        avoidShowingBackground: true,
        hotSpots: [],
      },
    );

    this._markersCount = 0;
    this._markers = {};
    this._markerElements = {};

    this._markerDraggablePreview = null;

    this._drawMarkers();
  }

  private _subscribeOnEvents(): void {
    if (
      this.pannellumContainerElementRef &&
      this.pannellumContainerElementRef.nativeElement &&
      this.allowChangeMarkers
    ) {
      const pannellumContainerElement = this.pannellumContainerElementRef.nativeElement;
      pannellumContainerElement.addEventListener('dragover', this._handleContainerDragOver.bind(this), false);
      pannellumContainerElement.addEventListener('drop', this._handleContainerDrop.bind(this), false);
      pannellumContainerElement.addEventListener('click', this._handleContainerClick.bind(this), false);
    }
  }

  private _unsubscribeOnEvents(): void {
    if (
      this.pannellumContainerElementRef &&
      this.pannellumContainerElementRef.nativeElement &&
      this.allowChangeMarkers
    ) {
      const pannellumContainerElement = this.pannellumContainerElementRef.nativeElement;
      pannellumContainerElement.removeEventListener('dragover', this._handleContainerDragOver.bind(this), false);
      pannellumContainerElement.removeEventListener('drop', this._handleContainerDrop.bind(this), false);
    }
  }

  private _destroyPannellum(): void {
    if (!this._pannellumViewer) {
      return;
    }

    this._pannellumViewer.destroy();
  }

  private _resizePannellum(): void {
    if (!this._pannellumViewer) {
      return;
    }

    this._pannellumViewer.resize();
  }

  private _drawMarkers(): void {
    if (!this.imageRef || !this.imageRef.markers || !this.imageRef.markers.length) {
      return;
    }

    for (let i = 0, num = this.imageRef.markers.length; i < num; i++) {
      const marker = this.imageRef.markers[i];

      const markerId = this._drawMarker(marker.y, marker.x);

      this._markers[markerId] = marker;
    }
  }

  private _drawMarker(pitch: number, yaw: number): number {
    if (!this._pannellumViewer) {
      return;
    }

    const [minPitch, maxPitch] = this._pannellumViewer.getPitchBounds();
    const [minYaw, maxYaw] = this._pannellumViewer.getYawBounds();

    if (
      (pitch < minPitch || maxPitch < pitch) ||
      (yaw < minYaw || maxYaw < yaw)
    ) {
      return;
    }

    const markerId = ++this._markersCount;

    this._createMarker({
      id: markerId,
      type: 'info',
      pitch: pitch,
      yaw: yaw,
      cssClass: 'image-viewer-image-panorama-marker',
      createTooltipFunc: (markerElement: HTMLElement, args: MarkerArgs) => (
        this._handleMarkerCreation(markerElement, args)
      ),
      createTooltipArgs: {
        markerId: markerId,
      },
    });

    return markerId;
  }

  private _createMarker(marker: PannellumViewerHotSpotConfig): void {
    this._pannellumViewer.addHotSpot(marker);
  }

  private _removeMarker(markerId: number): void {
    this._pannellumViewer.removeHotSpot(markerId);

    if (this._isMarkerHovered) {
      this._removeMarkerTextTooltip(`text-for-${markerId}`);
    }
  }

  private _createMarkerTextTooltip(id: string, x: number, y: number, text: string): void {
    const canvasElement = this._renderer.createElement('canvas');
    const canvasContext = canvasElement.getContext('2d');

    canvasElement.width = window.innerWidth;
    canvasElement.height = window.innerHeight;

    canvasContext.font = '12px "Avenir Next Cyr", sans-serif';

    const textBoundary = (
      ImageViewerImagePanoramaComponent._markerTextMaxWidth - ImageViewerImagePanoramaComponent._markerTextPaddingX * 2
    );

    let markerTextWidth = canvasContext.measureText(text).width;
    if (textBoundary <= markerTextWidth) {
      markerTextWidth = textBoundary;
    }

    const markerTextHalfWidth = markerTextWidth / 2;

    const markerTextElement = this._renderer.createElement('div');
    const markerText = this._renderer.createText(text);

    this._renderer.addClass(markerTextElement, 'image-viewer-image-panorama-marker-text');

    this._renderer.setAttribute(markerTextElement, 'id', id);

    this._renderer.setStyle(markerTextElement, 'transform', `translate(${x}px, ${y}px)`);
    this._renderer.setStyle(markerTextElement, 'margin-left', `-${markerTextHalfWidth}px`);

    this._renderer.appendChild(markerTextElement, markerText);
    this._renderer.appendChild(document.body, markerTextElement);
  }

  private _removeMarkerTextTooltip(id: string): void {
    const markerTextElement = document.body.querySelector(`#${id}`);
    if (!markerTextElement) {
      return;
    }

    markerTextElement.remove();
  }

  private _handleMarkerCreation(markerElement: HTMLElement, args: MarkerArgs): void {
    if (!markerElement || !args || !args.markerId) {
      return;
    }

    if (this.allowChangeMarkers) {
      this._renderer.setAttribute(markerElement, 'draggable', 'true');

      markerElement.addEventListener(
        'dragstart',
        (event) => this._handleMarkerDragStart(markerElement, args, event),
        false,
      );
      markerElement.addEventListener(
        'dragend',
        (event) => this._handleMarkerDragEnd(markerElement, args, event),
        false,
      );
      markerElement.addEventListener(
        'drag',
        (event) => this._handleMarkerDrag(markerElement, args, event),
        false,
      );

      markerElement.addEventListener(
        'contextmenu',
        (event) => this._handleMarkerContextMenu(markerElement, args, event),
        false,
      );
    }

    markerElement.addEventListener(
      'mouseover',
      (event) => this._handleMarkerMouseOver(markerElement, args, event),
      false,
    );
    markerElement.addEventListener(
      'mouseleave',
      (event) => this._handleMarkerMouseLeave(markerElement, args, event),
      false,
    );

    this._markerElements[args.markerId] = markerElement;
  }

  //
  // Event handlers
  //

  private _handleContainerDragOver(event: DragEvent): void {
    if (!this._pannellumViewer) {
      return;
    }

    event.preventDefault();
  }

  private _handleContainerDrop(event: DragEvent): void {
    if (!this._pannellumViewer) {
      return;
    }

    event.preventDefault();

    if (this._markerDraggablePreview) {
      return;
    }

    const pointerPosition = this._getPointerPosition(event);
    const markerPosition = this._getMarkerPosition(pointerPosition);

    const markerId = this._drawMarker(markerPosition.pitch, markerPosition.yaw);

    this._openMarkerDialog(markerId, markerPosition);
  }

  private _handleContainerClick(event: MouseEvent): void {
    if (!this._pannellumViewer) {
      return;
    }

    if (!this.addNotePressed) {
      return;
    }

    event.preventDefault();

    if (this._markerDraggablePreview) {
      return;
    }
    this._handleMarkerCreateOrUpdate(event);
  }

  private _handleMarkerCreateOrUpdate(event: MouseEvent, markerId?: number): void {
    let dialogMarker = this._markers[markerId];
    const isCreateEvent = !this._markers[markerId];
    const pointerPosition = this._getPointerPosition(event);
    const markerPosition = this._getMarkerPosition(pointerPosition);
    if (this.tempMarkerId) {
      this._removeMarker(this.tempMarkerId);
      this.tempMarkerId = null;
    }
    if (isCreateEvent) {
      markerId = this._drawMarker(markerPosition.pitch, markerPosition.yaw);
      dialogMarker = new models.ImageViewerImageMarkerRef(this.imageRef, markerPosition.yaw, markerPosition.pitch);
      this.tempMarkerId = markerId;
    }
    dialogMarker.text = this._markers[markerId]?.text;
    let x = pointerPosition.x + ImageViewerImagePanoramaComponent._markerTextPaddingX * 2;
    if (pointerPosition.x > this.innerWidth / 2) {
      x = pointerPosition.x - 275;
    }
    this.markerDrawn.emit({ markerRef: dialogMarker, x: x, y: pointerPosition.y });
  }

  private _handleMarkerDragStart(markerElement: HTMLElement, args: MarkerArgs, event: DragEvent): void {
    if (
      !this._renderer || !this.pannellumContainerElementRef || !this.pannellumContainerElementRef.nativeElement ||
      !markerElement || !args || !event
    ) {
      return;
    }

    if (this._isMarkerHovered) {
      this._removeMarkerTextTooltip(`text-for-${args.markerId}`);
    }

    this._renderer.addClass(markerElement, 'is-invisible');

    const draggablePreview = this._renderer.createElement('div');

    this._renderer.addClass(draggablePreview, 'image-viewer-image-panorama-marker-draggable-preview');
    this._renderer.setStyle(draggablePreview, 'opacity', '0');

    this._renderer.appendChild(this.pannellumContainerElementRef.nativeElement, draggablePreview);

    this._markerDraggablePreview = draggablePreview;
  }

  private _handleMarkerDrag(markerElement: HTMLElement, args: MarkerArgs, event: DragEvent): void {
    if (
      !this._renderer || !this._markerDraggablePreview ||
      !this.pannellumContainerElementRef || !this.pannellumContainerElementRef.nativeElement ||
      !markerElement || !args || !event
    ) {
      return;
    }

    const containerClientRect = this.pannellumContainerElementRef.nativeElement.getBoundingClientRect();
    const markerPreviewClientRect = this._markerDraggablePreview.getBoundingClientRect();

    const pointerPosition = this._getPointerPosition(event);

    const prevX = (markerPreviewClientRect.left - containerClientRect.left);
    const prevY = (markerPreviewClientRect.top - containerClientRect.top);

    let x = pointerPosition.x - this._markerDraggablePreview.clientWidth / 2;
    let y = pointerPosition.y - this._markerDraggablePreview.clientHeight / 2;

    const leftBoundary = 0;
    const rightBoundary = containerClientRect.width - this._markerDraggablePreview.clientWidth;

    const topBoundary = 0;
    const bottomBoundary = containerClientRect.height - this._markerDraggablePreview.clientHeight;

    if (x < leftBoundary || rightBoundary < x) {
      x = x < leftBoundary ? leftBoundary : rightBoundary;
    }

    if (y < topBoundary || bottomBoundary < y) {
      y = y < topBoundary ? topBoundary : bottomBoundary;
    }

    if (pointerPosition.x < leftBoundary || containerClientRect.width < pointerPosition.x) {
      x = prevX;
    }

    if (pointerPosition.y < topBoundary || containerClientRect.height < pointerPosition.y) {
      y = prevY;
    }

    this._markerDraggablePreview.style.transform = `translate(${x}px, ${y}px)`;
    this._markerDraggablePreview.style.opacity = '1';
  }

  private _handleMarkerDragEnd(markerElement: HTMLElement, args: MarkerArgs, event: DragEvent): void {
    if (
      !this._pannellumViewer || !this._markerDraggablePreview ||
      !this.pannellumContainerElementRef || !this.pannellumContainerElementRef.nativeElement ||
      !markerElement || !args || !event
    ) {
      return;
    }

    const markerPreviewClientRect = this._markerDraggablePreview.getBoundingClientRect();

    this._markerDraggablePreview.remove();
    this._markerDraggablePreview = null;

    // Remove previous marker
    this._removeMarker(args.markerId);

    const containerClientRect = this.pannellumContainerElementRef.nativeElement.getBoundingClientRect();

    const markerPosition = this._getMarkerPosition({
      x: (markerPreviewClientRect.left - containerClientRect.left) + markerPreviewClientRect.width / 2,
      y: (markerPreviewClientRect.top - containerClientRect.top) + markerPreviewClientRect.height / 2,
    });

    // Create new marker with same id and text
    this._createMarker({
      id: args.markerId,
      type: 'info',
      pitch: markerPosition.pitch,
      yaw: markerPosition.yaw,
      cssClass: 'image-viewer-image-panorama-marker',
      createTooltipFunc: (element: HTMLElement, a: MarkerArgs) => this._handleMarkerCreation(element, a),
      createTooltipArgs: args,
    });

    const marker = this._markers[args.markerId];
    if (!marker) {
      return;
    }

    marker.x = markerPosition.yaw;
    marker.y = markerPosition.pitch;

    if (this.markerChanged$) {
      this.markerChanged$.emit(marker);
    }
  }

  private _handleMarkerMouseOver(markerElement: HTMLElement, args: MarkerArgs, event: MouseEvent): void {
    if (!markerElement || !args || !event || !this._markers) {
      return;
    }

    const marker = this._markers[args.markerId];
    if (!marker) {
      return;
    }

    const markerElementClientRect = markerElement.getBoundingClientRect();

    const positionOffsetX = markerElementClientRect.width / 2 - ImageViewerImagePanoramaComponent._markerTextPaddingX;
    const positionOffsetY = markerElementClientRect.height + ImageViewerImagePanoramaComponent._markerTextMargin;

    const markerTextPositionX = markerElementClientRect.left + positionOffsetX;
    const markerTextPositionY = markerElementClientRect.top + positionOffsetY;

    this._createMarkerTextTooltip(
      `text-for-${args.markerId}`,
      markerTextPositionX,
      markerTextPositionY,
      marker.text,
    );

    this._isMarkerHovered = true;
  }

  private _handleMarkerMouseLeave(markerElement: HTMLElement, args: MarkerArgs, event: MouseEvent): void {
    if (!markerElement || !args || !event) {
      return;
    }

    this._removeMarkerTextTooltip(`text-for-${args.markerId}`);

    this._isMarkerHovered = false;
  }

  private _handleMarkerContextMenu(markerElement: HTMLElement, args: MarkerArgs, event: MouseEvent): void {
    if (!markerElement || !args || !event) {
      return;
    }

    event.preventDefault();

    const x = event.clientX || event.pageX;
    const y = event.clientY || event.pageY;

    if (this._isMarkerHovered) {
      this._removeMarkerTextTooltip(`text-for-${args.markerId}`);
    }

    this._createContextMenu(args.markerId, x, y);
  }

  private _getPointerPosition(
    event: {clientX: number, clientY: number, pageX: number, pageY: number},
  ): {x: number, y: number} {
    const containerClientRect = this.pannellumContainerElementRef.nativeElement.getBoundingClientRect();
    return {
      x: (event.clientX || event.pageX) - containerClientRect.left,
      y: (event.clientY || event.pageY) - containerClientRect.top,
    };
  }

  private _getMarkerPosition(position: { x: number, y: number }): { pitch: number, yaw: number } {
    if (!this.pannellumContainerElementRef || !this.pannellumContainerElementRef.nativeElement) {
      return;
    }

    const config = this._pannellumViewer.getConfig();

    const containerWidth = this.pannellumContainerElementRef.nativeElement.clientWidth;
    const containerHeight = this.pannellumContainerElementRef.nativeElement.clientHeight;

    const x = position.x / containerWidth * 2 - 1;
    const y = (1 - position.y / containerHeight * 2) * containerHeight / containerWidth;

    const focal = 1 / Math.tan(config.hfov * Math.PI / 360);

    const sin = Math.sin(config.pitch * Math.PI / 180);
    const cos = Math.cos(config.pitch * Math.PI / 180);

    const a = focal * cos - y * sin;

    const root = Math.sqrt(x * x + a * a);

    const pitch = Math.atan((y * cos + focal * sin) / root) * 180 / Math.PI;

    let yaw = Math.atan2(x / root, a / root) * 180 / Math.PI + config.yaw;
    if (yaw < -180) {
      yaw += 360;
    } else if (yaw > 180) {
      yaw -= 360;
    }

    return {
      pitch: pitch,
      yaw: yaw,
    };
  }

  private _createContextMenu(markerId: number, x: number, y: number): void {
    if (!markerId) {
      return;
    }

    const contextMenuBackdrop = this._renderer.createElement('div');

    this._renderer.addClass(contextMenuBackdrop, 'image-viewer-image-panorama-context-menu-backdrop');

    const contextMenuElement = this._renderer.createElement('div');
    const contextMenuListElement = this._renderer.createElement('ul');

    const contextMenuItems = [
      { text: 'Edit', action: (event: MouseEvent) => this._handleMarkerCreateOrUpdate(event, markerId) },
      { text: 'Remove', action: (event: MouseEvent) => this._deleteMarker(markerId) },
    ];

    for (let i = 0, num = contextMenuItems.length; i < num; i++) {
      const item = contextMenuItems[i];

      const contextMenuItemElement = this._renderer.createElement('li');
      const contextMenuItemButtonElement = this._renderer.createElement('button');
      const contextMenuItemButtonText = this._renderer.createText(item.text);

      contextMenuItemButtonElement.type = 'button';
      contextMenuItemButtonElement.addEventListener('mousedown', (event: MouseEvent) => {
        if (event) {
          event.preventDefault();
        }

        item.action(event);
      });

      this._renderer.appendChild(contextMenuItemButtonElement, contextMenuItemButtonText);
      this._renderer.appendChild(contextMenuItemElement, contextMenuItemButtonElement);
      this._renderer.appendChild(contextMenuListElement, contextMenuItemElement);
    }

    this._renderer.addClass(contextMenuElement, 'image-viewer-image-panorama-context-menu');

    this._renderer.setStyle(contextMenuElement, 'left', `${x}px`);
    this._renderer.setStyle(contextMenuElement, 'top', `${y}px`);

    this._renderer.appendChild(contextMenuElement, contextMenuListElement);
    this._renderer.appendChild(contextMenuBackdrop, contextMenuElement);

    this._renderer.appendChild(document.body, contextMenuBackdrop);

    contextMenuBackdrop.addEventListener('click', () => {
      this._renderer.removeChild(document.body, contextMenuBackdrop);
    });
  }

  private _openMarkerDialog(markerId: number, position?: {yaw: number, pitch: number}): void {
    if (!markerId || !this.imageRef) {
      return;
    }

    const isCreateEvent = !this._markers[markerId];
    const isChangeEvent = !isCreateEvent;

    if (isCreateEvent && !position) {
      return;
    }

    let dialogTitle = 'Edit Marker';
    let dialogMarker = this._markers[markerId];

    if (isCreateEvent) {
      dialogTitle = 'Create Marker';
      dialogMarker = new models.ImageViewerImageMarkerRef(this.imageRef, position.yaw, position.pitch);
    }

    const dialogRef = this._dialogService.show(ImageViewerMarkerDialogComponent, {
      title: dialogTitle,
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 450,
      height: 'auto',
      maxHeight: 700,
      injectableData: {
        marker: dialogMarker,
      },
    });

    const hidingSubscription = dialogRef.onHiding
      .subscribe(() => {
        hidingSubscription.unsubscribe();

        if (isCreateEvent && !dialogRef.outputData) {
          this._removeMarker(markerId);
          return;
        }

        if (!dialogRef.outputData || !dialogRef.outputData.marker) {
          return;
        }

        this._markers[markerId] = dialogRef.outputData.marker;

        if (isCreateEvent && this.markerCreated$) {
          this.markerCreated$.emit(this._markers[markerId]);
        }

        if (isChangeEvent && this.markerChanged$) {
          this.markerChanged$.emit(this._markers[markerId]);
        }
      });
  }

  private _deleteMarker(markerId: number): void {
    if (!markerId) {
      return;
    }

    this._removeMarker(markerId);

    const marker = this._markers[markerId];

    if (marker && this.markerDeleted$) {
      this.markerDeleted$.emit(marker);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
