import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { UnsavedChangesGuard } from '../infrastructure/guards/unsaved-changes.guard';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { NameEditComponent } from './components/name-edit/name-edit.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { UserModule } from './user.module';
import {NoActiveUserStatusComponent} from './components/no-active-user-status/no-active-user-status.component';

const routes: Routes = [
  {path: '', component: ProfileComponent},
  {path: 'profile-edit', component: ProfileEditComponent, canDeactivate: [UnsavedChangesGuard]},
  {path: 'name-edit', component: NameEditComponent},
  {path: 'documents', component: DocumentsComponent},
  {path: 'no-active-user-status', component: NoActiveUserStatusComponent},

];

export const UserRouter: ModuleWithProviders<UserModule> = RouterModule.forChild(routes);
