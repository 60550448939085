<section class="w-auth w-auth--sing-in">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt=""/>
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">
        A balanced approach to the commercial real estate leasing process, unifying all parties during negotiations.
      </p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <h1 class="b-auth-title">
          Welcome to Statera!
        </h1><!--b-auth-title -->

        <form class="w-auth-form" name="form" novalidate
              #form="ngForm" (ngSubmit)="submit(form)">
          <div class="d-flex flex-column">
            <span class="error-msg"
                  *ngIf="form.submitted && eml.hasError('email')">
                Incorrect email
            </span>

            <span class="error-msg"
                  *ngIf="form.submitted && eml.hasError('required')">
              Required
            </span>

            <p class="b-label">
              Work Email
            </p>
            <input class="b-input" email required type="email" name="eml" id="eml"
                   [(ngModel)]="vm.emailAddress" #eml="ngModel"
                   [class.is-invalid]="form.submitted && (eml.hasError('required') || eml.hasError('email'))">
          </div><!--d-flex-->

          <div class="d-flex flex-column pt-4">
            <span class="error-msg"
                  *ngIf="form.submitted && pwd.hasError('required')">
              Required
            </span>

            <p class="b-label">
              Password
            </p>
            <input class="b-input" required type="password" name="pwd" id="pwd"
                   [(ngModel)]="vm.password" #pwd="ngModel"
                   [class.is-invalid]="form.submitted && pwd.hasError('required')">
          </div><!--d-flex-->

          <div class="d-flex flex-column align-items-center pt-4 w-100">
            <button class="btn btn-sign-in">
              <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="text-uppercase">
                sign in
              </span>
            </button>
          </div><!--d-flex-->
        </form>

        <div class="w-auth-controls text-center">
          <a [routerLink]="['../forgot-password']" class="b-fancy-link">
            Forgot password?
          </a>
        </div>

        <form #signInForm novalidate ngNoForm method="POST" action="/signin">
          <input type="hidden" name="emailAddress" id="emailAddress" [value]="eml.value">
          <input type="hidden" name="password" id="password" [value]="pwd.value">
        </form>

        <div class="w-auth-help-text">
          If you don't have an account.

          <a [routerLink]="['../signup']" class="b-fancy-link">
            sign up
          </a>
        </div><!--w-auth-help-text-->
      </div><!--login-content-->
    </section><!--login-->
  </div><!--w-auth-content-->
</section><!--w-auth-->

<app-footer></app-footer>
