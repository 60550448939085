import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { OrderByPipe } from '../../../infrastructure/pipes/order-by.pipe';

import * as models from '../../../infrastructure/models/generated';

export interface TableRow {
  buildingId: number;
  buildingUnitId: number;
  tenantCompanyName: string;
  buildingAddress: string;
  unit: string;
  squareFeet: number;
  leaseStatus: models.OccupancyAndVacancyReportLeaseStatus;
  nextLeaseStatus: models.OccupancyAndVacancyReportLeaseStatus;
  leaseStatusDisplay: string;
  nextLeaseStatusDisplay: string;
  nextLeaseCommencement: Date | string;
  leaseExpirationDate: Date | string;
  renewalIndex: number;
  inActiveLease: boolean;
  leaseId: number;
}

@Component({
  selector: 'app-analytics-occupancy-and-vacancy-report-single-period-table',
  templateUrl: 'occupancy-and-vacancy-report-single-period-table.component.html',
  styleUrls: ['occupancy-and-vacancy-report-single-period-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OccupancyAndVacancyReportSinglePeriodTableComponent {
  @Input() set occupancyAndVacancyReportPeriod(value: models.IOccupancyAndVacancyReportPeriodViewModel) {
    this._occupancyAndVacancyReportPeriod = value;
    this.tableData = this.getTableData();
  }

  get occupancyAndVacancyReportPeriod(): models.IOccupancyAndVacancyReportPeriodViewModel {
    return this._occupancyAndVacancyReportPeriod;
  }

  LeaseStatus: typeof models.OccupancyAndVacancyReportLeaseStatus = models.OccupancyAndVacancyReportLeaseStatus;

  orderByConfig: string;
  tableData: Array<TableRow>;

  private readonly _router: Router;
  private readonly _orderByPipe: OrderByPipe;
  private readonly _dialogRefService: DialogRefService;
  private _occupancyAndVacancyReportPeriod: models.IOccupancyAndVacancyReportPeriodViewModel;

  constructor(router: Router, orderByPipe: OrderByPipe, dialogRefService: DialogRefService) {
    this._router = router;
    this._orderByPipe = orderByPipe;
    this._dialogRefService = dialogRefService;
  }

  getTableData(): Array<TableRow> {
    if (
      !this.occupancyAndVacancyReportPeriod ||
      !this.occupancyAndVacancyReportPeriod.table ||
      !this.occupancyAndVacancyReportPeriod.table.length
    ) {
      return [];
    }

    return this.occupancyAndVacancyReportPeriod.table
      .map(record => {
        return <TableRow>{
          buildingId: record.buildingId,
          buildingUnitId: record.buildingUnitId,
          tenantCompanyName: record.tenantCompany && record.tenantCompany.name,
          buildingAddress: record.buildingAddress,
          unit: record.buildingUnitName,
          squareFeet: record.squareFeet,
          leaseStatus: record.leaseStatus,
          nextLeaseStatus: record.nextLeaseStatus,
          nextLeaseCommencement: record.nextLeaseCommencementDate,
          leaseStatusDisplay: record.leaseStatusDisplay,
          nextLeaseStatusDisplay: record.nextLeaseStatusDisplay,
          leaseExpirationDate: record.leaseExpirationDate,
          renewalIndex: record.renewalIndex,
          inActiveLease: record.inActiveLease,
          leaseId: record.leaseId
        };
      });
  }

  applyColumnOrder(columnName: string): void {
    this.orderByConfig = columnName === this.orderByConfig
      ? `-${columnName}`
      : columnName;

    this.tableData = this._orderByPipe.transform(this.tableData, this.orderByConfig);
  }

  navigate(row: TableRow): void {
    if (!row.leaseId && !row.buildingId) {
      return;
    }

    this._dialogRefService.hideAll();

    if (row.inActiveLease && row.leaseId) {
      this._router.navigate(['colabo', 'main', row.leaseId]);
    } else {
      this._router.navigate(['landlord', 'buildings', 'building-desktop', row.buildingId], {
        queryParams: {
          'tab': 'units',
          'searchText': row.unit
        }
      });
    }
  }
}
