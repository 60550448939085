import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { TermManager } from '@statera/sdk/term';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-lease-term',
  templateUrl: './lease-term.component.html',
  styleUrls: ['./lease-term.component.scss']
})
export class LeaseTermComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('termValueElementRef') termValueElementRef: ElementRef;
  @ViewChild('termTextElementRef') termTextElementRef: ElementRef;

  @Input() termValue: string;
  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTermConfiguration: models.ILeaseTermConfiguration;
  @Input() numberOfLines: number;
  @Input() showAmendmentNumber: boolean;
  @Input() amendmentNumber: number;
  @Input() isCurrentTermValue: boolean;
  @Input() showThirdPartyLease: boolean;
  @Input() project: models.IProjectViewModel;
  @Input() documentSection: string;

  leaseTerm: string;
  LeaseTermType = models.LeaseTermType;
  BaseRentalRateType = models.BaseRentalRateType;
  ProjectType = models.ProjectTypeEnum;

  isExpanded: boolean;
  isOverflow: boolean;

  private readonly _termManager: TermManager;
  private readonly _ngZone: NgZone;

  private readonly _destroy$: Subject<void>;
  private readonly _termValueChange$: ReplaySubject<string>;

  constructor(termManager: TermManager, ngZone: NgZone) {
    this._termManager = termManager;
    this._ngZone = ngZone;
    this._destroy$ = new Subject<void>();
    this._termValueChange$ = new ReplaySubject<string>(1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    // This block triggers re-rendering when termValue, lease or leaseTermConfigurations props are changed
    if (
      this._isTermValueChanged(changes) ||
      this._isLeaseValueChanged(changes) ||
      this._isLeaseTermConfigurationChanged(changes)
    ) {
      let currentTermValue = this.leaseTerm;
      if (changes.termValue && changes.termValue.currentValue) {
        currentTermValue = changes.termValue.currentValue;
      }

      let currentLease = this.lease;
      if (changes.lease && changes.lease.currentValue) {
        currentLease = changes.lease.currentValue;
      }

      let currentLeaseTermConfig = this.leaseTermConfiguration;
      if (changes.leaseTermConfiguration && changes.leaseTermConfiguration.currentValue) {
        currentLeaseTermConfig = changes.leaseTermConfiguration.currentValue;
      }

      let leaseTermValue: string;
      if (currentTermValue) {
        leaseTermValue = currentTermValue;
      } else {
        leaseTermValue = this._termManager.getLeaseTermValue(currentLeaseTermConfig, currentLease);
      }

      if (leaseTermValue && currentLeaseTermConfig.leaseTermType === models.LeaseTermType.BaseRentalRate) {
        if (currentLease) {
          const baseRentalRateTerm = <models.IBaseRentalRateViewModel>this._termManager
            .getLeaseTerm(currentLease, currentLeaseTermConfig.leaseTermType);

          if (baseRentalRateTerm && baseRentalRateTerm.baseRentalRateType !== models.BaseRentalRateType.RequestMultiplyOptions) {
            const isMultipleOptions = baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions;

            leaseTermValue = ['Starting Base Rental Rate:', leaseTermValue].join(isMultipleOptions ? '\n' : ' ');
          }
        } else {
          leaseTermValue = `Starting Base Rental Rate: ${leaseTermValue}`;
        }
      }

      this.leaseTerm = leaseTermValue;

      this._termValueChange$.next(leaseTermValue);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    let previousWindowWidth = window.innerWidth;

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        tap(() => {
          const currentWindowWidth = window.innerWidth;
          if (previousWindowWidth - currentWindowWidth !== 0) {
            this._checkTermValueTextOverflow(this.leaseTerm);

            previousWindowWidth = currentWindowWidth;
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._termValueChange$
      .pipe(
        tap(termValue => this._checkTermValueTextOverflow(termValue)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  shouldShowCurrentBadge(): boolean {
    if (!this.leaseTermConfiguration || this.leaseTermConfiguration.leaseTermType === models.LeaseTermType.FreeRent) {
      return false;
    }

    return this.isCurrentTermValue;
  }

  shouldShowRenewedBadge(): boolean {
    if (
      !this.lease ||
      !this.lease.commencementTerm ||
      !this.leaseTermConfiguration ||
      this.leaseTermConfiguration.leaseTermType === models.LeaseTermType.FreeRent
    ) {
      return false;
    }

    const today = moment().startOf('day');

    const commencement = moment(this.lease.commencementTerm.commencement).startOf('day');
    if (!commencement.isValid()) {
      return false;
    }

    return (
      !this.isCurrentTermValue &&
      (
        today.isBefore(commencement) &&
        this.lease.leaseStatus === models.LeaseStatus.Active
      )
    );
  }

  getAmendmentBadgeText(): string {
    if (this.amendmentNumber > 0) {
      return `Amendment ${this.amendmentNumber}`;
    }

    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal &&
      this.project.projectStatus === models.ProjectStatus.Active) {
      return;
    }

    return 'Original';
  }

  private _checkTermValueTextOverflow(termValue: string): void {
    this._ngZone.runOutsideAngular(() => {
      const getElementWidth = (element: HTMLElement): number => element.getBoundingClientRect()?.width ?? 0;

      const timeoutCallback = () => {
        if (
          !this.termValueElementRef || !this.termValueElementRef.nativeElement ||
          !this.termTextElementRef || !this.termTextElementRef.nativeElement || !termValue
        ) {
          return;
        }

        const linesNumber = this._getLinesNumber(termValue);
        if (this.numberOfLines < linesNumber) {
          this.isOverflow = true;
          return;
        }

        const textElement = this.termTextElementRef.nativeElement;

        const textStyle = getComputedStyle(textElement);

        const fontSize = textStyle.fontSize;
        const fontFamily = textStyle.fontFamily;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = `${fontSize} ${fontFamily}`;

        let textWidth = context.measureText(termValue).width;
        const containerWidth = getElementWidth(textElement);

        const popoverTriggerWrapper = textElement.querySelector('.popover-trigger-wrapper');
        if (popoverTriggerWrapper) {
          const popoverWidth = getElementWidth(popoverTriggerWrapper);

          textWidth += popoverWidth;
        }

        this.isOverflow = this.numberOfLines < textWidth / containerWidth;
      };

      setTimeout(() => this._ngZone.run(timeoutCallback));
    });
  }

  private _isTermValueChanged(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.termValue &&
      (
        changes.termValue.isFirstChange() ||
        (
          (changes.termValue.previousValue && changes.termValue.currentValue) &&
          changes.termValue.previousValue !== changes.termValue.currentValue
        )
      )
    );
  }

  private _isLeaseValueChanged(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.lease &&
      (
        changes.lease.isFirstChange() ||
        (
          (changes.lease.previousValue && changes.lease.currentValue) &&
          changes.lease.previousValue.id !== changes.lease.currentValue.id
        )
      )
    );
  }

  private _isLeaseTermConfigurationChanged(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.leaseTermConfiguration &&
      (
        changes.leaseTermConfiguration.isFirstChange() ||
        (
          (changes.leaseTermConfiguration.previousValue && changes.leaseTermConfiguration.currentValue) &&
          changes.leaseTermConfiguration.previousValue.id !== changes.leaseTermConfiguration.currentValue.id
        )
      )
    );
  }

  private _getLinesNumber(termValue: string): number {
    let linesNumber = 1;
    for (let i = 0, num = termValue.length; i < num; i++) {
      const char = termValue.charCodeAt(i);

      if (char === 0x0A /** line feed */) {
        linesNumber++;
      }
    }

    return linesNumber;
  }
}
