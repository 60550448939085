import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { StorageService } from '../../../../infrastructure/services/storage.service';
import { ApplicationQuery } from '../../../../shared/states/application/application.query';

import { IPortfolioViewModel } from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-aside-dropdown',
  templateUrl: './aside-dropdown.component.html',
  styleUrls: ['./aside-dropdown.component.scss'],
})
export class AsideDropdownComponent implements OnInit, OnDestroy {
  @Input() portfolio: IPortfolioViewModel;
  @Input() isCollapsed = true;

  @Output() collapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dropdownElementRef') dropdownElementRef: ElementRef;
  @ViewChild('dropdownLinkElementRef') dropdownLinkElementRef: ElementRef;
  @ViewChild('dropdownContentElementRef') dropdownContentElementRef: ElementRef;

  selectedPortfolioId$: Observable<number>;

  private readonly _storageService: StorageService;
  private readonly _applicationQuery: ApplicationQuery;
  private readonly _destroy$: Subject<void>;

  constructor(storageService: StorageService, applicationQuery: ApplicationQuery) {
    this._storageService = storageService;
    this._applicationQuery = applicationQuery;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.selectedPortfolioId$ = this._applicationQuery.getSelectedPortfolio$;

    this.selectedPortfolioId$
      .pipe(
        takeUntil(this._destroy$),
        tap(selectedPortfolio => {
          if (this.portfolio) {
            this.isCollapsed = this.portfolio.id !== selectedPortfolio;
          }

          this._collapse(this.isCollapsed);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;

    this.collapsed.emit(this.isCollapsed);

    this._collapse(this.isCollapsed);
  }

  private _collapse(isCollapsed: boolean): void {
    if (
      !this.dropdownElementRef || !this.dropdownElementRef.nativeElement ||
      !this.dropdownLinkElementRef || !this.dropdownLinkElementRef.nativeElement ||
      !this.dropdownContentElementRef || !this.dropdownContentElementRef.nativeElement
    ) {
      return;
    }

    const linkElementClientRect = this.dropdownLinkElementRef.nativeElement.getBoundingClientRect();
    const contentElementClientRect = this.dropdownContentElementRef.nativeElement.getBoundingClientRect();

    let dropdownHeight = 45;
    if (!isCollapsed) {
      dropdownHeight = <number>linkElementClientRect.height + <number>contentElementClientRect.height;
    }

    this.dropdownElementRef.nativeElement.style.height = `${dropdownHeight}px`;
  }
}
