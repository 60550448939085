import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, debounceTime, skip, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsFiltersManager } from '@statera/sdk/analytics-filters';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

export const ANALYTICS_FILTERS_CHANGE_DEBOUNCE_TIME = 1500;

enum FilterType {
  Portfolio = 'Portfolio',
  Location = 'Location',
  Deal = 'Deal Type',
  Size = 'Size',
  Lease = 'Lease expiration',
  Tenant = 'Tenant company',
  Industry = 'Industry',
  Space = 'Space use'
}

@Component({
  selector: 'app-analytics-filters',
  templateUrl: 'analytics-filters.component.html',
  styleUrls: ['analytics-filters.component.scss'],
})
export class AnalyticsFiltersComponent implements OnInit, OnDestroy {
  @Output() selectedAnalyticsFiltersChange: EventEmitter<analyticsFiltersModels.AnalyticsFilters>;

  isLoaded: boolean;

  analyticsFilters: BehaviorSubject<analyticsFiltersModels.AnalyticsFilters>;
  visibleAnalyticsFilters: analyticsFiltersModels.AnalyticsFilters;

  selectedFilters: Array<analyticsFiltersModels.AnyFilter>;

  filterLinks = [
    {text: FilterType.Portfolio},
    {text: FilterType.Location},
    {text: FilterType.Deal},
    {text: FilterType.Size},
    {text: FilterType.Lease},
    {text: FilterType.Tenant},
    {text: FilterType.Industry},
    {text: FilterType.Space}
  ];

  filterType: typeof FilterType;

  private readonly _analyticsFiltersManager: AnalyticsFiltersManager;
  private readonly _destroy: Subject<void>;

  constructor(analyticsFiltersManager: AnalyticsFiltersManager) {
    this._analyticsFiltersManager = analyticsFiltersManager;
    this._destroy = new Subject<void>();

    this.analyticsFilters = new BehaviorSubject<analyticsFiltersModels.AnalyticsFilters>(null);

    this.selectedAnalyticsFiltersChange = new EventEmitter<analyticsFiltersModels.AnalyticsFilters>();
  }

  ngOnInit(): void {
    this.isLoaded = false;

    this.filterType = FilterType;

    this._analyticsFiltersManager
      .getAnalyticsFilters()
      .pipe(
        tap((analyticsFilters) => {
          this.analyticsFilters.next(analyticsFilters);
        }),
        tap(() => this.isLoaded = true),
        catchError(err => {
          this.isLoaded = true;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.analyticsFilters
      .pipe(
        skip(1),
        tap(analyticsFilters => {
          this.visibleAnalyticsFilters = this._analyticsFiltersManager.getVisibleAnalyticsFilters(analyticsFilters);
          this.selectedFilters = this._analyticsFiltersManager.getSelectedFilters(analyticsFilters);
        }),
        debounceTime(ANALYTICS_FILTERS_CHANGE_DEBOUNCE_TIME),
        tap(analyticsFilters => {
          const selectedAnalyticsFilters = this._analyticsFiltersManager.getSelectedAnalyticsFilters(analyticsFilters);
          this.selectedAnalyticsFiltersChange.emit(selectedAnalyticsFilters);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getFilterName<T extends analyticsFiltersModels.AnyFilter>(filter: T): string {
    return this._analyticsFiltersManager.getFilterName<T>(filter);
  }

  getSelectedFiltersCount<T extends analyticsFiltersModels.AnyFilter>(filters: Array<T>): number {
    return this._analyticsFiltersManager.getSelectedFiltersCount<T>(filters);
  }

  getSelectedLocationFiltersCount(): number {
    const analyticsFilters = this.analyticsFilters.getValue();

    return this._analyticsFiltersManager.getSelectedLocationFiltersCount(analyticsFilters);
  }

  isLocationFiltersAvailable(): boolean {
    const analyticsFilters = this.analyticsFilters.getValue();

    return this._analyticsFiltersManager.isLocationFiltersAvailable(analyticsFilters);
  }

  //
  // Cleaning logic
  //

  clearFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearFilter(filter: analyticsFiltersModels.AnyFilter): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearFilter(analyticsFilters, filter);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearPortfolioFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearPortfolioFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearLocationFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearLocationFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearLeaseExpirationFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearLeaseExpirationFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearDealTypeFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearDealTypeFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearTenantCompanyFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearTenantCompanyFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearIndustryFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearIndustryFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  clearSpaceUseFilters(): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .clearSpaceUseFilters(analyticsFilters);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  //
  // Change handlers
  //

  handlePortfolioFilterChange(portfolios: Array<analyticsFiltersModels.PortfolioFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handlePortfolioFilterChange(analyticsFilters, portfolios);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationStateFilterChange(locationStates: Array<analyticsFiltersModels.LocationStateFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationStateFilterChange(analyticsFilters, locationStates);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationCityFilterChange(locationCities: Array<analyticsFiltersModels.LocationCityFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationCityFilterChange(analyticsFilters, locationCities);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationMarketFilterChange(locationMarkets: Array<analyticsFiltersModels.LocationMarketFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationMarketFilterChange(analyticsFilters, locationMarkets);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationSubMarketFilterChange(locationSubMarkets: Array<analyticsFiltersModels.LocationSubMarketFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationSubMarketFilterChange(analyticsFilters, locationSubMarkets);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationZipCodeFilterChange(locationZipCodes: Array<analyticsFiltersModels.LocationZipCodeFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationZipCodeFilterChange(analyticsFilters, locationZipCodes);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLocationAddressFilterChange(locationAddresses: Array<analyticsFiltersModels.LocationAddressFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLocationAddressFilterChange(analyticsFilters, locationAddresses);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleDealTypeFilterChange(dealTypes: Array<analyticsFiltersModels.DealTypeFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleDealTypeFilterChange(analyticsFilters, dealTypes);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleLeaseExpirationFilterChange(leaseExpirations: Array<analyticsFiltersModels.LeaseExpirationFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleLeaseExpirationFilterChange(analyticsFilters, leaseExpirations);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleTenantCompanyFilterChange(tenantCompanies: Array<analyticsFiltersModels.TenantCompanyFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleTenantCompanyFilterChange(analyticsFilters, tenantCompanies);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleIndustryFilterChange(industries: Array<analyticsFiltersModels.IndustryFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleIndustryFilterChange(analyticsFilters, industries);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleSpaceUseFilterChange(spaceUses: Array<analyticsFiltersModels.SpaceUseFilter>): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleSpaceUseFilterChange(analyticsFilters, spaceUses);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }

  handleSquareFeetRangeFilterChange(squareFeetRange: analyticsFiltersModels.SquareFeetRangeFilter): void {
    const analyticsFilters = this.analyticsFilters.getValue();

    const updatedAnalyticsFilters = this._analyticsFiltersManager
      .handleSquareFeetRangeFilterChange(analyticsFilters, squareFeetRange);

    this.analyticsFilters.next(updatedAnalyticsFilters);
  }
}
