<div class="d-flex flex-column flex-grow-1 scroll-view-container"
     #scrollViewContainer>
  <dx-scroll-view class="flex-grow-1"
                  (onScroll)="onMessagesScroll($event)" #scrollView>
    <ng-container *ngIf="messages.length; else noMessages">
      <div *ngFor="let item of messages" #messageElements>
        <div class="b-message"
             [class.b-message--self]="item.isMy"
             [class.b-message--broker]="item.isBrokerMessage"
             [class.active]="tooltipVisible && currentTooltipMessageId === item.id">
          <img class="b-message-avatar"
               [src]="item.authorAvatarUrl" />
          <div class="b-message-body position-relative">
            <div class="b-message-body-content">
              <div class="b-message-body-text"
                   [innerHtml]="item?.innerHtml">
              </div>
            </div>
            <div class="b-message-details position-absolute d-flex align-items-center">
              <i class="message-icon-edited"
                 inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"
                 *ngIf="item.edited">
              </i>
              <time class="b-message-date">
                {{ getDate(item?.createdOn) }} at {{ item?.createdOn | safeDate: 'hh:mm aaa' }}
              </time>
              <i class="message-icon-read"
                 inlineSVG="assets/img/sign-icons.svg#check-status-icon"
                 *ngIf="item.isMy && item.isRead">
              </i>
            </div>
          </div>
          <div class="b-message-more-button"
               *ngIf="((allowEdit || allowDelete) && item.isMy && !disabled) && item?.messageKind !== ChatMessageKind.FloorPlanBudgetChange"
               (click)="messageMoreClick($event, item.id)">
            <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-more-horizontal-24"></i>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noMessages>
      <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1 no-message">
        <span class="no-message__icon" inlineSVG="assets/img/sign-icons.svg#comment-icon"></span>
        <span class="no-message__text">No {{ context === MessengerContext.Chat ? 'Messages' : 'Comments' }}  Yet :(</span>
        <span class="no-message__conv">Send a message to start a conversation</span>
      </div>
    </ng-template>
  </dx-scroll-view>

  <ng-container *ngIf="allowAdd || allowEdit">
    <div class="w-colabo-chat-editing-status"
         *ngIf="editing">
      <div>
        <div class="w-colabo-chat-editing-status-icon">
          <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
        </div>
        <div class="w-colabo-chat-editing-status-content">
          <div class="w-colabo-chat-editing-status-content-message-caption">
            edit message
          </div>
          <div class="w-colabo-chat-editing-status-content-message"
               [innerHTML]="currentEditingMessage.content">
          </div>
        </div>
      </div>
      <div>
        <button type="button"
                (click)="cancelEditingMessage()">
          <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-x-26"></i>
        </button>
      </div>
    </div>
    <div class="w-colabo-chat-editor d-flex"
         *ngIf="allowAdd || (editing && allowEdit)"
         #editorContainer>
      <dx-html-editor class="messages-editor"
                      [placeholder]="placeholder" [(value)]="htmlEditorValue"
                      [disabled]="disabled"
                      (onInitialized)="htmlEditorInitialized($event)"
                      (onContentReady)="htmlEditorContentReady()"
                      #htmlEditor>
      </dx-html-editor>

      <button *ngIf="showEmojiPicker" class="b-btn btn btn-emoji d-flex justify-content-center align-items-center"
              [disabled]="disabled"
              [popover]="emojiPopoverTemplate" container="body" placement="top left"
              containerClass="emoji-popover" [outsideClick]="false"
              (onShown)="handleEmojiPopoverShown()"
              (onHidden)="handleEmojiPopoverHidden()"
              #emojiPopover="bs-popover"
              #emojiPopoverTrigger>
        <span inlineSVG="assets/v2/icons/colabo-icons.svg#icon-emoji"></span>
      </button>
      <ng-template #emojiPopoverTemplate>
        <app-emoji-picker (picked)="handleEmojiPicked($event)"
                          #emojiPopoverEmojiPicker>
        </app-emoji-picker>
      </ng-template>

      <button *ngIf="showFileUploader" id="menuButton" class="b-btn btn btn-attachment d-flex justify-content-center align-items-center" [disabled]="disabled"
                 (click)="bottomMenuVisible = true">
        <span inlineSVG="assets/img/sign-icons.svg#attachment-icon"></span>
      </button>

      <button class="b-btn btn btn-colabo-send-message" [disabled]="disabled"
              (click)="sendMessage()">
        <span inlineSVG="assets/img/sign-icons.svg#send-icon"></span>
      </button>
    </div>
  </ng-container>

</div>

<dx-context-menu target="#menuButton"
                 [cssClass]="'html-editor-menu'"
                 [dataSource]="items" [position]="{ my: 'bottom right', at: 'top right' }" [disabled]="disabled"
                 [(visible)]="bottomMenuVisible"
                 (onItemClick)="textAreaMenuItemClick($event)" #bottomMenu>
</dx-context-menu>

<dx-tooltip position="left"
            class="white-tooltip"
            [width]="150" [showEvent]="'click'" [(visible)]="tooltipVisible"
            #messageMenuToolTip>
  <div class="d-flex flex-column tooltip-menu d-flex flex-column"
       *dxTemplate="let data = data; of: 'content'">
      <a class="nav-link  text-14 header-text d-flex edit" href="javascript:;"
         (click)="startEditingMessage()">
        <i inlineSVG="../../../../assets/img/messages/messages-icons.svg#edit"></i>
        Edit
      </a>
      <a class="nav-link  text-14 header-text d-flex delete" href="javascript:;"
         (click)="startDeletingMessage()">
        <i inlineSVG="../../../../assets/img/messages/messages-icons.svg#trash"></i>
        Delete
      </a>
  </div>
</dx-tooltip>

<dx-file-uploader id="file" name="file"
                  [uploadUrl]="imageUploadUrl" [visible]="false" [disabled]="disabled"
                  (onUploaded)="onImageUploaded($event)" (onUploadError)="onImageUploadError($event)"
                  (onUploadAborted)="onImageUploadAborted($event)" #imageUploader>
</dx-file-uploader>

<dx-popup title="Attach file"
          [showTitle]="true" [deferRendering]="false" [maxWidth]="500" [height]="350" [(visible)]="filePopupVisible">
  <dx-box class="popup-box" direction="col" width="100%">
    <dxi-item [ratio]="10">
      <dx-file-uploader id="file" name="file"
                        [uploadUrl]="fileUploadUrl"
                        (onUploaded)="onFileUploaded($event)" (onUploadError)="onFileUploadError($event)"
                        (onUploadAborted)="onFileUploadAborted($event)" #fileUploader>
      </dx-file-uploader>
    </dxi-item>
    <dxi-item [baseSize]="25">
      <div class="d-flex flex-row-reverse">
        <dx-button text="Attach" type="success"
                   (onClick)="filePopupAttachClick()">
        </dx-button>
        <dx-button text="Cancel" class="mr-2"
                   (onClick)="filePopupCancelClick()">
        </dx-button>
      </div>
    </dxi-item>
  </dx-box>
</dx-popup>
