<dx-scroll-view width="100%" [showScrollbar]="'always'">
  <table class="table">
    <thead>
    <tr>
      <th [class.order-desc]="orderByConfig === '-tenantCompanyName'"
          [class.order-asc]="orderByConfig === 'tenantCompanyName'"
          (click)="applyColumnOrder('tenantCompanyName')">
        Tenant
      </th>
      <th [class.order-desc]="orderByConfig === '-buildingAddress'"
          [class.order-asc]="orderByConfig === 'buildingAddress'"
          (click)="applyColumnOrder('buildingAddress')">
        Building Address
      </th>
      <th [class.order-desc]="orderByConfig === '-unit'"
          [class.order-asc]="orderByConfig === 'unit'"
          (click)="applyColumnOrder('unit')">
        Unit
      </th>
      <th [class.order-desc]="orderByConfig === '-squareFeet'"
          [class.order-asc]="orderByConfig === 'squareFeet'"
          (click)="applyColumnOrder('squareFeet')">
        Size
      </th>
      <th [class.order-desc]="orderByConfig === '-status'"
          [class.order-asc]="orderByConfig === 'status'"
          (click)="applyColumnOrder('status')">
        Status
      </th>
      <th [class.order-desc]="orderByConfig === '-totalDaysInStage'"
          [class.order-asc]="orderByConfig === 'totalDaysInStage'"
          (click)="applyColumnOrder('totalDaysInStage')">
        Total Days
      </th>
      <th [class.order-desc]="orderByConfig === '-daysInStageTenant'"
          [class.order-asc]="orderByConfig === 'daysInStageTenant'"
          (click)="applyColumnOrder('daysInStageTenant')">
        By Tenant
      </th>
      <th [class.order-desc]="orderByConfig === '-daysInStageLandlord'"
          [class.order-asc]="orderByConfig === 'daysInStageLandlord'"
          (click)="applyColumnOrder('daysInStageLandlord')">
        By Landlord
      </th>
      <th [class.order-desc]="orderByConfig === '-totalDaysInStageVsBenchmark'"
          [class.order-asc]="orderByConfig === 'totalDaysInStageVsBenchmark'"
          (click)="applyColumnOrder('totalDaysInStageVsBenchmark')">
        vs Benchmark
      </th>
      <th [class.order-desc]="orderByConfig === '-totalDaysInStageVsAverage'"
          [class.order-asc]="orderByConfig === 'totalDaysInStageVsAverage'"
          (click)="applyColumnOrder('totalDaysInStageVsAverage')">
        vs Average
      </th>
    </tr>
    </thead>
    <tbody>
    <tr title="Go to deal"
        *ngFor="let tableRow of tableData;"
        (click)="navigateTo3PanelView(tableRow)">
      <!-- Start: Tenant Company Name -->
      <td>
        {{tableRow.tenantCompanyName}}
      </td>
      <!-- End: Tenant Company Name -->
      <!-- Start: Building Address -->
      <td>
        {{tableRow.buildingAddress}}
      </td>
      <!-- End: Building Address -->
      <!-- Start: Unit -->
      <td>
        {{tableRow.unit}}
      </td>
      <!-- End: Unit -->
      <!-- Start: Square Feet -->
      <td style="white-space: pre-line;">
        {{tableRow.squareFeet}}
      </td>
      <!-- End: Square Feet -->
      <!-- Start: Status -->
      <td>
        {{tableRow.status}}
      </td>
      <!-- End: Status -->
      <!-- Start: Total Days -->
      <td>
        {{tableRow.totalDaysInStage}}
      </td>
      <!-- End: Total Days -->
      <!-- Start: By Tenant -->
      <td>
        {{tableRow.daysInStageTenant}}
      </td>
      <!-- End: By Tenant -->
      <!-- Start: By Landlord -->
      <td>
        {{tableRow.daysInStageLandlord}}
      </td>
      <!-- End: By Landlord -->
      <!-- Start: Comparison with Benchmark -->
      <td>
        <ng-container *ngIf="tableRow.totalDaysInStageVsBenchmark | safeNumber: '1.0-0' | abs as days; else asUsual">
          <span class="lease-status-badge badge-success"
                *ngIf="tableRow.totalDaysInStageVsBenchmark < 0">
            -{{days}}
          </span>

          <span class="lease-status-badge badge-danger"
                *ngIf="0 < tableRow.totalDaysInStageVsBenchmark">
            +{{days}}
          </span>
        </ng-container>
        <ng-template #asUsual>
          –
        </ng-template>
      </td>
      <!-- End: Comparison with Benchmark -->
      <!-- Start: Comparison with Average -->
      <td>
        <ng-container *ngIf="tableRow.totalDaysInStageVsAverage | safeNumber: '1.0-0' | abs as days; else asUsual">
          <span class="lease-status-badge badge-success"
                *ngIf="tableRow.totalDaysInStageVsAverage < 0">
            -{{days}}
          </span>
          <span class="lease-status-badge badge-danger"
                *ngIf="0 < tableRow.totalDaysInStageVsAverage">
            +{{days}}
          </span>
        </ng-container>
        <ng-template #asUsual>
          –
        </ng-template>
        <span class="arrow"></span>
      </td>
      <!-- End: Comparison with Average -->
    </tr>
    </tbody>
  </table>
</dx-scroll-view>
