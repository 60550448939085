import { Injectable } from '@angular/core';

import * as models from './term-use.model';
import { TermUseRepository } from './term-use.repository';


@Injectable()
export class TermUseManager {
  private readonly _termUseRepository: TermUseRepository;

  constructor(termUseRepository: TermUseRepository) {
    this._termUseRepository = termUseRepository;
  }

  getOptions(project: models.Project): Array<{name: string, value: models.SpaceUseTermType}> {
    if (project && project.projectTypeId === models.ProjectType.NewDeal) {
      return [
        {
          name: 'Please submit the intended Use and occupancy of the premises',
          value: models.SpaceUseTermType.None,
        },
        {
          name: 'Custom',
          value: models.SpaceUseTermType.Custom,
        },
      ];
    }
    return [
      {
        name: 'Per current lease',
        value: models.SpaceUseTermType.PerCurrentLease,
      },
      {
        name: 'Please submit the intended Use and occupancy of the premises',
        value: models.SpaceUseTermType.None,
      },
      {
        name: 'Custom',
        value: models.SpaceUseTermType.Custom,
      },
    ];
  }

  getValues(): Array<models.SpaceUseOption> {
    return [
      {
        id: 'office',
        text: 'Office',
        hasProduct: false,
      },
      {
        id: 'manufacturing',
        text: 'Manufacturing',
        hasProduct: true,
      },
      {
        id: 'distribution',
        text: 'Distribution',
        hasProduct: true,
      },
      {
        id: 'cold_storage',
        text: 'Cold Storage',
        hasProduct: true,
      },
      {
        id: 'food_processing',
        text: 'Food Processing',
        hasProduct: false,
      },
      {
        id: 'warehouse',
        text: 'Warehouse',
        hasProduct: true,
      },
      {
        id: 'data_center',
        text: 'Data Center',
        hasProduct: false,
      },
      {
        id: 'truck_terminal',
        text: 'Truck Terminal',
        hasProduct: true,
      },
      {
        id: 'showroom',
        text: 'Showroom',
        hasProduct: true,
      },
      {
        id: 'production',
        text: 'Production',
        hasProduct: true,
      },
      {
        id: 'rnd',
        text: 'R&D',
        hasProduct: true,
      },
    ];
  }
}
