<form name="form" class="h-100" novalidate
      (ngSubmit)="submit()" #form="ngForm">
  <div class="d-flex flex-column h-100">
    <!-- Start: Table content -->
    <div class="flex-grow-1 flex-shrink-1 overflow-hidden mh-0">
      <dx-scroll-view width="100%" height="100%">
        <div class="popup-content">
          <div class="alert alert-danger"
               *ngIf="form.submitted && !isBurnDownScheduleValid()">
            <ul class="errors">
              <li *ngFor="let errorMessage of getBurnDownScheduleErrorMessages()">
                {{errorMessage}}
              </li>
            </ul>
          </div>

          <!-- Start: Burn Down Schedule Table -->
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th colspan="2">
                  <div class="schedule-title">
                    <span>burn down schedule:</span>
                    <button type="button" class="b-btn-icon-info"
                            (click)="$event.preventDefault()" #burndownTooltip>
                      <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
                    </button>
                    <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                                [width]="250" [target]="burndownTooltip">
                      <div class="d-flex flex-column tooltip-menu">
                        This option allows you to create a burn down of the Security Deposit amount over a period of time by adding specific dates and $ amounts.
                      </div>
                    </dx-tooltip>
                  </div>
                </th>
                <th colspan="2">
                  <span class="deposit-title">security deposit amount: $100</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align-middle w-40">Lease Month</td>
                <td class="align-middle w-40">Date Reduced</td>
                <td class="align-middle w-40">Security Deposit Amount</td>
                <td class="align-middle w-20"></td>
              </tr>
              <tr *ngFor="let burnDownScheduleValue of burnDownScheduleValues; let index = index;">
                <!-- Start: Lease Mo -->
                <td class="align-middle w-40">
                  <dx-number-box appIgnoreScrollwheel name="leaseMonth_{{index}}"
                                 min="1" class="real-estate-input" [max]="getMaxLeaseMonth()"
                                 [(ngModel)]="burnDownScheduleValue.leaseMonth"
                                 (ngModelChange)="calculateIncreaseDate($event, index); reorderCustomValues();"
                                 #leaseMonth>
                  </dx-number-box>
                </td>
                <!-- End: Lease Mo -->
                <!-- Start: Date Of Change -->
                <td class="align-middle w-40 schedule-date">
                  {{burnDownScheduleValue.dateOfChange | date:'MM.dd.yy'}}
                </td>
                <!-- End: Date Of Change -->
                <!-- Start: $ Amount -->
                <td class="align-middle w-40">
                  <app-input-field [placeholder]="'(e.g 5)'" [name]="'amount_' + index"
                                   [min]="0" [step]="0"
                                   [format]="'#0'" [width]="'65px'"
                                   [value]="burnDownScheduleValue.dollarAmount" [prefix]="'$'"
                                   (valueChange)="burnDownScheduleValue.dollarAmount = $event"
                  ></app-input-field>
                </td>
                <!-- End: $ Amount -->
                <!-- Start: Action -->
                <td class="text-center align-middle w-20">
                  <a role="button" class="cursor-pointer d-flex justify-content-center align-items-center minus-icon" title="Remove"
                     (click)="removeBurnDownValue(index);">
                    <span inlineSVG="assets/img/signs-icons.svg#icon-minus"></span>
                  </a>
                </td>
                <!-- End: Action -->
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="5">
                  <div class="d-table w-100">
                    <div class="d-flex flex-row mx-n1 justify-content-center">
                      <div class="px-1">
                        <dx-button type="success" class="deposit-btn"
                                   (click)="addBurnDownValue()">
                          <div class="d-flex justify-center align-items-center">
                            <span class="action-icon" inlineSVG="assets/img/signs-icons.svg#icon-plus-100"></span>
                            <span>Add a Row</span>
                          </div>
                        </dx-button>
                      </div>
                      <div class="px-1">
                        <dx-button type="cancel" class="deposit-btn"
                                   (click)="removeAllBurnDownValues();">
                          <div class="d-flex justify-center align-items-center">
                            <span class="action-icon" inlineSVG="assets/img/signs-icons.svg#icon-minus-100"></span>
                            <span>Remove All Rows</span>
                          </div>
                        </dx-button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
          <!-- End: Burn Down Schedule Table -->

          <!-- Start: Preview table -->
          <ng-container *ngIf="canShowPreview()">
            <div class="table-responsive">
              <app-security-deposit-burn-down-schedule-result [burnDownScheduleValues]="burnDownScheduleValues">
              </app-security-deposit-burn-down-schedule-result>
            </div>
          </ng-container>
          <!-- End: Preview table -->
        </div>
      </dx-scroll-view>
    </div>
    <!-- End: Table content -->
  </div>
</form>
