import * as models from '@statera/sdk/common';

export interface Message {
  id: number;
  isMy: boolean;
  authorDisplayName: string;
  authorAvatarUrl: string;
  content: string;
  createdOn: Date;
  data: any;
  edited: boolean;
  uploadedFileId: number;
  versionedFile: models.IVersionedFileViewModel;
  messageKind?: models.ChatMessageKind;
  floorPlanAnchor?: models.IPlanAnchorViewModel;
  badge: string;
  isBrokerMessage?: boolean;
  isRead?: boolean;
}

export type Lease = models.ILeaseViewModel;
export type StartupInfo = models.IStartupInfoViewModel;

export type LeaseTermType = models.LeaseTermType;
export const LeaseTermType = models.LeaseTermType;
export const LeaseStatus = models.LeaseStatus;
