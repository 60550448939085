<dx-accordion class="w-accordion" itemTemplate="itemTpl" itemTitleTemplate="titleTpl"
              *ngIf="!isMobileView && lease"
              [dataSource]="leaseTermTemplates" [(selectedIndex)]="accordionSelectedIndex"
              [collapsible]="true" [multiple]="true" [focusStateEnabled]="false"
              [animationDuration]="accordionAnimationDuration"
              (onOptionChanged)="handleAccordionOptionChange($event)"
              #dxAccordionComponent>
  <div *dxTemplate="let leaseTermTemplate of 'titleTpl';">
    {{ leaseTermTemplate.termGroup }}
  </div>
  <div *dxTemplate="let leaseTermTemplate of 'itemTpl';">
    <div class="w-lease-term"
         *ngIf="lease && (lease.currentLeaseProvided || lease.hasThirdPartyLease)">
      <ng-container *ngFor="let term of leaseTermTemplate.terms">
        <div class="b-lease-term">
            <span class="b-lease-term-name">
              <ng-container *ngIf="term?.config">
                {{ term.config.description }}
              </ng-container>
            </span>
          <!--b-lease-term-name-->

          <ng-container *ngIf="term?.config && isAccordionItemOpened(leaseTermTemplate.termGroup)">
            <ng-container *ngIf="term?.history && 0 < term?.history?.length; else noHistory">
              <ul class="lease-term-dashboard-term-history">
                <li *ngFor="let abstractTerm of term?.history"
                    #popoverContainerElement>
                  <span class="lease-term-dashboard-term-history-value">
                    <app-lease-term [numberOfLines]="1" [lease]="abstractTerm.lease" [termValue]="getTermDisplayValue(abstractTerm)"
                                    [showAmendmentNumber]="true" [amendmentNumber]="abstractTerm.amendmentNumber"
                                    [isCurrentTermValue]="abstractTerm.isCurrentTermValue"
                                    [leaseTermConfiguration]="term?.config"
                                    [project]="project" [documentSection]="getTermDocumentSection(abstractTerm, abstractTerm.lease)">
                      <!-- Start: Rental Rate Schedule Preview Table -->
                      <ng-container *ngIf="isBaseRentalRate(abstractTerm)">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement" [resizeByContent]="true"
                                                             [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(abstractTerm)">
                        </app-lease-term-custom-table-popover>
                      </ng-container>
                      <!-- End: Rental Rate Schedule Preview Table -->
                      <!-- Start: Size Phase-in Table -->
                      <ng-container *ngIf="isTenantSquareFootagePhaseInTermValue(abstractTerm)">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getTenantSquareFootagePhaseInCustomTable(abstractTerm)">
                        </app-lease-term-custom-table-popover>
                      </ng-container>
                      <!-- End: Size Phase-in Table -->
                      <!-- Start: Rental Rate Escalation Custom Table -->
                      <ng-container *ngIf="isRentalRateEscalationCustomOption(abstractTerm)">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getRentalRateEscalationCustomTable(abstractTerm)">
                        </app-lease-term-custom-table-popover>
                      </ng-container>
                      <!-- End: Rental Rate Escalation Custom Table -->

                      <!-- Start: Burn Down Custom Table -->
                      <ng-container *ngIf="isBurnDownOption(abstractTerm)">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getSecurityDepositBurnDownCustomTable(abstractTerm)">
                        </app-lease-term-custom-table-popover>
                      </ng-container>
                      <!-- End: Burn Down Custom Table -->

                      <!-- Start: Real Estate Break Down table -->
                      <ng-container *ngIf="isRealEstateTaxesBreakdownTable(abstractTerm)">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getRealEstateTaxesBreakdownTable(abstractTerm)">
                        </app-lease-term-custom-table-popover>
                      </ng-container>
                      <!-- End: Real Estate Break Down table -->
                    </app-lease-term>
                  </span>
                </li>
              </ul>
            </ng-container>

            <ng-template #noHistory>
              <div *ngIf="isShowWithoutHistory()" #popoverContainerElement>
                <app-lease-term *ngIf="isAccordionItemOpened(leaseTermTemplate.termGroup)"
                                [numberOfLines]="1" [lease]="lease" [leaseTermConfiguration]="term.config"
                                [showAmendmentNumber]="true" [amendmentNumber]="term?.amendmentNumber"
                                [termValue]="term?.value" [isCurrentTermValue]="term?.isCurrentTermValue"
                                [project]="project" [documentSection]="getTermDocumentSection(term, lease)">
                  <!-- Start: Rental Rate Schedule Preview Table -->
                  <ng-container *ngIf="isBaseRentalRate(term?.leaseTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement" [resizeByContent]="true"
                                                         [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(term?.leaseTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Rental Rate Schedule Preview Table -->
                  <!-- Start: Size Phase-in Table -->
                  <ng-container *ngIf="isTenantSquareFootagePhaseInTermValue(term?.leaseTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getTenantSquareFootagePhaseInCustomTable(term?.leaseTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Size Phase-in Table -->
                  <!-- Start: Rental Rate Escalation Custom Table -->
                  <ng-container *ngIf="isRentalRateEscalationCustomOption(term?.leaseTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getRentalRateEscalationCustomTable(term?.leaseTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Rental Rate Escalation Custom Table -->

                  <!-- Start: Burn Down Custom Table -->
                  <ng-container *ngIf="isBurnDownOption(term?.leaseTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getSecurityDepositBurnDownCustomTable(term?.leaseTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Burn Down Custom Table -->

                  <!-- Start: Real Estate Break Down table -->
                  <ng-container *ngIf="isRealEstateTaxesBreakdownTable(term?.leaseTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getRealEstateTaxesBreakdownTable(term?.leaseTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Real Estate Break Down table -->
                </app-lease-term>
              </div>

              <ng-container *ngIf="term?.thirdPartyLeaseValue">
                <div #popoverContainerElement>
                  <app-lease-term *ngIf="isAccordionItemOpened(leaseTermTemplate.termGroup)"
                                  [numberOfLines]="1" [lease]="lease" [leaseTermConfiguration]="term.config"
                                  [showThirdPartyLease]="true" [termValue]="term?.thirdPartyLeaseValue"
                                  [isCurrentTermValue]="term?.isCurrentTermValue" [project]="project"
                                  [documentSection]="getTermDocumentSection(term, lease)">
                    <!-- Start: Rental Rate Schedule Preview Table -->
                    <ng-container *ngIf="isBaseRentalRate(term?.leaseTerm)">
                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement" [resizeByContent]="true"
                                                           [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(term?.leaseTerm)">
                      </app-lease-term-custom-table-popover>
                    </ng-container>
                    <!-- End: Rental Rate Schedule Preview Table -->
                    <!-- Start: Size Phase-in Table -->
                    <ng-container *ngIf="isTenantSquareFootagePhaseInTermValue(term?.leaseTerm)">
                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                           [leaseTermCustomTable]="getTenantSquareFootagePhaseInCustomTable(term?.leaseTerm)">
                      </app-lease-term-custom-table-popover>
                    </ng-container>
                    <!-- End: Size Phase-in Table -->
                    <!-- Start: Rental Rate Escalation Custom Table -->
                    <ng-container *ngIf="isRentalRateEscalationCustomOption(term?.leaseTerm)">
                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                           [leaseTermCustomTable]="getRentalRateEscalationCustomTable(term?.leaseTerm)">
                      </app-lease-term-custom-table-popover>
                    </ng-container>
                    <!-- End: Rental Rate Escalation Custom Table -->

                    <!-- Start: Burn Down Custom Table -->
                    <ng-container *ngIf="isBurnDownOption(term?.leaseTerm)">
                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                           [leaseTermCustomTable]="getSecurityDepositBurnDownCustomTable(term?.leaseTerm)">
                      </app-lease-term-custom-table-popover>
                    </ng-container>
                    <!-- End: Burn Down Custom Table -->

                    <!-- Start: Real Estate Break Down table -->
                    <ng-container *ngIf="isRealEstateTaxesBreakdownTable(term?.leaseTerm)">
                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                           [leaseTermCustomTable]="getRealEstateTaxesBreakdownTable(term?.leaseTerm)">
                      </app-lease-term-custom-table-popover>
                    </ng-container>
                    <!-- End: Real Estate Break Down table -->
                  </app-lease-term>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
          <!--b-lease-term-value-->
        </div>
        <!--b-lease-term-->
      </ng-container>
    </div>
    <!--w-lease-term-->

    <div class="no-lease" *ngIf="lease && !lease.currentLeaseProvided && !lease.thirdPartyLease">
      <svg viewBox="0 0 22 22">
        <path d="M3.007,10.936c0-3.059-0.001-6.117,0-9.175c0-0.785,0.552-1.336,1.333-1.336   c3.517,0,7.036,0.002,10.553-0.003c0.311,0,0.562,0.104,0.775,0.313c0.979,0.949,1.955,1.897,2.93,2.851   c0.271,0.268,0.387,0.598,0.387,0.988c-0.006,5.16-0.016,10.32,0.009,15.48c0.004,0.721-0.604,1.389-1.396,1.385   c-4.429-0.021-8.855-0.01-13.286-0.01c-0.744,0-1.306-0.564-1.306-1.316C3.007,17.053,3.007,13.994,3.007,10.936z M14.461,1.429   c-0.104-0.006-0.173-0.012-0.24-0.012c-3.308,0-6.616,0-9.923,0c-0.279,0-0.288,0.011-0.288,0.315c0,6.086,0,12.172,0,18.256   c0,0.514-0.021,0.432,0.418,0.434c4.42,0.002,8.841,0.002,13.261,0.002c0.301,0,0.302-0.002,0.302-0.322c0-4.979,0-9.958,0-14.939   c0-0.082,0-0.163,0-0.257c-0.964,0-1.89-0.001-2.814,0c-0.562,0.001-0.716-0.148-0.716-0.72c0-0.405,0-0.81,0-1.214   C14.461,2.464,14.461,1.959,14.461,1.429z M17.479,3.887c-0.688-0.669-1.33-1.295-2-1.947c0,0.682,0,1.308,0,1.947   C16.133,3.887,16.771,3.887,17.479,3.887z"/>
        <path d="M10.998,11.389c-1.252,0-2.505,0-3.758,0c-0.046,0-0.093,0.001-0.14-0.001   c-0.353-0.007-0.581-0.204-0.582-0.501c0-0.3,0.225-0.5,0.58-0.501c1.137-0.003,2.271-0.002,3.408-0.002   c1.463,0,2.928,0,4.389,0.002c0.322,0,0.529,0.146,0.58,0.397c0.065,0.343-0.166,0.604-0.556,0.605   c-0.918,0.004-1.835,0.002-2.754,0.002C11.775,11.39,11.388,11.39,10.998,11.389L10.998,11.389z"/>
        <path d="M10.987,13.373c1.3,0,2.6,0,3.897,0c0.37,0,0.601,0.195,0.599,0.502c-0.002,0.312-0.229,0.504-0.601,0.504   c-2.583,0-5.167,0-7.75-0.002c-0.397,0-0.641-0.219-0.613-0.537c0.025-0.289,0.243-0.465,0.593-0.465   c1.111-0.004,2.225-0.002,3.337-0.004C10.63,13.371,10.809,13.371,10.987,13.373z"/>
        <path d="M10.988,16.361c1.292,0,2.583,0,3.874,0c0.25,0,0.469,0.055,0.58,0.301c0.16,0.35-0.09,0.699-0.515,0.703   c-0.716,0.008-1.432,0.004-2.146,0.004c-1.821,0-3.642,0-5.462,0c-0.132,0-0.266,0.002-0.395-0.021   c-0.24-0.041-0.401-0.238-0.406-0.473c-0.006-0.238,0.164-0.447,0.41-0.496c0.075-0.016,0.154-0.016,0.231-0.016   c1.276-0.002,2.553,0,3.828,0C10.988,16.361,10.988,16.361,10.988,16.361z"/>
        <path d="M9.518,8.399C8.71,8.399,7.9,8.4,7.092,8.398c-0.321-0.001-0.527-0.165-0.569-0.44   c-0.047-0.306,0.202-0.562,0.56-0.563c0.941-0.003,1.882-0.001,2.824,0c0.676,0,1.354-0.003,2.028,0.002   c0.353,0.003,0.565,0.209,0.558,0.519c-0.009,0.293-0.226,0.481-0.571,0.482C11.121,8.401,10.318,8.399,9.518,8.399z"/>
      </svg>

      <span>No current lease provided</span>
    </div>
  </div>
</dx-accordion>

<ng-container *ngIf="isMobileView">
  <ng-container *ngFor="let leaseTermTemplate of leaseTermTemplates">
    <div *ngIf="activeDot === leaseTermTemplate.termGroup">
      <ng-container *ngFor="let term of leaseTermTemplate.terms">
        <div class="d-flex flex-column pb-3">
          <h6 class="text-14">
            {{ term.config.description }}
          </h6>
          <span class="text-muted text-16">
            <app-lease-term [numberOfLines]="1" [lease]="lease" [leaseTermConfiguration]="term.config"
                            [termValue]="term.value" [project]="project">
            </app-lease-term>
          </span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="d-flex justify-content-center pb-1">
    <ng-container *ngFor="let leaseTermTemplate of leaseTermTemplates">
      <div class="pr-2">
        <span class="dot"
              [ngClass]="{ 'bg-primary': activeDot === leaseTermTemplate.termGroup }"
              (click)="clickDot(leaseTermTemplate.termGroup)">
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>
