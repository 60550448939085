import * as models from '@statera/sdk/common';

export type QuizType = models.QuizType;
export const QuizType = models.QuizType;

export type ProjectType = models.ProjectTypeEnum;
export const ProjectType = models.ProjectTypeEnum;

export type ProjectStatus = models.ProjectStatus;
export const ProjectStatus = models.ProjectStatus;

export type RenewalProjectTriggerType = models.RenewalProjectTriggerType;
export const RenewalProjectTriggerType = models.RenewalProjectTriggerType;

export type LeaseAmendmentsAnswerType = models.LeaseAmendmentsAnswerType;
export const LeaseAmendmentsAnswerType = models.LeaseAmendmentsAnswerType;

export type SelectCitiesAnswerType = models.SelectCitiesAnswerType;
export const SelectCitiesAnswerType = models.SelectCitiesAnswerType;

export type IdealUnitSizeAnswerType = models.IdealUnitSizeAnswerType;
export const IdealUnitSizeAnswerType = models.IdealUnitSizeAnswerType;

export type Quiz = models.IQuizViewModel;
export type QuizStep = models.IQuizStepViewModel;
export type QuizQuestion = models.IQuizQuestionViewModel;
export type QuizRangeAnswer = models.IQuizRangeAnswerViewModel;
export type AnswerType = models.AnswerType;
export const AnswerType = models.AnswerType;
export type QuestionType = models.QuestionType;
export const QuestionType = models.QuestionType;
export type QuizAnswer = models.IQuizAnswerViewModel;
export type QuizQuestionOption = models.IQuizQuestionOptionViewModel;
export type IUserInvitationRequestViewModel = models.IUserInvitationRequestViewModel;
export type Lease = models.ILeaseViewModel;
export type Project = models.IProjectViewModel;
export type Address = models.IAddressViewModel;
export type ApprovingAppointment = models.IApprovingAppointmentViewModel;
export type IRejectionLeaseViewModel = models.IRejectionLeaseViewModel;
export type RenewalProjectParams = models.IRenewalProjectParamsViewModel;
export type CancelledRequest = models.ICancelledRequestViewModel;
export type QuizLeaseAmendmentChoiceViewModel = models.IQuizLeaseAmendmentChoiceViewModel;
export type SelectCitiesAnswer = models.ISelectCitiesAnswerViewModel;
export type IdealUnitSizeAnswer = models.IIdealUnitSizeAnswerViewModel;
export type LeaseAmendmentsAnswer = models.ILeaseAmendmentsAnswerViewModel;
export type BuildingSpecsAnswer = models.IBuildingSpecsAnswerViewModel;
export type LeaseTermAnswer = models.ILeaseTermAnswerViewModel;
export type SpecificUseForBuildingAnswer = models.ISpecificUseForBuildingAnswer;
export type DateOrDateRangeAnswer = models.IDateOrDateRangeAnswerViewModel;
export type QuizItem = models.IQuizItemViewModel;

export interface LeaseAmendmentsOption {
  text: string;
  value: LeaseAmendmentsAnswerType;
  tooltip: string;
  disabled: boolean;
}

export enum RestructureAnswerType {
  TenantImprovement = 106,
  ExtendLease = 108,
  SizeChange = 114
}
