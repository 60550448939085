<div class="wrapper">
  <div class="title">
    <div class="container">
      <div class="d-flex flex-row align-items-center justify-content-between">
        <div>
          <h1 class="text-uppercase">
            REQUEST FINANCIAL INFORMATION
            <button type="button" class="b-btn-icon"
                    (click)="$event.preventDefault()" #requestFinancialInformationTitle>
              <span class="icon-info"></span>
            </button>
            <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                        [width]="250" [target]="requestFinancialInformationTitle">
              <div class="d-flex flex-column tooltip-menu">
                {{getTooltipText()}}
              </div>
            </dx-tooltip>
          </h1>
        </div>
        <div>
          <button type="button" class="close"
                  (click)="close()">
            <i class="dx-icon dx-icon-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <dx-scroll-view width="100%" height="100%">
      <div class="container">
        <form name="form" novalidate
              (ngSubmit)="submit(form)" #form="ngForm">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">
                Please provide any additional comments or directions for Tenant.
              </div>
              <div class="dx-field-value">
                <dx-text-area id="message" name="message" rows="2" required
                              [autoResizeEnabled]="true" [(ngModel)]="request.message"
                              [isValid]="form.submitted ? message.valid : true"
                              #message="ngModel">
                </dx-text-area>
                <div class="my-1 invalid-feedback d-block"
                     *ngIf="form.submitted && message.hasError('required')">
                  Comment is required
                </div>
              </div>
            </div>
          </div>

          <div class="dx-fieldset">
            <div class="d-flex flex-row mx-n2">
              <div class="px-2 w-50">
                <dx-button type="success" width="100%" text="Send request"
                           [useSubmitBehavior]="true">
                </dx-button>
              </div>
              <div class="px-2 w-50">
                <dx-button type="normal" text="Cancel" width="100%"
                           (onClick)="close()">
                </dx-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </dx-scroll-view>
  </div>
</div>

