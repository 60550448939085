import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseRequestManager } from '@statera/sdk/lease-request';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'request-financials-dialog.component.html',
  styleUrls: ['request-financials-dialog.component.scss'],
})
export class RequestFinancialsDialogComponent implements OnInit, OnDestroy {
  request: models.IFinancialRequestViewModel;

  readonly leaseId: number;
  readonly lease: models.ILeaseViewModel;

  private readonly _leaseRequestManager: LeaseRequestManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseRequestManager: LeaseRequestManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
  ) {
    this._leaseRequestManager = leaseRequestManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.request = this.request || <models.IFinancialRequestViewModel>{};
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._leaseRequestManager
      .createFinancialRequest(this.leaseId, this.request)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((createdRequest) => {
        this._dialogRefService.outputData = {createdRequest};

        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getFinancialsRequestSendAlertText(),
        });

        this.close();
      });
  }

  close(): void {
    this._dialogRefService.hide();
  }

  getTooltipText(): string {
    const landlordCompanyName = this.lease?.landlordCompany?.name ?? 'Landlord Company';
    const tenantCompanyName = this.lease?.tenantCompany?.name ?? 'Tenant Company';
    return `${landlordCompanyName} and ${tenantCompanyName} realize that some information received by one party from
     the other pursuant to these negotiation shall be confidential.  It is therefore agreed that any information received
     by one party from the other, and clearly designated in writing as "CONFIDENTIAL" at the time of transfer, shall not
     be disclosed by either party to any third party and shall not be used by either party for purposes other than those
     contemplated for these Lease negotiations or a period of one (1) year from effective date of this request.`;
  }
}
