import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-forbidden',
  templateUrl: 'forbidden.component.html',
  styleUrls: ['forbidden.component.scss'],
})
export class ForbiddenComponent {
  getYear(): number {
    return moment().get('year');
  }
}
