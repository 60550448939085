import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as models from './colabo.model';

@Injectable({
  providedIn: 'root',
})
export class ColaboStore {
  readonly chatTreeItems: BehaviorSubject<Array<models.ColaboTreeItem>>;
  readonly selectedChatTreeItem: BehaviorSubject<models.ColaboTreeItem>;

  constructor() {
    this.chatTreeItems = new BehaviorSubject<Array<models.ColaboTreeItem>>([]);
    this.selectedChatTreeItem = new BehaviorSubject<models.ColaboTreeItem>(null);
  }
}
