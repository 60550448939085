import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { SafeDatePipe } from '../../infrastructure/pipes/safe-date.pipe';
import { ReplacerPipe } from '../pipes/replacer.pipe';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  private _httpClient: HttpClient;
  private _safeDatePipe: SafeDatePipe;
  private _replacerPipe: ReplacerPipe;

  // TODO: [FIXME] We must stop use Subject for additional logic, use pipe on top-layer instead
  alertGroupViewModel = new BehaviorSubject<models.IAlertGroupViewModel>(<models.IAlertGroupViewModel>{});

  constructor(
    httpClient: HttpClient,
    safeDatePipe: SafeDatePipe,
    replacerPipe: ReplacerPipe
  ) {
    this._httpClient = httpClient;
    this._safeDatePipe = safeDatePipe;
    this._replacerPipe = replacerPipe;
  }

  markReadAlert(alert: models.IAlertViewModel) {
    return this._httpClient.post(`${environment.webApiUrl}/Alert/MarkReadAlert`, alert);
  }

  markReadAllAlerts(leaseId: number) {
    const markReadAllAlerts = <models.IMarkReadAllAlertsViewModel> {leaseId : leaseId};
    return this._httpClient.post(`${environment.webApiUrl}/Alert/markReadAllAlerts`, markReadAllAlerts);
  }

  getLastStateChangedAlertsByLeaseId(leaseId: number): Observable<Array<models.IAlertViewModel>> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString());

    return this._httpClient
      .get<Array<models.IAlertViewModel>>(`${environment.webApiUrl}/Alert/GetLastStateChangedAlertsByLeaseId`, {params: params})
      .pipe(
        map(alerts => {
          alerts.map(alert => {
            if (alert) {
              alert.text = this.getRenderedTemplate(alert);
            }
            return alert;
          });
          return alerts;
        }),
        share()
      );
  }

  getRenderedTemplate(alert: models.IAlertViewModel): string {
    if (alert && alert.text) {
      let text = alert.text;

      let tenantCompanyName: string;
      if (alert.leaseTenantCompany) {
        tenantCompanyName = alert.leaseTenantCompany.name;
      } else {
        tenantCompanyName = 'Tenant Company';
      }

      let landlordCompanyName: string;
      if (alert.leaseLandlordCompany) {
        landlordCompanyName = alert.leaseLandlordCompany.name;
      } else {
        landlordCompanyName = 'Landlord Company';
      }

      const address: Array<string> = [];
      if (alert.leaseBuilding) {
        let buildingAddress: string;
        buildingAddress = alert.leaseBuilding.address && alert.leaseBuilding.address.displayString;
        if (!buildingAddress) {
          buildingAddress = alert.leaseBuilding.name;
        }
        address.push(buildingAddress || `Building #${alert.leaseBuilding.id}`);
      }

      if (alert.leaseBuildingUnit) {
        const buildingUnitName = alert.leaseBuildingUnit.name;

        address.push(buildingUnitName || `Unit #${alert.leaseBuildingUnit.id}`);
      }

      let visitDate = '';
      if (alert.appointment && alert.appointment.startDate && alert.appointment.endDate) {
        const date = this._safeDatePipe.transform(alert.appointment.startDate, 'mediumDate');

        const startDate = new Date(alert.appointment.startDate);
        const endDate = new Date(alert.appointment.endDate);

        // Workaround: Special case
        if (startDate.getHours() === 11 && startDate.getMinutes() === 30 && endDate.getHours() === 12) {
          visitDate = `${date} 11:30 AM - 12:00 AM`;
        } else {
          const startHour = this._safeDatePipe.transform(alert.appointment.startDate, 'shortTime');
          const endHour = this._safeDatePipe.transform(alert.appointment.endDate, 'shortTime');

          visitDate = `${date} ${startHour} - ${endHour}`;
        }
      }

      const leaseId = alert.leaseId && alert.leaseId.toString();

      const regExp = /@Raw\b\("(?<innerHtml>.*)"\)/gmi;
      const regExpMatches = regExp.exec(text);

      if (regExpMatches && 0 < regExpMatches.length && regExpMatches.groups && regExpMatches.groups.innerHtml) {
        const fullMath = regExpMatches[0];
        const innerHtml = regExpMatches.groups.innerHtml;

        text = text.replace(fullMath, innerHtml);
      }

      const model = {
        '@Model.TenantCompanyName': tenantCompanyName,
        '@Model.LandlordCompanyName': landlordCompanyName,
        '@Model.LeaseId': leaseId,
        '@Model.VisitDate': visitDate,
        '@Model.Url': '',
        '@Model.BaseUrl': '',
        '@Model.BuildingAddress': 0 < address.length ? address.join(', ') : '',
      };

      return this._replacerPipe.transform(text, model);
    }
  }
}
