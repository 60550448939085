import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ExpirationsReportTooltip, ExpirationsReportTooltipChartType } from '../../models/expirations-report-tooltip.model';

import { AnalyticsTooltipService, Context } from '../../services/analytics-tooltip.service';

@Component({
  templateUrl: 'expirations-report-tooltip.component.html',
  styleUrls: ['expirations-report-tooltip.component.scss'],
})
export class ExpirationsReportTooltipComponent implements OnInit, AfterViewInit {
  @ViewChild('expirationsReportTooltipElementRef') expirationsReportTooltipElementRef: ElementRef;

  readonly injectableData: {
    analyticsTooltipService: AnalyticsTooltipService<ExpirationsReportTooltipComponent, ExpirationsReportTooltip>,
    model: ExpirationsReportTooltip,
    context: Context,
  };

  ExpirationReportTooltipChartType: typeof ExpirationsReportTooltipChartType = ExpirationsReportTooltipChartType;

  expirationsReportTooltip: ExpirationsReportTooltip;

  ngOnInit(): void {
    if (!this.injectableData || !this.injectableData.model) {
      return;
    }

    this.expirationsReportTooltip = this.injectableData.model;
  }

  ngAfterViewInit(): void {
    if (
      !this.injectableData || !this.injectableData.context ||
      !this.expirationsReportTooltipElementRef || !this.expirationsReportTooltipElementRef.nativeElement
    ) {
      return;
    }

    const { canvasElement, tooltipModel } = this.injectableData.context;
    if (!canvasElement || !tooltipModel) {
      return;
    }

    const canvasBoundingClientRect = canvasElement.getBoundingClientRect();

    const tooltipElement = this.expirationsReportTooltipElementRef.nativeElement;

    tooltipElement.style.left = `${canvasBoundingClientRect.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipElement.style.top = `${canvasBoundingClientRect.top + window.pageYOffset + tooltipModel.caretY}px`;
  }
}
