import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import {StateraUserClaimRepository} from './statera-user-claim.repository';
import {StateraUserClaimManager} from './statera-user-claim.manager';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    StateraUserClaimRepository,
    StateraUserClaimManager,
  ],
})
export class StateraUserClaimModule {
  static forChild(configuration: Configuration): ModuleWithProviders<StateraUserClaimModule> {
    return {
      ngModule: StateraUserClaimModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        StateraUserClaimRepository,
        StateraUserClaimManager,
      ],
    };
  }
}
