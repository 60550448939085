import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-portfolio',
  templateUrl: 'analytics-filters-portfolio.component.html',
  styleUrls: ['analytics-filters-portfolio.component.scss'],
})
export class AnalyticsFiltersPortfolioComponent {
  @Input() portfolios: Array<analyticsFiltersModels.PortfolioFilter>;
  @Output() portfoliosChange: EventEmitter<Array<analyticsFiltersModels.PortfolioFilter>>;

  constructor() {
    this.portfoliosChange = new EventEmitter<Array<analyticsFiltersModels.PortfolioFilter>>();
  }

  handlePortfolioSelection(): void {
    this.portfoliosChange.next([...this.portfolios]);
  }
}
