import { CommonModule, DatePipe, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  DxAccordionModule,
  DxBoxModule,
  DxButtonGroupModule,
  DxButtonModule,
  DxCalendarModule,
  DxChartModule,
  DxContextMenuModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxNumberBoxModule, DxPopoverModule,
  DxPopupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxValidationGroupModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { InlineSVGModule } from 'ng-inline-svg';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { ImageViewerModule } from '../image-viewer/image-viewer.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { SafeDatePipe } from '../infrastructure/pipes/safe-date.pipe';
import { SafeNumberPipe } from '../infrastructure/pipes/safe-number.pipe';
import { ProfileService } from '../user/services/profile.service';
import { AppointmentEditDialogComponent } from './components/appointment-edit-dialog/appointment-edit-dialog.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { BuildingComponent } from './components/building/building.component';
import { ComparisonReportComponent } from './components/comparison-report/comparison-report.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { GanttChartComponent } from './components/gantt-chart/gantt-chart.component';
import { ImprovementCostRejectionDialogComponent } from './components/improvement-cost-rejection-dialog/improvement-cost-rejection-dialog.component';
import { ImprovementRejectionCommentDialogComponent } from './components/improvement-rejection-comment-dialog/improvement-rejection-comment-dialog.component';
import { LeaseAnalysisComponent } from './components/lease-analysis/lease-analysis.component';
import { LeaseTermCustomTablePopoverComponent } from './components/lease-term-custom-table-popover/lease-term-custom-table-popover.component';
import { LeaseTermDashboardComponent } from './components/lease-term-dashboard/lease-term-dashboard.component';
import { LeaseTermComponent } from './components/lease-term/lease-term.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ServerErrorComponent } from './components/server-error/server-error.component';
import { TimelineVerticalComponent } from './components/timeline-vertical/timeline-vertical.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { CheckAccessDirective } from './directives/check-access.directive';
import { AppointmentService } from './services/appointment.service';
import { DocumentsService } from './services/documents.service';
import { LeaseService } from './services/lease.service';
import { ProjectAccessService } from './services/project-access.service';
import { ProjectService } from './services/project.service';
import { QuizService } from './services/quiz.service';
import { RequestService } from './services/request.service';
import { ProjectScheduleComponent } from './components/project-schedule/project-schedule.component';
import { ReplacerPipe } from './pipes/replacer.pipe';
import { BaseDemoComponent } from './components/base-demo/base-demo.component';
import { ProjectScheduleDemoComponent } from './components/project-schedule-demo/project-schedule-demo.component';
import { OnboadringPopupComponent } from './components/onboadring-popup/onboadring-popup.component';
import { SquareFootagePipe } from './pipes/square-footage.pipe';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AvatarListComponent } from './components/avatar-list/avatar-list.component';
import { LeaseTermCustomTableComponent } from './components/lease-term-custom-table/lease-term-custom-table.component';
import { OnboardingAnswerComponent } from './components/onboarding-answer/onboarding-answer.component';
import { ColaboBadgesComponent } from './components/colabo-badges/colabo-badges.component';
import { AwaitingLeaseUploadBadgeComponent } from './components/colabo-badges/awaiting-lease-upload-badge/awaiting-lease-upload-badge.component';
import { NoCurrentLeaseProvidedAnswerComponent } from './components/no-current-lease-provided-answer/no-current-lease-provided-answer.component';
import { ComparisonReportChangeIndicatorComponent } from './components/comparison-report/comparison-report-change-indicator/comparison-report-change-indicator.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { IgnoreScrollwheelDirective } from './directives/ignore-scrollwheel.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DxChartModule,
    DxToolbarModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxButtonGroupModule,
    DxTooltipModule,
    DxTextBoxModule,
    DxFormModule,
    DxTextAreaModule,
    DxPopupModule,
    InlineSVGModule,
    PopoverModule,
    DxListModule,
    DxScrollViewModule,
    DxBoxModule,
    DxContextMenuModule,
    DxFileUploaderModule,
    InfrastructureModule,
    DxHtmlEditorModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxLoadIndicatorModule,
    DxTabsModule,
    DxSchedulerModule,
    DxCalendarModule,
    DxAccordionModule,
    DxValidationGroupModule,
    DxValidatorModule,
    DxPopoverModule,
    DocumentViewerModule,
    ImageViewerModule,
  ],
  declarations: [
    GanttChartComponent,
    LeaseTermComponent,
    TimelineComponent,
    BuildingComponent,
    TimelineVerticalComponent,
    MessagesComponent,
    LeaseAnalysisComponent,
    ComparisonReportComponent,
    AppointmentsComponent,
    ForbiddenComponent,
    ServerErrorComponent,
    LeaseTermDashboardComponent,
    ProjectScheduleComponent,
    ReplacerPipe,
    BaseDemoComponent,
    ProjectScheduleDemoComponent,
    OnboadringPopupComponent,
    LeaseTermCustomTablePopoverComponent,
    ImprovementCostRejectionDialogComponent,
    ImprovementRejectionCommentDialogComponent,
    AppointmentEditDialogComponent,
    CheckAccessDirective,
    SortableHeaderDirective,
    SquareFootagePipe,
    GalleryComponent,
    AvatarListComponent,
    LeaseTermCustomTableComponent,
    OnboardingAnswerComponent,
    ColaboBadgesComponent,
    AwaitingLeaseUploadBadgeComponent,
    NoCurrentLeaseProvidedAnswerComponent,
    ComparisonReportChangeIndicatorComponent,
    InputFieldComponent,
    IgnoreScrollwheelDirective,
  ],
  exports: [
    AppointmentsComponent,
    GanttChartComponent,
    LeaseTermComponent,
    TimelineComponent,
    BuildingComponent,
    TimelineVerticalComponent,
    MessagesComponent,
    LeaseAnalysisComponent,
    ComparisonReportComponent,
    LeaseTermDashboardComponent,
    ProjectScheduleComponent,
    OnboadringPopupComponent,
    LeaseTermCustomTablePopoverComponent,
    CheckAccessDirective,
    SortableHeaderDirective,
    SquareFootagePipe,
    GalleryComponent,
    AvatarListComponent,
    LeaseTermCustomTableComponent,
    OnboardingAnswerComponent,
    ColaboBadgesComponent,
    AwaitingLeaseUploadBadgeComponent,
    NoCurrentLeaseProvidedAnswerComponent,
    InputFieldComponent,
    IgnoreScrollwheelDirective,
  ],
  providers: [
    ProjectService,
    LeaseService,
    AppointmentService,
    DatePipe,
    SafeDatePipe,
    SafeNumberPipe,
    ReplacerPipe,
    ProfileService,
    RequestService,
    ProjectAccessService,
    QuizService,
    DocumentsService,
    I18nPluralPipe,
  ],
  entryComponents: [
    ImprovementCostRejectionDialogComponent,
    ImprovementRejectionCommentDialogComponent,
    AppointmentEditDialogComponent,
  ],
})
export class SharedModule {
}
