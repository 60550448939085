<div class="occupancy-and-vacancy-report">
  <ng-container *ngIf="isLoaded; else preloader">
    <div class="occupancy-and-vacancy-report-info">
      <div class="occupancy-and-vacancy-report-info-statistics">
        <span class="value">{{vacantUnitCount()}}</span>
        <span class="caption">Vacant units</span>
      </div>

      <ul class="occupancy-and-vacancy-report-info-legends">
        <li>
          <a (click)="onLegendItemClick()">
            <span class="occupancy-and-vacancy-report-info-legends-bg"
                  [style.background-color]="'rgba(118, 129, 243, 1)'">
            </span>
            <span>Occupied SF</span>
          </a>
        </li>
        <li>
          <a (click)="onLegendItemClick()">
            <span class="occupancy-and-vacancy-report-info-legends-bg"
                  [style.background-color]="'rgba(161, 168, 247, 1)'">
            </span>
            <span>Vacant SF</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="occupancy-and-vacancy-report-chart">
      <canvas baseChart
              [data]="barChartData"
              [options]="barChartOptions"
              [legend]="false"
              [type]="'bar'"
              (chartClick)="handleChartClickEvent($event)"
              #canvasElementRef>
      </canvas>
    </div>
  </ng-container>
  <ng-template #preloader>
    <div class="occupancy-and-vacancy-report-preloader">
      <span>
      </span>
    </div>
  </ng-template>
</div>
