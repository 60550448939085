import { Injectable } from '@angular/core';

import { PlanViewerMarkerRef, PlanViewerMarkerType } from '../models/plan-viewer.model';

@Injectable()
export class PlanViewerMarkerSortService {
  sortByIntersection(markers: Array<PlanViewerMarkerRef>): Array<PlanViewerMarkerRef> {
    const input = markers
      .map((marker, index) => ({
        index: index,
        polygon: this.convertMarkerToPolygon(marker),
        marker: marker,
        intersectionMap: {},
      }));

    for (let i = 0; i < markers.length; i++) {
      const s = input[i];

      for (let j = 0; j < markers.length; j++) {
        if (i === j) {
          continue;
        }

        const c = input[j];

        const clip = this.getPolygonClip(s.polygon, c.polygon);
        if (clip.length) {
          s.intersectionMap[j] = true;
        }
      }
    }

    const standaloneMarkers = input
      .filter(x => !Object.keys(x.intersectionMap).length)
      .map(x => x.marker);

    const intersectedMarkers = input
      .filter(x => Object.keys(x.intersectionMap).length);

    const intersectedMarkersIndices = intersectedMarkers.map(m => m.index);

    return [
      ...standaloneMarkers,
      ...intersectedMarkers
        .sort((marker) => {
          const markerIntersectionIndices = Object.keys(marker.intersectionMap).map(i => parseInt(i, 10));
          return markerIntersectionIndices.some(i => intersectedMarkersIndices.some(j => i === j)) ? -1 : 1;
        })
        .map(x => x.marker),
    ];
  }

  getPolygonClip(subjectPolygon: Array<[number, number]>, clipPolygon: Array<[number, number]>) {
    let outputList = subjectPolygon;

    let cp1 = clipPolygon[clipPolygon.length - 1];
    let cp2 = null;
    let s = null;
    let e = null;

    for (let i = 0; i < clipPolygon.length; i++) {
      const inputList = outputList;
      outputList = [];

      cp2 = clipPolygon[i];
      s = inputList[inputList.length - 1]; // last on the input list

      for (let j = 0; j < inputList.length; j++) {
        e = inputList[j];

        if (this.isInside(e, cp1, cp2)) {
          if (!this.isInside(s, cp1, cp2)) {
            outputList.push(this.getIntersection(e, s, cp1, cp2));
          }
          outputList.push(e);
        } else if (this.isInside(s, cp1, cp2)) {
          outputList.push(this.getIntersection(e, s, cp1, cp2));
        }

        s = e;
      }

      cp1 = cp2;
    }

    return outputList;
  }

  isInside(p: [number, number], cp1: [number, number], cp2: [number, number]): boolean {
    return (
      (cp2[0] - cp1[0]) * (p[1] - cp1[1]) > (cp2[1] - cp1[1]) * (p[0] - cp1[0])
    );
  }

  getIntersection(e: [number, number], s: [number, number], cp1: [number, number], cp2: [number, number]): [number, number] {
    const dc = [cp1[0] - cp2[0], cp1[1] - cp2[1]];
    const dp = [ s[0] - e[0], s[1] - e[1] ];
    const n1 = cp1[0] * cp2[1] - cp1[1] * cp2[0];
    const n2 = s[0] * e[1] - s[1] * e[0];
    const n3 = 1.0 / (dc[0] * dp[1] - dc[1] * dp[0]);

    return [
      (n1 * dp[0] - n2 * dc[0]) * n3,
      (n1 * dp[1] - n2 * dc[1]) * n3
    ];
  }

  convertMarkerToPolygon(marker: PlanViewerMarkerRef): Array<[number, number]> {
    switch (marker.type) {
      case PlanViewerMarkerType.Polygon:
        return marker.points
          .reduce((acc, cur, index) => {
            const pointIndex = Math.floor(index / 2);
            const pointCoordinateIndex = index % 2;

            if (!acc[pointIndex]) {
              acc[pointIndex] = [];
            }

            acc[pointIndex][pointCoordinateIndex] = cur;

            return acc;
          }, []);
      case PlanViewerMarkerType.Rect:
        return [
          [marker.x, marker.y],
          [marker.x + marker.width, marker.y],
          [marker.x + marker.width, marker.y + marker.height],
          [marker.x, marker.y + marker.height],
          [marker.x, marker.y],
        ];
      case PlanViewerMarkerType.Circle:
        const circlePolygon: Array<[number, number]> = [];

        const circleRadius = Math.abs(marker.width || marker.height);

        const precision = 100;
        const pointZero = {
          x: marker.x - circleRadius / 2,
          y: marker.y - circleRadius / 2,
        };

        for (let theta = 0; theta < precision; theta += 2 * Math.PI / precision) {
          circlePolygon.push([
            pointZero.x + circleRadius * Math.cos(theta),
            pointZero.y + circleRadius * Math.sin(theta),
          ]);
        }

        return circlePolygon;
      case PlanViewerMarkerType.Cross:
        const crossWidth = 2;
        const crossSize = Math.abs(marker.width || marker.height);

        const halfS = crossSize / 2;
        const halfW = crossWidth / 2;

        return [
          [marker.x - halfW, marker.y],
          [marker.x - halfS - halfW, marker.y - halfS],
          [marker.x - halfS, marker.y - halfS - halfW],
          [marker.x, marker.y - halfW],
          [marker.x + halfS, marker.y - halfS - halfW],
          [marker.x + halfS + halfW, marker.y - halfS],
          [marker.x + halfW, marker.y],
          [marker.x + halfS + halfW, marker.y + halfS],
          [marker.x + halfS, marker.y + halfS + halfW],
          [marker.x, marker.y + halfW],
          [marker.x - halfS, marker.y + halfS + halfW],
          [marker.x - halfS - halfW, marker.y + halfS],
          [marker.x - halfW, marker.y],
        ];
      default:
        throw new Error('Unknown marker type');
    }
  }
}
