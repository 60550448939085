import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { CompanyManager } from './company.manager';
import { CompanyRepository } from './company.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    CompanyManager,
    CompanyRepository,
  ],
})
export class CompanyModule {
  static forChild(configuration: Configuration): ModuleWithProviders<CompanyModule> {
    return {
      ngModule: CompanyModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        CompanyManager,
        CompanyRepository,
      ],
    };
  }
}
