import { StaticProvider } from '@angular/core';

import { DialogService } from '../services/dialog.service';

export class DialogOptions {
  id?: number;
  title?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  fullScreen?: boolean;
  dragEnabled?: boolean;

  containerClassNames?: Array<string>;

  providers?: Array<StaticProvider>;
  dialogService?: DialogService;

  injectableData?: any;
}
