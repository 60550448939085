<div class="header">
  <div class="header-block">
    <app-colabo-badges [project]="currentProject" [lease]="lease">
    </app-colabo-badges>
    <div class="header-block-title">
      <i inlineSVG="assets/v2/icons/colabo-icons.svg#building-icon"></i>
      <span>
        {{getTitle()}}
      </span>
    </div>

    <div class="header-block-address"
         *ngIf="lease?.building.address">
      <i inlineSVG="assets/v2/icons/colabo-icons.svg#location-icon"></i>
      <span class="header-block-address-name">{{ getAddress() }}</span>

      <ng-container *ngIf="getOpponentCompany() as opponentCompany">
        <span class="header-block-address-dot"></span>
        <img alt
             [src]="opponentCompany.logo?.url || '/assets/img/nofoto.png'" />
        <span class="header-block-address-company-name">
          {{opponentCompany.name}}
        </span>
      </ng-container>
    </div>

    <div class="term-list-buttons">
      <ng-container *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Read; leaseId:lease?.id">
        <ng-container *appIsFeatureEnabled="Feature.ColaboFeature">
          <button class="term-list-button"
                  *ngIf="shouldShowExpandColaboButton"
                  (click)="handleExpandColaboClick()">
            <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-expand"></i>
            Expand Colábo
          </button>
        </ng-container>
      </ng-container>

      <ng-container *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id">
        <ng-container *appIsFeatureEnabled="Feature.ColaboFeature">
          <button class="term-list-button"
                  *ngIf="shouldShowConvertToDealButton"
                  [popover]="convertToDealPopoverTemplate" placement="bottom" [delay]="100"
                  [triggers]="isConvertToDealButtonDisabled ? 'mouseenter:mouseleave' : ''"
                  [disabled]="isConvertToDealButtonDisabled"
                  (click)="handleConvertToDealClick()">
            <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-renew"></i>
            Convert to deal
          </button>
          <ng-template #convertToDealPopoverTemplate>
            Coming soon
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id">
        <button class="term-list-button icon-only"
                *ngIf="shouldShowRequestTourButton"
                [popover]="requestTourPopoverTemplate" placement="bottom" triggers="mouseenter:mouseleave" [delay]="100"
                [disabled]="isRequestTourButtonDisabled"
                (click)="handleRequestTourClick()">
          <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-tour"></i>
        </button>
        <ng-template #requestTourPopoverTemplate>
          {{getRequestTourButtonTooltip()}}{{isRequestTourButtonDisabled ? ': Coming soon' : ''}}
        </ng-template>
      </ng-container>

      <ng-container *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Read; leaseId:lease?.id">
        <button class="term-list-button icon-only"
                *ngIf="shouldShowLeaseAnalysisButton"
                [popover]="leaseAnalysisPopoverTemplate" placement="bottom" triggers="mouseenter:mouseleave" [delay]="100"
                (click)="handleLeaseAnalysisClick()">
          <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-lease-analysis"></i>
        </button>
        <ng-template #leaseAnalysisPopoverTemplate>
          Lease Analysis
        </ng-template>
      </ng-container>

      <ng-container *appCheckAccess="StateraClaimType.Collabo_Negotiation_Cancel; stateraClaimValue: StateraClaimValue.Read; leaseId:lease?.id">
        <ng-container *ngIf="shouldShowCancelNegotiationButton">
          <ng-container *ngIf="isInquiryProject(); else cancelNegotiationButtonTemplate">
            <button class="term-list-button icon-only cancel-negotiation"
                    [popover]="cancelNegotiationPopoverTemplate" placement="bottom" triggers="mouseenter:mouseleave" [delay]="100"
                    (click)="handleCancelNegotiationClick()">
              <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-archive"></i>
            </button>
          </ng-container>
          <ng-template #cancelNegotiationButtonTemplate>
            <ng-container *appIsFeatureEnabled="Feature.ColaboFeature">
              <button class="term-list-button icon-only cancel-negotiation"
                      [popover]="cancelNegotiationPopoverTemplate" placement="bottom" triggers="mouseenter:mouseleave" [delay]="100"
                      (click)="handleCancelNegotiationClick()">
                <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-trash"></i>
              </button>
            </ng-container>
          </ng-template>
          <ng-template #cancelNegotiationPopoverTemplate>
            <ng-container *ngIf="isInquiryProject()">
              Archive Inquiry
            </ng-container>
            <ng-container *ngIf="!isInquiryProject()">
              Cancel Negotiation
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <div class="header-block-read"
         *ngIf="readBy"
         #readBadge>
      <div class="header-block-read-unread"
           *ngIf="readBy.unread">
        <span class="header-block-read-unread-icon" inlineSVG="assets/img/sign-icons.svg#unread-icon"></span>
        <span class="header-block-read-text">
          {{ readBy.text }}
        </span>
      </div>

      <ng-container *ngIf="!readBy.unread">
      <span class="header-block-read-text">
        {{ readBy.text }}
      </span>
        <div class="d-flex align-items-center">
          <img class="header-block-read-avatar" alt
               [src]="readBy.avatarUrl || '/assets/img/nofoto.png'"/>
          <span class="header-block-read-name">
            {{readBy.name}}
          </span>
        </div>
        <ng-container *ngIf="readBy.date">
          <span #readBadge
                class="header-block-read-time header-block-read-text">
            {{readBy.date | timeAgo}}
          </span>
          <dx-tooltip class="small-tooltip tooltip-arrow" position="top"
                      showEvent="dxhoverstart" hideEvent="dxhoverend"
                      [target]="readBadge">
            <p class="tooltip-text">
              {{readBy.date | date: 'MMM dd, YYYY, hh.mm aaa'}}
            </p>
          </dx-tooltip>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="isInquiryProject()">
  <div class="inquiry-terms-placeholder">
    <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-empty-file"></i>
    <h6>
      No Terms Yet :(
    </h6>
    <p>
      Convert this inquiry to a deal to add terms for negotiation
    </p>
  </div>
</ng-container>

<ng-container *ngIf="!isInquiryProject()">

  <dx-scroll-view #scrollView [showScrollbar]="'always'" class="b-list-status">
    <dx-accordion class="b-lease-terms"
                  [(dataSource)]="termConfigurations" [collapsible]="true" [multiple]="false" [animationDuration]="300"
                  #accordion>
      <div *dxTemplate="let leaseTermConfiguration of 'title'; index as i; last as last"
           [ngClass]="{'term-demo' : leaseTermConfiguration.leaseTermType == LeaseTermType.Term}">
        {{ dummy === undefined ? ready(last) : dummy }}

        <div class="lease-title">
          <ng-container *appIsFeatureEnabled="Feature.ColaboFeature; else noLinkInHeaderTemplate">
            <a class="lease-name"
               *ngIf="isTwoPaneViewAccessible; else noLinkInHeaderTemplate"
               [routerLink]="['/colabo/terms']"
               [queryParams]="{ leaseId: lease?.id, termName: leaseTermConfiguration?.termName}">
              {{ leaseTermConfiguration.description }}
            </a>
          </ng-container>
          <ng-template #noLinkInHeaderTemplate>
            <div class="lease-name">
              {{ leaseTermConfiguration.description }}
            </div>
          </ng-template>

          <div *ngIf="leaseTermConfiguration">
            <button id="tooltip{{leaseTermConfiguration.termName}}"
                    [disabled]="!leaseTermConfiguration.tooltip"
                    class="b-btn-icon-info mr-3">
              <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
            </button>
            <dx-tooltip target="#tooltip{{leaseTermConfiguration.termName}}"
                        position="top"
                        [width]="250"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend">
              <div class="d-flex flex-column tooltip-menu">
                {{leaseTermConfiguration.tooltip}}
              </div>
            </dx-tooltip>
          </div>
        </div>


        <div class="status-row status" [ngClass]="getStatusClass(leaseTermConfiguration.leaseTermType)">
          {{ getTermStatusName(leaseTermConfiguration.leaseTermType) }}
        </div>
      </div>

      <div *dxTemplate="let leaseTermConfiguration of 'item'">
        <ng-container *ngIf="lease">
          <ng-container>
            <p *ngIf="showCurrentLeaseLanguage(lease)" class="font-weight-bold">
              This is the current lease language:
            </p>

            <p *ngIf="showThirdPartyLease(lease)" class="font-weight-bold">
              <ng-container *ngIf="lease && !lease.thirdPartyLease">
                N/A
              </ng-container>

              <ng-container *ngIf="lease && lease.thirdPartyLease">
                This is the current lease language:
              </ng-container>
            </p>
            <app-no-current-lease-provided-answer [lease]="lease"></app-no-current-lease-provided-answer>
            <div class="mb-3"
                 #popoverContainerElement>
              <ng-container *ngIf="getTerm(leaseTermConfiguration) as abstractTerm">
                <app-lease-term *ngIf="leaseTermConfiguration.leaseTermType !== LeaseTermType.Commencement && !isNewDeal()"
                                [numberOfLines]="3" [termValue]="getTermDisplayValue(abstractTerm)"
                                [leaseTermConfiguration]="leaseTermConfiguration" [project]="project">
                  <!-- Start: Rental Rate Schedule Preview Table -->
                  <ng-container *ngIf="isBaseRentalRate(abstractTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(abstractTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Rental Rate Schedule Preview Table -->
                  <!-- Start: Size Phase-in Table -->
                  <ng-container *ngIf="isTenantSquareFootagePhaseInTermValue(abstractTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getTenantSquareFootagePhaseInCustomTable(abstractTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Size Phase-in Table -->
                  <!-- Start: Rental Rate Escalation Custom Table -->
                  <ng-container *ngIf="isRentalRateEscalationCustomOption(abstractTerm)">
                    <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                         [leaseTermCustomTable]="getRentalRateEscalationCustomTable(abstractTerm)">
                    </app-lease-term-custom-table-popover>
                  </ng-container>
                  <!-- End: Rental Rate Escalation Custom Table -->
                </app-lease-term>
                <ng-container *ngIf="leaseTermConfiguration.leaseTermType === LeaseTermType.Commencement">
                  {{getTermDisplayValue(abstractTerm) | safeDate : 'MMMM d, y'}} (Lease Commenced)
                </ng-container>
              </ng-container>
            </div>
            <p *ngIf="leaseTermConfiguration.leaseTermType === LeaseTermType.Commencement && getTermAbstractExpirationDate()">
              {{getTermAbstractExpirationDate() | safeDate : 'MMMM d, y'}} (Lease Expiration)
            </p>
          </ng-container>

          <ng-container *ngIf="getTermStatus(leaseTermConfiguration) !== TermStatus.Draft && (hasAbstractLease() || isNewDeal())">
            <p class="font-weight-bold">
              <ng-container *ngIf="isAbstractLease(); else notAbstract">
                Last negotiated response:
              </ng-container>
              <ng-template #notAbstract>
                Current negotiation response:
              </ng-template>
            </p>

            <div [class.accepted-in-letter-of-intent]="isAcceptedInLoi()"
                 #popoverContainerElement>
              <div class="accepted-in-letter-of-intent-badge"
                   *ngIf="isAcceptedInLoi()">
                Accepted in LOI
              </div>
              <!-- Start: Custom Lease Term View -->
              <ng-container *ngIf="leaseTermConfiguration"
                            [ngSwitch]="leaseTermConfiguration.leaseTermType">
                <!-- Start: Size Term -->
                <ng-container *ngSwitchCase="LeaseTermType.TenantSquareFootage">
                  <ng-container [ngSwitch]="getTenantSquareFootageTermType()">
                    <ng-container *ngSwitchCase="TenantSquareFootageTermType.PhaseIn">
                      <app-tenant-square-footage-phase-in-result
                        [phaseInResults]="getTenantSquareFootagePhaseInResults(lease?.tenantSquareFootageTerm)">
                      </app-tenant-square-footage-phase-in-result>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <p>
                        <app-lease-term *ngIf="lease"
                                        [numberOfLines]="3" [lease]="lease"
                                        [leaseTermConfiguration]="leaseTermConfiguration"
                                        [project]="project">
                        </app-lease-term>
                      </p>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- End: Size Term -->
                <!-- Start: Rental Rate Escalation Term -->
                <ng-container *ngSwitchCase="LeaseTermType.RentalRateAnnualEscalation">
                  <ng-container [ngSwitch]="getRentalRateEscalationTermType()">
                    <!-- Start: Rental Rate Escalation Custom Results -->
                    <ng-container *ngSwitchCase="RentalRateEscalationTermType.Custom">
                      <app-rental-rate-escalation-result-table
                        [customRentalRateEscalationCustomResults]="getRentalRateEscalationCustomOption(lease?.rentalRateAnnualEscalationTerm)"
                        [unitMetrics]="getUnitMetrics(lease?.baseRentalRateTerm)">
                      </app-rental-rate-escalation-result-table>
                    </ng-container>
                    <!-- End: Rental Rate Escalation Custom Results -->
                    <ng-container *ngSwitchDefault>
                      <p>
                        <app-lease-term *ngIf="lease"
                                        [numberOfLines]="3" [lease]="lease"
                                        [leaseTermConfiguration]="leaseTermConfiguration"
                                        [project]="project">
                        </app-lease-term>
                      </p>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- End: Rental Rate Escalation Term -->
                <!-- Start: Base Rental Rate Term -->
                <ng-container *ngSwitchCase="LeaseTermType.BaseRentalRate">
                  <div class="d-flex flex-row justify-content-start align-items-center mx-n1">
                    <div class="px-1">
                      <app-lease-term *ngIf="lease"
                                      [numberOfLines]="3" [lease]="lease"
                                      [leaseTermConfiguration]="leaseTermConfiguration"
                                      [project]="project">
                        <!-- Start: Rental Rate Schedule Preview Table -->
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(lease?.baseRentalRateTerm)">
                        </app-lease-term-custom-table-popover>
                        <!-- End: Rental Rate Schedule Preview Table -->
                      </app-lease-term>
                    </div>
                  </div>
                </ng-container>
                <!-- End: Base Rental Rate Term -->
                <!-- Start: Real Estate Taxes Cam Expenses -->
                <ng-container *ngSwitchCase="LeaseTermType.RealEstateTaxesCamExpenses">
                  <div class="d-flex flex-row justify-content-start align-items-center mx-n1">
                    <div class="px-1">
                      <app-lease-term *ngIf="lease"
                                      [numberOfLines]="3" [lease]="lease"
                                      [leaseTermConfiguration]="leaseTermConfiguration"
                                      [project]="project">
                        <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                             [leaseTermCustomTable]="getRealEstateTaxesCustomTable(lease?.realEstateTaxesCamExpensesTerm)">
                        </app-lease-term-custom-table-popover>
                      </app-lease-term>
                    </div>
                  </div>
                </ng-container>
                <!-- End: Real Estate Taxes Cam Expenses -->
                <!-- Start: Security Deposit Term -->
                <ng-container *ngSwitchCase="LeaseTermType.SecurityDeposit">
                  <ng-container *ngIf="hasSecurityDepositTermBurnDownOption(); else elseBlock">
                    <app-security-deposit-burn-down-schedule-result
                      [burnDownScheduleValues]="getBurnDownScheduleResults(lease?.securityDepositTerm)">
                    </app-security-deposit-burn-down-schedule-result>
                  </ng-container>
                  <ng-template #elseBlock>
                    <p>
                      <app-lease-term *ngIf="lease"
                                      [numberOfLines]="3" [lease]="lease"
                                      [leaseTermConfiguration]="leaseTermConfiguration"
                                      [project]="project">
                      </app-lease-term>
                    </p>
                  </ng-template>
                </ng-container>
                <!-- End: Security Deposit Term -->
                <ng-container *ngSwitchDefault>
                  <p>
                    <app-lease-term *ngIf="lease"
                                    [numberOfLines]="3" [lease]="lease" [leaseTermConfiguration]="leaseTermConfiguration"
                                    [project]="project">
                    </app-lease-term>
                  </p>
                </ng-container>
              </ng-container>
              <!-- End: Custom Lease Term View -->
            </div>

          </ng-container>
        </ng-container>
      </div>
    </dx-accordion>
  </dx-scroll-view>
</ng-container>
