import { Component, Input, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';

import DataSource from 'devextreme/data/data_source';

import { CommonTools } from '@statera/sdk/common';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { AlertService } from '../../../shared/services/alert.service';
import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit, OnDestroy {
  @ViewChild('buttonContainer') buttonContainer: ElementRef;

  _height = 200;
  treeListHeight = 200;

  @Input() set height(val) {
    if (val) {
      this._height = val;
      const buttonContainerHeight = this.buttonContainer ? this.buttonContainer.nativeElement.clientHeight : 0;
      this.treeListHeight = val - buttonContainerHeight;
    }
  }

  @Input() project: models.IProjectViewModel;

  get height() {
    return this._height;
  }

  _lease: models.ILeaseViewModel;
  @Input()
  set lease(val: models.ILeaseViewModel) {
    this._lease = val;

    if (val) {
      this.setDataSource();
    }
  }

  get lease() {
    return this._lease;
  }

  leaseTermConfigurations: Array<models.ILeaseTermConfiguration>;

  AlertType = models.AlertType;
  alertDataSource: any;
  Tools = CommonTools;
  dataLoaded = false;

  private readonly _alertService: AlertService;
  private readonly _authService: AuthService;
  private readonly _router: Router;
  private readonly _domSanitizer: DomSanitizer;

  private readonly _destroy$: Subject<void>;

  constructor(alertService: AlertService, authService: AuthService, router: Router, domSanitizer: DomSanitizer) {
    this._alertService = alertService;
    this._authService = authService;
    this._router = router;
    this._domSanitizer = domSanitizer;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._authService
      .infoLoadComplete
      .pipe(
        tap((info) => {
          if (info && info.lists) {
            this.leaseTermConfigurations = info.lists.leaseTermConfigurations;
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  readAllAlerts(): void {
    this._alertService.markReadAllAlerts(this._lease.id).subscribe();
  }

  onItemClick(e): void {
    const alert = <models.IAlertViewModel>e.itemData;
    if (!alert) {
      return;
    }

    this._alertService
      .markReadAlert(alert)
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          if (
            alert.alertSettingTemplate.alertType === models.AlertType.StateChange &&
            alert.projectType === models.ProjectTypeEnum.NewDealInquiry
          ) {
            this._router.navigate(['colabo', 'main', alert.leaseId]);
            return;
          }

          if (!this.isTwoPaneViewAvailable()) {
            return;
          }

          if (alert.alertSettingTemplate.alertType === models.AlertType.StateChange) {
            this._router.navigate(['/colabo/terms'], {queryParams: {leaseId: alert.leaseId}});
            return;
          }

          if (alert.alertSettingTemplate.alertType === models.AlertType.TermComment) {
            if (!alert.leaseTermType || !this.leaseTermConfigurations) {
              return;
            }

            const leaseTermConfiguration = this
              .leaseTermConfigurations
              .find(x => x.leaseTermType === alert.leaseTermType);

            if (!leaseTermConfiguration) {
              return;
            }

            this._router
              .navigate(
                ['/colabo/terms'],
                {
                  queryParams: {
                    leaseId: alert.leaseId,
                    termName: leaseTermConfiguration.termName,
                    chatTab: alert.termCommentType === models.TermCommentType.BothTeams
                      ? 'External'
                      : 'Internal',
                  },
                },
              );
          }

          if (alert.alertSettingTemplate.alertType === models.AlertType.Message) {
            this._router
              .navigate(
                ['/colabo/terms'],
                {
                  queryParams: {
                    leaseId: alert.leaseId,
                    chatTab: (
                      alert.chatChannelType === models.ChatChannelType.LeaseLandlordTeam ||
                      alert.chatChannelType === models.ChatChannelType.LeaseTenantTeam
                    )
                      ? 'Internal'
                      : 'External',
                  },
                },
              );
          }
        }),
      )
      .subscribe();
  }

  getAvatarUrl(alertViewModel: models.IAlertViewModel): string {
    if (alertViewModel.alertCreatorType === models.AlertCreatorType.System) {
      return 'assets/img/oxana-small.png';
    }

    if (alertViewModel && alertViewModel.createdBy) {
      if (alertViewModel.createdBy.role === 'Admin') {
        return 'assets/img/oxana-small.png';
      }

      if (alertViewModel.createdBy.avatar && alertViewModel.createdBy.avatar.url) {
        return alertViewModel.createdBy.avatar.url;
      }
    }

    return 'assets/img/avatar.png';
  }

  getLeaseTermDescriptionByLeaseTermType(leaseTermType: models.LeaseTermType): string {
    if (!leaseTermType || !this.leaseTermConfigurations) {
      return;
    }

    const leaseTermConfiguration = this
      .leaseTermConfigurations
      .find(x => x.leaseTermType === leaseTermType);

    if (!leaseTermConfiguration) {
      return;
    }

    return leaseTermConfiguration.description;
  }

  getDocumentNameByProjectType(projectType: models.ProjectTypeEnum): string {
    switch (projectType) {
      case models.ProjectTypeEnum.NewDeal:
        return 'Lease Document';
      default:
        return 'Lease Amendment';
    }
  }

  getDisplayName(alert: models.IAlertViewModel, createdBy: models.IProfileViewModel): string {
    if (alert && alert.alertCreatorType === models.AlertCreatorType.System) {
      return 'Aleasa';
    }

    if (createdBy) {
      return createdBy.displayName;
    }
  }

  setDataSource(): void {
    this.alertDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'id',
        loadUrl: `${environment.webApiUrl}/alert/GetAlertsByLeaseIdExcludeMessages?leaseId=${this.lease.id}`,
        onBeforeSend: (m, o) => {
          o.xhrFields = {withCredentials: true};
        },
      }),
      pageSize: 15,
      postProcess: (data: Array<models.IAlertViewModel & {sanitizedHtml: SafeHtml}>) => {
        if (!data) {
          return data;
        }

        for (let i = 0, num = data.length; i < num; i++) {
          const alert = data[i];

          data[i].sanitizedHtml = this._domSanitizer
            .bypassSecurityTrustHtml(alert.text ? this._alertService.getRenderedTemplate(alert) : '');
        }

        this.dataLoaded = true;

        return data;
      },
    });
  }

  isTwoPaneViewAvailable(): boolean {
    return this.project && !!this.project.projectState;
  }

  getTermCommentTypeName(termCommentType: models.TermCommentType): string {
    if (termCommentType === models.TermCommentType.LandlordTeam || termCommentType === models.TermCommentType.TenantTeam) {
      return 'internal';
    }
    return 'external';
  }

  getVersionedFileCommentTypeName(versionedFileCommentType: models.VersionedFileCommentType): string {
    if (
      versionedFileCommentType === models.VersionedFileCommentType.LandlordTeam ||
      versionedFileCommentType === models.VersionedFileCommentType.TenantTeam
    ) {
      return 'internal';
    }
    return 'external';
  }

  getChatChannelTypeName(chatChannelType: models.ChatChannelType): string {
    if (chatChannelType === models.ChatChannelType.LeaseTenantTeam || chatChannelType === models.ChatChannelType.LeaseLandlordTeam) {
      return 'Internal';
    }
    return 'External';
  }

  getDate(date: Date) {
    const today = moment().startOf('day'); // Today
    const yesterDay = moment().subtract(1, 'days').startOf('day');  // Yesterday
    const current = moment(date).startOf('day');  // Current

    if (today.isSame(current)) {
      return 'Today';
    } else if (yesterDay.isSame(current)) {
      return 'Yesterday';
    } else {
      return current.format('MMM DD');
    }
  }
}
