import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { LeaseManager } from './lease.manager';
import { LeaseRepository } from './lease.repository';

//
// The lease module provides an API for the general functionality of the lease entity.
// If you want to add or change logic for a specific lease term, use a separate module instead.
//

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    LeaseManager,
    LeaseRepository,
  ],
})
export class LeaseModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LeaseModule> {
    return {
      ngModule: LeaseModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LeaseManager,
        LeaseRepository,
      ],
    };
  }
}
