<dx-scroll-view width="100%" height="100%">
  <div class="modal-dialog">
    <!-- Start: Dialog buttons -->
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary"
              (click)="onCloseClick()">
        Close
      </button>
      <button type="button" class="btn btn-primary"
              (click)="onSaveChangesClick()">
        Save changes
      </button>
    </div>
    <!-- End: Dialog buttons -->
    <!-- Start: Cropper action buttons -->
    <div class="action-buttons">
      <button type="button"
              class="btn btn-light btn-select second"
              (click)="onRotateClick(-90)">
        <span class="text-uppercase text-13 fw-500">
          <i class="fa fa-undo"></i>
          Left
        </span>
      </button>
      <button type="button"
              class="btn btn-light btn-select fourth"
              (click)="onSelectClick()">
        <span class="text-uppercase text-13 fw-500">
          Select File
        </span>
      </button>
      <button type="button"
              class="btn btn-light btn-select third"
              (click)="onRotateClick(90)">
        <span class="text-uppercase text-13 fw-500">
          Right
          <i class="fa fa-repeat"></i>
        </span>
      </button>
    </div>
    <!-- End: Cropper action buttons -->
    <!-- Start: Cropper -->
    <div class="cropper">
      <img [croppieOptions]="croppieOptions"
           (update)="handleUpdate()"
           #croppie="croppie" />
    </div>
    <!-- End: Cropper -->
    <!-- Start: Uploader -->
    <dx-file-uploader id="file"
                      [visible]="false"
                      [name]="uploaderOptions.name"
                      [uploadUrl]="uploaderOptions.uploadUrl"
                      (onUploaded)="uploaderOptions.onUploaded($event)"
                      #fileUploader>
    </dx-file-uploader>
    <!-- End: Uploader -->
  </div>
</dx-scroll-view>
