import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Configuration } from '@statera/sdk/common';

import * as models from './lease-floor-plan-comment.model';

@Injectable()
export class LeaseFloorPlanCommentRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addMessage(message: models.LeaseFloorPlanComment): Observable<models.LeaseFloorPlanComment> {
    return this._httpClient
      .post<models.LeaseFloorPlanComment>(
        `${this._configuration.apiEndpoint}/floorplan/CreateLeaseFloorPlanComment`,
        message,
      );
  }

  getMessages(leaseId: number, planId: number, skip?: number, take?: number): Observable<Array<models.LeaseFloorPlanComment>> {
    let params = new HttpParams();

    params = params.set('leaseId', leaseId.toString());
    params = params.set('planId', planId.toString());

    if (skip || skip === 0) {
      params = params.set('skip', skip.toString());
    }

    if (take || take === 0) {
      params = params.set('take', take.toString());
    }

    return this._httpClient
      .get<Array<models.LeaseFloorPlanComment>>(
        `${this._configuration.apiEndpoint}/floorplan/GetLeaseFloorPlanComments`,
        {
          params,
        },
      );
  }

  updateMessage(message: models.LeaseFloorPlanComment): Observable<models.LeaseFloorPlanComment> {
    return this._httpClient
      .post<models.LeaseFloorPlanComment>(
        `${this._configuration.apiEndpoint}/floorplan/UpdateLeaseFloorPlanComment`,
        message,
      );
  }

  deleteMessage(message: models.LeaseFloorPlanComment): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/floorplan/DeleteLeaseFloorPlanComment`,
        message,
      );
  }

  markAllAsRead(leaseId: number, planId: number): Observable<void> {
    let params = new HttpParams();

    params = params.set('leaseId', leaseId.toString());
    params = params.set('planId', planId.toString());

    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/floorplan/MarkLeaseFloorPlanCommentsAsRead`,
        null,
        {
          params,
        },
      )
      .pipe(
        share(),
      );
  }
}
