<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-12 mb-2 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options | filter : 'disabled'" [(value)]="leaseTerm.utilitiesType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Select appropriate option">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>

    <div class="row term-container mb-2" *ngIf="leaseTerm.utilitiesType === UtilitiesTermType.LandlordPaysExcept">
      <div class="col-sm-6">
        <label for="tenantMeteredUtilities">Please pick all utilities that apply:</label>
        <dx-tag-box id="tenantMeteredUtilities" name="tenant-metered-utilities" displayExpr="text" required
                    [(dataSource)]="tenantMeteredOptions" [showClearButton]="true"
                    [(ngModel)]="tenantMeteredItems"
                    (ngModelChange)="clearErrors()"
                    #tenantMeteredUtilities="ngModel">
        </dx-tag-box>
      </div>
    </div>

    <div class="row term-container mb-4"
         *ngIf="leaseTerm.utilitiesType === UtilitiesTermType.Custom">
      <div class="col-8 pl-0  app-signage">
        <app-term-custom-value-template-textarea placeholder="Insert custom utilities clause"
                                                 required requiredMessage="Custom Utilities clause is required"
                                                 [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                 [(value)]="leaseTerm.customDisplayValue"
                                                 (valueChange)="clearErrors()">
        </app-term-custom-value-template-textarea>
      </div>
    </div>

    <ng-container *ngIf="leaseTerm.utilitiesType &&
                        (leaseTerm.utilitiesType === UtilitiesTermType.LandlordPays ||
                         leaseTerm.utilitiesType === UtilitiesTermType.LandlordPaysExcept)">
      <div class="row term-container mb-2">
        <div class="col-sm-6 green-lease">
          <div class="green-lease__checkbox">
            <dx-check-box id="greenLeaseOption" [(value)]="leaseTerm.hasGreenLeaseOption">
            </dx-check-box>
          </div>

          <div class="green-lease__title">
            <label for="greenLeaseOption">Green Lease Clause (Optional) 🌿</label>
          </div>
        </div>
      </div>

      <div class="row term-container mb-2" *ngIf="leaseTerm.hasGreenLeaseOption">
        <div class="col-sm-12 mb-2 radio-group-container">
          <div class="dx-field-value">
            <dx-radio-group displayExpr="name" valueExpr="value"
                            [dataSource]="greenLeaseOptions" [(value)]="leaseTerm.greenLeaseOption"
                            (valueChange)="clearErrors()">
              <dx-validator>
                <dxi-validation-rule type="required" message="Select appropriate Green Lease option">
                </dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>
        <div class="col-sm-6"></div>
      </div>
    </ng-container>

    <!-- Start: Validation summary -->
    <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
    </div>
    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
