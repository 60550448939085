import { Injectable } from '@angular/core';

import { ChatMessageManager } from './chat-message/chat-message.manager';
import { ChatMessageRepository } from './chat-message/chat-message.repository';

import { LeaseFloorPlanCommentManager } from './lease-floor-plan-comment/lease-floor-plan-comment.manager';
import { LeaseFloorPlanCommentRepository } from './lease-floor-plan-comment/lease-floor-plan-comment.repository';

import { TermCommentManager } from './term-comment/term-comment.manager';
import { TermCommentRepository } from './term-comment/term-comment.repository';
import { TermCommentType } from './term-comment/term-comment.model';

import { VersionedFileCommentManager } from './versioned-file-comment/versioned-file-comment.manager';
import { VersionedFileCommentRepository } from './versioned-file-comment/versioned-file-comment.repository';
import { VersionedFileCommentType } from './versioned-file-comment/versioned-file-comment.model';

import * as models from './message.model';

@Injectable()
export class MessageManagerFactory {
  private readonly _chatMessageRepository: ChatMessageRepository;
  private readonly _leaseFloorPlanCommentRepository: LeaseFloorPlanCommentRepository;
  private readonly _termCommentRepository: TermCommentRepository;
  private readonly _versionedFileCommentRepository: VersionedFileCommentRepository;

  constructor(
    chatMessageRepository: ChatMessageRepository,
    leaseFloorPlanCommentRepository: LeaseFloorPlanCommentRepository,
    termCommentRepository: TermCommentRepository,
    versionedFileCommentRepository: VersionedFileCommentRepository,
  ) {
    this._chatMessageRepository = chatMessageRepository;
    this._leaseFloorPlanCommentRepository = leaseFloorPlanCommentRepository;
    this._termCommentRepository = termCommentRepository;
    this._versionedFileCommentRepository = versionedFileCommentRepository;
  }

  createChatMessageManager(
    startupInfo: models.StartupInfo,
    chatChannelId: number,
    lease: models.Lease,
  ): ChatMessageManager {
    return new ChatMessageManager(
      this._chatMessageRepository,
      startupInfo,
      chatChannelId,
      lease,
    );
  }

  createLeaseFloorPlanCommentManager(
    startupInfo: models.StartupInfo,
    leaseId: number,
    planId: number,
    lease: models.Lease,
  ): LeaseFloorPlanCommentManager {
    return new LeaseFloorPlanCommentManager(
      this._leaseFloorPlanCommentRepository,
      startupInfo,
      leaseId,
      planId,
      lease,
    );
  }

  createTermCommentManager(
    startupInfo: models.StartupInfo,
    leaseTermType: models.LeaseTermType,
    termCommentType: TermCommentType,
    lease: models.Lease,
  ): TermCommentManager {
    return new TermCommentManager(
      this._termCommentRepository,
      startupInfo,
      termCommentType,
      leaseTermType,
      lease,
    );
  }

  createVersionedFileCommentManager(
    startupInfo: models.StartupInfo,
    versionedFileCommentType: VersionedFileCommentType,
    versionedFileId?: number,
    lease?: models.Lease,
  ): VersionedFileCommentManager {
    return new VersionedFileCommentManager(
      this._versionedFileCommentRepository,
      startupInfo,
      versionedFileCommentType,
      versionedFileId,
      lease,
    );
  }
}
