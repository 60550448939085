import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommonTools } from '@statera/sdk/common';

import { ILeaseViewModel, ITenantDashboardViewModel } from '../../infrastructure/models/generated';

import * as moment from 'moment';

import { TermManager } from '@statera/sdk/term';

import { SafeNumberPipe } from '../../infrastructure/pipes/safe-number.pipe';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';
import { LeaseStore } from '../states/lease/lease.store';

@Injectable({
  providedIn: 'root',
})
export class LeaseService {
  private _httpClient: HttpClient;
  private _safeNumber: SafeNumberPipe;
  private readonly _termManager: TermManager;
  private readonly _leaseStore: LeaseStore;
  private _numberFormat = '1.2-2';

  constructor(httpClient: HttpClient, safeNumber: SafeNumberPipe, termManager: TermManager, leaseStore: LeaseStore) {
    this._httpClient = httpClient;
    this._safeNumber = safeNumber;
    this._termManager = termManager;
    this._leaseStore = leaseStore;
  }

  getLease(leaseId: number): Observable<models.ILeaseViewModel> {
    return this._httpClient.get<models.ILeaseViewModel>(`${environment.webApiUrl}/lease/get?leaseId=${leaseId}`);
  }

  getLeaseWithAbstracts(leaseId: number): Observable<models.ILeaseViewModel> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .get<models.ILeaseViewModel>(`${environment.webApiUrl}/Lease/GetLeaseWithAbstracts`, {params})
      .pipe(
        tap(lease => {
          const leaseCopy = CommonTools.deepCopy(lease);
          this._leaseStore.upsert(leaseCopy.id, leaseCopy);
        })
      );
  }

  getTermStatus(termName: string, leaseTermStatuses: Array<models.ILeaseTermStatus>, lease): string {
    if (!leaseTermStatuses) {
      return models.TermStatus[models.TermStatus.Draft];
    }

    const termStatus = leaseTermStatuses.find(x => x.termName === termName);

    if (termStatus == null) {
      const leaseTermStatus: models.ILeaseTermStatus = <models.ILeaseTermStatus>{
        termName,
        termStatus: models.TermStatus.Draft
      };
      leaseTermStatuses.push(leaseTermStatus);
      return models.TermStatus[leaseTermStatus.termStatus];
    }
    return models.TermStatus[termStatus.termStatus];
  }

  getLeases(excludeAbstracts: boolean = false, includeRejected: boolean = false): Observable<Array<models.ILeaseViewModel>> {
    let params = new HttpParams();

    if (excludeAbstracts) {
      params = params.set('excludeAbstracts', 'true');
    }

    if (includeRejected) {
      params = params.set('includeRejected', 'true');
    }

    return this._httpClient
      .get<Array<models.ILeaseViewModel>>(`${environment.webApiUrl}/lease/GetLeases`, {params});
  }

  getTenantLeaseAnalysisData(model: models.ILeaseAnalysisOptionsViewModel): Observable<models.ILeaseAnalysisTenantPerspectiveResult> {
    return this._httpClient.post<models.ILeaseAnalysisTenantPerspectiveResult>(
      `${environment.webApiUrl}/lease-analysis/tenant-report`,
      model,
    );
  }

  getLandlordLeaseAnalysisData(model: models.ILeaseAnalysisOptionsViewModel): Observable<models.ILeaseAnalysisLandlordPerspectiveResult> {
    return this._httpClient.post<models.ILeaseAnalysisLandlordPerspectiveResult>(
      `${environment.webApiUrl}/lease-analysis/landlord-report`,
      model,
    );
  }

  getLeaseComparisonSummaryReport(model: models.ILeaseAnalysisOptionsViewModel): Observable<models.IComparisonSummaryReportViewModel> {
    return this._httpClient.post<models.IComparisonSummaryReportViewModel>(
      `${environment.webApiUrl}/lease-analysis/comparison-summary-report`,
      model,
    );
  }

  renewLease(model: models.ILeaseViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient.post<models.ILeaseViewModel>(
      `${environment.webApiUrl}/lease/renewLease`,
      model,
    );
  }

  newDeal(model: models.ILeaseViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient.post<models.ILeaseViewModel>(
      `${environment.webApiUrl}/lease/newDealLease`,
      model,
    );
  }

  newDealByBuildingUnit(model: models.IBuildingUnitViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient.post<models.ILeaseViewModel>(
      `${environment.webApiUrl}/lease/newDealLease`,
      model,
    );
  }

  restructureLease(model: models.ILeaseViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient.post<models.ILeaseViewModel>(
      `${environment.webApiUrl}/lease/RestructureLease`,
      model,
    );
  }

  renewLeaseByLandlord(model: models.ILeaseViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient.post<models.ILeaseViewModel>(
      `${environment.webApiUrl}/lease/renewLeaseByLandlord`,
      model,
    );
  }

  getLeaseAnalysisProjects(): Observable<Array<models.ILeaseAnalysisProjectViewModel>> {
    return this._httpClient.get<Array<models.ILeaseAnalysisProjectViewModel>>(
      `${environment.webApiUrl}/lease-analysis/projects`
    );
  }

  cancelRequestViaLandlordWizard(model: models.IRejectionLeaseViewModel): Observable<void> {
    return this._httpClient.post<void>(
      `${environment.webApiUrl}/lease/CancelRequestViaLandlordWizard`, model
    );
  }

  setFloorPlanRequested(leaseId: number, isFloorPlanRequested: boolean) {
    const floorPlanRequested: models.IFloorPlanRequestedViewModel = <models.IFloorPlanRequestedViewModel>{
      leaseId,
      isFloorPlanRequested,
    };

    return this._httpClient.post(`${environment.webApiUrl}/lease/PostFloorPlanRequested`, floorPlanRequested);
  }

  getStartingRate(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return null;
    }

    const { baseRentalRateTerm } = lease;
    if (!baseRentalRateTerm || !baseRentalRateTerm.baseRentalRateType) {
      return '';
    }

    let baseRentalRateValue = 0;
    switch (baseRentalRateTerm.baseRentalRateType) {
      case models.BaseRentalRateType.Net:
        baseRentalRateValue = baseRentalRateTerm.baseRentalRateNetValue;
        break;

      case models.BaseRentalRateType.Gross:
        baseRentalRateValue = baseRentalRateTerm.baseRentalRateGrossValue;
        break;

      case models.BaseRentalRateType.BaseYear:
        baseRentalRateValue = baseRentalRateTerm.actualYearRate;
        break;
    }

    let unitMetrics = 'PSF/Yr';
    if (baseRentalRateTerm.baseRentalRateUnitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = 'PSF/Mo';
    }

    return `$${this._safeNumber.transform(baseRentalRateValue, '.2-2')} ${unitMetrics}`;
  }

  getServiceType(lease: models.ILeaseViewModel): string {
    if (lease) {
      const baseRentalRate = lease.baseRentalRateTerm;

      if (baseRentalRate) {
        if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Gross) {
          return 'Gross';
        }

        if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Net) {
          return 'Net';
        }

        if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.None) {
          return 'None';
        }

        if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.BaseYear) {
          return 'Modified Gross';
        }
      }
    }

    return '';
  }

  getFreeRent(lease: models.ILeaseViewModel): string {
    if (lease) {
      const freeRent = lease.freeRentTerm;

      if (freeRent) {
        if (freeRent.freeRentTermType === models.FreeRentTermType.None) {
          return 'None';
        }

        let taxType = '';

        if (freeRent.freeRentTaxesType === models.FreeRentTaxesType.Gross) {
          taxType = 'Gross';
        }

        if (freeRent.freeRentTaxesType === models.FreeRentTaxesType.Net) {
          taxType = 'Net';
        }

        if (freeRent.freeRentTermType === models.FreeRentTermType.MonthsCount) {
          return `${freeRent.freeRentMonthsCount} months ${taxType}`;
        }

        if (freeRent.freeRentTermType === models.FreeRentTermType.SpecificSchedule) {
          return `Months ${freeRent.specificMonths.join(', ')} are ${taxType}`;
        }

        return 'None';
      }
    }

    return 'None';
  }

  getImprovements(lease: models.ILeaseViewModel): string {
    const result = [];

    if (lease) {
      const { tenantImprovementsTerm } = lease;

      if (tenantImprovementsTerm) {
        const {
          tenantImprovementsType,
          tenantImprovementsValue,
          tenantImprovementsAmortized,
          tenantImprovementsAmortizationOption,
          tenantImprovementsAmortizationAmount,
        } = tenantImprovementsTerm;

        if (tenantImprovementsType === models.TenantImprovementsType.TurnKey) {
          result.push('Turnkey');
        }

        if (tenantImprovementsType === models.TenantImprovementsType.CustomAmortized) {
          result.push(`$${this._safeNumber.transform(tenantImprovementsAmortized, this._numberFormat)} Amortized`);
        }

        if (tenantImprovementsType === models.TenantImprovementsType.AsIs) {
          result.push('None');
        }

        if (tenantImprovementsType === models.TenantImprovementsType.DollarAllowance) {
          if (tenantImprovementsAmortizationOption === models.TenantImprovementsAmortizationOption.AmortizationOption) {
            const amortizationAmount = this._safeNumber
              .transform(tenantImprovementsAmortizationAmount, this._numberFormat);

            result.push(`$${amortizationAmount} Amortized`);
          }
        }

        if (tenantImprovementsValue) {
          result
            .unshift(
              `$${this._safeNumber.transform(tenantImprovementsValue, this._numberFormat)} Included`
            );
        }

        return result.join('; ');
      }
    }

    return '';
  }

  getTotal(leaseFloorPlans: Array<models.ILeaseFloorPlanViewModel>) {
    if (leaseFloorPlans) {

      let sum = 0;

      leaseFloorPlans.forEach(leaseFloorPlan => {
        if (leaseFloorPlan && leaseFloorPlan.totalAnchorAmount) {
          sum += leaseFloorPlan.totalAnchorAmount;
        } else {
          leaseFloorPlan.anchors.forEach(anchor => {
            if (anchor && anchor.amount) {
              sum += anchor.amount;
            }
          });
        }
      });

      return sum;
    }
  }

  getOperationExp(lease: models.ILeaseViewModel): string {
    if (lease) {
      const realEstateTaxesCamExpenses = lease.realEstateTaxesCamExpensesTerm;
      if (realEstateTaxesCamExpenses) {
        if (realEstateTaxesCamExpenses.estimatedOpEx && realEstateTaxesCamExpenses.estimatedReTaxes) {
          const sum = realEstateTaxesCamExpenses.estimatedOpEx + realEstateTaxesCamExpenses.estimatedReTaxes;
          const unitMetrics = this._termManager.getBaseRentalRateUnitMetricsDisplayValue(lease.baseRentalRateTerm);

          return `$${this._safeNumber.transform(sum, this._numberFormat)} ${unitMetrics} (3% inflation)`;
        }
        return '';
      }
    }
    return '';
  }


  getBaseYearStopInfo(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return null;
    }

    const { baseRentalRateTerm, realEstateTaxesCamExpensesTerm } = lease;
    if (
      !baseRentalRateTerm ||
      !realEstateTaxesCamExpensesTerm ||
      !realEstateTaxesCamExpensesTerm.baseYearForExpenses ||
      !baseRentalRateTerm.baseRentalRateType ||
      baseRentalRateTerm.baseRentalRateType !== models.BaseRentalRateType.BaseYear
    ) {
      return null;
    }

    const baseYear = moment(realEstateTaxesCamExpensesTerm.baseYearForExpenses).year();

    let baseYearStopInfo = `${baseYear} Base Year Stop`;

    if (
      realEstateTaxesCamExpensesTerm.estimatedReTaxesType === models.RealEstateTaxesType.Stop &&
      realEstateTaxesCamExpensesTerm.estimatedOpExType === models.OperatingExpensesType.Stop
    ) {
      return baseYearStopInfo;
    }

    if (realEstateTaxesCamExpensesTerm.estimatedReTaxesType === models.RealEstateTaxesType.Stop) {
      baseYearStopInfo = `${baseYearStopInfo} for RE Taxes only`;
    }

    if (realEstateTaxesCamExpensesTerm.estimatedOpExType === models.OperatingExpensesType.Stop) {
      baseYearStopInfo = `${baseYearStopInfo} for OpEx only`;
    }

    return baseYearStopInfo;
  }

  getRentIncreases(lease: models.ILeaseViewModel): string {
    if (lease) {
      const rentalRateAnnualEscalation = lease.rentalRateAnnualEscalationTerm;

      if (rentalRateAnnualEscalation) {
        if (rentalRateAnnualEscalation.escalationTermType === models.EscalationTermType.FixedPercentagePerYear) {
          return `${this._safeNumber.transform(rentalRateAnnualEscalation.escalationPercentagePerYear, this._numberFormat)}% Per Annum`;
        }

        if (rentalRateAnnualEscalation.escalationTermType === models.EscalationTermType.FixedAmountPsfPerYear) {
          return `$${this._safeNumber.transform(rentalRateAnnualEscalation.escalationPsfValuePerYear, this._numberFormat)} PSF Per Annum`;
        }

        if (rentalRateAnnualEscalation.escalationTermType === models.EscalationTermType.Custom) {
          return 'Varied Steps';
        }

        if (rentalRateAnnualEscalation.escalationTermType === models.EscalationTermType.None) {
          return 'No escalations';
        }
      }
    }

    return '';
  }

  rejectLease(rejectionLeaseViewModel: models.IRejectionLeaseViewModel) {
    return this._httpClient.post(`${environment.webApiUrl}/lease/RejectLease`, rejectionLeaseViewModel);
  }

  getTenantSquareFootage(tenantSquareFootageTerm: models.ITenantSquareFootageTermViewModel): string {
    if (!tenantSquareFootageTerm) {
      return '0';
    }

    return this._safeNumber.transform(tenantSquareFootageTerm.tenantSquareFootageValue);
  }

  getDashboardLeases(): Observable<Array<ITenantDashboardViewModel>> {
    return this._httpClient.get<Array<ITenantDashboardViewModel>>(`${environment.webApiUrl}/leases/dashboard`);
  }

  getLeaseSignature(leaseId: number): Observable<models.ILeaseSignViewModel> {
    return this._httpClient.get<models.ILeaseSignViewModel>(`${environment.webApiUrl}/leases/${leaseId}/signature`);
  }

  setLeaseDocumentSigner(leaseId: number, whoSignLeaseDocumentViewModel: models.IWhoSignLeaseSignatureViewModel):
    Observable<models.IWhoSignLeaseSignatureViewModel> {
    return this._httpClient
      .post<models.IWhoSignLeaseSignatureViewModel>(
        `${environment.webApiUrl}/leases/${leaseId}/lease-signer`,
        whoSignLeaseDocumentViewModel
      );
  }
}
