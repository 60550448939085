import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { CommonTools } from '@statera/sdk/common';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { toLocalDate } from '../../../../../infrastructure/models/local-date.model';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-rental-rate-annual-escalation',
  templateUrl: './rental-rate-annual-escalation.component.html',
  styleUrls: ['./rental-rate-annual-escalation.component.scss']
})
export class RentalRateAnnualEscalationComponent extends BaseTermDirective<models.IRentalRateAnnualEscalationTermViewModel>
  implements OnInit, OnChanges, OnDestroy {
  rentalRateEscalationCustomPopupVisible = false;
  customRentalRateEscalations = true;
  isVisibleRadioGroup = true;
  isVisibleEscalationTaxRadioGroup = false;

  TerminationOptionType = models.TerminationOptionType;
  EscalationTermType = models.EscalationTermType;
  RentalRateEscalationTaxType = models.RentalRateEscalationTaxType;
  RentalRateAnnualEscalationTermCustomValueType = models.RentalRateAnnualEscalationTermCustomValueType;
  RentalRateAnnualEscalationTermCustomRepeatType = models.RentalRateAnnualEscalationTermCustomRepeatType;

  startingRate = 0;

  customRentalRateEscalationCustomResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>;

  options: Array<{ name: string, value: models.EscalationTermType, disabled?: boolean }> = [];

  escalationTaxOptions: Array<{ name: string, value: models.RentalRateEscalationTaxType }> = [
    {
      name: 'Escalation on Base Rental Rate',
      value: models.RentalRateEscalationTaxType.NetRentalRate,
    },
    {
      name: 'Escalation on Base Rental Rate, excluding any Base Year Stops',
      value: models.RentalRateEscalationTaxType.BaseRentalRate,
    },
  ];

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  private readonly _projectAccessService: ProjectAccessService;

  private _baseRentalRate: models.IBaseRentalRateViewModel;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initializePage();
    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this.initializePage();
          }),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (!this.termManager.isSameTerms(
          <models.ILeaseTermViewModel>changes?.leaseTerm?.previousValue,
          <models.ILeaseTermViewModel>changes?.leaseTerm?.currentValue)) {
      this.initializePage();
    }
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
        this.lease.term.hasMultiplyOptions
      ) ||
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      )
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease?.term?.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    if (
      this.lease?.term?.hasMultiplyOptions &&
      this.lease?.term?.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.leaseTerm?.escalationTermType === models.EscalationTermType.RequestMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      !this.leaseTerm?.leaseTermOptions?.selectedLeaseTerm &&
      (
        this.leaseTerm.escalationTermType === null ||
        this.leaseTerm.escalationTermType === models.EscalationTermType.MultiplyOptions
      ) &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isCounterButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      ) &&
      this.leaseTerm.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready
    ) {
      return false;
    }

    return super.isCounterButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.lease?.term?.termStatus !== models.TermStatus.Pending &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    const isTenantCounterOfferStep = (
      this.project?.projectState?.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project?.projectState?.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (!leaseTermHistoryRecord?.termHistoryModels?.length) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.lease?.term?.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease,
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        )
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick(): void {
    if (
      this.leaseTerm?.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm?.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    this.handleAcceptButtonClick();
  }

  onSaveTerm($event) {
    this.clearErrors();

    const {escalationTermType} = this.leaseTerm;
    if (escalationTermType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (escalationTermType === models.EscalationTermType.Custom) {
      if (!this.areCustomValuesValid()) {
        return;
      }

      if (this.leaseTerm && this.leaseTerm.rentalRateAnnualEscalationTermCustomValues) {
        this.leaseTerm.rentalRateAnnualEscalationTermCustomValues = this.leaseTerm.rentalRateAnnualEscalationTermCustomValues
          .map(customValue => {
            return <models.IRentalRateAnnualEscalationTermCustomValueViewModel>{
              ...customValue,
              increaseDate: toLocalDate(customValue.increaseDate),
              stepIncreasePercentage: customValue?.stepIncreasePercentage ?? 0,
              stepIncreasePsfValue: customValue?.stepIncreasePsfValue ?? 0,
            };
          });
      }
    }

    super.saveLeaseTerm(this.leaseTerm);

    $event.preventDefault();
  }

  initializePage() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.IRentalRateAnnualEscalationTermViewModel>{};
    }

    this.options = this._getOptions();
    this.rentalRateEscalationCustomPopupVisible = false;
    this.customRentalRateEscalations = true;
    this.isVisibleRadioGroup = true;
    this.isVisibleEscalationTaxRadioGroup = false;

    if (!this.isOption) {
      this.leaseTerm = {...this.leaseTerm};
    }

    if (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp
    ) {
      if (this.lease.term && this.lease.term.termType === models.TermTypeEnum.MultiplyOptions) {
        this.options.forEach(value => {
          value.disabled = true;
        });
      }
    }

    if (this.leaseTerm.escalationTermType === models.EscalationTermType.MultiplyOptions) {
      this.isVisibleRadioGroup = false;
      this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);
    }

    this._baseRentalRate = this.lease.baseRentalRateTerm;

    this.startingRate = 0;

    if (this._baseRentalRate) {
      switch (this._baseRentalRate.baseRentalRateType) {
        case models.BaseRentalRateType.Gross:
          this.startingRate = this._baseRentalRate.baseRentalRateGrossValue ? this._baseRentalRate.baseRentalRateGrossValue : 0;
          break;
        case models.BaseRentalRateType.Net:
          this.startingRate = this._baseRentalRate.baseRentalRateNetValue ? this._baseRentalRate.baseRentalRateNetValue : 0;
          break;
        case models.BaseRentalRateType.BaseYear:
          this.startingRate = this._baseRentalRate.actualYearRate ? this._baseRentalRate.actualYearRate : 0;
          break;
      }

      this._setRentalRateEscalationTaxType(this.leaseTerm.escalationTermType);
    }

    if (this.isOption) {
      const indexOfCustomOption = this.options
        .findIndex(x => x.value === models.EscalationTermType.Custom);

      if (0 <= indexOfCustomOption) {
        this.options[indexOfCustomOption].disabled = true;
      }
    }

    if (this.isTenantCounterUnsolicitedOfferStage() && this.isMultipleOptions()) {
      this.isVisibleRadioGroup = true;
    }
  }

  optionChanged($event): void {
    if ($event.name === 'value') {
      this.isVisibleEscalationTaxRadioGroup = false;

      this._setRentalRateEscalationTaxType($event.value);
      this.leaseTermPropertyChange();
    }
    if ($event.value === models.EscalationTermType.Custom) {
      this.initializePage();
    }
  }

  private _setRentalRateEscalationTaxType(escalationTermType: models.EscalationTermType) {
    if (escalationTermType !== models.EscalationTermType.FixedPercentagePerYear &&
      escalationTermType !== models.EscalationTermType.FixedAmountPsfPerYear) {
      return;
    }

    if (this._baseRentalRate) {
      if (this._baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Gross) {
        this.leaseTerm.rentalRateEscalationTaxType = models.RentalRateEscalationTaxType.GrossRentalRate;
      }

      if (this._baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Net) {
        this.leaseTerm.rentalRateEscalationTaxType = models.RentalRateEscalationTaxType.NetRentalRate;
      }

      if (this._baseRentalRate.baseRentalRateType === models.BaseRentalRateType.BaseYear) {
        this.isVisibleEscalationTaxRadioGroup = true;
      }
    }
  }

  canInsertNewOption(): boolean {
    return this.termManager.canInsertNewOption(this.lease.term, this.project);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getOptions() {
    const isSendRfp = this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.SendRfp, this.project, this.lease);

    return [
      {
        name: 'None',
        value: models.EscalationTermType.None,
      },
      {
        name: 'Fixed Percentage Per Year',
        value: models.EscalationTermType.FixedPercentagePerYear,
      },
      {
        name: 'Fixed PSF Per Year',
        value: models.EscalationTermType.FixedAmountPsfPerYear,
      },
      {
        name: 'Custom',
        value: models.EscalationTermType.Custom,
      },
      {
        name: 'Please provide an aggressive, market sensitive Rental Rate Escalation.',
        value: models.EscalationTermType.RequestMultiplyOptions,
        disabled: !isSendRfp,
      },
    ];
  }

  getBaseRentalRateUnitMetrics(): models.BaseRentalRateUnitMetrics {
    return this.termManager.getBaseRentalRateUnitMetrics(this.lease.baseRentalRateTerm);
  }

  getBaseRentalRateUnitMetricsDisplayValue(): string {
    return this.termManager.getBaseRentalRateUnitMetricsDisplayValue(this.lease.baseRentalRateTerm);
  }

  getBaseRentalRateType(): string {
    if (this._baseRentalRate) {
      switch (this._baseRentalRate.baseRentalRateType) {
        case models.BaseRentalRateType.Gross:
          return 'Gross';
        case models.BaseRentalRateType.Net:
          return 'Net';
        case models.BaseRentalRateType.BaseYear:
          return 'Modified Gross';
      }
    }

    return '';
  }

  areCustomValuesValid(): boolean {
    if (
      !this.leaseTerm ||
      !this.leaseTerm.rentalRateAnnualEscalationTermCustomValues ||
      !this.leaseTerm.rentalRateAnnualEscalationTermCustomValues.length
    ) {
      return false;
    }

    const emptyRows = this.leaseTerm.rentalRateAnnualEscalationTermCustomValues
      .filter(x => !x.leaseMonth || !x.increaseDate || (!x.stepIncreasePsfValue && !x.stepIncreasePercentage));

    return 0 === emptyRows.length;
  }

  getCustomValuesErrorMessages(): Array<string> {
    const errors = [];

    if (!this.areCustomValuesValid()) {
      errors.push('Please check your escalation table, something is wrong');
    }

    return errors;
  }

  customValueChange(value: string) {
    this.leaseTerm.escalationPercentagePerYear = Number(value);
    this.clearErrors();
    this.leaseTermPropertyChange();
  }
}
