import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { TenantRequestRepository } from './tenant-request.repository';
import { TenantRequestManager } from './tenant-request.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    TenantRequestRepository,
    TenantRequestManager,
  ],
})
export class TenantRequestModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TenantRequestModule> {
    return {
      ngModule: TenantRequestModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TenantRequestRepository,
        TenantRequestManager,
      ],
    };
  }
}
