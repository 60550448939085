<app-timeline *ngIf="project && isShowTimeline()" [project]="project"></app-timeline>

<div class="my-card-lease">
  <dx-tab-panel class="colabo-tab-panel"
                *ngIf="isMobile && isDataLoaded"
                [dataSource]="contentTabs" [selectedIndex]="0" [swipeEnabled]="true"
                [focusStateEnabled]="false" [deferRendering]="false"
                #tabPanel>
    <div *dxTemplate="let company of 'templ1'">
      <ng-container *ngTemplateOutlet="tabTemplate1"></ng-container>
    </div>

    <div *dxTemplate="let company of 'templ2'" class="w-col-chat col-chat">
      <ng-container *ngTemplateOutlet="tabTemplate2"></ng-container>
    </div>
  </dx-tab-panel>

  <section *ngIf="!isMobile && isDataLoaded" class="w-columns">
    <section class="w-columns-lease-attribute b-card-lease">
      <ng-container *ngTemplateOutlet="tabTemplate1"></ng-container>
    </section><!--w-columns-lease-attribute-->

    <section class="w-columns-comments" id="colabo-chat">
      <ng-container *ngTemplateOutlet="tabTemplate2"></ng-container>
    </section><!--w-columns-comments-->
  </section>

  <ng-container *ngIf="lease">
    <ng-container
      *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Read; leaseId:lease?.id">
      <ng-container *ngIf="letterOfIntentPopupVisible">
        <app-letter-of-intent [lease]="lease" (signChange)="reInitializePage()" [project]="project"
                              [showSignButton]="showSignLOIButton()"
                              [(popupVisible)]="letterOfIntentPopupVisible">
        </app-letter-of-intent>
      </ng-container>

      <dx-popup height="95%" width="80%"
                [showTitle]="true" [showCloseButton]="true" title="Signing a Letter of Intent" [dragEnabled]="false"
                [(visible)]="signingLeaseAmendmentPopupVisible">
        <app-sign-document *ngIf="signingLeaseAmendmentPopupVisible"
                           [lease]="lease" [project]="project"
                           (previewLOI)="onLOIPreview()"
                           (signChange)="reInitializePage()">
        </app-sign-document>
      </dx-popup>

      <app-lease-documents *ngIf="leaseDocumentsComponentVisible"
                           [lease]="lease" [project]="project"
                           (stageChanged)="reInitializePage()" (leaseDocumentClosed)="onLeaseDocumentClosed($event)"
                           #leaseDocumentsComponent>
      </app-lease-documents>

      <app-lease-execute *ngIf="leaseExecutePopupVisible"
                         [lease]="lease" [project]="project"
                         (leaseExecutionClosed)="onLeaseExecutionClosed($event)" (stageChanged)="reInitializePage()">
      </app-lease-execute>
    </ng-container>

  </ng-container>


  <!-- Templates -->

  <ng-template #tabTemplate1>
    <ng-container *ngIf="lease">
      <ng-container
        *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Read; leaseId:lease?.id">
        <header class="b-card-lease-head">
          <h1 class="b-header">
            <div class="d-flex align-items-center"
                 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <span class="header-text">
                  <ng-container *ngIf="getProjectName() as projectName">{{ projectName }}:</ng-container>
                  {{ getLeaseName(lease) }}
                  <ng-container *ngIf="isLandlord()">
                    ({{ lease.tenantCompany?.name }})
                  </ng-container>
                </span>

              <div>
                <button class="b-btn b-btn-icon b-btn--small btn"
                        #infoTooltipAnchor>
                  <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
                </button>

                <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" position="top" contentTemplate="content"
                            [width]="250"
                            [target]="infoTooltipAnchor">
                  <div class="d-flex flex-column tooltip-menu">
                    This offer is a request only and is not intended to legally bind either party.
                    Only a mutually executed lease document approved by {{ getTenantCompanyName() }}'s
                    and {{ getLandlordCompanyName() }}'s
                    upper management and legal department shall be binding between the parties.
                  </div>
                </dx-tooltip>
              </div>
            </div>

            <button class="controls-back right" (click)="openComments()">
              <span class="icon-forward"></span>
            </button>
            <span class="b-header-controls">
                <button class="b-btn-outline b-btn-outline-primary btn" [routerLink]="'../main/' + lease?.id">
                  <i inlineSVG="assets/img/arrow-line.svg"></i>
                  <span>
                    Back
                  </span>
                </button>

                <ng-container *ngIf="isWaitingForApproval()">
                  <button class="b-btn b-btn-primary btn approval" type="button"
                          disabled>
                    Pending Internal Approval
                  </button>
                </ng-container>
                <ng-container *ngIf="shouldShowSendOfferForApprovalButton()">
                  <ng-container *ngIf="isSendOfferForApproval()">
                    <button class="b-btn b-btn-primary btn approval" type="button"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(sendOfferForApprovalButton, true);"
                            #sendOfferForApprovalButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isUnsolicitedOfferByLandlordBroker()">
                    <button class="b-btn b-btn-primary btn approval" type="button"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(unsolicitedOfferForApprovalByLandlordBrokerButton);"
                            #unsolicitedOfferForApprovalByLandlordBrokerButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleTenantCounterUnsolicitedOffer()">
                    <button class="b-btn b-btn-primary btn approval"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(sendTenantBrokerCounterApprovalUnsolicitedOfferButton);"
                            #sendTenantBrokerCounterApprovalUnsolicitedOfferButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isReviewTenantImprovementsMultiplyOptionsByLandlordBroker()">
                    <button type="button" class="b-btn b-btn-primary btn approval"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(reviewTenantImprovementsMultiplyOptionsByLandlordBrokerButton);"
                            #reviewTenantImprovementsMultiplyOptionsByLandlordBrokerButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isReviewTenantImprovementsMultiplyOptionsByTenantBroker()">
                    <button type="button" class="b-btn b-btn-primary btn approval"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(reviewTenantImprovementsMultiplyOptionsByTenantBrokerButton);"
                            #reviewTenantImprovementsMultiplyOptionsByTenantBrokerButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord()">
                    <button type="button" class="b-btn b-btn-primary btn approval"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(receiveAndAnalyzeResponseByLandlordBrokerButton);"
                            #receiveAndAnalyzeResponseByLandlordBrokerButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleProposalNegotiation()">
                    <button type="button" class="b-btn b-btn-primary btn approval"
                            *ngIf="sendOfferForApprovalButtonVisible"
                            (click)="sendOfferForApproval(visibleProposalNegotiationApprovalButton);"
                            #visibleProposalNegotiationApprovalButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isChooseOptionStageByBroker()">
                    <button type="button" class="b-btn b-btn-primary btn approval"
                            (click)="sendOfferForApproval(chooseOptionStageApprovalButton);"
                            #chooseOptionStageApprovalButton>
                      Send Offer For Approval
                    </button>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="shouldShowSendOfferButton()">

                  <ng-container *ngIf="isSendOffer()">
                    <button class="b-btn b-btn-primary btn" type="button"
                            [class.approval]="project.pendingForInternalApproval"
                            *ngIf="sendOfferButtonVisible"
                            (click)="sendOffer(sendOfferButton);"
                            #sendOfferButton>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="mr-2">
                        <path
                          d="M0.186047 3.51752C-0.066208 3.41599 -0.0632403 3.26492 0.202864 3.17627L9.64314 0.0252467C9.90479 -0.0619239 10.0547 0.0846813 9.98146 0.34124L7.28381 9.7943C7.20962 10.0563 7.04887 10.0682 6.92917 9.82897L4.67569 5.31541L0.186047 3.51752ZM2.60473 3.41846L5.39239 4.53534L6.89603 7.54767L8.64995 1.40116L2.60423 3.41846H2.60473Z"
                          fill="#424B5A"/>
                      </svg>
                      {{ !project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isUnsolicitedOfferByLandlord()">
                    <button class="b-btn b-btn-primary btn" type="button"
                            [class.approval]="project.pendingForInternalApproval"
                            *ngIf="sendOfferButtonVisible"
                            (click)="sendUnsolicitedOffer(unsolicitedOfferByLandlordButton);"
                            #unsolicitedOfferByLandlordButton>
                      {{ !project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isUnsolicitedOfferByTenant()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="acceptUnsolicitedOfferByTenant(unsolicitedOfferByTenantAcceptButton);"
                            #unsolicitedOfferByTenantAcceptButton>
                      Proceed
                    </button>
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="rejectUnsolicitedOfferByTenant(unsolicitedOfferByTenantRejectButton);"
                            #unsolicitedOfferByTenantRejectButton>
                      Reject
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleTenantCounterUnsolicitedOffer()">
                    <button class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            (click)="sendTenantCounterUnsolicitedOffer(sendTenantCounterUnsolicitedOfferButton);"
                            #sendTenantCounterUnsolicitedOfferButton>
                       {{ !project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isReviewTenantImprovementsMultiplyOptionsByLandlord()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            *ngIf="reviewTenantImprovementsMultiplyOptionsByLandlordButtonVisible"
                            (click)="reviewTenantImprovementsMultiplyOptionsByLandlord(reviewTenantImprovementsMultiplyOptionsByLandlordButton);"
                            #reviewTenantImprovementsMultiplyOptionsByLandlordButton>
                        {{ !project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isReviewTenantImprovementsMultiplyOptionsByTenant()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            *ngIf="reviewTenantImprovementsMultiplyOptionsByTenantButtonVisible"
                            (click)="reviewTenantImprovementsMultiplyOptionsByTenant(reviewTenantImprovementsMultiplyOptionsByTenantButton);"
                            #reviewTenantImprovementsMultiplyOptionsByTenantButton>
                        {{ !project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isChooseOptionStageByTenant()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            (click)="chooseOption(chooseOptionButton)"
                            #chooseOptionButton>
                      {{ !project.pendingForInternalApproval ? 'Continue' : 'Approve & Continue' }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            (click)="receiveAndAnalyzeResponseByLandlord(receiveAndAnalyzeResponseByLandlordButton);"
                            #receiveAndAnalyzeResponseByLandlordButton>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                        <path d="M3.18605 6.51752C2.93379 6.41599 2.93676 6.26492 3.20286 6.17627L12.6431 3.02525C12.9048 2.93808 13.0547 3.08468 12.9815 3.34124L10.2838 12.7943C10.2096 13.0563 10.0489 13.0682 9.92917 12.829L7.67569 8.31541L3.18605 6.51752ZM5.60473 6.41846L8.39239 7.53534L9.89603 10.5477L11.6499 4.40116L5.60423 6.41846H5.60473Z" fill="#424B5A"/>
                      </svg>

                      {{ getReceiveAndAnalyzeResponseButtonText() }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleProposalNegotiation()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            [class.approval]="project.pendingForInternalApproval"
                            (click)="counterOffer(counterOfferButton);"
                            #counterOfferButton>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.186047 3.51752C-0.066208 3.41599 -0.0632403 3.26492 0.202864 3.17627L9.64314 0.0252467C9.90479 -0.0619239 10.0547 0.0846813 9.98146 0.34124L7.28381 9.7943C7.20962 10.0563 7.04887 10.0682 6.92917 9.82897L4.67569 5.31541L0.186047 3.51752ZM2.60473 3.41846L5.39239 4.53534L6.89603 7.54767L8.64995 1.40116L2.60423 3.41846H2.60473Z"
                          fill="#424B5A"/>
                      </svg>
                      <span class="ml-2">
                        {{ getCounterOfferButtonText() }}
                      </span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isVisibleFinalizeButton()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="showFinalizeBusinessTerms();">



                      <i inlineSVG="assets/img/finalize-doc.svg"></i>

                      <span>
                          {{ getFinalizeTermsButtonText() }}
                      </span>

                    </button>
                  </ng-container>

                  <ng-container *ngIf="IsLeaseExecutionAvailable()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="showLeaseExecution();">
                      Lease Execution
                    </button>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="IsVisibleTourButton()">
                  <button type="button" id="tourButton" class="b-btn btn"
                          [disabled]="IsDisabledTourButton()"
                          (mouseenter)="onTourButtonMouseEnter()"
                          (mouseleave)="onTourButtonMouseLeave()"
                          (click)="tourButtonClick();"
                          #tourButtonElement>
                    <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.745 0.113186L0.1215 2.88519C0.0844394 2.90739 0.0537666 2.93883 0.0324727 2.97642C0.0111789 3.01401 -9.04248e-06 3.05648 5.48362e-09 3.09969V9.45819C5.48362e-09 9.5908 0.0526784 9.71797 0.146447 9.81174C0.240215 9.90551 0.367392 9.95819 0.5 9.95819H9.5C9.63261 9.95819 9.75979 9.90551 9.85355 9.81174C9.94732 9.71797 10 9.5908 10 9.45819V3.10019C10 3.05698 9.98882 3.01451 9.96753 2.97692C9.94623 2.93933 9.91556 2.90789 9.8785 2.88569L5.2595 0.113186C5.18179 0.0665605 5.09287 0.0419312 5.00225 0.0419312C4.91163 0.0419312 4.82271 0.0665605 4.745 0.113186ZM1 8.95819V3.52469V3.52419L5.002 1.12419L9 3.52419V8.95819H1ZM7.61068 5.60578L7.61989 5.5556L7.61067 5.50541C7.38358 4.2698 6.30144 3.3334 5.00003 3.3334C3.69866 3.3334 2.61625 4.26977 2.38938 5.50546L2.38017 5.55565L2.38939 5.60584C2.61648 6.84144 3.69862 7.77785 5.00003 7.77785C6.3014 7.77785 7.38382 6.84148 7.61068 5.60578ZM6.03948 6.4263C5.74422 6.66179 5.37776 6.79008 5.00009 6.79019C4.62242 6.79008 4.25585 6.66179 3.96058 6.4263C3.68195 6.20409 3.48228 5.89884 3.38998 5.55576C3.48256 5.21298 3.68234 4.90809 3.96093 4.68617C4.2562 4.45097 4.62253 4.3229 5.00003 4.3229C5.37753 4.3229 5.74386 4.45097 6.03913 4.68617C6.31772 4.90809 6.5175 5.21298 6.61008 5.55576C6.51778 5.89884 6.31811 6.20409 6.03948 6.4263ZM5.61306 5.55566C5.61306 5.89426 5.33857 6.16875 4.99997 6.16875C4.66136 6.16875 4.38687 5.89426 4.38687 5.55566C4.38687 5.21705 4.66136 4.94256 4.99997 4.94256C5.33857 4.94256 5.61306 5.21705 5.61306 5.55566Z"
                                fill="currentColor"/>
                    </svg>
                    <span class="ml-2">
                      {{ getTourButtonCaption() }}
                    </span>

                  <dx-tooltip position="top"
                              [width]="200" [(visible)]="tourComingSoonTooltipVisible" [target]="tourButtonElement"
                              *ngIf="IsDisabledTourButton()">
                    <p>Coming Soon</p>
                  </dx-tooltip>
                  </button>
                </ng-container>

                <ng-container *ngIf="IsVisibleShowLeaseDocumentsButton()">
                  <button type="button" class="b-btn btn"
                          (click)="showLeaseDocuments();">
                    <i inlineSVG="assets/img/lease-document.svg"></i>
                    <span>Lease Amendment</span>
                  </button>
                </ng-container>

                <ng-container *appIsFeatureEnabled="Feature.FloorPlanFeature">
                  <ng-container
                    *appCheckAccess="StateraClaimType.FloorPlan; stateraClaimValue: StateraClaimValue.Read; leaseId:lease.id">
                  <ng-container *ngIf="floorPlans && floorPlans.length > 0">
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="showFloorPlan();">

                       <i inlineSVG="assets/img/floor-plan.svg"></i>
                      Floor Plan
                    </button>
                  </ng-container>
                </ng-container>
                </ng-container>

                <ng-container
                  *appCheckAccess="StateraClaimType.CertificateOfInsurance; stateraClaimValue: StateraClaimValue.Read; leaseId:lease.id">
                  <div *ngIf="isShowCertificateOfInsuranceButton()">
                    <button type="button" id="tooltipCOI" class="b-btn b-btn-primary btn"
                            (click)="onShowCertificateOfInsuranceClick();"
                            #coiButtonElement>

                       <i inlineSVG="assets/img/requestCOI.svg"></i>

                      Request COI
                    </button>

                    <dx-tooltip showEvent="dxhoverstart" contentTemplate="content"
                                [width]="200" [(visible)]="tooltipCOIVisible" [target]="coiButtonElement">
                      <p>You may request a Certificate of Insurance from the Tenant</p>
                      <button class="b-btn mb-1 b-btn--small b-btn-primary btn"
                              (click)="tooltipCOIVisible = false">
                        Got it!
                      </button>
                    </dx-tooltip>
                  </div>

                  <ng-container *ngIf="shouldShowReviewCOIForm()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            #reviewCOI
                            (click)="showCertificateOfInsurance();">
                      Review COI
                      <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content"
                                  [target]="reviewCOI">
                        <p>Certificate of Insurance</p>
                      </dx-tooltip>
                    </button>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="showViewLOI()">
                    <button type="button" class="b-btn b-btn-primary btn"
                            (click)="showLOI()">

                      <i inlineSVG="assets/img/view-loi-eye.svg"></i>

                      View LOI
                    </button>
                </ng-container>

                <button type="button" class="b-btn b-btn-outline-primary btn"
                        #addTerms
                        *ngIf="canShowElectTermsForNegotiationDialog()"
                        (click)="showElectTermsForNegotiationDialog()">
                  <i inlineSVG="assets/img/plus-icon.svg"></i>
                  <span>Add terms</span>
                  <dx-tooltip position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
                              contentTemplate="content"
                              [width]="300" [target]="addTerms">
                    <p>Add additional terms to the negotiation</p>
                  </dx-tooltip>
                </button>

                <ng-container
                  *appCheckAccess="StateraClaimType.Financial_Requests; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id">
                  <button type="button" class="b-btn b-btn-outline-primary btn"
                          #uploadFinancials
                          *ngIf="showUploadFinancialDocumentsButton()"
                          (click)="uploadFinancialDocumentsButtonClick()">

                     <i inlineSVG="assets/img/uploadFinancials.svg"></i>

                    <span>
                      Upload Financials
                    </span>

                    <dx-tooltip position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
                                contentTemplate="content"
                                [width]="300" [target]="uploadFinancials">
                     <p>Financial documents will need to be provided and approved before going to Lease.</p>
                    </dx-tooltip>
                  </button>

                  <button type="button" class="b-btn b-btn-outline-primary btn"
                          #requestFinancials
                          *ngIf="showRequestFinancialDocumentsButton()"
                          (click)="requestFinancialDocuments()">

                     <i inlineSVG="assets/img/requestCOI.svg"></i>


                    Request Financials
                    <dx-tooltip position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
                                contentTemplate="content"
                                [width]="300" [target]="requestFinancials">
                      <p>Financial documents will need to be provided and approved before going to Lease.</p>
                    </dx-tooltip>
                  </button>
                </ng-container>

                <div>
                  <button type="button" class="b-btn b-btn-outline-primary btn btn-svg"
                          #downloadTerms
                          (click)="generateTermsOfSummary();" inlineSVG="assets/img/load.svg">
                  </button>
                  <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content"
                              [target]="downloadTerms">
                    <p>Download Summary of Terms</p>
                  </dx-tooltip>
                </div>

                <button class="b-btn b-btn-outline-primary btn btn-svg"
                        *ngIf="showLeaseSettingsButton()"
                        #leaseSettingsTemplate placement="bottom"
                        (click)="openLeaseSettings()">
                  <i class="m-0" inlineSVG="assets/v2/icons/settings.svg"></i>
                  <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content"
                              [target]="leaseSettingsTemplate">
                    <p>Lease Settings</p>
                  </dx-tooltip>
                </button>

              </span><!--b-header-controls-->
          </h1><!--b-header-->
        </header><!--b-card-lease-head-->

        <app-terms-accordion-header [projects]="projects" [lease]="lease">
        </app-terms-accordion-header>

        <article class="b-card-lease-body"
                 #leaseTermsElement>
          <dx-accordion class="b-lease-terms"
                        [dataSource]="termConfigurations" [disabled]="disabledAccordion"
                        [collapsible]="true" [multiple]="false" [(selectedIndex)]="accordionIndex"
                        [animationDuration]="isDemo ? 0 : accordionAnimationDurationMs"
                        (onSelectionChanged)="accordionOptionChanged($event)"
                        #accordion>
            <div *dxTemplate="let termConfiguration of 'title'">
              <app-term-header [lease]="lease"
                               [termConfiguration]="termConfiguration"
                               [leaseHistory]="leaseHistoryRecord"
                               (initialized)="onItemHeaderInitialized($event)">
              </app-term-header>
            </div><!--loop-->

            <div *dxTemplate="let termConfiguration of 'item'">
              <app-term-value-display *ngIf="lease"
                                      [termConfiguration]="termConfiguration" [lease]="lease" [project]="project"
                                      [showTitle]="showTitle"
                                      #termValueDisplayComponent>
              </app-term-value-display>

              <ng-container *ngIf="leaseHistoryRecord && !isDemo && lease">
                <app-term-history [project]="project" [demoRentalRateTerm]="false"
                                  [lease]="lease" [leaseTermConfiguration]="termConfiguration"
                                  [leaseHistory]="leaseHistoryRecord"
                                  [popoverContainer]="leaseTermsElement" [hideTitle]="true"
                                  (isRecord)="showTitle = $event">
                </app-term-history>
              </ng-container>

              <!--        For initial demo-->
              <app-term-history *ngIf="isDemo"
                                [demoRentalRateTerm]="demoRentalRateTerm" [hideTitle]="true"
                                (isRecord)="showTitle = $event">
              </app-term-history>

              <form class="w-form terms-form"
                    *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Write; leaseId: lease.id">
                <ng-container [ngSwitch]="termConfiguration.termName">
                  <!-- FIXME: Move term accordion items to a complete separate components and get rid of
                              [isRejectFormVisible], [isRejectFormVisibleChange], [rejectButtonClicked],
                              [acceptButtonClicked], (rejectClicked) and (acceptClicked). -->
                  <app-assignment *ngSwitchCase="'Assignment'"
                                  [startupInfo]="startupInfo"
                                  [project]="project"
                                  [lease]="lease"
                                  [leaseTerm]="lease.assignmentTerm"
                                  [leaseTermConfiguration]="termConfiguration"
                                  [leaseHistoryRecord]="leaseHistoryRecord"
                                  [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                  [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                  [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                  (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                  (rejectClicked)="onRejectClicked(termConfiguration)"
                                  (acceptClicked)="onAcceptClicked(termConfiguration)"
                                  (stageChanged)="onStageChanged($event)"
                                  (leaseTermSaved)="onLeaseTermSaved($event)"
                                  (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                  (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-assignment>

                  <app-base-rental-rate *ngSwitchCase="'RentalRate'"
                                        [startupInfo]="startupInfo"
                                        [project]="project"
                                        [lease]="lease"
                                        [leaseTerm]="lease.baseRentalRateTerm"
                                        [leaseTermConfiguration]="termConfiguration"
                                        [leaseHistoryRecord]="leaseHistoryRecord"
                                        [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                        [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                        [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                        (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                        (rejectClicked)="onRejectClicked(termConfiguration)"
                                        (acceptClicked)="onAcceptClicked(termConfiguration)"
                                        (stageChanged)="onStageChanged($event)"
                                        (leaseTermSaved)="onLeaseTermSaved($event)"
                                        (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                        (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-base-rental-rate>

                  <app-commencement-term *ngSwitchCase="'Commencement'"
                                         [startupInfo]="startupInfo"
                                         [project]="project"
                                         [lease]="lease"
                                         [leaseTerm]="lease.commencementTerm"
                                         [leaseTermConfiguration]="termConfiguration"
                                         [leaseHistoryRecord]="leaseHistoryRecord"
                                         [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                         [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                         [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                         (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                         (rejectClicked)="onRejectClicked(termConfiguration)"
                                         (acceptClicked)="onAcceptClicked(termConfiguration)"
                                         (stageChanged)="onStageChanged($event)"
                                         (leaseTermSaved)="onLeaseTermSaved($event)"
                                         (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                         (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-commencement-term>

                  <app-estoppel-certificate *ngSwitchCase="'EstoppelCertificate'"
                                            [startupInfo]="startupInfo"
                                            [project]="project"
                                            [lease]="lease"
                                            [leaseTerm]="lease.estoppelCertificateTerm"
                                            [leaseTermConfiguration]="termConfiguration"
                                            [leaseHistoryRecord]="leaseHistoryRecord"
                                            [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                            [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                            [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                            (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                            (rejectClicked)="onRejectClicked(termConfiguration)"
                                            (acceptClicked)="onAcceptClicked(termConfiguration)"
                                            (stageChanged)="onStageChanged($event)"
                                            (leaseTermSaved)="onLeaseTermSaved($event)"
                                            (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                            (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-estoppel-certificate>

                  <app-expansion-option *ngSwitchCase="'ExpansionOption'"
                                        [startupInfo]="startupInfo"
                                        [project]="project"
                                        [lease]="lease"
                                        [leaseTerm]="lease.expansionOptionTerm"
                                        [leaseTermConfiguration]="termConfiguration"
                                        [leaseHistoryRecord]="leaseHistoryRecord"
                                        [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                        [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                        [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                        (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                        (rejectClicked)="onRejectClicked(termConfiguration)"
                                        (acceptClicked)="onAcceptClicked(termConfiguration)"
                                        (stageChanged)="onStageChanged($event)"
                                        (leaseTermSaved)="onLeaseTermSaved($event)"
                                        (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                        (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-expansion-option>

                  <app-free-rent *ngSwitchCase="'FreeRent'"
                                 [startupInfo]="startupInfo"
                                 [project]="project"
                                 [lease]="lease"
                                 [leaseTerm]="lease.freeRentTerm"
                                 [leaseTermConfiguration]="termConfiguration"
                                 [leaseHistoryRecord]="leaseHistoryRecord"
                                 [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                 [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                 [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                 (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                 (rejectClicked)="onRejectClicked(termConfiguration)"
                                 (acceptClicked)="onAcceptClicked(termConfiguration)"
                                 (stageChanged)="onStageChanged($event)"
                                 (leaseTermSaved)="onLeaseTermSaved($event)"
                                 (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                 (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-free-rent>

                  <app-holdover-provision *ngSwitchCase="'HoldoverProvision'"
                                          [startupInfo]="startupInfo"
                                          [project]="project"
                                          [lease]="lease"
                                          [leaseTerm]="lease.holdoverProvisionTerm"
                                          [leaseTermConfiguration]="termConfiguration"
                                          [leaseHistoryRecord]="leaseHistoryRecord"
                                          [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                          [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                          [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                          (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                          (rejectClicked)="onRejectClicked(termConfiguration)"
                                          (acceptClicked)="onAcceptClicked(termConfiguration)"
                                          (stageChanged)="onStageChanged($event)"
                                          (leaseTermSaved)="onLeaseTermSaved($event)"
                                          (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                          (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-holdover-provision>

                  <app-hvac *ngSwitchCase="'Hvac'"
                            [startupInfo]="startupInfo"
                            [project]="project"
                            [lease]="lease"
                            [leaseTerm]="lease.hvacTerm"
                            [leaseTermConfiguration]="termConfiguration"
                            [leaseHistoryRecord]="leaseHistoryRecord"
                            [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                            [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                            [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                            (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                            (rejectClicked)="onRejectClicked(termConfiguration)"
                            (acceptClicked)="onAcceptClicked(termConfiguration)"
                            (stageChanged)="onStageChanged($event)"
                            (leaseTermSaved)="onLeaseTermSaved($event)"
                            (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                            (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-hvac>

                  <app-insurance *ngSwitchCase="'Insurance'"
                                 [startupInfo]="startupInfo"
                                 [project]="project"
                                 [lease]="lease"
                                 [leaseTerm]="lease.insuranceTerm"
                                 [leaseTermConfiguration]="termConfiguration"
                                 [leaseHistoryRecord]="leaseHistoryRecord"
                                 [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                 [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                 [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                 (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                 (rejectClicked)="onRejectClicked(termConfiguration)"
                                 (acceptClicked)="onAcceptClicked(termConfiguration)"
                                 (stageChanged)="onStageChanged($event)"
                                 (leaseTermSaved)="onLeaseTermSaved($event)"
                                 (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                 (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-insurance>

                  <app-landlord-maintenance *ngSwitchCase="'LandlordMaintenance'"
                                            [startupInfo]="startupInfo"
                                            [project]="project"
                                            [lease]="lease"
                                            [leaseTerm]="lease.landlordMaintenanceTerm"
                                            [leaseTermConfiguration]="termConfiguration"
                                            [leaseHistoryRecord]="leaseHistoryRecord"
                                            [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                            [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                            [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                            (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                            (rejectClicked)="onRejectClicked(termConfiguration)"
                                            (acceptClicked)="onAcceptClicked(termConfiguration)"
                                            (stageChanged)="onStageChanged($event)"
                                            (leaseTermSaved)="onLeaseTermSaved($event)"
                                            (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                            (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-landlord-maintenance>

                  <app-real-estate-taxes-cam-expenses *ngSwitchCase="'RealEstateTaxesCamExpenses'"
                                                      [startupInfo]="startupInfo"
                                                      [project]="project"
                                                      [lease]="lease"
                                                      [leaseTerm]="lease.realEstateTaxesCamExpensesTerm"
                                                      [leaseTermConfiguration]="termConfiguration"
                                                      [leaseHistoryRecord]="leaseHistoryRecord"
                                                      [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                                      [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                                      [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                                      [acceptButtonClicked]="acceptButtonClicked[termConfiguration.termName]"
                                                      (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                                      (rejectClicked)="onRejectClicked(termConfiguration)"
                                                      (acceptClicked)="onAcceptClicked(termConfiguration)"
                                                      (stageChanged)="onStageChanged($event)"
                                                      (leaseTermSaved)="onLeaseTermSaved($event)"
                                                      (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                                      (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-real-estate-taxes-cam-expenses>

                  <app-renewal-option *ngSwitchCase="'RenewalOption'"
                                      [startupInfo]="startupInfo"
                                      [project]="project"
                                      [lease]="lease"
                                      [leaseTerm]="lease.renewalOptionTerm"
                                      [leaseTermConfiguration]="termConfiguration"
                                      [leaseHistoryRecord]="leaseHistoryRecord"
                                      [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                      [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                      [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                      (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                      (rejectClicked)="onRejectClicked(termConfiguration)"
                                      (acceptClicked)="onAcceptClicked(termConfiguration)"
                                      (stageChanged)="onStageChanged($event)"
                                      (leaseTermSaved)="onLeaseTermSaved($event)"
                                      (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                      (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-renewal-option>

                  <app-rental-rate-annual-escalation *ngSwitchCase="'RentalRateAnnualEscalation'"
                                                     [startupInfo]="startupInfo"
                                                     [lease]="lease"
                                                     [project]="project"
                                                     [leaseTerm]="lease.rentalRateAnnualEscalationTerm"
                                                     [leaseTermConfiguration]="termConfiguration"
                                                     [leaseHistoryRecord]="leaseHistoryRecord"
                                                     [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                                     [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                                     [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                                     (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                                     (rejectClicked)="onRejectClicked(termConfiguration)"
                                                     (acceptClicked)="onAcceptClicked(termConfiguration)"
                                                     (stageChanged)="onStageChanged($event)"
                                                     (leaseTermSaved)="onLeaseTermSaved($event)"
                                                     (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                                     (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-rental-rate-annual-escalation>

                  <app-security-deposit *ngSwitchCase="'SecurityDeposit'"
                                        [startupInfo]="startupInfo"
                                        [project]="project"
                                        [lease]="lease"
                                        [leaseTerm]="lease.securityDepositTerm"
                                        [leaseTermConfiguration]="termConfiguration"
                                        [leaseHistoryRecord]="leaseHistoryRecord"
                                        [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                        [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                        [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                        [financialRequests]="financialRequests"
                                        (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                        (rejectClicked)="onRejectClicked(termConfiguration)"
                                        (acceptClicked)="onAcceptClicked(termConfiguration)"
                                        [errorOccured$]="errorOccured$[termConfiguration.termName]"
                                        (stageChanged)="onStageChanged($event)"
                                        (leaseTermSaved)="onLeaseTermSaved($event)"
                                        (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                        (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-security-deposit>

                  <app-self-help *ngSwitchCase="'SelfHelp'"
                                 [startupInfo]="startupInfo"
                                 [project]="project"
                                 [lease]="lease"
                                 [leaseTerm]="lease.selfHelpTerm"
                                 [leaseTermConfiguration]="termConfiguration"
                                 [leaseHistoryRecord]="leaseHistoryRecord"
                                 [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                 [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                 [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                 (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                 (rejectClicked)="onRejectClicked(termConfiguration)"
                                 (acceptClicked)="onAcceptClicked(termConfiguration)"
                                 (stageChanged)="onStageChanged($event)"
                                 (leaseTermSaved)="onLeaseTermSaved($event)"
                                 (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                 (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-self-help>

                  <app-space-use *ngSwitchCase="'SpaceUse'"
                                 [startupInfo]="startupInfo"
                                 [project]="project"
                                 [lease]="lease"
                                 [leaseTerm]="lease.spaceUseTerm"
                                 [leaseTermConfiguration]="termConfiguration"
                                 [leaseHistoryRecord]="leaseHistoryRecord"
                                 [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                 [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                 [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                 (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                 (rejectClicked)="onRejectClicked(termConfiguration)"
                                 (acceptClicked)="onAcceptClicked(termConfiguration)"
                                 (stageChanged)="onStageChanged($event)"
                                 (leaseTermSaved)="onLeaseTermSaved($event)"
                                 (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                 (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-space-use>

                  <app-tenant-improvements *ngSwitchCase="'TenantImprovements'"
                                           [project]="project"
                                           [lease]="lease"
                                           [leaseTerm]="lease.tenantImprovementsTerm"
                                           [leaseTermConfiguration]="termConfiguration"
                                           [leaseHistoryRecord]="leaseHistoryRecord"
                                           [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                           [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                           [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                           [floorPlans]="floorPlans"
                                           (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                           (rejectClicked)="onRejectClicked(termConfiguration)"
                                           (acceptClicked)="onAcceptClicked(termConfiguration)"
                                           (stageChanged)="onStageChanged($event)"
                                           (leaseTermSaved)="onLeaseTermSaved($event)"
                                           (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                           (setPreviousValue)="onSetPreviousLeaseTermValue($event)"
                                           (refreshColabo)="refreshColabo(TenantImprovements)">
                  >
                  </app-tenant-improvements>

                  <app-tenant-maintenance *ngSwitchCase="'TenantMaintenance'"
                                          [startupInfo]="startupInfo"
                                          [project]="project"
                                          [lease]="lease"
                                          [leaseTerm]="lease.tenantMaintenanceTerm"
                                          [leaseTermConfiguration]="termConfiguration"
                                          [leaseHistoryRecord]="leaseHistoryRecord"
                                          [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                          [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                          [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                          (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                          (rejectClicked)="onRejectClicked(termConfiguration)"
                                          (acceptClicked)="onAcceptClicked(termConfiguration)"
                                          (stageChanged)="onStageChanged($event)"
                                          (leaseTermSaved)="onLeaseTermSaved($event)"
                                          (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                          (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-tenant-maintenance>

                  <app-tenant-square-footage *ngSwitchCase="'TenantSquareFootage'"
                                             [startupInfo]="startupInfo"
                                             [project]="project"
                                             [lease]="lease"
                                             [leaseTerm]="lease.tenantSquareFootageTerm"
                                             [leaseTermConfiguration]="termConfiguration"
                                             [leaseHistoryRecord]="leaseHistoryRecord"
                                             [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                             [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                             [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                             (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                             (rejectClicked)="onRejectClicked(termConfiguration)"
                                             (acceptClicked)="onAcceptClicked(termConfiguration)"
                                             [errorOccured$]="errorOccured$[termConfiguration.termName]"
                                             (stageChanged)="onStageChanged($event)"
                                             (leaseTermSaved)="onLeaseTermSaved($event)"
                                             (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                             (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-tenant-square-footage>

                  <app-term *ngSwitchCase="'Term'"
                            [startupInfo]="startupInfo"
                            [project]="project"
                            [lease]="lease"
                            [leaseTerm]="lease.term"
                            [leaseTermConfiguration]="termConfiguration"
                            [leaseHistoryRecord]="leaseHistoryRecord"
                            [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                            [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                            [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                            (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                            (rejectClicked)="onRejectClicked(termConfiguration)"
                            (acceptClicked)="onAcceptClicked(termConfiguration)"
                            (stageChanged)="onStageChanged($event)"
                            (leaseTermSaved)="onLeaseTermSaved($event)"
                            (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                            (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-term>
                  <app-termination-option *ngSwitchCase="'TerminationOption'"
                                          [startupInfo]="startupInfo"
                                          [project]="project"
                                          [lease]="lease"
                                          [leaseTerm]="lease.terminationOptionTerm"
                                          [leaseTermConfiguration]="termConfiguration"
                                          [leaseHistoryRecord]="leaseHistoryRecord"
                                          [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                          [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                          [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                          (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                          (rejectClicked)="onRejectClicked(termConfiguration)"
                                          (acceptClicked)="onAcceptClicked(termConfiguration)"
                                          (stageChanged)="onStageChanged($event)"
                                          (leaseTermSaved)="onLeaseTermSaved($event)"
                                          (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                          (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-termination-option>

                  <app-condition-of-premises *ngSwitchCase="'ConditionOfPremises'"
                                             [startupInfo]="startupInfo"
                                             [project]="project"
                                             [lease]="lease"
                                             [leaseTerm]="lease.conditionOfPremisesTerm"
                                             [leaseTermConfiguration]="termConfiguration"
                                             [leaseHistoryRecord]="leaseHistoryRecord"
                                             [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                             [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                             [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                             (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                             (rejectClicked)="onRejectClicked(termConfiguration)"
                                             (acceptClicked)="onAcceptClicked(termConfiguration)"
                                             (stageChanged)="onStageChanged($event)"
                                             (leaseTermSaved)="onLeaseTermSaved($event)"
                                             (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                             (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-condition-of-premises>

                  <app-code-compliance *ngSwitchCase="'CodeCompliance'"
                                       [startupInfo]="startupInfo"
                                       [project]="project"
                                       [lease]="lease"
                                       [leaseTerm]="lease.codeComplianceTerm"
                                       [leaseTermConfiguration]="termConfiguration"
                                       [leaseHistoryRecord]="leaseHistoryRecord"
                                       [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                       [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                       [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                       (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                       (rejectClicked)="onRejectClicked(termConfiguration)"
                                       (acceptClicked)="onAcceptClicked(termConfiguration)"
                                       (stageChanged)="onStageChanged($event)"
                                       (leaseTermSaved)="onLeaseTermSaved($event)"
                                       (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                       (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-code-compliance>

                  <app-utilities *ngSwitchCase="'Utilities'"
                                 [startupInfo]="startupInfo"
                                 [project]="project"
                                 [lease]="lease"
                                 [leaseTerm]="lease.utilitiesTerm"
                                 [leaseTermConfiguration]="termConfiguration"
                                 [leaseHistoryRecord]="leaseHistoryRecord"
                                 [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                 [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                 [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                 (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                 (rejectClicked)="onRejectClicked(termConfiguration)"
                                 (acceptClicked)="onAcceptClicked(termConfiguration)"
                                 (stageChanged)="onStageChanged($event)"
                                 (leaseTermSaved)="onLeaseTermSaved($event)"
                                 (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                 (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-utilities>

                  <app-parking *ngSwitchCase="'Parking'"
                               [startupInfo]="startupInfo"
                               [project]="project"
                               [lease]="lease"
                               [leaseTerm]="lease.parkingTerm"
                               [leaseTermConfiguration]="termConfiguration"
                               [leaseHistoryRecord]="leaseHistoryRecord"
                               [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                               [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                               [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                               (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                               (rejectClicked)="onRejectClicked(termConfiguration)"
                               (acceptClicked)="onAcceptClicked(termConfiguration)"
                               (stageChanged)="onStageChanged($event)"
                               (leaseTermSaved)="onLeaseTermSaved($event)"
                               (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                               (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-parking>

                  <app-signage *ngSwitchCase="'Signage'"
                               [startupInfo]="startupInfo"
                               [project]="project"
                               [lease]="lease"
                               [leaseTerm]="lease.signageTerm"
                               [leaseTermConfiguration]="termConfiguration"
                               [leaseHistoryRecord]="leaseHistoryRecord"
                               [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                               [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                               [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                               (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                               (rejectClicked)="onRejectClicked(termConfiguration)"
                               (acceptClicked)="onAcceptClicked(termConfiguration)"
                               (stageChanged)="onStageChanged($event)"
                               (leaseTermSaved)="onLeaseTermSaved($event)"
                               (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                               (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-signage>

                  <app-hazardous-materials *ngSwitchCase="'HazardousMaterials'"
                                           [startupInfo]="startupInfo"
                                           [project]="project"
                                           [lease]="lease"
                                           [leaseTerm]="lease.hazardousMaterialsTerm"
                                           [leaseTermConfiguration]="termConfiguration"
                                           [leaseHistoryRecord]="leaseHistoryRecord"
                                           [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                           [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                           [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                           (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                           (rejectClicked)="onRejectClicked(termConfiguration)"
                                           (acceptClicked)="onAcceptClicked(termConfiguration)"
                                           (stageChanged)="onStageChanged($event)"
                                           (leaseTermSaved)="onLeaseTermSaved($event)"
                                           (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                           (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-hazardous-materials>

                  <app-non-disturbance *ngSwitchCase="'NonDisturbance'"
                                       [startupInfo]="startupInfo"
                                       [project]="project"
                                       [lease]="lease"
                                       [leaseTerm]="lease.nonDisturbanceTerm"
                                       [leaseTermConfiguration]="termConfiguration"
                                       [leaseHistoryRecord]="leaseHistoryRecord"
                                       [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                                       [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                                       [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                                       (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                                       (rejectClicked)="onRejectClicked(termConfiguration)"
                                       (acceptClicked)="onAcceptClicked(termConfiguration)"
                                       (stageChanged)="onStageChanged($event)"
                                       (leaseTermSaved)="onLeaseTermSaved($event)"
                                       (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                                       (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-non-disturbance>

                  <app-rail *ngSwitchCase="'Rail'"
                            [startupInfo]="startupInfo"
                            [project]="project"
                            [lease]="lease"
                            [leaseTerm]="lease.railTerm"
                            [leaseTermConfiguration]="termConfiguration"
                            [leaseHistoryRecord]="leaseHistoryRecord"
                            [accordionOpened$]="accordionOpened$[termConfiguration.termName]"
                            [isRejectFormVisible]="rejectFormVisibilityMap[termConfiguration.termName]"
                            [rejectButtonClicked]="rejectButtonClicked[termConfiguration.termName]"
                            (isRejectFormVisibleChange)="isRejectFormVisibleChangeHandler($event, termConfiguration)"
                            (rejectClicked)="onRejectClicked(termConfiguration)"
                            (acceptClicked)="onAcceptClicked(termConfiguration)"
                            (stageChanged)="onStageChanged($event)"
                            (leaseTermSaved)="onLeaseTermSaved($event)"
                            (leaseTermCanceled)="onTermChangeCancelled(termConfiguration)"
                            (setPreviousValue)="onSetPreviousLeaseTermValue($event)">
                  </app-rail>
                </ng-container>
              </form><!--w-form-->
            </div><!--loop-->
          </dx-accordion><!--b-lease-terms-->
        </article><!--b-card-lease-body-->
      </ng-container>
    </ng-container>


  </ng-template><!--tabTemplate1-->

  <ng-template #tabTemplate2>
    <ng-container *ngIf="lease">
      <ng-container
        *appCheckAccess="StateraClaimType.Collabo_Comments; stateraClaimValue: StateraClaimValue.Read; leaseId:lease.id">
        <app-term-comments class="term-comments"
                           [lease]="lease" [leaseTermConfiguration]="currentLeaseTermConfiguration"
                           (closeComments)="closeComments();">
        </app-term-comments>
      </ng-container>
    </ng-container>
  </ng-template><!--tabTemplate2-->
</div>

<app-colabo-demo-terms #colaboDemoTerms (skipped)="demoTutorialSkipped()">
</app-colabo-demo-terms>

<app-floorplan-demo #floorplanDemoTenant [lease]="lease">
</app-floorplan-demo>
