import { Injectable } from '@angular/core';

import {
  BaseRentalRateUnitMetrics,
  CommonTools,
  CommonTransformer,
  IRealEstateTaxesCamExpensesViewModel,
  PropertyManagementFeePercentageType,
  PropertyManagementFeeType,
  RentalRateAnnualEscalationTermCustomValueType,
} from '@statera/sdk/common';

import moment from 'moment';
import { Observable } from 'rxjs';

import * as models from './term.model';

import { TermRepository } from './term.repository';

@Injectable()
export class TermManager {
  private readonly _dateFormat = 'MMMM d, y';
  private readonly _alphabet = 'abcdefghijklmnopqrstuvwxyz';

  private readonly _termRepository: TermRepository;
  private readonly _commonTransformer: CommonTransformer;

  constructor(
    termRepository: TermRepository,
    commonTransformer: CommonTransformer,
  ) {
    this._termRepository = termRepository;
    this._commonTransformer = commonTransformer;
  }

  saveLeaseTerm(leaseTerm: models.LeaseTerm, leaseId: number = null): Observable<void> {
    return this._termRepository.saveLeaseTerm(leaseTerm, leaseId);
  }

  setPreviousLeaseTermValue(previousLeaseTermViewModel: models.PreviousLeaseTerm): Observable<void> {
    return this._termRepository.setPreviousLeaseTermValue(previousLeaseTermViewModel);
  }

  getLeaseFloorPlans(leaseId: number): Observable<Array<models.LeaseFloorPlan>> {
    return this._termRepository.getLeaseFloorPlans(leaseId);
  }

  getStageHistoryRecords(leaseId: number): Observable<Array<models.StageHistoryRecord>> {
    return this._termRepository.getStageHistoryRecords(leaseId);
  }

  getCommentProfiles(
    leaseId: number,
    termCommentType: models.TermCommentType,
  ): Observable<Array<models.Profile>> {
    return this._termRepository.getCommentProfiles(leaseId, termCommentType);
  }

  validateDuplicateLeaseTerm(leaseTerm: models.LeaseTerm, leaseId: number): Observable<boolean> {
    return this._termRepository.validateDuplicateLeaseTerm(leaseTerm, leaseId);
  }

  getMarketRentalRate(leaseId: number): Observable<models.MarketRentalRate> {
    return this._termRepository.getMarketRentalRate(leaseId);
  }

  // TODO: Move to the term-length/term-term manager
  getOptions(project: models.Project): Array<{name: string, value: models.TermTypeOption, visible?: boolean}> {
    if (project.projectTypeId === models.ProjectTypeEnum.Restructure) {
      return [
        {name: '12 Months', value: models.TermTypeOption._12Months},
        {name: '24 Months', value: models.TermTypeOption._24Months},
        {name: '36 Months', value: models.TermTypeOption._36Months},
        {name: '60 Months', value: models.TermTypeOption._60Months},
        {name: '84 Months', value: models.TermTypeOption._84Months},
        {name: 'Custom', value: models.TermTypeOption.Custom},
        {name: 'Please provide multiple options', value: models.TermTypeOption.MultiplyOptions, visible: false},
      ];
    }

    return [
      {name: '36 Months', value: models.TermTypeOption._36Months},
      {name: '60 Months', value: models.TermTypeOption._60Months},
      {name: '84 Months', value: models.TermTypeOption._84Months},
      {name: '120 Months', value: models.TermTypeOption._120Months},
      {name: 'Custom', value: models.TermTypeOption.Custom},
      {name: 'Please provide multiple options', value: models.TermTypeOption.MultiplyOptions, visible: false},
    ];
  }

  // TODO: Move to the term-length/term-term manager
  getTermTypeOptionByTermValue(termValue: number): models.TermTypeOption {
    if (termValue === 36) {
      return models.TermTypeOption._36Months;
    } else if (termValue === 60) {
      return models.TermTypeOption._60Months;
    } else if (termValue === 84) {
      return models.TermTypeOption._84Months;
    } else if (termValue === 120) {
      return models.TermTypeOption._120Months;
    } else {
      return models.TermTypeOption.Custom;
    }
  }

  isShowRemainderValue(project: models.Project) {
    return project && project.projectTypeId === models.ProjectTypeEnum.Restructure;
  }

  getRemainderValue(commencementTerm: models.CommencementTerm): string {
    if (!commencementTerm || !commencementTerm.commencement) {
      return;
    }
    const now = new Date();
    const commencementDate = new Date(commencementTerm.commencement);

    const difference = moment(commencementDate).diff(moment(now), 'months');

    if (difference < 0) {
      return;
    }

    return `(${difference} months remain from new Lease Commencement date.)`;
  }

  // TODO: Move to the term-length/term-term manager
  getTermValueByOption(termTypeOption: string, term: models.Term) {
    switch (termTypeOption) {
      case models.TermTypeOption._12Months:
        return 12;
      case models.TermTypeOption._24Months:
        return 24;
      case models.TermTypeOption._36Months:
        return 36;
      case models.TermTypeOption._60Months:
        return 60;
      case models.TermTypeOption._84Months:
        return 84;
      case models.TermTypeOption._120Months:
        return 120;
      case models.TermTypeOption.Custom:
        return term.termValue;
      default:
        return null;
    }
  }

  isRequiredCommencementTerm(project: models.Project): boolean {
    return project && project.projectTypeId === models.ProjectTypeEnum.Restructure;
  }

  getLeaseTermValue(leaseTermConfiguration: models.LeaseTermConfiguration, lease: models.Lease): string {
    if (leaseTermConfiguration && lease) {
      switch (leaseTermConfiguration.leaseTermType) {
        case models.LeaseTermType.Commencement:
          return this.getCurrentTermValue(lease.commencementTerm);

        case models.LeaseTermType.Expiration:
          return this._commonTransformer.transformDateSafe(lease.expiration, this._dateFormat);

        case models.LeaseTermType.Term:
          return this.getCurrentTermValue(lease.term);

        case models.LeaseTermType.BuildingPercentage:
          if (lease.buildingPercentage) {
            if (CommonTools.isNumeric(lease.buildingPercentage)) {
              return `${this._commonTransformer.transformNumberSafe(lease.buildingPercentage, '.')}%`;
            }

            return lease.buildingPercentage.toString();
          }
          break;

        case models.LeaseTermType.LandlordMaintenance:
          return this.getCurrentTermValue(lease.landlordMaintenanceTerm);

        case models.LeaseTermType.TenantMaintenance:
          return this.getCurrentTermValue(lease.tenantMaintenanceTerm);

        case models.LeaseTermType.SelfHelp:
          return this.getCurrentTermValue(lease.selfHelpTerm);

        case models.LeaseTermType.Assignment:
          return this.getCurrentTermValue(lease.assignmentTerm);

        case models.LeaseTermType.ExpansionOption:
          return this.getCurrentTermValue(lease.expansionOptionTerm);

        case models.LeaseTermType.EstoppelCertificate:
          return this.getCurrentTermValue(lease.estoppelCertificateTerm);

        case models.LeaseTermType.BaseRentalRate:
          return this.getCurrentTermValue(lease.baseRentalRateTerm);

        case models.LeaseTermType.TenantSquareFootage:
          return this.getCurrentTermValue(lease.tenantSquareFootageTerm);

        case models.LeaseTermType.FreeRent:
          return this.getCurrentTermValue(lease.freeRentTerm);

        case models.LeaseTermType.SecurityDeposit:
          return this.getCurrentTermValue(lease.securityDepositTerm);

        case models.LeaseTermType.RealEstateTaxesCamExpenses:
          return this.getCurrentTermValue(lease.realEstateTaxesCamExpensesTerm);

        case models.LeaseTermType.RentalRateAnnualEscalation:
          return this.getCurrentTermValue(lease.rentalRateAnnualEscalationTerm);

        case models.LeaseTermType.TenantImprovements:
          return this.getCurrentTermValue(lease.tenantImprovementsTerm);

        case models.LeaseTermType.Hvac:
          return this.getCurrentTermValue(lease.hvacTerm);

        case models.LeaseTermType.RenewalOption:
          return this.getCurrentTermValue(lease.renewalOptionTerm);

        case models.LeaseTermType.TerminationOption:
          return this.getCurrentTermValue(lease.terminationOptionTerm);

        case models.LeaseTermType.Insurance:
          return this.getCurrentTermValue(lease.insuranceTerm);

        case models.LeaseTermType.HoldoverProvision:
          return this.getCurrentTermValue(lease.holdoverProvisionTerm);

        case models.LeaseTermType.ForceMajeure:
          return this.getCurrentTermValue(lease.forceMajeureTerm);

        case models.LeaseTermType.Parking:
          return this.getCurrentTermValue(lease.parkingTerm);

        case models.LeaseTermType.SpaceUse:
          return this.getCurrentTermValue(lease.spaceUseTerm);

        case models.LeaseTermType.ConditionOfPremises:
          return this.getCurrentTermValue(lease.conditionOfPremisesTerm);

        case models.LeaseTermType.CodeCompliance:
          return this.getCurrentTermValue(lease.codeComplianceTerm);

        case models.LeaseTermType.Utilities:
          return this.getCurrentTermValue(lease.utilitiesTerm);

        case models.LeaseTermType.Signage:
          return this.getCurrentTermValue(lease.signageTerm);

        case models.LeaseTermType.NonDisturbance:
          return this.getCurrentTermValue(lease.nonDisturbanceTerm);

        case models.LeaseTermType.HazardousMaterials:
          return this.getCurrentTermValue(lease.hazardousMaterialsTerm);

        case models.LeaseTermType.Rail:
          return this.getCurrentTermValue(lease.railTerm);
      }

      return '';
    }
  }

  getCurrentTermValue(term: models.LeaseTerm): string {
    if (!term || !term.leaseTermType) {
      return '';
    }

    switch (term.leaseTermType) {
      case models.LeaseTermType.Commencement:
        if (this.isCommencementTerm(term)) {
          return this._commonTransformer.transformDateSafe(term.displayValue, this._dateFormat);
        }

        break;

      case models.LeaseTermType.Term:
        if (this.isTermTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.LandlordMaintenance:
        if (this.isLandlordMaintenanceOrResponsibilitiesTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.TenantMaintenance:
        if (this.isTenantMaintenanceOrResponsibilitiesTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.SelfHelp:
        if (this.isSelfHelpTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Assignment:
        if (this.isAssignmentOrSublettingTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.ExpansionOption:
        if (this.isExpansionOptionTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.EstoppelCertificate:
        if (this.isEstoppelCertificateTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.BaseRentalRate:
        if (this.isBaseRentalRateTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.TenantSquareFootage:
        if (this.isTenantSquareFootageTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.FreeRent:
        if (this.isFreeRentTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.SecurityDeposit:
        if (this.isSecurityDepositTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.RealEstateTaxesCamExpenses:
        if (this.isRealEstateTaxesAndCamExpensesTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.RentalRateAnnualEscalation:
        if (this.isRentalRateEscalationTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.TenantImprovements:
        if (this.isTenantImprovementsTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Hvac:
        if (this.isHvacTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.RenewalOption:
        if (this.isRenewalOptionTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.TerminationOption:
        if (this.isTerminationOptionTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Insurance:
        if (this.isInsuranceTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.HoldoverProvision:
        if (this.isHoldoverProvisionTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.ForceMajeure:
        if (this.isForceMajeureTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Parking:
        if (this.isParkingTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.SpaceUse:
        if (this.isSpaceUseTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.ConditionOfPremises:
        if (this.isConditionOfPremisesTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.CodeCompliance:
        if (this.isCodeComplianceTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Utilities:
        if (this.isUtilitiesTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Signage:
        if (this.isSignageTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.NonDisturbance:
        if (this.isNonDisturbanceTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.HazardousMaterials:
        if (this.isHazardousMaterialsTerm(term)) {
          return term.displayValue;
        }

        break;

      case models.LeaseTermType.Rail:
        if (this.isRailTerm(term)) {
          return term.displayValue;
        }

        break;
    }
  }

  getTermAbstractHistory(lease: models.Lease, termType: models.LeaseTermType): Array<models.LeaseTerm> {
    const history: Array<models.LeaseTerm> = [];

    let abstractLease = lease.abstractLease;
    let abstractTerm = this.getOriginalAbstractTerm(lease, termType);

    while (abstractTerm != null && abstractLease != null) {
      history.push({
        ...abstractTerm,
        lease: abstractLease,
      });

      abstractTerm = (<models.LeaseTerm & {abstract: models.LeaseTerm}>abstractTerm).abstract;
      abstractLease = abstractLease.abstractLease;
    }

    let currentTerm = this.getLeaseTerm(lease, termType);

    if (!lease.isAbstractLease) {
      currentTerm = this._getAbstractTerm(lease, termType);
    }

    return history
      .reverse()
      .reduce(
        (acc, historyItem) => {
          const sameValueIndex = acc.findIndex(x => this.isSameTerms(x, historyItem));

          let indexToUpdate = sameValueIndex;

          if (sameValueIndex < 0) {
            indexToUpdate = acc.length;

            acc.push(historyItem);
          }

          if (this.isSameTerms(acc[indexToUpdate], currentTerm)) {
            acc[indexToUpdate] = <models.LeaseTerm>{
              ...acc[indexToUpdate],
              isCurrentTermValue: currentTerm.isCurrentTermValue,
            };
          }

          return acc;
        },
        [] /** acc */
      );
  }

  isSameTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    return (
      (x && y) &&
      (
        (
          this.isCommencementTerm(x) &&
          this.isCommencementTerm(y) &&
          this.isSameCommencementTerms(x, y)
        ) ||
        (
          this.isTermTerm(x) &&
          this.isTermTerm(y) &&
          this.isSameTermTerms(x, y)
        ) ||
        (
          this.isTenantSquareFootageTerm(x) &&
          this.isTenantSquareFootageTerm(y) &&
          this.isSameTenantSquareFootageTerms(x, y)
        ) ||
        (
          this.isBaseRentalRateTerm(x) &&
          this.isBaseRentalRateTerm(y) &&
          this.isSameBaseRentalRateTerms(x, y)
        ) ||
        (
          this.isFreeRentTerm(x) &&
          this.isFreeRentTerm(y) &&
          this.isSameFreeRentTerms(x, y)
        ) ||
        (
          this.isSecurityDepositTerm(x) &&
          this.isSecurityDepositTerm(y) &&
          this.isSameSecurityDepositTerms(x, y)
        ) ||
        (
          this.isRealEstateTaxesAndCamExpensesTerm(x) &&
          this.isRealEstateTaxesAndCamExpensesTerm(y) &&
          this.isSameRealEstateTaxesAndCamExpensesTerms(x, y)
        ) ||
        (
          this.isRentalRateEscalationTerm(x) &&
          this.isRentalRateEscalationTerm(y) &&
          this.isSameRentalRateEscalationTerms(x, y)
        ) ||
        (
          this.isTenantImprovementsTerm(x) &&
          this.isTenantImprovementsTerm(y) &&
          this.isSameTenantImprovementsTerms(x, y)
        ) ||
        (
          this.isLandlordMaintenanceOrResponsibilitiesTerm(x) &&
          this.isLandlordMaintenanceOrResponsibilitiesTerm(y) &&
          this.isSameLandlordMaintenanceOrResponsibilitiesTerms(x, y)
        ) ||
        (
          this.isTenantMaintenanceOrResponsibilitiesTerm(x) &&
          this.isTenantMaintenanceOrResponsibilitiesTerm(y) &&
          this.isSameTenantMaintenanceOrResponsibilitiesTerms(x, y)
        ) ||
        (
          this.isSelfHelpTerm(x) &&
          this.isSelfHelpTerm(y) &&
          this.isSameSelfHelpTerms(x, y)
        ) ||
        (
          this.isAssignmentOrSublettingTerm(x) &&
          this.isAssignmentOrSublettingTerm(y) &&
          this.isSameAssignmentOrSublettingTerms(x, y)
        ) ||
        (
          this.isRenewalOptionTerm(x) &&
          this.isRenewalOptionTerm(y) &&
          this.isSameRenewalOptionTerms(x, y)
        ) ||
        (
          this.isEstoppelCertificateTerm(x) &&
          this.isEstoppelCertificateTerm(y) &&
          this.isSameEstoppelCertificateTerms(x, y)
        ) ||
        (
          this.isTerminationOptionTerm(x) &&
          this.isTerminationOptionTerm(y) &&
          this.isSameTerminationOptionTerms(x, y)
        ) ||
        (
          this.isExpansionOptionTerm(x) &&
          this.isExpansionOptionTerm(y) &&
          this.isSameExpansionOptionTerms(x, y)
        ) ||
        (
          this.isHvacTerm(x) &&
          this.isHvacTerm(y) &&
          this.isSameHvacTerms(x, y)
        ) ||
        (
          this.isInsuranceTerm(x) &&
          this.isInsuranceTerm(y) &&
          this.isSameInsuranceTerms(x, y)
        ) ||
        (
          this.isHoldoverProvisionTerm(x) &&
          this.isHoldoverProvisionTerm(y) &&
          this.isSameHoldoverProvisionTerms(x, y)
        ) ||
        (
          this.isForceMajeureTerm(x) &&
          this.isForceMajeureTerm(y) &&
          this.isSameForceMajeureTerms(x, y)
        ) ||
        (
          this.isParkingTerm(x) &&
          this.isParkingTerm(y) &&
          this.isSameParkingTerms(x, y)
        ) ||
        (
          this.isExpirationTerm(x) &&
          this.isExpirationTerm(y) &&
          this.isSameExpirationTerms(x, y)
        ) ||
        (
          this.isBuildingPercentageTerm(x) &&
          this.isBuildingPercentageTerm(y) &&
          this.isSameBuildingPercentageTerms(x, y)
        ) ||
        (
          this.isSpaceUseTerm(x) &&
          this.isSpaceUseTerm(y) &&
          this.isSameSpaceUseTerms(x, y)
        ) ||
        (
          this.isConditionOfPremisesTerm(x) &&
          this.isConditionOfPremisesTerm(y) &&
          this.isSameConditionOfPremisesTerms(x, y)
        ) ||
        (
          this.isCodeComplianceTerm(x) &&
          this.isCodeComplianceTerm(y) &&
          this.isSameCodeComplianceTerms(x, y)
        ) ||
        (
          this.isUtilitiesTerm(x) &&
          this.isUtilitiesTerm(y) &&
          this.isSameUtilitiesTerms(x, y)
        ) ||
        (
          this.isSignageTerm(x) &&
          this.isSignageTerm(y) &&
          this.isSameSignageTerms(x, y)
        ) ||
        (
          this.isNonDisturbanceTerm(x) &&
          this.isNonDisturbanceTerm(y) &&
          this.isSameNonDisturbanceTerms(x, y)
        ) ||
        (
          this.isHazardousMaterialsTerm(x) &&
          this.isHazardousMaterialsTerm(y) &&
          this.isSameHazardousMaterialsTerms(x, y)
        ) ||
        (
          this.isRailTerm(x) &&
          this.isRailTerm(y) &&
          this.isSameRailTerms(x, y)
        )
      )
    );
  }

  isSameCommencementTerms(x: models.CommencementTerm, y: models.CommencementTerm): boolean {
    return (
      x && y &&
      x.commencement && y.commencement &&
      moment(x.commencement).isSame(moment(y.commencement))
    );
  }

  isSameTermTerms(x: models.Term, y: models.Term): boolean {
    return (
      x && y &&
      x.termValue === y.termValue &&
      x.termType === y.termType &&
      JSON.stringify(x.leaseTermOptions) === JSON.stringify(y.leaseTermOptions)
    );
  }

  isSameTenantSquareFootageTerms(x: models.TenantSquareFootageTerm, y: models.TenantSquareFootageTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.tenantSquareFootageTermType !== y.tenantSquareFootageTermType) {
      return false;
    }

    switch (x.tenantSquareFootageTermType) {
      case models.TenantSquareFootageTermType.ExistingSquareFootage:
        return x.tenantSquareFootageAbstractValue === y.tenantSquareFootageAbstractValue;

      case models.TenantSquareFootageTermType.Custom:
        return x.tenantSquareFootageCustomValue === y.tenantSquareFootageCustomValue;

      case models.TenantSquareFootageTermType.PhaseIn:
        return (
          x.tenantSquareFootagePhaseInResults && y.tenantSquareFootagePhaseInResults &&
          x.tenantSquareFootagePhaseInResults.length === y.tenantSquareFootagePhaseInResults.length &&
          x.tenantSquareFootagePhaseInResults
            .every((phaseInResult: models.TenantSquareFootagePhaseInResult, i: number): boolean => {
              const anotherPhaseInResult = y.tenantSquareFootagePhaseInResults[i];
              return (
                phaseInResult && anotherPhaseInResult &&
                phaseInResult.totalSf === anotherPhaseInResult.totalSf &&
                moment(phaseInResult.startDate).isSame(moment(anotherPhaseInResult.startDate)) &&
                moment(phaseInResult.endDate).isSame(moment(anotherPhaseInResult.endDate))
              );
            })
        );

      default:
        return false;
    }
  }

  isSameBaseRentalRateTerms(x: models.BaseRentalRate, y: models.BaseRentalRate): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.baseRentalRateType !== y.baseRentalRateType) {
      return false;
    }

    switch (x.baseRentalRateType) {
      case models.BaseRentalRateType.BaseYear:
        return (
          x.actualYearRate === y.actualYearRate &&
          moment(x.baseYear).isSame(moment(y.baseYear))
        );

      case models.BaseRentalRateType.Net:
        return x.baseRentalRateNetValue === y.baseRentalRateNetValue;

      case models.BaseRentalRateType.Gross:
        return x.baseRentalRateGrossValue === y.baseRentalRateGrossValue;

      case models.BaseRentalRateType.MultiplyOptions:
        return !(
          x.leaseTermOptions?.options?.length !== y.leaseTermOptions?.options?.length ||
          x.leaseTermOptions
            ?.options
            ?.some((xOption, xOptionIndex) => {
              const yOption = y.leaseTermOptions?.options?.[xOptionIndex];
              return (
                xOption.baseRentalRateType !== yOption.baseRentalRateType ||
                (
                  xOption.baseRentalRateType === models.BaseRentalRateType.Net &&
                  xOption.baseRentalRateNetValue !== yOption.baseRentalRateNetValue
                ) ||
                (
                  xOption.baseRentalRateType === models.BaseRentalRateType.Gross &&
                  xOption.baseRentalRateGrossValue !== yOption.baseRentalRateGrossValue
                ) ||
                (
                  xOption.baseRentalRateType === models.BaseRentalRateType.BaseYear &&
                  xOption.actualYearRate !== yOption.actualYearRate
                )
              );
            })
        );

      default:
        return true;
    }
  }

  isSameFreeRentTerms(x: models.FreeRentTerm, y: models.FreeRentTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.freeRentTermType !== y.freeRentTermType) {
      return false;
    }

    switch (x.freeRentTermType) {
      case models.FreeRentTermType.MonthsCount:
        return (
          x.freeRentMonthsCount === y.freeRentMonthsCount &&
          x.freeRentTaxesType === y.freeRentTaxesType
        );

      case models.FreeRentTermType.SpecificSchedule:
        return (
          x.specificMonths && y.specificMonths &&
          x.specificMonths.length === y.specificMonths.length &&
          x.freeRentTaxesType === y.freeRentTaxesType &&
          x.specificMonths.every((month: number, i: number): boolean => month === y.specificMonths[i])
        );

      default:
        return true;
    }
  }

  isSameSecurityDepositTerms(x: models.SecurityDepositTerm, y: models.SecurityDepositTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.securityDepositTermType !== y.securityDepositTermType) {
      return false;
    }

    switch (x.securityDepositTermType) {
      case models.SecurityDepositTermType.MonthsCount:
        return x.monthsCount === y.monthsCount;

      case models.SecurityDepositTermType.MoneyValue:
        return x.securityDepositMoneyValue === y.securityDepositMoneyValue;

      case models.SecurityDepositTermType.ReducingValue:
        return x.securityDepositReducingValue === y.securityDepositReducingValue;

      default:
        return true;
    }
  }

  isSameRealEstateTaxesAndCamExpensesTerms(
    x: models.RealEstateTaxesCamExpenses,
    y: models.RealEstateTaxesCamExpenses,
  ): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.realEstateTaxesCamExpensesTermType !== y.realEstateTaxesCamExpensesTermType) {
      return false;
    }

    switch (x.realEstateTaxesCamExpensesTermType) {
      case models.RealEstateTaxesCamExpensesTermType.PercentagePerYear:
        return (
          x.percentagePerYear === y.percentagePerYear &&
          x.realEstateTaxesCamExpensesCumulativeType === y.realEstateTaxesCamExpensesCumulativeType
        );

      case models.RealEstateTaxesCamExpensesTermType.EstimatedReTaxesOpEx:
        return (
          x.estimatedReTaxes === y.estimatedReTaxes &&
          x.estimatedOpEx === y.estimatedOpEx
        );

      case models.RealEstateTaxesCamExpensesTermType.Custom:
        return x.realEstateTaxesCamExpensesText === y.realEstateTaxesCamExpensesText;

      default:
        return true;
    }
  }

  isSameRentalRateEscalationTerms(
    x: models.RentalRateAnnualEscalationTerm,
    y: models.RentalRateAnnualEscalationTerm,
  ): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.escalationTermType !== y.escalationTermType) {
      return false;
    }

    switch (x.escalationTermType) {
      case models.EscalationTermType.FixedPercentagePerYear:
        return x.escalationPercentagePerYear === y.escalationPercentagePerYear;

      case models.EscalationTermType.FixedAmountPsfPerYear:
        return x.escalationPsfValuePerYear === y.escalationPsfValuePerYear;

      case models.EscalationTermType.Custom:
        return (
          x.rentalRateAnnualEscalationTermCustomResults && y.rentalRateAnnualEscalationTermCustomResults &&
          x.rentalRateAnnualEscalationTermCustomResults.length === y.rentalRateAnnualEscalationTermCustomResults.length &&
          x.rentalRateAnnualEscalationTermCustomResults
            .every((escalationResult: models.RentalRateAnnualEscalationTermCustomResult, i: number): boolean => {
              const anotherEscalationResult = y.rentalRateAnnualEscalationTermCustomResults[i];
              return (
                escalationResult && anotherEscalationResult &&
                escalationResult.leaseMonth === anotherEscalationResult.leaseMonth &&
                escalationResult.rsfValue === anotherEscalationResult.rsfValue
              );
            })
        );

      default:
        return true;
    }
  }

  isSameTenantImprovementsTerms(x: models.TenantImprovements, y: models.TenantImprovements): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.tenantImprovementsType !== y.tenantImprovementsType) {
      return false;
    }

    switch (x.tenantImprovementsType) {
      case models.TenantImprovementsType.TurnKey:
        return x.tenantImprovementsOptionDescription === y.tenantImprovementsOptionDescription;

      case models.TenantImprovementsType.DollarAllowance:
        return (
          x.tenantImprovementsValue === y.tenantImprovementsValue &&
          x.tenantImprovementsOptionDescription === y.tenantImprovementsOptionDescription &&
          x.tenantImprovementsAmortizationOption === y.tenantImprovementsAmortizationOption
        );

      case models.TenantImprovementsType.CustomAmortized:
        return (
          x.tenantImprovementsAmortized === y.tenantImprovementsAmortized &&
          x.tenantImprovementsAmortizedMonthsCount === y.tenantImprovementsAmortizedMonthsCount &&
          x.tenantImprovementsPercentageRate === y.tenantImprovementsPercentageRate &&
          x.tenantImprovementsOptionDescription === y.tenantImprovementsOptionDescription
        );

      case models.TenantImprovementsType.Custom:
        return x.tenantImprovementsCustom === y.tenantImprovementsCustom;

      default:
        return true;
    }
  }

  isSameLandlordMaintenanceOrResponsibilitiesTerms(
    x: models.LandlordMaintenanceTerm,
    y: models.LandlordMaintenanceTerm,
  ): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.landlordMaintenanceTermType !== y.landlordMaintenanceTermType) {
      return false;
    }

    switch (x.landlordMaintenanceTermType) {
      case models.LandlordMaintenanceTermType.Custom:
        return x.landlordMaintenance === y.landlordMaintenance;
      default:
        return true;
    }
  }

  isSameTenantMaintenanceOrResponsibilitiesTerms(
    x: models.TenantMaintenanceTerm,
    y: models.TenantMaintenanceTerm,
  ): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.tenantMaintenanceTermType !== y.tenantMaintenanceTermType) {
      return false;
    }

    switch (x.tenantMaintenanceTermType) {
      case models.TenantMaintenanceTermType.Custom:
        return x.tenantMaintenance === y.tenantMaintenance;
      default:
        return true;
    }
  }

  isSameSelfHelpTerms(x: models.SelfHelpTerm, y: models.SelfHelpTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.selfHelpTermType !== y.selfHelpTermType) {
      return false;
    }

    switch (x.selfHelpTermType) {
      case models.SelfHelpTermType.Custom:
        return x.selfHelp === y.selfHelp;
      default:
        return true;
    }
  }

  isSameAssignmentOrSublettingTerms(x: models.AssignmentTerm, y: models.AssignmentTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.assignmentTermType !== y.assignmentTermType) {
      return false;
    }

    switch (x.assignmentTermType) {
      case models.AssignmentTermType.Custom:
        return x.assignment === y.assignment;
      default:
        return true;
    }
  }

  isSameRenewalOptionTerms(x: models.RenewalOptionTerm, y: models.RenewalOptionTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.renewalOptionTermType !== y.renewalOptionTermType) {
      return false;
    }

    switch (x.renewalOptionTermType) {
      case models.RenewalOptionTermType.RenewalOptionValues:
        return (
          x.renewalOptionsCount === y.renewalOptionsCount &&
          x.renewalOptionYearsCount === y.renewalOptionYearsCount &&
          x.renewalOptionHowManyMonthsNotice === y.renewalOptionHowManyMonthsNotice
        );
      case models.RenewalOptionTermType.Custom:
        return x.renewalOptionCustomText === y.renewalOptionCustomText;
      default:
        return true;
    }
  }

  isSameEstoppelCertificateTerms(x: models.EstoppelCertificateTerm, y: models.EstoppelCertificateTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.estoppelCertificateTermType !== y.estoppelCertificateTermType) {
      return false;
    }

    switch (x.estoppelCertificateTermType) {
      case models.EstoppelCertificateTermType.Custom:
        return x.estoppelCertificate === y.estoppelCertificate;
      default:
        return true;
    }
  }

  isSameTerminationOptionTerms(x: models.TerminationOptionTerm, y: models.TerminationOptionTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.terminationOptionType !== y.terminationOptionType) {
      return false;
    }

    switch (x.terminationOptionType) {
      case models.TerminationOptionType.RightToTerminateLease:
        return (
          x.terminationMonth === y.terminationMonth &&
          x.howManyMonthsToNotice === y.howManyMonthsToNotice &&
          x.terminationOptionFeeType === y.terminationOptionFeeType &&
          (
            (
              x.terminationOptionFeeType === models.TerminationOptionFeeType.AmortizationRate &&
              x.amortizationRate === y.amortizationRate
            ) ||
            (
              x.terminationOptionFeeType === models.TerminationOptionFeeType.DollarValue &&
              x.terminationOptionDollarValue === y.terminationOptionDollarValue
            )
          )
        );
      default:
        return true;
    }
  }

  isSameExpansionOptionTerms(x: models.ExpansionOptionTerm, y: models.ExpansionOptionTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.expansionOptionTermType !== y.expansionOptionTermType) {
      return false;
    }

    switch (x.expansionOptionTermType) {
      case models.ExpansionOptionTermType.Custom:
        return x.expansionOption === y.expansionOption;
      default:
        return true;
    }
  }

  isSameHvacTerms(x: models.HvacTerm, y: models.HvacTerm): boolean {
    if (!x || !y) {
      return false;
    }

    if (x.detailedDisplayValue === y.detailedDisplayValue) {
      return true;
    }

    if (x.hvacTermType !== y.hvacTermType) {
      return false;
    }

    switch (x.hvacTermType) {
      case models.HvacTermType.MoneyValue:
        return x.hvacMoneyValue === y.hvacMoneyValue;
      case models.HvacTermType.PercentValue:
        return x.hvacPercentValue === y.hvacPercentValue;
      default:
        return true;
    }
  }

  isSameInsuranceTerms(x: models.InsuranceTerm, y: models.InsuranceTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue ||
      x.text === y.text
    );
  }

  isSameHoldoverProvisionTerms(x: models.HoldoverProvisionTerm, y: models.HoldoverProvisionTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue ||
      x.text === y.text
    );
  }

  isSameForceMajeureTerms(x: models.ForceMajeureTerm, y: models.ForceMajeureTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue ||
      x.text === y.text
    );
  }

  isSameParkingTerms(x: models.ParkingTerm, y: models.ParkingTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameExpirationTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameBuildingPercentageTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameSpaceUseTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameConditionOfPremisesTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameCodeComplianceTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameUtilitiesTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameSignageTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameNonDisturbanceTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameHazardousMaterialsTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isSameRailTerms(x: models.LeaseTerm, y: models.LeaseTerm): boolean {
    if (!x || !y) {
      return false;
    }

    return (
      x.detailedDisplayValue === y.detailedDisplayValue
    );
  }

  isCommencementTerm(term: models.LeaseTerm): term is models.CommencementTerm {
    return term.leaseTermType === models.LeaseTermType.Commencement;
  }

  isTermTerm(term: models.LeaseTerm): term is models.Term {
    return term.leaseTermType === models.LeaseTermType.Term;
  }

  isTenantSquareFootageTerm(term: models.LeaseTerm): term is models.TenantSquareFootageTerm {
    return term.leaseTermType === models.LeaseTermType.TenantSquareFootage;
  }

  isBaseRentalRateTerm(term: models.LeaseTerm): term is models.BaseRentalRate {
    return term.leaseTermType === models.LeaseTermType.BaseRentalRate;
  }

  isFreeRentTerm(term: models.LeaseTerm): term is models.FreeRentTerm {
    return term.leaseTermType === models.LeaseTermType.FreeRent;
  }

  isSecurityDepositTerm(term: models.LeaseTerm): term is models.SecurityDepositTerm {
    return term.leaseTermType === models.LeaseTermType.SecurityDeposit;
  }

  isRealEstateTaxesAndCamExpensesTerm(term: models.LeaseTerm): term is models.RealEstateTaxesCamExpenses {
    return term.leaseTermType === models.LeaseTermType.RealEstateTaxesCamExpenses;
  }

  isRentalRateEscalationTerm(term: models.LeaseTerm): term is models.RentalRateAnnualEscalationTerm {
    return term.leaseTermType === models.LeaseTermType.RentalRateAnnualEscalation;
  }

  isTenantImprovementsTerm(term: models.LeaseTerm): term is models.TenantImprovements {
    return term.leaseTermType === models.LeaseTermType.TenantImprovements;
  }

  isLandlordMaintenanceOrResponsibilitiesTerm(term: models.LeaseTerm): term is models.LandlordMaintenanceTerm {
    return term.leaseTermType === models.LeaseTermType.LandlordMaintenance;
  }

  isTenantMaintenanceOrResponsibilitiesTerm(term: models.LeaseTerm): term is models.TenantMaintenanceTerm {
    return term.leaseTermType === models.LeaseTermType.TenantMaintenance;
  }

  isSelfHelpTerm(term: models.LeaseTerm): term is models.SelfHelpTerm {
    return term.leaseTermType === models.LeaseTermType.SelfHelp;
  }

  isAssignmentOrSublettingTerm(term: models.LeaseTerm): term is models.AssignmentTerm {
    return term.leaseTermType === models.LeaseTermType.Assignment;
  }

  isRenewalOptionTerm(term: models.LeaseTerm): term is models.RenewalOptionTerm {
    return term.leaseTermType === models.LeaseTermType.RenewalOption;
  }

  isEstoppelCertificateTerm(term: models.LeaseTerm): term is models.EstoppelCertificateTerm {
    return term.leaseTermType === models.LeaseTermType.EstoppelCertificate;
  }

  isTerminationOptionTerm(term: models.LeaseTerm): term is models.TerminationOptionTerm {
    return term.leaseTermType === models.LeaseTermType.TerminationOption;
  }

  isExpansionOptionTerm(term: models.LeaseTerm): term is models.ExpansionOptionTerm {
    return term.leaseTermType === models.LeaseTermType.ExpansionOption;
  }

  isHvacTerm(term: models.LeaseTerm): term is models.HvacTerm {
    return term.leaseTermType === models.LeaseTermType.Hvac;
  }

  isInsuranceTerm(term: models.LeaseTerm): term is models.InsuranceTerm {
    return term.leaseTermType === models.LeaseTermType.Insurance;
  }

  isHoldoverProvisionTerm(term: models.LeaseTerm): term is models.HoldoverProvisionTerm {
    return term.leaseTermType === models.LeaseTermType.HoldoverProvision;
  }

  isForceMajeureTerm(term: models.LeaseTerm): term is models.ForceMajeureTerm {
    return term.leaseTermType === models.LeaseTermType.ForceMajeure;
  }

  isParkingTerm(term: models.LeaseTerm): term is models.ParkingTerm {
    return term.leaseTermType === models.LeaseTermType.Parking;
  }

  isExpirationTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.Expiration;
  }

  isBuildingPercentageTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.BuildingPercentage;
  }

  isSpaceUseTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.SpaceUse;
  }

  isConditionOfPremisesTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.ConditionOfPremises;
  }

  isCodeComplianceTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.CodeCompliance;
  }

  isUtilitiesTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.Utilities;
  }

  isSignageTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.Signage;
  }

  isNonDisturbanceTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.NonDisturbance;
  }

  isHazardousMaterialsTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.HazardousMaterials;
  }

  isRailTerm(term: models.LeaseTerm): term is models.LeaseTerm {
    return term.leaseTermType === models.LeaseTermType.Rail;
  }

  getTermCurrentAbstractValue(
    lease: models.Lease,
    leaseTermConfiguration: models.LeaseTermConfiguration,
    displayValueType: models.TermDisplayValueType = models.TermDisplayValueType.Original,
  ): string {
    if (!lease || !leaseTermConfiguration) {
      return null;
    }

    const abstractTermValue = this._getCurrentAbstractTermValue(
      lease,
      leaseTermConfiguration.leaseTermType,
      displayValueType,
    );

    return this._transformTermValue(abstractTermValue, leaseTermConfiguration.leaseTermType);
  }

  getTermOriginalAbstractValue(
    lease: models.Lease,
    leaseTermConfiguration: models.LeaseTermConfiguration,
    displayValueType: models.TermDisplayValueType = models.TermDisplayValueType.Original,
  ): string {
    if (!lease || !leaseTermConfiguration) {
      return;
    }

    const abstractTermValue = this._getOriginalAbstractTermValue(
      lease,
      leaseTermConfiguration.leaseTermType,
      displayValueType,
    );

    return this._transformTermValue(abstractTermValue, leaseTermConfiguration.leaseTermType);
  }

  getCurrentAbstractTerm(lease: models.Lease, termType: models.LeaseTermType): models.LeaseTerm {
    if (!lease || !termType) {
      return null;
    }

    if (lease.hasThirdPartyLease) {
      return this.getLeaseTerm(lease.thirdPartyLease, termType);
    }

    if (lease.isAbstractLease) {
      return this.getLeaseTerm(lease, termType);
    }

    return this._getAbstractTerm(lease, termType);
  }

  getOriginalAbstractTerm(lease: models.Lease, termType: models.LeaseTermType) {
    if (!lease || !termType) {
      return null;
    }

    return this._getAbstractTerm(lease, termType);
  }

  private _getSimpleAbstractTerm(lease: models.Lease, termType: models.LeaseTermType): models.LeaseTerm {
    if (!lease || !lease.abstractLease || !lease.abstractLease.commencementTerm || !termType) {
      return null;
    }

    const now = moment().startOf('day');
    let isCurrentTermValue = false;

    if (lease.abstractLease.calculatedExpirationDate) {
      const commencementDate = moment(lease.abstractLease.commencementTerm.commencement).startOf('day');
      const expirationDate = moment(lease.abstractLease.calculatedExpirationDate).startOf('day');
      isCurrentTermValue = commencementDate.isSameOrBefore(now) && now.isSameOrBefore(expirationDate);
    }

    const abstractTerm = <models.LeaseTerm & {abstract: models.LeaseTerm}>{
      abstract: this._getSimpleAbstractTerm(lease.abstractLease, termType),
      leaseTermType: termType,
      amendmentNumber: lease.abstractLease.amendmentNumber,
      isCurrentTermValue: isCurrentTermValue,
    };

    switch (termType) {
      case models.LeaseTermType.Expiration: {
        return <models.LeaseTerm>{
          ...abstractTerm,
          displayValue: lease.abstractLease.calculatedExpirationDate?.toString(),
          detailedDisplayValue: lease.abstractLease.calculatedExpirationDate?.toString(),
        };
      }
      case models.LeaseTermType.BuildingPercentage: {
        return <models.LeaseTerm>{
          ...abstractTerm,
          displayValue: lease.abstractLease.buildingPercentage,
          detailedDisplayValue: lease.abstractLease.buildingPercentage,
        };
      }
    }

    return null;
  }

  private _getAbstractTerm(lease: models.Lease, termType: models.LeaseTermType): models.LeaseTerm {
    if (!lease || !termType) {
      return null;
    }

    const getTerm = () => {
      switch (termType) {
        case models.LeaseTermType.Commencement: {
          if (lease.commencementTerm) {
            return lease.commencementTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Term: {
          if (lease.term) {
            return lease.term.abstract;
          }
          break;
        }
        case models.LeaseTermType.RealEstateTaxes: {
          if (lease.realEstateTaxesCamExpensesTerm) {
            return lease.realEstateTaxesCamExpensesTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.LandlordMaintenance: {
          if (lease.landlordMaintenanceTerm) {
            return lease.landlordMaintenanceTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.TenantMaintenance: {
          if (lease.tenantMaintenanceTerm) {
            return lease.tenantMaintenanceTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.SelfHelp: {
          if (lease.selfHelpTerm) {
            return lease.selfHelpTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Assignment: {
          if (lease.assignmentTerm) {
            return lease.assignmentTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.ExpansionOption: {
          if (lease.expansionOptionTerm) {
            return lease.expansionOptionTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.EstoppelCertificate: {
          if (lease.estoppelCertificateTerm) {
            return lease.estoppelCertificateTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.BaseRentalRate: {
          if (lease.baseRentalRateTerm) {
            return lease.baseRentalRateTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.TenantSquareFootage: {
          if (lease.tenantSquareFootageTerm) {
            return lease.tenantSquareFootageTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.FreeRent: {
          if (lease.freeRentTerm) {
            return lease.freeRentTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.SecurityDeposit: {
          if (lease.securityDepositTerm) {
            return lease.securityDepositTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.RealEstateTaxesCamExpenses: {
          if (lease.realEstateTaxesCamExpensesTerm) {
            return lease.realEstateTaxesCamExpensesTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.RentalRateAnnualEscalation: {
          if (lease.rentalRateAnnualEscalationTerm) {
            return lease.rentalRateAnnualEscalationTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.TenantImprovements: {
          if (lease.tenantImprovementsTerm) {
            return lease.tenantImprovementsTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Hvac: {
          if (lease.hvacTerm) {
            return lease.hvacTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.RenewalOption: {
          if (lease.renewalOptionTerm) {
            return lease.renewalOptionTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.TerminationOption: {
          if (lease.terminationOptionTerm) {
            return lease.terminationOptionTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Insurance: {
          if (lease.insuranceTerm) {
            return lease.insuranceTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.HoldoverProvision: {
          if (lease.holdoverProvisionTerm) {
            return lease.holdoverProvisionTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.ForceMajeure: {
          if (lease.forceMajeureTerm) {
            return lease.forceMajeureTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Parking: {
          if (lease.parkingTerm) {
            return lease.parkingTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.SpaceUse: {
          if (lease.spaceUseTerm) {
            return lease.spaceUseTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.ConditionOfPremises: {
          if (lease.conditionOfPremisesTerm) {
            return lease.conditionOfPremisesTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.CodeCompliance: {
          if (lease.codeComplianceTerm) {
            return lease.codeComplianceTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Utilities: {
          if (lease.utilitiesTerm) {
            return lease.utilitiesTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Signage: {
          if (lease.signageTerm) {
            return lease.signageTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.NonDisturbance: {
          if (lease.nonDisturbanceTerm) {
            return lease.nonDisturbanceTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.HazardousMaterials: {
          if (lease.hazardousMaterialsTerm) {
            return lease.hazardousMaterialsTerm.abstract;
          }
          break;
        }
        case models.LeaseTermType.Rail: {
          if (lease.railTerm) {
            return lease.railTerm.abstract;
          }
          break;
        }
        default: {
          switch (termType) {
            case models.LeaseTermType.Expiration:
            case models.LeaseTermType.BuildingPercentage:
              return this._getSimpleAbstractTerm(lease, termType);
            default:
              return;
          }
        }
      }
    };
    if (lease.hasThirdPartyLease) {
      const thirdPartyLeaseTerm = this.getLeaseTerm(lease.thirdPartyLease, termType);
      if (!thirdPartyLeaseTerm) {
        return null;
      }
      return <models.LeaseTerm>{
        ...thirdPartyLeaseTerm,
        lease: lease.abstractLease,
      };
    }

    const term = getTerm();
    if (!term) {
      return null;
    }

    return <models.LeaseTerm>{
      ...term,
      lease: lease.abstractLease,
    };
  }

  getTermDisplayValue(term: models.LeaseTerm): string {
    if (!term) {
      return null;
    }

    return this._transformTermValue(term.displayValue, term.leaseTermType);
  }

  getTermDetailedDisplayValue(term: models.LeaseTerm): string {
    if (!term) {
      return null;
    }

    switch (term.leaseTermType) {
      case models.LeaseTermType.TenantSquareFootage:
        return this._getTenantSquareFootageAbstractDisplayValue(<models.TenantSquareFootageTerm>term);
      case models.LeaseTermType.RentalRateAnnualEscalation:
        return this._getRentalRateEscalationAbstractDisplayValue(<models.RentalRateAnnualEscalationTerm>term);
      case models.LeaseTermType.ConditionOfPremises:
        return term.displayValue;
      default:
        return this._transformTermValue(term.detailedDisplayValue, term.leaseTermType);
    }
  }

  private _getCurrentAbstractTermValue(
    lease: models.Lease,
    termType: models.LeaseTermType,
    displayValueType: models.TermDisplayValueType = models.TermDisplayValueType.Original,
  ): string {
    if (!lease || !termType) {
      return;
    }

    switch (termType) {
      case models.LeaseTermType.Expiration:
        return this._commonTransformer.transformDateSafe(lease.expirationAbstractValue, this._dateFormat);
      case models.LeaseTermType.BuildingPercentage:
        return lease.buildingPercentageAbstractValue;
      default:
        const abstractTerm = this.getCurrentAbstractTerm(lease, termType);
        if (!abstractTerm) {
          return null;
        }

        switch (displayValueType) {
          case models.TermDisplayValueType.Original:
            return this.getTermDisplayValue(abstractTerm);
          case models.TermDisplayValueType.Detailed:
            return this.getTermDetailedDisplayValue(abstractTerm);
          default:
            return null;
        }
    }
  }

  private _getOriginalAbstractTermValue(
    lease: models.Lease,
    termType: models.LeaseTermType,
    displayValueType: models.TermDisplayValueType = models.TermDisplayValueType.Original,
  ): string {
    if (!lease || !termType) {
      return;
    }

    switch (termType) {
      case models.LeaseTermType.Expiration:
        return this._commonTransformer.transformDateSafe(lease.expirationOriginalAbstractValue, this._dateFormat);
      case models.LeaseTermType.BuildingPercentage:
        return lease.buildingPercentageOriginalAbstractValue;
      default:
        const abstractTerm = this.getOriginalAbstractTerm(lease, termType);
        if (!abstractTerm) {
          return null;
        }

        switch (displayValueType) {
          case models.TermDisplayValueType.Original:
            return this.getTermDisplayValue(abstractTerm);
          case models.TermDisplayValueType.Detailed:
            return this.getTermDetailedDisplayValue(abstractTerm);
          default:
            return null;
        }
    }
  }

  private _getTenantSquareFootageAbstractDisplayValue(term: models.TenantSquareFootageTerm): string {
    if (!term) {
      return null;
    }

    if (
      term.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn &&
      term.tenantSquareFootagePhaseInResults &&
      term.tenantSquareFootagePhaseInResults.length
    ) {
      const latestPhaseInResult = term.tenantSquareFootagePhaseInResults[term.tenantSquareFootagePhaseInResults.length - 1];
      if (!latestPhaseInResult) {
        return null;
      }

      return (
        `${this._commonTransformer.transformNumberSafe(latestPhaseInResult.totalSf, '2.')} ` +
        `SF (Phase-in Option)`
      );
    }

    return term.detailedDisplayValue;
  }

  private _getRentalRateEscalationAbstractDisplayValue(term: models.RentalRateAnnualEscalationTerm): string {
    if (!term) {
      return null;
    }

    if (
      term.escalationTermType === models.EscalationTermType.Custom &&
      term.rentalRateAnnualEscalationTermCustomResults &&
      term.rentalRateAnnualEscalationTermCustomResults.length
    ) {
      const customResults = term.rentalRateAnnualEscalationTermCustomResults;
      const latestEscalationResult = customResults[customResults.length - 1];
      if (!latestEscalationResult) {
        return null;
      }

      const valueType = latestEscalationResult.rentalRateAnnualEscalationTermCustomValueType;
      if (valueType === RentalRateAnnualEscalationTermCustomValueType.FixedPercentagePerYear) {
        const percentageIncrease = this._commonTransformer
          .transformNumberSafe((latestEscalationResult.stepIncreasingPercent || 0) * 100, '1.0');

        return (
          `${percentageIncrease}% (Custom Escalation Table)`
        );
      }

      const rsfIncrease = this._commonTransformer
        .transformNumberSafe(latestEscalationResult.stepIncreasingRsf, '1.2-2');

      let unitMetricsLanguage = 'PSF/Yr';
      if (latestEscalationResult.unitMetrics === BaseRentalRateUnitMetrics.PsfPerMonth) {
        unitMetricsLanguage = 'PSF/Mo';
      }

      return (
        `$${rsfIncrease} ${unitMetricsLanguage} (Custom Escalation Table)`
      );
    }

    return term.detailedDisplayValue;
  }

  private _transformTermValue(termValue: string, termType: models.LeaseTermType) {
    switch (termType) {
      case models.LeaseTermType.Commencement:
      case models.LeaseTermType.Expiration:
        return this._commonTransformer.transformDateSafe(termValue, this._dateFormat);
      case models.LeaseTermType.TenantSquareFootage:
        if (termValue && !termValue.endsWith('SF') && !termValue.includes('SF')) {
          return `${this._commonTransformer.transformNumberSafe(termValue, '2.')} SF`;
        }
        return termValue;
      case models.LeaseTermType.BuildingPercentage:
        if (termValue && CommonTools.isNumeric(termValue)) {
          return `${this._commonTransformer.transformNumberSafe(termValue, '.')}%`;
        }
        return termValue;
      default:
        return termValue;
    }
  }

  getStatusByLeaseTermType(leaseTermType: models.LeaseTermType, lease: models.Lease) {
    let termStatus: models.TermStatus;
    switch (leaseTermType) {
      case models.LeaseTermType.Commencement: {
        if (lease && lease.commencementTerm) {
          termStatus = lease.commencementTerm.termStatus;
        }
        break;
      }
      case models.LeaseTermType.Term:
        if (lease && lease.term) {
          termStatus = lease.term.termStatus;
        }
        break;

      case models.LeaseTermType.LandlordMaintenance:
        if (lease && lease.landlordMaintenanceTerm) {
          termStatus = lease.landlordMaintenanceTerm.termStatus;
        }
        break;
      case models.LeaseTermType.TenantMaintenance:
        if (lease && lease.tenantMaintenanceTerm) {
          termStatus = lease.tenantMaintenanceTerm.termStatus;
        }
        break;
      case models.LeaseTermType.SelfHelp:
        if (lease && lease.selfHelpTerm) {
          termStatus = lease.selfHelpTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Assignment:
        if (lease && lease.assignmentTerm) {
          termStatus = lease.assignmentTerm.termStatus;
        }
        break;
      case models.LeaseTermType.ExpansionOption:
        if (lease && lease.expansionOptionTerm) {
          termStatus = lease.expansionOptionTerm.termStatus;
        }
        break;
      case models.LeaseTermType.EstoppelCertificate:
        if (lease && lease.estoppelCertificateTerm) {
          termStatus = lease.estoppelCertificateTerm.termStatus;
        }
        break;

      case models.LeaseTermType.BaseRentalRate:
        if (lease && lease.baseRentalRateTerm) {
          termStatus = lease.baseRentalRateTerm.termStatus;
        }
        break;
      case models.LeaseTermType.TenantSquareFootage:
        if (lease && lease.tenantSquareFootageTerm && lease.tenantSquareFootageTerm) {
          termStatus = lease.tenantSquareFootageTerm.termStatus;
        }
        break;
      case models.LeaseTermType.FreeRent:
        if (lease && lease.freeRentTerm) {
          termStatus = lease.freeRentTerm.termStatus;
        }
        break;
      case models.LeaseTermType.SecurityDeposit:
        if (lease && lease.securityDepositTerm) {
          termStatus = lease.securityDepositTerm.termStatus;
        }
        break;
      case models.LeaseTermType.RealEstateTaxesCamExpenses:
        if (lease && lease.realEstateTaxesCamExpensesTerm) {
          termStatus = lease.realEstateTaxesCamExpensesTerm.termStatus;
        }
        break;
      case models.LeaseTermType.RentalRateAnnualEscalation:
        if (lease && lease.rentalRateAnnualEscalationTerm) {
          termStatus = lease.rentalRateAnnualEscalationTerm.termStatus;
        }
        break;
      case models.LeaseTermType.TenantImprovements:
        if (lease && lease.tenantImprovementsTerm) {
          termStatus = lease.tenantImprovementsTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Hvac:
        if (lease && lease.hvacTerm) {
          termStatus = lease.hvacTerm.termStatus;
        }
        break;
      case models.LeaseTermType.RenewalOption:
        if (lease && lease.renewalOptionTerm) {
          termStatus = lease.renewalOptionTerm.termStatus;
        }
        break;
      case models.LeaseTermType.TerminationOption:
        if (lease && lease.terminationOptionTerm) {
          termStatus = lease.terminationOptionTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Insurance:
        if (lease && lease.insuranceTerm) {
          termStatus = lease.insuranceTerm.termStatus;
        }
        break;
      case models.LeaseTermType.HoldoverProvision:
        if (lease && lease.holdoverProvisionTerm) {
          termStatus = lease.holdoverProvisionTerm.termStatus;
        }
        break;
      case models.LeaseTermType.ForceMajeure:
        if (lease && lease.forceMajeureTerm) {
          termStatus = lease.forceMajeureTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Parking:
        if (lease && lease.parkingTerm) {
          termStatus = lease.parkingTerm.termStatus;
        }
        break;
      case models.LeaseTermType.SpaceUse:
        if (lease && lease.spaceUseTerm) {
          termStatus = lease.spaceUseTerm.termStatus;
        }
        break;
      case models.LeaseTermType.ConditionOfPremises:
        if (lease && lease.conditionOfPremisesTerm) {
          termStatus = lease.conditionOfPremisesTerm.termStatus;
        }
        break;
      case models.LeaseTermType.CodeCompliance:
        if (lease && lease.codeComplianceTerm) {
          termStatus = lease.codeComplianceTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Utilities:
        if (lease && lease.utilitiesTerm) {
          termStatus = lease.utilitiesTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Signage:
        if (lease && lease.signageTerm) {
          termStatus = lease.signageTerm.termStatus;
        }
        break;
      case models.LeaseTermType.NonDisturbance:
        if (lease && lease.nonDisturbanceTerm) {
          termStatus = lease.nonDisturbanceTerm.termStatus;
        }
        break;
      case models.LeaseTermType.HazardousMaterials:
        if (lease && lease.hazardousMaterialsTerm) {
          termStatus = lease.hazardousMaterialsTerm.termStatus;
        }
        break;
      case models.LeaseTermType.Rail:
        if (lease && lease.railTerm) {
          termStatus = lease.railTerm.termStatus;
        }
        break;
    }

    if (!termStatus) {
      termStatus = models.TermStatus.Draft;
    }

    return termStatus;
  }

  private _getSimpleLeaseTerm(lease: models.Lease, leaseTermType: models.LeaseTermType): models.LeaseTerm {
    if (!lease || !lease.commencementTerm || !leaseTermType) {
      return null;
    }

    const now = moment().startOf('day');

    const commencementDate = moment(lease.commencementTerm.commencement).startOf('day');
    const expirationDate = moment(lease.calculatedExpirationDate).startOf('day');

    const term = <models.LeaseTerm & {abstract: models.LeaseTerm}>{
      abstract: this._getSimpleAbstractTerm(lease, leaseTermType),
      leaseTermType: leaseTermType,
      amendmentNumber: lease.amendmentNumber,
      isCurrentTermValue: commencementDate.isSameOrBefore(now) && now.isSameOrBefore(expirationDate),
    };

    switch (leaseTermType) {
      case models.LeaseTermType.Expiration: {
        if (!lease.calculatedExpirationDate) {
          return null;
        }

        return <models.LeaseTerm>{
          ...term,
          displayValue: lease.calculatedExpirationDate.toString(),
          detailedDisplayValue: lease.calculatedExpirationDate.toString(),
        };
      }

      case models.LeaseTermType.BuildingPercentage: {
        if (!lease.buildingPercentage) {
          return null;
        }

        return <models.LeaseTerm>{
          ...term,
          displayValue: lease.buildingPercentage,
          detailedDisplayValue: lease.buildingPercentage,
        };
      }

      default:
        return null;
    }
  }

  getLeaseTerm(lease: models.Lease, leaseTermType: models.LeaseTermType): models.LeaseTerm {
    if (!lease) {
      return null;
    }

    const getTerm = () => {
      switch (leaseTermType) {
        case models.LeaseTermType.Commencement:
          return lease.commencementTerm;
        case models.LeaseTermType.Term:
          return lease.term;
        case models.LeaseTermType.LandlordMaintenance:
          return lease.landlordMaintenanceTerm;
        case models.LeaseTermType.TenantMaintenance:
          return lease.tenantMaintenanceTerm;
        case models.LeaseTermType.SelfHelp:
          return lease.selfHelpTerm;
        case models.LeaseTermType.Assignment:
          return lease.assignmentTerm;
        case models.LeaseTermType.ExpansionOption:
          return lease.expansionOptionTerm;
        case models.LeaseTermType.EstoppelCertificate:
          return lease.estoppelCertificateTerm;
        case models.LeaseTermType.BaseRentalRate:
          return lease.baseRentalRateTerm;
        case models.LeaseTermType.TenantSquareFootage:
          return lease.tenantSquareFootageTerm;
        case models.LeaseTermType.FreeRent:
          return lease.freeRentTerm;
        case models.LeaseTermType.SecurityDeposit:
          return lease.securityDepositTerm;
        case models.LeaseTermType.RealEstateTaxesCamExpenses:
          return lease.realEstateTaxesCamExpensesTerm;
        case models.LeaseTermType.RentalRateAnnualEscalation:
          return lease.rentalRateAnnualEscalationTerm;
        case models.LeaseTermType.TenantImprovements:
          return lease.tenantImprovementsTerm;
        case models.LeaseTermType.Hvac:
          return lease.hvacTerm;
        case models.LeaseTermType.RenewalOption:
          return lease.renewalOptionTerm;
        case models.LeaseTermType.TerminationOption:
          return lease.terminationOptionTerm;
        case models.LeaseTermType.Insurance:
          return lease.insuranceTerm;
        case models.LeaseTermType.HoldoverProvision:
          return lease.holdoverProvisionTerm;
        case models.LeaseTermType.ForceMajeure:
          return lease.forceMajeureTerm;
        case models.LeaseTermType.SpaceUse:
          return lease.spaceUseTerm;
        case models.LeaseTermType.Parking:
          return lease.parkingTerm;
        case models.LeaseTermType.ConditionOfPremises:
          return lease.conditionOfPremisesTerm;
        case models.LeaseTermType.CodeCompliance:
          return lease.codeComplianceTerm;
        case models.LeaseTermType.Utilities:
          return lease.utilitiesTerm;
        case models.LeaseTermType.Signage:
          return lease.signageTerm;
        case models.LeaseTermType.NonDisturbance:
          return lease.nonDisturbanceTerm;
        case models.LeaseTermType.HazardousMaterials:
          return lease.hazardousMaterialsTerm;
        case models.LeaseTermType.Rail:
          return lease.railTerm;
        default: {
          switch (leaseTermType) {
            case models.LeaseTermType.Expiration:
            case models.LeaseTermType.BuildingPercentage:
              return this._getSimpleLeaseTerm(lease, leaseTermType);
            default:
              return null;
          }
        }
      }
    };

    const term = getTerm();
    if (!term) {
      return null;
    }

    return <models.LeaseTerm>{
      ...term,
      lease: lease,
    };
  }

  getCurrentTerm(term: models.Term): string {
    if (!term) {
      return 'N/A';
    }

    switch (term.termType) {
      case models.TermTypeEnum.MultiplyOptions: {
        if (!term.leaseTermOptions || !term.leaseTermOptions.options) {
          return 'N/A';
        }

        if (term.leaseTermOptions.selectedLeaseTerm) {
          return this.getCurrentTerm(term.leaseTermOptions.selectedLeaseTerm);
        }

        const result: Array<string> = [];

        const optionsCount = term.leaseTermOptions.options.length;
        for (let i = 0; i < optionsCount; i++) {
          const optionName = this._alphabet[i];

          result.push(`OPTION ${optionName.toUpperCase()}: ${this.getCurrentTerm(term.leaseTermOptions.options[i])}`);
        }

        return result.join('\n');
      }

      default: {
        const termNumber = this.getCurrentTermNumber(term);
        if (!termNumber) {
          return 'N/A';
        }

        const monthsSpelling = termNumber === 1 ? 'month' : 'months';

        return `${termNumber} ${monthsSpelling}`;
      }
    }
  }

  getCurrentTermNumber(term: models.Term): number {
    if (!term) {
      return 0;
    }

    if (term.termType !== models.TermTypeEnum.MultiplyOptions) {
      return term.termValue;
    }

    if (!term.leaseTermOptions) {
      return 0;
    }

    return this.getCurrentTermNumber(term.leaseTermOptions.selectedLeaseTerm);
  }

  getCurrentFreeRent(freeRentTerm: models.FreeRentTerm): string {
    if (!freeRentTerm) {
      return 'N/A';
    }

    switch (freeRentTerm.freeRentTermType) {
      case models.FreeRentTermType.None: {
        return 'None';
      }

      case models.FreeRentTermType.MultiplyOptions: {
        if (!freeRentTerm.leaseTermOptions || !freeRentTerm.leaseTermOptions.options) {
          return 'N/A';
        }

        if (freeRentTerm.leaseTermOptions.selectedLeaseTerm) {
          return this.getCurrentFreeRent(freeRentTerm.leaseTermOptions.selectedLeaseTerm);
        }

        if (freeRentTerm.leaseTermOptions.options.every(x => this.getCurrentFreeRent(x) === 'N/A')) {
          return 'N/A';
        }

        const result: Array<string> = [];

        const optionsCount = freeRentTerm.leaseTermOptions.options.length;
        for (let i = 0; i < optionsCount; i++) {
          const optionName = this._alphabet[i];

          result.push(
            `OPTION ${optionName.toUpperCase()}: ` +
            this.getCurrentFreeRent(freeRentTerm.leaseTermOptions.options[i])
          );
        }

        return result.join('\n');
      }

      case models.FreeRentTermType.MonthsCount: {
        const freeRent = this.getCurrentFreeRentNumber(freeRentTerm);
        if (!freeRent) {
          return 'N/A';
        }

        let taxesType: string;
        switch (freeRentTerm.freeRentTaxesType) {
          case models.FreeRentTaxesType.Net:
            taxesType = 'net';
            break;

          case models.FreeRentTaxesType.Gross:
            taxesType = 'gross';
            break;
        }

        const monthsSpelling = freeRent === 1 ? 'month' : 'months';

        return `${freeRent} ${monthsSpelling} ${taxesType}`;
      }

      case models.FreeRentTermType.SpecificSchedule: {
        if (!freeRentTerm.specificMonths || !freeRentTerm.specificMonths.length) {
          return 'N/A';
        }

        let taxesType: string;
        switch (freeRentTerm.freeRentTaxesType) {
          case models.FreeRentTaxesType.Net:
            taxesType = 'net';
            break;

          case models.FreeRentTaxesType.Gross:
            taxesType = 'gross';
            break;
        }

        return `Months ${freeRentTerm.specificMonths.join(', ')} ${taxesType}`;
      }

      default: {
        return 'N/A';
      }
    }
  }

  getCurrentFreeRentNumber(freeRentTerm: models.FreeRentTerm): number {
    if (!freeRentTerm) {
      return 0;
    }

    if (freeRentTerm.freeRentTermType === models.FreeRentTermType.MonthsCount) {
      return freeRentTerm.freeRentMonthsCount;
    }

    if (
      freeRentTerm.freeRentTermType === models.FreeRentTermType.MultiplyOptions &&
      freeRentTerm.leaseTermOptions &&
      freeRentTerm.leaseTermOptions.selectedLeaseTerm
    ) {
      return this.getCurrentFreeRentNumber(freeRentTerm.leaseTermOptions.selectedLeaseTerm);
    }

    return 0;
  }

  getCurrentTenantImprovements(tenantImprovements: models.TenantImprovements): string {
    if (!tenantImprovements) {
      return 'N/A';
    }

    switch (tenantImprovements.tenantImprovementsType) {
      case models.TenantImprovementsType.AsIs:
        return 'As-Is';

      case models.TenantImprovementsType.TurnKey:
        return 'Turnkey';

      case models.TenantImprovementsType.FloorPlanRequested:
        return 'Floor plan requested';

      case models.TenantImprovementsType.Custom:
        return tenantImprovements.tenantImprovementsCustom;

      case models.TenantImprovementsType.CustomAmortized:
        return this._commonTransformer.transformCurrencySafe(tenantImprovements.tenantImprovementsAmortized);

      case models.TenantImprovementsType.DollarAllowance:
        return this._commonTransformer.transformCurrencySafe(tenantImprovements.tenantImprovementsValue);

      default:
        return 'N/A';
    }
  }

  getCurrentTenantImprovementsNumber(tenantImprovements: models.TenantImprovements): number {
    if (!tenantImprovements) {
      return 0;
    }

    switch (tenantImprovements.tenantImprovementsType) {
      case models.TenantImprovementsType.CustomAmortized:
        return tenantImprovements.tenantImprovementsAmortized;

      case models.TenantImprovementsType.DollarAllowance:
        return tenantImprovements.tenantImprovementsValue;

      default:
        return 0;
    }
  }

  getCurrentTenantSquareFootage(tenantSquareFootageTerm: models.TenantSquareFootageTerm): string {
    if (!tenantSquareFootageTerm) {
      return 'N/A';
    }

    if (tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions) {
      if (!tenantSquareFootageTerm.leaseTermOptions || !tenantSquareFootageTerm.leaseTermOptions.options) {
        return 'N/A';
      }

      if (tenantSquareFootageTerm.leaseTermOptions.selectedLeaseTerm) {
        return this.getCurrentTenantSquareFootage(tenantSquareFootageTerm.leaseTermOptions.selectedLeaseTerm);
      }

      if (tenantSquareFootageTerm.leaseTermOptions.options
            .every(x => this.getCurrentTenantSquareFootage(x) === 'N/A')) {
        return 'N/A';
      }

      const result: Array<string> = [];

      const optionsCount = tenantSquareFootageTerm.leaseTermOptions.options.length;
      for (let i = 0; i < optionsCount; i++) {
        const optionName = this._alphabet[i];
        const option = tenantSquareFootageTerm.leaseTermOptions.options[i];

        let optionSF = this.getCurrentTenantSquareFootage(option);
        if (option.tenantSquareFootageTermType === models.TenantSquareFootageTermType.ExistingSquareFootage) {
          optionSF = `Existing Square Footage - ${optionSF}`;
        }

        result.push(`OPTION ${optionName.toUpperCase()}: ${optionSF}`);
      }

      return result.join('\n');
    }

    const sf = this.getCurrentTenantSquareFootageNumber(tenantSquareFootageTerm);

    return `${this._commonTransformer.transformNumberSafe(sf)} SF`;
  }

  getCurrentTenantSquareFootageNumber(tenantSquareFootageTerm: models.TenantSquareFootageTerm): number {
    if (!tenantSquareFootageTerm) {
      return 0;
    }

    if (
      tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions &&
      tenantSquareFootageTerm.leaseTermOptions
    ) {
      if (tenantSquareFootageTerm.leaseTermOptions.selectedLeaseTerm) {
        return this.getCurrentTenantSquareFootageNumber(tenantSquareFootageTerm.leaseTermOptions.selectedLeaseTerm);
      }

      const sortedOptions = tenantSquareFootageTerm.leaseTermOptions.options
        .map(x => this.getCurrentTenantSquareFootageNumber(x))
        .sort((prev, curr) => curr - prev);

      if (sortedOptions && sortedOptions.length) {
        return sortedOptions[0];
      }

      return 0;
    }

    let sf = 0;
    switch (tenantSquareFootageTerm.tenantSquareFootageTermType) {
      case models.TenantSquareFootageTermType.ExistingSquareFootage:
        if (tenantSquareFootageTerm.abstract) {
          sf = tenantSquareFootageTerm.abstract.tenantSquareFootageCustomValue;
        } else {
          sf = tenantSquareFootageTerm.tenantSquareFootageAbstractValue;
        }
        break;
      case models.TenantSquareFootageTermType.Custom:
        sf = tenantSquareFootageTerm.tenantSquareFootageCustomValue;
        break;
      case models.TenantSquareFootageTermType.PhaseIn:
        const sortedPhaseInResults = (tenantSquareFootageTerm.tenantSquareFootagePhaseInResults || [])
          .sort((prev, curr) => curr.totalSf - prev.totalSf);

        const firstPhaseInResult = sortedPhaseInResults[0];
        if (firstPhaseInResult) {
          sf = firstPhaseInResult.totalSf;
        }
        break;
    }

    if (!sf) {
      return 0;
    }

    return sf;
  }

  getCurrentRentalRate(baseRentalRate: models.BaseRentalRate): string {
    if (!baseRentalRate) {
      return 'N/A';
    }

    if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions) {
      if (!baseRentalRate.leaseTermOptions || !baseRentalRate.leaseTermOptions.options) {
        return 'N/A';
      }

      if (baseRentalRate.leaseTermOptions.selectedLeaseTerm) {
        return this.getCurrentRentalRate(baseRentalRate.leaseTermOptions.selectedLeaseTerm);
      }

      if (baseRentalRate.leaseTermOptions.options.every(x => this.getCurrentRentalRate(x) === 'N/A')) {
        return 'N/A';
      }

      const result: Array<string> = [];

      const optionsCount = baseRentalRate.leaseTermOptions.options.length;
      for (let i = 0; i < optionsCount; i++) {
        const optionName = this._alphabet[i];

        result.push(
          `OPTION ${optionName.toUpperCase()}: ` +
          this.getCurrentRentalRate(baseRentalRate.leaseTermOptions.options[i])
        );
      }

      return result.join('\n');
    }

    let currentRentalRate = -1;
    let currentRentalRateType = '';

    switch (baseRentalRate.baseRentalRateType) {
      case models.BaseRentalRateType.Gross:
        currentRentalRate = baseRentalRate.baseRentalRateGrossValue;
        currentRentalRateType = 'Gross';
        break;
      case models.BaseRentalRateType.Net:
        currentRentalRate = baseRentalRate.baseRentalRateNetValue;
        currentRentalRateType = 'Net';
        break;
      case models.BaseRentalRateType.BaseYear:
        currentRentalRate = baseRentalRate.actualYearRate;
        currentRentalRateType = 'Modified Gross';
        break;
    }

    if (currentRentalRate === -1) {
      currentRentalRateType = '';

      if (CommonTools.isNumeric(baseRentalRate.abstractValue)) {
        currentRentalRate = parseInt(baseRentalRate.abstractValue, 10);
      } else {
        return this.getCurrentRentalRate(baseRentalRate.abstract);
      }
    }

    const unitMetrics = this.getBaseRentalRateUnitMetricsDisplayValue(baseRentalRate);

    return [
        `$${this._commonTransformer.transformNumberSafe(currentRentalRate, '1.2-2')}`,
        unitMetrics,
        currentRentalRateType,
      ]
      .filter(Boolean)
      .join(' ');
  }

  getCurrentRentalRateNumber(baseRentalRate: models.BaseRentalRate): number {
    if (!baseRentalRate) {
      return 0;
    }

    if (
      baseRentalRate.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      baseRentalRate.leaseTermOptions &&
      baseRentalRate.leaseTermOptions.selectedLeaseTerm
    ) {
      return this.getCurrentRentalRateNumber(baseRentalRate.leaseTermOptions.selectedLeaseTerm);
    }

    let currentRentalRate = -1;

    switch (baseRentalRate.baseRentalRateType) {
      case models.BaseRentalRateType.Gross:
        currentRentalRate = baseRentalRate.baseRentalRateGrossValue;
        break;
      case models.BaseRentalRateType.Net:
        currentRentalRate = baseRentalRate.baseRentalRateNetValue;
        break;
      case models.BaseRentalRateType.BaseYear:
        currentRentalRate = baseRentalRate.actualYearRate;
        break;
    }

    if (currentRentalRate === -1) {
      if (CommonTools.isNumeric(baseRentalRate.abstractValue)) {
        currentRentalRate = parseInt(baseRentalRate.abstractValue, 10);
      } else {
        return this.getCurrentRentalRateNumber(baseRentalRate.abstract);
      }
    }

    if (0 < currentRentalRate &&
        baseRentalRate.baseRentalRateUnitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      currentRentalRate = currentRentalRate * 12;
    }

    return currentRentalRate;
  }

  getBaseRentalRateUnitMetrics(baseRentalRateTermViewModel: models.BaseRentalRate): models.BaseRentalRateUnitMetrics {
    let unitMetrics = models.BaseRentalRateUnitMetrics.PsfPerYear;
    if (!baseRentalRateTermViewModel) {
      return unitMetrics;
    }

    if (baseRentalRateTermViewModel.baseRentalRateUnitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = models.BaseRentalRateUnitMetrics.PsfPerMonth;
    }

    if (
      baseRentalRateTermViewModel.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      baseRentalRateTermViewModel.leaseTermOptions &&
      baseRentalRateTermViewModel.leaseTermOptions.options &&
      baseRentalRateTermViewModel.leaseTermOptions.options.length
    ) {
      unitMetrics = this.getBaseRentalRateUnitMetrics(baseRentalRateTermViewModel.leaseTermOptions.options[0]);
    }

    return unitMetrics;
  }

  getBaseRentalRateUnitMetricsDisplayValue(baseRentalRateTermViewModel: models.BaseRentalRate): string {
    let displayValue = 'PSF/Yr';
    if (!baseRentalRateTermViewModel) {
      return displayValue;
    }

    const unitMetrics = this.getBaseRentalRateUnitMetrics(baseRentalRateTermViewModel);
    if (unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      displayValue = 'PSF/Mo';
    }

    return displayValue;
  }

  getBaseRentalRateSchedulePreview(term: models.LeaseTerm): Array<models.BaseRentalScheduleRow> {
    const isBaseRentalRate = this.isBaseRentalRate(term);
    if (!isBaseRentalRate) {
      return [];
    }

    return (<models.BaseRentalRate>term).schedule.rows;
  }

  getBaseRentalRateScheduleCustomTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    const isBaseRentalRate = this.isBaseRentalRate(term);
    if (!isBaseRentalRate) {
      return null;
    }

    return (<models.BaseRentalRate>term).leaseTermCustomTable;
  }

  getSecurityDepositBurnDownCustomTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    const isBurnDownOption = this.isBurnDownOption(term);
    if (!isBurnDownOption) {
      return null;
    }

    return (<models.SecurityDepositTerm>term).leaseTermCustomTable;
  }

  getTenantSquareFootagePhaseInCustomTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    const isPhaseInValue = this.isTenantSquareFootagePhaseInTermValue(term);
    if (!isPhaseInValue) {
      return null;
    }

    return (<models.TenantSquareFootageTerm>term).leaseTermCustomTable;
  }

  getTenantSquareFootagePhaseInResults(term: models.LeaseTerm): Array<models.TenantSquareFootagePhaseInResult> {
    const isPhaseInValue = this.isTenantSquareFootagePhaseInTermValue(term);
    if (!isPhaseInValue) {
      return [];
    }

    return (<models.TenantSquareFootageTerm>term).tenantSquareFootagePhaseInResults;
  }

  getRentalRateEscalationCustomTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    const isRentalRate = this.isRentalRateEscalationCustomOption(term);
    if (!isRentalRate) {
      return null;
    }

    return (<models.RentalRateAnnualEscalationTerm>term).leaseTermCustomTable;
  }

  getRealEstateTaxesCustomTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    return (<models.RealEstateTaxesCamExpenses>term).leaseTermCustomTable;
  }

  getMultipleOptionsCustomTable(lease: models.Lease): models.LeaseTermCustomTable {
    const customTable = <models.LeaseTermCustomTable>{};

    customTable.tableName = 'Compare Options';

    const optionsLength = lease.term.leaseTermOptions.options.length;

    customTable.headerRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: ''
        },
      ],
    };

    customTable.rows = [];

    // header
    lease.term.leaseTermOptions.options.forEach((value, index) => {
      const name = this.getLeaseTermOptionName(index);
      customTable.headerRow.cells.push(<models.LeaseTermCustomCell>{displayValue: name});
    });

    const termRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Term'
        },
      ],
    };

    const tenantSquareFootageRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Size',
        },
      ],
    };

    const baseRentalRateRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Base Rental Rate',
        },
      ],
    };

    const freeRentRateRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Free Rent',
        },
      ],
    };

    const rentalRateEscalationRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Rental Rate Escalation',
        },
      ],
    };

    const terminationOptionRow = <models.LeaseTermCustomRow>{
      cells: [
        <models.LeaseTermCustomCell>{
          displayValue: 'Termination Option',
        },
      ],
    };

    for (let i = 0; i < optionsLength; i++) {
      if (lease.term.leaseTermOptions) {
        const termCell = this._getCellFromOptions(lease.term.leaseTermOptions.options, i);
        if (termCell) {
          termRow.cells.push(termCell);
        }
      }

      if (lease.tenantSquareFootageTerm.leaseTermOptions) {
        const termCell = this._getCellFromOptions(lease.tenantSquareFootageTerm.leaseTermOptions.options, i);
        if (termCell) {
          tenantSquareFootageRow.cells.push(termCell);
        }
      }

      if (lease.baseRentalRateTerm.leaseTermOptions) {
        const cell = this._getCellFromOptions(lease.baseRentalRateTerm.leaseTermOptions.options, i);
        if (cell) {
          baseRentalRateRow.cells.push(cell);
        }
      }

      if (lease.freeRentTerm.leaseTermOptions) {
        const cell = this._getCellFromOptions(lease.freeRentTerm.leaseTermOptions.options, i);
        if (cell) {
          freeRentRateRow.cells.push(cell);
        }
      }

      if (lease.rentalRateAnnualEscalationTerm.leaseTermOptions) {
        const cell = this._getCellFromOptions(lease.rentalRateAnnualEscalationTerm.leaseTermOptions.options, i);
        if (cell) {
          rentalRateEscalationRow.cells.push(cell);
        }
      }

      if (lease.terminationOptionTerm.leaseTermOptions) {
        const cell = this._getCellFromOptions(lease.terminationOptionTerm.leaseTermOptions.options, i);
        if (cell) {
          terminationOptionRow.cells.push(cell);
        }
      }
    }

    customTable.rows.push(termRow);
    customTable.rows.push(tenantSquareFootageRow);
    customTable.rows.push(baseRentalRateRow);

    if (lease.freeRentTerm && lease.freeRentTerm.isElectedForNegotiation) {
      customTable.rows.push(freeRentRateRow);
    }

    customTable.rows.push(rentalRateEscalationRow);

    if (lease.terminationOptionTerm && lease.terminationOptionTerm.isElectedForNegotiation) {
      customTable.rows.push(terminationOptionRow);
    }

    return customTable;
  }

  getRentalRateEscalationCustomOption(
    term: models.LeaseTerm,
  ): Array<models.RentalRateAnnualEscalationTermCustomResult> {
    const isCustomEscalation = this.isRentalRateEscalationCustomOption(term);
    if (!isCustomEscalation) {
      return [];
    }

    return (<models.RentalRateAnnualEscalationTerm>term).rentalRateAnnualEscalationTermCustomResults;
  }

  getRealEstateTaxesBreakdownTable(term: models.LeaseTerm): models.LeaseTermCustomTable {
    const isRealEstateTaxesBreakdownTable = this.isRealEstateTaxesBreakdownTable(term);
    if (!isRealEstateTaxesBreakdownTable) {
      return null;
    }

    return (<models.RealEstateTaxesCamExpenses>term).leaseTermCustomTable;
  }

  isTenantSquareFootagePhaseInTermValue(term: models.LeaseTerm): boolean {
    return (
      term &&
      term.leaseTermType === models.LeaseTermType.TenantSquareFootage &&
      (
        (
          (<models.TenantSquareFootageTerm>term).tenantSquareFootageTermType ===
          models.TenantSquareFootageTermType.PhaseIn
        ) &&
        (<models.TenantSquareFootageTerm>term).tenantSquareFootagePhaseInResults &&
        0 < (<models.TenantSquareFootageTerm>term).tenantSquareFootagePhaseInResults.length
      )
    );
  }

  isRentalRateEscalationCustomOption(term: models.LeaseTerm): boolean {
    return (
      term &&
      this.isRentalRateEscalationTerm(term) &&
      (
        term.escalationTermType === models.EscalationTermType.Custom &&
        term.rentalRateAnnualEscalationTermCustomResults &&
        0 < term.rentalRateAnnualEscalationTermCustomResults.length
      )
    );
  }

  isBaseRentalRate(term: models.LeaseTerm): boolean {
    return (
      term &&
      term.leaseTermType === models.LeaseTermType.BaseRentalRate &&
      (
        (<models.BaseRentalRate>term).schedule &&
        (<models.BaseRentalRate>term).schedule.rows &&
        0 < (<models.BaseRentalRate>term).schedule.rows.length
      )
    );
  }

  isBurnDownOption(term: models.LeaseTerm): boolean {
    return (
      term &&
      term.leaseTermType === models.LeaseTermType.SecurityDeposit &&
      (
        (<models.SecurityDepositTerm>term).hasBurnDownScheduleValues &&
        (<models.SecurityDepositTerm>term).burnDownScheduleValues &&
        0 < (<models.SecurityDepositTerm>term).burnDownScheduleValues.length
      )
    );
  }

  isRealEstateTaxesBreakdownTable(term: models.LeaseTerm): boolean {
    return term && term.leaseTermType === models.LeaseTermType.RealEstateTaxesCamExpenses &&
      (<models.RealEstateTaxesCamExpenses>term).leaseTermCustomTable &&
      (<models.RealEstateTaxesCamExpenses>term).leaseTermCustomTable.rows &&
      (<models.RealEstateTaxesCamExpenses>term).leaseTermCustomTable.rows.length > 0;
  }

  getLeaseTermOptionName(index: number): string {
    return this._alphabet[index].toUpperCase();
  }

  canGetMultipleOptionsCustomTable(lease: models.Lease): boolean {
    return (
      lease &&
      lease.term &&
      lease.term.leaseTermOptions &&
      lease.term.leaseTermOptions.options &&
      !!lease.term.leaseTermOptions.options.length
    );
  }

  canInsertNewOption(term: models.Term, project: models.Project): boolean {
    if (!term || !project || !project.projectState) {
      return false;
    }

    const {
      SendRfp,
      ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
      UnsolicitedOfferByLandlord
    } = models.RenewalProjectTemplateItemType;

    return (
      term.termType === models.TermTypeEnum.MultiplyOptions &&
      (
        project.projectState.renewalProjectTemplateItemType === SendRfp ||
        project.projectState.renewalProjectTemplateItemType === ReviewTenantImprovementsSelectMultiplyOptionsByLandlord ||
        project.projectState.renewalProjectTemplateItemType === UnsolicitedOfferByLandlord
      )
    );
  }

  isMultipleOptionsTerm(leaseTermConfiguration: models.LeaseTermConfiguration): boolean {
    return (
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.TenantSquareFootage ||
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.Term ||
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.BaseRentalRate ||
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.RentalRateAnnualEscalation ||
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.FreeRent ||
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.TerminationOption
    );
  }

  isShowMultiplyOptionsForTenant(term: models.Term, project: models.Project): boolean {
    if (!term || !project || !project.projectState) {
      return false;
    }

    const { renewalProjectTemplateItemType } = project.projectState;

    return (
      (
        term.termType === models.TermTypeEnum.MultiplyOptions ||
        term.termType === models.TermTypeEnum.MultipleOptionsWithCustomValue
      ) &&
      (
        (
          renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
        ) ||
        renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
      )
    );
  }

  // TODO: Move to the term-length/term-term manager
  isShowMultiplyOptionsForTenantStepExceptTenantCounterUnsolicitedOffer(
    term: models.Term,
    project: models.Project
  ): boolean {
    if (!term || !project || !project.projectState) {
      return false;
    }

    const { renewalProjectTemplateItemType } = project.projectState;

    return (
      (
        renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      ) &&
      (
        term.termType === models.TermTypeEnum.MultiplyOptions ||
        term.termType === models.TermTypeEnum.MultipleOptionsWithCustomValue
      )
    );
  }

  getLeaseTermConfigurations(
    termConfigurations: Array<models.LeaseTermConfiguration>,
    project: models.Project,
    lease: models.Lease,
    electedTermsOnly: boolean = false
  ): Array<models.LeaseTermConfiguration> {
    if (project && project.projectType) {
      termConfigurations = termConfigurations
        .filter(tc => project.projectType.leaseTermTypes.some(ltt => ltt === tc.leaseTermType));
    }

    termConfigurations = termConfigurations
      .filter(conf => conf.isUsedInTermPage);

    if (!electedTermsOnly) {
      return termConfigurations;
    }

    if (!termConfigurations || !termConfigurations.length) {
      return termConfigurations;
    }

    const electedTermConfigurations = [];

    for (let i = 0, num = termConfigurations.length; i < num; i++) {
      const termConfiguration = termConfigurations[i];

      const term = this.getLeaseTerm(lease, termConfiguration.leaseTermType);
      if (term && term.isElectedForNegotiation) {
        electedTermConfigurations.push(termConfiguration);
      }
    }

    return electedTermConfigurations;
  }

  getUniqueTermHistoryValues(
    leasesWithRole: Array<models.LeaseAndTermModel>,
    leaseTermConfiguration: models.LeaseTermConfiguration,
  ): Array<models.TermHistoryModel> {
    const termHistoryModels: Array<models.TermHistoryModel> = [];
    // If it has a equal values in a row then just add first one.
    leasesWithRole.reduce<models.LeaseAndTermModel>((previousValue, currentLease) => {
      const { termValue } = currentLease;

      // custom escalation table
      if (this.isCustomEscalationTable(leaseTermConfiguration, currentLease)) {

        if (!previousValue || !previousValue.lease || !previousValue.lease.rentalRateAnnualEscalationTerm) {
          termHistoryModels.push(
            {
              uniqueValue: termValue,
              lease: currentLease.lease,
              role: currentLease.role,
              createdOn: currentLease.createdOn,
            },
          );
          return currentLease;
        }

        const hasntDuplications = this.hasntDupsRentalRateAnnualEscalation(
          currentLease.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues,
          previousValue.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues,
        );

        if (hasntDuplications) {
          const areEscalationDatesChanged = this.areEscalationDatesChanged(
            currentLease.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomResults,
            previousValue.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomResults,
          );

          if (areEscalationDatesChanged && termHistoryModels[termHistoryModels.length - 1]) {
            termHistoryModels[termHistoryModels.length - 1] = {
              ...termHistoryModels[termHistoryModels.length - 1],
              lease: currentLease.lease,
            };

            return currentLease;
          }

          termHistoryModels.push(
            {
              uniqueValue: termValue,
              lease: currentLease.lease,
              role: currentLease.role,
              createdOn: currentLease.createdOn,
            },
          );
        }

        return currentLease;
      }

      // phase in table
      if (
        leaseTermConfiguration.leaseTermType === models.LeaseTermType.TenantSquareFootage &&
        currentLease && currentLease.lease && currentLease.lease.tenantSquareFootageTerm &&
        currentLease.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn
      ) {
        if (!previousValue || !previousValue.lease || !previousValue.lease.tenantSquareFootageTerm) {
          termHistoryModels.push(
            {
              uniqueValue: termValue,
              lease: currentLease.lease,
              role: currentLease.role,
              createdOn: currentLease.createdOn,
            },
          );

          return currentLease;
        }

        const hasntDuplications = this.hasntDupsTenantSquareFootageCustomTable(
          currentLease.lease.tenantSquareFootageTerm.tenantSquareFootagePhaseInValues,
          previousValue.lease.tenantSquareFootageTerm.tenantSquareFootagePhaseInValues,
        );

        if (hasntDuplications) {
          const arePhaseInDatesChanged = this.areTenantSquareFootagePhaseInDatesChanged(
            currentLease.lease.tenantSquareFootageTerm.tenantSquareFootagePhaseInResults,
            previousValue.lease.tenantSquareFootageTerm.tenantSquareFootagePhaseInResults,
          );

          if (arePhaseInDatesChanged && termHistoryModels[termHistoryModels.length - 1]) {
            termHistoryModels[termHistoryModels.length - 1] = {
              ...termHistoryModels[termHistoryModels.length - 1],
              lease: currentLease.lease,
            };

            return currentLease;
          }

          termHistoryModels.push({
            uniqueValue: termValue,
            lease: currentLease.lease,
            role: currentLease.role,
            createdOn: currentLease.createdOn,
          });

          return currentLease;
        }

        return currentLease;
      }

      // burn down security deposit table
      if (this.isBurnDownScheduleTable(leaseTermConfiguration, currentLease)) {
        if (!previousValue || !previousValue.lease || !previousValue.lease.securityDepositTerm) {
          termHistoryModels.push(
            {
              uniqueValue: termValue,
              lease: currentLease.lease,
              role: currentLease.role,
              createdOn: currentLease.createdOn,
            });
          return currentLease;
        }

        const hasntDuplications = this.hasntDupsBurnDownSchedule(
          currentLease.lease.securityDepositTerm.burnDownScheduleValues,
          previousValue.lease.securityDepositTerm.burnDownScheduleValues,
        );

        if (hasntDuplications) {
          termHistoryModels.push({
            uniqueValue: termValue,
            lease: currentLease.lease,
            role: currentLease.role,
            createdOn: currentLease.createdOn,
          });
        }

        return currentLease;
      }

      if (termValue === '') {
        return currentLease;
      }

      if (
        previousValue &&
        currentLease &&
        leaseTermConfiguration &&

        previousValue.lease && currentLease.lease &&
        previousValue.lease.term && currentLease.lease.term &&
        previousValue.lease.term.termType === currentLease.lease.term.termType &&
        previousValue.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&

        previousValue.termValue === termValue &&
        previousValue.role !== currentLease.role &&

        (
          leaseTermConfiguration.leaseTermType === models.LeaseTermType.Term ||
          leaseTermConfiguration.leaseTermType === models.LeaseTermType.TenantSquareFootage
        )
      ) {
        const indexOfPreviousTermHistoryModel = termHistoryModels
          .findIndex(x =>
            x.uniqueValue === previousValue.termValue &&
            x.role === previousValue.role &&
            x.createdOn === previousValue.createdOn
          );

        if (0 <= indexOfPreviousTermHistoryModel) {
          termHistoryModels[indexOfPreviousTermHistoryModel].shouldShowAcceptanceMark = true;
        }
      }

      if (!previousValue || !previousValue.termValue || previousValue.termValue !== termValue) {
        termHistoryModels.push(
          {
            uniqueValue: termValue,
            lease: currentLease.lease,
            role: currentLease.role,
            createdOn: currentLease.createdOn,
          },
        );
      }

      return currentLease;
    }, null);

    return termHistoryModels;
  }

  isPhaseInTable(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    currentLease: models.LeaseAndTermModel,
  ) {
    return (
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.TenantSquareFootage &&
      currentLease &&
      currentLease.lease &&
      currentLease.lease.tenantSquareFootageTerm &&
      currentLease.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn
    );
  }

  isCustomEscalationTable(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    currentLease: models.LeaseAndTermModel,
  ) {
    return (
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.RentalRateAnnualEscalation &&
      currentLease &&
      currentLease.lease &&
      currentLease.lease.rentalRateAnnualEscalationTerm &&
      currentLease.lease.rentalRateAnnualEscalationTerm.escalationTermType === models.EscalationTermType.Custom
    );
  }

  isBurnDownScheduleTable(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    currentLease: models.LeaseAndTermModel,
  ) {
    return (
      leaseTermConfiguration.leaseTermType === models.LeaseTermType.SecurityDeposit &&
      currentLease &&
      currentLease.lease &&
      currentLease.lease.securityDepositTerm &&
      currentLease.lease.securityDepositTerm.hasBurnDownScheduleValues
    );
  }

  getTermStatus(lease: models.Lease, leaseTermConfiguration: models.LeaseTermConfiguration): models.TermStatus {
    if (!lease || !leaseTermConfiguration) {
      return null;
    }

    const term = this.getLeaseTerm(lease, leaseTermConfiguration.leaseTermType);
    if (!term) {
      return null;
    }

    return term.termStatus;
  }

  isCustomRentalRateEscalation(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    lease: models.Lease,
  ): boolean {
    if (!lease || !lease.rentalRateAnnualEscalationTerm ||
      leaseTermConfiguration.leaseTermType !== models.LeaseTermType.RentalRateAnnualEscalation) {
      return false;
    }
    const termType = (<models.RentalRateAnnualEscalationTerm>lease.rentalRateAnnualEscalationTerm).escalationTermType;
    return termType === models.EscalationTermType.Custom;
  }

  isTenantSquareFootagePhaseInTable(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    lease: models.Lease,
  ): boolean {
    if (!lease || !lease.tenantSquareFootageTerm ||
      leaseTermConfiguration.leaseTermType !== models.LeaseTermType.TenantSquareFootage) {
      return false;
    }

    const termType = (<models.TenantSquareFootageTerm>lease.tenantSquareFootageTerm).tenantSquareFootageTermType;
    return termType === models.TenantSquareFootageTermType.PhaseIn;
  }

  isSecurityDepositBurnDownTable(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    lease: models.Lease,
  ): boolean {
    if (!lease || !lease.securityDepositTerm ||
      leaseTermConfiguration.leaseTermType !== models.LeaseTermType.SecurityDeposit) {
      return false;
    }
    return lease.securityDepositTerm.hasBurnDownScheduleValues;
  }

  hasntDupsBurnDownSchedule(
    currentValues: Array<models.BurnDownSchedule>,
    previousValues: Array<models.BurnDownSchedule>,
  ) {
    if (currentValues == null || previousValues == null) {
      return currentValues !== previousValues;
    }

    if (currentValues.length !== previousValues.length) {
      return true;
    }

    for (let i = 0; i < currentValues.length; i++) {
      const currValue = currentValues[i];
      const prevValue = previousValues[i];
      if (
        currValue.dateOfChange !== prevValue.dateOfChange ||
        currValue.dollarAmount !== prevValue.dollarAmount
      ) {
        return true;
      }
    }
    return false;
  }

  hasntDupsRentalRateAnnualEscalation(
    currentValues: Array<models.RentalRateAnnualEscalationTermCustomValue>,
    previousValues: Array<models.RentalRateAnnualEscalationTermCustomValue>,
  ): boolean {
    if (currentValues == null || previousValues == null) {
      return currentValues !== previousValues;
    }

    if (currentValues.length !== previousValues.length) {
      return true;
    }

    for (let i = 0; i < currentValues.length; i++) {
      const currValue = currentValues[i];
      const prevValue = previousValues[i];

      if (
        currValue.leaseMonth !== prevValue.leaseMonth ||
        currValue.stepIncreasePercentage !== prevValue.stepIncreasePercentage ||
        currValue.stepIncreasePsfValue !== prevValue.stepIncreasePsfValue ||
        currValue.rentalRateAnnualEscalationTermCustomRepeatType !== prevValue.rentalRateAnnualEscalationTermCustomRepeatType ||
        currValue.rentalRateAnnualEscalationTermCustomValueType !== prevValue.rentalRateAnnualEscalationTermCustomValueType ||
        currValue.repeatLeaseMonth !== prevValue.repeatLeaseMonth
      ) {
        return true;
      }
    }

    return false;
  }

  hasntDupsTenantSquareFootageCustomTable(
    currentValues: Array<models.TenantSquareFootagePhaseInValue>,
    previousValues: Array<models.TenantSquareFootagePhaseInValue>,
  ): boolean {
    if (currentValues == null || previousValues == null) {
      return currentValues !== previousValues;
    }

    if (currentValues.length !== previousValues.length) {
      return true;
    }

    for (let i = 0; i < currentValues.length; i++) {
      const currValue = currentValues[i];
      const prevValue = previousValues[i];

      if (
        (!currValue.leaseMonth || !prevValue.leaseMonth) ||
        (!currValue.totalSfOccupied || !prevValue.totalSfOccupied) ||
        (!currValue.repeatType || !prevValue.repeatType) ||
        currValue.leaseMonth !== prevValue.leaseMonth ||
        currValue.totalSfOccupied !== prevValue.totalSfOccupied ||
        currValue.repeatType !== prevValue.repeatType
      ) {
        return true;
      }
    }

    return false;
  }

  areTenantSquareFootagePhaseInDatesChanged(
    currentValues: Array<models.TenantSquareFootagePhaseInResult>,
    previousValues: Array<models.TenantSquareFootagePhaseInResult>,
  ): boolean {
    if (!currentValues || !previousValues || !currentValues.length || !previousValues.length) {
      return false;
    }

    const currFirstPhaseInItem = currentValues[0];
    const prevFirstPhaseInItem = previousValues[0];
    const currLastPhaseInItem = currentValues[currentValues.length - 1];
    const prevLastPhaseInItem = previousValues[previousValues.length - 1];

    return (
      currLastPhaseInItem && prevLastPhaseInItem &&
      (
        !moment(prevFirstPhaseInItem.startDate)
          .startOf('day')
          .isSame(moment(currFirstPhaseInItem.startDate).startOf('day')) ||
        !moment(prevLastPhaseInItem.endDate)
          .startOf('day')
          .isSame(moment(currLastPhaseInItem.endDate).startOf('day'))
      )
    );
  }

  areEscalationDatesChanged(
    currentValues: Array<models.RentalRateAnnualEscalationTermCustomResult>,
    previousValues: Array<models.RentalRateAnnualEscalationTermCustomResult>,
  ): boolean {
    if (!currentValues || !previousValues || !currentValues.length || !previousValues.length) {
      return false;
    }

    const currLastEscalationItem = currentValues[currentValues.length - 1];
    const prevLastEscalationItem = previousValues[previousValues.length - 1];

    return (
      currLastEscalationItem && prevLastEscalationItem &&
      (
        currLastEscalationItem.leaseMonth !== prevLastEscalationItem.leaseMonth
      )
    );
  }

  getTermUpdatedDate(
    leaseTermConfiguration: models.LeaseTermConfiguration,
    lease: models.Lease,
    stageHistoryRecord: models.StageHistoryRecord,
  ): Date {
    const result: Date = new Date();

    if (leaseTermConfiguration) {
      const leaseTerm = this.getLeaseTerm(lease, leaseTermConfiguration.leaseTermType);
      if (leaseTerm && leaseTerm.updatedOn) {
        return leaseTerm.updatedOn;
      }
    }

    if (stageHistoryRecord && stageHistoryRecord.createdOn) {
      return stageHistoryRecord.createdOn;
    }

    return result;
  }

  isRateLessForRestructureProject(
    project: models.Project,
    lease: models.Lease,
    leaseTerm: models.BaseRentalRate,
  ): boolean {
    const currentProjectType = project.projectTypeId;
    if (currentProjectType !== models.ProjectTypeEnum.Restructure) {
      return false;
    }

    if (!lease.abstractLease || !lease.abstractLease.baseRentalRateTerm) {
      return false;
    }

    if (lease.abstractLease
      && lease.abstractLease.baseRentalRateTerm
      && lease.abstractLease.baseRentalRateTerm.baseRentalRateType !== leaseTerm.baseRentalRateType) {
      return false;
    }

    if (leaseTerm.baseRentalRateType === models.BaseRentalRateType.Net
      && leaseTerm.baseRentalRateNetValue < lease.abstractLease.baseRentalRateTerm.baseRentalRateNetValue) {
      return true;
    }

    if (leaseTerm.baseRentalRateType === models.BaseRentalRateType.Gross
      && leaseTerm.baseRentalRateGrossValue < lease.abstractLease.baseRentalRateTerm.baseRentalRateGrossValue) {
      return true;
    }

    if (leaseTerm.baseRentalRateType === models.BaseRentalRateType.BaseYear
      && leaseTerm.actualYearRate < lease.abstractLease.baseRentalRateTerm.actualYearRate) {
      return true;
    }

    return false;
  }

  getRealEstateTaxesCamExpensesBreakdownTotalValue(
    realEstateTaxesCamExpensesBreakdown: models.RealEstateTaxesCamExpensesBreakdown,
  ): number {
    if (!realEstateTaxesCamExpensesBreakdown) {
      return null;
    }

    let total = 0;

    const keys = Object.keys(realEstateTaxesCamExpensesBreakdown);
    for (let i = 0, num = keys.length; i < num; i++) {
      const key = keys[i];
      if (key === 'total') {
        continue;
      }

      if (typeof realEstateTaxesCamExpensesBreakdown[key] === 'number') {
        total += realEstateTaxesCamExpensesBreakdown[key];
      }
    }

    return total;
  }

  getTenantSquareFootageRangesByDateRange(
    lease: models.Lease,
    startDate: Date | string,
    endDate: Date | string
  ): Array<{ sf: number, daysCount: number }> {
    const tenantSquareFootageRanges: Array<{ sf: number, daysCount: number }> = [];

    const tenantSquareFootageTerm = lease.tenantSquareFootageTerm;

    if (!tenantSquareFootageTerm) {
      return tenantSquareFootageRanges;
    }

    const daysDiff = (start: Date | string, end: Date | string) =>
      moment(end).startOf('day').diff(start, 'days') + 1;

    switch (tenantSquareFootageTerm.tenantSquareFootageTermType) {
      case models.TenantSquareFootageTermType.ExistingSquareFootage:
        tenantSquareFootageRanges.push({
          sf: tenantSquareFootageTerm.tenantSquareFootageAbstractValue || 0,
          daysCount: daysDiff(startDate, endDate),
        });

        break;

      case models.TenantSquareFootageTermType.Custom:
        tenantSquareFootageRanges.push({
          sf: tenantSquareFootageTerm.tenantSquareFootageCustomValue || 0,
          daysCount: daysDiff(startDate, endDate),
        });

        break;

      case models.TenantSquareFootageTermType.PhaseIn:
        if (!tenantSquareFootageTerm.tenantSquareFootagePhaseInResults) {
          return tenantSquareFootageRanges;
        }

        const phaseInResults = tenantSquareFootageTerm
          .tenantSquareFootagePhaseInResults
          .filter(phaseInResult =>
            (
              moment(startDate).isBefore(phaseInResult.endDate) &&
              moment(phaseInResult.startDate).isBefore(endDate)
            )
          );

        for (const phaseInResult of phaseInResults) {
          let phaseInStartDate = phaseInResult.startDate;
          if (moment(phaseInStartDate).isBefore(startDate)) {
            phaseInStartDate = startDate;
          }

          let phaseInEndDate: Date | string = moment(phaseInResult.endDate).add(1, 'day').toDate();
          if (moment(endDate).isBefore(phaseInEndDate)) {
            phaseInEndDate = endDate;
          }

          tenantSquareFootageRanges.push({
            sf: phaseInResult.totalSf,
            daysCount: daysDiff(phaseInStartDate, phaseInEndDate),
          });
        }

        break;
    }

    return tenantSquareFootageRanges;
  }
  getTermsWithMultipleOptions(lease: models.Lease): Array<models.LeaseTerm> {
    const terms: Array<models.LeaseTerm> = [];
    if (!lease) {
      return terms;
    }

    const fieldNames = Object.keys(lease);
    for (let i = 0, num = fieldNames.length; i < num; i++) {
      const field = lease[fieldNames[i]];
      if (
        !field ||
        typeof field.hasOwnProperty !== 'function' ||
        !field.hasOwnProperty('leaseTermType')
      ) {
        continue;
      }

      const term = <models.LeaseTerm>field;
      if (!term.hasMultiplyOptions || !term.isElectedForNegotiation) {
        continue;
      }

      terms.push(term);
    }

    return terms;
  }

  fillInitialMultipleOptions(lease: models.Lease): models.Lease {
    if (!lease) {
      return lease;
    }

    const termLength = <models.Term>this.getLeaseTerm(lease, models.LeaseTermType.Term);
    if (
      !termLength ||
      !termLength.hasMultiplyOptions ||
      !termLength.leaseTermOptions ||
      !termLength.leaseTermOptions.options ||
      !termLength.leaseTermOptions.options.length
    ) {
      return lease;
    }

    const numberOfOptions = termLength.leaseTermOptions.options.length;

    const termsWithMultipleOptions = this.getTermsWithMultipleOptions(lease);
    for (let i = 0, num = termsWithMultipleOptions.length; i < num; i++) {
      const term = <models.LeaseTerm & { leaseTermOptions: models.LeaseTermOptions<models.LeaseTerm> }>termsWithMultipleOptions[i];
      if (
        term.leaseTermOptions &&
        term.leaseTermOptions.options &&
        term.leaseTermOptions.options.length
      ) {
        continue;
      }

      term.leaseTermOptions = <models.LeaseTermOptions<models.LeaseTerm>>{
        options: new Array(numberOfOptions).fill(<models.LeaseTerm>{ leaseTermType: term.leaseTermType }),
        selectedLeaseTerm: null,
        selectedLeaseTermIndex: null,
      };
    }

    return lease;
  }

  getBaseRentalRate(lease: models.Lease): number {
    if (!lease || !lease.baseRentalRateTerm) {
      return 0;
    }

    const { baseRentalRateTerm } = lease;

    switch (baseRentalRateTerm.baseRentalRateType) {
      case models.BaseRentalRateType.Net:
        return baseRentalRateTerm.baseRentalRateNetValue;

      case models.BaseRentalRateType.Gross:
        return baseRentalRateTerm.baseRentalRateGrossValue;

      case models.BaseRentalRateType.BaseYear:
        return baseRentalRateTerm.actualYearRate;

      default:
        return 0;
    }
  }

  getTotalEstimatedRealEstateTaxesValue(
    leaseTerm: IRealEstateTaxesCamExpensesViewModel,
    lease: models.Lease,
    includeReTaxes: boolean = false
  ): number {
    let result = (
      (leaseTerm?.insurance ?? 0) +
      (leaseTerm?.estimatedOpEx ?? 0) +
      this.getPropertyManagementFee(leaseTerm, lease)
    );

    if (includeReTaxes) {
      result += (leaseTerm?.estimatedReTaxes ?? 0);
    }

    return result;
  }

  getPropertyManagementFee(leaseTerm: IRealEstateTaxesCamExpensesViewModel, lease: models.Lease) {
    let managementFee = 0;
    if (!leaseTerm) {
      return managementFee;
    }

    if (leaseTerm.propertyManagementFeeType === PropertyManagementFeeType.PsfValue) {
      managementFee = leaseTerm.propertyManagementFeePsf ?? 0;
    }

    if (leaseTerm.propertyManagementFeeType === PropertyManagementFeeType.Percentage && lease) {
      const { term, baseRentalRateTerm } = lease;

      if
      (
        term.termType === models.TermTypeEnum.MultiplyOptions &&
        baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
        baseRentalRateTerm.leaseTermOptions &&
        baseRentalRateTerm.leaseTermOptions.options &&
        baseRentalRateTerm.leaseTermOptions.options.length
      ) {
        if (!baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm) {
          return managementFee;
        }
      }

      const baseRentalRateValue = this.getBaseRentalRate(lease) ?? 0;
      const percentage = leaseTerm.propertyManagementFeePercentage ?? 0;

      if (leaseTerm.propertyManagementFeePercentageType === PropertyManagementFeePercentageType.BaseRent) {
        managementFee = baseRentalRateValue * (percentage / 100);
      }

      if (leaseTerm.propertyManagementFeePercentageType === PropertyManagementFeePercentageType.GrossReceipts) {
        managementFee = (
          (
            baseRentalRateValue +
            (leaseTerm.insurance ?? 0) +
            (leaseTerm.estimatedReTaxes ?? 0) +
            (leaseTerm.estimatedOpEx ?? 0)
          ) *
          (percentage / 100)
        );
      }
    }

    return managementFee;
  }

  convertPropertyManagementFeeToCorrectValue(
    value: number,
    leaseTerm: IRealEstateTaxesCamExpensesViewModel,
    lease: models.Lease
  ): IRealEstateTaxesCamExpensesViewModel {
    if (!leaseTerm) {
      return leaseTerm;
    }

    if (leaseTerm.propertyManagementFeeType === PropertyManagementFeeType.PsfValue) {
      leaseTerm.propertyManagementFeePsf = value ?? 0;
    }

    if (leaseTerm.propertyManagementFeeType === PropertyManagementFeeType.Percentage && lease) {
      const { term, baseRentalRateTerm } = lease;

      if
      (
        term.termType === models.TermTypeEnum.MultiplyOptions &&
        baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
        baseRentalRateTerm.leaseTermOptions &&
        baseRentalRateTerm.leaseTermOptions.options &&
        baseRentalRateTerm.leaseTermOptions.options.length
      ) {
        if (!baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm) {
          return leaseTerm;
        }
      }

      const baseRentalRateValue = this.getBaseRentalRate(lease) ?? 0;

      if (leaseTerm.propertyManagementFeePercentageType === PropertyManagementFeePercentageType.BaseRent) {
        leaseTerm.propertyManagementFeePercentage = (value / baseRentalRateValue) * 100;
      }

      if (leaseTerm.propertyManagementFeePercentageType === PropertyManagementFeePercentageType.GrossReceipts) {
        leaseTerm.propertyManagementFeePercentage = (
          value / (
            baseRentalRateValue +
            (leaseTerm.insurance ?? 0) +
            (leaseTerm.estimatedReTaxes ?? 0) +
            (leaseTerm.estimatedOpEx ?? 0)
          )
        ) * 100;
      }
    }

    return leaseTerm;
  }

  private _getCellFromOptions(
    leaseTermOptions: Array<models.LeaseTerm>,
    optionNumber: number
  ): models.LeaseTermCustomCell {
    if (leaseTermOptions && optionNumber < leaseTermOptions.length && leaseTermOptions[optionNumber]) {
      return <models.LeaseTermCustomCell>{
        displayValue: leaseTermOptions[optionNumber].displayValue,
      };
    }
  }
}
