<div class="user-mobile">
  <div class="d-flex flex-column align-items-center pt-3">
    <button class="btn bg-transparent cancel-btn">
      <span class="pr-1" inlineSVG="assets/img/arrow-back.svg"> </span>
      <span class="text-capitalize text-15 text-primary" [routerLink]="['/profile']">
        cancel
      </span>
    </button>
    <img class="profile-photo" [src]="avatarUrl" alt="profile photo" />
    <div class="pt-2"
         (click)="onUploadPictureButtonClicked()">
      <a class="pt-2 profile-name">
        {{ displayName }}
      </a>
      <a class="pl-1" inlineSVG="assets/img/edit-icon.svg"> </a>
    </div>
    <span class="pt-1 text-muted text-14">
      {{ role }}
    </span>
  </div>
  <hr />
</div>

<div class="profile-edit">
  <div class="border profile-card pt-4">
    <img class="profile-photo object-fit-cover" alt="" srcset="" width="105" height="105"
         [src]="avatarUrl" />
    <div class="d-flex flex-column align-items-center pt-3 pb-3">
      <span class="text-13 fw-500">
        {{ displayName }}
      </span>
      <span class="text-13 text-muted pt-1">
        {{ role }}
      </span>
    </div>

    <div class="pt-3 pr-2 pl-2 pb-2 w-90">
      <button class="btn btn-primary w-100"
              (click)="onUploadPictureButtonClicked()">
        <span class="text-uppercase text-13 fw-500">
          upload picture
        </span>
      </button>
    </div>

    <div class="pt-2 pr-2 pl-2 pb-2 w-90">
      <button class="btn w-100"
              (click)="deleteAvatar()">
        <span class="text-uppercase text-13 fw-500 text-opacity-secondary">
          {{avatarDeletingState}}
        </span>
      </button>
    </div>

    <div class="pb-4 pt-3">
      <button class="btn profile-btn back-to-profile-btn"
              *ngIf="isProfileCompleted$ | async"
              [routerLink]="['../']">
        <span class="text-uppercase text-13">
          back to profile
        </span>
      </button>
    </div>
  </div>

  <form name="form" class="form" novalidate
        #form="ngForm" (ngSubmit)="submit(form)">
    <div class="d-flex flex-column">
      <div class="container fieldset bg-danger text-white"
           *ngIf="!(isProfileCompleted$ | async)">
        Please fill your profile
      </div>

      <div class="container fieldset">
        <div class="name">
          <h6 class="big-text">Personal information</h6>
          <div class="row">
            <div class="col-sm-4">
              <label class="text-14 text-capitalize">
                first name
              </label>
              <span class="error-msg"
                    *ngIf="form.submitted && firstName.hasError('required')">Required</span>
              <input
                class="rounded"
                name="firstName"
                id="firstName"
                type="text"
                #firstName="ngModel"
                required
                appTrimOnBlur
                [(ngModel)]="vm.firstName"
                [class.is-invalid]="form.submitted && firstName.hasError('required')"
              />
              <div class="text-12 name-definition pt-2">
                Displayed on your public profile, notifications and other places
              </div>
            </div>

            <div class="col-sm-4">
              <label class="text-14 text-capitalize">
                last name
              </label>
              <span class="error-msg"
                    *ngIf="form.submitted && lastName.hasError('required')">Required</span>
              <input
                class="rounded"
                id="lastName"
                name="lastName"
                type="text"
                #lastName="ngModel"
                required
                [(ngModel)]="vm.lastName"
                [class.is-invalid]="form.submitted && lastName.hasError('required')"
                appTrimOnBlur
              />
              <div class="text-12 name-definition pt-2">
                Displayed on your public profile, notifications and other places
              </div>
            </div>

            <div class="col-sm-4">
              <label class="text-14 text-capitalize">
                primary email
              </label>
              <input
                class="rounded"
                readonly
                id="primaryEmail"
                name="primaryEmail"
                type="text"
                [ngModel]="vm.primaryEmail"
                appTrimOnBlur
              />
            </div>
          </div>
        </div>

        <div class="row ">
          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              mobile phone
            </label>

            <dx-text-box
              #mobilePhone
              class="rounded"
              id="mobilePhone"
              name="mobilePhone"
              [ngModel]="vm.mobilePhone"
              (ngModelChange)="onMobilePhoneChange($event)"
              mask="+1 (X00) 000-0000"
              [useMaskedValue]="true"
              [maskRules]="phoneRules"
            >
            </dx-text-box>
          </div>

          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              additional email
            </label>
            <span class="error-msg"
                  *ngIf="form.submitted && additionalEmailAddress.hasError('email')">wrong email</span>
            <input
              email
              type="email"
              class="rounded"
              id="additionalEmailAddress"
              name="additionalEmailAddress"
              #additionalEmailAddress="ngModel"
              [(ngModel)]="vm.additionalEmailAddress"
              [class.is-invalid]="form.submitted && additionalEmailAddress.hasError('email')"
            />
          </div>

          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              title
            </label>
            <span class="error-msg"
                  *ngIf="form.submitted && title.hasError('required')">Required</span>
            <input
              type="text"
              class="rounded"
              id="title"
              name="title"
              required
              [(ngModel)]="vm.title"
              [class.is-invalid]="form.submitted && title.hasError('required')"
              #title="ngModel"
              appTrimOnBlur
            />
          </div>
        </div>
      </div>

      <div *ngIf="role === Roles.Landlord" class="container fieldset">
        <h6>Landlord profile</h6>
        <div class="row">
          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              type of investor
            </label>
            <select class="custom-select rounded" id="investorType" name="investorType"
                    [(ngModel)]="vm.investorType">
              <option *ngFor="let item of investorTypes"
                      [value]="item.id">
                {{ item.text }}
              </option>
            </select>
          </div>
          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              markets
            </label>
            <input class="rounded" id="markets" name="markets" type="text"
                   [(ngModel)]="vm.markets" />
          </div>
        </div>

        <hr />
        <h6>Size of Portfolio</h6>

        <div class="row">
          <div class="col-sm-4">
            <label class="text-14 text-capitalize">
              for office
            </label>
            <dx-number-box placeholder="0.0 SF" format="#0.## SF"
                           min="0" step="0"
                           [(value)]="vm.portfolioSizeOffice">
            </dx-number-box>
          </div>

          <div class="col-sm-4">
            <label class="text-14 text-capitalize ">
              industrial
            </label>
            <dx-number-box format="#0.## SF" placeholder="0.0 SF"
                           min="0" step="0"
                           [(value)]="vm.portfolioSizeIndustrial">
            </dx-number-box>
          </div>

          <div class="col-sm-4">
            <label class="text-14 text-capitalize ">
              land
            </label>
            <dx-number-box format="#0.## acres" placeholder="0.0 acres"
                           min="0" step="0"
                           [(value)]="vm.portfolioSizeLand">
            </dx-number-box>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <label class="text-14 text-capitalize ">
              retail
            </label>
            <dx-number-box
              min="0" step="0"
              format="#0.## SF"
              placeholder="0.0 SF"
              [(value)]="vm.portfolioSizeRetail"
            ></dx-number-box>
          </div>
        </div>
      </div>

      <div class="container fieldset"
           *ngIf="vm.company">
        <h6 class="big-text">Your company</h6>

        <div class="row">
          <div class="col-12">
            <label class="text-14 text-capitalize">
              Logo
            </label>
            <p>
              <img class="logo cursor-default" height="64" alt="Company logo"
                   [src]="vm.company?.logo?.thumbnailUrl || 'assets/img/nofoto.png'" />
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Name
            </label>
            <p>
              {{vm.company.name || '–'}}
            </p>
          </div>
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Phone
            </label>
            <p>
              {{vm.company.phone || '–'}}
            </p>
          </div>
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Web Site
            </label>
            <p>
              {{vm.company.webSite || '–'}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Home Market
            </label>
            <p>
              {{vm.company.homeMarket || '–'}}
            </p>
          </div>
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Other Markets
            </label>
            <p>
              {{vm.company.otherMarkets || '–'}}
            </p>
          </div>
          <div class="col-4">
            <label class="text-14 text-capitalize">
              Industry
            </label>
            <p>
              {{ vm.company.industryId ? industries[vm.company.industryId]?.name : '–'}}
            </p>
          </div>
        </div>

        <div *ngFor="let item of filterEmptyAddresses(vm.company.addresses); let index = index">
          <hr/>

          <div class="address-name">
            {{item.name}}
          </div>

          <div class="row">
            <div class="col-4">
              <label class="text-14 text-capitalize">
                Address Line 1
              </label>
              <p>
                {{item.addressLine1 || '–'}}
              </p>
            </div>
            <div class="col-4">
              <label class="text-14 text-capitalize">
                Address Line 2
              </label>
              <p>
                {{item.addressLine2 || '–'}}
              </p>
            </div>
            <div class="col-4">
              <label class="text-14 text-capitalize">
                City
              </label>
              <p>
                {{item.city || '–'}}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <label class="text-14 text-capitalize">
                State
              </label>
              <p>
                {{item.stateCode || '–'}}
              </p>
            </div>
            <div class="col-4">
              <label class="text-14 text-capitalize">
                Postal Code
              </label>
              <p>
                {{item.zipCode || '–'}}
              </p>
            </div>
            <div class="col-4">
              <label class="text-14 text-capitalize">
                Country
              </label>
              <p>
                {{item.countryCode || '–'}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="action-buttons">
        <div class="pr-4">
          <button type="submit" class="save-btn btn btn-primary w-100 ">
            <span class="text-uppercase text-13 fw-500">
              save changes
            </span>
          </button>
        </div>
        <div class="cancel-btn-width"
             *ngIf="isProfileCompleted$ | async">
          <button type="button" class="btn"
                  [routerLink]="['../']">
            <span class="text-uppercase text-13 fw-500 text-opacity-secondary">
              cancel
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
