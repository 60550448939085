import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './term-history.model';

@Injectable()
export class TermHistoryRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getLeaseHistoryRecords(leaseId: number): Observable<models.LeaseHistoryRecordViewModel> {
    return this._httpClient
      .get<models.LeaseHistoryRecordViewModel>(
        `${this._configuration.apiEndpoint}/leases/${leaseId}/history-records`
      );
  }
}
