import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticateResult } from '@statera/sdk/auth';
import { Configuration } from '@statera/sdk/common';

import * as models from './invitation.model';

@Injectable()
export class InvitationRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  sendUserInvitationRequests(userInvitationRequests: Array<models.InvitationRequest>): Observable<void> {
    return this._httpClient.post<void>(
      `${this._configuration.apiEndpoint}/invitation/send-user-invitation-requests`,
      userInvitationRequests);
  }

  sendUserInvitationRequest(userInvitationRequest: models.InvitationRequest): Observable<void> {
    return this._httpClient.post<void>(
      `${this._configuration.apiEndpoint}/invitation/send-user-invitation-request`,
      userInvitationRequest);
  }

  resendUserInvitationRequest(userInvitationRequestId: number): Observable<void> {
    let params = new HttpParams();

    if (userInvitationRequestId) {
      params = params.set('userInvitationRequestId', userInvitationRequestId.toString(10));
    }

    return this._httpClient.post<void>(
      `${this._configuration.apiEndpoint}/invitation/resend-user-invitation-request`,
      null,
      {params});
  }

  getUserInvitationInfo(protectedToken: string): Observable<models.InvitationInfo> {
    let params = new HttpParams();

    if (protectedToken) {
      params = params.set('protectedToken', protectedToken);
    }

    return this._httpClient.get<models.InvitationInfo>(
      `${this._configuration.apiEndpoint}/invitation/user-invitation-info`,
      {params});
  }

  acceptInvitation(acceptInvitation: models.AcceptInvitation): Observable<AuthenticateResult> {
    return this._httpClient.post<AuthenticateResult>(
      `${this._configuration.apiEndpoint}/invitation/accept-invitation`,
      acceptInvitation,
      {withCredentials: true});
  }
}
