import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { AuthService } from '../../../auth/services/auth.service';

import { ExpirationsReportComponent } from '../../../analytics/components/expirations-report/expirations-report.component';
import { ActiveDealsByStageReportComponent } from '../../../analytics/components/active-deals-by-stage-report/active-deals-by-stage-report.component';
import { OccupancyAndVacancyReportComponent } from '../../../analytics/components/occupancy-and-vacancy-report/occupancy-and-vacancy-report.component';
import { DealsByStageReportComponent } from '../../../analytics/components/deals-by-stage-report/deals-by-stage-report.component';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';
import * as models from '../../../infrastructure/models/generated';

type Initiator = 'tenant' | 'landlord';

interface DealsByStageReportProjectType {
  id: number;
  name: string;
  mapping: { [initiator: string]: models.DealsByStageReportDealType };
}

interface DealsByStageReportInitiatedBy {
  name: string;
  role: Initiator;
  visible: boolean;
}

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private static readonly _dealsByStageReportProjectTypes: Array<DealsByStageReportProjectType> = [
    {
      id: 1,
      name: 'Renewal',
      mapping: {
        tenant: models.DealsByStageReportDealType.RenewalInitiatedByTenant,
        landlord: models.DealsByStageReportDealType.RenewalInitiatedByLandlord,
      },
    },
    {
      id: 2,
      name: 'Restructure',
      mapping: {
        tenant: models.DealsByStageReportDealType.RestructureInitiatedByTenant,
      }
    },
    {
      id: 3,
      name: 'New Deal',
      mapping: {
        tenant: models.DealsByStageReportDealType.NewDealInitiatedByTenant,
      }
    },
  ];

  private static readonly _dealsByStageReportInitiatedBy: Array<DealsByStageReportInitiatedBy> = [
    {
      name: 'Tenant',
      role: 'tenant',
      visible: true,
    },
    {
      name: 'Landlord',
      role: 'landlord',
      visible: true,
    },
  ];

  @ViewChild(ExpirationsReportComponent) expirationsComponent: ExpirationsReportComponent;
  @ViewChild(ActiveDealsByStageReportComponent) activeDealsByStageReportComponent: ActiveDealsByStageReportComponent;
  @ViewChild(OccupancyAndVacancyReportComponent) occupancyAndVacancyReportComponent: OccupancyAndVacancyReportComponent;
  @ViewChild(DealsByStageReportComponent) dealsByStageReportComponent: DealsByStageReportComponent;

  greetingMessage: string;
  userFirstName: string;

  dealsByStageReportCurrentProjectTypeId: number;
  dealsByStageReportCurrentInitiatedBy: Initiator;
  dealsByStageReportCurrentNumberOfYears: number;

  dealsByStageReportProjectTypes: Array<DealsByStageReportProjectType>;
  dealsByStageReportInitiatedBy: Array<DealsByStageReportInitiatedBy>;
  dealsByStageReportNumberOfYears: Array<{ name: string, numberOfYears: number }>;

  selectedAnalyticsFilters: models.IAnalyticsFiltersViewModel;

  isSidebarCollapsed;

  private readonly _authService: AuthService;
  private readonly _destroy$: Subject<void>;

  constructor(authService: AuthService) {
    this._authService = authService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.selectedAnalyticsFilters = <models.IAnalyticsFiltersViewModel>{};

    this._configureDealsByStageReport();

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          if (!info) {
            return;
          }

          this.greetingMessage = this._getGreetingMessage();
          this.userFirstName = info.firstName;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleSelectedAnalyticsFiltersChange(selectedAnalyticsFilters: analyticsFiltersModels.AnalyticsFilters): void {
    this.selectedAnalyticsFilters = selectedAnalyticsFilters;
  }

  showMoreInfoOfExpirations(): void {
    this.expirationsComponent.showChart();
  }

  showMoreInfoOfActiveDealsByStage(): void {
    this.activeDealsByStageReportComponent.showChart();
  }

  showMoreInfoOfEOYProjectedOccupancy(): void {
    this.occupancyAndVacancyReportComponent.showChart();
  }

  showMoreInfoOfDealsByStage(): void {
    this.dealsByStageReportComponent.showChart();
  }

  handleDealsByStageReportProjectTypesChange(projectTypeId: number): void {
    const projectTypeOption = this.dealsByStageReportProjectTypes.find(x => x.id === projectTypeId);
    if (!projectTypeOption) {
      return;
    }

    const initiators = Object.keys(projectTypeOption.mapping);
    if (!initiators || !this.dealsByStageReportInitiatedBy || !this.dealsByStageReportInitiatedBy.length) {
      return;
    }

    this.dealsByStageReportInitiatedBy = this.dealsByStageReportInitiatedBy.map(initiator => {
      initiator.visible = initiators.includes(initiator.role);

      if (!initiator.visible && this.dealsByStageReportCurrentInitiatedBy === initiator.role) {
        this.dealsByStageReportCurrentInitiatedBy = this.dealsByStageReportInitiatedBy.find(x => x.visible)?.role;
      }

      return initiator;
    });
  }

  getDealsByStageReportDealType(): models.DealsByStageReportDealType {
    let dealType = models.DealsByStageReportDealType.RenewalInitiatedByTenant;

    if (!this.dealsByStageReportCurrentProjectTypeId || !this.dealsByStageReportCurrentInitiatedBy) {
      return dealType;
    }

    const projectTypeOption = this.dealsByStageReportProjectTypes.find(x => x.id === this.dealsByStageReportCurrentProjectTypeId);
    if (!projectTypeOption) {
      return dealType;
    }

    dealType = projectTypeOption.mapping[this.dealsByStageReportCurrentInitiatedBy];

    return dealType;
  }

  private _getGreetingMessage(): string {
    const now = moment();
    const hours = now.get('hours');

    let greeting = 'Good Morning, ';

    // 12:00 pm <= hours < 4:59 pm
    if (12 <= hours && hours < 17) {
      greeting = 'Good Afternoon, ';
    }

    // 5 pm <= hours < 11:59 pm
    if (17 <= hours && hours < 24) {
      greeting = 'Good Evening, ';
    }

    return greeting;
  }

  private _configureDealsByStageReport(): void {
    this.dealsByStageReportProjectTypes = DashboardComponent._dealsByStageReportProjectTypes;
    this.dealsByStageReportInitiatedBy = [...DashboardComponent._dealsByStageReportInitiatedBy];
    this.dealsByStageReportNumberOfYears = [1, 3, 5, 10].map(x => {
      return {
        name: x === 1 ? '1 year' : `${x} years`,
        numberOfYears: x,
      };
    });

    this.dealsByStageReportCurrentProjectTypeId = 1;
    this.dealsByStageReportCurrentInitiatedBy = 'tenant';
    this.dealsByStageReportCurrentNumberOfYears = 5;
  }
}
