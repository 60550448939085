import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantRequestDeleteViewModel } from './tenant-request.model';

import { TenantRequestRepository } from './tenant-request.repository';

import * as models from './tenant-request.model';

@Injectable()
export class TenantRequestManager {
  private readonly _tenantRequestRepository: TenantRequestRepository;

  constructor(tenantRequestRepository: TenantRequestRepository) {
    this._tenantRequestRepository = tenantRequestRepository;
  }

  getById(id: number): Observable<models.TenantRequestViewModel> {
    return this._tenantRequestRepository
      .getById(id);
  }

  putStatusById(id: number, status: models.TenantRequestStatus): Observable<models.TenantRequestViewModel> {
    return this._tenantRequestRepository
      .putStatusById(id, status);
  }

  deleteById(id: number, deleteViewModel?: models.TenantRequestDeleteViewModel): Observable<void> {
    return this._tenantRequestRepository
      .deleteById(id, deleteViewModel);
  }
}
