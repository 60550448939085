import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { AlertService } from '../../alert/services/alert.service';
import { executeDelayed } from '../operators/execute-delayed';
import { ProgressBarService } from '../services/progress-bar.service';
import { AuthService } from '../../auth/services/auth.service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private static readonly _spinnerDelay = 1000;

  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _progressBarService: ProgressBarService;
  private readonly _router: Router;
  private readonly _loaderService: LoaderService;

  constructor(
    authService: AuthService,
    alertService: AlertService,
    progressBarService: ProgressBarService,
    router: Router,
    loaderService: LoaderService
  ) {
    this._authService = authService;
    this._alertService = alertService;
    this._progressBarService = progressBarService;
    this._router = router;
    this._loaderService = loaderService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._progressBarService.start();

    const {isAuthenticated} = this._authService;
    if (isAuthenticated) {
      request = request.clone({ withCredentials: true });
    }

    let isSpinnerShown = false;

    const spinnerObserver = new Subject<void>();

    spinnerObserver
      .pipe(
        tap(() => {
          isSpinnerShown = true;
          this._loaderService.show();
        }),
        finalize(() => isSpinnerShown && this._loaderService.hide()),
      )
      .subscribe();

    return next
      .handle(request)
      .pipe(
        executeDelayed(() => spinnerObserver.next(), HttpClientInterceptor._spinnerDelay),
        catchError(error => {
          if (500 <= error.status) {
            this._router.navigate(['/server-error']);
            return throwError(error);
          }

          if (error.status === 401) {
            if (this._authService.isAuthenticated) {
              this._authService.logout();
              this._router.navigate(['/signin-user']);
            }

            return throwError(error);
          }

          if (error.status === 0) {
            console.error(error);
            return throwError(error);
          }

          this._alertService.pushErrorAlert({
            message: error.error,
          });

          window.scrollTo(0, 0);

          return throwError(error);
        }),
        finalize(() => {
          spinnerObserver.complete();

          this._progressBarService.complete();
        }),
      );
  }
}
