import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DxLoadPanelComponent } from 'devextreme-angular';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseDocumentManager } from '@statera/sdk/lease-document';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { StateraUserClaimService } from '../../../auth/services/statera-user-claim.service';
import { ProjectService } from '../../../shared/services/project.service';
import { LoaderService } from '../../../infrastructure/services/loader.service';

import * as models from '../../../infrastructure/models/generated';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

@Component({
  selector: 'app-lease-execute',
  templateUrl: './lease-execute.component.html',
  styleUrls: ['./lease-execute.component.scss'],
})
export class LeaseExecuteComponent implements OnInit, AfterViewInit {
  @ViewChild('loadPanel') loadPanel: DxLoadPanelComponent;

  @Input() lease: models.ILeaseViewModel;

  // TODO: [FIXME] We must stop use setters/getters for Input/Output attributes, for change handling use OnChanges live-cycle hook
  @Input()
  set project(project: models.IProjectViewModel) {
    if (project) {
      this._project = project;
    }
  }

  get project(): models.IProjectViewModel {
    return this._project;
  }

  @Output() leaseExecutionClosed = new EventEmitter();
  @Output() stageChanged = new EventEmitter();

  versionedFile: models.IVersionedFileViewModel;
  leaseVersionedFile: models.ILeaseAmendmentFileViewModel;
  docExists = false;
  popupVisible = false;
  initialized = false;
  signedDocUrl: any;

  get isLandlord() {
    return this._authService.role.toLowerCase() === 'landlord';
  }

  get isTenant() {
    return this._authService.role.toLowerCase() === 'tenant';
  }

  get statusText() {
    if (this.isExternalEmail) {
      return 'Waiting for your Team Member to sign the document.';
    }
    if (this._projectService.hasActionRequired(this.project, this.lease)) {
      return 'Action Required';
    } else {
      if (this.project && this.project.projectState && this.project.projectState.writeAccessToTermsByRole) {
        return 'Pending Response';
      } else {
        return '';
      }
    }
  }
  isExternalEmail: boolean;

  private _cdRef: ChangeDetectorRef;
  private _sanitizer: DomSanitizer;
  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private _project: models.IProjectViewModel;
  private readonly _projectService: ProjectService;
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private readonly _leaseDocumentManager: LeaseDocumentManager;
  private readonly _loaderService: LoaderService;

  constructor(
    cdRef: ChangeDetectorRef,
    sanitizer: DomSanitizer,
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    projectService: ProjectService,
    stateraUserClaimsService: StateraUserClaimService,
    leaseDocumentManager: LeaseDocumentManager,
    loaderService: LoaderService,
    stateraUserClaimManager: StateraUserClaimManager
  ) {
    this._cdRef = cdRef;
    this._sanitizer = sanitizer;
    this._authService = authService;
    this._alertMessagesManager = alertMessagesManager;
    this._alertService = alertService;
    this._projectService = projectService;
    this._stateraUserClaimManager = stateraUserClaimManager;
    this._leaseDocumentManager = leaseDocumentManager;
    this._loaderService = loaderService;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (!this.lease) {
      this._alertService.pushErrorAlert({
        message: this._alertMessagesManager.getLeaseNotSetAlertText(),
      });

      return;
    }

    this._leaseDocumentManager.getSignDocument(this.lease.id).subscribe(x => {
      this.docExists = !!x;
      if (this.docExists) {
        this.leaseVersionedFile = x;
        this.versionedFile = this.leaseVersionedFile.versionedFile;
        this.setSignedDocUrl();

        if (
          this._stateraUserClaimManager.checkUserAccess(
            models.StateraClaimTypeAsEnum.Signature_Sign,
            models.StateraClaimValueAsEnum.Write,
            null,
            null,
            null,
            this.lease.id)
        ) {
          if (
            this.signedDocUrl ||
            (this._authService.isLandlord() && !x.landlordSignDate) ||
            (this._authService.isTenant() && !x.tenantSignDate)
          ) {
            this.popupVisible = true;
          }
        }

        if ((this._authService.isLandlord() && x.isExternalLandlordEmail) || (this._authService.isTenant() && x.isExternalTenantEmail)) {
          this.popupVisible = true;
          this.isExternalEmail = true;
        }
      }
    });
  }

  onPopupShown() {
    if (!this.signedDocUrl && !this.initialized && !this.isExternalEmail) {
      this._cdRef.checkNoChanges();
      this._loaderService.show();
      (<any>window).eversign.open({
        url: this.leaseVersionedFile.signUrl,
        containerID: 'eversignContainer',
        width: '100%',
        height: '100%',
        events: {
          signed: () => {
            this._loaderService.show();
            this._leaseDocumentManager.setAsSigned(this.lease.id).subscribe(x => {
              this.leaseVersionedFile = x;
              this.versionedFile = this.leaseVersionedFile.versionedFile;
              this.setSignedDocUrl();
              this._loaderService.hide();
              if (!this.signedDocUrl) {
                this.popupVisible = false;
              }
              this.stageChanged.emit();
            });
          },
          loaded: () => this._loaderService.hide(),
          declined: () => this._loaderService.hide(),
          error: () => {
            this._loaderService.hide();
            if (this.popupVisible) {
              this._alertService.pushWarningAlert({
                message: this._alertMessagesManager.getEversignErrorAlertText(),
              });
            }
          },
        },
      });
      this._cdRef.detectChanges();
      this.initialized = true;
    }
  }

  setSignedDocUrl() {
    const url = this.leaseVersionedFile &&
      this.leaseVersionedFile.signedDocument &&
      this.leaseVersionedFile.signedDocument.url;
    this.signedDocUrl = url ? this._sanitizer.bypassSecurityTrustResourceUrl(url) : null;
  }

  onPopupHidden($event) {
    this.leaseExecutionClosed.emit();
  }

  getProjectIndicatorClass(): string {
    if (this.project) {
      if (this._projectService.isClosed(this.project)) {
        return 'closed';
      } else {
        if (this._projectService.hasActionRequired(this.project, this.lease)) {
          return 'error';
        } else {
          return 'success';
        }
      }
    }

    return 'closed';
  }
}
