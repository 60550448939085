<ng-container *ngIf="building">
  <h6>Building Highlights</h6>
  <div class="w-fancy-item">
    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Total Building Size
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="building.totalBuildingSize">
          {{ building?.totalBuildingSize | number }} SF
        </ng-container>
        <ng-container *ngIf="!building?.totalBuildingSize">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Building Type
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
        {{ getBuildingTypeName(building?.buildingType) }}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Land Area (acres)
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
        <ng-container *ngIf="building?.totalLandArea">
          {{ building?.totalLandArea | number }} Acres
        </ng-container>
        <ng-container *ngIf="!building.totalLandArea">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Year Built
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
        <ng-container *ngIf="building?.builtYear">
          {{ building?.builtYear }}
        </ng-container>
        <ng-container *ngIf="!building.builtYear">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Ceiling Height
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
        <ng-container *ngIf="building?.clearHeight">
          {{ getClearHeight(building.clearHeight) }}
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Class (A, B or C)
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
        <ng-container *ngIf="building?.buildingClass != null">
          {{ getBuildingClass(building.buildingClass) }}
        </ng-container>
        <ng-container *ngIf="building?.buildingClass == null">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Construction Type
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="building?.constructionType?.name">
          {{ building?.constructionType?.name }}
        </ng-container>
        <ng-container *ngIf="!building?.constructionType?.name">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->
    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Total Parking
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="building?.totalParkingSpaces">
          {{ building?.totalParkingSpaces }}
        </ng-container>
        <ng-container *ngIf="!building?.totalParkingSpaces">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->
    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Total Docks
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        {{ getDocks(building?.totalDocksInterior, building?.totalDocksExterior) }}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->
    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Column Spacing
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="building?.columnSpacing">
          {{ building?.columnSpacing }}
        </ng-container>
        <ng-container *ngIf="!building?.columnSpacing">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->
  </div><!--w-fancy-item-->

  <h6>Unit Specifications</h6>

  <div class="w-fancy-item">
    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Size
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="buildingUnit?.size">
          {{ buildingUnit?.size | number }} SF
        </ng-container>
        <ng-container *ngIf="!buildingUnit?.size">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Docks
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        {{ getDocks(buildingUnit?.totalDocksInterior, buildingUnit?.totalDocksExterior) }}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Office Space
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        <ng-container *ngIf="buildingUnit?.totalOfficeArea">
          {{ buildingUnit?.totalOfficeArea | number }} SF
        </ng-container>
        <ng-container *ngIf="!buildingUnit?.totalOfficeArea">
          —
        </ng-container>
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Drive-in Doors
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        {{getDriveInDoors(buildingUnit?.driveInDoors, buildingUnit?.totalDriveInDoorsSize)}}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Clear Height
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body text-nowrap">
        {{ getClearHeight(buildingUnit?.clearHeight) }}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->

    <div class="b-fancy-item">
      <div class="b-fancy-item-title">
        Power
      </div><!--b-fancy-item-title-->

      <div class="b-fancy-item-body">
         {{getPower(buildingUnit?.amps, buildingUnit?.volts, buildingUnit?.phase)}}
      </div><!--b-fancy-item-body-->
    </div><!--b-fancy-item-->
  </div>
</ng-container>

