import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogRefService} from '../../../../dialog/services/dialog-ref.service';
import {Subject} from 'rxjs';
import {NgForm} from '@angular/forms';
import {BuildingBudgetManager} from '@statera/sdk/building-budget';
import {takeUntil} from 'rxjs/operators';
import * as models from '../../../../infrastructure/models/generated';

@Component({
  templateUrl: './building-budget-dialog.component.html',
  styleUrls: ['./building-budget-dialog.component.scss']
})
export class BuildingBudgetDialogComponent implements OnInit, OnDestroy {
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy: Subject<void>;
  private readonly _buildingBudgetManager: BuildingBudgetManager;
  building: models.IBuildingViewModel;
  buildingBudget: models.IBuildingBudgetViewModel;
  editMode: boolean;
  constructor(dialogRefService: DialogRefService,
              buildingBudgetManager: BuildingBudgetManager) {
    this._dialogRefService = dialogRefService;
    this._buildingBudgetManager = buildingBudgetManager;

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.editMode = !!this.buildingBudget;
    if (!this.buildingBudget) {
      this.buildingBudget = <models.IBuildingBudgetViewModel>{
        buildingId: this.building.id,
        capitalItems: [],
        marketRateGrowths: this.getDefaultMarketRateGrowth(),
        hasCapitalItems: false
      };
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  submit(form: NgForm): void {
    if (!form.valid) {
      return;
    }
    this._buildingBudgetManager
      .upsertBuildingBudget(this.buildingBudget)
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.close();
    });
  }
  getDefaultMarketRateGrowth(): Array<models.IMarketRateGrowth> {
    return [
      <models.IMarketRateGrowth>{
        year: 1,
        value: 0
      },
      <models.IMarketRateGrowth>{
        year: 2,
        value: 0
      },
      <models.IMarketRateGrowth>{
        year: 3,
        value: 0
      },
      <models.IMarketRateGrowth>{
        year: 4,
        value: 0
      },
      <models.IMarketRateGrowth>{
        year: 5,
        value: 0
      }
    ];
  }

  getAverageMarketRateGrowth(): number {
    return this._buildingBudgetManager.getAverageMarketRateGrowth(this.buildingBudget);
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
