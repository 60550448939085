<div class="greeting-wrapper">
  <dx-scroll-view width="100%" height="100%">
    <div class="greeting"
         #greetingElementRef>
      <div class="greeting-slider"
           [style.transform]="'translateX(' + -slideOffset + 'px)'">
        <!-- Start: First slide -->
        <div class="greeting-slide"
             [style.width.px]="greetingElementRef.clientWidth"
             #slideElementRef>
          <div>
            <figure class="greeting-image">
              <img src="assets/img/oxana2.png" alt="Alease image"/>
            </figure>
            <p class="greeting-title">
              <span class="d-block greeting-title-big">
                Welcome to Statera
              </span>
              A simplified approach to leasing industrial facilities.
            </p>
            <hr>
            <p class="greeting-highlight">
              I'm Aleasa and will be your guide throughout this experience.
            </p>
          </div>

          <div class="greeting-btn">
            <button type="button" class="btn-primary"
                    (click)="nextSlide()">
              Next
            </button>
          </div>
        </div>
        <!-- End: First slide -->
        <!-- Start: Second slide -->
        <div class="greeting-slide"
             [style.width.px]="greetingElementRef.clientWidth"
             #slideElementRef>
          <div>
            <p class="greeting-title">
              <span class="d-block greeting-title-big">
                Welcome to Statera
              </span>
              A simplified approach to leasing industrial facilities.
            </p>
            <div class="row justify-content-stretch align-items-stretch">
              <div class="col-4">
                <img src="assets/img/onboarding1.svg" class="img-fluid" alt=""/>
                <p>
                  Your Dashboard is the central hub for all relevant lease information.
                </p>
              </div>
              <div class="col-4">
                <img src="assets/img/onboarding3.svg" class="img-fluid" alt=""/>
                <p>
                  Our Colábo tool will guide you through any leasing inquiries or lease negotiations.
                </p>
              </div>
              <div class="col-4">
                <img src="assets/img/onboarding2.jpg" class="img-fluid" alt=""/>
                <p>
                  Track all lease terms, critical dates, documents, financial analysis,
                  communicate directly with stakeholders and eSign documents with ease.
                </p>
              </div>
            </div>
          </div>
          <div class="greeting-btn">
            <button type="button" class="btn-muted"
                    (click)="prevSlide()">
              Back
            </button>
            <button type="button" class="btn-primary"
                    (click)="nextSlide()">
              Next
            </button>
          </div>
        </div>
        <!-- End: Second slide -->
        <!-- Start: Third slide -->
        <div class="greeting-slide"
             [style.width.px]="greetingElementRef.clientWidth"
             #slideElementRef>
          <div>
            <p class="greeting-title">
              <span class="d-block greeting-title-big">
                Welcome to Statera
              </span>
              A simplified approach to leasing industrial facilities.
            </p>
            <div class="mt-4">
              <img src="assets/img/onboarding4.png" class="img-fluid" width="150" height="150" alt=""/>
              <p class="my-4">
                Brokers will need to communicate with their clients to determine who leads the negotiations.
              </p>
            </div>
          </div>
          <div class="greeting-btn">
            <button type="button" class="btn-muted"
                    (click)="prevSlide()">
              Back
            </button>
            <button type="button" class="btn-primary"
                    (click)="close()">
              Done
            </button>
          </div>
        </div>
        <!-- End: Third slide -->
      </div>

      <div class="greeting-dots">
        <span *ngFor="let i of slideElementRefs; let index = index; trackBy: identify"
              [class.active]="index <= slideIndex">
        </span>
      </div>
    </div>
  </dx-scroll-view>

  <button type="button" class="greeting-close"
          (click)="close()">
    <img src="assets/img/close.svg" alt="Close"/>
  </button>
</div>
