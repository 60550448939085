import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-submarket',
  templateUrl: 'analytics-filters-location-submarket.component.html',
  styleUrls: ['analytics-filters-location-submarket.component.scss'],
})
export class AnalyticsFiltersLocationSubMarketComponent {
  @Input() locationSubMarkets: Array<analyticsFiltersModels.LocationSubMarketFilter>;
  @Output() locationSubMarketsChange: EventEmitter<Array<analyticsFiltersModels.LocationSubMarketFilter>>;

  constructor() {
    this.locationSubMarketsChange = new EventEmitter<Array<analyticsFiltersModels.LocationSubMarketFilter>>();
  }

  handleSubMarketSelection(): void {
    this.locationSubMarketsChange.next([...this.locationSubMarkets]);
  }
}
