<form name="form"
      (submit)="onSaveTerm($event)"
      *ngIf="isVisibleTermForm()"
      #form="ngForm">
  <div class="row"
       *ngIf="!isOption">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container mb-2" *ngIf="isVisibleRadioGroup">
      <div class="col-sm-12 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name"
                          valueExpr="value" itemTemplate="radio"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.escalationTermType"
                          (onOptionChanged)="clearErrors(); optionChanged($event)">
            <div *dxTemplate="let data of 'radio'">
              <span>{{ data.name }}</span>
              <ng-container
                *ngIf="(data.value === EscalationTermType.FixedPercentagePerYear || data.value === EscalationTermType.FixedAmountPsfPerYear)">
                <ng-container
                  *ngIf="leaseTerm.escalationTermType === EscalationTermType.FixedPercentagePerYear && data.value === EscalationTermType.FixedPercentagePerYear">
                  <app-input-field appIgnoreScrollwheel className="d-block pl-0 my-3"
                                   [class.col-6]="isVisibleEscalationTaxRadioGroup"
                                   [id]="'escalationPercentagePerYear'"
                                   [placeholder]="'(e.g. 3)'"
                                   [className]="'real-estate-input mb-0'"
                                   [format]="'#0.##'"
                                   [min]="0"
                                   [max]="100"
                                   [errorType]="'required'"
                                   [errorMessage]="'Fixed Percentage is required'"
                                   [value]="leaseTerm.escalationPercentagePerYear"
                                   [suffix]="'% per year'"
                                   [width]="'40px'"
                                   (valueChange)="leaseTerm.escalationPercentagePerYear = $event; clearErrors(); leaseTermPropertyChange()"
                  ></app-input-field>

<!--                  <div class="customInput111">-->
<!--                    <input type="number" placeholder="(e.g. 3)"  appTestInput-->
<!--                           id="escalationPercentagePerYear"-->
<!--                           [value]="leaseTerm.escalationPercentagePerYear"-->
<!--                           (valueChange)="customValueChange($event)">-->
<!--                    <span>% per year</span>-->
<!--                  </div>-->

                  <ng-container *ngTemplateOutlet="escalationType"></ng-container>
                </ng-container>

                <ng-container
                  *ngIf="leaseTerm.escalationTermType === EscalationTermType.FixedAmountPsfPerYear && data.value === EscalationTermType.FixedAmountPsfPerYear">
                  <app-input-field className="d-block pl-0 my-3"
                                   [class.col-6]="isVisibleEscalationTaxRadioGroup"
                                   [id]="'escalationPsfValuePerYear'"
                                   [placeholder]="'(e.g. 3)'"
                                   [className]="'real-estate-input mb-0'"
                                   [format]="'#,##0.00'" [min]="0" [step]="0"
                                   [errorType]="'required'"
                                   [errorMessage]="'Fixed Psf Per Year is required'"
                                   [value]="leaseTerm.escalationPsfValuePerYear"
                                   [prefix]="'$'"
                                   [suffix]="'PSF per year'"
                                   [width]="'40px'"
                                   (valueChange)="leaseTerm.escalationPsfValuePerYear = $event; clearErrors(); leaseTermPropertyChange()"
                  ></app-input-field>
                  <ng-container *ngTemplateOutlet="escalationType"></ng-container>
                </ng-container>
              </ng-container>

              <ng-template #escalationType>
                <ng-container *ngIf="isVisibleEscalationTaxRadioGroup">
                  <div class="row choose-rental-rate mt-2 mb-1">
                    <label for="escalationTaxOptions">
                      Choose Escalation Type
                    </label>
                    <div class="col-sm-1">
                      <button type="button" class="b-btn-icon-info"
                              (click)="$event.preventDefault()" #tooltipEscalationTaxOptions>
                        <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
                      </button>
                      <dx-tooltip target="#tooltipEscalationTaxOptions"
                                  position="top"
                                  [width]="250"
                                  showEvent="dxhoverstart"
                                  hideEvent="dxhoverend">
                        <div class="d-flex flex-column tooltip-menu">
                          Reminder: Any RE Taxes & OpEx that are included in the Base Rental Rate as Base Year Stops
                          will fluctuate annually separately.
                          Choose whether the escalation is on the Base Rental Rate value with or without any Base Year
                          Stops.
                        </div>
                      </dx-tooltip>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-12 pb-2 radio-group-container">
                      <div class="dx-field-value">
                        <dx-radio-group id="escalationTaxOptions" displayExpr="name" valueExpr="value"
                                        [dataSource]="escalationTaxOptions"
                                        [(value)]="leaseTerm.rentalRateEscalationTaxType"
                                        (onValueChanged)="leaseTermPropertyChange()">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="Choose Rental Rate">
                            </dxi-validation-rule>
                          </dx-validator>
                        </dx-radio-group>
                      </div>
                    </div><!--col-->
                    <div class="col-sm-6"></div>
                  </div><!--row-->
                </ng-container>
              </ng-template>
            </div>
            <dx-validator>
              <dxi-validation-rule type="required"
                                   message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div><!--col-->
      <div class="col-sm-6"></div>
    </div><!--row-->

    <ng-container *ngIf="canInsertNewOption()">
      <ng-container *ngFor="let termOption of leaseTerm?.leaseTermOptions?.options; let index=index;">
        <div class="row">
          <label>
            OPTION {{ getOptionName(index) }}
          </label>
        </div>
        <div class="row">
          <app-rental-rate-annual-escalation [isOption]="true" [project]="project" [lease]="lease"
                                             [(leaseTerm)]="leaseTerm?.leaseTermOptions?.options[index]">
          </app-rental-rate-annual-escalation>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="leaseTerm?.escalationTermType === EscalationTermType.Custom && !startingRate">
      <div class="content">
        <div class="term-toast term-toast-neutral" style="margin: 7px 0">
          <i inlineSVG="assets/img/information.svg"></i>
          Base Rental Rate must be filled out for a custom Rental Rate Escalation schedule.
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="leaseTerm?.escalationTermType === EscalationTermType.Custom && startingRate">
      <div class="content">
        <app-rental-rate-escalation-custom-schedule [startingRate]="startingRate" [lease]="lease"
                                                    [(customValues)]="leaseTerm.rentalRateAnnualEscalationTermCustomValues"
                                                    [(customResults)]="customRentalRateEscalationCustomResults">
        </app-rental-rate-escalation-custom-schedule>

        <div style="margin: 7px 0"
             *ngIf="form.submitted && !areCustomValuesValid()">
          <div class="term-toast term-toast-error mb-0"
               *ngFor="let errorMessage of getCustomValuesErrorMessages()">
            <i inlineSVG="assets/img/x.svg"></i>
            {{ errorMessage }}
          </div>
        </div>

        <div class="steps">
          <p *ngIf="!startingRate">
            Results Preview is not shown because starting rate is $0 {{ getBaseRentalRateUnitMetricsDisplayValue() }}
          </p>
        </div>

        <div class="escalation-table-wrapper"
             *ngIf="startingRate">
          <app-rental-rate-escalation-result-table
            [customRentalRateEscalationCustomResults]="customRentalRateEscalationCustomResults"
            [fullTable]="true" [unitMetrics]="getBaseRentalRateUnitMetrics()">
          </app-rental-rate-escalation-result-table>
        </div>
      </div>
    </ng-container>

    <!-- Start: Validation summary -->

    <div class="validation-summary">
      <dx-validation-summary ngForm="form">
      </dx-validation-summary>
      <div class="term-toast term-toast-error"
           *ngFor="let msg of errors">
        <i inlineSVG="assets/img/x.svg"></i>
        {{ msg }}
      </div>
    </div>

    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div *ngIf="!isOption">
        <div class="dx-fieldset">
          <dx-button id="button" text="Save" type="success"
                     class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel"
                     class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<div class="term-toast term-toast-information"
     *ngIf="isTermWillBeAddressedInFollowingStages()">
  <i inlineSVG="assets/img/information.svg"></i>
  This Term will be addressed in the following Stage(s).
</div>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>

