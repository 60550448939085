import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as moment from 'moment';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { SafeDatePipe } from '../../../infrastructure/pipes/safe-date.pipe';

import * as models from '../../../infrastructure/models/generated';

interface Appointment {
  id: number;
  title: string;
  date: Date;
  time: [Date, Date];
}

interface TimeSelector {
  label: string;
  value: [Date, Date];
}

@Component({
  templateUrl: 'appointment-edit-dialog.component.html',
  styleUrls: ['appointment-edit-dialog.component.scss'],
})
export class AppointmentEditDialogComponent implements OnInit {
  private static readonly _defaultDate = '1900-01-01';

  @ViewChild(NgForm) form: NgForm;

  appointment: Appointment;

  timeSelectors: Array<TimeSelector>;

  readonly isReschedule: boolean;
  readonly leaseId: number;
  readonly appointmentViewModel: models.IAppointmentViewModel;

  private readonly _dialogRefService: DialogRefService;
  private readonly _safeDatePipe: SafeDatePipe;

  constructor(dialogRefService: DialogRefService, safeDatePipe: SafeDatePipe) {
    this._dialogRefService = dialogRefService;
    this._safeDatePipe = safeDatePipe;
  }

  ngOnInit(): void {
    this.timeSelectors = this._prepareTimeSelectors();
    this.appointment = this._getAppointment();
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    this._dialogRefService.outputData = {
      appointmentViewModel: this._getAppointmentViewModel(),
    };

    this.close();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  isDateDisabled(model: {date: Date}): boolean {
    if (!model || !model.date) {
      return true;
    }

    const today = moment().startOf('day');
    const date = moment(model.date).startOf('day');

    if (today.isSame(date)) {
      return false;
    }

    return today.isAfter(date);
  }

  private _getAppointment(): Appointment {
    if (!this.appointmentViewModel) {
      return <Appointment>{
        date: new Date(),
      };
    }

    const intervalStart = new Date(this.appointmentViewModel.startDate);
    const intervalEnd = new Date(this.appointmentViewModel.endDate);

    const startTime = moment(AppointmentEditDialogComponent._defaultDate)
      .set({
        hours: intervalStart.getHours(),
        minutes: intervalStart.getMinutes(),
        seconds: 0,
      });

    const endTime = moment(AppointmentEditDialogComponent._defaultDate)
      .set({
        hours: intervalEnd.getHours(),
        minutes: intervalEnd.getMinutes(),
        seconds: 0,
      });

    let time = this.timeSelectors.find(x => moment(x.value[0]).isSame(startTime) && moment(x.value[1]).isSame(endTime));
    if (!time) {
      time = this.timeSelectors[0];
    }

    return {
      id: this.appointmentViewModel.id,
      title: this.appointmentViewModel.text,
      date: new Date(this.appointmentViewModel.startDate),
      time: time.value,
    };
  }

  private _getAppointmentViewModel(): models.IAppointmentViewModel {
    const [startTime, endTime] = this.appointment.time;
    if (!startTime || !endTime) {
      return;
    }

    const startDate = moment(this.appointment.date)
      .set({
        hours: startTime.getHours(),
        minutes: startTime.getMinutes(),
        seconds: 0
      });

    const endDate = moment(this.appointment.date)
      .set({
        hours: endTime.getHours(),
        minutes: endTime.getMinutes(),
        seconds: 0
      });

    return <models.IAppointmentViewModel>{
      id: this.appointment.id,
      text: this.appointment.title,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      entityId: this.leaseId,
      appointmentType: models.AppointmentType.TenantImprovementsVisit,
    };
  }

  private _prepareTimeSelectors(): Array<TimeSelector> {
    const start = moment(AppointmentEditDialogComponent._defaultDate).set({hours: 9, minutes: 0, seconds: 0});
    const end = moment(AppointmentEditDialogComponent._defaultDate).set({hours: 19, minutes: 0, seconds: 0});

    const numberOfIntervals = end.diff(start, 'hours');

    const timeSelectors = new Array<TimeSelector>(numberOfIntervals * 2);

    for (let i = 0, num = timeSelectors.length; i < num; i++) {
      const intervalStart = start.toDate();
      const intervalEnd = start.add(30, 'minutes').toDate();

      timeSelectors[i] = {
        label: this._getTimeIntervalString(intervalStart, intervalEnd),
        value: [intervalStart, intervalEnd],
      };
    }

    return timeSelectors;
  }

  private _getTimeIntervalString(startDate: Date, endDate: Date): string {
    if (!startDate || !endDate) {
      return;
    }

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    // Workaround: Special case
    if (startDate.getHours() === 11 && startDate.getMinutes() === 30 && endDate.getHours() === 12) {
      return '11:30 AM - 12:00 AM';
    }

    const from = this._safeDatePipe.transform(startDate.toString(), 'hh:mm a');
    const until = this._safeDatePipe.transform(endDate.toString(), 'hh:mm a');

    return `${from} - ${until}`;
  }
}
