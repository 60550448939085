import { SelectItem } from './common.model';

export class CommonTools {
  static StringIsNumber(value) {
    return !isNaN(Number(value));
  }

  static EnumToArray(theEnum): Array<SelectItem> {
    const filterFn = (x) => CommonTools.StringIsNumber(x);

    return Object
      .keys(theEnum)
      .filter(filterFn)
      .map(key => {
        return <SelectItem>{
          id: Number(key),
          text: theEnum[key].replace(/([a-z])([A-Z])|(\_)/g, '$1 $2'),
        };
      });
  }

  static SelectItemsToDictionary(items: Array<SelectItem>): Array<any> {
    const result = [];
    // tslint:disable-next-line:forin
    for (const i in items) {
      result[items[i].id] = items[i].text;
    }
    return result;
  }

  static renderNumber(val: number) {
    return val ? Math.round(val) : '-';
  }

  static monthDiff(start, end) {
    start = new Date(start);
    end = new Date(end);
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365));
  }

  static daysDiff(start, end) {
    return Math.round(Math.abs(new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60 * 60 * 24));
  }

  static capitalizeFirstLetter(columnName: string): string {
    if (columnName) {
      return columnName.charAt(0).toLowerCase() + columnName.slice(1);
    }

    return '';
  }

  static addMonths(date: Date, monthsCount: number): Date {
    const n = new Date(date);
    date.setDate(1);
    date.setMonth(date.getMonth() + monthsCount);
    date.setDate(Math.min(n.getDate(), CommonTools.getDaysInMonth(date)));
    return date;
  }

  static errorText(error: any): string {
    if (error.error instanceof Error) {
      return `An error occurred: ${error.error.message}`;
    } else {
      return `Backend returned code ${error.status}, body was: ${error.error}`;
    }
  }

  static DateWithoutTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static dateWithoutTimezone(date: Date): Date {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }

  static DateSetTime(date: Date, hours: number, minutes: number) {
    date.setHours(hours);
    date.setMinutes(minutes);
  }

  static zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return `${new Array(<number>width + (/\./.test(number) ? 2 : 1)).join('0')}${number}`;
    }
    return number.toString();
  }

  static searchToObject(search) {
    const obj = search
      .substring(1)
      .split('&')
      .reduce(function(result, value) {
        const parts = value.split('=');
        if (parts[0]) {
          result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return result;
      }, {});
    return obj;
  }

  static isNumeric(val: any): boolean {
    return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
  }

  static isLeapYear(year): boolean {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
  }

  static getDaysInMonthByYearAndMonth(year, month) {
    return [31, (CommonTools.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  static getDaysInMonth(date) {
    return CommonTools.getDaysInMonthByYearAndMonth(date.getFullYear(), date.getMonth());
  }

  static isArray(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  static isObject(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  static round(number: number): number {
    if (number === 0) {
      return number;
    }

    const [wholeStr, fractionalStr] = number.toString().split('.');
    if (!fractionalStr || !fractionalStr.length) {
      return number;
    }

    const fractionalPart = Array.prototype.slice.call(fractionalStr)
      .map(function(x) {
        return parseInt(x, 10);
      })
      .reverse()
      .reduce(function(acc: number, x: number): number {
        if (5 <= acc) {
          return x + 1;
        }
        return x;
      }, 0);

    const wholePart = parseInt(wholeStr, 10);
    if (5 <= fractionalPart) {
      return wholePart + 1;
    }

    return wholePart;
  }

  static compareTwoFloatNumber(n1: number, n2: number): boolean {
    const precision = 0.001;
    return Math.abs(n1 - n2) <= precision;
  }

  static downloadFile(fileUrl: string): void {
    const iframe = document.createElement('iframe');
    if (!iframe) {
      return;
    }

    iframe.style.visibility = 'hidden';
    iframe.style.display = 'none';

    iframe.src = fileUrl;

    document.body.append(iframe);
  }

  static isNull<T>(obj: T): boolean {
    return obj === null;
  }

  static isString<T>(obj: T): boolean {
    return Object.prototype.toString.call(obj) === '[object String]';
  }

  static isUndefined<T>(obj: T): boolean {
    return Object.prototype.toString.call(obj) === '[object Undefined]';
  }

  static isEqual(x: any, y: any): boolean {
    if (x === y) {
      return true;
    }

    if (x && y && typeof x === 'object' && typeof y === 'object') {
      if (x.constructor !== y.constructor) {
        return false;
      }

      if (Array.isArray(x)) {
        if (x.length !== y.length) {
          return false;
        }

        for (let i = x.length; i-- !== 0;) {
          if (!this.isEqual(x[i], y[i])) {
            return false;
          }
        }

        return true;
      }

      if ((x instanceof Map) && (y instanceof Map)) {
        if (x.size !== y.size) {
          return false;
        }

        for (const entry of x.entries()) {
          if (!y.has(entry[0])) {
            return false;
          }
        }

        for (const entry of x.entries()) {
          if (!this.isEqual(entry[1], y.get(entry[0]))) {
            return false;
          }
        }

        return true;
      }

      if ((x instanceof Set) && (y instanceof Set)) {
        if (x.size !== y.size) {
          return false;
        }

        for (const entry of x.entries()) {
          if (!y.has(entry[0])) {
            return false;
          }
        }

        return true;
      }

      if (ArrayBuffer.isView(x) && ArrayBuffer.isView(y)) {
        if (x.byteLength !== y.byteLength) {
          return false;
        }

        for (let i = x.byteLength; i-- !== 0;) {
          if (x[i] !== y[i]) {
            return false;
          }
        }

        return true;
      }

      if (x.constructor === RegExp) {
        return x.source === y.source && x.flags === y.flags;
      }

      if (x.valueOf !== Object.prototype.valueOf) {
        return x.valueOf() === y.valueOf();
      }

      if (x.toString !== Object.prototype.toString) {
        return x.toString() === y.toString();
      }

      const xKeys = Object.keys(x);
      const yKeys = Object.keys(y);

      if (xKeys.length !== yKeys.length) {
        return false;
      }

      for (let i = xKeys.length; i-- !== 0;) {
        if (!Object.prototype.hasOwnProperty.call(y, xKeys[i])) {
          return false;
        }
      }

      for (let i = xKeys.length; i-- !== 0;) {
        const key = xKeys[i];

        if (!this.isEqual(x[key], y[key])) {
          return false;
        }
      }

      return true;
    }

    return x !== x && y !== y;
  }

  static deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) { return obj; }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = CommonTools.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) { copy[attr] = CommonTools.deepCopy(obj[attr]); }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }

  static deepMerge<T>(target: T, ...sources: Array<T>): T {
    if (!sources.length) {
      return target;
    }

    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (!source.hasOwnProperty(key)) {
          continue;
        }

        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }

          this.deepMerge(target[key], source[key]);

          continue;
        }

        Object.assign(target, { [key]: source[key] });
      }
    }

    return this.deepMerge(target, ...sources);
  }

  static replaceCharactersExceptNumbers(value: string): string {
    if (value) {
      return value
        .replace(/[^\d]/g, '');
    }
  }

  static removeNullableFields<T extends object>(obj: T): T {
    if (!obj) {
      return obj;
    }

    const objectClone = {...obj};

    const objectKeys = Object.keys(objectClone);
    for (let i = 0, num = objectKeys.length; i < num; i++) {
      const objectKey = objectKeys[i];
      if (objectClone.hasOwnProperty(objectKey) && objectClone[objectKey] === null) {
        delete objectClone[objectKey];
      }
    }

    return objectClone;
  }

  static isNullOrUndefined (value: any) {
    return value === undefined || value === null;
  }

  static humanizeNumber(value: number | string): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (typeof value !== 'number' || isNaN(value)) {
      return '';
    }

    if (1000000 <= value) {
      return `${(value / 1000000).toFixed(value % 1000000 === 0 ? 0 : 1)}M`;
    }

    if (1000 <= value) {
      return `${(value / 1000).toFixed(value % 1000 === 0 ? 0 : 1)}K`;
    }

    return value.toString();
  }

  static getUniqueArrayValues<T>(array: Array<T>): Array<T> {
    const filterCallback = (element: T, i: number, self: Array<T>): boolean => {
      return self.indexOf(element) === i;
    };

    return array.filter(filterCallback);
  }

  static silentBreak<T>(fn: () => T): T {
    try { return fn(); } catch (e) { return null; }
  }
}
