import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewRef } from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import { Subject } from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { ApplicationStore } from '../../../shared/states/application/application.store';
import { ApplicationQuery } from '../../../shared/states/application/application.query';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { StorageService } from '../../../infrastructure/services/storage.service';

import * as models from '../../../infrastructure/models/generated';
import { BuildingHelper } from '../../../shared/helpers/building.helper';

enum FilterType {
  Assets = 'Assets',
  Deals = 'Deals',
  Inquiries = 'Inquiries',
  Leases = 'Leases',
  Requests = 'Requests',
  Funds = 'Funds'
}

class PortfolioCheckbox {
  constructor(portfolio: models.IPortfolioViewModel) {
    this.portfolio = portfolio;
    this.checked = false;
  }

  portfolio: models.IPortfolioViewModel;
  checked: boolean;
}

class AssetCheckbox {
  constructor(building: models.IBuildingViewModel) {
    this.building = building;
    this.checked = false;
  }

  building: models.IBuildingViewModel;
  checked: boolean;
}

enum DealStageGroupType {
  RenewalInitiatedByTenant = models.ProjectTypeEnum.Renewal,
  RenewalInitiatedByLandlord = models.ProjectTypeEnum.RenewalInitiatedByLandlord,
  RestructureByTenant = models.ProjectTypeEnum.Restructure,
  NewDeal = models.ProjectTypeEnum.NewDeal
}

const DealStageGroupName = {
  [DealStageGroupType.RenewalInitiatedByTenant]: 'Renewal Initiated by Tenant',
  [DealStageGroupType.RenewalInitiatedByLandlord]: 'Renewal Initiated by Landlord',
  [DealStageGroupType.RestructureByTenant]: 'Restructure Initiated by Tenant',
  [DealStageGroupType.NewDeal]: 'New Deal',

};

class DealStageGroup {
  constructor(groupName: string, checkboxes: Array<ProjectTemplateItemCheckbox>) {
    this.groupName = groupName;
    this.checkboxes = checkboxes;
  }

  groupName: string;
  checkboxes: Array<ProjectTemplateItemCheckbox>;
}

class ProjectTemplateItemCheckbox {
  constructor(projectTemplateItem: models.IProjectTemplateItem) {
    this.projectTemplateItem = projectTemplateItem;
    this.checked = false;
  }

  projectTemplateItem: models.IProjectTemplateItem;
  checked: boolean;
}

enum DealType {
  LeaseRenewal = 'Lease renewal',
  LeaseRestructure = 'Lease restructure',
  Maintenance = 'Maintenance',
  NewDeal = 'New Deal'
}

class ProjectTypeCheckbox {
  constructor(dealType: DealType, projectTypes: Array<models.IProjectType>) {
    this.dealType = dealType;
    this.projectTypes = projectTypes;
    this.checked = false;
  }

  dealType: DealType;
  projectTypes: Array<models.IProjectType>;
  checked: boolean;
}

enum LeaseExpirationFilterEnum {
  In3Years = 'In 3+ years',
  Within2And3Years = 'Within 2-3 years',
  Within1And2Years = 'Within 1-2 years',
  LessThan1Year = 'Less than 1 year'
}

class LeaseExpirationFilterCheckbox {
  constructor(leaseExpirationFilterEnum: LeaseExpirationFilterEnum) {
    this.leaseExpirationFilterEnum = leaseExpirationFilterEnum;
    this.checked = false;
  }

  checked: boolean;
  leaseExpirationFilterEnum: LeaseExpirationFilterEnum;
}

class FundsCheckbox {
  constructor(funds: string) {
    this.funds = funds;
    this.checked = false;
  }

  funds: string;
  checked: boolean;
}

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BuildingsComponent implements OnInit, OnDestroy {
  private _checkedPortfolios: Array<models.IPortfolioViewModel> = [];
  private _checkedBuildings: Array<models.IBuildingViewModel> = [];
  private _checkedProjectTemplateItems: Array<models.IProjectTemplateItem> = [];
  private _checkedProjectTypes: Array<models.IProjectType> = [];
  private _leaseExpirationDates: Array<[Date, Date]> = [];
  private _projectTemplateItems: Array<models.IProjectTemplateItem> = [];

  customOptions: any = {
    dots: true,
    margin: 15,
    nav: false,
  };

  activeIndex = 0;
  portfolio = <models.IPortfolioViewModel>{};
  portfolioCheckboxes: Array<PortfolioCheckbox>;
  assetsCheckboxes: Array<AssetCheckbox> = [];
  projectTemplateItemCheckboxes: Array<ProjectTemplateItemCheckbox> = [];
  projectTypeCheckboxes: Array<ProjectTypeCheckbox>;
  dealStageGroups: Array<DealStageGroup> = [];
  portfolios: Array<models.IPortfolioViewModel> = [];
  assets: Array<models.IBuildingViewModel>;
  leaseExpirationCheckboxes: Array<LeaseExpirationFilterCheckbox> = [];
  fundsCheckboxes: Array<FundsCheckbox>;
  tooltipVisible = false;
  isSidebarCollapsed = false;
  filterDisabled = false;

  tooltipMenuItems = [
    {
      text: 'Add Portfolio',
      path: '../buildings/add-portfolio',
    },
    {
      text: 'Add Building',
      path: '../buildings/add-building',
    },
  ];

  selectedTabIndex = 0;
  tabs = [{text: 'Portfolio'}, {text: 'Search'}];
  filterLinks = [{icon: 'dx-icon-home', text: FilterType.Assets},
    {icon: 'dx-icon-tags', text: FilterType.Deals},
    {icon: 'dx-icon-info', text: FilterType.Inquiries},
    {icon: 'dx-icon-key', text: FilterType.Leases},
    {icon: 'dx-icon-box', text: FilterType.Requests},
    {icon: 'dx-icon-box', text: FilterType.Funds}
  ];
  searchFilterText = '';
  FilterType = FilterType;

  buildingHelper = BuildingHelper;

  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _router: Router;
  private readonly _storageService: StorageService;
  private readonly _applicationStore: ApplicationStore;
  private readonly _applicationQuery: ApplicationQuery;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;
  private readonly _buildingManager: BuildingManager;
  private _currentRoute: string;

  constructor(
    landlordStore: LandlordStore,
    landlordManager: LandlordManager,
    router: Router,
    storageService: StorageService,
    applicationQuery: ApplicationQuery,
    applicationStore: ApplicationStore,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    buildingManager: BuildingManager,
  ) {
    this._storageService = storageService;
    this._router = router;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
    this._applicationStore = applicationStore;
    this._applicationQuery = applicationQuery;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._authService = authService;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
    this._buildingManager = buildingManager;
  }

  ngOnInit(): void {

    this._router.events.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._currentRoute = this._router.url;
      this.isSidebarCollapsed = this._currentRoute !== '/landlord/buildings';
    });

    this._landlordManager
      .getPortfolios()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(items => {
        if (!items || items.length === 0) {
          // this is disabled SMVP-3865
          this._alertService.pushWarningAlert({
            message: this._alertMessagesManager.getWaitUntilPortfolioWillBeUploadedByAdministratorAlertText(),
          });

          return;
        }

        this.itemClick(0);
      });

    this._landlordStore
      .portfolios$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((portfolios) => {
        this.portfolios = portfolios;
        this.portfolioCheckboxes = this.portfolios.map(x => {
          return new PortfolioCheckbox(x);
        });
      });

    this._buildingManager
      .requestBuildingsFunds()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(funds => {
        this.fundsCheckboxes = funds.map(x => new FundsCheckbox(x));
      });

    this._authService.infoLoadComplete
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(startupInfo => {
        if (!startupInfo || !startupInfo.lists) {
          return;
        }

        if (startupInfo.lists.templateItems) {
          this._projectTemplateItems = startupInfo.lists.templateItems;
          this.projectTemplateItemCheckboxes = startupInfo.lists.templateItems.filter(x => !x.parentProjectTemplateItem).map(x => {
            return new ProjectTemplateItemCheckbox(x);
          });
        }

        if (startupInfo.lists.projectTypes) {
          const renewalTypes = startupInfo.lists.projectTypes
            .filter(x =>
              x.projectTypeEnum === models.ProjectTypeEnum.Renewal ||
              x.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord
            );

          const restructureTypes = startupInfo.lists.projectTypes
            .filter(x => x.projectTypeEnum === models.ProjectTypeEnum.Restructure);

          const maintenanceTypes = startupInfo.lists.projectTypes
            .filter(x => x.projectTypeEnum === models.ProjectTypeEnum.Maintenance);

          const newDealTypes = startupInfo.lists.projectTypes
            .filter(x => x.projectTypeEnum === models.ProjectTypeEnum.NewDeal);

          this.projectTypeCheckboxes = [
            new ProjectTypeCheckbox(DealType.LeaseRenewal, renewalTypes),
            new ProjectTypeCheckbox(DealType.LeaseRestructure, restructureTypes),
            new ProjectTypeCheckbox(DealType.Maintenance, maintenanceTypes),
            new ProjectTypeCheckbox(DealType.NewDeal, newDealTypes)
          ];
        }
      });


    this.leaseExpirationCheckboxes = [
      new LeaseExpirationFilterCheckbox(LeaseExpirationFilterEnum.In3Years),
      new LeaseExpirationFilterCheckbox(LeaseExpirationFilterEnum.Within2And3Years),
      new LeaseExpirationFilterCheckbox(LeaseExpirationFilterEnum.Within1And2Years),
      new LeaseExpirationFilterCheckbox(LeaseExpirationFilterEnum.LessThan1Year),
    ];

    this._applicationQuery.getLandlordDashboardDisabledValue$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(x => {
        this.filterDisabled = x;

        if (!(<ViewRef>this._changeDetectorRef).destroyed) {
          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  itemClick(i) {
    this.portfolio = this.portfolios[i];
    this.activeIndex = i;
    this._applicationStore.updateSelectedPortfolio(this.portfolio);
    const assetFilters = <models.IAssetFilter>{portfolioIds: [this.portfolio.id]};
    this.onClearAssetsFilter(null);
    this._applicationStore.updateLandlordDashboardAssetFilter(assetFilters);
  }

  onSidebarCollapsed(isCollapsed, portfolio: models.IPortfolioViewModel) {
    if (!isCollapsed) {
      this._applicationStore.updateSelectedPortfolio(portfolio);
      const assetFilters = <models.IAssetFilter>{portfolioIds: [portfolio.id]};
      this.onClearAssetsFilter(null);
      this._applicationStore.updateLandlordDashboardAssetFilter(assetFilters);
    }
  }

  onPortfolioCheckValueChanged($event, portfolio: models.IPortfolioViewModel) {
    if ($event.value) {
      this._checkedPortfolios.push(portfolio);
      portfolio.buildings.forEach(x => {
        this.assetsCheckboxes.push(new AssetCheckbox(x));
      });
    } else {
      this._checkedPortfolios = this._checkedPortfolios.filter(x => x.id !== portfolio.id);
      this.assetsCheckboxes = this.assetsCheckboxes.filter(x => x.building.portfolioId !== portfolio.id);
    }

    this._setAssetsFilter();

  }

  onAssetValueChanged($event, building: models.IBuildingViewModel) {
    if ($event.value) {
      this._checkedBuildings.push(building);
    } else {
      this._checkedBuildings = this._checkedBuildings.filter(x => x.id !== building.id);
    }
    this._setAssetsFilter();
  }

  onClearAssetsFilter($event) {
    this._checkedBuildings = [];
    this._checkedPortfolios = [];
    this.assetsCheckboxes = [];

    this.portfolioCheckboxes.forEach(x => {
      x.checked = false;
    });
    this._setAssetsFilter();
  }

  onClearFundsFilter($event) {
    this.fundsCheckboxes.forEach(x => {
      x.checked = false;
    });
    this._setFundsFilter();
  }

  onClearDealsFilter($event) {
    this._checkedPortfolios = [];
    this._checkedProjectTypes = [];
    this.projectTypeCheckboxes.forEach(x => {
      x.checked = false;
    });

    this.projectTemplateItemCheckboxes.forEach(x => {
      x.checked = false;
    });
    this._setDealsFilter();
  }

  onClearLeaseExpirationDates($event) {
    this.leaseExpirationCheckboxes.forEach(x => {
      x.checked = false;
    });

    this._setLeasesFilter();
  }

  onProjectTemplateItemCheckboxValueChanged(event, projectTemplateItem: models.IProjectTemplateItem) {
    if (event.value) {
      this._checkedProjectTemplateItems.push(projectTemplateItem);
    } else {
      this._checkedProjectTemplateItems = this._checkedProjectTemplateItems.filter(x => x.id !== projectTemplateItem.id);
    }

    this._setDealsFilter();
  }

  onProjectTypeCheckboxChecked(event, checkbox: ProjectTypeCheckbox): void {
    if (!event) {
      return;
    }

    if (event.value) {
      this._checkedProjectTypes = [...checkbox.projectTypes];

      this.projectTypeCheckboxes = this.projectTypeCheckboxes
        .map(x => {
          if (x.dealType !== checkbox.dealType) {
            return {...x, checked: false};
          }

          return x;
        });
    } else {
      this._checkedProjectTypes = [];
    }

    this.dealStageGroups = this.getDealStageGroupsBySelectedDealTypes();

    this._setDealsFilter();
  }

  onLeaseExpirationCheckboxValueChanged($event, leaseExpirationFilterEnum: LeaseExpirationFilterEnum) {
    const leaseExpirationDates: Array<[Date, Date]> = [];

    this.leaseExpirationCheckboxes.filter(x => x.checked).forEach(leaseExpirationCheckbox => {
      leaseExpirationDates.push(this._getLeaseExpirationInterval(leaseExpirationCheckbox.leaseExpirationFilterEnum));
    });

    this._leaseExpirationDates = leaseExpirationDates;

    this._setLeasesFilter();
  }

  onFundsFilterCheckboxChanged($event) {
    this._setFundsFilter();
  }

  onSearchFilterTextChanged($event) {
    this._applicationStore.updateLandlordDashboardSearchText(this.searchFilterText);
  }

  getAssetFilterName(building: models.IBuildingViewModel): string {
    const result = ' ';
    if (building && building.address && building.address.displayString) {
      return building.address.displayString;
    }

    if (building && building.name) {
      return building.name;
    }

    return result;
  }

  getDealStagesBySelectedDealTypes(): Array<ProjectTemplateItemCheckbox> {
    if (
      !this.projectTypeCheckboxes || !this.projectTypeCheckboxes.length || this.projectTypeCheckboxes.every(x => !x.checked) ||
      !this.projectTemplateItemCheckboxes || !this.projectTemplateItemCheckboxes.length
    ) {
      return [];
    }

    const projectTypeIds = [].concat(
      ...this.projectTypeCheckboxes
        .filter(x => x.checked)
        .map(x => x.projectTypes.map(type => type.id))
    );

    return this.projectTemplateItemCheckboxes
      .filter(x => projectTypeIds.includes(x.projectTemplateItem.projectType.id))
      .sort(x => x.projectTemplateItem.stageNumber);
  }

  getDealStageGroupsBySelectedDealTypes(): Array<DealStageGroup> {
    const stages = this.getDealStagesBySelectedDealTypes();
    if (!stages || !stages.length) {
      return [];
    }

    const groupsObj = stages
      .reduce(
        (acc, checkbox) => {
          if (!checkbox || !checkbox.projectTemplateItem || !checkbox.projectTemplateItem.projectType) {
            return acc;
          }

          const projectType = checkbox.projectTemplateItem.projectType.projectTypeEnum;

          (acc[projectType] = acc[projectType] || []).push(checkbox);

          return acc;
        },
        {} /** acc */
      );

    return Object
      .keys(groupsObj)
      .map(projectType => new DealStageGroup(DealStageGroupName[projectType], groupsObj[projectType]));
  }

  handleStageCheckboxContentReady(event, checkbox: ProjectTemplateItemCheckbox): void {
    if (!event || !event.element || !checkbox || !checkbox.projectTemplateItem) {
      return;
    }

    const textContainer = event.element.querySelector('.dx-checkbox-text');
    if (!textContainer) {
      return;
    }

    const stageNumberElement = document.createElement('span');

    stageNumberElement.classList.add('stage-number');

    stageNumberElement.append(checkbox.projectTemplateItem.stageNumber.toString(10));

    textContainer.prepend(stageNumberElement);
  }

  private _setLeasesFilter() {
    const leaseExpirationFilters: Array<models.ILeaseExpirationFilter> = [];

    this._leaseExpirationDates.forEach(x => {
      leaseExpirationFilters.push(<models.ILeaseExpirationFilter>{
        startLeaseExpirationDate: x[0],
        endLeaseExpirationDate: x[1],
      });
    });

    this._applicationStore.updateLeaseExpirationFilters(leaseExpirationFilters);
  }

  private _setFundsFilter() {
    const fundsFilter: Array<models.IFundFilter> = [];

    this.fundsCheckboxes.filter(x => x.checked).forEach(x => {
      fundsFilter.push(<models.IFundFilter>{
        fund: x.funds
      });
    });

    this._applicationStore.updateFundsFilters(fundsFilter);
  }

  private _setAssetsFilter() {
    const assets: Array<models.IBuildingViewModel> = [];

    this._checkedPortfolios.forEach(value => {
      value.buildings.forEach(building => assets.push(building));
    });

    this.assets = assets;

    const assetFilter = <models.IAssetFilter>{
      portfolioIds: this._checkedPortfolios.map(x => x.id),
      assetIds: this._checkedBuildings.map(x => x.id),
    };

    this._applicationStore.updateLandlordDashboardAssetFilter(assetFilter);
  }

  private _setDealsFilter() {
    const projectTemplateIds: Array<models.IProjectTemplateItem> = [];

    this._checkedProjectTemplateItems.forEach(parentProjectTemplate => {
      this._projectTemplateItems.forEach(projectTemplate => {
        if ((projectTemplate.id === parentProjectTemplate.id)
          || (projectTemplate.parentProjectTemplateItem && projectTemplate.parentProjectTemplateItem.id === parentProjectTemplate.id)) {
          projectTemplateIds.push(projectTemplate);
        }
      });
    });

    const dealsFilter = <models.IDealsFilter>{
      projectTemplateIds: projectTemplateIds.map(x => x.id),
      projectTypes: this._checkedProjectTypes.map(x => x.id),
    };

    this._applicationStore.updateLandlordDashboardDealsFilter(dealsFilter);
  }

  private _getLeaseExpirationInterval(leaseExpirationFilterEnum: LeaseExpirationFilterEnum): [Date, Date] {
    let startDate: Date;
    let endDate: Date;
    switch (leaseExpirationFilterEnum) {
      case LeaseExpirationFilterEnum.LessThan1Year: {
        startDate = new Date();
        endDate = moment(new Date()).add(1, 'y').toDate();
        return [startDate, endDate];
      }
      case LeaseExpirationFilterEnum.Within1And2Years: {
        startDate = moment(new Date()).add(1, 'y').toDate();
        endDate = moment(new Date()).add(2, 'y').toDate();
        return [startDate, endDate];
      }
      case LeaseExpirationFilterEnum.Within2And3Years: {
        startDate = moment(new Date()).add(2, 'y').toDate();
        endDate = moment(new Date()).add(3, 'y').toDate();
        return [startDate, endDate];
      }
      case LeaseExpirationFilterEnum.In3Years: {
        startDate = moment(new Date()).add(3, 'y').toDate();
        endDate = moment(new Date()).add(100, 'y').toDate();
        return [startDate, endDate];
      }
    }
  }
}
