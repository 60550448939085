<div class="video-player">
  <ng-container [ngSwitch]="playerType">
    <!-- Start: HTML5 Player -->
    <ng-container *ngSwitchCase="PlayerType.HTML5">
      <app-video-player-html5 [source]="source"
                              [playObserver]="playObserver"
                              [pauseObserver]="pauseObserver"
                              (loaded)="handleLoaded()"
                              (playing)="handlePlaying()"
                              (played)="handlePlayed()"
                              (paused)="handlePaused()">
      </app-video-player-html5>
    </ng-container>
    <!-- End: HTML5 Player -->
    <!-- Start: YouTube Player -->
    <ng-container *ngSwitchCase="PlayerType.YouTube">
      <app-video-player-youtube [source]="source" [muted]="muted"
                                [playObserver]="playObserver"
                                [pauseObserver]="pauseObserver"
                                (loaded)="handleLoaded()"
                                (playing)="handlePlaying()"
                                (played)="handlePlayed()"
                                (paused)="handlePaused()">
      </app-video-player-youtube>
    </ng-container>
    <!-- End: YouTube Player -->
    <!-- Start: Vimeo Player -->
    <ng-container *ngSwitchCase="PlayerType.Vimeo">
      <app-video-player-vimeo [source]="source" [muted]="muted"
                              [playObserver]="playObserver"
                              [pauseObserver]="pauseObserver"
                              (loaded)="handleLoaded()"
                              (playing)="handlePlaying()"
                              (played)="handlePlayed()"
                              (paused)="handlePaused()">
      </app-video-player-vimeo>
    </ng-container>
    <!-- End: Vimeo Player -->
  </ng-container>
</div>
