import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { FeatureTogglingRepository } from './feature-toggling.repository';

import { CompanyFeatureToggles, Feature } from './feature-toggling.model';

@Injectable()
export class FeatureTogglingManager {
  private readonly _featureTogglingRepository: FeatureTogglingRepository;
  private readonly _loggerService: LoggerService;

  constructor(
    featureTogglingRepository: FeatureTogglingRepository,
    loggerService: LoggerService
  ) {
    this._featureTogglingRepository = featureTogglingRepository;
    this._loggerService = loggerService;
  }

  getOrRequestCompanyFeatureToggle(feature: Feature): Observable<boolean> {
    return this
      .getCompanyFeatureToggles()
      .pipe(
        take(1),
        switchMap(companyFeatureToggles => {
          if (!companyFeatureToggles) {
            return this.requestCompanyFeatureToggles();
          }

          return of(companyFeatureToggles);
        }),
        map(companyFeatureToggles =>
          companyFeatureToggles.hasOwnProperty(feature) &&
          companyFeatureToggles[feature]
        ),
      );
  }

  getCompanyFeatureToggle(feature: Feature): Observable<boolean> {
    return this
      .getCompanyFeatureToggles()
      .pipe(
        filter(companyFeatureToggles => !!companyFeatureToggles),
        map(companyFeatureToggles =>
          companyFeatureToggles.hasOwnProperty(feature) &&
          companyFeatureToggles[feature]
        ),
      );
  }

  getCompanyFeatureToggleSync(feature: Feature): boolean {
    const companyFeatureToggles = this._featureTogglingRepository.getStoredCompanyFeatureTogglesSync();
    if (!companyFeatureToggles) {
      return false;
    }

    return (
      companyFeatureToggles.hasOwnProperty(feature) &&
      companyFeatureToggles[feature]
    );
  }

  requestCompanyFeatureToggles(): Observable<CompanyFeatureToggles> {
    return this._featureTogglingRepository
      .requestCompanyFeatureToggles()
      .pipe(
        tap(companyFeatureToggles => {
          this._featureTogglingRepository
            .setStoredCompanyFeatureToggles(companyFeatureToggles);

          this._loggerService.info(
            LoggerTopic.AvailableUnit,
            `Company feature toggles received and stored`,
            companyFeatureToggles,
          );
        }),
      );
  }

  getCompanyFeatureToggles(): Observable<CompanyFeatureToggles> {
    return this._featureTogglingRepository
      .getStoredCompanyFeatureToggles();
  }
}
