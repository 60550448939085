import { Component, Input } from '@angular/core';

import { DialogRefService } from '../../../../dialog/services/dialog-ref.service';

import * as models from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-term-custom-value-template-upsert-dialog',
  templateUrl: './term-custom-value-template-upsert-dialog.component.html',
  styleUrls: ['./term-custom-value-template-upsert-dialog.component.scss']
})
export class TermCustomValueTemplateUpsertDialogComponent {
  @Input() template: models.ITermCustomValueTemplateViewModel;

  private readonly _dialogRefService: DialogRefService;

  constructor(
    dialogRefService: DialogRefService,
  ) {
    this._dialogRefService = dialogRefService;
  }

  handleCancelClick(): void {
    this.close();
  }

  handleSaveClick(): void {
    this._dialogRefService.outputData = {
      template: this.template,
    };

    this.close();
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
