import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-market',
  templateUrl: 'analytics-filters-location-market.component.html',
  styleUrls: ['analytics-filters-location-market.component.scss'],
})
export class AnalyticsFiltersLocationMarketComponent {
  @Input() locationMarkets: Array<analyticsFiltersModels.LocationMarketFilter>;
  @Output() locationMarketsChange: EventEmitter<Array<analyticsFiltersModels.LocationMarketFilter>>;

  constructor() {
    this.locationMarketsChange = new EventEmitter<Array<analyticsFiltersModels.LocationMarketFilter>>();
  }

  handleMarketSelection(): void {
    this.locationMarketsChange.next([...this.locationMarkets]);
  }
}
