<div class="d-flex align-items-start dashes dashes-position" *ngFor="let step of steps; let i = index">
  <div class=" d-flex pl-3 ">
    <div class="step d-flex flex-column" [ngClass]="{ 'bg-primary': step.id === activeStepId }" id="{{ 'point' + i }}" (click)="toggleTooltip(i)">
      <span>
        {{ i + 1 }}
      </span>
    </div>
  </div>

  <div class="d-flex pl-2 pb-2 flex-grow-1">
    <div class="d-flex flex-column pb-2 flex-grow-1">
      <span class="text-dark text-14 text-capitalize pb-1 step-name text-muted" [ngClass]="{ 'fw-600  step-active': step.active }">
        {{ step.name }}
      </span>
    </div>
  </div>

  <dx-tooltip [target]="'#point' + i" [(visible)]="tooltipVisible[i]" width="300" position="right">
    <div *dxTemplate="let data = data; of: 'content'">
      <div>
        {{ steps[i].description }}
      </div>
      <div>
        {{ getMilestoneInterval(i) }}
      </div>
    </div>
  </dx-tooltip>
</div>


<!-- <timeline></timeline> -->
