import { StaticProvider } from '@angular/core';

import { ImageViewerService } from '../services/image-viewer.service';

export class ImageViewerOptions {
  title?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  fullScreen?: boolean;
  dragEnabled?: boolean;

  providers?: Array<StaticProvider>;
  imageViewerService?: ImageViewerService;
  activeIndex?: number;
  allowChangeMarkers?: boolean;
  enableArrowNavigation: boolean;
  enableZoom?: boolean;
}
