import { EventEmitter } from '@angular/core';

import { DialogOptions } from '../../models/dialog.model';

export abstract class BaseDialogContainerComponent {
  abstract readonly onShowing: EventEmitter<any>;
  abstract readonly onHiding: EventEmitter<any>;
  abstract readonly onShown: EventEmitter<any>;
  abstract readonly onHidden: EventEmitter<any>;
  abstract readonly onContentReady: EventEmitter<any>;

  abstract readonly config: DialogOptions;

  isVisible: boolean;
  title: string | null;

  abstract getContainerClassNames(): string;
  abstract show();
  abstract hide();
  abstract repaint();
  abstract setTitle(title: string | null);
}
