<div [ngSwitch]="reportType" class="key-statistics-report">
  <div class="key-statistics-report__header">
    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.LeasingActivity">
      <p>Leasing Activity Report: Choose Fields to Export</p>
    </ng-container>

    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.Inquiries">
      <p>Inquiries: Choose Fields to Export</p>
    </ng-container>

    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.Basic">
      <p>Basic Report</p>
    </ng-container>

    <button (click)="close()" class="key-statistics-report__close-btn">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.00001 4.38907L8.88909 0.5L10 1.61094L6.11095 5.50001L10 9.38909L8.88909 10.5L5.00001 6.61095L1.11094 10.5L0 9.38909L3.88907 5.50001L0 1.61094L1.11094 0.5L5.00001 4.38907Z"
          fill="#686F7B"/>
      </svg>
    </button>
  </div>

  <div class="key-statistics-report__body">
    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.LeasingActivity">
      <div class="report__body-checkbox-group">
        <span class="checkbox-group-title">basic deal details</span>

        <div class="select-all">
          <label class="custom-checkbox-container" for="selectAll">Select all
            <input type="checkbox" id="selectAll" [(ngModel)]="allBasicDealDetailsFieldsSelected" (click)="toggleAllBasicDetailsFields()">
            <span class="checkmark"></span>
          </label>
        </div>

        <label class="custom-checkbox-container" *ngFor="let field of basicDetailsFields">{{ field.title }}
          <input type="checkbox" [(ngModel)]="selectedLeasingActivityFields[field.value]" (change)="updateBasicDetailsFieldsSelectAll()">
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="report__body-checkbox-group">
        <span class="checkbox-group-title">Financial Details</span>
        <div class="select-all">
          <label class="custom-checkbox-container" for="selectAll2">Select all
            <input type="checkbox" id="selectAll2" [(ngModel)]="allFinancialDealDetailsFieldsSelected" (click)="toggleAllFinancialFields()">
            <span class="checkmark"></span>
          </label>
        </div>

        <label class="custom-checkbox-container" *ngFor="let field of financialDetailsFields">{{ field.title }}
          <input type="checkbox" [(ngModel)]="selectedLeasingActivityFields[field.value]" (change)="updateFinancialDetailsFieldsSelectAll()">
          <span class="checkmark"></span>
        </label>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.Inquiries">
      <div class="report__body-checkbox-group">
        <span class="checkbox-group-title">Inquiry details</span>
        <div class="select-all">
          <label class="custom-checkbox-container" for="selectAll3">Select all
            <input type="checkbox" id="selectAll3" [(ngModel)]="allInquiriesFieldsSelected" (click)="toggleAllInquiriesFields($event)">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="report__body-checkbox-group no-column">
          <label class="custom-checkbox-container" *ngFor="let field of inquiriesFields">{{ field.title }}
            <input type="checkbox" [(ngModel)]="selectedInquiriesFields[field.value]" (change)="updateInquiriesSelectAll()">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="KeyStatisticsReportExportType.Basic">
      <p class="basic-report">Export a basic Statera report that will contain the basic Leasing Activity and Inquiries Information</p>
    </ng-container>

  </div>
    <div class="key-statistics-report__footer">

        <button class="cancel" (click)="close()">
          cancel
        </button>

        <div class="export-button-group">
          <button class="export" [disabled]="!isExportButtonEnabled()" (click)="exportReportClick()">
            export
          </button>

          <div class="export-custom-select" style="width:70px;">
            <select [(ngModel)]="selectedFileType">
              <option [value]="ExportFileType.Pdf">PDF</option>
              <option [value]="ExportFileType.Excel">XLS</option>
            </select>
          </div>
        </div>
    </div>
  </div>
