import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { InvitationManager } from '@statera/sdk/invitation';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'greeting-dialog.component.html',
  styleUrls: ['greeting-dialog.component.scss'],
})
export class GreetingDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('slideElementRef') slideElementRefs: QueryList<ElementRef>;

  slideIndex: number;
  slideOffset: number;
  slidesCount: number;

  invitationRequest: models.IUserInvitationRequestViewModel;
  invitationRequests: Array<models.IUserInvitationRequestViewModel>;

  isCoBroker: boolean;

  readonly startupInfo: models.IStartupInfoViewModel;

  private readonly _ngZone: NgZone;
  private readonly _router: Router;
  private readonly _dialogRefService: DialogRefService;
  private readonly _alertService: AlertService;
  private readonly _invitationManager: InvitationManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    ngZone: NgZone,
    router: Router,
    dialogRefService: DialogRefService,
    alertService: AlertService,
    invitationManager: InvitationManager,
  ) {
    this._ngZone = ngZone;
    this._router = router;
    this._dialogRefService = dialogRefService;
    this._alertService = alertService;
    this._invitationManager = invitationManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    if (!this.startupInfo) {
      return;
    }

    this.slideIndex = 0;
    this.slideOffset = 0;
    this.slidesCount = 0;

    this.invitationRequest = <models.IUserInvitationRequestViewModel>{};
    this.invitationRequests = [];

    this._alertService.stopRenderingAlerts();

    this._dialogRefService.onContentReady
      .pipe(
        tap((event) => {
          if (!event || !event.component) {
            return;
          }

          const contentElement = event.component.content();
          if (contentElement) {
            const parent = contentElement.parentElement;
            if (parent) {
              parent.style.setProperty('border-radius', '6px', 'important');
            }
          }

          this._dialogRefService.repaint();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._alertService.startRenderingAlerts();

    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    if (!this.slideElementRefs || !this.slideElementRefs.length) {
      return;
    }

    this.slidesCount = this.slideElementRefs.length;
  }

  close(): void {
    this._dialogRefService
      .onHiding
      .pipe(
        tap(() => {
          this._router.navigate(['profile', 'profile-edit']);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._dialogRefService.hide();
  }

  nextSlide(): void {
    this.slideOffset = this._getSlideOffset(++this.slideIndex);
  }

  prevSlide(): void {
    this.slideOffset = this._getSlideOffset(--this.slideIndex);
  }

  identify(idx: number) {
    return idx <= this.slideIndex;
  }

  private _getSlideOffset(i: number): number {
    if (!this.slideElementRefs || !this.slideElementRefs.length) {
      return;
    }

    const slides = this.slideElementRefs.toArray();
    if (!slides || !slides.length) {
      return;
    }

    const slide = slides[i] && slides[i].nativeElement;
    if (!slide) {
      return;
    }

    return slide.clientWidth * i;
  }
}
