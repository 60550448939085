<div class="b-card">
  <h1 class="b-header fancy text-capitalize">
    add building
  </h1>

  <div class="row d-flex flex-column"
       *ngIf="portfolios.length === 0" >
    <div class="pl-3">
      You have no portfolio yet. Please create at least one to save building and leases data
    </div>
    <div>
      <a class="nav-link text-uppercase text-13 header-text"
        [routerLink]="'../add-portfolio'">
        Add portfolio
      </a>
    </div>
  </div>

  <app-building-form
    *ngIf="portfolios.length > 0">
  </app-building-form>
</div>
