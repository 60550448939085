import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ProjectStore } from '../states/project/project.store';

import { SharedConstants } from '../constants/shared.constants';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';
import { ProjectAccessService } from './project-access.service';

@Injectable()
export class ProjectService {
  private readonly _httpClient: HttpClient;
  private readonly _projectStore: ProjectStore;
  private readonly _projectAccessService: ProjectAccessService;

  constructor(
    httpClient: HttpClient,
    projectStore: ProjectStore,
    projectAccessService: ProjectAccessService
  ) {
    this._httpClient = httpClient;
    this._projectStore = projectStore;
    this._projectAccessService = projectAccessService;
  }

  deleteCertificateOfInsuranceFile(insuranceOfCertificateFileId: number, leaseId: number): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('leaseId', leaseId.toString())
        .set('insuranceCertificateFileId', insuranceOfCertificateFileId.toString())
    };
    return this._httpClient
      .delete(`${environment.webApiUrl}/insuranceCertificate/delete`, options);
  }

  getProjectsByLeaseId(leaseId: number): Observable<Array<models.IProjectViewModel>> {
    const options = {
      params: new HttpParams().set('leaseId', leaseId.toString())
    };

    return this._httpClient
      .get<Array<models.IProjectViewModel>>(`${environment.webApiUrl}/project/GetByLeaseId`, options)
      .pipe(
        tap(projects => {
          if (projects) {
            projects.forEach(project => {
              this._projectStore.update(project.id, project);
            });
          }
        }),
      );
  }

  renewalProject(
    lease: models.ILeaseViewModel,
    renewalProjectTriggerType: models.RenewalProjectTriggerType,
    project: models.IProjectViewModel,
    approvingAppointment: models.IApprovingAppointmentViewModel = null,
    isAcceptTiNegotiationByTenantOrLandlord: boolean = null,
    isAcceptTiOptionAfterFloorPlan: boolean = null,
    rejectionLeaseViewModel: models.IRejectionLeaseViewModel = null
  ) {
    let params = new HttpParams();

    if (!approvingAppointment) {
      approvingAppointment = <models.IApprovingAppointmentViewModel>{};
    }

    if (rejectionLeaseViewModel && rejectionLeaseViewModel.dateToRevisit) {
      rejectionLeaseViewModel.dateToRevisit = moment(rejectionLeaseViewModel.dateToRevisit)
        .startOf('day')
        .toDate();
    }

    const clientUtcOffset = ((new Date()).getTimezoneOffset() / 60) * -1;

    const renewalProjectParamsViewModel = <models.IRenewalProjectParamsViewModel>{
      leaseViewModel: lease,
      approvingAppointment: approvingAppointment,
      isAcceptTiNegotiationByTenantOrLandlord: isAcceptTiNegotiationByTenantOrLandlord,
      isAcceptTiOptionAfterFloorPlan: isAcceptTiOptionAfterFloorPlan,
      clientUtcOffset: clientUtcOffset,
      projectId: project.id,
      rejectionLeaseViewModel,
    };

    if (renewalProjectTriggerType) {
      params = params.set('renewalProjectTriggerType', renewalProjectTriggerType.toString());
    }

    return this._httpClient
      .post<models.ILeaseViewModel>(
        `${environment.webApiUrl}/project/RenewalProject`,
        renewalProjectParamsViewModel,
        {
          params,
        });
  }

  insuranceCertificateProject(
    insuranceCertificateParamsViewModel: models.IInsuranceCertificateParamsViewModel,
    insuranceCertificateTriggerType: models.InsuranceCertificateTriggerType
  ) {
    let params = new HttpParams();

    if (insuranceCertificateTriggerType) {
      params = params.set('insuranceCertificateTriggerType', insuranceCertificateTriggerType.toString());
    }

    return this._httpClient
      .post<models.IInsuranceCertificateParamsViewModel>(`${environment.webApiUrl}/project/InsuranceCertificateProject`,
        insuranceCertificateParamsViewModel, {params});
  }

  updateSignDocumentState(lease: models.ILeaseViewModel): Observable<boolean> {
    let params = new HttpParams();

    if (lease && lease.id) {
      params = params.set('leaseId', lease.id.toString());
    }

    return this._httpClient
      .get<boolean>(`${environment.webApiUrl}/project/UpdateDocumentSigningState`, {params});
  }

  getProjects(): Observable<Array<models.IProjectViewModel>> {
    return this._httpClient
      .get<Array<models.IProjectViewModel>>(`${environment.webApiUrl}/project/GetProjects`)
      .pipe(
        tap(projects => this._projectStore.set(projects)),
      );
  }

  getStatisticsProjects(): Observable<Array<models.IProjectViewModel>> {
    return this._httpClient
      .get<Array<models.IProjectViewModel>>(`${environment.webApiUrl}/project/GetStatisticsProjects`);
  }

  hasActionRequired(project: models.IProjectViewModel, lease: models.ILeaseViewModel): boolean {
    if (project && project.projectState) {
      return this._projectAccessService
        .checkAccessToRenewalProject(project.projectState.renewalProjectTemplateItemType, project, lease);
    }

    return false;
  }

  isClosed(project: models.IProjectViewModel): boolean {
    if (!project) {
      return false;
    }

    if (project.projectStatus === models.ProjectStatus.Completed || project.projectStatus === models.ProjectStatus.Rejected) {
      return true;
    }

    if (project && project.projectState) {
      return project.projectState.renewalProjectTemplateItemType === SharedConstants.ClosedProjectStateType;
    }

    return false;
  }

  getProjectStatistic(projectId: number): Observable<models.IProjectStatisticViewModel> {
    const options = {
      params: new HttpParams().set('projectId', projectId.toString()),
    };

    return this._httpClient
      .get<models.IProjectStatisticViewModel>(`${environment.webApiUrl}/project/GetProjectStatistic`, options);
  }

  deleteLeaseDocumentDraft(versionedFileId: number) {
    return this._httpClient
      .post<models.ILeaseViewModel>(`${environment.webApiUrl}/project/DeleteVersionedFile/${versionedFileId}`, null);
  }

  generate(lease: models.ILeaseViewModel) {
    return this._httpClient
      .post<models.ILeaseViewModel>(`${environment.webApiUrl}/project/GenerateTermsSummary`, lease);
  }

  getActiveProject(projects: Array<models.IProjectViewModel>): models.IProjectViewModel {
    if (projects) {
      return projects.find(x => x.projectStatus === models.ProjectStatus.Active);
    }
  }

  getNegotiationProject(projects: Array<models.IProjectViewModel>): models.IProjectViewModel {
    return projects.find(x => x.projectTypeId === models.ProjectTypeEnum.Renewal ||
      x.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord
      || x.projectTypeId === models.ProjectTypeEnum.Restructure
      || x.projectTypeId === models.ProjectTypeEnum.NewDeal);
  }

  getCertificateOfInsuranceProject(projects: Array<models.IProjectViewModel>): models.IProjectViewModel {
    return projects.find(x => x.projectTypeId === models.ProjectTypeEnum.InsuranceCertificate);
  }

  getProjectIndicatorClass(leaseId: number, projects: Array<models.IProjectViewModel>, lease: models.ILeaseViewModel): string {
    if (!projects) {
      return '';
    }

    const leaseProjects = projects.filter(x => x.leaseId === leaseId);
    const statuses = [];
    if (leaseProjects) {
      for (let i = 0; i < leaseProjects.length; i++) {
        const project = leaseProjects[i];
        if (!project) {
          return '';
        }

        if (this.hasActionRequired(project, lease)) {
          statuses.push('error');
        }

        if (this.isClosed(project)) {
          statuses.push('closed');
        } else {
          statuses.push('success');
        }
      }
    }

    const errorStatus = statuses.find(x => x === 'error');

    if (errorStatus) {
      return errorStatus;
    }

    const successStatus = statuses.find(x => x === 'success');

    if (successStatus) {
      return successStatus;
    }

    return 'closed';
  }

  getProjectName(project: models.IProjectViewModel): string {
    if (project) {
      switch (project.projectTypeId) {
        case models.ProjectTypeEnum.Renewal:
        case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
          return 'Renewal';
        case models.ProjectTypeEnum.Restructure:
          return 'Restructure';
        case models.ProjectTypeEnum.InsuranceCertificate:
          return 'Insurance Of Certificate';
        case models.ProjectTypeEnum.NewDeal:
          return 'New Deal';
        case models.ProjectTypeEnum.NewDealInquiry:
          return 'Inquiry';
      }
    }
  }

  convertInquiryToNewDeal(projectId: number): Observable<models.IProjectViewModel> {
    return this._httpClient
      .post<models.IProjectViewModel>(
        `${environment.webApiUrl}/project/${projectId}/ConvertToNewDeal`,
        null);
  }
}
