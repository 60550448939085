import * as models from '@statera/sdk/common';

export type ChatMessage = models.IChatMessageViewModel;
export type AlertGroup = models.IAlertGroupViewModel;

export class WebsocketResponse<T> {
  model: T;
  isPulling: boolean;
}

export class StageChanged {
  leaseId: number;
  projectId: number;
  isProjectCompleted: boolean;
  isApprovalSubStage: boolean;
  fromRenewalStageType?: models.RenewalProjectTemplateItemType;
  toRenewalStageType?: models.RenewalProjectTemplateItemType;
  fromInsuranceCertificateStageType?: models.InsuranceCertificateProjectTemplateItemType;
  toInsuranceCertificateStageType?: models.InsuranceCertificateProjectTemplateItemType;
}

export class RefreshColabo {
  leaseId: number;
  reason: string;
}
