<section class="name-edit">
    <div class="d-flex flex-column align-items-center pt-3">
        <button class="btn bg-transparent cancel-btn">
          <span class="pr-1"
          inlineSVG = 'assets/img/arrow-back.svg'> </span>
          <span class="text-capitalize text-15 text-primary" 
            [routerLink]="['../profile']">
            cancel
          </span>  
        </button>
        <img class="profile-photo" src="assets/img/avatar.jpg" alt="profile photo">
        <span class="edit-photo" 
         inlineSVG = 'assets/img/edit-picture.svg'> </span>
        <div class="pt-2">
          <span class="pt-2 profile-name">
            {{displayName}}
          </span>
        </div>
        <span class="pt-1 text-muted text-14">
          Tenant
        </span>
      </div>
      <hr/>
      
  <form class="p-3">
    <div class="d-flex flex-column pb-3">
      <label class="text-14 text-capitalize">
        name
      </label>
      <input class="rounded" type="text">
      <span class="text-14 name-definition pt-2">
          Displayed on your public profile, notifications and other places
      </span>
    </div>
    <div class="d-flex flex-column pb-3">
      <label class="text-14 text-capitalize">
        screen name
      </label>
      <input class="rounded" type="text">
    </div>
    <hr/>
    <div>
        <button class="btn btn-primary w-100"> 
        <span class="text-uppercase">
          save changes
        </span>
      </button>
    </div>
  </form>
</section>
