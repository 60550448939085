import {Component, Input, OnInit} from '@angular/core';
import * as models from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-comparison-report-change-indicator',
  templateUrl: './comparison-report-change-indicator.component.html',
  styleUrls: ['./comparison-report-change-indicator.component.scss']
})
export class ComparisonReportChangeIndicatorComponent implements OnInit {

  @Input() valueDirection: models.ValueDirection;
  @Input() valueDifferenceInPercent: number;
  @Input() valueDifference: number;
  @Input() valueDifferenceInMoney: number;
  @Input() showColorDirection = true;
  @Input() numberFormat = '1.2-2';
  ValueDirection = models.ValueDirection;
  constructor() { }

  ngOnInit(): void {
  }

  abs(value: number): number {
    return Math.abs(value);
  }

}
