import * as ng from '@angular/core';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';
import { ComponentLoaderService } from '../../infrastructure/services/component-loader.service';

import { DialogRefService } from './dialog-ref.service';

import { BaseDialogContainerComponent } from '../components/base-dialog-container/base-dialog-container.component';
import { DialogContainerComponent } from '../components/dialog-container/dialog-container.component';

import { DialogOptions } from '../models/dialog.model';

@ng.Injectable()
export class DialogService {
  private readonly _renderer: ng.Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;

  private readonly _loaders: Array<ComponentLoaderService<BaseDialogContainerComponent>>;
  private readonly _config: DialogOptions;

  constructor(rendererFactory: ng.RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
    this._loaders = [];
    this._config = new DialogOptions();
  }

  show(
    content: ng.TemplateRef<any> | ng.Type<any> | string,
    config?: DialogOptions,
  ): DialogRefService {
    this._createLoaders();

    const dialogRef = this._showDialog(content, {...this._config, ...config}, DialogContainerComponent);

    this._updatePageScrollBehavior();

    return dialogRef;
  }

  hide(id?: number): void {
    this._hideDialog(id);

    this._removeLoader(id);

    this._updatePageScrollBehavior();
  }

  private _createLoaders(): void {
    const loader = this._componentLoaderFactory
      .createLoader<BaseDialogContainerComponent>(null, null, null);
    this._loaders.push(loader);
  }

  private _removeLoader(id?: number): void {
    if (typeof id !== 'number') {
      this._loaders.splice(0, this._loaders.length);
      return;
    }

    const idx = this._loaders.findIndex(x => x.instance.config.id === id);
    if (0 <= idx) {
      this._loaders.splice(idx, 1);
    }
  }

  private _updatePageScrollBehavior(): void {
    if (!this._loaders || !this._loaders.length) {
      this._renderer.removeClass(document.documentElement, 'no-scroll');
      return;
    }

    this._renderer.addClass(document.documentElement, 'no-scroll');
  }

  private _showDialog(
    content: ng.TemplateRef<any> | ng.Type<any> | string,
    config: DialogOptions,
    baseDialogContainerComponent: ng.Type<BaseDialogContainerComponent>,
  ): DialogRefService {
    const loader = this._loaders[this._loaders.length - 1];
    if (this._config && this._config.providers) {
      for (let i = 0, len = this._config.providers.length; i < len; i++) {
        loader.provide(this._config.providers[i]);
      }
    }

    const dialogRef = new DialogRefService();

    const dialogContainerRef = loader
      .provide({provide: DialogRefService, useValue: dialogRef})
      .provide({provide: DialogOptions, useValue: {...config, dialogService: this, id: this._loaders.length - 1}})
      .attach(baseDialogContainerComponent);

    dialogContainerRef.show(content, null, config.injectableData);

    dialogRef.hide = () => dialogContainerRef.instance.hide();
    dialogRef.hideAll = () => {
      for (let i = this._loaders.length - 1; 0 <= i; i--) {
        this._loaders[i].instance.hide();
      }
    };

    dialogRef.repaint = () => dialogContainerRef.instance.repaint();
    dialogRef.setTitle = (title: string) => dialogContainerRef.instance.setTitle(title);

    return dialogRef;
  }

  private _hideDialog(id?: number): void {
    if (typeof id !== 'number') {
      for (let i = 0, len = this._loaders.length; i < len; i++) {
        const loader = this._loaders[i];
        loader.hide(loader.instance.config.id);
      }

      return;
    }

    const idx = this._loaders.findIndex(x => x.instance.config.id === id);
    if (0 <= idx) {
      const loader = this._loaders[idx];
      loader.hide(loader.instance.config.id);
    }
  }
}
