import { Injectable } from '@angular/core';

import * as models from '@statera/sdk/common';

@Injectable()
export class AlertMessagesManager {
  getInvitationRequestSentAlertText(): string {
    return 'Your invitation request has been sent';
  }

  getTeamMemberRemovedAlertText(): string {
    return 'Team member successfully removed';
  }

  getFinancialsUploadedAlertText(): string {
    return 'Financials uploaded successfully';
  }

  getUserVerificationLinkSentAlertText(): string {
    return (
      'Success / A verification link has been sent to your email address. ' +
      'Please click on the link to finish the registration process'
    );
  }

  getResetPasswordLinkSentAlertText(): string {
    return (
      'A reset password link has been sent to your email address. ' +
      'Please click on the link, in order to continue the reset password process'
    );
  }

  getPasswordChangedAlertText(): string {
    return 'Your password was changed successfully';
  }

  get2faCodeResentAlertText(): string {
    return 'Sms was sent successfully';
  }

  getElectedTermsSavedAlertText(): string {
    return 'Your changes have been successfully saved';
  }

  getNotificationRequestSendAlertText(): string {
    return 'The notification request sent successfully, please check the log files if you did not receive the letter';
  }

  getJobStartedAlertText(): string {
    return 'The job successfully started. Please check alerts page.';
  }

  getPortfolioCreatedAlertText(): string {
    return 'The portfolio was successfully created';
  }

  getBuildingCreatedAlertText(): string {
    return 'The Building was successfully created';
  }

  getLandlordCancelSurveyAdditionalInfoProvidedAlertText(): string {
    return 'Thank you for providing additional information to the Tenant';
  }

  getTenantCancelSurveyAdditionalInfoProvidedAlertText(): string {
    return 'Thank you for providing the additional information. Please continue to use the system to communicate as needed';
  }

  getFinancialsRequestSendAlertText(): string {
    return 'Financials request sent successfully';
  }

  getAvatarSavedAlertText(): string {
    return 'The avatar has been successfully saved';
  }

  getProfileSavedAlertText(): string {
    return 'The profile was successfully saved! Feel free to explore the application.';
  }

  getCoBrokerInvitationCancelledAlertText(): string {
    return 'Understood! Please proceed and happy negotiating 😊';
  }

  getCertificateOfInsuranceRequestAlertText(): string {
    return 'Please check all sections that apply, provide any necessary details and submit to Tenant.';
  }

  getWaitingForDraftLeaseAmendmentAlertText(projectType: models.ProjectTypeEnum): string {
    let documentName: string;
    switch (projectType) {
      case models.ProjectTypeEnum.NewDeal:
        documentName = 'Lease Document';
        break;
      default:
        documentName = 'Lease Amendment';
    }

    return `Waiting for Landlord to submit a draft of the ${documentName}`;
  }

  getFunctionNotImplementedAlertText(): string {
    return 'This function is not implemented yet';
  }

  getFinalizeLeaseRequestAlertText(): string {
    return `Great, let's proceed with finalizing the request.`;
  }

  getUploadingAbortedAlertText(): string {
    return 'Uploading aborted';
  }

  getEversignErrorAlertText(): string {
    return 'The Eversign service is not initialized. The document may already be signed.';
  }

  getTenantImprovementsTermNotSetAlertText(): string {
    return `You should set 'Accepted' or 'Rejected' status for Tenant Improvements term first.`;
  }

  getTermsStatusesNotSetAlertText(): string {
    return `You should set 'Accept', 'Ready' or 'Reject' status for all terms`;
  }

  getProfileRequiredFieldsEmptyAlertText(): string {
    return 'Please fill out required fields in the profile.';
  }

  getTooEarlyToInitiateDealAlertText(): string {
    return 'This function is currently not available, as the lease has more than 2 years remaining.';
  }

  getOnlyIndustrialBuildingTypeCanBeSelectedAlertText(): string {
    return 'Currently only Industrial can be selected';
  }

  getMarkersAmountRequiredAlertText(): string {
    return 'You should fill amount value for each marker or fill total estimated cost only.';
  }

  getAllFloorPlanMarkersAmountRequiredAlertText(): string {
    return 'You should fill amount value for each marker or fill total estimated cost only for all floor plans.';
  }

  getWaitUntilPortfolioWillBeUploadedByAdministratorAlertText(): string {
    return 'Please wait until administrator uploads all portfolio information.';
  }

  getCanNotSelectMoreQuizItemsAlertText(): string {
    return 'You cannot choose any more items';
  }

  getFinancialsUploadErrorAlertText(): string {
    return 'Unable to upload financial file due to an error. Please try again.';
  }

  getCertificateOfInsuranceFormNotFilledAlertText(): string {
    return 'Please review attached form and upload updated Certificate of Insurance.';
  }

  getSaveElectedTermsErrorAlertText(): string {
    return 'Cannot save changes due to an error. Please try again.';
  }

  getLeaseNotSetAlertText(): string {
    return 'The lease not set';
  }

  getEversignLoadErrorAlertText(): string {
    return `We were unable to open Eversign. <br/>Please contact system administrator if the issue persists.`;
  }

  getRealEstateTaxesOpExNotMatchAlertText(): string {
    return 'Total for OpEx did not match, please insert correct information.';
  }

  getInsertRealEstateTaxesEstimatedOpExValueAlertText(): string {
    return 'Insert value for estimated OpEx';
  }

  getGenerateSummaryOfTermsErrorAlertText(): string {
    return 'Oops, something happened.';
  }

  getConfirmResendInvitationAlertText(): string {
    return 'Are you certain you would like to resend invitation?';
  }

  getConfirmRfpSubmitAlertText(): string {
    return 'Are you certain you would like to submit an RFP for this available space?';
  }

  getConfirmInquirySendAlertText(): string {
    return 'Are you certain you would like to submit an Inquiry on this available space?';
  }

  getConfirmRejectUpdatedCertificateOfInsuranceAlertText(): string {
    return 'Are you certain that the Tenant is compliant and you want to bypass this step?';
  }

  getConfirmCertificateOfInsuranceComplianceAlertText(): string {
    return 'Are you certain the COI is in compliance?';
  }

  getConfirmCertificateOfInsuranceNonComplianceAlertText(): string {
    return 'Are you certain the COI is not in compliance?';
  }

  getConfirmCancelDealAlertText(): string {
    return 'Are you certain you wish to cancel this Negotiation?';
  }

  getConfirmSetMultipleOptionsTermPreviousValueAlertText(): string {
    return (
      `Hold on ✋!  All responses that you provided for the multiple options ` +
      `will be reverted back to the previous answer.  ` +
      `Are you certain you want to proceed?`
    );
  }

  getConfirmNonCustomMultipleOptionSelectionAlertText(): string {
    return (
      '<h6 style="color:orange;margin-bottom:16px;">' +
        'WARNING&nbsp;' +
        '<span style="display:inline-block;width:20px;height:20px;line-height:20px;' +
                     'text-align:center;color:white;background-color:orange;border-radius:50%;">' +
          '!' +
        '</span>' +
      '</h6>' +
      '<p style="font-weight:600;">Please confirm</p>' +
      '<p>' +
        'By choosing Option A or Option B, you are agreeing to all the remaining terms provided for that Option.  ' +
        'If this is not correct, please choose Custom option.' +
      '</p>' +
      '<p>' +
        '<span style="font-weight:600;">Note:</span> ' +
        'You will have a chance to Modify these in next stage, if necessary.' +
      '</p>'
    );
  }

  getConfirmApproveTenantImprovementsAlertText(): string {
    return 'Do you approve tenant improvements list?';
  }

  getConfirmSendScheduleContractorVisitAlertText(): string {
    return 'Send selected date(s) & time(s) to Tenant for review?';
  }

  getConfirmBypassContractorVisitAlertText(): string {
    return 'Are you sure you want to bypass the contractor visit to obtain budgetary pricing?';
  }

  getConfirmSendTenantImprovementsCostsAlertText(): string {
    return 'Are you sure you want to send TI cost breakdown for review?';
  }

  getConfirmApproveTenantImprovementsCostsAlertText(): string {
    return (
      'Are you certain you want to approve the total budget cost? ' +
      'Upon approval you will be redirected to Colábo, in order to finalize the Tenant Improvements term.'
    );
  }

  getConfirmProceedToBudgetAlertText(): string {
    return 'Are you certain you have all budget numbers to provide to Tenant?';
  }

  getConfirmSendOfferAlertText(): string {
    return 'Are you certain you would like to send?';
  }

  getConfirmSendLOIAlertText(): string {
    return 'Are you certain you would like to proceed?';
  }

  getConfirmSendUnsolicitedOfferAlertText(): string {
    return 'Are you certain you would like to send?';
  }

  getConfirmProceedUnsolicitedOfferByTenantAlertText(): string {
    return 'Are you certain you would like to proceed?';
  }

  getConfirmRejectUnsolicitedOfferByTenantAlertText(): string {
    return 'Are you certain you would like to reject?';
  }

  getConfirmProceedWithoutFinancialsAlertText(): string {
    return (
      'Wait a minute, financials have not been approved. ' +
      'Are you certain you want to proceed without financials?'
    );
  }

  getConfirmContinueAlertText(): string {
    return 'Are you certain you would like to continue?';
  }

  getConfirmTenantShouldUploadCertificateOfInsuranceAlertText(): string {
    return 'Now that the Lease is signed, do you need the Tenant to upload an updated COI for compliance?';
  }

  getFirstCertificateOfInsuranceUploadAlertText(): string {
    return 'Now that the Lease is signed, do you need the Tenant to upload a COI for compliance?';
  }

  getConfirmTenantComplianceAlertText(): string {
    return 'Are you certain that the Tenant is compliant?';
  }

  getConfirmUploadFinancialsAlertText(): string {
    return 'Financial documents will need to be provided and approved before going to Lease.';
  }

  getConfirmAddTenantImprovementsTermWithFloorPlanBypassAlertText(): string {
    return (
      '<h6 style="color:red;margin-bottom:16px;">' +
      'IMPORTANT&nbsp;' +
      '<span style="display:inline-block;width:20px;height:20px;line-height:20px;' +
      'text-align:center;color:white;background-color:#C33C35;border-radius:50%;">' +
      '!' +
      '</span>' +
      '</h6>' +
      '<p style="font-weight:600;">Please confirm</p>' +
      '<p>' +
      'You are adding Tenant Improvements term. ' +
      'There is no option to utilize the Floor Plan at this point. ' +
      'Do you want to proceed?' +
      '</p>'
    );
  }

  getConfirmUploadLeaseAmendmentForTenantReviewAlertText(projectTypeId: number): string {
    const documentName = projectTypeId === models.ProjectTypeEnum.NewDeal
      ? 'Lease Document'
      : 'Lease Amendment';

    return (
      'A fully executed copy of the LOI is in your Documents folder and also sent via email. ' +
      `<br/>Please upload the ${documentName} for Tenant\'s review,` +
      ' so they can either sign if in agreement or provide comments.'
    );
  }

  getConfirmAcceptedTermChangeAlertText(): string {
    return (
      'Hold on. You are changing an already accepted term. ' +
      'This may impact other terms in the negotiation. ' +
      'Are you certain you would like to proceed?'
    );
  }

  getConfirmChangeAcceptedTenantImprovementsTermAlertText(): string {
    return (
      'Hold on. You are changing an already accepted term. ' +
      'This may impact other terms in the negotiation. ' +
      'Are you certain you would like to proceed?'
    );
  }

  getConfirmRequestFloorPlanFromTenantAlertText(): string {
    return 'Are you sure you want to request a floor plan? This additional step allows both users to discuss each individual improvement and any associated costs.';
  }

  getCancelRequestFloorPlanFromTenantAlertText(): string {
    return 'Are you sure you want to Cancel?';
  }

  getConfirmChangeTenantImprovementsAlertText(): string {
    return (
      '<h6 style="color:red;margin-bottom:16px;">' +
      'IMPORTANT&nbsp;' +
      '<span style="display:inline-block;width:20px;height:20px;line-height:20px;' +
      'text-align:center;color:white;background-color:#C33C35;border-radius:50%;">' +
      '!' +
      '</span>' +
      '</h6>' +
      '<p style="font-weight:600;">Please confirm</p>' +
      '<p>' +
      'You are changing Tenant Improvements term, which may directly impact remaining economic terms. ' +
      'There is no option to utilize the Floor Plan at this point. ' +
      'Do you want to proceed?' +
      '</p>'
    );
  }

  getConfirmRequestTenantImprovementsAlertText(): string {
    return 'Please note that requesting any Tenant Improvements may cause the Landlord to modify other Lease Terms.';
  }

  getConfirmRenewalStartAlertText(): string {
    return 'Are you certain you would like to begin the Renewal negotiation?';
  }

  getConfirmInquiryConvertToDealAlertText(): string {
    return 'Are you certain you would like to begin the new deal negotiation?';
  }

  getConfirmRenewalRestructureAlertText(): string {
    return 'Are you certain you would like to begin the Restructure negotiation?';
  }

  getConfirmNewDealStartAlertText(): string {
    return 'Are you certain you would like to begin the new deal negotiation?';
  }

  getConfirmCancelDeadByLandlordAlertText(): string {
    return 'Are you sure you want to cancel this request and return to the negotiation?';
  }

  getConfirmCancelDeadByTenantAlertText(): string {
    return 'Are you sure you want to cancel this request and return to the negotiation?';
  }

  getConfirmChangeQuizAnswerAlertText(): string {
    return 'Are you sure change your answer? All next answers will be deleted';
  }

  getConfirmSkipQuizUploadFinancialsAlertText(): string {
    return (
      'You are choosing to skip, Landlord may still request financials during the negotiation. ' +
      'The economics of the transaction may be affected as a result.'
    );
  }

  getConfirmMarkerMoveAlertText(): string {
    return 'Are you sure you want to move this item?';
  }

  getConfirmApproveFinancialsAlertText(): string {
    return 'Are you sure you want to approve the financials?';
  }

  getConfirmAcceptVisitDateAlertText(): string {
    return 'Are you sure you want to Approve selected date, as all other dates will be deleted?';
  }

  getConfirmDeleteContractorVisitAlertText(): string {
    return 'Are you sure you want to delete the scheduled contractor visit?';
  }

  getConfirmAcceptContractorVisitRescheduleAlertText(): string {
    return 'Are you sure you want to Approve selected date?';
  }

  getReadyToContinueAlertText(): string {
    return (
      `Super! Now, please press <span class="alert-button-badge">Continue</span> to get to the next stage.`
    );
  }

  getBrokerInvitedAlertText(brokerName: string): string {
    return (
      `Waiting for ${brokerName} to join the negotiation. ` +
      `You will be notified as soon as they arrive. ` +
      `In the meantime you may utilize the system as needed until your landlord rep joins the deal.`
    );
  }

  getBrokerInvitedByTenantAlertText(brokerName: string): string {
    return `Waiting for ${brokerName} to join the negotiation. You will be notified as soon as they arrive.`;
  }

  getTenantImprovementsModifiedAlertText(companyName: string): string {
    return (
      `${companyName} has modified the Tenant Improvements term.`
    );
  }

  getCheckLandlordFinancialRequestAlertText(landlordCompanyName: string): string {
    return (
      `Great news! Before going to sign the LOI, we'll verify with ${landlordCompanyName} ` +
      `if they need your updated financials before proceeding. Please stand by.`
    );
  }

  getTermValueNotSetAlertText(termName: string): string {
    return `You should fill term - ${termName}`;
  }

  getNeedToSetAllTermStatusesAlertText(termStatus: string): string {
    return `You should set '${termStatus}' status for all terms`;
  }

  getConfirmFloorPlanSendForApproveAlertText(clientType: string): string {
    return `Are you sure want to send to ${clientType} for approval?`;
  }

  getConfirmFloorPlanIsValidBeforeSendAlertText(isLandlord: boolean): string {
    return isLandlord
      ? `Let's make sure that everything is correct prior to sending.`
      : `Prior to sending the Floor Plan to the Landlord for review, let's make sure that everything is accurate.`;
  }

  getConfirmCancelTenantImprovementsAlertText(isLandlord: boolean): string {
    return isLandlord
      ? 'Hold on, are you sure you want to bypass the detailed Floor Plan process to determine the TI allowance?'
      : '<h6 style="color:red;margin-bottom:16px;">' +
        'IMPORTANT&nbsp;' +
        '<span style="display:inline-block;width:20px;height:20px;line-height:20px;' +
        'text-align:center;color:white;background-color:#C33C35;border-radius:50%;">' +
        '!' +
        '</span>' +
        '</h6>' +
        '<p style="font-weight:600;">Please confirm</p>' +
        '<p>' +
        'Wait a sec 👆🏻, are we cancelling the TI request and continuing the negotiations ' +
        'on an \'As-Is\' basis with no allowance?' +
        '</p>' +
        '<p>' +
        '<span style="font-weight:600;">Note:</span> ' +
        'You will no longer have access to request changes or improvements costs in this Floor Plan module.' +
        '</p>';
  }

  getConfirmUserIsDocumentSignerAlertText(displayName: string): string {
    return `Hello ${displayName}, will you be signing the Letter of Intent?`;
  }

  getConfirmUserIsDocumentSignerOfTheLeaseDocumentAlertText(displayName: string): string {
    return `Hello ${displayName}, will you be signing the Lease Document?`;
  }

  getConfirmSendOfferForApprovalAlertText(clientType: string): string {
    return `Are you sure want to send to ${clientType} for approval?`;
  }

  getConfirmAcceptLeaseDocumentAlertText(versionedFile: models.IVersionedFileViewModel,
                                         versionedFiles: Array<models.IVersionedFileViewModel>,
                                         projectType?: models.ProjectTypeEnum): string {
    const documentName = projectType === models.ProjectTypeEnum.NewDeal
      ? 'Lease Document'
      : 'Lease Amendment';

    return (versionedFile && versionedFile.version === 1 && versionedFiles && versionedFiles.length === 1)
      ? `Great, you're almost there 😃! Please sign the clean copy upon receipt.`
      : 'Once approved, you cannot change this document or upload new versions. ' +
        `Are you sure you want to approve the ${documentName}? Please save all changes before approval.`;
  }

  getConfirmRemoveTeamMemberAlertText(memberFullName: string): string {
    return `Are you certain you would like to remove ${memberFullName} from the transaction?`;
  }

  getConfirmLandlordInitiatedDealAlertText(tenantCompanyName: string): string {
    return `Are you certain you want to initiate a deal with ${tenantCompanyName}?`;
  }

  getConfirmInviteBrokerByTenantAlertText(landlordCompanyName: string): string {
    return (
      `<span class="red-colored">Please confirm:</span><br/><br/> ` +
      `By registering the Broker, you are agreeing that they shall be acknowledged by ${landlordCompanyName} ` +
      `as your Broker of Record for this transaction. Compensation to Broker shall be paid by ${landlordCompanyName} ` +
      `and is reflected as an expense of the total cost of the lease. ` +
      `The Broker will have the ability to negotiate on your behalf throughout the process, with your approval.`
    );
  }

  getConfirmTourAlternateDateText(): string {
    return 'Are you certain you would like to choose an Alternate Date and Time?';
  }

  getConfirmCancelTourText(): string {
    return 'Are you certain you would like to cancel the tour?';
  }

  getConfirmAreYouCertainText(): string {
    return 'Are you certain?';
  }

  getTourAlternateDateSentText(otherCompanyName: string): string {
    return (
      'You have successfully sent alternate date(s) and time(s). A message and email have been sent ' +
      `to ${otherCompanyName}, waiting for their response.`
    );
  }

  getConfirmTourDateText(timeSlot: string): string {
    return `Are you certain you would like to confirm ${timeSlot}?`;
  }

  getConfirmOverrideBuildingsExistingTeamMembers(): string {
    return 'This will override the existing teams of these buildings. Are you sure you’d like to proceed?';
  }

  getPreviousButtonConfirmationMessage(): string {
    return 'If you revert back to the Previous answer, any inputs you made will be lost.  Are you certain you want to go to the Previous response??';
  }

  getConfirmLandlordBrokerInvitationDialogCloseAlertText(): string {
    return 'Are you certain you want to cancel approval of this request?';
  }

  getInquiryConfirmArchiveMessage(): string {
    return 'Are you sure you\'d like to archive?';
  }

  getInquiryConfirmDeleteMessage(): string {
    return 'Are you sure you\'d like to delete?';
  }

  getConfirmSendChatMessageAlertText(): string {
    return 'Are you certain you want to send this message to both Landlord and Tenant teams?';
  }

  getConfirmSendCommentAlertText(): string {
    return 'Are you certain you want to send this Comment to both Landlord and Tenant teams?';
  }

  getConfirmDeleteAnchor(): string {
    return 'Are you sure you\'d like to delete?';
  }

  getConfirmFloorPlanDelete(): string {
    return 'Are you sure you want to delete the Floor Plan?';
  }
}
