import { Injectable } from '@angular/core';

import { LoggerDriver } from '../../logger.driver';
import { LoggerLevel, LoggerTopic } from '../../logger.model';

@Injectable()
export class ConsoleDriver implements LoggerDriver {
  private static readonly _timestampStyle = `color: #A6A6A6`;

  private static readonly _levelStyles = {
    'debug': 'color: #9AA2AA',
    'info': 'color: #659AD2',
    'warning': 'color: #F9C749',
    'error': 'color: #EC3D47',
  };

  write(level: LoggerLevel, topic: LoggerTopic, message: string, payload?: any): void {
    let levelStyle = ConsoleDriver._levelStyles[level];
    if (!levelStyle) {
      levelStyle = ConsoleDriver._levelStyles[LoggerLevel.Debug];
    }

    const timestamp = new Date().toISOString();

    const args = [
      `%c[${timestamp}] %c[${topic}] ${level}: ${message}`,
      ConsoleDriver._timestampStyle,
      levelStyle,
    ];

    if (typeof payload !== 'undefined') {
      if (level === LoggerLevel.Error) {
        args.push('\n');
      }

      args.push(payload);
    }

    console.log(...args);
  }
}
