import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { ProfileService } from '../../../user/services/profile.service';
import { AppService } from '../../services/app.service';

import * as models from '../../../infrastructure/models/generated';

declare let Driver: any;

@Component({
  selector: 'app-base-demo',
  templateUrl: './base-demo.component.html',
  styleUrls: ['./base-demo.component.scss'],
})
export class BaseDemoComponent implements OnInit, OnDestroy {
  introType: models.IntroType;
  IntroType = models.IntroType;
  driver: any;
  current = 0;
  component: any;
  visible = false;

  get isMobile(): boolean {
    return AppService.isMobile;
  }

  cssClassesForSteps = [];
  cssClassesForSkipButton = [];

  @Output() skipped = new EventEmitter();

  tpl = `<div class="demo">
  <div class="oxana">&nbsp;</div>
  <div class="content">
    <p>{message}</p>
  </div>
</div>`;

  steps = [];

  stepsStyle: object;

  protected profileService: ProfileService;
  protected authService: AuthService;
  protected alertService: AlertService;

  constructor(profileService: ProfileService, authService: AuthService, alertService: AlertService) {
    this.profileService = profileService;
    this.authService = authService;
    this.alertService = alertService;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.driver) {
      this.driver.reset();
    }
  }

  setStepsStyle(stepsStyle: object): void {
    this.stepsStyle = stepsStyle;
  }

  reset(): void {
    this.current = 0;

    this.driver = new Driver({
      showButtons: false,
      allowClose: false,
    });

    this.driver.defineSteps(this.steps);
    this.driver.start();
  }

  show(component: any): void {
    this.component = component;
    if (
      this.isMobile ||
      (this.authService.startupInfo.introsShown &&
        this.authService.startupInfo.introsShown.find(x => x.introType === this.introType))
    ) {
      return;
    }
    this.visible = true;
    if (!this.driver) {
      this.driver = new Driver({
        showButtons: false,
        allowClose: false,
      });
      this.driver.defineSteps(this.steps);
      this.driver.start();
    } else {
      this.driver.reset(true);
      this.goto(this.current);
    }
  }

  next() {
    if (this.current < this.steps.length - 1) {
      this.current++;
      this.goto(this.current);
    } else {
      this.visible = false;
      this.driver.reset();
      this.skip();
    }
  }

  goto(stepNumber: number) {
    this.current = stepNumber;
    this.driver.highlight(this.steps[stepNumber]);
  }

  protected getMessage(message: string) {
    return this.tpl.replace('{message}', message);
  }

  skip() {
    this.driver.reset();
    this.profileService.skipIntro(this.introType).subscribe(() => {
      this.visible = false;
      this.authService.startupInfo.introsShown.push(<models.IUserIntroViewModel>{
        userId: this.authService.startupInfo.id,
        introType: this.introType,
      });

      this.skipped.emit();
    });
  }

  isShowDemo(): boolean {
    return !(this.isMobile ||
      (
        this.authService.startupInfo.introsShown &&
        this.authService.startupInfo.introsShown.find(x => x.introType === this.introType)
      ));
  }

  protected checkElements(selectors) {
    const items = document.querySelectorAll(selectors);
    if (items === null || items.length < selectors.length) {
      return this.rafAsync().then(() => this.checkElements(selectors));
    } else {
      return Promise.resolve(true);
    }
  }

  protected elementExist(selector: string): boolean {
    const items = document.querySelectorAll(selector);
    if (items === null || !items.length) {
      return false;
    }

    return true;
  }

  protected rafAsync() {
    return new Promise(resolve => {
      requestAnimationFrame(resolve);
    });
  }

  protected removeDriverFixStackingClass() {
    const elements = document.querySelectorAll('.driver-fix-stacking');

    elements.forEach((element) => {
      element.classList.remove('driver-fix-stacking');
    });
  }

}
