import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { ImageCacheService } from './services/image-cache.service';
import { ImageViewerRefService } from './services/image-viewer-ref.service';
import { ImageViewerService } from './services/image-viewer.service';

import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImageViewerImageComponent } from './components/image-viewer-image/image-viewer-image.component';
import { ImageViewerImagePanoramaComponent } from './components/image-viewer-image-panorama/image-viewer-image-panorama.component';
import { ImageViewerImageStandardComponent } from './components/image-viewer-image-standard/image-viewer-image-standard.component';
import { ImageViewerContainerComponent } from './components/image-viewer-container/image-viewer-container.component';
import { ImageViewerMarkerDialogComponent } from './components/image-viewer-marker-dialog/image-viewer-marker-dialog.component';
import { ImageViewerPreloaderComponent } from './components/image-viewer-preloader/image-viewer-preloader.component';
import {InlineSVGModule} from 'ng-inline-svg';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        dx.DxButtonModule,
        dx.DxPopupModule,
        dx.DxScrollViewModule,
        dx.DxTextAreaModule,
        dx.DxTooltipModule,
        InlineSVGModule,
    ],
  declarations: [
    ImageViewerComponent,
    ImageViewerImageComponent,
    ImageViewerImagePanoramaComponent,
    ImageViewerImageStandardComponent,
    ImageViewerContainerComponent,
    ImageViewerMarkerDialogComponent,
    ImageViewerPreloaderComponent,
  ],
  exports: [
    ImageViewerComponent,
    ImageViewerImageComponent,
  ],
  providers: [
    ImageCacheService,
    ImageViewerRefService,
    ImageViewerService,
  ],
  entryComponents: [
    ImageViewerContainerComponent,
    ImageViewerMarkerDialogComponent,
  ],
})
export class ImageViewerModule {
  static forRoot(): ModuleWithProviders<ImageViewerModule> {
    return {
      ngModule: ImageViewerModule,
      providers: [
        ImageCacheService,
        ImageViewerRefService,
        ImageViewerService,
      ],
    };
  }
}
