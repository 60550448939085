import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { catchError, share, skipWhile, tap } from 'rxjs/operators';

import { AuthManager, Role } from '@statera/sdk/auth';

import { StorageService } from '../../infrastructure/services/storage.service';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
  private _isAuthenticated: boolean;

  // TODO: [FIXME] We must stop using Subject for additional logic, use pipe on the top-layer instead
  private _startupUserInfoSubject: BehaviorSubject<models.IStartupInfoViewModel>;
  private _isAuthenticatedSubject: ReplaySubject<boolean>;

  readonly infoLoadComplete: Observable<models.IStartupInfoViewModel>;
  readonly isAuthenticatedUser: Observable<boolean>;
  readonly defaultSourceUrl: string;
  readonly defaultAvatarUrl: string;

  startupInfo: models.IStartupInfoViewModel;

  // TODO: [FIXME] We must stop using forms for login/logout, use XMLHttpRequest instead
  logoutForm: any;

  private readonly _authManager: AuthManager;
  private readonly _router: Router;
  private readonly _storageService: StorageService;

  constructor(
    authManager: AuthManager,
    router: Router,
    storageService: StorageService
  ) {
    this._authManager = authManager;
    this._router = router;
    this._storageService = storageService;

    this._isAuthenticated = false;
    this.startupInfo = <models.IStartupInfoViewModel>{
      isPending: true,
      avatarOptions: {},
      lists: {
      }
    };

    this._startupUserInfoSubject = new BehaviorSubject<models.IStartupInfoViewModel>(null);
    this._isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    this.infoLoadComplete = this._startupUserInfoSubject.asObservable().pipe(skipWhile(x => !x), distinctUntilChanged());
    this.isAuthenticatedUser = this._isAuthenticatedSubject.asObservable().pipe(distinctUntilChanged());
    this.defaultAvatarUrl = environment.defaultAvatarUrl;
    this.defaultSourceUrl = environment.defaultSourceUrl;
  }

  get avatarUrl() {
    return this._isAuthenticatedAndStartupInfo() && this.startupInfo.avatarUrl
      ? this.startupInfo.avatarUrl
      : this.defaultAvatarUrl;
  }

  get avatarSourceUrl() {
    return this._isAuthenticatedAndStartupInfo() && this.startupInfo.avatarSourceUrl
      ? this.startupInfo.avatarSourceUrl
      : this.defaultSourceUrl;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  set isAuthenticated(val) {
    this._isAuthenticated = val;
  }

  // TODO: [FIXME] We must stop using view logic inside services
  get displayName() {
    return this._isAuthenticatedAndStartupInfo() && this.startupInfo.displayName
      ? this.startupInfo.displayName
      : 'Welcome!';
  }

  get role() {
    return this._isAuthenticatedAndStartupInfo() && this.startupInfo.role
      ? this.startupInfo.role
      : '';
  }

  get userId(): number {
    return this._isAuthenticatedAndStartupInfo() && this.startupInfo.id
      ? this.startupInfo.id
      : 0;
  }

  // TODO: [FIXME] We must stop using subscriptions in services
  /* this method called once at the app creating */
  getStartupInfo(): Observable<models.IStartupInfoViewModel> {
    return this._authManager
      .getIndependentStartupInfo()
      .pipe(
        share(),
        tap((startupInfo) => {
          this.startupInfo = startupInfo;

          this._isAuthenticated = true;
          this._isAuthenticatedSubject.next(true);
          this._startupUserInfoSubject.next(startupInfo);
        }),
        catchError((err) => {
          this._isAuthenticated = false;
          this._startupUserInfoSubject.error(err);
          this._isAuthenticatedSubject.next(false);

          return throwError(err);
        }),
      );
  }

  getUpdatedStartupInfo() {
    this._authManager
      .getIndependentStartupInfo()
      .subscribe(startupInfo => {
        this.startupInfo = startupInfo;
        this._startupUserInfoSubject.next(<models.IStartupInfoViewModel>startupInfo);
      });
  }

  setStartupInfo(startupInfo: models.IStartupInfoViewModel) {
    this.startupInfo = startupInfo;
    this._startupUserInfoSubject.next(startupInfo);
  }

  logout() {
    if (this.logoutForm) {
      this.logoutForm.submit();
    }

    this._purgeStartupInfo();
  }

  isTenant() {
    return this.role === Role.Tenant;
  }

  isLandlord() {
    return this.role === Role.Landlord;
  }

  isBroker() {
    return this.role === Role.Broker;
  }

  private _isAuthenticatedAndStartupInfo() {
    return this._isAuthenticated && this.startupInfo;
  }

  private _purgeStartupInfo() {
    this._isAuthenticated = false;
    this._startupUserInfoSubject.next(<models.IStartupInfoViewModel>{});
    this._isAuthenticatedSubject.next(false);
  }
}
