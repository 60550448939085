import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Configuration } from '@statera/sdk/common';

import { PointOfInterestManager } from './point-of-interest.manager';
import { PointOfInterestRepository } from './point-of-interest.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    PointOfInterestManager,
    PointOfInterestRepository,
  ],
})
export class PointOfInterestModule {
  static forChild(configuration: Configuration): ModuleWithProviders<PointOfInterestModule> {
    return {
      ngModule: PointOfInterestModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        PointOfInterestManager,
        PointOfInterestRepository,
      ],
    };
  }
}
