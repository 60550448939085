import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { BuildingComponent } from './components/building/building.component';
import { BuildingDesktopComponent } from './components/building-desktop/building-desktop.component';
import { PersonDesktopComponent } from './components/person-desktop/person-desktop.component';
import { AddPortfolioComponent } from './components/add-portfolio/add-portfolio.component';
import { AddBuildingComponent } from './components/add-building/add-building.component';
import { EditBuildingComponent } from './components/edit-building/edit-building.component';
import { EditBuildingMobileComponent } from './components/edit-building-mobile/edit-building-mobile.component';
import { LandlordModule } from './landlord.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {path: 'dashboard', component: DashboardComponent},
  {
    path: 'buildings',
    component: BuildingsComponent,
    children: [
      {path: '', component: BuildingComponent},
      {path: ':id', component: BuildingComponent},
      {path: 'add-portfolio', component: AddPortfolioComponent},
      {path: 'add-building', component: AddBuildingComponent},
      {path: 'edit-building/:id', component: EditBuildingComponent},
      {path: 'building-desktop/:id', component: BuildingDesktopComponent},
      {path: 'person-desktop/:id', component: PersonDesktopComponent},
    ]
  },
  {path: 'edit-building-mobile/:id', component: EditBuildingMobileComponent},
];

export const LandlordRouter: ModuleWithProviders<LandlordModule> = RouterModule.forChild(routes);
