<ul class="analytics-filters-location-address">
  <li *ngFor="let address of locationAddresses; let index = index">
    <app-analytics-filters-checkbox [name]="'address-' + index"
                                    [(value)]="address.isSelected"
                                    (valueChange)="handleAddressSelection()">
      <span class="d-inline-flex flex-row align-items-center mx-n1">
        <span class="px-1">
          <ng-container *ngIf="address.buildingPictureThumbnailUrl || address.buildingPictureUrl; else noPicture">
            <img class="analytics-filters-location-address-building-picture" width="32" height="32" alt="Building picture"
                 [src]="address.buildingPictureThumbnailUrl || address.buildingPictureUrl" />
          </ng-container>
          <ng-template #noPicture>
            <img width="32" height="32" alt="No picture" src="/assets/img/landlord/dashboard/image-placeholder.svg" />
          </ng-template>
        </span>
        <span class="px-1">
          {{address.name}}
        </span>
      </span>
    </app-analytics-filters-checkbox>
  </li>
</ul>
