import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { environment } from '../../../environments/environment';

export interface Properties {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private readonly _loggerService: LoggerService;
  private readonly _monitor: ApplicationInsights;

  constructor(loggerService: LoggerService) {
    this._loggerService = loggerService;

    if (!environment.applicationInsights || !environment.applicationInsights.enabled) {
      loggerService.info(LoggerTopic.General, 'Application insights logging disabled');
      return;
    }

    const { instrumentationKey } = environment.applicationInsights;
    if (!instrumentationKey) {
      loggerService.error(LoggerTopic.General, 'Cannot run application insights logging: Instrumentation key is not provided');
      return;
    }

    const monitor = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });

    monitor.loadAppInsights();

    this._monitor = monitor;
  }

  trackEvent(name: string, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackEvent({name}, properties));
  }

  trackException(exception: Error, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackException({exception}, properties));
  }

  trackTrace(message: string, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackTrace({message}, properties));
  }

  trackMetric(name: string, average: number, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackMetric({name, average}, properties));
  }

  startTrackPage(name: string): void {
    this._runIfConfigured(monitor => {
      monitor.startTrackPage(name);
      this._loggerService.debug(LoggerTopic.General, `Start of page tracking: ${name}`);
    });
  }

  stopTrackPage(name: string): void {
    this._runIfConfigured(monitor => {
      monitor.stopTrackPage(name);
      this._loggerService.debug(LoggerTopic.General, `End of page tracking: ${name}`);
    });
  }

  private _runIfConfigured(callback: (monitor: ApplicationInsights) => void): void {
    if (!this._monitor) {
      return;
    }

    callback(this._monitor);
  }
}
