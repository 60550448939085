import { Injectable } from '@angular/core';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { StorageDriver } from '../../storage.driver';

@Injectable()
export class LocalStorageDriver implements StorageDriver {
  private readonly _loggerService: LoggerService;

  constructor(loggerService: LoggerService) {
    this._loggerService = loggerService;
  }

  set<T>(key: string, value: T): void {
    try {
      const valueAsString = JSON.stringify(value);

      localStorage.setItem(key, valueAsString);
    } catch (exception) {
      this._loggerService.error(LoggerTopic.Storage, `Cannot store a value due to an error`, exception);
    }
  }

  get<T>(key: string): T {
    const itemAsString = localStorage.getItem(key);
    if (!itemAsString) {
      return null;
    }

    try {
      return <T>JSON.parse(itemAsString);
    } catch (exception) {
      return null;
    }
  }

  has(key: string): boolean {
    return localStorage.hasOwnProperty(key);
  }

  delete(key: string): void {
    if (!this.has(key)) {
      return;
    }

    localStorage.removeItem(key);
  }
}
