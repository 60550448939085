import * as models from '@statera/sdk/common';

export type LeaseHistoryRecordViewModel = models.ILeaseHistoryRecordViewModel;
export type LeaseTermConfiguration = models.ILeaseTermConfiguration;

export interface TermSettingDiff {
  TermSettingDiffTypeColor: TermSettingDiffTypeColor;
  Value: any;
  Role: string;
}

export enum TermSettingDiffTypeColor {
  Grey,
  Red,
  Green
}

export interface TermHistoryRecord {
  termSettingDiffs: Array<TermSettingDiff>;
  leaseTeam: models.LeaseTeam;
  shouldShowAcceptanceMark: boolean;
  isHighlighted: boolean;
  isModifiedValue: boolean;
  timestamp: Date;
  leaseTermCustomTable: models.ILeaseTermCustomTableViewModel;
  hasLeaseTermCustomTable: boolean;
  previewLeaseTermTable: models.ILeaseTermCustomTableViewModel;
  hasPreviewLeaseTermTable: boolean;
  createdOn?: Date;
  hasAdditionalValueTable: boolean;
  additionalValueTable: models.ILeaseTermCustomTableViewModel;
}

export interface TermHistoryResult {
  previousChanges: Array<TermHistoryRecord>;
  lastNotAcceptedValue: TermHistoryRecord;
  acceptedValue: TermHistoryRecord;
}
