<section class="w-auth w-auth--reset-email">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt="">
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">
        Statera's platform will shorten the timeline &amp; provide relevant information to the tenant and landlord in a
        more efficient manner
      </p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <h1 class="b-auth-title">
          Forgot your password?
        </h1><!--b-auth-title -->

        <form class="w-auth-form" name="form" novalidate
              #form="ngForm"
              (ngSubmit)="submit(form)">

          <fieldset [disabled]="submitted ? 'disabled' : null">
            <div class="d-flex flex-column">
              <span class="error-msg" *ngIf="form.submitted && emailAddress.hasError('required')">
                Required
              </span>

              <p class="b-label">
                Enter your Email to reset password
              </p>
              <input class="b-input" type="email" name="emailAddress" id="emailAddress"
                     required [(ngModel)]="vm.emailAddress" #emailAddress="ngModel"
                     [class.is-invalid]="form.submitted && emailAddress.hasError('required')">
            </div>

            <div class="d-flex flex-column align-items-center pt-4 w-100">
              <button class="btn  btn-sign-in">
                <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                <span class="text-uppercase">
                  reset password
                </span>
              </button>
            </div>
          </fieldset>
        </form>

        <div class="w-auth-help-text center">
          Back to

          <a [routerLink]="['../signin-user']" class="b-fancy-link">
            sign in
          </a>
        </div><!--w-auth-help-text-->
      </div>
    </section>
  </div><!--w-auth-content-->
</section><!--w-auth-->

<app-footer></app-footer>
