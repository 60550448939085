import * as models from '@statera/sdk/common';

export type Login = models.ILoginViewModel;
export type AuthenticateResult = models.IAuthenticateResultViewModel;
export type PhoneNumber = models.IPost2faCodeViewModel;
export type VerifyPhoneNumberRequest = models.IVerifyPhoneNumberViewModel;
export type RegisterRequest = models.IRegisterViewModel;
export type PasswordResetRequest = models.IPasswordResetRequestViewModel;
export type PasswordResetData = models.IPasswordResetViewModel;
export type Post2FaCodeRequest = models.IPost2faCodeViewModel;
export type StartupInfo = models.IStartupInfoViewModel;

export interface UserInfo {
  userName: string;
  displayName: string;
}

export type Role = models.Role;
export const Role = models.Role;
