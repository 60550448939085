import {Location} from '@angular/common';
import {Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AlertMessagesManager} from '@statera/sdk/alert';
import {Role} from '@statera/sdk/auth';
import {SelectItem} from '@statera/sdk/common';
import {Feature, FeatureTogglingManager} from '@statera/sdk/feature-toggling';
import {LeaseManager} from '@statera/sdk/lease';
import {ProjectManager} from '@statera/sdk/project';
import {DxSelectBoxComponent} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {combineLatest, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AlertService} from '../../../alert/services/alert.service';
import {AuthService} from '../../../auth/services/auth.service';

import {StateraUserClaimService} from '../../../auth/services/statera-user-claim.service';

import {GreetingDialogComponent} from '../../../broker/components/greeting-dialog/greeting-dialog.component';
import {TourFormComponent} from '../../../colabo/components/tour-form/tour-form.component';
import {TourInfoComponent} from '../../../colabo/components/tour-info/tour-info.component';
import {DialogService} from '../../../dialog/services/dialog.service';
import * as models from '../../../infrastructure/models/generated';
import {SitePlanComponent} from '../../../plan/components/site-plan/site-plan.component';

import {coBrokerInvitationByBrokerRequestQueryParam} from '../../../shared/constants/shared.constants';
import {LeaseService} from '../../../shared/services/lease.service';
import {ProjectAccessService} from '../../../shared/services/project-access.service';
import {ProjectService} from '../../../shared/services/project.service';
import {ProjectQuery} from '../../../shared/states/project/project.query';
import {ManageTeamDialogComponent} from '../../../team/components/manage-team-dialog/manage-team-dialog.component';
import {ProfileService} from '../../../user/services/profile.service';
import {TenantPagesService} from '../../../user/services/tenant-pages.service';

@Component({
  selector: 'app-negotiate',
  templateUrl: './negotiate.component.html',
  styleUrls: ['./negotiate.component.scss'],
})
export class NegotiateComponent implements OnInit, OnDestroy {
  @Output()
  changeLease = new EventEmitter();

  @ViewChild('leaseSelectBox') leaseSelectBox: DxSelectBoxComponent;
  QuestionType = models.QuestionType;
  id: number = null;
  selectedLeaseTabIndex = 0;
  selectedInquiryTabIndex = 0;
  selectedProjectTabIndex = 0;
  buildingPicture = '';
  noDataPendingVisible = false;
  BuildingClass: typeof models.BuildingClass = models.BuildingClass;
  LeaseStatus: typeof models.LeaseStatus = models.LeaseStatus;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  ProjectTypeEnum: typeof models.ProjectTypeEnum = models.ProjectTypeEnum;
  Feature: typeof Feature = Feature;


  lease: models.ILeaseViewModel = <models.ILeaseViewModel>{
    id: 0,
    landlordCompany: <models.ICompanyViewModel>{name: ''},
    building: <models.IBuildingViewModel>{
      attachments: [<models.IBuildingAttachmentViewModel>{}],
      address: <models.IAddressViewModel>{displayString: ''},
    },
  };
  tenantRequest?: models.ITenantRequestViewModel;

  leaseTabs: DataSource;
  inquiryTabs: DataSource;
  projectTabs = [
    {
      id: 'leases',
      text: 'Lease Abstract(s)',
    },
    {
      id: 'inquiries',
      text: 'Inquiries / Tour(s)',
    },
  ];

  activeStep: number;

  /* mobile view */
  steps = [];
  activeStepId = 0;
  templateItems: Array<models.IProjectTemplateItem> = [];

  activeTab = 'Details';
  leaseSelectItems = <Array<SelectItem>>[];
  accordionTitle = 'Show Less';
  leases: Array<models.ITenantDashboardViewModel> = [];
  inquiries: Array<models.ITenantDashboardViewModel> = [];
  leaseId: number;
  chatChannel: models.IChatChannel;
  leaseTermConfigurations: Array<models.ILeaseTermConfiguration>;
  projects: Array<models.IProjectViewModel>;
  currentProject: models.IProjectViewModel;
  leaseTeam: models.ILeaseTeamViewModel;
  toursLoaded = false;
  tour?: models.ITourViewModel;

  buildingPictures: Array<models.IBuildingAttachmentViewModel>;
  buildingPictureUrl = '';
  monthsCountToDetermineRestructureOrRenewalProject: number;
  _startupInfo: models.IStartupInfoViewModel;

  isProjectScheduleFeatureEnabled: Observable<boolean>;

  private readonly _route: ActivatedRoute;
  private readonly _router: Router;
  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _leaseService: LeaseService;
  private readonly _projectQuery: ProjectQuery;
  private readonly _projectService: ProjectService;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _projectManager: ProjectManager;
  private readonly _tenantPagesService: TenantPagesService;
  private readonly _stateraUserClaimService: StateraUserClaimService;
  private readonly _dialogService: DialogService;
  private readonly _profileService: ProfileService;
  private readonly _renderer: Renderer2;
  private readonly _ngZone: NgZone;
  private readonly _leaseManager: LeaseManager;
  private readonly _location: Location;
  private readonly _featureTogglingManager: FeatureTogglingManager;

  private readonly _destroy$: Subject<void>;

  constructor(
    route: ActivatedRoute,
    router: Router,
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    leaseService: LeaseService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    projectManager: ProjectManager,
    projectQuery: ProjectQuery,
    tenantPagesService: TenantPagesService,
    stateraUserClaimService: StateraUserClaimService,
    dialogService: DialogService,
    profileService: ProfileService,
    renderer: Renderer2,
    ngZone: NgZone,
    leaseManager: LeaseManager,
    location: Location,
    featureTogglingManager: FeatureTogglingManager
  ) {
    this._route = route;
    this._router = router;
    this._authService = authService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._leaseService = leaseService;
    this._projectQuery = projectQuery;
    this._projectService = projectService;
    this._projectAccessService = projectAccessService;
    this._projectManager = projectManager;
    this._tenantPagesService = tenantPagesService;
    this._stateraUserClaimService = stateraUserClaimService;
    this._dialogService = dialogService;
    this._profileService = profileService;
    this._renderer = renderer;
    this._ngZone = ngZone;
    this._leaseManager = leaseManager;
    this._location = location;
    this._featureTogglingManager = featureTogglingManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.leaseTabs = new DataSource({
      key: 'id',
      store: [],
      paginate: false,
    });
    this.inquiryTabs = new DataSource({
      key: 'id',
      store: [],
      paginate: false,
    });

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          if (!info) {
            return;
          }

          if (info.lists) {
            this.templateItems = info.lists.templateItems;
            this.leaseTermConfigurations = info.lists.leaseTermConfigurations;
            this.steps = this.templateItems
              .filter(x => !x.parentProjectTemplateItem)
              .map(x => {
                return {
                  id: x.id,
                  name: x.name,
                  description: x.description,
                };
              });
          }
          this._startupInfo = info;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._leaseService
      .getDashboardLeases()
      .pipe(
        tap(leases => {
          if (!leases || !leases.length) {
            return null;
          }

          const leaseTabsStore = this.leaseTabs.store();
          const inquiryTabsStore = this.inquiryTabs.store();
          if (leaseTabsStore) {
            leases
              .forEach(lease => {
                if (lease.projectType === models.ProjectTypeEnum.NewDealInquiry) {
                  inquiryTabsStore
                    .insert({
                      id: lease.id,
                      title: this._tenantPagesService.getLeaseDisplayName(lease),
                    });

                  return;
                }

                leaseTabsStore
                  .insert({
                    id: lease.id,
                    title: this._tenantPagesService.getLeaseDisplayName(lease),
                  });
              });

            this.leaseTabs.load();
            this.inquiryTabs.load();
          }

          this.leases = leases.filter(l => l.projectType !== models.ProjectTypeEnum.NewDealInquiry);
          this.inquiries = leases.filter(l => l.projectType === models.ProjectTypeEnum.NewDealInquiry);
          this._showGreetingsDialogForBroker(this._startupInfo, this.leases);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe(leases => {
        if (!leases) {
          return;
        }

        // In this case, we must subscribe inside the current subscription
        // to prevent the leases dashboard from reloading on param changes.
        const routeParams$ = this._route.params;
        const queryParams$ =  this._route.queryParams;

        combineLatest([routeParams$, queryParams$])
          .pipe(
            takeUntil(this._destroy$),
            map(param => {
              const currentLeaseId = (
                parseInt(param[0]['id'], 10) || this.leases?.[0]?.id || this.inquiries?.[0]?.id
              );

              if (!currentLeaseId) {
                this.noDataPendingVisible = true;
                return null;
              }

              const leaseIndex = this.leases.findIndex(x => x.id === currentLeaseId);
              const inquiryIndex = this.inquiries.findIndex(x => x.id === currentLeaseId);

              if (leaseIndex !== -1) {
                this.selectedLeaseTabIndex = leaseIndex;
                this.selectedProjectTabIndex = 0;
              } else if (inquiryIndex !== -1) {
                this.selectedInquiryTabIndex = inquiryIndex;
                this.selectedProjectTabIndex = 1;
              }

              this.leaseSelectItems = [
                <SelectItem>{
                  id: currentLeaseId,
                  text: `Lease abstract ${currentLeaseId}`,
                },
              ];

              return { currentLeaseId, queryParams: param[1] };
            }),
            switchMap(param => {
              if (this.isInquiriesTab()) {
                this.toursLoaded = false;
                this.tour = null;
                this.leaseTeam = null;

                return this._projectManager
                  .getProjectRequestByLeaseId(param.currentLeaseId)
                  .pipe(
                    tap(pr => this.tenantRequest = pr),
                    switchMap(_ => of(param)),
                  );
              }

              return of(param);
            }),
            switchMap(param => {
              const currentLeaseId = param?.currentLeaseId;
              if (!currentLeaseId) {
                return of(null);
              }

              return this._leaseService
                .getLeaseWithAbstracts(currentLeaseId)
                .pipe(
                  switchMap(lease => {
                    if (!lease) {
                      return of(null);
                    }

                    this.lease = lease;

                    this.id = lease.id;
                    this.leaseId = lease.id;

                    this.buildingPictures = this.getBuildingAttachments();

                    if (param.queryParams && param.queryParams.target === coBrokerInvitationByBrokerRequestQueryParam.target) {
                      this.showManageTeamDialog();
                    }

                    return this._projectQuery
                      .selectAll()
                      .pipe(
                        tap(projects => {
                          if (!projects) {
                            return;
                          }

                          this.projects = projects;
                          this.currentProject = this.projects.find(x => x.leaseId === currentLeaseId);

                          const project = this.projects.find(x => x.leaseId === currentLeaseId);
                          if (project) {
                            if (!this._projectService.isClosed(project)) {
                              this.activeStepId = project.projectStateId;
                            } else {
                              this.activeStepId = undefined;
                            }
                          }
                        }),
                      );
                  }),
                  switchMap(x => {
                    if (this.currentProject && this.isInquiriesTab()) {
                      return this._projectManager
                        .getProjectTours(this.currentProject.id)
                        .pipe(
                          tap(tours => {
                            this.toursLoaded = true;
                            this.tour = tours.find((t) => t.status !== models.TourStatus.Archived);
                          }),
                          switchMap(_ => {
                            return this._leaseManager.getLeaseTeam(this.lease.id)
                              .pipe(
                                tap(team => this.leaseTeam = team),
                              );
                          }),
                        );
                    }

                    return of(null);
                  }),
                  takeUntil(this._destroy$),
                );
            })
          )
          .subscribe();
      });

    this.isProjectScheduleFeatureEnabled = this._featureTogglingManager.getCompanyFeatureToggle(Feature.ProjectScheduleFeature);
  }

  private _showGreetingsDialogForBroker(info: models.IStartupInfoViewModel, leases: Array<models.ITenantDashboardViewModel>) {
    if (info && info.introsShown && leases && leases.length > 0) {
      const brokerGreetingIntro = info.introsShown.find(x => x.introType === models.IntroType.BrokerGreeting);
      if (!brokerGreetingIntro && info.role === Role.Broker) {
        this._showBrokerGreetingDialog(info);
      }
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getBuildingAttachments(): Array<models.IBuildingAttachmentViewModel> {
    if (!this.lease?.building?.attachments?.length) {
      return [
        <models.IBuildingAttachmentViewModel>{
          file: <models.IFileViewModel>{
            url: 'assets/img/nofoto.png',
          },
          buildingAttachmentType: models.BuildingAttachmentType.Picture,
        },
      ];
    }

    return this.lease.building.attachments;
  }

  onSelectionChanged(e) {
    if (!e || !e.selectedItem || this.id === e.selectedItem.id) {
      return;
    }

    this._router.navigate([this._authService.role.toLowerCase(), 'dashboard', 'lease', e.selectedItem.id]);
  }

  selectLeaseTab(e) {
    if (!e || !this.leases || !this.leases[e.itemIndex]) {
      return;
    }
    this._router.navigate([this._authService.role.toLowerCase(), 'dashboard', 'lease', this.leases[e.itemIndex].id]);
  }

  selectInquiryTab(e) {
    if (!e || !this.inquiries?.[e.itemIndex]) {
      return;
    }
    this._router.navigate([this._authService.role.toLowerCase(), 'dashboard', 'lease', this.inquiries[e.itemIndex].id]);
  }

  isTourButtonDisabled(): boolean {
    if (!this.toursLoaded) {
      return true;
    }

    return !!this.tour;
  }

  showTourRequestForm() {
    const tourSubject = new Subject<models.ITourViewModel>();
    tourSubject
      .pipe(
        tap((tour) => this.tour = tour),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    if (this.tour?.status === models.TourStatus.Scheduled) {
      this._dialogService.show(TourInfoComponent, {
        width: 330,
        height: 370,
        injectableData: {
          tenantCompany: this.lease.tenantCompany,
          landlordCompany: this.lease.landlordCompany,
          buildingUnit: this.lease.buildingUnit,
          building: this.lease.building,
          tour: this.tour,
          tour$: tourSubject,
          leaseTeam: models.LeaseTeam.TenantTeam,
          projectId: this.currentProject.id,
        },
      });
      return;
    }

    const counterPartUser = this._projectAccessService.getTurnRole(this.lease) === Role.Tenant
      ? this.leaseTeam.landlordTeam[0]?.user
      : this.leaseTeam.tenantTeam[0]?.user;
    const counterPartCompany = counterPartUser.company;

    this._dialogService
      .show(TourFormComponent, {
        width: 1220,
        height: 495,
        injectableData: {
          tenantCompany: this.lease.tenantCompany,
          landlordCompany: this.lease.landlordCompany,
          buildingUnit: this.lease.buildingUnit,
          building: this.lease.building,
          leaseTeam: models.LeaseTeam.TenantTeam,
          projectId: this.currentProject.id,
          counterPartUser: counterPartUser,
          counterPartCompany: counterPartCompany,
          tour: this.tour,
          tour$: tourSubject,
        },
      });
  }

  navigateToProjectSchedule(): void {
    if (!this.currentProject) {
      return;
    }

    const urlTree = this._router
      .createUrlTree(
        [
          'v2',
          'colabo',
          this.currentProject.id,
          'project-schedule'
        ],
        {},
      );

    const url = this._router.serializeUrl(urlTree);

    window.open(url, '_blank');
  }

  onStartNegotiation(btn?: HTMLButtonElement): void {
    if (!this.canStartNegotiation()) {
      return;
    }

    if (this.canRenewalLease()) {
      this.renewLease(btn);
      return;
    }

    if (this.canRestructureLease()) {
      this.restructureLease(btn);
      return;
    }
  }

  canStartNegotiation(): boolean {
    if (this.isInquiriesTab()) {
      return false;
    }

    return this.canRenewalLease() || this.canRestructureLease();
  }

  getNegotiationTooltip(): string {
    if (this.isInquiriesTab()) {
      return null;
    }

    if (this.canRenewalLease()) {
      return this.getRenewalButtonTooltip();
    }

    if (this.canRestructureLease()) {
      return this.getRestructureButtonTooltip();
    }

    return null;
  }

  renewLease(renewButton?: HTMLButtonElement): void {
    if (!this.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmRenewalStartAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          if (renewButton) {
            this._renderer.setAttribute(renewButton, 'disabled', 'disabled');
          }
        }),
        switchMap(() => this._leaseService
          .renewLease(this.lease)
          .pipe(
            switchMap((lease) => this._projectService
              .getProjectsByLeaseId(lease.id)
              .pipe(
                switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
                tap(() => {
                  if (renewButton) {
                    this._renderer.removeAttribute(renewButton, 'disabled');
                  }

                  this._ngZone.run(() => {
                    const urlTree = this._router
                      .createUrlTree(
                        [
                          'v2',
                          'wizard',
                          lease.id
                        ],
                        {},
                      );

                    const url = this._router.serializeUrl(urlTree);

                    window.location.href = url;
                  });
                }),
                takeUntil(this._destroy$),
              ),
            ),
            takeUntil(this._destroy$),
          ),
        ),
        catchError(err => {
          this._renderer.removeAttribute(renewButton, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return throwError(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  convertInquiryToDeal(renewButton?: HTMLButtonElement): void {
    if (!this.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmInquiryConvertToDealAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          if (renewButton) {
            this._renderer.setAttribute(renewButton, 'disabled', 'disabled');
          }
        }),
        switchMap(() => this._projectService
          .convertInquiryToNewDeal(this.currentProject?.id)
          .pipe(
            switchMap((project) => this._projectService
              .getProjectsByLeaseId(project.leaseId)
              .pipe(
                switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
                tap(() => {
                  if (renewButton) {
                    this._renderer.removeAttribute(renewButton, 'disabled');
                  }

                  this._ngZone.run(() => {
                    const urlTree = this._router
                      .createUrlTree(
                        [
                          'v2',
                          'wizard',
                          project.leaseId
                        ],
                        {},
                      );

                    const url = this._router.serializeUrl(urlTree);

                    window.location.href = url;
                  });
                }),
                takeUntil(this._destroy$),
              ),
            ),
            takeUntil(this._destroy$),
          ),
        ),
        catchError(err => {
          this._renderer.removeAttribute(renewButton, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return throwError(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  newDealLease(newDealButton: HTMLButtonElement): void {
    if (!this.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmNewDealStartAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this._renderer.setAttribute(newDealButton, 'disabled', 'disabled');
        }),
        switchMap(() => this._leaseService
          .newDeal(this.lease)
          .pipe(
            switchMap((lease) => this._projectService
              .getProjectsByLeaseId(lease.id)
              .pipe(
                switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
                tap(() => {
                  this._renderer.removeAttribute(newDealButton, 'disabled');
                  this._ngZone.run(() => {
                    const urlTree = this._router
                      .createUrlTree(
                        [
                          'v2',
                          'wizard',
                          lease.id
                        ],
                        {},
                      );

                    const url = this._router.serializeUrl(urlTree);

                    window.location.href = url;
                  });
                }),
                takeUntil(this._destroy$),
              ),
            ),
            takeUntil(this._destroy$),
          ),
        ),
        catchError(err => {
          this._renderer.removeAttribute(newDealButton, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  restructureLease(restructureButton: HTMLButtonElement): void {
    if (!this.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmRenewalRestructureAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this._renderer.setAttribute(restructureButton, 'disabled', 'disabled');
        }),
        switchMap(() => this._leaseService
          .restructureLease(this.lease)
          .pipe(
            switchMap((lease) => this._projectService
              .getProjectsByLeaseId(lease.id)
              .pipe(
                switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
                tap(() => {
                  this._renderer.removeAttribute(restructureButton, 'disabled');

                  this._ngZone.run(() => {
                    const urlTree = this._router
                      .createUrlTree(
                        [
                          'v2',
                          'wizard',
                          lease.id
                        ],
                        {},
                      );

                    const url = this._router.serializeUrl(urlTree);

                    window.location.href = url;
                  });
                }),
                takeUntil(this._destroy$),
              ),
            ),
            takeUntil(this._destroy$),
          ),
        ),
        switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
        catchError(err => {
          this._renderer.removeAttribute(restructureButton, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return throwError(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  /* mobile view */
  clickTab(tabName) {
    this.activeTab = tabName;
  }

  clickStep(i) {
    this.steps[i].active = true;
    this.activeStep = <number>i + 1;
  }

  isShowRenewalButton(): boolean {
    return this._projectManager.isRenewAvailable(this.leases, this.projects, this.lease, this.currentProject);
  }

  getMilestoneInterval(i): string {
    if (this.currentProject && this.currentProject.milestones) {
      const milestone = this.currentProject.milestones.find(x => x.templateItemId === this.steps[i].id);
      if (milestone) {
        return `${new Date(milestone.startDate).toDateString()} - ${new Date(
          milestone.endDate,
        ).toDateString()}`;
      }
    }
    return '';
  }

  onAccordionChanged($event) {
    if ($event.addedItems && $event.addedItems.length > 0) {
      this.accordionTitle = 'Show Less';
    } else {
      this.accordionTitle = 'Show More';
    }
  }

  projectTabChanged(tabIndex: number) {
    if (this.projectTabs[tabIndex].id === 'leases') {
      this.selectLeaseTab({
        itemIndex: this.selectedLeaseTabIndex,
      });
    } else {
      this.selectInquiryTab({
        itemIndex: this.selectedInquiryTabIndex,
      });
    }
  }

  getProjectIndicatorClass(index: number): string {
    if (!this.projects || !this.leases || !this.leases[index]) {
      return;
    }

    const leaseId = this.leases[index].id;
    return this._projectService.getProjectIndicatorClass(leaseId, this.projects, this.lease);
  }

  showManageTeamDialog(): void {
    const _dialogServiceRef =  this._dialogService.show(ManageTeamDialogComponent, {
      title: null,
      width: 1024,
      maxHeight: '90vh',
      injectableData: {
        lease: this.lease,
        role: this._authService.role,
      },
    });

    _dialogServiceRef
      .onHidden
      .pipe(
        takeUntil(this._destroy$),
        tap(t => {
          this._location.replaceState(
            this._router
              .createUrlTree(
                [this._location.path().split('?')[0]], // Get uri
              )
              .toString(),
          );
        })
      )
      .subscribe();
  }

  showSitePlanDialog(): void {
    this._dialogService.show(SitePlanComponent, {
      width: '95%',
      height: '95%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      dragEnabled: false,
      injectableData: {
        building: this.lease.building,
      },
    });
  }

  canRenewalLease() {
    return this._leaseManager
      .canRenewLease(this.lease, this._startupInfo, this.leases, this.projects);
  }

  canSendRfp() {
    return this._leaseManager
      .canConvertToNewDeal(this.lease, this._startupInfo, this.projects);
  }

  canRestructureLease() {
    return this._leaseManager
      .canRestructureLease(this.lease, this._startupInfo, this.leases, this.projects);
  }

  getRenewalButtonTooltip() {
    return this._leaseManager
      .getRenewalButtonTooltip(this.lease, this.currentProject, this.leases, this.projects, this._startupInfo);
  }

  getSendRfpButtonTooltip() {
    return this._leaseManager
      .getSendRfpButtonTooltip(this.currentProject, this._startupInfo);
  }

  getTourAlreadyRequestedButtonTooltip(): string {
    return 'A tour request has already been sent. Please navigate to Colabo for further tour negotiations.';
  }

  getRestructureButtonTooltip() {
    return this._leaseManager
      .getRestructureButtonTooltip(this.lease, this.currentProject, this.leases, this.projects, this._startupInfo);
  }

  getTenantRequestTypeName(): string {
    switch (this.tenantRequest.type) {
      case models.TenantRequestType.Renewal: return 'Renewal';
      case models.TenantRequestType.RenewalInitiatedByLandlord: return 'Renewal';
      case models.TenantRequestType.Restructure: return 'Restructure';
      case models.TenantRequestType.NewDeal: return 'New Deal';
      case models.TenantRequestType.Inquiry: return 'Inquiry';
      case models.TenantRequestType.Tour: return 'Tour';
    }
  }

  isInquiriesTab(): boolean {
    return this.projectTabs[this.selectedProjectTabIndex]?.id === 'inquiries';
  }

  handleLeaseAnalysisClick(): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'leases',
        this.lease.id,
        'lease-analysis',
      ]);

    window.location.href = this._router.serializeUrl(urlTree);
  }

  private _showBrokerGreetingDialog(info: models.IStartupInfoViewModel): void {
    const dialogRef = this._dialogService.show(GreetingDialogComponent, {
      title: null,
      width: 700,
      height: 'auto',
      injectableData: {
        startupInfo: info,
      },
    });

    if (dialogRef) {
      dialogRef.onHidden
        .pipe(
          switchMap(() => this._profileService.skipIntro(models.IntroType.BrokerGreeting)),
          tap(() => {
            this._authService.startupInfo.introsShown.push(<models.IUserIntroViewModel>{
              userId: this._authService.startupInfo.id,
              introType: models.IntroType.BrokerGreeting,
            });
          }),
        )
        .subscribe();
    }
  }
}
