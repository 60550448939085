<button type="button" class="b-btn-icon-info position-absolute"
        (click)="$event.preventDefault()" #burndownTooltip>
  <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
</button>
<dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
            [width]="250" [target]="burndownTooltip">
  <div>
    By default, all units inherit budget values from the building budget. Here you can adjust those values individually for each unit.
  </div>
</dx-tooltip>
<dx-scroll-view>
  <form name="form" class="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="outer">
      <div class="inner">
        <table>
          <thead>
          <tr>
            <th class="fix" rowspan="2">
              <div class="d-flex flex-column">
                <span>Budget Version</span>
                <dx-select-box name="version" id="version"
                               itemTemplate="field"
                               [displayExpr]="renderDate" class="real-estate-input py-0"
                               [items]="buildingBudgetVersions" [ngModel]="currentBudgetVersion"
                               (ngModelChange)="handleBudgetVersionChange($event)"
                               #version="ngModel">
                  <div *dxTemplate="let data of 'field'">
                    <span>{{ data?.versionAsDate | safeDate : 'dd/MM/yy' }}</span>
                  </div>
                </dx-select-box>
              </div>
            </th>
            <th colspan="2" class="border-right">
              Investment/return
            </th>
            <th colspan="10" class="border-right">
              unit level mla
            </th>
            <th colspan="2" class="border-right">
              leasing commissions (renewal)
            </th>

            <th colspan="2">
              leasing commissions (new deal)
            </th>
          </tr>
          <tr>
            <th class="fix">
            </th>
            <th align="right">
              <span>Budgeted CapEx</span>
            </th>
            <th class="border-right">
              <span>Yield On Cost (Unit)</span>
            </th>
            <th>
              <span>Market Rent</span>
            </th>
            <th class="align-left">
              <span>Market Rent Growth</span>
            </th>
            <th>
              <span>Escalations</span>
            </th>
            <th>
              <span>Lease Term</span>
            </th>
            <th>
              <span>Free Rent (Renewal)</span>
            </th>
            <th>
              <span>Free Rent (New Deal)</span>
            </th>
            <th>
              <span>T.I. <br>(Renewal)</span>
            </th>
            <th>
              <span>T.I. <br>(New Deal)</span>
            </th>
            <th>
              <span>Downtime</span>
            </th>
            <th class="border-right">
              <span>Renewal Probability</span>
            </th>
            <th>
              <span>Landlord Rep</span>
            </th>
            <th class="border-right">
              <span>Tenant Rep</span>
            </th>
            <th>
              <span>Landlord Rep</span>
            </th>
            <th>
              <span>Tenant Rep</span>
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let buildingUnitBudget of buildingUnitBudgetsToSave; let index = index;">
            <td class="fix">{{getBuildingUnitName(buildingUnitBudget.buildingUnitId)}}</td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="$ (e.g. 50) PSF" min="0"
                             step="0" format="$ #,###.## PSF" name="budgetedCapEx_{{index}}"
                             [(ngModel)]="buildingUnitBudget.budgetedCapex">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Budgeted CapEx is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td class="border-right">
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="yieldOnCash_{{index}}"
                             [(ngModel)]="buildingUnitBudget.yieldOnCash">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Yield on Cash is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>

            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="$ (e.g. 50) PSF" min="0" step="0" format="$ #,###.## PSF" name="marketRent_{{index}}"
                             [(ngModel)]="buildingUnitBudget.marketRate">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Market Rent is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td class="align-left">
              <div class="d-flex align-items-center">
                <span>{{getAverageMarketRateGrowth(buildingUnitBudget)}}% Avg.</span>
                <i inlineSVG="assets/img/sign-icons.svg#eye-icon" class="eye-icon"></i>
              </div>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="escalations_{{index}}"
                             [(ngModel)]="buildingUnitBudget.escalations">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Escalations is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="months" min="0" step="0" format="#,###.## months" name="leaseTerm_{{index}}"
                             [(ngModel)]="buildingUnitBudget.leaseTerm">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Lease Term is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="months" min="0" step="0" format="#,###.## months" name="freeRentRenewal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.freeRentRenewal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Free Rent is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="months" min="0" step="0" format="#,###.## months" name="freeRentNewDeal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.freeRentNewDeal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Free Rent is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="$ (e.g. 50) $PSF" min="0" step="0" format="$ #,###.## PSF" name="tenantImprovementsRenewal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.tenantImprovementsRenewal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Tenant Improvements is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="$ (e.g. 50) $PSF" min="0" step="0" format="$ #,###.## PSF" name="tenantImprovementsNewDeal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.tenantImprovementsNewDeal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Tenant Improvements is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="months" min="0" step="0" format="#,###.## months" name="downtime_{{index}}"
                             [(ngModel)]="buildingUnitBudget.downtime">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Downtime is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>

            <td class="border-right">
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="renewalProbability_{{index}}"
                             [(ngModel)]="buildingUnitBudget.renewalProbability">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Renewal Probability is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>

            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="landlordRepRenewal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.landlordRepRenewal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Landlord Rep is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>

            <td class="border-right">
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="tenantRepRenewal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.tenantRepRenewal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Tenant Rep is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>


            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="landlordRepNewDeal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.landlordRepNewDeal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Landlord Rep is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>

            <td>
              <dx-number-box class="no-invalid-message hovered-input mb-0" placeholder="%" min="0" step="0" format="#,###.## '%'" name="tenantRepNewDeal_{{index}}"
                             [(ngModel)]="buildingUnitBudget.tenantRepNewDeal">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Tenant Rep is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Start: Action Buttons -->
    <div class="form-actions main-actions">
      <div class="d-flex justify-content-end">
        <div class="form-action-button">
          <dx-button type="normal" text="Cancel" width="100%" (click)="close()">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="success" width="100%" text="Save"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
      </div>
    </div>
    <!-- End: Action Buttons -->
  </form>
</dx-scroll-view>

