import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { AuthRepository } from './auth.repository';

import * as models from './auth.model';

@Injectable()
export class AuthManager {
  private readonly _authRepository: AuthRepository;
  private readonly _loggerService: LoggerService;

  constructor(authRepository: AuthRepository, loggerService: LoggerService) {
    this._authRepository = authRepository;
    this._loggerService = loggerService;
  }

  isUserAuthenticated(): Observable<boolean> {
    return this._authRepository.isUserAuthenticated();
  }

  register(model: models.RegisterRequest) {
    return this._authRepository.register(model);
  }

  checkUser(login: models.Login): Observable<models.AuthenticateResult> {
    return this._authRepository.checkUser(login);
  }

  getUsers() {
    return this._authRepository.getUsers();
  }

  resetPasswordRequest(model: models.PasswordResetRequest) {
    return this._authRepository.resetPasswordRequest(model);
  }

  resetPassword(model: models.PasswordResetData) {
    return this._authRepository.resetPassword(model);
  }

  post2faCode(model: models.Post2FaCodeRequest) {
    return this._authRepository.post2faCode(model);
  }

  resend2faCode() {
    return this._authRepository.resend2FACode();
  }

  deactivate() {
    return this._authRepository.deactivate();
  }

  getIndependentStartupInfo(): Observable<models.StartupInfo> {
    return this._authRepository.getStartupInfo();
  }

  getStartupInfo(): Observable<models.StartupInfo> {
    return this._authRepository
      .getStoredStartupInfo()
      .pipe(
        switchMap(storedInfo => {
          if (storedInfo) {
            return of(storedInfo);
          }

          return this._authRepository
            .getStartupInfo()
            .pipe(
              tap(info => this._authRepository.setStoredStartupInfo(info)),
            );
        }),
      );
  }

  requestStartupInfo(): Observable<models.StartupInfo> {
    return this._authRepository
      .getStartupInfo()
      .pipe(
        tap(info => {
          this._authRepository.setStoredStartupInfo(info);

          this._loggerService.info(
            LoggerTopic.Auth,
            `Startup info received and stored`,
            info,
          );
        }),
      );
  }

  getStoredStartupInfo(): Observable<models.StartupInfo> {
    return this._authRepository.getStoredStartupInfo();
  }
}
