import { Injectable } from '@angular/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import { BehaviorSubject } from 'rxjs';

import * as models from './available-unit.model';

@Injectable({
  providedIn: 'root',
})
export class AvailableUnitStore {
  private static readonly _listOptionsInitialState: models.AvailableUnitListOptions = {
    boundingBox: [-124.7844079, 24.7433195, -66.9513812, 49.3457868], // USA Bounds
    orderBy: models.AvailableUnitListOrderBy.Address,
    order: models.AvailableUnitListOrder.Asc,
    searchQuery: null,
    companies: null,
    markets: null,
    subMarkets: null,
    cities: null,
    assetTypes: null,
    classes: null,
    availableSize: null,
    clearHeight: null,
    filterApplyingOrder: [],
    polygon: null,
  };

  readonly list: BehaviorSubject<FeatureCollection<Point>>;

  readonly listOptions: BehaviorSubject<models.AvailableUnitListOptions>;
  readonly listFilters: BehaviorSubject<models.AvailableUnitListFilters>;
  readonly sidebarVisible: BehaviorSubject<boolean>;
  readonly isListFiltersVisible: BehaviorSubject<boolean>;
  readonly isListFiltersLoaded: BehaviorSubject<boolean>;

  readonly focusedPoint: BehaviorSubject<Feature<Point>>;

  readonly selectedPoint: BehaviorSubject<Feature<Point>>;
  readonly selectedPointSelectedAvailableUnit: BehaviorSubject<models.BuildingUnitViewModel>;

  constructor() {
    this.list = new BehaviorSubject<FeatureCollection<Point>>(null);

    this.listOptions = new BehaviorSubject<models.AvailableUnitListOptions>(AvailableUnitStore._listOptionsInitialState);
    this.listFilters = new BehaviorSubject<models.AvailableUnitListFilters>(null);
    this.sidebarVisible = new BehaviorSubject<boolean>(true);
    this.isListFiltersVisible = new BehaviorSubject<boolean>(false);
    this.isListFiltersLoaded = new BehaviorSubject<boolean>(false);

    this.focusedPoint = new BehaviorSubject<Feature<Point>>(null);

    this.selectedPoint = new BehaviorSubject<Feature<Point>>(null);
    this.selectedPointSelectedAvailableUnit = new BehaviorSubject<models.BuildingUnitViewModel>(null);
  }
}
