import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommonTools } from '@statera/sdk/common';
import { TermManager } from '@statera/sdk/term';

import * as moment from 'moment/moment';

import * as models from '../../../../../../infrastructure/models/generated';
import { toLocalDate } from '../../../../../../infrastructure/models/local-date.model';

@Component({
  selector: 'app-rental-rate-escalation-custom-schedule',
  templateUrl: 'rental-rate-escalation-custom-schedule.component.html',
  styleUrls: ['rental-rate-escalation-custom-schedule.component.scss'],
})
export class RentalRateEscalationCustomScheduleComponent implements OnInit {
  @Input() startingRate: number;

  @Input() lease: models.ILeaseViewModel;

  @Input() customValues: Array<models.IRentalRateAnnualEscalationTermCustomValueViewModel>;
  @Output() customValuesChange: EventEmitter<Array<models.IRentalRateAnnualEscalationTermCustomValueViewModel>>;

  @Input() customResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>;
  @Output() customResultsChange: EventEmitter<Array<models.IRentalRateAnnualEscalationTermCustomResult>>;

  increaseTypes: Array<{ text: string, value: models.RentalRateAnnualEscalationTermCustomValueType }> = [
    {text: '%', value: models.RentalRateAnnualEscalationTermCustomValueType.FixedPercentagePerYear},
    {text: 'PSF/Yr', value: models.RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear},
  ];

  repeatIntervals: Array<{ text: string, value: models.RentalRateAnnualEscalationTermCustomRepeatType }> = [
    {text: 'NO', value: models.RentalRateAnnualEscalationTermCustomRepeatType.No},
    {text: 'EVERY YEAR', value: models.RentalRateAnnualEscalationTermCustomRepeatType.EveryYear},
    {text: 'MONTH', value: models.RentalRateAnnualEscalationTermCustomRepeatType.Month},
  ];

  RentalRateAnnualEscalationTermCustomValueType: typeof models.RentalRateAnnualEscalationTermCustomValueType;
  RentalRateAnnualEscalationTermCustomRepeatType: typeof models.RentalRateAnnualEscalationTermCustomRepeatType;

  private readonly _termManager: TermManager;

  constructor(termManager: TermManager) {
    this._termManager = termManager;

    this.customValuesChange = new EventEmitter<Array<models.IRentalRateAnnualEscalationTermCustomValueViewModel>>();
    this.customResultsChange = new EventEmitter<Array<models.IRentalRateAnnualEscalationTermCustomResult>>();

    this.RentalRateAnnualEscalationTermCustomValueType = models.RentalRateAnnualEscalationTermCustomValueType;
    this.RentalRateAnnualEscalationTermCustomRepeatType = models.RentalRateAnnualEscalationTermCustomRepeatType;
  }

  ngOnInit(): void {
    this.customValues = this.customValues ?? [];

    if (!this.customValues.length) {
      this.insertCustomRentalRateEscalation();
    }

    this.calculateResultTable();
  }

  handleIncreaseTypeChange(
    event: { value: models.RentalRateAnnualEscalationTermCustomValueType },
    customValue: models.IRentalRateAnnualEscalationTermCustomValueViewModel,
  ): void {
    if (!event || !event.value) {
      return;
    }

    customValue.rentalRateAnnualEscalationTermCustomValueType = event.value;
    customValue.stepIncreasePsfValue = null;
    customValue.stepIncreasePercentage = null;

    this._handleCustomValueChange();
  }

  handleRepeatTypeInitialization(event: { component: any }): void {
    if (!event || !event.component) {
      return;
    }

    event.component.dropDownOptionsCache = {
      ...event.component.dropDownOptionsCache,
      resizeEnabled: true,
      minWidth: 140
    };
  }

  handleRepeatTypeChange(
    event: { value: models.RentalRateAnnualEscalationTermCustomRepeatType },
    customValue: models.IRentalRateAnnualEscalationTermCustomValueViewModel,
  ): void {
    if (!event || !event.value) {
      return;
    }

    customValue.rentalRateAnnualEscalationTermCustomRepeatType = event.value;

    this._handleCustomValueChange();
  }

  deleteCustomRentalRateEscalation(id: number): void {
    if (!this.customValues || !this.customValues.length) {
      return;
    }

    this.customValues = this.customValues.filter(x => x.id !== id);

    if (!this.customValues.length) {
      this.insertCustomRentalRateEscalation();
    }

    this._handleCustomValueChange();
  }

  removeAllRows(): void {
    if (!this.customValues || !this.customValues.length) {
      return;
    }

    this.customValues = [];
    this.insertCustomRentalRateEscalation();

    this._handleCustomValueChange();
  }

  insertCustomRentalRateEscalation() {
    let index = 1;
    if (this.customValues && this.customValues.length) {
      index = this.customValues[this.customValues.length - 1].id + 1;
    }

    if (this.customValues) {
      this.customValues.push(
        <models.IRentalRateAnnualEscalationTermCustomValueViewModel>{
          id: index,
          rentalRateAnnualEscalationTermCustomRepeatType: models.RentalRateAnnualEscalationTermCustomRepeatType.No,
          rentalRateAnnualEscalationTermCustomValueType: models.RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear
        },
      );
    }
  }

  handleLeaseMonthChange(
    value: number,
    customValue: models.IRentalRateAnnualEscalationTermCustomValueViewModel,
  ): void {
    if (
      !value ||
      !this.lease ||
      !this.lease.commencementTerm ||
      !this.lease.commencementTerm.commencement
    ) {
      return;
    }

    const commencementDate = new Date(this.lease.commencementTerm.commencement);

    customValue.increaseDate = new Date(
      commencementDate.setMonth(commencementDate.getMonth() + value - 1)
    );

    this._handleCustomValueChange();
  }

  handleCustomValueChange(): void {
    this._handleCustomValueChange();
  }

  private _handleCustomValueChange(): void {
    this.customValuesChange.next(this.customValues); // emit new values

    this.calculateResultTable(); // recalculate results
  }

  calculateResultTable(): void {
    if (
      !this.lease ||
      !this.lease.commencementTerm ||
      !this.lease.commencementTerm.commencement
    ) {
      return;
    }

    const commencement = new Date(this.lease.commencementTerm.commencement);
    const expiration = new Date(this.lease.calculatedExpirationDate);

    const psfValue: number = this.startingRate;
    if (!psfValue) {
      return;
    }

    let customRentalRateEscalationCustomResults: Array<models.IRentalRateAnnualEscalationTermCustomResult> = [];
    const commencementDate = new Date(commencement);
    // add first month
    customRentalRateEscalationCustomResults.push(<models.IRentalRateAnnualEscalationTermCustomResult>{
      date: commencementDate,
      leaseMonth: 1,
      stepIncreasingPercent: 0,
      stepIncreasingRsf: 0,
      percentValue: 0,
      rsfValue: psfValue,
    });

    this.customValues = this.customValues.sort((n1, n2) => this._sortFunctionForCustomTable(n1, n2));

    // add repeated steps to result list
    for (const customValue of this.customValues) {
      if (!Number.isFinite(customValue.leaseMonth) || Number.isNaN(customValue.leaseMonth)) {
        continue;
      }

      const existingCustomResult = customRentalRateEscalationCustomResults
        .find(x => x.leaseMonth === customValue.leaseMonth);

      const increaseDate = moment(commencementDate).set({
        month: commencementDate.getMonth() + customValue.leaseMonth - 1
      });

      customValue.increaseDate = increaseDate.toDate();

      if (existingCustomResult) {
        existingCustomResult.stepIncreasingPercent += customValue.stepIncreasePercentage;
        existingCustomResult.stepIncreasingRsf += customValue.stepIncreasePsfValue;
      } else {
        customRentalRateEscalationCustomResults.push(<models.IRentalRateAnnualEscalationTermCustomResult>{
          date: toLocalDate(increaseDate),
          leaseMonth: customValue.leaseMonth,
          stepIncreasingPercent: (
            customValue.stepIncreasePercentage
              ? customValue.stepIncreasePercentage
              : 0
          ),
          stepIncreasingRsf: (
            customValue.stepIncreasePsfValue
              ? customValue.stepIncreasePsfValue
              : 0
          )
        });
      }

      if (
        customValue.rentalRateAnnualEscalationTermCustomRepeatType === models.RentalRateAnnualEscalationTermCustomRepeatType.Month ||
        customValue.rentalRateAnnualEscalationTermCustomRepeatType === models.RentalRateAnnualEscalationTermCustomRepeatType.EveryYear
      ) {
        const repeatMonth = (
          customValue.rentalRateAnnualEscalationTermCustomRepeatType === models.RentalRateAnnualEscalationTermCustomRepeatType.EveryYear
            ? 12
            : customValue.repeatLeaseMonth
        );

        const monthsCountBetweenTwoDays: number = this._monthDiff(increaseDate.toDate(), expiration);

        let repeatedRow = 0;
        if (repeatMonth) {
          repeatedRow = monthsCountBetweenTwoDays / repeatMonth;
        }

        if (repeatedRow > 0) {
          for (let i = 1; i <= repeatedRow; i++) {
            const leaseMonth: number = customValue.leaseMonth + i * repeatMonth;

            const existingCustomResult2 = customRentalRateEscalationCustomResults
              .find(x => x.leaseMonth === leaseMonth);

            if (existingCustomResult2) {
              existingCustomResult2.stepIncreasingPercent += customValue.stepIncreasePercentage;
              existingCustomResult2.stepIncreasingRsf += customValue.stepIncreasePsfValue;
            } else {
              customRentalRateEscalationCustomResults.push(<models.IRentalRateAnnualEscalationTermCustomResult>{
                date: toLocalDate(CommonTools.addMonths(increaseDate.toDate(), i * repeatMonth)),
                leaseMonth: customValue.leaseMonth + i * repeatMonth,
                stepIncreasingPercent: customValue.stepIncreasePercentage,
                stepIncreasingRsf: customValue.stepIncreasePsfValue,
              });
            }
          }
        }
      }
    }

    // sort result table by date
    customRentalRateEscalationCustomResults = customRentalRateEscalationCustomResults
      .sort((n1, n2) => this._sortFunctionForCustomResultTable(n1, n2));

    // calculate value

    const unitMetrics = this._termManager.getBaseRentalRateUnitMetrics(this.lease.baseRentalRateTerm);

    for (let i = 0, num = customRentalRateEscalationCustomResults.length; i < num; i++) {
      const escalationCustomResult = customRentalRateEscalationCustomResults[i];
      const nextEscalationCustomResult = customRentalRateEscalationCustomResults[i + 1];

      const previousValue = 0 !== i ? customRentalRateEscalationCustomResults[i - 1] : null;
      if (previousValue) {
        let increasePercentage = escalationCustomResult.stepIncreasingPercent || 0;
        if (escalationCustomResult.stepIncreasingRsf) {
          let stepIncreasingRsf = escalationCustomResult.stepIncreasingRsf;
          if (unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
            stepIncreasingRsf = stepIncreasingRsf / 12;
          }

          const newPsfValue = previousValue.rsfValue + stepIncreasingRsf;
          increasePercentage += +(newPsfValue / (previousValue.rsfValue) - 1);
        }

        escalationCustomResult.percentValue = +increasePercentage;
        escalationCustomResult.rsfValue = previousValue.rsfValue + +(previousValue.rsfValue * increasePercentage);
      }

      const startDate = escalationCustomResult.date;
      let endDate = nextEscalationCustomResult && nextEscalationCustomResult.date;
      if (!endDate && this.lease.commencementTerm.commencement && this.lease.term) {
        endDate = moment(this.lease.commencementTerm.commencement).add(this.lease.term.termValue, 'months').toDate();
      }

      if (startDate && endDate) {
        const tenantSquareFootageRanges = this._termManager
          .getTenantSquareFootageRangesByDateRange(this.lease, startDate, endDate);

        escalationCustomResult.monthlyAmount = tenantSquareFootageRanges.reduce(
          (acc: number, range: { sf: number, daysCount: number }): number => {
            const fullYearDaysCount = 365;
            const monthAverageDaysCount = fullYearDaysCount / 12;
            const months = range.daysCount / monthAverageDaysCount;

            const result = acc + (((escalationCustomResult.rsfValue / fullYearDaysCount) * range.daysCount) * range.sf);

            if (months > 1) {
              return result / months;
            }

            return result;
          },
          0,
        );
      }
    }

    this.customResults = customRentalRateEscalationCustomResults;
    this.customResultsChange.next(this.customResults);
  }

  getBaseRentalRateUnitMetrics(): models.BaseRentalRateUnitMetrics {
    return this._termManager.getBaseRentalRateUnitMetrics(this.lease.baseRentalRateTerm);
  }

  getBaseRentalRateUnitMetricsDisplayValue(): string {
    return this._termManager.getBaseRentalRateUnitMetricsDisplayValue(this.lease.baseRentalRateTerm);
  }

  private _sortFunctionForCustomTable(n1, n2) {
    if (n1.increaseDate > n2.increaseDate) {
      return 1;
    }

    if (n1.increaseDate < n2.increaseDate) {
      return -1;
    }

    return 0;
  }

  private _sortFunctionForCustomResultTable(n1, n2) {
    if (n1.date > n2.date) {
      return 1;
    }

    if (n1.date < n2.date) {
      return -1;
    }

    return 0;
  }

  private _monthDiff(date1: Date | string, date2: Date | string): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    let months: number;

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();

    return months <= 0 ? 0 : months;
  }
}
