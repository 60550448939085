import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { race, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import Player from '@vimeo/player';

@Component({
  selector: 'app-video-player-vimeo',
  templateUrl: './video-player-vimeo.component.html',
  styleUrls: ['./video-player-vimeo.component.scss'],
})
export class VideoPlayerVimeoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() source: string;
  @Input() muted: boolean;

  @Input() playObserver: Subject<void>;
  @Input() pauseObserver: Subject<void>;

  @Output() loaded: EventEmitter<void>;
  @Output() playing: EventEmitter<void>;
  @Output() played: EventEmitter<void>;
  @Output() paused: EventEmitter<void>;

  @ViewChild('playerElementRef', {read: ElementRef, static: false}) playerElementRef: ElementRef;

  isControlsVisible: boolean;

  player: Player;

  private readonly _loadedHandler: () => void;
  private readonly _playHandler: () => void;
  private readonly _endedHandler: () => void;
  private readonly _pauseHandler: () => void;

  private readonly _destroy: Subject<void>;

  constructor() {
    this.loaded = new EventEmitter<void>();
    this.playing = new EventEmitter<void>();
    this.played = new EventEmitter<void>();
    this.paused = new EventEmitter<void>();

    this._loadedHandler = () => this._handleLoaded();
    this._playHandler = () => this._handlePlay();
    this._endedHandler = () => this._handleEnded();
    this._pauseHandler = () => this._handlePause();

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.isControlsVisible = false;

    this.playObserver
      .pipe(
        tap(() => this._play()),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.pauseObserver
      .pipe(
        tap(() => this._pause()),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.playing
      .pipe(
        tap(() => this.isControlsVisible = true),
        takeUntil(this._destroy),
      )
      .subscribe();

    race([this.paused, this.played])
      .pipe(
        tap(() => this.isControlsVisible = false),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const playerContainer = this.playerElementRef?.nativeElement;
    if (playerContainer) {
      this.player = new Player(this.playerElementRef.nativeElement, {
        url: this.source,
        responsive: false,
        muted: this.muted,
        keyboard: false,
        title: false,
        byline: false,
        width: playerContainer.innerWidth,
        height: playerContainer.innerHeight,
      });

      if (this.player) {
        this.player.on('loaded', this._loadedHandler);
        this.player.on('play', this._playHandler);
        this.player.on('pause', this._pauseHandler);
        this.player.on('ended', this._endedHandler);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.off('loaded', this._loadedHandler);
      this.player.off('play', this._playHandler);
      this.player.off('pause', this._pauseHandler);
      this.player.off('ended', this._endedHandler);
    }

    this._destroy.next();
    this._destroy.complete();
  }

  private _handleLoaded(): void {
    this.loaded.next();
  }

  private _handlePlay(): void {
    this.playing.next();
  }

  private _handleEnded(): void {
    this.played.next();
  }

  private _handlePause(): void {
    this.paused.next();
  }

  private _play(): void {
    this.player.play();
  }

  private _pause(): void {
    this.player.pause();
  }
}
