import { Component, Input, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { ProjectManager } from '@statera/sdk/project';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

enum TourProcessState {
  // Create initial tour proposal
  Initial,
  // Reply rejecting options
  ReplyReject,
  // Reply accepting one of the options
  ReplyAccept,
  // Edit scheduled tour
  Edit,
}

const TIME_SLOTS = [
  '9:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
  '12:00PM',
  '12:30PM',
  '1:00PM',
  '1:30PM',
  '2:00PM',
  '2:30PM',
  '3:00PM',
  '3:30PM',
  '4:00PM',
  '4:30PM',
  '5:00PM',
  '5:30PM',
  '6:00PM',
  '6:30PM',
  '7:00PM',
  '7:30PM',
  '8:00PM',
];

interface TimeSlotEntry {
  date: Date;
  timeSlots: Array<string>;
}

interface TimeSlotOption {
  date: Date;
  timeSlot: string;
}

@Component({
  selector: 'app-tour-form',
  templateUrl: './tour-form.component.html',
  styleUrls: ['./tour-form.component.scss']
})
export class TourFormComponent implements OnInit {
  @Input() landlordCompany: models.ICompanyViewModel;
  @Input() tenantCompany: models.ICompanyViewModel;
  @Input() buildingUnit: models.IBuildingUnitViewModel;
  @Input() building: models.IBuildingViewModel;
  @Input() leaseTeam: models.LeaseTeam;
  @Input() projectId: number;
  @Input() counterPartCompany: models.ICompanyViewModel;
  @Input() counterPartUser: models.IProfileViewModel;
  @Input() tour?: models.ITourViewModel;
  @Input() tour$?: Subject<models.ITourViewModel>;

  TourProcessState = TourProcessState;

  private readonly _projectManager: ProjectManager;
  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  timeSlots = TIME_SLOTS;

  state = TourProcessState.Initial;
  selectedTimeSlots: Array<TimeSlotEntry> = [];
  timeSlotOptions: Array<TimeSlotOption> = [];
  approvedTimeSlotOption?: TimeSlotOption;

  selectedDate = new Date();
  selectedDateTitle = '';

  private _cachedBuildingThumbnailUrl = '';

  constructor(
    projectManager: ProjectManager,
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
  ) {
    this._projectManager = projectManager;
    this._authService = authService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    if (!this.tour) {
      this.state = TourProcessState.Initial;
      return;
    }

    const needsApproval = (
      (this.leaseTeam === models.LeaseTeam.TenantTeam && !this.tour.isApprovedByTenant) ||
      (this.leaseTeam === models.LeaseTeam.LandlordTeam && !this.tour.isApprovedByLandlord)
    );

    if (this.tour.status === models.TourStatus.WaitingForApproval || this.tour.status === models.TourStatus.Scheduled) {
      this.selectedTimeSlots = this.tour.dateTimeSlots.map((ts) => {
        return {
          date: moment(ts.date).toDate(),
          timeSlots: [ ...ts.timeSlots ],
        };
      });
      this.timeSlotOptions = this._getTimeSlotOptions();
      if (needsApproval) {
        this.state = TourProcessState.ReplyAccept;
      } else {
        this.state = this.tour.status === models.TourStatus.Scheduled ? TourProcessState.Edit : TourProcessState.ReplyReject;
      }
      return;
    }

    if (needsApproval) {
      this.state = TourProcessState.ReplyAccept;
      return;
    }

    this.state = TourProcessState.Initial;
  }

  getDateTitle(date: Date): string {
    return moment(date).format('dddd, MMMM D');
  }

  onDateSelected($event: Date): void {
    this.selectedDate = $event;
    this.selectedDateTitle = this.getDateTitle(this.selectedDate);
  }

  onTimeSlotClick(timeSlot: string) {
    let entry: TimeSlotEntry;
    const entryIndex = this.selectedTimeSlots.findIndex((ts) => ts.date.getTime() === this.selectedDate.getTime());

    if (entryIndex === -1) {
      entry = <TimeSlotEntry>{
        date: this.selectedDate,
        timeSlots: [timeSlot],
      };

      this.selectedTimeSlots.push(entry);
      this.selectedTimeSlots.sort((a, b) => a.date.getTime() - b.date.getTime());

      return;
    } else {
      entry = this.selectedTimeSlots[entryIndex];
    }

    const timeSlotIndex = entry.timeSlots.indexOf(timeSlot);
    if (timeSlotIndex !== -1) {
      entry.timeSlots.splice(timeSlotIndex, 1);

      if (entry.timeSlots.length === 0) {
        this.selectedTimeSlots.splice(entryIndex, 1);
      }

      return;
    }

    entry.timeSlots.push(timeSlot);

    this.selectedTimeSlots.sort((a, b) => a.date.getTime() - b.date.getTime());
    entry.timeSlots.sort((a, b) => TIME_SLOTS.indexOf(a) - TIME_SLOTS.indexOf(b));
  }

  onRemoveSlotClick(entry: TimeSlotEntry, slot: string): void {
    const index = entry.timeSlots.indexOf(slot);
    entry.timeSlots.splice(index, 1);

    if (entry.timeSlots.length === 0) {
      const entryIndex = this.selectedTimeSlots.indexOf(entry);
      this.selectedTimeSlots.splice(entryIndex, 1);
    }
  }

  onConfirmTimeSlotsButtonClick() {
    if (this.state === TourProcessState.Initial) {
      const action = this.tour
        ? this._projectManager
          .updateProjectTour({ ...this.tour, dateTimeSlots: this.selectedTimeSlots, confirmedDateTimeSlot: null })
        : this._projectManager
          .createProjectTour(this.projectId, this.selectedTimeSlots);

      action
        .pipe(
          tap((result) => {
            this.tour$?.next(result);
            this._dialogRefService.hide();
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();

      return;
    }

    if (this.state === TourProcessState.ReplyReject || this.state === TourProcessState.Edit) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmAreYouCertainText(),
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() =>
            this._projectManager
              .updateProjectTour({ ...this.tour, dateTimeSlots: this.selectedTimeSlots, confirmedDateTimeSlot: null })
              .pipe(
                tap((result) => {
                  this.tour$?.next(result);
                  this._dialogRefService.hide();
                }),
              )
          ),
          tap(() => this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getTourAlternateDateSentText(this.getOtherPartyCompany()?.name),
          })),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  isSlotActive(slot: string): boolean {
    if (this.state === TourProcessState.ReplyAccept) {
      if (!this.approvedTimeSlotOption) {
        return false;
      }

      return this.selectedDate.getFullYear() === this.approvedTimeSlotOption.date.getFullYear() &&
             this.selectedDate.getMonth() === this.approvedTimeSlotOption.date.getMonth() &&
             this.selectedDate.getDate() === this.approvedTimeSlotOption.date.getDate() &&
             slot === this.approvedTimeSlotOption.timeSlot;
    }
    const entry = this.selectedTimeSlots.find((ts) => ts.date.getTime() === this.selectedDate.getTime());
    if (!entry) {
      return false;
    }

    return entry.timeSlots.includes(slot);
  }

  isShowConfirmButton(): boolean {
    return (
      this.state === TourProcessState.Initial ||
      this.state === TourProcessState.ReplyReject ||
      this.state === TourProcessState.Edit
    ) && this.selectedTimeSlots.length > 0;
  }

  getBuildingPictureUrl(): string {
    if (!this.building.attachments) {
      return 'assets/img/nofoto.png';
    }

    if (!this._cachedBuildingThumbnailUrl) {
      const firstPicture = this.building.attachments
        .filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture)
        .sort((a, b) => a.sortOrder - b.sortOrder)[0];

      this._cachedBuildingThumbnailUrl = firstPicture?.file?.thumbnailUrl ?? 'assets/img/nofoto.png';
    }

    return this._cachedBuildingThumbnailUrl;
  }

  getReferencedDates(): Array<Date> | null {
    return this.selectedTimeSlots?.map((ts) => ts.date);
  }

  getTimeSlotsForSelectedDate(): Array<TimeSlotEntry> {
    return this.selectedTimeSlots
      .filter((ts) =>
        ts.date.getFullYear() === this.selectedDate.getFullYear() &&
        ts.date.getMonth() === this.selectedDate.getMonth() &&
        ts.date.getDate() === this.selectedDate.getDate());
  }

  isNoTimeSlotsPicked(): boolean {
    return this.getTimeSlotsForSelectedDate()?.length > 0;
  }

  getAvailableTimeSlots(): Array<string> {
    const result = [];
    const availableTimeSlots = this.getTimeSlotsForSelectedDate().map((ts) => ts.timeSlots);

    for (let x = 0; x < availableTimeSlots.length; x++) {
      for (let i = 0; i < availableTimeSlots[x].length; i++) {
        result.push(availableTimeSlots[x][i]);
      }
    }

    return result;
  }

  setApprovedTimeSlot(slot: string): void {
    this.approvedTimeSlotOption = <TimeSlotOption>{
      date: this.selectedDate,
      timeSlot: slot,
    };
  }

  private _getTimeSlotOptions(): Array<TimeSlotOption> {
    const options: Array<TimeSlotOption> = [];

    this.selectedTimeSlots.forEach((ts) =>
      ts.timeSlots.forEach((slot) =>
        options.push({
          date: ts.date,
          timeSlot: slot,
        })));

    return options;
  }

  isShowApproveButton(): boolean {
    return this.state === TourProcessState.ReplyAccept && !!this.approvedTimeSlotOption;
  }

  onApproveTourButtonClick() {
    if (!this.approvedTimeSlotOption) {
      return;
    }

    const timeSlotText =
      `${moment(this.approvedTimeSlotOption.date).format('MMMM D')} ${this.approvedTimeSlotOption.timeSlot}`;
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmTourDateText(timeSlotText),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() =>
          this._projectManager
            .updateProjectTour({
              ...this.tour,
              confirmedDateTimeSlot: this.approvedTimeSlotOption,
            })
        ),
        tap((tour) => {
          this.tour$?.next(tour);
          this._dialogRefService.hide();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onReplyRejectButtonClick() {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmTourAlternateDateText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => this.state = TourProcessState.ReplyReject),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  close() {
    this._dialogRefService.hide();
  }

  getUserName(): string {
    return this._authService.startupInfo.firstName;
  }

  isLandlord(): boolean {
    return this.leaseTeam === models.LeaseTeam.LandlordTeam;
  }

  isTooManyTimeSlotsSelected(): boolean {
    return this._getSelectedTimeSlotsCount() > 5;
  }

  getOtherPartyLogoUrl(): string {
    const company = this.isLandlord()
      ? this.tenantCompany
      : this.landlordCompany;
    return company.logo?.thumbnailUrl ?? '/assets/img/nofoto.png';
  }

  getOtherPartyCompany(): models.ICompanyViewModel {
    return this.isLandlord()
      ? this.tenantCompany
      : this.landlordCompany;
  }

  cancelTour() {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCancelTourText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._projectManager.cancelTour(this.tour)),
        tap(() => {
          this.tour$?.next(null);
          this.close();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _getSelectedTimeSlotsCount(): number {
    if (!this.selectedTimeSlots) {
      return 0;
    }

    let count = 0;
    this.selectedTimeSlots.forEach((ts) =>
      ts.timeSlots.forEach(() => count++));

    return count;
  }
}
