import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermHistoryRepository } from './term-history.repository';
import * as models from './term-history.model';
import * as jsdiff from 'diff';

@Injectable()
export class TermHistoryManager {

  private readonly _termHistoryRepository: TermHistoryRepository;

  constructor(
    termHistoryRepository: TermHistoryRepository,
  ) {
    this._termHistoryRepository = termHistoryRepository;
  }

  getLeaseHistory(leaseId: number): Observable<models.LeaseHistoryRecordViewModel> {
    return this._termHistoryRepository.getLeaseHistoryRecords(leaseId);
  }

  getDiffClassName(termSettingDiff?: models.TermSettingDiff): string | null {
    if (!termSettingDiff) {
      return null;
    }

    switch (termSettingDiff.TermSettingDiffTypeColor) {
      case models.TermSettingDiffTypeColor.Green:
        return 'term-diff-added';

      case models.TermSettingDiffTypeColor.Grey:
        return 'term-diff-untouched';

      case models.TermSettingDiffTypeColor.Red:
        return 'term-diff-removed';

      default:
        return null;
    }
  }

  getChangeHistoryRecords(leaseHistory: models.LeaseHistoryRecordViewModel,
                                       leaseTermConfiguration: models.LeaseTermConfiguration): models.TermHistoryResult {
    const records = [];
    let lastValue = null;
    let acceptedValue = null;
    if (leaseHistory && leaseHistory.leaseTermHistoryRecords && leaseTermConfiguration) {
      const termHistoryChanges = leaseHistory.leaseTermHistoryRecords.find(x => x.leaseTermType === leaseTermConfiguration.leaseTermType);
      if (termHistoryChanges && termHistoryChanges.termHistoryModels) {
        termHistoryChanges.termHistoryModels.forEach((termHistoryModel, index) => {
          let oldValue;
          if (index > 0 && index < termHistoryChanges.termHistoryModels.length) {
            oldValue = termHistoryChanges.termHistoryModels[index - 1].displayValue;
          }

          const isLastRejectedOffer = index === termHistoryChanges.termHistoryModels.length - 2;

          if (isLastRejectedOffer) {
            oldValue = termHistoryChanges.termHistoryModels[termHistoryChanges.termHistoryModels.length - 1].displayValue;
          }

          const newValue = termHistoryModel.displayValue;

          const termSettingDiffs: Array<models.TermSettingDiff> = [];
          if (newValue) {
            oldValue = !oldValue ? ' ' : oldValue;

            const oldValueLines = oldValue.trim().split('\n');
            const newValueLines = newValue.trim().split('\n');
            const isOptionValues =
              (oldValueLines.length === 1 && oldValueLines[0].startsWith('OPTION ')
                && newValueLines.length > oldValueLines.length) || !(oldValueLines[0].startsWith('OPTION ') && newValueLines[0].startsWith('OPTION '));

            // All the acrobatic hackery presented above is for the cases where we have an option selected.
            // Term history diffs with transitions from (Option A, Option B) to Option X is quite unreadable
            // for user unless we use line-by-line diffs.
            const diff = isOptionValues
              ? jsdiff.diffLines(oldValue.toString(), newValue.toString())
              : jsdiff.diffWords(oldValue.toString(), newValue.toString());

            diff.forEach(function (part) {
              if (!part.removed) {
                const color: models.TermSettingDiffTypeColor = isLastRejectedOffer
                  ? part.added
                    ? models.TermSettingDiffTypeColor.Red
                    : part.removed ? models.TermSettingDiffTypeColor.Green : models.TermSettingDiffTypeColor.Grey
                  : part.added
                    ? models.TermSettingDiffTypeColor.Green
                    : part.removed ? models.TermSettingDiffTypeColor.Red : models.TermSettingDiffTypeColor.Grey;
                const value = part.value;

                termSettingDiffs.push(<models.TermSettingDiff>{
                  TermSettingDiffTypeColor: color,
                  Value: value,
                });
              }
            });
          }
          const termHistoryRecord = <models.TermHistoryRecord>{
            termSettingDiffs,
            shouldShowAcceptanceMark: termHistoryModel.shouldShowAcceptanceMark,
            isHighlighted: termHistoryChanges.termHistoryModels.length > 1,
            isModifiedValue: termHistoryModel.isModifiedValue,
            timestamp: new Date(termHistoryModel.createdOn),
            leaseTeam: termHistoryModel.leaseTeam,
            leaseTermCustomTable: termHistoryModel.leaseTermCustomTable,
            hasLeaseTermCustomTable: termHistoryModel.hasLeaseTermCustomTable,
            previewLeaseTermTable: termHistoryModel.previewLeaseTermTable,
            hasPreviewLeaseTermTable: termHistoryModel.hasPreviewLeaseTermTable,
            hasAdditionalValueTable: !!termHistoryModel.additionalValueTable,
            additionalValueTable: termHistoryModel.additionalValueTable,
          };

          if (termHistoryModel.isAcceptedValue) {
            acceptedValue = termHistoryRecord;
            acceptedValue.timestamp = new Date(termHistoryModel.acceptedDate);
            acceptedValue.createdOn = new Date(termHistoryModel.createdOn);
            return;
          }

          if (termHistoryModel.isLastNonAcceptedValue) {
            lastValue = termHistoryRecord;
            return;
          }

          termHistoryRecord.isHighlighted = termHistoryChanges.termHistoryModels.length > 1
            && index >= termHistoryChanges.termHistoryModels.length - 2;
          records.push(termHistoryRecord);
        });
      }
    }
    return {previousChanges: records, lastNotAcceptedValue: lastValue, acceptedValue: acceptedValue};
  }
}
