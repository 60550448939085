import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

class InviteExternalSignerToSignatures {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  templateUrl: './invite-external-signer-to-signatures-dialog.component.html',
  styleUrls: ['./invite-external-signer-to-signatures-dialog.component.scss']
})
export class InviteExternalSignerToSignaturesDialogComponent implements OnInit, OnDestroy {

  inviteExternalSignerToSignaturesModel: InviteExternalSignerToSignatures;

  readonly lease: models.ILeaseViewModel;
  readonly addSigner$: Subject<InviteExternalSignerToSignatures>;

  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  constructor(
    dialogRefService: DialogRefService
  ) {
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.inviteExternalSignerToSignaturesModel = <InviteExternalSignerToSignatures>{};
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    if (this.addSigner$) {
      this.addSigner$.next(this.inviteExternalSignerToSignaturesModel);
      this._dialogRefService.hide();
    }
  }

  cancel(): void {
    if (this.addSigner$) {
      this.addSigner$.next(null);
    }

    this._dialogRefService.hide();
  }
}
