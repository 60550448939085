import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Notification, NotificationTopic } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationStore {
  readonly notificationTopics: {[topic: string]: BehaviorSubject<Array<Notification<unknown>>>};

  constructor() {
    this.notificationTopics = {};
  }

  getOrCreateNotificationTopic<T>(topic: NotificationTopic): BehaviorSubject<Array<Notification<T>>> {
    if (!this.notificationTopics[topic]) {
      this.notificationTopics[topic] = new BehaviorSubject<Array<Notification<T>>>([]);
    }

    return <BehaviorSubject<Array<Notification<T>>>>this.notificationTopics[topic];
  }
}
