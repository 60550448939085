import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { LeaseDocumentManager } from './lease-document.manager';
import { LeaseDocumentRepository } from './lease-document.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    LeaseDocumentManager,
    LeaseDocumentRepository,
  ],
})
export class LeaseDocumentModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LeaseDocumentModule> {
    return {
      ngModule: LeaseDocumentModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LeaseDocumentManager,
        LeaseDocumentRepository,
      ],
    };
  }
}
