<div class="expirations-report-tooltip"
     *ngIf="expirationsReportTooltip"
     #expirationsReportTooltipElementRef>
  <table>
    <thead>
    <tr>
      <th [ngSwitch]="expirationsReportTooltip.expirationsReportTooltipChartType">
        <ng-container *ngSwitchCase="ExpirationReportTooltipChartType.SinglePeriod">
          {{expirationsReportTooltip.startDate | safeDate: 'MMMM'}}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{expirationsReportTooltip.startDate | safeDate: 'y'}}
        </ng-container>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        <div class="d-flex">
          <span class="value">{{expirationsReportTooltip.totalSquareFeet | safeNumber: '1.0-0'}} SF</span>
        </div>
      </td>
    </tr>
    <tr *ngIf="expirationsReportTooltip.totalSquareFeetPercentage">
      <td>
        <div class="d-flex">
          <span class="value">{{expirationsReportTooltip.totalSquareFeetPercentage | safeNumber: '1.0-0'}}%</span>
          <span class="caption">expiring</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="d-flex">
          <span class="value">{{expirationsReportTooltip.totalNumberOfUnitsExpiring | safeNumber: '1.0-0'}}</span>
          <span class="caption">unit(s)</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="d-flex">
          <span class="value">{{expirationsReportTooltip.totalNumberOfUniqueTenantExpiring | safeNumber: '1.0-0'}}</span>
          <span class="caption">tenant(s)</span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
