import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { UserManager } from '@statera/sdk/user';

import { ProfileService } from '../../services/profile.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { AlertService } from '../../../alert/services/alert.service';

import * as models from '../../models/alert-preferences.model';
import {
  PhoneNumberVerifyReason,
  VerifyPhoneNumberDialogComponent
} from '../verify-phone-number-dialog/verify-phone-number-dialog.component';

@Component({
  templateUrl: './alert-preferences.component.html',
  styleUrls: ['./alert-preferences.component.scss']
})
export class AlertPreferencesComponent implements OnInit, OnDestroy {
  private readonly _dialogRefService: DialogRefService;
  private readonly _profileService: ProfileService;
  private readonly _userManager: UserManager;
  private readonly _dialogService: DialogService;
  private readonly _alertService: AlertService;

  constructor(
    dialogRefService: DialogRefService,
    profileService: ProfileService,
    userManager: UserManager,
    dialogService: DialogService,
    alertService: AlertService
  ) {
    this._dialogRefService = dialogRefService;
    this._profileService = profileService;
    this._userManager = userManager;
    this._dialogService = dialogService;
    this._alertService = alertService;
  }

  alertsPreference: models.AlertPreferences;

  isLoading = false;
  isSwitchVisible = false;
  isMessengerSmsSwitchDisabled = true;
  isMessengerEmailSwitchDisabled = true;
  emailNotifications: boolean;
  messengerSmsNotifications: boolean;
  messengerEmailNotifications: boolean;
  notificationsDisplay: boolean;
  profile: models.User;

  leaseCommencementPreference: models.AlertPreferenceOption;
  leaseCommencementPreferenceOptions: Array<models.AlertPreferenceOption>;
  leaseExpirationPreference: models.AlertPreferenceOption;
  leaseExpirationPreferenceOptions: Array<models.AlertPreferenceOption>;
  rentEscalationPreference: models.AlertPreferenceOption;
  rentEscalationPreferenceOptions: Array<models.AlertPreferenceOption>;
  renewalOptionPreference: models.AlertPreferenceOption;
  renewalOptionPreferenceOptions: Array<models.AlertPreferenceOption>;
  terminationOptionPreference: models.AlertPreferenceOption;
  terminationOptionPreferenceOptions: Array<models.AlertPreferenceOption>;
  inquiriesAndRequestsPreference: models.AlertPreferenceOption;
  inquiriesAndRequestsPreferenceOptions: Array<models.AlertPreferenceOption>;

  private _destroy$ = new Subject();

  ngOnInit() {
    this._userManager.isLoading$
      .pipe(takeUntil(this._destroy$))
      .subscribe((isLoading) => this.isLoading = isLoading);

    this._userManager.userChange$
      .pipe(takeUntil(this._destroy$))
      .subscribe((profile) => {
        this.profile = profile;
        this.alertsPreference = profile.alertsPreferenceViewModel;

        this._initializeEmailAndPushNotificationSettings();
        this._initializeLeaseCommencementFilter();
        this._initializeLeaseExpirationFilter();
        this._initializeRentEscalation();
        this._initializeRenewalOptionFilter();
        this._initializeTerminationOptionFilter();
      });

    if (this.profile && this.profile.alertsPreferenceViewModel) {
      this.alertsPreference = this.profile.alertsPreferenceViewModel;
    } else {
      this.alertsPreference = <models.AlertPreferences>{};
    }

    this._initializeEmailAndPushNotificationSettings();
    this._initializeLeaseCommencementFilter();
    this._initializeLeaseExpirationFilter();
    this._initializeRentEscalation();
    this._initializeRenewalOptionFilter();
    this._initializeTerminationOptionFilter();
    this._initializeInquiriesAndRequestsFilter();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onAlertPreferencesChanged($event, button: HTMLButtonElement) {
    button.disabled = true;

    this._userManager
      .saveUser({
        ...this.profile,
        notificationsDisplay: this.notificationsDisplay ?
          models.NotificationsDisplay.Enabled :
          models.NotificationsDisplay.Disabled,
        emailNotifications: this.emailNotifications ?
          models.EmailNotifications.Enabled :
          models.EmailNotifications.Disabled,
        messengerSmsNotifications: this.messengerSmsNotifications ?
          models.MessengerSmsNotifications.Enabled :
          models.MessengerSmsNotifications.Disabled,
        messengerEmailNotifications: this.messengerEmailNotifications ?
          models.MessengerEmailNotifications.Enabled :
          models.MessengerEmailNotifications.Disabled,
        alertsPreferenceViewModel: {
          ...this.alertsPreference,
          leaseCommencementIntervalType: this.leaseCommencementPreference.type,
          leaseCommencementIntervalValue: this.leaseCommencementPreference.value,
          leaseExpirationIntervalType: this.leaseExpirationPreference.type,
          leaseExpirationIntervalValue: this.leaseExpirationPreference.value,
          leaseRentEscalationIntervalType: this.rentEscalationPreference.type,
          leaseRentEscalationIntervalValue: this.rentEscalationPreference.value,
          leaseRenewalOptionIntervalType: this.renewalOptionPreference.type,
          leaseRenewalOptionIntervalValue: this.renewalOptionPreference.value,
          leaseTerminationOptionIntervalType: this.terminationOptionPreference.type,
          leaseTerminationOptionIntervalValue: this.terminationOptionPreference.value,
          inquiriesAndRequestsIntervalType: this.inquiriesAndRequestsPreference.type,
          inquiriesAndRequestsIntervalValue: this.inquiriesAndRequestsPreference.value,
        },
      })
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        button.disabled = false;

        this._dialogRefService.hide();
      });
  }

  onMessengerSmsNotificationsChanged($event: { value: boolean }) {
    if (!$event.value) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: 'Are you sure you want to turn SMS notifications off?',
      });

      if (alertReference) {
        alertReference
          .declined
          .pipe(
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe(() => {
            this.messengerSmsNotifications = true;
          });
      }

      return;
    }

    if (this.profile.mobilePhoneConfirmed) {
      return;
    }

    const dialogRef = this._dialogService.show(VerifyPhoneNumberDialogComponent, {
      title: 'Verify Phone Number',
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 900,
      height: 700,
      maxHeight: 700,
      injectableData: {
        user: this.profile,
        reason: PhoneNumberVerifyReason.SmsNotifications,
      },
    });

    dialogRef.onHiding
      .pipe(
        tap(() => this.messengerSmsNotifications = this.profile.mobilePhoneConfirmed),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onClosed($event) {
    this._dialogRefService.hide();
  }

  private _initializeLeaseCommencementFilter() {
    this.leaseCommencementPreferenceOptions = [
      {
        type: models.AlertIntervalType.Month,
        value: 1,
        displayValue: models.AlertPreferenceOptionDisplayValue.OneMonth,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 2,
        displayValue: models.AlertPreferenceOptionDisplayValue.TwoMonths,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 3,
        displayValue: models.AlertPreferenceOptionDisplayValue.ThreeMonths,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 6,
        displayValue: models.AlertPreferenceOptionDisplayValue.SixMonths,
      },
    ];

    this.leaseCommencementPreference = this.leaseCommencementPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.leaseCommencementIntervalType &&
        option.value === this.alertsPreference.leaseCommencementIntervalValue
      ));

    if (!this.leaseCommencementPreference) {
      this.leaseCommencementPreference = this.leaseCommencementPreferenceOptions[2];
    }
  }

  private _initializeLeaseExpirationFilter() {
    this.leaseExpirationPreferenceOptions = [
      {
        type: models.AlertIntervalType.Month,
        value: 6,
        displayValue: models.AlertPreferenceOptionDisplayValue.SixMonths,
      },
      {
        type: models.AlertIntervalType.Year,
        value: 1,
        displayValue: models.AlertPreferenceOptionDisplayValue.OneYear,
      },
    ];

    this.leaseExpirationPreference = this.leaseExpirationPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.leaseExpirationIntervalType &&
        option.value === this.alertsPreference.leaseExpirationIntervalValue
      ));

    if (!this.leaseExpirationPreference) {
      this.leaseExpirationPreference = this.leaseExpirationPreferenceOptions[1];
    }
  }

  private _initializeRentEscalation() {
    this.rentEscalationPreferenceOptions = [
      {
        type: models.AlertIntervalType.Month,
        value: 9,
        displayValue: models.AlertPreferenceOptionDisplayValue.NineMonths,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 10,
        displayValue: models.AlertPreferenceOptionDisplayValue.TenMonths,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 11,
        displayValue: models.AlertPreferenceOptionDisplayValue.ElevenMonths,
      },
      {
        type: models.AlertIntervalType.Off,
        value: 0,
        displayValue: models.AlertPreferenceOptionDisplayValue.Off,
      },
    ];

    this.rentEscalationPreference = this.rentEscalationPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.leaseRentEscalationIntervalType &&
        option.value === this.alertsPreference.leaseRentEscalationIntervalValue
      ));

    if (!this.rentEscalationPreference) {
      this.rentEscalationPreference = this.rentEscalationPreferenceOptions[1];
    }
  }

  private _initializeRenewalOptionFilter() {
    this.renewalOptionPreferenceOptions = [
      {
        type: models.AlertIntervalType.Month,
        value: 6,
        displayValue: models.AlertPreferenceOptionDisplayValue.SixMonths,
      },
      {
        type: models.AlertIntervalType.Year,
        value: 1,
        displayValue: models.AlertPreferenceOptionDisplayValue.OneYear,
      },
      {
        type: models.AlertIntervalType.Off,
        value: 0,
        displayValue: models.AlertPreferenceOptionDisplayValue.Off,
      },
    ];

    this.renewalOptionPreference = this.renewalOptionPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.leaseRenewalOptionIntervalType &&
        option.value === this.alertsPreference.leaseRenewalOptionIntervalValue
      ));

    if (!this.renewalOptionPreference) {
      this.renewalOptionPreference = this.renewalOptionPreferenceOptions[1];
    }
  }

  private _initializeTerminationOptionFilter() {
    this.terminationOptionPreferenceOptions = [
      {
        type: models.AlertIntervalType.Month,
        value: 6,
        displayValue: models.AlertPreferenceOptionDisplayValue.SixMonths,
      },
      {
        type: models.AlertIntervalType.Year,
        value: 1,
        displayValue: models.AlertPreferenceOptionDisplayValue.OneYear,
      },
      {
        type: models.AlertIntervalType.Off,
        value: 0,
        displayValue: models.AlertPreferenceOptionDisplayValue.Off,
      },
    ];

    this.terminationOptionPreference = this.terminationOptionPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.leaseTerminationOptionIntervalType &&
        option.value === this.alertsPreference.leaseTerminationOptionIntervalValue
      ));

    if (!this.terminationOptionPreference) {
      this.terminationOptionPreference = this.terminationOptionPreferenceOptions[1];
    }
  }

  private _initializeInquiriesAndRequestsFilter() {
    this.inquiriesAndRequestsPreferenceOptions = [
      {
        type: models.AlertIntervalType.Week,
        value: 1,
        displayValue: models.AlertPreferenceOptionDisplayValue.OneWeek,
      },
      {
        type: models.AlertIntervalType.Week,
        value: 3,
        displayValue: models.AlertPreferenceOptionDisplayValue.ThreeWeeks,
      },
      {
        type: models.AlertIntervalType.Week,
        value: 6,
        displayValue: models.AlertPreferenceOptionDisplayValue.SixWeeks,
      },
      {
        type: models.AlertIntervalType.Month,
        value: 3,
        displayValue: models.AlertPreferenceOptionDisplayValue.ThreeMonths,
      },
      {
        type: models.AlertIntervalType.Off,
        value: 0,
        displayValue: models.AlertPreferenceOptionDisplayValue.Off,
      },
    ];

    this.inquiriesAndRequestsPreference = this.inquiriesAndRequestsPreferenceOptions
      .find(option => (
        option.type === this.alertsPreference.inquiriesAndRequestsIntervalType &&
        option.value === this.alertsPreference.inquiriesAndRequestsIntervalValue
      ));

    if (!this.inquiriesAndRequestsPreference) {
      this.inquiriesAndRequestsPreference = this.inquiriesAndRequestsPreferenceOptions[0];
    }
  }

  private _initializeEmailAndPushNotificationSettings() {
    if (this.profile) {
      this.notificationsDisplay = this.profile.notificationsDisplay === models.NotificationsDisplay.Enabled;
      this.emailNotifications = this.profile.emailNotifications === models.EmailNotifications.Enabled;
      this.messengerSmsNotifications =
        this.profile.mobilePhoneConfirmed && this.profile.messengerSmsNotifications === models.MessengerSmsNotifications.Enabled;
      this.messengerEmailNotifications = this.profile.messengerEmailNotifications === models.MessengerEmailNotifications.Enabled;
      this.isMessengerSmsSwitchDisabled = !this.profile.mobilePhone;
      this.isMessengerEmailSwitchDisabled = !this.profile.emailConfirmed;
      this.isSwitchVisible = true;
    }
  }
}
