import { Component, Input, Output, EventEmitter, ViewChild, OnInit, ElementRef } from '@angular/core';
import { DxTooltipComponent } from 'devextreme-angular';

import { TermManager } from '@statera/sdk/term';

import * as models from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-term-header',
  templateUrl: './term-header.component.html',
  styleUrls: ['./term-header.component.scss'],
})
export class TermHeaderComponent implements OnInit {
  private static readonly _termStatusNames = {
    [models.TermStatus.Draft]: 'Draft',
    [models.TermStatus.Pending]: 'Pending',
    [models.TermStatus.Accepted]: 'Accepted',
    [models.TermStatus.Rejected]: 'Rejected',
    [models.TermStatus.Ready]: 'Ready',
  };

  @ViewChild('demoTooltip') demoTooltip: DxTooltipComponent;

  @Input() termConfiguration: models.ILeaseTermConfiguration;
  @Input() lease: models.ILeaseViewModel;
  @Input() stageHistoryRecords: Array<models.IStageHistoryRecordViewModel>;
  @Input() leaseHistory: models.ILeaseHistoryRecordViewModel;

  @Output() initialized = new EventEmitter();

  tooltipVisible: any = {};

  private readonly _termManager: TermManager;
  private readonly _elementRef: HTMLElement;

  constructor(termManager: TermManager, elementRef: ElementRef) {
    this._termManager = termManager;
    this._elementRef = <HTMLElement>elementRef.nativeElement;
  }

  showDemo(withScroll: boolean) {
    if (withScroll) {
      this._elementRef.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
    this.demoTooltip.instance.show();
  }

  hideDemo() {
    this.demoTooltip.instance.hide();
  }

  clickOnStatus(e) {
    e.stopPropagation();
    return false;
  }

  getTermStatus(lease: models.ILeaseViewModel): string {
    const termStatus = this._termManager.getStatusByLeaseTermType(this.termConfiguration.leaseTermType, lease);

    if (termStatus == null) {
      return TermHeaderComponent._termStatusNames[models.TermStatus.Draft];
    }

    return TermHeaderComponent._termStatusNames[termStatus];
  }

  hasNewTermBadge(): boolean {
    if (
      !this.termConfiguration ||
      !this.leaseHistory ||
      !this.leaseHistory.leaseTermHistoryRecords ||
      !this.leaseHistory.leaseTermHistoryRecords.length
    ) {
      return false;
    }

    const leaseTermHistoryRecords = this.leaseHistory.leaseTermHistoryRecords
      .find(x => x.leaseTermType === this.termConfiguration.leaseTermType);

    if (!leaseTermHistoryRecords) {
      return false;
    }

    return leaseTermHistoryRecords.isElectedForNegotiationAtActualStage;
  }

  ngOnInit() {
    this.initialized.emit(this);
  }
}
