import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import {BuildingBudgetManager} from './building-budget.manager';
import {BuildingBudgetRepository} from './building-budget.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    BuildingBudgetManager,
    BuildingBudgetRepository,
  ],
})
export class BuildingBudgetModule {
  static forChild(configuration: Configuration): ModuleWithProviders<BuildingBudgetModule> {
    return {
      ngModule: BuildingBudgetModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        BuildingBudgetManager,
        BuildingBudgetRepository,
      ],
    };
  }
}
