<div class="main-table">
  <div class="main-table-header">
    <div class="title">
      <span class="title-text">Custom Rental Rate Escalations:</span>
      <button type="button" class="b-btn-icon-info"
              #customRentalRateEscalationsTooltip>
        <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
      </button>
      <dx-tooltip position="top"
                  hideEvent="dxhoverend" showEvent="dxhoverstart"
                  [width]="250" [target]="customRentalRateEscalationsTooltip">
        <span class="d-flex flex-column tooltip-menu">
          Insert any custom PSF or % escalation at any month and for any duration of time during the lease term.
          Click on the fields in the Escalations box and results will be displayed in Results Preview box below.
        </span>
      </dx-tooltip>
    </div>

    <p class="title-content">Starting Rate: ${{startingRate}} {{getBaseRentalRateUnitMetricsDisplayValue()}}</p>
  </div>

  <div class="steps-table escalation-table">
    <div class="row">
      <div class="column-first">Lease Month</div>
      <div class="column-second">Increase Date</div>
      <div class="column-third">Increase</div>
      <div class="column-fourth">Repeat to End</div>
      <div class="column-fifth"></div>
    </div>

    <div class="row" *ngFor="let customValue of customValues">
      <div class="column-first justify-content-center">
        <app-input-field className="real-estate-input py-0" format="#,##0" appIgnoreScrollwheel
                         [min]="1"
                         [errorCondition]="false"
                         [(value)]="customValue.leaseMonth"
                         (valueChange)="handleLeaseMonthChange($event, customValue)">
        </app-input-field>
      </div>
      <div class="column-second justify-content-center">
        <ng-container *ngIf="customValue.increaseDate">
          {{customValue.increaseDate | safeDate: 'MM.dd.yy'}}
        </ng-container>
      </div>
      <div class="column-third justify-content-center">
        <div class="selectable-input-wrapper d-flex flex-row gap-4">
          <ng-container *ngIf="customValue.rentalRateAnnualEscalationTermCustomValueType === RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear">
            <app-input-field className="real-estate-input py-0" width="65px" placeholder="(e.g.5)"
                             prefix="$" format="#0.##" appIgnoreScrollwheel
                             [min]="0" [step]="0"
                             [(value)]="customValue.stepIncreasePsfValue"
                             (valueChange)="handleCustomValueChange()">
            </app-input-field>
          </ng-container>

          <!-- percent value-->
          <ng-container *ngIf="customValue.rentalRateAnnualEscalationTermCustomValueType === RentalRateAnnualEscalationTermCustomValueType.FixedPercentagePerYear">
            <app-input-field className="real-estate-input py-0" width="65px" placeholder="(e.g.5)" format="percent"
                             appIgnoreScrollwheel
                             [min]="0" [step]="0"
                             [(value)]="customValue.stepIncreasePercentage"
                             (valueChange)="handleCustomValueChange()">
            </app-input-field>
          </ng-container>

          <dx-select-box displayExpr="text" valueExpr="value" class="real-estate-input col-3 py-0" placeholder="Select Increase Type"
                         [dataSource]="increaseTypes"
                         [(value)]="customValue.rentalRateAnnualEscalationTermCustomValueType"
                         (onValueChanged)="handleIncreaseTypeChange($event, customValue)">
          </dx-select-box>
        </div>
      </div>
      <div class="column-fourth justify-content-center">
        <div class="selectable-input-wrapper d-flex flex-row gap-4">
          <app-input-field className="real-estate-input py-0" width="65px" placeholder="(e.g.5)" format="#,##0"
                           appIgnoreScrollwheel
                           *ngIf="customValue.rentalRateAnnualEscalationTermCustomRepeatType === RentalRateAnnualEscalationTermCustomRepeatType.Month"
                           [min]="0" [step]="0"
                           [(value)]="customValue.repeatLeaseMonth"
                           (valueChange)="handleCustomValueChange()">
          </app-input-field>
          <dx-select-box displayExpr="text" valueExpr="value" class="real-estate-input col-3 py-0" placeholder="Select Repeat Type"
                         [class.rounded-border]="customValue.rentalRateAnnualEscalationTermCustomRepeatType !== RentalRateAnnualEscalationTermCustomRepeatType.Month"
                         [dataSource]="repeatIntervals"
                         [width]="customValue.rentalRateAnnualEscalationTermCustomRepeatType === RentalRateAnnualEscalationTermCustomRepeatType.Month ? '48px' : 'auto'"
                         [(value)]="customValue.rentalRateAnnualEscalationTermCustomRepeatType"
                         (onInitialized)="handleRepeatTypeInitialization($event)"
                         (onValueChanged)="handleRepeatTypeChange($event, customValue)">
          </dx-select-box>
        </div>
      </div>
      <div class="column-fifth justify-content-center">
        <button class="remove-row-btn" inlineSVG="assets/img/signs-icons.svg#icon-minus-100"
                (click)="deleteCustomRentalRateEscalation(customValue.id)">
        </button>
      </div>
    </div>

    <div class="row steps-table-actions">
      <button type="button"
              (click)="insertCustomRentalRateEscalation()">
        <i inlineSVG="assets/img/signs-icons.svg#icon-plus-100"></i>
        <span>Add a row</span>
      </button>
      <button type="button"
              (click)="removeAllRows()">
        <i inlineSVG="assets/img/signs-icons.svg#icon-minus-100"></i>
        <span>Remove all rows</span>
      </button>
    </div>
  </div>
</div>
