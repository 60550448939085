import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { TermUseManager } from './term-use.manager';

import { TermUseRepository } from './term-use.repository';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    TermUseRepository,
    TermUseManager,
  ],
})
export class TermUseModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TermUseModule> {
    return {
      ngModule: TermUseModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TermUseRepository,
        TermUseManager
      ],
    };
  }
}
