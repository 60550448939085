<div class="active-deals-by-stage-report">
  <ng-container *ngIf="isLoaded; else preloader">
    <div class="active-deals-by-stage-report-chart">
      <canvas baseChart
              [data]="doughnutChartData" [options]="doughnutChartOptions" [legend]="false" [type]="'doughnut'"
              [plugins]="doughnutChartPlugins"
              (chartClick)="handleChartClickEvent($event)"
              #canvasElementRef
              #doughnutChart="base-chart">
      </canvas>

      <div class="active-deals-by-stage-report-chart-statistics">
        <span class="value">{{maxStage.percents}}%</span>
        <span class="caption">{{maxStage.name}}</span>
      </div>
    </div>

    <ul class="active-deals-by-stage-report-legends">
      <li *ngFor="let legend of doughnutChartLegend">
        <a role="button">
          <span class="active-deals-by-stage-report-legends-bg"
                [style.background-color]="legend.theme.baseColor">
          </span>
          <span class="active-deals-by-stage-report-legends-name">
            {{legend.group.name}} ({{legend.group.leaseIds.length}} deal{{legend.group.leaseIds.length == 1 ? '' : 's'}})
          </span>
        </a>
      </li>
    </ul>
  </ng-container>
  <ng-template #preloader>
    <div class="active-deals-by-stage-report-preloader">
      <span>
      </span>
    </div>
  </ng-template>
</div>
