import { Injectable } from '@angular/core';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { FeatureCollection, Point } from 'geojson';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PointOfInterestListOptionsViewModel } from './point-of-interest.model';

import { PointOfInterestRepository } from './point-of-interest.repository';

@Injectable()
export class PointOfInterestManager {
  private readonly _pointOfInterestRepository: PointOfInterestRepository;
  private readonly _loggerService: LoggerService;

  constructor(pointOfInterestRepository: PointOfInterestRepository, loggerService: LoggerService) {
    this._pointOfInterestRepository = pointOfInterestRepository;
    this._loggerService = loggerService;
  }

  requestPointsOfInterest(listOptions: PointOfInterestListOptionsViewModel): Observable<FeatureCollection<Point>> {
    return this._pointOfInterestRepository
      .getPointsOfInterest(listOptions)
      .pipe(
        tap(pointsOfInterest => {
          this._loggerService
            .info(
              LoggerTopic.PointOfInterest,
              `Points of interest received`,
              pointsOfInterest,
            );
        }),
      );
  }
}
