<div class="message-panel">
  <app-avatar-list [avatarList]="avatarItems" context="chat-internal">
  </app-avatar-list>
</div>

<app-messages [allowAdd]="true" [allowEdit]="true" [allowDelete]="true" [placeholder]="'Reply to internal team here...'"
              [disabled]="currentChatMessageManager?.chatMessageManager?.isDisabled(currentChatMessageManager?.lease)"
              [context]="MessengerContext.Chat" [confirmSend]="false"
              [messageManager]="currentChatMessageManager?.chatMessageManager"
              (messagesLoaded)="messagesLoaded()" #messagesView>
</app-messages>
