import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {DxButtonModule, DxScrollViewModule, DxTextAreaModule, DxTooltipModule} from 'devextreme-angular';

import { RequestFinancialsDialogComponent } from './components/request-financials-dialog/request-financials-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxTextAreaModule,
    DxButtonModule,
    DxTooltipModule,
    DxScrollViewModule,
  ],
  declarations: [
    RequestFinancialsDialogComponent,
  ],
  entryComponents: [
    RequestFinancialsDialogComponent,
  ],
  providers: [],
})
export class LeaseRequestModule {
  static forRoot(): ModuleWithProviders<LeaseRequestModule> {
    return {
      ngModule: LeaseRequestModule,
      providers: [],
    };
  }
}
