import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import {ProfileRepository} from './profile.repository';
import {ProfileManager} from './profile.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    ProfileRepository,
    ProfileManager,
  ],
})
export class ProfileModule {
  static forChild(configuration: Configuration): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        ProfileManager,
        ProfileRepository,
      ],
    };
  }
}
