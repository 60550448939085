<div class="plan-viewer-editor">
  <ul>
    <li>
      <button type="button" title="Pan and Zoom"
              [class.is-active]="isDragEnabled"
              (click)="setIsDragEnabled(!isDragEnabled)">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#move"></i>
      </button>
    </li>
    <!-- Start: Shapes -->
    <li *ngIf="mode === modes.Marker">
      <button type="button" title="Add circle marker"
              [class.is-active]="isDrawCircleEnabled"
              (click)="setIsDrawCircleEnabled(!isDrawCircleEnabled)">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#circle"></i>
      </button>
    </li>
    <li>
      <button type="button" title="Add rectangle marker"
              [class.is-active]="isDrawRectEnabled"
              (click)="setIsDrawRectEnabled(!isDrawRectEnabled);">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#rectangle"></i>
      </button>
    </li>
    <li *ngIf="mode === modes.Marker">
      <button type="button" title="Add cross marker"
              [class.is-active]="isDrawCrossEnabled"
              (click)="setIsDrawCrossEnabled(!isDrawCrossEnabled)">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#cross"></i>
      </button>
    </li>
    <!-- End: Shapes -->
    <!-- Start: Rotate -->
    <li>
      <button type="button" title="Rotate plan 90 degrees counter clockwise"
              (click)="rotateLeft()">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#square-left"></i>
      </button>
    </li>
    <li>
      <button type="button" title="Rotate plan 90 degrees clockwise"
              (click)="rotateRight()">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#square-right"></i>
      </button>
    </li>
    <!-- End: Rotate -->
    <li>
      <button type="button" title="Add polygon marker"
              [class.is-active]="isDrawPolygonEnabled"
              (click)="setIsDrawPolygonEnabled(!isDrawPolygonEnabled)">
        <i inlineSVG="assets/icons/floor-plan-btn-icon.svg#chain"></i>
      </button>
    </li>
  </ul>
</div>
