export enum ExpirationsReportTooltipChartType {
  MultiplePeriods = 1,
  SinglePeriod = 2,
}

export interface ExpirationsReportTooltip {
  expirationsReportTooltipChartType: ExpirationsReportTooltipChartType;
  startDate: Date | string;
  endDate: Date | string;
  totalSquareFeet: number;
  totalSquareFeetPercentage: number;
  totalNumberOfUnitsExpiring: number;
  totalNumberOfUniqueTenantExpiring: number;
}
