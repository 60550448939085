<div class="building-attachments">
  <carousel [ngClass]="getCarouselClassName()"
            [showIndicators]="showIndicators" [interval]="0"
            (click)="handleCarouselClick($event)"
            (activeSlideChange)="handleActiveSlideChange($event)">
    <ng-container *ngIf="attachments?.length; else noAttachmentsTemplate">
      <ng-container *ngFor="let attachment of getSortedAttachments(); let i = index">
        <ng-container [ngSwitch]="attachment.buildingAttachmentType">
          <!-- Start: Picture slide -->
          <ng-container *ngSwitchCase="BuildingAttachmentType.Picture">
            <slide *ngIf="attachment?.file">
              <img [alt]="'Attachment ' + (i + 1)"
                   [src]="attachment.file.url"
                   appProgressiveImage />
            </slide>
          </ng-container>
          <!-- End: Picture slide -->
          <!-- Start: HTML5 Video slide -->
          <ng-container *ngSwitchCase="BuildingAttachmentType.Video">
            <slide *ngIf="attachment?.file">
              <app-video-player [source]="attachment.file.url"
                                [playerType]="VideoPlayerType.HTML5"
                                (playing)="handlePlaying()"
                                (played)="handlePlayed()"
                                (paused)="handlePaused()"
                                #videoPlayer>
              </app-video-player>
            </slide>
          </ng-container>
          <!-- End: HTML5 Video slide -->
          <!-- Start: YouTube Video slide -->
          <ng-container *ngSwitchCase="BuildingAttachmentType.Youtube">
            <slide *ngIf="attachment?.externalUrl">
              <app-video-player [source]="attachment.externalUrl"
                                [playerType]="VideoPlayerType.YouTube"
                                (playing)="handlePlaying()"
                                (played)="handlePlayed()"
                                (paused)="handlePaused()"
                                #videoPlayer>
              </app-video-player>
            </slide>
          </ng-container>
          <!-- End: YouTube Video slide -->
          <!-- Start: Vimeo Video slide -->
          <ng-container *ngSwitchCase="BuildingAttachmentType.Vimeo">
            <slide *ngIf="attachment?.externalUrl">
              <app-video-player [source]="attachment.externalUrl"
                                [playerType]="VideoPlayerType.Vimeo"
                                (playing)="handlePlaying()"
                                (played)="handlePlayed()"
                                (paused)="handlePaused()"
                                #videoPlayer>
              </app-video-player>
            </slide>
          </ng-container>
          <!-- End: Vimeo Video slide -->
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Start: No attachments slide -->
    <ng-template #noAttachmentsTemplate>
      <slide>
        <img class="building-attachments-no-attachment"
             src="assets/img/no-image.svg" alt="" />
      </slide>
    </ng-template>
    <!-- End: No attachments slide -->
  </carousel>
</div>
