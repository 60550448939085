import {Injectable} from '@angular/core';
import {
  FloorPlanType,
  ICreateOrUpdateFloorPlanProViewModel,
  ICreatePlanProjectViewModel,
  IFileViewModel,
  IFloorPlanProInvitationViewModel,
  IFloorPlanProViewModel,
  IFloorPlanViewModel,
  IInviteFloorPlanProViewModel,
  ILeaseFloorPlanViewModel,
  ILeaseViewModel,
  IPlanAnchorCommentViewModel,
  IPlanAnchorImageMarkerViewModel,
  IPlanAnchorViewModel,
  IPlanProjectViewModel,
  IPlanViewModel,
  IProjectViewModel,
  LeaseTeam,
  PlanAnchorStatus,
  StateraClaimTypeAsEnum,
  StateraClaimValueAsEnum,
} from '@statera/sdk/common';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';
import {ProjectManager} from '@statera/sdk/project';
import {LeaseManager} from '@statera/sdk/lease';
import {FloorPlanRepository} from './floor-plan.repository';
import {Observable} from 'rxjs';

@Injectable()
export class FloorPlanManager {
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private readonly _projectManager: ProjectManager;
  private readonly _leaseManager: LeaseManager;
  private readonly _floorPlanRepository: FloorPlanRepository;

  constructor(
    stateraUserClaimManager: StateraUserClaimManager,
    projectManager: ProjectManager,
    leaseManager: LeaseManager,
    floorPlanRepository: FloorPlanRepository
  ) {
    this._stateraUserClaimManager = stateraUserClaimManager;
    this._projectManager = projectManager;
    this._leaseManager = leaseManager;
    this._floorPlanRepository = floorPlanRepository;
  }
  addAnchor(anchor: IPlanAnchorViewModel): Observable<IPlanAnchorViewModel> {
    return this._floorPlanRepository.addAnchor(anchor);
  }

  addComment(comment: IPlanAnchorCommentViewModel, token?: string) {
    return this._floorPlanRepository.addComment(comment, token);
  }

  updateAnchor(anchor: IPlanAnchorViewModel): Observable<void> {
    return this._floorPlanRepository.updateAnchor(anchor);
  }

  deleteAnchor(anchor: IPlanAnchorViewModel): Observable<void> {
    return this._floorPlanRepository.deleteAnchor(anchor);
  }

  updatePlan(model: IPlanViewModel): Observable<IPlanViewModel> {
    return this._floorPlanRepository.updatePlan(model);
  }

  uploadFloorPlanAnchorImage(file: File): Observable<IFileViewModel> {
    return this._floorPlanRepository.uploadFloorPlanAnchorImage(file);
  }

  getLeaseFloorPlans(leaseId: number = null): Observable<Array<ILeaseFloorPlanViewModel>> {
    return this._floorPlanRepository.getLeaseFloorPlans(leaseId);
  }

  updateAnchorAmount(
    anchor: IPlanAnchorViewModel,
    token?: string
  ): Observable<void> {
    return this._floorPlanRepository.updateAnchorAmount(anchor, token);
  }

  createFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    return this._floorPlanRepository.createFloorPlanAnchorImageMarker(marker);
  }

  updateFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    return this._floorPlanRepository.updateFloorPlanAnchorImageMarker(marker);
  }

  deleteFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    return this._floorPlanRepository.deleteFloorPlanAnchorImageMarker(marker);
  }

  showRequestFloorPlanButton(userId: number, role: string,
                             lease: ILeaseViewModel, floorPlans: Array<ILeaseFloorPlanViewModel>,
                             floorPlanType: FloorPlanType): boolean {
    if (floorPlans && floorPlans.length) {
      return false;
    }

    if (floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseTeam = this._leaseManager
        .getUserLeaseTeam(lease, userId, role);

      return leaseTeam === LeaseTeam.LandlordTeam;
    }
    return false;
  }

  showCreateFloorPlanButton(userId: number, role: string,
                             lease: ILeaseViewModel, floorPlans: Array<ILeaseFloorPlanViewModel>,
                             floorPlanType: FloorPlanType): boolean {
    if (floorPlans && floorPlans.length) {
      return false;
    }

    if (floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseTeam = this._leaseManager
        .getUserLeaseTeam(lease, userId, role);

      return leaseTeam === LeaseTeam.TenantTeam;
    }
    return false;
  }

  createLeaseFloorPlan(lease: ILeaseViewModel) {
    return this._floorPlanRepository.createLeaseFloorPlan(lease.id);
  }

  allowCreateOrUpdateMarker(userId: number, role: string, floorPlan: IFloorPlanViewModel,
                            lease?: ILeaseViewModel, project?: IProjectViewModel): boolean {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseFloorPlan = <ILeaseFloorPlanViewModel>floorPlan;
      const hasAccess = this._stateraUserClaimManager.checkUserAccess(
        StateraClaimTypeAsEnum.FloorPlan,
        StateraClaimValueAsEnum.Write,
        null,
        null,
        null,
        leaseFloorPlan.leaseId
      );
      const leaseTeam = this._leaseManager.getUserLeaseTeam(lease, userId, role);
      const currentTurn = this._projectManager.isCurrentRoleTurnOnTerms(role, userId, project, lease);
      return hasAccess && leaseTeam === LeaseTeam.TenantTeam && currentTurn;
    }
    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      const floorPlanViewModel = <IFloorPlanProViewModel>floorPlan;
      if (floorPlanViewModel.createdById === userId) {
        return true;
      }
    }

    return false;
  }

  canChangeAnchorStatus(userId: number, role: string, floorPlan: IFloorPlanViewModel,
                          lease?: ILeaseViewModel, project?: IProjectViewModel): boolean {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseFloorPlan = <ILeaseFloorPlanViewModel>floorPlan;
      const hasAccess = this._stateraUserClaimManager.checkUserAccess(
        StateraClaimTypeAsEnum.FloorPlan,
        StateraClaimValueAsEnum.Write,
        null,
        null,
        null,
        leaseFloorPlan.leaseId
      );
      const leaseTeam = this._leaseManager.getUserLeaseTeam(lease, userId, role);
      const currentTurn = this._projectManager.isCurrentRoleTurnOnTerms(role, userId, project, lease);
      return hasAccess && leaseTeam === LeaseTeam.LandlordTeam && currentTurn;
    }
    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      return false;
    }
  }

  showAnchorStatus(floorPlan: IFloorPlanViewModel): boolean {
    return floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan;
  }

  canChangeAmount(userId: number, role: string, floorPlan: IFloorPlanViewModel,
                        lease?: ILeaseViewModel, project?: IProjectViewModel): boolean {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseFloorPlan = <ILeaseFloorPlanViewModel>floorPlan;
      const hasAccess = this._stateraUserClaimManager.checkUserAccess(
        StateraClaimTypeAsEnum.FloorPlan,
        StateraClaimValueAsEnum.Write,
        null,
        null,
        null,
        leaseFloorPlan.leaseId
      );
      const currentTurn = this._projectManager.isCurrentRoleTurnOnTerms(role, userId, project, lease);
      return hasAccess && currentTurn;
    }

    return floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro;
  }

  showTabs(floorPlan: IFloorPlanViewModel) {
    return floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan;
  }

  showCopyLinkButton(userId: number, role: string, floorPlan: IFloorPlanViewModel) {
    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      const floorPlanViewModel = <IFloorPlanProViewModel>floorPlan;
      return floorPlanViewModel.createdById === userId;
    }

    return false;
  }

  canEditFloorPlan(userId: number, role: string, floorPlan: IFloorPlanViewModel): boolean {
    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      const floorPlanViewModel = <IFloorPlanProViewModel>floorPlan;
      return floorPlanViewModel.createdById === userId;
    }
    return false;
  }

  getTotalAmountCosts(anchors: Array<IPlanAnchorViewModel>): number {
    return anchors
      .filter(x => x.amount && x.status !== PlanAnchorStatus.Rejected)
      .map(x => x.amount)
      .reduce((a, b) => a + b, 0);
  }

  getFloorPlanDescription(floorPlan: IFloorPlanViewModel, userId: number, role: string,
                          lease?: ILeaseViewModel): string {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseTeam = this._leaseManager.getUserLeaseTeam(lease, userId, role);
      if (leaseTeam === LeaseTeam.TenantTeam) {
        return 'You have received the Floor plan. Accept/Reject improvements and add costs, as necessary.';
      }
      return 'In this module you can mark up the Improvements, add detailed descriptions and costs for reference.';
    }

    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      const floorPlanViewModel = <IFloorPlanProViewModel>floorPlan;
      if (floorPlanViewModel.createdById === userId) {
        return 'You can edit, leave comments and share this Plan with whomever you like.';
      }

      return 'You have received the plan.  Modify improvements, add comments and estimated costs, if required.';
    }
  }

  getFloorPlanTitle(floorPlan: IFloorPlanViewModel, userId: number, role: string,
                    lease?: ILeaseViewModel, project?: IProjectViewModel, planProject?: IPlanProjectViewModel): string {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      const leaseTeam = this._leaseManager.getUserLeaseTeam(lease, userId, role);
      if (leaseTeam === LeaseTeam.TenantTeam) {
        return 'Floor Plan: Tenant Improvements.';
      }
      return 'Floor Plan: Add Tenant Improvements.';
    }

    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      if (planProject) {
        return 'Plan: ' + planProject.name;
      }

      return 'Plan:';
    }

    return 'Floor Plan';
  }

  getFloorPlanAnchorListTitle(floorPlan: IFloorPlanViewModel, userId: number) {
    if (floorPlan?.floorPlanType === FloorPlanType.LeaseFloorPlan) {
      return 'Tenant Improvements';
    }

    if (floorPlan?.floorPlanType === FloorPlanType.FloorPlanPro) {
      const floorPlanViewModel = <IFloorPlanProViewModel>floorPlan;
      if (floorPlanViewModel.createdById === userId) {
        return 'Plan';
      }
    }

    return 'Improvements';
  }

  updateStatusAsync(id: number, status: PlanAnchorStatus, token?: string): Observable<void> {
    return this._floorPlanRepository.updateStatusAsync(id, status);
  }

  getChildPlanProjects(planProjectId?: number, token?: string): Observable<Array<IPlanProjectViewModel>> {
    return this._floorPlanRepository.getChildPlanProjects(planProjectId, token);
  }

  getPlanProject(planProjectId: number, token?: string): Observable<IPlanProjectViewModel> {
    return this._floorPlanRepository.getPlanProject(planProjectId, token);
  }

  createPlanProject(createPlanProjectViewModel: ICreatePlanProjectViewModel): Observable<IPlanProjectViewModel> {
    return this._floorPlanRepository.createPlanProject(createPlanProjectViewModel);
  }

  createFloorPlanPro(createFloorPlanProViewModel: ICreateOrUpdateFloorPlanProViewModel): Observable<IFloorPlanProViewModel> {
    return this._floorPlanRepository.createFloorPlanPro(createFloorPlanProViewModel);
  }

  updateFloorPlanPro(createFloorPlanProViewModel: ICreateOrUpdateFloorPlanProViewModel): Observable<IFloorPlanProViewModel> {
    return this._floorPlanRepository.updateFloorPlanPro(createFloorPlanProViewModel);
  }

  getFloorPlanPro(floorPlanId: number, token?: string): Observable<IFloorPlanProViewModel> {
    return this._floorPlanRepository.getFloorPlanPro(floorPlanId, token);
  }

  inviteToFloorPlanProOrProject(inviteFloorPlanPro: IInviteFloorPlanProViewModel): Observable<IFloorPlanProInvitationViewModel> {
    return this._floorPlanRepository.inviteToFloorPlanProOrProject(inviteFloorPlanPro);
  }

  deleteFloorPlan(floorPlan: IFloorPlanViewModel): Observable<void> {
    return this._floorPlanRepository.deleteFloorPlan(floorPlan);
  }
}
