<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row mb-4">
      <dx-date-box type="date" dateSerializationFormat ="yyyy-MM-dd" pickerType="calendar"
                   placeholder="INSERT CUSTOM LEASE COMMENCEMENT"
                   [(value)]="leaseTerm.commencement" (onValueChanged)="clearErrors(); leaseTermPropertyChange()"
                   class="real-estate-input">
      </dx-date-box>
    </div>

    <div class="row"
         *ngIf="showBeneficialOccupancyOptions()">
      <div class="row col-12 pl-0 mb-2">
        <dx-check-box [(value)]="leaseTerm.hasBeneficialOccupancy"
                      text="Beneficial Occupancy (Optional)">
        </dx-check-box>
      </div>
      <ng-container *ngIf="leaseTerm.hasBeneficialOccupancy">
        <div class="row">
          <label for="beneficialOccupancyTypeOptions">
            Please select option
          </label>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12 mb-2 radio-group-container">
            <dx-radio-group id="beneficialOccupancyTypeOptions" displayExpr="name" valueExpr="value"
                            [dataSource]="beneficialOccupancyTypeOptions" [(value)]="leaseTerm.beneficialOccupancyType"
                            (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
              <dx-validator>
                <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>

        <ng-container *ngIf="leaseTerm?.beneficialOccupancyType === BeneficialOccupancyType.Custom">

              <div class="mb-2 app-term-custom-value-template-textarea">
                  <app-term-custom-value-template-textarea placeholder="INSERT CUSTOM TEXT"
                                                           id="terminationOptionCustomText"
                                                           required requiredMessage="Custom text is required"
                                                           [(value)]="leaseTerm.beneficialOccupancyCustomText"
                                                           (ValueChanged)="clearErrors(); leaseTermPropertyChange()"
                  >
                  </app-term-custom-value-template-textarea>
              </div>

        </ng-container>

        <ng-container *ngIf="leaseTerm?.beneficialOccupancyType === BeneficialOccupancyType.TenantShallBeProvidedBeneficialOccupancy">
          <div class="row">
            <div class="col-sm-6 dx-field-value">
              <label>Choose Beneficial Occupancy date:</label>

              <dx-date-box type="date" dateSerializationFormat ="yyyy-MM-dd" pickerType="calendar"
                           placeholder="INSERT BENEFICIAL OCCUPANCY DATE"
                           [(value)]="leaseTerm.beneficialOccupancyAcceptableDate"
                           (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
              </dx-date-box>
            </div>
          </div>

          <div class="row term-container">
            <div class="col-sm-6 dx-field-value">
              <label for="tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriod">Choose abated costs during Beneficial Occupancy:</label>
              <dx-tag-box id="tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriod" displayExpr="name" valueExpr="value" placeholder="Select all that apply..."
                          [(dataSource)]="tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodTypeOptions"
                          [showClearButton]="true" [(value)]="leaseTerm.tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriod"
                          (onValueChanged)="clearErrors(); leaseTermPropertyChange()"
              >
                <dx-validator>
                  <dxi-validation-rule type="required" message="Please select at least one of the provided options before saving">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-tag-box>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-sm-12 pl-0">
        <div class="term-toast term-toast-neutral"
             *ngIf="rejectButtonClicked && (isTenantSquareFootageTypePhaseIn() || isRentalRateEscalationTypeCustom())">
          <i inlineSVG="assets/img/information.svg"></i>
          Please note that this change will be reflected in
          <ng-container *ngIf="isTenantSquareFootageTypePhaseIn() && !isRentalRateEscalationTypeCustom()">
            the Phase-In table
          </ng-container>
          <ng-container *ngIf="!isTenantSquareFootageTypePhaseIn() && isRentalRateEscalationTypeCustom()">
            the Escalation table
          </ng-container>
          <ng-container *ngIf="isTenantSquareFootageTypePhaseIn() && isRentalRateEscalationTypeCustom()">
            the Phase-In and Escalation tables
          </ng-container>
          automatically.
        </div>

        <div class="term-toast term-toast-error"
             *ngIf="isShowStrangeCommencementValueWarning()">
          <i inlineSVG="assets/img/x.svg"></i>
          Please check current Lease Expiration & make sure Lease Commencement date is correct.
        </div>
      </div>
    </div>

    <!-- Start: Validation summary -->
      <div >
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>
    <!-- End: Validation summary -->


    <div class="row main-actions">
      <div class="px-0">
        <div class="dx-fieldset">
          <dx-button id="button" text="Save" type="success" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
