import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  spinnerComponentShowSubject = new Subject<any>();
  spinnerComponentHideSubject = new Subject<any>();

  spinnersStack: Array<string> = [];

  constructor() {
  }

  show() {
    this.spinnersStack.push(`spinner ${this.spinnersStack.length}`);
    this.spinnerComponentShowSubject.next();
  }

  hide() {
    this.spinnersStack.pop();

    if (this.spinnersStack.length === 0) {
      this.spinnerComponentHideSubject.next();
    }
  }
}
