import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsRepository } from './analytics.repository';

import * as models from './analytics.model';
import {AnalyticsReportGroupingOption, EngagementWithEmailsViewModel} from './analytics.model';
import moment from 'moment';

@Injectable()
export class AnalyticsManager {
  private readonly _analyticsRepository: AnalyticsRepository;

  constructor(analyticsRepository: AnalyticsRepository) {
    this._analyticsRepository = analyticsRepository;
  }

  getAnalyticsFilters(): Observable<models.AnalyticsFilters> {
    return this._analyticsRepository.getAnalyticsFilters();
  }

  getKeyStatisticsReport(filters: models.AnalyticsFilters): Observable<models.KeyStatisticsReport> {
    return this._analyticsRepository.getKeyStatisticsReport(filters);
  }

  getExpirationsReport(
    periods: Array<{startDate: string, endDate: string}>,
    groupingOption: models.ExpirationsReportGroupingOption,
    filters: models.AnalyticsFilters,
  ): Observable<models.ExpirationsReport> {
    return this._analyticsRepository.getExpirationsReport(periods, groupingOption, filters);
  }

  getOccupancyAndVacancyReport(
    periods: Array<{startDate: string, endDate: string}>,
    groupingOption: models.OccupancyAndVacancyReportGroupingOption,
    filters: models.AnalyticsFilters,
  ): Observable<models.OccupancyAndVacancyReport> {
    return this._analyticsRepository.getOccupancyAndVacancyReport(periods, groupingOption, filters);
  }

  getDealsByStageReport(
    period: {startDate: string, endDate: string},
    dealType: models.DealsByStageReportDealType,
    filters: models.AnalyticsFilters,
  ): Observable<models.DealsByStageReport> {
    return this._analyticsRepository.getDealsByStageReport(period, dealType, filters);
  }

  getActiveDealsByStageReport(
    filters: models.AnalyticsFilters,
  ): Observable<models.ActiveDealsByStageReport> {
    return this._analyticsRepository.getActiveDealsByStageReport(filters);
  }

  getInquiryInsightsKeyStatisticsReport(
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsKeyStatisticsReport> {
    return this._analyticsRepository.getInquiryInsightsKeyStatisticsReport(filters);
  }

  getInquiryInsightsInquiryDynamicsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiryDynamicsReport> {
    return this._analyticsRepository.getInquiryInsightsInquiryDynamicsReport(periods, filters);
  }

  getInquiryInsightsInquiriesSubmittedByReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesSubmittedByReport> {
    return this._analyticsRepository.getInquiryInsightsInquiriesSubmittedByReport(periods, filters);
  }

  getInquiryInsightsInquiriesActivityReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesActivityReport> {
    return this._analyticsRepository.getInquiryInsightsInquiriesActivityReport(period, filters);
  }

  getInquiryInsightsAverageSizeRequestedReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsAverageSizeRequestedReport> {
    return this._analyticsRepository.getInquiryInsightsAverageSizeRequestedReport(periods, filters);
  }

  getInquiryInsightsInquiriesWithToursConvertedToDealReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiriesWithToursConvertedToDealReport> {
    return this._analyticsRepository.getInquiryInsightsInquiriesWithToursConvertedToDealReport(periods, filters);
  }

  getInquiryInsightsInquiryDeleteTopReasonsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsInquiryDeleteTopReasonsReport> {
    return this._analyticsRepository.getInquiryInsightsInquiryDeleteTopReasonsReport(period, filters);
  }

  getInquiryInsightsTopBuildingsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsTopBuildingsReport> {
    return this._analyticsRepository.getInquiryInsightsTopBuildingsReport(period, filters);
  }

  getInquiryInsightsTopLocationsReport(
    period: {startDate: string, endDate: string},
    filters: models.AnalyticsFilters,
  ): Observable<models.InquiryInsightsTopLocationsReport> {
    return this._analyticsRepository.getInquiryInsightsTopLocationsReport(period, filters);
  }

  getListingSharesAndViewsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.ListingSharesAnsViewsReportViewModel> {
    return this._analyticsRepository.getListingSharesAndViewsReport(periods, filters);
  }

  getListingViewsEngagementReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.ListingViewsEngagementReportViewModel> {
    return this._analyticsRepository.getListingViewsEngagementReport(periods, filters);
  }

  getEngagementWithEmailsReport(
    periods: Array<{startDate: string, endDate: string}>,
    filters: models.AnalyticsFilters,
  ): Observable<models.EngagementWithEmailsViewModel> {
    return this._analyticsRepository.getEngagementWithEmailsReport(periods, filters);
  }

  getPercentageDynamics(currentValue: number, previousValue: number): number {
    if (!previousValue && !currentValue) {
      return 0;
    } else if (!previousValue && currentValue) {
      return 100;
    } else if (previousValue && !currentValue) {
      return -100;
    } else {
      return (
        (
          (previousValue - currentValue) /
          (previousValue)
        ) *
        -100
      );
    }
  }

  getAnalyticsPeriods(groupingOption: AnalyticsReportGroupingOption, periodNumber: number = 5):
    Array<{startDate: string, endDate: string}> {
    const today = moment().startOf('day');
    return new Array(periodNumber)
      .fill(null)
      .map((_, index) => {
        let date = today.clone();

        let startDate;
        let endDate;
        switch (groupingOption) {
          case AnalyticsReportGroupingOption.Month:
            date = date.subtract(index, 'months');
            startDate = date.clone().startOf('month');
            endDate = date.clone().endOf('month');
            break;
          case AnalyticsReportGroupingOption.Week:
          default:
            date = date.subtract(index, 'weeks');
            startDate = date.clone().startOf('week');
            endDate = date.clone().endOf('week');
            break;
        }

        return {
          startDate: startDate.startOf('day').format('YYYY-MM-DD'),
          endDate: endDate.endOf('day').format('YYYY-MM-DD'),
        };
      })
      .reverse();
  }

  getAnalyticsLabels(groupingOption: AnalyticsReportGroupingOption,
                     periods: Array<{ startDate: Date | string, endDate: Date | string}>): Array<string> {
    return periods
      .map(x => {
        const startDate = moment(x.startDate);
        const endDate = moment(x.endDate);

        switch (groupingOption) {
          case AnalyticsReportGroupingOption.Month:
            return startDate.format('MMM');
          case AnalyticsReportGroupingOption.Year:
            return startDate.format('YYYY');
          case AnalyticsReportGroupingOption.Week:
          default:
            if (startDate.get('month') === endDate.get('month')) {
              return `${startDate.format('MMM')} ${startDate.get('date')}–${endDate.get('date')}`;
            }

            return `${startDate.format('MMM DD')} – ${endDate.format('MMM DD')}`;
        }
      })
      .map(x => x.toUpperCase());
  }
}
