<div class="attachments-search">
  <h2 class="b-title">{{getHeaderText()}}</h2>

  <dx-text-box class="attachments-textbox" mode="search" placeholder="Search"
               [(value)]="searchAttachmentText"
               (onValueChanged)="handleSearchValueChange($event)">
  </dx-text-box>
</div>

<div class="attachments-accordion-caption">
  <p>Group name</p>
  <p>Edit options</p>
</div>

<dx-accordion class="attachments-accordion"
              [dataSource]="foldersWithFiles" [collapsible]="true" [multiple]="true" [animationDuration]="300"
              (onContentReady)="onAttachmentsAccordionContentReady($event)"
              (onItemTitleClick)="onItemTitleClick($event)"
              #dxAccordionComponent>
  <div *dxTemplate="let folderWithFiles of 'title'">
    <span>
      {{folderWithFiles.folder.name}}
      <ng-container *ngIf="!hasAccess(folderWithFiles, StateraClaimValue.Read)">
        (Files Restricted)
      </ng-container>
    </span>
    <!-- Temporary workaround as neither Tenant nor Landlord are able to upload both Site Plans and Floor Plans properly. -->
    <div *ngIf="hasAccess(folderWithFiles, StateraClaimValue.Write) &&
                folderWithFiles.folder.tenantFileFolderType !== TenantFileFolderType.Plans"
         class="action-block">
      <dx-file-uploader name="file" selectButtonText="Upload"
                        [style.visibility]="folderWithFiles.folder.isShared ? 'hidden' : 'visible'"
                        [uploadUrl]="buildUploadUrl(folderWithFiles.folder.id)"
                        (onUploaded)="onUploaded($event)" (onUploadError)="onUploadError($event)"
                        (onUploadAborted)="onUploadAborted()">
      </dx-file-uploader>
      <ng-container *ngIf="!folderWithFiles.folder.isShared">
        <button type="button"
                (click)="moveFiles(folderWithFiles)" title="Move files">
          <i class="fa fa-arrows"></i>
        </button>
        <button type="button"
                *ngIf="folderWithFiles.folder.id !== summaryOfTermsFolderId"
                [disabled]="!isSelectedFilesShareable(folderWithFiles.selectedFiles)"
                (click)="shareFiles(folderWithFiles)" title="Share files">
          <i class="fa fa-share"></i>
        </button>
      </ng-container>
      <!-- Temporary workaround as neither Tenant nor Landlord are able to upload both Site Plans and Floor Plans properly. -->
      <ng-container *ngIf="folderWithFiles.folder.tenantFileFolderType !== TenantFileFolderType.Plans &&
                           folderWithFiles.folder.buildingFileFolderType !== BuildingFileFolderType.SitePlans">
        <button type="button" title="Delete files"
                (click)="removeFiles(folderWithFiles)">
          <i class="fa fa-trash-o"></i>
        </button>
      </ng-container>
    </div>
  </div>

  <div *dxTemplate="let folderWithFiles of 'item'">
    <div class="file">
      <span class="headers">File(s)</span>
      <span>Date modified</span>
    </div>

    <dx-list selectionMode="multiple" searchExpr="file.name" searchMode="contains"
             [dataSource]="folderWithFiles.files" [allowItemDeleting]="false" [showSelectionControls]="true"
             [(selectedItems)]="folderWithFiles.selectedFiles" [(searchValue)]="searchAttachmentText"
             (onItemRendered)="onItemRendered($event)"
             #dxListComponent>
      <div class="file"
           *dxTemplate="let item of 'item'">
        <ng-container *ngIf="item.file">
          <a role="button" class="text-primary cursor-pointer file-name"
             [title]="item.file.name"
             (click)="previewFile($event, item.file)">
            {{item.file.name}}
          </a>
          <span class="file-date">
            {{item.file.createdOn  | date:'MM/dd/yy'}} @ {{item.file.createdOn  | date:'HH:mm:ss'}}
          </span>
        </ng-container>
      </div>
    </dx-list>
  </div>
</dx-accordion>

<dx-file-uploader id="fileUploaderForRequestedDocument" name="file"
                  [visible]="false" [(uploadUrl)]="uploadUrlForRequestedDocument"
                  (onUploaded)="onUploaded($event)" (onUploadError)="onUploadError($event)"
                  (onUploadAborted)="onUploadAborted()"
                  #fileUploaderForRequestedDocument>
</dx-file-uploader>

<dx-popup title="Move files"
          [maxWidth]="500" [maxHeight]="300" [showTitle]="true" [showCloseButton]="true" [closeOnOutsideClick]="true"
          [dragEnabled]="true" [(visible)]="isMoveFilesPopupVisible"
          (onHidden)="onMoveFilesPopupHidden()">
  <dx-box class="popup-box justify-content-between" direction="col" width="100%">
    <dxi-item [baseSize]="60">
      <dx-select-box class="mb-3" displayExpr="name" valueExpr="id"
                     [(value)]="moveFilesDestinationFolderId" [items]="moveFilesDestinationFolders">
      </dx-select-box>
    </dxi-item>
    <dxi-item [baseSize]="35">
      <div>
        <dx-button class="popup-button-ok" text="Move" type="success"
                   (onClick)="movePupupOkClick()">
        </dx-button>
        <dx-button class="popup-button-cancel" text="Cancel"
                   (onClick)="movePupupCancelClick()">
        </dx-button>
      </div>
    </dxi-item>
  </dx-box>
</dx-popup>

<dx-popup id="moveFilesCheckPopup" title="Move files"
          [maxWidth]="500" [maxHeight]="300" [showTitle]="true" [showCloseButton]="true"
          [closeOnOutsideClick]="true" [dragEnabled]="true" [(visible)]="isMoveFilesCheckPopupVisible">
  <dx-box class="popup-box justify-content-between" direction="col" width="100%">
    <dxi-item [baseSize]="35">
      <h5>
        Are you sure you want to move this file?
      </h5>
    </dxi-item>
    <dxi-item [baseSize]="35">
      <div>
        <dx-button class="popup-button-ok" text="Yes" type="success"
                   (onClick)="moveCheckPupupOkClick()">
        </dx-button>
        <dx-button class="popup-button-cancel" text="Cancel"
                   (onClick)="moveCheckPupupCancelClick()">
        </dx-button>
      </div>
    </dxi-item>
  </dx-box>
</dx-popup>

