import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DxFileUploaderComponent, DxScrollViewComponent, DxTooltipComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { filter, map, share } from 'rxjs/operators';

import { VersionedFileCommentManager, Message, VersionedFileCommentMessage } from '@statera/sdk/message';

import { environment } from '../../../../environments/environment';

import { AlertService } from '../../../alert/services/alert.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-lease-document-comments',
  templateUrl: 'lease-document-comments.component.html',
  styleUrls: ['lease-document-comments.component.scss'],
})
export class LeaseDocumentCommentsComponent {
  private readonly _domSanitizer: DomSanitizer;
  private readonly _alertService: AlertService;

  @Input() height = 0;

  private _messageManager: VersionedFileCommentManager;

  @Input() set messageManager(val) {
    if (val) {
      this._messageManager = val;
      this.cleaning = false;
      this.loadData();
    } else {
      this.messages = [];
      this.cleaning = true;
    }
  }

  @Input() versionedFiles: Array<models.IVersionedFileViewModel>;

  @Input() allowAdd = true;
  @Input() allowEdit = true;
  @Input() allowDelete = true;
  @Output() imageUploaded = new EventEmitter<File>();
  @Output() messagesLoaded = new EventEmitter();
  @Output() versionedFileSelected = new EventEmitter<models.IVersionedFileViewModel>();
  @ViewChild('scrollView') scrollView: DxScrollViewComponent;
  @ViewChild('scrollViewContainer') scrollViewContainer: any;
  @ViewChild('messageMenuToolTip') messageMenuToolTip: DxTooltipComponent;
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;
  @ViewChild('imageUploader') imageUploader: DxFileUploaderComponent;
  @ViewChild('messagesContainer') messagesContainer: any;
  @ViewChild('editorContainer') editorContainer: any;

  @ViewChildren('messageElements') set messageElements(messageElements: QueryList<ElementRef>) {
    if (!messageElements || !messageElements.length) {
      return;
    }

    const subscription = messageElements.changes
      .pipe(
        filter((messageElementRefList: QueryList<ElementRef>) => 0 < messageElementRefList.length),
      )
      .subscribe((messageElementRefList: QueryList<ElementRef>) => {
        this._initializeScrollView(messageElementRefList);
        subscription.unsubscribe();
      });
  }

  MessageType = models.MessageType;
  htmlEditor: any;
  editing = false;
  imageUploadUrl = environment.webApiUrl + '/chat/sendimage';
  filePopupVisible = false;
  uploadedFile: models.IFile;
  fileUploadUrl = environment.webApiUrl + '/storage/Upload';
  htmlEditorValue = '';
  messages: Array<VersionedFileCommentMessage> = [];
  pageSize = 10;
  pageIndex = 0;
  canLoadNext = true;
  tooltipVisible = false;
  _currentEditingMessageId = 0;
  placeHolderValue = 'Write something here...';

  get currentEditingMessageId() {
    return this._currentEditingMessageId;
  }

  set currentEditingMessageId(val) {
    this._currentEditingMessageId = val;
    if (val) {
      this.currentEditingMessage = this.messages.find(x => x.id === this.currentEditingMessageId);
    } else {
      this.currentEditingMessage = null;
    }
  }

  currentEditingMessage: VersionedFileCommentMessage;
  cleaning = false;
  items = [
    {id: 'attachfile', text: 'Attach File', icon: 'fa fa-file-o'},
  ];

  bottomMenuVisible = false;

  constructor(domSanitizer: DomSanitizer, alertService: AlertService) {
    this._domSanitizer = domSanitizer;
    this._alertService = alertService;
  }

  resetEditor() {
    this.editing = false;
    this.htmlEditorValue = '';
    this.currentEditingMessage = null;
  }

  loadData() {
    this.pageIndex = 0;
    this.messages = [];
    this.getCurrentPage();
  }

  getVersionedFileVersion(comment: VersionedFileCommentMessage): string | void {
    if (!comment.versionedFile) {
      return null;
    }

    const version = comment.versionedFile.version;
    if (version <= 1) {
      return 'Original Draft';
    }

    return `Version ${version}`;
  }

  getCurrentPage(done: any = null) {
    const subscription = this._messageManager
      .getMessages(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(
        map(response => response
          .map((item) => {
            item.content = this.removeParagaph(item.content);
            (<Message & {innerHtml: SafeHtml}>item).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(item.content);
            return <VersionedFileCommentMessage>item;
          }),
        ),
        share(),
      )
      .subscribe(messages => {
        this.messages = [...messages, ...this.messages]
          .sort((x, y) => x.id - y.id);

        if (this.messagesLoaded) {
          this.messagesLoaded.emit();
        }
      })
      .add(() => {
        if (done) {
          done();
        }

        subscription.unsubscribe();
      });
  }

  getNextPage(done: any = null) {
    if (this.cleaning) {
      this.cleaning = false;
    } else {
      this.pageIndex++;
      this.getCurrentPage(done);
    }
  }

  onMessagesScroll(e) {
    if (this.canLoadNext && e.scrollOffset.top === 0) {
      this.canLoadNext = false;
      this.getNextPage(() => {
        e.component.release();
        this.canLoadNext = true;
      });
    }
  }

  addMessage(message, uploadedFileId: number = null) {
    const subscription = this._messageManager
      .addMessage(<VersionedFileCommentMessage>{
        content: this.removeParagaph(message),
        uploadedFileId: uploadedFileId,
      })
      .subscribe(msg => {
        (<Message & {innerHtml: SafeHtml}>msg).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(msg.content);
        this.messages.push(msg);
        this.scrollView.instance.scrollBy(this.scrollView.instance.scrollHeight());
        this.resetEditor();
        subscription.unsubscribe();
      });
  }

  messageIconClick(e, messageId: number) {
    e.stopPropagation();
    this.messageMenuToolTip.instance.option('target', e.target);
    this.tooltipVisible = true;
    this.currentEditingMessageId = messageId;
  }

  htmlEditorInitialized(e) {
    this.htmlEditor = e.component;
  }

  htmlEditorContentReady() {
    const me = this;
    // TODO: [FIXME] We must stop use jQuery, we already using Angular :)
    $('.ql-editor').on('keydown', e => {
      const message = $(`<div>${me.htmlEditorValue.replace('<p><br></p>', '')}</div>`).text().trim();
      // TODO: [FIXME] We must stop use deprecated props/methods
      // tslint:disable:deprecation
      if (message && !e.shiftKey && e.keyCode === 13) {
        me.sendMessage();
      }
      if (e.keyCode === 27) {
        me.cancelEditing();
      }
      // tslint:enable:deprecation
    });
  }

  sendMessage() {
    this.htmlEditor.focus();
    const text: string = this.htmlEditorValue.trim();
    this.htmlEditorValue = '';
    this.htmlEditor.focus();
    if (text) {
      if (this.editing) {
        const message = JSON.parse(JSON.stringify(this.currentEditingMessage));
        message.content = text;
        const subscription = this._messageManager
          .updateMessage(message)
          .subscribe(response => {
            (<Message & {innerHtml: SafeHtml}>this.currentEditingMessage).innerHtml = this._domSanitizer
              .bypassSecurityTrustHtml(response.content);

            this.currentEditingMessage.content = response.content;
            this.currentEditingMessageId = 0;
            this.resetEditor();
            subscription.unsubscribe();
          });
      } else {
        this.addMessage(text);
      }
    }
  }

  cancelEditing() {
    if ((this.currentEditingMessage && this.currentEditingMessage.content !== this.htmlEditorValue) ||
      this.htmlEditorValue.trim()) {
      confirm('Cancel editing message?', 'Please confirm').done(confirmed => {
        if (confirmed) {
          this.resetEditor();
        }
      });
    } else {
      this.resetEditor();
    }
  }

  textAreaMenuItemClick(e) {
    e.event.stopPropagation();
    let selection = this.htmlEditor.getSelection();
    if (!selection) {
      this.htmlEditor.focus();
      selection = this.htmlEditor.getSelection();
    }
    const format = this.htmlEditor.getFormat(selection.index, selection.length);
    switch (e.itemData.id) {
      case 'addimage':
        $((<any>this.imageUploader.instance)._selectButton.element()).trigger('click');
        break;
      case 'attachfile':
        this.filePopupVisible = true;
        break;
      case 'bold':
      case 'italic':
      case 'strike':
      case 'underline':
      case 'blockquote':
      case 'code-block':
        this.htmlEditor.format(e.itemData.id, !format[e.itemData.id]);
        break;
      case 'alignleft':
      case 'aligncenter':
      case 'alignright':
      case 'alignjustify':
        const align = e.itemData.id.replace('align', '');
        this.htmlEditor.format('align', format.align && format.align === align ? false : align);
        break;
      case 'orderedlist':
      case 'bulletlist':
        const list = e.itemData.id.replace('list', '');
        this.htmlEditor.format('list', format.list && format.list === list ? false : list);
        break;
    }
  }

  onImageUploaded(e) {
    const file = this.uploadedFile = JSON.parse(e.request.responseText);
    if (this.imageUploaded) {
      this.imageUploaded.emit(file);
    }
    this.addMessage(`<a href="${file.url}"><img src="${file.url}" width="200px" /></a>`, file.id);
    e.component.reset();
  }

  onImageUploadError(e) {
    e.component.reset();
  }

  onImageUploadAborted(e) {
    e.component.reset();
  }

  onFileUploaded(e) {
    this.uploadedFile = JSON.parse(e.request.responseText);
  }

  onFileUploadError(e) {
    e.component.reset();
    this._alertService.pushErrorAlert({
      message: e.request.responseText,
    });
  }

  onFileUploadAborted(e) {
    e.component.reset();
  }

  filePopupAttachClick() {
    const subscription = this._messageManager
      .addMessage(<VersionedFileCommentMessage>{
        content: JSON.stringify(<models.IChatFileViewModel>{fileUid: this.uploadedFile.uid}),
      })
      .subscribe(message => {
        (<Message & {innerHtml: SafeHtml}>message).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(message.content);
        this.messages.push(message);
        this.scrollView.instance.scrollBy(this.scrollView.instance.scrollHeight());
        this.resetEditor();
        subscription.unsubscribe();
      });

    this.fileUploader.instance.reset();
    this.filePopupVisible = false;
  }

  filePopupCancelClick() {
    this.resetFileData();
    this.filePopupVisible = false;
    this.fileUploader.instance.reset();
  }

  resetFileData() {
    this.uploadedFile = null;
  }

  startEditingMessage() {
    this.htmlEditor.option('value', this.currentEditingMessage.content);
    this.editing = true;
    this.htmlEditor.focus();
    this.tooltipVisible = false;
  }

  startDeletingMessage() {
    confirm('Are you sure you want to delete the message', 'Confirm').done(confirmed => {
      if (confirmed) {
        this.tooltipVisible = false;
        const subscription = this._messageManager
          .deleteMessage(this.currentEditingMessage)
          .subscribe(() => {
            this.messages.splice(this.messages.indexOf(this.currentEditingMessage), 1);
            subscription.unsubscribe();
          });
      }
    });
  }

  removeParagaph(htmlStr: string) {
    return htmlStr ? htmlStr.replace(/<p><br><\/p>/g, '') : '';
  }

  private _initializeScrollView(messageElementRefList: QueryList<ElementRef>): void {
    if (!this.scrollView || !this.scrollView.instance || !messageElementRefList || !messageElementRefList.length) {
      return;
    }

    const messagesHeight = messageElementRefList.reduce(
      (acc: number, elementRef: ElementRef): number => {
        if (!elementRef.nativeElement) {
          return acc;
        }

        const elementBoundingClientRect = elementRef.nativeElement.getBoundingClientRect();
        return acc + <number>elementBoundingClientRect.height;
      },
      0,
    );

    const scrollViewClientHeight = this.scrollView.instance.clientHeight();
    if (messagesHeight < scrollViewClientHeight) {
      this.getNextPage();
    }

    if (this.messages.length <= this.pageSize) {
      const scrollToBottom = () => {
        const scrollViewScrollHeight = this.scrollView.instance.scrollHeight();
        this.scrollView.instance.scrollBy(scrollViewScrollHeight);
      };

      scrollToBottom();

      const imagePromises: Array<Promise<void>> = [];
      messageElementRefList.forEach((elementRef: ElementRef): void => {
        if (!elementRef.nativeElement) {
          return;
        }

        const imageElements = elementRef.nativeElement.querySelectorAll('img');
        imageElements.forEach((imageElement: HTMLImageElement): void => {
          if (imageElement.complete) {
            imagePromises.push(
              Promise.resolve(),
            );

            return;
          }

          imagePromises.push(
            new Promise((resolve: () => void, reject: () => void): void => {
              imageElement.onload = () => resolve();
              imageElement.onerror = () => reject();
            }),
          );
        });
      });

      if (imagePromises.length) {
        Promise
          .all(imagePromises)
          .then(() => scrollToBottom());
      }
    }
  }
}
