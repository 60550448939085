import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './tenant.model';

@Injectable()
export class TenantRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  cancelRequestViaTenantWizard(quizId: number, leaseId: number, model: Array<models.QuizAnswer>): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/lease/CancelRequestViaTenantWizard?quizId=${quizId}&leaseId=${leaseId}`, model
      );
  }

  getLease(leaseId: number): Observable<models.Lease> {
    return this._httpClient
      .get<models.Lease>(`${this._configuration.apiEndpoint}/lease/get?leaseId=${leaseId}`);
  }

  getProjectsByLeaseId(leaseId: number): Observable<Array<models.Project>> {
    const options = {
      params: new HttpParams().set('leaseId', leaseId.toString())
    };

    return this._httpClient
      .get<Array<models.Project>>(`${this._configuration.apiEndpoint}/project/GetByLeaseId`, options);
  }

  getBuildingAddresses(): Observable<Array<models.Address>> {
    return this._httpClient
      .get<Array<models.Address>>(`${this._configuration.apiEndpoint}/Address/GetBuildingAddresses`);
  }

  cancelRequest(leaseId: number): Observable<models.CancelledRequest> {
    const cancelledRequest = <models.CancelledRequest>{ leaseId: leaseId };

    return this._httpClient
      .post<models.CancelledRequest>(`${this._configuration.apiEndpoint}/lease/CancelRequest`, cancelledRequest);
  }
}
