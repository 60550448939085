<div class="term-custom-value-template-dialog">
  <div class="term-custom-value-template-header">
    <div>
      <div class="term-custom-value-template-icon">
        <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-template"></i>
      </div>
      <div class="term-custom-value-template-title">
        {{template.id ? 'Edit' : 'Save as'}} a template
      </div>
    </div>
    <div>
      <button type="button" class="close-button"
              (click)="close()">
        <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-x-16"></i>
      </button>
    </div>
  </div>
  <div class="term-custom-value-template-content">
    <dx-scroll-view width="100%" height="100%">
      <div class="term-custom-value-template-content-inner">
        <dx-text-area required
                      valueChangeEvent="keyup"
                      placeholder="Input a custom clause"
                      [(value)]="template.content">
          <dx-validator>
            <dxi-validation-rule type="required" message="This field is required">
            </dxi-validation-rule>
          </dx-validator>
        </dx-text-area>
      </div>
    </dx-scroll-view>
  </div>
  <div class="term-custom-value-template-footer">
    <button type="button" class="button-secondary"
            (click)="handleCancelClick()">
      Cancel
    </button>
    <button type="button" class="button-primary"
            [disabled]="!template.content"
            (click)="handleSaveClick()">
      Save
    </button>
  </div>
</div>
