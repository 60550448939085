<div class="container">
    <div style="width:100%;height:222px;background:#f3f3f3;;padding-top:1%">
        <div class="adM">

        </div>
        <div style="margin:auto;margin-top:55px;margin-bottom:35px; width: fit-content; justify-content: center; display: flex;">
            <div class="adM">
            </div>
            <img src="https://i.ibb.co/QC8G7hg/Statera-logo-color.png" style="margin:auto" alt="" class="CToWUd" />
        </div>
        <div style="margin:auto;width:fit-content;border-radius:50%; justify-content: center; display: flex;">
            <span style="width: 192px; background-color: white; border-radius: 50%; font-size: 96pt; text-align: center;">🙃</span>
        </div>
    </div>
    <div style="font-size: 24px; width: 80%; margin: 120px auto 70px; font-family: sans-serif; text-align: center;">
        <span style="font-size: 36pt;">
        </span>
        <p style="font-size: 42pt;">
            <strong>
                Oh boy!
                <br />
            </strong>
        </p>
        <p style="font-size: 21pt;">
            Sorry, it's us, not you.<br />We're on it, please check back soon!
        </p>
    </div>
    <hr style="border-bottom: 0.1px solid #59b681; width: 100%; margin-top: 2%; margin-bottom: 3%;" />
    <div style="font-size:26px;width:80%;margin:auto; font-family: sans-serif;margin-top: 60px;margin-bottom: 30px;">
        Contact us:
    </div>
    <div style="width:80%;margin:auto;margin-top:1%">
        <div style="width:100%;">
            <img src="https://i.ibb.co/GPwfTFz/email.png" alt="" />
            <span style="font-size:20px; padding-top: 3px; margin-left: 10px; font-family: sans-serif;">
                <a href="mailto:help@statera.re" target="_blank">help@statera.re</a>
            </span>
        </div>
        <div style="width:100%; margin-top: 3%;margin-bottom: 60px;">
            <img src="https://i.ibb.co/3hMKYcP/web.png" style="margin-bottom: -5px;" alt="" />
            <span style="font-size:20px; margin-left: 7px; font-family: sans-serif;">
                <a href="http://www.statera.re" target="_blank">www.statera.re</a>
            </span>
        </div>
    </div>
    <div style="width:100%;background:#f3f3f3;;padding-top:3%;padding-bottom:3%;margin-top:3%;margin-bottom:5%;">
        <p style="text-align:center;;font-size:22px; font-family: sans-serif;">
            Connect with us
        </p>
        <div style="width:fit-content;margin:auto;margin-top:1%;  justify-content: center; display: flex;">
            <a href="https://www.facebook.com/" style="text-decoration: none;">
                <img src="https://i.ibb.co/8X2Gy1h/facebook.png" style="width:50px;height:50px;margin-left:auto; margin-right: 5px;" class="CToWUd"
                    alt="" />
            </a>
            <a href="https://linkedin.com/in/statera-cre-4a93a016b" style="text-decoration: none;">
                <img src="https://i.ibb.co/0B3mk2c/in.png" style="width:50px;height:50px;margin-right:auto; margin-left: 5px;" alt="" />
            </a>
        </div>
    </div>
    <p style="text-align:center;color:gray;font-size:16px;margin-bottom:1%; font-family: sans-serif;">
        © 2022 Statera RE, Inc. All rights reserved.
    </p>
</div>
