<div class="multi-range-slider">
  <input type="range"
         class="multi-range-slider-thumb multi-range-slider-thumb-left"
         [min]="min" [max]="max"
         [step]="step"
         [ngModel]="minValue"
         (ngModelChange)="handleMinValueChange($event)"
         #leftThumb />
  <input type="range"
         class="multi-range-slider-thumb multi-range-slider-thumb-right"
         [min]="min" [max]="max"
         [step]="step"
         [ngModel]="maxValue"
         (ngModelChange)="handleMaxValueChange($event)"
         #rightThumb />
  <div class="multi-range-slider-inner">
    <div class="multi-range-slider-track"></div>
    <div class="multi-range-slider-range"
         [style]="getRangeStyle()">
    </div>
  </div>
</div>
