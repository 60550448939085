import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: Array<any>,  filter: string) {
    if (!value) {
      return;
    }
    return value.filter(item => !item[filter]);
  }
}
