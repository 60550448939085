<div class="my-lease-analysis"
     *ngIf="!isNoProjectsAvailable; else isNoProjectsAvailablePlaceholder">
  <div class="lease-tabs">
    <dx-toolbar class="bg-transparent">
      <dxi-item location="before" locateInMenu="never">
        <span class="toolbar-label">Lease(s):</span>
      </dxi-item>
      <dxi-item location="before" locateInMenu="never">
        <dx-select-box displayExpr="text" valueExpr="id" width="550" noDataText="No leases"
                       groupTemplate="groupTpl" fieldTemplate="fieldTpl" itemTemplate="itemTpl"
                       [dataSource]="leaseSelectGroups" [grouped]="true"
                       [(value)]="selectedLeaseId">
          <div *dxTemplate="let data of 'groupTpl'">
            <div class="select-box-group-tpl">
              <span class="icon-unit"></span>
              <span>{{ data.key }}</span>
            </div>
          </div>

          <div *dxTemplate="let data of 'fieldTpl'">
            <dx-text-box class="w-100"
                         [value]="getLeaseAnalysisProjectSelectedName(data)" [readOnly]="true">
            </dx-text-box>
          </div>

          <div *dxTemplate="let data of 'itemTpl'">
            <span class="select-box-group-tpl">
              <span>{{data.text}}</span>
              <span class="expired-badge"
                    *ngIf="data.isExpired">
                Expired
              </span>
            </span>
          </div>
        </dx-select-box>
      </dxi-item>

      <dxi-item location="before">
        <dx-button icon="fa fa-pie-chart" hint="Proposal Comparison Summary Report"
                   [disabled]="disabledCashComparisonButton"
                   (onClick)="goToCashFlowComparisonReport()">
        </dx-button>
      </dxi-item>

      <dxi-item location="before">
        <dx-button icon="fa fa-file-excel-o" hint="Export to Excel" [disabled]="!selectedLeaseId" (onClick)="exportToExcelClick()"></dx-button>
      </dxi-item>
      <dxi-item location="before">
        <dx-button icon="fa fa-file-pdf-o" hint="Export to PDF" [disabled]="!selectedLeaseId" (onClick)="exportToPdfClick()"></dx-button>
      </dxi-item>

      <dxi-item location="before">
        <dx-select-box class="b-dx-select lease-year-select" [items]="leaseYearTypes" displayExpr="text" valueExpr="id" [(value)]="leaseYearTypesValue">
        </dx-select-box>
      </dxi-item>
    </dx-toolbar>

    <div *ngIf="userRole === 'Landlord'">
      <a href="https://www.yardiasp14.com/44884mashc/pages/LoginAdvanced.aspx" target="_blank" #yardiTooltipAnchor>
        <img alt="Yardi logo" src="/assets/img/yardi-logo.svg" class="lease-tabs__yardi-logo" />
      </a>
      <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend" [target]="yardiTooltipAnchor">
        Click here to follow link
      </dx-tooltip>
    </div>

    <dx-tabs [items]="tabs"
             [(selectedIndex)]="selectedTabIndex"
             (onSelectionChanged)="selectedTabChanged()"
             [elementAttr]="{ class: 'tabs-rounded' }"
             itemTemplate="tabItem">
      <div *dxTemplate="let itemData of 'tabItem'; let i = index" id="{{'tab' + i}}">
        <span class="dx-tab-text">{{itemData.text}}</span>
      </div>
    </dx-tabs>
  </div>

  <section class="w-calculator container-fluid">
    <div class="w-calculator-lease-description">
      <section class="b-build-card">
        <h1 class="b-build-card-title">
          <span class="icon-pointer"></span>

          <div class="b-build-card-title-inner">
            <span class="b-build-card-title-street">
              {{selectedLease?.building?.name}}
            </span>
            <!--b-build-card-title-street-->
            <span class="b-build-card-title-city">
              {{selectedLease?.building?.address?.displayString}}
            </span>
            <!--b-build-card-title-city-->
          </div>
        </h1>
        <!--b-build-card-title-->

        <figure class="b-build-card-image">
          <img class="img-fluid"
               alt=""
               *ngIf="buildingPictureUrl"
               [src]="buildingPictureUrl">
        </figure>
        <!--b-build-card-image-->
      </section>
      <!--b-build-card-->

      <section class="b-lease-details">
        <div class="b-lease-details__header">
          <h2 class="b-title">Lease Details</h2>
          <app-colabo-badges [project]="selectedProject" [lease]="selectedLease"></app-colabo-badges>
        </div>
        <!--b-header2-->

        <ul class="b-list-description my-description">
          <li>
            <span class="key">
              Use:
            </span>

            <span class="value">
              {{BuildingType[selectedLease?.building?.buildingType]}}
            </span>
          </li>

          <li>
            <span class="key">
              Unit / Suite:
            </span>
            <span class="value">
              {{selectedLease?.buildingUnit?.name}}
            </span>
          </li>

          <li>
            <span class="key">
              Rental SF:
            </span>
            <span class="value">
              {{getRentalSquareFootage()}}
            </span>
          </li>

          <li class="highlighted">
            <span class="key">
              Tenant:
            </span>
            <span class="value">
              {{selectedLease?.tenantCompany?.name}}
            </span>
          </li>

          <li class="highlighted">
            <span class="key">
              Model:
            </span>
            <span class="value">

              <ng-container *ngIf="currentRenewalProjectTemplateItemType === RenewalProjectTemplateItemType.LeaseAmendmentSigned">
                Completed <i class="fa fa-check-circle-o completed-icon" aria-hidden="true"></i>
              </ng-container>
              <ng-container *ngIf="currentRenewalProjectTemplateItemType !== RenewalProjectTemplateItemType.LeaseAmendmentSigned">
                {{getStageName()}}
              </ng-container>
            </span>
          </li>
        </ul>

      </section>
      <!--b-lease-detals-->

      <section class="b-lease-terms">
        <div class="b-lease-terms-stats">
          <h2 class="b-title">Lease Terms</h2>
          <!--b-header2-->

          <ul class="b-list-description">
            <li>
              <span class="key">
                Starting rent:
              </span>
              <span class="value">
                {{getStartingRate()}}
              </span>
            </li>

            <li>
              <span class="key">
                Rent increases:
              </span>
              <span class="value">
                {{getRentIncreases()}}
              </span>
            </li>

            <li>
              <span class="key">
                Free Rent:
              </span>
              <span class="value">
                {{getFreeRent()}}
              </span>
            </li>

            <li>
              <span class="key">
                Service Type:
              </span>
              <span class="value">
                {{getServiceType()}}
              </span>
            </li>

            <li>
              <span class="key">
                OpEx (RE Taxes & CAM):
              </span>
              <span class="value">
                {{getOperationExp()}}
              </span>
            </li>

            <li>
              <span class="key">
                Improvements:
              </span>
              <span class="value">
                {{getImprovements()}}
              </span>
            </li>
          </ul>
          <!--b-list-description-->
        </div>
        <!--b-lease-terms-stats-->

        <div class="b-lease-terms-descriptions">
          <img src="assets/img/Document.svg" alt="">
          <ul class="b-list">
            <li>
              <span class="b-list-key">
                Lease starts:
              </span>
              <span class="b-list-value">
                {{selectedLeaseAfterLandlordStage?.commencementTerm.commencement | safeDate}}
              </span>
            </li>

            <li>
              <span class="b-list-key">
                Lease ends:
              </span>
              <span class="b-list-value">
                {{(getLeaseEndDate()) | safeDate: 'MMM d, y'}}
              </span>
            </li>

            <li>
              <span class="b-list-key">
                Duration:
              </span>
              <span class="b-list-value"
                    *ngIf="selectedLeaseAfterLandlordStage?.term?.termValue">
                {{selectedLeaseAfterLandlordStage?.term?.termValue}} Months
              </span>

              <span class="b-list-value"
                    *ngIf="!selectedLeaseAfterLandlordStage?.term">
                Term value doesn't exist
              </span>
            </li>
          </ul>
        </div>
        <!--b-lease-terms-descriptions-->
      </section>
      <!--b-lease-terms-->
    </div>
    <!--w-calculator-lease-description-->

    <div class="w-calculator-metrics"
         *ngIf="isTenantTab()">
      <section class="b-financial-summary">
        <div class="b-financial-summary-head">
          <h2 class="b-title">Financial summary</h2>
          <!--b-header2-->

          <div class="b-financial-summary-head-controls">
          </div>
          <!--b-financial-summary-head-controls-->
        </div>
        <!--b-financial-summary-head-->

        <div class="table-responsive">
          <table class="b-table-result table">
            <thead>
              <tr *ngIf="leaseYearTypesValue===LeaseYearType.LeaseYear">
                <th class="cell--head">&ensp;</th>

                <th class="cell--head text-right"
                    *ngFor="let year of tenantYears; let i = index">
                  Year {{i+1}}
                </th>

                <th class="cell--head text-right">
                  Total
                </th>
              </tr>

              <tr *ngIf="leaseYearTypesValue===LeaseYearType.CalendarYear">
                <th class="cell--head">
                  &ensp;
                </th>

                <th class="cell--head text-right"
                    *ngFor="let year of tenantYears">
                  {{year.startDate | safeDate: 'yyyy'}}
                </th>

                <th class="cell--head text-right">
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="cell--title">Base Rent</td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.baseRent)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.baseRent)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title">Escalations</td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.escalations)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.escalations)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title">Free Rent</td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  <ng-container *ngIf="year.freeRent">
                    ({{getFormattedValue(year.freeRent)}})
                  </ng-container>
                  <ng-container *ngIf="!year.freeRent">
                    0
                  </ng-container>
                </td>

                <td class="cell--value" *ngIf="tenantTotal?.freeRent">
                  <ng-container *ngIf="tenantTotal.freeRent">
                    ({{getFormattedValue(tenantTotal.freeRent)}})
                  </ng-container>
                  <ng-container *ngIf="!tenantTotal.freeRent">
                    0
                  </ng-container>
                </td>
              </tr>

              <tr class="cell--divider"></tr>

              <tr class="cell--color">
                <td class="cell--total-title">
                  Total Base Rent
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.totalBaseRent)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.totalBaseRent)}}
                </td>
              </tr>
              <!--cell--color-->

              <tr class="cell--divider"></tr>

              <tr>
                <td class="cell--title">
                  Real Estate Taxes
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.realEstateTaxes)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.realEstateTaxes)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title">
                  CAM (Common Area Maint.)

                  <a role="button"
                     *ngIf="showBreakdownRowTenant(tenantYears) && !showBreakdownRows" (click)="showBreakdownRows = true">
                    <i class="fa fa-plus"></i>
                  </a>

                  <a role="button"
                     *ngIf="showBreakdownRowTenant(tenantYears) && showBreakdownRows" (click)="showBreakdownRows = false">
                    <i class="fa fa-minus"></i>
                  </a>
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.commonAreaMaintenance)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.commonAreaMaintenance)}}
                </td>
              </tr>

              <ng-container *ngIf="showBreakdownRows">
                <tr>
                  <td class="cell--title breakdown-title">
                    Insurance
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.insurance)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.insurance)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Repairs & Maintenance
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.repairsAndMaintenance)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.repairsAndMaintenance)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Fire & Safety
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.fireAndLifeSafety)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.fireAndLifeSafety)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Parking Lot Maintenance
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.parkingLotMaintenance)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.parkingLotMaintenance)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Roof Repairs
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.roofRepairs)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.roofRepairs)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Landscaping
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.landscaping)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.landscaping)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Snow Removal
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.snowRemoval)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.snowRemoval)}}
                  </td>
                </tr>

                <tr>
                  <td class="cell--title breakdown-title">
                    Management Fee
                  </td>

                  <td class="cell--value"
                      *ngFor="let year of tenantYears">
                    {{getFormattedValue(year.commonAreaMaintenanceBreakdown.managementFee)}}
                  </td>

                  <td class="cell--value">
                    {{getFormattedValue(tenantTotal.commonAreaMaintenanceBreakdown.managementFee)}}
                  </td>
                </tr>

              </ng-container>

              <tr class="cell--color">
                <td class="cell--total-title">
                  Total Recoveries
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.totalRecoveries)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.totalRecoveries)}}
                </td>
              </tr>
              <!--cell--color-->

              <tr class="cell--divider"></tr>

              <tr class="cell--color-gradient">
                <td class="cell--title">
                  Total Rent
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.totalRent)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.totalRent)}}
                </td>
              </tr>
              <!--cell--color-gradient-->

              <tr>
                <td class="cell--title">
                  Tenant Improvements
                </td>
                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.tenantImprovementsAmortization)}}
                </td>
                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.tenantImprovementsAmortization)}}
                </td>
              </tr>

              <tr class="cell--color">
                <td class="cell--total-title">
                  Total Cost
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.totalCost)}}
                </td>

                <td class="cell--value">

                  {{getFormattedValue(tenantTotal?.totalCost)}}
                </td>
              </tr>

              <tr class="cell--divider"></tr>

              <tr class="cell--color-gradient">
                <td class="cell--total-title">
                  Cash Flow
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.cashFlow)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.cashFlow)}}
                </td>
              </tr>
              <!--cell--color-gradient-->

              <tr class="cell--divider"></tr>

              <tr class="cell--color-gradient">
                <td class="cell--total-title">
                  Cumulative Cash Flow
                </td>

                <td class="cell--value"
                    *ngFor="let year of tenantYears">
                  {{getFormattedValue(year.cumulativeCashFlow)}}
                </td>
                <td class="cell--value">
                  {{getFormattedValue(tenantTotal.cumulativeCashFlow)}}
                </td>
              </tr>
              <!--cell--color-gradient-->
            </tbody>
          </table>
          <!--b-table-result-->
        </div>
        <!--table-responsive-->
      </section>
      <!--b-financial-summary-->

      <section class="b-key-metrics">
        <h2 class="b-title">Key Metrics</h2>
        <!--b-header2-->

        <div class="table-responsive">
          <table class="b-table-metrics table">
            <thead>
              <tr>
                <th>
                  Name
                </th>

                <th class="text-right">
                  Total
                </th>

                <th class="text-right">
                  {{getUnitMetricsDisplayValue()}}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="b-table-metrics-title">
                  Present&nbsp;Value
                  <span class="d-block">
                    ({{selectedLease?.commencementTerm.commencement | safeDate: 'MM/dd/yyyy'}} @ 6%)
                  </span>
                </td>

                <td class="b-table-metrics-cell">
                  ${{getFormattedValue(tenantLeaseAnalysisData?.presentValue)}}
                </td>

                <td class="b-table-metrics-cell">
                  ${{tenantLeaseAnalysisData?.psfPresentValue | safeNumber: '1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="b-table-metrics-title">
                  Total&nbsp;Cash&nbsp;Flow
                </td>

                <td class="b-table-metrics-cell">
                  ${{getFormattedValue(tenantLeaseAnalysisData?.totalCashFlow)}}
                </td>

                <td class="b-table-metrics-cell">
                  ${{tenantLeaseAnalysisData?.psfTotalCashFlow | safeNumber: '1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="b-table-metrics-title">
                  Average Cash&nbsp;Flow&nbsp;/&nbsp;Yr
                </td>
                <td class="b-table-metrics-cell">
                  ${{getFormattedValue(tenantLeaseAnalysisData?.averageCashFlow)}}
                </td>
                <td class="b-table-metrics-cell">
                  ${{tenantLeaseAnalysisData?.psfAverageCashFlow | safeNumber: '1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="b-table-metrics-title">
                  Average&nbsp;Monthly&nbsp;Rent
                </td>
                <td class="b-table-metrics-cell">
                  ${{getFormattedValue(tenantLeaseAnalysisData?.averageMonthlyRate)}}
                </td>
                <td class="b-table-metrics-cell">
                  N/A
                </td>
              </tr>
              <tr>
                <td class="b-table-metrics-title">
                  Net Effective<br>Rent&nbsp;/&nbsp;Yr
                </td>
                <td class="b-table-metrics-cell">
                  ${{getFormattedValue(tenantLeaseAnalysisData?.proposalEffectiveRent)}}
                </td>
                <td class="b-table-metrics-cell">
                  ${{tenantLeaseAnalysisData?.psfProposalEffectiveRate | safeNumber: '1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="b-table-metrics-title">
                  Concession&nbsp;Value
                </td>
                <td class="b-table-metrics-cell">
                  N/A
                </td>
                <td class="b-table-metrics-cell">
                  ${{tenantLeaseAnalysisData?.valueOfConcessions | safeNumber: '1.2-2'}}
                </td>
              </tr>
            </tbody>
          </table>
          <!--b-table-metrics-->
        </div>
        <!--table-responsive-->
      </section>
      <!--b-key-metrics-->
    </div>

    <div class="w-calculator-metrics"
         *ngIf="isLandlordTab()">
      <section class="b-financial-summary">
        <div class="b-financial-summary-head">
          <h2 class="b-title">Financial summary</h2>
          <!--b-header2-->

          <div class="b-financial-summary-head-controls">
            <div class="type">
              <div class="b-financial-summary-head-controls">
              </div>
              <!--b-financial-summary-head-controls-->
            </div>
            <!--type-->
          </div>
          <!--b-financial-summary-head-controls-->
        </div>
        <!--b-financial-summary-head-->

        <div class="table-responsive">
          <table class="b-table-result table">
            <thead>
            <tr *ngIf="leaseYearTypesValue === LeaseYearType.LeaseYear">
              <th class="cell--head">&ensp;</th>

              <th class="cell--head text-right"
                  *ngFor="let year of landlordYears; let i = index">
                Year {{i+1}}
              </th>

              <th class="cell--head text-right">
                Total
              </th>
            </tr>

            <tr *ngIf="leaseYearTypesValue === LeaseYearType.CalendarYear">
              <th class="cell--head">
                &ensp;
              </th>

              <th class="cell--head text-right"
                  *ngFor="let year of landlordYears">
                {{year.startDate | safeDate: 'yyyy'}}
              </th>

              <th class="cell--head text-right">
                Total
              </th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td class="cell--title">Base Rent</td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.baseRent)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.baseRent)}}
              </td>
            </tr>

            <tr>
              <td class="cell--title">Escalations</td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.escalations)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.escalations)}}
              </td>
            </tr>

            <tr>
              <td class="cell--title">Free Rent</td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                <ng-container *ngIf="year.freeRent">
                  ({{getFormattedValue(year.freeRent)}})
                </ng-container>
                <ng-container *ngIf="!year.freeRent">
                  0
                </ng-container>
              </td>

              <td class="cell--value"
                  *ngIf="landlordTotal?.freeRent">
                <ng-container *ngIf="landlordTotal.freeRent">
                  ({{getFormattedValue(landlordTotal.freeRent)}})
                </ng-container>
                <ng-container *ngIf="!landlordTotal.freeRent">
                  0
                </ng-container>
              </td>
            </tr>

            <tr class="cell--divider"></tr>

            <tr class="cell--color">
              <td class="cell--total-title">
                Total Base Rent
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.totalBaseRent)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.totalBaseRent)}}
              </td>
            </tr>
            <!--cell--color-->

            <tr class="cell--divider"></tr>

            <tr>
              <td class="cell--title">
                Real Estate Taxes
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                <ng-container *ngIf="year.realEstateTaxes">
                  ({{getFormattedValue(year.realEstateTaxes)}})
                </ng-container>
                <ng-container *ngIf="!year.realEstateTaxes">
                  0
                </ng-container>
              </td>

              <td class="cell--value">
                <ng-container *ngIf="landlordTotal.realEstateTaxes">
                  ({{getFormattedValue(landlordTotal.realEstateTaxes)}})
                </ng-container>
                <ng-container *ngIf="!landlordTotal.realEstateTaxes">
                  0
                </ng-container>
              </td>
            </tr>

            <tr>
              <td class="cell--title">
                CAM (Common Area Maint.)

                <a role="button"
                   *ngIf="showBreakdownRowLandlord(landlordYears) && !showBreakdownRows" (click)="showBreakdownRows = true">
                  <i class="fa fa-plus"></i>
                </a>

                <a role="button"
                   *ngIf="showBreakdownRowLandlord(landlordYears) && showBreakdownRows" (click)="showBreakdownRows = false">
                  <i class="fa fa-minus"></i>
                </a>
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                <ng-container *ngIf="year.commonAreaMaintenance">
                  ({{getFormattedValue(year.commonAreaMaintenance)}})
                </ng-container>
                <ng-container *ngIf="!year.commonAreaMaintenance">
                  0
                </ng-container>
              </td>

              <td class="cell--value">
                <ng-container *ngIf="landlordTotal.commonAreaMaintenance">
                  ({{getFormattedValue(landlordTotal.commonAreaMaintenance)}})
                </ng-container>
                <ng-container *ngIf="!landlordTotal.commonAreaMaintenance">
                  0
                </ng-container>
              </td>
            </tr>

            <ng-container *ngIf="showBreakdownRows">
              <tr>
                <td class="cell--title breakdown-title">
                  Insurance
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.insurance)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.insurance)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Repairs & Maintenance
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.repairsAndMaintenance)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.repairsAndMaintenance)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Fire & Safety
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.fireAndLifeSafety)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.fireAndLifeSafety)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Parking Lot Maintenance
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.parkingLotMaintenance)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.parkingLotMaintenance)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Roof Repairs
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.roofRepairs)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.roofRepairs)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Landscaping
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.landscaping)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.landscaping)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Snow Removal
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.snowRemoval)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.snowRemoval)}}
                </td>
              </tr>

              <tr>
                <td class="cell--title breakdown-title">
                  Management Fee
                </td>

                <td class="cell--value"
                    *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.commonAreaMaintenanceBreakdown.managementFee)}}
                </td>

                <td class="cell--value">
                  {{getFormattedValue(landlordTotal.commonAreaMaintenanceBreakdown.managementFee)}}
                </td>
              </tr>

            </ng-container>

            <tr class="cell--color">
              <td class="cell--total-title">
                Total Operating Expenses
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                <ng-container *ngIf="year.totalOperatingExpenses">
                  ({{getFormattedValue(year.totalOperatingExpenses)}})
                </ng-container>
                <ng-container *ngIf="!year.totalOperatingExpenses">
                  0
                </ng-container>
              </td>

              <td class="cell--value">
                <ng-container *ngIf="landlordTotal.totalOperatingExpenses">
                  ({{getFormattedValue(landlordTotal.totalOperatingExpenses)}})
                </ng-container>
                <ng-container *ngIf="!landlordTotal.totalOperatingExpenses">
                  0
                </ng-container>
              </td>
            </tr>
            <!--cell--color-->

            <tr class="cell--divider"></tr>

            <tr>
              <td class="cell--title">
                Real Estate Taxes
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.realEstateTaxes)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.realEstateTaxes)}}
              </td>
            </tr>

            <tr>
              <td class="cell--title">
                CAM (Common Area Maint.)

                <a role="button"
                   *ngIf="showBreakdownRowLandlord(landlordYears) && !showBreakdownRows" (click)="showBreakdownRows = true">
                  <i class="fa fa-plus"></i>
                </a>

                <a role="button"
                   *ngIf="showBreakdownRowLandlord(landlordYears) && showBreakdownRows" (click)="showBreakdownRows = false">
                  <i class="fa fa-minus"></i>
                </a>
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.commonAreaMaintenance)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.commonAreaMaintenance)}}
              </td>
            </tr>

            <tr class="cell--color">
              <td class="cell--total-title">
                Total Recoveries
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.totalRecoveries)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.totalRecoveries)}}
              </td>
            </tr>

            <tr class="cell--color">
              <td class="cell--total-title">
                Net Operating Income
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.netOperatingIncome)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.netOperatingIncome)}}
              </td>
            </tr>

            <tr class="cell--divider"></tr>

            <tr class="cell--color-gradient">
              <td class="cell--title">
                Total Rent
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.totalRent)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.totalRent)}}
              </td>
            </tr>
            <!--cell--color-gradient-->

            <tr>
              <td class="cell--title">
                Improvement Allowance
              </td>
              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.tenantImprovements)}}
              </td>
              <td class="cell--value">
                {{getFormattedValue(landlordTotal.tenantImprovements)}}
              </td>
            </tr>

            <tr *ngIf="isShowImprovementAmortizationRow()">
              <td class="cell--title">
                Improvement Amortization
              </td>
              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                <ng-container *ngIf="year.tenantImprovementsAmortization">
                  ({{getFormattedValue(year.tenantImprovementsAmortization)}})
                </ng-container>
                <ng-container *ngIf="!year.tenantImprovementsAmortization">
                  0
                </ng-container>
              </td>
              <td class="cell--value">
                <ng-container *ngIf="landlordTotal.tenantImprovementsAmortization">
                  ({{getFormattedValue(landlordTotal.tenantImprovementsAmortization)}})
                </ng-container>
                <ng-container *ngIf="!landlordTotal.tenantImprovementsAmortization">
                  0
                </ng-container>
              </td>
            </tr>

            <tr *ngIf="isShowImprovementsLoanFundingRow()">
              <td class="cell--title">
                TI Loan Funding
              </td>
              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                  {{getFormattedValue(year.tenantImprovementsLoanFunding)}}
              </td>
              <td class="cell--value">
                {{getFormattedValue(landlordTotal.tenantImprovementsLoanFunding)}}
              </td>
            </tr>

            <tr *ngIf="isShowLeaseCommissionsRow()">
              <td class="cell--title">
                Lease Commissions
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.leaseCommissions)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.leaseCommissions)}}
              </td>
            </tr>

            <tr class="cell--color">
              <td class="cell--total-title">
                Total Other Costs
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.totalCost)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal?.totalCost)}}
              </td>
            </tr>

            <tr class="cell--divider"></tr>

            <tr class="cell--color-gradient">
              <td class="cell--total-title">
                Cash Flow
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.cashFlow)}}
              </td>

              <td class="cell--value">
                {{getFormattedValue(landlordTotal.cashFlow)}}
              </td>
            </tr>
            <!--cell--color-gradient-->

            <tr class="cell--divider"></tr>

            <tr class="cell--color-gradient">
              <td class="cell--total-title">
                Cumulative Cash Flow
              </td>

              <td class="cell--value"
                  *ngFor="let year of landlordYears">
                {{getFormattedValue(year.cumulativeCashFlow)}}
              </td>
              <td class="cell--value">
                {{getFormattedValue(landlordTotal.cumulativeCashFlow)}}
              </td>
            </tr>
            <!--cell--color-gradient-->
            </tbody>
          </table>
          <!--b-table-result-->
        </div>
        <!--table-responsive-->
      </section>
      <!--b-financial-summary-->

      <section class="b-key-metrics">
        <h2 class="b-title">Key Metrics</h2>
        <!--b-header2-->

        <div class="table-responsive">
          <table class="b-table-metrics table">
            <thead>
            <tr>
              <th>
                Name
              </th>

              <th class="text-right">
                Total
              </th>

              <th class="text-right">
                {{getUnitMetricsDisplayValue()}}
              </th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td class="b-table-metrics-title">
                Present&nbsp;Value
                <span class="d-block">
                    ({{selectedLease?.commencementTerm.commencement | safeDate: 'MM/dd/yyyy'}} @ 6%)
                  </span>
              </td>

              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.presentValue)}}
              </td>

              <td class="b-table-metrics-cell">
                ${{landlordLeaseAnalysisData?.psfPresentValue | safeNumber: '1.2-2'}}
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Total&nbsp;Cash&nbsp;Flow
              </td>

              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.totalCashFlow)}}
              </td>

              <td class="b-table-metrics-cell">
                ${{landlordLeaseAnalysisData?.psfTotalCashFlow | safeNumber: '1.2-2'}}
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Average Cash&nbsp;Flow&nbsp;/&nbsp;Yr
              </td>
              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.averageCashFlow)}}
              </td>
              <td class="b-table-metrics-cell">
                ${{landlordLeaseAnalysisData?.psfAverageCashFlow | safeNumber: '1.2-2'}}
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Average&nbsp;Monthly&nbsp;Rent
              </td>
              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.averageMonthlyRate)}}
              </td>
              <td class="b-table-metrics-cell">
                N/A
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Total Net Operating Income
              </td>
              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.totalNetOperatingIncome)}}
              </td>
              <td class="b-table-metrics-cell">
                N/A
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Net Effective<br>Rent&nbsp;/&nbsp;Yr
              </td>
              <td class="b-table-metrics-cell">
                ${{getFormattedValue(landlordLeaseAnalysisData?.proposalEffectiveRent)}}
              </td>
              <td class="b-table-metrics-cell">
                ${{landlordLeaseAnalysisData?.psfProposalEffectiveRate | safeNumber: '1.2-2'}}
              </td>
            </tr>
            <tr>
              <td class="b-table-metrics-title">
                Concession&nbsp;Value
              </td>
              <td class="b-table-metrics-cell">
                N/A
              </td>
              <td class="b-table-metrics-cell">
                ${{landlordLeaseAnalysisData?.valueOfConcessions | safeNumber: '1.2-2'}}
              </td>
            </tr>
            </tbody>
          </table>
          <!--b-table-metrics-->
        </div>
        <!--table-responsive-->
      </section>
      <!--b-key-metrics-->
    </div>
    <!--w-calculator-metrics-->
  </section>

  <div class="alert alert-success" role="alert">
    Statera makes no guaranty as to the accuracy of the information in this report.  The analysis herein should not be construed as investment, tax or legal advice and is subject to change and verification.
  </div>
</div>

<ng-template #isNoProjectsAvailablePlaceholder>
  <div class="p-4">
    <div class="alert alert-info">
      This tool is not available yet.
    </div>
  </div>
</ng-template>
