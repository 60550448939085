import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';

import { PortfolioInvitationRepository } from './portfolio-invitation.repository';

import * as models from './portfolio-invitation.model';

@Injectable()
export class PortfolioInvitationManager {
  private readonly _portfolioInvitationRepository: PortfolioInvitationRepository;

  constructor(portfolioInvitationRepository: PortfolioInvitationRepository) {
    this._portfolioInvitationRepository = portfolioInvitationRepository;
  }

  sendUserInvitationRequest(portfolioInvitation: models.PortfolioInvitation): Observable<string | void> {
    return this._portfolioInvitationRepository.createPortfolioInvitation(portfolioInvitation);
  }

  acceptPortfolioInvitation(protectedToken: string): Observable<models.PortfolioInvitationUser> {
    return this._portfolioInvitationRepository.acceptPortfolioInvitation(protectedToken);
  }

  getPortfolioInvitationInfo(protectedToken: string): Observable<models.PortfolioInvitationInfo> {
    return this._portfolioInvitationRepository.getPortfolioInvitationInfo(protectedToken);
  }

  requestPortfolioInvitationInfo(protectedToken: string): Observable<models.PortfolioInvitationInfo> {
    return this._portfolioInvitationRepository
      .getPortfolioInvitationInfo(protectedToken)
      .pipe(
        tap((invitationInfo: models.PortfolioInvitationInfo) => this.setStoredPortfolioInvitationInfo(invitationInfo)),
      );
  }

  setStoredPortfolioInvitationInfo(invitationInfo: models.PortfolioInvitationInfo): void {
    this._portfolioInvitationRepository.setStoredPortfolioInvitationInfo(invitationInfo);
  }

  getStoredPortfolioInvitationInfo(): Observable<models.PortfolioInvitationInfo> {
    return this._portfolioInvitationRepository.getStoredPortfolioInvitationInfo();
  }
}
