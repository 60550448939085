<form class="elect-terms-for-negotiation-dialog-form" name="form" novalidate
      (ngSubmit)="submit(form)" #form="ngForm">
  <div class="elect-terms-for-negotiation-dialog-term-list">
    <dx-scroll-view width="100%" height="100%">
      <div class="elect-terms-for-negotiation-dialog-term-list-wrapper">
        <div class="elect-terms-for-negotiation-dialog-term-list-search">
          <input type="text" id="search" name="search" class="form-control" placeholder="Type to search..."
                 [(ngModel)]="search"
                 (ngModelChange)="applySearchFilter($event)" />
        </div>

        <div class="elect-terms-for-negotiation-dialog-term-list-item"
             *ngFor="let electedTerm of electedTerms; let index = index;">
          <ng-container *ngIf="electedTerm.isVisible">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input"
                     [id]="'elected-term-'+index" [name]="'elected-term-'+index"
                     [disabled]="electedTerm.isElectedByDefault || !electedTerm.canBeDeleted"
                     [(ngModel)]="electedTerm.isElected" />
              <label class="custom-control-label mb-0"
                     [for]="'elected-term-'+index">
                <p class="font-weight-bold">
                  {{electedTerm.leaseTermConfiguration.description}}
                </p>
                <p class="text-opacity-secondary">
                  {{electedTerm.leaseTermConfiguration.tooltip}}
                </p>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </dx-scroll-view>
  </div>

  <div class="elect-terms-for-negotiation-dialog-buttons">
    <div class="d-flex flex-row mx-n2">
      <div class="px-2 w-50">
        <button type="submit" class="btn btn-primary w-100">
          Save
        </button>
      </div>
      <div class="px-2 w-50">
        <button type="button" class="btn btn-outline-secondary w-100"
                (click)="close()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
