import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule, Configuration } from '@statera/sdk/common';
import { AlertModule } from '@statera/sdk/alert';
import { AnalyticsModule } from '@statera/sdk/analytics';
import { AnalyticsFiltersModule } from '@statera/sdk/analytics-filters';
import { AuthModule } from '@statera/sdk/auth';
import { AvailableUnitModule } from '@statera/sdk/available-unit';
import { BuildingModule } from '@statera/sdk/building';
import { BuildingUnitModule } from '@statera/sdk/building-unit';
import { BuildingUnitListingModule } from '@statera/sdk/building-unit-listing';
import { CertificateOfInsuranceModule } from '@statera/sdk/certificate-of-insurance';
import { ColaboModule } from '@statera/sdk/colabo';
import { CompanyModule } from '@statera/sdk/company';
import { DashboardModule } from '@statera/sdk/dashboard';
import { FeatureTogglingModule } from '@statera/sdk/feature-toggling';
import { FinancialsRequestModule } from '@statera/sdk/financials-request';
import { InfrastructureModule } from '@statera/sdk/infrastructure';
import { InvitationModule } from '@statera/sdk/invitation';
import { LandlordModule } from '@statera/sdk/landlord';
import { LeaseModule } from '@statera/sdk/lease';
import { LeaseDocumentModule } from '@statera/sdk/lease-document';
import { LeaseRequestModule } from '@statera/sdk/lease-request';
import { LoggerModule } from '@statera/sdk/logger';
import { MessageModule } from '@statera/sdk/message';
import { NotificationModule } from '@statera/sdk/notification';
import { PointOfInterestModule } from '@statera/sdk/point-of-interest';
import { PortfolioInvitationModule } from '@statera/sdk/portfolio-invitation';
import { ProjectModule } from '@statera/sdk/project';
import { QuizModule } from '@statera/sdk/quiz';
import { SignatureModule } from '@statera/sdk/signature';
import { StorageModule } from '@statera/sdk/storage';
import { TeamModule } from '@statera/sdk/team';
import { TenantModule } from '@statera/sdk/tenant';
import { TenantRequestModule } from '@statera/sdk/tenant-request';
import { TermModule } from '@statera/sdk/term';
import { TermCustomValueTemplateModule } from '@statera/sdk/term-custom-value-template';
import { TermHistoryModule } from '@statera/sdk/term-history';
import { TermHoldoverProvisionModule } from '@statera/sdk/term-holdover-provision';
import { UserModule } from '@statera/sdk/user';
import { WebsocketModule } from '@statera/sdk/websocket';
import { TermUseModule } from '@statera/sdk/term-use';
import { FloorPlanModule } from '@statera/sdk/floor-plan';
import { StateraUserClaimModule } from '@statera/sdk/statera-user-claim';
import { BuildingBudgetModule } from '@statera/sdk/building-budget';
import { LeaseAnalysisModule } from '@statera/sdk/lease-analysis';
import {ProfileModule} from '@statera/sdk/profile';

@NgModule({
  imports: [
    AlertModule,
    AnalyticsModule,
    AnalyticsFiltersModule,
    AuthModule,
    BuildingModule,
    BuildingUnitModule,
    BuildingUnitListingModule,
    CertificateOfInsuranceModule,
    ColaboModule,
    CommonModule,
    CompanyModule,
    DashboardModule,
    FeatureTogglingModule,
    FinancialsRequestModule,
    InfrastructureModule,
    InvitationModule,
    LandlordModule,
    LeaseModule,
    LeaseDocumentModule,
    LeaseRequestModule,
    LoggerModule,
    MessageModule,
    NotificationModule,
    PointOfInterestModule,
    PortfolioInvitationModule,
    ProjectModule,
    SignatureModule,
    StorageModule,
    TeamModule,
    TenantModule,
    TenantRequestModule,
    TermModule,
    TermCustomValueTemplateModule,
    TermHoldoverProvisionModule,
    TermUseModule,
    UserModule,
    AvailableUnitModule,
    WebsocketModule,
    QuizModule,
    TermHistoryModule,
    FloorPlanModule,
    StateraUserClaimModule,
    BuildingBudgetModule,
    LeaseAnalysisModule,
  ],
  exports: [
    AlertModule,
    AnalyticsModule,
    AnalyticsFiltersModule,
    AuthModule,
    AvailableUnitModule,
    BuildingModule,
    BuildingUnitModule,
    BuildingUnitListingModule,
    CertificateOfInsuranceModule,
    ColaboModule,
    CommonModule,
    CompanyModule,
    DashboardModule,
    FeatureTogglingModule,
    FinancialsRequestModule,
    InfrastructureModule,
    InvitationModule,
    LandlordModule,
    LeaseModule,
    LeaseDocumentModule,
    LeaseRequestModule,
    LoggerModule,
    MessageModule,
    NotificationModule,
    PointOfInterestModule,
    PortfolioInvitationModule,
    ProjectModule,
    QuizModule,
    SignatureModule,
    StorageModule,
    TeamModule,
    TenantModule,
    TenantRequestModule,
    TermModule,
    TermCustomValueTemplateModule,
    TermHistoryModule,
    TermHoldoverProvisionModule,
    TermUseModule,
    UserModule,
    WebsocketModule,
    FloorPlanModule,
    StateraUserClaimModule,
    BuildingBudgetModule,
    LeaseAnalysisModule,
    ProfileModule
  ],
})
export class SDKModule {
  static forRoot(configuration: Configuration): ModuleWithProviders<SDKModule> {
    return {
      ngModule: SDKModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
      ],
    };
  }
}
