import * as ng from '@angular/core';
import { Component, Input, Output, ViewChild, ViewRef } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take, takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { VideoPlayerComponent } from '../../../video-player/components/video-player/video-player.component';

import * as models from '../../models/document-viewer.model';

import { ImageViewerImageKind, ImageViewerImageRef } from '../../../image-viewer/models/image-viewer-image.model';

import { ImageCacheService } from '../../../image-viewer/services/image-cache.service';

import { VideoPlayerType } from '../../../video-player/models/video-player.model';

@Component({
  selector: 'app-document-viewer-document',
  templateUrl: 'document-viewer-document.component.html',
  styleUrls: ['document-viewer-document.component.scss'],
})
export class DocumentViewerDocumentComponent implements ng.OnInit, ng.OnChanges, ng.OnDestroy {
  private static readonly _officeThirdPartyDocumentViewerLink = 'https://view.officeapps.live.com/op/embed.aspx?src={{URL}}';
  private static readonly _genericThirdPartyDocumentViewerLink = 'https://docs.google.com/gview?url={{URL}}&embedded=true';
  private static readonly _thirdPartyDocumentViewerCheckLoadingInterval = 1000;
  private static readonly _thirdPartyDocumentViewerCheckLoadingMaxChecks = 150;

  @ViewChild('thirdPartyDocumentViewerIframeElementRef') thirdPartyDocumentViewerIframeElementRef: ng.ElementRef;
  @ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent;
  @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

  @Input() documentRef: models.DocumentViewerDocumentRef;

  @Input() width: number;
  @Input() height: number;

  @Output() loaded: ng.EventEmitter<boolean>;

  DocumentViewerType: typeof models.DocumentViewerViewerType = models.DocumentViewerViewerType;

  imageRef: ImageViewerImageRef;

  isIframeVisible: boolean;
  isCanBeLoaded: boolean;

  private readonly _ngZone: ng.NgZone;
  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _renderer: ng.Renderer2;
  private readonly _imageCacheService: ImageCacheService;

  private readonly _destroy$: Subject<void>;

  readonly VideoPlayerType: typeof VideoPlayerType;

  constructor(
    ngZone: ng.NgZone,
    changeDetectorRef: ng.ChangeDetectorRef,
    renderer: ng.Renderer2,
    imageCacheService: ImageCacheService,
  ) {
    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._renderer = renderer;
    this._imageCacheService = imageCacheService;

    this._destroy$ = new Subject<void>();

    this.VideoPlayerType = VideoPlayerType;

    this.loaded = new ng.EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.width = this.width || null;
    this.height = this.height || null;
    this.isIframeVisible = false;
    this.isCanBeLoaded = true;
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.documentRef &&
      (
        changes.documentRef.isFirstChange() ||
        (
          (changes.documentRef.previousValue && changes.documentRef.currentValue) &&
          changes.documentRef.previousValue.url !== changes.documentRef.currentValue.url
        )
      )
    ) {
      this._initDocumentViewer();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();
  }

  getPDFDocumentUrl(): string {
    if (
      !this.documentRef?.url ||
      !this.documentRef?.url?.startsWith('/api/storage/')
    ) {
      return this.documentRef?.url;
    }

    const fileKey = this.documentRef?.url.substr('/api/storage/'.length - 1);

    return `/api/storage/${encodeURIComponent(fileKey)}`;
  }

  handlePDFLoadComplete(): void {
    this.loaded.emit(true);
  }

  private _initDocumentViewer(): void {
    if (!this.documentRef) {
      return;
    }

    switch (this.documentRef.viewerType) {
      case models.DocumentViewerViewerType.Image:
        this._startImageDocumentLoading(this.documentRef);
        break;

      case models.DocumentViewerViewerType.Video:
        this._startVideoDocumentLoading(this.documentRef);
        break;

      case models.DocumentViewerViewerType.Pdf:
        this._startPdfDocumentLoading(this.documentRef);
        break;

      case models.DocumentViewerViewerType.Unknown:
      case models.DocumentViewerViewerType.OfficeDocument:
        this._startThirdPartyDocumentViewerLoading(this.documentRef);
        break;

      default:
        this.loaded.emit(true);
        break;
    }
  }

  private _getThirdPartyDocumentViewerLink(documentRef: models.DocumentViewerDocumentRef): string {
    if (!documentRef || !documentRef.url) {
      return null;
    }

    let viewerLink;
    switch (this.documentRef.viewerType) {
      case models.DocumentViewerViewerType.Unknown:
        viewerLink = DocumentViewerDocumentComponent._genericThirdPartyDocumentViewerLink;
        break;
      case models.DocumentViewerViewerType.OfficeDocument:
        viewerLink = DocumentViewerDocumentComponent._officeThirdPartyDocumentViewerLink;
        break;
    }

    if (!viewerLink) {
      return null;
    }

    viewerLink = viewerLink
      .replace(
        '{{URL}}',
        `${this._getOriginLocation()}${documentRef.url}`,
      );

    return encodeURI(viewerLink);
  }

  private _getOriginLocation(): string {
    let origin = location.origin;

    if (
      environment.overrideLocalhost &&
      (
        origin.includes('localhost') ||
        origin.includes('127.0.0.1') ||
        origin.includes('debug.statera.re')
      )
    ) {
      origin = environment.overrideLocalhost;
    }

    return origin;
  }

  private _startImageDocumentLoading(documentRef: models.DocumentViewerDocumentRef): void {
    this.loaded.emit(false);

    this.isCanBeLoaded = true;

    this._imageCacheService
      .getCachedImage(documentRef.url)
      .pipe(
        tap((cachedImage) => {
          this.imageRef = <ImageViewerImageRef>{
            imageKind: ImageViewerImageKind.Standard,
            imageElement: cachedImage.element,
            imageDataUrl: cachedImage.dataUrl,
            width: cachedImage.width,
            height: cachedImage.height,
          };

          this.loaded.emit(true);
        }),
        catchError((err) => {
          this.isCanBeLoaded = false;
          return of(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _startVideoDocumentLoading(documentRef: models.DocumentViewerDocumentRef): void {
    this.loaded.emit(false);

    this.isCanBeLoaded = true;

    this.videoPlayer
      .loaded
      .pipe(
        tap(() => {
          this.loaded.emit(true);
        }),
        catchError((err) => {
          this.isCanBeLoaded = false;
          return of(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _startPdfDocumentLoading(documentRef: models.DocumentViewerDocumentRef): void {
    this.loaded.emit(false);

    this.isCanBeLoaded = true;

    this.pdfViewer
      .afterLoadComplete
      .pipe(
        tap(() => {
          this.loaded.emit(true);
        }),
        catchError((err) => {
          this.isCanBeLoaded = false;
          return of(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _startThirdPartyDocumentViewerLoading(documentRef: models.DocumentViewerDocumentRef): void {
    if (!documentRef || !documentRef.url) {
      return;
    }

    let checkCount = 0;
    let interval: any;

    this.isIframeVisible = false;
    this.isCanBeLoaded = true;

    this.loaded.emit(false);

    this._ngZone.runOutsideAngular(() => {
      const isIframeLoaded = (iframe: HTMLIFrameElement): boolean => {
        let isLoaded = false;

        try {
          if (!/MSIE (\d+\.\d+);/.test(navigator.userAgent) || -1 < navigator.userAgent.indexOf('Trident/')) {
            isLoaded = !iframe.contentDocument;
          } else {
            isLoaded = !iframe.contentWindow.document;
          }
        } catch {}

        return isLoaded;
      };

      const freeInterval = () => {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
      };

      const intervalFn = () => {
        if (!(<ViewRef>this._changeDetectorRef).destroyed) {
          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        }

        if (!this.thirdPartyDocumentViewerIframeElementRef?.nativeElement) {
          this.isIframeVisible = true;
          return;
        }

        const iframe = this.thirdPartyDocumentViewerIframeElementRef.nativeElement;

        const currOfficeDocumentViewerLink = iframe.src;
        const nextOfficeDocumentViewerLink = this._getThirdPartyDocumentViewerLink(documentRef);

        if (!isIframeLoaded(iframe) || currOfficeDocumentViewerLink !== nextOfficeDocumentViewerLink) {
          checkCount++;

          if (DocumentViewerDocumentComponent._thirdPartyDocumentViewerCheckLoadingMaxChecks <= checkCount) {
            this.loaded.emit(true);

            this.isCanBeLoaded = false;

            freeInterval();
            return;
          }

          iframe.src = nextOfficeDocumentViewerLink;

          return;
        }

        this.loaded.emit(true);

        freeInterval();
      };

      interval = setInterval(intervalFn, DocumentViewerDocumentComponent._thirdPartyDocumentViewerCheckLoadingInterval);
    });
  }
}
