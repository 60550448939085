<form name="form"
      (submit)="saveLeaseTerm($event)" *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6 class="radio-group-title">Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-11  radio-group-container mb-2">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.estoppelCertificateTermType"
                          (valueChange)="clearErrors()">
            <div *dxTemplate="let data of 'radio'">
              <ng-container *ngIf="data.value === EstoppelCertificateTermType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest; else customValue">
                <div class="d-flex flex-column">
                  <div class="insert-row mb-2">
                    <span>Within</span>
                    <app-input-field appIgnoreScrollwheel
                                          [id]="'estoppelCertificateDays'" [placeholder]="'(e.g. 30)'"
                                          [className]="'real-estate-input py-0'"
                                          [format]="'#0'" [width]="'75px'"
                                          [errorMessage]="'Days value is required'"
                                          [value]="leaseTerm.tenantDeadlineAfterLandlordRequestInDays" [suffix]="'days'"
                                          [disabled]="leaseTerm.estoppelCertificateTermType !== EstoppelCertificateTermType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest"
                                          (valueChange)="leaseTerm.tenantDeadlineAfterLandlordRequestInDays = $event; clearErrors()"
                    ></app-input-field>
                    <span>of Landlord's request, Tenant shall execute and deliver </span>
                  </div>
                  <span>to Landlord an Estoppel Certificate containing customary provisions.</span>
                </div>
              </ng-container>
              <ng-template #customValue>
                {{data.name}}
              </ng-template>
            </div>
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div *ngIf="leaseTerm.estoppelCertificateTermType === EstoppelCertificateTermType.Custom" class="mb-2">
      <app-term-custom-value-template-textarea placeholder="Insert custom estoppel certificate clause"
                                               required requiredMessage="Custom Estoppel Certificate clause is required"
                                               [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                               [(value)]="leaseTerm.estoppelCertificate"
                                               (valueChange)="clearErrors()">
      </app-term-custom-value-template-textarea>
    </div>

    <!-- Start: Validation summary -->

      <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>

    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
