import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-free-rent',
  templateUrl: './free-rent.component.html',
  styleUrls: ['./free-rent.component.scss'],
})
export class FreeRentComponent extends BaseTermDirective<models.IFreeRentTermViewModel> implements OnInit, OnChanges, OnDestroy {

  options: any = [];

  net = 'Net';
  gross = 'Gross';
  taxesRadioGroup: Array<any> = [
    {name: this.net, value: models.FreeRentTaxesType.Net},
    {name: this.gross, value: models.FreeRentTaxesType.Gross},
  ];

  FreeRentTaxesType = models.FreeRentTaxesType;
  FreeRentTermType = models.FreeRentTermType;
  isVisibleTaxesRadioGroup = false;
  tooltipSpecificMonthsVisible = false;
  isVisibleRadioGroup = true;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  protected _projectAccessService: ProjectAccessService;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this._destroy$ = new Subject<void>();
    this._projectAccessService = projectAccessService;
  }

  ngOnInit() {
    super.ngOnInit();

    this._initializePage();

    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this._initializePage();
          }),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseTerm &&
      changes.leaseTerm.previousValue &&
      changes.leaseTerm.currentValue &&
      !this.termManager.isSameTerms(
        <models.ILeaseTermViewModel>changes.leaseTerm.previousValue,
        <models.ILeaseTermViewModel>changes.leaseTerm.currentValue,
      )
    ) {
      this._initializePage();
    }
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
        this.lease.term.hasMultiplyOptions
      ) ||
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      )
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.leaseTermOptions &&
      !this.leaseTerm.leaseTermOptions.selectedLeaseTerm &&
      (
        this.leaseTerm.freeRentTermType === null ||
        this.leaseTerm.freeRentTermType === models.FreeRentTermType.MultiplyOptions
      ) &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isCounterButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      ) &&
      this.leaseTerm.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready
    ) {
      return false;
    }

    return super.isCounterButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.termStatus !== models.TermStatus.Pending &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    const isTenantCounterOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease,
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        )
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick(): void {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    this.handleAcceptButtonClick();
  }

  optionChanged($event) {
    if ($event.name === 'value') {
      this.isVisibleTaxesRadioGroup = $event.value !== models.FreeRentTermType.None;
      this.leaseTerm.freeRentTaxesType = null;
      this.leaseTermPropertyChange();
    }
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    const {freeRentTermType, freeRentTaxesType} = this.leaseTerm;
    if (!freeRentTermType) {
      this.addError('You should choose appropriate options.');
      return;
    }

    const isOptionWithTaxesTypeUnspecified = (
      freeRentTermType === models.FreeRentTermType.MultiplyOptions &&
      this.leaseTerm.leaseTermOptions &&
      this.leaseTerm.leaseTermOptions.options &&
      this.leaseTerm.leaseTermOptions.options.some(o =>
        o.freeRentTermType !== models.FreeRentTermType.None &&
        !o.freeRentTaxesType,
      )
    );

    const isFreeRentTaxesUnspecified = (
      freeRentTermType !== models.FreeRentTermType.None &&
      freeRentTermType !== models.FreeRentTermType.MultiplyOptions &&
      !freeRentTaxesType
    );

    if (isFreeRentTaxesUnspecified || isOptionWithTaxesTypeUnspecified) {
      this.addError('Please choose Net or Gross option.');
      return;
    }

    if (
      this.project.projectTypeId === models.ProjectTypeEnum.Restructure &&
      this._projectAccessService.getTurnRole(this.lease) === Role.Tenant &&
      (
        this.leaseTerm.freeRentTermType !== models.FreeRentTermType.None &&
        this.leaseTerm.freeRentTermType !== models.FreeRentTermType.MultiplyOptions
      )
    ) {
      this.addError('Please note that requesting any Free Rent may cause the Landlord to modify other Lease Terms.');
    }

    super.saveLeaseTerm(this.leaseTerm);

    $event.preventDefault();
  }

  onSpecificMonthsCreating(e) {
    const newValue = +e.text;
    if (!Number.isInteger(newValue)) {
      e.component.option('isValid', false);
      this.addError('Please insert actual month number.');
    }

    if (newValue <= 0 || newValue >= 100) {
      e.component.option('isValid', false);
      this.addError('Please insert actual month number between 0 and 100.');
    }

    e.customItem = newValue;
  }

  getZeroComparisonTarget() {
    return 0;
  }

  private _initializePage() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.IFreeRentTermViewModel>{};
    }

    this.options = this._getRadioGroupOptions();
    this.isVisibleTaxesRadioGroup = false;
    this.tooltipSpecificMonthsVisible = false;
    this.isVisibleRadioGroup = true;

    if (!this.isOption) {
      this.leaseTerm = {...this.leaseTerm};
    }

    const {freeRentTermType} = this.leaseTerm;

    if (freeRentTermType) {
      if (freeRentTermType !== models.FreeRentTermType.None) {
        this.isVisibleTaxesRadioGroup = true;
      }
    }

    if (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
      this.isMultipleOptions()
    ) {
      this.isVisibleTaxesRadioGroup = false;
      this.options.forEach(value => {
        value.disabled = true;
      });
    }

    if (freeRentTermType === models.FreeRentTermType.MultiplyOptions && !this.isTenantCounterUnsolicitedOfferStage()) {
      this.isVisibleRadioGroup = false;
      this.isVisibleTaxesRadioGroup = false;
      this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);
    }

    if (this.isTenantCounterUnsolicitedOfferStage() && this.isMultipleOptions()) {
      this.isVisibleTaxesRadioGroup = false;
    }
  }

  onTagBoxInitialized($event) {
    $event.component.option('openOnFieldClick', false);
  }

  canInsertNewOption(): boolean {
    return this.termManager.canInsertNewOption(this.lease.term, this.project);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getRadioGroupOptions() {
    return [
      {
        name: 'None',
        value: models.FreeRentTermType.None,
      },
      {
        name: 'Months at beginning of New Lease Commencement Date',
        value: models.FreeRentTermType.MonthsCount,
      },
      {
        name: 'Custom (specific schedule)',
        value: models.FreeRentTermType.SpecificSchedule,
      },
      {
        name: 'Please state the number of months of free rent the Landlord is willing to grant Tenant for both Lease Term options.',
        value: models.FreeRentTermType.RequestMultiplyOptions,
        disabled: true,
      },
    ];
  }
}
