<form name="form" class="tenant-square-footage-form h-100" novalidate
      (ngSubmit)="noop()" #form="ngForm">
  <div class="d-flex flex-column h-100">
    <!-- Start: Popup content -->
    <div class="flex-grow-1 flex-shrink-1 overflow-hidden mh-0">
      <dx-scroll-view width="100%" height="100%">
        <div class="popup-content">
          <!-- Start: SF Schedule table -->
          <div class="table-responsive mb-2">
            <table class="table">
              <thead>
              <tr>
                <th colspan="2">
                  <div class="schedule-title">
                    <span>Sf Schedule:</span>
                    <button type="button" class="b-btn-icon-info"
                            (click)="$event.preventDefault()"
                            #burndownTooltip>
                      <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
                    </button>
                    <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                                [width]="250" [target]="burndownTooltip">
                      <div class="d-flex flex-column tooltip-menu">
                        In order to create a Phase-in schedule, add the Lease months and Total SF occupied for each period, as necessary.
                        The Preview window table will show all results before saving the schedule.
                      </div>
                    </dx-tooltip>
                  </div>
                </th>
                <th colspan="3" class="text-right">
                  <span class="phase-in-title">
                    Current or Total Unit size: {{getCurrentUnitSize() | safeNumber: '1.0-0'}} SF
                  </span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align-middle w-25">Lease month</td>
                <td class="align-middle w-25">Phase-in Date</td>
                <td class="align-middle w-25">Total SF Occupied</td>
                <td class="align-middle w-25">Repeat to End</td>
                <td class="align-middle w-0"></td>
              </tr>
              <tr *ngFor="let phaseInValue of phaseInValues; let index = index;">
                <!-- Start: Lease Mo -->
                <td class="align-middle w-25">
                  <app-input-field name="leaseMonth_{{index}}" className="real-estate-input"
                                   placeholder="" format="#"
                                   errorMessage="Lease month is required"
                                   [step]="0" [min]="1" [max]="getTerm()" [disabled]="isDisabled(index)"
                                   [(value)]="phaseInValue.leaseMonth"
                                   (valueChange)="sortPhaseInValues(); calculatePhaseInResults(); handlePhaseInValueChange();">
                  </app-input-field>
                </td>
                <!-- End: Lease Mo -->
                <!-- Start: Increase Date SF -->
                <td class="align-middle w-25">
                  <p class="mb-0 text-center"
                     *ngIf="getDate(phaseInValue.leaseMonth) as phaseInDate"
                     [class.text-muted]="isDisabled(index)">
                    {{phaseInDate | date: 'MM.dd.yy'}}
                  </p>
                </td>
                <!-- End: Increase Date SF -->
                <!-- Start: Total SF Occupied -->
                <td class="align-middle w-25">
                  <app-input-field name="totalSfOccupied_{{index}}" className="real-estate-input" suffix="SF"
                                   placeholder="(e.g 5,000)" format="#,##0" width="93px"
                                   errorMessage="Total SF Occupied is required"
                                   [step]="0" [min]="0" [max]="getCurrentUnitSize()" [disabled]="isDisabled(index)"
                                   [(value)]="phaseInValue.totalSfOccupied"
                                   (valueChange)="calculatePhaseInResults(); handlePhaseInValueChange();">
                  </app-input-field>
                </td>
                <!-- End: Total SF Occupied -->
                <!-- Start: Repeat to End -->
                <td class="align-middle w-25">
                  <dx-select-box name="repeatToEnd_{{index}}" placeholder=""
                                 displayExpr="name" valueExpr="value" class="real-estate-input"
                                 [disabled]="isDisabled(index)" [items]="repeatToEndOptions"
                                 [(value)]="phaseInValue.repeatType"
                                 (onValueChanged)="calculatePhaseInValueProperties(index); calculatePhaseInResults(); handlePhaseInValueChange();"
                                 (onInitialized)="handleRepeatTypeInitialization($event)">
                  </dx-select-box>
                </td>
                <!-- End: Repeat to End -->
                <!-- Start: Action -->
                <td class="text-center align-middle w-0">
                  <a role="button" class="cursor-pointer d-flex justify-content-center align-items-center minus-icon" title="Remove"
                     (click)="removePhaseInValue(index);">
                    <span inlineSVG="assets/img/signs-icons.svg#icon-minus"></span>
                  </a>
                </td>
                <!-- End: Action -->
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="5">
                  <div class="d-table w-100">
                    <div class="d-flex flex-row mx-n1 justify-content-center">
                      <div class="px-1">
                        <dx-button type="success" class="phase-in-btn"
                                   [disabled]="isAddButtonDisabled()"
                                   (click)="addPhaseInValue(); handlePhaseInValueChange();">
                          <div class="d-flex justify-center align-items-center">
                            <span class="action-icon" inlineSVG="assets/img/signs-icons.svg#icon-plus-100"></span>
                            <span>Add a Row</span>
                          </div>
                        </dx-button>
                      </div>
                      <div class="px-1">
                        <dx-button type="cancel" class="phase-in-btn"
                                   (click)="removePhaseInValue(); calculatePhaseInResults(); handlePhaseInValueChange();">
                          <div class="d-flex justify-center align-items-center">
                            <span class="action-icon" inlineSVG="assets/img/signs-icons.svg#icon-minus-100"></span>
                            <span>Remove All Rows</span>
                          </div>
                        </dx-button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
          <!-- End: SF Schedule table -->

          <!-- Start: Preview table -->
          <ng-container *ngIf="0 < phaseInResults.length">
            <div>
              <app-tenant-square-footage-phase-in-result [phaseInResults]="phaseInResults.toArray()">
              </app-tenant-square-footage-phase-in-result>
            </div>
          </ng-container>
          <!-- End: Preview table -->
        </div>
      </dx-scroll-view>
    </div>
    <!-- End: Popup content -->
  </div>
</form>
