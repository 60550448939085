import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { VideoPlayerState, VideoPlayerType } from '../../models/video-player.model';

import { VideoPlayerHTML5Component } from '../video-player-html5/video-player-html5.component';

@Component({
  selector: 'app-video-player',
  templateUrl: 'video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() source: string;
  @Input() playerType: VideoPlayerType;
  @Input() muted: boolean;

  @Output() loaded: EventEmitter<void>;
  @Output() playing: EventEmitter<void>;
  @Output() played: EventEmitter<void>;
  @Output() paused: EventEmitter<void>;

  playerState: VideoPlayerState;

  PlayerType: typeof VideoPlayerType;
  PlayerState: typeof VideoPlayerState;

  playObserver: Subject<void>;
  pauseObserver: Subject<void>;

  constructor() {
    this.loaded = new EventEmitter<void>();
    this.playing = new EventEmitter<void>();
    this.played = new EventEmitter<void>();
    this.paused = new EventEmitter<void>();

    this.PlayerType = VideoPlayerType;
    this.PlayerState = VideoPlayerState;

    this.playObserver = new Subject<void>();
    this.pauseObserver = new Subject<void>();
  }

  ngOnInit(): void {
    this.playerType = this.playerType ?? this._computePlayerType();
    this.muted = this.muted === undefined ? true : this.muted;
  }

  handleLoaded(): void {
    this.loaded.emit();
  }

  handlePlaying(): void {
    this.playerState = VideoPlayerState.Playing;
    this.playing.emit();
  }

  handlePlayed(): void {
    this.playerState = VideoPlayerState.Played;
    this.played.emit();
  }

  handlePaused(): void {
    this.playerState = VideoPlayerState.Paused;
    this.paused.emit();
  }

  play(): void {
    this.playObserver?.next();
  }

  pause(): void {
    this.pauseObserver?.next();
  }

  private _computePlayerType(): VideoPlayerType {
    if (this.source.includes('youtube')) {
      return VideoPlayerType.YouTube;
    }

    if (VideoPlayerHTML5Component.canPlaySource(this.source)) {
      return VideoPlayerType.HTML5;
    }

    return null; // TODO: Add checks here
  }
}
