<div class="container">
  <ng-container>
    <div class="pane left-pane">
      <div class="pane-header">
        <p>
          <ng-container *ngIf="state !== TourProcessState.Edit">Set up a Tour</ng-container>
          <ng-container *ngIf="state === TourProcessState.Edit">Edit Tour</ng-container>
        </p>
      </div>

      <div class="pane__content">
        <div class="building-info">
          <img width="42" height="42" [src]="getBuildingPictureUrl()" />
          <div class="building-info__address">
            <p>{{ building.address.addressLine1 }}</p>
            <div class="building-info__unit-name">{{ buildingUnit.name }}</div>
          </div>
        </div>

        <div class="counterpart-cell">
          <div class="counterpart-cell__images-container">
<!--            <div class="counterpart-cell__user-avatar"-->
<!--                 [class.counterpart-cell__user-avatar_empty]="!counterPartUser?.avatar?.url">-->
<!--              <ng-container *ngIf="counterPartUser?.avatar?.url; else noUserAvatarTemplate">-->
<!--                <img [src]="counterPartUser.avatar.url" width="27" height="27" />-->
<!--              </ng-container>-->
<!--              <ng-template #noUserAvatarTemplate>-->
<!--                <i inlineSVG="/assets/icons/user-icons.svg#icon-user-17"></i>-->
<!--              </ng-template>-->
<!--            </div>-->

<!--            <div class="counterpart-cell__company-logo"-->
<!--                 [class.counterpart-cell__company-logo_empty]="!counterPartCompany?.logo?.url">-->
<!--              <ng-container *ngIf="counterPartCompany?.logo?.url; else noCompanyLogoTemplate">-->
<!--                <img [src]="counterPartCompany.logo.url" width="26" height="26" alt="Counterpart company logo" />-->
<!--              </ng-container>-->
<!--              <ng-template #noCompanyLogoTemplate>-->
<!--                <i inlineSVG="/assets/icons/user-icons.svg#icon-user-company-16"></i>-->
<!--              </ng-template>-->
<!--            </div>-->
          </div>
          <div class="counterpart-cell__info">
            <div class="counterpart-cell__info__user">
              {{ counterPartUser?.displayName }}
            </div>
            <div class="counterpart-cell__info__name">
              {{ counterPartCompany?.name }}
            </div>
          </div>
        </div>

        <div class="tour-length-info">
          <i inlineSVG="assets/img/clock.svg"></i>
          <span>Tour length: 30 min.</span>
        </div>

        <div class="pane__footer">
          <ng-container *ngIf="state === TourProcessState.Initial || state === TourProcessState.ReplyReject">
            Pick the time slots and dates out of the available and then the other party
            will pick the final time slot for the tour out of them.
          </ng-container>
          <ng-container *ngIf="state === TourProcessState.ReplyAccept">
            Pick from the available time slots, so the other party can confirm a final
            date and time for the tour.
          </ng-container>
          <ng-container *ngIf="state === TourProcessState.Edit">
            You have chosen to edit the tour date and time.
            Please pick the new time slots that work for you and we will send them to the other party.
          </ng-container>
        </div>
      </div>
    </div>

    <div class="pane middle-pane">
      <div class="pane-header pane-header--noborder">Pick all the time slots and dates that work for you:</div>

      <div class="calendar">
        <div class="calendar__wrapper">
          <app-tour-form-calendar [referencedDates]="getReferencedDates()"
                                  (dateSelected)="onDateSelected($event)">
          </app-tour-form-calendar>
        </div>

        <div class="calendar__time-slots">
          <div class="calendar__selected-date">{{ selectedDateTitle }}</div>

          <dx-scroll-view>
            <div class="calendar__time-slots-list">
              <ng-container
                *ngIf="state === TourProcessState.Initial || state === TourProcessState.ReplyReject || state === TourProcessState.Edit; else availableSlots">
                <button type="button"
                        class="calendar__time-slot-button"
                        [class.calendar__time-slot-button--active]="isSlotActive(slot)"
                        (click)="onTimeSlotClick(slot)"
                        *ngFor="let slot of timeSlots">{{ slot }}</button>
              </ng-container>
              <ng-template #availableSlots>
                <button type="button"
                        class="calendar__time-slot-button"
                        [class.calendar__time-slot-button--active]="isSlotActive(slot)"
                        (click)="setApprovedTimeSlot(slot)"
                        *ngFor="let slot of getAvailableTimeSlots()">{{ slot }}</button>
              </ng-template>
            </div>
          </dx-scroll-view>
        </div>
      </div>
    </div>

    <div class="pane right-pane">
      <div class="pane-header">
        Time slots you picked:

        <div class="close">
          <button type="button" class="close-button"
                  (click)="close()">
            <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-close-x-16"></i>
          </button>
        </div>
      </div>

      <div class="pane__content h-100">
        <dx-scroll-view *ngIf="isNoTimeSlotsPicked(); else noTimeSlotsPicked">
          <div class="d-flex flex-column pane__content-wrapper">
            <div *ngFor="let entry of getTimeSlotsForSelectedDate()">
              <div class="picked-time-slots__date-heading">{{ getDateTitle(entry.date) }}</div>
              <div class="picked-time-slots__badges">
                <div class="picked-time-slots__badge"
                     *ngFor="let slot of entry.timeSlots">
                  <span>{{ slot }}</span>
                  <img src="/assets/img/badge-delete.svg"
                       alt="Remove time slot button"
                       (click)="onRemoveSlotClick(entry, slot)"
                       *ngIf="state === TourProcessState.Initial || state === TourProcessState.ReplyReject || state === TourProcessState.Edit" />
                </div>
              </div>
            </div>
          </div>
        </dx-scroll-view>

        <ng-template #noTimeSlotsPicked>
          <div class="picked-time-slots__no-data">
            <i inlineSVG="/assets/img/no-data.svg"></i>
            <p>No time slots picked :(</p>
            <p class="subtitle">Pick one or several time slots to send a tour request</p>
          </div>
        </ng-template>

        <div class="pane__footer">
          <ng-container *ngIf="isTooManyTimeSlotsSelected(); else confirmButtonTemplate">
            Please select no more than five time slots
          </ng-container>

          <ng-template #confirmButtonTemplate>
            <button type="button"
                    class="picked-time-slots__confirm-button" (click)="onConfirmTimeSlotsButtonClick()"
                    *ngIf="isShowConfirmButton()">
              Confirm time slots
            </button>
          </ng-template>

          <button type="button"
                  class="picked-time-slots__confirm-button" (click)="onApproveTourButtonClick()"
                  *ngIf="isShowApproveButton()">
            Confirm time slot
          </button>

          <div *ngIf="state === TourProcessState.ReplyReject || state === TourProcessState.Edit" class="tour-cancel">
            <i inlineSVG="assets/img/x-small.svg"></i>
            <a (click)="cancelTour()">Cancel Tour?</a>
          </div>

          <div *ngIf="state === TourProcessState.ReplyAccept" class="tour-cancel">
            <i inlineSVG="assets/img/x-small.svg"></i>
            <a (click)="onReplyRejectButtonClick()">Offer different time slots</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
