import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-commencement-term',
  templateUrl: './commencement-term.component.html',
  styleUrls: ['./commencement-term.component.scss'],
})
export class CommencementTermComponent extends BaseTermDirective<models.ICommencementTermViewModel> implements OnInit {
  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  options: any = [
    {name: 'Custom', value: 1},
  ];

  option: any;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  BeneficialOccupancyType = models.BeneficialOccupancyType;

  beneficialOccupancyTypeOptions: any = [
    {
      name: 'None Granted',
      value: models.BeneficialOccupancyType.NoneGranted,
    },
    {
      name: (
        'Tenant shall be provided Beneficial Occupancy to the new Premises at a mutually acceptable date, ' +
        'no later than (CHOOSE DATE) for set-up while construction is being completed. ' +
        'Tenant shall be responsible for its utilities during this period, but shall not be responsible for any' +
        ' (CHOOSE EITHER BASE RENT, OPERATING EXPENSES, OR BOTH)'
      ),
      value: models.BeneficialOccupancyType.TenantShallBeProvidedBeneficialOccupancy,
    },
    {
      name: 'Custom',
      value: models.BeneficialOccupancyType.Custom,
    },
  ];

  tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodTypeOptions: any = [
    {
      name: 'Base Rent',
      value: models.TenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodType.BaseRent,
    },
    {
      name: 'Operating Expenses',
      value: models.TenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodType.OperatingExpenses,
    }
  ];

  ngOnInit() {
    this.option = this.options[0];
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ICommencementTermViewModel>{};
    }

    super.ngOnInit();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.commencement) {
      this.addError('You should choose appropriate options.');
      return;
    }

    let onSavedFunction = null;
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.Restructure) {
      onSavedFunction = () => {
        if (this.leaseTerm.termRemainderValuePeriod && this.leaseTerm.periodAsString) {
          this.addError(`Please note that this is ${this.leaseTerm.periodAsString} prior to the current Lease Expiration.`);
        }
      };
    }

    super.saveLeaseTerm(this.leaseTerm, null, onSavedFunction);

    $event.preventDefault();
  }

  isTenantSquareFootageTypePhaseIn(): boolean {
    return (
      this.lease &&
      this.lease.tenantSquareFootageTerm &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn
    );
  }

  isRentalRateEscalationTypeCustom(): boolean {
    return (
      this.lease &&
      this.lease.rentalRateAnnualEscalationTerm &&
      this.lease.rentalRateAnnualEscalationTerm.escalationTermType === models.EscalationTermType.Custom
    );
  }

  isExpansion(): boolean {
    return this.lease && this.lease.tenantSquareFootageTerm && this.lease.tenantSquareFootageTerm.isExpansion;
  }

  showBeneficialOccupancyOptions() {
    return this.isExpansion() ||
      (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal);
  }

  isShowStrangeCommencementValueWarning(): boolean {
    if (!this.lease?.abstractLease?.expiration) {
      return false;
    }

    // REM: direct comparison of moment/date objects doesn't really work well.
    const a = moment(this.leaseTerm.commencement, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const b = moment(this.lease.abstractLease?.expiration, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    return a !== b;
  }
}
