import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './versioned-file-comment.model';

@Injectable()
export class VersionedFileCommentRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addMessage(message: models.VersionedFileComment): Observable<models.VersionedFileComment> {
    return this._httpClient
      .post<models.VersionedFileComment>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/CreateComment`,
        message,
      );
  }

  getMessages(
    versionedFileCommentType: models.VersionedFileCommentType,
    leaseId?: number,
    skip?: number,
    take?: number,
  ): Observable<Array<models.VersionedFileComment>> {
    let params = new HttpParams();

    params = params.set('versionedFileCommentType', versionedFileCommentType.toString());

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString());
    }

    if (skip || skip === 0) {
      params = params.set('skip', skip.toString());
    }

    if (take || take === 0) {
      params = params.set('take', take.toString());
    }

    return this._httpClient
      .get<Array<models.VersionedFileComment>>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/GetCommentsByLease`,
        {
          params,
        },
      );
  }

  updateMessage(message: models.VersionedFileComment): Observable<models.VersionedFileComment> {
    return this._httpClient
      .post<models.VersionedFileComment>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/UpdateComment`,
        message,
      );
  }

  deleteMessage(message: models.VersionedFileComment): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/DeleteComment`,
        message,
      );
  }
}
