import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TermCustomValueTemplateRepository } from './term-custom-value-template.repository';

import * as models from './term-custom-value-template.model';

@Injectable()
export class TermCustomValueTemplateManager {
  private readonly _termCustomValueTemplateRepository: TermCustomValueTemplateRepository;

  constructor(termCustomValueTemplateRepository: TermCustomValueTemplateRepository) {
    this._termCustomValueTemplateRepository = termCustomValueTemplateRepository;
  }

  /**
   * Requests management
   */

  getList(): Observable<Array<models.TermCustomValueTemplateViewModel>> {
    return this._termCustomValueTemplateRepository
      .getList()
      .pipe(
        tap((list) => {
          this._termCustomValueTemplateRepository.setStoredList(list);
        }),
      );
  }

  create(viewModel: models.TermCustomValueTemplateViewModel): Observable<models.TermCustomValueTemplateViewModel> {
    return this._termCustomValueTemplateRepository
      .create(viewModel);
  }

  update(viewModel: models.TermCustomValueTemplateViewModel): Observable<models.TermCustomValueTemplateViewModel> {
    return this._termCustomValueTemplateRepository
      .update(viewModel);
  }

  delete(id: number): Observable<void> {
    return this._termCustomValueTemplateRepository
      .delete(id);
  }

  /**
   * Store management
   */

  getStoredList(): Observable<Array<models.TermCustomValueTemplateViewModel>> {
    return this._termCustomValueTemplateRepository
      .getStoredList();
  }

  setStoredList(list: Array<models.TermCustomValueTemplateViewModel>): void {
    return this._termCustomValueTemplateRepository
      .setStoredList(list);
  }
}
