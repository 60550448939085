<dx-scroll-view #scrollView id="scrollview">
  <form name="form" class="form w-form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-header">
      <!-- Start: Picture -->
      <ng-container
        *ngIf="getMainBuildingPicture() as mainBuildingUnitPicture; else noBuildingUnitPictureTemplate">
        <img [src]="mainBuildingUnitPicture.file.url"/>
      </ng-container>
      <ng-template #noBuildingUnitPictureTemplate>
        <img src="assets/v2/img/no-image.svg"/>
      </ng-template>
      <!-- End: Picture -->
      <div>
        <!-- Start: Name -->
        <div class="form-header-title">
          <i inlineSVG="assets/img/sign-icons.svg#unit-icon"></i>
          <p>
            {{ buildingUnit.name }}
          </p>
        </div>
        <!-- End: Name -->
        <!-- Start: Size -->
        <ng-container *ngIf="buildingUnit.size; else noBuildingUnitSizeTemplate">
          <p class="form-header-size">
            Unit size: {{ buildingUnit.size | safeNumber }} SF
          </p>
        </ng-container>
        <ng-template #noBuildingUnitSizeTemplate>
          <p class="form-header-size">
            Unit size: N/A
          </p>
        </ng-template>
        <!-- End: Size -->
      </div>
    </div>
    <div>
      <p class="form-title">set listing details</p>
      <div class="d-flex form-date-range">
        <!-- Start: Availability Date -->
        <div class="availability-date w-50">
          <p class="form-text">
            Availability Date
          </p>
          <div class="dx-field-value">
            <div>
              <dx-date-box class="real-estate-input" type="date" name="availableOn"
                           [(ngModel)]="buildingUnitListing.availableOn"
                           [disabledDates]="isDateDisabled">
              </dx-date-box>
            </div>
          </div>
          <p class="form-text lease-expiration"
             *ngIf="!isLeaseExpired()">
            Current Lease Expires: {{ suite.leaseEndDate | date:'MM/dd/yyyy' }}
          </p>
        </div>
        <!-- End: Availability Date -->
        <!-- Start: Listed Space Range -->
        <div class="dx-field mb-0 w-50">
          <p class="form-text">
            Set Listed Space Range
          </p>
          <div class="dx-field-value">
            <!-- Start: Sizing -->
            <div style="margin-bottom: 4px;">
              <app-multi-range-slider [min]="0"
                                      [max]="suite.buildingUnit.size"
                                      [step]="10"
                                      [minValue]="buildingUnitListing.sizeMin"
                                      (minValueChange)="handleBuildingUnitListingSizeMinChange($event)"
                                      [(maxValue)]="buildingUnitListing.sizeMax">
              </app-multi-range-slider>
            </div>
            <div>
              <div class="d-flex align-items-center justify-content-between form-range">
                <div>
                  <dx-number-box class="real-estate-input mb-0"
                                 placeholder="0 SF"
                                 format="#,### SF"
                                 min="0" step="0"
                                 [value]="buildingUnitListing.sizeMin"
                                 (valueChange)="handleBuildingUnitListingSizeMinChange($event)">
                  </dx-number-box>
                </div>
                <i inlineSVG="assets/img/sign-icons.svg#minus-icon"></i>
                <div>
                  <dx-number-box class="real-estate-input mb-0"
                                 [placeholder]="suite.buildingUnit.size ? (suite.buildingUnit.size | safeNumber) + ' SF' : '14,000 SF'"
                                 min="1" step="0" format="#,### SF"
                                 [value]="buildingUnitListing.sizeMax"
                                 (valueChange)="handleBuildingUnitListingSizeMaxChange($event)">
                    <dx-validator>
                      <dxi-validation-rule type="custom"
                                           [validationCallback]="isSizeMaxFieldValid">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </div>
              <div class="dx-field-value-error"
                   *ngIf="buildingUnitListing.sizeMin && !buildingUnitListing.sizeMax">
                Maximum size is required, if Minimum size is provided
              </div>
              <div class="dx-field-value-error"
                   *ngIf="!buildingUnitListing.sizeMin && !buildingUnitListing.sizeMax">
                Maximum size is required
              </div>
            </div>
            <!-- End: Sizing -->
          </div>
        </div>
      </div>
      <!-- End: Listed Space Range -->
    </div>
    <div class="form-pricing-section">
      <p class="form-title">SET PRICING</p>
      <!-- Start: Pricing -->
      <div>
        <div class="row">
          <div class="col-6">
            <div class="dx-field">
              <p class="form-text">
                Choose Rental Structure
              </p>
              <div class="dx-field-value">
                <dx-select-box class="real-estate-input py-0" displayExpr="text" valueExpr="id"
                               [dataSource]="buildingUnitListingPriceStructureSelectItems"
                               [(value)]="buildingUnitListing.priceStructure">
                </dx-select-box>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="dx-field">
              <p class="form-text">
                Display Costs In
              </p>
              <div class="dx-field-value">
                <dx-select-box class="real-estate-input py-0" displayExpr="text" valueExpr="id"
                               [dataSource]="buildingUnitListingPriceUnitMetricsSelectItems"
                               [(value)]="buildingUnitListing.priceUnitMetrics">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Price type is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="dx-field">
              <p class="form-text">
                Asking Price
              </p>
              <div class="dx-field-value">
                <dx-radio-group class="price-type-radio-group"
                                displayExpr="text" valueExpr="id" itemTemplate="radio"
                                [dataSource]="buildingUnitListingPriceTypeSelectItems"
                                [(value)]="buildingUnitListing.priceType">
                  <div class="radio"
                       *dxTemplate="let data of 'radio'"
                       [class.insert-data]="data.insertData">
                    <p *ngIf="!data.insertData; else insert">
                      {{ data.text }}
                    </p>
                    <ng-template #insert>
                      <div class="insert-row">
                        <div class="d-flex justify-content-between align-items-baseline form-price">
                          <div>
                            <dx-number-box
                              class="real-estate-input price-input no-invalid-message"
                              min="0" step="0"
                              [placeholder]="'$ (min)'"
                              [format]="'$ #,###.## '"
                              [disabled]="buildingUnitListing.priceType === buildingUnitListingPriceTypes.Negotiable"
                              [(value)]="buildingUnitListing.priceMin"
                              #priceMinField>
                              <dx-validator>
                                <dxi-validation-rule type="custom"
                                                     [validationCallback]="isPriceMinFieldValid">
                                </dxi-validation-rule>
                              </dx-validator>
                            </dx-number-box>
                          </div>
                          <p class="form-text">
                            to
                          </p>
                          <div>
                            <dx-number-box
                              class="real-estate-input price-input no-invalid-message"
                              min="0" step="0"
                              [format]="'$ #,###.## '"
                              [placeholder]="'$ (max)'"
                              [disabled]="buildingUnitListing.priceType === buildingUnitListingPriceTypes.Negotiable"
                              [(value)]="buildingUnitListing.priceMax">
                            </dx-number-box>
                          </div>
                          <p class="form-text">
                            {{ getPriceUnitMetricsLabel() }}
                          </p>
                        </div>
                      </div>
                      <ng-container
                        *ngIf="!(buildingUnitListing.priceType === buildingUnitListingPriceTypes.Negotiable)">
                        <div class="dx-field-value-error pt-0"
                             *ngIf="!priceMinField.isValid && buildingUnitListing.priceMax && !buildingUnitListing.priceMin">
                          Minimum price is required, if Maximum price is provided
                        </div>
                        <div class="dx-field-value-error pt-0"
                             *ngIf="!priceMinField.isValid && !buildingUnitListing.priceMax && !buildingUnitListing.priceMin">
                          Minimum price is required
                        </div>
                      </ng-container>
                    </ng-template>
                  </div>
                </dx-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="dx-field">
              <p class="form-text">
                Estimated RE Taxes
              </p>
              <div class="dx-field-value">
                <app-input-field className="no-invalid-message mb-0"
                                 [min]="0" [step]="0" [placeholder]="'(e.g. 5)'"
                                 [prefix]="'$'" [suffix]="getPriceUnitMetricsLabel()"
                                 [format]="'#,###.##'"
                                 [value]="buildingUnitListing.estimatedReTaxes"
                                 [width]="'75px'"
                                 (valueChange)="buildingUnitListing.estimatedReTaxes = $event">
                </app-input-field>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="dx-field">
              <p class="form-text">
                Estimated CAM
              </p>
              <div class="dx-field-value">
                <app-input-field className="no-invalid-message mb-0"
                                 [min]="0" [step]="0" [placeholder]="'(e.g. 5)'"
                                 [prefix]="'$'" [suffix]="getPriceUnitMetricsLabel()"
                                 [format]="'#,###.##'"
                                 [value]="buildingUnitListing.estimatedOpEx"
                                 [width]="'75px'"
                                 (valueChange)="buildingUnitListing.estimatedOpEx = $event">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="dx-field">
              <p class="form-text">
                Estimated Insurance
              </p>
              <div class="dx-field-value">
                <app-input-field className="no-invalid-message mb-0"
                                 [min]="0" [step]="0" [placeholder]="'(e.g. 5)'"
                                 [prefix]="'$'" [suffix]="getPriceUnitMetricsLabel()"
                                 [format]="'#,###.##'"
                                 [value]="buildingUnitListing.insurance"
                                 [width]="'75px'"
                                 (valueChange)="buildingUnitListing.insurance = $event">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End: Pricing -->
    </div>
    <div>
      <p class="form-title">photos and other details</p>
      <!-- Start: Floor Plan(s) -->
      <div class="dx-field">
        <p class="form-text">
          Attach Floor Plan(s)
        </p>
        <div class="dx-field-value file-control">
          <ng-template #floorPhotoUploader>
            <dx-file-uploader name="file" accept="image/*,image/heic" uploadMode="useForm"
                              [multiple]="true" [showFileList]="false"
                              (onValueChanged)="handleUploadImagesChange(buildingUnitFloorPlanImagesToUpload, $event)">
            </dx-file-uploader>
          </ng-template>
          <ng-component *ngTemplateOutlet="floorPhotoUploader">
          </ng-component>
          <div class="file-control-actions">
            <i inlineSVG="assets/img/sign-icons.svg#floor-plan-icon"></i>
            <div class="d-flex flex-column align-items-center file-control-drag">
              <p>Choose a file</p>
              <p>or drag it here</p>
            </div>
            <div class="file-control-size d-flex align-items-center">
              <p>jpg, png, webp or PDF</p>
              <i inlineSVG="assets/img/sign-icons.svg#circle-icon"></i>
              <p>20mb max</p>
            </div>
          </div>
          <ng-container *ngIf="getFloorPlanAttachmentStyleOptions() as styleOptions">
            <div class="position-absolute file-control-files"
                 [class]="styleOptions.className"
                 *ngIf="buildingUnit.floorPlans.length || buildingUnitFloorPlanImagesToUpload.length">
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let floorPlan of buildingUnit.floorPlans; let index = index;">
                <div class="position-relative file-control-file">
                  <img alt="" class="preview-image"
                       [src]="floorPlan.picture.thumbnailUrl"/>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeBuildingUnitFloorPlan(index)">
                  </a>
                </div>
              </div>
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let image of buildingUnitFloorPlanImagesToUpload; let index = index;">
                <div class="position-relative file-control-file">
                  <ng-container
                    *ngIf="(image.previewUrl | async) as imagePreviewUrl; else noPreviewImage">
                    <img alt="" class="preview-image"
                         [src]="imagePreviewUrl"/>
                  </ng-container>
                  <ng-template #noPreviewImage>
                    <div class="preloader">
                      <span></span>
                    </div>
                  </ng-template>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeFileToUpload(buildingUnitFloorPlanImagesToUpload, index)">
                  </a>
                </div>
              </div>
              <div class="position-absolute cursor-pointer add-icon">
                <div class="position-relative d-flex justify-content-center align-items-center">
                  <a role="button"
                     inlineSVG="assets/img/sign-icons.svg#plus-icon-16">
                  </a>
                  <ng-component *ngTemplateOutlet="floorPhotoUploader"></ng-component>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- End: Floor Plan(s) -->
      <!-- Start: Photo(s) -->
      <div class="dx-field">
        <p class="form-text">
          Attach Photo(s)
        </p>
        <div class="dx-field-value file-control">
          <ng-template #photoUploader>
            <dx-file-uploader name="file" accept="image/*,image/heic" uploadMode="useForm"
                              [multiple]="true" [showFileList]="false"
                              (onValueChanged)="handleUploadImagesChange(buildingUnitPicturesToUpload, $event)">
            </dx-file-uploader>
          </ng-template>
          <ng-component *ngTemplateOutlet="photoUploader"></ng-component>
          <div class="file-control-actions">
            <i inlineSVG="assets/img/sign-icons.svg#upload-img-icon"></i>
            <div class="d-flex flex-column align-items-center file-control-drag">
              <p>Choose a file</p>
              <p>or drag it here</p>
            </div>
            <div class="file-control-size d-flex align-items-center">
              <p>jpg, png, webp or PDF</p>
              <i inlineSVG="assets/img/sign-icons.svg#circle-icon"></i>
              <p>20mb max</p>
            </div>
          </div>
          <ng-container *ngIf="getPictureAttachmentStyleOptions() as styleOptions">
            <div class="position-absolute file-control-files"
                 [class]="styleOptions.className"
                 *ngIf="getPictures().length || buildingUnitPicturesToUpload.length">
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let picture of getPictures(); let index = index;">
                <div class="position-relative file-control-file">
                  <img alt="" class="preview-image"
                       [src]="picture.file.url"/>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeBuildingAttachment(picture.id)">
                  </a>
                </div>
              </div>
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let image of buildingUnitPicturesToUpload; let index = index;">
                <div class="position-relative file-control-file">
                  <ng-container
                    *ngIf="(image.previewUrl | async) as imagePreviewUrl; else noPreviewImage">
                    <img alt="" class="preview-image"
                         [src]="imagePreviewUrl"/>
                  </ng-container>
                  <ng-template #noPreviewImage>
                    <div class="preloader">
                      <span></span>
                    </div>
                  </ng-template>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeFileToUpload(buildingUnitPicturesToUpload, index)">
                  </a>
                </div>
              </div>
              <div class="position-absolute cursor-pointer add-icon">
                <div class="position-relative d-flex justify-content-center align-items-center">
                  <a role="button"
                     inlineSVG="assets/img/sign-icons.svg#plus-icon-16">
                  </a>
                  <ng-component *ngTemplateOutlet="photoUploader"></ng-component>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- End: Photo(s) -->
      <!-- Start: Video(s) -->
      <div class="dx-field">
        <p class="form-text">
          Attach Virtual Tour Video(s)
        </p>
        <div class="dx-field-value file-control">
          <ng-template #videoUploader>
            <dx-file-uploader name="file" uploadMode="useForm"
                              [multiple]="true" [showFileList]="false"
                              [allowedFileExtensions]="['.mp4', '.webm', '.ogg', '.mov']"
                              (onValueChanged)="handleUploadVideosChange(buildingUnitVideosToUpload, $event)">
            </dx-file-uploader>
          </ng-template>
          <ng-component *ngTemplateOutlet="videoUploader"></ng-component>
          <div class="file-control-actions">
            <i inlineSVG="assets/img/sign-icons.svg#upload-video-icon"></i>
            <div class="d-flex flex-column align-items-center file-control-drag">
              <p>Choose a file</p>
              <p>or drag it here</p>
            </div>
            <div class="file-control-size d-flex align-items-center">
              <p>mp4, avi, wmv</p>
              <i inlineSVG="assets/img/sign-icons.svg#circle-icon"></i>
              <p>10gb max</p>
            </div>
          </div>
          <ng-container *ngIf="getVideoAttachmentStyleOptions() as styleOptions">
            <div class="position-absolute file-control-files"
                 [class]="styleOptions.className"
                 *ngIf="getVideos().length || buildingUnitVideosToUpload.length">
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let video of getVideos(); let index = index;">
                <div class="position-relative file-control-file">
                  <video [src]="video?.file?.url" class="preview-image" preload="metadata"
                         controls>
                  </video>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeBuildingAttachment(video.id)">
                  </a>
                </div>
              </div>
              <div class="file-control-files-wrapper"
                   [style.height]="styleOptions.height"
                   *ngFor="let video of buildingUnitVideosToUpload; let index = index;">
                <div class="position-relative file-control-file">
                  <ng-container
                    *ngIf="(video.previewUrl | async) as videoUrl; else noPreviewImage">
                    <video [src]="videoUrl" class="preview-image" preload="metadata"
                           controls>
                    </video>
                  </ng-container>
                  <ng-template #noPreviewImage>
                    <div class="preloader">
                      <div class="loading"></div>
                      <p>Uploading your file...</p>
                    </div>
                  </ng-template>
                  <a role="button" class="position-absolute cursor-pointer delete-icon"
                     inlineSVG="assets/img/sign-icons.svg#trash-icon"
                     (click)="removeFileToUpload(buildingUnitVideosToUpload, index)">
                  </a>
                </div>
              </div>
              <div class="position-absolute cursor-pointer add-icon">
                <div class="position-relative d-flex justify-content-center align-items-center">
                  <a role="button"
                     inlineSVG="assets/img/sign-icons.svg#plus-icon-16">
                  </a>
                  <ng-component *ngTemplateOutlet="videoUploader"></ng-component>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- End: Video(s) -->
      <!-- Start: Links(s) -->
      <div class="file-control-link">
        <!-- Start: Virtual Tour links(s) -->
        <div class="position-relative dx-field">
          <dx-text-box class="real-estate-input no-invalid-message py-0" name="virtual-tour-link"
                       placeholder="Or add a virtual tour link (YouTube or Vimeo)"
                       [(ngModel)]="virtualTourLinkUrl">
            <dx-validator>
              <dxi-validation-rule type="custom"
                                   [validationCallback]="isLinkFieldValid">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <i class="position-absolute attach-link"
             inlineSVG="assets/img/link.svg">
          </i>
          <a role="button" class="position-absolute attach-button"
             inlineSVG="assets/img/plus.svg"
             *ngIf="virtualTourLinkUrl"
             (click)="addVirtualTourLink()">
          </a>
        </div>

        <div class="dx-field">

          <div class="delete-url" *ngFor="let url of getUploadedYoutubeOrVimeoLinksLinks();">
            <i inlineSVG="assets/icons/inlineSVG/listSpace.svg#link"></i>
            <a >

              {{ url.externalUrl }}
            </a>
            <i class="ml-auto" inlineSVG="assets/icons/inlineSVG/listSpace.svg#minus" (click)="deleteUploadedLink(url.id)"></i>
          </div>

          <div  class="delete-url" *ngFor="let url of getYoutubeOrVimeoLinksToUpload();">
            <i inlineSVG="assets/icons/inlineSVG/listSpace.svg#link"></i>
            <a >

              {{ url }}
            </a>
            <i  class="ml-auto" inlineSVG="assets/icons/inlineSVG/listSpace.svg#minus"  (click)="deleteLinkToUpload(url)"></i>
          </div>

        </div>
        <!-- End: Virtual Tour links(s) -->
        <!-- Start: External links(s) -->
        <div class="dx-field">
          <p class="form-text">
            External links
          </p>

          <div class="position-relative">
            <dx-text-box class="real-estate-input no-invalid-message py-0" name="external-link"
                         placeholder="Are there any additional links you would like to provide?"
                         [(ngModel)]="externalLinkUrl">
              <dx-validator>
                <dxi-validation-rule type="custom"
                                     [validationCallback]="isLinkFieldValid">
                </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
            <i class="position-absolute attach-link"
               inlineSVG="assets/img/link.svg">
            </i>
            <a role="button" class="position-absolute attach-button"
               inlineSVG="assets/img/plus.svg"
               *ngIf="externalLinkUrl"
               (click)="addExternalLink()">
            </a>
          </div>
        </div>
        <div class="dx-field">

          <div class="delete-url" *ngFor="let url of getUploadedExternalLinks()">
            <i inlineSVG="assets/icons/inlineSVG/listSpace.svg#link"></i>
            <a >
              {{ url.externalUrl }}
            </a>
            <i class="ml-auto" inlineSVG="assets/icons/inlineSVG/listSpace.svg#minus"  (click)="deleteUploadedLink(url.id)"></i>
          </div>

          <div class="delete-url" *ngFor="let url of getExternalUrlsToUpload()">
            <i inlineSVG="assets/icons/inlineSVG/listSpace.svg#link"></i>
            <a >
              {{ url }}
            </a>
            <i class="ml-auto" inlineSVG="assets/icons/inlineSVG/listSpace.svg#minus" (click)="deleteLinkToUpload(url)"></i>
          </div>

        </div>
        <!-- End: External links(s) -->
      </div>
      <div>
        <!-- Start: Description -->
        <div class="file-control-link">
          <div class="dx-field">
            <p class="form-text">
              Details
            </p>
            <div class="dx-field-value">
              <dx-text-area class="real-estate-input py-0" name="notes"
                            placeholder="Are there any additional details you would like to provide?  E.g. “This unit fits perfectly to use as storage space”"
                            [(ngModel)]="buildingUnitListing.notes">
              </dx-text-area>
            </div>
          </div>
        </div>
        <!-- End: Description -->
      </div>

    </div>
    <!-- End: Action Buttons -->
    <!-- Start: Action Buttons -->
    <div class="form-actions main-actions">

      <ng-container *ngIf="isListed">
        <div class="form-action-button">
          <dx-button type="normal" text="Cancel" width="100%"
                     [disabled]="isLoading"
                     (onClick)="close()">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="normal" text="Unlist Space" width="100%"
                     [disabled]="isLoading"
                     (onClick)="deleteListing();">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="success" width="100%" text="Save Listing"
                     [disabled]="isLoading"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
      </ng-container>
      <ng-container *ngIf="!isListed">
        <div class="form-action-button">
          <dx-button type="normal" text="Cancel" width="100%"
                     [disabled]="isLoading"
                     (onClick)="close()">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="success" width="100%" text="List Space"
                     [disabled]="isLoading"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
      </ng-container>
    </div>
  </form>

</dx-scroll-view>
