import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { IPortfolioViewModel } from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-add-building',
  templateUrl: './add-building.component.html',
  styleUrls: ['./add-building.component.scss']
})
export class AddBuildingComponent implements OnInit {
  portfolios: Array<IPortfolioViewModel> = [];

  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;

  constructor(landlordStore: LandlordStore, landlordManager: LandlordManager) {
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
  }

  ngOnInit() {
    this._landlordStore
      .portfolios$
      .pipe(
        take(1)
      )
      .subscribe((portfolios) => {
        this.portfolios = portfolios;
      });
  }
}
