import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { SignatureRepository } from './signature.repository';
import { SignatureManager } from './signature.manager';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    SignatureRepository,
    SignatureManager,
  ],
})
export class SignatureModule {
  static forChild(configuration: Configuration): ModuleWithProviders<SignatureModule> {
    return {
      ngModule: SignatureModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        SignatureRepository,
        SignatureManager,
      ],
    };
  }
}
