import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import * as dx from 'devextreme-angular';

import { BuildingModule } from '../building/building.module';
import { FeatureTogglingModule } from '../feature-toggling/feature-toggling.module';
import { PlanModule } from '../plan/plan.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { SharedModule } from '../shared/shared.module';

import { NegotiateComponent } from './components/negotiate/negotiate.component';
import { ScheduleProjectsComponent } from './components/schedule-projects/schedule-projects.component';
import { ProjectRequestModule } from '../project-request/project-request.module';

@NgModule({
  declarations: [
    NegotiateComponent,
    ScheduleProjectsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BuildingModule,
    PlanModule,
    SharedModule,
    InfrastructureModule,
    ProjectRequestModule,
    FeatureTogglingModule,

    dx.DxTabsModule,
    dx.DxSelectBoxModule,
    dx.DxAccordionModule,
    dx.DxGalleryModule,
    dx.DxPopupModule,
    dx.DxToolbarModule,
    dx.DxTextBoxModule,
    dx.DxButtonModule,
    dx.DxTooltipModule,
  ],
  exports: [
    NegotiateComponent,
    ScheduleProjectsComponent,
  ],
})
export class DashboardModule {}
