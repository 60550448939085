import {Component, HostListener, Input, ViewChild} from '@angular/core';

import * as ng from '@angular/core';

import * as models from '../../models/image-viewer-image.model';

import { ImageViewerImageStandardComponent } from '../image-viewer-image-standard/image-viewer-image-standard.component';
import { ImageViewerImagePanoramaComponent } from '../image-viewer-image-panorama/image-viewer-image-panorama.component';

@Component({
  selector: 'app-image-viewer-image',
  templateUrl: 'image-viewer-image.component.html',
  styleUrls: ['image-viewer-image.component.scss'],
})
export class ImageViewerImageComponent implements ng.OnInit, ng.OnChanges {
  @ViewChild('imageViewerImageStandardComponent') imageViewerImageStandardComponent: ImageViewerImageStandardComponent;
  @ViewChild('imageViewerImagePanoramaComponent') imageViewerImagePanoramaComponent: ImageViewerImagePanoramaComponent;
  @ViewChild('addNoteInput', { static: false }) addNoteInput: ng.ElementRef;

  @Input() imageRef: models.ImageViewerImageRef;

  @Input() width: number;
  @Input() height: number;
  @Input() minWidth: number;
  @Input() minHeight: number;

  @Input() isPreviewMode: boolean;

  @Input() allowChangeMarkers: boolean;

  @Input() markerCreated$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerChanged$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerDeleted$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() addNotePressed: boolean;

  ImageKind: typeof models.ImageViewerImageKind;
  markerRefToEdit: models.ImageViewerImageMarkerRef;
  note: string;
  innerWidth: number;

  constructor() {
    this.ImageKind = models.ImageViewerImageKind;
  }

  ngOnInit(): void {
    this.width = this.width || null;
    this.height = this.height || null;
    this.minWidth = this.minWidth || null;
    this.minHeight = this.minHeight || null;
    this.isPreviewMode = this.isPreviewMode || false;
    this.innerWidth = window.innerWidth;

    this.markerCreated$ = this.markerCreated$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
    this.markerChanged$ = this.markerChanged$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
    this.markerDeleted$ = this.markerDeleted$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      this.imageViewerImagePanoramaComponent &&
      (
        (changes.width && changes.width.previousValue !== changes.width.currentValue) ||
        (changes.height && changes.height.previousValue !== changes.height.currentValue)
      )
    ) {
      this.imageViewerImagePanoramaComponent.resize();
    }

    if (changes.addNotePressed && !changes.addNotePressed.isFirstChange() && !changes.addNotePressed.currentValue) {
      this.addNoteInput.nativeElement.style.display = 'none';
    }
  }

  onMarkerDrawn(marker: { markerRef: models.ImageViewerImageMarkerRef, x: number, y: number }): void {
    this.addNoteInput.nativeElement.style.display = 'flex';
    // let x = marker.x + 20;
    // if (marker.x > this.innerWidth / 2) {
    //   x = marker.x - 280;
    // }
    this.addNoteInput.nativeElement.style['transform'] = `translate(${marker.x}px, ${marker.y}px)`;
    this.markerRefToEdit = marker.markerRef;
    this.note = marker.markerRef?.text || '';
  }

  addNote(): void {
    if (!this.note || !this.markerRefToEdit) {
      return;
    }
    this.markerRefToEdit.text = this.note;
    this.addNoteInput.nativeElement.style.display = 'none';
    this.note = '';
    const isCreateEvent = !this.imageRef.markers.some(x => x.id === this.markerRefToEdit.id);
    if (isCreateEvent) {
      this.markerCreated$.emit(this.markerRefToEdit);
    } else {
      this.markerChanged$.emit(this.markerRefToEdit);
    }
    this.markerRefToEdit = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
