import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserManager } from '@statera/sdk/user';

import { AuthService } from '../../../auth/services/auth.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../models/two-factor-auth-settings.model';

export enum PhoneNumberVerifyReason {
  TwoFactorAuth,
  SmsNotifications,
}

@Component({
  templateUrl: './verify-phone-number-dialog.component.html',
  styleUrls: ['./verify-phone-number-dialog.component.scss']
})
export class VerifyPhoneNumberDialogComponent implements OnInit, OnDestroy {
  private readonly _authService: AuthService;
  private readonly _userManager: UserManager;
  private readonly _dialogRefService: DialogRefService;

  @Input() user: models.User;
  @Input() reason: PhoneNumberVerifyReason;

  PhoneNumberVerifyReason: typeof PhoneNumberVerifyReason = PhoneNumberVerifyReason;

  codePhoneNumber: string;
  startupInfo: models.StartupInfo;
  isSmsCodeSent = false;
  isPhoneNumberVerified = false;
  isEnteredCodeInvalid = false;
  maskedPhoneNumber = '';

  private _destroy$ = new Subject();

  constructor(authService: AuthService, userManager: UserManager, dialogRefService: DialogRefService) {
    this._authService = authService;
    this._userManager = userManager;
    this._dialogRefService = dialogRefService;
  }

  ngOnInit() {
    this.startupInfo = this._authService.startupInfo;
    this.maskedPhoneNumber = `**-****${this.user.mobilePhone.slice(-2)}`;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  sendVerificationCode($event) {
    this._userManager.sendPhoneNumberVerificationCode();

    this.isSmsCodeSent = true;
    this.isEnteredCodeInvalid = false;

    $event.stopPropagation();
  }

  verifyCode(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this._userManager
      .handlePhoneNumberVerify(this.user, this.codePhoneNumber, this.reason === PhoneNumberVerifyReason.TwoFactorAuth)
      .pipe(
        tap(result => {
          if (result.success) {
            this.isPhoneNumberVerified = true;
            this.isEnteredCodeInvalid = false;
            return;
          }

          this.isEnteredCodeInvalid = true;
          this.isPhoneNumberVerified = false;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  close() {
    this._dialogRefService.hide();
  }
}
