import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-condition-of-premises',
  templateUrl: './condition-of-premises.component.html',
  styleUrls: ['./condition-of-premises.component.scss']
})
export class ConditionOfPremisesComponent extends BaseTermDirective<models.IConditionOfPremisesTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  ConditionOfPremisesType: typeof models.ConditionOfPremisesTermType = models.ConditionOfPremisesTermType;

  private readonly _destroy$: Subject<void>;

  options = [
    {
      name: 'As is',
      value: models.ConditionOfPremisesTermType.AsIs,
    },
    {
      name:
        'Landlord shall deliver all building systems including mechanical, electrical, plumbing and HVAC systems in ' +
        'good working order, ADA compliant. All warranties for HVAC and other building systems shall be passed to Tenant.',
      value: models.ConditionOfPremisesTermType.LandlordDelivered,
    },
    {
      name:
        'Landlord shall deliver all building systems including mechanical, electrical, plumbing and HVAC systems in ' +
        'good working order, ADA compliant and will warrant all systems for (INSERT WARRANTY LENGTH) months',
      value: models.ConditionOfPremisesTermType.LandlordDeliveredWithWarranty,
    },
    {
      name: 'Custom',
      value: models.ConditionOfPremisesTermType.Custom,
    },
  ];
  warrantyMonthOptions = [
    {
      label: '3 months',
      value: 3,
    },
    {
      label: '6 months',
      value: 6,
    },
    {
      label: '9 months',
      value: 9
    },
    {
      label: '12 months',
      value: 12,
    },
  ];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }
}
