import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { SignatureByTokenComponent } from './components/signature-by-token/signature-by-token.component';
import { SignatureModule } from './signature.module';

const routes: Routes = [
  {path: '', component: SignatureByTokenComponent},
];

export const SignatureRouter: ModuleWithProviders<SignatureModule> = RouterModule.forChild(routes);
