import { EventEmitter, Injectable } from '@angular/core';

import { ImageViewerImageMarkerRef } from '../models/image-viewer-image.model';

@Injectable()
export class ImageViewerRefService {
  hide: () => void;

  onShowing: EventEmitter<any>;
  onShown: EventEmitter<any>;
  onHiding: EventEmitter<any>;
  onHidden: EventEmitter<any>;

  onContentReady: EventEmitter<any>;

  onMarkerCreated: EventEmitter<ImageViewerImageMarkerRef>;
  onMarkerChanged: EventEmitter<ImageViewerImageMarkerRef>;
  onMarkerDeleted: EventEmitter<ImageViewerImageMarkerRef>;

  constructor() {
    this.hide = Function;

    this.onShowing = new EventEmitter<any>(true);
    this.onShown = new EventEmitter<any>(true);
    this.onHiding = new EventEmitter<any>(true);
    this.onHidden = new EventEmitter<any>(true);

    this.onContentReady = new EventEmitter<any>(true);

    this.onMarkerCreated = new EventEmitter<ImageViewerImageMarkerRef>(true);
    this.onMarkerChanged = new EventEmitter<ImageViewerImageMarkerRef>(true);
    this.onMarkerDeleted = new EventEmitter<ImageViewerImageMarkerRef>(true);
  }
}
