import { Injectable } from '@angular/core';

import * as models from '@statera/sdk/common';
import { TermHoldoverProvisionRepository } from './term-holdover-provision.repository';


@Injectable()
export class TermHoldoverProvisionManager {
  private readonly _termHoldoverProvisionRepository: TermHoldoverProvisionRepository;

  constructor(termHoldoverProvisionRepository: TermHoldoverProvisionRepository) {
    this._termHoldoverProvisionRepository = termHoldoverProvisionRepository;
  }

  getOptions(project: models.IProjectViewModel): Array<{name: string, value: models.HoldoverProvisionType}> {
    if (project && project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      return [
        {
          name: (
            'In the event Tenant remains in the Premises following the end of the Lease Term,' +
            'Tenant shall pay Landlord Holdover Rent equal to (INSERT PERCENT VALUE) of Rent for the first' +
            ' (INSERT COUNT OF DAYS) day(s) and (INSERT PERCENT VALUE) Rent thereafter'
          ),
          value: models.HoldoverProvisionType.TenantRemainsFollowingEndTerm,
        },
        {
          name: 'Possession of Premises by Tenant after Lease termination shall not extend the Term and Monthly Base Rent shall be (INSERT HOLDOVER PERCENT VALUE) of Monthly Base Rent in effect immediately prior to the Holdover period.',
          value: models.HoldoverProvisionType.MonthlyRentPriorToHoldover,
        },
        {
          name: 'Custom',
          value: models.HoldoverProvisionType.Custom,
        }
      ];
    }
    return [
      {
        name: 'Per current lease',
        value: models.HoldoverProvisionType.PerCurrentLease,
      },
      {
        name: (
          'In the event Tenant remains in the Premises following the end of the Lease Term,' +
          'Tenant shall pay Landlord Holdover Rent equal to (INSERT PERCENT VALUE) of Rent for the first' +
          ' (INSERT COUNT OF DAYS) day(s) and (INSERT PERCENT VALUE) Rent thereafter'
        ),
        value: models.HoldoverProvisionType.TenantRemainsFollowingEndTerm,
      },
      {
        name: 'Possession of Premises by Tenant after Lease termination shall not extend the Term and Monthly Base Rent shall be (INSERT HOLDOVER PERCENT VALUE) of Monthly Base Rent in effect immediately prior to the Holdover period.',
        value: models.HoldoverProvisionType.MonthlyRentPriorToHoldover,
      },
      {
        name: 'Custom',
        value: models.HoldoverProvisionType.Custom,
      }
    ];
  }
}
