import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AlertManager, AlertNotification } from '@statera/sdk/alert';

import { Notification } from '@statera/sdk/notification';
import { Observable, race, Subject, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../../../auth/services/auth.service';
import { UserStatus } from '../../../infrastructure/models/generated';
import { ActivityTrackerService } from '../../../infrastructure/services/activity-tracker.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert-container',
  templateUrl: 'alert-container.component.html',
  styleUrls: ['alert-container.component.scss'],
  animations: [
    trigger('animateAlertContainer', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.3s ease-in', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.3s ease-out', style({opacity: 0})),
      ]),
    ]),
    trigger('animateAlert', [
      transition(':enter', [
        style({transform: 'translateY(15px)', opacity: 0}),
        animate('0.3s ease-in', style({transform: 'translateX(0)', opacity: 1})),
      ]),
      transition(':leave', [
        style({transform: 'translateY(0)', opacity: 1}),
        animate('0.3s ease-out', style({transform: 'translateY(-15px)', opacity: 0})),
      ]),
    ]),
  ],
})
export class AlertContainerComponent implements OnInit, OnDestroy {
  private static readonly _alertAnimationEnterDuration: number = 350;
  private static readonly _alertAnimationLeaveDuration: number = 350;

  alerts: Array<AlertNotification>;

  private readonly _authService: AuthService;
  private readonly _alertManager: AlertManager;
  private readonly _alertService: AlertService;
  private readonly _activityTrackerService: ActivityTrackerService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy: Subject<void>;

  constructor(
    authService: AuthService,
    alertManager: AlertManager,
    alertService: AlertService,
    activityTrackerService: ActivityTrackerService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this._authService = authService;
    this._alertManager = alertManager;
    this._alertService = alertService;
    this._activityTrackerService = activityTrackerService;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.alerts = [];

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          let alertNotificationTracker: () => Observable<Array<Notification<AlertNotification>>>;
          if (!info || !info.id) {
            alertNotificationTracker = () => this._alertManager
              .processAlertNotifications();
          } else {
            alertNotificationTracker = () => this._alertManager
              .processRealtimeAlertNotifications(info.role);
          }

          if (info && info.userStatus !== UserStatus.Activated) {
            alertNotificationTracker = () => of(null);
          }

          this._trackNotifications(alertNotificationTracker);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();

    this._changeDetectorRef.detach();
  }

  identifyAlert(index: number, item: AlertNotification): number | string {
    return item.id;
  }

  private _trackNotifications(alertNotificationTracker: () => Observable<Array<Notification<AlertNotification>>>): void {
    let subscription: Subscription;

    const unsubscribe = () => {
      if (!subscription) {
        return;
      }

      subscription.unsubscribe();
      subscription = null;
    };

    const subscribe = () => {
      unsubscribe();

      subscription = alertNotificationTracker()
        .pipe(
          tap(notifications => {
            this.alerts = this._processNotifications(notifications);

            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
          }),
          takeUntil(this._destroy),
        )
        .subscribe();
    };

    this._activityTrackerService
      .track()
      .pipe(
        switchMap(isUserActive => this._alertService
          .alertRenderingStopped
          .pipe(
            map(isAlertRenderingStopped => {
              return {
                isAlertRenderingStopped: isAlertRenderingStopped,
                isUserActive: isUserActive,
              };
            }),
          ),
        ),
        tap(combo => {
          unsubscribe();

          if (combo.isAlertRenderingStopped) {
            this.alerts = [];
            return;
          }

          if (!combo.isUserActive) {
            return;
          }

          subscribe();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._alertService
      .alertRenderingStopped
      .pipe(
        tap(isStopped => {
          unsubscribe();

          if (isStopped) {
            this.alerts = [];
            return;
          }

          subscribe();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  private _processNotifications(notifications: Array<Notification<AlertNotification>>): Array<AlertNotification> {
    const alerts = [];

    if (!notifications || !notifications.length) {
      return alerts;
    }

    for (let i = 0, num = notifications.length; i < num; i++) {
      const notification = notifications[i];

      const alert = notification.payload;
      if (!alert) {
        continue;
      }

      alert
        .reference
        .showing
        .pipe(
          tap(() => {
            setTimeout(
              () => alert.reference.fireShown(),
              AlertContainerComponent._alertAnimationEnterDuration
            );
          }),
          take(1),
          takeUntil(
            race(this._destroy, notification.dequeued),
          ),
        )
        .subscribe();

      alert
        .reference
        .hiding
        .pipe(
          tap(() => {
            setTimeout(
              () => alert.reference.fireHidden(),
              AlertContainerComponent._alertAnimationLeaveDuration
            );
          }),
          take(1),
          takeUntil(
            race(this._destroy, notification.dequeued),
          ),
        )
        .subscribe();

      alert
        .reference
        .hidden
        .pipe(
          tap(() => {
            const indexOfAlert = this.alerts.indexOf(alert);
            if (indexOfAlert < 0) {
              return;
            }

            this.alerts.splice(indexOfAlert, 1);

            this._alertManager.dequeueAlertNotification(alert);
          }),
          take(1),
          takeUntil(
            race(this._destroy, notification.dequeued),
          ),
        )
        .subscribe();

      alerts.unshift(alert);
    }

    return alerts;
  }
}
