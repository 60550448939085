import * as models from '@statera/sdk/common';

export * from '../message.model';

export type ChatProfile = models.IChatProfileViewModel;

export type LeaseTermComment = models.ILeaseTermCommentViewModel;

export type TermCommentType = models.TermCommentType;
export const TermCommentType = models.TermCommentType;
