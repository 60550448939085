<div class="header-badge">
  <span class="header-badge-item header-badge-item"
        *ngIf="project && project.projectStatus !== ProjectStatus.Completed"
        [class]="className ? 'header-badge-item-' + className : 'header-badge-item-default'">
    {{getProjectName(project)}}
  </span>
  <span class="header-badge-item header-badge-item-completed"
        *ngIf="project && project.projectStatus === ProjectStatus.Completed">
    {{getCompletedProjectName(project)}}
  </span>
  <span class="header-badge-item header-badge-item-danger"
        *ngIf="project && project.projectStatus === ProjectStatus.Cancelled">
    Cancelled
  </span>
  <span class="header-badge-item header-badge-item-danger"
        *ngIf="project && project.projectStatus === ProjectStatus.Rejected">
    Rejected
  </span>
  <span class="header-badge-item header-badge-item-info"
        *ngFor="let badge of badges">
    {{ badge }}
  </span>

  <app-awaiting-lease-upload-badge [project]="project">
  </app-awaiting-lease-upload-badge>
</div>
