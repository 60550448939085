import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js';

import * as models from '../../../infrastructure/models/generated';

import { DealsByStageReportChartType } from '../../models/deals-by-stage-report-tooltip.model';
import { DealsByStageReportSingleStageTooltip } from '../../models/deals-by-stage-report-single-stage-tooltip.model';

import { AnalyticsTooltipService } from '../../services/analytics-tooltip.service';

import { DealsByStageReportSingleStageTooltipComponent } from '../deals-by-stage-report-single-stage-tooltip/deals-by-stage-report-single-stage-tooltip.component';

interface ChartData {
  tenant: Array<number>;
  landlord: Array<number>;
}

type TooltipComponentType = DealsByStageReportSingleStageTooltipComponent;
type TooltipModelType = DealsByStageReportSingleStageTooltip;

const DEAL_COUNTS_KEYS: Array<keyof models.IDealCountsInfo> = ['all', 'closed', 'active', 'dead'];

@Component({
  selector: 'app-analytics-deals-by-stage-report-single-stage',
  templateUrl: 'deals-by-stage-report-single-stage.component.html',
  styleUrls: ['deals-by-stage-report-single-stage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsByStageReportSingleStageComponent implements OnInit {
  @ViewChild('canvasElementRef') canvasElementRef: ElementRef;

  @Input() set dealsByStageReportLeaseStage(value: models.IDealsByStageReportLeaseStageViewModel) {
    this._dealsByStageReportLeaseStage = value;

    this._loadChartData();
  }

  get dealsByStageReportLeaseStage(): models.IDealsByStageReportLeaseStageViewModel {
    return this._dealsByStageReportLeaseStage;
  }

  barChartOptions: ChartOptions<'bar'>;
  barChartData: ChartConfiguration<'bar'>['data'];

  private _dealsByStageReportLeaseStage: models.IDealsByStageReportLeaseStageViewModel;
  private _tooltipComponent: TooltipComponentType;

  private readonly _analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>;

  constructor(analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>) {
    this._analyticsTooltipService = analyticsTooltipService;
  }

  ngOnInit(): void {
    this.barChartOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            color: '#DCDFE5',
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 5,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 10,
          },
          suggestedMin: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          external: (x) => this._externalTooltipHandler(x.tooltip),
        },
      },
    };

    this._loadChartData();
  }

  private _loadChartData() {
    this.barChartData = {
      labels: ['All Deals', 'Closed Deals', 'Active Deals', 'Dead Deals'],
      datasets: [],
    };

    const chartData = this._getChartData();
    if (!chartData) {
      this.barChartData.datasets = [];
      return;
    }

    this.barChartData.datasets = [
      {
        data: chartData.tenant,
        label: 'Tenant',
        backgroundColor: 'rgba(118, 129, 243, 1)',
        hoverBackgroundColor: 'rgba(84, 97, 240, 1)',
        borderColor: 'rgba(255, 255, 255, 0)',
        hoverBorderColor: 'rgba(255, 255, 255, 0)',
        borderWidth: 3,
        barThickness: 15,
        borderSkipped: false,
        borderRadius: [
          { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
          { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
        ],
      },
      {
        data: chartData.landlord,
        label: 'Landlord',
        backgroundColor: 'rgba(240, 156, 148, 1)',
        hoverBackgroundColor: 'rgba(231, 90, 77, 1)',
        borderColor: 'rgba(255, 255, 255, 0)',
        hoverBorderColor: 'rgba(255, 255, 255, 0)',
        borderWidth: 3,
        barThickness: 15,
        borderSkipped: false,
        borderRadius: [
          { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
          { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
        ],
      },
    ];
  }

  private _getChartData(): ChartData {
    if (!this._dealsByStageReportLeaseStage || !this._dealsByStageReportLeaseStage.dealCountsInfo) {
      return;
    }

    const {dealCountsInfo} = this._dealsByStageReportLeaseStage;

    return {
      tenant: [
        dealCountsInfo.all ? (dealCountsInfo.all.tenant || 0) : 0,
        dealCountsInfo.closed ? (dealCountsInfo.closed.tenant || 0) : 0,
        dealCountsInfo.active ? (dealCountsInfo.active.tenant || 0) : 0,
        dealCountsInfo.dead ? (dealCountsInfo.dead.tenant || 0) : 0,
      ],
      landlord: [
        dealCountsInfo.all ? (dealCountsInfo.all.landlord || 0) : 0,
        dealCountsInfo.closed ? (dealCountsInfo.closed.landlord || 0) : 0,
        dealCountsInfo.active ? (dealCountsInfo.active.landlord || 0) : 0,
        dealCountsInfo.dead ? (dealCountsInfo.dead.landlord || 0) : 0,
      ],
    };
  }

  private _externalTooltipHandler(tooltipModel: TooltipModel<'bar'>): void {
    if (this.canvasElementRef && this.canvasElementRef.nativeElement) {
      this.canvasElementRef.nativeElement.classList.remove('cursor-pointer');
    }

    if (!tooltipModel.getActiveElements().length) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    if (this._tooltipComponent) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
    }

    if (
      !tooltipModel || !tooltipModel.dataPoints || !tooltipModel.dataPoints.length ||
      !this._dealsByStageReportLeaseStage || !this._dealsByStageReportLeaseStage.dealCountsInfo ||
      !this.canvasElementRef || !this.canvasElementRef.nativeElement
    ) {
      return;
    }

    this.canvasElementRef.nativeElement.classList.add('cursor-pointer');

    const dataPoints = tooltipModel.dataPoints[0];
    if (!dataPoints) {
      return;
    }

    const activeItemIndex = dataPoints.dataIndex;
    const activeDataSetIndex = dataPoints.datasetIndex;

    const countsInfo = this._dealsByStageReportLeaseStage.dealCountsInfo[DEAL_COUNTS_KEYS[activeItemIndex]];
    if (!countsInfo) {
      return;
    }

    const {x, y, height, base, horizontal} = <any>tooltipModel.getActiveElements()[0].element;

    if (horizontal) {
      tooltipModel.x = tooltipModel.caretX = <number>base + (<number>x - <number>base) / 2;
      tooltipModel.y = tooltipModel.caretY = <number>y + <number>height / 2;
    } else {
      tooltipModel.x = tooltipModel.caretX = <number>x;
      tooltipModel.y = tooltipModel.caretY = <number>base;
    }

    this._tooltipComponent = this._analyticsTooltipService.show(
      DealsByStageReportSingleStageTooltipComponent,
      {
        chartType: DealsByStageReportChartType[DealsByStageReportChartType[activeDataSetIndex]],
        dealGroupName: <string>this.barChartData.labels[activeItemIndex],
        daysTakenByLandlord: countsInfo.landlord,
        daysTakenByTenant: countsInfo.tenant,
      },
      {
        tooltipModel: tooltipModel,
        canvasElement: this.canvasElementRef.nativeElement,
      },
    );
  }
}
