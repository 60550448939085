import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { InvitationManager } from '@statera/sdk/invitation';

import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'accept-invitation.component.html',
  styleUrls: ['accept-invitation.component.scss'],
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {
  LeaseUserRoles = models.LeaseUserRole;
  ProjectTypes = models.ProjectTypeEnum;
  UserInvitationRequestKinds = models.UserInvitationRequestKind;

  acceptInvitation: models.IAcceptInvitationViewModel & {confirmPassword: string};

  isUserAuthenticated: boolean;

  userInvitationInfo$: Observable<models.IUserInvitationInfoViewModel>;

  isAutoAccept: boolean;

  isLoading: boolean;

  error: string;

  private _protectedToken: string;

  private readonly _invitationManager: InvitationManager;
  private readonly _authService: AuthService;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;

  private readonly _destroy$: Subject<void>;

  constructor(
    invitationService: InvitationManager,
    authService: AuthService,
    activatedRoute: ActivatedRoute,
    router: Router,
  ) {
    this._invitationManager = invitationService;
    this._authService = authService;
    this._activatedRoute = activatedRoute;
    this._router = router;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.acceptInvitation = <models.IAcceptInvitationViewModel & {confirmPassword: string}>{};

    this._activatedRoute
      .queryParamMap
      .pipe(
        tap(params => {
          this._protectedToken = params.get('token');
          this.isAutoAccept = params.get('autoAccept') === 'true';
        }),
        switchMap(() => this._authService.isAuthenticatedUser),
        tap(isAuthenticated => {
          this.isUserAuthenticated = isAuthenticated;
          this.userInvitationInfo$ = this._invitationManager.getUserInvitationInfo(this._protectedToken);

          if (this.isAutoAccept) {
            this._invitationManager
              .acceptInvitation({
                ...this.acceptInvitation,
                protectedToken: this._protectedToken,
              })
              .pipe(
                tap(() => {
                  window.location.reload();
                }),
                take(1),
                takeUntil(this._destroy$),
              )
              .subscribe();
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getInvitationFromClause(userInvitationInfo: models.IUserInvitationInfoViewModel): string {
    const invitedByFullName = `${userInvitationInfo.invitedByUser?.firstName} ${userInvitationInfo.invitedByUser?.lastName}`;

    switch (userInvitationInfo.leaseUserRole) {
      case models.LeaseUserRole.Tenant:
        return userInvitationInfo.invitedByUser?.company?.name || invitedByFullName;
      case models.LeaseUserRole.Broker:
        return userInvitationInfo.relatedToCompany?.name || invitedByFullName;
      case models.LeaseUserRole.CoBroker:
        return invitedByFullName;
      default:
        return invitedByFullName;
    }
  }

  getLeaseProcessClause(userInvitationInfo: models.IUserInvitationInfoViewModel): string {
    switch (userInvitationInfo.project?.projectTypeId) {
      case models.ProjectTypeEnum.Renewal:
        return 'lease renewal';
      case models.ProjectTypeEnum.Restructure:
        return 'lease restructure';
      case models.ProjectTypeEnum.NewDeal:
        return 'new lease';
      default:
        return 'lease negotiation';
    }
  }

  submit(form: NgForm, userInvitationInfo: models.IUserInvitationInfoViewModel): void {
    if
    (
      form.invalid ||
      (
        userInvitationInfo.shouldResetPassword &&
        this.acceptInvitation.confirmPassword !== this.acceptInvitation.userPassword
      )
    ) {
      return;
    }

    this.isLoading = true;

    this._invitationManager
      .acceptInvitation({
        ...this.acceptInvitation,
        protectedToken: this._protectedToken,
      })
      .pipe(
        tap((result) => {
          if (result && result.requiresTwoFactor) {
            const redirectAfterRoute = `/accept-invitation?autoAccept=true&token=${this._protectedToken}`;
            this._router
              .navigate(
                ['/2fa'],
                {
                  queryParams: { redirectAfter: encodeURIComponent(redirectAfterRoute) },
                },
              );
            return;
          }

          window.location.reload();
        }),
        tap(() => this.isLoading = false),
        catchError(error => {
          this.isLoading = false;

          this.error = error.error || error.message || error.responseText;

          console.error(error);

          return throwError(error);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
