import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './term.model';

@Injectable()
export class TermRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  saveLeaseTerm(
    leaseTerm: models.LeaseTerm,
    leaseId: number = null,
  ): Observable<void> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString());

    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/term/LeaseTerm`,
        leaseTerm,
        {
          params,
        },
      );
  }

  setPreviousLeaseTermValue(previousLeaseTermViewModel: models.PreviousLeaseTerm): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/term/SetPreviousTermValue`,
        previousLeaseTermViewModel,
      );
  }

  getLeaseFloorPlans(leaseId: number): Observable<Array<models.LeaseFloorPlan>> {
    const params = new HttpParams()
      .set('id', leaseId.toString());

    return this._httpClient
      .get<Array<models.LeaseFloorPlan>>(
        `${this._configuration.apiEndpoint}/floorplan/GetLeaseFloorPlans`,
        {
          params,
        },
      );
  }

  getStageHistoryRecords(leaseId: number): Observable<Array<models.StageHistoryRecord>> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString());

    return this._httpClient
      .get<Array<models.StageHistoryRecord>>(
        `${this._configuration.apiEndpoint}/term/GetStageHistoryRecords`,
        {
          params,
        },
      );
  }

  getCommentProfiles(
    leaseId: number,
    termCommentType: models.TermCommentType,
  ): Observable<Array<models.Profile>> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString())
      .set('termCommentType', termCommentType.toString());

    return this._httpClient
      .get<Array<models.Profile>>(
        `${this._configuration.apiEndpoint}/terms/comment-profiles`,
        {
          params,
        },
      );
  }

  validateDuplicateLeaseTerm(leaseTerm: models.LeaseTerm, leaseId: number): Observable<boolean> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString());

    return this._httpClient
      .post<boolean>(
        `${this._configuration.apiEndpoint}/term/ValidateDuplicateLeaseTerm`,
        leaseTerm,
        {
          params,
        },
      );
  }

  getMarketRentalRate(leaseId: number): Observable<models.MarketRentalRate> {
    const params = new HttpParams()
      .set('leaseId', leaseId.toString());

    return this._httpClient
      .get<models.MarketRentalRate>(
        `${this._configuration.apiEndpoint}/term/GetMarketRentalRate`,
        {
          params,
        },
      );
  }
}
