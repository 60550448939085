import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-self-help',
  templateUrl: './self-help.component.html',
  styleUrls: ['./self-help.component.scss'],
})
export class SelfHelpComponent extends BaseTermDirective<models.ISelfHelpTermViewModel> implements OnInit {
  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  options: any = [];

  SelfHelpTermType = models.SelfHelpTermType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  ngOnInit() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ISelfHelpTermViewModel>{};
    }
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.selfHelp && this.leaseTerm.selfHelpTermType === models.SelfHelpTermType.Custom) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.leaseTerm.selfHelpTermType === models.SelfHelpTermType.PerCurrentLease) {
      this.leaseTerm.selfHelp = this.leaseTerm.abstractValue;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'None',
          value: models.SelfHelpTermType.None,
        },
        {
          name: (
            'Tenant requires a Landlord Default provision, which provision shall include a Tenant Self Help remedy. ' +
            'Tenant must have the right to cure the default and offset rent for the cost thereof'
          ),
          value: models.SelfHelpTermType.TenantRequiresLandlordProvision,
        },
        {
          name: 'Custom',
          value: models.SelfHelpTermType.Custom,
        },
      ];
      return;
    }
    this.options = [
      {
        name: 'Per current lease',
        value: models.SelfHelpTermType.PerCurrentLease,
      },
      {
        name: 'None',
        value: models.SelfHelpTermType.None,
      },
      {
        name: (
          'Tenant requires a Landlord Default provision, which provision shall include a Tenant Self Help remedy. ' +
          'Tenant must have the right to cure the default and offset rent for the cost thereof'
        ),
        value: models.SelfHelpTermType.TenantRequiresLandlordProvision,
      },
      {
        name: 'Custom',
        value: models.SelfHelpTermType.Custom,
      },
    ];
  }

}
