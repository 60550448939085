<section class="w-comparison">
  <div class="w-heading">
    <h1 class="b-title">Comparison Summary Report - Renewal</h1>
    <dx-button icon="fa fa-file-pdf-o" hint="Export to PDF" (onClick)="exportToPdfClick()"></dx-button>
  </div>
</section>

<div class="table-responsive">
  <table *ngIf="items?.length" class="b-table-comparison table" [ngClass]="{'table-fixed': items?.length < 4}">
    <thead>
    <tr>
      <th class="card--cell timeline--cell">
        <a [routerLink]="['/lease-analysis/' ]" [queryParams]="{leaseId : leaseId}" class="back-link"><span
          class="icon-backward"></span>Lease Analysis</a>

        <section class="b-building-card">
          <div class="b-building-card-inner">
            <ng-container *ngIf="selectedLease?.building?.address as buildingAddress">
              <p class="b-building-card-title">
                {{[buildingAddress.addressLine1, buildingAddress.addressLine2] | separatedList}}
              </p>
              <p class="b-building-card-subtitle">
                {{[buildingAddress.city, buildingAddress.stateCode, buildingAddress.zipCode] | separatedList}}
              </p>
            </ng-container>
          </div>
          <figure class="b-building-card-media">
            <img alt="" class="img-fluid"
                 [src]="buildingHelper.buildingPicture(selectedLease?.building)" />
          </figure>

          <div class="b-building-card-inner">
            <p class="b-building-card-subtitle">
              Unit:
              {{[(selectedLease?.buildingUnit?.name || 'N/A'), ((getTenantSquareFootage(selectedLease) || 0) | safeNumber) + ' SF'] | separatedList: '| '}}
              <ng-container
               *ngIf="selectedLease?.tenantCompany as tenantCompany">
                | {{tenantCompany.name}}
              </ng-container>
            </p>

          </div>
        </section>
      </th>

      <th class="card--cell timeline--cell"
          [attr.colspan]="containsMultipleOptions() ? getNumberOfMultipleOptions() * 4 + (items.length - 1) * 2 * 2 : items.length * 2 * 2">
        <div class="w-steps">
          <div *ngFor="let item of items" class="b-step"
               [class.large]="1 < item.items.length">
            <div class="b-step-count">
              {{getProjectTemplateNumber(item?.projectTemplateItem)}}
            </div>
            <div class="b-step-text">
              {{getProjectTemplateName(item?.projectTemplateItem)}}
            </div>
          </div>
        </div>
      </th>
      <th class="card--cell timeline--cell existing-lease--cell" colspan="2"
          *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
        <div class="existing-lease text-center">
          <p class="mb-0" *ngIf="getLeaseAnalyticsTypeCompareWith() === LeaseAnalyticsType.AbstractLease">Current Lease</p>
          <p class="mb-0" *ngIf="getLeaseAnalyticsTypeCompareWith() === LeaseAnalyticsType.ThirdPartyLease">3rd Party Lease</p>
          <p class="mb-0">Based on {{getTenantSquareFootage(leaseAnalysisResult?.lease) || 0}} SF</p>

          <p class="my-2 text-info"
               *ngIf="!leaseAnalysisResult.isReTaxesSet || !leaseAnalysisResult.isOpExpensesSet">
            Information in this section does not include
            {{getReTaxesAndOpExpensesMessage(leaseAnalysisResult)}}
          </p>
          <p class="my-2 text-info"
             *ngIf="leaseAnalysisResult.isReTaxesSet && leaseAnalysisResult.isOpExpensesSet">
            RE Taxes and OpEx used here are estimates
          </p>
        </div>
      </th>
    </tr>
    </thead>

    <tbody>
      <tr>
        <td class="date--cell"
            [attr.rowspan]="containsMultipleOptions() ? 2 : 1">
          <h2 class="b-title">Proposal Date</h2>
        </td>

        <td class="date--cell" rowspan="1"
            *ngFor="let item of items; let index = index;"
            [attr.colspan]="2 * item.items.length * 2">
          <ng-container *ngIf="index === 0 && getLeaseWhichWeCompareWith()">
            <div>
              <button type="button" class="b-btn-icon"
                      (click)="$event.preventDefault()" #existingLeaseTooltip>
                <span class="icon-info"></span>
              </button>
              <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                          [width]="250" [target]="existingLeaseTooltip">
                <div class="d-flex flex-column tooltip-menu">
                  All net changes displayed for this stage are reflected from Existing Lease figures.
                </div>
              </dx-tooltip>
            </div>
          </ng-container>
          {{(item?.proposalDate) | safeDate: 'MMM d, y'}}
        </td>

        <td class="date--cell" colspan="2"
            [attr.rowspan]="containsMultipleOptions() ? 2 : 1"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          Existing Lease
        </td>
      </tr>

      <tr class="group-title--row"
          *ngIf="containsMultipleOptions()">
        <ng-container *ngFor="let item of items">
          <td class="multiple-options-header regular--cell" colspan="4"
              *ngFor="let option of item.items; let index = index;">
            <ng-container *ngIf="1 < item.items.length">
              Option {{indexToOptionValue(index)}}
            </ng-container>

            <p class="mb-0 fw-500 text-15">
              Based on {{getTenantSquareFootage(option.lease)}} SF
            </p>
          </td>
        </ng-container>
      </tr>

      <tr class="group-title--row headers--row">
        <td>
          Cash flow metrics
        </td>

        <ng-container *ngFor="let item of items; let i=index;">
          <ng-container *ngFor="let item of item.items;">
            <td>$</td>
            <td>+/- Change</td>
            <td>{{getUnitMetricsDisplayValue(item?.unitMetrics)}}</td>
            <td>+/- Change</td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <td>$</td>
          <td>{{getUnitMetricsDisplayValue(leaseAnalysisResult.unitMetrics)}}</td>
        </ng-container>
      </tr>

      <tr>
        <td>
          Total Cash Flow
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.totalCashFlow">
              <td>
                {{getFormattedValue(item.totalCashFlow)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDifferenceInPercent]="item.valuesDirection?.totalCashFlowValueDirection?.differenceInPercent"
                  [valueDirection]="item.valuesDirection?.totalCashFlowValueDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                {{item.psfTotalCashFlow | safeNumber: psfNumberFormat}}
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.psfTotalCashFlowValueDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.psfTotalCashFlowValueDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
            <ng-container *ngIf="!item.totalCashFlow">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.totalCashFlow; else noTotalCashFlow">
            <td>
              {{getFormattedValue(leaseAnalysisResult.totalCashFlow)}}
              <ng-container *ngIf="leaseAnalysisResult.remainingCashFlow">
                ({{getFormattedValue(leaseAnalysisResult.remainingCashFlow)}} – Remaining)
              </ng-container>
            </td>
            <td class="regular--cell">
              {{leaseAnalysisResult.psfTotalCashFlow | safeNumber: psfNumberFormat}}
            </td>
          </ng-container>
          <ng-template #noTotalCashFlow>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Present Value
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.presentValue">
              <td>
                {{getFormattedValue(item.presentValue)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDifferenceInPercent]="item.valuesDirection?.presentValueDirection?.differenceInPercent"
                  [valueDirection]="item.valuesDirection?.presentValueDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                {{item.psfPresentValue | safeNumber: psfNumberFormat}}
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.presentValuePsfDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.presentValuePsfDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
            <ng-container *ngIf="!item.presentValue">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.presentValue; else noPresentValue">
            <td>
              {{getFormattedValue(leaseAnalysisResult.presentValue)}}
            </td>
            <td class="regular--cell">
              {{leaseAnalysisResult.psfPresentValue | safeNumber: psfNumberFormat}}
            </td>
          </ng-container>
          <ng-template #noPresentValue>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Average Cash Flow / Yr
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.averageCashFlow">
              <td>
                {{getFormattedValue(item.averageCashFlow)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDifferenceInPercent]="item.valuesDirection?.averageCashFlowDirection?.differenceInPercent"
                  [valueDirection]="item.valuesDirection?.averageCashFlowDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                {{item.psfAverageCashFlow | safeNumber: psfNumberFormat}}
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.psfAverageCashFlowDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.psfAverageCashFlowDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
            <ng-container *ngIf="!item.averageCashFlow">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.averageCashFlow; else noAverageCashFlow">
            <td>
              {{getFormattedValue(leaseAnalysisResult.averageCashFlow)}}
            </td>
            <td class="regular--cell">
              {{leaseAnalysisResult.psfAverageCashFlow | safeNumber: psfNumberFormat}}
            </td>
          </ng-container>
          <ng-template #noAverageCashFlow>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Average Monthly Gross Rent
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.averageMonthlyRate">
              <td>
                {{getFormattedValue(item.averageMonthlyRate)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDifferenceInPercent]="item.valuesDirection?.averageMonthlyRateDirection?.differenceInPercent"
                  [valueDirection]="item.valuesDirection?.averageMonthlyRateDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                N/A
              </td>
              <td class="regular--cell">
                N/A
              </td>
            </ng-container>
            <ng-container *ngIf="!item.averageMonthlyRate">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.averageMonthlyRate; else noAverageMonthlyRate">
            <td>
              {{getFormattedValue(leaseAnalysisResult.averageMonthlyRate)}} (Net)
            </td>
            <td class="regular--cell">
              N/A
            </td>
          </ng-container>
          <ng-template #noAverageMonthlyRate>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Year 1 Cash Flow
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.years.length > 0">
              <td>
                {{getFormattedValue(item.years[0].cashFlow)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDirection]="item.valuesDirection?.psfYearOneCashFlowDirection?.valueDirection"
                  [valueDifferenceInPercent]="item.valuesDirection?.yearOneCashFlowDirection?.differenceInPercent"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                {{(item.years[0].psfCashFlow) | safeNumber: psfNumberFormat}}
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.psfYearOneCashFlowDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.psfYearOneCashFlowDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.averageCashFlow; else noAverageCashFlow">
            <td>
              {{getFormattedValue(leaseAnalysisResult.years[0].cashFlow)}}
            </td>
            <td class="regular--cell">
              {{(leaseAnalysisResult.years[0].psfCashFlow) | safeNumber: psfNumberFormat}}
            </td>
          </ng-container>
          <ng-template #noAverageCashFlow>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Net Effective Rent / Yr
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.proposalEffectiveRent">
              <td>
                {{getFormattedValue(item.proposalEffectiveRent)}}
              </td>
              <td>
                <app-comparison-report-change-indicator
                  [valueDifferenceInPercent]="item.valuesDirection?.proposalEffectiveRateDirection?.differenceInPercent"
                  [valueDirection]="item.valuesDirection?.proposalEffectiveRateDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
              <td>
                {{(item.psfProposalEffectiveRate) | safeNumber: psfNumberFormat}}
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.psfProposalEffectiveRateDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.psfProposalEffectiveRateDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
            <ng-container *ngIf="!item.proposalEffectiveRent">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.proposalEffectiveRent; else noProposalEffectiveRent">
            <td>
              N/A
            </td>
            <td class="regular--cell">
              N/A
            </td>
          </ng-container>
          <ng-template #noProposalEffectiveRent>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr>
        <td>
          Value of Concessions
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items;">
            <ng-container *ngIf="item.valueOfConcessions">
              <td>
                N/A
              </td>
              <td>
                N/A
              </td>
              <td>
                ${{item.valueOfConcessions | safeNumber: psfNumberFormat}}/PSF
              </td>
              <td class="regular--cell">
                <app-comparison-report-change-indicator
                  [valueDifference]="item.valuesDirection?.valueOfConcessionsDirection?.differenceInValue"
                  [valueDirection]="item.valuesDirection?.valueOfConcessionsDirection?.valueDirection"
                >
                </app-comparison-report-change-indicator>
              </td>
            </ng-container>
            <ng-container *ngIf="!item.valueOfConcessions">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="regular--cell">-</td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="leaseAnalysisResult.valueOfConcessions; else noValueOfConcessions">
            <td>
              N/A
            </td>
            <td class="regular--cell">
              ${{leaseAnalysisResult.valueOfConcessions | safeNumber: psfNumberFormat}}/PSF
            </td>
          </ng-container>
          <ng-template #noValueOfConcessions>
            <td>-</td>
            <td class="regular--cell">-</td>
          </ng-template>
        </ng-container>
      </tr>

      <tr class="group-title--row">
        <td>
          Lease Terms
        </td>

        <ng-container *ngFor="let item of items; let i=index;">
          <ng-container *ngFor="let item of item.items;">
            <td colspan="2"></td>
            <td colspan="2">+/- Change</td>
          </ng-container>
        </ng-container>

        <td colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
        </td>
      </tr>

      <tr>
        <td>
          Service Type
        </td>

        <ng-container *ngFor="let item of items;">
          <td *ngFor="let item of item.items" class="regular--cell" colspan="4">
            {{getServiceType(item?.lease)}}
          </td>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{getServiceType(leaseAnalysisResult.lease)}}
        </td>
      </tr>

      <tr>
        <td>
          Term
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items">
            <td colspan="2">
              {{item?.lease?.term?.termValue}} Months
            </td>
            <td class="regular--cell" colspan="2">
              <app-comparison-report-change-indicator
                [valueDifference]="item.valuesDirection?.termDirection?.differenceInValue"
                [valueDirection]="item.valuesDirection?.termDirection?.valueDirection"
                [showColorDirection]="false"
                [numberFormat]="'1.0-0'"
              >
              </app-comparison-report-change-indicator>
            </td>
          </ng-container>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{leaseAnalysisResult?.lease?.term?.termValue}} Months
        </td>
      </tr>

      <tr>
        <td>
          Base Rent
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items">
            <td colspan="2">
              {{getStartingRate(item?.lease)}}
            </td>
            <td class="regular--cell" colspan="2">
              <app-comparison-report-change-indicator
                [valueDifferenceInMoney]="item.valuesDirection?.baseRentDirection?.differenceInValue"
                [valueDirection]="item.valuesDirection?.baseRentDirection?.valueDirection"
                [showColorDirection]="false"
              >
              </app-comparison-report-change-indicator>
            </td>
          </ng-container>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{getStartingRate(leaseAnalysisResult?.lease)}}
          <ng-container *ngIf="leaseAnalysisResult?.endingBaseRent">
            ({{getEndingRate(leaseAnalysisResult)}} – Ending)
          </ng-container>
        </td>
      </tr>

      <tr>
        <td>
          Free Rent
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items">
            <td colspan="2">
              {{getFreeRent(item?.lease)}}
            </td>
            <td class="regular--cell" colspan="2">
              <app-comparison-report-change-indicator
                [valueDifferenceInMoney]="item.valuesDirection?.freeRentDirection?.differenceInValue"
                [valueDirection]="item.valuesDirection?.freeRentDirection?.valueDirection"
                [showColorDirection]="false"
              >
              </app-comparison-report-change-indicator>
            </td>
          </ng-container>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{getFreeRent(leaseAnalysisResult?.lease)}}
        </td>
      </tr>

      <tr>
        <td>
          Escalations
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items" >
            <td colspan="2">
              {{getRentIncreases(item?.lease)}}
            </td>
            <td class="regular--cell" colspan="2">
              <app-comparison-report-change-indicator
                [valueDifference]="item.valuesDirection?.escalationDirection?.differenceInValue"
                [valueDirection]="item.valuesDirection?.escalationDirection?.valueDirection"
                [showColorDirection]="false"
              >
              </app-comparison-report-change-indicator>
            </td>
          </ng-container>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{getRentIncreases(leaseAnalysisResult?.lease)}}
        </td>
      </tr>

      <tr>
        <td>
          Tenant Improvements (PSF)
        </td>

        <ng-container *ngFor="let item of items">
          <ng-container *ngFor="let item of item.items">
            <td colspan="2">
              {{getImprovements(item?.lease)}}
            </td>
            <td class="regular--cell" colspan="2">
              <app-comparison-report-change-indicator
                [valueDifference]="item.valuesDirection?.tenantImprovementsDirection?.differenceInValue"
                [valueDirection]="item.valuesDirection?.tenantImprovementsDirection?.valueDirection"
                [showColorDirection]="false"
              >
              </app-comparison-report-change-indicator>
            </td>
          </ng-container>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          {{getImprovements(leaseAnalysisResult?.lease)}}
        </td>
      </tr>

      <tr>
        <td>
          Operating Expenses
        </td>

        <ng-container *ngFor="let item of items">
          <td *ngFor="let item of item.items" class="regular--cell" colspan="4">
            {{getOperationExp(item?.lease)}}
            <ng-container *ngIf="getBaseYearStopInfo(item?.lease) as baseYearStopInfo">
              - {{baseYearStopInfo}}
            </ng-container>
          </td>
        </ng-container>

        <td class="regular--cell" colspan="2"
            *ngIf="getLeaseWhichWeCompareWith() as leaseAnalysisResult">
          <ng-container *ngIf="getOperationExp(leaseAnalysisResult?.lease) as operatingExp; else noOperatingExp">
            {{operatingExp}} Operating Expenses
          </ng-container>
          <ng-template #noOperatingExp>
            N/A
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="alert alert-success" role="alert">
  Statera makes no guaranty as to the accuracy of the information in this report. The analysis herein should not be
  construed as investment, tax or legal advice and is subject to change and verification.
</div>
