import {
  Component,
  Input,
  OnDestroy,
  OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { of, ReplaySubject, zip } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as dx from 'devextreme-angular';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/common';
import { BuildingManager } from '@statera/sdk/building';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { Feature } from '@statera/sdk/feature-toggling';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { ImageViewerService } from '../../../image-viewer/services/image-viewer.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { ProjectService } from '../../../shared/services/project.service';
import { StateraUserClaimService } from '../../../auth/services/statera-user-claim.service';

import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';
import * as planViewerModels from '../../../plan-viewer/models/plan-viewer.model';
import * as imageViewerModels from '../../../image-viewer/models/image-viewer-image.model';

@Component({
  templateUrl: './site-plan.component.html',
  styleUrls: ['./site-plan.component.scss']
})
export class SitePlanComponent implements OnInit, OnDestroy {
  @ViewChild('planSidebarScrollviewElementRef') planSidebarScrollviewElementRef: dx.DxScrollViewComponent;

  @Input() building: models.IBuildingViewModel;

  startupInfo: models.IStartupInfoViewModel;
  plans: Array<models.IPlanViewModel>;

  selectedPlan: models.IPlanViewModel;
  selectedBuildingUnit: models.IBuildingUnitViewModel;

  buildingTypes: typeof models.BuildingType;
  buildingClasses: typeof models.BuildingClass;

  renewalOptionTermTypes: typeof models.RenewalOptionTermType;
  expansionOptionTermTypes: typeof models.ExpansionOptionTermType;

  readonly Feature: typeof Feature = Feature;

  get sitePlanImage(): planViewerModels.PlanViewerImage {
    if (!this.selectedPlan) {
      return null;
    }

    return {
      id: this.selectedPlan.id,
      imageUrl: this.selectedPlan.picture.url,
      angle: this.selectedPlan.angle,
      markers: this.selectedPlan
        .anchors
        .map(anchor => {
          let isOwnSpace: boolean;
          let isAvailableSpace: boolean;
          if (this.startupInfo && anchor.buildingUnit && anchor.buildingUnit.lease) {
            isOwnSpace = this.startupInfo.companyId === anchor.buildingUnit.lease.tenantCompany.id;
          }
          if (anchor.buildingUnit && anchor.buildingUnit.listing) {
            isAvailableSpace = true;
          }
          return <planViewerModels.PlanViewerMarkerRef>{
            id: anchor.id,
            title: anchor.name,
            description: anchor.description,
            isOwnSpace: isOwnSpace,
            isAvailableSpace: isAvailableSpace,
            buildingUnitId: anchor.buildingUnitId,
            buildingUnit: anchor.buildingUnit,
            x: anchor.x,
            y: anchor.y,
            width: anchor.width,
            height: anchor.height,
            points: anchor.points,
            type: planViewerModels.PlanViewerMarkerType[planViewerModels.PlanViewerMarkerType[anchor.anchorType]],
            color: this.getMarkerColor(anchor),
            area: anchor.buildingArea,
            images: this.getPlanAnchorImages(anchor),
            amount: anchor.amount
          };
        }),
    };
  }

  private readonly _buildingManager: BuildingManager;
  private readonly _leaseManager: LeaseManager;
  private readonly _authService: AuthService;
  private readonly _dialogRefService: DialogRefService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _imageViewerService: ImageViewerService;
  private readonly _leaseService: LeaseService;
  private readonly _renderer: Renderer2;
  private readonly _projectService: ProjectService;
  private readonly _projectManager: ProjectManager;
  private readonly _stateraUserClaimService: StateraUserClaimService;
  private readonly _router: Router;

  private readonly _destroy$: ReplaySubject<void>;

  constructor(
    buildingManager: BuildingManager,
    leaseManager: LeaseManager,
    authService: AuthService,
    dialogRefService: DialogRefService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    imageViewerService: ImageViewerService,
    leaseService: LeaseService,
    renderer: Renderer2,
    projectService: ProjectService,
    projectManager: ProjectManager,
    stateraUserClaimService: StateraUserClaimService,
    router: Router
  ) {
    this._buildingManager = buildingManager;
    this._leaseManager = leaseManager;
    this._authService = authService;
    this._dialogRefService = dialogRefService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._imageViewerService = imageViewerService;
    this._leaseService = leaseService;
    this._renderer = renderer;
    this._projectService = projectService;
    this._projectManager = projectManager;
    this._stateraUserClaimService = stateraUserClaimService;
    this._router = router;

    this._destroy$ = new ReplaySubject<void>(1);
  }

  ngOnInit(): void {
    this.buildingTypes = models.BuildingType;
    this.buildingClasses = models.BuildingClass;

    this.renewalOptionTermTypes = models.RenewalOptionTermType;
    this.expansionOptionTermTypes = models.ExpansionOptionTermType;

    zip(
      this._authService.infoLoadComplete,
      this._buildingManager.requestBuildingSitePlans(this.building.id),
    )
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(([startupInfo, sitePlans]) => {
        this.startupInfo = startupInfo;
        this.plans = sitePlans;
        this.selectedPlan = sitePlans[0];
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isLeaseRenewalOptionAvailable(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.isRenewalOptionAvailable(lease);
  }

  isLeaseExpansionOptionAvailable(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.isExpansionOptionAvailable(lease);
  }

  isLeaseExpirationInLessThanAYear(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.isExpirationInLessThanAYear(lease);
  }

  getMarkerColor(anchor: models.IPlanAnchorViewModel): planViewerModels.PlanViewerMarkerColor {
    if (!anchor.buildingUnit) {
      return planViewerModels.PlanViewerMarkerColor.Blue;
    }

    return anchor.buildingUnit.listing ?
      planViewerModels.PlanViewerMarkerColor.White :
      planViewerModels.PlanViewerMarkerColor.Gray;
  }

  getPlanAnchorImages(anchor: models.IPlanAnchorViewModel): Array<planViewerModels.PlanViewerMarkerImageRef> {
    if (!anchor || !anchor.images || !anchor.images.length) {
      return [];
    }

    return anchor.images.map((anchorImage) => {
      return <planViewerModels.PlanViewerMarkerImageRef>{
        imageViewerImage: {
          id: anchorImage.id,
          imageUrl: anchorImage.image.url,
          imageKind: imageViewerModels.ImageViewerImageKind[imageViewerModels.ImageViewerImageKind[anchorImage.imageKind]],
          markers: anchorImage.markers.map((anchorImageMarker) => {
            return <imageViewerModels.ImageViewerImageMarkerRef>{
              id: anchorImageMarker.id,
              x: anchorImageMarker.x,
              y: anchorImageMarker.y,
              text: anchorImageMarker.text,
            };
          }),
        },
      };
    });
  }

  handlePlanMarkerSelect(markerId: number): void {
    const selectedMarker = this.selectedPlan
      .anchors
      .find(x => (
        x.id === markerId
      ));

    if (selectedMarker && selectedMarker.buildingUnit) {
      this.selectedBuildingUnit = selectedMarker.buildingUnit;
    } else {
      this.selectedBuildingUnit = null;
    }

    this.planSidebarScrollviewElementRef.instance.scrollTo(0);
  }

  handlePlanChange({ value }: { value: models.IPlanViewModel }): void {
    this.selectedBuildingUnit = null;
  }

  close(): void {
    this._dialogRefService.hide();
  }

  submitRfp(button: HTMLButtonElement, selectedBuildingUnit: models.IBuildingUnitViewModel) {
    button.disabled = true;
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmRfpSubmitAlertText(),
    });

    alertReference
      .declined
      .pipe(
        tap(() => {
          this._renderer.removeAttribute(button, 'disabled');
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this._renderer.setAttribute(button, 'disabled', 'disabled');
        }),
        switchMap(() => this._projectManager.getActiveNewDealByBuildingUnitId(this.selectedBuildingUnit.id)),
        switchMap(activeDeal => {
          if (activeDeal) {
            // Convert the existing inquiry to a new deal
            return this._projectService.convertInquiryToNewDeal(activeDeal.id)
              .pipe(
                map(project => project.lease),
              );
          } else {
            // Create a new deal
            return this._leaseManager.createNewDeal(selectedBuildingUnit);
          }
        }),
        switchMap(lease => this._stateraUserClaimService.requestStateraUserClaims$()
          .pipe(
            map(() => lease),
          )),
        tap(lease => {
          this._renderer.removeAttribute(button, 'disabled');

          const urlTree = this._router
            .createUrlTree(
              [
                'v2',
                'wizard',
                lease.id
              ],
              {},
            );

          const url = this._router.serializeUrl(urlTree);

          window.location.href = url;

          this._dialogRefService.hide();
        }),
        catchError(err => {
          this._renderer.removeAttribute(button, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  sendInquiry(button: HTMLButtonElement, selectedBuildingUnit: models.IBuildingUnitViewModel) {
    button.disabled = true;
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmInquirySendAlertText(),
    });
    let _lease = null;

    alertReference
      .declined
      .pipe(
        tap(() => {
          this._renderer.removeAttribute(button, 'disabled');
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this._renderer.setAttribute(button, 'disabled', 'disabled');
        }),
        switchMap( x => this._leaseManager.createNewDealInquiryByBuildingUnit(selectedBuildingUnit)),
        tap(lease => _lease = lease),
        switchMap(lease => this._projectService.getProjectsByLeaseId(lease.id)),
        switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
        tap(() => {
          this._renderer.removeAttribute(button, 'disabled');

          const urlTree = this._router
            .createUrlTree(
              [
                'v2',
                'wizard',
                _lease.id
              ],
              {},
            );

          const url = this._router.serializeUrl(urlTree);

          window.location.href = url;

          this._dialogRefService.hide();
        }),
        catchError(err => {
          this._renderer.removeAttribute(button, 'disabled');

          this._alertService.pushErrorAlert({
            message: err.error,
          });

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  openFloorPlanViewer(button: HTMLButtonElement, selectedBuildingUnit: models.IBuildingUnitViewModel): void {
    button.disabled = true;

    const images = selectedBuildingUnit
      .floorPlans
      .map((floorPlan) => ({
        id: floorPlan.id,
        imageUrl: floorPlan.picture.url,
        imageKind: imageViewerModels.ImageViewerImageKind.Standard,
      }));

    const imageViewerRef = this._imageViewerService.show(
      images,
      {
        width: '95%',
        height: '95%',
        maxWidth: 1800,
        closeOnOutsideClick: false,
        showCloseButton: true,
        title: 'Floor Plan',
        activeIndex: 0,
        allowChangeMarkers: false,
        enableArrowNavigation: true,
        enableZoom: false,
      },
    );

    imageViewerRef.onHiding
      .pipe(
        tap(() => {
          button.disabled = false;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  isSubmitRfpButtonDisabled() {
    return this.startupInfo.role !== Role.Tenant;
  }
}
