import { Injectable } from '@angular/core';
import { emojis } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import * as moment from 'moment';

interface EmojiSupportCache extends Map<string, boolean> {
  has(unicode: string): boolean;
  get(unicode: string): boolean;
  set(unicode: string, supported: boolean): this;
}

interface EmojiSupportCacheStorage {
  cache: string;
  expiresOn: Date;
}

@Injectable()
export class EmojiSupportCacheService {
  static StorageItemKey = 'emoji-mart.support';

  private _cache: EmojiSupportCache;

  private _canvasContext: CanvasRenderingContext2D;

  private _canvasWidth: number;
  private _canvasHeight: number;

  constructor() {
    if (!navigator.userAgent.includes('jsdom')) {
      this._initCanvas();
      this._initCache();
    }
  }

  isEmojiSupported(unified: string): boolean {
    if (!this._cache) {
      return false;
    }

    if (this._cache.has(unified)) {
      return this._cache.get(unified);
    }

    const codePoints = unified.split('-').map(u => parseInt(`0x${u}`, 16));
    const emoji = String.fromCodePoint(...codePoints);

    const isEmojiSupported = this._isEmojiSupported(emoji);

    this._cache.set(unified, isEmojiSupported);
    try {
      const storage = this._getCacheStorage();

      this._setCacheStorage({
        cache: JSON.stringify([...this._cache]),
        expiresOn: (
          storage?.expiresOn ||
          moment().add(30, 'days').toDate()
        ),
      });
    } catch {}

    return isEmojiSupported;
  }

  private _initCanvas(): void {
    this._canvasContext = <CanvasRenderingContext2D>document
      .createElement('canvas')
      .getContext(
        '2d',
        {
          willReadFrequently: true,
        },
      );

    this._canvasHeight = 25;
    this._canvasWidth = 20;

    this._canvasContext.font = `${Math.floor(this._canvasHeight / 2)}px Arial, Sans-Serif`;
    this._canvasContext.textBaseline = 'top';
    this._canvasContext.canvas.width = this._canvasWidth * 2;
    this._canvasContext.canvas.height = this._canvasHeight;
  }

  private _initCache(): void {
    this._cache = new Map<string, boolean>();

    try {
      const storage = this._getCacheStorage();

      const now = moment();
      const expiresOn = moment(storage?.expiresOn);
      if (storage?.expiresOn && expiresOn.isAfter(now)) {
        this._cache = new Map(JSON.parse(storage.cache));
      } else {
        for (let i = 0; i < emojis.length; i++) {
          const emoji = emojis[i];

          const isEmojiSupported = this.isEmojiSupported(emoji.unified);

          this._cache.set(emoji.unified, isEmojiSupported);
        }

        this._setCacheStorage({
          cache: JSON.stringify([...this._cache]),
          expiresOn: moment().add(30, 'days').toDate(),
        });
      }
    } catch {}
  }

  private _getCacheStorage(): EmojiSupportCacheStorage {
    const storageItem = localStorage.getItem(EmojiSupportCacheService.StorageItemKey);
    return <EmojiSupportCacheStorage>JSON.parse(storageItem);
  }

  private _setCacheStorage(storage: EmojiSupportCacheStorage): void {
    const storageItem = JSON.stringify(storage);
    localStorage.setItem(EmojiSupportCacheService.StorageItemKey, storageItem);
  }

  // @see https://github.com/koala-interactive/is-emoji-supported
  private _isEmojiSupported(emoji: string): boolean {
    if (!this._canvasContext) {
      return false;
    }

    this._canvasContext.clearRect(0, 0, this._canvasWidth * 2, this._canvasHeight);

    // Draw in red on the left
    this._canvasContext.fillStyle = '#FF0000';
    this._canvasContext.fillText(emoji, 0, 22);

    // Draw in blue on right
    this._canvasContext.fillStyle = '#0000FF';
    this._canvasContext.fillText(emoji, this._canvasWidth, 22);

    const a = this._canvasContext.getImageData(0, 0, this._canvasWidth, this._canvasHeight).data;
    const count = a.length;
    let i = 0;

    // Search the first visible pixel
    // tslint:disable-next-line:curly
    for (; i < count && !a[i + 3]; i += 4);

    // No visible pixel
    if (i >= count) {
      return false;
    }

    // Emoji has immutable color, so we check the color of the emoji in two different colors
    // the result show be the same.
    const x = this._canvasWidth + ((i / 4) % this._canvasWidth);
    const y = Math.floor(i / 4 / this._canvasWidth);
    const b = this._canvasContext.getImageData(x, y, 1, 1).data;

    if (a[i] !== b[0] || a[i + 2] !== b[2]) {
      return false;
    }

    // Some emojis are a contraction of different ones, so if it's not
    // supported, it will show multiple characters
    if (this._canvasContext.measureText(emoji).width >= this._canvasWidth) {
      return false;
    }

    // Supported
    return true;
  }
}
