import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BuildingUnitListingRepository } from './building-unit-listing.repository';

import * as models from './building-unit-listing.model';

@Injectable()
export class BuildingUnitListingManager {
  private readonly _buildingUnitListingRepository: BuildingUnitListingRepository;

  constructor(buildingUnitListingRepository: BuildingUnitListingRepository) {
    this._buildingUnitListingRepository = buildingUnitListingRepository;
  }

  createBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._buildingUnitListingRepository
      .createBuildingUnitListing(buildingUnitListing);
  }

  updateBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._buildingUnitListingRepository
      .updateBuildingUnitListing(buildingUnitListing);
  }

  deleteBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._buildingUnitListingRepository
      .deleteBuildingUnitListing(buildingUnitListing);
  }
}
