import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager } from '@statera/sdk/auth';
import { UserManager } from '@statera/sdk/user';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { ProfileService } from '../../services/profile.service';

import * as models from '../../../infrastructure/models/generated';

import { AlertPreferencesComponent } from '../alert-settings/alert-preferences.component';
import {
  PhoneNumberVerifyReason,
  VerifyPhoneNumberDialogComponent
} from '../verify-phone-number-dialog/verify-phone-number-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private readonly _profileService: ProfileService;
  private readonly _authService: AuthService;
  private readonly _authManager: AuthManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogService: DialogService;
  private readonly _userManager: UserManager;

  primaryEmail: string;
  isLoading = false;

  InvestorType = models.InvestorType;
  activeTab = 'information';
  tabs = ['information', 'security', 'company', 'notification'];
  vm: models.IProfileViewModel;
  avatarUrl: string;
  displayName: string;
  role: string;
  logoSettings: models.ILogotypeSettings;
  NotificationsDisplay = models.NotificationsDisplay;
  EmailNotifications = models.EmailNotifications;
  isSwitchVisible = false;
  emailNotifications: boolean;
  notificationsDisplay: boolean;
  isTwoFactorAuthEnabled: boolean;

  deactivateAccountPopupVisible = false;

  private _destroy$ = new Subject();

  get isLandlord(): boolean {
    return this.role === 'Landlord';
  }

  constructor(
    profileService: ProfileService,
    authService: AuthService,
    authManager: AuthManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogService: DialogService,
    userManager: UserManager,
  ) {
    this._profileService = profileService;
    this._authService = authService;
    this._authManager = authManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogService = dialogService;
    this._userManager = userManager;
  }

  ngOnInit(): void {
    this._authService.infoLoadComplete.subscribe(x => {
      this.primaryEmail = x.primaryEmail;
      this.avatarUrl = this._authService.avatarUrl;
      this.displayName = this._authService.displayName;
      this.role = this._authService.role;
      this.primaryEmail = x ? x.primaryEmail : '';
      this.logoSettings = x.logotypeSettings;
      this.vm = this._profileService.emptyProfile;
      this.isTwoFactorAuthEnabled = x.twoFactorEnabled;
    });

    this._userManager
      .userChange$
      .pipe(takeUntil(this._destroy$))
      .subscribe((profile) => {
        this.vm = profile;

        if (profile) {
          this.emailNotifications = profile.emailNotifications === models.EmailNotifications.Enabled;
          this.notificationsDisplay = profile.notificationsDisplay === models.NotificationsDisplay.Enabled;
          this.isSwitchVisible = true;
        }
      });

    this._userManager
      .isLoading$
      .pipe(takeUntil(this._destroy$))
      .subscribe((isLoading) => this.isLoading = isLoading);

    this._userManager.loadUser();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  receiveName(event) {
    this.activeTab = event;
  }

  submit(form: NgForm) {
    if (form.invalid || this.isLoading) {
      return;
    }

    this._userManager
      .resetPasswordRequest(this.primaryEmail)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getResetPasswordLinkSentAlertText(),
        });
      });
  }

  deactivateAccount() {
    this._authManager.deactivate().subscribe(() => this._authService.logout());
    this.deactivateAccountPopupVisible = false;
  }

  onAlertSettingsButtonClicked($event) {
    this._dialogService.show(AlertPreferencesComponent, {
      title: 'Notification Preferences',
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 450,
      height: 700,
      maxHeight: 700,
      injectableData: {
        profile: this.vm
      },
    });
  }

  toggle2FA($event: { value: boolean }) {
    if (!$event.value) {
      if (this.vm.mobilePhoneConfirmed) {
        const alertReference = this._alertService.pushConfirmAlert({
          message: 'Are you sure you want to turn 2FA off?',
        });

        if (alertReference) {
          alertReference
            .confirmed
            .pipe(
              take(1),
              takeUntil(this._destroy$),
            )
            .subscribe(() => {
              this._userManager.setTwoFactorAuthEnabled(false);
              this.vm.mobilePhoneConfirmed = false;
            });

          alertReference
            .declined
            .pipe(
              take(1),
              takeUntil(this._destroy$),
            )
            .subscribe(() => {
              this.isTwoFactorAuthEnabled = true;
            });
        }
      }

      return;
    }

    if (this.vm.mobilePhone) {
      const dialogRef = this._dialogService.show(VerifyPhoneNumberDialogComponent, {
        title: 'SET UP TWO-FACTOR AUTHENTICATION',
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 900,
        height: 700,
        maxHeight: 700,
        injectableData: {
          user: this.vm,
          reason: PhoneNumberVerifyReason.TwoFactorAuth,
        },
      });

      dialogRef.onHiding
        .pipe(
          tap(() => this.isTwoFactorAuthEnabled = this.vm.mobilePhoneConfirmed),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }
}
