<div class="term-custom-value-template-dialog">
  <div class="term-custom-value-template-header">
    <div>
      <div class="term-custom-value-template-icon">
        <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-trash"></i>
      </div>
      <div class="term-custom-value-template-title">
        Choose from template
      </div>
    </div>
    <div>
      <button type="button" class="close-button"
              (click)="close()">
        <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-x-16"></i>
      </button>
    </div>
  </div>
  <div class="term-custom-value-template-content">
    <dx-scroll-view width="100%" height="100%">
      <div class="term-custom-value-template-content-inner">
        <div class="delete-question">
          Are you sure you want to delete this template?
        </div>
        <div class="delete-warning">
          this action can’t be undone
        </div>
      </div>
    </dx-scroll-view>
  </div>
  <div class="term-custom-value-template-footer">
    <button type="button" class="button-secondary"
            (click)="handleCancelClick()">
      Cancel
    </button>
    <button type="button" class="button-primary"
            (click)="handleDeleteClick()">
      Delete
    </button>
  </div>
</div>
