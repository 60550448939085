import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs = [];
  @Input() activeTab;
  @Output() name: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  clickTab(tab) {
    this.activeTab = tab;
    this.name.emit(tab);
  }
}
