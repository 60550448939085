import { ILeaseTermOptionsViewModel } from '@statera/sdk/common';
import * as models from '@statera/sdk/common';

export type RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;
export const RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;

export type Profile = models.IProfileViewModel;

export type LeaseFloorPlan = models.ILeaseFloorPlanViewModel;
export type StageHistoryRecord = models.IStageHistoryRecordViewModel;

export type Project = models.IProjectViewModel;
export type ProjectTypeEnum = models.ProjectTypeEnum;
export const ProjectTypeEnum = models.ProjectTypeEnum;

export type Term = models.ITermViewModel;
export type CommencementTerm = models.ICommencementTermViewModel;
export type TermCommentType = models.TermCommentType;
export const TermCommentType = models.TermCommentType;
export type TermStatus = models.TermStatus;
export const TermStatus = models.TermStatus;
export type TermTypeEnum = models.TermTypeEnum;
export const TermTypeEnum = models.TermTypeEnum;

export type Lease = models.ILeaseViewModel;
export type LeaseTerm = models.ILeaseTermViewModel & {lease?: Lease};
export type LeaseTermOptions<T> = models.ILeaseTermOptionsViewModel<T>;
export type LeaseTermType = models.LeaseTermType;
export const LeaseTermType = models.LeaseTermType;
export type LeaseTermConfiguration = models.ILeaseTermConfiguration;
export type LeaseTermCustomTable = models.ILeaseTermCustomTableViewModel;
export type LeaseTermCustomRow = models.ILeaseTermCustomRowViewModel;
export type LeaseTermCustomCell = models.ILeaseTermCustomCellViewModel;
export type PreviousLeaseTerm = models.IPreviousLeaseTermViewModel;

export type TenantSquareFootageTerm = models.ITenantSquareFootageTermViewModel;
export type TenantSquareFootageTermType = models.TenantSquareFootageTermType;
export const TenantSquareFootageTermType = models.TenantSquareFootageTermType;
export type TenantSquareFootagePhaseInResult = models.ITenantSquareFootagePhaseInResultViewModel;
export type TenantSquareFootagePhaseInValue = models.ITenantSquareFootagePhaseInValueViewModel;

export type BaseRentalRate = models.IBaseRentalRateViewModel;
export type BaseRentalRateType = models.BaseRentalRateType;
export const BaseRentalRateType = models.BaseRentalRateType;
export type BaseRentalRateUnitMetrics = models.BaseRentalRateUnitMetrics;
export const BaseRentalRateUnitMetrics = models.BaseRentalRateUnitMetrics;
export type BaseRentalScheduleRow = models.IBaseRentalScheduleRowViewModel;

export type FreeRentTerm = models.IFreeRentTermViewModel;
export type FreeRentTermType = models.FreeRentTermType;
export const FreeRentTermType = models.FreeRentTermType;
export type FreeRentTaxesType = models.FreeRentTaxesType;
export const FreeRentTaxesType = models.FreeRentTaxesType;

export type SecurityDepositTerm = models.ISecurityDepositTermViewModel;
export type SecurityDepositTermType = models.SecurityDepositTermType;
export const SecurityDepositTermType = models.SecurityDepositTermType;

export type RealEstateTaxesCamExpenses = models.IRealEstateTaxesCamExpensesViewModel;
export type RealEstateTaxesCamExpensesTermType = models.RealEstateTaxesCamExpensesTermType;
export const RealEstateTaxesCamExpensesTermType = models.RealEstateTaxesCamExpensesTermType;

export type RentalRateAnnualEscalationTerm = models.IRentalRateAnnualEscalationTermViewModel;
export type EscalationTermType = models.EscalationTermType;
export const EscalationTermType = models.EscalationTermType;
export type RentalRateAnnualEscalationTermCustomResult = models.IRentalRateAnnualEscalationTermCustomResult;
export type RentalRateAnnualEscalationTermCustomValue = models.IRentalRateAnnualEscalationTermCustomValueViewModel;

export type TenantImprovements = models.ITenantImprovementsViewModel;
export type TenantImprovementsType = models.TenantImprovementsType;
export const TenantImprovementsType = models.TenantImprovementsType;

export type LandlordMaintenanceTerm = models.ILandlordMaintenanceTermViewModel;
export type LandlordMaintenanceTermType = models.LandlordMaintenanceTermType;
export const LandlordMaintenanceTermType = models.LandlordMaintenanceTermType;

export type TenantMaintenanceTerm = models.ITenantMaintenanceTermViewModel;
export type TenantMaintenanceTermType = models.TenantMaintenanceTermType;
export const TenantMaintenanceTermType = models.TenantMaintenanceTermType;

export type SelfHelpTerm = models.ISelfHelpTermViewModel;
export type SelfHelpTermType = models.SelfHelpTermType;
export const SelfHelpTermType = models.SelfHelpTermType;

export type AssignmentTerm = models.IAssignmentTermViewModel;
export type AssignmentTermType = models.AssignmentTermType;
export const AssignmentTermType = models.AssignmentTermType;

export type RenewalOptionTerm = models.IRenewalOptionTermViewModel;
export type RenewalOptionTermType = models.RenewalOptionTermType;
export const RenewalOptionTermType = models.RenewalOptionTermType;

export type EstoppelCertificateTerm = models.IEstoppelCertificateTermViewModel;
export type EstoppelCertificateTermType = models.EstoppelCertificateTermType;
export const EstoppelCertificateTermType = models.EstoppelCertificateTermType;

export type TerminationOptionTerm = models.ITerminationOptionTermViewModel;
export type TerminationOptionType = models.TerminationOptionType;
export const TerminationOptionType = models.TerminationOptionType;
export type TerminationOptionFeeType = models.TerminationOptionFeeType;
export const TerminationOptionFeeType = models.TerminationOptionFeeType;

export type ExpansionOptionTerm = models.IExpansionOptionTermViewModel;
export type ExpansionOptionTermType = models.ExpansionOptionTermType;
export const ExpansionOptionTermType = models.ExpansionOptionTermType;

export type HvacTerm = models.IHvacTermViewModel;
export type HvacTermType = models.HvacTermType;
export const HvacTermType = models.HvacTermType;

export type InsuranceTerm = models.IInsuranceTermViewModel;
export type HoldoverProvisionTerm = models.IHoldoverProvisionTermViewModel;
export type ForceMajeureTerm = models.IForceMajeureTermViewModel;
export type ParkingTerm = models.IParkingTermViewModel;

export type MarketRentalRate = models.IMarketRentalRateViewModel;
export type BurnDownSchedule = models.IBurnDownScheduleViewModel;
export type RealEstateTaxesCamExpensesBreakdown = models.IRealEstateTaxesCamExpensesBreakdownViewModel;

export enum TermTypeOption {
  _12Months = '12Months',
  _24Months = '24Months',
  _36Months = '36Months',
  _60Months = '60Months',
  _84Months = '84Months',
  _120Months = '120Months',
  Custom = 'Custom',
  MultiplyOptions = 'Please provide multiple options',
  MultipleOptionsWithCustomValue = 'MultipleOptionsWithCustomValue',
}

export enum TermDisplayValueType {
  Original,
  Detailed,
}

export interface TermHistoryModel {
  uniqueValue: string;
  lease: models.ILeaseViewModel;
  role: string;
  createdOn: Date;
  shouldShowAcceptanceMark?: boolean;
}

export interface LeaseAndTermModel {
  termValue: string;
  lease: models.ILeaseViewModel;
  role: string;
  createdOn: Date;
}
