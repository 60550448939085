<div class="deals-by-stage-single-stage-tooltip"
     *ngIf="dealsByStageReportSingleStageTooltip"
     #tooltipElementRef>
  <table>
    <thead>
    <tr>
      <th>
        {{dealsByStageReportSingleStageTooltip.dealGroupName}}
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container [ngSwitch]="dealsByStageReportSingleStageTooltip.chartType">
      <tr *ngSwitchCase="DealsByStageReportSingleStageChartType.DaysTakenByTenant">
        <td>
          <div class="d-flex">
            <span class="value">{{dealsByStageReportSingleStageTooltip.daysTakenByTenant | safeNumber: '1.1-1'}}</span>
            <span class="caption">Days Taken by Tenant</span>
          </div>
        </td>
      </tr>
      <tr *ngSwitchCase="DealsByStageReportSingleStageChartType.DaysTakenByLandlord">
        <td>
          <div class="d-flex">
            <span class="value">{{dealsByStageReportSingleStageTooltip.daysTakenByLandlord | safeNumber: '1.1-1'}}</span>
            <span class="caption">Days Taken by Landlord</span>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
