import {  Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs/internal/observable/of';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';
import { AuthService } from '../../../auth/services/auth.service';

import { ProjectService } from '../../../shared/services/project.service';

import * as models from '../../../infrastructure/models/generated';

interface ProjectSelectItem {
  id: number;
  text: string;
  project: models.IProjectViewModel;
  lease: models.ILeaseViewModel;
  isExpired: boolean;
}

interface ProjectSelectItemGroup {
  key: string;
  items: Array<ProjectSelectItem>;
}

@Component({
  selector: 'app-schedule-projects',
  templateUrl: './schedule-projects.component.html',
  styleUrls: ['./schedule-projects.component.scss'],
})
export class ScheduleProjectsComponent implements OnInit, OnDestroy {
  @ViewChild('selectBox') selectBox: DxSelectBoxComponent;

  projects: Array<ProjectSelectItemGroup>;
  project: models.IProjectViewModel;
  projectStatistics: models.IProjectStatisticViewModel;

  isLoadingState: boolean;

  private readonly _location: Location;
  private readonly _activatedRouter: ActivatedRoute;
  private readonly _projectService: ProjectService;
  private readonly _router: Router;
  private readonly _destroy$: Subject<void>;
  private readonly _authService: AuthService;

  constructor(
    location: Location,
    activatedRouter: ActivatedRoute,
    projectService: ProjectService,
    router: Router,
    authService: AuthService,
  ) {
    this._location = location;
    this._activatedRouter = activatedRouter;
    this._projectService = projectService;
    this._router = router;
    this._destroy$ = new Subject<void>();
    this._authService = authService;
  }

  ngOnInit() {
    this._projectService
      .getStatisticsProjects()
      .pipe(
        switchMap((projects) => this._activatedRouter
          .paramMap
          .pipe(
            switchMap((paramMap) => {
              const projectId = parseInt(paramMap.get('id'), 10);

              let project = projects[0];
              if (projectId && !isNaN(projectId)) {
                project = projects.find(x => x.id === projectId);
              }

              if (!project) {
                return of(null);
              }

              this.isLoadingState = true;

              const now = moment().startOf('day');

              return fromPromise(this.navigate(project.id))
                .pipe(
                  switchMap(() =>
                    this._projectService
                      .getProjectStatistic(project.id)
                      .pipe(
                        tap(statistics => {
                          this.projects = projects
                            .map((proj) => {
                              let expirationDate;
                              if (proj.lease && proj.lease.calculatedExpirationDate) {
                                expirationDate = moment(proj.lease.calculatedExpirationDate).startOf('day');
                              }

                              return <ProjectSelectItem>{
                                id: proj.id,
                                text: this.getProjectItemName(proj.lease),
                                project: proj,
                                lease: proj.lease,
                                isExpired: proj.lease && proj.lease.isAbstractLease && now.isAfter(expirationDate),
                              };
                            })
                            .reduce(
                              (acc: Array<ProjectSelectItemGroup>, projectSelectItem) => {
                                const groupKey = this._getProjectGroupName(projectSelectItem.lease);

                                let indexOfGroupItem = acc.findIndex(x => x.key === groupKey);
                                if (indexOfGroupItem < 0) {
                                  indexOfGroupItem = acc.length;

                                  acc.push({key: groupKey, items: []});
                                }

                                acc[indexOfGroupItem].items.push(projectSelectItem);

                                return acc;
                              },
                              [],
                            )
                            .sort((a, b) => b.items[0].id - a.items[0].id);

                          this.project = project;
                          this.projectStatistics = statistics;

                          this.isLoadingState = false;
                        }),
                      )
                  ),
                );
            }),
          )
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  selectProject(e) {
    const projectId = e.itemData.id;
    this.navigate(projectId);
  }

  back() {
    this._location.back();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  navigate(projectId: number): Promise<boolean> {
    return this._router.navigate([this._authService.role.toLowerCase(), 'dashboard', 'project', projectId]);
  }

  private _getProjectGroupName(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return;
    }

    let buildingAddress = `Building #${lease.buildingId}`;
    if (lease.building && lease.building.address) {
      buildingAddress = lease.building.address.displayString;
    }

    let buildingUnitName: string;
    if (lease.buildingUnitId) {
      buildingUnitName = `Unit #${lease.buildingUnitId}`;
      if (lease.buildingUnit) {
        buildingUnitName = lease.buildingUnit.name;
      }
    }

    return [buildingAddress, buildingUnitName]
      .filter(Boolean)
      .join(', ');
  }

  getProjectItemName(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return;
    }

    let amendmentNumber = 'Original';
    if (0 < lease.amendmentNumber) {
      amendmentNumber = `Amendment ${lease.amendmentNumber}`;
    }

    return amendmentNumber;
  }

  getProjectSelectedName(projectSelectItem: ProjectSelectItem): string {
    if (!projectSelectItem || !projectSelectItem.project || !projectSelectItem.project.lease) {
      return;
    }

    const groupName = this._getProjectGroupName(projectSelectItem.project.lease);
    const itemName = this.getProjectItemName(projectSelectItem.project.lease);

    return [groupName, itemName]
      .filter(Boolean)
      .join(': ');
  }
}

