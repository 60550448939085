import { File } from '@statera/sdk/common';
import * as models from '@statera/sdk/common';

export type BuildingViewModel = models.IBuildingViewModel;

export type BuildingUnitViewModel = models.IBuildingUnitViewModel;

export type PlanViewModel = models.IPlanViewModel;

export type AvailableUnitListBoundingBox = [number, number, number, number];

export type DemographicsReport = models.IDemographicsReport;

export type AvailableUnitsForbiddenResult = models.IAvailableUnitsForbiddenResultViewModel;

export type AvailableUnitsForbiddenResultReason = models.AvailableUnitsForbiddenResultReason;
export const AvailableUnitsForbiddenResultReason = models.AvailableUnitsForbiddenResultReason;

export enum AvailableUnitListOrder {
  Asc = 1,
  Desc = 2,
}

export enum AvailableUnitListOrderBy {
  Address = 1,
  Size = 2,
}

export class AvailableUnitListOptions {
  boundingBox?: AvailableUnitListBoundingBox;
  order?: AvailableUnitListOrder;
  orderBy?: AvailableUnitListOrderBy;
  searchQuery?: string;
  companies?: Array<number>;
  markets?: Array<number>;
  subMarkets?: Array<number>;
  cities?: Array<string>;
  assetTypes?: Array<number>;
  classes?: Array<number>;
  availableSize?: [number, number];
  clearHeight?: [number, number];
  filterApplyingOrder?: Array<AvailableUnitFilterType>;
  polygonType?: string;
  polygon?: Array<[number, number]>;
}

export enum AvailableUnitSearchSuggestionType {
  Building = 1,
  Filter = 2,
}

export class AvailableUnitSearchSuggestion {
  suggestionType: AvailableUnitSearchSuggestionType;
  buildingId?: number;
  buildingPicture?: File;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  zipCode: string;
  geopointLatitude: number;
  geopointLongitude: number;
}

export class AvailableUnitListFilters {
  companies: Array<AvailableUnitCompanyFilter>;
  markets: Array<AvailableUnitMarketFilter>;
  subMarkets: Array<AvailableUnitSubMarketFilter>;
  cities: Array<AvailableUnitCityFilter>;
  buildingTypes: Array<AvailableUnitBuildingTypeFilter>;
  buildingClasses: Array<AvailableUnitBuildingClassFilter>;
  availableSizeRange: AvailableUnitAvailableSizeFilter;
  clearHeightRange: AvailableUnitClearHeightFilter;
}

export class AvailableUnitCompanyFilter {
  id: number;
  name: string;
  selected?: boolean;
  logo?: File;
}

export class AvailableUnitMarketFilter {
  id: number;
  name: string;
  selected?: boolean;
}

export class AvailableUnitSubMarketFilter {
  id: number;
  name: string;
  selected?: boolean;
}

export class AvailableUnitCityFilter {
  name: string;
  selected?: boolean;
}

export class AvailableUnitBuildingTypeFilter {
  groupName: string;
  assetTypes: Array<AvailableUnitBuildingTypeAssetTypeFilter>;
}

export class AvailableUnitBuildingTypeAssetTypeFilter {
  id: number;
  name: string;
  selected?: boolean;
}

export class AvailableUnitBuildingClassFilter {
  id: number;
  name: string;
  selected?: boolean;
}

export class AvailableUnitAvailableSizeFilter {
  min: number;
  max: number;
  currentMin?: number;
  currentMax?: number;

  constructor() {
    this.min = 0;
    this.max = 0;
    this.currentMin = 0;
    this.currentMax = 0;
  }
}

export class AvailableUnitClearHeightFilter {
  min: number;
  max: number;
  currentMin?: number;
  currentMax?: number;

  constructor() {
    this.min = 0;
    this.max = 0;
    this.currentMin = 0;
    this.currentMax = 0;
  }
}

export enum AvailableUnitFilterType {
  Company = 0,
  Market = 1,
  SubMarket = 2,
  City = 3,
  AssetType = 4,
  Class = 5,
  AvailableSize = 6,
  ClearHeight = 7,
}

export class AvailableUnitFilterBadge {
  name: string;
  remove?: () => void;
}
