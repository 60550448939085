import { Component, Input, Output, EventEmitter } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { AlertService as UIAlertService } from '../../../../alert/services/alert.service';

@Component({
  selector: 'app-term-action-buttons',
  templateUrl: './term-action-buttons.component.html',
  styleUrls: ['./term-action-buttons.component.scss'],
})
export class TermActionButtonsComponent {
  @Input() isAcceptButtonVisible: boolean;
  @Input() isCounterButtonVisible: boolean;
  @Input() isModifyButtonVisible: boolean;
  @Input() isChooseOptionButtonVisible: boolean;
  @Input() isPreviousButtonVisible: boolean;
  @Input() isOkButtonVisible: boolean;

  @Input() isButtonsDisabled: boolean;

  @Output() acceptButtonClicked: EventEmitter<void>;
  @Output() counterButtonClicked: EventEmitter<void>;
  @Output() modifyButtonClicked: EventEmitter<void>;
  @Output() chooseOptionClicked: EventEmitter<void>;
  @Output() previousButtonClicked: EventEmitter<void>;
  @Output() okButtonClicked: EventEmitter<void>;

  private readonly _uiAlertService: UIAlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _destroy$: Subject<void>;
  constructor(uiAlertService: UIAlertService, alertMessagesManager: AlertMessagesManager) {
    this._uiAlertService = uiAlertService;
    this._alertMessagesManager = alertMessagesManager;
    this.acceptButtonClicked = new EventEmitter<void>();
    this.counterButtonClicked = new EventEmitter<void>();
    this.modifyButtonClicked = new EventEmitter<void>();
    this.chooseOptionClicked = new EventEmitter<void>();
    this.previousButtonClicked = new EventEmitter<void>();
    this.okButtonClicked = new EventEmitter<void>();
    this._destroy$ = new Subject<void>();
  }

  handleAcceptButtonClick(event): void {
    event.preventDefault();

    this.acceptButtonClicked.emit();
  }

  handleCounterButtonClick(event): void {
    event.preventDefault();

    this.counterButtonClicked.emit();
  }

  handleModifyButtonClick(event): void {
    event.preventDefault();

    this.modifyButtonClicked.emit();
  }

  handleChooseOptionButtonClick(event): void {
    event.preventDefault();

    this.chooseOptionClicked.emit();
  }

  handlePreviousButtonClick(event): void {
    event.preventDefault();

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getPreviousButtonConfirmationMessage(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => this.previousButtonClicked.emit()),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handleOkButtonClick(event): void {
    event.preventDefault();

    this.okButtonClicked.emit();
  }
}
