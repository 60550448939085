import * as ng from '@angular/core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnChanges {
  private readonly _lastProjectTemplateItemType = models.RenewalProjectTemplateItemType.LeaseAmendmentSigned;

  templateItems: Array<models.IProjectTemplateItem> = [];
  steps = [];
  activeStepId = 0;

  private _info: models.IStartupInfoViewModel;

  tooltipVisible: Array<boolean> = [];

  @Input()
  project: models.IProjectViewModel;

  @Input() showTooltips = true;

  protected readonly _authService: AuthService;

  constructor(authService: AuthService) {
    this._authService = authService;
  }

  ngOnInit() {
    this._authService.infoLoadComplete.subscribe(info => {
      this._info = info;
      this._initializePage();
    });
  }

  toggleTooltip(i) {
    this.tooltipVisible[i] = !this.tooltipVisible[i];
  }

  getMilestoneInterval(i) {
    if (this.project && this.project.milestones) {
      const milestone = this.project.milestones.find(x => x.templateItemId === this.steps[i].id);
      if (milestone) {
        return `${new Date(milestone.startDate).toDateString()} - ${new Date(milestone.endDate).toDateString()}`;
      }
    }
    return '';
  }

  private _initializePage() {
    if (this._info) {
      this.templateItems = this._info.lists.templateItems;
      this.steps = this.templateItems
        .filter(x =>
          !x.parentProjectTemplateItem &&
          x.projectTypeId === this.project.projectTypeId
        )
        .sort((x, y) => x.sortOrder - y.sortOrder)
        .map(x => {
          return {
            id: x.id,
            name: x.name,
            description: x.description,
          };
        });
    }

    if (this.project && this.project.projectState) {

      const isOpenProject = this.project.projectState.renewalProjectTemplateItemType !== this._lastProjectTemplateItemType;
      if (isOpenProject) {
        if (this.project.projectState.parentProjectTemplateItem !== null) {
          this.activeStepId = this.project.projectState.parentProjectTemplateItem.id;
        } else {
          this.activeStepId = this.project.projectState.id;
        }
      } else {
        this.activeStepId = undefined;
      }
    } else {
      this.activeStepId = 0;
    }


  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (changes.project && changes.project.isFirstChange()) {
      this._initializePage();
      return;
    }

    if (
      changes.project &&
      changes.project.currentValue && changes.project.previousValue &&
      changes.project.currentValue.id !== changes.project.previousValue.id) {
      this._initializePage();
    }
  }
}
