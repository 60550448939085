import { Component, Input, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import { SecurityDepositBurnDownScheduleTableComponent } from './security-deposit-burn-down-schedule-table/security-deposit-burn-down-schedule-table.component';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';
import {FinancialsRequestManager} from '@statera/sdk/financials-request';

@Component({
  selector: 'app-security-deposit',
  templateUrl: './security-deposit.component.html',
  styleUrls: ['./security-deposit.component.scss'],
})
export class SecurityDepositComponent extends BaseTermDirective<models.ISecurityDepositTermViewModel> implements OnInit {
  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTermConfiguration: models.ILeaseTermConfiguration;
  @Input() project: models.IProjectViewModel;
  @Input() financialRequests: Array<models.ILeaseRequestViewModel>;

  SecurityDepositTermType = models.SecurityDepositTermType;
  SecurityGuarantyOptionType = models.SecurityGuarantyOptionType;

  options: any = [];
  beginningOrEndOfTermOptions = [
    {
      name: 'Beginning of Term',
      value: models.SecurityDepositMonths.BeginningOfTerm,
    },
    {
      name: 'End of Term',
      value: models.SecurityDepositMonths.EndOfTerm,
    }
  ];

  guarantyOptions: any = [
    {name: 'Corporate', value: models.SecurityGuarantyOptionType.Corporate},
    {name: 'Personal', value: models.SecurityGuarantyOptionType.Personal},
  ];

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  private readonly _dialogService: DialogService;
  private readonly _financialRequestManager: FinancialsRequestManager;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    financialRequestManager: FinancialsRequestManager,

    dialogService: DialogService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this._dialogService = dialogService;
    this._financialRequestManager = financialRequestManager;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.leaseTerm) {
      this.leaseTerm = <models.ISecurityDepositTermViewModel>{};
    }
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    const {securityDepositTermType} = this.leaseTerm;
    if (securityDepositTermType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (!this._validateBurnDownOption()) {
      this.addError('You should fill at least one value in burn down schedule table.');
      return;
    }

    if (securityDepositTermType === models.SecurityDepositTermType.LetterOfCredit
      && this.leaseTerm.amountOfLetterCredit === 0) {
      this.addError('Letter of Credit $ amount cannot be $0.00.');
      return;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  openBurnDownScheduleTable($event) {
    if (this.leaseTerm?.securityDepositTermType === models.SecurityDepositTermType.LetterOfCredit &&
      !this.leaseTerm.amountOfLetterCredit) {
      this.addError('Please fill in dollar amount for Security Deposit to open Burn Down Schedule.');
      return;
    }
    if (this.leaseTerm?.securityDepositTermType === models.SecurityDepositTermType.ReducingValue &&
      !this.leaseTerm.securityDepositMoneyValue) {
      this.addError('Please fill in dollar amount for Security Deposit to open Burn Down Schedule.');
      return;
    }
    const dialogRef = this._dialogService.show(SecurityDepositBurnDownScheduleTableComponent, {
      showCloseButton: false,
      closeOnOutsideClick: false,
      width: 700,
      maxWidth: 700,
      maxHeight: 720,
      containerClassNames: [
        'burn-down-schedule-dialog',
      ],
      injectableData: {
        lease: this.lease,
        securityDepositTerm: this.leaseTerm,
      },
    });

    const hidingSubscription = dialogRef.onHiding
      .subscribe(() => {
        hidingSubscription.unsubscribe();
        if (!dialogRef.outputData || !dialogRef.outputData.burnDownScheduleValues) {
          return;
        }
        this.leaseTerm.burnDownScheduleValues = dialogRef.outputData.burnDownScheduleValues;
      });
  }

  private _validateBurnDownOption(): boolean {
    if (this.leaseTerm.hasBurnDownScheduleValues
      && (!this.leaseTerm.burnDownScheduleValues || this.leaseTerm.burnDownScheduleValues.length === 0)) {
      return false;
    }

    if (this.isVisibleBurnDownScheduleContainer() && this.leaseTerm.isBurnDownScheduleChecked &&
      (!this.leaseTerm.burnDownScheduleValues || this.leaseTerm.burnDownScheduleValues.length === 0)) {
      return false;
    }
    return true;
  }

  isVisibleBurnDownScheduleContainer(): boolean {
    return this.leaseTerm &&
      (this.leaseTerm.securityDepositTermType === models.SecurityDepositTermType.LetterOfCredit
        || this.leaseTerm.securityDepositTermType === models.SecurityDepositTermType.MoneyValue);

  }

  isBurnDownScheduleOptionDisabled(): boolean {
    return (
      this.lease && this.lease.term && this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.lease.term.leaseTermOptions && !this.lease.term.leaseTermOptions.selectedLeaseTerm
    );
  }

  isVisibleTenantFinancialDocumentUploadedToast(): boolean {
    return this.leaseTerm &&
      this.leaseTerm.securityDepositTermType === models.SecurityDepositTermType.DeterminedUponReviewOfTenantFinancials
     && !this._financialRequestManager.shouldUploadByTenantTeam(this.project, this.financialRequests);
  }
  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: '(INSERT VALUE FOR MONTHS) per new Base Rental Rate',
          value: models.SecurityDepositTermType.MonthsCount,
        },
        {
          name: 'To be determined upon review of Tenant Financials.',
          value: models.SecurityDepositTermType.DeterminedUponReviewOfTenantFinancials,
        },
        {
          name: 'Tenant to deliver at their expense the Security Deposit in an ' +
            'irrevocable and unconditional Letter of Credit in the amount of [INSERT DOLAR VALUE]',
          value: models.SecurityDepositTermType.LetterOfCredit,
        },
        {
          name: 'Guaranty',
          value: models.SecurityDepositTermType.Guaranty,
        },
        {
          name: 'Custom',
          value: models.SecurityDepositTermType.MoneyValue,
        },
      ];
      return;
    }
    this.options = [
      {
        name: 'Per current lease',
        value: models.SecurityDepositTermType.PerCurrentLease,
      },
      {
        name: '(INSERT VALUE FOR MONTHS) per new Base Rental Rate',
        value: models.SecurityDepositTermType.MonthsCount,
      },
      {
        name: 'Reduce Security Deposit to (INSERT DOLLAR VALUE) and apply difference to first months rent of new Lease Term',
        value: models.SecurityDepositTermType.ReducingValue,
      },
      {
        name: 'Tenant to deliver at their expense the Security Deposit ' +
          'in an irrevocable and unconditional Letter of Credit in the amount of [INSERT DOLAR VALUE]',
        value: models.SecurityDepositTermType.LetterOfCredit,
      },
      {
        name: 'Guaranty',
        value: models.SecurityDepositTermType.Guaranty,
      },
      {
        name: 'Custom',
        value: models.SecurityDepositTermType.MoneyValue,
      },
    ];
  }
}
