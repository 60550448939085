<div class="term-custom-value-template-dialog">
  <!-- Start: Header -->
  <div class="term-custom-value-template-header">
    <div>
      <div class="term-custom-value-template-icon">
        <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-template"></i>
      </div>
      <div class="term-custom-value-template-title">
        <ng-container [ngSwitch]="mode">
          <ng-container *ngSwitchCase="Modes.ChooseTemplate">
            Choose from template
          </ng-container>
          <ng-container *ngSwitchCase="Modes.EditTemplateList">
            Saved templates
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div>
      <button type="button" class="close-button"
              (click)="close()">
        <i inlineSVG="assets/v2/icons/signs-icons.svg#icon-x-24"></i>
      </button>
    </div>
  </div>
  <!-- End: Header -->
  <!-- Start: Content -->
  <div class="term-custom-value-template-content">
    <dx-scroll-view width="100%" height="100%">
      <div class="term-custom-value-template-content-inner">
        <!-- Start: Loading template -->
        <ng-template #loadingTemplate>
          <div class="p-5 d-flex align-items-center justify-content-center">
            <app-spinner>
            </app-spinner>
          </div>
        </ng-template>
        <!-- End: Loading template -->
        <!-- Start: Templates -->
        <div class="templates"
             *ngIf="!isLoading; else loadingTemplate">
          <!-- Start: Empty template list placeholder -->
          <ng-template #noListItemsTemplate>
            <div class="templates-list-empty">
              No templates were saved yet
            </div>
          </ng-template>
          <!-- End: Empty template list placeholder -->
          <!-- Start: Template list -->
          <div class="templates-list"
               *ngIf="templates?.length; else noListItemsTemplate">
            <!-- Start: Template list items -->
            <ng-container *ngFor="let template of templates">
              <ng-container [ngSwitch]="mode">
                <ng-container *ngSwitchCase="Modes.ChooseTemplate">
                  <ng-container *ngTemplateOutlet="chooseListItemTemplate; context: { template: template }">
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Modes.EditTemplateList">
                  <ng-container *ngTemplateOutlet="editListItemTemplate; context: { template: template }">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- End: Template list items -->
            <!-- Start: Choose mode list item template -->
            <ng-template let-template="template"
                         #chooseListItemTemplate>
              <div class="template-list-item template-list-item-selectable"
                   [class.template-list-item-selected]="template.id === selectedTemplate?.id"
                   (click)="handleTemplateListItemClick(template)">
                <div class="template-list-item-content">
                  {{template.content}}
                </div>
              </div>
            </ng-template>
            <!-- End: Choose mode list item template -->
            <!-- Start: Edit mode list item template -->
            <ng-template let-template="template"
                         #editListItemTemplate>
              <div class="template-list-item">
                <div class="template-list-item-content">
                  {{template.content}}
                </div>
                <div class="template-list-item-buttons">
                  <button type="button"
                          placement="bottom"
                          (click)="handleTemplateListItemEditClick(template)">
                    <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
                  </button>
                  <button type="button"
                          placement="bottom"
                          (click)="handleTemplateListItemDeleteClick(template)">
                    <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-trash"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <!-- End: Edit mode list item template -->
          </div>
          <!-- End: Template list -->
        </div>
        <!-- End: Templates -->
      </div>
    </dx-scroll-view>
  </div>
  <!-- End: Content -->
  <!-- Start: Footer -->
  <div class="term-custom-value-template-footer"
       *ngIf="mode == Modes.ChooseTemplate && !isLoading">
    <button type="button" class="button-secondary"
            (click)="handleCancelClick()">
      Cancel
    </button>
    <button type="button" class="button-primary"
            [disabled]="!selectedTemplate"
            (click)="handleChooseClick()">
      Choose
    </button>
  </div>
  <!-- End: Footer -->
</div>
