import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { IActiveDealsByStageReportStageGroupViewModel } from '../../../infrastructure/models/generated';

export interface IActiveDealsByStageReportSingleStageDialogViewModel {
  groups: Array<IActiveDealsByStageReportStageGroupViewModel>;
  initialGroupId: number;
}

@Component({
  templateUrl: 'active-deals-by-stage-report-single-stage-dialog.component.html',
  styleUrls: ['active-deals-by-stage-report-single-stage-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveDealsByStageReportSingleStageDialogComponent implements OnInit {
  readonly activeDealsByStageReportSingleStageDialogViewModel: IActiveDealsByStageReportSingleStageDialogViewModel;

  activePeriodId: number;

  private readonly _dialogRefService: DialogRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;

  constructor(dialogRefService: DialogRefService, changeDetectorRef: ChangeDetectorRef) {
    this._dialogRefService = dialogRefService;
    this._changeDetectorRef = changeDetectorRef;
  }

  ngOnInit(): void {
    this.activePeriodId = this.activeDealsByStageReportSingleStageDialogViewModel.initialGroupId || 0;
  }

  goToNextPeriod(): void {
    const { groups } = this.activeDealsByStageReportSingleStageDialogViewModel;
    if (!groups) {
      return;
    }

    let nextId = this.activePeriodId + 1;
    if (groups.length <= nextId) {
      nextId = 0;
    }

    this.activePeriodId = nextId;

    this._dialogRefService.setTitle(groups[nextId].name);

    this._changeDetectorRef.markForCheck();
  }

  goToPreviousPeriod(): void {
    const { groups } = this.activeDealsByStageReportSingleStageDialogViewModel;
    if (!groups) {
      return;
    }

    let nextId = this.activePeriodId - 1;
    if (nextId < 0) {
      nextId = groups.length - 1;
    }

    this.activePeriodId = nextId;

    this._dialogRefService.setTitle(groups[nextId].name);

    this._changeDetectorRef.markForCheck();
  }
}
