import { Injectable } from '@angular/core';
import { Configuration } from '@statera/sdk/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthStore } from './auth.store';

import * as models from './auth.model';

@Injectable()
export class AuthRepository {
  private readonly _authStore: AuthStore;
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(authStore: AuthStore, configuration: Configuration, httpClient: HttpClient) {
    this._authStore = authStore;
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  isUserAuthenticated(): Observable<boolean> {
    return this._httpClient.post<boolean>(`${this._configuration.apiEndpoint}/auth/IsAuthenticated`, null);
  }

  checkUser(model: models.Login): Observable<models.AuthenticateResult> {
    return this._httpClient.post<models.AuthenticateResult>(`${this._configuration.apiEndpoint}/auth/CheckUser`, model);
  }

  verifyPhoneNumber(model: models.VerifyPhoneNumberRequest) {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/VerifyPhoneNumber`, model);
  }

  register(model: models.RegisterRequest): Observable<void> {
    return this._httpClient.post<void>(`${this._configuration.apiEndpoint}/auth/Register`, model);
  }

  resetPasswordRequest(model: models.PasswordResetRequest) {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/PasswordResetRequest`, model);
  }

  resetPassword(model: models.PasswordResetData) {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/PasswordReset`, model);
  }

  disableTwoFactorAuth() {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/DisableTwoFactorAuth`, {});
  }

  post2faCode(model: models.Post2FaCodeRequest) {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/Post2faCode`, model);
  }

  resend2FACode() {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/Resend2FACode`, {});
  }

  deactivate() {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/Deactivate`, null);
  }

  getUsers(): Observable<Array<models.UserInfo>> {
    return this._httpClient.get<Array<models.UserInfo>>(`${this._configuration.apiEndpoint}/auth/GetUsers`);
  }

  getStartupInfo(): Observable<models.StartupInfo> {
    return this._httpClient.get<models.StartupInfo>(`${this._configuration.apiEndpoint}/app/Info`);
  }

  setStoredStartupInfo(startupInfo: models.StartupInfo): void {
    this._authStore.startupInfo.next(startupInfo);
  }

  getStoredStartupInfo(): Observable<models.StartupInfo> {
    return this._authStore.startupInfo;
  }
}
