<div class="expirations-report-single-period">
  <ul class="expirations-report-single-period-legends">
    <li>
      <a (click)="onLegendItemClick()">
        <span class="expirations-report-single-period-legends-bg"
              [style.background-color]="'rgba(240, 156, 148, 1)'">
            </span>
        <span>Expiring SF</span>
      </a>
    </li>
  </ul>

  <div class="expirations-report-single-period-chart">
    <canvas baseChart
            [data]="barChartData"
            [options]="barChartOptions"
            [legend]="false"
            [type]="'bar'"
            #canvasElementRef>
    </canvas>
  </div>
</div>
