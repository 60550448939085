import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LeaseManager } from '@statera/sdk/lease';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { TermManager } from '@statera/sdk/term';

import { SafeCurrencyPipe } from '../../../../infrastructure/pipes/safe-currency.pipe';

import { TermsPageService } from '../../../services/terms-page.service';

import * as models from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-term-value-display',
  templateUrl: './term-value-display.component.html',
  styleUrls: ['./term-value-display.component.scss'],
})
export class TermValueDisplayComponent implements OnInit, OnDestroy {
  @Input() lease: models.ILeaseViewModel;
  @Input() termConfiguration: models.ILeaseTermConfiguration;
  @Input() project: models.IProjectViewModel;
  @Input() showTitle = false;

  LeaseTermType = models.LeaseTermType;

  term: models.ILeaseTermViewModel;
  termValue: any;

  marketRentalRate$: Observable<models.IMarketRentalRateViewModel> = of(null);

  private readonly _termsPageService: TermsPageService;
  private readonly _termManager: TermManager;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _safeCurrencyPipe: SafeCurrencyPipe;
  private readonly _leaseManager: LeaseManager;

  tooltipVisible = false;

  popoverSize: { width?: number };

  constructor(
    termsPageService: TermsPageService,
    termManager: TermManager,
    changeDetectorRef: ChangeDetectorRef,
    safeCurrencyPipe: SafeCurrencyPipe,
    leaseManager: LeaseManager
  ) {
    this._termsPageService = termsPageService;
    this._termManager = termManager;
    this._changeDetectorRef = changeDetectorRef;
    this._safeCurrencyPipe = safeCurrencyPipe;
    this._leaseManager = leaseManager;
  }

  ngOnInit(): void {
    this.term = this._termManager.getOriginalAbstractTerm(this.lease, this.termConfiguration.leaseTermType);
    this.termValue = this._termManager.getTermDetailedDisplayValue(this.term);

    this.marketRentalRate$ = this.marketRentalRate$
      .pipe(
        take(1),
        switchMap(() => {
          if (!this.lease || !this.lease.id) {
            return of(null);
          }

          return this._termManager.getMarketRentalRate(this.lease.id);
        }),
      );
  }

  ngOnDestroy(): void {
    this._changeDetectorRef.detach();
  }

  isBaseRentalRate(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isBaseRentalRate(term);
  }

  isTenantSquareFootagePhaseInTermValue(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isTenantSquareFootagePhaseInTermValue(term);
  }

  isRentalRateEscalationCustomOption(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isRentalRateEscalationCustomOption(term);
  }

  getBaseRentalRateScheduleCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getBaseRentalRateScheduleCustomTable(term);
  }

  getTenantSquareFootagePhaseInCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getTenantSquareFootagePhaseInCustomTable(term);
  }

  getRentalRateEscalationCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRentalRateEscalationCustomTable(term);
  }

  isRealEstateTaxesBreakdownTable(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isRealEstateTaxesBreakdownTable(term);
  }

  getRealEstateTaxesBreakdownTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRealEstateTaxesBreakdownTable(term);
  }

  getCurrentAvgMarketRentalRate(
    marketRentalRate: models.IMarketRentalRateViewModel,
    baseRentalRateTerm: models.IBaseRentalRateViewModel
  ): string {
    if (!marketRentalRate || !baseRentalRateTerm) {
      return null;
    }

    const unitMetrics = this._termManager.getBaseRentalRateUnitMetricsDisplayValue(baseRentalRateTerm);
    const unitMetricsEnum = this._termManager.getBaseRentalRateUnitMetrics(baseRentalRateTerm);

    let avgMarketRate = marketRentalRate.avgRate;
    if (unitMetricsEnum === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      avgMarketRate = avgMarketRate / 12;
    }

    return `${this._safeCurrencyPipe.transform(avgMarketRate)} ${unitMetrics}`;
  }

  showCurrentLeaseLanguage(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.showCurrentLeaseLanguage(lease);
  }

  showThirdPartyLease(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.showThirdPartyLease(lease);
  }
}
