import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TenantRepository } from './tenant.repository';

import * as models from './tenant.model';

@Injectable()
export class TenantManager {

  private readonly _tenantRepository: TenantRepository;

  constructor(tenantRepository: TenantRepository) {
    this._tenantRepository = tenantRepository;
  }

  cancelRequestViaTenantWizard(quizId: number, leaseId: number, model: Array<models.QuizAnswer>): Observable<void> {
    return this
      ._tenantRepository
      .cancelRequestViaTenantWizard(quizId, leaseId, model);
  }

  getBuildingAddresses(): Observable<Array<models.Address>> {
    return this
      ._tenantRepository
      .getBuildingAddresses();
  }

  getProjectsByLeaseId(leaseId: number): Observable<Array<models.Project>> {
    return this
      ._tenantRepository
      .getProjectsByLeaseId(leaseId);
  }
}
