import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import * as models from '../../../infrastructure/models/generated';
import { ProjectService } from '../../services/project.service';

export type AvatarContext = 'chat-external' | 'chat-internal';

export class AvatarListItem {
  constructor(fullName: string, firstName: string, lastName: string, url: string, companyName: string) {
    this.fullName = fullName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.url = url;
    this.companyName = companyName;
  }
  fullName: string;
  firstName: string;
  lastName: string;
  url: string;
  companyName: string;
}
@Component({
  selector: 'app-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnInit, OnDestroy {
  @Input()
  projects: Array<models.IProjectViewModel>;
  @Input()
  avatarList: Array<AvatarListItem>;
  @Input()
  leaseId: number;
  @Input() context: AvatarContext;

  info: models.IStartupInfoViewModel;

  private readonly _projectService: ProjectService;
  private readonly _authService: AuthService;
  private readonly _destroy$: Subject<void>;
  constructor(projectService: ProjectService, authService: AuthService) {
    this._projectService = projectService;
    this._authService = authService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          this.info = info;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  limitAvatarList(avatarList: Array<AvatarListItem>, limit: number): Array<AvatarListItem> {
    return [...(avatarList || [])].slice(0, limit);
  }

  getInitials(avatarListItem: AvatarListItem): string {
    return avatarListItem.firstName[0].toUpperCase() + avatarListItem.lastName[0].toUpperCase();
  }
}
