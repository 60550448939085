<ng-container *ngIf="isCanBeLoaded; else cannotPreviewTemplate">
  <ng-container [ngSwitch]="documentRef?.viewerType">
    <ng-container *ngSwitchCase="DocumentViewerType.Unknown">
      <ng-container *ngTemplateOutlet="thirdPartyDocumentViewerTemplate">
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DocumentViewerType.Image">
      <div [style.width.px]="width" [style.height.px]="height"
           appStopDevexpressScrollTampering>
        <ng-container *ngIf="imageRef">
          <app-image-viewer-image [imageRef]="imageRef" [width]="width" [height]="height">
          </app-image-viewer-image>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="DocumentViewerType.Video">
      <div [style.width.px]="width" [style.height.px]="height"
           appStopDevexpressScrollTampering>
        <app-video-player [source]="documentRef.url"
                          [playerType]="VideoPlayerType.HTML5"
                          #videoPlayer>
        </app-video-player>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="DocumentViewerType.Pdf">
      <div [style.width.px]="width" [style.height.px]="height"
           appStopDevexpressScrollTampering>
        <pdf-viewer [style.height.%]="100"
                    [src]="getPDFDocumentUrl()" [zoom]="0.9"
                    [render-text]="true" [original-size]="false"
                    (after-load-complete)="handlePDFLoadComplete()"
                    #pdfViewer>
        </pdf-viewer>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="DocumentViewerType.OfficeDocument">
      <ng-container *ngTemplateOutlet="thirdPartyDocumentViewerTemplate">
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="cannotPreviewTemplate">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #thirdPartyDocumentViewerTemplate>
  <div class="document-viewer third-party-document-viewer"
       [style.width.px]="width" [style.height.px]="height">
    <iframe *ngIf="isIframeVisible"
            [width]="width + 1" [height]="height + 1"
            #thirdPartyDocumentViewerIframeElementRef>
    </iframe>
  </div>
</ng-template>

<ng-template #cannotPreviewTemplate>
  <div class="document-viewer unsupported-document"
       [style.width.px]="width" [style.height.px]="height">
    <p class="mb-0">
      Unfortunately, we cannot preview this document.
    </p>
  </div>
</ng-template>
