<div class="invite-team-member">
  <div class="invite-team-member-header">
    <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#plus"></i>

    <span>
      Invite a member
    </span>

    <a role="button" href="javascript:void(0);" class="invite-member-close-button"
       (click)="close()">
      <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#cross-close"></i>
    </a>
  </div>

  <form class="invite-team-member-form" name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-body-wrapper">
      <div class="dx-field">
        <div class="dx-field-value">
          <dx-text-box name="fullName" id="fullName" required
                       placeholder="Full name" class="real-estate-input"
                       [isValid]="form.submitted ? fullNameField.valid : true"
                       [(ngModel)]="fullName"
                       #fullNameField="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && fullNameField.hasError('required')">
            Full name is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-text-box name="emailAddress" id="emailAddress" required email
                       placeholder="Email" class="real-estate-input"
                       [isValid]="form.submitted ? emailAddress.valid : true"
                       [(ngModel)]="userInvitationRequest.emailAddress"
                       #emailAddress="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('required')">
            Email address is required
          </div>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('email')">
            Enter a valid email address
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-select-box name="role" id="role" required
                     valueExpr="value" displayExpr="name"
                     placeholder="Role" class="real-estate-input"
                     [items]="roles"
                     [isValid]="form.submitted ? role.valid : true"
                     [(ngModel)]="userInvitationRequest.role"
                     #role="ngModel">
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && role.hasError('required')">
            Role is required
          </div>
        </div>
      </div>
    </div>

    <div class="invite-team-member-form-buttons">
      <button type="button" class="invite-team-member-form-button"
              (click)="close()">
        Cancel
      </button>
      <button type="submit" class="invite-team-member-form-button invite">
        Invite
      </button>
    </div>
  </form>
</div>
