import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './user.model';

@Injectable()
export class UserRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getCurrentUserProfile(): Observable<models.User> {
    return this._httpClient.get<models.User>(`${this._configuration.apiEndpoint}/profile`);
  }

  saveUserProfile(user: models.User): Observable<models.User> {
    return this._httpClient.post<models.User>(`${this._configuration.apiEndpoint}/profile/Save`, user);
  }

  sendResetPasswordRequest(model: models.PasswordResetRequest): Observable<any> {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/PasswordResetRequest`, model);
  }

  verifyPhoneNumber(model: models.PhoneNumberVerificationInfo): Observable<models.VerifyPhoneNumberResult> {
    return this._httpClient.post<models.VerifyPhoneNumberResult>(`${this._configuration.apiEndpoint}/auth/VerifyPhoneNumber`, model);
  }

  setTwoFactorAuthEnabled(model: models.TwoFactorAuthSettingValue): Observable<any> {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/TwoFactorAuth`, model);
  }

  deleteAvatar(): Observable<void> {
      return this._httpClient.delete<void>(`${this._configuration.apiEndpoint}/profile/DeleteAvatar`);
  }

  saveAvatar(model: models.UserAvatar): Observable<models.StartupInfo> {
    return this._httpClient.post<models.StartupInfo>(`${this._configuration.apiEndpoint}/profile/SaveAvatar`, model);
  }

  requestPhoneNumberVerificationToken(): Observable<any> {
    return this._httpClient.post(`${this._configuration.apiEndpoint}/auth/RequestPhoneNumberVerificationToken`, null);
  }
}
