import {
  Component, ElementRef,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { BuildingAttachmentType, IBuildingAttachmentViewModel } from '@statera/sdk/common';

import { VideoPlayerComponent } from '../../../video-player/components/video-player/video-player.component';

import { VideoPlayerType } from '../../../video-player/models/video-player.model';

@Component({
  selector: 'app-building-attachments',
  templateUrl: 'building-attachments.component.html',
  styleUrls: ['./building-attachments.component.scss'],
})
export class BuildingAttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren('videoPlayer') videoPlayers: QueryList<VideoPlayerComponent>;

  @Input() carouselClass: string;
  @Input() attachments: Array<IBuildingAttachmentViewModel>;
  @Input() showIndicators: boolean;
  @Input() showControls: boolean;

  readonly BuildingAttachmentType: typeof BuildingAttachmentType;
  readonly VideoPlayerType: typeof VideoPlayerType;

  private readonly _elementRef: ElementRef;

  private readonly _mouseEnter: Subject<number>;
  private readonly _mouseMove: Subject<number>;
  private readonly _mouseLeave: Subject<void>;

  private readonly _destroy: Subject<void>;

  constructor(elementRef: ElementRef) {
    this.BuildingAttachmentType = BuildingAttachmentType;
    this.VideoPlayerType = VideoPlayerType;

    this._elementRef = elementRef;

    this._mouseEnter = new Subject<number>();
    this._mouseMove = new Subject<number>();
    this._mouseLeave = new Subject<void>();

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.showControls ??= false;
    this.showIndicators ??= false;

    merge(
      this._mouseEnter,
      this._mouseMove,
      this._mouseLeave,
    )
      .pipe(
        debounceTime(10),
        tap((clientY) => {
          if (typeof clientY === 'undefined') {
            this.showControls = false;
            return;
          }

          const elementRect = this._elementRef.nativeElement.getBoundingClientRect();

          const mousePositionYPercentage = (clientY - elementRect.y) / elementRect.height * 100;

          if (mousePositionYPercentage >= 38 && mousePositionYPercentage <= 62) {
            this.showControls = true;
          } else {
            this.showControls = false;
          }
        }),
        debounceTime(2400),
        tap(() => {
          this.showControls = false;
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.showControls &&
      changes.showControls.previousValue !== changes.showControls.currentValue
    ) {
      this.showControls = changes.showControls.currentValue ?? true;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getCarouselClassName(): string {
    let className = '';

    if (this.carouselClass) {
      className += this.carouselClass;
    }

    if (!this.showControls) {
      className += ' controls-hidden';
    }

    return className;
  }

  getSortedAttachments(): Array<IBuildingAttachmentViewModel> {
    return this.attachments
      .sort((a, b) => {
        if (!a.sortOrder) {
          return 0;
        }

        if (a.sortOrder === b.sortOrder) {
          return 0;
        }

        return a.sortOrder < b.sortOrder ? -1 : 1;
      });
  }


  @HostListener('mouseenter', ['$event'])
  handleMouseEnter(event: MouseEvent): void {
    this._mouseEnter.next(event.clientY);
  }

  @HostListener('mousemove', ['$event'])
  handleMouseMove(event: MouseEvent): void {
    this._mouseMove.next(event.clientY);
  }

  @HostListener('mouseleave')
  handleMouseLeave(): void {
    this._mouseLeave.next();
  }

  handleCarouselClick(event: MouseEvent): void {
    if (!event?.target || (<HTMLElement>event.target).tagName === 'IMG') {
      return;
    }

    event.stopPropagation();
  }

  handlePlaying(): void {}

  handlePlayed(): void {}

  handlePaused(): void {}

  handleActiveSlideChange(currentSlideIndex: number): void {
    this.videoPlayers?.forEach(x => x.pause());
  }
}
