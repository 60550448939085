import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Role } from '@statera/sdk/auth';
import { Observable } from 'rxjs';

import { ProjectManager } from '@statera/sdk/project';

import { ProjectStore, ProjectState } from './project.store';
import * as models from '../../../infrastructure/models/generated';
import { SharedConstants } from '../../constants/shared.constants';

@Injectable({
  providedIn: 'root',
})
export class ProjectQuery extends QueryEntity<ProjectState> {
  private readonly _projectManager: ProjectManager;

  constructor(protected store: ProjectStore, projectManager: ProjectManager) {
    super(store);

    this._projectManager = projectManager;
  }

  hasActionsRequired = (role, userId, teamAlignmentRoles: Array<models.ITeamAlignmentRoleViewModel> = null) => {
    return this.hasEntity(project => (
      this._projectManager.isCurrentRoleTurnOnTerms(role, userId, project, null, teamAlignmentRoles)
    ));
  }

  hasAllClosed = () => {
    return this.getCount() > 0 && !this.hasEntity((project: models.IProjectViewModel) => {
      if (project && project.projectState) {
        return project.projectState.renewalProjectTemplateItemType !== SharedConstants.ClosedProjectStateType;
      }
      return true;
    });
  }

  hasProjectWithCurrentState = () => {
    return this.hasEntity(project => {
      return !!(project && project.projectState);
    });
  }

  getRenewalProject(leaseId: number): Observable<models.IProjectViewModel> {
    return this.selectEntity(x => x.leaseId === leaseId &&
      (
        x.projectTypeId === models.ProjectTypeEnum.Renewal ||
        x.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord ||
        x.projectTypeId === models.ProjectTypeEnum.Restructure ||
        x.projectTypeId === models.ProjectTypeEnum.NewDeal
      ));
  }
}
