<div class="container mb-5 mt-2">
  <div class="mx-2">
    <h3>My Documents</h3>
    <dx-select-box displayExpr="text" valueExpr="id" [dataSource]="leaseSelectItems" [(value)]="leaseId" (onValueChanged)="selectLease($event)">
    </dx-select-box>
  </div>

  <app-attachments *ngIf="mode" [files]="documents" [mode]="mode" [entityId]="leaseId">
  </app-attachments>
</div>
