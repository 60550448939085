<form name="form" (submit)="saveLeaseTerm($event)" *ngIf="isVisibleTermForm()">
  <div class="row"
       *ngIf="!isOption">
    <h6 class="radio-group-title">Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container"
         *ngIf="isVisibleRadioGroup">
      <div class="radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.terminationOptionType" (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
            <div *dxTemplate="let data of 'radio'">
              <ng-container *ngIf="data.value === TerminationOptionType.RightToTerminateLease; else customTemplate">
                <div class="d-flex flex-column">
                  <div class="insert-row mb-2">
                    <span>Tenant shall have the right to terminate the lease effective after the end of month</span>
                    <app-input-field appIgnoreScrollwheel
                                          [id]="'terminationMonth'" [placeholder]="'(e.g. 1)'"
                                          [className]="'real-estate-input py-0'" [min]="0" [max]="1200"
                                          [format]="'#0'" [width]="'70px'"
                                          [errorMessage]="'Termination month is required'"
                                          [value]="leaseTerm.terminationMonth"
                                          [disabled]="leaseTerm.terminationOptionType !== TerminationOptionType.RightToTerminateLease"
                                          (valueChange)="leaseTerm.terminationMonth = $event; clearErrors()"
                    ></app-input-field>
                  </div>
                  <div class="insert-row mb-2">
                    <span>by providing the Landlord a minimum of</span>
                    <app-input-field appIgnoreScrollwheel
                                          [id]="'howManyMonthsToNotice'" [placeholder]="'(e.g. 6)'"
                                          [className]="'real-estate-input py-0'"
                                          [format]="'#0'" [width]="'70px'"
                                          [errorMessage]="'Notice period prior to termination is required'"
                                          [value]="leaseTerm.howManyMonthsToNotice" [suffix]="'month(s)'"
                                          [disabled]="leaseTerm.terminationOptionType !== TerminationOptionType.RightToTerminateLease"
                                          (valueChange)="leaseTerm.howManyMonthsToNotice = $event; clearErrors()"
                    ></app-input-field>
                    <span>prior written notice</span>
                  </div>
                  <ng-container *ngIf="leaseTerm?.terminationOptionType === TerminationOptionType.RightToTerminateLease">
                    <span class="termination-fee-text">Termination fee shall be submitted to Landlord concurrently with prior written notice and equal to:</span>
                    <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radioChild"
                                    [dataSource]="feeOptions"
                                    [(value)]="leaseTerm.terminationOptionFeeType" (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
                      <div *dxTemplate="let data of 'radioChild'">
                        <div class="d-flex flex-column"
                             *ngIf="data.value === TerminationOptionFeeType.AmortizationRate">
                          <div class="insert-row mb-2">
                            <span>Unamortized costs of the transaction at</span>
                            <app-input-field appIgnoreScrollwheel
                                                  [id]="'amortizationRate'" [placeholder]="'(e.g. 1)'"
                                                  [className]="'real-estate-input py-0'"
                                                  [format]="'#0.##'" [width]="'70px'"
                                                  [errorMessage]="'Annual Amortization rate is required'"
                                                  [value]="leaseTerm.amortizationRate" [suffix]="'%'"
                                                  [disabled]="leaseTerm.terminationOptionFeeType !== TerminationOptionFeeType.AmortizationRate"
                                                  (valueChange)="leaseTerm.amortizationRate = $event; clearErrors()"
                            ></app-input-field>
                            <span>over the remaining term </span>
                          </div>
                          <span class="mb-3">of the lease after termination</span>
                        </div>
                        <div class="d-flex flex-column"
                             *ngIf="data.value === TerminationOptionFeeType.DollarValue">
                          <div class="insert-row">
                            <span>A flat fee of</span>
                            <app-input-field [id]="'terminationOptionDollarValue'" [placeholder]="'$ (e.g. 300)'"
                                                  [className]="'real-estate-input py-0'" [min]="0" [step]="0"
                                                  [format]="'#,##0.00'" [width]="'120px'"
                                                  [errorMessage]="'Dollar value is required'"
                                                  [value]="leaseTerm.terminationOptionDollarValue"
                                                  [disabled]="leaseTerm.terminationOptionFeeType !== TerminationOptionFeeType.DollarValue"
                                                  (valueChange)="leaseTerm.terminationOptionDollarValue = $event; clearErrors()"
                            ></app-input-field>
                          </div>
                        </div>
                      </div>
                      <dx-validator>
                        <dxi-validation-rule type="required" message="You should choose termination option fee for Termination Option term"></dxi-validation-rule>
                      </dx-validator>
                    </dx-radio-group>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #customTemplate>
                <p>{{ data.name }}</p>
              </ng-template>
            </div>
            <dx-validator>
              <dxi-validation-rule type="required" message="Select custom or please provide incns"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div>
      <!--col-->
    </div>
    <!--row-->

    <div class="row col-8"
         *ngIf="leaseTerm?.terminationOptionType === TerminationOptionType.Custom">

        <app-term-custom-value-template-textarea placeholder="Insert custom termination option clause"
                                                 required requiredMessage="Custom Termination Option clause is required"
                                                 [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                 [(value)]="leaseTerm.terminationOptionCustomText"
                                                 (valueChange)="clearErrors(); leaseTermPropertyChange()">
        </app-term-custom-value-template-textarea>
      </div>


    <ng-container *ngIf="canInsertNewOption()">
      <ng-container *ngFor="let termOption of leaseTerm?.leaseTermOptions?.options; let index=index;">
        <div class="row">
          <label>
            OPTION {{getOptionName(index)}}
          </label>
        </div>
        <div class="row">
          <app-termination-option [isOption]="true" [project]="project" [lease]="lease" [(leaseTerm)]="leaseTerm?.leaseTermOptions?.options[index]">
          </app-termination-option>
        </div>
      </ng-container>
    </ng-container>

    <!-- Start: Validation summary -->

<!--      <div class="validation-summary">-->
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
<!--        <div class="term-toast term-toast-error"-->
<!--             *ngFor="let msg of errors">-->
<!--          <i inlineSVG="assets/img/x.svg"></i>-->
<!--          {{msg}}-->
<!--        </div>-->
<!--      </div>-->

    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked" [showSaveButton]="!isOption"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<div class="term-toast term-toast-information"
     *ngIf="isTermWillBeAddressedInFollowingStages()">
  <i inlineSVG="assets/img/information.svg"></i>
  This Term will be addressed in the following Stage(s).
</div>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
