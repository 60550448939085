import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { DealsByStageReportChartType, DealsByStageReportTooltip } from '../../models/deals-by-stage-report-tooltip.model';

import { AnalyticsTooltipService, Context } from '../../services/analytics-tooltip.service';

@Component({
  templateUrl: 'deals-by-stage-report-tooltip.component.html',
  styleUrls: ['deals-by-stage-report-tooltip.component.scss'],
})
export class DealsByStageReportTooltipComponent implements OnInit, AfterViewInit {
  @ViewChild('dealsByStageReportTooltipElementRef') dealsByStageReportTooltipElementRef: ElementRef;

  readonly injectableData: {
    analyticsTooltipService: AnalyticsTooltipService<DealsByStageReportTooltipComponent, DealsByStageReportTooltip>,
    model: DealsByStageReportTooltip,
    context: Context,
  };

  DealsByStageReportChartType: typeof DealsByStageReportChartType = DealsByStageReportChartType;

  dealsByStageReportTooltip: DealsByStageReportTooltip;

  ngOnInit(): void {
    if (!this.injectableData || !this.injectableData.model) {
      return;
    }

    this.dealsByStageReportTooltip = this.injectableData.model;
  }

  ngAfterViewInit(): void {
    if (
      !this.injectableData || !this.injectableData.context ||
      !this.dealsByStageReportTooltipElementRef || !this.dealsByStageReportTooltipElementRef.nativeElement
    ) {
      return;
    }

    const { canvasElement, tooltipModel } = this.injectableData.context;
    if (!canvasElement || !tooltipModel) {
      return;
    }

    const canvasBoundingClientRect = canvasElement.getBoundingClientRect();

    const tooltipElement = this.dealsByStageReportTooltipElementRef.nativeElement;

    tooltipElement.style.left = `${canvasBoundingClientRect.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipElement.style.top = `${canvasBoundingClientRect.top + window.pageYOffset + tooltipModel.caretY}px`;
  }
}
