<div class="tenant-project">
  <dx-toolbar>
    <dxi-item location="before" locateInMenu="never">
      <h1 class="h6">Project Schedule</h1>
    </dxi-item>
    <dxi-item location="before" locateInMenu="never">
      <dx-select-box valueExpr="id" displayExpr="text" width="550"
                     groupTemplate="groupTpl" fieldTemplate="fieldTpl" itemTemplate="itemTpl"
                     [items]="projects" [grouped]="true" [value]="project?.id"
                     (onItemClick)="selectProject($event)"
                     #selectBox>
        <div *dxTemplate="let data of 'groupTpl'">
          <div class="select-box-group-tpl">
            <span class="icon-unit"></span>
            <span>{{ data.key }}</span>
          </div>
        </div>

        <div *dxTemplate="let data of 'fieldTpl'">
          <dx-text-box class="w-100"
                       [value]="getProjectSelectedName(data)" [readOnly]="true">
          </dx-text-box>
        </div>

        <div *dxTemplate="let data of 'itemTpl'">
            <span class="select-box-group-tpl">
              <span>{{data.text}}</span>
              <span class="expired-badge"
                    *ngIf="data.isExpired">
                Expired
              </span>
            </span>
        </div>
      </dx-select-box>
    </dxi-item>
    <dxi-item location="before">
      <dx-button icon="fa fa-filter" hint="Filter"></dx-button>
    </dxi-item>
  </dx-toolbar>

  <ng-container *ngIf="!isLoadingState; else loadingTpl">
    <app-project-schedule *ngIf="project && projectStatistics"
                          [project]="project" [projectStatistic]="projectStatistics" [lease]="lease">
    </app-project-schedule>
  </ng-container>
  <ng-template #loadingTpl>
    <p class="text-center text-muted mx-4">
      Loading...
    </p>
  </ng-template>
</div>

