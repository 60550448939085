import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { LandlordStore } from './landlord.store';
import { LandlordManager } from './landlord.manager';
import { LandlordRepository } from './landlord.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    LandlordStore,
    LandlordManager,
    LandlordRepository,
  ],
})
export class LandlordModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LandlordModule> {
    return {
      ngModule: LandlordModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LandlordStore,
        LandlordManager,
        LandlordRepository,
      ],
    };
  }
}
