import { Injectable } from '@angular/core';

import * as ng from '@angular/core';

import { ComponentLoaderService } from './component-loader.service';

@Injectable()
export class ComponentLoaderFactoryService {
  private readonly _componentFactoryResolver: ng.ComponentFactoryResolver;
  private readonly _ngZone: ng.NgZone;
  private readonly _injector: ng.Injector;
  private readonly _applicationRef: ng.ApplicationRef;

  constructor(
    componentFactoryResolver: ng.ComponentFactoryResolver,
    ngZone: ng.NgZone,
    injector: ng.Injector,
    applicationRef: ng.ApplicationRef,
  ) {
    this._componentFactoryResolver = componentFactoryResolver;
    this._ngZone = ngZone;
    this._injector = injector;
    this._applicationRef = applicationRef;
  }

  createLoader<T>(
    elementRef: ng.ElementRef,
    viewContainerRef: ng.ViewContainerRef,
    renderer: ng.Renderer2,
  ): ComponentLoaderService<T> {
    return new ComponentLoaderService<T>(
      elementRef,
      viewContainerRef,
      renderer,
      this._componentFactoryResolver,
      this._ngZone,
      this._injector,
      this._applicationRef,
    );
  }
}
