<div class="row main-actions">
  <ng-container *ngIf="showSaveButton">
    <dx-button #submitButton
               id="button" text="Save" type="success" class="b-btn b-btn--small b-btn-primary btn"
               [useSubmitBehavior]="true">
    </dx-button>
  </ng-container>

  <ng-container *ngIf="rejectButtonClicked">
      <dx-button id="cancelButton" text="Cancel" type="cancel" class="b-btn b-btn--small b-btn-primary btn"
                 [useSubmitBehavior]="false"
                 (onClick)="cancelLeaseTerm.emit()">
      </dx-button>
  </ng-container>
</div>
