<dx-scroll-view width="100%" height="100%">
  <div class="" id="modalAlertSettings">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Start: In-App Notifications -->
          <div class="notification-block">
            <img src="/assets/img/push.svg" alt="Bell icon">
            <div>
              <div class="d-flex">
                <dx-switch [disabled]="isLoading"
                           [(ngModel)]="notificationsDisplay">
                </dx-switch>
                <h3>In-App Notifications</h3>
              </div>
              <p>Receive notifications about contracts updates and settings via software component</p>
            </div>
          </div>
          <!-- End: In-App Notifications -->
          <!-- Start: Email Notifications -->
          <div class="notification-block">
            <img src="/assets/img/envelope.svg" alt="Envelope icon">
            <div>
              <div class="d-flex">
                <dx-switch [disabled]="isLoading"
                           [(ngModel)]="emailNotifications">
                </dx-switch>
                <h3>Email Notifications</h3>
              </div>
              <p>Receive notifications about contracts updates and product offers via email</p>
            </div>
          </div>
          <!-- End: Email Notifications -->
          <!-- Start: Messenger SMS Notifications -->
          <div class="notification-block">
            <img src="/assets/img/sms.svg" alt="Phone icon">
            <div>
              <div class="d-flex">
                <dx-switch [disabled]="isLoading"
                           [(ngModel)]="messengerSmsNotifications"
                           (onValueChanged)="onMessengerSmsNotificationsChanged($event)">
                </dx-switch>
                <h3>Messenger SMS Notifications</h3>
              </div>
              <p>Receive notifications about new messages via text</p>
            </div>
          </div>
          <!-- End: Messenger SMS Notifications -->
          <!-- Start: Messenger Email Notifications -->
          <div class="notification-block">
            <img src="/assets/img/envelope.svg" alt="Envelope icon">
            <div>
              <div class="d-flex">
                <dx-switch [disabled]="isLoading"
                           [(ngModel)]="messengerEmailNotifications">
                </dx-switch>
                <h3>Messenger Email Notifications</h3>
              </div>
              <p>Receive notifications about new messages via email</p>
            </div>
          </div>
          <!-- End: Messenger Email Notifications -->
          <form name="formAlertSettings">
            <!-- Start: Lease Commencement -->
            <div class="form-group">
              <label for="leaseCommencement">Lease Commencement</label>
              <select id="leaseCommencement" name="leaseCommencement"
                      [(ngModel)]="leaseCommencementPreference">
                <option *ngFor="let option of leaseCommencementPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Lease Commencement -->
            <!-- Start: Lease Expiration -->
            <div class="form-group">
              <label for="leaseExpiration">Lease Expiration</label>
              <select id="leaseExpiration" name="leaseExpiration"
                      [(ngModel)]="leaseExpirationPreference">
                <option *ngFor="let option of leaseExpirationPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Lease Expiration -->
            <!-- Start: Rent Escalation -->
            <div class="form-group">
              <label for="rentEscalation">Rent Escalation</label>
              <select id="rentEscalation" name="rentEscalation"
                      [(ngModel)]="rentEscalationPreference">
                <option *ngFor="let option of rentEscalationPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Rent Escalation -->
            <!-- Start: Renewal Option -->
            <div class="form-group">
              <label for="renewalOption">Renewal Option</label>
              <select id="renewalOption" name="renewalOption"
                      [(ngModel)]="renewalOptionPreference">
                <option *ngFor="let option of renewalOptionPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Renewal Option -->
            <!-- Start: Termination Option -->
            <div class="form-group">
              <label for="terminationOption">Termination Option</label>
              <select id="terminationOption" name="terminationOption"
                      [(ngModel)]="terminationOptionPreference">
                <option *ngFor="let option of terminationOptionPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Termination Option -->
            <!-- Start: Inquiries & Requests -->
            <div class="form-group">
              <label for="inquiriesAndRequests">Inquiries & Requests</label>
              <select id="inquiriesAndRequests" name="inquiriesAndRequests"
                      [(ngModel)]="inquiriesAndRequestsPreference">
                <option *ngFor="let option of inquiriesAndRequestsPreferenceOptions"
                        [ngValue]="option">
                  {{option.displayValue}}
                </option>
              </select>
            </div>
            <!-- End: Inquiries & Requests -->
          </form>
        </div>
        <div class="modal-footer">
          <button  type="button" class="btn btn-secondary" (click)="onClosed($event)">Close</button>
          <button #saveChangesButton type="button" class="btn btn-primary"
                  (click)="onAlertPreferencesChanged($event, saveChangesButton)">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</dx-scroll-view>
