import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { GanttChartComponent } from '../gantt-chart/gantt-chart.component';
import { ProjectScheduleDemoComponent } from '../project-schedule-demo/project-schedule-demo.component';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-project-schedule',
  templateUrl: './project-schedule.component.html',
  styleUrls: ['./project-schedule.component.scss'],
})
export class ProjectScheduleComponent implements AfterViewInit {
  @Input() project: models.IProjectViewModel;
  @Input() projectStatistic: models.IProjectStatisticViewModel;
  @Input() lease: models.ILeaseViewModel;

  @ViewChild('ganttChart') ganttChart: GanttChartComponent;
  @ViewChild('projectScheduleDemoComponent') projectScheduleDemoComponent: ProjectScheduleDemoComponent;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.projectScheduleDemoComponent && this.isStarted()) {
        this.projectScheduleDemoComponent.show(this);
      }
    }, 500);
  }

  isFinished(): boolean {
    if (this.project && this.project.projectState) {
      return this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LeaseAmendmentSigned;
    }
    return false;
  }

  isStarted(): boolean {
    return !!(this.project && this.project.projectState);
  }

  isRenewalOptionNoticeDateSet(): boolean {
    return this.projectStatistic && !!this.projectStatistic.renewalNoticeDate;
  }
}
