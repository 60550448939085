import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Configuration } from '@statera/sdk/common';

import * as models from './anchor-comment.model';

@Injectable()
export class AnchorCommentRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addMessage(message: models.PlanAnchorComment, token?: string): Observable<models.PlanAnchorComment> {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    return this._httpClient
      .post<models.PlanAnchorComment>(
        `${this._configuration.apiEndpoint}/floorplan/CreateFloorPlanAnchorComment`,
        message,
        {params}
      );
  }

  getMessages(anchorId?: number, skip?: number, take?: number, token?: string): Observable<Array<models.PlanAnchorComment>> {
    let params = new HttpParams();

    if (anchorId) {
      params = params.set('id', anchorId.toString());
    }

    if (skip || skip === 0) {
      params = params.set('skip', skip.toString());
    }

    if (take || take === 0) {
      params = params.set('take', take.toString());
    }

    if (token) {
      params = params.set('token', token);
    }

    return this._httpClient
      .get<Array<models.PlanAnchorComment>>(
        `${this._configuration.apiEndpoint}/floorplan/GetAnchorComments`,
        {
          params,
        },
      );
  }

  updateMessage(message: models.PlanAnchorComment): Observable<models.PlanAnchorComment> {
    return this._httpClient
      .post<models.PlanAnchorComment>(
        `${this._configuration.apiEndpoint}/floorplan/UpdateFloorPlanAnchorComment`,
        message,
      );
  }

  deleteMessage(message: models.PlanAnchorComment): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/floorplan/DeleteFloorPlanAnchorComment`,
        message,
      );
  }

  markAllAsRead(anchorId: number): Observable<void> {
    let params = new HttpParams();

    if (anchorId) {
      params = params.set('id', anchorId.toString());
    }

    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/floorplan/MarkAsReadByAnchorId`,
        null,
        {
          params,
        },
      )
      .pipe(
        share(),
      );
  }
}
