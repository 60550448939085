import { Component, Input } from '@angular/core';

import * as models from '../../../../infrastructure/models/generated';

@Component({
  selector: 'app-base-rental-schedule',
  templateUrl: './base-rental-schedule.component.html',
  styleUrls: ['./base-rental-schedule.component.scss']
})
export class BaseRentalScheduleComponent {
  @Input() baseRentalRate: models.IBaseRentalRateViewModel;
}
