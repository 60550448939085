import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as models from './landlord.model';

@Injectable()
export class LandlordStore {
  preSelectedPortfolio$: BehaviorSubject<models.PortfolioViewModel>;
  portfolios$: BehaviorSubject<Array<models.PortfolioViewModel>>;

  constructor() {
    this.preSelectedPortfolio$ = new BehaviorSubject<models.PortfolioViewModel>(null);
    this.portfolios$ = new BehaviorSubject<Array<models.PortfolioViewModel>>([]);
  }
}
