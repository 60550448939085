import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxAccordionModule,
  DxBoxModule,
  DxButtonModule,
  DxFileUploaderModule,
  DxListModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTextBoxModule
} from 'devextreme-angular';

import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { SharedModule } from '../shared/shared.module';

import { AttachmentsComponent } from './components/attachments/attachments.component';

@NgModule({
  declarations: [
    AttachmentsComponent,
  ],
  imports: [
    CommonModule,

    DxTextBoxModule,
    DxAccordionModule,
    DxFileUploaderModule,
    DxListModule,
    DxPopupModule,
    DxBoxModule,
    DxSelectBoxModule,
    DxButtonModule,

    DocumentViewerModule,
    SharedModule,
  ],
  exports: [
    AttachmentsComponent,
  ],
})
export class AttachmentsModule {}
