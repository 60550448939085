<div class="lease--settings d-flex flex-column justify-beetwen">
    <div class="d-flex flex-row align-items-center justify-content-between title">
        <div class="d-flex align-items-center ga">
            <img src="assets/v2/icons/settings.svg" alt="gear" srcset="">
            <h1> Colábo Settings </h1>
        </div>

        <button type="button" class="close"
                (click)="close()">
            <i class="dx-icon dx-icon-close"></i>
        </button>
    </div>

    <form name="form" novalidate #form="ngForm" class="form"
          (ngSubmit)="submit(form)">
        <div class="lease--settings__body">
            <p class="mb-0">
                I'd like to display and calculate all costs of this lease as
            </p>
            <div class="px-2">
                <dx-select-box class="lease--settings__select real-estate-input" displayExpr="label" valueExpr="value"
                               [dataSource]="leaseMetrics"
                               [(value)]="leaseSettings.leaseMetrics"
                               [width]="'99px'">
                </dx-select-box>
            </div>
        </div>

        <div class="lease--settings__footer">
            <dx-button type="normal" text="Cancel" width="100%"
                       (onClick)="close()">
            </dx-button>
            <dx-button type="success" width="100%" text="Save"
                       [useSubmitBehavior]="true">
            </dx-button>
        </div>
    </form>

</div>


