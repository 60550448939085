<div class="key-statistics-report">
    <div class="key-statistics-report-card">
        <div class="key-statistics-report-card-body">
            <div class="info-block">
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.totalSquareFeet || 0) | squareFootage | safeNumber: '1.0-0' }}
                    </ng-container>
                </div>
                <span class="caption">Total SF</span>
            </div>

            <div class="info-block">
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.uniquePortfoliosCount || 0) | safeNumber: '1.0-0' }}
                    </ng-container>
                </div>
                <span class="caption">Portfolios</span>
            </div>

            <div class="info-block">
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.weightedAverageLeaseTerm || 0) | safeNumber: '1.1-1' }}Y
                    </ng-container>
                </div>
                <span class="caption">WALT</span>
            </div>

            <div class="info-block">
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.uniqueBuildingsCount || 0) | safeNumber: '1.0-0' }}
                    </ng-container>
                </div>
                <span class="caption">Buildings</span>
            </div>

            <div class="info-block">
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.totalOccupancyPercentage || 0) | safeNumber: '1.0-0' }}%
                    </ng-container>
                </div>
                <span class="caption">Current Occupancy</span>
            </div>
        </div>
    </div>
</div>

<div class="key-statistics-report">
    <div class="key-statistics-report-card">
        <div class="key-statistics-report-card-body">
            <div class="info-block center">
                <span class="icon-calendar"></span>
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.squareFeetExpiringThisYear || 0) | squareFootage | safeNumber: '1.0-0' }}
                    </ng-container>
                </div>
                <span class="caption">SF Expiring <span class="icon-info" #tooltipAnchor></span> </span>
                <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" position="top" contentTemplate="content"
                            [target]="tooltipAnchor">
                    <div class="dashboard-card-header-tooltip">
                        12 Months
                    </div>
                </dx-tooltip>
            </div>
        </div>
    </div>

    <div class="key-statistics-report-card">
        <div class="key-statistics-report-card-body">
            <div class="info-block center">
                <span class="icon-search"></span>
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.totalVacanciesPercentage || 0) | safeNumber: '1.0-0' }}%
                    </ng-container>
                </div>
                <span class="caption">Total Vacancy</span>
            </div>
        </div>
    </div>

    <div class="key-statistics-report-card">
        <div class="key-statistics-report-card-body">
            <div class="info-block center">
                <span class="icon-lease"></span>
                <div class="value">
                    <ng-container *ngIf="isLoaded; else preloader">
                        {{ (keyStatisticsReport?.activeDealsCount || 0) | safeNumber: '1.0-0' }}
                    </ng-container>
                </div>
                <span class="caption">Active Deals</span>
            </div>
        </div>
    </div>

    <!-- Start export card-->

    <div class="key-statistics-report-card exports-report">
        <span class="export-report__caption">export reports</span>
        <div class="exports-report__body">
            <div class="exports-report__body-item">
                <div class="d-flex align-items-center">
                    <div class="bodi-item__icon">
                        <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.8591 4.36365C19.4667 4.36365 20.0494 4.60501 20.479 5.03464C20.9086 5.46427 21.15 6.04697 21.15 6.65456V8.18183H19.6227V17.3455C19.6227 17.9531 19.3813 18.5358 18.9517 18.9654C18.5221 19.395 17.9394 19.6364 17.3318 19.6364H6.64088C6.0333 19.6364 5.4506 19.395 5.02097 18.9654C4.59134 18.5358 4.34998 17.9531 4.34998 17.3455V15.8182H16.5682V17.3455C16.5682 17.5325 16.6369 17.713 16.7611 17.8528C16.8854 17.9926 17.0567 18.0819 17.2424 18.1038L17.3318 18.1091C17.5188 18.1091 17.6994 18.0404 17.8391 17.9161C17.9789 17.7918 18.0682 17.6206 18.0901 17.4348L18.0954 17.3455V5.89092H8.16816C7.98112 5.89094 7.80059 5.95961 7.66082 6.0839C7.52105 6.20819 7.43175 6.37946 7.40987 6.56521L7.40452 6.65456V14.2909H5.87725V6.65456C5.87725 6.04697 6.11861 5.46427 6.54824 5.03464C6.97787 4.60501 7.56057 4.36365 8.16816 4.36365H18.8591Z" fill="#59B680"/>
                        </svg>
                    </div>

                    <span>Leasing Activity</span>
                </div>

                <button (click)="openExportDialog(KeyStatisticsReportExportType.LeasingActivity)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6054 10L5.66553 4.55531L7.36228 3L14.9989 10L7.36228 17L5.66553 15.4447L11.6054 10Z" fill="#686F7B"/>
                    </svg>
                </button>
            </div>
            <div class="exports-report__body-item">
                <div class="d-flex align-items-center">
                    <div class="bodi-item__icon">
                        <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.291 3.2037L3.9687 8.1933C3.90199 8.23327 3.84678 8.28985 3.80845 8.35752C3.77012 8.42518 3.74998 8.50163 3.75 8.5794V20.0247C3.75 20.2634 3.84482 20.4923 4.0136 20.6611C4.18239 20.8299 4.41131 20.9247 4.65 20.9247H20.85C21.0887 20.9247 21.3176 20.8299 21.4864 20.6611C21.6552 20.4923 21.75 20.2634 21.75 20.0247V8.5803C21.75 8.50253 21.7299 8.42608 21.6915 8.35842C21.6532 8.29075 21.598 8.23417 21.5313 8.1942L13.2171 3.2037C13.0772 3.11977 12.9172 3.07544 12.7541 3.07544C12.5909 3.07544 12.4309 3.11977 12.291 3.2037ZM5.55 19.1247V9.3444V9.3435L12.7536 5.0235L19.95 9.3435V19.1247H5.55ZM13.6501 17.3249V15.5249H11.8501V17.3249H13.6501ZM10.4698 9.29962C10.058 9.7315 9.77731 10.2715 9.66038 10.8566L11.4262 11.2103C11.4763 10.9596 11.5966 10.7283 11.773 10.5433C11.9495 10.3582 12.1748 10.2271 12.4229 10.1652C12.6709 10.1032 12.9315 10.113 13.1742 10.1933C13.4169 10.2736 13.6318 10.4213 13.7939 10.619C13.956 10.8167 14.0587 11.0563 14.0899 11.3101C14.1211 11.5639 14.0796 11.8213 13.9703 12.0524C13.8609 12.2835 13.6882 12.4788 13.4722 12.6155C13.2562 12.7523 13.0058 12.8249 12.7501 12.8249H11.8501V14.6249H12.7501C13.3468 14.625 13.9313 14.4555 14.4355 14.1363C14.9398 13.8171 15.343 13.3613 15.5982 12.8219C15.8535 12.2825 15.9504 11.6817 15.8775 11.0894C15.8047 10.4971 15.5652 9.93771 15.1868 9.47624C14.8084 9.01476 14.3068 8.67023 13.7403 8.48272C13.1737 8.29521 12.5656 8.27244 11.9866 8.41706C11.4077 8.56168 10.8817 8.86773 10.4698 9.29962Z" fill="#59B680"/>
                        </svg>
                    </div>
                    <span>Inquiries</span>
                </div>


                <button (click)="openExportDialog(KeyStatisticsReportExportType.Inquiries)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6054 10L5.66553 4.55531L7.36228 3L14.9989 10L7.36228 17L5.66553 15.4447L11.6054 10Z" fill="#686F7B"/>
                    </svg>
                </button>
            </div>
            <div class="exports-report__body-item">
                <div class="d-flex align-items-center">
                    <div class="bodi-item__icon">
                        <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3101 19.5541V8.63998L15.2675 3.59998H6.03174C5.56722 3.59998 5.19006 3.98218 5.19006 4.43326V19.5667C5.19029 19.7878 5.27826 19.9997 5.43467 20.156C5.59107 20.3122 5.80311 20.4 6.02418 20.4H19.4759C19.5863 20.3992 19.6953 20.3767 19.797 20.3338C19.8986 20.2909 19.9907 20.2283 20.0682 20.1498C20.1457 20.0712 20.2069 19.9782 20.2484 19.876C20.2899 19.7738 20.3108 19.6644 20.3101 19.5541ZM14.4301 9.47998H18.6301V18.72H6.87006V5.27998H14.4301V9.47998ZM11.9499 10.7999V13.351L9.98994 12.4799V16.3999H15.0299V12.4799L11.9499 10.7999Z" fill="#59B680"/>
                        </svg>
                    </div>

                    <span>Basic Report</span>
                </div>
                <button (click)="openExportDialog(KeyStatisticsReportExportType.Basic)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6054 10L5.66553 4.55531L7.36228 3L14.9989 10L7.36228 17L5.66553 15.4447L11.6054 10Z" fill="#686F7B"/>
                    </svg>
                </button>
            </div>

        </div>
    </div>

    <!-- End export card-->

</div>

<ng-template #preloader>
    <div class="key-statistics-report-preloader">
      <span>
      </span>
    </div>
</ng-template>
