import { Component, OnInit, ElementRef } from '@angular/core';

import { AuthService } from '../../../auth/services/auth.service';

import { TimelineComponent } from '../timeline/timeline.component';

@Component({
  selector: 'app-timeline-vertical',
  templateUrl: './timeline-vertical.component.html',
  styleUrls: ['./timeline-vertical.component.scss'],
})
export class TimelineVerticalComponent extends TimelineComponent implements OnInit {
  checkDemo = true;

  protected readonly _authService: AuthService;
  private readonly _elementRef: ElementRef;

  constructor(authService: AuthService, elementRef: ElementRef) {
    super(authService);

    this._authService = authService;
    this._elementRef = elementRef;
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.checkDemo) {
        const items = this._elementRef.nativeElement.ownerDocument.getElementsByClassName('dx-item');
        if (items[16] && items[16].style) {
          items[16].style.zIndex = '1000';
        }
      }
    }, 10000);

    this._authService.infoLoadComplete.subscribe(info => {
      if (info) {
        this.templateItems = info.lists.templateItems;
        this.steps = this.templateItems.filter(x => !x.parentProjectTemplateItem).map(x => {
          return {
            id: x.id,
            name: x.name,
            description: x.description,
          };
        });
      }
    });
  }
}

