import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { LeaseRequestManager } from './lease-request.manager';
import { LeaseRequestRepository } from './lease-request.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    LeaseRequestManager,
    LeaseRequestRepository,
  ],
})
export class LeaseRequestModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LeaseRequestModule> {
    return {
      ngModule: LeaseRequestModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LeaseRequestManager,
        LeaseRequestRepository,
      ],
    };
  }
}
