import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-insurance',
  templateUrl: 'insurance.component.html',
  styleUrls: ['insurance.component.scss'],
})
export class InsuranceComponent extends BaseTermDirective<models.IInsuranceTermViewModel> implements OnInit {
  InsuranceTermType: typeof models.InsuranceTermType;

  options: Array<{name: string, value: models.InsuranceTermType}>;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.leaseTerm) {
      this.leaseTerm = <models.IInsuranceTermViewModel>{};
    }

    this.InsuranceTermType = models.InsuranceTermType;

    this._initializeOptions();
  }

  saveLeaseTerm($event): void {
    this.clearErrors();

    const {insuranceTermType} = this.leaseTerm;
    if (!insuranceTermType) {
      this.addError('You should choose appropriate options.');
      return;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'Custom',
          value: models.InsuranceTermType.Custom,
        }
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.InsuranceTermType.PerCurrentLease,
      },
      {
        name: 'Custom',
        value: models.InsuranceTermType.Custom,
      }
    ];
  }
}
