<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Text
        </div>
        <div class="dx-field-value">
          <dx-text-area name="text" id="text" required
                        [isValid]="form.submitted ? text.valid : true"
                        [(ngModel)]="marker.text" [autoResizeEnabled]="true"
                        #text="ngModel">
          </dx-text-area>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && text.hasError('required')">
            Text is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%"
                     [text]="marker?.id ? 'Update' : 'Create'" [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
