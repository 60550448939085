<div class="occupancy-and-vacancy-report-tooltip"
     *ngIf="occupancyAndVacancyTooltip"
     #occupancyAndVacancyTooltipElementRef>
  <table>
    <thead>
    <tr>
      <th
        [ngSwitch]="occupancyAndVacancyTooltip.occupancyAndVacancyReportTooltipChartType">
        <ng-container *ngSwitchCase="ChartType.SinglePeriod">
          {{occupancyAndVacancyTooltip.startDate | safeDate: 'MMMM' }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{occupancyAndVacancyTooltip.startDate | safeDate: 'y' }}
        </ng-container>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="occupancyAndVacancyTooltip.tooltipType === TooltipType.Occupancy">
      <tr>
        <td>
          <div class="d-flex">
            <span class="value">{{occupancyAndVacancyTooltip.occupancyPercentage | safeNumber: '1.0-0'}}%</span>
            <span class="caption">occupied</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex">
            <span class="value">{{occupancyAndVacancyTooltip.occupancy | safeNumber: '1.0-0'}} SF</span>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="occupancyAndVacancyTooltip.tooltipType === TooltipType.Vacancy">
      <tr>
        <td>
          <div class="d-flex">
            <span class="value">{{occupancyAndVacancyTooltip.vacanciesPercentage | safeNumber: '1.0-0'}}%</span>
            <span class="caption">vacant</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex">
            <span class="value">{{occupancyAndVacancyTooltip.vacancies | safeNumber: '1.0-0'}} SF</span>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
