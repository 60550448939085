<dx-popup class="image-viewer-container"
          [title]="config.title" [showTitle]="false"
          [showCloseButton]="config.showCloseButton" [closeOnOutsideClick]="config.closeOnOutsideClick"
          [width]="config.width" [height]="config.height" [fullScreen]="config.fullScreen"
          [dragEnabled]="config.dragEnabled"
          [maxWidth]="config.maxWidth" [maxHeight]="config.maxHeight" [visible]="isVisible"
          (onShowing)="onShowing.emit($event)" (onShown)="onShown.emit($event)"
          (onHiding)="onHiding.emit($event)" (onHidden)="onHidden.emit($event)"
          (onContentReady)="onContentReady.emit($event)"
          #dxPopupComponent>

  <div class="image-viewer-container_title">
    <div class="title_section">
      <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#floorPlaneScheme"></i>
      <span class="title_text">{{ config.title }}</span>
    </div>

    <div class="title_section">
      <button *ngIf="injectableData?.allowChangeMarkers" type="button" class="addNote" (click)="addNotePressed = true">
        <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#plus"></i>
        add a note
      </button>

      <button type="button" class="close" (click)="hide()">
        <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#cross-close"></i>
      </button>
    </div>
  </div>

  <div class="image-viewer-container-content"
       *ngIf="isReady"
       #contentElement>
    <div *ngIf="addNotePressed && injectableData?.allowChangeMarkers" class="addNote_description">
        <span>
          Click anywhere on the image to add a note.
        </span>
      <button (click)="addNotePressed = false">CANCEL</button>
    </div>
    <app-image-viewer [images]="injectableData?.images" [allowChangeMarkers]="injectableData?.allowChangeMarkers"
                      [container]="contentElement" [activeImageIndex]="injectableData?.activeIndex"
                      [enablePreviewNavigation]="true" [enableArrowNavigation]="injectableData?.enableArrowNavigation"
                      [enableZoom]="injectableData?.enableZoom"
                      (markerCreated)="onMarkerCreated.emit($event)" (markerChanged)="onMarkerChanged.emit($event)"
                      (markerDeleted)="onMarkerDeleted.emit($event)"
                      [addNotePressed]="addNotePressed"
    >
    </app-image-viewer>
  </div>
  <div class="image-viewer-container-preloader"
       *ngIf="!isReady">
    <app-image-viewer-preloader>
    </app-image-viewer-preloader>
  </div>
</dx-popup>
