import { IFileViewModel } from './common.model.generated';

export * from './common.model.generated';

export class Configuration {
  apiEndpoint: string;
  websocketEndpoint: string;
  loggingEnabled: boolean;
  websocketReconnectionAttemptsInterval?: number;
  websocketReconnectionAttemptsLimit?: number;
  renewalOnboardingQuizId: number;
  restructureOnboardingQuizId: number;
  tenantCancellationQuizId: number;
  newDealQuizId: number;
  newDealInquiryQuizId: number;
}

export enum Role {
  Admin = 'Admin',
  Tenant = 'Tenant',
  Landlord = 'Landlord',
  Broker = 'Broker',
  CoBroker = 'CoBroker',
  RepBroker = 'RepBroker',
}

export class SelectItem {

  constructor();
  constructor(id: number, text: string);
  constructor(id?: number, text?: string) {
    this.id = id;
    this.text = text;
  }

  id: number;
  text: string;
  disabled?: boolean;
}

export type File = IFileViewModel;
