import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { isObservable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AlertService } from '../../alert/services/alert.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  private _alertService: AlertService;

  constructor(alertService: AlertService) {
    this._alertService = alertService;
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const canDeactivateValue = component.canDeactivate();

    if (!isObservable(canDeactivateValue)) {
      return this._canDeactivate(canDeactivateValue);
    }

    return canDeactivateValue
      .pipe(
        map((value) => this._canDeactivate(value)),
      );
  }

  private _canDeactivate(value: boolean): boolean {
    return value ?
      true :
      confirm(
        'It looks like you have been editing something. ' +
        'Press Cancel to go back and save these changes, or OK to lose these changes.'
      );
  }
}
