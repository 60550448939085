import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager } from '@statera/sdk/auth';

import { AlertService } from '../../../alert/services/alert.service';
import { AuthService } from '../../services/auth.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {
  @ViewChild('signInForm') signInForm: any;

  vm: models.IPost2faCodeViewModel;

  error: string;

  isLoading: boolean;

  private readonly _authManager: AuthManager;
  private readonly _authService: AuthService;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  private readonly _destroy: Subject<void>;

  constructor(
    authManager: AuthManager,
    authService: AuthService,
    activatedRoute: ActivatedRoute,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
  ) {
    this._authManager = authManager;
    this._authService = authService;
    this._activatedRoute = activatedRoute;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.vm = <models.IPost2faCodeViewModel>{};

    this.isLoading = false;
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this.isLoading = true;

    this._authManager
      .post2faCode({...this.vm})
      .pipe(
        switchMap(() => this._authService.getStartupInfo()),
        switchMap(() => this._activatedRoute.queryParamMap),
        tap((queryParams) => {
          const redirectAfter = queryParams.get('redirectAfter');
          if (redirectAfter) {
            this._router.navigateByUrl(decodeURIComponent(redirectAfter));
            return;
          }

          this._router.navigate(['/']);
        }),
        tap(() => this.isLoading = false),
        catchError(error => {
          this.isLoading = false;

          this.error = error.error || error.message || error.responseText;

          console.error(error);

          return throwError(error);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  signOut() {
    this._authService.logout();
  }

  resend2FACode($event) {
    this._authManager.resend2faCode().subscribe(x => {
      this._alertService.pushSuccessAlert({
        message: this._alertMessagesManager.get2faCodeResentAlertText(),
      });
    });
    $event.preventDefault();
  }
}
