import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager, AlertNotification, AlertType } from '@statera/sdk/alert';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

import { AuthService } from '../../auth/services/auth.service';
import { AlertService } from '../services/alert.service';

import { coBrokerInvitationByBrokerRequestQueryParam } from '../../shared/constants/shared.constants';

@Injectable()
export class CoBrokerInvitationInterceptor implements NotificationInterceptor<AlertNotification> {
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _router: Router;
  private readonly _loggerService: LoggerService;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    router: Router,
    loggerService: LoggerService
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._authService = authService;
    this._router = router;
    this._loggerService = loggerService;
  }

  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert) {
      return notification;
    }

    if (alert.alertType !== AlertType.CoBrokerInvitationByBroker) {
      return notification;
    }

    alert
      .reference
      .confirmed
      .pipe(
        tap(() => {
          this._router
            .navigate(
              [this._authService.role.toLowerCase(), 'dashboard', 'lease', alert.leaseId],
              {
                queryParams: coBrokerInvitationByBrokerRequestQueryParam,
              },
            )
            .then(() => {
              this._loggerService
                .info(LoggerTopic.Alert, 'Navigated to dashboard');
            });
        }),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alert
      .reference
      .declined
      .pipe(
        tap(() => {
          alert.reference
            .hidden
            .pipe(
              tap(() => this._alertService
                .pushInfoAlert({
                  message: this._alertMessagesManager.getCoBrokerInvitationCancelledAlertText(),
                })
              ),
              take(1),
            )
            .subscribe();
        }),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    return notification;
  }
}
