import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'deals-by-stage-report-single-stage-dialog.component.html',
  styleUrls: ['deals-by-stage-report-single-stage-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsByStageReportSingleStageDialogComponent implements OnInit {
  dealsByStageReportLeaseStages: Array<models.IDealsByStageReportLeaseStageViewModel>;
  activeIndex: number;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit(): void {
    this.dealsByStageReportLeaseStages = this.dealsByStageReportLeaseStages || [];
    this.activeIndex = this.activeIndex || 0;
  }

  nextGroup(): void {
    const groups = this.dealsByStageReportLeaseStages;
    if (!groups) {
      return;
    }

    if (groups.length <= ++this.activeIndex) {
      this.activeIndex = 0;
    }

    this._dialogRefService.setTitle(groups[this.activeIndex].stageName);
  }

  prevGroup(): void {
    const groups = this.dealsByStageReportLeaseStages;
    if (!groups) {
      return;
    }

    if (--this.activeIndex < 0) {
      this.activeIndex = groups.length - 1;
    }

    this._dialogRefService.setTitle(groups[this.activeIndex].stageName);
  }
}
