import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { LoggerModule } from '@statera/sdk/logger';

import { NotificationRepository } from './notification.repository';

@NgModule({
  imports: [
    HttpClientModule,
    LoggerModule,
  ],
  providers: [
    NotificationRepository,
  ],
})
export class NotificationModule {
  static forChild(configuration: Configuration): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        NotificationRepository,
      ],
    };
  }
}
