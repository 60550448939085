<form name="form"
      (submit)="saveLeaseTerm($event)"
      *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6 class="radio-group-title">Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container mb-2">
      <div class="col-sm-12 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name"
                          valueExpr="value"
                          itemTemplate="radio"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.securityDepositTermType"
                          (valueChange)="clearErrors()">

            <div *dxTemplate="let data of 'radio'; let index = index">
              <ng-container *ngIf="data.value === SecurityDepositTermType.PerCurrentLease
                                   || data.value === SecurityDepositTermType.MoneyValue
                                   || data.value === SecurityDepositTermType.DeterminedUponReviewOfTenantFinancials">
                <div class="d-flex flex-column">
                  <p>{{ data.name }}</p>
                  <ng-container
                    *ngIf="leaseTerm.securityDepositTermType === SecurityDepositTermType.MoneyValue && data.value === SecurityDepositTermType.MoneyValue">
                    <div class="money-value-field mt-n2 mb-4">
                      <app-input-field [id]="'securityDepositMoneyValue'" [placeholder]="'(e.g. 5)'"
                                       [className]="'real-estate-input'" [min]="0" [step]="0"
                                       [format]="'#,##0.00'" [width]="'130px'"
                                       [errorMessage]="'Month count is required'"
                                       [value]="leaseTerm.securityDepositMoneyValue" [prefix]="'$'"
                                       (valueChange)="leaseTerm.securityDepositMoneyValue = $event; clearErrors()"
                      ></app-input-field>
                    </div>
                    <ng-container *ngTemplateOutlet="schedule"></ng-container>
                  </ng-container>
                </div>
              </ng-container>
              <div class="d-flex flex-column"
                   *ngIf="data.value === SecurityDepositTermType.MonthsCount">
                <div class="insert-row mb-2">
                  <app-input-field appIgnoreScrollwheel
                                   [id]="'monthsCount'" [placeholder]="'(e.g. 5)'"
                                   [className]="'real-estate-input py-0'" [min]="1" [max]="120"
                                   [format]="'#0'" [width]="'65px'"
                                   [errorMessage]="'Month count is required'"
                                   [value]="leaseTerm.monthsCount" [suffix]="'month(s)'"
                                   [disabled]="leaseTerm.securityDepositTermType !== SecurityDepositTermType.MonthsCount"
                                   (valueChange)="leaseTerm.monthsCount = $event; clearErrors()"
                  ></app-input-field>
                  <span>per new Base Rental Rate</span>
                  <ng-container *ngIf="leaseTerm?.securityDepositTermType === SecurityDepositTermType.MonthsCount">
                    <dx-select-box id="beginningOrEndOfTerm" displayExpr="name" valueExpr="value"
                                   class="real-estate-input py-0" placeholder="Choose term date" width="200px"
                                   [dataSource]="beginningOrEndOfTermOptions"
                                   [(value)]="leaseTerm.depositMonths" (valueChange)="clearErrors()">
                      <dx-validator>
                        <dxi-validation-rule type="required"
                                             message="Choose Beginning or End of Term"></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex flex-column"
                   *ngIf="data.value === SecurityDepositTermType.ReducingValue">
                <div class="insert-row mb-2">
                  <span>Reduce Security Deposit to</span>
                  <app-input-field [id]="'securityDepositReducingValue'" [placeholder]="'(e.g. 5)'"
                                   [className]="'real-estate-input py-0'" [min]="1" [max]="1000000" [step]="0"
                                   [format]="'#,##0.00'" [width]="'95px'"
                                   [errorMessage]="'Reduce Security Deposit is required'"
                                   [value]="leaseTerm.securityDepositReducingValue" [prefix]="'$'"
                                   [disabled]="leaseTerm.securityDepositTermType !== SecurityDepositTermType.ReducingValue"
                                   (valueChange)="leaseTerm.securityDepositReducingValue = $event; clearErrors()"
                  ></app-input-field>
                  <span>and apply difference to first months rent</span>
                </div>
                <span class="mb-1">of new Lease Term</span>
              </div>

              <div class="d-flex flex-column"
                   *ngIf="data.value === SecurityDepositTermType.LetterOfCredit">
                <span class="mb-2">Tenant to deliver at their expense the Security Deposit in an irrevocable and unconditional</span>
                <div class="insert-row mb-2">
                  <span>Letter of Credit in the amount of</span>
                  <app-input-field [id]="'securityDepositLetterOfCreditValue'" [placeholder]="'(e.g. 5)'"
                                   [className]="'py-0'" [min]="0" [step]="0"
                                   [format]="'#,##0.00'" [width]="'95px'"
                                   [errorMessage]="'Reduce Security Deposit is required'"
                                   [value]="leaseTerm.amountOfLetterCredit" [prefix]="'$'"
                                   [disabled]="leaseTerm.securityDepositTermType !== SecurityDepositTermType.LetterOfCredit"
                                   (valueChange)="leaseTerm.amountOfLetterCredit = $event; clearErrors()"
                  ></app-input-field>
                </div>

                <ng-container *ngIf="leaseTerm.securityDepositTermType === SecurityDepositTermType.LetterOfCredit">
                  <ng-container *ngTemplateOutlet="schedule"></ng-container>
                </ng-container>
              </div>

              <div class="d-flex flex-column"
                   *ngIf="data.value === SecurityDepositTermType.Guaranty">
                <div class="insert-row mb-2">
                  <dx-select-box id="escalationTaxOptions" displayExpr="name" valueExpr="value"
                                 class="real-estate-input py-0" placeholder="Choose guaranty option" width="150px"
                                 [dataSource]="guarantyOptions"
                                 [disabled]="leaseTerm.securityDepositTermType !== SecurityDepositTermType.Guaranty"
                                 [value]="SecurityGuarantyOptionType.Corporate"
                                 (valueChange)="leaseTerm.securityGuarantyOptionType = $event; clearErrors()">
                    <dx-validator>
                      <dxi-validation-rule type="required"
                                           message="Please select one of the provided options before saving"></dxi-validation-rule>
                    </dx-validator>
                  </dx-select-box>
                  <span>guaranty</span>
                </div>
              </div>
            </div>

            <dx-validator>
              <dxi-validation-rule type="required"
                                   message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div><!--col-->
      <div class="col-sm-6"></div>
    </div><!--row-->

    <!-- Start: Validation summary -->
    <div class="term-toast term-toast-neutral"
         *ngIf="isVisibleBurnDownScheduleContainer() && isBurnDownScheduleOptionDisabled()">
      <i inlineSVG="assets/img/information.svg"></i>
      Burn Down Schedule option will be available once a length of term has been determined.
    </div>

    <div class="term-toast term-toast-neutral"
         *ngIf="isVisibleTenantFinancialDocumentUploadedToast()">
      <i inlineSVG="assets/img/x.svg"></i>
      Tenant has provided Financials for Landlord review.
    </div>

    <div>
      <dx-validation-summary ngForm="form">
      </dx-validation-summary>
      <div class="term-toast term-toast-error"
           *ngFor="let msg of errors">
        <i inlineSVG="assets/img/x.svg"></i>
        {{ msg }}
      </div>
    </div>

    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>

<ng-template #schedule>
  <ng-container *ngIf="isVisibleBurnDownScheduleContainer()">
    <div class="row">
      <dx-check-box class="dx-state-hover" [(value)]="leaseTerm.isBurnDownScheduleChecked"
                    (valueChange)="clearErrors()"
                    [disabled]="isBurnDownScheduleOptionDisabled()">
      </dx-check-box>
      <label class="px-2 sub-text">Add Burn Down Schedule</label>
    </div>

    <ng-container *ngIf="leaseTerm.isBurnDownScheduleChecked">
      <app-security-deposit-burn-down-schedule-table [securityDepositTerm]="leaseTerm"
                                                     [lease]="lease"></app-security-deposit-burn-down-schedule-table>
    </ng-container>
  </ng-container>
</ng-template>
