<table *ngIf="hasQuizAnswers()">
  <thead>
  <tr>
    <th>Question</th>
    <th>Answer</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let quizItem of projectRequest.quizAnswers">
    <td>{{ getQuizQuestionText(quizItem.quizQuestion) }}</td>
    <td [ngSwitch]="quizItem?.quizQuestion?.questionType">
      <!-- Start: QuestionType.File -->
      <ng-container *ngSwitchCase="QuestionType.File">
        <a *ngIf="quizItem.quizAnswerFiles?.length > 0; else noFile"
           [href]="renderAnswer(quizItem)">
          File Uploaded
        </a>
        <ng-template #noFile>
          No File
        </ng-template>
      </ng-container>
      <!-- End: QuestionType.File -->
      <!-- Start: QuestionType.FinancialDocuments -->
      <ng-container *ngSwitchCase="QuestionType.FinancialDocuments">
        <div>
          <div>
            <a role="button" class="text-primary cursor-pointer"
               *ngIf="isThereAnyFinancials(); else noFile"
               (click)="previewFinancials()">
              View Financials
            </a>
            <ng-template #noFile>
              Financials have not been uploaded
            </ng-template>
          </div>
          <ng-container *ngIf="isProjectActive()">
            <ng-container *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId: lease?.id">
              <button type="button" class="btn btn-secondary mt-2"
                      [disabled]="isThereFinancialRequestWithRequestedStatus()"
                      (click)="openRequestFinancialsDialog()">
                Request{{isThereAnyFinancials() ? ' additional ' : ' '}}financial info
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <!-- End: QuestionType.FinancialDocuments -->
      <!-- Start: QuestionType.MultipleChoice -->
      <ng-container *ngSwitchCase="QuestionType.MultipleChoice">
        <ul>
          <li *ngFor="let option of renderAnswer(quizItem)">
            {{ option }}
          </li>
        </ul>
      </ng-container>
      <!-- End: QuestionType.MultipleChoice -->
      <!-- Start: QuestionType.MultipleChoiceMaxTwoItems -->
      <ng-container *ngSwitchCase="QuestionType.MultipleChoiceMaxTwoItems">
        <ul>
          <li *ngFor="let option of renderAnswer(quizItem)">
            {{ option.text }}
          </li>
        </ul>
      </ng-container>
      <!-- End: QuestionType.MultipleChoiceMaxTwoItems -->
      <!-- Start: QuestionType.BrokerInvitation -->
      <ng-container *ngSwitchCase="QuestionType.BrokerInvitation">
        <ng-container *ngIf="quizItem.questionOptionViewModel?.answerType === AnswerType.ProceedWithoutBroker">
        <span>
          Proceed Direct Without a Broker
        </span>
        </ng-container>

        <ng-container *ngIf="quizItem.questionOptionViewModel?.answerType === AnswerType.InviteBroker;">
          <ng-container *ngIf="quizItem?.userInvitationRequests; let brokerInvitations; else unableToShowBrokerInvitation">
            <span>Broker info:</span> <br/>
            <ng-container *ngFor="let brokerInvitation of brokerInvitations; let i = index">
              <span>
                {{ brokerInvitation.firstName }} {{ brokerInvitation.lastName }},
                  {{ brokerInvitation.companyName }},
              </span>

              <span class="text-nowrap">
                <a [href]="'mailto:' + brokerInvitation.emailAddress">{{ brokerInvitation.emailAddress }}</a>
              </span>
              <ng-container *ngIf="i !== brokerInvitations.length - 1">
                <br/>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #unableToShowBrokerInvitation>
          <span>
            Unable to show broker invitation
          </span>
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- End: QuestionType.BrokerInvitation -->
      <ng-container *ngSwitchDefault>
        <div class="quiz-answer">{{ renderAnswer(quizItem) }}</div>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
