import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';

import { AuthRouterModule } from './auth-router.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { NoAuthGuard } from './guards/no-auth.guard';

import { AuthService } from './services/auth.service';
import { TwoFactorAuthComponent } from './components/two-factor-auth/two-factor-auth.component';
import { StateraUserClaimService } from './services/statera-user-claim.service';

import { AuthenticationComponent } from './components/authentication/authentication.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordRequestComponent } from './components/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    AuthRouterModule,
    InfrastructureModule,

    DxRadioGroupModule,
    DxTextBoxModule,
    DxSelectBoxModule
  ],
  declarations: [
    AuthenticationComponent,
    RegisterComponent,
    ResetPasswordRequestComponent,
    ResetPasswordComponent,
    TwoFactorAuthComponent,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        NoAuthGuard,
        StateraUserClaimService
      ],
    };
  }
}


