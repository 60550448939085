<dx-file-uploader id="file" name="file"
                  [uploadUrl]="uploadUrl" [visible]="false" [accept]="getAcceptFiles()"
                  (onUploadStarted)="onUploadStarted()" (onUploaded)="onUploaded($event)"
                  (onUploadError)="onUploadError($event)"
                  (onUploadAborted)="onUploadAborted()" #fileUploader>
</dx-file-uploader>

<dx-popup width="95%" height="95%" [title]="getPopupTitle()"
          [minWidth]="600" [showTitle]="true" [showCloseButton]="true" [dragEnabled]="false" [(visible)]="popupVisible"
          (onShown)="onPopupShown()" (onHidden)="onPopupHidden($event)"
          #dxPopupComponent>
  <div class="popup-body lease-documents-demo">
    <div class="doc-columns">
      <div class="column rounded column-file-versions">
        <div class="column-header">File Name and Versions</div>
        <div class="column-content">
          <div class="column-content-field">
            <span class="status" [ngClass]="{ 'error': hasActionRequired(), 'success': !hasActionRequired()}">
              <span *ngIf="hasActionRequired()">Action Required</span>
              <span *ngIf="!hasActionRequired()">Pending Response</span>
              <span class="indicator"></span>
            </span>
          </div>
          <div class="column-content-field">
            <div class="column-content-field-label">File name:</div>
            <div class="column-content-field-value">{{versionedFile?.name}}</div>
          </div>
          <div class="column-content-field column-content-versions-list">
            <div class="column-content-field-label">Versions:</div>
            <div class="column-content-field-value">
              <dx-list height="100%" selectionMode="single"
                       [dataSource]="versionedFiles" [keyExpr]="'id'" [(selectedItems)]="selectedVersionedFiles"
                       (onItemClick)="onVersionSelected($event)" #versionsList>
                <div *dxTemplate="let item of 'item'; let itemIndex = index" class="version">
                  <div class="version-number">
                    {{versionedFiles?.length - itemIndex}}
                  </div>
                  <div class="version-avatar">
                    <img alt
                         [src]="item.currentEditor?.avatar?.url ? item.currentEditor?.avatar?.url : 'assets/img/avatar.png'"/>
                  </div>
                  <div>
                    <div class="version-author">
                      <span>
                        {{item.fileVersion?.name}}
                        <ng-container *ngIf="versionedFiles?.length - itemIndex === 1">
                          (Original Draft)
                        </ng-container>
                        <ng-container *ngIf="versionedFiles?.length - itemIndex !== 1">
                          (Version {{versionedFiles?.length - itemIndex}})
                        </ng-container>
                      </span>
                    </div>
                    <div class="version-date">
                      {{item?.fileVersion?.createdOn ? (item?.fileVersion?.createdOn | safeDate: 'hh:mm aaa MM/dd/yyyy') : 'File ' + item.id}}
                    </div>
                  </div>
                </div>
              </dx-list>
            </div>
          </div>
        </div>
      </div>

      <div class="column column-doc ml-3 mr-3">
        <div class="column-header">Document Preview</div>

        <dx-toolbar class="p-1">
          <dxi-item location="before">
            <dx-button icon="fa fa-download" text="Download" class="download-button"
                       (onClick)="download($event)">
            </dx-button>
          </dxi-item>
        </dx-toolbar>

        <div class="column-content column-content-doc"
             #pdfViewerContainer>
          <ng-container *ngIf="versionedFile?.pdfFileVersion?.url as pdfFile">
            <pdf-viewer [style.height.px]="pdfViewerHeight"
                        [src]="pdfFile" [render-text]="true" [original-size]="false"
                        (after-load-complete)="handlePDFLoadComplete($event)"
                        #pdfViewer>
            </pdf-viewer>
          </ng-container>
        </div>

        <div class="column-doc-footer">
          Please remember to only submit redline versions with Track Changes
        </div>
      </div>

      <div class="column column-comments rounded">
        <div class="column-header">Comments</div>
        <div>
          <div class="d-flex flex-row align-items-center justify-content-between flex-wrap">
            <div class="mb-2">
              <dx-tabs [items]="tabs" [(selectedIndex)]="selectedTabIndex"  itemTemplate="tabTemplate"
                       (onSelectionChanged)="selectionChanged($event)">
                <div *dxTemplate="let itemData of 'tabTemplate'; let itemIndex = index">
                  <span class="b-title">{{itemData.text}}</span>
                </div>
              </dx-tabs>
            </div>
            <div class="mx-2 mb-2">
              <app-avatar-list *ngIf="commentProfiles" [avatarList]="avatarItems">
              </app-avatar-list>
            </div>
          </div>
        </div>
        <div class="column-content d-flex flex-folumn">
          <app-lease-document-comments [messageManager]="versionedFileCommentManager"
                                       [allowAdd]="isShowChatMessages || !!leaseVersionedFile"
                                       [versionedFiles]="versionedFiles"
                                       (versionedFileSelected)="handleVersionedFileChange($event)">
          </app-lease-document-comments>
        </div>
      </div>
    </div>

    <div class="popup-buttons"
         *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id">
      <div class="popup-buttons-demo">
        <dx-button class="mx-2" text="Delete Version" type="danger"
                   [visible]="isVisibleDeleteButton"
                   (click)="delete()">
        </dx-button>
        <dx-button class="mx-2" type="success"
                   [text]="isCleanCopySubmittal ? 'Upload Clean Copy' : 'Upload Document'"
                   [visible]="isVisibleUploadButton || isDemo"
                   (click)="upload($event)">
        </dx-button>
        <dx-button class="mx-2" type="success"
                   [text]="getSubmitButtonText()"
                   [visible]="isVisibleSubmitButton"
                   (click)="submit()">
        </dx-button>
        <dx-button class="mx-2" type="success"
                   text="Send for Approval"
                   [visible]="isVisibleSendForApprovalButton"
                   (click)="sendForApproval()">
        </dx-button>
        <dx-button class="mx-2" type="success"
                   text="Pending Internal Approval"
                   [visible]="isVisiblePendingInternalApprovalButton"
                   [disabled]="true">
        </dx-button>
        <dx-button class="mx-2" type="success"
                   [text]="getAcceptButtonText()"
                   [visible]="isVisibleAcceptButton || isDemo"
                   (click)="accept()">
        </dx-button>
        <dx-button class="mx-2" text="Go to Signature" type="success"
                   [visible]="isVisibleGoToSignatureButton"
                   (click)="goToSignature()">
        </dx-button>
      </div>

    </div>
  </div>
</dx-popup>

<app-lease-documents-demo #leaseDocumentsDemoComponent>
</app-lease-documents-demo>
