<app-timeline
  *ngIf="!isInquiriesTab() && currentProject && currentProject.projectTypeId !== ProjectTypeEnum.NewDealInquiry"
  [project]="currentProject">
</app-timeline>

<section *ngIf="noDataPendingVisible">
  <div class="card mt-3 m-1">

    <div class="green-block">
    </div>

    <img src="https://i.ibb.co/7NztCLp/avatar.png" alt="">

    <h6 class="heading">
      Please wait for the lease abstract to be finalized.
      You will be notified shortly & on your way to renewing your lease!
    </h6>
  </div>
</section>

<section class="container negotiate-block"
         *ngIf="!noDataPendingVisible">
  <div class="row">
    <div class="col-lg-4 order-md-1">
      <h1 class="b-title">
        Building & Lease
      </h1>
      <!--b-title-->

      <section class="b-lease wrapper-for-empty-message">
        <figure class="b-lease-media">
          <app-building-attachments [attachments]="buildingPictures">
          </app-building-attachments>
        </figure>
        <!--b-lease-media-->

        <header class="b-lease-head">
          <h1 class="b-lease-head-text">
            <span class="icon-pointer"></span>
            {{ lease?.building?.address?.displayString }}
          </h1>
          <!--b-lease-head-text-->
          <ng-container *appIsFeatureEnabled="Feature.ColaboFeature">
            <ng-container
              *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease.id">

              <div class="d-flex flex-nowrap w-100">
                <ng-container *ngIf="!isInquiriesTab(); else inquiriesTemplate">
                  <div *ngIf="canStartNegotiation()">
                    <button type="button"
                            class="btn btn-md btn-success"
                            (click)="onStartNegotiation(startNegotiationBtn)"
                            #startNegotiationBtn>
                      Start negotiation
                    </button>

                    <ng-container *ngIf="getNegotiationTooltip() as tooltipText">
                      <dx-tooltip position="top"
                                  showEvent="dxhoverstart" hideEvent="dxhoverend"
                                  [target]="startNegotiationBtn"
                                  [width]="250"
                                  [closeOnOutsideClick]="false"
                                  #negotiationTooltip>
                        <div>
                          {{ tooltipText }}
                        </div>
                      </dx-tooltip>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-template #inquiriesTemplate>
                  <div class="pr-2 flex-grow-1"
                       (mouseenter)="rfpTooltip.visible = !canSendRfp()"
                       (mouseleave)="rfpTooltip.visible = false"
                       #sendRfpContainerElement>
                    <button type="button"
                            class="btn b-btn b-btn-default w-100"
                            (click)="convertInquiryToDeal(sendRfpButton)"
                            [disabled]="!canSendRfp()"
                            #sendRfpButton>
                      Send RFP
                    </button>
                    <dx-tooltip position="top"
                                [target]="sendRfpContainerElement"
                                [width]="250"
                                [closeOnOutsideClick]="false"
                                #rfpTooltip>
                      <div>
                        {{ getSendRfpButtonTooltip() }}
                      </div>
                    </dx-tooltip>
                  </div>

                  <div class="flex-grow-1"
                       (mouseenter)="tourTooltip.visible = isTourButtonDisabled()"
                       (mouseleave)="tourTooltip.visible = false"
                       #tourButtonContainerElement>
                    <button id="tourButton"
                            class="btn b-btn b-btn-default w-100"
                            [disabled]="isTourButtonDisabled()"
                            (click)="showTourRequestForm()"
                            #tourButton>
                      Request Tour
                    </button>
                    <dx-tooltip position="top"
                                [target]="tourButtonContainerElement"
                                [width]="250"
                                [closeOnOutsideClick]="false"
                                #tourTooltip>
                      <div>
                        {{ getTourAlreadyRequestedButtonTooltip() }}
                      </div>
                    </dx-tooltip>
                  </div>
                </ng-template>
              </div>

            </ng-container>
          </ng-container>
        </header>
        <!--b-lease-head-->

        <!-- Start: Quick Links -->
        <ng-container *ngIf="id">
          <h6>Quick Links</h6>
          <ul class="quick-links"
              *ngIf="id">
            <ng-container
              *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
              <li>
                <a role="button" href="javascript:void(0)"
                   (click)="showManageTeamDialog()">
                  <i class="quick-link-icon manage-team"></i>
                  <span>Manage Team</span>
                </a>
              </li>
            </ng-container>
            <ng-container
              *appCheckAccess="StateraClaimType.Team_Member_CoBroker; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
              <li>
                <a role="button" href="javascript:void(0)"
                   (click)="showManageTeamDialog()">
                  <i class="quick-link-icon manage-team"></i>
                  <span>Manage Team</span>
                </a>
              </li>
            </ng-container>
            <ng-container
              *appCheckAccess="StateraClaimType.Documents; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
              <li>
                <a role="button" href="javascript:void(0)"
                   [routerLink]="['/profile/documents']" [queryParams]="{ leaseId: id }">
                  <i class="quick-link-icon documents"></i>
                  <span>Documents</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="!isInquiriesTab()">
              <ng-container
                *appCheckAccess="StateraClaimType.Schedule; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
                <li *ngIf="isProjectScheduleFeatureEnabled | async">
                  <a role="button" href="javascript:void(0)"
                     (click)="navigateToProjectSchedule()">
                    <i class="quick-link-icon project-schedule"></i>
                    <span>Project Schedule</span>
                  </a>
                </li>
              </ng-container>
            </ng-container>
            <ng-container
              *appCheckAccess="StateraClaimType.SitePlan; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
              <li>
                <a role="button" href="javascript:void(0)"
                   (click)="showSitePlanDialog()">
                  <i class="quick-link-icon site-plan"></i>
                  <span>Site Plan</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="!isInquiriesTab()">
              <ng-container
                *appCheckAccess="StateraClaimType.LeaseAnalysis; stateraClaimValue: StateraClaimValue.Read; leaseId:id">
                <li>
                  <a role="button"
                     (click)="handleLeaseAnalysisClick()"
                  >
                    <i class="quick-link-icon lease-analysis"></i>
                    <span>Lease Analysis</span>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
        <!-- End: Quick Links -->

        <!--        <h6>Information</h6>-->
        <article class="b-lease-body">
          <app-building [building]="lease?.building" [buildingUnit]="lease?.buildingUnit">
          </app-building>
        </article>
        <!--b-lease-body-->
      </section>
      <!--b-lease-->
    </div>
    <!--col-->

    <div class="col-lg-8 d-flex flex-column">
      <!--      <h1 class="b-title">Lease Abstract(s)</h1>-->
      <dx-tabs accessKey="id" keyExpr="id" [dataSource]="projectTabs" [(selectedIndex)]="selectedProjectTabIndex"
               (selectedIndexChange)="projectTabChanged($event)"></dx-tabs>
      <!--b-title-->

      <div class="c-card flex-grow-1 mb-3" *ngIf="!isInquiriesTab()">
        <dx-tabs itemTemplate="tabTitle" accessKey="id" keyExpr="id"
                 [showNavButtons]="true" [scrollingEnabled]="true"
                 [dataSource]="leaseTabs" [(selectedIndex)]="selectedLeaseTabIndex"
                 (onItemClick)="selectLeaseTab($event)">
          <div *dxTemplate="let tab of 'tabTitle'; index as i">
            <span>{{ tab.title }}</span>
            <span class="indicator"
                  [ngClass]="getProjectIndicatorClass(i)">
            </span>
          </div>
        </dx-tabs>
        <app-lease-term-dashboard [lease]="lease" [leaseTermConfigurations]="leaseTermConfigurations"
                                  [project]="currentProject"
                                  *ngIf="!isInquiriesTab()">
        </app-lease-term-dashboard>
      </div>

      <div class="c-card flex-grow-1 mb-3" *ngIf="isInquiriesTab()">
        <dx-tabs itemTemplate="tabTitle" accessKey="id" keyExpr="id"
                 [showNavButtons]="true" [scrollingEnabled]="true"
                 [dataSource]="inquiryTabs" [(selectedIndex)]="selectedInquiryTabIndex"
                 (onItemClick)="selectInquiryTab($event)">
          <div *dxTemplate="let tab of 'tabTitle'; index as i">
            <span>{{ tab.title }}</span>
            <span class="indicator"
                  [ngClass]="getProjectIndicatorClass(i)">
            </span>
          </div>
        </dx-tabs>

        <ng-container *ngIf="tenantRequest">
          <h2
            class="project-request-title">{{ tenantRequest.createdAt | date: 'longDate' }} {{ getTenantRequestTypeName() }}
            request</h2>
          <app-project-request-summary [lease]="lease" [projectRequest]="tenantRequest">
          </app-project-request-summary>
        </ng-container>
      </div>
      <!--c-card-->
    </div>
    <!--col-->
  </div>
  <!--row-->
</section>
<!--container-->

<section *ngIf="id" class="negotiate-mobile">
  <dx-select-box class="lease-selector" placeholder="Choose Lease abstract"
                 displayExpr="text" valueExpr="id"
                 [dataSource]="leaseSelectItems" [(value)]="leaseId"
                 (onSelectionChanged)="onSelectionChanged($event)"
                 #leaseSelectBox>
  </dx-select-box>

  <div class="border-bottom border-top tabs pt-2">
    <span class="text-secondary  text-capitalize tab"
          [ngClass]="{ 'text-dark border-bottom border-primary border-2': activeTab == 'Details' }"
          (click)="clickTab('Details')">
      Details
    </span>
    <span class="text-secondary  text-capitalize tab"
          [ngClass]="{ 'text-dark border-bottom border-primary border-2': activeTab == 'Lease abstract' }"
          (click)="clickTab('Lease abstract')">
      Lease abstract
    </span>
    <span class="text-secondary text-capitalize tab"
          [ngClass]="{ 'text-dark border-bottom border-primary border-2': activeTab == 'Timeline' }"
          (click)="clickTab('Timeline')">
      Timeline
    </span>
  </div>

  <div *ngIf="activeTab === 'Timeline'">
    <div class="d-flex align-items-start dashes dashes-position" *ngFor="let step of steps; let i = index">
      <div class=" d-flex pl-3 ">
        <div class="step d-flex flex-column " [ngClass]="{ 'bg-primary': step.id === activeStepId }"
             (click)="clickStep(i)">
          <span>
            {{ i + 1 }}
          </span>
        </div>
      </div>

      <div class="d-flex pl-2 pb-2 flex-grow-1">
        <div class="d-flex flex-column pb-2 flex-grow-1">
          <span class="text-14 text-capitalize pb-1 step-name text-muted"
                [ngClass]="{ 'fw-600 step-active': step.id === activeStepId }">
            {{ step.name }}
          </span>
          <span class="text-12 text-muted step-sub-text" *ngIf="step.active">
            {{ step.description }}
          </span>
        </div>
        <span class="text-13 text-muted  step-date pr-3" *ngIf="step.active">
          {{ getMilestoneInterval(i) }}
        </span>
      </div>
    </div>
  </div>

  <div class="pt-1" *ngIf="activeTab === 'Lease abstract'">
    <div class="card pt-1 border-right-0 border-left-0 rounded-0">
      <div class="card-body">
        <app-lease-term-dashboard [lease]="lease" [leaseTermConfigurations]="leaseTermConfigurations"
                                  [isMobileView]="true" [project]="currentProject">
        </app-lease-term-dashboard>
      </div>
    </div>
  </div>

  <div class="pr-2 pl-2 pt-1" *ngIf="activeTab === 'Details'">
    <div class="card p-0">
      <div class="details-image">
        <div class="d-flex flex-column details-buttons">
          <span class="details-button" inlineSVG="assets/img/renew my lease.svg" (click)="renewLease()"
                *ngIf="isShowRenewalButton()">
          </span>
          <span class="details-button" inlineSVG="assets/img/schedule.svg"
                *ngIf="isProjectScheduleFeatureEnabled | async"
                (click)="navigateToProjectSchedule()"> </span>
          <span class="details-button" inlineSVG="assets/img/documents.svg" routerLink="/profile/documents">
          </span>
        </div>
        <img class="card-img-top" [src]="buildingPicture" alt="Details image"/>
      </div>

      <div class="card-body pl-2 pb-3">
        <div class="d-flex flex-column pb-3">
          <div class="d-flex flex-column">
            <span class="text-dark fw-600 text-14 pb-1">
              Landlord
            </span>
            <span class="text-muted text-16">
              {{ lease?.landlordCompany?.name }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <span class="pr-2" inlineSVG="assets/img/map-pin-icon.svg"> </span>
            <span>Address</span>
          </div>
          <span class="text-primary pb-1 text-14">
            {{ lease?.building?.address?.displayString }}
          </span>
        </div>

        <app-building [building]="lease?.building"></app-building>
      </div>
    </div>
  </div>
</section>
