import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { CommonTools } from '@statera/sdk/common';

@Injectable()
@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform<T>(input: T, config?: any): T;
  transform(input: Array<any>, config?: any): Array<any>;

  transform(input: any, config?: any): any {
    if (!Array.isArray(input)) {
      return input;
    }

    const out = [...input];

    // sort by multiple properties
    if (Array.isArray(config)) {
      return out.sort((a, b) => {
        for (let i = 0, num = config.length; i < num; ++i) {
          const [prop, asc] = this._extractFromConfig(config[i]);
          const pos = this._orderCompare(prop, asc, a, b);
          if (pos !== 0) {
            return pos;
          }
        }

        return 0;
      });
    }

    // sort by a single property value
    if (CommonTools.isString(config)) {
      const [prop, asc, sign] = this._extractFromConfig(config);

      if (config.length === 1) {
        // tslint:disable-next-line:switch-default
        switch (sign) {
          case '+':
            return out.sort(this._simpleSort.bind(this));

          case '-':
            return out.sort(this._simpleSort.bind(this)).reverse();
        }
      }

      return out.sort(this._orderCompare.bind(this, prop, asc));
    }

    // default sort by value
    return out.sort(this._simpleSort.bind(this));
  }

  private _simpleSort(a: any, b: any) {
    return CommonTools.isString(a) && CommonTools.isString(b) ? a.toLowerCase().localeCompare(b.toLowerCase()) : a - b;
  }

  private _orderCompare(prop: string, asc: boolean, a: any, b: any) {
    const first = this._extractDeepPropertyByMapKey(a, prop);
    const second = this._extractDeepPropertyByMapKey(b, prop);

    if (first === second) {
      return 0;
    }

    if (CommonTools.isUndefined(first) || first === '') {
      return 1;
    }

    if (CommonTools.isUndefined(second) || second === '') {
      return -1;
    }

    if (CommonTools.isString(first) && CommonTools.isString(second)) {
      const pos = first.toLowerCase().localeCompare(second.toLowerCase());

      return asc ? pos : -pos;
    }

    return asc ? first - second : second - first;
  }

  private _extractFromConfig(config: any) {
    const sign = config.substr(0, 1);
    const prop = config.replace(/^[-+]/, '');
    const asc = sign !== '-';

    return [prop, asc, sign];
  }

  private _extractDeepPropertyByMapKey(obj: any, map: string): any {
    const keys = map.split('.');
    const head = keys.shift();

    return keys.reduce(
      (prop: any, key: string) => {
        if (!CommonTools.isUndefined(prop) && !CommonTools.isNull(prop) && !CommonTools.isUndefined(prop[key])) {
          return prop[key];
        }

        return undefined;
      },
      obj[head || '']
    );
  }
}
