<ng-container *ngIf="isValid() && isProjectStarted()">
  <div class="gantt-chart-wrapper"
       #ganttChartWrapper>
    <div class="gantt-chart">
      <!-- Start: Aside -->
      <!-- Note: Don't delete gantt-chart-aside-demo, It uses for tutorial-->
      <div class="gantt-chart-aside gantt-chart-aside-demo"
           #ganttChartAside>
        <div class="gantt-chart-aside-menu large"
             [style.padding-bottom.px]="getHorizontalScrollbarHeight(ganttChartScroller)">
          <ul class="gantt-chart-groups">
            <li class="gantt-chart-groups-header">
              <div>
                <a role="button" class="d-flex flex-row flex-nowrap justify-content-center cursor-pointer mx-n1"
                   (click)="back()">
                  <span class="px-1">
                    <span class="icon-backward small"></span>
                  </span>
                  <span class="px-1">
                    Back
                  </span>
                </a>
              </div>
              <div class="gantt-chart-groups-header-info">
                <a role="button" class="cursor-pointer"
                   #ganttChartHeaderInfo>
                  <span class="icon-info mx-2"></span>

                  <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content" position="top"
                              [target]="ganttChartHeaderInfo">
                    <div class="gantt-chart-tooltip"
                         *dxTemplate="let data of 'content'">
                      <p class="gantt-chart-tooltip-content text-center">
                        This will slide open additional details on Stage status and exact due dates for each Stage per a suggested schedule.
                      </p>
                    </div>
                  </dx-tooltip>
                </a>
              </div>
            </li>
            <li *ngFor="let point of getChartPoints(); let i = index;">
              <span>
                <a role="button" class="cursor-pointer"
                   (click)="scrollToChartPoint(point)">
                  {{i + 1}}. {{point.group}}
                </a>
              </span>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="isStatusesVisible">
          <div class="gantt-chart-aside-menu light">
            <ul class="gantt-chart-groups">
              <li class="gantt-chart-groups-header">
                <div>
                  Due Date
                </div>
              </li>
              <li *ngFor="let point of getChartPoints(); let i = index;">
                <span>
                  {{point.endDate | date: 'M/dd/yyyy'}}
                </span>
              </li>
            </ul>
          </div>
          <div class="gantt-chart-aside-menu dark">
            <ul class="gantt-chart-groups">
              <li class="gantt-chart-groups-header">
                <div>
                  Status
                </div>
              </li>
              <li *ngFor="let point of getChartPoints(); let i = index;">
                <span>
                  {{getChartPointStatusText(point)}}
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
        <div class="gantt-chart-aside-menu show-statuses-button">
          <a role="button" class="gantt-chart-statuses-button cursor-pointer"
             (click)="isStatusesVisible = !isStatusesVisible">
            <span [class.icon-forward]="!isStatusesVisible" [class.icon-backward]="isStatusesVisible">
            </span>
          </a>
        </div>
      </div>
      <!-- End: Aside -->
      <!-- Start: View -->
      <!-- Note: Don't delete gantt-chart-demo, It uses for tutorial-->
      <div class="gantt-chart-view-wrapper gantt-chart-demo">
        <div class="gantt-chart-view-scroller"
             #ganttChartScroller>
          <div class="gantt-chart-view">
            <!-- Start: Columns -->
            <div class="gantt-chart-columns"
                 #ganttChartColumns>
              <!-- Start: Column -->
              <div class="gantt-chart-column"
                   *ngFor="let datePoint of datePoints">
                <!-- Start: Column month -->
                <div class="gantt-chart-column-month"
                     [title]="getDatePointMonth(datePoint)">
                  <ng-container *ngIf="8 <= datePoint.days?.length">
                    {{getDatePointMonth(datePoint)}}
                  </ng-container>
                </div>
                <!-- End: Column month -->
                <!-- Start: Column days -->
                <div class="gantt-chart-column-days">
                  <div class="gantt-chart-column-day"
                       *ngFor="let day of datePoint.days; let i = index;"
                       [class.today]="isTodayDatePoint(datePoint, day)"
                       [class.end]="isEndDatePoint(datePoint, day)"
                       [class.lxp]="isSixMonthPriorToLXP(datePoint, day)"
                       [class.lxp-day]="isLeaseExpirationDatePoint(datePoint, day)"
                       #ganttChartColumn>
                    <div class="gantt-chart-column-day-header">
                      <span>
                        {{day}}
                      </span>
                    </div>

                    <!-- Start: Column day trigger with tooltip -->
                    <div class="gantt-chart-column-day-trigger"
                         *ngIf="isTodayDatePoint(datePoint, day)
                             || isEndDatePoint(datePoint, day)
                             || isSixMonthPriorToLXP(datePoint, day)
                             || isLeaseExpirationDatePoint(datePoint, day)"
                         #ganttChartColumnDayTrigger>
                      <!-- Start: Tooltip -->
                      <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content" position="top"
                                  [target]="ganttChartColumnDayTrigger">
                        <div class="gantt-chart-tooltip"
                             *dxTemplate="let data of 'content'">
                          <p class="gantt-chart-tooltip-header text-uppercase">
                            <ng-container *ngIf="isTodayDatePoint(datePoint, day) && !isEndDatePoint(datePoint, day)">
                              Today’s Activity
                            </ng-container>
                            <ng-container *ngIf="isEndDatePoint(datePoint, day) && !isTodayDatePoint(datePoint, day)">
                              Project Completion Date
                            </ng-container>
                            <ng-container *ngIf="isSixMonthPriorToLXP(datePoint, day)">
                              6 Months Prior To LXP
                            </ng-container>
                            <ng-container *ngIf="isLeaseExpirationDatePoint(datePoint, day)">
                              Lease Expiration Date
                            </ng-container>
                            <ng-container *ngIf="isTodayDatePoint(datePoint, day) && isEndDatePoint(datePoint, day)">
                              Today’s Activity / Project Completion Date
                            </ng-container>
                          </p>
                          <p class="gantt-chart-tooltip-content blue">
                            {{getDatePointDate(datePoint, day).toDate() | date: 'EEE, MMM dd, yyyy'}}
                          </p>
                        </div>
                      </dx-tooltip>
                      <!-- End: Tooltip -->
                    </div>
                    <!-- End: Column day trigger with tooltip -->
                  </div>
                </div>
                <!-- End: Column days -->
              </div>
              <!-- End: Column -->
              <!-- Start: Points -->
              <div class="gantt-chart-points">
                <div class="gantt-chart-point-item"
                     *ngFor="let point of getChartPoints();">
                  <div class="gantt-chart-point-wrapper"
                       [style.width.px]="point.style.width" [style.left.px]="point.style.left">
                    <div class="gantt-chart-point">
                      <div class="gantt-chart-point-view"
                           [id]="'gantt-chart-point-'+ point.index"
                           [class.blue]="!isActiveChartPoint(point)" [class.dark]="isActiveChartPoint(point) && !isDelayedProject()"
                           [class.red]="isActiveChartPoint(point) && isDelayedProject()"
                           (mouseover)="handleMouseOver($event, ganttChartPoint, ganttChartPointTooltip)"
                           #ganttChartPoint>
                        <span>
                          {{point.daysCount | i18nPlural: ({'=1': '# day', 'other': '# days'})}}
                        </span>

                        <!-- Start: Tooltip -->
                        <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" contentTemplate="content" position="top"
                                    [target]="ganttChartPoint" #ganttChartPointTooltip>
                          <div class="gantt-chart-tooltip"
                               *dxTemplate="let data of 'content'">
                            <p class="gantt-chart-tooltip-header mb-2">
                              {{point.milestone?.templateItem?.description}}
                            </p>
                            <p class="gantt-chart-tooltip-content">
                              {{point.startDate.toDate() | date: 'EEE, MMM dd, yyyy'}}
                              –
                              {{point.endDate.toDate() | date: 'EEE, MMM dd, yyyy'}}
                            </p>
                            <p class="gantt-chart-tooltip-content bold">
                              {{point.daysCount | i18nPlural: ({'=1': '# day duration', 'other': '# days duration'})}}
                            </p>
                            <hr class="my-2" />
                            <ng-container *ngIf="getChartPointRenewalDateDaysCount(point) as point">
                              <p class="gantt-chart-tooltip-content" [class.error-msg]="point?.isExpired">
                                {{point?.value}}
                              </p>
                            </ng-container>
                            <p class="gantt-chart-tooltip-content bold mt-1"
                               *ngIf="isDelayedProject()">
                              Project completion date is delayed
                            </p>
                          </div>
                        </dx-tooltip>
                        <!-- End: Tooltip -->
                      </div>

                      <div class="gantt-chart-schedule-offset right"
                           *ngIf="getChartPointStatus(point) === chartPointStatuses.Complete"
                           #ganttChartScheduleLabel>
                        <span class="text-uppercase">
                          <ng-container *ngIf="getScheduleOffset(point) === 0">
                            On Schedule
                          </ng-container>
                          <ng-container *ngIf="0 < getScheduleOffset(point)">
                            {{(getScheduleOffset(point) | abs) | i18nPlural: ({'=1': '# day', 'other': '# days'})}} ahead
                          </ng-container>
                          <ng-container *ngIf="getScheduleOffset(point) < 0">
                            {{(getScheduleOffset(point) | abs) | i18nPlural: ({'=1': '# day', 'other': '# days'})}} behind
                          </ng-container>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- End: Points -->
            </div>
            <!-- End: Columns -->
          </div>
        </div>
      </div>
      <!-- End: View -->
    </div>
  </div>
</ng-container>
<p class="text-center text-muted mx-4"
   *ngIf="!isValid()">
  Sorry, something was wrong
</p>
<p class="text-center text-muted"
   *ngIf="isValid() && !isProjectStarted()">
  This project has not started yet
</p>
