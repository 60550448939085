import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { FeatureTogglingManager } from '@statera/sdk/feature-toggling';
import { Observable, of, Subject, zip } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { Role } from '@statera/sdk/auth';

import * as models from './infrastructure/models/generated';

import { AuthService } from './auth/services/auth.service';
import { StateraUserClaimService } from './auth/services/statera-user-claim.service';
import { MonitoringService } from './infrastructure/services/monitoring.service';
import { ProgressBarService } from './infrastructure/services/progress-bar.service';
import { ScriptLoaderService, ScriptModel } from './infrastructure/services/script-loader.service';
import { OnboadringPopupComponent } from './shared/components/onboadring-popup/onboadring-popup.component';
import { AppService } from './shared/services/app.service';
import { ProjectService } from './shared/services/project.service';
import { ApplicationQuery } from './shared/states/application/application.query';
import {AlertService as UIAlertService} from './alert/services/alert.service';

type WindowWithDataLayer = any; // Window & {dataLayer: Array<[string, unknown]>};
type WindowWithCalendly = any; // Window & {Calendly: {initBadgeWidget: (arg: unknown) => void}};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly _googleAnalyticsScriptUrl: string = 'https://www.googletagmanager.com/gtag/js?id=';
  private static readonly _calendlyScriptUrl: string = 'https://assets.calendly.com/assets/external/widget.js';

  @ViewChild(NgProgressComponent) progressBarComponent: NgProgressComponent;
  @ViewChild(OnboadringPopupComponent) onboadringPopupComponent: OnboadringPopupComponent;

  private readonly _authService: AuthService;
  private readonly _progressBarService: ProgressBarService;
  private readonly _appService: AppService;
  private readonly _applicationQuery: ApplicationQuery;
  private readonly _scriptLoaderService: ScriptLoaderService;
  private readonly _stateraUserClaimService: StateraUserClaimService;
  private readonly _featureTogglingManager: FeatureTogglingManager;
  private readonly _projectService: ProjectService;
  private readonly _router: Router;
  private readonly _monitoringService: MonitoringService;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    progressBarService: ProgressBarService,
    appService: AppService,
    applicationQuery: ApplicationQuery,
    scriptLoaderService: ScriptLoaderService,
    stateraUserClaimService: StateraUserClaimService,
    featureTogglingManager: FeatureTogglingManager,
    projectService: ProjectService,
    router: Router,
    monitoringService: MonitoringService
  ) {
    this._authService = authService;
    this._progressBarService = progressBarService;
    this._appService = appService;
    this._applicationQuery = applicationQuery;
    this._scriptLoaderService = scriptLoaderService;
    this._stateraUserClaimService = stateraUserClaimService;
    this._featureTogglingManager = featureTogglingManager;
    this._projectService = projectService;
    this._router = router;
    this._monitoringService = monitoringService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._appService
      .getConfiguration()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._authService
      .getStartupInfo()
      .pipe(
        tap((startupInfo: models.IStartupInfoViewModel) => {
          if (
            startupInfo &&
            !startupInfo.emptyProfile &&
            startupInfo.introsShown &&
            startupInfo.userStatus === models.UserStatus.Activated
          ) {
            const onboarding = startupInfo.introsShown
              .find(x => x.introType === models.IntroType.Onboadring);

            if (onboarding || !this.onboadringPopupComponent) {
              return;
            }

            if (startupInfo.role !== Role.Broker && startupInfo.role !== Role.CoBroker) {
              this.onboadringPopupComponent.showPopup();
            }
          }
        }),
        switchMap(() => this._featureTogglingManager.requestCompanyFeatureToggles()),
        switchMap(() => this._stateraUserClaimService.requestStateraUserClaims$()),
        switchMap(() => this._projectService.getProjects()),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._applicationQuery
      .getConfiguration$
      .pipe(
        switchMap((configuration: models.IConfigurationViewModel) => {
          if (!configuration) {
            return of(null);
          }

          const configurations$: Array<Observable<ScriptModel>> = [];

          // Add google analytics if configuration exist
          if (configuration.googleAnalyticsTrackingId) {
            configurations$.push(
              this._getGoogleAnalyticsScript$(configuration)
            );
          }
          if (configuration.calendlySettingsViewModel) {
            configurations$.push(
              this._getCalendlyScript$(configuration)
            );
          }

          if (configuration.intercomSettingsViewModel) {
            configurations$.push(
              this._getIntercomScript$(configuration)
            );
          }

          if (!configurations$ || !configurations$.length) {
            return of(null);
          }

          return zip(...configurations$);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._router
      .events
      .pipe(
        tap(event => {
          // Start track page loading time
          if (event instanceof NavigationStart) {
            this._monitoringService.startTrackPage(event.url);
            return;
          }

          // Stop track page loading time
          if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
            this._monitoringService.stopTrackPage(event.url);
            return;
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.progressBarComponent) {
      this._progressBarService.progressBar = this.progressBarComponent;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getGoogleAnalyticsScript$(configuration: models.IConfigurationViewModel): Observable<ScriptModel> {
    if (!configuration || !configuration.googleAnalyticsTrackingId) {
      return of(null);
    }

    const scriptUrl = AppComponent._googleAnalyticsScriptUrl + configuration.googleAnalyticsTrackingId;

    return this._scriptLoaderService
      .load(<ScriptModel>{
        name: configuration.googleAnalyticsTrackingId,
        src: scriptUrl,
        async: true
      })
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          if (!(<WindowWithDataLayer>window).dataLayer) {
            (<WindowWithDataLayer>window).dataLayer = [];
          }

          const dataLayer = (<WindowWithDataLayer>window).dataLayer;

          dataLayer.push(['js', new Date()]);
          dataLayer.push(['config', configuration.googleAnalyticsTrackingId]);
        }),
      );
  }

  private _getCalendlyScript$(configuration: models.IConfigurationViewModel): Observable<ScriptModel> {
    if (!configuration || !configuration.calendlySettingsViewModel) {
      return of(null);
    }

    const scriptUrl = AppComponent._calendlyScriptUrl;

    return this._scriptLoaderService
      .load(<ScriptModel>{
        src: scriptUrl,
        async: false,
        name: configuration.calendlySettingsViewModel.url
      })
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          (<WindowWithCalendly>window).Calendly
            .initBadgeWidget({
              url: configuration.calendlySettingsViewModel.url,
              text: configuration.calendlySettingsViewModel.text,
              color: configuration.calendlySettingsViewModel.color,
              textColor: configuration.calendlySettingsViewModel.textColor,
              branding: configuration.calendlySettingsViewModel.branding
            });
        }),
      );
  }

  private _getIntercomScript$(configuration: models.IConfigurationViewModel): Observable<any> {
    if (!configuration || !configuration.intercomSettingsViewModel) {
      return of(null);
    }
    (<any>window).Intercom('boot', {
      api_base: configuration.intercomSettingsViewModel.apiBase,
      app_id: configuration.intercomSettingsViewModel.appId,
    });

    return this._authService
      .infoLoadComplete
      .pipe(
        tap((startupInfo: models.IStartupInfoViewModel) => {
          if ((<any>window).Intercom) {
            if (!startupInfo || !startupInfo.id) {
              (<any>window).Intercom('shutdown');
            } else {
              (<any>window).Intercom('update', {
                user_id: startupInfo?.id,
                email: startupInfo?.primaryEmail,
                user_hash: startupInfo?.userHmacHash,
                company: {
                  id: startupInfo?.companyId,
                  name: startupInfo?.companyName,
                }
              });
            }
          }
        })
      );
  }
}
