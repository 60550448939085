import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './chat-message.model';

@Injectable()
export class ChatMessageRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addMessage(message: models.ChatMessage): Observable<models.ChatMessage> {
    return this._httpClient
      .post<models.ChatMessage>(
        `${this._configuration.apiEndpoint}/chat/AddMessage`,
        message,
      );
  }

  getMessages(chatChannelId?: number, skip?: number, take?: number): Observable<Array<models.ChatMessage>> {
    let params = new HttpParams();

    if (chatChannelId) {
      params = params.set('chatChannelId', chatChannelId.toString());
    }

    if (skip || skip === 0) {
      params = params.set('skip', skip.toString());
    }

    if (take || take === 0) {
      params = params.set('take', take.toString());
    }

    return this._httpClient
      .get<Array<models.ChatMessage>>(
        `${this._configuration.apiEndpoint}/chat/GetChatMessages`,
        {
          params,
        },
      );
  }

  updateMessage(message: models.ChatMessage): Observable<models.ChatMessage> {
    return this._httpClient
      .post<models.ChatMessage>(
        `${this._configuration.apiEndpoint}/chat/EditMessage`,
        message,
      );
  }

  deleteMessage(message: models.ChatMessage): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/chat/RemoveMessage`,
        message,
      );
  }

  getChatProfiles(chatChannelId: number): Observable<Array<models.ChatProfile>> {
    let params = new HttpParams();

    if (chatChannelId) {
      params = params.set('id', chatChannelId.toString());
    }

    return this._httpClient
      .get<Array<models.ChatProfile>>(
        `${this._configuration.apiEndpoint}/chat/GetChatProfiles`,
        {
          params,
        },
      );
  }

  getCountUnreadMessages(chatChannelId: number): Observable<models.CountUnreadMessages> {
    let params = new HttpParams();

    if (chatChannelId) {
      params = params.set('chatChannelId', chatChannelId.toString());
    }

    return this._httpClient
      .get<models.CountUnreadMessages>(
        `${this._configuration.apiEndpoint}/chat/GetCountUnreadMessages`,
        {
          params,
        },
      );
  }

  markAllAsRead(chatChannelId: number): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/chat/ReadMessages`,
        <models.ReadMessages>{
          chatChannelId,
        },
      );
  }
}
