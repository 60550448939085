import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { CertificateOfInsuranceManager } from './certificate-of-insurance.manager';
import { CertificateOfInsuranceRepository } from './certificate-of-insurance.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    CertificateOfInsuranceManager,
    CertificateOfInsuranceRepository
  ],
})
export class CertificateOfInsuranceModule {
  static forChild(configuration: Configuration): ModuleWithProviders<CertificateOfInsuranceModule> {
    return {
      ngModule: CertificateOfInsuranceModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        CertificateOfInsuranceManager,
        CertificateOfInsuranceRepository
      ],
    };
  }
}
