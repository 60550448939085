import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TeamRepository } from './team.repository';

import * as models from './team.model';

@Injectable()
export class TeamManager {
  private readonly _teamRepository: TeamRepository;

  constructor(teamRepository: TeamRepository) {
    this._teamRepository = teamRepository;
  }

  sendInvitationRequest(invitationRequest: models.InvitationRequest): Observable<void> {
    const request = {...invitationRequest};
    if (request.companyId <= 0) {
      delete request.companyId;
    }

    return this._teamRepository
      .sendInvitationRequest(invitationRequest);
  }

  getCompanies(companyType: models.CompanyType): Observable<Array<models.Company>> {
    return this._teamRepository
      .getCompanies(companyType.toString());
  }

  getLeaseTeamMembers(leaseId: number): Observable<Array<models.TeamMember>> {
    return this._teamRepository.getLeaseTeamMembers(leaseId);
  }

  deleteLeaseTeamMember(leaseId: number, userInvitationRequestId: number): Observable<void> {
    return this._teamRepository.deleteLeaseTeamMember(leaseId, userInvitationRequestId);
  }

  getPortfolioTeamMembers(portfolioId: number): Observable<Array<models.TeamMember>> {
    return this._teamRepository.getPortfolioTeamMembers(portfolioId);
  }

  deletePortfolioTeamMember(portfolioId: number, userInvitationRequestId: number): Observable<void> {
    return this._teamRepository.deletePortfolioTeamMember(portfolioId, userInvitationRequestId);
  }

  getBuildingTeamMembers(buildingId: number): Observable<Array<models.TeamMember>> {
    return this._teamRepository.getBuildingTeamMembers(buildingId);
  }

  deleteBuildingTeamMember(buildingId: number, userInvitationRequestId: number): Observable<void> {
    return this._teamRepository.deleteBuildingTeamMember(buildingId, userInvitationRequestId);
  }

  isTeamsMembersMatch(teams: Array<Array<models.TeamMember>>): boolean {
    const firstTeamMemberIds = (
      teams[0].map(y => y.userId) ?? []
    );

    for (let i = 1; i < teams.length; i++) {
      const subsequentTeamMemberIds = (
        teams[i]?.map(y => y.userId) ?? []
      );

      const teamMemberIdsIntersection = firstTeamMemberIds
        .filter(element =>
          subsequentTeamMemberIds.includes(element)
        );

      if (teamMemberIdsIntersection.length !== firstTeamMemberIds.length) {
        return false;
      }
    }

    return true;
  }
}
