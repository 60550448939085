<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Please choose from the following multiple choices for why this was rejected
        </div>
        <div class="dx-field-value">
          <dx-radio-group id="leaseRejectionStatus" name="leaseRejectionStatus"
                          displayExpr="name" valueExpr="value" required
                          [dataSource]="projectRenewalRejectionStatuses" [isValid]="form.submitted ? leaseRejectionStatus.valid : true"
                          [(ngModel)]="rejectionLease.projectRenewalRejectionStatus"
                          #leaseRejectionStatus="ngModel">
          </dx-radio-group>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && leaseRejectionStatus.hasError('required')">
            Select appropriate option
          </div>
        </div>
      </div>

      <ng-container *ngIf="rejectionLease?.projectRenewalRejectionStatus === ProjectRenewalRejectionStatus.TooEarly">
        <div class="dx-field">
          <div class="dx-field-label">
            Let's revisit deal
          </div>
          <div class="dx-field-value">
            <dx-date-box type="date" name="dateToRevisit" dateSerializationFormat="yyyy-MM-dd"
                         [required]="true"
                         [disabledDates]="this.isDateDisabled"
                         [isValid]="form.submitted ? dateToRevisitInput.valid : true"
                         [(ngModel)]="rejectionLease.dateToRevisit"
                         #dateToRevisitInput="ngModel">
            </dx-date-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && dateToRevisitInput.hasError('required')">
              This field is required
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Reject Request"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
