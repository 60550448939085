<div class="attachments-search">
  <h2 class="b-title">Restructure, renewal and new deal requests</h2>

  <dx-text-box name="search" mode="search" placeholder="Search" class="attachments-textbox"
               [(value)]="searchRequest">
  </dx-text-box>
</div>

<dx-accordion class="attachments-accordion"
              [dataSource]="requests" [collapsible]="true" [multiple]="true"
              [animationDuration]="300" [selectedItems]="openedRequests">
  <div *dxTemplate="let requests of 'title'; let index = index;">
    <span>
      {{requests.date | safeDate: 'MM/dd/yy'}}
    </span>

    <span class="mx-1">
      {{requests.type}}
    </span>

    <ng-container *ngIf="index === 0 && requests.quizAnswers && requests.quizAnswers.length">
      <ng-container *ngIf="getRequestStatus(project) as requestStatus">
        <span class="mx-1"
              *ngIf="requestStatus !== RequestStatus.Pending">
          <span class="request-status"
                [class.badge-success]="requestStatus === RequestStatus.Approved"
                [class.badge-danger]="requestStatus === RequestStatus.Rejected">
            <span>{{requestStatus}}</span>
            <ng-container *ngIf="getRequestStatusChangeDate(project) as requestStatusChangeDate">
              <span class="request-status-delimiter"></span>
              <span>{{requestStatusChangeDate | safeDate: 'MM/dd/yy hh:mm a'}}</span>
            </ng-container>
          </span>
        </span>
      </ng-container>
    </ng-container>
  </div>

  <div *dxTemplate="let request of 'item'; let index = index;">
    <p class="px-3 my-4"
       *ngIf="showApproveAndRejectBlocks && index === 0">
      Tenant has requested a Renewal, Restructure or New Deal. Please review and respond at your earliest convenience.
    </p>
    <ng-container *ngIf="index === 0">
      <div class="request-scroll">
        <table class="table-requests">
          <tr>
            <th class="w-65">Question</th>
            <th class="w-35">Answer</th>
          </tr>
          <tr *ngFor="let quizItem of request.quizItems; let index = index;">
            <td>
              {{quizItem?.question}}
            </td>
            <td>
              <app-onboarding-answer [quizItem]="quizItem"></app-onboarding-answer>
              <ng-container *ngIf="QuestionType.FinancialDocuments === quizItem?.quizQuestion?.questionType">
                <ng-container
                  *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:leaseId">
                  <button *ngIf="isRequestMoreInfoButtonVisible()" type="button" class="btn btn-secondary ml-3"
                          [disabled]="isFinancialsRequested()"
                          (click)="requestAdditionalFinancialDocuments()">
                    Request financial info
                  </button>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </table>
      </div>

      <div class="request-response"
           *ngIf="!isLeaseCancelled() && getRequestedFinancialDocuments() as requestedFinancialDocuments">
        <p class="form-label font-weight-bold"
           *ngIf="requestedFinancialDocuments.length || isFinancialsRequested()">
          {{ shouldApproveOrRejectRequest(request) ? 'Please review financials before approving request' : 'Financials:' }}
        </p>

        <div class="alert alert-info"
             *ngIf="isFinancialsRequested()">
          Financial info has been requested. Please wait for Tenant's response.
        </div>

        <div class="my-4">
          <ol>
            <li *ngFor="let requestedDocument of requestedFinancialDocuments">
              <a role="button" class="text-primary cursor-pointer"
                 (click)="previewRequestedDocument(requestedDocument)">
                {{requestedDocument.file.name}}
              </a>
            </li>
          </ol>
        </div>
      </div>
      <ng-container
        *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:leaseId">
        <div class="btn-block text-left mx-3"
             *ngIf="shouldApproveOrRejectRequest(request)">
          <button type="button" class="btn btn-primary"
                  [disabled]="isFinancialsRequested()"
                  (click)="approveRequest()">
            Approve
          </button>
          <button *ngIf="showRejectButton()" type="button" class="btn btn-secondary"
                  [disabled]="isFinancialsRequested()"
                  (click)="rejectRequest()">
            Reject
          </button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="index === 1">
      <div class="request-scroll">
        <table class="table-requests">
          <tr>
            <th class="w-65">Reason for Cancellation</th>
            <th class="w-35">Description or Comments</th>
          </tr>
          <tr *ngFor="let quizAnswer of request.quizAnswers; let index = index;">
            <ng-container *ngIf="isCustomRender(quizAnswer.quizQuestion.id)">
              <ng-container [ngSwitch]="quizAnswer.quizQuestion.id">
                <ng-container *ngSwitchCase="questions.MovingOutOfState">
                  <td>Out of state</td>
                  <td>Tenant is looking to move to
                    {{getQuestionAnswer(questions.City, request.quizAnswers)?.customAnswerText
                      ? getQuestionAnswer(questions.State, request.quizAnswers).selectedOptionText + ', ' + getQuestionAnswer(questions.City, request.quizAnswers).customAnswerText
                      : getQuestionAnswer(questions.State, request.quizAnswers).selectedOptionText}}
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.SpaceIsNoLongerRequired">
                  <td>Space is no longer required</td>
                  <td>---</td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.Economics">
                  <td>Economics</td>
                  <td>Tenant's new monthly budget
                    is {{getQuestionAnswer(questions.MinAmount, request.quizAnswers)?.answerNumber}}$
                    - {{getQuestionAnswer(questions.MaxAmount, request.quizAnswers)?.answerNumber}}$
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.SpaceUseChange">
                  <td>Space use change</td>
                  <td>{{getQuestionAnswer(questions.PleaseDescribeSpaceUseChange, request.quizAnswers).customAnswerText}}</td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.IncorrectConfiguration">
                  <td>Incorrect space configuration</td>
                  <td>{{getQuestionAnswer(questions.PleaseDescribeIncorrectConfigurationText, request.quizAnswers).customAnswerText}}</td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.RequireLessSpace">
                  <td>Require less space</td>
                  <td>Tenant requires
                    approximately {{getQuestionAnswer(questions.RequireLessSpaceMinSF, request.quizAnswers).answerNumber}}
                    SF
                    - {{getQuestionAnswer(questions.RequireLessSpaceMaxSF, request.quizAnswers).answerNumber}} SF
                  </td>
                </ng-container>
                <ng-container *ngSwitchCase="questions.RequireMoreSpace">
                  <td>Require more space</td>
                  <td>Tenant requires
                    approximately {{getQuestionAnswer(questions.RequireMoreSpaceMinSF, request.quizAnswers).answerNumber}}
                    SF
                    - {{getQuestionAnswer(questions.RequireMoreSpaceMaxSF, request.quizAnswers).answerNumber}} SF
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!isCustomRender(quizAnswer.quizQuestion.id)">
              <td>
                {{quizAnswer?.quizQuestion?.text}}
              </td>
              <td [ngSwitch]="quizAnswer?.quizQuestion?.questionType">
                <!-- Start: QuestionType.File -->
                <ng-container *ngSwitchCase="QuestionType.File">
                  <a *ngIf="quizAnswer.uploadedFileUrl; else noFile"
                     [href]="renderAnswer(quizAnswer)">
                    File Uploaded
                  </a>
                  <ng-template #noFile>
                    No File
                  </ng-template>
                </ng-container>
                <!-- End: QuestionType.File -->
                <!-- Start: QuestionType.FinancialDocuments -->
                <ng-container *ngSwitchCase="QuestionType.FinancialDocuments">
                  <a role="button" class="text-primary cursor-pointer"
                     *ngIf="quizAnswer.quizAnswerFiles && quizAnswer.quizAnswerFiles.length > 0; else noFile"
                     (click)="previewFinancialFiles(quizAnswer)">
                    View Financials
                  </a>
                  <ng-template #noFile>
                    No File
                  </ng-template>
                </ng-container>
                <!-- End: QuestionType.FinancialDocuments -->
                <!-- Start: QuestionType.MultipleChoice -->
                <ng-container *ngSwitchCase="QuestionType.MultipleChoice">
                  <ul>
                    <li *ngFor="let option of renderAnswer(quizAnswer)">
                      {{ option }}
                    </li>
                  </ul>
                </ng-container>
                <!-- End: QuestionType.MultipleChoice -->
                <!-- Start: QuestionType.MultipleChoiceMaxTwoItems -->
                <ng-container *ngSwitchCase="QuestionType.MultipleChoiceMaxTwoItems">
                  <ul>
                    <li *ngFor="let option of renderAnswer(quizAnswer)">
                      {{ option }}
                    </li>
                  </ul>
                </ng-container>
                <!-- End: QuestionType.MultipleChoiceMaxTwoItems -->
                <ng-container *ngSwitchDefault>
                  {{ renderAnswer(quizAnswer) }}
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </table>
      </div>
      <div class="request-response"
           *ngIf="getQuestionAnswer(questions.Comment, request.quizAnswers)?.customAnswerText">
        <h6 class="form-label">
          Comment
        </h6>

        <div>
          {{getQuestionAnswer(questions.Comment, request.quizAnswers)?.customAnswerText}}
        </div>
      </div>
    </ng-container>
  </div>
</dx-accordion>

