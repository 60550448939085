<div class="expirations-report">
  <ng-container *ngIf="isLoaded; else preloader">
    <div class="expirations-report-info">
      <div class="expirations-report-info-statistics">
        <span class="value">{{expiringLeasesCount()}}</span>
        <span class="caption">Leases expiring this year</span>
      </div>

      <ul class="expirations-report-info-legends">
        <li>
          <a (click)="onLegendItemClick()">
            <span class="expirations-report-info-legends-bg"
                  [style.background-color]="'rgba(240, 156, 148, 1)'">
            </span>
            <span>Expiring SF</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="expirations-report-chart">
      <canvas chartType="bar" baseChart
              [data]="barChartData"
              [options]="barChartOptions"
              [legend]="false"
              [type]="'bar'"
              (chartClick)="handleChartClickEvent($event)"
              #canvasElementRef>
      </canvas>
    </div>
  </ng-container>
  <ng-template #preloader>
    <div class="expirations-report-preloader">
      <span>
      </span>
    </div>
  </ng-template>
</div>
