<div class="plan-viewer-marker-popover-container"
     [class.dynamic-width]="injectableData.marker.buildingUnit && !getImages()"
     *ngIf="injectableData?.marker"
     [@popoverAnimation] #popoverContainerElementRef>
  <div class="plan-viewer-marker-popover">

    <button *ngIf="mode === PlanViewerMode.Marker" class="plan-viewer-marker-popover__close-btn"
            (click)="_hidePopover()">
      <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#closeFloorPlan"></i>
    </button>

    <div class="plan-viewer-marker-popover-image-viewer"
         *ngIf="getImages() as images; else noImage"
         #imageViewerContainerElement>
      <app-image-viewer [images]="images" [container]="imageViewerContainerElement" [isPreviewMode]="true"
                        [minHeight]="200" [enableArrowNavigation]="true"
                        (zoomClicked)="handleZoomClick($event)">
      </app-image-viewer>
    </div>
    <ng-template #noImage>
      <div class="plan-viewer-marker-popover-image-viewer no-image"
           *ngIf="!injectableData.marker.buildingUnit && !injectableData.marker.buildingUnitId">
        <img src="/assets/img/nofoto.png" alt=""/>
      </div>
    </ng-template>
    <div class="plan-viewer-marker-popover-body">
      <div *ngIf="mode === PlanViewerMode.Marker" class="plan-viewer-marker-popover-body_title">
        <span class="marker-number" *ngIf="injectableData?.marker?.index">
          {{ injectableData.marker.index }}
        </span>

        <ng-container *ngIf="injectableData?.marker?.canChangeStatus">
          <button *ngIf="injectableData?.marker?.status !== PlanAnchorStatus.Rejected; else returnToList"
                  class="reject-marker-btn" (click)="changeStatus(PlanAnchorStatus.Rejected)">
            <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#marker-reject-cross"></i>
            REJECT
          </button>

          <ng-template #returnToList>
            <button (mouseover)="rejectedButtonHover=true" (mouseout)="rejectedButtonHover=false"
                    class="return-to-list-btn" (click)="changeStatus(PlanAnchorStatus.Pending)">
              <ng-container *ngIf="rejectedButtonHover">
                <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#marker-return-list"></i>
                RETURN TO LIST
              </ng-container>
              <ng-container *ngIf="!rejectedButtonHover">
                <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#marker-reject-cross"></i>
                REJECTED
              </ng-container>
            </button>
          </ng-template>
        </ng-container>

        <div *ngIf="injectableData?.marker?.editable" class="btn__group">
          <button (click)="handleEditClick()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.864 4.84304L7.157 4.13604L2.5 8.79304V9.50004H3.207L7.864 4.84304ZM8.571 4.13604L9.278 3.42904L8.571 2.72204L7.864 3.42904L8.571 4.13604ZM3.621 10.5H1.5V8.37854L8.2175 1.66104C8.31126 1.56731 8.43842 1.51465 8.571 1.51465C8.70358 1.51465 8.83074 1.56731 8.9245 1.66104L10.339 3.07554C10.4327 3.16931 10.4854 3.29646 10.4854 3.42904C10.4854 3.56162 10.4327 3.68878 10.339 3.78254L3.6215 10.5H3.621Z"
                fill="currentColor"/>
            </svg>
            edit
          </button>

          <button (click)="handleDeleteClick()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.0998 3.4798H10.1998V4.3198H9.3598V9.7798C9.3598 9.8912 9.31556 9.99802 9.23679 10.0768C9.15802 10.1556 9.0512 10.1998 8.9398 10.1998H3.0598C2.94841 10.1998 2.84159 10.1556 2.76282 10.0768C2.68405 9.99802 2.6398 9.8912 2.6398 9.7798V4.3198H1.7998V3.4798H3.8998V2.2198C3.8998 2.10841 3.94405 2.00159 4.02282 1.92282C4.10159 1.84405 4.20841 1.7998 4.3198 1.7998H7.6798C7.7912 1.7998 7.89802 1.84405 7.97679 1.92282C8.05555 2.00159 8.0998 2.10841 8.0998 2.2198V3.4798ZM8.5198 4.3198H3.4798V9.3598H8.5198V4.3198ZM6.59368 6.8398L7.33624 7.58236L6.74236 8.17624L5.9998 7.43368L5.25724 8.17624L4.66336 7.58236L5.40592 6.8398L4.66336 6.09724L5.25724 5.50336L5.9998 6.24592L6.74236 5.50336L7.33624 6.09724L6.59368 6.8398ZM4.7398 2.6398V3.4798H7.2598V2.6398H4.7398Z"
                fill="currentColor"/>
            </svg>
            delete
          </button>
        </div>
      </div>

      <div class="plan-viewer-marker-popover-body__info">
        <h3>{{ injectableData?.marker?.title }}</h3>


        <ng-container *ngIf="!injectableData?.marker?.buildingUnitId &&
                           !injectableData?.marker?.buildingUnit; else buildingUnitDescriptionTemplate">
          <p class="plan-viewer-marker-popover-description"
             *ngIf="injectableData?.marker?.description">
            {{ injectableData?.marker?.description }}
          </p>
        </ng-container>

        <ng-template #buildingUnitDescriptionTemplate>
          <p class="plan-viewer-marker-popover-description"
             *ngIf="injectableData?.marker?.buildingUnit && injectableData?.marker?.buildingUnit.description">
            {{ injectableData?.marker?.buildingUnit.description }}
          </p>
        </ng-template>

        <div class="plan-viewer-marker-popover-body__info-footer">
          <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#interior-office"></i>

          <span>interior office</span>

          <span class="info-footer_comments">{{ comments?.length ? comments.length : 0 }} comments</span>

          <span *ngIf="getLastCommentDate(comments) as lastCommentDate" class="info-footer_date">
            {{ lastCommentDate | timeAgo }}
          </span>

        </div>

        <span class="panoramic-images-hint"
              *ngIf="hasPanoramicImages()">
          (Click into picture for more details)
        </span>

      </div>

      <div class="plan-viewer-marker-popover-badges"
           *ngIf="injectableData?.marker?.isOwnSpace ||
                  injectableData?.marker?.isAvailableSpace">
        <div class="plan-viewer-marker-popover-badge"
             *ngIf="injectableData?.marker?.isOwnSpace">
          Current Space
        </div>
        <div class="plan-viewer-marker-popover-badge"
             *ngIf="injectableData?.marker?.isAvailableSpace">
          Available Space
        </div>
      </div>

      <ng-container *ngIf="mode === PlanViewerMode.Marker && injectableData?.marker?.canChangeAmount">

        <div *ngIf="showEditCostForm; else changeAmountTemplate" class="add-an-estimated-cost">

          <div class="coast-input_wrapper">
            <span>$</span>
            <input [(ngModel)]="injectableData.marker.amount" type="number" name="" id="" placeholder="(e.g. 100222)">
          </div>

          <button (click)="saveAmount()"
                  [disabled]="!injectableData?.marker?.amount">{{ isNewAmount ? 'add an estimated cost' : 'edit cost' }}
          </button>
        </div>

        <ng-template #changeAmountTemplate>
          <div *ngIf="injectableData?.marker?.amount" class="amount">
            <span class="amount__label">Estimated Cost:</span>
            <span class="amount__value btn__group">
              {{ injectableData?.marker?.amount | currency }}
              <button (click)="changeAmount()">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.864 4.84304L7.157 4.13604L2.5 8.79304V9.50004H3.207L7.864 4.84304ZM8.571 4.13604L9.278 3.42904L8.571 2.72204L7.864 3.42904L8.571 4.13604ZM3.621 10.5H1.5V8.37854L8.2175 1.66104C8.31126 1.56731 8.43842 1.51465 8.571 1.51465C8.70358 1.51465 8.83074 1.56731 8.9245 1.66104L10.339 3.07554C10.4327 3.16931 10.4854 3.29646 10.4854 3.42904C10.4854 3.56162 10.4327 3.68878 10.339 3.78254L3.6215 10.5H3.621Z"
                    fill="currentColor"/>
                </svg>
              </button>
            </span>
          </div>
        </ng-template>
      </ng-container>

      <div class="rejection-comment" *ngIf="injectableData.marker.status === PlanAnchorStatus.Rejected">
        <ng-container *ngIf="injectableData?.marker?.canChangeStatus">
          <span class="rejection-comment_text">You have rejected this improvement.</span>
          <ng-container *ngIf="getLastRejectionComment() as rejectionComment">
            <ng-container *ngIf="!rejectionComment.content">
            <span class="basic-text">
              Comment on the reason for that if you’d like.
            </span>

              <div class="rejection-comment__input">
                <input [(ngModel)]="rejectionCommentContent" type="text" class="rejection-comment__input-input"
                       placeholder="Decribe the reason...">
                <button [disabled]="!rejectionCommentContent" class="rejection-comment__input-btn"
                        (click)="addRejectionComment(rejectionComment.id)">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.241861 4.57277C-0.0860704 4.44078 -0.0822124 4.2444 0.263723 4.12915L12.5361 0.0328208C12.8762 -0.080501 13.0711 0.110086 12.9759 0.443612L9.46896 12.7326C9.37251 13.0732 9.16353 13.0887 9.00792 12.7777L6.0784 6.91004L0.241861 4.57277ZM3.38615 4.444L7.01011 5.89594L8.96484 9.81198L11.2449 1.8215L3.3855 4.444H3.38615Z"
                      fill="white"/>
                  </svg>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="injectableData?.marker?.editable">
          <span class="rejection-comment_text">Landlord has rejected this improvement. Edit it to move it back to the list of TIs.</span>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="mode === PlanViewerMode.Marker">
      <ng-container *ngTemplateOutlet="anchorCommentList"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #anchorCommentList>
  <div class="floor-plan-comments">
    <div #commentWrapper class="floor-plan-comments__wrapper">
      <ng-container *ngIf="!comments?.length">
        <div class="floor-plan-comments__empty">
          <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#no-comments"></i>
          <h4>No Comments Yet :(</h4>
          <p>Leave a comment under this Improvement to discuss any details with the Landlord</p>
        </div>
      </ng-container>

      <div *ngFor="let comment of comments" class="floor-plan-comments__comment">
        <div class="floor-plan-comments__comment-user">
          <img [src]="getAvatarUrl(comment)" alt="user_avatar">
          <span>{{ getAuthorDisplayName(comment) }}</span>
          <span *ngIf="comment.planAnchorCommentType === PlanAnchorCommentType.AnchorRejection"
                class="rejection-comment">
          rejected this improvement
        </span>
        </div>

        <div class="floor-plan-comments__comment-comment" [innerHtml]="comment.content">
        </div>

        <div class="floor-plan-comments__comment-date">
          {{ comment.createdOn | safeDate: 'MMM dd, hh:mm a' }}
        </div>

      </div>
    </div>

    <div class="floor-plan-comments__input">
      <input [(ngModel)]="messageValue" type="text" class="floor-plan-comments__input-input"
             placeholder="Leave a comment...">
      <button [disabled]="!messageValue" class="floor-plan-comments__input-btn" (click)="addComment(messageValue)">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.241861 4.57277C-0.0860704 4.44078 -0.0822124 4.2444 0.263723 4.12915L12.5361 0.0328208C12.8762 -0.080501 13.0711 0.110086 12.9759 0.443612L9.46896 12.7326C9.37251 13.0732 9.16353 13.0887 9.00792 12.7777L6.0784 6.91004L0.241861 4.57277ZM3.38615 4.444L7.01011 5.89594L8.96484 9.81198L11.2449 1.8215L3.3855 4.444H3.38615Z"
            fill="white"/>
        </svg>
      </button>
    </div>
  </div>
</ng-template>
