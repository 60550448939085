<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6 class="">Please choose from the following:</h6>
  </div>
  <dx-validation-group #termValidationGroup>
    <div class="row term-container">
      <div class="radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                          [dataSource]="options" [(value)]="leaseTerm.holdoverProvisionType"
                          (valueChange)="clearErrors()">
            <div *dxTemplate="let data of 'radio'; let index = index">
              <ng-container *ngIf="data.value === HoldoverProvisionType.PerCurrentLease || data.value === HoldoverProvisionType.Custom">
                <p>{{data.name}}</p>
              </ng-container>
              <div class="d-flex flex-column"
                 *ngIf="data.value === HoldoverProvisionType.TenantRemainsFollowingEndTerm">
                <span>
                  In the event Tenant remains in the Premises following the end of the Lease Term, Tenant shall
                </span>
                <div class="insert-row my-1">
                  <span>pay Landlord Holdover Rent equal to</span>

                  <app-input-field [id]="'landlordHoldoverRentForFirstDaysInPercent'" [placeholder]="'(e.g. 5)'"
                                        [className]="'real-estate-input py-0'" [min]="0" [step]="0"
                                        [format]="'#0.##'" [width]="'70px'"
                                        [errorMessage]="'Initial holdover value is required'"
                                        [value]="leaseTerm.landlordHoldoverRentForFirstDaysInPercent" [suffix]="'%'"
                                        [disabled]="leaseTerm.holdoverProvisionType !== HoldoverProvisionType.TenantRemainsFollowingEndTerm"
                                        (valueChange)="leaseTerm.landlordHoldoverRentForFirstDaysInPercent = $event; clearErrors()"
                  ></app-input-field>
                  <span>of Rent for the first</span>
                  <app-input-field appIgnoreScrollwheel
                                        [id]="'countOfFirstLandlordHoldoverRentDays'" [placeholder]="'(e.g. 5)'"
                                        [className]="'real-estate-input py-0'" [min]="0" [max]="365"
                                        [format]="'#0'" [width]="'70px'"
                                        [errorMessage]="'Initial holdover period is required'"
                                        [value]="leaseTerm.countOfFirstLandlordHoldoverRentDays" [suffix]="'day(s)'"
                                        [disabled]="leaseTerm.holdoverProvisionType !== HoldoverProvisionType.TenantRemainsFollowingEndTerm"
                                        (valueChange)="leaseTerm.countOfFirstLandlordHoldoverRentDays = $event; clearErrors()"
                  ></app-input-field>
                  <span>and</span>
                </div>
                <div class="insert-row mb-1">
                  <app-input-field [id]="'landlordHoldoverRentForRemainingDaysInPercent'" [placeholder]="'(e.g. 5)'"
                                        [className]="'real-estate-input py-0'" [min]="0" [step]="0"
                                        [format]="'#0.##'" [width]="'70px'"
                                        [errorMessage]="'Holdover after initial period value is required'"
                                        [value]="leaseTerm.landlordHoldoverRentForRemainingDaysInPercent" [suffix]="'%'"
                                        [disabled]="leaseTerm.holdoverProvisionType !== HoldoverProvisionType.TenantRemainsFollowingEndTerm"
                                        (valueChange)="leaseTerm.landlordHoldoverRentForRemainingDaysInPercent = $event; clearErrors()"
                  ></app-input-field>
                  <span>of Rent thereafter.</span>
                </div>
              </div>
              <div class="d-flex flex-column"
                   *ngIf="data.value === HoldoverProvisionType.MonthlyRentPriorToHoldover">
                <span>
                  Possession of Premises by Tenant after Lease termination shall not extend the Term and
                </span>
                <div class="insert-row my-1">
                  <span> Monthly Base Rent shall be</span>
                  <app-input-field appIgnoreScrollwheel
                                        [id]="'landlordHoldoverRentInPercent'" [placeholder]="'(e.g. 5)'"
                                        [className]="'real-estate-input py-0'"
                                        [format]="'#0.##'" [width]="'70px'"
                                        [errorMessage]="'Landlord holdover rent value is required'"
                                        [value]="leaseTerm.landlordHoldoverRentInPercent" [suffix]="'%'"
                                        [disabled]="leaseTerm.holdoverProvisionType !== HoldoverProvisionType.MonthlyRentPriorToHoldover"
                                        (valueChange)="leaseTerm.landlordHoldoverRentInPercent = $event; clearErrors()"
                  ></app-input-field>
                  <span>of Monthly Base Rent in effect immediately</span>
                </div>
                <span>
                   prior to the Holdover period.
                </span>
              </div>
            </div>
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div><!--col-->
      <div class="col-sm-6"></div>
    </div><!--row-->

    <div class="col-8" *ngIf="leaseTerm?.holdoverProvisionType === HoldoverProvisionType.Custom">

      <app-term-custom-value-template-textarea placeholder="Insert custom holdover provision clause"
                                                 required requiredMessage="Custom Holdover Provision clause is required"
                                                 [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                 [(value)]="leaseTerm.text"
                                                 (valueChange)="clearErrors(); leaseTermPropertyChange()">
        </app-term-custom-value-template-textarea>

    </div>

    <!-- Start: Validation summary -->
      <div >
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>
    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>


