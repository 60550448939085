import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DocumentsMode } from '../../infrastructure/models/generated';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable()
export class ProfileService {
  primaryAddressName = 'HQ Address';
  otherAddressName = 'Other Address';
  emptyAddresses = [
    <models.IAddressViewModel>{
      name: this.primaryAddressName,
      isPrimary: true,
    },
    <models.IAddressViewModel>{
      name: this.otherAddressName,
      isPrimary: false,
    },
  ];
  emptyProfile: models.IProfileViewModel = <models.IProfileViewModel>{
    company: {
      addresses: this.emptyAddresses,
    },
  };

  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  save(model: models.IProfileViewModel): Observable<models.IProfileViewModel> {
    return this._httpClient.post<models.IProfileViewModel>(`${environment.webApiUrl}/profile/Save`, model);
  }

  skipIntro(introType: models.IntroType) {
    return this._httpClient.post(`${environment.webApiUrl}/profile/SkipIntro?type=${introType}`, null);
  }

  resetIntro(introType: models.IntroType): Observable<void> {
    const params = new HttpParams().set('type', introType.toString(10));

    return this._httpClient
      .post<void>(`${environment.webApiUrl}/profile/ResetIntro`, null, { params });
  }
}
