import {Component, Input, OnInit} from '@angular/core';
import * as models from '../../../../infrastructure/models/generated';
import {AuthService} from '../../../../auth/services/auth.service';
import {LeaseManager} from '@statera/sdk/lease';

@Component({
  selector: 'app-awaiting-lease-upload-badge',
  templateUrl: './awaiting-lease-upload-badge.component.html',
  styleUrls: ['./awaiting-lease-upload-badge.component.scss']
})
export class AwaitingLeaseUploadBadgeComponent {

  @Input()
  project: models.IProjectViewModel;

  private readonly _authService: AuthService;
  private readonly _leaseManager: LeaseManager;
  constructor(authService: AuthService, leaseManager: LeaseManager) {
    this._authService = authService;
    this._leaseManager = leaseManager;
  }

  isShow() {
    return this._leaseManager.isShowAwaitingLeaseUploadBadge(this.project, this._authService?.role);
  }
}
