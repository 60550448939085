import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { TermCustomValueTemplateStore } from './term-custom-value-template.store';

import * as models from './term-custom-value-template.model';

@Injectable()
export class TermCustomValueTemplateRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;
  private readonly _termCustomValueTemplateStore: TermCustomValueTemplateStore;

  constructor(
    configuration: Configuration,
    httpClient: HttpClient,
    termCustomValueTemplateStore: TermCustomValueTemplateStore,
  ) {
    this._configuration = configuration;
    this._httpClient = httpClient;
    this._termCustomValueTemplateStore = termCustomValueTemplateStore;
  }

  /**
   * Requests repository
   */

  getList(): Observable<Array<models.TermCustomValueTemplateViewModel>> {
    return this._httpClient
      .get<Array<models.TermCustomValueTemplateViewModel>>(
        `${this._configuration.apiEndpoint}/term/custom-value-templates`,
      );
  }

  create(viewModel: models.TermCustomValueTemplateViewModel): Observable<models.TermCustomValueTemplateViewModel> {
    return this._httpClient
      .post<models.TermCustomValueTemplateViewModel>(
        `${this._configuration.apiEndpoint}/term/custom-value-templates`,
        viewModel,
      );
  }

  update(viewModel: models.TermCustomValueTemplateViewModel): Observable<models.TermCustomValueTemplateViewModel> {
    return this._httpClient
      .put<models.TermCustomValueTemplateViewModel>(
        `${this._configuration.apiEndpoint}/term/custom-value-templates/${viewModel.id}`,
        viewModel,
      );
  }

  delete(id: number): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/term/custom-value-templates/${id}`,
      );
  }

  /**
   * Store repository
   */

  getStoredList(): Observable<Array<models.TermCustomValueTemplateViewModel>> {
    return this._termCustomValueTemplateStore.list;
  }

  setStoredList(list: Array<models.TermCustomValueTemplateViewModel>): void {
    return this._termCustomValueTemplateStore.list.next(list);
  }
}
