import { Injectable } from '@angular/core';
import { ProjectManager } from '@statera/sdk/project';

import { TermManager } from '@statera/sdk/term';

import { ProjectAccessService } from '../../shared/services/project-access.service';
import * as models from '../../infrastructure/models/generated';

@Injectable()
export class TermsPageService {
  static readonly duplicateValueMessage = (
    'Uh oh, that seems to be the same response. Please accept or provide a different response.'
  );

  private readonly _projectAccessService: ProjectAccessService;
  private readonly _projectManager: ProjectManager;

  constructor(projectAccessService: ProjectAccessService, projectManager: ProjectManager) {
    this._projectAccessService = projectAccessService;
    this._projectManager = projectManager;
  }

  isVisibleEditingTerm(
    project: models.IProjectViewModel,
    lease: models.ILeaseViewModel,
    leaseTermType: models.LeaseTermType = null
  ): boolean {
    if (leaseTermType === models.LeaseTermType.TenantImprovements) {
      return this.isVisibleTenantImprovementsRadioGroup(project, lease);
    }

    const {
      PendingInternalApproval,
      ReceiveAndAnalyzeResponse,
      SendRfp,
      UnsolicitedOffer,
      TenantCounterUnsolicitedOffer,
      Restructure_EstablishCriteriaByTenant,
    } = models.RenewalProjectTemplateItemType;

    return (
      this._projectAccessService.checkAccessToRenewalProject(PendingInternalApproval, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(ReceiveAndAnalyzeResponse, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(SendRfp, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(UnsolicitedOffer, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(TenantCounterUnsolicitedOffer, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(Restructure_EstablishCriteriaByTenant, project, lease)
    );
  }

  isVisibleTenantImprovementsRadioGroup(project: models.IProjectViewModel, lease: models.ILeaseViewModel): boolean {
    const {
      SendRfp,
      UnsolicitedOffer,
      TenantCounterUnsolicitedOffer,
      Restructure_EstablishCriteriaByTenant,
      ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
      ReviewTenantImprovementsSelectMultiplyOptionsTenant,
      TenantCounterOffer,
      LandlordCounterOffer
    } = models.RenewalProjectTemplateItemType;

    return (
      this._projectAccessService.checkAccessToRenewalProject(SendRfp, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(Restructure_EstablishCriteriaByTenant, project, lease) ||
      this.isVisibleReviewTenantImprovementsByLanlord(project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(UnsolicitedOffer, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(TenantCounterUnsolicitedOffer, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(ReviewTenantImprovementsSelectMultiplyOptionsByLandlord, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(ReviewTenantImprovementsSelectMultiplyOptionsTenant, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(TenantCounterOffer, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(LandlordCounterOffer, project, lease)
    );
  }

  isVisibleReviewTenantImprovementsByLanlord(project: models.IProjectViewModel, lease: models.ILeaseViewModel): boolean {
    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
      project,
      lease
    );
  }

  isShowMultiplyOptionsInTerm(project: models.IProjectViewModel, lease: models.ILeaseViewModel) {
    return (this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
      project,
      lease
    ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.SendRfp,
        project,
        lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
        project,
        lease
      )
    );
  }

  isShowLeaseDocuments(project: models.IProjectViewModel, lease: models.ILeaseViewModel): boolean {
    if (project && project.projectState) {
      const templateItemType = project.projectState.renewalProjectTemplateItemType;
      return (
        templateItemType === models.RenewalProjectTemplateItemType.LeaseDocumentsStepByTenant ||
        templateItemType === models.RenewalProjectTemplateItemType.LeaseDocumentsStepByLandlord ||
        templateItemType === models.RenewalProjectTemplateItemType.CleanCopySubmittalByLandlord ||
        templateItemType === models.RenewalProjectTemplateItemType.CleanCopyPreparationByAdmin ||
        templateItemType === models.RenewalProjectTemplateItemType.CleanCopyReceiptByTenant ||
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.LeaseDocumentsFirstStepByLandlord,
          project,
          lease
        )
      );
    }

    return false;
  }

  validateDuplicateTermValue(
    project: models.IProjectViewModel,
    termManager: TermManager,
    leaseTerm: models.ILeaseTermViewModel,
    leaseId: number
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this._projectManager.skipDuplicateValidation(project)) {
        resolve();
        return;
      }

      const onExceptionMessage = 'Cannot contact validation server.';

      if (!leaseTerm) {
        resolve();
        return;
      }

      termManager
        .validateDuplicateLeaseTerm(leaseTerm, leaseId)
        .toPromise()
        .then((res: boolean) => {
          if (!res) {
            reject(TermsPageService.duplicateValueMessage);
            return;
          }

          resolve();
        })
        .catch(error => {
          console.error(onExceptionMessage, error);
          reject(onExceptionMessage);
        });
    });
  }

  isFirstOffer(project: models.IProjectViewModel, lease: models.ILeaseViewModel) {
    const { SendRfp, UnsolicitedOfferByLandlord } = models.RenewalProjectTemplateItemType;

    return (
      this._projectAccessService.checkAccessToRenewalProject(SendRfp, project, lease) ||
      this._projectAccessService.checkAccessToRenewalProject(UnsolicitedOfferByLandlord, project, lease)
    );
  }
}
