import { Injectable } from '@angular/core';
import polylabel from 'polylabel';

import * as konva from 'konva/konva';

import * as models from '../models/plan-viewer.model';

@Injectable()
export class PlanViewerMarkerService {
  private static readonly _blueColor = '#2F9BB1';
  private static readonly _whiteColor = '#FFFFFF';
  private static readonly _grayColor = '#686F7B';
  private static readonly _greenColor = '#9bd3b34d';
  private static readonly _redColor = '#FF0000';

  private static readonly _isDebugMode = false;

  private _markersCount: number;

  constructor() {
    this._markersCount = 0;
  }

  createMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!attributes) {
      return;
    }

    switch (attributes.type) {
      case models.PlanViewerMarkerType.Circle:
        return this._createCircleMarker(attributes);

      case models.PlanViewerMarkerType.Rect:
        return this._createRectMarker(attributes);

      case models.PlanViewerMarkerType.Cross:
        return this._createCrossMarker(attributes);

      case models.PlanViewerMarkerType.Polygon:
        return this._createPolygonMarker(attributes);
    }
  }

  continueMarker(marker: konva.Group, attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!marker || !attributes) {
      return;
    }

    switch (attributes.type) {
      case models.PlanViewerMarkerType.Polygon:
        return this._continuePolygonMarker(marker, attributes);
    }
  }

  changeMarkerSize(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): konva.Group {
    if (!marker) {
      return;
    }

    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
        return this._changeCircleMarkerSize(marker, attributes);

      case models.PlanViewerMarkerType.Rect:
        return this._changeRectMarkerSize(marker, attributes);

      case models.PlanViewerMarkerType.Cross:
        return this._changeCrossMarkerSize(marker, attributes);

      case models.PlanViewerMarkerType.Polygon:
        return this._changePolygonMarkerSize(marker, attributes);
    }
  }

  changeMarkerColor(marker: konva.Group, attributes: models.PlanViewerMarkerColorAttributes): void {
    if (!marker) {
      return;
    }

    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
        return this._changeCircleMarkerColor(marker, attributes);

      case models.PlanViewerMarkerType.Rect:
        return this._changeRectMarkerColor(marker, attributes);

      case models.PlanViewerMarkerType.Cross:
        return this._changeCrossMarkerColor(marker, attributes);

      case models.PlanViewerMarkerType.Polygon:
        return this._changePolygonMarkerColor(marker, attributes);
    }
  }

  setMarkerAccent(marker: konva.Group): void {
    if (!marker) {
      return;
    }

    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
      case models.PlanViewerMarkerType.Cross: {
        const strokeElement = this.getStrokeElement(marker);
        if (!strokeElement) {
          return;
        }

        strokeElement.strokeWidth(3);

        break;
      }

      case models.PlanViewerMarkerType.Rect:
      case models.PlanViewerMarkerType.Polygon: {
        if (marker.attrs.hasInfoIcon) {
          const backgroundElement = this.getBackgroundElement(marker);
          if (!backgroundElement) {
            return;
          }

          backgroundElement.fill(this._getColor(models.PlanViewerMarkerColor.Green));
        } else {
          const strokeElement = this.getStrokeElement(marker);
          if (!strokeElement) {
            return;
          }

          strokeElement.strokeWidth(3);
        }

        break;
      }

      default:
        return null;
    }
  }

  unsetMarkerAccent(marker: konva.Group): void {
    if (!marker) {
      return;
    }

    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
      case models.PlanViewerMarkerType.Cross: {
        const strokeElement = this.getStrokeElement(marker);
        if (!strokeElement) {
          return;
        }

        strokeElement.strokeWidth(0);

        break;
      }

      case models.PlanViewerMarkerType.Rect:
      case models.PlanViewerMarkerType.Polygon: {
        if (marker.attrs.hasInfoIcon) {
          const backgroundElement = this.getBackgroundElement(marker);
          if (!backgroundElement) {
            return;
          }

          backgroundElement.fill(marker.attrs.color);
        } else {
          const strokeElement = this.getStrokeElement(marker);
          if (!strokeElement) {
            return;
          }

          strokeElement.strokeWidth(0);
        }

        break;
      }

      default:
        return null;
    }
  }

  getMarkerType(marker: konva.Group): models.PlanViewerMarkerType {
    const markerName = marker.name();
    if (!markerName) {
      return null;
    }

    switch (markerName) {
      case 'marker-circle-group':
        return models.PlanViewerMarkerType.Circle;

      case 'marker-rect-group':
        return models.PlanViewerMarkerType.Rect;

      case 'marker-cross-group':
        return models.PlanViewerMarkerType.Cross;

      case 'marker-polygon-group':
        return models.PlanViewerMarkerType.Polygon;

      default:
        return null;
    }
  }

  getMarkerBoundingBox(marker: konva.Group): models.PlanViewerMarkerBoundingBox {
    if (!marker) {
      return null;
    }

    let width: number;
    let height: number;

    let scale = 1;

    const imageGroup = marker.getParent();
    if (imageGroup) {
      scale = imageGroup.scaleX();
    }

    const absolutePosition = marker.getAbsolutePosition();

    let x = absolutePosition.x;
    let y = absolutePosition.y;

    const markerType = this.getMarkerType(marker);
    switch (markerType) {
      case models.PlanViewerMarkerType.Circle: {
        const strokeElement = this.getStrokeElement<konva.Circle>(marker);
        const strokeElementRadius = strokeElement.radius() * scale;

        width = strokeElementRadius * 2;
        height = strokeElementRadius * 2;
        x -= strokeElementRadius;
        y -= strokeElementRadius;

        break;
      }

      case models.PlanViewerMarkerType.Rect: {
        const strokeElement = this.getStrokeElement<konva.Rect>(marker);

        width = strokeElement.width() * scale;
        height = strokeElement.height() * scale;

        if (width < 0) {
          width = Math.abs(width);
          x -= Math.abs(width);
        }

        if (height < 0) {
          height = Math.abs(height);
          y -= Math.abs(height);
        }

        break;
      }

      case models.PlanViewerMarkerType.Cross: {
        const strokeElement = this.getStrokeElement<konva.Shape>(marker);
        const strokeWidth = strokeElement.width() * scale;
        const strokeHeight = strokeElement.height() * scale;

        width = strokeWidth * 2;
        height = strokeHeight * 2;
        x -= strokeWidth;
        y -= strokeHeight;

        if (width < 0) {
          width = Math.abs(width);
          x -= Math.abs(width);
        }

        if (height < 0) {
          height = Math.abs(height);
          y -= Math.abs(height);
        }

        break;
      }

      case models.PlanViewerMarkerType.Polygon: {
        const strokeElement = this.getStrokeElement<konva.Line>(marker);
        const strokeElementRect = strokeElement.getSelfRect();

        width = strokeElementRect.width * scale;
        height = strokeElementRect.height * scale;
        x += strokeElementRect.x * scale;
        y += strokeElementRect.y * scale;

        break;
      }

      default:
        return null;
    }

    const centerX = <number>x + <number>Math.abs(width / 2);
    const centerY = <number>y + <number>Math.abs(height / 2);

    return {
      width: width,
      height: height,
      x: x,
      y: y,
      centerX: centerX,
      centerY: centerY,
    };
  }

  getBackgroundElement<T extends konva.Shape>(marker: konva.Group): T {
    if (!marker) {
      return null;
    }

    let backgroundElementId: string;
    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
        backgroundElementId = '#background-circle';
        break;

      case models.PlanViewerMarkerType.Rect:
        backgroundElementId = '#background-rect';
        break;

      case models.PlanViewerMarkerType.Cross:
        backgroundElementId = '#background-cross';
        break;

      case models.PlanViewerMarkerType.Polygon:
        backgroundElementId = '#background-polygon';
        break;

      default:
        return null;
    }

    return <T>marker.findOne(backgroundElementId);
  }

  getStrokeElement<T extends konva.Shape>(marker: konva.Group): T {
    if (!marker) {
      return null;
    }

    let strokeElementId: string;
    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Circle:
        strokeElementId = '#background-circle-stroke';
        break;

      case models.PlanViewerMarkerType.Rect:
        strokeElementId = '#background-rect-stroke';
        break;

      case models.PlanViewerMarkerType.Cross:
        strokeElementId = '#background-cross-stroke';
        break;

      case models.PlanViewerMarkerType.Polygon:
        strokeElementId = '#background-polygon-stroke';
        break;

      default:
        return null;
    }

    return <T>marker.findOne(strokeElementId);
  }

  getInfoIcon<T extends konva.Shape>(marker: konva.Group): T {
    if (!marker) {
      return null;
    }

    let infoIconElementId: string;
    switch (this.getMarkerType(marker)) {
      case models.PlanViewerMarkerType.Rect:
      case models.PlanViewerMarkerType.Polygon:
        infoIconElementId = '#info-icon';
        break;

      default:
        return null;
    }

    return <T>marker.findOne(infoIconElementId);
  }

  createMarkerPlaceholder(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): void {
    if (!marker || !attributes) {
      return;
    }

    const markerType = this.getMarkerType(marker);
    if (markerType !== models.PlanViewerMarkerType.Circle) {
      return;
    }

    const markerPlaceholderSize = attributes.height;

    const markerPlaceholder = new konva.Rect({
      id: 'marker-placeholder',
      x: -markerPlaceholderSize,
      y: -markerPlaceholderSize,
      width: markerPlaceholderSize * 2,
      height: markerPlaceholderSize * 2,
      fill: 'transparent',
      strokeWidth: 0,
    });

    marker.add(markerPlaceholder);
  }

  removeMarkerPlaceholder(marker: konva.Group) {
    if (!marker) {
      return;
    }

    const markerPlaceholder = marker.findOne('#marker-placeholder');
    if (!markerPlaceholder) {
      return;
    }

    markerPlaceholder.remove();
  }

  addInfoIcon(marker: konva.Group): konva.Text {
    if (!marker) {
      return;
    }

    const markerBoundingBox = this.getMarkerBoundingBox(marker);
    if (!markerBoundingBox) {
      return;
    }

    const strokeElement = this.getStrokeElement<konva.Line>(marker);
    const strokeElementRect = strokeElement.getSelfRect();

    const infoIcon = new konva.Text({
      id: 'info-icon',
      text: '\uf05a',
      fontSize: 16,
      fontFamily: 'FontAwesome',
      fill: this._getColor(models.PlanViewerMarkerColor.Gray),
    });

    switch (marker.attrs.name) {
      case 'marker-polygon-group':
        const polygonLinearRing = strokeElement
          .attrs
          .points
          .reduce((acc, cur, index) => {
            const pointIndex = Math.floor(index / 2);
            const pointCoordinateIndex = index % 2;

            if (!acc[pointIndex]) {
              acc[pointIndex] = [];
            }

            acc[pointIndex][pointCoordinateIndex] = cur;

            return acc;
          }, []);

        const poleOfInaccessibility = polylabel([polygonLinearRing],  12.0);

        infoIcon.setAttrs({
          x: poleOfInaccessibility[0] - (infoIcon.width() / 2),
          y: poleOfInaccessibility[1] - (infoIcon.height() / 2),
        });
        break;
      case 'marker-rect-group':
        infoIcon.setAttrs({
          x: (strokeElementRect.width / 2) - (infoIcon.width() / 2),
          y: (strokeElementRect.height / 2) - (infoIcon.height() / 2),
        });
        break;
    }

    marker.add(infoIcon);

    return infoIcon;
  }

  private _createCircleMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!attributes) {
      return;
    }

    const circleFillColor = this._getColor(attributes.color);

    const backgroundCircleRadius = Math.abs(attributes.width || attributes.height);
    const foregroundCircleRadius = backgroundCircleRadius / 1.75;
    const foregroundArchRadius = foregroundCircleRadius / 2;

    const circleGroup = new konva.Group({
      id: `marker-group-${++this._markersCount}`,
      name: 'marker-circle-group',
      x: attributes.x,
      y: attributes.y,
      height: backgroundCircleRadius,
      draggable: attributes.draggable,
    });

    const backgroundCircle = new konva.Circle({
      id: 'background-circle',
      radius: backgroundCircleRadius,
      fill: circleFillColor,
      opacity: 0.25,
      strokeWidth: 0,
    });

    const backgroundCircleStroke = new konva.Circle({
      id: 'background-circle-stroke',
      radius: backgroundCircleRadius,
      fill: 'transparent',
      stroke: circleFillColor,
      strokeWidth: 0,
    });

    const foregroundCircle = new konva.Circle({
      id: 'foreground-circle',
      radius: foregroundCircleRadius,
      fill: circleFillColor,
      opacity: 0.25,
      strokeWidth: 0,
    });

    const foregroundArc = new konva.Arc({
      id: 'foreground-circle-arc',
      outerRadius: foregroundArchRadius,
      innerRadius: foregroundArchRadius / 1.75,
      angle: 360,
      fill: circleFillColor,
      strokeWidth: 0,
    });

    circleGroup.add(backgroundCircle);
    circleGroup.add(backgroundCircleStroke);
    circleGroup.add(foregroundCircle);
    circleGroup.add(foregroundArc);

    this._addDebugElements(circleGroup);

    return circleGroup;
  }

  private _createRectMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!attributes) {
      return;
    }

    const rectFillColor = this._getColor(attributes.color);

    const rectGroup = new konva.Group({
      id: `marker-group-${++this._markersCount}`,
      name: 'marker-rect-group',
      x: attributes.x,
      y: attributes.y,
      width: attributes.width,
      height: attributes.height,
      draggable: attributes.draggable,
      color: rectFillColor,
      hasInfoIcon: attributes.hasInfoIcon,
    });

    const backgroundRect = new konva.Rect({
      id: 'background-rect',
      width: attributes.width,
      height: attributes.height,
      fill: rectFillColor,
      opacity: 0.5,
      strokeWidth: 0,
    });

    const backgroundRectStroke = new konva.Rect({
      id: 'background-rect-stroke',
      width: attributes.width,
      height: attributes.height,
      fill: 'transparent',
      stroke: attributes.hasInfoIcon ? this._getColor(models.PlanViewerMarkerColor.Gray) : rectFillColor,
      strokeWidth: 0,
    });

    const foregroundRect = new konva.Rect({
      id: 'foreground-rect',
      width: attributes.width,
      height: attributes.height,
      fill: 'transparent',
      stroke: attributes.hasInfoIcon ? this._getColor(models.PlanViewerMarkerColor.Gray) : rectFillColor,
      strokeWidth: 1,
    });

    rectGroup.add(backgroundRect);
    rectGroup.add(backgroundRectStroke);
    rectGroup.add(foregroundRect);

    this._addDebugElements(rectGroup);

    return rectGroup;
  }

  private _createCrossMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!attributes) {
      return;
    }

    const crossFillColor = this._getColor(attributes.color);

    const crossSize = attributes.width;

    const crossGroup = new konva.Group({
      id: `marker-group-${++this._markersCount}`,
      name: 'marker-cross-group',
      x: attributes.x,
      y: attributes.y,
      height: crossSize,
      draggable: attributes.draggable,
    });

    const drawCrossFunc = (context: konva.Context, shape: konva.Shape) => {
      context.beginPath();

      const size = <number>shape.getAttr('width');
      const x = <number>shape.getAttr('x');
      const y = <number>shape.getAttr('y');

      context.moveTo(x - size, y - size);
      context.lineTo(x + size, y + size);
      context.moveTo(x - size, y + size);
      context.lineTo(x + size, y - size);

      context.fillStrokeShape(shape);
    };

    const backgroundCross = new konva.Shape({
      id: 'background-cross',
      fill: crossFillColor,
      width: crossSize,
      height: crossSize,
      stroke: crossFillColor,
      strokeWidth: 2,
      sceneFunc: drawCrossFunc,
    });

    const backgroundCrossStroke = new konva.Shape({
      id: 'background-cross-stroke',
      fill: crossFillColor,
      width: crossSize,
      height: crossSize,
      stroke: crossFillColor,
      strokeWidth: 0,
      sceneFunc: drawCrossFunc,
    });

    const foregroundCross = new konva.Rect({
      id: 'foreground-cross',
      fill: 'transparent',
      x: -crossSize,
      y: -crossSize,
      width: crossSize * 2,
      height: crossSize * 2,
      strokeWidth: 0,
    });

    crossGroup.add(backgroundCross);
    crossGroup.add(backgroundCrossStroke);
    crossGroup.add(foregroundCross);

    this._addDebugElements(crossGroup);

    return crossGroup;
  }

  private _createPolygonMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!attributes) {
      return;
    }

    const polygonFillColor = this._getColor(attributes.color);

    const polygonGroup = new konva.Group({
      id: `marker-group-${++this._markersCount}`,
      name: 'marker-polygon-group',
      startingPoint: [attributes.x, attributes.y],
      x: attributes.x,
      y: attributes.y,
      width: attributes.width,
      height: attributes.height,
      draggable: attributes.draggable,
      color: polygonFillColor,
      hasInfoIcon: attributes.hasInfoIcon,
    });

    const backgroundPolygon = new konva.Line({
      id: 'background-polygon',
      points: attributes.points || [0, 0],
      closed: attributes.closed,
      fill: polygonFillColor,
      opacity: 0.5,
      strokeWidth: 0,
    });

    const backgroundPolygonStroke = new konva.Line({
      id: 'background-polygon-stroke',
      points: attributes.points || [0, 0],
      closed: attributes.closed,
      fill: 'transparent',
      stroke: attributes.hasInfoIcon ? this._getColor(models.PlanViewerMarkerColor.Gray) : polygonFillColor,
      strokeWidth: 0,
      hitStrokeWidth: 0,
    });

    const foregroundPolygon = new konva.Line({
      id: 'foreground-polygon',
      points: attributes.points || [0, 0],
      closed: attributes.closed,
      fill: 'transparent',
      lineJoin: 'bevel',
      stroke: attributes.hasInfoIcon ? this._getColor(models.PlanViewerMarkerColor.Gray) : polygonFillColor,
      strokeWidth: 1,
    });

    polygonGroup.add(backgroundPolygon);
    polygonGroup.add(backgroundPolygonStroke);
    polygonGroup.add(foregroundPolygon);

    this._addDebugElements(polygonGroup);

    return polygonGroup;
  }

  private _continuePolygonMarker(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): konva.Group {
    const backgroundPolygon = marker.findOne('#background-polygon');
    const backgroundPolygonStroke = marker.findOne('#background-polygon-stroke');
    const foregroundPolygon = marker.findOne('#foreground-polygon');

    const points = backgroundPolygon.attrs.points;

    let isClosed = false;
    if (points.length >= 6) {
      if (attributes.width < 6 && attributes.width > -6 &&
          attributes.height < 6 && attributes.height > -6) {
        attributes.width = 0;
        attributes.height = 0;
        isClosed = true;
      }
    }

    const nextPoints = [
      0,
      0,
      ...points.slice(2, points.length - 2),
      attributes.width,
      attributes.height,
    ];

    if (!isClosed) {
      nextPoints.push(attributes.width, attributes.height);
    }

    backgroundPolygon.setAttrs({
      points: nextPoints,
      closed: isClosed,
    });
    backgroundPolygonStroke.setAttrs({
      points: nextPoints,
      closed: isClosed,
    });
    foregroundPolygon.setAttrs({
      points: nextPoints,
      closed: isClosed,
    });

    marker.setAttrs({
      width: attributes.width,
      height: attributes.height,
      closed: isClosed,
    });

    return marker;
  }

  private _changeCircleMarkerSize(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): konva.Group {
    if (!marker || !attributes) {
      return;
    }

    const backgroundCircle = marker.findOne('#background-circle');
    const backgroundCircleStroke = marker.findOne('#background-circle-stroke');
    const foregroundCircle = marker.findOne('#foreground-circle');
    const foregroundArc = marker.findOne('#foreground-circle-arc');

    if (!backgroundCircle || !backgroundCircleStroke || !foregroundCircle || !foregroundArc) {
      return;
    }

    const backgroundCircleRadius = Math.abs(attributes.width);
    const foregroundCircleRadius = backgroundCircleRadius / 1.75;
    const foregroundArchRadius = foregroundCircleRadius / 2;

    backgroundCircle.setAttrs({
      radius: backgroundCircleRadius,
    });

    backgroundCircleStroke.setAttrs({
      radius: backgroundCircleRadius,
    });

    foregroundCircle.setAttrs({
      radius: foregroundCircleRadius,
    });

    foregroundArc.setAttrs({
      outerRadius: foregroundArchRadius,
      innerRadius: foregroundArchRadius / 1.75,
    });

    marker.setAttrs({
      height: backgroundCircleRadius,
    });

    return marker;
  }

  private _changeRectMarkerSize(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): konva.Group {
    if (!marker || !attributes) {
      return;
    }

    const backgroundRect = marker.findOne('#background-rect');
    const backgroundRectStroke = marker.findOne('#background-rect-stroke');
    const foregroundRect = marker.findOne('#foreground-rect');

    if (!backgroundRect || !backgroundRectStroke || !foregroundRect) {
      return;
    }

    backgroundRect.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    backgroundRectStroke.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    foregroundRect.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    marker.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    return marker;
  }

  private _changeCrossMarkerSize(marker: konva.Group, attributes: models.PlanViewerMarkerSizeAttributes): konva.Group {
    if (!marker || !attributes) {
      return;
    }

    const backgroundCross = marker.findOne('#background-cross');
    const backgroundCrossStroke = marker.findOne('#background-cross-stroke');
    const foregroundCross = marker.findOne('#foreground-cross');

    if (!backgroundCross || !backgroundCrossStroke || !foregroundCross) {
      return;
    }

    const crossSize = attributes.width;

    backgroundCross.setAttrs({
      width: crossSize,
      height: crossSize,
    });

    backgroundCrossStroke.setAttrs({
      width: crossSize,
      height: crossSize,
    });

    foregroundCross.setAttrs({
      x: -crossSize,
      y: -crossSize,
      width: crossSize * 2,
      height: crossSize * 2,
    });

    marker.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    return marker;
  }

  private _changePolygonMarkerSize(
    marker: konva.Group,
    attributes: models.PlanViewerMarkerSizeAttributes,
  ): konva.Group {
    if (!marker || !attributes) {
      return;
    }

    const backgroundPolygon = marker.findOne('#background-polygon');
    const backgroundPolygonStroke = marker.findOne('#background-polygon-stroke');
    const foregroundPolygon = marker.findOne('#foreground-polygon');

    if (!backgroundPolygon || !backgroundPolygonStroke || !foregroundPolygon) {
      return;
    }

    const points = backgroundPolygon.attrs.points;

    if (points.length >= 6) {
      if (attributes.width < 6 && attributes.width > -6 &&
          attributes.height < 6 && attributes.height > -6) {
        attributes.width = 0;
        attributes.height = 0;
      }
    }

    const nextPoints = [
      0,
      0,
      ...points.slice(2, points.length - 2),
      attributes.width,
      attributes.height,
    ];

    backgroundPolygon.setAttrs({
      points: nextPoints,
    });
    backgroundPolygonStroke.setAttrs({
      points: nextPoints,
    });
    foregroundPolygon.setAttrs({
      points: nextPoints,
    });

    marker.setAttrs({
      width: attributes.width,
      height: attributes.height,
    });

    return marker;
  }

  private _changeCircleMarkerColor(marker: konva.Group, attributes: models.PlanViewerMarkerColorAttributes): void {
    if (!marker || !attributes) {
      return;
    }

    const backgroundCircle = marker.findOne('#background-circle');
    const backgroundCircleStroke = marker.findOne('#background-circle-stroke');
    const foregroundCircle = marker.findOne('#foreground-circle');
    const foregroundArc = marker.findOne('#foreground-circle-arc');

    if (!backgroundCircle || !backgroundCircleStroke || !foregroundCircle || !foregroundArc) {
      return;
    }

    const color = this._getColor(attributes.color);

    backgroundCircle.setAttrs({ fill: color });
    backgroundCircleStroke.setAttrs({ stroke: color });
    foregroundCircle.setAttrs({ fill: color });
    foregroundArc.setAttrs({ fill: color });
  }

  private _changeRectMarkerColor(marker: konva.Group, attributes: models.PlanViewerMarkerColorAttributes): void {
    if (!marker || !attributes) {
      return;
    }

    const backgroundRect = marker.findOne('#background-rect');
    const backgroundRectStroke = marker.findOne('#background-rect-stroke');
    const foregroundRect = marker.findOne('#foreground-rect');

    if (!backgroundRect || !backgroundRectStroke || !foregroundRect) {
      return;
    }

    const color = this._getColor(attributes.color);

    backgroundRect.setAttrs({ fill: color });
    backgroundRectStroke.setAttrs({ stroke: color });
    foregroundRect.setAttrs({ stroke: color });
  }

  private _changeCrossMarkerColor(marker: konva.Group, attributes: models.PlanViewerMarkerColorAttributes): void {
    if (!marker || !attributes) {
      return;
    }

    const backgroundCross = marker.findOne('#background-cross');
    const backgroundCrossStroke = marker.findOne('#background-cross-stroke');

    if (!backgroundCross || !backgroundCrossStroke) {
      return;
    }

    const color = this._getColor(attributes.color);

    backgroundCross.setAttrs({ stroke: color });
    backgroundCrossStroke.setAttrs({ stroke: color });
  }

  private _changePolygonMarkerColor(marker: konva.Group, attributes: models.PlanViewerMarkerColorAttributes): void {
    if (!marker || !attributes) {
      return;
    }

    const polygonStroke = marker.findOne('#background-polygon-stroke');

    if (!polygonStroke) {
      return;
    }

    const color = this._getColor(attributes.color);

    polygonStroke.setAttrs({ stroke: color });
  }

  private _getColor(color: models.PlanViewerMarkerColor): string {
    switch (color) {
      case models.PlanViewerMarkerColor.Blue:
        return PlanViewerMarkerService._blueColor;

      case models.PlanViewerMarkerColor.Gray:
        return PlanViewerMarkerService._grayColor;

      case models.PlanViewerMarkerColor.White:
        return PlanViewerMarkerService._whiteColor;

      case models.PlanViewerMarkerColor.Green:
        return PlanViewerMarkerService._greenColor;

      case models.PlanViewerMarkerColor.Red:
        return PlanViewerMarkerService._redColor;

      default:
        return PlanViewerMarkerService._blueColor;
    }
  }

  private _addDebugElements(marker: konva.Group): void {
    if (!marker || !PlanViewerMarkerService._isDebugMode) {
      return;
    }

    const markerBoundingBox = this.getMarkerBoundingBox(marker);
    if (!markerBoundingBox) {
      return;
    }

    const boundingBox = new konva.Rect({
      listening: false,
      strokeWidth: 1,
      stroke: 'chartreuse',
      x: markerBoundingBox.x,
      y: markerBoundingBox.y,
      width: markerBoundingBox.width,
      height: markerBoundingBox.height,
      offsetX: marker.x(),
      offsetY: marker.y(),
    });

    marker.add(boundingBox);

    const boundingBoxDot = new konva.Circle({
      listening: false,
      radius: 2,
      fill: 'chartreuse',
      x: markerBoundingBox.x,
      y: markerBoundingBox.y,
      offsetX: marker.x(),
      offsetY: marker.y(),
    });

    marker.add(boundingBoxDot);

    const boundingBoxCenterDot = new konva.Circle({
      listening: false,
      radius: 2,
      fill: 'chartreuse',
      x: markerBoundingBox.centerX,
      y: markerBoundingBox.centerY,
      offsetX: marker.x(),
      offsetY: marker.y(),
    });

    marker.add(boundingBoxCenterDot);

    const positionDot = new konva.Circle({
      listening: false,
      radius: 2,
      fill: 'aqua',
    });

    marker.add(positionDot);
  }
}
