<div class="alert-container-backdrop"
     *ngIf="alerts && alerts.length"
     @animateAlertContainer>
  <div class="alert-container">
    <app-alert *ngFor="let alert of alerts; let index = index; trackBy: identifyAlert"
               [alert]="alert"
               @animateAlert>
    </app-alert>
  </div>
</div>
