import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ApplicationStore, ApplicationState } from './application.store';

import * as models from '../../../infrastructure/models/generated';

@Injectable({ providedIn: 'root' })
export class ApplicationQuery extends QueryEntity<ApplicationState> {
  getSelectedPortfolio$: Observable<number> = this
    .select(state => state.ui.selectedPortfolioId);

  getConfiguration$: Observable<models.IConfigurationViewModel> = this
    .select(state => state.configuration);

  getLandlordDashboardFilter$: Observable<models.ILandlordDashboardFilterViewModel> = this
    .select(state => state.ui.landlordDashboardFilters);

  getLandlordDashboardDisabledValue$: Observable<boolean> = this
    .select(state => state.ui.filterDisabled);

  constructor(protected store: ApplicationStore) {
    super(store);
  }
}
