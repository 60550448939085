import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import moment from 'moment/moment';

import { MessageManager } from '../message.manager';

import { VersionedFileCommentRepository } from './versioned-file-comment.repository';

import * as models from './versioned-file-comment.model';

export class VersionedFileCommentManager extends MessageManager<models.VersionedFileComment> {
  private readonly _startupInfo: models.StartupInfo;
  private readonly _versionedFileCommentType: models.VersionedFileCommentType;
  private readonly _versionedFileId?: number;
  private readonly _lease: models.Lease;

  private readonly _versionedFileCommentRepository: VersionedFileCommentRepository;

  constructor(
    versionedFileCommentRepository: VersionedFileCommentRepository,
    startupInfo: models.StartupInfo,
    versionedFileCommentType: models.VersionedFileCommentType,
    versionedFileId?: number,
    lease?: models.Lease,
  ) {
    super();

    this._versionedFileCommentRepository = versionedFileCommentRepository;

    this._startupInfo = startupInfo;
    this._versionedFileCommentType = versionedFileCommentType;
    this._versionedFileId = versionedFileId;
    this._lease = lease;
  }

  addMessage(message: models.VersionedFileCommentMessage): Observable<models.VersionedFileCommentMessage> {
    return this._versionedFileCommentRepository
      .addMessage({
        ...this.fromMessage(message),
        authorId: this._startupInfo.id,
        versionedFileId: this._versionedFileId,
        versionedFileCommentType: this._versionedFileCommentType,
      })
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  getMessages(skip: number, take: number): Observable<Array<models.VersionedFileCommentMessage>> {
    return this._versionedFileCommentRepository
      .getMessages(
        this._versionedFileCommentType,
        this._lease ? this._lease.id : null,
        skip,
        take,
      )
      .pipe(
        map(response => response.map(item => this.toMessage(item))),
        share(),
      );
  }

  updateMessage(message: models.VersionedFileCommentMessage): Observable<models.VersionedFileCommentMessage> {
    return this._versionedFileCommentRepository
      .updateMessage(
        this.fromMessage(message),
      )
      .pipe(
        map(() => message),
        share(),
      );
  }

  deleteMessage(message: models.VersionedFileCommentMessage): Observable<void> {
    return this._versionedFileCommentRepository
      .deleteMessage(
        this.fromMessage(message),
      );
  }

  toMessage(x: models.VersionedFileComment): models.VersionedFileCommentMessage {
    const result = <models.Message>{
      id: x.id,
      content: x.content,
      createdOn: x.createdOn,
      isMy: this._startupInfo.id === x.authorId,
      isRead: x.isRead,
      authorDisplayName: x.author ? (x.author.displayName ? x.author.displayName : x.author.primaryEmail) : '',
      edited: this.isEdited(x),
      versionedFile: x.versionedFile,
      data: {
        versionedFileId: x.versionedFileId,
        authorId: x.authorId,
      },
      badge: this.getChatBadgeText(this._lease, x.authorId),
    };

    result.authorAvatarUrl = 'assets/img/avatar.png';
    if (x.author && x.author.avatar && x.author.avatar) {
      result.authorAvatarUrl = x.author.avatar.thumbnailUrl ? x.author.avatar.thumbnailUrl : x.author.avatar.url;
    }

    return result;
  }

  fromMessage(x: models.VersionedFileCommentMessage): models.VersionedFileComment {
    return <models.VersionedFileComment>{
      id: x.id,
      content: x.content,
      authorId: x.data ? x.data.authorId : null,
      versionedFileId: x.data ? x.data._versionedFileId : null,
      fileId: x.uploadedFileId,
    };
  }

  isEdited(x: models.VersionedFileComment): boolean {
    try {
      return (
        x.createdOn &&
        x.updatedOn &&
        moment(x.updatedOn).isAfter(moment(x.createdOn))
      );
    } catch (e) {
      return false;
    }
  }
}
