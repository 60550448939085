import * as models from '@statera/sdk/common';

export type PagedResult<T> = models.IPagedResult<T>;

export type LandlordDashboardFilterViewModel = models.ILandlordDashboardFilterViewModel;

export type PortfolioViewModel = models.IPortfolioViewModel;
export type PortfolioBuildingViewModel = models.IPortfolioBuildingViewModel;

export type AddressViewModel = models.IAddressViewModel;
export type BuildingViewModel = models.IBuildingViewModel;
export type DealViewModel = models.IDealViewModel;
export type SuiteViewModel = models.ISuiteViewModel;
export type BriefDealSummaryViewModel = models.IBriefDealSummaryViewModel;

export type CompactTenantViewModel = models.ICompactTenantViewModel;
export type TenantDetailViewModel = models.ITenantDetailViewModel;

export type AttachedFolderViewModel = models.IAttachedFolderViewModel;
export type AttachedFileViewModel = models.IAttachedFileViewModel;
export type BuildingFileViewModel = models.IBuildingFileViewModel;

export enum DocumentAttachmentPlaces {
  Building,
  BuildingTenant,
  TenantFiles
}
