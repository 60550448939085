import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { FinancialsRequestManager } from './financials-request.manager';
import { FinancialsRequestRepository } from './financials-request.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    FinancialsRequestManager,
    FinancialsRequestRepository,
  ],
})
export class FinancialsRequestModule {
  static forChild(configuration: Configuration): ModuleWithProviders<FinancialsRequestModule> {
    return {
      ngModule: FinancialsRequestModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        FinancialsRequestManager,
        FinancialsRequestRepository,
      ],
    };
  }
}
