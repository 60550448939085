import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';


@Component({
  selector: 'app-code-compliance',
  templateUrl: './code-compliance.component.html',
  styleUrls: ['./code-compliance.component.scss']
})
export class CodeComplianceComponent extends BaseTermDirective<models.ICodeComplianceTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  CodeComplianceTermType: typeof models.CodeComplianceTermType = models.CodeComplianceTermType;

  private readonly _destroy$: Subject<void>;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'To be addressed in lease document',
          value: models.CodeComplianceTermType.ToBeAddressedInLease,
        },
        {
          name:
            'The Building and all systems, at Landlord’s expense, shall comply with all code requirements throughout the lease term. ' +
            'Any costs associated with Code Compliance in all Building common and core areas will be at the Landlord’s sole expense ' +
            'and not passed on to Tenant throughout the lease term and any extension, unless such compliance is due to a Tenant request.',
          value: models.CodeComplianceTermType.LandlordExpense,
        },
        {
          name: 'Custom',
          value: models.CodeComplianceTermType.Custom,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.CodeComplianceTermType.PerCurrentLease,
      },
      {
        name: 'To be addressed in lease document',
        value: models.CodeComplianceTermType.ToBeAddressedInLease,
      },
      {
        name:
          'The Building and all systems, at Landlord’s expense, shall comply with all code requirements throughout the lease term. ' +
          'Any costs associated with Code Compliance in all Building common and core areas will be at the Landlord’s sole expense ' +
          'and not passed on to Tenant throughout the lease term and any extension, unless such compliance is due to a Tenant request.',
        value: models.CodeComplianceTermType.LandlordExpense,
      },
      {
        name: 'Custom',
        value: models.CodeComplianceTermType.Custom,
      },
    ];
  }
}
