import { Injectable } from '@angular/core';

import * as ng from '@angular/core';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';
import { ComponentLoaderService } from '../../infrastructure/services/component-loader.service';

import { PlanViewerMarkerPopoverOptions } from '../models/plan-viewer-marker-popover-options.model';

import * as planViewerModels from '../models/plan-viewer.model';

import { PlanViewerMarkerPopoverComponent } from '../components/plan-viewer-marker-popover/plan-viewer-marker-popover.component';

@Injectable()
export class PlanViewerMarkerPopoverService {
  private readonly _renderer: ng.Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;
  private readonly _config: PlanViewerMarkerPopoverOptions;

  private _loader: ComponentLoaderService<PlanViewerMarkerPopoverComponent>;

  constructor(rendererFactory: ng.RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
    this._config = new PlanViewerMarkerPopoverOptions();
  }

  show(
    marker: planViewerModels.PlanViewerMarkerRef,
    config?: PlanViewerMarkerPopoverOptions,
    mode?: planViewerModels.PlanViewerMode,
  ): PlanViewerMarkerPopoverComponent {
    if (this._loader) {
      return this._loader.instance;
    }

    this._loader = this._componentLoaderFactory
      .createLoader<PlanViewerMarkerPopoverComponent>(null, null, null);

    const imageViewerContainerRef = this._loader
      .provide({
        provide: PlanViewerMarkerPopoverOptions,
        useValue: {...config, planViewerMarkerPopupService: this},
      })
      .attach(PlanViewerMarkerPopoverComponent);

    imageViewerContainerRef.show(null, null, { marker, mode });

    return imageViewerContainerRef.instance;
  }

  hide(): void {
    if (!this._loader) {
      return;
    }

    this._loader.hide();
    this._loader = null;
  }
}
