<dx-scroll-view class="improvement-cost-rejection"
                width="100%" height="100%">
  <!-- Start: Title -->
  <h5 class="title"
      *ngIf="title">
    {{title}}
  </h5>
  <!-- End: Title -->
  <!-- Start: Content -->
  <form name="form" novalidate
        (ngSubmit)="submit()"
        #form="ngForm">
    <!-- Start: Description -->
    <div class="dx-fieldset">
      <p>
        I see you are not in agreement with the estimated dollar amount here.
        Please provide an allowance that you feel is suitable and send back to {{isLandlord ? 'Tenant' : 'Landlord'}} for review.
      </p>
    </div>
    <!-- End: Description -->
    <!-- Start: Value input -->
    <div class="dx-fieldset estimated-dollar-amount">
      <div class="dx-field">
        <div class="dx-field-label">
          Insert Estimated Dollar Amount
        </div>
        <div class="dx-field-value">
          <dx-number-box name="cost" id="cost" required
                         format="$#,##0.00"
                         placeholder="$0.00"
                         min="0" step="0"
                         [isValid]="form.submitted ? cost.valid : true"
                         [(ngModel)]="amount"
                         #cost="ngModel">
          </dx-number-box>
          <div class="previous-value"
               [style.color]="isLandlord ? '#0E4C92' : '#FF9292'">
            {{originalAmount | safeCurrency}}
          </div>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && cost.hasError('required')">
            Estimated Dollar Amount is required
          </div>
        </div>
      </div>
    </div>
    <!-- End: Value input -->
    <!-- Start: Action buttons -->
    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Submit"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>
    <!-- End: Action buttons -->
  </form>
  <!-- End: Content -->
</dx-scroll-view>
