import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { OrderByPipe } from '../../../infrastructure/pipes/order-by.pipe';

import * as models from '../../../infrastructure/models/generated';

export interface TableRow {
  tenantCompanyName: string;
  buildingAddress: string;
  unit: string;
  squareFeet: number;
  leaseExpirationDate: string;
  inActiveLease: boolean;
  buildingId: number;
  leaseId: number;
}

@Component({
  selector: 'app-analytics-expirations-report-single-period-table',
  templateUrl: 'expirations-report-single-period-table.component.html',
  styleUrls: ['expirations-report-single-period-table.component.scss'],
})
export class ExpirationsReportSinglePeriodTableComponent {
  @Input() set expirationsReportPeriod(value: models.IExpirationsReportPeriodViewModel) {
    this._expirationsReportPeriod = value;
    this.tableData = this.getTableData();
  }

  get expirationsReportPeriod() {
    return this._expirationsReportPeriod;
  }

  orderByConfig: string;
  tableData: Array<TableRow>;

  private readonly _router: Router;
  private readonly _orderByPipe: OrderByPipe;
  private readonly _dialogRefService: DialogRefService;
  private _expirationsReportPeriod: models.IExpirationsReportPeriodViewModel;

  constructor(router: Router, orderByPipe: OrderByPipe, dialogRefService: DialogRefService) {
    this._router = router;
    this._orderByPipe = orderByPipe;
    this._dialogRefService = dialogRefService;
  }

  getTableData(): Array<TableRow> {
    if (
      !this.expirationsReportPeriod ||
      !this.expirationsReportPeriod.table ||
      !this.expirationsReportPeriod.table.length
    ) {
      return [];
    }

    return this.expirationsReportPeriod.table
      .map(record => {
        return <TableRow>{
          buildingAddress: record.buildingAddress,
          unit: record.buildingUnitName,
          squareFeet: record.squareFeet,
          tenantCompanyName: record.tenantCompany && record.tenantCompany.name,
          leaseExpirationDate: record.leaseExpirationDate,
          inActiveLease: record.inActiveLease,
          buildingId: record.buildingId,
          leaseId: record.leaseId
        };
      });
  }

  applyColumnOrder(columnName: string): void {
    this.orderByConfig = columnName === this.orderByConfig
      ? `-${columnName}`
      : columnName;

    this.tableData = this._orderByPipe.transform(this.tableData, this.orderByConfig);
  }

  getTotalSquareFeet(): number {
    let totalSquareFeet = 0;

    if (!this.tableData || !this.tableData.length) {
      return totalSquareFeet;
    }

    for (let i = 0, num = this.tableData.length; i < num; i++) {
      totalSquareFeet += this.tableData[i].squareFeet;
    }

    return totalSquareFeet;
  }

  navigateTo3PanelView(row: TableRow): void {
    if (!row.leaseId) {
      return;
    }

    this._dialogRefService.hideAll();
    if (row.inActiveLease) {
      this._router.navigate(['colabo', 'main', row.leaseId]);
    } else {
      this._router.navigate(['landlord', 'buildings', 'building-desktop', row.buildingId], {
        queryParams: {
          'tab': 'units',
          'searchText': row.unit
        }
      });
    }
  }
}
