import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-multi-range-slider',
  templateUrl: './multi-range-slider.component.html',
})
export class MultiRangeSliderComponent implements OnInit, OnChanges {
  @ViewChild('leftThumb') leftThumb: ElementRef<HTMLInputElement>;
  @ViewChild('rightThumb') rightThumb: ElementRef<HTMLInputElement>;

  @Input() min: number;
  @Input() max: number;

  @Input() step: number;

  @Input() minValue: number;
  @Output() minValueChange: EventEmitter<number>;

  @Input() maxValue: number;
  @Output() maxValueChange: EventEmitter<number>;

  constructor() {
    this.minValueChange = new EventEmitter<number>();
    this.maxValueChange = new EventEmitter<number>();
  }

  ngOnInit(): void {
    this.step = this.step || 1;

    this.minValue = this.minValue || this.min;
    this.maxValue = this.maxValue || this.max;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.step) {
      this.step = this.step || 1;
    }

    if (changes.minValue || changes.maxValue) {
      this.minValue = this.minValue || this.min;
      this.maxValue = this.maxValue || this.max;
    }
  }

  getRangeStyle(): Partial<CSSStyleDeclaration> {
    const minPercent = this._getPercent(this.minValue);
    const maxPercent = this._getPercent(this.maxValue);

    const left = minPercent;

    let width = maxPercent - minPercent;
    if (width < 0) {
      width = 0;
    }
    if (width > (100 - minPercent)) {
      width = 100 - minPercent;
    }

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  }

  handleMinValueChange(minValue: number): void {
    const value = Math.min(minValue, this.maxValue - 1, this.max - 1);

    this.leftThumb.nativeElement.value = <any>value;

    this.minValueChange.emit(value);
  }

  handleMaxValueChange(maxValue: number): void {
    const value = Math.max(maxValue, this.minValue + 1, this.min + 1);

    this.rightThumb.nativeElement.value = <any>value;

    this.maxValueChange.emit(value);
  }

  private _getPercent(value: number): number {
    return Math.round(((value - this.min) / (this.max - this.min)) * 100);
  }
}
