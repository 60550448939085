import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyRepository } from './company.repository';
import { Company, CompanyType } from './company.model';
import {ICompanyPredictionViewModel} from '@statera/sdk/common';

@Injectable()
export class CompanyManager {
  private readonly _companyRepository: CompanyRepository;

  constructor(companyRepository: CompanyRepository) {
    this._companyRepository = companyRepository;
  }

  getCompanies(companyType: CompanyType): Observable<Array<Company>> {
    return this._companyRepository.getCompanies(companyType);
  }

  searchCompanies(text: string, companyType: CompanyType): Observable<Array<ICompanyPredictionViewModel>> {
    return this._companyRepository.searchCompanies(text, companyType);
  }

  updateCompany(company: Company): Observable<Company> {
    return this._companyRepository.updateCompany(company);
  }

  getCompany(companyId: number): Observable<Company> {
    return this._companyRepository.getCompany(companyId);
  }
}
