import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LandlordStore } from './landlord.store';
import { LandlordRepository } from './landlord.repository';

import * as models from './landlord.model';

@Injectable()
export class LandlordManager {
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordRepository: LandlordRepository;

  constructor(landlordStore: LandlordStore, landlordRepository: LandlordRepository) {
    this._landlordStore = landlordStore;
    this._landlordRepository = landlordRepository;
  }

  getPortfolios(): Observable<Array<models.PortfolioViewModel>> {
    return this._landlordRepository
      .getPortfolios()
      .pipe(
        tap((items) => (
          this._landlordStore.portfolios$.next(items)
        )),
      );
  }

  searchBuildingsByFilter(filter: models.LandlordDashboardFilterViewModel):
    Observable<models.PagedResult<models.PortfolioBuildingViewModel>> {
    return this._landlordRepository
      .searchBuildingsByFilter(filter);
  }

  addPortfolio(model: models.PortfolioViewModel): Observable<Array<models.PortfolioViewModel>> {
    return this._landlordRepository
      .addPortfolio(model)
      .pipe(
        tap((items) => (
          this._landlordStore.portfolios$.next(items)
        ))
      );
  }

  saveBuilding(model: models.BuildingViewModel): Observable<Array<models.PortfolioViewModel>> {
    return this._landlordRepository
      .saveBuilding(model)
      .pipe(
        tap((items) => {
          this._landlordStore.portfolios$.next(items);
          this._landlordStore.preSelectedPortfolio$.next(null);
        }),
      );
  }

  getTenants(buildingId: number): Observable<Array<models.CompactTenantViewModel>> {
    return this._landlordRepository
      .getTenants(buildingId);
  }

  getDeals(buildingId: number): Observable<Array<models.DealViewModel>> {
    return this._landlordRepository
      .getDeals(buildingId);
  }

  getSuites(buildingId: number): Observable<Array<models.SuiteViewModel>> {
    return this._landlordRepository
      .getSuites(buildingId);
  }

  getBriefDealSummaries(leaseId: number): Observable<Array<models.BriefDealSummaryViewModel>> {
    return this._landlordRepository
      .getBriefDealSummaries(leaseId);
  }

  getTenantDetails(leaseId: number): Observable<models.TenantDetailViewModel> {
    return this._landlordRepository
      .getTenantDetails(leaseId);
  }

  // building files

  getBuildingFileFolders(): Observable<Array<models.AttachedFolderViewModel>> {
    return this._landlordRepository
      .getBuildingFileFolders();
  }

  deleteBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._landlordRepository
      .deleteBuildingFiles(files);
  }

  updateBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._landlordRepository
      .updateBuildingFiles(files);
  }

  shareBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<Array<models.AttachedFileViewModel>> {
    return this._landlordRepository
      .shareBuildingFiles(files);
  }

  // lease files

  getLeaseFiles(leaseId: number): Observable<Array<models.AttachedFileViewModel>> {
    return this._landlordRepository
      .getLeaseFiles(leaseId);
  }

  getLeaseFileFolders(): Observable<Array<models.AttachedFolderViewModel>> {
    return this._landlordRepository
      .getLeaseFileFolders();
  }

  deleteLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._landlordRepository
      .deleteLeaseFiles(files);
  }

  updateLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._landlordRepository
      .updateLeaseFiles(files);
  }

  shareLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<Array<models.AttachedFileViewModel>> {
    return this._landlordRepository
      .shareLeaseFiles(files);
  }
}
