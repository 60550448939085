import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { AlertService } from '../../../alert/services/alert.service';
import { AuthManager } from '@statera/sdk/auth';

import { IPasswordResetViewModel } from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  vm: IPasswordResetViewModel = {id: '', token: '', password: '', confirmPassword: ''};
  submitted = false;

  private readonly _router: Router;
  private readonly _route: ActivatedRoute;
  private readonly _authManager: AuthManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  private readonly _destroy$: Subject<void>;

  constructor(
    router: Router,
    route: ActivatedRoute,
    authManager: AuthManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager
  ) {
    this._router = router;
    this._route = route;
    this._authManager = authManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._destroy$ = new Subject<void>();
  }

  // This logic contradicts itself. We should not redirect into ourselves.
  ngOnInit(): void {
    if (this._route.snapshot.queryParams) {
      this.vm = Object.assign(this.vm, <IPasswordResetViewModel>this._route.snapshot.queryParams);
    }
  }

  // Add custom validator to have form validating instead here
  submit(form: NgForm): void {
    if (form.invalid || (this.vm.password !== this.vm.confirmPassword)) {
      return;
    }

    this.submitted = true;

    this._authManager
      .resetPassword({...this.vm})
      .pipe(
        tap(() => {
          this._router.navigate(['/signin-user']);
          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getPasswordChangedAlertText(),
          });
        }),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => {
        this.submitted = false;
      });
  }
}
