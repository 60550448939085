import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {SignatureManager } from '@statera/sdk/signature';

@Component({
  selector: 'app-signature-by-token',
  templateUrl: './signature-by-token.component.html',
  styleUrls: ['./signature-by-token.component.scss']
})
export class SignatureByTokenComponent implements OnInit {
  private _route: ActivatedRoute;
  private readonly _signatureManager: SignatureManager;

  constructor(signatureManager: SignatureManager, route: ActivatedRoute) {
    this.getScreenSize();
    this._signatureManager = signatureManager;
    this._route = route;
  }
  screenHeight: number;
  screenWidth: number;
  url: string;
  token: string;

  ngOnInit() {
    this.token = this._route.snapshot.queryParamMap.get('token');
    if (this.token) {
      const subscription = this._signatureManager
        .getSignatureInfo(this.token)
        .subscribe(x => {
          subscription.unsubscribe();
          this.url = x.embeddedSigningUrl;
        });
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  onSigned($event) {
    const subscription = this._signatureManager
      .sendSignature(this.token)
      .subscribe(x => {
        window.location.href = '/';
        subscription.unsubscribe();
      });
  }
}
