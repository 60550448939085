import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { BuildingFormComponent } from '../building-form/building-form.component';

@Component({
  selector: 'app-edit-building-mobile',
  templateUrl: './edit-building-mobile.component.html',
  styleUrls: ['./edit-building-mobile.component.scss']
})
export class EditBuildingMobileComponent implements OnInit {
  @ViewChild('buildingForm') buildingForm: BuildingFormComponent;

  id = 0;

  private readonly _route: ActivatedRoute;
  private readonly _buildingManager: BuildingManager;
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;

  constructor(
    route: ActivatedRoute,
    buildingManager: BuildingManager,
    landlordStore: LandlordStore,
    landlordManager: LandlordManager
  ) {
    this._buildingManager = buildingManager;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
    this._route = route;

    this._landlordStore
      .portfolios$
      .subscribe((portfolios) => {
        if (portfolios && this.buildingForm) {
          this.buildingForm.building = this._buildingManager
            .findInPortfolios(this.id, portfolios);
        }
      });
  }

  ngOnInit() {
    this._route
      .params
      .subscribe((params) => {
        this.id = parseInt(params['id'], 10);

        this._landlordStore
          .portfolios$
          .pipe(
            take(1),
          )
          .subscribe((portfolios) => {
            if (portfolios.length) {
              this.buildingForm.building = this._buildingManager
                .findInPortfolios(this.id, portfolios);
            } else {
              this._landlordManager
                .getPortfolios()
                .subscribe((fetchedPortfolios) => {
                  this.buildingForm.building = this._buildingManager
                    .findInPortfolios(this.id, fetchedPortfolios);
                });
            }
          });
      });
  }
}
