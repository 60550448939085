import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LeaseManager } from '@statera/sdk/lease';
import { DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import * as models from '../../../infrastructure/models/generated';

import { ProjectService } from '../../../shared/services/project.service';

import {
  ILeaseViewModel,
  IProjectViewModel,
  RenewalProjectTriggerType
} from '../../../infrastructure/models/generated';
import { LoaderService } from '../../../infrastructure/services/loader.service';

@Component({
  selector: 'app-letter-of-intent',
  templateUrl: './letter-of-intent.component.html',
  styleUrls: ['./letter-of-intent.component.scss'],
})
export class LetterOfIntentComponent implements OnInit, OnDestroy {
  private readonly _projectService: ProjectService;
  private readonly _leaseManager: LeaseManager;
  private readonly _loaderService: LoaderService;
  private readonly _destroy$: Subject<void>;

  constructor(projectService: ProjectService, leaseManager: LeaseManager, loaderService: LoaderService) {
    this._projectService = projectService;
    this._leaseManager = leaseManager;
    this._loaderService = loaderService;
    this._destroy$ = new Subject<void>();
  }

  height;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  letterOfIntent: models.IFileViewModel;

  @ViewChild('scrollView') scrollView: DxScrollViewComponent;

  @Input() lease: ILeaseViewModel;
  @Input() project: IProjectViewModel;
  @Input() popupVisible: boolean;

  @Output() signChange = new EventEmitter();
  @Output() popupVisibleChange = new EventEmitter();
  @Input() showSignButton = false;

  ngOnInit(): void {
    if (this.lease) {
      this._loaderService.show();

      this._leaseManager
        .getLetterOfIntent(this.lease.id)
        .pipe(
          tap(letterOfIntent => this.letterOfIntent = letterOfIntent),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  generateSignDocument(button: HTMLButtonElement): void {
    button.disabled = true;
    this._projectService
      .renewalProject(this.lease, RenewalProjectTriggerType.GenerateSignDocument, this.project)
      .subscribe(() => this.signChange.emit());
  }

  onResizeEnd(e: any): void {
    this.height = this._getHeight(parseInt(e.component.content().style.height, 10));
  }

  onShown(e: any): void {
    this.height = this._getHeight(parseInt(e.component.content().style.height, 10));
  }

  handleVisibilityChange(isVisible: boolean): void {
    this.popupVisible = isVisible;
    this.popupVisibleChange.next(isVisible);
  }

  handlePDFLoadComplete(): void {
    this._loaderService.hide();
  }

  private _getHeight(height: number): number {
    return height * 0.85;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
