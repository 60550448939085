import { Injectable, Renderer2, RendererFactory2, Type } from '@angular/core';
import { TooltipModel } from 'chart.js';

import { ComponentLoaderFactoryService } from '../../infrastructure/services/component-loader-factory.service';
import { ComponentLoaderService } from '../../infrastructure/services/component-loader.service';

export interface Context {
  tooltipModel: TooltipModel<any>;
  canvasElement: HTMLCanvasElement;
}

@Injectable()
export class AnalyticsTooltipService<TComponent, TModel> {
  private _loader: ComponentLoaderService<TComponent>;

  private readonly _renderer: Renderer2;
  private readonly _componentLoaderFactory: ComponentLoaderFactoryService;

  constructor(rendererFactory: RendererFactory2, componentLoaderFactory: ComponentLoaderFactoryService) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._componentLoaderFactory = componentLoaderFactory;
  }

  show(tooltipComponent: Type<TComponent>, model: TModel, context: Context): TComponent {
    if (this._loader) {
      return <TComponent>this._loader.instance;
    }

    this._loader = this._componentLoaderFactory.createLoader<TComponent>(null, null, null);

    const tooltipRef = this._loader.attach(tooltipComponent);

    tooltipRef.show(null, null, { analyticsTooltipService: this, model: model, context: context });

    return <TComponent>tooltipRef.instance;
  }

  hide(): void {
    if (!this._loader) {
      return;
    }

    this._loader.hide();
    this._loader = null;
  }
}
