<dx-popup class="document-viewer-container"
          [title]="title" [showCloseButton]="config.showCloseButton" [closeOnOutsideClick]="config.closeOnOutsideClick"
          [width]="config.width" [height]="config.height" [fullScreen]="config.fullScreen" [dragEnabled]="config.dragEnabled"
          [maxWidth]="config.maxWidth" [maxHeight]="config.maxHeight" [visible]="isVisible"
          (onShowing)="onShowing.emit($event)" (onShown)="onShown.emit($event)"
          (onHiding)="onHiding.emit($event)" (onHidden)="onHidden.emit($event)"
          (onContentReady)="onContentReady.emit($event)">
  <div class="document-viewer-container-content"
       *ngIf="isReady"
       #contentElement>
    <app-document-viewer [container]="contentElement" [documents]="injectableData?.documents"
                         [activeDocumentIndex]="injectableData?.activeIndex"
                         (documentSelected)="handleDocumentSelect($event)">
    </app-document-viewer>
  </div>
</dx-popup>
