import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/common';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent extends BaseTermDirective<models.IParkingTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  ParkingTermType: typeof models.ParkingTermType = models.ParkingTermType;

  private readonly _destroy$: Subject<void>;

  carParkingRequired = false;
  trailerParkingRequired = false;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    if (this.leaseTerm.parkingType === models.ParkingTermType.TenantRequested) {
      if (!this.carParkingRequired && !this.trailerParkingRequired) {
        this.addError('Car parking or trailer parking is required');
      }

      if (
        this.carParkingRequired &&
        !this.leaseTerm.unreservedParkingStallsCount &&
        !this.leaseTerm.reservedParkingStallsCount
      ) {
        this.addError('Car parking count is required');
      }

      if (
        this.trailerParkingRequired &&
        !this.leaseTerm.unreservedTrailerDropsCount &&
        !this.leaseTerm.reservedTrailerDropsCount
      ) {
        this.addError('Trailer parking count is required');
      }
    }

    if (this.errors.length > 0) {
      return;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project?.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'Please identify all car/trailer parking available for Tenant’s use.',
          value: models.ParkingTermType.PleaseIdentifyByTenant,
          disabled: false,
        },
        {
          name: 'Please identify all car/trailer Parking required for Tenant’s use.',
          value: models.ParkingTermType.PleaseIdentifyByLandlord,
          disabled: false,
        },
        {
          name: 'Tenant shall have a minimum amount of Parking spaces in the designated areas',
          value: models.ParkingTermType.TenantRequested,
          disabled: false,
        },
        {
          name: 'Tenant shall have its pro rata share of Parking, which shall be addressed in the Lease document.',
          value: models.ParkingTermType.ToBeAddressedInLease,
          disabled: false,
        },
        {
          name: 'Custom',
          value: models.ParkingTermType.Custom,
          disabled: false,
        },
      ];
    } else {
      this.options = [
        {
          name: 'Per current lease',
          value: models.ParkingTermType.PerCurrentLease,
          disabled: false,
        },
        {
          name: 'Please identify all car/trailer parking available for Tenant’s use.',
          value: models.ParkingTermType.PleaseIdentifyByTenant,
          disabled: false,
        },
        {
          name: 'Please identify all car/trailer Parking required for Tenant’s use.',
          value: models.ParkingTermType.PleaseIdentifyByLandlord,
          disabled: false,
        },
        {
          name: 'Tenant shall have a minimum amount of Parking spaces in the designated areas',
          value: models.ParkingTermType.TenantRequested,
          disabled: false,
        },
        {
          name: 'Tenant shall have its pro rata share of Parking, which shall be addressed in the Lease document.',
          value: models.ParkingTermType.ToBeAddressedInLease,
          disabled: false,
        },
        {
          name: 'Custom',
          value: models.ParkingTermType.Custom,
          disabled: false,
        },
      ];
    }

    const carParkingCount = (this.leaseTerm.reservedParkingStallsCount || 0) + (this.leaseTerm.unreservedParkingStallsCount || 0);
    const trailerParkingCount = (this.leaseTerm.reservedTrailerDropsCount || 0) + (this.leaseTerm.unreservedTrailerDropsCount || 0);
    this.carParkingRequired = carParkingCount > 0;
    this.trailerParkingRequired = trailerParkingCount > 0;

    if (this._authService.role !== Role.Landlord) {
      const pleaseIdentifyOptionIndex = this.options.findIndex(x => x.value === models.ParkingTermType.PleaseIdentifyByLandlord);
      if (pleaseIdentifyOptionIndex !== -1) {
        this.options.splice(pleaseIdentifyOptionIndex, 1);
      }
    }

    if (this._authService.role !== Role.Tenant) {
      const pleaseIdentifyOptionIndex = this.options.findIndex(x => x.value === models.ParkingTermType.PleaseIdentifyByTenant);
      if (pleaseIdentifyOptionIndex !== -1) {
        this.options.splice(pleaseIdentifyOptionIndex, 1);
      }
    }

    if (
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.SendRfp, this.project, this.lease) &&
      !this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord, this.project, this.lease)
    ) {
      const tenantOption = this.options.find(x => x.value === models.ParkingTermType.PleaseIdentifyByTenant);
      if (tenantOption) {
        tenantOption.disabled = true;
      }
      const landlordOption = this.options.find(x => x.value === models.ParkingTermType.PleaseIdentifyByLandlord);
      if (landlordOption) {
        landlordOption.disabled = true;
      }
    }
  }
}
