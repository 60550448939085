import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTooltipComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { CommonTools, SelectItem } from '@statera/sdk/common';
import { TermManager } from '@statera/sdk/term';

import { AuthService } from '../../../auth/services/auth.service';
import { LeaseService } from '../../services/lease.service';

import { SafeDatePipe } from '../../../infrastructure/pipes/safe-date.pipe';

import { SafeNumberPipe } from '../../../infrastructure/pipes/safe-number.pipe';
import * as models from '../../../infrastructure/models/generated';
import { ProjectQuery } from '../../states/project/project.query';
import {ILeaseAnalysisYearLandlordPerspective} from '../../../infrastructure/models/generated';

interface LeaseSelectItem {
  id: string;
  text: string;
  isExpired: boolean;
  leaseAnalysisProject: models.ILeaseAnalysisProjectViewModel;
}

interface LeaseSelectItemGroup {
  key: string;
  items: Array<LeaseSelectItem>;
}

enum LeaseAnalysisTab {
  Tenant,
  Landlord,
}

@Component({
  selector: 'app-lease-analysis',
  templateUrl: './lease-analysis.component.html',
  styleUrls: ['./lease-analysis.component.scss'],
})
export class LeaseAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild('yearTooltip') yearTooltip: DxTooltipComponent;
  BuildingType = models.BuildingType;
  Tools = CommonTools;
  LeaseYearType = models.LeaseAnalysisYearType;
  leaseYearTypes = CommonTools.EnumToArray(models.LeaseAnalysisYearType);
  _leaseYearTypesValue = models.LeaseAnalysisYearType.CalendarYear;
  set leaseYearTypesValue(val) {
    this._leaseYearTypesValue = val;
    this.tenantTotal = <models.ILeaseAnalysisYearTenantPerspective>{};
    this.tenantLeaseAnalysisData = <models.ILeaseAnalysisTenantPerspectiveResult>{};
    this.tenantYears = [];
    this.landlordTotal = <models.ILeaseAnalysisYearLandlordPerspective>{};
    this.landlordLeaseAnalysisData = <models.ILeaseAnalysisLandlordPerspectiveResult>{};
    this.landlordYears = [];
    this.calculate(this.selectedLeaseAfterLandlordStage, this._selectedOptionName);
  }

  get leaseYearTypesValue() {
    return this._leaseYearTypesValue;
  }

  AssetsTypes = CommonTools.SelectItemsToDictionary(CommonTools.EnumToArray(models.BuildingType));
  LeaseAnalysisTab: typeof LeaseAnalysisTab = LeaseAnalysisTab;

  leases: Array<models.ILeaseViewModel> = [];
  leaseSelectItems: Array<LeaseSelectItem> = [];
  leaseSelectGroups: Array<LeaseSelectItemGroup> = [];
  selectedLeaseAfterLandlordStage: models.ILeaseViewModel = null;
  selectedLease: models.ILeaseViewModel = null;
  assetType = '';
  buildingPictureUrl?: string = null;

  tenantLeaseAnalysisData: models.ILeaseAnalysisTenantPerspectiveResult;
  tenantYears: Array<models.ILeaseAnalysisYearTenantPerspective> = [];
  tenantTotal = <models.ILeaseAnalysisYearTenantPerspective>{};

  landlordLeaseAnalysisData: models.ILeaseAnalysisLandlordPerspectiveResult;
  landlordYears: Array<models.ILeaseAnalysisYearLandlordPerspective> = [];
  landlordTotal = <models.ILeaseAnalysisYearLandlordPerspective>{};

  calculating = false;
  selectedLeaseAnalysisProject: models.IProjectViewModel;
  selectedProject: models.IProjectViewModel;
  leaseAnalysisProjects: Array<models.IProjectViewModel>;
  projects: Array<models.IProjectViewModel>;
  numberFormat = '1.0-2';
  disabledCashComparisonButton = true;
  currentRenewalProjectTemplateItemType: models.RenewalProjectTemplateItemType;
  RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;
  tabs = [
    {
      text: 'Tenant',
      value: LeaseAnalysisTab.Tenant,
    },
    {
      text: 'Landlord',
      value: LeaseAnalysisTab.Landlord,
    },
  ];
  selectedTabIndex = 0;
  userRole: string;

  _selectedLeaseId: string;

  yearsSelectItems: Array<SelectItem> = [];
  yearFromId = 0;
  yearToId = 0;

  isNoProjectsAvailable: boolean;
  showBreakdownRows = false;

  private _selectedProjectTemplateItem: models.IProjectTemplateItemViewModel;
  private _selectedOptionName: string;

  private readonly _authService: AuthService;
  private readonly _leaseService: LeaseService;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _termManager: TermManager;
  private readonly _router: Router;
  private readonly _location: Location;
  private readonly _safeNumber: SafeNumberPipe;
  private readonly _safeDate: SafeDatePipe;
  private readonly _destroy$: Subject<void>;
  private readonly _projectQuery: ProjectQuery;

  constructor(
    authService: AuthService,
    leaseService: LeaseService,
    activatedRoute: ActivatedRoute,
    termManager: TermManager,
    router: Router,
    location: Location,
    safeNumber: SafeNumberPipe,
    safeDate: SafeDatePipe,
    projectQuery: ProjectQuery,
  ) {
    this._authService = authService;
    this._leaseService = leaseService;
    this._activatedRoute = activatedRoute;
    this._termManager = termManager;
    this._router = router;
    this._location = location;
    this._safeNumber = safeNumber;
    this._safeDate = safeDate;
    this._projectQuery = projectQuery;
    this._destroy$ = new Subject<void>();
  }

  set selectedLeaseId(val) {
    this._selectedLeaseId = val;
    if (this.leases && this.leases.length) {
      const [leaseIdStr, optionName] = val.split(':');
      const leaseId = parseInt(leaseIdStr, 10);

      this._selectedOptionName = optionName;

      if (leaseId) {
        this.tenantYears = [];
        this.tenantTotal = <models.ILeaseAnalysisYearTenantPerspective>{};
        this.landlordYears = [];
        this.landlordTotal = <models.ILeaseAnalysisYearLandlordPerspective>{};

        this.selectedLease = this.leases.find(x => x.id === leaseId);
        if (this.selectedLease.building) {
          this.assetType = this.AssetsTypes[this.selectedLease.building.buildingType];
        }
        this._updateBuildingPictureUrl();
        this.calculate(this.selectedLease, optionName);
        this.selectedLeaseAnalysisProject = this.leaseAnalysisProjects.find(x => x.leaseId === leaseId);
        this.selectedProject = this.projects.find(x => x.leaseId === leaseId);

        if (this.selectedLeaseAnalysisProject && this.selectedLeaseAnalysisProject.projectState) {
          this.currentRenewalProjectTemplateItemType = this.selectedLeaseAnalysisProject.projectState.renewalProjectTemplateItemType;
        }

        this._replaceLocationState(leaseId, optionName);
      } else {
        this.selectedLeaseAfterLandlordStage = null;
        this.selectedLease = null;
        this.buildingPictureUrl = null;
        this.assetType = '';
        this.selectedLeaseAnalysisProject = null;
        this.selectedProject = null;
      }
    }
  }

  get selectedLeaseId() {
    return this._selectedLeaseId;
  }

  ngOnInit(): void {
    this._projectQuery.selectAll().subscribe(projects => {
      this.projects = projects;
    });

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          this.userRole = info.role;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._leaseService
      .getLeaseAnalysisProjects()
      .pipe(
        takeUntil(this._destroy$),
        map(leaseAnalysisProjects => {
          if (!leaseAnalysisProjects || !leaseAnalysisProjects.length) {
            this.isNoProjectsAvailable = true;
            return;
          }

          this.leaseAnalysisProjects = leaseAnalysisProjects.map(x => x.project);
          this.leases = leaseAnalysisProjects.map(x => x.lease);

          const now = moment().startOf('day');

          this.leaseSelectItems = leaseAnalysisProjects
            .map(leaseAnalysisProject => {
              const expirationDate = moment(leaseAnalysisProject.lease.calculatedExpirationDate).startOf('day');

              return <LeaseSelectItem>{
                id: [leaseAnalysisProject.lease.id, leaseAnalysisProject.optionName].filter(Boolean).join(':'),
                text: this.getLeaseAnalysisProjectItemName(leaseAnalysisProject),
                isExpired: leaseAnalysisProject.lease.isAbstractLease && now.isAfter(expirationDate),
                leaseAnalysisProject: leaseAnalysisProject,
              };
            });

          this.leaseSelectGroups = this.leaseSelectItems
            .reduce(
              (acc: Array<LeaseSelectItemGroup>, selectItem) => {
                const groupKey = this._getLeaseAnalysisProjectGroupName(selectItem.leaseAnalysisProject);

                let indexOfGroupItem = acc.findIndex(x => x.key === groupKey);
                if (indexOfGroupItem < 0) {
                  indexOfGroupItem = acc.length;

                  acc.push({key: groupKey, items: []});
                }

                acc[indexOfGroupItem].items.push(selectItem);

                return acc;
              },
              [],
            )
            .sort((a, b) => parseInt(b.items[0].id, 10) - parseInt(a.items[0].id, 10));


          return this.leaseSelectItems;
        }),
        switchMap(leaseSelectItems => this._activatedRoute.queryParams
          .pipe(
            takeUntil(this._destroy$),
            tap(params => {
              const leaseId = params['leaseId'];
              const option = params['option'];

              if (!leaseId && leaseSelectItems && leaseSelectItems.length) {
                this.selectedLeaseId = leaseSelectItems[0].id;
                return;
              }

              if (!option) {
                const options = leaseSelectItems.filter(x => {
                  const [id] = x.id.split(':');
                  return id === leaseId;
                });

                if (options.length) {
                  this.selectedLeaseId = options[0].id;
                  return;
                }

                return; // no leases
              }

              this.selectedLeaseId = [leaseId, option].filter(Boolean).join(':');
            }),
          ),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getLeaseAnalysisProjectGroupName(leaseAnalysisProject: models.ILeaseAnalysisProjectViewModel): string {
    if (!leaseAnalysisProject || !leaseAnalysisProject.lease) {
      return;
    }

    const { lease } = leaseAnalysisProject;

    let buildingAddress = `Building #${lease.buildingId}`;
    if (lease.building && lease.building.address) {
      buildingAddress = lease.building.address.displayString;
    }

    let buildingUnitName: string;
    if (lease.buildingUnitId) {
      buildingUnitName = `Unit #${lease.buildingUnitId}`;
      if (lease.buildingUnit) {
        buildingUnitName = lease.buildingUnit.name;
      }
    }

    return [buildingAddress, buildingUnitName]
      .filter(Boolean)
      .join(', ');
  }

  getLeaseAnalysisProjectItemName(leaseAnalysisProject: models.ILeaseAnalysisProjectViewModel): string {
    if (!leaseAnalysisProject || !leaseAnalysisProject.lease) {
      return;
    }

    let amendmentNumber = 'Original';
    if (0 < leaseAnalysisProject.lease.amendmentNumber) {
      amendmentNumber = `Amendment ${leaseAnalysisProject.lease.amendmentNumber}`;
    }

    let optionName: string;
    if (leaseAnalysisProject.optionName) {
      optionName = `Option ${leaseAnalysisProject.optionName}`;
    }

    return [amendmentNumber, optionName]
      .filter(Boolean)
      .join(' – ');
  }

  getLeaseAnalysisProjectSelectedName(leaseSelectItem: LeaseSelectItem): string {
    if (!leaseSelectItem || !leaseSelectItem.leaseAnalysisProject) {
      return;
    }

    const groupName = this._getLeaseAnalysisProjectGroupName(leaseSelectItem.leaseAnalysisProject);
    const itemName = this.getLeaseAnalysisProjectItemName(leaseSelectItem.leaseAnalysisProject);

    return [groupName, itemName]
      .filter(Boolean)
      .join(': ');
  }

  calculate(lease: models.ILeaseViewModel, optionName: string = null): void {
    if (!this.calculating) {
      this.calculating = true;

      const leaseAnalysisData = <models.ILeaseAnalysisOptionsViewModel>{
        lease: {...lease},
        leaseYearType: this.leaseYearTypesValue,
        optionName: optionName,
      };

      const source = this.isTenantTab()
        ? this._leaseService
          .getTenantLeaseAnalysisData(leaseAnalysisData)
          .subscribe((x) => {
            this.tenantLeaseAnalysisData = x;
            this.tenantYears = x.years;
            this.selectedLeaseAfterLandlordStage = x.lease;
            this._selectedProjectTemplateItem = x.projectTemplateItem;
            this.processAnalysisData();
          }, (error) => this.calculating = false)
        : this._leaseService
          .getLandlordLeaseAnalysisData(leaseAnalysisData)
          .subscribe((x) => {
            this.landlordLeaseAnalysisData = x;
            this.landlordYears = <Array<ILeaseAnalysisYearLandlordPerspective>>x.years;
            this.selectedLeaseAfterLandlordStage = x.lease;
            this._selectedProjectTemplateItem = x.projectTemplateItem;
            this.processAnalysisData();
          }, (error) => this.calculating = false);
    }
  }

  processAnalysisData() {
    if (this._selectedProjectTemplateItem) {
      this.disabledCashComparisonButton = true;

      const {ReviewTenantImprovementsSelectMultiplyOptionsByLandlord} = models.RenewalProjectTemplateItemType;
      if (this._selectedProjectTemplateItem.id === ReviewTenantImprovementsSelectMultiplyOptionsByLandlord) {
        this.disabledCashComparisonButton = false;
      } else {
        let rootStage: models.IProjectTemplateItemViewModel;
        if (this._selectedProjectTemplateItem.parentProjectTemplateItem) {
          rootStage = this._selectedProjectTemplateItem.parentProjectTemplateItem;
        } else {
          rootStage = this._selectedProjectTemplateItem;
        }

        if (rootStage) {
          this.disabledCashComparisonButton = (
            rootStage.renewalProjectTemplateItemType < models.RenewalProjectTemplateItemType.ReceiveAndAnalyzeResponse
          );
        }
      }
    }

    this.calcTotal();
    this.calcYearsSelectItems();
    this.calculating = false;
  }

  sum(arr): number {
    return arr.reduce((a, b) => <number>a + <number>b, 0);
  }

  calcTotal() {
    const cumulativeCashFlow = this.isTenantTab()
      ? this.tenantYears[this.tenantYears.length - 1] ? this.tenantYears[this.tenantYears.length - 1].cumulativeCashFlow : 0
      : this.landlordYears[this.landlordYears.length - 1] ? this.landlordYears[this.landlordYears.length - 1].cumulativeCashFlow : 0;
    const commonAreaMaintenanceBreakdown = <models.ICommonAreaMaintenanceBreakdown>{
      insurance: 0,
      realEstateTaxes: 0,
      repairsAndMaintenance: 0,
      fireAndLifeSafety: 0,
      parkingLotMaintenance: 0,
      roofRepairs: 0,
      landscaping: 0,
      snowRemoval: 0,
      managementFee: 0,
    };
    for (const year of this.tenantYears) {
      if (year.commonAreaMaintenanceBreakdown) {
        commonAreaMaintenanceBreakdown.insurance += year.commonAreaMaintenanceBreakdown.insurance;
        commonAreaMaintenanceBreakdown.realEstateTaxes += year.commonAreaMaintenanceBreakdown.realEstateTaxes;
        commonAreaMaintenanceBreakdown.repairsAndMaintenance += year.commonAreaMaintenanceBreakdown.repairsAndMaintenance;
        commonAreaMaintenanceBreakdown.fireAndLifeSafety += year.commonAreaMaintenanceBreakdown.fireAndLifeSafety;
        commonAreaMaintenanceBreakdown.parkingLotMaintenance += year.commonAreaMaintenanceBreakdown.parkingLotMaintenance;
        commonAreaMaintenanceBreakdown.roofRepairs += year.commonAreaMaintenanceBreakdown.roofRepairs;
        commonAreaMaintenanceBreakdown.landscaping += year.commonAreaMaintenanceBreakdown.landscaping;
        commonAreaMaintenanceBreakdown.snowRemoval += year.commonAreaMaintenanceBreakdown.snowRemoval;
        commonAreaMaintenanceBreakdown.managementFee += year.commonAreaMaintenanceBreakdown.managementFee;
      }
    }

    if (this.isTenantTab()) {
      this.tenantTotal = <models.ILeaseAnalysisYearTenantPerspective>{
        realEstateTaxes: this.sum(this.tenantYears.map(x => x.realEstateTaxes)),
        tenantImprovementsAmortization: this.sum(this.tenantYears.map(x => x.tenantImprovementsAmortization)),
        totalBaseRent: this.sum(this.tenantYears.map(x => x.totalBaseRent)),
        totalRecoveries: this.sum(this.tenantYears.map(x => x.totalRecoveries)),
        totalRent: this.sum(this.tenantYears.map(x => x.totalRent)),
        baseRent: this.sum(this.tenantYears.map(x => x.baseRent)),
        cashFlow: this.sum(this.tenantYears.map(x => x.cashFlow)),
        cumulativeCashFlow: cumulativeCashFlow,
        commonAreaMaintenance: this.sum(this.tenantYears.map(x => x.commonAreaMaintenance)),
        commonAreaMaintenanceBreakdown: commonAreaMaintenanceBreakdown,
        escalations: this.sum(this.tenantYears.map(x => x.escalations)),
        freeRent: this.sum(this.tenantYears.map(x => x.freeRent)),
        totalCost: this.sum(this.tenantYears.map(x => x.totalCost)),
      };
    } else {
      this.landlordTotal = <models.ILeaseAnalysisYearLandlordPerspective>{
        realEstateTaxes: this.sum(this.landlordYears.map(x => x.realEstateTaxes)),
        tenantImprovements: this.sum(this.landlordYears.map(x => x.tenantImprovements)),
        tenantImprovementsAmortization: this.sum(this.landlordYears.map(x => x.tenantImprovementsAmortization)),
        tenantImprovementsLoanFunding: this.sum(this.landlordYears.map(x => x.tenantImprovementsLoanFunding)),
        totalBaseRent: this.sum(this.landlordYears.map(x => x.totalBaseRent)),
        totalOperatingExpenses: this.sum(this.landlordYears.map(x => x.totalOperatingExpenses)),
        totalRecoveries: this.sum(this.landlordYears.map(x => x.totalRecoveries)),
        netOperatingIncome: this.sum(this.landlordYears.map(x => x.netOperatingIncome)),
        totalRent: this.sum(this.landlordYears.map(x => x.totalRent)),
        baseRent: this.sum(this.landlordYears.map(x => x.baseRent)),
        cashFlow: this.sum(this.landlordYears.map(x => x.cashFlow)),
        cumulativeCashFlow: cumulativeCashFlow,
        commonAreaMaintenance: this.sum(this.landlordYears.map(x => x.commonAreaMaintenance)),
        commonAreaMaintenanceBreakdown: commonAreaMaintenanceBreakdown,
        escalations: this.sum(this.landlordYears.map(x => x.escalations)),
        freeRent: this.sum(this.landlordYears.map(x => x.freeRent)),
        totalCost: this.sum(this.landlordYears.map(x => x.totalCost)),
        leaseCommissions: this.sum(this.landlordYears.map(x => x.leaseCommissions)),
      };
    }
  }

  calcYearsSelectItems(): void {
    this.yearsSelectItems = this.tenantYears.map((x, i) => {
      return <SelectItem>{
        id: i,
        text: this.leaseYearTypesValue === models.LeaseAnalysisYearType.LeaseYear
          ? `Year ${(i + 1)}`
          : this._safeDate.transform(x.startDate.toString(), 'yyyy'),

      };
    });
    this.yearFromId = 0;
    this.yearToId = this.yearsSelectItems.length - 1;
  }

  getLeaseEndDate(): string {
    if (!this.selectedLeaseAfterLandlordStage) {
      return null;
    }

    const { calculatedExpirationDate, commencementTerm, term } = this.selectedLeaseAfterLandlordStage;

    if (calculatedExpirationDate) {
      return calculatedExpirationDate.toString();
    }

    if (commencementTerm && commencementTerm.commencement && term && term.termValue) {
      const commencementDate = moment(this.selectedLeaseAfterLandlordStage.commencementTerm.commencement).startOf('day');
      return commencementDate.add(term.termValue, 'months').add(-1, 'day').toString();
    }

    return null;
  }

  getStartingRate(): string {
    return this._leaseService.getStartingRate(this.selectedLeaseAfterLandlordStage);
  }

  getRentIncreases(): string {
    return this._leaseService.getRentIncreases(this.selectedLeaseAfterLandlordStage);
  }

  getFreeRent(): string {
    return this._leaseService.getFreeRent(this.selectedLeaseAfterLandlordStage);
  }

  getServiceType(): string {
    return this._leaseService.getServiceType(this.selectedLeaseAfterLandlordStage);
  }

  getOperationExp(): string {
    return this._leaseService.getOperationExp(this.selectedLeaseAfterLandlordStage);
  }

  getImprovements(): string {
    return this._leaseService.getImprovements(this.selectedLeaseAfterLandlordStage);
  }

  getStageName(): string {
    if (!this._selectedProjectTemplateItem) {
      return;
    }

    let templateItemName = this._selectedProjectTemplateItem.name;
    let templateItemId = this._selectedProjectTemplateItem.stageNumber;

    if (this._selectedProjectTemplateItem.parentProjectTemplateItem) {
      templateItemName = this._selectedProjectTemplateItem.parentProjectTemplateItem.name;
      templateItemId = this._selectedProjectTemplateItem.parentProjectTemplateItem.stageNumber;
    }

    const [, optionName] = this.selectedLeaseId.split(':');
    if (optionName) {
      return `${templateItemName} (Stage ${templateItemId} – Option ${optionName})`;
    }

    return `${templateItemName} (Stage ${templateItemId})`;
  }

  private _replaceLocationState(leaseId: number = null, option: string = null): void {
    const queryParams = {
      leaseId: leaseId,
      option: option,
    };

    if (!leaseId) {
      delete queryParams.leaseId;
    }

    if (!option) {
      delete queryParams.option;
    }

    this._router.navigate(this._location.path().split('?').slice(0, 1), {queryParams});
  }

  goToCashFlowComparisonReport(): void {
    const [leaseId] = this.selectedLeaseId.split(':');
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'leases',
        leaseId,
        'comparison-report'
      ]);

    const url = this._router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  getFormattedValue(value: number): string | number {
    if (value) {
      const roundingValue = Math.round(value);
      return this._safeNumber.transform(roundingValue, '1.0-0');
    }

    return 0;
  }

  getRentalSquareFootage(): string {
    if (!this.selectedLeaseAfterLandlordStage) {
      return '0';
    }

    const { tenantSquareFootageTerm } = this.selectedLeaseAfterLandlordStage;
    if (!tenantSquareFootageTerm) {
      return '0';
    }

    let isPhaseInOption = false;

    const getTenantSquareFootage = (term: models.ITenantSquareFootageTermViewModel): number => {
      isPhaseInOption = false;

      let sf = 0;

      switch (term.tenantSquareFootageTermType) {
        case models.TenantSquareFootageTermType.ExistingSquareFootage:
          sf = getTenantSquareFootage(term.abstract);
          break;

        case models.TenantSquareFootageTermType.PhaseIn:
          const phaseInResults = term.tenantSquareFootagePhaseInResults;
          for (let i = 0, num = phaseInResults.length; i < num; i++) {
            const phaseInResult = phaseInResults[i];
            if (sf < phaseInResult.totalSf) {
              sf = phaseInResult.totalSf;
            }
          }

          isPhaseInOption = true;

          break;

        default:
          sf = term.tenantSquareFootageValue;
          break;
      }

      return sf;
    };

    const tenantSquareFootage = getTenantSquareFootage(tenantSquareFootageTerm);

    if (isPhaseInOption) {
      return `${this._safeNumber.transform(tenantSquareFootage)} (Phase-in Option)`;
    }

    return this._safeNumber.transform(tenantSquareFootage);
  }

  showBreakdownRowTenant(years: Array<models.ILeaseAnalysisYearTenantPerspective>): boolean {
    return !!years.find(x => x.commonAreaMaintenanceBreakdown);
  }

  showBreakdownRowLandlord(years: Array<models.ILeaseAnalysisYearLandlordPerspective>): boolean {
    return !!years.find(x => x.commonAreaMaintenanceBreakdown);
  }

  getUnitMetricsDisplayValue(): string {
    let unitMetrics = 'PSF/Yr';
    if (!this.tenantLeaseAnalysisData) {
      return unitMetrics;
    }

    if (this.tenantLeaseAnalysisData.unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = 'PSF/Mo';
    }

    return unitMetrics;
  }

  selectedTabChanged() {
    if (this.selectedLease) {
      this.calculate(this.selectedLease, this._selectedOptionName);
    }
  }

  exportToExcelClick() {
    window.open(this._getExportExcelPath(), '_self');
  }

  exportToPdfClick() {
    window.open(this._getExportPdfPath(), '_self');
  }

  isShowLeaseCommissionsRow(): boolean {
    if (this.isTenantTab()) {
      return false;
    }
    return !this.landlordYears.every(x => x.leaseCommissions === 0);
  }

  isShowImprovementAmortizationRow(): boolean {
    if (this.isTenantTab()) {
      return false;
    }
    return !this.landlordYears.every(x => x.tenantImprovementsAmortization === 0);
  }

  isShowImprovementsLoanFundingRow(): boolean {
    if (this.isTenantTab()) {
      return false;
    }
    return !this.landlordYears.every(x => x.tenantImprovementsLoanFunding === 0);
  }

  isTenantTab(): boolean {
    return this.tabs[this.selectedTabIndex].value === LeaseAnalysisTab.Tenant;
  }

  isLandlordTab(): boolean {
    return this.tabs[this.selectedTabIndex].value === LeaseAnalysisTab.Landlord;
  }

  private _updateBuildingPictureUrl(): void {
    this.buildingPictureUrl = this.selectedLease
      .building
      .attachments
      ?.filter((x) => x.buildingAttachmentType === models.BuildingAttachmentType.Picture)
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      [0]
      .file
      .url;
  }

  private _getExportExcelPath(): string {
    if (this.isTenantTab()) {
      return `/api/lease-analysis/tenant-report/export?leaseId=${this.selectedLeaseId}&format=Excel&yearType=${this.leaseYearTypesValue}`;
    }
    return `/api/lease-analysis/landlord-report/export?leaseId=${this.selectedLeaseId}&format=Excel&yearType=${this.leaseYearTypesValue}`;
  }

  private _getExportPdfPath(): string {
    if (this.isTenantTab()) {
      return `/api/lease-analysis/tenant-report/export?leaseId=${this.selectedLeaseId}&format=Pdf&yearType=${this.leaseYearTypesValue}`;
    }
    return `/api/lease-analysis/landlord-report/export?leaseId=${this.selectedLeaseId}&format=Pdf&yearType=${this.leaseYearTypesValue}`;
  }
}
