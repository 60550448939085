import { Observable } from 'rxjs';

export enum ImageViewerImageKind {
  Standard = 0,
  Panorama = 1,
}

export class ImageViewerImageCache {
  [index: number]: Observable<ImageViewerImageRef>;
}

export class ImageViewerImageRef {
  id: number;
  index: number;
  imageKind: ImageViewerImageKind;
  imageElement: HTMLImageElement;
  width: number;
  height: number;
  imageDataUrl: string;
  markers: Array<ImageViewerImageMarkerRef>;
}

export class ImageViewerImage {
  id: number;
  imageUrl: string;
  imageKind?: ImageViewerImageKind;
  markers?: Array<ImageViewerImageMarkerRef>;
}

export class ImageViewerImageMarkerRef {
  id: number;
  x: number;
  y: number;
  text: string;
  imageRef?: ImageViewerImageRef;

  constructor(imageRef: ImageViewerImageRef, x: number, y: number) {
    this.imageRef = imageRef;
    this.x = x;
    this.y = y;
  }
}
