import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogRefService} from '../../../../dialog/services/dialog-ref.service';
import {Subject} from 'rxjs';
import {BuildingBudgetManager} from '@statera/sdk/building-budget';
import {NgForm} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import * as models from '../../../../infrastructure/models/generated';
import {toLocalDate} from '../../../../infrastructure/models/local-date.model';

@Component({
  templateUrl: './building-unit-budget-dialog.component.html',
  styleUrls: ['./building-unit-budget-dialog.component.scss']
})
export class BuildingUnitBudgetDialogComponent implements OnInit, OnDestroy {
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy: Subject<void>;
  private readonly _buildingBudgetManager: BuildingBudgetManager;
  building: models.IBuildingViewModel;
  buildingUnits: Array<models.IBuildingUnitViewModel>;
  currentBudgetVersion: models.IBuildingBudgetVersionViewModel;
  buildingBudgetVersions: Array<models.IBuildingBudgetVersionViewModel>;

  buildingUnitBudgetsToSave: Array<models.IBuildingBudgetViewModel> = [];
  selectTextOnEditStart = true;
  constructor(dialogRefService: DialogRefService,
              buildingBudgetManager: BuildingBudgetManager) {
    this._dialogRefService = dialogRefService;
    this._buildingBudgetManager = buildingBudgetManager;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.init(this.currentBudgetVersion);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  getAverageMarketRateGrowth(buildingUnitBudget: models.IBuildingBudgetViewModel): number {
    return this._buildingBudgetManager.getAverageMarketRateGrowth(buildingUnitBudget);
  }

  submit(form: NgForm): void {
    if (!form.valid) {
      return;
    }
    this._buildingBudgetManager
      .upsertBuildingBudgetsBulk(this.buildingUnitBudgetsToSave)
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.close();
      });
  }

  getBuildingUnitName(buildingUnitId: number): string {
    return this.buildingUnits.find(bu => bu.id === buildingUnitId)?.name;
  }

  selectBuildingBudget(buildingBudgetVersion: any): void {
    this.init(buildingBudgetVersion?.selectedItem);
  }

  init(budgetVersion: models.IBuildingBudgetVersionViewModel): void {
    this.currentBudgetVersion = budgetVersion;
    this.buildingUnitBudgetsToSave = budgetVersion?.buildingUnitBudgets ?? [];
    this.buildingUnits.forEach(bu => {
      const buildingUnitBudget = budgetVersion?.buildingUnitBudgets.find(b => b.buildingUnitId === bu.id);
      if (!buildingUnitBudget) {
        this.buildingUnitBudgetsToSave
          .push({ ...budgetVersion.buildingBudget, buildingUnitId: bu.id, id: 0});
      }
    });
  }

  buildingBudgetVersionExpression(buildingBudgetVersion: models.IBuildingBudgetVersionViewModel): string {
    if (!buildingBudgetVersion) {
      return '';
    }
    return toLocalDate(buildingBudgetVersion.versionAsDate);
  }

  handleBudgetVersionChange(version: models.IBuildingBudgetVersionViewModel) {
    this.init(version);
  }

  renderDate(item: models.IBuildingBudgetVersionViewModel) {
    if (item) {
      const date = new Date(item.versionAsDate);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${day}/${month}/${date.getFullYear().toString().substring(2)}`;
    }
  }
}
