<ng-container *ngIf="baseRentalRate && baseRentalRate.schedule && baseRentalRate.schedule.rows; else elseBlock">
  <table>
    <tr>
      <th>Start</th>
      <th>End</th>
      <th>Base Rent <sup>PSF/YR</sup></th>
      <th>Monthly Rent</th>
    </tr>

    <tr *ngFor="let row of baseRentalRate.schedule.rows">
      <td>{{row.start | date:'MM/dd/yyyy'}}</td>
      <td>{{row.end | date:'MM/dd/yyyy'}}</td>
      <td>{{row.baseRent | currency}}</td>
      <td>
        <ng-container *ngIf="row.monthlyRent">{{row.monthlyRent | currency}}</ng-container>
        <ng-container *ngIf="!row.monthlyRent">-</ng-container>
      </td>
    </tr>
  </table>
</ng-container>

<ng-template #elseBlock>
  <div class="comment">
    No Base Rental Schedule available
  </div>
</ng-template>
