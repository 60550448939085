import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StepsWithWizard } from '../../shared/constants/shared.constants';

import { ProjectService } from '../../shared/services/project.service';

import * as models from '../../infrastructure/models/generated';

import { QuizService } from '../../shared/services/quiz.service';

@Injectable()
export class NegotiateGuard implements CanActivate {
  private readonly _quizService: QuizService;
  private readonly _projectService: ProjectService;
  private readonly _router: Router;

  constructor(quizService: QuizService, projectService: ProjectService, router: Router) {
    this._quizService = quizService;
    this._projectService = projectService;
    this._router = router;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const leaseId = parseInt(route.paramMap.get('id') || '0', 10);
    if (!leaseId) {
      return of(true);
    }

    return this._projectService
      .getProjectsByLeaseId(leaseId)
      .pipe(
        switchMap((projects: Array<models.IProjectViewModel>): Observable<boolean | UrlTree> => {
          const project = this._projectService.getActiveProject(projects);

          if (!project || !project.projectState) {
            return of(true);
          }

          if (StepsWithWizard.some(x => x === project.projectState.renewalProjectTemplateItemType)) {
            const urlTree = this._router
              .createUrlTree(
                [
                  'v2',
                  'wizard',
                  leaseId
                ],
                {},
              );

            const url = this._router.serializeUrl(urlTree);

            window.location.href = url;

            return of(false);
          }

          return of(true);
        }),
      );
  }
}
