import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-zip-code',
  templateUrl: 'analytics-filters-location-zip-code.component.html',
  styleUrls: ['analytics-filters-location-zip-code.component.scss'],
})
export class AnalyticsFiltersLocationZipCodeComponent {
  @Input() locationZipCodes: Array<analyticsFiltersModels.LocationZipCodeFilter>;
  @Output() locationZipCodesChange: EventEmitter<Array<analyticsFiltersModels.LocationZipCodeFilter>>;

  constructor() {
    this.locationZipCodesChange = new EventEmitter<Array<analyticsFiltersModels.LocationZipCodeFilter>>();
  }

  handleZipCodeSelection(): void {
    this.locationZipCodesChange.next([...this.locationZipCodes]);
  }
}
