import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CommonTools } from '@statera/sdk/common';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { UploadableImage } from '../../../infrastructure/directives/image-uploader.directive';

import * as models from '../../../infrastructure/models/generated';

import { PlanViewerMarkerImageRef, PlanViewerMarkerRef, PlanViewerMode } from '../../models/plan-viewer.model';

@Component({
  templateUrl: 'plan-viewer-marker-dialog.component.html',
  styleUrls: ['plan-viewer-marker-dialog.component.scss'],
})
export class PlanViewerMarkerDialogComponent implements OnInit {
  private static readonly _newTitleOption = 'New Title';

  @ViewChild('form', { read: NgForm }) form: NgForm;

  mode: PlanViewerMode;
  marker: PlanViewerMarkerRef;

  suggestions: Array<{ id?: number, name: string }>;
  selectedSuggestion: { id?: number, name: string };

  images: Array<UploadableImage>;

  areas: Array<{ id: number, text: string }>;

  modes: typeof PlanViewerMode;

  isCustomTitleControlVisible: boolean;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit(): void {
    this.marker = this.marker || new PlanViewerMarkerRef();
    this.images = new Array<UploadableImage>();
    this.areas = CommonTools.EnumToArray(models.BuildingAreaType);
    this.modes = PlanViewerMode;
    this.suggestions = this.suggestions || [];

    this.isCustomTitleControlVisible = false;
    switch (this.mode) {
      case PlanViewerMode.Marker:
        this.isCustomTitleControlVisible = 0 === this.suggestions.length;

        if (this.suggestions.length) {
          this.suggestions.push({ name: PlanViewerMarkerDialogComponent._newTitleOption });
        }

        this.selectedSuggestion = this.suggestions
          .find(x => x.name === this.marker.title);

        break;
      case PlanViewerMode.Unit:
        this.selectedSuggestion = this.suggestions
          .find(x => x.id === this.marker.buildingUnitId);

        break;
    }
  }

  suggestionsDisplayExpr(item?: { id?: number, name: string }): string {
    return item && item.name;
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    const marker = {
      ...this.marker,
      buildingUnitId: undefined,
      images: this.images.map(x => {
        return <PlanViewerMarkerImageRef>{
          file: x.file,
        };
      }),
    };

    if (this.mode === PlanViewerMode.Unit) {
      marker.buildingUnitId = this.selectedSuggestion.id;
    }

    this._dialogRefService.outputData = {
      marker: marker,
    };

    this.close();
  }

  close(): void {
    if (!this._dialogRefService) {
      return;
    }

    this._dialogRefService.hide();
  }

  handleTitleSelectBoxChange(suggestion: { id?: number, name: string }): void {
    if (PlanViewerMarkerDialogComponent._newTitleOption !== suggestion.name) {
      this.marker.title = suggestion.name;
      return;
    }

    this.isCustomTitleControlVisible = true;
  }
}
