import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { DxNumberBoxComponent } from 'devextreme-angular';

export interface ValidationRule {
  type: string;
  message: string;
  validationCallback: (options: {value: any}) => boolean | Promise<any>;
}

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @ViewChild('numberBox') numberBox: DxNumberBoxComponent;

  @Input() id = '';
  @Input() placeholder = '';
  @Input() className = '';
  @Input() name = '';
  @Input() format = '';
  @Input() width = '';
  @Input() height = '';
  @Input() stylingMode = '';
  @Input() errorMessage = '';
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() errorType = 'required';
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() disabled: boolean;
  @Input() errorCondition = true;
  @Input() validationCallback: any;
  @Input() value: number;
  @Input() rounded: boolean;
  @Input() validationRules: Array<ValidationRule>;

  @Output() valueChange = new EventEmitter();
  @Output() focusIn = new EventEmitter();
  @Output() focusOut = new EventEmitter();

  handleValueChange(value: number): void {
    this.value = value;
    this.valueChange.next(value);
  }
}
