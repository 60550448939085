import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CompanyFeatureToggles } from './feature-toggling.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureTogglingStore {
  readonly companyFeatureToggles: BehaviorSubject<CompanyFeatureToggles>;

  constructor() {
    this.companyFeatureToggles = new BehaviorSubject<CompanyFeatureToggles>(null);
  }
}
