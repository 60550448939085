import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './team.model';

@Injectable()
export class TeamRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  sendInvitationRequest(invitationRequest: models.InvitationRequest): Observable<void> {
    return this._httpClient.post<void>(`${this._configuration.apiEndpoint}/invitation/send-user-invitation-request`, invitationRequest);
  }

  getCompanies(companyType: string): Observable<Array<models.Company>> {
    let params = new HttpParams();
    params = params.set('companyType', companyType);

    return this._httpClient.get<Array<models.Company>>(`${this._configuration.apiEndpoint}/companies`, {params});
  }

  getLeaseTeamMembers(leaseId: number): Observable<Array<models.TeamMember>> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .get<Array<models.TeamMember>>(
        `${this._configuration.apiEndpoint}/team/lease/members`,
        {params},
      );
  }

  deleteLeaseTeamMember(leaseId: number, userInvitationRequestId: number): Observable<void> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    if (userInvitationRequestId) {
      params = params.set('userInvitationRequestId', userInvitationRequestId.toString(10));
    }

    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/team/lease/members`,
        {params},
      );
  }

  getPortfolioTeamMembers(portfolioId: number): Observable<Array<models.TeamMember>> {
    let params = new HttpParams();

    if (portfolioId) {
      params = params.set('portfolioId', portfolioId.toString(10));
    }

    return this._httpClient
      .get<Array<models.TeamMember>>(
        `${this._configuration.apiEndpoint}/team/portfolio/members`,
        {params},
      );
  }

  deletePortfolioTeamMember(portfolioId: number, userInvitationRequestId: number): Observable<void> {
    let params = new HttpParams();

    if (portfolioId) {
      params = params.set('portfolioId', portfolioId.toString(10));
    }

    if (userInvitationRequestId) {
      params = params.set('userInvitationRequestId', userInvitationRequestId.toString(10));
    }

    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/team/portfolio/members`,
        {params},
      );
  }

  getBuildingTeamMembers(buildingId: number): Observable<Array<models.TeamMember>> {
    let params = new HttpParams();

    if (buildingId) {
      params = params.set('buildingId', buildingId.toString(10));
    }

    return this._httpClient
      .get<Array<models.TeamMember>>(
        `${this._configuration.apiEndpoint}/team/building/members`,
        {params},
      );
  }

  deleteBuildingTeamMember(buildingId: number, userInvitationRequestId: number): Observable<void> {
    let params = new HttpParams();

    if (buildingId) {
      params = params.set('buildingId', buildingId.toString(10));
    }

    if (userInvitationRequestId) {
      params = params.set('userInvitationRequestId', userInvitationRequestId.toString(10));
    }

    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/team/building/members`,
        {params},
      );
  }
}
