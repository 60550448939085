import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '../../../infrastructure/services/loader.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @Input()
  url: string;
  @Input()
  width: number;
  @Input()
  height: number;
  @Output()
  signed = new EventEmitter();
  @Output()
  error = new EventEmitter();

  private readonly _loaderService: LoaderService;

  constructor(loaderService: LoaderService) {
    this._loaderService = loaderService;
  }

  ngOnInit() {
    this._initializeSignature();
  }

  _initializeSignature() {
    this._loaderService.show();

    (<any>window).eversign.open({
      url: this.url,
      containerID: 'eversignContainerId',
      width: this.width,
      height: this.height,
      events: {
        loaded: () => {
          this._loaderService.hide();
        },
        signed: () => {
          this.signed.emit();
        },
        error: (e) => {
          this._loaderService.hide();
          this.error.emit();
        },
      },
    });
  }

}
