import * as models from '../../infrastructure/models/generated';

export class BuildingHelper {
  private static _noImageUrl = 'assets/img/nofoto.png';

  static buildingPictureThumbnail(building: models.IBuilding | models.IBuildingViewModel): string {
    if (!building || !building.attachments) {
      return this._noImageUrl;
    }
    const pictureAttachments = (<Array<models.IBuildingAttachmentViewModel | models.IBuildingAttachment>>
      building.attachments)
      .filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture) ?? [];
    const [pictureAttachment] = pictureAttachments;
    if (!pictureAttachment?.file?.thumbnailUrl) {
      return this._noImageUrl;
    }

    return pictureAttachment.file.thumbnailUrl;
  }

  static buildingPicture(building: models.IBuilding | models.IBuildingViewModel): string {
    if (!building || !building.attachments) {
      return this._noImageUrl;
    }
    const pictureAttachments =
      (<Array<models.IBuildingAttachmentViewModel | models.IBuildingAttachment>>building?.attachments)?.filter(
        x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture) ?? [];
    const [pictureAttachment] = pictureAttachments;
    if (!pictureAttachment?.file?.url) {
      return this._noImageUrl;
    }

    return pictureAttachment.file.url;
  }
}
