<ng-container *ngIf="isVisibleTermForm()">
  <div class="row"
       *ngIf="!isOption">
    <h6>Please choose from the following:</h6>
  </div>
  <form name="form" novalidate
        (ngSubmit)="submit()"
        #form="ngForm">
    <dx-validation-group>
      <!-- Start: Options -->
      <div class="row term-container"
           *ngIf="isOption || !isMultipleOptionsVisible()">
        <div class="col-sm-12 radio-group-container mb-1">
          <div class="dx-field-value">
            <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                            [dataSource]="options" [(value)]="leaseTerm.tenantSquareFootageTermType"
                            (valueChange)="clearErrors();">
              <div *dxTemplate="let data of 'radio'">
                <div class="d-flex align-items-center">
                  <span>{{data.name}}</span>
                  <ng-container *ngIf="data.value === tenantSquareFootageTermType.PhaseIn">
                    <button type="button" class="b-btn-icon-info ml-2"
                            (click)="$event.preventDefault()" #burndownTooltip>
                      <span class="icon-info" inlineSVG="assets/img/signs-icons.svg#icon-tooltip"></span>
                    </button>
                    <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                                [width]="250" [target]="burndownTooltip">
                      <div class="d-flex flex-column tooltip-menu">
                        This option provides the ability to phase into the premises over a period of time, rather than paying for the entire space from the beginning of the lease term.
                      </div>
                    </dx-tooltip>
                  </ng-container>
                </div>
              </div>
              <dx-validator>
                <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
                </dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>
      </div>
      <!-- End: Options -->
      <!-- Start: Option controls -->
      <ng-container [ngSwitch]="leaseTerm.tenantSquareFootageTermType">
        <!-- Start: Custom -->
        <ng-container *ngSwitchCase="tenantSquareFootageTermType.Custom">
          <div class="tenant-square-footage-custom-value">
            <app-input-field [id]="'tenant-square-footage-custom-value'" [placeholder]="'(e.g. 1000)'"
                             [className]="'real-estate-input'" [min]="1" [step]="0" [max]="100000000"
                             [format]="'#,##0'" [width]="'115px'"
                             [errorType]="'required'" [errorMessage]="'Custom size clause is required'"
                             [value]="leaseTerm.tenantSquareFootageCustomValue" [suffix]="'SF'"
                             (valueChange)="leaseTerm.tenantSquareFootageCustomValue = $event; clearErrors()"
            ></app-input-field>
          </div>

        </ng-container>
        <!-- End: Custom -->
        <!-- Start: Phase-in -->
        <ng-container *ngSwitchCase="tenantSquareFootageTermType.PhaseIn">
          <ng-container *ngIf="!canFillPhaseInTable(); else phaseInTemplate">
            <div class="tenant-square-footage-phase-in-table">
              <ng-container *ngIf="!getTerm() && !getCommencement(); else splittedConditionsTemplate">
                <div class="term-toast term-toast-neutral" style="margin: 0 0 16px">
                  <i inlineSVG="assets/img/information.svg"></i>
                  Lease term and Commencement date must be filled out for a Phase-in schedule.
                </div>
              </ng-container>
              <ng-template #splittedConditionsTemplate>
                <ng-container *ngIf="!getTerm()">
                  <div class="term-toast term-toast-neutral" style="margin: 0 0 16px">
                    <i inlineSVG="assets/img/information.svg"></i>
                    Lease term must be filled out for a Phase-in schedule.
                  </div>
                </ng-container>
                <ng-container *ngIf="!getCommencement()">
                  <div class="term-toast term-toast-neutral" style="margin: 0 0 16px">
                    <i inlineSVG="assets/img/information.svg"></i>
                    Commencement date must be filled out for a Phase-in schedule.
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #phaseInTemplate>
            <div class="tenant-square-footage-phase-in-table">
              <app-tenant-square-footage-phase-in-table [lease]="lease"
                                                        [(tenantSquareFootage)]="leaseTerm"
                                                        (phaseInValueChange)="clearErrors()">
              </app-tenant-square-footage-phase-in-table>
            </div>
          </ng-template>
        </ng-container>
        <!-- End: Phase-in -->
        <!-- Start: Expand -->
        <ng-container *ngSwitchCase="tenantSquareFootageTermType.Expand">
          <div *ngFor="let option of expandRadioOptions; let index = index;">
            <div class="custom-control custom-radio">
              <input class="custom-control-input" type="radio"
                     [name]="'expandRadioItem'+index" [id]="'expand-radio-item-'+ index" [value]="option.value"
                     [(ngModel)]="expandRadioOption"
                     (ngModelChange)="clearErrors()" />

              <label class="custom-control-label"
                     [for]="'expand-radio-item-'+ index">
                {{option.name}}
              </label>
            </div>

            <div class="radio-control"
                 *ngIf="expandRadioOption === index">
              <ng-container [ngSwitch]="expandRadioOption">
                <!-- Start: Building Units -->
                <ng-container *ngSwitchCase="0">
                  <dx-drop-down-box placeholder="Please select one or multiple vacant units"
                                    [dataSource]="vacantUnitsDataSource"
                                    [displayValueFormatter]="selectedVacantUnitsValueFormatter"
                                    [(value)]="leaseTerm.tenantSquareFootageExpandByUnits"
                                    (valueChange)="clearErrors()">
                    <dx-data-grid class="tenant-square-footage-control"
                                  [dataSource]="vacantUnits" [selection]="{ mode: 'multiple' }"
                                  [paging]="{ enabled: true, pageSize: 10 }"
                                  [filterRow]="{ visible: false }" [scrolling]="{ mode: 'virtual' }"
                                  [showColumnHeaders]="false" [hoverStateEnabled]="false"
                                  (onSelectionChanged)="handleExpandByUnitsChange($event)">
                      <dxo-scrolling mode="virtual">
                      </dxo-scrolling>

                      <dxo-paging [enabled]="true" [pageSize]="10">
                      </dxo-paging>

                      <dxi-column dataField="name" dataType="text" cellTemplate="nameTpl">
                      </dxi-column>

                      <div *dxTemplate="let item of 'nameTpl'">
                        {{item?.data?.name}} – {{(item?.data?.size || 0) | safeNumber}} SF
                      </div>
                    </dx-data-grid>

                    <dx-validator>
                      <dxi-validation-rule type="required" message="At least one vacant unit is required">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-drop-down-box>
                </ng-container>
                <!-- End: Building Units -->
                <!-- Start: Custom -->
                <ng-container *ngSwitchCase="1">
                  <dx-number-box id="tenant-square-footage-custom-expand-value"
                                 name="tenantSquareFootageCustomExpandValue" format="#,##0 SF"
                                 [min]="getMinTenantSquareFootage(vacantUnits)"
                                 [max]="getMaxTenantSquareFootage(vacantUnits)"
                                 step="0"
                                 [(value)]="leaseTerm.tenantSquareFootageRestructuredValue"
                                 (valueChange)="clearErrors()">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="Tenant square footage clause is required">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </ng-container>
                <!-- End: Custom -->
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- End: Expand -->
        <!-- Start: Relocate -->
        <ng-container *ngSwitchCase="tenantSquareFootageTermType.Relocate">
          <div *ngFor="let option of relocateRadioOptions; let index = index;">
            <div class="custom-control custom-radio">
              <input class="custom-control-input" type="radio"
                     [name]="'relocateRadioItem'+index" [id]="'relocate-radio-item-'+ index" [value]="option.value"
                     [(ngModel)]="relocateRadioOption"
                     (ngModelChange)="clearErrors()"/>

              <label class="custom-control-label"
                     [for]="'relocate-radio-item-'+ index">
                {{option.name}}
              </label>
            </div>

            <div class="radio-control"
                 *ngIf="relocateRadioOption === index">
              <ng-container [ngSwitch]="relocateRadioOption">
                <!-- Start: Building Units -->
                <ng-container *ngSwitchCase="0">
                  <dx-drop-down-box placeholder="Please select one or multiple vacant units"
                                    [dataSource]="vacantUnitsDataSource"
                                    [displayValueFormatter]="selectedVacantUnitsValueFormatter"
                                    [(value)]="leaseTerm.tenantSquareFootageRelocateByUnits"
                                    (valueChange)="clearErrors()">
                    <dx-data-grid class="tenant-square-footage-control"
                                  [dataSource]="vacantUnits" [selection]="{ mode: 'multiple' }"
                                  [paging]="{ enabled: true, pageSize: 10 }"
                                  [filterRow]="{ visible: false }" [scrolling]="{ mode: 'virtual' }"
                                  [showColumnHeaders]="false" [hoverStateEnabled]="false"
                                  (onSelectionChanged)="handleRelocateByUnitsChange($event)">
                      <dxo-scrolling mode="virtual">
                      </dxo-scrolling>

                      <dxo-paging [enabled]="true" [pageSize]="10">
                      </dxo-paging>

                      <dxi-column dataField="name" dataType="text" cellTemplate="nameTpl">
                      </dxi-column>

                      <div *dxTemplate="let item of 'nameTpl'">
                        {{item?.data?.name}} – {{(item?.data?.size || 0) | safeNumber}} SF
                      </div>
                    </dx-data-grid>

                    <dx-validator>
                      <dxi-validation-rule type="required" message="At least one vacant unit is required">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-drop-down-box>
                </ng-container>
                <!-- End: Building Units -->
                <!-- Start: Custom -->
                <ng-container *ngSwitchCase="1">
                  <dx-number-box id="tenant-square-footage-custom-relocate-value"
                                 name="tenantSquareFootageCustomRelocateValue" format="#,##0 SF"
                                 [min]="getMinTenantSquareFootage(vacantUnits)"
                                 [max]="getMaxTenantSquareFootage(vacantUnits)"
                                 step="0"
                                 [(value)]="leaseTerm.tenantSquareFootageRestructuredValue"
                                 (valueChange)="clearErrors()">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="Tenant square footage clause is required">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </ng-container>
                <!-- End: Custom -->
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- End: Relocate -->
        <!-- Start: Downsize -->
        <ng-container *ngSwitchCase="tenantSquareFootageTermType.Downsize">
            <div class="">
                <app-input-field id="tenant-square-footage-custom-downsize-value" placeholder="(e.g. 10.000)" class="downsize--input"
                                 format="#,##0" width="110px" suffix="SF"
                                 errorMessage="Tenant square footage clause is required"
                                 [min]="1" [max]="lease.tenantSquareFootageTerm?.abstract?.tenantSquareFootageValue ?? getMaxTenantSquareFootage(vacantUnits)" [step]="0"
                                 [name]="'tenantSquareFootageCustomDownsizeValue'"
                                 [(value)]="leaseTerm.tenantSquareFootageRestructuredValue"
                                 (valueChange)="clearErrors()">
                </app-input-field>
            </div>
        </ng-container>
        <!-- End: Downsize -->
      </ng-container>
      <!-- End: Option controls -->
      <!-- Start: Multiple options -->
      <ng-container *ngIf="canInsertNewOption()">
        <ng-container *ngFor="let termOption of leaseTerm?.leaseTermOptions?.options; let index = index;">
          <div class="row">
            <div class="col-12">
              <label>
                OPTION {{getOptionName(index)}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-tenant-square-footage [isOption]="true" [project]="project" [lease]="lease"
                                         [(leaseTerm)]="leaseTerm?.leaseTermOptions?.options[index]">
              </app-tenant-square-footage>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- End: Multiple options -->
      <!-- Start: Validation summary -->

        <div class="validation-summary">
          <dx-validation-summary ngForm="form">
          </dx-validation-summary>
          <div class="term-toast term-toast-error"
               *ngFor="let msg of errors">
            <i inlineSVG="assets/img/x.svg"></i>
            {{msg}}
          </div>
        </div>

      <!-- End: Validation summary -->
      <!-- Start: Form buttons -->
      <div class="row main-actions"
           *ngIf="!isOption">
        <!-- Start: Save -->
        <div class="px-0">
          <div class="dx-fieldset">
            <dx-button class="b-btn b-btn--small b-btn-primary btn" text="Save" type="success"
                       [useSubmitBehavior]="true" #submitButton>
            </dx-button>
          </div>
        </div>
        <!-- End: Save -->
        <!-- Start: Cancel -->
        <div class="px-0"
             *ngIf="rejectButtonClicked">
          <div class="dx-fieldset">
            <dx-button class="b-btn b-btn--small b-btn-primary btn" text="Cancel" type="cancel"
                       [useSubmitBehavior]="false" (onClick)="onCancelLeaseTerm()">
            </dx-button>
          </div>
        </div>
        <!-- End: Cancel -->
      </div>
      <!-- End: Form buttons -->
    </dx-validation-group>
  </form>
</ng-container>

<div class="term-toast term-toast-information"
     *ngIf="isTermWillBeAddressedInFollowingStages()">
  <i inlineSVG="assets/img/information.svg"></i>
  This Term will be addressed in the following Stage(s).
</div>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
