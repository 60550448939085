import * as ng from '@angular/core';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as models from '../../models/document-viewer.model';

import { DocumentViewerRefService } from '../../services/document-viewer-ref.service';

@Component({
  templateUrl: 'document-viewer-dialog.component.html',
  styleUrls: ['document-viewer-dialog.component.scss'],
})
export class DocumentViewerDialogComponent implements OnInit, OnDestroy {
  readonly onShowing: ng.EventEmitter<any>;
  readonly onHiding: ng.EventEmitter<any>;
  readonly onShown: ng.EventEmitter<any>;
  readonly onHidden: ng.EventEmitter<any>;
  readonly onContentReady: ng.EventEmitter<any>;

  readonly config: models.DocumentViewerOptions;

  isVisible: boolean;
  isReady: boolean;

  injectableData: {
    documents: Array<models.DocumentViewerDocument>,
    activeIndex: number,
  };

  title: string;

  private readonly _ngZone: NgZone;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _documentViewerRefService: DocumentViewerRefService;

  private readonly _destroy$: Subject<void>;

  constructor(
    ngZone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    documentViewerRefService: DocumentViewerRefService,
    config: models.DocumentViewerOptions
  ) {
    this.onShowing = new ng.EventEmitter<any>();
    this.onHiding = new ng.EventEmitter<any>();
    this.onShown = new ng.EventEmitter<any>();
    this.onHidden = new ng.EventEmitter<any>();
    this.onContentReady = new ng.EventEmitter<any>();

    this.config = config;

    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._documentViewerRefService = documentViewerRefService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.isReady = false;
    this.title = this.config.title || 'Preview';

    this._subscribeOnDxEvents();

    // Fire sub-events
    this._firePopupRefEvent(this.onShowing, this._documentViewerRefService.onShowing);
    this._firePopupRefEvent(this.onHiding, this._documentViewerRefService.onHiding);
    this._firePopupRefEvent(this.onShown, this._documentViewerRefService.onShown);
    this._firePopupRefEvent(this.onHidden, this._documentViewerRefService.onHidden);
    this._firePopupRefEvent(this.onContentReady, this._documentViewerRefService.onContentReady);

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if ((<ViewRef>this._changeDetectorRef).destroyed) {
          return;
        }

        this.show();

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();
  }

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }

  handleDocumentSelect(documentRef: models.DocumentViewerDocumentRef): void {
    if (!documentRef) {
      return;
    }

    this.title = documentRef.name || 'Preview';
  }

  private _subscribeOnDxEvents(): void {
    this.onHidden
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this._documentViewerRefService.hide()),
      )
      .subscribe();

    this.onShown
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.isReady = true;
        })
      )
      .subscribe();
  }

  private _firePopupRefEvent(from: ng.EventEmitter<any>, to: ng.EventEmitter<any>): void {
    from.pipe(takeUntil(this._destroy$)).subscribe((event) => to.emit(event));
  }
}
