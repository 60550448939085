import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Configuration } from '@statera/sdk/common';

import { BuildingRepository } from './building.repository';
import { BuildingManager } from './building.manager';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    BuildingRepository,
    BuildingManager,
  ],
})
export class BuildingModule {
  static forChild(configuration: Configuration): ModuleWithProviders<BuildingModule> {
    return {
      ngModule: BuildingModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        BuildingRepository,
        BuildingManager,
      ],
    };
  }
}
