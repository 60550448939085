import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { environment } from '../../../../environments/environment';

import { AlertService } from '../../../alert/services/alert.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  planPopupVisible = false;

  private readonly _httpClient: HttpClient;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  constructor(httpClient: HttpClient, alertService: AlertService, alertMessagesManager: AlertMessagesManager) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._httpClient = httpClient;
  }

  ngOnInit() {
  }

  testNotifications(template: string) {
    this._httpClient
      .post(environment.webApiUrl + '/DocTemplate/SendAllNotifications?template=' + template, null)
      .subscribe(() => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getNotificationRequestSendAlertText(),
        });
      });
  }

  testJobs(jobName: string) {
    this._httpClient
      .post(environment.webApiUrl + '/DocTemplate/ExecuteJob?jobName=' + jobName, null)
      .subscribe(() => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getJobStartedAlertText(),
        });
      });
  }
}
