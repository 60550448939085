import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { InvitationRepository } from './invitation-repository.service';
import { InvitationManager } from './invitation-manager.service';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    InvitationRepository,
    InvitationManager,
  ],
})
export class InvitationModule {
  static forChild(configuration: Configuration): ModuleWithProviders<InvitationModule> {
    return {
      ngModule: InvitationModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        InvitationRepository,
        InvitationManager,
      ],
    };
  }
}
