export enum VideoPlayerType {
  HTML5 = 'html5',
  YouTube = 'youtube',
  Vimeo = 'vimeo',
}

export enum VideoPlayerState {
  Playing = 'playing',
  Played = 'played',
  Paused = 'paused',
}
