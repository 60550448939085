import {Component, OnDestroy, OnInit} from '@angular/core';
import * as models from '../../../infrastructure/models/generated';
import {DialogRefService} from '../../../dialog/services/dialog-ref.service';
import {Subject} from 'rxjs';
import {NgForm} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {LeaseManager} from '@statera/sdk/lease';

@Component({
  templateUrl: './lease-settings.component.html',
  styleUrls: ['./lease-settings.component.scss']
})
export class LeaseSettingsComponent implements OnInit, OnDestroy {
  leaseSettings: models.ILeaseSettingsViewModel;
  readonly lease: models.ILeaseViewModel;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;
  private readonly _leaseManager: LeaseManager;
  leaseMetrics = [
    {
      label: 'PSF/Yr',
      value: models.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      label: 'PSF/Mo',
      value: models.BaseRentalRateUnitMetrics.PsfPerMonth,
    },
  ];
  constructor(dialogRefService: DialogRefService, leaseManager: LeaseManager) {
    this._dialogRefService = dialogRefService;
    this._leaseManager = leaseManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.leaseSettings = this.leaseSettings || <models.ILeaseSettingsViewModel>{
      leaseMetrics: this.lease.leaseMetrics
    };
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._leaseManager
      .saveLeaseSettings(this.lease.id, this.leaseSettings)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((leaseSettings) => {
        this.close();
      });
  }

}
