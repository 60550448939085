import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { Feature, FeatureTogglingManager } from '@statera/sdk/feature-toggling';

@Directive({
  selector: '[appIsFeatureEnabled]',
})
export class IsFeatureEnabledDirective implements OnInit, OnDestroy {
  @Input('appIsFeatureEnabled') feature: Feature;
  @Input() appIsFeatureEnabledThen: TemplateRef<any>;
  @Input() appIsFeatureEnabledElse: TemplateRef<any>;

  private readonly _templateRef: TemplateRef<any>;
  private readonly _viewContainerRef: ViewContainerRef;
  private readonly _featureTogglingManager: FeatureTogglingManager;
  private readonly _destroy: Subject<void>;

  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    featureTogglingManager: FeatureTogglingManager,
  ) {
    this._templateRef = templateRef;
    this._viewContainerRef = viewContainerRef;
    this._featureTogglingManager = featureTogglingManager;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this._featureTogglingManager
      .getCompanyFeatureToggle(this.feature)
      .pipe(
        catchError(() => of(null)),
        tap(featureFlag => {
          this._viewContainerRef.clear();

          if (featureFlag) {
            let templateRef = this._templateRef;
            if (this.appIsFeatureEnabledThen) {
              templateRef = this.appIsFeatureEnabledThen;
            }

            this._viewContainerRef.createEmbeddedView(templateRef);
            return;
          }

          if (this.appIsFeatureEnabledElse) {
            this._viewContainerRef.createEmbeddedView(this.appIsFeatureEnabledElse);
            return;
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}

