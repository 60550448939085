  <dx-box class="popup-box" direction="col" width="100%" style="height: auto">
    <dxi-item [baseSize]="80">
      <div class="w-alerts-header">
        <p>Recent Lease Activity:</p>
        <div *ngIf="dataLoaded" #buttonContainer class="w-alerts-header-button">
          <button class="btn" (click)="readAllAlerts()">
            <span>Mark all as read</span>
            <span class="mark-icon" inlineSVG="assets/img/sign-icons.svg#check-status-icon"></span>
          </button>
        </div>
      </div>
    </dxi-item>
    <dxi-item [ratio]="10">
      <dx-list
        class="w-alerts"
        [dataSource]="alertDataSource"
        [focusStateEnabled]="false"
        [activeStateEnabled]="false"
        pageLoadMode="scrollBottom"
        noDataText="No alerts"
        (onItemClick)="onItemClick($event)">
        <div
          *dxTemplate="let item of 'item'"
          class="b-alert">
          <figure class="b-alert-ava">
            <img [src]="getAvatarUrl(item)" class="img-fluid rounded-circle" alt>
          </figure>
          <div class="b-alert-body">
            <div class="b-alert-body-text">
              <strong>{{getDisplayName(item,item?.createdBy)}}</strong>
              <span *ngIf="item?.alertSettingTemplate?.alertType === AlertType.TermComment">
                left an {{getTermCommentTypeName(item?.termCommentType)}} comment on <strong><ng-container *ngIf="item?.leaseTermType"> {{getLeaseTermDescriptionByLeaseTermType(item?.leaseTermType)}}:</ng-container></strong>
              </span>
              <span *ngIf="item?.alertSettingTemplate?.alertType === AlertType.VersionedFileComment">
                left an {{getVersionedFileCommentTypeName(item?.termCommentType)}} comment on <strong><ng-container *ngIf="item?.projectType && item?.versionedFileCommentType"> {{getDocumentNameByProjectType(item?.projectType)}}:</ng-container></strong>
              </span>
              <div class="b-alert-body-external"
                   *ngIf="item?.alertSettingTemplate?.alertType === AlertType.TermComment || item?.alertSettingTemplate?.alertType === AlertType.VersionedFileComment; else onlyComment">
                <div class="b-alert-body-comment" [innerHtml]="item.sanitizedHtml"></div>
              </div>
              <ng-template #onlyComment>
                <div class="b-alert-body-comment" [innerHtml]="item.sanitizedHtml"></div>
              </ng-template>
            </div>
            <time class="b-alert-body-time">
              {{ getDate(item?.createdOn) }} at {{ item?.createdOn | safeDate: 'hh:mm aaa' }}
            </time>
          </div>
        </div>
      </dx-list>
    </dxi-item>
  </dx-box>




