<form name="form" (submit)="saveLeaseTerm($event)" *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6>Please choose from the following</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-11 mb-4 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          itemTemplate="radio"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.landlordMaintenanceTermType"
                          (valueChange)="clearErrors()">
            <div class="radio"
                 *dxTemplate="let data of 'radio'"
                 [class.insert-data]="data.insertData">
              <p *ngIf="!data.insertData; else soleMaintenanceByLandlordRadioOptionTemplate">
                {{data.name}}
              </p>
              <ng-template #soleMaintenanceByLandlordRadioOptionTemplate>
                <p>
                  Landlord shall maintain, at its sole cost and expense, the building footings,
                  floor slab and foundations, structural steel columns and girders,
                  plumbing lines located outside of the Building, under the slab or within exterior walls.
                  Landlord to maintain, with cost to be part of Operating Expenses,
                  the roof (replacement at Landlord's sole cost and expense), exterior walls, building systems,
                  mechanicals, parking lot, lighting, fire safety, windows, and common areas.
                  Capital improvements and replacements to be amortized over the estimated
                  economic useful life or payback period.
                </p>
                <ng-container *ngIf="leaseTerm.landlordMaintenanceTermType === LandlordMaintenanceTermType.SoleMaintenanceByLandlord">
                  <div class="my-2">
                    <dx-check-box id="hasOperationCondition" name="hasOperationCondition"
                                  class="v2-checkbox"
                                  text="Add an Operating Condition"
                                  [(value)]="leaseTerm.hasOperationCondition"
                                  (onValueChanged)="clearErrors()">
                    </dx-check-box>
                  </div>
                  <ng-container *ngIf="leaseTerm.hasOperationCondition">
                    <div class="mb-2">
                      All building systems including, but not limited to HVAC equipment, plumbing, electrical,
                      office and warehouse lighting, windows/glass, fire safety, doors,
                      dock equipment and all other structural components shall be in good repair and
                      operating condition at lease commencement date.
                    </div>
                    <div class="inline-row">
                      <span>Landlord shall warrant all systems for</span>
                      <app-input-field appIgnoreScrollwheel [id]="'warrantDaysCount'" [placeholder]="'(e.g. 60)'"
                                            [className]="'real-estate-input no-invalid-message py-0'" [min]="0"
                                            [format]="'#0.##'" [width]="'75px'"
                                            [errorMessage]="'Warrant days count is required'"
                                            [value]="leaseTerm.warrantDaysCount" [suffix]="'days'"
                                            (valueChange)="leaseTerm.warrantDaysCount = $event; clearErrors()"
                      ></app-input-field>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>
            </div>
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div *ngIf="leaseTerm.landlordMaintenanceTermType === LandlordMaintenanceTermType.Custom"
         class="col-8 mb-4 pl-0 mt-n4">
      <app-term-custom-value-template-textarea placeholder="Insert custom Landlord Maintenance or responsibilities clause"
                                               required requiredMessage="Custom Landlord Maintenance or Responsibilities clause is required"
                                               className="real-estate-input mw-100"
                                               [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                               [(value)]="leaseTerm.landlordMaintenance"
                                               (valueChange)="clearErrors()">
      </app-term-custom-value-template-textarea>
    </div>

    <!-- Start: Validation summary -->
    <div class="row">
      <div class="col-sm-12">
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>
    </div>
    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div class="col-sm-3 col-6">
        <div class="dx-fieldset">
          <dx-button #submitButton id="button" text="Save" type="success" [useSubmitBehavior]="true" class="b-btn b-btn--small b-btn-primary btn">
          </dx-button>
        </div>
      </div>

      <div class="col-sm-3 col-6" *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" [useSubmitBehavior]="false" (onClick)="onCancelLeaseTerm()" class="b-btn b-btn--small b-btn-primary btn">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
