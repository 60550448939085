<div class="b-card visit d-flex flex-column">
  <div class="visit-header">
    Select a Date & Time
  </div>

  <dx-calendar
    #calendar
    class="visit-calendar"
    [firstDayOfWeek]="1"
    [(value)]="selectedDate"
    cellTemplate="cellTemplate"
  >
      <span *dxTemplate="let cell of 'cellTemplate'" [ngClass]="getCellCssClass(cell.date)">
        {{cell.text}}
      </span>
  </dx-calendar>
</div>

<div class="b-card visit d-flex flex-column" *ngIf="isShowCancelVisitButton">
  <div class="visit-header">
    You can cancel schedule visit
  </div>

  <dx-button
    text="Cancel"
    type="success"
    class="m-2"
    (onClick)="onCancelVisitButtonClicked($event)">
  </dx-button>
</div>

<div class="b-card visit flex-grow-1 d-flex flex-column">
  <div class="visit-header">
    Site Visit Options
  </div>

  <dx-toolbar *appCheckAccess="StateraClaimType.FloorPlan; stateraClaimValue: StateraClaimValue.Write; leaseId:lease.id">
    <dxi-item location="before"
              [visible]="isRescheduleAcceptanceAvailable()">
      <dx-button icon="fa fa-check-circle" hint="Approve visit"
                 [disabled]="!selectedItemKeys || !selectedItemKeys.length || selectedItem?.approved"
                 (onClick)="acceptReschedule()">
      </dx-button>
    </dxi-item>

    <dxi-item location="before"
              [visible]="isAppointmentRescheduleAvailable()">
      <dx-button icon="fa fa-pencil" hint="Reschedule Contractor"
                 [disabled]="(!selectedItemKeys || !selectedItemKeys.length)"
                 (onClick)="rescheduleVisit()">
      </dx-button>
    </dxi-item>

    <dxi-item location="before" [visible]="isLandlord">
      <dx-button
        icon="fa fa-plus-circle"
        hint="Add new visit"
        [disabled]="!selectedDate"
        [visible]="isAllowEditVisit"
        (onClick)="addVisit()"
      ></dx-button>
    </dxi-item>
    <dxi-item location="before" [visible]="!isLandlord">
      <dx-button
        icon="fa fa-check-circle"
        hint="Approve visit"
        [visible]="isAllowApproveVisit"
        [disabled]="!selectedItemKeys || !selectedItemKeys.length || selectedItem?.approved"
        (onClick)="acceptVisit()"
      ></dx-button>
    </dxi-item>
    <dxi-item location="before">
      <dx-button
        icon="fa fa-pencil"
        hint="Edit visit"
        [disabled]="(!selectedItemKeys || !selectedItemKeys.length)"
        [visible]="isAllowEditVisit"
        (onClick)="editVisit()"
      ></dx-button>
    </dxi-item>
    <dxi-item location="before">
      <dx-button
        icon="fa fa-times"
        hint="Delete visit"
        [visible]="isAllowEditVisit"
        [disabled]="(!selectedItemKeys || !selectedItemKeys.length)"
        (onClick)="deleteVisit()"
      ></dx-button>
    </dxi-item>
  </dx-toolbar>

  <dx-list
    class="visit-list-items pending-list"
    noDataText="No items in the list"
    [items]="appointments"
    [keyExpr]="'id'"
    [focusStateEnabled]="false"
    [activeStateEnabled]="false"
    [showSelectionControls]="true"
    selectionMode="single"
    [(selectedItemKeys)]="selectedItemKeys"
    (onOptionChanged)="onVisitListSelectionChanged($event)"
  >
      <div *dxTemplate="let item of 'item'">
        <div class="d-flex align-items-center justify-content-between">
          <div class="flex-grow-1 d-flex flex-column">
            <div class="appointment-text">
              {{item.text}}
            </div>
            <div class="flex-grow-1">
              {{renderDateTime(item)}}
            </div>
          </div>

          <div [ngClass]="{'accepted': item.approved, 'pending': !item.approved}">
            <span class="status-indicator"></span>
            <span class="status-text">
              {{item.approved ? 'Approved' : 'Pending'}}
            </span>
          </div>
        </div>
      </div>
  </dx-list>
</div>
