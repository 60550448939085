import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';
import { InsuranceCertificate } from './certificate-of-insurance.model';

@Injectable()
export class CertificateOfInsuranceRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient, configuration: Configuration) {
    this._httpClient = httpClient;
    this._configuration = configuration;
  }

  getCertificateOfInsuranceByLeaseId(leaseId: number): Observable<InsuranceCertificate> {
    let params = new HttpParams();

    params = params.set('leaseId', leaseId.toString());

    return this._httpClient.get<InsuranceCertificate>(`${this._configuration.apiEndpoint}/insuranceCertificates`,
      {params});
  }
}
