import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { TenantQuestions } from '../../../infrastructure/enums/questions';

import { QuizType } from '@statera/sdk/common';
import { QuizManager } from '@statera/sdk/quiz';
import { TenantManager } from '@statera/sdk/tenant';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-tenant-lease-cancellation',
  templateUrl: './lease-tenant-cancellation.component.html',
  styleUrls: ['./lease-tenant-cancellation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaseTenantCancellationComponent implements OnInit, OnDestroy {
  @Input()
  leaseId: number;
  @Input()
  project: models.IProjectViewModel;
  @Input()
  buildingAddress: string;

  @ViewChild('dxPopupComponent') popup: DxPopupComponent;

  private readonly _formBuilder: FormBuilder;

  form: FormGroup;
  questions: Array<models.IQuizQuestionViewModel> = [];
  destroy$ = new Subject();
  questionTypes = models.QuestionType;
  groupIdValue: { [key: number]: string } = {};
  cancelled = false;
  submittedQuestionIds = [];

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _tenantManager: TenantManager;
  private readonly _destroy$ = new Subject<void>();
  private readonly _dialogRefService: DialogRefService;
  private readonly _ngZone: NgZone;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _quizManager: QuizManager;

  private _formGroupValidator: ValidatorFn = (formGroup: FormGroup) => {
    const isNotEmpty = Object.keys(formGroup.controls).some(controlName => {
      return !formGroup.controls[controlName].disabled && !!formGroup.controls[controlName].value;
    });

    return isNotEmpty
      ? null
      : { isEmpty: true };
  }

  constructor(
    formBuilder: FormBuilder,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    tenantManager: TenantManager,
    dialogRefService: DialogRefService,
    ngZone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    quizManager: QuizManager,
  ) {
    this._formBuilder = formBuilder;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._tenantManager = tenantManager;
    this._destroy$ = new Subject<void>();
    this._dialogRefService = dialogRefService;
    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._quizManager = quizManager;

    this.form = this._formBuilder.group({});
  }

  ngOnInit() {
    this._quizManager
      .getQuizQuestions(QuizType.TenantCancellationWizard, this.leaseId)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(questions => {
        this.questions = questions;
        questions.forEach(q => {
          this.form.addControl(q.id.toString(), new FormControl(null,
            /* tslint:disable-next-line */
              (q.id !== TenantQuestions.City ? Validators.required : undefined)
            )
          );
          if (q.parentQuestionId) {
            this.form.controls[q.id.toString()].disable();
          }
        });
        this.form.setValidators(this._formGroupValidator);
        this._ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            if (this._dialogRefService) {
              this._dialogRefService.repaint();
            }
          });
        });
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isControlValid(ngForm: FormGroupDirective, qid: string) {
    if (!this.form || !this.form.controls) {
      return true;
    }

    if (this.form.controls[qid].disabled) {
      return true;
    }

    if (this.form.controls[qid].touched) {
      return this.form.controls[qid].valid;
    }

    if (ngForm.submitted && this.submittedQuestionIds.includes(qid)) {
      return this.form.controls[qid].valid;
    }

    return true;
  }

  getQuestionGroups(): Array<{ id: number, questions: Array<models.IQuizQuestionViewModel> }> {
    const groupIds = [...new Set(this.questions.filter(q => !!q.groupId).map(q => q.groupId))];
    return groupIds.sort()
      .map((groupId) => ({
        id: groupId,
        questions: this.questions
          .filter(q => q.groupId === groupId)
          .sort((left, right) => left.id - right.id)
      }));
  }

  getIndependentQuestionsInForm(): Array<models.IQuizQuestionViewModel> {
    if (!this.form) {
      return [];
    }
    return this.questions.filter(q =>
      !q.groupId &&
      !q.parentQuestionId &&
      q.questionType !== models.QuestionType.Done &&
      Object.keys(this.form.controls).includes(q.id.toString())
    ).sort((left, right) => left.id - right.id);
  }

  getChildrenQuestionsInForm(questionId: string): Array<models.IQuizQuestionViewModel> {
    if (!this.form) {
      return [];
    }
    return this.questions.filter(q => q.parentQuestionId === +questionId);
  }

  onChecked(checked: boolean, questionId: string) {
    const isParent = this.questions.some(q => q.id === +questionId && !q.parentQuestionId);
    if (!isParent) {
      return;
    }

    const children = this.questions.filter(q => q.parentQuestionId === +questionId);
    if (checked) {
      children.forEach(q => {
        switch (q.questionType) {
          case models.QuestionType.SqftNumber:
          case models.QuestionType.Number:
          case models.QuestionType.DollarNumber:
          case models.QuestionType.Text:
          case models.QuestionType.Choice:
          case models.QuestionType.Done:
            this.form.controls[q.id.toString()].enable();
            break;
        }
      });
    } else {
      children.forEach(q => this.form.controls[q.id.toString()].disable());
    }
  }

  submitForm(btn: DxButtonComponent) {
    this.submittedQuestionIds = Object.keys(this.form.value);
    const isInvalid = !!this.form.errors || Object.keys(this.form.controls).some(controlName => {
      if (!this.questions.find(q => q.id === +controlName).parentQuestionId) {
        return false;
      }
      return !this.form.controls[controlName].disabled && this.form.controls[controlName].invalid;
    });

    if (isInvalid) {
      return;
    }

    btn.disabled = true;
    const answers = Object.keys(this.form.value).map(questionId => {
      const question = this.questions.find(q => q.id === +questionId);
      const quizId = this.questions[0].quizId;

      if (!this.form.value[questionId] && !Object.values(this.groupIdValue).some(id => questionId === id)) {
        return;
      }

      const value = <models.IQuizAnswerViewModel>{
        id: 0,
        leaseId: this.leaseId,
        quizId: quizId,
        quizQuestionId: +questionId,
      };

      switch (question.questionType) {
        case models.QuestionType.DollarNumber:
        case models.QuestionType.Number:
        case models.QuestionType.SqftNumber:
          value.answerNumber = this.form.value[questionId];
          break;

        case models.QuestionType.Choice:
          const selectedOption = question.options.find(o => o.id === this.form.value[questionId]);
          value.customAnswerText = JSON.stringify(selectedOption);
          value.selectedOptionId = selectedOption.id;
          value.selectedOptionText = selectedOption.text;
          break;

        case models.QuestionType.Text:
          value.customAnswerText = this.form.value[questionId];
          break;

        case models.QuestionType.YesNo:
          value.customAnswerText = this.form.value[questionId] || Object.values(this.groupIdValue).some(id => questionId === id)
            ? 'Yes'
            : 'No';
          break;
      }

      return value;
    }).filter(Boolean);

    this._tenantManager
      .cancelRequestViaTenantWizard(this.questions[0].quizId, this.leaseId, answers)
      .subscribe(() => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getTenantCancelSurveyAdditionalInfoProvidedAlertText(),
        });

        btn.disabled = false;
        this.cancelled = true;
        this._dialogRefService.outputData = true;
        this._dialogRefService.hide();
      });
  }

  close() {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCancelDeadByTenantAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._dialogRefService.outputData = false;
        this._dialogRefService.hide();
      });
  }

  onQuestionGroupValueChanged(groupId: number, question: models.IQuizQuestionViewModel) {
    if (this.groupIdValue[groupId]) {
      this.form.controls[this.groupIdValue[groupId]].setValue(false);
    }

    if (this.groupIdValue[groupId] === question.id.toString()) {
      delete this.groupIdValue[groupId];
      const children = this.questions.filter(q => q.parentQuestionId === question.id);
      children.forEach(q => this.form.controls[q.id.toString()].disable());
      return;
    }

    const otherQuestionIdsInGroup = this.questions.filter(q => q.groupId === groupId && q.id !== question.id)
      .map(q => q.id);

    const oldChildren = [
      ...this.questions.filter(q => otherQuestionIdsInGroup.includes(q.parentQuestionId)),
    ];

    const newChildren = [...this.questions.filter(q => q.parentQuestionId === question.id)];

    newChildren.forEach(q => {
      switch (q.questionType) {
        case models.QuestionType.SqftNumber:
        case models.QuestionType.Number:
        case models.QuestionType.DollarNumber:
        case models.QuestionType.Text:
        case models.QuestionType.Choice:
        case models.QuestionType.Done:
          this.form.controls[q.id.toString()].enable();
          break;
      }
    });

    oldChildren.forEach(q => this.form.controls[q.id.toString()].disable());

    this.groupIdValue[groupId] = question.id.toString();
    this.form.controls[this.groupIdValue[groupId]].setValue(true);
  }

  getSorryMessageText(): string {
    if (this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal) {
      return 'are no longer interested in the space';
    }
    return 'will not be renewing';
  }
}
