<div class="view-container">
  <div class="view-container-chart">
    <div class="view-container-chart-arrows">
      <button class="left" title="Previous period"
              (click)="goToPreviousPeriod()">
        <span></span>
      </button>
      <span class="view-container-chart-arrows-caption">
        {{ occupancyAndVacancyReportSinglePeriodDialogViewModel.periods[activePeriodId].startDate | date: 'yyyy'}}
      </span>
      <button class="right" title="Next period"
              (click)="goToNextPeriod()">
        <span></span>
      </button>
    </div>

    <app-analytics-occupancy-and-vacancy-report-single-period
      [occupancyAndVacancyReportPeriod]="occupancyAndVacancyReportSinglePeriodDialogViewModel.periods[activePeriodId]">
    </app-analytics-occupancy-and-vacancy-report-single-period>
  </div>

  <div class="view-container-table">
    <app-analytics-occupancy-and-vacancy-report-single-period-table
      [occupancyAndVacancyReportPeriod]="occupancyAndVacancyReportSinglePeriodDialogViewModel.periods[activePeriodId]">
    </app-analytics-occupancy-and-vacancy-report-single-period-table>
  </div>
</div>
