import * as models from '@statera/sdk/common';

export type Lease = models.ILeaseViewModel;
export type CancelledRequest = models.ICancelledRequestViewModel;
export type File = models.IFileViewModel;
export type TenantDashboard = models.ITenantDashboardViewModel;

export type LeaseTerm = models.ILeaseTermViewModel;

export type LeaseTermType = models.LeaseTermType;
export const LeaseTermType = models.LeaseTermType;

export type LeaseTeam = models.LeaseTeam;
export const LeaseTeam = models.LeaseTeam;

export type LeaseStatus = models.LeaseStatus;
export const LeaseStatus = models.LeaseStatus;

export type LeaseTermConfiguration = models.ILeaseTermConfigurationViewModel;

export type ElectedTermForNegotiation = models.IElectedTermForNegotiationViewModel;

export type StartupInfo = models.IStartupInfoViewModel;

export type Project = models.IProjectViewModel;

export type ProjectStatus = models.ProjectStatus;

export type LeaseActivity = models.ILeaseActivityViewModel;
export const ProjectStatus = models.ProjectStatus;

export type ProjectTypeEnum = models.ProjectTypeEnum;
export const ProjectTypeEnum = models.ProjectTypeEnum;

export type Role = models.Role;
export const Role = models.Role;
export const RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;

export type BuildingUnitViewModel = models.IBuildingUnitViewModel;

export type LeaseSettings = models.ILeaseSettingsViewModel;

export type LeaseTeamInfo = models.ILeaseTeamViewModel;
