import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';
import { TourFormComponent } from '../tour-form/tour-form.component';
import { DialogService } from '../../../dialog/services/dialog.service';

@Component({
  selector: 'app-tour-info',
  templateUrl: './tour-info.component.html',
  styleUrls: ['./tour-info.component.scss']
})
export class TourInfoComponent {
  @Input() landlordCompany: models.ICompanyViewModel;
  @Input() tenantCompany: models.ICompanyViewModel;
  @Input() buildingUnit: models.IBuildingUnitViewModel;
  @Input() building: models.IBuildingViewModel;
  @Input() leaseTeam: models.LeaseTeam;
  @Input() projectId: number;
  @Input() counterPartCompany: models.ICompanyViewModel;
  @Input() counterPartUser: models.IProfileViewModel;
  @Input() tour?: models.ITourViewModel;
  @Input() tour$?: Subject<models.ITourViewModel>;

  private readonly _dialogRefService: DialogRefService;
  private readonly _dialogService: DialogService;

  constructor(
    dialogRefService: DialogRefService,
    dialogService: DialogService,
  ) {
    this._dialogRefService = dialogRefService;
    this._dialogService = dialogService;
  }

  close() {
    this._dialogRefService.hide();
  }

  isLandlord(): boolean {
    return this.leaseTeam === models.LeaseTeam.LandlordTeam;
  }

  getOtherPartyLogoUrl(): string {
    const company = this.getOtherPartyCompany();
    return company.logo?.thumbnailUrl ?? '/assets/img/nofoto.png';
  }

  getOtherPartyCompany(): models.ICompanyViewModel {
    return this.isLandlord()
      ? this.tenantCompany
      : this.landlordCompany;
  }

  edit(): void {
    this.close();
    this._dialogService
      .show(TourFormComponent, {
        width: 1220,
        height: 465,
        containerClassNames: ['no-border'],
        injectableData: {
          tenantCompany: this.tenantCompany,
          landlordCompany: this.landlordCompany,
          buildingUnit: this.buildingUnit,
          building: this.building,
          counterPartUser: this.counterPartUser,
          counterPartCompany: this.counterPartCompany,
          leaseTeam: this.leaseTeam,
          projectId: this.projectId,
          tour: this.tour,
          tour$: this.tour$,
        },
      });
  }
}
