import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-tenant-company',
  templateUrl: 'analytics-filters-tenant-company.component.html',
  styleUrls: ['analytics-filters-tenant-company.component.scss'],
})
export class AnalyticsFiltersTenantCompanyComponent {
  @Input() tenantCompanies: Array<analyticsFiltersModels.TenantCompanyFilter>;
  @Output() tenantCompaniesChange: EventEmitter<Array<analyticsFiltersModels.TenantCompanyFilter>>;

  constructor() {
    this.tenantCompaniesChange = new EventEmitter<Array<analyticsFiltersModels.TenantCompanyFilter>>();
  }

  handleTenantCompanySelection(): void {
    this.tenantCompaniesChange.next([...this.tenantCompanies]);
  }
}
