import { Params } from '@angular/router';
import * as models from '../../infrastructure/models/generated';

export const SharedConstants = {
  ClosedProjectStateType: models.RenewalProjectTemplateItemType.LeaseAmendmentSigned,
};

export const StepsWithWizard: Array<models.RenewalProjectTemplateItemType> = [
  models.RenewalProjectTemplateItemType.EstablishCriteria,
  models.RenewalProjectTemplateItemType.EstablishCriteriaByTenant,
  models.RenewalProjectTemplateItemType.UnsolicitedOfferRenewalWizard,
  models.RenewalProjectTemplateItemType.Restructure_EstablishCriteriaByTenant
];

export const coBrokerInvitationByBrokerRequestQueryParam = <Params>{
  target: 'team-members',
};
