import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import * as models from '../../../infrastructure/models/generated';

export interface LeaseState extends EntityState<models.ILeaseViewModel> {}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'lease',
  idKey: 'id',
})
export class LeaseStore extends EntityStore<LeaseState> {
  constructor() {
    super();
  }
}

