import * as models from '../../../../infrastructure/models/generated';

export class LeaseTermEventModel {
  constructor(
    leaseTermViewModel: models.ILeaseTermViewModel,
    onEventFinished: () => void = null,
    isExpandNextTerm: boolean = true,
    exitEditModeAfterSaving = true,
    onEventError = null,
  ) {
    this.leaseTermViewModel = leaseTermViewModel;
    this.onEventFinished = onEventFinished;
    this.isExpandNextTerm = isExpandNextTerm;
    this.exitEditModeAfterSaving = exitEditModeAfterSaving;
    this.onEventError = onEventError;
  }

  leaseTermViewModel: models.ILeaseTermViewModel = null;
  onEventFinished: () => void = null;
  isExpandNextTerm: boolean = null;
  exitEditModeAfterSaving = true;
  onEventError: () => void = null;
}
