import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as models from '../../models/plan-viewer.model';

@Component({
  selector: 'app-plan-viewer-editor',
  templateUrl: 'plan-viewer-editor.component.html',
  styleUrls: ['plan-viewer-editor.component.scss'],
})
export class PlanViewerEditorComponent implements OnInit, OnChanges {
  @Input() mode: models.PlanViewerMode;

  @Input() isDragEnabled: boolean;
  @Input() isDrawCircleEnabled: boolean;
  @Input() isDrawRectEnabled: boolean;
  @Input() isDrawCrossEnabled: boolean;
  @Input() isDrawPolygonEnabled: boolean;
  @Input() rotationAngle: number;

  @Output() isDragEnabledChange: EventEmitter<boolean>;
  @Output() isDrawCircleEnabledChange: EventEmitter<boolean>;
  @Output() isDrawRectEnabledChange: EventEmitter<boolean>;
  @Output() isDrawCrossEnabledChange: EventEmitter<boolean>;
  @Output() isDrawPolygonEnabledChange: EventEmitter<boolean>;
  @Output() rotationAngleChange: EventEmitter<number>;

  modes: typeof models.PlanViewerMode;

  constructor() {
    this.isDragEnabledChange = new EventEmitter<boolean>();
    this.isDrawCircleEnabledChange = new EventEmitter<boolean>();
    this.isDrawRectEnabledChange = new EventEmitter<boolean>();
    this.isDrawCrossEnabledChange = new EventEmitter<boolean>();
    this.isDrawPolygonEnabledChange = new EventEmitter<boolean>();
    this.rotationAngleChange = new EventEmitter<number>();
  }

  ngOnInit(): void {
    this.rotationAngle = this.rotationAngle || 0;
    this.modes = models.PlanViewerMode;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.mode &&
      changes.mode.previousValue !== changes.mode.currentValue
    ) {
      this.setIsDragEnabled(true);
      this.setIsDrawCircleEnabled(false);
      this.setIsDrawRectEnabled(false);
      this.setIsDrawCrossEnabled(false);
      this.setIsDrawPolygonEnabled(false);
    }
  }

  setIsDragEnabled(isDragEnabled: boolean): void {
    if (isDragEnabled) {
      this._dropAllSelections();
    }

    this.isDragEnabled = isDragEnabled;
    this.isDragEnabledChange.emit(isDragEnabled);
  }

  setIsDrawCircleEnabled(isDrawCircleEnabled: boolean): void {
    if (isDrawCircleEnabled) {
      this._dropAllSelections();
    }

    this.isDrawCircleEnabled = isDrawCircleEnabled;
    this.isDrawCircleEnabledChange.emit(isDrawCircleEnabled);
  }

  setIsDrawRectEnabled(isDrawRectEnabled: boolean): void {
    if (isDrawRectEnabled) {
      this._dropAllSelections();
    }

    this.isDrawRectEnabled = isDrawRectEnabled;
    this.isDrawRectEnabledChange.emit(isDrawRectEnabled);
  }

  setIsDrawCrossEnabled(isDrawCrossEnabled: boolean): void {
    if (isDrawCrossEnabled) {
      this._dropAllSelections();
    }

    this.isDrawCrossEnabled = isDrawCrossEnabled;
    this.isDrawCrossEnabledChange.emit(isDrawCrossEnabled);
  }

  setIsDrawPolygonEnabled(isDrawPolygonEnabled: boolean): void {
    if (isDrawPolygonEnabled) {
      this._dropAllSelections();
    }

    this.isDrawPolygonEnabled = isDrawPolygonEnabled;
    this.isDrawPolygonEnabledChange.emit(isDrawPolygonEnabled);
  }

  rotateLeft(): void {
    this.rotationAngle = this._getRotationAngle(-90);
    this.rotationAngleChange.emit(this.rotationAngle);
  }

  rotateRight(): void {
    this.rotationAngle = this._getRotationAngle(90);
    this.rotationAngleChange.emit(this.rotationAngle);
  }

  private _getRotationAngle(angle: number): number {
    let nextRotationAngle = this.rotationAngle;

    nextRotationAngle += angle;

    if (nextRotationAngle >= 360) {
      nextRotationAngle -= 360;
    }

    if (nextRotationAngle < 0) {
      nextRotationAngle += 360;
    }

    return nextRotationAngle;
  }

  private _dropAllSelections(): void {
    this.setIsDragEnabled(false);
    this.setIsDrawCircleEnabled(false);
    this.setIsDrawRectEnabled(false);
    this.setIsDrawCrossEnabled(false);
    this.setIsDrawPolygonEnabled(false);
  }
}
