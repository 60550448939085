import * as models from '@statera/sdk/common';

import {
  AlertIntervalType,
  NotificationsDisplay,
  EmailNotifications,
  MessengerEmailNotifications,
  MessengerSmsNotifications
} from '@statera/sdk/common';
export {
  AlertIntervalType,
  NotificationsDisplay,
  EmailNotifications,
  MessengerSmsNotifications,
  MessengerEmailNotifications
};

export type User = models.IProfileViewModel;
export type AlertPreferences = models.IAlertsPreferenceViewModel;

export interface AlertPreferenceOption {
  type: models.AlertIntervalType;
  value: number;
  displayValue: AlertPreferenceOptionDisplayValue;
}

export enum AlertPreferenceOptionDisplayValue {
  OneWeek = 'One Week',
  ThreeWeeks = 'Three Weeks',
  SixWeeks = 'Six Weeks',
  OneMonth = 'One Month',
  TwoMonths = 'Two Months',
  ThreeMonths = 'Three Months',
  SixMonths = 'Six Months',
  NineMonths = 'Nine Months',
  TenMonths = 'Ten Months',
  ElevenMonths = 'Eleven Months',
  OneYear = 'One Year',
  Off = 'Off',
}
