<dx-popup width="95%" height="90%"
          [maxWidth]="1200" [showCloseButton]="true" [visible]="popupVisible" [dragEnabled]="false"
          [resizeEnabled]="true"
          (visibleChange)="handleVisibilityChange($event)"
          (onShown)="onShown($event)" (onResizeEnd)="onResizeEnd($event)">
  <section class="lot-blobk"
           *ngIf="popupVisible && letterOfIntent">
    <dx-scroll-view width="100%"
                    [showScrollbar]="'always'" [scrollByContent]="true" [(height)]="height"
                    #scrollView>
      <pdf-viewer [src]="letterOfIntent.url" [style.height.px]="height" [render-text]="true" [original-size]="false"
                  (after-load-complete)="handlePDFLoadComplete()">
      </pdf-viewer>
    </dx-scroll-view>

    <div class="text-center">
      <ng-container *ngIf="showSignButton">
        <button class="btn btn-primary btn-lg" type="submit"
                (click)="generateSignDocument(signDocumentButton)"
                #signDocumentButton>
          Sign Document
        </button>
      </ng-container>
      <a class="btn btn-outline-primary"
         *ngIf="letterOfIntent"
         [href]="letterOfIntent?.url">
        Download PDF file
      </a>
    </div>
  </section>
</dx-popup>



