import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BuildingUnitRepository } from './building-unit.repository';

import * as models from './building-unit.model';

@Injectable()
export class BuildingUnitManager {
  private readonly _buildingUnitRepository: BuildingUnitRepository;

  constructor(buildingUnitRepository: BuildingUnitRepository) {
    this._buildingUnitRepository = buildingUnitRepository;
  }

  updateBuildingUnit(buildingUnit: models.BuildingUnitViewModel): Observable<void> {
    return this._buildingUnitRepository
      .updateBuildingUnit(buildingUnit);
  }

  createBuildingUnitFloorPlan(buildingUnitId: number, floorPlanImage: File): Observable<void> {
    return this._buildingUnitRepository
      .createBuildingUnitFloorPlan(buildingUnitId, floorPlanImage);
  }

  deleteBuildingUnitFloorPlan(buildingUnitId: number, floorPlanId: number): Observable<void> {
    return this._buildingUnitRepository
      .deleteBuildingUnitFloorPlan(buildingUnitId, floorPlanId);
  }

  createBuildingUnitPicture(buildingUnitId: number, picture: File): Observable<void> {
    return this._buildingUnitRepository
      .createBuildingUnitPicture(buildingUnitId, picture);
  }

  createBuildingUnitVideo(buildingUnitId: number, video: File): Observable<void> {
    return this._buildingUnitRepository
      .createBuildingUnitVideo(buildingUnitId, video);
  }

  createBuildingUnitLink(buildingUnitId: number, url: string): Observable<void> {
    return this._buildingUnitRepository
      .createBuildingUnitLink(buildingUnitId, url);
  }

  deleteBuildingUnitAttachment(buildingUnitId: number, attachmentId: number): Observable<void> {
    return this._buildingUnitRepository
        .deleteBuildingUnitAttachment(buildingUnitId, attachmentId);
  }
}
