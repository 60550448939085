import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LeaseRequestRepository} from './lease-request.repository';

import * as models from './lease-request.model';

@Injectable()
export class LeaseRequestManager {
  private readonly _leaseRequestRepository: LeaseRequestRepository;

  constructor(leaseRequestRepository: LeaseRequestRepository) {
    this._leaseRequestRepository = leaseRequestRepository;
  }

  createFinancialRequest(leaseId: number, request: models.FinancialRequest): Observable<models.LeaseRequest> {
    return this._leaseRequestRepository
      .createFinancialRequest(leaseId, request);
  }

  getFinancialRequests(leaseId: number) {
    return this._leaseRequestRepository
      .getFinancialRequests(leaseId);
  }
}
