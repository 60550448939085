import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPopupModule } from 'devextreme-angular';

import { DialogRefService } from './services/dialog-ref.service';
import { DialogService } from './services/dialog.service';

import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';

@NgModule({
  imports: [
    CommonModule,
    DxPopupModule,
  ],
  declarations: [
    DialogContainerComponent,
  ],
  providers: [
    DialogRefService,
    DialogService,
  ],
  entryComponents: [
    DialogContainerComponent,
  ],
})
export class DialogModule {
  static forChild(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [
        DialogRefService,
        DialogService,
      ],
    };
  }
}
