import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { TenantRepository } from './tenant.repository';
import { TenantManager } from './tenant.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    TenantRepository,
    TenantManager,
  ],
})
export class TenantModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TenantModule> {
    return {
      ngModule: TenantModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TenantRepository,
        TenantManager,
      ],
    };
  }
}
