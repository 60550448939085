<form name="form"
      (submit)="saveLeaseTerm($event)"
      *ngIf="isVisibleTermForm()">
  <div class="row"
       *ngIf="!isOption">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="term-container"
         *ngIf="isVisibleRadioGroup && !isLandlordTurn">
      <div class="radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="radioGroup | filter : 'disabled'"
                          [(value)]="selectedRadioGroup"
                          (onOptionChanged)="radioGroupChanged($event); clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>

    </div>

    <ng-container *ngIf="isVisibleTaxesRadioGroup() || isLandlordTurn">
      <div class="selectable-input-wrapper d-flex flex-row gap-4">
        <ng-container *ngIf="!canInsertNewOption() || (canInsertNewOption() && isOption)"
                      [ngSwitch]="selectedTax">
          <ng-container *ngSwitchCase="net">
            <app-input-field id="net" placeholder="(e.g. 5)" className="real-estate-input mb-0"
                             format="#,##0.00" width="68px" prefix="$"
                             errorMessage="Net value is required"
                             [min]="0" [step]="0" [max]="100000000"
                             [suffix]="getUnitMetricsDisplayValue()"
                             [validationCallback]="validateZeroValues"
                             [(value)]="leaseTerm.baseRentalRateNetValue"
                             (valueChange)="clearErrors()"
                             (focusIn)="handleFieldDxFieldFocus($event)"
                             (focusOut)="handleFieldDxFieldBlur($event)">
            </app-input-field>
          </ng-container>
          <ng-container *ngSwitchCase="gross">
            <app-input-field id="gross" placeholder="(e.g. 5)" className="real-estate-input mb-0"
                             format="#,##0.00" width="68px" prefix="$"
                             errorMessage="Gross value is required"
                             [min]="0" [step]="0" [max]="100000000"
                             [suffix]="getUnitMetricsDisplayValue()"
                             [validationCallback]="validateZeroValues"
                             [(value)]="leaseTerm.baseRentalRateGrossValue"
                             (valueChange)="clearErrors()"
                             (focusIn)="handleFieldDxFieldFocus($event)"
                             (focusOut)="handleFieldDxFieldBlur($event)">
            </app-input-field>
          </ng-container>
          <ng-container *ngSwitchCase="baseYear">
            <app-input-field id="actualYearRate" placeholder="(e.g. 5)" className="real-estate-input mb-0"
                             format="#,##0.00" width="68px" prefix="$"
                             errorMessage="Base rent is required"
                             [min]="0" [step]="0" [max]="100000000"
                             [suffix]="getUnitMetricsDisplayValue()"
                             [validationCallback]="validateZeroValues"
                             [(value)]="leaseTerm.actualYearRate"
                             (valueChange)="clearErrors()"
                             (focusIn)="handleFieldDxFieldFocus($event)"
                             (focusOut)="handleFieldDxFieldBlur($event)">
            </app-input-field>
          </ng-container>
        </ng-container>

        <dx-select-box displayExpr="name" valueExpr="value"
                       class="real-estate-input"
                       [class.base-rental-type-standalone]="canInsertNewOption() && !isOption"
                       placeholder="Select Rental Structure"
                       [disabled]="canInsertNewOption() && isOption"
                       [inputAttr]="{ 'aria-label': 'Select Rental Structure' }"
                       [dataSource]="taxesRadioGroup"
                       [(value)]="selectedTax"
                       (onValueChanged)="taxesRadioGroupChanged($event)"
                       (valueChange)="clearErrors()">
        </dx-select-box>
      </div>
    </ng-container>
    <ng-container *ngIf="canInsertNewOption()">
      <ng-container *ngFor="let termOption of leaseTerm?.leaseTermOptions?.options; let i = index ">
        <ng-container *ngIf="leaseTerm?.leaseTermOptions?.options[i].baseRentalRateType">
          <div class="row">
            <label>
              OPTION {{getOptionName(i)}}
            </label>
          </div>
          <div class="row">
            <app-base-rental-rate [isOption]="true" [project]="project" [lease]="lease"
                                  [(leaseTerm)]="leaseTerm?.leaseTermOptions?.options[i]">
            </app-base-rental-rate>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Start: Validation summary -->
    <div class="term-toast term-toast-neutral"
         *ngIf="isTypeDifferentFromOriginalOne()">
      <i inlineSVG="assets/img/information.svg"></i>
      {{getMessageAboutDifferentTypesOfBaseRentalRate()}}
    </div>

    <div class="term-toast term-toast-neutral"
         *ngIf="!this.isOption && isUnitMetricsDifferentFromOriginal()">
      <i inlineSVG="assets/img/information.svg"></i>
      This structure is different from the current lease, please adjust Real Estate Taxes and CAM Expenses term before sending Offer.
    </div>


      <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>

    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div *ngIf="!isOption">
        <div class="dx-fieldset">
          <dx-button id="button" class="b-btn b-btn--small b-btn-primary btn" text="Save" type="success"
                     *appCheckAccess="StateraClaimType.Collabo; stateraClaimValue: StateraClaimValue.Write; leaseId:lease.id"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" class="b-btn b-btn--small b-btn-primary btn" text="Cancel" type="cancel"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<div class="term-toast term-toast-information"
     *ngIf="isTermWillBeAddressedInFollowingStages()">
  <i inlineSVG="assets/img/information.svg"></i>
  This Term will be addressed in the following Stage(s).
</div>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
