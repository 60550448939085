import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { UserRepository } from './user.repository';
import { UserManager } from './user.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    UserRepository,
    UserManager,
  ],
})
export class UserModule {
  static forChild(configuration: Configuration): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        UserManager,
        UserRepository,
      ],
    };
  }
}
