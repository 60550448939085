import { Injectable } from '@angular/core';

import { StorageRepository } from './storage.repository';

@Injectable()
export class StorageManager {
  private readonly _storageRepository: StorageRepository;

  constructor(storageRepository: StorageRepository) {
    this._storageRepository = storageRepository;
  }

  set<T>(key: string, value: T): void {
    return this._storageRepository.set<T>(key, value);
  }

  get<T>(key: string): T {
    return this._storageRepository.get<T>(key);
  }

  has(key: string): boolean {
    return this._storageRepository.has(key);
  }

  delete(key: string): void {
    return this._storageRepository.delete(key);
  }
}
