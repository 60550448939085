<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">

    <div class="wrapper">
      <h1 class="broker-invitation-title">
        <span>
          Broker invitation
        </span>
        <img src="/assets/img/add-member.png" alt="Add member icon"/>
      </h1>
    </div>
    <div class="dx-fieldset">
      Statera has the necessary tools to automate and guide you through all of
      the negotiation steps with ease.
      However, if you would like to include a Broker as part of the process, you can choose to do so by inviting them
      via email below.<br/>
      <br/>
      To include a Broker, type their email and information below to invite them.
      We will send them a unique invite link allowing them to sign up and participate in this negotiation
      process.
    </div>

    <div class="dx-fieldset">
      <div class="row">
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              First name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="firstName" id="firstName" required
                           [isValid]="form.submitted ? firstName.valid : true"
                           [(ngModel)]="userInvitationRequest.firstName"
                           #firstName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && firstName.hasError('required')">
                First name is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="dx-field">
            <div class="dx-field-label">
              Last name
            </div>
            <div class="dx-field-value">
              <dx-text-box name="lastName" id="lastName" required
                           [isValid]="form.submitted ? lastName.valid : true"
                           [(ngModel)]="userInvitationRequest.lastName"
                           #lastName="ngModel">
              </dx-text-box>
              <div class="my-1 invalid-feedback d-block"
                   *ngIf="form.submitted && lastName.hasError('required')">
                Last name is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Company name
        </div>
        <div class="dx-field-value">
          <dx-select-box name="company" id="company" displayExpr="name" required
                         placeholder="" itemTemplate="listItem"
                         noDataText="No companies found matching your criteria"
                         [dataSource]="companiesDataSource" [acceptCustomValue]="true"
                         [searchEnabled]="true" [showDropDownButton]="false"
                         [isValid]="form.submitted ? company.valid : true"
                         [ngModel]="userInvitationRequest.company"
                         (ngModelChange)="handleCompanyChange($event)"
                         (onCustomItemCreating)="handleCustomCompanyCreating($event)"
                         #company="ngModel">
            <ng-container *dxTemplate="let company of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img width="40" height="40" style="object-fit: cover"
                       [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                       [alt]="company.name"/>
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && company.hasError('required')">
            Company name is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Email address
        </div>
        <div class="dx-field-value">
          <dx-text-box name="emailAddress" id="emailAddress" required
                       [isValid]="form.submitted ? emailAddress.valid : true"
                       [(ngModel)]="userInvitationRequest.emailAddress"
                       #emailAddress="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('required')">
            Email address is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" class="btn btn-primary broker-invitation-submit-button"
                     width="100%" icon="plus" text="Invite"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" class="btn btn-outline-secondary broker-invitation-submit-button"
                     text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
