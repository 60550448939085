import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as moment from 'moment';

import { DialogRefService } from '../../../../../../dialog/services/dialog-ref.service';

import * as models from '../../../../../../infrastructure/models/generated';
import { toLocalDate } from '../../../../../../infrastructure/models/local-date.model';

import {SecurityDepositTermType} from '../../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-security-deposit-burn-down-schedule-table',
  templateUrl: './security-deposit-burn-down-schedule-table.component.html',
  styleUrls: ['./security-deposit-burn-down-schedule-table.component.scss']
})
export class SecurityDepositBurnDownScheduleTableComponent implements OnInit {
  @ViewChild(NgForm) form: NgForm;

  @Input() lease: models.ILeaseViewModel;
  @Input() securityDepositTerm: models.ISecurityDepositTermViewModel;

  burnDownScheduleValues: Array<models.IBurnDownScheduleViewModel> = [];
  SecurityDepositTermType = models.SecurityDepositTermType;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit() {
    if (
      this.securityDepositTerm &&
      this.securityDepositTerm.burnDownScheduleValues &&
      this.securityDepositTerm.burnDownScheduleValues.length > 0
    ) {
      this.burnDownScheduleValues = JSON.parse(JSON.stringify(this.securityDepositTerm.burnDownScheduleValues));
    } else {
      this.addBurnDownValue();
    }
  }

  submit() {
    if (!this.form || this.form.invalid || !this.isBurnDownScheduleValid()) {
      return;
    }

    this.securityDepositTerm.burnDownScheduleValues = this.burnDownScheduleValues;

    this._dialogRefService.outputData = {
      burnDownScheduleValues: this.burnDownScheduleValues.map(x => {
        return <models.IBurnDownScheduleViewModel>{
          dateOfChange: toLocalDate(x.dateOfChange),
          dollarAmount: x.dollarAmount,
        };
      }),
    };
    this._dialogRefService.hide();
  }

  close() {
    this._dialogRefService.hide();
  }

  addBurnDownValue() {
    let dollarAmount = 0;
    if (this.lease.securityDepositTerm?.securityDepositTermType === SecurityDepositTermType.LetterOfCredit) {
      dollarAmount = this.lease.securityDepositTerm?.amountOfLetterCredit ?? 0;
    }
    if (this.lease.securityDepositTerm?.securityDepositTermType === SecurityDepositTermType.ReducingValue) {
      dollarAmount = this.lease.securityDepositTerm?.securityDepositMoneyValue ?? 0;
    }
    this.burnDownScheduleValues.push(<models.IBurnDownScheduleViewModel>{
      leaseMonth: 1,
      dollarAmount: dollarAmount,
      dateOfChange: this.lease.commencementTerm?.commencement
    });
  }

  removeBurnDownValue(index: number) {
    this.burnDownScheduleValues.splice(index, 1);
  }

  removeAllBurnDownValues() {
    this.burnDownScheduleValues = [
      <models.IBurnDownScheduleViewModel>{},
    ];
  }

  canShowPreview(): boolean {
    return (
      0 < this.burnDownScheduleValues.length &&
      0 < this.burnDownScheduleValues.filter(x => x.dollarAmount || x.dateOfChange).length
    );
  }

  isBurnDownScheduleValid(): boolean {
    return !this.getBurnDownScheduleErrorMessages().length;
  }

  getBurnDownScheduleErrorMessages(): Array<string> {
    const errors = [];

    if (!this.form.submitted) {
      return errors;
    }

    if (!this.burnDownScheduleValues || !this.burnDownScheduleValues.length) {
      errors.push('Please fill in Burn Down Schedule');
      return errors;
    }

    if (this.burnDownScheduleValues.some(x => typeof x.dollarAmount !== 'number' || !x.dateOfChange)) {
      errors.push('Please check your Burn Down Schedule, something is wrong');
      return errors;
    }

    return errors;
  }

  calculateIncreaseDate(leaseMonth: number, index: number): void {
    if (leaseMonth <= 0 || !this.burnDownScheduleValues || !this.lease || !this.lease.commencementTerm) {
      return;
    }

    const commencementDate = this.lease.commencementTerm.commencement;
    if (!commencementDate) {
      return;
    }
    const expirationDate = this.lease.calculatedExpirationDate;

    if (!expirationDate) {
      return;
    }

    this.burnDownScheduleValues[index].dateOfChange = moment(commencementDate)
      .startOf('day')
      .add(leaseMonth - 1, 'months')
      .format('YYYY-MM-DD');
  }

  reorderCustomValues(): void {
    if (!this.burnDownScheduleValues) {
      return;
    }

    this.burnDownScheduleValues = this.burnDownScheduleValues.sort((a, b) => a.leaseMonth - b.leaseMonth);
  }

  getMaxLeaseMonth() {
    const commencementDate = this.lease.commencementTerm.commencement;
    if (!commencementDate) {
      return 0;
    }
    const expirationDate = this.lease.calculatedExpirationDate;

    if (!expirationDate) {
      return 0;
    }
    return Math.round(moment(expirationDate).diff(moment(commencementDate), 'months', true));
  }
}
