import { Observable } from 'rxjs';
import { AnchorCommentRepository } from './anchor-comment.repository';

import {Injectable} from '@angular/core';
import {IPlanAnchorCommentViewModel} from '@statera/sdk/common';

@Injectable()
export class AnchorCommentManager {
  private readonly _anchorCommentRepository: AnchorCommentRepository;

  constructor(
    anchorCommentRepository: AnchorCommentRepository
  ) {
    this._anchorCommentRepository = anchorCommentRepository;
  }

  addMessage(message: IPlanAnchorCommentViewModel, anchorId: number, token?: string): Observable<IPlanAnchorCommentViewModel> {
    return this._anchorCommentRepository
      .addMessage(
        {
          ...message,
          anchorId: anchorId,
        },
        token
      );
  }

  updateMessage(message: IPlanAnchorCommentViewModel, anchorId: number): Observable<IPlanAnchorCommentViewModel> {
    return this._anchorCommentRepository
      .updateMessage(
        {
          ...message,
          anchorId: anchorId,
        },
      );
  }

  getMessages(anchorId: number, token?: string): Observable<Array<IPlanAnchorCommentViewModel>> {
    return this._anchorCommentRepository
      .getMessages(
        anchorId,
        null,
        null,
        token
      );
  }
}
