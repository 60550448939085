import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import moment from 'moment/moment';

import { MessageManager } from '../message.manager';

import { ChatMessageRepository } from './chat-message.repository';

import * as models from './chat-message.model';

export class ChatMessageManager extends MessageManager<models.ChatMessage> {
  private readonly _startupInfo: models.StartupInfo;
  private readonly _chatChannelId: number;
  private readonly _lease: models.Lease;

  private readonly _chatMessageRepository: ChatMessageRepository;

  constructor(
    chatMessageRepository: ChatMessageRepository,
    startupInfo: models.StartupInfo,
    chatChannelId: number,
    lease: models.Lease,
  ) {
    super();

    this._chatMessageRepository = chatMessageRepository;

    this._startupInfo = startupInfo;
    this._chatChannelId = chatChannelId;
    this._lease = lease;
  }

  getMessages(skip: number, take: number): Observable<Array<models.Message>> {
    return this._chatMessageRepository
      .getMessages(
        this._chatChannelId,
        skip,
        take,
      )
      .pipe(
        map(response => response.map(item => this.toMessage(item))),
        share(),
      );
  }

  addMessage(message: models.Message) {
    return this._chatMessageRepository
      .addMessage(
        this.fromMessage(message),
      )
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  updateMessage(message: models.Message): Observable<models.Message> {
    return this._chatMessageRepository
      .updateMessage(
        this.fromMessage(message),
      )
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  deleteMessage(message: models.Message): Observable<void> {
    return this._chatMessageRepository
      .deleteMessage(
        this.fromMessage(message),
      );
  }

  getChatProfiles(): Observable<Array<models.ChatProfile>> {
    return this._chatMessageRepository
      .getChatProfiles(
        this._chatChannelId,
      );
  }

  getCountUnreadMessages(): Observable<models.CountUnreadMessages> {
    return this._chatMessageRepository
      .getCountUnreadMessages(
        this._chatChannelId,
      );
  }

  markAllAsRead() {
    return this._chatMessageRepository
      .markAllAsRead(
        this._chatChannelId,
      );
  }

  toMessage(x: models.ChatMessage): models.Message {
    return <models.Message>{
      id: x.id,
      content: x.message,
      createdOn: x.createdOn,
      isMy: this._startupInfo.id === x.createdById,
      isRead: x.isRead,
      authorDisplayName: x.authorDisplayName,
      authorAvatarUrl: x.authorAvatarUrl ? x.authorAvatarUrl : 'assets/img/avatar.png',
      edited: this.isEdited(x),
      versionedFile: x.versionedFile,
      messageKind: x.messageKind,
      floorPlanAnchor: x.floorPlanAnchor,
      badge: this.getChatBadgeText(this._lease, x.createdById),
      isBrokerMessage: this.isBrokerMessage(this._lease, x.createdById),
    };
  }

  fromMessage(x: models.Message): models.ChatMessage {
    return <models.ChatMessage>{
      chatChannelId: this._chatChannelId,
      message: x.content,
      id: x.id,
      data: x.data,
    };
  }

  isEdited(x: models.ChatMessage) {
    try {
      return (
        x.createdOn &&
        x.updatedOn &&
        moment(x.updatedOn).isAfter(moment(x.createdOn))
      );
    } catch (e) {
      return false;
    }
  }
}
