<section class="w-auth w-auth--sing-in">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt=""/>
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">
        A balanced approach to the commercial real estate leasing process, unifying all parties during negotiations.
      </p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <h1 class="b-auth-title">
          Welcome to Statera!
        </h1><!--b-auth-title -->

        <form class="w-auth-form" name="form" novalidate
              #form="ngForm" (ngSubmit)="submit(form)">
          <div class="d-flex flex-column">
            <p class="b-label">
              SMS code
            </p>

            <input type="text" name="eml" id="eml"
                   class="b-input"
                   [class.is-invalid]="form.submitted && (codeInput.hasError('required'))"
                   required [minLength]="6"
                   [(ngModel)]="vm.code"
                   (ngModelChange)="error = null"
                   #codeInput="ngModel">

            <span class="error-msg two-fa-error-msg mt-1"
                  *ngIf="form.submitted && codeInput.hasError('required')">
              Required
            </span>

            <span class="error-msg two-fa-error-msg mt-1"
                  *ngIf="form.submitted && error">
              {{error}}
            </span>
          </div><!--d-flex-->

          <div class="d-flex flex-column align-items-center pt-4 w-100">
            <button class="btn btn-sign-in">
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="text-uppercase">
                Verify
              </span>
            </button>
          </div><!--d-flex-->
        </form>
        <div class="w-auth-controls text-center">
          <a class="b-fancy-link" routerLink="" (click)="signOut()">
            Signout
          </a>
        </div>

        <div class="w-auth-help-text">
          If you didn't receive the code

          <a class="b-fancy-link" routerLink="/2fa" (click)="resend2FACode($event)">
            Send Sms Again
          </a>
        </div><!--w-auth-help-text-->
      </div><!--login-content-->
    </section><!--login-->
  </div><!--w-auth-content-->
</section><!--w-auth-->

<app-footer></app-footer>

