import {Component, Input, OnInit} from '@angular/core';
import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-no-current-lease-provided-answer',
  templateUrl: './no-current-lease-provided-answer.component.html',
  styleUrls: ['./no-current-lease-provided-answer.component.scss']
})
export class NoCurrentLeaseProvidedAnswerComponent {

  @Input() lease: models.ILeaseViewModel;
}
