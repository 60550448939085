<div class="row"
     *ngIf="isAccepted || isLineThrough || userRole">
  <p class="font-weight-bold" [class.line-through]="isLineThrough"
     [class.landlord-changes]="userRole === 'Landlord'" [class.tenant-changes]="userRole === 'Tenant'">
    Security deposit burn down schedule:
  </p>
</div>
<div class="table-responsive"
     [class.with-margin]="isAccepted || isLineThrough || userRole">
  <table class="table">
    <thead>
    <tr>
      <th class="align-middle w-25" colspan="3">
        results preview:
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="align-middle w-25">Lease Month</td>
      <td class="align-middle w-25">Date Reduced</td>
      <td class="align-middle w-25">Security Deposit $ Amount</td>
    </tr>
    <tr *ngFor="let burnDownScheduleValue of burnDownScheduleValues; let index = index;"
        [class.changes-accepted]="isAccepted" [class.line-through]="isLineThrough"
        [class.landlord-changes]="userRole === 'Landlord'" [class.tenant-changes]="userRole === 'Tenant'">
      <!-- Start: Lease Month -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{burnDownScheduleValue.leaseMonth}}
        </p>
      </td>
      <!-- End: Date of Change -->
      <!-- Start: Date of Change -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{burnDownScheduleValue.dateOfChange | date: 'MM.dd.yy'}}
        </p>
      </td>
      <!-- End: Date of Change -->
      <!-- Start: Dollar Amount -->
      <td class="align-middle w-25">
        <p class="mb-0">
          {{burnDownScheduleValue.dollarAmount | safeCurrency}}
        </p>
      </td>
      <!-- End: Dollar Amount -->
    </tr>
    </tbody>
  </table>
</div>
