import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { CroppieDirective } from 'angular-croppie-module';
import * as Croppie from 'croppie';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { UserManager } from '@statera/sdk/user';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

interface DxFileUploaderOptions {
  name: string;
  uploadUrl: string;
  onUploaded: (e: { request: { responseText: string } }) => void;
}

@Component({
  templateUrl: './avatar-edit-dialog.component.html',
  styleUrls: ['./avatar-edit-dialog.component.scss']
})
export class AvatarEditDialogComponent implements OnInit, AfterViewInit {
  // angular-croppie-module CroppieDirective doesn't track input changes,
  // but it has methods to perform necessary actions instead.
  @ViewChild('croppie') croppieDirective: CroppieDirective;
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;

  croppieOptions: Croppie.CroppieOptions;
  uploaderOptions: DxFileUploaderOptions;
  orientation = 0;

  sourceUrl: string;
  canSave = false;

  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _userManager: UserManager;

  constructor(
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
    userManager: UserManager,
  ) {
    this._authService = authService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._userManager = userManager;
  }

  ngOnInit(): void {
    const croppieMaxWidth = 450;
    const croppieMaxHeight = 336;

    const ratio = croppieMaxHeight / croppieMaxWidth;

    let width;
    let height;

    if (window.innerWidth < window.innerHeight) {
      width = window.innerWidth > croppieMaxWidth ? croppieMaxWidth : window.innerWidth;
      height = width * ratio;
    } else {
      height = window.innerHeight - 110;
      width = height / ratio;
    }

    if (width > croppieMaxWidth) {
      width = croppieMaxWidth;
    }
    if (height > croppieMaxHeight) {
      height = croppieMaxHeight;
    }

    const viewportSize = Math.min(width, height) / 2;

    this.croppieOptions = {
      boundary: {
        width,
        height,
      },
      viewport: {
        width: viewportSize,
        height: viewportSize,
        type: 'circle',
      },
      showZoomer: true,
      enableOrientation: true,
      enforceBoundary: true,
    };

    this.sourceUrl = this._authService.avatarSourceUrl;

    this.uploaderOptions = {
      name: 'file',
      uploadUrl: environment.webApiUrl + '/storage',
      onUploaded: (e) => {
        this.sourceUrl = e.request.responseText;
        this.croppieDirective
          .croppie
          .bind({
            url: this.sourceUrl,
          });
        this.canSave = true;
      },
    };

    this.orientation = 0;
  }

  ngAfterViewInit() {
    if (
      this._authService.startupInfo &&
      this._authService.avatarSourceUrl !== this._authService.defaultSourceUrl
    ) {
      this.croppieDirective
        .croppie
        .bind(
          Object.assign(
            this._authService.startupInfo.avatarOptions,
            {
              url: this._authService.avatarSourceUrl,
            },
          ),
        );
    } else {
      this.croppieDirective
        .croppie
        .bind({
          url: this._authService.avatarSourceUrl,
        });
    }
  }

  handleUpdate(): void {
    this.canSave = !this.isCropDataEqual(
      this.croppieDirective.croppie.get(),
      this._authService.startupInfo.avatarOptions
    );
  }

  isCropDataEqual(one: Croppie.CropData, two: Croppie.CropData) {
    return (
      one && two &&
      one.orientation === two.orientation &&
      one.zoom === two.zoom &&
      one.points[0] * 1 === two.points[0] &&
      one.points[1] * 1 === two.points[1] &&
      one.points[2] * 1 === two.points[2] &&
      one.points[3] * 1 === two.points[3]
    );
  }

  onRotateClick(degree: number): void {
    // Croppie.js doesn't give us a way to get current image rotation, so we
    // have to track it manually.
    this.orientation = (this.orientation + (degree / 90)) % 4;

    this.croppieDirective
      .croppie
      .rotate(<90 | 180 | 270 | -90 | -180 | -270>degree);
  }

  onSelectClick(): void {
    // DxFileUploader does not support programmatic file select dialog triggers.
    (<any>this.fileUploader.instance)
      ._selectButton
      .element()
      .click();
  }

  onCloseClick(): void {
    this._dialogRefService.hide();
  }

  onSaveChangesClick(): void {
    if (this.canSave) {
      const avatarOptions = this.croppieDirective.croppie.get();
      avatarOptions.orientation = this.orientation;

      this._userManager
        .handleAvatarSave(this.sourceUrl, avatarOptions)
        .subscribe(info => {
          this._authService.startupInfo = info;
          this._authService.setStartupInfo(info);
          this._dialogRefService.hide();
          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getAvatarSavedAlertText(),
          });
        });
    } else {
      this._dialogRefService.hide();
    }
  }
}
