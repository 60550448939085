<div class="row mt-2 d-flex flex-row">
  <div class="d-flex align-items-center w-50">
    <ng-container *ngIf="isAcceptButtonVisible">
      <button type="button" class="btn b-btn-save b-btn-xl mr-4"
              [disabled]="isButtonsDisabled"
              (click)="handleAcceptButtonClick($event)">
        <span class="text-uppercase">
          Accept
        </span>
      </button>
    </ng-container>

    <ng-container *ngIf="isCounterButtonVisible">
      <button type="button" class="b-btn b-btn-cancel b-btn-xl"
              [disabled]="isButtonsDisabled"
              (click)="handleCounterButtonClick($event);">
        <span class="text-uppercase">
          Counter
        </span>
      </button>
      <ng-content select="[multipleOptionsView]"></ng-content>
    </ng-container>

  </div>

  <div class="d-flex align-items-center w-50 "
       *ngIf="isModifyButtonVisible">
    <button type="button" class="btn b-btn-save b-btn-xl mr-4 modify"
            [disabled]="isButtonsDisabled"
            (click)="handleModifyButtonClick($event);">
      <span class="text-uppercase">
        Modify
      </span>
    </button>
  </div>

  <div class="col-sm-3 col-12 mb-2 mr-4"
       *ngIf="isChooseOptionButtonVisible">
    <button type="button" class="b-btn b-btn--small b-btn-primary btn btn-block"
            [disabled]="isButtonsDisabled"
            (click)="handleChooseOptionButtonClick($event)">
      <span class="text-uppercase">
        Choose option
      </span>
    </button>
  </div>

  <div class="col-12 pl-0"
       *ngIf="isPreviousButtonVisible">
    <button type="button" class="b-btn--small btn previous-btn"
            [disabled]="isButtonsDisabled"
            (click)="handlePreviousButtonClick($event);">
      <span inlineSVG="/assets/img/renew.svg"></span>
      <span class="btn-text">
        Reset to Previous Response
      </span>
    </button>
  </div>

  <div class="col-sm-3 col-12"
       *ngIf="isOkButtonVisible">
    <button type="button" class="b-btn b-btn-save b-btn-xl ng-star-inserted"
            [disabled]="isButtonsDisabled"
            (click)="handleOkButtonClick($event);">
      <span class="text-uppercase">
        Ok
      </span>
    </button>
  </div>
</div>
