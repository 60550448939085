import * as models from '@statera/sdk/common';

export type LeaseAmendmentFile = models.ILeaseAmendmentFileViewModel;

export type VersionedFile = models.IVersionedFileViewModel;
export type VersionedFileCommentType = models.VersionedFileCommentType;
export const VersionedFileCommentType = models.LeaseTermType;

export type Profile = models.IProfileViewModel;
export type WhoSignLeaseAmendment = models.IWhoSignLeaseAmendmentViewModel;
