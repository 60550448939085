import { Injectable } from '@angular/core';

import * as models from './certificate-of-insurance.model';
import { CertificateOfInsuranceRepository } from './certificate-of-insurance.repository';

@Injectable()
export class CertificateOfInsuranceManager {
  private readonly _certificateOfInsuranceRepository: CertificateOfInsuranceRepository;
  constructor(certificateOfInsuranceRepository: CertificateOfInsuranceRepository) {
    this._certificateOfInsuranceRepository = certificateOfInsuranceRepository;
  }

  getCertificateOfInsuranceByLeaseId(leaseId: number) {
    return this._certificateOfInsuranceRepository.getCertificateOfInsuranceByLeaseId(leaseId);
  }

  isValidInsuranceCertificate(insuranceCertificate: models.InsuranceCertificate): boolean {
    if (!insuranceCertificate) {
      return false;
    }

    if (insuranceCertificate.hasWorkersCompensation &&
      (!insuranceCertificate.diseasePolicyLimit
        || !insuranceCertificate.diseaseValueForEachAccident || !insuranceCertificate.diseaseValueForEachEmployee)) {
      return false;
    }

    return true;
  }

  isGeneralLiabilityTotalsRequired(insuranceCertificate: models.InsuranceCertificate): boolean {
    if (!insuranceCertificate || !insuranceCertificate.hasGeneralLiability) {
      return false;
    }

    const fields = [
      insuranceCertificate.generalLiabilityCoverageLevelEachOccurrence,
      insuranceCertificate.damageToRentedPremises,
      insuranceCertificate.medExp,
      insuranceCertificate.personalAndAdvInjury,
      insuranceCertificate.generalAggregate,
      insuranceCertificate.productsCompOpAgg,
    ];

    return fields.every(x => !x);
  }

  isAutoLiabilityTotalsRequired(insuranceCertificate: models.InsuranceCertificate): boolean {
    if (!insuranceCertificate || !insuranceCertificate.hasAutoLiability) {
      return false;
    }

    const fields = [
      insuranceCertificate.combinedSingleLimit,
      insuranceCertificate.bodilyInjuryPerPerson,
      insuranceCertificate.bodilyInjuryPerAccident,
      insuranceCertificate.propertyDamage,
    ];

    return fields.every(x => !x);
  }

  isUmbrellaLiabilityTotalsRequired(insuranceCertificate: models.InsuranceCertificate): boolean {
    if (!insuranceCertificate || !insuranceCertificate.hasUmbrellaLiability) {
      return false;
    }

    const fields = [
      insuranceCertificate.umbrellaLiabilityCoverageLevelEachOccurrence,
      insuranceCertificate.umbrellaLiabilityCoverageLevelAggregate,
    ];

    return fields.every(x => !x);
  }

  isWorkersCompensationTotalsRequired(insuranceCertificate: models.InsuranceCertificate): boolean {
    if (!insuranceCertificate || !insuranceCertificate.hasWorkersCompensation) {
      return false;
    }

    const fields = [
      insuranceCertificate.diseaseValueForEachAccident,
      insuranceCertificate.diseaseValueForEachEmployee,
      insuranceCertificate.diseasePolicyLimit,
    ];

    return fields.every(x => !x);
  }
}
