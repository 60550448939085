import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'rejection-reason-dialog.component.html',
  styleUrls: ['rejection-reason-dialog.component.scss'],
})
export class RejectionReasonDialogComponent implements OnInit {
  private static readonly _projectRenewalRejectionStatuses = [
    {
      name: 'Too Early',
      value: models.ProjectRenewalRejectionStatus.TooEarly,
    },
    {
      name: 'Renewal is not offered',
      value: models.ProjectRenewalRejectionStatus.RenewalIsNotOffered,
    },
    {
      name: 'Other Tenant(s) have interest in the space.',
      value: models.ProjectRenewalRejectionStatus.OtherTenantInterestedIn,
    },
  ];

  @ViewChild('form', { read: NgForm }) form: NgForm;

  rejectionLease: models.IRejectionLeaseViewModel;
  projectRenewalRejectionStatuses: Array<{name: string, value: models.ProjectRenewalRejectionStatus}>;

  ProjectRenewalRejectionStatus: typeof models.ProjectRenewalRejectionStatus;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit(): void {
    this.rejectionLease = this.rejectionLease || <models.IRejectionLeaseViewModel>{};
    this.projectRenewalRejectionStatuses = RejectionReasonDialogComponent._projectRenewalRejectionStatuses;
    this.ProjectRenewalRejectionStatus = models.ProjectRenewalRejectionStatus;
  }

  isDateDisabled(model: {date: Date}): boolean {
    if (!model || !model.date) {
      return true;
    }

    const today = moment().startOf('day');
    const date = moment(model.date).startOf('day');

    if (today.isSame(date)) {
      return false;
    }

    return today.isAfter(date);
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    this._dialogRefService.outputData = {
      rejectionLease: this.rejectionLease,
    };

    this.close();
  }

  close(): void {
    if (!this._dialogRefService) {
      return;
    }

    this._dialogRefService.hide();
  }
}
