<section class="w-auth w-auth--sing-in">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt=""/>
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">
        A balanced approach to the commercial real estate leasing process, unifying all parties during negotiations.
      </p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <ng-container *ngIf="(userInvitationInfo$ | async) as userInvitationInfo">
          <h1 class="b-auth-title mb-0">
            Welcome {{userInvitationInfo.shouldResetPassword ? 'to Statera!' : 'Back!'}}
          </h1><!--b-auth-title -->

          <div class="mt-3 mb-4">
            <p class="mb-3">
              <ng-container [ngSwitch]="userInvitationInfo.userInvitationRequestKind">
                <ng-container *ngSwitchCase="UserInvitationRequestKinds.Lease">
                  Your invitation from {{getInvitationFromClause(userInvitationInfo)}}
                  to participate in the {{getLeaseProcessClause(userInvitationInfo)}}
                  process at {{userInvitationInfo.lease?.building?.address?.displayString}} awaits.
                </ng-container>
                <ng-container *ngSwitchCase="UserInvitationRequestKinds.Portfolio">
                  Your invitation from {{getInvitationFromClause(userInvitationInfo)}}
                  to be their representative for {{userInvitationInfo.portfolio?.name}} portfolio awaits.
                </ng-container>
                <ng-container *ngSwitchCase="UserInvitationRequestKinds.Building">
                  Your invitation from {{getInvitationFromClause(userInvitationInfo)}}
                  to be their representative for {{userInvitationInfo.building?.address?.displayString}} building awaits.
                </ng-container>
                <ng-container *ngSwitchDefault>
                  Your invitation from {{getInvitationFromClause(userInvitationInfo)}} awaits.
                </ng-container>
              </ng-container>
            </p>

            <p *ngIf="userInvitationInfo.shouldResetPassword; else shouldNotResetPasswordTpl">
              Let's start by setting up your password.
            </p>

            <ng-template #shouldNotResetPasswordTpl>
              <p>
                <ng-container *ngIf="isUserAuthenticated; else notAuthenticatedTpl">
                  <ng-container *ngIf="!isAutoAccept">
                    Please accept your invite to get started.
                  </ng-container>
                </ng-container>
                <ng-template #notAuthenticatedTpl>
                  Please login & accept your invite to get started.
                </ng-template>
              </p>
            </ng-template>
          </div>

          <form name="form" novalidate
                *ngIf="!isAutoAccept"
                (ngSubmit)="submit(form, userInvitationInfo)"
                #form="ngForm">
            <ng-container *ngIf="userInvitationInfo.shouldResetPassword; else authorizeTpl">
              <!-- Start: Password field -->
              <div class="d-flex flex-column mb-3">
                <p class="b-label">
                  Create Password
                </p>

                <input class="b-input" required type="password" name="password" id="password"
                       [class.is-invalid]="form.submitted && password.invalid"
                       [(ngModel)]="acceptInvitation.userPassword"
                       #password="ngModel">

                <span class="error-msg mt-1"
                      *ngIf="form.submitted && password.hasError('required')">
                  Password is required
                </span>
              </div>
              <!-- End: Password field -->
              <!-- Start: Repeat password field -->
              <div class="d-flex flex-column">
                <p class="b-label">
                  Confirm Password
                </p>

                <input class="b-input" required type="password" name="confirmPassword" id="confirmPassword"
                       [class.is-invalid]="form.submitted && (confirmPassword.invalid || acceptInvitation.confirmPassword !== acceptInvitation.userPassword)"
                       [(ngModel)]="acceptInvitation.confirmPassword"
                       #confirmPassword="ngModel">

                <span class="error-msg mt-1"
                      *ngIf="form.submitted && confirmPassword.hasError('required')">
                  Confirm password is required
                </span>

                <span class="error-msg mt-1"
                      *ngIf="form.submitted && acceptInvitation.confirmPassword !== acceptInvitation.userPassword">
                  The password and confirmation password do not match
                </span>
              </div>
              <!-- End: Repeat password field -->
            </ng-container>

            <ng-template #authorizeTpl>
              <ng-container *ngIf="!isUserAuthenticated">
                <!-- Start: Password field -->
                <div class="d-flex flex-column">
                  <p class="b-label">
                    Password
                  </p>

                  <input type="password" name="password" id="signin-password"
                         class="b-input"
                         [class.is-invalid]="form.submitted && passwordInput.invalid"
                         required
                         [(ngModel)]="acceptInvitation.userPassword"
                         (ngModelChange)="error = null"
                         #passwordInput="ngModel">

                  <span class="error-msg accept-invitation-error-msg mt-1"
                        *ngIf="form.submitted && passwordInput.hasError('required')">
                    Password is required
                  </span>

                  <span class="error-msg accept-invitation-error-msg mt-1"
                        *ngIf="form.submitted && error">
                    {{error}}
                  </span>
                </div>
                <!-- End: Password field -->
              </ng-container>
            </ng-template>

            <div class="d-flex flex-column align-items-center pt-4 w-100">
              <button class="btn btn-sign-in">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="text-uppercase">
                  Accept invitation
                </span>
              </button>
            </div>
          </form>
        </ng-container>

      </div><!--login-content-->
    </section><!--login-->
  </div><!--w-auth-content-->
</section><!--w-auth-->

<app-footer></app-footer>
