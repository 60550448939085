import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {of, Subject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {dxPopupOptions} from 'devextreme/ui/popup';

import {TermCustomValueTemplateManager} from '@statera/sdk/term-custom-value-template';

import {DialogService} from '../../../../dialog/services/dialog.service';

import {
  TermCustomValueTemplateDialogComponent,
  TermCustomValueTemplateDialogMode
} from '../term-custom-value-template-dialog/term-custom-value-template-dialog.component';
import {
  TermCustomValueTemplateUpsertDialogComponent
} from '../term-custom-value-template-upsert-dialog/term-custom-value-template-upsert-dialog.component';

import * as models from '../../../../infrastructure/models/generated';

enum DropdownItemKind {
  ChooseTemplate = 1,
  EditTemplateList = 2,
}

interface DropdownItem {
  kind?: DropdownItemKind;
  badge?: string;
  disabled?: boolean;
  html?: string;
  icon?: string;
  onClick?: Function | string;
  template?: any;
  text?: string;
  visible?: boolean;
}

@Component({
  selector: 'app-term-custom-value-template-textarea',
  templateUrl: './term-custom-value-template-textarea.component.html',
  styleUrls: ['./term-custom-value-template-textarea.component.scss'],
})
export class TermCustomValueTemplateTextareaComponent implements OnInit, OnDestroy {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() requiredMessage: string;
  @Input() className = '';
  @Input() leaseTermType: models.LeaseTermType;
  @Output() valueChange: EventEmitter<string>;

  moreDropdownOptions: dxPopupOptions;
  moreDropdownButtons: Array<any | DropdownItem>;

  private readonly _termCustomValueTemplateManager: TermCustomValueTemplateManager;
  private readonly _dialogService: DialogService;

  private readonly _destroy: Subject<void>;

  constructor(
    termCustomValueTemplateManager: TermCustomValueTemplateManager,
    dialogService: DialogService,
  ) {
    this._termCustomValueTemplateManager = termCustomValueTemplateManager;
    this._dialogService = dialogService;

    this._destroy = new Subject<void>();

    this.valueChange = new EventEmitter<string>();

    this.moreDropdownOptions = {
      width: 185,
      onShown: function (args) {
        args
          .component
          .content()
          .parentElement
          .parentElement
          .classList
          .add('custom-value-textarea-more-dropdown-overlay');
      },
    };
    this.moreDropdownButtons = [
      {
        kind: DropdownItemKind.ChooseTemplate,
        text: 'Choose from a template',
      },
      {
        kind: DropdownItemKind.EditTemplateList,
        text: 'Edit templates',
      },
    ];
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder || 'INSERT CUSTOM TEXT';
    this.required = typeof this.required !== 'undefined' && (typeof this.required === 'string' || !!this.required);
    this.requiredMessage = this.requiredMessage || 'This field is required';
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  handleValueChange(value: string): void {
    this.valueChange.emit(value);
  }

  handleTemplateButtonClick(): void {
    if (this.value) {
      this._openTermCustomValueTemplateUpsertDialog();
    } else {
      this._openTermCustomValueTemplateDialog(TermCustomValueTemplateDialogMode.ChooseTemplate);
    }
  }

  handleMoreDropDownButtonClick({itemData}: { itemData: DropdownItem }): void {
    switch (itemData.kind) {
      case DropdownItemKind.ChooseTemplate:
        this._openTermCustomValueTemplateDialog(TermCustomValueTemplateDialogMode.ChooseTemplate);
        break;
      case DropdownItemKind.EditTemplateList:
        this._openTermCustomValueTemplateDialog(TermCustomValueTemplateDialogMode.EditTemplateList);
        break;
      default:
        throw new Error('Unknown dropdown item kind');
    }
  }

  private _openTermCustomValueTemplateDialog(mode: TermCustomValueTemplateDialogMode): void {
    const dialogRef = this._dialogService
      .show(TermCustomValueTemplateDialogComponent, {
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 556,
        height: 'auto',
        maxHeight: '90%',
        injectableData: {
          mode: mode,
          leaseTermType: this.leaseTermType,
        },
      });

    dialogRef
      .onHiding
      .pipe(
        switchMap(() => {
          if (dialogRef.outputData?.selectedTemplate) {
            const template = dialogRef.outputData.selectedTemplate;

            template.usageCount += 1;

            this.valueChange.emit(template.content);

            return this._termCustomValueTemplateManager
              .update(template)
              .pipe(
                switchMap(() => (
                  this._termCustomValueTemplateManager.getList()
                )),
              );
          }

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  private _openTermCustomValueTemplateUpsertDialog(): void {
    const dialogRef = this._dialogService
      .show(TermCustomValueTemplateUpsertDialogComponent, {
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 556,
        height: 'auto',
        maxHeight: '90%',
        injectableData: {
          template: <models.ITermCustomValueTemplateViewModel>{
            content: this.value,
            leaseTermType: this.leaseTermType,
          },
        },
      });

    dialogRef
      .onHiding
      .pipe(
        switchMap(() => {
          if (dialogRef.outputData?.template) {
            dialogRef.outputData.usageCount = 1;

            this.valueChange.emit(dialogRef.outputData.template.content);

            return this._termCustomValueTemplateManager
              .create(dialogRef.outputData.template)
              .pipe(
                switchMap(() => (
                  this._termCustomValueTemplateManager.getList()
                )),
              );
          }

          return of(null);
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
