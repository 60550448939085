import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { alert } from 'devextreme/ui/dialog';

import { SelectItem } from '@statera/sdk/common';
import { DocumentAttachmentPlaces, LandlordManager } from '@statera/sdk/landlord';
import { LeaseManager } from '@statera/sdk/lease';

import { AlertService } from '../../../alert/services/alert.service';
import { DocumentsService } from '../../../shared/services/documents.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { AuthService } from '../../../auth/services/auth.service';
import { TenantPagesService } from '../../services/tenant-pages.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  leaseSelectItems: Array<SelectItem> = [];
  leaseId: number;
  selectedRowKeys: Array<any> = [];
  documents: Array<models.IAttachedFileViewModel>;
  mode: DocumentAttachmentPlaces;

  private readonly _documentsService: DocumentsService;
  private readonly _tenantPagesService: TenantPagesService;
  private readonly _alertService: AlertService;
  private readonly _router: Router;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _destroy$: Subject<void>;
  private readonly _leaseService: LeaseService;
  private readonly _leaseManager: LeaseManager;
  private readonly _authService: AuthService;
  private readonly _landlordManager: LandlordManager;

  constructor(
    documentsService: DocumentsService,
    alertService: AlertService, router: Router, activatedRoute: ActivatedRoute,
    tenantPageService: TenantPagesService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    authService: AuthService,
    landlordManager: LandlordManager
  ) {
    this._documentsService = documentsService;
    this._alertService = alertService;
    this._router = router;
    this._activatedRoute = activatedRoute;
    this._leaseService = leaseService;
    this._tenantPagesService = tenantPageService;
    this._leaseManager = leaseManager;
    this._authService = authService;
    this._landlordManager = landlordManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._loadData();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  selectLease(e) {
    this.leaseId = e.value;

    this._router
      .navigate([], {queryParams: {leaseId: e.value}});
  }

  private _loadData() {
    const queryParamsObservable = this._activatedRoute.queryParams;
    const getLeasesObservable = this._leaseService.getLeases(true, true);
    combineLatest([queryParamsObservable, getLeasesObservable])
      .pipe(
        tap(params => {
          const queryParams = params[0];
          const leases = params[1];
          if (leases && leases.length) {
            this.leaseSelectItems = leases.map(x => (<SelectItem>{
              id: x.id,
              text: this._tenantPagesService.getLeaseDisplayName(x),
            }));
            if (this.leaseSelectItems.length) {
              if (queryParams && queryParams.leaseId) {
                try {
                  this.leaseId = parseInt(queryParams.leaseId, 10) || this.leaseSelectItems[0].id;
                } catch (e) {
                  this.leaseId = this.leaseSelectItems[0].id;
                }
              } else {
                this.leaseId = this.leaseSelectItems[0].id;
              }
              this.mode = this._getMode(leases);
              this._loadDocuments(this.mode);
            } else {
              alert('You do not have leases', 'No leases');
            }
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private _getMode(leases: Array<models.ILeaseViewModel>): DocumentAttachmentPlaces {
    const lease = leases.find(x => x.id === this.leaseId);
    const leaseTeam = this._leaseManager
      .getUserLeaseTeam(lease, this._authService.userId, this._authService.role);
    switch (leaseTeam) {
      case models.LeaseTeam.TenantTeam:
        return DocumentAttachmentPlaces.TenantFiles;
      case models.LeaseTeam.LandlordTeam:
        return DocumentAttachmentPlaces.BuildingTenant;
      default:
        return DocumentAttachmentPlaces.TenantFiles;
    }
  }

  private _loadDocuments(mode: DocumentAttachmentPlaces): void {
    if (mode === DocumentAttachmentPlaces.TenantFiles) {
      this._documentsService
        .getDocumentByLeaseId(this.leaseId)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(x => this.documents = x);
    }

    if (mode === DocumentAttachmentPlaces.BuildingTenant) {
      this._landlordManager
        .getLeaseFiles(this.leaseId)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(x => this.documents = x);
    }
  }
}
