import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LandlordManager } from '@statera/sdk/landlord';
import { TermManager } from '@statera/sdk/term';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-brief-deal-summary',
  templateUrl: './brief-deal-summary.component.html',
  styleUrls: ['./brief-deal-summary.component.scss']
})
export class BriefDealSummaryComponent implements OnInit, OnDestroy {
  @Input() leaseId: number;

  briefDealSummaries: Observable<Array<models.IBriefDealSummaryViewModel>>;

  private readonly _landlordManager: LandlordManager;
  private readonly _termManager: TermManager;
  private _destroy = new Subject();
  maxStageCount = 5;

  constructor(landlordManager: LandlordManager, termManager: TermManager) {
    this._landlordManager = landlordManager;
    this._termManager = termManager;

    this.getCurrentTerm = this.getCurrentTerm.bind(this);
    this.getCurrentTermNumber = this.getCurrentTermNumber.bind(this);
    this.getCurrentFreeRent = this.getCurrentFreeRent.bind(this);
    this.getCurrentFreeRentNumber = this.getCurrentFreeRentNumber.bind(this);
    this.getCurrentTenantSquareFootage = this.getCurrentTenantSquareFootage.bind(this);
    this.getCurrentTenantSquareFootageNumber = this.getCurrentTenantSquareFootageNumber.bind(this);
    this.getCurrentRentalRate = this.getCurrentRentalRate.bind(this);
    this.getCurrentRentalRateNumber = this.getCurrentRentalRateNumber.bind(this);
    this.getCurrentTenantImprovements = this.getCurrentTenantImprovements.bind(this);
    this.getCurrentTenantImprovementsNumber = this.getCurrentTenantImprovementsNumber.bind(this);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
    this.briefDealSummaries = this._landlordManager
      .getBriefDealSummaries(this.leaseId)
      .pipe(
        map(list => {
          if ((list?.length ?? 0) < 2) {
            return list;
          }

          let prev = list[0];
          const result: Array<models.IBriefDealSummaryViewModel> = [prev];
          for (let i = 1; i < list.length; i++) {
            if (!this._areProposalsEqual(list[i], prev)) {
              result.push(list[i]);
            }
            prev = list[i];
          }

          return result;
        }),
        takeUntil(this._destroy),
      );
  }

  getCurrentTerm(deal: models.IBriefDealSummaryViewModel): string {
    if (!deal) {
      return 'N/A';
    }

    return (
      this._termManager.getCurrentTerm(deal.term) +
      (deal.term.isModifiedValue ? ' [modified]' : '')
    );
  }

  getCurrentTermNumber(deal: models.IBriefDealSummaryViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentTermNumber(deal.term);
  }

  getCurrentFreeRent(deal: models.IBriefDealSummaryViewModel): string {
    if (!deal) {
      return 'N/A';
    }

    return (
      this._termManager.getCurrentFreeRent(deal.freeRentTerm) +
      (deal.freeRentTerm.isModifiedValue ? ' [modified]' : '')
    );
  }

  getCurrentFreeRentNumber(deal: models.IBriefDealSummaryViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentFreeRentNumber(deal.freeRentTerm);
  }

  getCurrentTenantSquareFootage(deal: models.IBriefDealSummaryViewModel): string {
    if (!deal) {
      return 'N/A';
    }

    return (
      this._termManager.getCurrentTenantSquareFootage(deal.tenantSquareFootageTerm) +
      (deal.tenantSquareFootageTerm.isModifiedValue ? ' [modified]' : '')
    );
  }

  getCurrentTenantSquareFootageNumber(deal: models.IBriefDealSummaryViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentTenantSquareFootageNumber(deal.tenantSquareFootageTerm);
  }

  getCurrentRentalRate(deal: models.IBriefDealSummaryViewModel): string {
    if (!deal) {
      return '$0';
    }

    return (
      this._termManager.getCurrentRentalRate(deal.baseRentalRateTerm) +
      (deal.baseRentalRateTerm.isModifiedValue ? ' [modified]' : '')
    );
  }

  getCurrentRentalRateNumber(deal: models.IBriefDealSummaryViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentRentalRateNumber(deal.baseRentalRateTerm);
  }

  getCurrentTenantImprovements(deal: models.IBriefDealSummaryViewModel): string {
    if (!deal) {
      return 'N/A';
    }

    return this._termManager.getCurrentTenantImprovements(deal.tenantImprovementsTerm);
  }

  getCurrentTenantImprovementsNumber(deal: models.IBriefDealSummaryViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentTenantImprovementsNumber(deal.tenantImprovementsTerm);
  }

  private _areProposalsEqual(a: models.IBriefDealSummaryViewModel, b: models.IBriefDealSummaryViewModel): boolean {
    return a.startDateOfDeal === b.startDateOfDeal
      && this.getCurrentTerm(a) === this.getCurrentTerm(a)
      && this.getCurrentTenantSquareFootage(a) === this.getCurrentTenantSquareFootage(b)
      && this.getCurrentRentalRate(a) === this.getCurrentRentalRate(b)
      && this.getCurrentFreeRent(a) === this.getCurrentFreeRent(b)
      && this.getCurrentTenantImprovements(a) === this.getCurrentTenantImprovements(b);
  }
}
