export class LinkedItem<T> {
  previous: LinkedItem<T>;
  next: LinkedItem<T>;
  value: T;

  constructor(value: T) {
    this.previous = null;
    this.next = null;
    this.value = value;
  }
}

export class LinkedList<T> {
  private _head: LinkedItem<T>;
  private _tail: LinkedItem<T>;

  length: number;

  constructor() {
    this._head = null;
    this._tail = null;
    this.length = 0;
  }

  firstItem(): LinkedItem<T> {
    return this._head;
  }

  lastItem(): LinkedItem<T> {
    return this._tail;
  }

  add(value: T): this {
    const item = new LinkedItem<T>(value);

    if (this._head === null) {
      this._head = item;
    } else {
      item.previous = this._tail;
      this._tail.next = item;
    }

    this._tail = item;
    this.length++;
    return this;
  }

  addItem(item: LinkedItem<T>): this {
    if (this._head === null) {
      this._head = item;
    } else {
      item.previous = this._tail;
      this._tail.next = item;
    }

    this._tail = item;
    this.length++;
    return this;
  }

  swap(x: LinkedItem<T>, y: LinkedItem<T>): this {
    const tmpValue = x.value;

    x.value = y.value;
    y.value = tmpValue;

    return this;
  }

  sort(fn: (x: T, y: T) => number): this {
    if (this.length < 2) {
      return this;
    }

    let currItem = this._tail;
    if (!currItem || !currItem.value) {
      return this;
    }

    while (currItem) {
      if (currItem.previous && fn(currItem.previous.value, currItem.value) <= 0) {
        this.swap(currItem.previous, currItem);
      }

      currItem = currItem.previous;
    }

    return this;
  }

  remove(index: number): this {
    if (!this._head || index < 0) {
      throw new RangeError(`Index ${index} does not exist in the list.`);
    }

    if (index === 0) {
      this._head = this._head.next;
      if (this._head === null) {
        this._tail = null;
      } else {
        this._head.previous = null;
      }

      this.length--;
      return this;
    }

    let current = this._head;
    let i = 0;

    while (current && i < index) {
      current = current.next;
      i++;
    }

    if (current) {
      current.previous.next = current.next;
      if (this._tail === current) {
        this._tail = current.previous;
      } else {
        current.next.previous = current.previous;
      }

      this.length--;
      return this;
    }

    throw new RangeError(`Index ${index} does not exist in the list.`);
  }

  getValue(index: number): T {
    const item = this.getItem(index);
    if (!item) {
      return null;
    }

    return item.value;
  }

  getItem(index: number): LinkedItem<T> {
    if (0 <= index) {
      let current = this._head;
      let i = 0;

      while (current && i < index) {
        current = current.next;
        i++;
      }

      if (!current) {
        return null;
      }

      return current;
    }

    return null;
  }

  *[Symbol.iterator](): Iterator<T> {
    let current = this._head;
    while (current !== null) {
      yield current.value;
      current = current.next;
    }
  }

  *itemsIterator(): Iterator<LinkedItem<T>> {
    let current = this._head;
    while (current !== null) {
      yield current;
      current = current.next;
    }
  }

  fromArray(values: Array<T>): this {
    for (let i = 0, num = values.length; i < num; i++) {
      this.add(values[i]);
    }

    return this;
  }

  toArray(): Array<T> {
    const result: Array<T> = [];
    for (const value of this) {
      result.push(value);
    }

    return result;
  }
}
