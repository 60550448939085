import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogRefService } from '../../services/dialog-ref.service';

import { BaseDialogContainerComponent } from '../base-dialog-container/base-dialog-container.component';

import { DialogOptions } from '../../models/dialog.model';

@Component({
  templateUrl: 'dialog-container.component.html',
})
export class DialogContainerComponent extends BaseDialogContainerComponent implements OnInit, OnDestroy {
  private static readonly _defaultClassNames = ['dialog', 'dialog-demo'];

  @ViewChild(DxPopupComponent) dxPopupComponent: DxPopupComponent;

  override readonly onShowing: EventEmitter<any>;
  override readonly onHiding: EventEmitter<any>;
  override readonly onShown: EventEmitter<any>;
  override readonly onHidden: EventEmitter<any>;
  override readonly onContentReady: EventEmitter<any>;

  override readonly config: DialogOptions;

  override isVisible: boolean;
  override title: string | null;

  private readonly _ngZone: NgZone;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  constructor(ngZone: NgZone, changeDetectorRef: ChangeDetectorRef, dialogRefService: DialogRefService, config: DialogOptions) {
    super();
    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();

    this.onShowing = new EventEmitter<any>();
    this.onHiding = new EventEmitter<any>();
    this.onShown = new EventEmitter<any>();
    this.onHidden = new EventEmitter<any>();
    this.onContentReady = new EventEmitter<any>();

    this.config = config;
    this.title = config.title;
  }

  ngOnInit(): void {
    const hiddenSubscription = this.onHidden
      .subscribe(() => {
        this.config.dialogService.hide(this.config.id);
        hiddenSubscription.unsubscribe();
      });

    // Fire sub-events
    this._firePopupRefEvent(this.onShowing, this._dialogRefService.onShowing);
    this._firePopupRefEvent(this.onHiding, this._dialogRefService.onHiding);
    this._firePopupRefEvent(this.onShown, this._dialogRefService.onShown);
    this._firePopupRefEvent(this.onHidden, this._dialogRefService.onHidden);
    this._firePopupRefEvent(this.onContentReady, this._dialogRefService.onContentReady);

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if ((<ViewRef>this._changeDetectorRef).destroyed) {
          return;
        }

        this.isVisible = true;

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();
  }

  getContainerClassNames(): string {
    if (this.config.containerClassNames) {
      return [...DialogContainerComponent._defaultClassNames, ...this.config.containerClassNames].join(' ');
    }

    return [...DialogContainerComponent._defaultClassNames].join(' ');
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  repaint(): void {
    if (!this.dxPopupComponent || !this.dxPopupComponent.instance) {
      return;
    }

    this.dxPopupComponent.instance.repaint();
  }

  setTitle(title: string | null) {
    this.title = title;
  }

  private _firePopupRefEvent(from: EventEmitter<any>, to: EventEmitter<any>): void {
    from.pipe(takeUntil(this._destroy$)).subscribe((event) => to.emit(event));
  }
}
