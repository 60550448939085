<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row"
       *ngIf="!isOption">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container mb-2"
         *ngIf="isVisibleRadioGroup">
      <div class="col-sm-12 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options | filter : 'disabled'" [(value)]="leaseTerm.freeRentTermType"
                          (onOptionChanged)="clearErrors(); optionChanged($event)">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div class="insert-months"
         *ngIf="leaseTerm?.freeRentTermType === FreeRentTermType.MonthsCount">

      <label for="monthCount" class="m-0">Insert value for how many months:</label>

      <dx-number-box appIgnoreScrollwheel id="monthCount" class="real-estate-input w-100"
                     placeholder="INSERT VALUE FOR HOW MANY MONTHS" format="#0 month(s)"
                     min="1" max="1000"
                     [(value)]="leaseTerm.freeRentMonthsCount"
                     (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
        <dx-validator>
          <dxi-validation-rule type="required" message="Value for how many months is required">
          </dxi-validation-rule>
          <dxi-validation-rule type="compare" comparisonType="!=="
                               message="Cannot save 0 months, please insert another value or choose 'None' multiple choice option instead."
                               [comparisonTarget]="getZeroComparisonTarget">
          </dxi-validation-rule>
        </dx-validator>
      </dx-number-box>
    </div>

    <div class="insert-months"
         *ngIf="leaseTerm?.freeRentTermType === FreeRentTermType.SpecificSchedule">
      <label class="m-0" for="monthCount">Insert which months</label>
      <dx-tag-box placeholder="Months (e.g. 1, 3, 5, 9):" class="real-estate-input insert-months-input"
                  [(value)]="leaseTerm.specificMonths"
                  [acceptCustomValue]="true"
                  (onCustomItemCreating)="onSpecificMonthsCreating($event)"
                  (onInitialized)="onTagBoxInitialized($event)"
                  (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
        <dx-validator>
          <dxi-validation-rule type="required" message="Specific months are required">
          </dxi-validation-rule>
        </dx-validator>
      </dx-tag-box>

      <div>
        <button id="tooltipSpecificMonths" class="b-btn-icon"
                (click)="tooltipSpecificMonthsVisible = true; $event.preventDefault()">
          <span class="icon-info"
                inlineSVG="assets/img/signs-icons.svg#icon-tooltip"
          ></span> {{ leaseTerm.specificMonths }}
        </button>
        <dx-tooltip target="#tooltipSpecificMonths" position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                    [(visible)]="tooltipSpecificMonthsVisible" [width]="250">
          <div class="d-flex flex-column tooltip-menu">
            Enter a number for a specific month one at a time and press enter.
            Repeat process for as many months as you require in this field.
          </div>
        </dx-tooltip>
      </div>
    </div>

    <ng-container *ngIf="isVisibleTaxesRadioGroup">
      <div class="row">
        <label for="taxedRadioGroup">
          Choose Net, Gross
        </label>
      </div>
      <div class="row mb-2">
        <div class="col-sm-8">
          <div class="dx-field-value">
            <dx-radio-group id="taxedRadioGroup" displayExpr="name" valueExpr="value"
                            [dataSource]="taxesRadioGroup" [(value)]="leaseTerm.freeRentTaxesType"
                            (onValueChanged)="clearErrors(); leaseTermPropertyChange()">
              <dx-validator>
                <dxi-validation-rule type="required" message="Choose Net, Gross">
                </dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>
        <!--col-->
        <div class="col-sm-6"></div>
      </div>
      <!--row-->
    </ng-container>

    <ng-container *ngIf="canInsertNewOption()">
      <ng-container *ngFor="let termOption of leaseTerm?.leaseTermOptions?.options; let index = index;">
        <div class="row">
          <label>
            OPTION {{ getOptionName(index) }}
          </label>
        </div>
        <div class="row">
          <app-free-rent [isOption]="true" [project]="project" [lease]="lease"
                         [(leaseTerm)]="leaseTerm?.leaseTermOptions?.options[index]">
          </app-free-rent>
        </div>
      </ng-container>
    </ng-container>

    <!-- Start: Validation summary -->
    <div>
      <dx-validation-summary ngForm="form">
      </dx-validation-summary>
      <div class="term-toast term-toast-error"
           *ngFor="let msg of errors">
        <i inlineSVG="assets/img/x.svg"></i>
        {{ msg }}
      </div>

    </div>
    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div class="col-sm-3 col-6"
           *ngIf="!isOption">
        <div class="dx-fieldset">
          <dx-button id="button" class="b-btn b-btn--small b-btn-primary btn" text="Save" type="success"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div class="col-sm-3 col-6"
           *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" class="b-btn b-btn--small b-btn-primary btn" text="Cancel" type="cancel"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<div class="term-toast term-toast-information"
     *ngIf="isTermWillBeAddressedInFollowingStages()">
  <i inlineSVG="assets/img/information.svg"></i>
  This Term will be addressed in the following Stage(s).
</div>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
