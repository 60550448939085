import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { MessageManagerFactory } from './message-manager.factory';
import { AnchorCommentRepository } from './anchor-comment/anchor-comment.repository';
import { ChatMessageRepository } from './chat-message/chat-message.repository';
import { LeaseFloorPlanCommentRepository } from './lease-floor-plan-comment/lease-floor-plan-comment.repository';
import { TermCommentRepository } from './term-comment/term-comment.repository';
import { VersionedFileCommentRepository } from './versioned-file-comment/versioned-file-comment.repository';
import {AnchorCommentManager} from './anchor-comment/anchor-comment.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    MessageManagerFactory,
    AnchorCommentRepository,
    ChatMessageRepository,
    LeaseFloorPlanCommentRepository,
    TermCommentRepository,
    VersionedFileCommentRepository,
    AnchorCommentManager
  ],
})
export class MessageModule {
  static forChild(configuration: Configuration): ModuleWithProviders<MessageModule> {
    return {
      ngModule: MessageModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        MessageManagerFactory,
      ],
    };
  }
}
