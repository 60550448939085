import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './quiz.model';

@Injectable()
export class QuizRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(
    configuration: Configuration,
    httpClient: HttpClient,
  ) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getWizardQuiz(leaseId: number): Observable<models.Quiz> {
    let params = new HttpParams();

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .get<models.Quiz>(
        `${this._configuration.apiEndpoint}/Quiz/GetWizardQuiz`,
        {
          params: params,
        },
      );
  }

  getQuizQuestionsByType(quizType: models.QuizType, leaseId: number): Observable<Array<models.QuizQuestion>> {
    let params = new HttpParams();

    if (typeof quizType === 'number') {
      params = params.set('quizType', quizType.toString());
    }

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .get<Array<models.QuizQuestion>>(
        `${this._configuration.apiEndpoint}/Quiz/GetQuizQuestionsByType`,
        {
          params: params,
        },
      );
  }

  getLastQuestion(quizId: number, leaseId: number): Observable<models.QuizQuestion> {
    let params = new HttpParams();

    if (typeof quizId === 'number') {
      params = params.set('quizId', quizId.toString());
    }

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .get<models.QuizQuestion>(
        `${this._configuration.apiEndpoint}/quiz/GetLastQuestion`,
        {
          params: params,
        },
      );
  }

  answer(model: models.QuizAnswer): Observable<models.QuizQuestion> {
    return this._httpClient
      .post<models.QuizQuestion>(
        `${this._configuration.apiEndpoint}/quiz/Answer`,
        model,
      );
  }

  restart(quizId: number, leaseId: number) {
    let params = new HttpParams();

    if (typeof quizId === 'number') {
      params = params.set('quizId', quizId.toString());
    }

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/quiz/Restart`,
        null,
        {
          params: params,
        },
      );
  }

  complete(leaseId: number, model: models.Quiz): Observable<models.QuizQuestion> {
    let params = new HttpParams();

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .post<models.QuizQuestion>(
        `${this._configuration.apiEndpoint}/quiz/Complete`,
        model,
        {
          params: params,
        },
      );
  }

  getQuestion(questionId: number, leaseId: number): Observable<models.QuizQuestion> {
    let params = new HttpParams();

    if (typeof questionId === 'number') {
      params = params.set('questionId', questionId.toString());
    }

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .get<models.QuizQuestion>(
        `${this._configuration.apiEndpoint}/quiz/GetQuestion`,
        {
          params: params,
        },
      );
  }

  getLeaseAmendmentsChoices(leaseId: number): Observable<Array<models.QuizLeaseAmendmentChoiceViewModel>> {
    let params = new HttpParams();

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    return this._httpClient
      .get<Array<models.QuizLeaseAmendmentChoiceViewModel>>(
        `${this._configuration.apiEndpoint}/quiz/lease-amendments-choices`,
        {
          params: params,
        },
      );
  }

  getSummaryQuizAnswers(leaseId: number, quizId: number): Observable<Array<models.QuizItem>> {
    let params = new HttpParams();

    if (typeof leaseId === 'number') {
      params = params.set('leaseId', leaseId.toString());
    }

    if (typeof quizId === 'number') {
      params = params.set('quizId', quizId.toString());
    }

    return this._httpClient
      .get<Array<models.QuizItem>>(
        `${this._configuration.apiEndpoint}/Quiz/GetSummaryQuizAnswers`,
        {
          params: params,
        },
      );
  }
}
