<div #addNoteInput class="addNote_input-wrapper">
  <input [(ngModel)]="note" type="text" placeholder="Add a note">

  <button type="button" [disabled]="!note" (click)="addNote()">
    <img src="./assets/icons/arrow-top.svg" alt="arrow-top">
  </button>
</div>
<ng-container *ngIf="imageRef">
  <ng-container *ngIf="!isPreviewMode; else previewTemplate">
    <div [ngSwitch]="imageRef.imageKind"
         [style.width.px]="width" [style.height.px]="height"
         [style.min-width.px]="minWidth" [style.min-height.px]="minHeight">
      <!-- Start: Standard Image -->
      <ng-container *ngSwitchCase="ImageKind.Standard">
        <app-image-viewer-image-standard [imageRef]="imageRef" [allowChangeMarkers]="allowChangeMarkers"
                                         [width]="width" [height]="height"
                                         [markerCreated$]="markerCreated$" [markerChanged$]="markerChanged$"
                                         [markerDeleted$]="markerDeleted$"
                                         [addNotePressed]="addNotePressed"
                                         (markerDrawn)="onMarkerDrawn($event)"
                                         #imageViewerImageStandardComponent>
        </app-image-viewer-image-standard>
      </ng-container>
      <!-- End: Standard Image -->
      <!-- Start: Panorama Image -->
      <ng-container *ngSwitchCase="ImageKind.Panorama">
        <app-image-viewer-image-panorama [imageRef]="imageRef" [allowChangeMarkers]="allowChangeMarkers"
                                         [markerCreated$]="markerCreated$" [markerChanged$]="markerChanged$"
                                         [markerDeleted$]="markerDeleted$"
                                         [addNotePressed]="addNotePressed"
                                         (markerDrawn)="onMarkerDrawn($event)"
                                         #imageViewerImagePanoramaComponent>
        </app-image-viewer-image-panorama>
      </ng-container>
      <!-- End: Panorama Image -->
    </div>
  </ng-container>
  <ng-template #previewTemplate>
    <div class="image-viewer-image-preview">
      <img alt=""
           [src]="imageRef.imageDataUrl" [style.width.px]="width" [style.height.px]="height"
           [style.min-height.px]="minHeight"/>

      <div class="image-viewer-image-preview-panorama-icon"
           *ngIf="imageRef.imageKind === ImageKind.Panorama">
        <img src="/assets/img/picture-cursor.svg" alt="360º"/>
      </div>
    </div>
  </ng-template>
</ng-container>
