export enum OccupancyAndVacancyReportTooltipChartType {
  MultiplePeriods = 1,
  SinglePeriod = 2,
}

export enum OccupancyAndVacancyReportTooltipType {
  Occupancy = 0,
  Vacancy = 1,
}

export class OccupancyAndVacancyReportTooltip {
  tooltipType: OccupancyAndVacancyReportTooltipType;
  occupancyAndVacancyReportTooltipChartType: OccupancyAndVacancyReportTooltipChartType;
  startDate: Date | string;
  endDate: Date | string;
  occupancy: number;
  occupancyPercentage: number;
  vacancies: number;
  vacanciesPercentage: number;
}
