import { FactoryProvider, InjectionToken, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export type CanActivateResult = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export type CanActivateCallback = (
  injector: Injector,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => CanActivateResult;

export interface Guard extends CanActivate, CanActivateChild {}

export class CanActivateGuardFactory {
  private static _guardCounter = 0;

  static make(callback: CanActivateCallback): [InjectionToken<CanActivate>, FactoryProvider] {
    const injectionToken = new InjectionToken<CanActivate>(`CAN_ACTIVATE_FACTORY_GUARD_${++CanActivateGuardFactory._guardCounter}`);
    const provider = {
      provide: injectionToken,
      deps: [Injector],
      useFactory: CanActivateGuardFactory._makeGuard(callback),
    };

    return [injectionToken, provider];
  }

  private static _makeGuard(callback: CanActivateCallback): (injector: Injector) => Guard {
    return (injector: Injector): Guard => new class implements CanActivate, CanActivateChild {
      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResult {
        return callback(injector, route, state);
      }

      canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResult {
        return callback(injector, route, state);
      }
    };
  }
}
