import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const WEEKDAYS_ABBR = [
  'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

enum MonthRelativity {
  Current,
  Previous,
  Next,
}

interface Cell {
  isGreyedOut: boolean;
  isToday: boolean;
  isDisabled: boolean;
  year: number;
  month: number;
  date: number;
  relativity: MonthRelativity;
}

@Component({
  selector: 'app-tour-form-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class TourFormCalendarComponent implements OnInit {
  @Output() dateSelected = new EventEmitter<Date>();
  @Input() referencedDates: Array<Date> = [];

  weekdays = WEEKDAYS_ABBR;

  selectedDate: Date;
  currentYear: number;
  currentMonth: number;
  rows: Array<Array<Cell>> = [];

  constructor() {
    let now = new Date();
    now = new Date(now.getUTCFullYear(), now.getMonth(), now.getDate());
    this.selectedDate = now;
    this.currentYear = now.getFullYear();
    this.currentMonth = now.getMonth();
  }

  ngOnInit(): void {
    this.dateSelected.emit(this.selectedDate);
    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  onNextMonthClick() {
    this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  onPreviousMonthClick() {
    this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  onDateClick(cell: Cell) {
    let date: Date;

    if (cell.relativity === MonthRelativity.Previous) {
      date = new Date(this.currentYear, this.currentMonth - 1, cell.date);
    } else if (cell.relativity === MonthRelativity.Next) {
      date = new Date(this.currentYear, this.currentMonth + 1, cell.date);
    } else {
      date = new Date(this.currentYear, this.currentMonth, cell.date);
    }

    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    this.selectedDate = localDate;
    this.dateSelected.emit(localDate);
  }

  getDateString(): string {
    return `${MONTHS[this.currentMonth]} ${this.currentYear}`;
  }

  isSelectedDate(cell: Cell): boolean {
    return cell.relativity === MonthRelativity.Current
      && this.selectedDate.getFullYear() === this.currentYear
      && this.selectedDate.getMonth() === this.currentMonth
      && this.selectedDate.getDate() === cell.date;
  }

  isReferencedDate(cell: Cell): boolean {
    return !this.isSelectedDate(cell) && !!this.referencedDates
      ?.find((d) => d.getFullYear() === cell.year && d.getMonth() === cell.month && d.getDate() === cell.date);
  }

  private _daysInMonth(month: number, year: number): number {
    return 32 - new Date(year, month, 32).getDate();
  }

  private _daysInPreviousMonth(): number {
    const year = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    const month = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    return this._daysInMonth(month, year);
  }

  private _getPreviousMonth(): Date {
    const year = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    const month = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    return new Date(year, month);
  }

  private _getNextMonth(): Date {
    const year = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
    const month = (this.currentMonth === 11) ? 0 : this.currentMonth + 1;
    return new Date(year, month);
  }

  private _updateCalendar(month: number, year: number) {
    const firstDay = (new Date(year, month)).getDay();
    const today = new Date();
    const daysInCurrentMonth = this._daysInMonth(month, year);
    const daysInPreviousMonth = this._daysInPreviousMonth();

    const rows = [];
    let date = 1;
    for (let rowNum = 0; rowNum < 6 && date <= daysInCurrentMonth; rowNum++) {
      const row: Array<Cell> = [];

      for (let colNum = 0; colNum < 7; colNum++) {
        if (rowNum === 0 && colNum < firstDay) {
          row.push({
            isGreyedOut: true,
            year: year,
            month: month,
            date: daysInPreviousMonth - (firstDay - colNum - 1),
            isDisabled: true,
            isToday: false,
            relativity: MonthRelativity.Previous,
          });
        } else if (date > daysInCurrentMonth) {
          row.push({
            isGreyedOut: true,
            year: year,
            month: month,
            date: date % daysInCurrentMonth,
            isDisabled: true,
            isToday: false,
            relativity: MonthRelativity.Next
          });

          date++;
        } else {
          row.push({
            isGreyedOut: false,
            isToday: date === today.getDate() && this.currentYear === today.getFullYear() && this.currentMonth === today.getMonth(),
            isDisabled:
              (this.currentYear === today.getFullYear() && this.currentMonth === today.getMonth() && date < today.getDate()) ||
              (this.currentYear === today.getFullYear() && this.currentMonth < today.getMonth()) ||
              (this.currentYear < today.getFullYear()),
            year: year,
            month: month,
            date: date,
            relativity: MonthRelativity.Current,
          });

          date++;
        }
      }

      rows.push(row);
    }

    this.rows = rows;
  }
}
