import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Configuration } from '@statera/sdk/common';

import { AvailableUnitManager } from './available-unit.manager';
import { AvailableUnitRepository } from './available-unit.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    AvailableUnitManager,
    AvailableUnitRepository,
  ],
})
export class AvailableUnitModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AvailableUnitModule> {
    return {
      ngModule: AvailableUnitModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AvailableUnitManager,
        AvailableUnitRepository,
      ],
    };
  }
}
