<div class="analytics-filters-square-feet">
  <div class="analytics-filters-square-feet-range">
    <dx-range-selector [(ngModel)]="squareFeetRangeValue"
                       (ngModelChange)="handleSquareFeetSelection($event)">
      <dxo-scale [startValue]="squareFeetRange.boundaries.min" [endValue]="squareFeetRange.boundaries.max"
                 [minorTickInterval]="100">
        <dxo-label [visible]="false">
        </dxo-label>

        <dxo-minor-tick [visible]="false">
        </dxo-minor-tick>

        <dxo-tick [visible]="false">
        </dxo-tick>
      </dxo-scale>
      <dxo-slider-marker [customizeText]="customizeSquareFeetText" [font]="rangeSelectorFontProperties">
      </dxo-slider-marker>
    </dx-range-selector>
  </div>

  <div class="analytics-filters-square-feet-form"
       *ngIf="squareFeetRangeValue && squareFeetRangeValue.length">
    <div class="d-flex flex-row align-items-center mx-n2">
      <div class="px-2 flex-fill">
        <input class="form-control form-control-sm" name="square-feet-min" type="number"
               [min]="squareFeetRange.boundaries.min" [max]="squareFeetRange.boundaries.max"
               [ngModel]="squareFeetRangeValue[0]"
               (ngModelChange)="handleSquareFeetMinChange($event, squareFeetValueMin)" #squareFeetValueMin/>
      </div>
      <div class="px-2 text-center">
        to
      </div>
      <div class="px-2 flex-fill">
        <input class="form-control form-control-sm" name="square-feet-max" type="number"
               [min]="squareFeetRange.boundaries.min" [max]="squareFeetRange.boundaries.max"
               [ngModel]="squareFeetRangeValue[1]"
               (ngModelChange)="handleSquareFeetMaxChange($event, squareFeetValueMax)" #squareFeetValueMax/>
      </div>
    </div>
  </div>
</div>
