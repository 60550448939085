import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager, Role } from '@statera/sdk/auth';
import { CompanyManager } from '@statera/sdk/company';
import { CompanyType } from '@statera/sdk/common';

import { AlertService } from '../../../alert/services/alert.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  vm: models.IRegisterViewModel;

  roles = [Role.Tenant, Role.Landlord];
  phoneRules = {X: /[02-9]/};
  submitted = false;
  companiesDataSource: DataSource;
  companyPrediction: models.ICompanyPredictionViewModel;

  private readonly _authManager: AuthManager;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _companyManager: CompanyManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    authManager: AuthManager,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    companyManager: CompanyManager,
  ) {
    this._authManager = authManager;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._companyManager = companyManager;

    this._destroy$ = new Subject<void>();
    this.vm = <models.IRegisterViewModel>{ role: Role.Tenant };
  }

  ngOnInit(): void {
    this.companiesDataSource = new DataSource({
      key: 'id',
      load: (loadOptions) => {
        if (!loadOptions || !loadOptions.searchValue) {
          return [];
        }
        return this._companyManager
          .searchCompanies(loadOptions.searchValue, CompanyType.LandlordCompany)
          .toPromise();
      },
      insert: (company) => {
        return null;
      }
    });
  }

  maskedPhoneNumberInitialized(e) {
    setTimeout(function () {
      e.component.___maskText = e.component.option('text');
      e.component.option('isValid', true);
    });
  }

  submit(form: NgForm) {
    if (form.invalid || this.vm.password !== this.vm.confirmPassword) {
      return;
    }

    this.submitted = true;

    this._authManager
      .register({...this.vm})
      .subscribe(() => {
        this._router.navigate(['signin-user']);

        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getUserVerificationLinkSentAlertText(),
        });
      })
      .add(() => {
        this.submitted = false;
      });
  }

  companyChange($event: any, companyName: string) {
    if (!companyName || companyName.length < 3) {
      return;
    }
    this.vm.companyId = null;
    let companyType = models.CompanyType.TenantCompany;

    if (this.vm?.role === Role.Landlord) {
      companyType = models.CompanyType.LandlordCompany;
    }
    this._companyManager
      .searchCompanies(companyName, companyType)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(companyPredictions => {
        this.companyPrediction = companyPredictions?.find(x => x);
      });
  }

  chooseNoPrediction() {
    this.companyPrediction = null;
  }

  chooseYesPrediction() {
    if (this.companyPrediction) {
      this.vm.companyId = this.companyPrediction.id;
      this.vm.companyName = this.companyPrediction.name;
    }
    this.companyPrediction = null;
  }
}
