import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './landlord.model';

@Injectable()
export class LandlordRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient, configuration: Configuration) {
    this._httpClient = httpClient;
    this._configuration = configuration;
  }

  getPortfolios(): Observable<Array<models.PortfolioViewModel>> {
    return this._httpClient
      .get<Array<models.PortfolioViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/GetPortfolios`,
      );
  }

  searchBuildingsByFilter(filter: models.LandlordDashboardFilterViewModel):
    Observable<models.PagedResult<models.PortfolioBuildingViewModel>> {
    return this._httpClient
      .post<models.PagedResult<models.PortfolioBuildingViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/GetBuildingsByFilter`,
        filter,
      );
  }

  addPortfolio(model: models.PortfolioViewModel): Observable<Array<models.PortfolioViewModel>> {
    return this._httpClient
      .post<Array<models.PortfolioViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/AddPortfolio`,
        model,
      );
  }

  saveBuilding(model: models.BuildingViewModel): Observable<Array<models.PortfolioViewModel>> {
    return this._httpClient
      .post<Array<models.PortfolioViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/SaveBuilding`,
        model,
      );
  }

  getTenants(buildingId: number): Observable<Array<models.CompactTenantViewModel>> {
    return this._httpClient
      .get<Array<models.CompactTenantViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/Tenants/${buildingId}`,
      );
  }

  getDeals(buildingId: number): Observable<Array<models.DealViewModel>> {
    return this._httpClient
      .get<Array<models.DealViewModel>>(
      `${this._configuration.apiEndpoint}/landlord/deals/${buildingId}`,
      );
  }

  getSuites(buildingId: number): Observable<Array<models.SuiteViewModel>> {
    return this._httpClient
      .get<Array<models.SuiteViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/suites/${buildingId}`,
      );
  }

  getBriefDealSummaries(leaseId: number): Observable<Array<models.BriefDealSummaryViewModel>> {
    return this._httpClient
      .get<Array<models.BriefDealSummaryViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/deals/briefSummaries/${leaseId}`,
      );
  }

  getTenantDetails(leaseId: number): Observable<models.TenantDetailViewModel> {
    return this._httpClient
      .get<models.TenantDetailViewModel>(
        `${this._configuration.apiEndpoint}/landlord/TenantDetails/${leaseId}`,
      );
  }

  // building files

  getBuildingFileFolders(): Observable<Array<models.AttachedFolderViewModel>> {
    return this._httpClient
      .get<Array<models.AttachedFolderViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/buildingFileFolders`,
      );
  }

  deleteBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/landlord/DeleteBuildingFiles`,
        files,
      );
  }

  updateBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/landlord/UpdateBuildingFiles`,
        files,
      );
  }

  shareBuildingFiles(files: Array<models.AttachedFileViewModel>): Observable<Array<models.AttachedFileViewModel>> {
    return this._httpClient
      .post<Array<models.BuildingFileViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/ShareBuildingFiles`,
        files,
      );
  }

  // lease files

  getLeaseFiles(leaseId: number): Observable<Array<models.AttachedFileViewModel>> {
    const params = new HttpParams().set('leaseId', leaseId.toString());
    return this._httpClient
      .get<Array<models.AttachedFileViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/leaseFiles`, {params}
      );
  }

  getLeaseFileFolders(): Observable<Array<models.AttachedFolderViewModel>> {
    return this._httpClient
      .get<Array<models.AttachedFolderViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/leaseFileFolders`,
      );
  }

  deleteLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/landlord/DeleteLeaseFiles`,
        files,
      );
  }

  updateLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${this._configuration.apiEndpoint}/landlord/UpdateLeaseFiles`,
        files,
      );
  }

  shareLeaseFiles(files: Array<models.AttachedFileViewModel>): Observable<Array<models.AttachedFileViewModel>> {
    return this._httpClient
      .post<Array<models.AttachedFileViewModel>>(
        `${this._configuration.apiEndpoint}/landlord/ShareLeaseFiles`,
        files,
      );
  }
}
