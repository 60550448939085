import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';

import { NOTIFICATION_INTERCEPTORS } from '@statera/sdk/notification';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { AlertComponent } from './components/alert/alert.component';
import { AlertContainerComponent } from './components/alert-container/alert-container.component';

import { BuildingUnitListingUpdatePricePromptInterceptor } from './interceptors/building-unit-listing-update-price-prompt.interceptor';
import { CoBrokerInvitationInterceptor } from './interceptors/cobroker-invitation.interceptor';
import { FinancialRequestInterceptor } from './interceptors/financial-request.interceptor';
import { FinancialReviewRequestInterceptor } from './interceptors/financial-review-request.interceptor';
import { InquiriesAndRequestsUpdatePromptInterceptor } from './interceptors/inquiries-and-requests-update-prompt.interceptor';
import { InternetDisconnectedInterceptor } from './interceptors/internet-disconnected.interceptor';
import { RefreshColaboInterceptor } from './interceptors/refresh-colabo.interceptor';
import { RenewalOrRestructureRequestInterceptor } from './interceptors/renewal-or-restructure-request.interceptor';
import { WebsocketConnectionTerminatedInterceptor } from './interceptors/websocket-connection-terminated.interceptor';

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,

    InfrastructureModule,
  ],
  declarations: [
    AlertComponent,
    AlertContainerComponent,
  ],
  exports: [
    AlertContainerComponent,
  ],
})
export class AlertModule {
  static forRoot(): ModuleWithProviders<AlertModule> {
    return {
      ngModule: AlertModule,
      providers: [
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: BuildingUnitListingUpdatePricePromptInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: CoBrokerInvitationInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: FinancialRequestInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: FinancialReviewRequestInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: InquiriesAndRequestsUpdatePromptInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: RefreshColaboInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: RenewalOrRestructureRequestInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: WebsocketConnectionTerminatedInterceptor,
          multi: true,
        },
        {
          provide: NOTIFICATION_INTERCEPTORS,
          useClass: InternetDisconnectedInterceptor,
          multi: true,
        },
      ],
    };
  }
}
