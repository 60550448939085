import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager, TermTypeOption } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import { Role } from '@statera/sdk/common';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
})
export class TermComponent extends BaseTermDirective<models.ITermViewModel> implements OnInit, OnChanges, OnDestroy {

  readonly multipleOptionsCustomOptionIndex = -1;

  radioGroupOptions: Array<{name: string, value: TermTypeOption, visible?: boolean}> = [];

  option: any;

  radioGroupValue: string;
  TermTypeOption = TermTypeOption;
  TermTypeEnum = models.TermTypeEnum;
  isVisibleRadioGroup = true;
  radioGroupForTermOptions: Array<{ text: string, value: number }>;

  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _projectService: ProjectService;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    alertMessagesManager: AlertMessagesManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._projectService = projectService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initialiseOptions();
    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this.initialiseOptions();
          }),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseTerm &&
      changes.leaseTerm.previousValue &&
      changes.leaseTerm.currentValue &&
      !this.termManager.isSameTerms(
        <models.ILeaseTermViewModel>changes.leaseTerm.previousValue,
        <models.ILeaseTermViewModel>changes.leaseTerm.currentValue,
      )
    ) {
      this.initialiseOptions();
    }
  }

  isButtonsVisible(): boolean {
    if (this.isRejectFormVisible) {
      return false;
    }

    if (this.rejectButtonClicked) {
      return false;
    }

    if (this.isOption) {
      return false;
    }

    const isCurrentRoleTurnOnTerms = this.projectManager
      .isCurrentRoleTurnOnTerms(this.startupInfo.role, this.startupInfo.id, this.project, this.lease);

    if (!isCurrentRoleTurnOnTerms) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus === models.TermStatus.Draft
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease
        )
      )
    ) {
      return true;
    }

    if (
      this.project &&
      this.startupInfo &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this.project.pendingForInternalApproval
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease
        )
      )
    ) {
      return false;
    }

    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isCounterButtonVisible(): boolean {
    const { ReviewTenantImprovementsSelectMultiplyOptionsTenant, TenantCounterUnsolicitedOffer } = models.RenewalProjectTemplateItemType;

    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        this.projectAccessService.checkAccessToRenewalProject(TenantCounterUnsolicitedOffer, this.project, this.lease) ||
        this.projectAccessService.checkAccessToRenewalProject(ReviewTenantImprovementsSelectMultiplyOptionsTenant, this.project, this.lease)
      )
    ) {
      return false;
    }

    return super.isCounterButtonVisible();
  }

  isChooseOptionButtonVisible(): boolean {
    const { ReviewTenantImprovementsSelectMultiplyOptionsTenant, TenantCounterUnsolicitedOffer } = models.RenewalProjectTemplateItemType;

    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        this.projectAccessService.checkAccessToRenewalProject(TenantCounterUnsolicitedOffer, this.project, this.lease) ||
        this.projectAccessService.checkAccessToRenewalProject(ReviewTenantImprovementsSelectMultiplyOptionsTenant, this.project, this.lease)
      )
    ) {
      return true;
    }

    return super.isChooseOptionButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    const { ReviewTenantImprovementsSelectMultiplyOptionsTenant, TenantCounterUnsolicitedOffer } = models.RenewalProjectTemplateItemType;

    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Pending &&
      (
        this.projectAccessService.checkAccessToRenewalProject(TenantCounterUnsolicitedOffer, this.project, this.lease) ||
        this.projectAccessService.checkAccessToRenewalProject(ReviewTenantImprovementsSelectMultiplyOptionsTenant, this.project, this.lease)
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    const isTenantCounterOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
          this.project,
          this.lease,
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        )
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this._alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this._alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick() {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterOffer,
          this.project,
          this.lease
        )
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    super.handleAcceptButtonClick();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.termValue && this.radioGroupValue === TermTypeOption.Custom) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.radioGroupValue === TermTypeOption.MultiplyOptions) {
      this.leaseTerm.termType = models.TermTypeEnum.MultiplyOptions;

      if (!this.leaseTerm.leaseTermOptions) {
        this.leaseTerm.leaseTermOptions = <models.ILeaseTermOptions<models.ITermViewModel>>{};
      }

      if (this.termOptions) {
        this.leaseTerm.leaseTermOptions.options = this.termOptions;
      }
    } else {
      this.leaseTerm.termType = models.TermTypeEnum.Custom;
    }

    if (this.radioGroupSelectedIndex === this.multipleOptionsCustomOptionIndex) {
      this.leaseTerm.termType = models.TermTypeEnum.MultipleOptionsWithCustomValue;
    }

    if (this.leaseTerm.termType === models.TermTypeEnum.MultiplyOptions && !this._checkMultiplyOptions()) {
      this.addError('You should fill options.');
      return;
    }

    if (this._areSameMultipleOptionsAndAreSameSquareFootageMultipleOptions()) {
      this.addError('Both Option A and B for Term and Size cannot be the same. Please adjust one or the other accordingly.');
      return;
    }

    if (
      this.isShowMultiplyOptionsTenantStep() &&
      this.radioGroupSelectedIndex !== -1
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmNonCustomMultipleOptionSelectionAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => {
            this.leaseTerm.termValue = this.getTermValue();
            super.saveLeaseTerm(this.leaseTerm, null, null);
          }),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();
    } else {
      this.leaseTerm.termValue = this.getTermValue();
      super.saveLeaseTerm(this.leaseTerm, null, null);
    }

    $event.preventDefault();
  }

  isVisibleTermForm(): boolean {
    if (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterOffer &&
      this.leaseTerm &&
      this.leaseTerm.termStatus === models.TermStatus.Ready
    ) {
      return false;
    }
    return super.isVisibleTermForm();
  }

  getTermValue(): number {
    if (this.radioGroupSelectedIndex === this.multipleOptionsCustomOptionIndex) {
      return this.leaseTerm.termValue;
    }
    return this.termManager.getTermValueByOption(this.radioGroupValue, this.leaseTerm);
  }

  private _areSameMultipleOptionsAndAreSameSquareFootageMultipleOptions(): boolean {
    return (
      this.lease &&

      this.lease.term &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.lease.term.leaseTermOptions &&
      this.lease.term.leaseTermOptions.options &&

      this.lease.term.leaseTermOptions.options.every(x => x.termType) &&

      this.areSameMultipleOptions(
        this.lease.term.leaseTermOptions.options,
        x => this.termManager.getCurrentTerm(x)
      ) &&

      this.lease.tenantSquareFootageTerm &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions &&
      this.lease.tenantSquareFootageTerm.leaseTermOptions &&
      this.lease.tenantSquareFootageTerm.leaseTermOptions.options &&

      this.lease.tenantSquareFootageTerm.leaseTermOptions.options.every(x => x.tenantSquareFootageTermType) &&

      this.areSameMultipleOptions(
        this.lease.tenantSquareFootageTerm.leaseTermOptions.options,
        x => this.termManager.getCurrentTenantSquareFootage(x)
      )
    );
  }

  private _checkMultiplyOptions(): boolean {
    if (!this.leaseTerm.leaseTermOptions ||
      !this.leaseTerm.leaseTermOptions.options) {
      return false;
    }

    if (this.leaseTerm.leaseTermOptions.options.length === 0) {
      return false;
    }

    return !(this.leaseTerm.leaseTermOptions.options.find(x => !x || !x.termValue));
  }

  termRadioGroupValueChanged($event) {
    if (this.radioGroupValue !== TermTypeOption.MultiplyOptions) {
      this.leaseTerm.termType = models.TermTypeEnum.Custom;
      this.leaseTerm.termValue = this.getTermValue();
    } else {
      this.leaseTerm.termType = models.TermTypeEnum.MultiplyOptions;
      this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);
    }
    this.leaseTermChange.emit(this.leaseTerm);
  }

  isTenantSquareFootageTypePhaseIn(): boolean {
    return (
      this.lease && this.lease.tenantSquareFootageTerm &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn
    );
  }

  isRentalRateEscalationTypeCustom(): boolean {
    return (
      this.lease && this.lease.rentalRateAnnualEscalationTerm &&
      this.lease.rentalRateAnnualEscalationTerm.escalationTermType === models.EscalationTermType.Custom
    );
  }

  radioGroupTermSaved(event) {
    if (!event || typeof event.value !== 'number') {
      return;
    }

    if (event.value === this.multipleOptionsCustomOptionIndex) {
      this.leaseTerm.termType = models.TermTypeEnum.MultipleOptionsWithCustomValue;
    }

    if (!this.leaseTerm.leaseTermOptions) {
      this.leaseTerm.leaseTermOptions = <models.ILeaseTermOptions<models.ITermViewModel>>{};
    }

    this.leaseTerm.leaseTermOptions.selectedLeaseTerm = this.termOptions[event.value];
    this.leaseTerm.leaseTermOptions.selectedLeaseTermIndex = event.value;
  }

  getRadioGroupForTermOptions(): Array<{ text: string, value: number }> {
    if (this.termOptions) {
      const options = this.termOptions.map((value, index) => {
        const name = this.termManager.getLeaseTermOptionName(index);
        return {
          text: 'Option: ' + name,
          value: index,
        };
      });

      return [
        ...options,
        {
          text: 'Custom',
          value: this.multipleOptionsCustomOptionIndex,
        },
      ];
    }
    return null;
  }

  canInsertNewOption(): boolean {
    return !this.isOption && this.termManager.canInsertNewOption(this.leaseTerm, this.project);
  }

  initialiseOptions() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ITermViewModel>{leaseTermType: models.LeaseTermType.Term};
    }

    this.radioGroupValue = null;
    this.radioGroupOptions = this.termManager.getOptions(this.project);
    this.isVisibleRadioGroup = true;

    // for option we should detect changes.
    if (!this.isOption) {
      this.leaseTerm = {...this.leaseTerm};
    }

    if (this.isOption) {
      const multiplyOption = this.radioGroupOptions.find(x => x.value === TermTypeOption.MultiplyOptions);
      multiplyOption.visible = false;
    }

    if (!this.isOption && this.project && this.termsPageService.isShowMultiplyOptionsInTerm(this.project, this.lease)) {
      const multiplyOption = this.radioGroupOptions.find(x => x.value === TermTypeOption.MultiplyOptions);
      multiplyOption.visible = true;
    }

    if (
      this.leaseTerm.termType === models.TermTypeEnum.MultiplyOptions ||
      this.leaseTerm.termType === models.TermTypeEnum.MultipleOptionsWithCustomValue
    ) {
      this.radioGroupValue = TermTypeOption.MultiplyOptions;
      if (this.leaseTerm && this.leaseTerm.leaseTermOptions && this.leaseTerm.leaseTermOptions.options) {
        this.termOptions = this.leaseTerm.leaseTermOptions.options;
      } else {
        this.termOptions = [];
        // add two options by default
        this.termOptions.push(<models.ITermViewModel>{leaseTermType: models.LeaseTermType.Term});
        this.termOptions.push(<models.ITermViewModel>{leaseTermType: models.LeaseTermType.Term});
      }
      if (this.isShowMultiplyOptionsTenantStep()) {
        this.radioGroupForTermOptions = this.getRadioGroupForTermOptions();
        if (this.leaseTerm && this.leaseTerm.leaseTermOptions) {
          this.radioGroupSelectedIndex = this.leaseTerm.leaseTermOptions.selectedLeaseTermIndex;
        }
        this.radioGroupTermSaved({value: this.radioGroupSelectedIndex});
        this.isVisibleRadioGroup = false;
      }
      super.ngOnInit();
      return;
    }

    if (this.leaseTerm.termValue) {
      this.radioGroupValue = this.termManager.getTermTypeOptionByTermValue(this.leaseTerm.termValue);
    }
  }

  isShowRemainderValue(): boolean {
    return this.termManager.isShowRemainderValue(this.project);
  }

  getRemainderValue(): string {
    return this.termManager.getRemainderValue(this.lease.commencementTerm);
  }

  isRequiredCommencementTerm(): boolean {
    return this.termManager.isRequiredCommencementTerm(this.project);
  }

  isShowTenantImprovementsAmortizationTermWarning(): boolean {
    if (!this.lease.tenantImprovementsTerm.isElectedForNegotiation) {
      return false;
    }

    let tiAmortizationTermValue;
    const tiTerm = this.lease.tenantImprovementsTerm;
    if (tiTerm.tenantImprovementsType === models.TenantImprovementsType.DollarAllowance) {
      if (tiTerm.tenantImprovementsAmortizationOption !== models.TenantImprovementsAmortizationOption.AmortizationOption) {
        return false;
      }

      tiAmortizationTermValue = tiTerm.tenantImprovementsAmortizationTerm;
    } else if (tiTerm.tenantImprovementsType === models.TenantImprovementsType.CustomAmortized) {
      tiAmortizationTermValue = tiTerm.tenantImprovementsAmortizedMonthsCount;
    } else {
      return false;
    }

    let leaseTermValue;
    if (this.leaseTerm.termType === models.TermTypeEnum.Custom) {
      leaseTermValue = this.leaseTerm.termValue;
    } else if (this.leaseTerm.termType === models.TermTypeEnum.MultiplyOptions) {
      if (this.leaseTerm.leaseTermOptions.selectedLeaseTerm) {
        leaseTermValue = this.leaseTerm.leaseTermOptions.selectedLeaseTerm.termValue;
      } else {
        const values = this.leaseTerm.leaseTermOptions.options.map((x) => {
          // Right now, options are always of type Custom, but this may be changed later.
          if (x.termType === models.TermTypeEnum.Custom) {
            return x.termValue;
          }

          return 0;
        });

        leaseTermValue = Math.min.apply(Math, values);
      }
    }

    // Hide warning if we're dealing with a default value somewhere.
    if (!leaseTermValue || !tiAmortizationTermValue) {
      return false;
    }

    return leaseTermValue < tiAmortizationTermValue;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
