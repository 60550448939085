<dx-scroll-view class="plan-viewer-marker-dialog-demo" width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">

    <div class="dx-fieldset">
      <p>
        Looks like you rejected this item, can you please provide a quick comment for clarity to the Tenant.
      </p>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Comment
        </div>
        <div class="dx-field-value">
          <dx-text-area name="comment" id="comment" required rows="5"
                        [isValid]="form.submitted ? comment.valid : true"
                        [(ngModel)]="rejectionReason" [autoResizeEnabled]="true"
                        #comment="ngModel">
          </dx-text-area>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && comment.hasError('required')">
            Comment is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Submit"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>
