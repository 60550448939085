import { Injectable } from '@angular/core';
import {LeaseAnalysisRepository} from './lease-analysis.repository';
import {
  ComparisonReportOptionType,
  IComparisonReportOptionViewModel, IComparisonReportSettingViewModel,
  IComparisonReportViewModel, ILeaseAnalysisOptionsViewModel, LeaseAnalysisYearType
} from '@statera/sdk/common';
import {Observable} from 'rxjs';

@Injectable()
export class LeaseAnalysisManager {
  private readonly _leaseAnalysisRepository: LeaseAnalysisRepository;
  constructor(leaseAnalysisRepository: LeaseAnalysisRepository) {
    this._leaseAnalysisRepository = leaseAnalysisRepository;
  }
  getComparisonReport(leaseId: number, optionType: ComparisonReportOptionType): Observable<IComparisonReportViewModel> {
    const param = <IComparisonReportOptionViewModel>{
      leaseId: leaseId,
      leaseYearType: LeaseAnalysisYearType.LeaseYear,
      optionType: optionType
    };
    return this._leaseAnalysisRepository.getComparisonReport(param);
  }

  changeComparisonReportSettings(comparisonReportSettings: IComparisonReportSettingViewModel) {
    return this._leaseAnalysisRepository.changeComparisonReportSettings(comparisonReportSettings);
  }

  getComparisonReportSettings(leaseId: number) {
    return this._leaseAnalysisRepository.getComparisonReportSettings(leaseId);
  }

  getTenantLeaseAnalysisData(model: ILeaseAnalysisOptionsViewModel) {
    return this._leaseAnalysisRepository.getTenantLeaseAnalysisData(model);
  }

  getLandlordLeaseAnalysisData(model: ILeaseAnalysisOptionsViewModel) {
    return this._leaseAnalysisRepository.getLandlordLeaseAnalysisData(model);
  }
}
