import { Injectable } from '@angular/core';

import { StorageDriver } from './storage.driver';

@Injectable()
export class StorageRepository {
  private readonly _storageDriver: StorageDriver;

  constructor(storageDriver: StorageDriver) {
    this._storageDriver = storageDriver;
  }

  set<T>(key: string, value: T): void {
    this._storageDriver.set(key, value);
  }

  get<T>(key: string): T {
    return this._storageDriver.get(key);
  }

  has(key: string): boolean {
    return this._storageDriver.has(key);
  }

  delete(key: string): void {
    this._storageDriver.delete(key);
  }
}
