<section>
  <div class="card mt-3 m-1">

    <div class="green-block">
    </div>

    <img src="https://i.ibb.co/7NztCLp/avatar.png" alt="">

    <h6 class="heading">
      Pending approval from the administrator
    </h6>
  </div>
</section>
