import * as models from '@statera/sdk/common';

export type SpaceUseTermType = models.SpaceUseTermType;
export const SpaceUseTermType = models.SpaceUseTermType;
export const ProjectType = models.ProjectTypeEnum;

export type Project = models.IProjectViewModel;

export interface SpaceUseOption {
  id: string;
  text: string;
  hasProduct: boolean;
}
