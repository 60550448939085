<div class="deals-by-stage-report">
  <ng-container *ngIf="isLoaded; else preloader">
    <div class="deals-by-stage-report-chart">
      <canvas baseChart
              [data]="barChartData" [options]="barChartOptions" [legend]="false" [type]="'bar'"
              (chartClick)="handleChartClickEvent($event)"
              #canvasElementRef>
      </canvas>
    </div>
  </ng-container>
  <ng-template #preloader>
    <div class="deals-by-stage-report-preloader">
      <span>
      </span>
    </div>
  </ng-template>
</div>
