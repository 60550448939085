import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignatureRepository } from './signature.repository';

import * as models from './signature.model';

@Injectable()
export class SignatureManager {
  private readonly _signatureRepository: SignatureRepository;

  constructor(signatureRepository: SignatureRepository) {
    this._signatureRepository = signatureRepository;
  }

  getSignatureInfo(token: string): Observable<models.ExternalSignature> {
    return this._signatureRepository.getSignatureInfo(token);
  }

  sendSignature(token: string) {
    return this._signatureRepository.sendSignature(token);
  }
}
