import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NegotiateComponent } from '../dashboard/components/negotiate/negotiate.component';

import { ScheduleProjectsComponent } from '../dashboard/components/schedule-projects/schedule-projects.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        redirectTo: 'dashboard/lease',
        pathMatch: 'full',
      },
      {
        path: 'dashboard/lease',
        component: NegotiateComponent,
      },
      {
        path: 'dashboard/lease/:id',
        component: NegotiateComponent,
      },
      {
        path: 'dashboard/project',
        component: ScheduleProjectsComponent,
      },
      {
        path: 'dashboard/project/:id',
        component: ScheduleProjectsComponent,
      },
    ]),
  ],
  exports: [
    RouterModule,
  ],
})
export class BrokerRouterModule {
}
