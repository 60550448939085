<dx-scroll-view width="100%" height="100%">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <ng-container *ngIf="!isPhoneNumberVerified">
          <div class="two-fa-icon">
            <img height="128" width="128" src="assets/img/2fa-lock.png" alt="2 factor authentication" />
          </div>

          <ng-container *ngIf="!isSmsCodeSent">
            <p *ngIf="reason === PhoneNumberVerifyReason.TwoFactorAuth">
              Two-factor authentication (2FA) makes your account more secure by verifying your identity when you log in.
            </p>

            <p>You'll get a one-time code to verify your identity. We will send a code to ({{maskedPhoneNumber}}).</p>

            <button type="button" class="btn btn-primary mt-3 two-fa-button" (click)="sendVerificationCode($event)">
              SEND
            </button>
          </ng-container>

          <ng-container *ngIf="isSmsCodeSent">
            <h3>Verify Your Phone Number</h3>

            <p>Enter the code sent to your mobile device.</p>

            <p *ngIf="isEnteredCodeInvalid">Code was incorrect, please try again.</p>

            <a (click)="sendVerificationCode($event)">Send a new code</a>

            <form class="sms-code-form" name="form" novalidate #form="ngForm" (ngSubmit)="verifyCode(form)">
              <dx-text-box
                id="code"
                name="code"
                placeholder="Enter 6 digit code"
                #code="ngModel"
                [(ngModel)]="codePhoneNumber"
                [required]="true"
              >
              </dx-text-box>
              <button type="submit" class="btn btn-primary mt-3">
                CONFIRM
              </button>
            </form>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isPhoneNumberVerified">
          <p class="success-title"><strong>Success</strong></p>

          <div class="two-fa-icon">
            <img width="128" height="128" src="assets/img/2fa-success.png" alt="Two factor authentication success" />
          </div>

          <ng-container *ngIf="reason === PhoneNumberVerifyReason.TwoFactorAuth">
            <h5>Your two-factor authentication was set up successfully.</h5>
            <p>After applying, you'll need to enter both your password and a security code from your mobile device to sign in to your account.</p>
          </ng-container>

          <ng-container *ngIf="reason === PhoneNumberVerifyReason.SmsNotifications">
            <h5>Your SMS Notifications preferences was set up successfully.</h5>
            <p>SMS notifications has been turned ON.</p>
          </ng-container>

          <button type="submit" class="btn btn-primary mt-3 two-fa-button" (click)="close()">
            FINISH
          </button>
        </ng-container>
      </div>
    </div>
  </div>


</dx-scroll-view>

