import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionalYearDate',
})
@Injectable()
export class OptionalYearDatePipe implements PipeTransform {
  private readonly _angularDatePipe: DatePipe;

  constructor(angularDatePipe: DatePipe) {
    this._angularDatePipe = angularDatePipe;
  }

  transform(value: any, ...args: Array<any>): any {
    // tslint:disable-next-line:prefer-const
    let [format, ...rest] = args;
    if (!format) {
      format = 'MMM d';
    }

    const now = new Date();
    const parsedDate = new Date(Date.parse(value));
    if (now.getFullYear() !== parsedDate.getFullYear()) {
      format += ', y';
    }

    return this._angularDatePipe.transform(value, format, ...rest);
  }
}
