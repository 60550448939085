import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { ImageViewerImageMarkerRef, ImageViewerImageRef } from '../../models/image-viewer-image.model';

@Component({
  templateUrl: 'image-viewer-marker-dialog.component.html',
  styleUrls: ['image-viewer-marker-dialog.component.scss'],
})
export class ImageViewerMarkerDialogComponent implements OnInit {
  @ViewChild('form', { read: NgForm }) form: NgForm;

  marker: ImageViewerImageMarkerRef;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit(): void {
    this.marker = this.marker || new ImageViewerImageMarkerRef(new ImageViewerImageRef(), 0, 0);
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    this._dialogRefService.outputData = {
      marker: this.marker,
    };

    this.close();
  }

  close(): void {
    if (!this._dialogRefService) {
      return;
    }

    this._dialogRefService.hide();
  }
}
