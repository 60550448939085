import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import * as models from '../../../infrastructure/models/generated';
import { AvatarListItem } from '../../../shared/components/avatar-list/avatar-list.component';
import { ProjectService } from '../../../shared/services/project.service';

@Component({
  selector: 'app-team-members-avatars',
  templateUrl: './team-members-avatars.component.html',
  styleUrls: ['./team-members-avatars.component.scss']
})
export class TeamMembersAvatarsComponent implements OnInit, OnDestroy, OnChanges {

  readonly StateraClaimType: typeof models.StateraClaimTypeAsEnum = models.StateraClaimTypeAsEnum;
  readonly StateraClaimValue: typeof models.StateraClaimValueAsEnum = models.StateraClaimValueAsEnum;

  @Input()
  projects: Array<models.IProjectViewModel>;
  @Input()
  teamMembers: Array<models.ITeamMemberViewModel>;
  @Input()
  leaseId: number;
  @Input()
  lease: models.ILeaseViewModel;
  info: models.IStartupInfoViewModel;
  avatarItems: Array<AvatarListItem>;

  private readonly _projectService: ProjectService;
  private readonly _destroy$: Subject<void>;
  private readonly _authService: AuthService;

  constructor(projectService: ProjectService, authService: AuthService) {
    this._projectService = projectService;
    this._destroy$ = new Subject<void>();
    this._authService = authService;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getAvatarList(teamMembers: Array<models.ITeamMemberViewModel>): Array<AvatarListItem> {
    return teamMembers.map(teamMember => new AvatarListItem(teamMember.fullName,
      teamMember.firstName, teamMember.lastName, teamMember.avatar ? teamMember.avatar.url : null, teamMember.companyName));
  }

  getProjectIndicatorClass(leaseId: number): string {
    if (this.projects) {
      return this._projectService.getProjectIndicatorClass(leaseId, this.projects, this.lease);
    }

    return 'closed';
  }

  getCurrentUserInitials(): string {
    if (this._authService.startupInfo) {
      return this._authService.startupInfo.firstName[0].toUpperCase() +
        this._authService.startupInfo.lastName[0].toUpperCase();
    }

    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.teamMembers &&
      (changes.teamMembers.isFirstChange() ||
      (changes.teamMembers.previousValue !== changes.teamMembers.currentValue))
    ) {
      this.avatarItems = this.getAvatarList(changes.teamMembers.currentValue);
    }
  }
}
