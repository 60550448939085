import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsFeatureEnabledDirective } from './directives/is-feature-enabled.directive';
import {InputDirective} from './directives/autoResizableInput.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
    declarations: [
        IsFeatureEnabledDirective,
        InputDirective,
    ],
    exports: [
        IsFeatureEnabledDirective,
        InputDirective,
    ],
})
export class FeatureTogglingModule {
  static forRoot(): ModuleWithProviders<FeatureTogglingModule> {
    return {
      ngModule: FeatureTogglingModule,
      providers: [],
    };
  }
}
