import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../../auth/services/auth.service';

import * as models from '../models/generated';

@Injectable({
  providedIn: 'root',
})
export class NoActiveUserStatusGuard implements CanActivate {
  static readonly NoActiveUserStatusPageUrl = '/profile/no-active-user-status';

  private readonly _authService: AuthService;
  private readonly _router: Router;

  constructor(
    authService: AuthService,
    router: Router,
  ) {
    this._authService = authService;
    this._router = router;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;

    return this._authService
      .infoLoadComplete
      .pipe(
        map((startupInfo) => {
          if (!startupInfo || startupInfo.userStatus === models.UserStatus.Deactivated) {
            if (NoActiveUserStatusGuard.NoActiveUserStatusPageUrl !== url) {
              return this._router.createUrlTree(['profile', 'no-active-user-status']);
            }
          }

          return true;
        }),
        catchError(() => {
          return of(true);
        }),
      );
  }
}
