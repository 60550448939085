import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import moment from 'moment';

import { MessageManager } from '../message.manager';

import { LeaseFloorPlanCommentRepository } from './lease-floor-plan-comment.repository';

import * as models from './lease-floor-plan-comment.model';

export class LeaseFloorPlanCommentManager extends MessageManager<models.LeaseFloorPlanComment> {
  private readonly _startupInfo: models.StartupInfo;
  private readonly _leaseId: number;
  private readonly _floorPlanId: number;
  private readonly _lease: models.Lease;

  private readonly _leaseFloorPlanCommentRepository: LeaseFloorPlanCommentRepository;

  constructor(
    leaseFloorPlanCommentRepository: LeaseFloorPlanCommentRepository,
    startupInfo: models.StartupInfo,
    leaseId: number,
    floorPlanId: number,
    lease: models.Lease,
  ) {
    super();

    this._leaseFloorPlanCommentRepository = leaseFloorPlanCommentRepository;

    this._startupInfo = startupInfo;
    this._leaseId = leaseId;
    this._floorPlanId = floorPlanId;
    this._lease = lease;
  }

  addMessage(message: models.Message) {
    return this._leaseFloorPlanCommentRepository
      .addMessage(
        {
          ...this.fromMessage(message),
          authorId: this._startupInfo.id,
          leaseId: this._leaseId,
          floorPlanId: this._floorPlanId,
        },
      )
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  getMessages(skip: number, take: number): Observable<Array<models.Message>> {
    return this._leaseFloorPlanCommentRepository
      .getMessages(
        this._leaseId,
        this._floorPlanId,
        skip,
        take,
      )
      .pipe(
        map(response => response.map(item => this.toMessage(item))),
        share(),
      );
  }

  updateMessage(message: models.Message): Observable<models.Message> {
    return this._leaseFloorPlanCommentRepository
      .updateMessage(
        this.fromMessage(message),
      )
      .pipe(
        map(() => message),
        share(),
      );
  }

  deleteMessage(message: models.Message): Observable<void> {
    return this._leaseFloorPlanCommentRepository
      .deleteMessage(
        this.fromMessage(message),
      );
  }

  markAllAsRead(): Observable<void> {
    return this._leaseFloorPlanCommentRepository
      .markAllAsRead(
        this._leaseId,
        this._floorPlanId,
      )
      .pipe(
        share(),
      );
  }

  toMessage(x: models.LeaseFloorPlanComment): models.Message {
    const result = <models.Message>{
      id: x.id,
      content: x.content,
      createdOn: x.createdOn,
      isMy: this._startupInfo.id === x.authorId,
      isRead: x.isRead,
      authorDisplayName: x.author ? (x.author.displayName ? x.author.displayName : x.author.primaryEmail) : '',
      edited: this.isEdited(x),
      messageKind: x.messageKind,
      data: {
        leaseId: x.leaseId,
        floorPlanId: x.floorPlanId,
        authorId: x.authorId
      },
      badge: this.getChatBadgeText(this._lease, x.authorId),
      isBrokerMessage: this.isBrokerMessage(this._lease, x.authorId),
    };
    let avatarUrl = 'assets/img/avatar.png';
    if (x.author && x.author.avatar && x.author.avatar) {
      avatarUrl = x.author.avatar.thumbnailUrl ? x.author.avatar.thumbnailUrl : x.author.avatar.url;
    }
    result.authorAvatarUrl = avatarUrl;
    return result;
  }

  fromMessage(x: models.Message): models.LeaseFloorPlanComment {
    return <models.LeaseFloorPlanComment>{
      id: x.id,
      content: x.content,
      authorId: x.data ? x.data.authorId : null,
      leaseId: x.data ? x.data.leaseId : null,
      floorPlanId: x.data ? x.data.floorPlanId : null,
      fileId: x.uploadedFileId,
    };
  }

  isEdited(x: models.LeaseFloorPlanComment): boolean {
    try {
      return (
        x.createdOn &&
        x.updatedOn &&
        moment(x.updatedOn).isAfter(moment(x.createdOn))
      );
    } catch (e) {
      return false;
    }
  }
}
