import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ProfileService } from '../../../user/services/profile.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-onboadring-popup',
  templateUrl: './onboadring-popup.component.html',
  styleUrls: ['./onboadring-popup.component.scss']
})
export class OnboadringPopupComponent implements OnInit {

  popupVisible = false;
  sliderPosition = 0;
  @ViewChild('slider') slider: ElementRef;
  shift = 0;
  private _profileService: ProfileService;
  private _authService: AuthService;

  constructor(profileService: ProfileService, authService: AuthService) {
    this._profileService = profileService;
    this._authService = authService;
  }

  ngOnInit() {
  }

  hidePopup() {
    this.popupVisible = false;
    const skipIntroSubscription =  this._profileService.skipIntro(models.IntroType.Onboadring).subscribe(() => {
      this._authService.startupInfo.introsShown.push(<models.IUserIntroViewModel>{
        userId: this._authService.startupInfo.id,
        introType: models.IntroType.Onboadring
      });
      skipIntroSubscription.unsubscribe();
    });
  }

  showPopup() {
    this.popupVisible = true;
  }

  sliderNext() {
    this.sliderPosition += 1;
    const shift = this.slider.nativeElement.children[0].clientWidth;
    this.shift = shift * -1;
  }

  sliderPrev() {
    this.sliderPosition -= 1;
    this.shift = 0;
  }

}
