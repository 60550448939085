import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';

import { AlertService } from '../../../alert/services/alert.service';
import { AuthService } from '../../../auth/services/auth.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { ProjectAccessService } from '../../../shared/services/project-access.service';
import { ProjectService } from '../../../shared/services/project.service';
import { TermsPageService } from '../../services/terms-page.service';

import * as models from '../../../infrastructure/models/generated';
import { LoaderService } from '../../../infrastructure/services/loader.service';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss'],
})
export class SignDocumentComponent implements OnInit, OnDestroy {
  @ViewChild('eversignContainerElementRef') eversignContainerElementRef: ElementRef;

  @Output()
  previewLOI: EventEmitter<void> = new EventEmitter<void>();

  isShowSigningBlock: boolean;
  isShowExternalSigningBlock: boolean;
  role: string;

  @Input() project: models.IProjectViewModel;
  @Input() lease: models.ILeaseViewModel;

  @Output() signChange = new EventEmitter();
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  leaseSignature: models.ILeaseSignViewModel;
  leaseTeam: models.LeaseTeam;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _leaseManager: LeaseManager;
  private readonly _leaseService: LeaseService;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _projectService: ProjectService;
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private readonly _termsPageService: TermsPageService;
  private readonly _loaderService: LoaderService;

  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    leaseManager: LeaseManager,
    leaseService: LeaseService,
    projectAccessService: ProjectAccessService,
    projectService: ProjectService,
    stateraUserClaimsService: StateraUserClaimManager,
    termsPageService: TermsPageService,
    loaderService: LoaderService
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._authService = authService;
    this._leaseManager = leaseManager;
    this._leaseService = leaseService;
    this._projectAccessService = projectAccessService;
    this._projectService = projectService;
    this._stateraUserClaimManager = stateraUserClaimsService;
    this._termsPageService = termsPageService;
    this._loaderService = loaderService;

    this._destroy$ = new Subject();
  }

  isTenant() {
    return this._authService.isTenant();
  }

  ngOnInit() {
    this._leaseService
      .getLeaseSignature(this.lease.id)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(leaseSignature => {
        this.role = this._authService.role;
        this.leaseSignature = leaseSignature;
        this.leaseTeam = this._leaseManager.getUserLeaseTeam(this.lease, this._authService.userId, this.role);

        const hasWriteAccess = this._projectAccessService
          .checkAccessToRenewalProject(
            this.project.projectState.renewalProjectTemplateItemType,
            this.project,
            this.lease
          );

        if (!hasWriteAccess) {
          return;
        }

        this._loaderService.show();

        this._projectService
          .updateSignDocumentState(this.lease)
          .pipe(
            switchMap(isUpdated => {
              // If the document signature is updated at our side we must reload the project to prevent state stuck.
              if (isUpdated) {
                return this._projectService
                  .getProjectsByLeaseId(this.lease.id)
                  .pipe(
                    tap(projects => {
                      this.project = this._projectService.getActiveProject(projects);
                    }),
                    map(_ => isUpdated),
                  );
              }

              return of(isUpdated);
            }),
            takeUntil(this._destroy$),
          )
          .subscribe((isUpdated) => {
            if (!isUpdated && this.leaseTeam === models.LeaseTeam.TenantTeam) {
              this.openSignatureFrame();
            }
          })
          .add(() => this._loaderService.hide());
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  hasActionRequired(): boolean {
    return this.project && this._projectService.hasActionRequired(this.project, this.lease);
  }

  openSignatureFrame(): void {
    if (!this.lease) {
      return;
    }

    const hasWriteAccess = this._projectAccessService.checkAccessToRenewalProject(
      this.project.projectState.renewalProjectTemplateItemType,
      this.project,
      this.lease
    );

    if (!hasWriteAccess) {
      return;
    }

    if (
      this.leaseTeam === models.LeaseTeam.TenantTeam &&
      this.leaseSignature &&
      this.leaseSignature.isExternalTenantEmail
    ) {
      this.isShowExternalSigningBlock = true;
      return;
    }

    if (
      this.leaseTeam === models.LeaseTeam.LandlordTeam &&
      this.leaseSignature &&
      this.leaseSignature.isExternalLandlordEmail
    ) {
      this.isShowExternalSigningBlock = true;
      return;
    }

    if (
      !this._stateraUserClaimManager.checkUserAccess(
        models.StateraClaimTypeAsEnum.Signature_Sign,
        models.StateraClaimValueAsEnum.Write,
        null,
        null,
        null,
        this.lease.id
      )
    ) {
      return;
    }

    let url = null;
    if (this.leaseTeam === models.LeaseTeam.TenantTeam) {
      url = this.leaseSignature.embeddedSigningUrlForTenant;
    }

    if (this.leaseTeam === models.LeaseTeam.LandlordTeam) {
      url = this.leaseSignature.embeddedSigningUrlForLandlord;
    }

    if (!url) {
      this._alertService.pushErrorAlert({
        message: this._alertMessagesManager.getEversignLoadErrorAlertText(),
      });

      return;
    }

    this.isShowSigningBlock = true;

    if (this.eversignContainerElementRef && this.eversignContainerElementRef.nativeElement) {
      this.eversignContainerElementRef.nativeElement.innerHTML = null;
    }

    this._loaderService.show();

    (<any>window).eversign.open({
      url: url,
      containerID: 'eversignContainerId',
      width: '100%',
      height: '100%',
      events: {
        loaded: () => {
          this._loaderService.hide();
        },
        error: (e) => {
          this._loaderService.hide();
        },
        signed: () => {
          this._loaderService.show();

          const subscription = this
            ._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.SignDocument, this.project)
            .subscribe(x => {
              this.signChange.emit();

              subscription.unsubscribe();
            })
            .add(() => this._loaderService.hide());
        },
      },
    });
  }
}
