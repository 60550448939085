<div class="steps" id="steps"
     *ngIf="visible && !this.isMobile"
     [ngClass]="cssClassesForSteps" [ngStyle]="stepsStyle">
  <div class="circles">
    <div *ngFor="let item of steps; let index=index;" class="circle"
         [class.choose]="current === index"
         (click)="goto(index)">
    </div>
  </div>
  <div class="skip" (click)="next()" id="skip">
    <p>
      <b>Next</b>
    </p>
    <div class="arrow">></div>
  </div>
</div>

<div class="steps skip-button"
     *ngIf="visible && !this.isMobile"
     [ngClass]="cssClassesForSkipButton" [ngStyle]="stepsStyle">
  <div class="skip" (click)="skip()" id="skip">
    <p>
      <b>Skip</b>
    </p>
    <div class="arrow">></div>
  </div>
</div>

<!--[attr.class]="getCssClassesForSkipButton()"-->
