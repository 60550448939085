import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { TermManager } from '@statera/sdk/term';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { OrderByPipe } from '../../../infrastructure/pipes/order-by.pipe';

import * as models from '../../../infrastructure/models/generated';

export interface TableRow {
  buildingAddress: string;
  unit: string;
  squareFeet: string;
  tenantCompanyName: string;
  totalDaysInStage: number;
  daysInStageTenant: number;
  daysInStageLandlord: number;
  totalDaysInStageVsBenchmark: number;
  totalDaysInStageVsAverage: number;
  buildingId: number;
  status: string;
  inActiveLease: boolean;
  leaseId: number;
}

@Component({
  selector: 'app-analytics-deals-by-stage-report-single-stage-table',
  templateUrl: 'deals-by-stage-report-single-stage-table.component.html',
  styleUrls: ['deals-by-stage-report-single-stage-table.component.scss']
})
export class DealsByStageReportSingleStageTableComponent implements OnChanges {
  @Input() dealsByStageLeaseStage: models.IDealsByStageReportLeaseStageViewModel;

  orderByConfig: string;
  tableData: Array<TableRow>;

  private readonly _router: Router;
  private readonly _orderByPipe: OrderByPipe;
  private readonly _dialogRefService: DialogRefService;
  private readonly _termManager: TermManager;

  constructor(
    router: Router,
    orderByPipe: OrderByPipe,
    dialogRefService: DialogRefService,
    termManager: TermManager,
  ) {
    this._router = router;
    this._orderByPipe = orderByPipe;
    this._dialogRefService = dialogRefService;
    this._termManager = termManager;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.dealsByStageLeaseStage &&
      (
        changes.dealsByStageLeaseStage.isFirstChange() ||
        (
          (changes.dealsByStageLeaseStage.previousValue && changes.dealsByStageLeaseStage.currentValue) &&
          changes.dealsByStageLeaseStage.previousValue.stageId !== changes.dealsByStageLeaseStage.currentValue.stageId
        )
      )
    ) {
      this.tableData = this.getTableData();
    }
  }

  applyColumnOrder(columnName: string): void {
    this.orderByConfig = columnName === this.orderByConfig
      ? `-${columnName}`
      : columnName;

    this.tableData = this._orderByPipe.transform(this.tableData, this.orderByConfig);
  }

  getTableData(): Array<TableRow> {
    if (
      !this.dealsByStageLeaseStage ||
      !this.dealsByStageLeaseStage.table ||
      !this.dealsByStageLeaseStage.table.length
    ) {
      return [];
    }

    return this.dealsByStageLeaseStage.table
      .map(stage => {
        return <TableRow>{
          buildingAddress: stage.buildingAddress,
          unit: stage.buildingUnitName,
          squareFeet: this._getSquareFeet(stage),
          tenantCompanyName: stage.tenantCompany && stage.tenantCompany.name,
          totalDaysInStage: stage.totalDaysInStage,
          daysInStageTenant: stage.daysTakenByTenant,
          daysInStageLandlord: stage.daysTakenByLandlord,
          totalDaysInStageVsBenchmark: stage.totalDaysInStageVsBenchmark,
          totalDaysInStageVsAverage: stage.totalDaysInStageVsAverage,
          inActiveLease: stage.inActiveLease,
          status: stage.leaseStatus,
          buildingId: stage.buildingId,
          leaseId: stage.leaseId
        };
      });
  }

  navigateTo3PanelView(row: TableRow): void {
    if (!row.leaseId && !row.buildingId) {
      return;
    }

    this._dialogRefService.hideAll();

    if (row.inActiveLease && row.leaseId) {
      this._router.navigate(['colabo', 'main', row.leaseId]);
    } else {
      this._router.navigate(['landlord', 'buildings', 'building-desktop', row.buildingId], {
        queryParams: {
          'tab': 'units',
          'searchText': row.unit
        }
      });
    }
  }

  private _getSquareFeet(stage: models.IDealsByStageReportLeaseStageTableItemViewModel): string {
    if (!stage) {
      return null;
    }

    return this._termManager.getCurrentTenantSquareFootage(stage.tenantSquareFootageTerm);
  }
}
