import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js';

import * as moment from 'moment';

import * as models from '../../../infrastructure/models/generated';

import {
  ExpirationsReportTooltip,
  ExpirationsReportTooltipChartType
} from '../../models/expirations-report-tooltip.model';

import { AnalyticsTooltipFactoryService } from '../../services/analytics-tooltip-factory.service';
import { AnalyticsTooltipService } from '../../services/analytics-tooltip.service';

import { ExpirationsReportTooltipComponent } from '../expirations-report-tooltip/expirations-report-tooltip.component';

type TooltipComponentType = ExpirationsReportTooltipComponent;
type TooltipModelType = ExpirationsReportTooltip;

@Component({
  selector: 'app-analytics-expirations-report-single-period',
  templateUrl: 'expirations-report-single-period.component.html',
  styleUrls: ['expirations-report-single-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationsReportSinglePeriodComponent {
  @ViewChild('canvasElementRef') canvasElementRef: ElementRef;

  @Input() set expirationsReportPeriod(value: models.IExpirationsReportPeriodViewModel) {
    this._expirationsReportPeriod = value;
    this._configureChart();
  }

  get expirationsReportPeriod() {
    return this._expirationsReportPeriod;
  }

  barChartOptions: ChartOptions<'bar'>;
  barChartData: ChartConfiguration<'bar'>['data'];

  private _expirationsReportPeriod: models.IExpirationsReportPeriodViewModel;
  private _tooltipComponent: TooltipComponentType;

  private readonly _analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>;

  constructor(analyticsTooltipFactoryService: AnalyticsTooltipFactoryService) {
    this._analyticsTooltipService = analyticsTooltipFactoryService.create<TooltipComponentType, TooltipModelType>();
  }

  private _configureChart(): void {
    this.barChartData = {
      labels: [],
      datasets: [],
    };
    this.barChartOptions = {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 5
          },
        },
        y: {
          grid: {
            color: '#DCDFE5',
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 10,
            callback: (label: number): string => {
              if (1000 <= label) {
                if (1000000 <= label) {
                  return `${label / 1000000}M`;
                }

                return `${label / 1000}K`;
              }

              return `${label}`;
            }
          },
          suggestedMin: 0,
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          external: (x) => this._externalTooltipHandler(x.tooltip),
        },
        legend: {
          display: false,
        },
      },
    };

    if (this.expirationsReportPeriod && this.expirationsReportPeriod.items) {
      this.barChartData.labels = this.expirationsReportPeriod.items.map(x => moment(x.startDate).format('MMMM'));
      this.barChartData.datasets = [
        {
          data: this.expirationsReportPeriod.items.map(x => x.totalSquareFeet),
          label: 'Expiring SF',
          backgroundColor: 'rgba(240, 156, 148, 1)',
          hoverBackgroundColor: 'rgba(231, 90, 77, 1)',
          borderColor: 'rgba(255, 255, 255, 0)',
          hoverBorderColor: 'rgba(255, 255, 255, 0)',
          borderWidth: 3,
          barThickness: 13,
          borderSkipped: false,
          borderRadius: [
            { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
            { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
          ],
        },
      ];
    }
  }

  private _externalTooltipHandler(tooltipModel: TooltipModel<'bar'>): void {
    if (this.canvasElementRef && this.canvasElementRef.nativeElement) {
      this.canvasElementRef.nativeElement.classList.remove('cursor-pointer');
    }

    if (!tooltipModel.getActiveElements().length) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    if (this._tooltipComponent) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
    }

    if (
      !tooltipModel || !tooltipModel.dataPoints || !tooltipModel.dataPoints.length ||
      !this.canvasElementRef || !this.canvasElementRef.nativeElement
    ) {
      return;
    }

    this.canvasElementRef.nativeElement.classList.add('cursor-pointer');

    const activeItemIndex = tooltipModel.dataPoints[0].dataIndex;

    const expirationsReportPeriodItem = this.expirationsReportPeriod.items[activeItemIndex];
    if (!expirationsReportPeriodItem) {
      return;
    }

    const {x, y, height, base, horizontal} = <any>tooltipModel.getActiveElements()[0].element;

    if (horizontal) {
      tooltipModel.x = tooltipModel.caretX = <number>base + (<number>x - <number>base) / 2;
      tooltipModel.y = tooltipModel.caretY = <number>y + <number>height / 2;
    } else {
      tooltipModel.x = tooltipModel.caretX = <number>x;
      tooltipModel.y = tooltipModel.caretY = <number>base;
    }

    this._tooltipComponent = this._analyticsTooltipService.show(
      ExpirationsReportTooltipComponent,
      {
        ...expirationsReportPeriodItem,
        expirationsReportTooltipChartType: ExpirationsReportTooltipChartType.SinglePeriod,
      },
      {
        tooltipModel: tooltipModel,
        canvasElement: this.canvasElementRef.nativeElement,
      },
    );
  }

  onLegendItemClick(): void {

  }
}
