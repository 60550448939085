import { Component, Input, Output, EventEmitter } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { AlertService as UIAlertService } from '../../../../alert/services/alert.service';

@Component({
  selector: 'app-term-secondary-action-buttons',
  templateUrl: './term-secondary-action-buttons.component.html',
  styleUrls: ['./term-secondary-action-buttons.component.scss'],
})
export class TermSecondaryActionButtonsComponent {
  @Input() rejectButtonClicked: boolean;
  @Input() showSaveButton = true;

  @Output() cancelLeaseTerm: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

}
