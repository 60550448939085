import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../../infrastructure/models/generated';
import { environment } from '../../../environments/environment';
import { SafeDatePipe } from '../../infrastructure/pipes/safe-date.pipe';

@Injectable()
export class QuizService {
  private _httpClient: HttpClient;
  private _safeDatePipe: SafeDatePipe;

  constructor(httpClient: HttpClient, safeDatePipe: SafeDatePipe) {
    this._httpClient = httpClient;
    this._safeDatePipe = safeDatePipe;
  }

  answer(model: models.IQuizAnswerViewModel): Observable<models.IQuizQuestionViewModel> {
    return this._httpClient.post<models.IQuizQuestionViewModel>(`${environment.webApiUrl}/quiz/Answer`, model);
  }

  getLastQuestion(quizId: number, leaseId: number): Observable<models.IQuizQuestionViewModel> {
    return this._httpClient.get<models.IQuizQuestionViewModel>(
      `${environment.webApiUrl}/quiz/GetLastQuestion?quizId=${quizId}&leaseId=${leaseId}`
    );
  }

  getOnboardingAnswers(leaseId: number, quizId: number): Observable<Array<models.IQuizAnswerViewModel>> {
    return this._httpClient
      .get<Array<models.IQuizAnswerViewModel>>(`${environment.webApiUrl}/Quiz/GetQuizAnswers?leaseId=${leaseId}&quizId=${quizId}`);
  }
}
