<div class="row mt-2">
  <div class="col-sm-6">
    <label class="b-label text-14 text-capitalize pr-2">
      Address Line 1
    </label>
    <span class="error-msg" *ngIf="initialized && !addressLine1.isValid">
      Required
    </span>
    <dx-text-box
      #addressLine1
      [(value)]="address.addressLine1">
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="col-sm-6">
    <label class="b-label text-14 text-capitalize pr-2">
      Address Line 2
    </label>
    <dx-text-box [(value)]="address.addressLine2"></dx-text-box>
  </div>
</div>

<div class="row mt-2">
  <div class="col-sm-4 ">
    <label class="b-label text-14 text-capitalize pr-2">
      Post Code
    </label>
    <span class="error-msg" *ngIf="initialized && !zipCode.isValid">
      Required
    </span>
    <dx-text-box
      #zipCode
      [(value)]="address.zipCode">
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="col-sm-4 ">
    <label class="b-label text-14 text-capitalize pr-2">
      City
    </label>
    <span class="error-msg" *ngIf="initialized && !city.isValid">
      Required
    </span>
    <dx-text-box
      #city
      [(value)]="address.city">
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="col-sm-4 ">
    <label class="text-14 text-capitalize ">
      Country
    </label>
    <span class="error-msg" *ngIf="initialized && !country.isValid">
      Required
    </span>
    <dx-select-box
      #country
      [(value)]="address.countryCode"
      [items]="countries"
      displayExpr="name"
      valueExpr="countryCode"
    >
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>
  </div>
</div>

<div class="row mt-2">
  <div class="col-sm-6">
    <label class="text-14 text-capitalize ">
      State
    </label>
    <span class="error-msg" *ngIf="initialized && !state.isValid">
      Required
    </span>
    <dx-select-box
      #state
      [(value)]="address.stateCode"
      [items]="states"
      displayExpr="name"
      valueExpr="stateCode"
      (onValueChanged)="onStateChange($event)"
    >
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>
  </div>
  <div class="col-sm-6">
    <label class="text-14 text-capitalize ">
      County
    </label>
    <dx-select-box
      #county
      [(value)]="address.countyName"
      [items]="counties"
      displayExpr="name"
      valueExpr="name"
    >
    </dx-select-box>
  </div>
</div>
