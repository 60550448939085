import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { TermCustomValueTemplateManager } from './term-custom-value-template.manager';
import { TermCustomValueTemplateRepository } from './term-custom-value-template.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    TermCustomValueTemplateManager,
    TermCustomValueTemplateRepository,
  ],
})
export class TermCustomValueTemplateModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TermCustomValueTemplateModule> {
    return {
      ngModule: TermCustomValueTemplateModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TermCustomValueTemplateManager,
        TermCustomValueTemplateRepository,
      ],
    };
  }
}
