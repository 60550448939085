import { Component, Input, OnInit } from '@angular/core';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '@statera/sdk/common';

export enum KeyStatisticsReportExportType {
  Basic,
  LeasingActivity,
  Inquiries,
}

const BASIC_DETAILS_FIELDS = [
  { title: 'Date', value: models.LeasingActivityReportExportField.Date },
  { title: 'Tenant', value: models.LeasingActivityReportExportField.Tenant },
  { title: 'Deal Type', value: models.LeasingActivityReportExportField.DealType },
  { title: 'Deal Stage', value: models.LeasingActivityReportExportField.DealStage },
  { title: 'Broker Name/Company', value: models.LeasingActivityReportExportField.BrokerNameAndCompany },
  { title: 'Size', value: models.LeasingActivityReportExportField.Size },
  { title: 'Unit', value: models.LeasingActivityReportExportField.Unit },
  { title: 'Cancelled Deal Reason', value: models.LeasingActivityReportExportField.CancelReason },
  { title: 'Deal Comment', value: models.LeasingActivityReportExportField.DealNotes },
];

const FINANCIAL_DETAILS_FIELDS = [
  { title: 'Proposed By', value: models.LeasingActivityReportExportField.ProposedBy },
  { title: 'Term', value: models.LeasingActivityReportExportField.Term },
  { title: 'Commencement', value: models.LeasingActivityReportExportField.Commencement },
  { title: 'Expiration', value: models.LeasingActivityReportExportField.Expiration },
  { title: 'Base Rent', value: models.LeasingActivityReportExportField.BaseRent },
  { title: 'Escalations', value: models.LeasingActivityReportExportField.Escalations },
  { title: 'Free Rent', value: models.LeasingActivityReportExportField.FreeRent },
  { title: 'Commissions', value: models.LeasingActivityReportExportField.Commissions },
  { title: 'Tenant Improvements ($)', value: models.LeasingActivityReportExportField.TenantImprovements },
];

const INQUIRIES_FIELDS = [
  { title: 'Building', value: models.InquiriesReportExportField.Building },
  { title: 'Unit', value: models.InquiriesReportExportField.Unit },
  { title: 'Inquiry Date', value: models.InquiriesReportExportField.Date },
  { title: 'Tenant', value: models.InquiriesReportExportField.Tenant },
  { title: 'Size Requested', value: models.InquiriesReportExportField.SizeRequested },
  { title: 'Inquiry Type', value: models.InquiriesReportExportField.InquiryType },
  { title: 'Broker Name/Company', value: models.InquiriesReportExportField.BrokerNameAndCompany },
  { title: 'Target Occupancy', value: models.InquiriesReportExportField.TargetOccupancy },
  { title: 'Days Since Inquiry', value: models.InquiriesReportExportField.DaysSinceInquiry },
  { title: 'Comments', value: models.InquiriesReportExportField.Comments },
];

const LEASING_ACTIVITY_DEFAULT_FIELDS = [
  models.LeasingActivityReportExportField.DealStage,
  models.LeasingActivityReportExportField.BrokerNameAndCompany,
  models.LeasingActivityReportExportField.Size,
  models.LeasingActivityReportExportField.Unit,
  models.LeasingActivityReportExportField.ProposedBy,
  models.LeasingActivityReportExportField.Term,
  models.LeasingActivityReportExportField.Commencement,
  models.LeasingActivityReportExportField.Expiration,
  models.LeasingActivityReportExportField.BaseRent,
  models.LeasingActivityReportExportField.Escalations,
  models.LeasingActivityReportExportField.FreeRent,
  models.LeasingActivityReportExportField.Commissions,
  models.LeasingActivityReportExportField.TenantImprovements,
];

const INQUIRIES_DEFAULT_FIELDS: Array<models.InquiriesReportExportField> = [
  models.InquiriesReportExportField.Tenant,
  models.InquiriesReportExportField.SizeRequested,
  models.InquiriesReportExportField.InquiryType,
  models.InquiriesReportExportField.BrokerNameAndCompany,
];

@Component({
  selector: 'app-key-statistics-report-export-dialog',
  templateUrl: './key-statistics-report-export-dialog.component.html',
  styleUrls: ['./key-statistics-report-export-dialog.component.scss']
})
export class KeyStatisticsReportExportDialogComponent implements OnInit {
  @Input() reportType: KeyStatisticsReportExportType;

  KeyStatisticsReportExportType = KeyStatisticsReportExportType;
  ExportFileType = models.LandlordDealsByStageReportFileType;

  selectedFileType = models.LandlordDealsByStageReportFileType.Pdf;
  inquiriesFields = INQUIRIES_FIELDS;
  allInquiriesFieldsSelected = false;
  selectedInquiriesFields: { [key in models.InquiriesReportExportField]?: boolean } = {};

  basicDetailsFields = BASIC_DETAILS_FIELDS;
  financialDetailsFields = FINANCIAL_DETAILS_FIELDS;
  allBasicDealDetailsFieldsSelected = false;
  allFinancialDealDetailsFieldsSelected = false;
  selectedLeasingActivityFields: { [key in models.LeasingActivityReportExportField]?: boolean } = {};

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  ngOnInit() {
    if (this.reportType === KeyStatisticsReportExportType.Inquiries) {
      Object.values(models.InquiriesReportExportField)
        .filter(value => typeof value === 'number') // Ensure we only get enum values, not names
        .forEach((fieldKey: models.InquiriesReportExportField) => {
          this.selectedInquiriesFields[fieldKey] = INQUIRIES_DEFAULT_FIELDS.includes(fieldKey);
        });

        this.allInquiriesFieldsSelected =
          Object
            .values(this.selectedInquiriesFields)
            .every(value => !!value);
    }

    if (this.reportType === KeyStatisticsReportExportType.LeasingActivity) {
      Object.values(models.LeasingActivityReportExportField)
        .filter(value => typeof value === 'number') // Ensure we only get enum values, not names
        .forEach((fieldKey: models.LeasingActivityReportExportField) => {
          this.selectedLeasingActivityFields[fieldKey] = LEASING_ACTIVITY_DEFAULT_FIELDS.includes(fieldKey);
        });

        this.allBasicDealDetailsFieldsSelected =
          BASIC_DETAILS_FIELDS
            .map(x => x.value)
            .every(key => !!this.selectedLeasingActivityFields[key]);
        this.allFinancialDealDetailsFieldsSelected =
          FINANCIAL_DETAILS_FIELDS
            .map(x => x.value)
            .every(key => !!this.selectedLeasingActivityFields[key]);
    }
  }

  close(): void {
    this._dialogRefService.hide();
  }

  toggleAllInquiriesFields($event): void {
    this.allInquiriesFieldsSelected = !this.allInquiriesFieldsSelected;
    Object.keys(this.selectedInquiriesFields).forEach(key => {
      this.selectedInquiriesFields[(<models.InquiriesReportExportField><any>key)] = this.allInquiriesFieldsSelected;
    });
  }

  updateInquiriesSelectAll(): void {
    this.allInquiriesFieldsSelected = Object.values(this.selectedInquiriesFields).every(value => value);
  }

  toggleAllBasicDetailsFields(): void {
    this.allBasicDealDetailsFieldsSelected = !this.allBasicDealDetailsFieldsSelected;
    BASIC_DETAILS_FIELDS
      .map(x => x.value)
      .forEach(key => {
        this.selectedLeasingActivityFields[key] = this.allBasicDealDetailsFieldsSelected;
      });
  }

  updateBasicDetailsFieldsSelectAll(): void {
    this.allBasicDealDetailsFieldsSelected =
      BASIC_DETAILS_FIELDS
        .map(x => x.value)
        .every(x => this.selectedLeasingActivityFields[x]);
  }

  toggleAllFinancialFields(): void {
    this.allFinancialDealDetailsFieldsSelected = !this.allFinancialDealDetailsFieldsSelected;
    FINANCIAL_DETAILS_FIELDS
      .map(x => x.value)
      .forEach(key => {
        this.selectedLeasingActivityFields[key] = this.allFinancialDealDetailsFieldsSelected;
      });
  }

  updateFinancialDetailsFieldsSelectAll(): void {
    this.allFinancialDealDetailsFieldsSelected =
      FINANCIAL_DETAILS_FIELDS
        .map(x => x.value)
        .every(x => this.selectedLeasingActivityFields[x]);
  }

  isExportButtonEnabled(): boolean {
    if (this.reportType === KeyStatisticsReportExportType.Basic) {
      return true;
    }

    if (this.reportType === KeyStatisticsReportExportType.LeasingActivity) {
      const selectedFieldsCount = BASIC_DETAILS_FIELDS
        .map(x => x.value)
        .concat(FINANCIAL_DETAILS_FIELDS.map(x => x.value))
        .filter(x => this.selectedLeasingActivityFields[x])
        .length;

      return selectedFieldsCount > 2;
    }

    if (this.reportType === KeyStatisticsReportExportType.Inquiries) {
      const selectedFieldsCount = INQUIRIES_FIELDS
        .map(x => x.value)
        .filter(x => this.selectedInquiriesFields[x])
        .length;

      return selectedFieldsCount > 2;
    }
  }

  exportReportClick(): void {
    let reportType: models.LandlordDealsByStageReportType;
    let fieldsQueryString: string | null = null;

    if (this.reportType === KeyStatisticsReportExportType.Basic) {
      reportType = models.LandlordDealsByStageReportType.Basic;
      fieldsQueryString = null;
    }

    if (this.reportType === KeyStatisticsReportExportType.LeasingActivity) {
      reportType = models.LandlordDealsByStageReportType.LeasingActivity;
      fieldsQueryString =
        BASIC_DETAILS_FIELDS.map(x => x.value)
          .concat(FINANCIAL_DETAILS_FIELDS.map(x => x.value))
          .filter(x => this.selectedLeasingActivityFields[x])
          .map(x => `leasingActivityFields=${x}`)
          .join('&');
    }

    if (this.reportType === KeyStatisticsReportExportType.Inquiries) {
      reportType = models.LandlordDealsByStageReportType.Inquiries;
      fieldsQueryString =
        INQUIRIES_FIELDS.map(x => x.value)
          .filter(x => this.selectedInquiriesFields[x])
          .map(x => `inquiriesFields=${x}`)
          .join('&');
    }

    let url =
      `/api/analytics/deals-by-stage-report/export?reportType=${reportType}&fileFormat=${this.selectedFileType}`;
    if (!!fieldsQueryString) {
      url += `&${fieldsQueryString}`;
    }

    window.open(url, '_self');
  }
}
