import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import { BaseTermDirective } from '../base-term.directive';

import * as models from '../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class AssignmentComponent extends BaseTermDirective<models.IAssignmentTermViewModel> implements OnInit {
  options: any = [];

  AssignmentTermType = models.AssignmentTermType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  ngOnInit(): void {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.IAssignmentTermViewModel>{};
    }
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event): void {
    this.clearErrors();

    if (!this.leaseTerm.assignment && this.leaseTerm.assignmentTermType === models.AssignmentTermType.Custom) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.leaseTerm.assignmentTermType === models.AssignmentTermType.PerCurrentLease) {
      this.leaseTerm.assignment = this.leaseTerm.abstractValue;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Tenant shall have the right, with the consent of Landlord, ' +
            'which shall not be unreasonably withheld without the consent of the Landlord but with notice, ' +
            'to assign the lease in its entirety to any subsidiary or affiliated corporation, as well as any successor ' +
            'of Tenant resulting from a merger or consolidation into any entity under the common control of the Tenant',
          value: models.AssignmentTermType.TenantShallHaveTheRight,
        },
        {
          name: 'Custom',
          value: models.AssignmentTermType.Custom,
        },
      ];

      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.AssignmentTermType.PerCurrentLease,
      },
      {
        name:
          'Tenant shall have the right, with the consent of Landlord, ' +
          'which shall not be unreasonably withheld without the consent of the Landlord but with notice, ' +
          'to assign the lease in its entirety to any subsidiary or affiliated corporation, as well as any successor ' +
          'of Tenant resulting from a merger or consolidation into any entity under the common control of the Tenant',
        value: models.AssignmentTermType.TenantShallHaveTheRight,
      },
      {
        name: 'Custom',
        value: models.AssignmentTermType.Custom,
      },
    ];
  }
}
