import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import * as models from './user.model';
import { UserRepository } from './user.repository';

@Injectable()
export class UserManager {
  isLoading$ = new BehaviorSubject<boolean>(false);
  userChange$ = new Subject<models.User>();

  private readonly _userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this._userRepository = userRepository;
  }

  loadUser(): void {
    this.isLoading$.next(true);

    this._userRepository
      .getCurrentUserProfile()
      .subscribe((profile) => {
        this.userChange$.next(profile);
        this.isLoading$.next(false);
      });
  }

  saveUser(user: models.User): Observable<models.User> {
    this.isLoading$.next(true);

    return this._userRepository
      .saveUserProfile(user)
      .pipe(
        tap((profile) => {
          this.userChange$.next(<models.User>profile);
          this.isLoading$.next(false);
        }),
        take(1),
      );
  }

  resetPasswordRequest(emailAddress: string): Observable<any> {
    return this._userRepository.sendResetPasswordRequest({emailAddress});
  }

  setTwoFactorAuthEnabled(isEnabled: boolean): void {
    this.isLoading$.next(true);

    this._userRepository
      .setTwoFactorAuthEnabled({ isEnabled: isEnabled })
      .subscribe(() => {
        this.isLoading$.next(false);
      });
  }

  sendPhoneNumberVerificationCode(): void {
    this.isLoading$.next(true);

    this._userRepository
      .requestPhoneNumberVerificationToken()
      .pipe(
        tap(() => this.isLoading$.next(false)),
      )
      .subscribe();
  }

  handlePhoneNumberVerify(user: models.User, verificationCode: string, enable2Fa: boolean): Observable<models.VerifyPhoneNumberResult> {
    this.isLoading$.next(true);

    return this._userRepository
      .verifyPhoneNumber({ token: verificationCode, enable2Fa: enable2Fa })
      .pipe(
        tap(result => {
          if (result.success) {
            this.userChange$.next({ ...user, mobilePhoneConfirmed: true });
          }
        }),
        tap(() => this.isLoading$.next(false)),
      );
  }

  handleAvatarDelete(): Observable<void> {
    this.isLoading$.next(true);

    return this._userRepository
      .deleteAvatar()
      .pipe(tap(() => this.isLoading$.next(false)));
  }

  handleAvatarSave(sourceImageUrl?: string, avatarOptions?: any): Observable<models.StartupInfo> {
    this.isLoading$.next(true);

    return this._userRepository
      .saveAvatar({avatarOptions, sourceImageUrl})
      .pipe(tap(() => this.isLoading$.next(false)));
  }
}
