import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';
import { LoggerModule } from '@statera/sdk/logger';

import { LocalStorageDriver } from './driver/local-storage/local-storage.driver';
import { StorageDriver } from './storage.driver';
import { StorageManager } from './storage.manager';
import { StorageRepository } from './storage.repository';

@NgModule({
  imports: [
    LoggerModule,
  ],
  providers: [
    StorageManager,
    StorageRepository,
    {
      provide: StorageDriver,
      useClass: LocalStorageDriver,
    },
  ],
})
export class StorageModule {
  static forRoot(configuration: Configuration): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        StorageManager,
        StorageRepository,
        {
          provide: StorageDriver,
          useClass: LocalStorageDriver,
        },
      ],
    };
  }
}
