<form name="form"
      class="real-estate-taxes-form"
      *ngIf="isVisibleTermForm() || shouldShowRealEstateTaxesAndCamExpenses()"
      (submit)="saveLeaseTerm($event)">
  <div class="row first-row">
    <h6 class="title">
      {{ shouldShowRealEstateTaxesAndCamExpenses() ? 'Input required' : 'Please choose from the following:' }}
    </h6>
    <!-- Start: Real Estate Taxes & CAM Expenses Tooltip -->
    <div class="pl-2"
         *ngIf="showEstimatesTooltip()">
      <button type="button" class="b-btn-icon"
              (click)="$event.preventDefault()" #realEstateTaxesCamExpensesTooltip>
        <i inlineSVG="assets/img/signs-icons.svg#icon-tooltip" class="icon-info__new"></i>
      </button>
      <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                  [width]="250" [target]="realEstateTaxesCamExpensesTooltip">
        <div class="d-flex flex-column tooltip-menu">
          Some multiple choices may be read-only due to the Base Rental Rate structure that was chosen.
        </div>
      </dx-tooltip>
    </div>
    <!-- End: Real Estate Taxes & CAM Expenses Tooltip -->
  </div>
  <dx-validation-group>
    <!-- if stage is not Send RFP-->
    <div class="row required-form-row"
         *ngIf="shouldShowRealEstateTaxesAndCamExpenses()">
      <ng-container *ngTemplateOutlet="realEstateTaxesAndOperationExpensesTemplate">
      </ng-container>
    </div>

    <ng-container *ngIf="isVisibleTermForm()">
      <div class="row term-container mb-2">
        <div class="col-sm-12 radio-group-container mb-2">
          <p class="counter">Counter this term</p>
          <div class="dx-field-value">
            <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                            [dataSource]="options | filter : 'disabled'"
                            [(value)]="leaseTerm.realEstateTaxesCamExpensesTermType"
                            (valueChange)="handleRealEstateTaxesCamExpensesTermTypeChange($event); clearErrors()"
                            #dxRadioGroupComponent>
                <div class="radio"
                     *dxTemplate="let data of 'radio'"
                     [class.insert-data]="data.insertData">
                  <p *ngIf="!data.insertData; else insert">{{data.name}}</p>
                  <ng-template #insert>
                    <div class="insert-row">
                      <span>Controllable Expenses are to be capped at</span>
                      <app-input-field appIgnoreScrollwheel [id]="'percentagePerYear2'" [placeholder]="'(e.g. 5)'"
                                            [className]="'real-estate-input py-0'" [min]="0"
                                            [format]="'#0.##'" [width]="'70px'"
                                            [errorMessage]="'Percentage cap is required'"
                                            [step]="0" [max]="100000000"
                                            [errorCondition]="data.value === leaseTerm.realEstateTaxesCamExpensesTermType"
                                            [disabled]="leaseTerm?.realEstateTaxesCamExpensesTermType !== RealEstateTaxesCamExpensesTermType.PercentagePerYear"
                                            [value]="leaseTerm.percentagePerYear" [suffix]="'% Per Year'"
                                            (valueChange)="leaseTerm.percentagePerYear = $event; clearErrors()"
                                            (focusIn)="handleFieldDxFieldFocus($event)"
                                            (focusOut)="handleFieldDxFieldBlur($event)"
                                            #dxNumberBoxComponent
                      ></app-input-field>
                      <span>on </span>
                      <dx-select-box displayExpr="name" valueExpr="value" class="real-estate-input py-0" placeholder="CUMULATIVE BASIS"
                                     [dataSource]="cumulativeOptions" [inputAttr]="{ 'aria-label': 'Cumulative basis' }"
                                     [disabled]="leaseTerm?.realEstateTaxesCamExpensesTermType !== RealEstateTaxesCamExpensesTermType.PercentagePerYear"
                                     [(value)]="leaseTerm.realEstateTaxesCamExpensesCumulativeType"
                                     (valueChange)="clearErrors()">
                      </dx-select-box>
                    </div>
                    <p *ngIf="data.name">{{data.name}}</p>

<!--                     Start: Percentage cap error message -->
                    <div class="row my-2"
                         *ngIf="data.value === leaseTerm.realEstateTaxesCamExpensesTermType && dxNumberBoxComponent?.numberBox?.validationStatus === 'invalid'">
                      <span class="error-message mb-0"> Percentage cap is required </span>
                    </div>
<!--                     End: Percentage cap error message -->
                  </ng-template>
                </div>
              <dx-validator>
                <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
                </dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </div>
        </div>
        <!--col-->

      </div>
      <!--row-->

      <div class="row" *ngIf="leaseTerm?.realEstateTaxesCamExpensesTermType === RealEstateTaxesCamExpensesTermType.Custom">
        <div class="col-8 pl-0">
          <app-term-custom-value-template-textarea id="realEstateTaxesCamExpensesText"
                                                   placeholder="Input a custom clause or choose from a template"
                                                   required requiredMessage="Custom Real Estate Taxes & CAM Expenses clause is required"
                                                   [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                   [(value)]="leaseTerm.realEstateTaxesCamExpensesText"
                                                   (valueChange)="clearErrors()">
          </app-term-custom-value-template-textarea>
        </div>
      </div>
      <ng-container *ngIf="leaseTerm?.realEstateTaxesCamExpensesTermType === RealEstateTaxesCamExpensesTermType.EstimatedReTaxesOpEx">
        <div class="row first-row">
          <h6 class="title">input required</h6>
        </div>
        <div class="row required-form-row">
          <ng-container *ngTemplateOutlet="realEstateTaxesAndOperationExpensesTemplate">
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #realEstateTaxesAndOperationExpensesTemplate>
      <!-- Start: Real Estate Taxes & Operation Expenses with Modified Gross -->
      <ng-container *ngIf="isBaseRentalRateModifiedGross(); else notModifiedGross">
        <div class="col-12 px-0 mt-4">
          <div class="row">
            <div class="col-sm-12 mb-4">
              <label class="year-title mb-0">
                Please provide Base Year info for Real Estate Taxes & CAM/OpEx Expenses:
              </label>
            </div>
          </div>

          <div class="row align-items-center mb-4">
            <div class="col-sm-12">
              <label for="baseYear" class="mb-1 fw-400 base-year">
                Base Year for Expenses:
              </label>
            </div>
            <div class="col-sm-12">
              <dx-date-box id="baseYear" displayFormat="year" dateSerializationFormat="yyyy-MM-dd"
                           class="real-estate-input no-select-calendar"
                           [placeholder]="'(e.g. 2024)'"
                           [calendarOptions]="{ zoomLevel: 'decade', minZoomLevel: 'decade', maxZoomLevel: 'decade' }"
                           [(value)]="leaseTerm.baseYearForExpenses"
                           (valueChange)="clearErrors()"
                           (onValueChanged)="leaseTermPropertyChange()">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Base Year for Expenses is required">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
          </div>
        </div>
        <div class="col-12 px-0">
          <div class="row">
            <div class="col-sm-12 mb-4">
              <label class="year-title mb-0">
                Please select at least one at least one Base Year Stop for this lease structure (unselected item will require a PSF estimate):
              </label>
            </div>
          </div>

          <div class="row align-items-center mb-4 mx-2">
            <div class="col-sm-6 pl-2">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"
                       id="baseYearInfoForReTaxes" name="baseYearInfoForReTaxes"
                       [(ngModel)]="isEstimatedReTaxesStopType"
                       (ngModelChange)="clearErrors(); handleEstimatedReTaxesTypeChange()" />

                <label class="custom-control-label mb-0 base-year break-down" for="baseYearInfoForReTaxes">
                  RE Taxes Base Year
                </label>
              </div>
            </div>

            <div class="col-sm-6 pl-0">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"
                       id="baseYearInfoForOpExp" name="baseYearInfoForOpExp"
                       [(ngModel)]="isEstimatedOpExStopType"
                       (ngModelChange)="clearErrors(); handleEstimatedOpExpTypeChange()" />

                <label class="custom-control-label mb-0 base-year break-down" for="baseYearInfoForOpExp">
                   CAM/OpEx Base Year
                </label>
              </div>
            </div>
          </div>

          <div class="row align-items-center mt-n3 mx-3 mb-4"
               *ngIf="isEstimatedOpExStopType || isEstimatedReTaxesStopType">
            <div class="col-sm-6 pl-4">
              <div class="d-flex flex-row align-items-center mx-n2"
                   *ngIf="isEstimatedReTaxesStopType">
                <div class="flex-fill px-4">
                  <dx-number-box min="0" class="real-estate-input"
                                 [placeholder]="'$ (e.g. 5) ' + getBaseRentalRateUnitMetrics()"
                                 [format]="getDevExpressPSFPerUnitMetricsFormat"
                                 [(value)]="leaseTerm.estimatedReTaxes"
                                 [max]="100000000"
                                 (valueChange)="clearErrors()"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="RE Taxes is required">
                      </dxi-validation-rule>
                      <dxi-validation-rule type="custom" message="RE Taxes is required"
                                           [validationCallback]="validateZeroValues">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </div>
            </div>
            <div class="col-sm-6 pl-4">
              <div class="d-flex flex-row align-items-center mx-n2"
                   *ngIf="isEstimatedOpExStopType">
                <div class="flex-fill px-4">
                  <dx-number-box min="0" class="real-estate-input"
                                 [placeholder]="'$ (e.g. 5) ' + getBaseRentalRateUnitMetrics()"
                                 [format]="getDevExpressPSFPerUnitMetricsFormat"
                                 [(value)]="leaseTerm.estimatedOpEx"
                                 (valueChange)="clearErrors()"
                                 (onFocusIn)="handleFieldDxFieldFocus($event)"
                                 (onFocusOut)="handleFieldDxFieldBlur($event)">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="CAM/OpEx is required">
                      </dxi-validation-rule>
                      <dxi-validation-rule type="custom" message="CAM/OpEx is required"
                                           [validationCallback]="validateZeroValues">
                      </dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </div>
            </div>
          </div>

          <div class="row pl-3 mb-4 mt-n2"
               *ngIf="(!isEstimatedOpExStopType && !isEstimatedReTaxesStopType)">
            <span class="error-text text-uppercase">
              At least one stop must be selected for Modified Gross lease structure
            </span>
          </div>

          <ng-container *ngIf="(!isEstimatedOpExStopType && isEstimatedReTaxesStopType || !isEstimatedReTaxesStopType && isEstimatedOpExStopType)">
            <div class="row">
              <div class="col-sm-12 mb-2">
                <label class="year-title mb-0">
                  Please provide an estimate for the unselected item:
                </label>
              </div>
            </div>

            <div class="row align-items-center mt-1 mb-4">
              <div *ngIf="!isEstimatedReTaxesStopType"
                   class="col-sm-6">
                <label>
                  RE Taxes Estimate
                </label>
                <div class="d-flex flex-row align-items-center mx-n2">
                  <div class="flex-fill px-2">
                    <dx-number-box min="0" class="real-estate-input"
                                   [placeholder]="'$ (e.g. 5) ' + getBaseRentalRateUnitMetrics()"
                                   [format]="getDevExpressPSFPerUnitMetricsFormat"
                                   [(value)]="leaseTerm.estimatedReTaxes"
                                   (valueChange)="clearErrors()"
                                   (onFocusIn)="handleFieldDxFieldFocus($event)"
                                   (onFocusOut)="handleFieldDxFieldBlur($event)">
                      <dx-validator>
                        <dxi-validation-rule type="required" message="RE Taxes is required">
                        </dxi-validation-rule>
                        <dxi-validation-rule type="custom" message="RE Taxes is required"
                                             [validationCallback]="validateZeroValues">
                        </dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                  </div>
                </div>
              </div>
              <div *ngIf="!isEstimatedOpExStopType"
                   class="col-sm-6">
                <label>
                  CAM/OpEx Estimate
                </label>
                <div class="d-flex flex-row align-items-center mx-n2">
                  <div class="flex-fill px-2">
                    <dx-number-box min="0" class="real-estate-input"
                                   [placeholder]="'$ (e.g. 5) ' + getBaseRentalRateUnitMetrics()"
                                   [format]="getDevExpressPSFPerUnitMetricsFormat"
                                   [(value)]="leaseTerm.estimatedOpEx"
                                   (valueChange)="clearErrors()"
                                   (onFocusIn)="handleFieldDxFieldFocus($event)"
                                   (onFocusOut)="handleFieldDxFieldBlur($event)">
                      <dx-validator>
                        <dxi-validation-rule type="required" message="CAM/OpEx is required">
                        </dxi-validation-rule>
                        <dxi-validation-rule type="custom" message="CAM/OpEx is required"
                                             [validationCallback]="validateZeroValues">
                        </dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Start: Operating Expenses Breakdown -->
          <ng-container *ngTemplateOutlet="operatingExpensesBreakdown">
          </ng-container>
          <!-- End: Operating Expenses Breakdown -->
        </div>
      </ng-container>
      <!-- End: Real Estate Taxes & Operation Expenses with Modified Gross -->
      <!-- Start: Real Estate Taxes & Operation Expenses -->
      <ng-template #notModifiedGross>
        <div class="col-12 col-sm-9 p-0 my-1">
          <div class="row mb-3">
            <h6 class="col-12 term-subtitle mt-1">
              Please provide estimated Real Estate Taxes & Operating Expenses:
            </h6>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-sm-6">
              <label class="mb-1">
                Estimated RE Taxes
              </label>
              <app-input-field [id]="'estimatedReTaxes'" [placeholder]="'(e.g. 5)'"
                                    [className]="'real-estate-input mb-0'" [min]="0"
                                    [format]="'#,##0.00' + getBaseRentalRateUnitMetrics()"
                                    [errorMessage]="'Estimated RE Taxes is required'"
                                    [value]="leaseTerm.estimatedReTaxes" [prefix]="'$'" [validationCallback]="validateZeroValues"
                                    (valueChange)="leaseTerm.estimatedReTaxes = $event; clearErrors()"
                                    (focusIn)="handleFieldDxFieldFocus($event)"
                                    (focusOut)="handleFieldDxFieldBlur($event)"
              ></app-input-field>
            </div>
            <div class="col-12 col-sm-6">
              <label class="mb-1">
                Estimated CAM
              </label>
              <app-input-field [id]="'estimatedOpEx'" [placeholder]="'(e.g. 5)'"
                                    [className]="'real-estate-input mb-0'" [min]="0"
                                    [format]="'#,##0.00' + getBaseRentalRateUnitMetrics()"
                                    [errorMessage]="'Estimated CAM/OpEx is required'"
                                    [value]="leaseTerm.estimatedOpEx" [prefix]="'$'" [validationCallback]="validateZeroValues"
                                    (valueChange)="leaseTerm.estimatedOpEx = $event; clearErrors()"
                                    (focusIn)="handleFieldDxFieldFocus($event)"
                                    (focusOut)="handleFieldDxFieldBlur($event)"
              ></app-input-field>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-sm-6">
              <label class="mb-1">Estimated Insurance</label>
              <app-input-field [id]="'insurance'" [placeholder]="'(e.g. 5)'"
                                    [className]="'real-estate-input mb-0'" [min]="0"
                                    [format]="'#,##0.00 ' + getBaseRentalRateUnitMetrics()"
                                    [errorMessage]="'Estimated Insurance is required'"
                                    [value]="leaseTerm.insurance" [prefix]="'$'" [validationCallback]="validateZeroValues"
                                    (valueChange)="leaseTerm.insurance = $event; clearErrors()"
                                    (focusIn)="handleFieldDxFieldFocus($event)"
                                    (focusOut)="handleFieldDxFieldBlur($event)"
              ></app-input-field>
            </div>
            <div class="col-12 col-sm-6">
              <label class="mb-1">Property Management Fee</label>
              <div class="property-management-fee d-flex flex-row gap-4">
                <app-input-field *ngIf="selectedManagementFeeDataSource === PropertyManagementFeeDataSourceType.PsfValue"
                                      [id]="'propertyManagementFeePsf'" [placeholder]="'(e.g. 5)'"
                                      [className]="'real-estate-input mb-0'" [min]="0"
                                      [format]="'#,##0.00'"
                                      width="80px"
                                      [errorMessage]="'MANAGEMENT FEE is required'"
                                      [value]="leaseTerm.propertyManagementFeePsf" [prefix]="'$'" [validationCallback]="validateZeroValues"
                                      (valueChange)="leaseTerm.propertyManagementFeePsf = $event; clearErrors()"
                                      (focusIn)="handleFieldDxFieldFocus($event)"
                                      (focusOut)="handleFieldDxFieldBlur($event)"
                ></app-input-field>
                <app-input-field *ngIf="selectedManagementFeeDataSource !== PropertyManagementFeeDataSourceType.PsfValue"
                                      [id]="'propertyManagementFeePercentage'" [placeholder]="'(e.g. 5)'"
                                      [className]="'real-estate-input mb-0'" [min]="0"
                                      [format]="'#,##0.00'"
                                      width="80px"
                                      [errorMessage]="'PERCENTAGE is required'"
                                      [value]="leaseTerm.propertyManagementFeePercentage" [validationCallback]="validateZeroValues"
                                      (valueChange)="leaseTerm.propertyManagementFeePercentage = $event; clearErrors()"
                                      (focusIn)="handleFieldDxFieldFocus($event)"
                                      (focusOut)="handleFieldDxFieldBlur($event)"
                ></app-input-field>

                <dx-select-box displayExpr="name" valueExpr="value" class="real-estate-input" placeholder="Select Management Fee Option"
                               width="150px"
                               [inputAttr]="{ 'aria-label': 'Management Fee Options' }"
                               [dataSource]="managementFeeDataSource"
                               [(value)]="selectedManagementFeeDataSource"
                               (onValueChanged)="handleManagementOptionsChange($event)"
                               (valueChange)="clearErrors()">
                </dx-select-box>
              </div>
            </div>
          </div>

          <!-- Start: Operating Expenses Breakdown -->
          <ng-container *ngTemplateOutlet="operatingExpensesBreakdown; context: this">
          </ng-container>
          <!-- End: Operating Expenses Breakdown -->
        </div>
      </ng-template>
      <!-- End: Real Estate Taxes & Operation Expenses -->

      <!-- Start: Operating Expenses Breakdown Template -->
      <ng-template #operatingExpensesBreakdown>
        <ng-container *ngIf="leaseTerm?.estimatedOpEx">
          <div class="row my-3">
            <div class="col-12">
              <div class="divider my-0"></div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12 d-flex align-center">
              <span class="term-subtitle my-0">
                Total OpEx Estimates: {{getTotalValue(true) | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}
              </span>

              <ng-container *ngIf="leaseTerm?.estimatedOpEx && leaseTerm?.estimatedReTaxes">
                <a role="button" class="ml-2 eye-icon cursor-pointer" inlineSVG="assets/img/eye.svg"
                   (click)="showPopover(totalEstimatesPopover)"
                   #totalEstimates>
                </a>

                <dx-popover [target]="totalEstimates" [minWidth]="300"
                            #totalEstimatesPopover>
                  <ng-container *ngIf="leaseTerm?.estimatedReTaxes && leaseTerm?.estimatedOpEx">
                    <ng-container *ngIf="isBaseRentalRateModifiedGross(); else baseRentalRateIsNotModifiedGrossTemplate">
                      <div class="row">
                        <div class="col-sm-12">
                          <ng-container *ngIf="leaseTerm.estimatedReTaxesType === RealEstateTaxesType.Stop && leaseTerm.estimatedOpExType === OperatingExpensesType.Stop">
                            (Base Year Stops)
                          </ng-container>

                          <p class="term-subtitle m-0 mb-2">
                            RE Taxes: {{leaseTerm.estimatedReTaxes | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}

                            <ng-container *ngIf="!(leaseTerm.estimatedReTaxesType === RealEstateTaxesType.Stop && leaseTerm.estimatedOpExType === OperatingExpensesType.Stop)"
                                          [ngSwitch]="leaseTerm.estimatedReTaxesType">
                              <ng-container *ngSwitchCase="RealEstateTaxesType.Stop">
                                (Base Year Stop)
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                (Estimate)
                              </ng-container>
                            </ng-container>
                          </p>

                          <p #popoverContainerElement class="term-subtitle my-0">
                            CAM/OpEx: {{leaseTerm.estimatedOpEx | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}

                            <ng-container *ngIf="!(leaseTerm.estimatedReTaxesType === RealEstateTaxesType.Stop && leaseTerm.estimatedOpExType === OperatingExpensesType.Stop)"
                                          [ngSwitch]="leaseTerm.estimatedOpExType">
                              <ng-container *ngSwitchCase="OperatingExpensesType.Stop">
                                (Base Year Stop)
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                (Estimate)
                              </ng-container>
                            </ng-container>

                            <ng-container *ngIf="getRealEstateTaxesBreakdownTable() as breakdownTable">
                              <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                                   [leaseTermCustomTable]="breakdownTable">
                              </app-lease-term-custom-table-popover>
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #baseRentalRateIsNotModifiedGrossTemplate>
                      <div class="row">
                        <div class="col-sm-12 px-0">
                          <table class="table real-estate-table">
                            <tbody>
                            <tr>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-start">
                                  <span>
                                    RE Taxes:
                                  </span>
                                </div>
                              </td>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-end">
                                  <span>
                                    {{leaseTerm.estimatedReTaxes | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-start">
                                  <span>
                                    CAM/OpEx:
                                  </span>
                                </div>
                              </td>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-end"
                                     #popoverContainerElement>
                                  <span>
                                    {{leaseTerm.estimatedOpEx | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}
                                    <ng-container *ngIf="getRealEstateTaxesBreakdownTable() as breakdownTable">
                                      <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                                           [leaseTermCustomTable]="breakdownTable">
                                      </app-lease-term-custom-table-popover>
                                    </ng-container>
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-start">
                                  <span>
                                    Insurance:
                                  </span>
                                </div>
                              </td>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-end">
                                  <span>
                                    {{leaseTerm.insurance | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-start">
                                  <span>
                                    Management:
                                  </span>
                                </div>
                              </td>
                              <td class="w-25">
                                <div class="d-flex flex-column align-items-end">
                                  <span>
                                    {{getPropertyManagementFee()}}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="w-25 total-row">
                                <div class="d-flex flex-column align-items-start">
                                  <span>
                                    Total:
                                  </span>
                                </div>
                              </td>
                              <td class="w-25 total-row">
                                <div class="d-flex flex-column align-items-end">
                                  <span>
                                    {{getTotalValue(true) | safeCurrency}} {{getBaseRentalRateUnitMetrics()}}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </dx-popover>
              </ng-container>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-sm-12">
              <div class="dx-fieldset">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="breakDown" name="breakDown"
                         [(ngModel)]="isOperatingExpensesBreakdownShown"/>
                  <label class="custom-control-label mb-0 base-year break-down" for="breakDown">
                    Add Breakdown of CAM Expenses
                  </label>
                </div>
              </div>
            </div>

            <div class="col-sm-12" *ngIf="isOperatingExpensesBreakdownShown">
              <p class="break-down-info my-3">
                You can add a detailed breakdown of CAM expenses if you want. Add the Expenses in $PSF to their
                corresponding categories. Their sum should be equal to
                <span>{{getTotalValue() | safeCurrency}} PSF</span>
              </p>

              <app-real-estate-taxes-cam-expenses-breakdown-table [(realEstateTaxesCamExpensesViewModel)]="leaseTerm"
                                                                  [lease]="lease">
              </app-real-estate-taxes-cam-expenses-breakdown-table>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <!-- End: Operating Expenses Breakdown Template -->
    </ng-template>

    <!-- Start: Validation summary -->

      <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>

    <!-- End: Validation summary -->

    <div class="main-actions d-flex">
      <div>
        <div class="dx-fieldset">
          <dx-button id="button" text="Save" type="success" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
