<div class="b-list-status">
  <div class="b-list-status-header">
    <div class="name">
      Lease Terms.

      <span class="status"
            [ngClass]="getProjectIndicatorClass()">
        {{getTermStatusPage()}}
        <span class="indicator"></span>
      </span>
      <app-awaiting-lease-upload-badge [project]="currentProject"></app-awaiting-lease-upload-badge>
    </div>
  </div><!--b-list-status-header-->
</div><!--b-list-status-->
