import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  loading = false;

  private _loaderService: LoaderService;

  constructor(loaderService: LoaderService) {
    this._loaderService = loaderService;
  }

  ngOnInit() {
    this._loaderService.spinnerComponentHideSubject.subscribe(x => {
      this.hide();
    });

    this._loaderService.spinnerComponentShowSubject.subscribe(x => {
      this.show();
    });
  }

  show() {
    this.loading = true;
  }

  hide() {
    this.loading = false;
  }
}
