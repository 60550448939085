import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as models from '../../../infrastructure/models/generated';
import {BuildingBudgetManager} from '@statera/sdk/building-budget';
import {Observable, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {DialogService} from '../../../dialog/services/dialog.service';
import {BuildingBudgetDialogComponent} from './building-budget-dialog/building-budget-dialog.component';
import {IBuildingBudgetViewModel} from '../../../infrastructure/models/generated';
import {BuildingUnitBudgetDialogComponent} from './building-unit-budget-dialog/building-unit-budget-dialog.component';

@Component({
  selector: 'app-building-budget',
  templateUrl: './building-budget.component.html',
  styleUrls: ['./building-budget.component.scss']
})
export class BuildingBudgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  building: models.IBuildingViewModel;
  @Input()
  buildingUnits: Array<models.IBuildingUnitViewModel>;
  currentBudgetVersion: models.IBuildingBudgetVersionViewModel;
  selectedBudgetVersion: models.IBuildingBudgetVersionViewModel;
  buildingBudgetVersions: Array<models.IBuildingBudgetVersionViewModel>;
  private readonly _destroy$: Subject<void>;
  private readonly _buildingBudgetManager: BuildingBudgetManager;
  private readonly _dialogService: DialogService;
  constructor(buildingBudgetManager: BuildingBudgetManager, dialogService: DialogService) {
    this._buildingBudgetManager = buildingBudgetManager;
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  selectBuildingBudget(budgetVersion: models.IBuildingBudgetVersionViewModel): void {
    this.selectedBudgetVersion = budgetVersion;
  }

  adjustBuildingUnitBudget(): void {
    this._dialogService
      .show(BuildingUnitBudgetDialogComponent, {
        title: 'Adjust Unit Budgets',
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 'calc(100% - 100px)',
        maxHeight: '90%',
        containerClassNames: ['building-unit-budgets'],
        injectableData: {
          building: this.building,
          buildingUnits: this.buildingUnits,
          currentBudgetVersion: this.selectedBudgetVersion,
          buildingBudgetVersions: this.buildingBudgetVersions
        },
      })
      .onHiding
      .pipe(
        switchMap(() => (
          this.getBuildingBudgetVersions()
        )),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  saveBuildingBudget(buildingBudget: IBuildingBudgetViewModel | null = null): void {
    this._dialogService
      .show(BuildingBudgetDialogComponent, {
        title: `${!buildingBudget ? 'Set up' : 'Edit'} Budget & MLA for this building`,
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 1000,
        maxHeight: '90%',
        height: '90%',
        containerClassNames: ['building-budget'],
        injectableData: {
          building: this.building,
          buildingBudget: buildingBudget,
        },
      })
      .onHiding
      .pipe(
        switchMap(() => (
          this.getBuildingBudgetVersions()
        )),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  getBuildingBudgetVersions(isInit: boolean = false): Observable<Array<models.IBuildingBudgetVersionViewModel>> {
    return this._buildingBudgetManager
      .getBuildingBudgets(this.building.id)
      .pipe(
        tap((budgets) => {
          this.buildingBudgetVersions = budgets;
          this.currentBudgetVersion = budgets.length > 0 ? budgets[0] : null;
          if (!this.selectedBudgetVersion || isInit) {
            this.selectedBudgetVersion = this.currentBudgetVersion;
          }
        }),
        takeUntil(this._destroy$)
      );
  }

  getHistoryBuildingBudgets(): Array<any> {
    if (!this.buildingBudgetVersions || this.buildingBudgetVersions.length < 2) {
      return [];
    }
    return this.buildingBudgetVersions.slice(1, this.buildingBudgetVersions.length);
  }

  getAverageMarketRateGrowth(buildingBudget: IBuildingBudgetViewModel): number {
    return this._buildingBudgetManager.getAverageMarketRateGrowth(buildingBudget);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.building &&
      (
        changes.building.isFirstChange() ||
        (
          (changes.building.previousValue && changes.building.currentValue) &&
          (changes.building.previousValue.id !== changes.building.currentValue.id)
        )
      )
    ) {
      this.getBuildingBudgetVersions(true)
        .pipe(
          takeUntil(this._destroy$)
        ).subscribe();
    }
  }
}
