import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './term-comment.model';

@Injectable()
export class TermCommentRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addMessage(message: models.LeaseTermComment): Observable<models.LeaseTermComment> {
    return this._httpClient
      .post<models.LeaseTermComment>(
        `${this._configuration.apiEndpoint}/term/comments`,
        message,
      );
  }

  updateMessage(message: models.LeaseTermComment): Observable<models.LeaseTermComment> {
    return this._httpClient
      .put<models.LeaseTermComment>(
        `${this._configuration.apiEndpoint}/term/comments`,
        message,
      );
  }

  deleteMessage(message: models.LeaseTermComment): Observable<any> {
    const params = new HttpParams().set('leaseTermCommentId', message.id.toString());
    return this._httpClient
      .delete(
        `${this._configuration.apiEndpoint}/term/comments`, { params }
      );
  }

  getMessages(
    termCommentType: models.TermCommentType,
    leaseTermType: models.LeaseTermType,
    leaseId?: number,
    skip?: number,
    take?: number,
  ): Observable<Array<models.LeaseTermComment>> {
    let params = new HttpParams()
      .set('termCommentType', termCommentType.toString())
      .set('leaseTermType', leaseTermType.toString());

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString());
    }

    if (skip || skip === 0) {
      params = params.set('skip', skip.toString());
    }

    if (take || take === 0) {
      params = params.set('take', take.toString());
    }

    return this._httpClient
      .get<Array<models.LeaseTermComment>>(
        `${this._configuration.apiEndpoint}/term/comments`,
        {
          params,
        },
      );
  }
}
