import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

@Component({
  templateUrl: 'improvement-rejection-comment-dialog.component.html',
  styleUrls: ['improvement-rejection-comment-dialog.component.scss'],
})
export class ImprovementRejectionCommentDialogComponent {
  @ViewChild(NgForm) form: NgForm;

  rejectionReason: string;

  private readonly _dialogRefService: DialogRefService;

  constructor(dialogRefService: DialogRefService) {
    this._dialogRefService = dialogRefService;
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    this._dialogRefService.outputData = {
      rejectionReason: this.rejectionReason,
    };

    this.close();
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
