<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container mb-2">
      <div class="col-sm-12 mb-4 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options"
                          [(value)]="leaseTerm.insuranceTermType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div class="row"
         *ngIf="leaseTerm.insuranceTermType === InsuranceTermType.Custom">
      <dx-text-area width="100%" placeholder="INSERT CUSTOM INSURANCE CLAUSE"
                    [(value)]="leaseTerm.text"
                    (valueChange)="clearErrors()"
                    [autoResizeEnabled]="true">
        <dx-validator>
          <dxi-validation-rule type="required" message="Custom text is required">
          </dxi-validation-rule>
        </dx-validator>
      </dx-text-area>
    </div>

    <!-- Start: Validation summary -->
    <div class="row">
      <div class="col-sm-12">
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
      </div>
    </div>
    <!-- End: Validation summary -->

    <div class="row">
      <div class="col-sm-3 col-6">
        <div class="dx-fieldset">
          <dx-button id="button" text="Save" type="success" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="true"
                     #submitButton>
          </dx-button>
        </div>
      </div>

      <div class="col-sm-3 col-6"
           *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" class="b-btn b-btn--small b-btn-primary btn"
                     [useSubmitBehavior]="false"
                     (onClick)="onCancelLeaseTerm()">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
