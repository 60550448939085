import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './tenant-request.model';

@Injectable()
export class TenantRequestRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getById(id: number): Observable<models.TenantRequestViewModel> {
    return this._httpClient
      .get<models.TenantRequestViewModel>(
        `${this._configuration.apiEndpoint}/tenant-request/${id}`,
      );
  }

  putStatusById(id: number, status: models.TenantRequestStatus): Observable<models.TenantRequestViewModel> {
    return this._httpClient
      .put<models.TenantRequestViewModel>(
        `${this._configuration.apiEndpoint}/tenant-request/${id}/status`,
        {
          status: status,
        },
      );
  }

  deleteById(id: number, deleteViewModel?: models.TenantRequestDeleteViewModel): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/tenant-request/${id}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          body: deleteViewModel,
        },
      );
  }
}
