<section>
  <div class="lease-block">
    <div>

      <h5 *ngIf="showCurrentLeaseLanguage(lease)">
        This is the current lease language:
      </h5>

      <h5 *ngIf="showThirdPartyLease(lease)">
        <ng-container *ngIf="lease && !lease.thirdPartyLease">
          N/A
        </ng-container>

        <ng-container *ngIf="lease && lease.thirdPartyLease">
          This is the current lease language:
        </ng-container>
      </h5>

      <app-no-current-lease-provided-answer [lease]="lease">
      </app-no-current-lease-provided-answer>

      <div class="lease-text-wrapper"
           *ngIf="lease"
           #popoverContainerElement>
        <app-lease-term *ngIf="termConfiguration.leaseTermType !== LeaseTermType.Commencement"
                        [numberOfLines]="2" [termValue]="termValue" [leaseTermConfiguration]="termConfiguration"
                        [project]="project">
          <!-- Start: Rental Rate Schedule Preview Table -->
          <ng-container *ngIf="isBaseRentalRate(term)">
            <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                 [leaseTermCustomTable]="getBaseRentalRateScheduleCustomTable(term)">
            </app-lease-term-custom-table-popover>
          </ng-container>
          <!-- End: Rental Rate Schedule Preview Table -->
          <!-- Start: Size Phase-in Table -->
          <ng-container *ngIf="isTenantSquareFootagePhaseInTermValue(term)">
            <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                 [leaseTermCustomTable]="getTenantSquareFootagePhaseInCustomTable(term)">
            </app-lease-term-custom-table-popover>
          </ng-container>
          <!-- End: Size Phase-in Table -->
          <!-- Start: Rental Rate Escalation Custom Table -->
          <ng-container *ngIf="isRentalRateEscalationCustomOption(term)">
            <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                 [leaseTermCustomTable]="getRentalRateEscalationCustomTable(term)">
            </app-lease-term-custom-table-popover>
          </ng-container>
          <!-- End: Rental Rate Escalation Custom Table -->

          <!-- Start: Real Estate Taxes Breakdown table -->
          <ng-container *ngIf="isRealEstateTaxesBreakdownTable(term)">
            <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                 [leaseTermCustomTable]="getRealEstateTaxesBreakdownTable(term)">
            </app-lease-term-custom-table-popover>
          </ng-container>
          <!-- End: Real Estate Taxes Breakdown table -->
        </app-lease-term>

        <p class="lease-text mb-1" *ngIf="termConfiguration.leaseTermType === LeaseTermType.Commencement && termValue">
          {{termValue | safeDate : 'MMMM d, y'}} (Lease Commenced)
        </p>
        
        <p class="lease-text" *ngIf="termConfiguration.leaseTermType === LeaseTermType.Commencement && lease?.expirationOriginalAbstractValue">
          {{lease?.expirationOriginalAbstractValue | safeDate: "MMMM d, y"}} (Lease Expiration)
        </p>
        
      </div>
      <div class="current-rate-block" *ngIf="termConfiguration.leaseTermType === LeaseTermType.BaseRentalRate">
        <ng-container *ngIf="(marketRentalRate$ | async) as marketRentalRate">
          <p>
            <span class="text-lg">
              {{getCurrentAvgMarketRentalRate(marketRentalRate, lease.baseRentalRateTerm)}}
            </span>

            <a role="button"
               class="current-rent-tooltip"
               id="currentRentTooltip"
               (click)="tooltipVisible = !tooltipVisible">
              <svg viewBox="0 0 6 13">
                <path d="M5.581 10.167l.38.519c-.096.1-.185.2-.283.291-.767.712-1.615 1.306-2.592 1.694-.326.13-.674.213-1.018.289-.15.033-.321.014-.476-.018-.339-.07-.542-.351-.585-.752-.053-.5.05-.983.165-1.463.286-1.206.575-2.411.86-3.617.024-.098.037-.2.041-.3.006-.13-.047-.181-.168-.143-.237.073-.474.15-.7.252-.291.13-.57.287-.871.44L0 6.803c.216-.17.425-.344.645-.505.74-.537 1.517-1.01 2.362-1.362.413-.171.831-.336 1.283-.368.455-.032.693.175.747.63.054.453-.027.892-.138 1.327-.309 1.208-.625 2.415-.933 3.624-.044.17-.076.348-.085.523-.014.247.081.316.317.248.44-.126.828-.353 1.197-.616l.186-.137M4.114 2.926c-.188-.04-.384-.06-.563-.126-.414-.148-.625-.471-.686-.894-.113-.785.338-1.535 1.09-1.794.464-.16.932-.166 1.366.1.323.199.475.51.53.878.133.896-.667 1.827-1.737 1.836" />
              </svg>
            </a>

            <dx-tooltip target="#currentRentTooltip"
                        position="right"
                        width="300px"
                        [(visible)]="tooltipVisible"
                        class="tooltip-danger">
              <span *dxTemplate="let data = data; of: 'content'">
                Statistics for average rents were compiled using sources deemed reliable for similar type and size properties.
                However, Statera RE, Inc (Statera) has not verified any such information and any recipient should
                independently verify such information that may be material to any decision that recipient may
                make in response to this data.
              </span>
            </dx-tooltip>
          </p>

          <p>Current Avg. Market Rental Rate</p>
        </ng-container>
      </div>
    </div>

    <p class="lease-response" *ngIf="showTitle">Current negotiation response:</p>
  </div>
</section>
<!--b-terms-controls-->
