import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-name-edit',
  templateUrl: './name-edit.component.html',
  styleUrls: ['./name-edit.component.scss']
})
export class NameEditComponent implements OnInit {
  private readonly _authService: AuthService;

  displayName: string;

  constructor(authService: AuthService) {
    this._authService = authService;
    this.displayName = authService.displayName;
  }

  ngOnInit() {
  }
}
