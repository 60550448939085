import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { StartupInfo } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  readonly startupInfo: BehaviorSubject<StartupInfo>;

  constructor() {
    this.startupInfo = new BehaviorSubject<StartupInfo>(null);
  }
}
