import { Component, Input, OnInit } from '@angular/core';
import * as models from '../../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-security-deposit-burn-down-schedule-result',
  templateUrl: './security-deposit-burn-down-schedule-result.component.html',
  styleUrls: ['./security-deposit-burn-down-schedule-result.component.scss']
})
export class SecurityDepositBurnDownScheduleResultComponent implements OnInit {

  @Input() burnDownScheduleValues: Array<models.IBurnDownScheduleViewModel> = [];
  @Input() userRole: string;
  @Input() isAccepted: boolean;
  @Input() isLineThrough: boolean;

  ngOnInit(): void {
    this.burnDownScheduleValues = this.burnDownScheduleValues || <Array<models.IBurnDownScheduleViewModel>>[];
  }
}
