import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-expansion-option',
  templateUrl: './expansion-option.component.html',
  styleUrls: ['./expansion-option.component.scss'],
})
export class ExpansionOptionComponent extends BaseTermDirective<models.IExpansionOptionTermViewModel> implements OnInit {
  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  options: any = [];

  ExpansionOptionTermType = models.ExpansionOptionTermType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  ngOnInit() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.IExpansionOptionTermViewModel>{};
    }
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.expansionOption && this.leaseTerm.expansionOptionTermType === models.ExpansionOptionTermType.Custom) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.leaseTerm.expansionOptionTermType === models.ExpansionOptionTermType.PerCurrentLease) {
      this.leaseTerm.expansionOption = this.leaseTerm.abstractValue;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'None', value: models.ExpansionOptionTermType.None,
        },
        {
          name: 'Right of first refusal on any space subject to existing tenant\'s rights, if any',
          value: models.ExpansionOptionTermType.RightOfFirstRefusal,
        },
        {
          name: 'Right of first offer on any adjacent space subject to existing tenant\'s rights, if any',
          value: models.ExpansionOptionTermType.RightOfFirstOffer,
        },
        {
          name: 'Custom', value: models.ExpansionOptionTermType.Custom,
        },
      ];
      return;
    }
    this.options = [
      {
        name: 'Per current lease', value: models.ExpansionOptionTermType.PerCurrentLease,
      },
      {
        name: 'None', value: models.ExpansionOptionTermType.None,
      },
      {
        name: 'Right of first refusal on any space subject to existing tenant\'s rights, if any',
        value: models.ExpansionOptionTermType.RightOfFirstRefusal,
      },
      {
        name: 'Right of first offer on any adjacent space subject to existing tenant\'s rights, if any',
        value: models.ExpansionOptionTermType.RightOfFirstOffer,
      },
      {
        name: 'Custom', value: models.ExpansionOptionTermType.Custom,
      },
    ];
  }
}
