import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration, IFileViewModel} from '@statera/sdk/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FileUploadManager {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }
  uploadFiles(files: Array<File>): Observable<Array<IFileViewModel>> {
    const formData = new FormData();
    if (!files) {
      return;
    }

    for (let i = 0, num = files.length; i < num; i++) {
      const file = files[i];
      if (!file) {
        continue;
      }

      formData.append('files', file, file.name);
    }

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<Array<IFileViewModel>>(
        `${this._configuration.apiEndpoint}/storage/UploadMultiply`,
        formData,
      );
  }
}
