import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-lease-expiration',
  templateUrl: 'analytics-filters-lease-expiration.component.html',
  styleUrls: ['analytics-filters-lease-expiration.component.scss'],
})
export class AnalyticsFiltersLeaseExpirationComponent {
  @Input() leaseExpirations: Array<analyticsFiltersModels.LeaseExpirationFilter>;
  @Output() leaseExpirationsChange: EventEmitter<Array<analyticsFiltersModels.LeaseExpirationFilter>>;

  constructor() {
    this.leaseExpirationsChange = new EventEmitter<Array<analyticsFiltersModels.LeaseExpirationFilter>>();
  }

  handleLeaseExpirationSelection(): void {
    this.leaseExpirationsChange.next([...this.leaseExpirations]);
  }
}
