import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as dx from 'devextreme-angular';

import { LandingRouter } from './landing.router';
import { PlanModule } from '../plan/plan.module';
import { SharedModule } from '../shared/shared.module';

import { LandingComponent } from './components/landing/landing.component';

@NgModule({
  imports: [
    CommonModule,

    LandingRouter,
    SharedModule,
    PlanModule,

    dx.DxPopupModule,
  ],
  declarations: [
    LandingComponent,
  ]
})
export class LandingModule {
}
