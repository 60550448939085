<form class="form">
  <div class="d-flex flex-column">
    <div class="container">
      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            type of building
          </label>
          <span class="error-msg" *ngIf="initialized && !buildingType.isValid">
            Required
          </span>
          <dx-select-box
            #buildingType
            [(value)]="building.buildingType"
            [items]="buildingTypes"
            displayExpr="text"
            valueExpr="id"
            (onSelectionChanged)="typeOfBuildingSelected()"
          >
            <dx-validator>
              <dxi-validation-rule type="required" message="Portfolio is required"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            portfolio
          </label>
          <span class="error-msg" *ngIf="initialized && !portfolioId.isValid">
            Required
          </span>
          <dx-select-box
            #portfolioId
            [(value)]="building.portfolioId"
            [items]="portfolios"
            displayExpr="name"
            valueExpr="id"
          >
            <dx-validator>
              <dxi-validation-rule type="required" message="Portfolio is required"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            building name
          </label>
          <span class="error-msg" *ngIf="initialized && !name.isValid">
            Required
          </span>
          <dx-text-box #name [(value)]="building.name">
            <dx-validator>
              <dxi-validation-rule type="required" message="Building Name is required"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            Total Office Area
          </label>
          <dx-number-box
            placeholder="0.0 square feet"
            min="0" step="0"
            format="#0.### SF"
            [(value)]="building.totalOfficeArea"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            Year Renovated
          </label>
          <dx-date-box
            class="w-100"
            [(value)]="building.renovatedDate"
            [useMaskBehavior]="true"
            displayFormat="'Year': yyyy"
            type="date"
          >
          </dx-date-box>
        </div>
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            Green Building Certification
          </label>
          <dx-tag-box
            [(value)]="buildingCertificates"
            [dataSource]="certificationsDataSource"
            (onCustomItemCreating)="onCustomItemCreating($event)"
            acceptCustomValue="true"
            valueExpr="id"
            displayExpr="text"
          ></dx-tag-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            Total Drive-in Doors & Size
          </label>
          <dx-text-box
            [(value)]="building.totalDriveInDoorsSize"
            mask="#0-#0'x#0'"
            [useMaskedValue]="true"
          ></dx-text-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Sprinkler System (type)
          </label>
          <dx-select-box
            [(value)]="building.sprinklerSystem"
            [items]="sprinklerSystems"
            displayExpr="text"
            valueExpr="id"
          ></dx-select-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Year Built
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            class="w-100"
            min="0"
            [(value)]="building.builtYear"
          >
          </dx-number-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="text-14 text-capitalize ">
            Class (A, B or C)
          </label>
          <dx-select-box
            [(value)]="building.buildingClass"
            [items]="buildingClasses"
            displayExpr="text"
            valueExpr="id"
          ></dx-select-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Total Building Size (RBA)
          </label>
          <dx-number-box
            placeholder="0.0 square feet"
            min="0" step="0"
            format="#0.### SF"
            [(value)]="building.totalBuildingSize"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Land Area (acres)
          </label>
          <dx-number-box
            placeholder="0.0 Acres"
            format="#0.## Acres"
            min="0" step="0"
            [(value)]="building.totalLandArea"
          ></dx-number-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Total Exterior Docks
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            placeholder="0"
            min="0"
            format="#0"
            [(value)]="building.totalDocksExterior"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Total Interior Docks
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            placeholder="0"
            min="0"
            format="#0"
            [(value)]="building.totalDocksInterior"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Rail Line (provider)
          </label>
          <dx-text-box [(value)]="building.railLine"></dx-text-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Utility Costs Text
          </label>
          <dx-text-box
            [(value)]="building.utilityCostsText"
            placeholder="Utility Costs Text"
          ></dx-text-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Utility Costs (PSF)
          </label>
          <dx-number-box
            placeholder="0"
            min="0" step="0"
            format="#0"
            [(value)]="building.utilityCosts"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Roof Type & Condition
          </label>
          <dx-text-box
            [(value)]="building.roofTypeCondition"
            placeholder="EPDM Roof - Installed 2012"
          ></dx-text-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Ceiling Height (feet)
          </label>
          <dx-number-box
            placeholder="0.0' clear"
            min="0" step="0"
            format="#0.##' clear"
            [(value)]="building.clearHeight"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Column Spacing
          </label>
          <dx-text-box
            #columnSpacing
            [(value)]="building.columnSpacing"
            mask="#0' x #0'"
            [useMaskedValue]="true"
          ></dx-text-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Total Parking Spaces
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            placeholder="0"
            min="0"
            format="#0 car Spaces"
            [(value)]="building.totalParkingSpaces"
          ></dx-number-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Amps
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            placeholder="amps"
            min="0"
            format="#0.0"
            [(value)]="building.amps"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Volts
          </label>
          <dx-text-box
            [(value)]="building.volts"
          ></dx-text-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Phase
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            min="0"
            format="#0"
            [(value)]="building.phase"
          ></dx-number-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Current Real Estate Taxes (PSF & Year)
          </label>
          <dx-number-box
            placeholder="$0.0 PSF"
            min="0" step="0"
            format="$#########.### PSF"
            [(value)]="building.mostCurrentRealEstateTaxesPSF"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Total Real Estate Taxes (annual)
          </label>
          <dx-number-box
            min="0" step="0"
            format="$###,### "
            placeholder="$0.0"
            [(value)]="building.mostCurrentRealEstateTaxesTotal"
          ></dx-number-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Operating Expenses (PSF)
          </label>
          <dx-number-box
            placeholder="0.0 PSF"
            min="0" step="0"
            format="$######.### PSF"
            [(value)]="building.operatingExpenses"
          ></dx-number-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-6 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Recent Interior Construction or Update
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            class="w-100"
            min="1000"
            [(value)]="building.mostRecentInteriorConstructionOrUpdateYear"
          >
          </dx-number-box>
        </div>
        <div class="col-sm-6 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Recent Exterior Construction or Update
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            class="w-100"
            min="1000"
            [(value)]="building.mostRecentExteriorConstructionOrUpdateYear"
          >
          </dx-number-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-6 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Recent Interior Construction or Update Text
          </label>
          <dx-text-box
            class="w-100"
            [(value)]="building.mostRecentInteriorConstructionOrUpdateText"
          >
          </dx-text-box>
        </div>
        <div class="col-sm-6 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Recent Exterior Construction or Update Text
          </label>
          <dx-text-box
            class="w-100"
            [(value)]="building.mostRecentExteriorConstructionOrUpdateText"
          >
          </dx-text-box>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Pin Number
          </label>
          <dx-text-box
            #pinNumber
            [(value)]="building.pinNumber"
            mask="##-##-###-###-####"
            [useMaskedValue]="true"
          ></dx-text-box>
        </div>
        <div class="col-sm-4 ">
          <label class="b-label text-14 text-capitalize pr-2">
            Most Recent Sale Price
          </label>
          <dx-number-box
            placeholder="$0.0"
            min="0" step="0"
            format="$###,### "
            [(value)]="building.mostRecentSalePrice"
          ></dx-number-box>
        </div>

        <div class="col-sm-4">
          <label class="b-label text-14 text-capitalize pr-2">
            Sale Date
          </label>
          <dx-number-box
            appIgnoreScrollwheel
            type="date"
            min="1000"
            [(value)]="building.saleYear"
          >
          </dx-number-box>
        </div>
      </div>


      <br />
      <hr />
      <div class="b-header text-capitalize">
        Market
      </div>

      <div class="col-sm-3">
        <label class="text-14 text-capitalize">
          Market
        </label>
        <dx-select-box [(value)]="building.marketId"
                       [items]="markets"
                       displayExpr="name"
                       valueExpr="id"
                       (onValueChanged)="onMarketChange($event)"
                       #market>
        </dx-select-box>
      </div>
      <div class="col-sm-3">
        <label class="text-14 text-capitalize">
          SubMarket
        </label>
        <dx-select-box [(value)]="building.subMarketId"
                       [items]="subMarkets"
                       displayExpr="name"
                       valueExpr="id"
                       (onValueChanged)="onSubMarketChange($event)"
                       #subMarket>
        </dx-select-box>
      </div>

      <br />
      <hr />
      <div class="b-header text-capitalize">
        Address
      </div>
      <app-address #address [(address)]="building.address"></app-address>

      <br />
      <hr />

      <div class="row pt-5 mb-5">
        <div class="col d-flex">
          <dx-button
            id="button"
            text="Save Building"
            [useSubmitBehavior]="true"
            (click)="submit()"
            class="b-btn b-btn--small b-btn-primary btn"
          >
          </dx-button>
          <button *ngIf="building" class="btn w-25" [routerLink]="['/landlord/dashboard']">
        <span class="text-uppercase text-13 fw-500 text-opacity-secondary">
          cancel
        </span>
          </button>
        </div>
      </div>


    </div>
  </div>

</form>
