import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';


@Component({
  selector: 'app-rail',
  templateUrl: './rail.component.html',
  styleUrls: ['./rail.component.scss']
})
export class RailComponent extends BaseTermDirective<models.IRailTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  RailTermType: typeof models.RailTermType = models.RailTermType;

  private readonly _destroy$: Subject<void>;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Landlord shall provide evidence that the switch and track is operational. ' +
            'If not operational, Landlord will be responsible for any repairs, maintenance or replacement.',
          value: models.RailTermType.LandlordResponsibility,
        },
        {
          name: 'Tenant shall be responsible for any repairs, maintenance or replacement necessary to make Rail active.',
          value: models.RailTermType.TenantResponsibility,
        },
        {
          name:
            'Rail is provided by [INSERT RAIL PROVIDER] and is serviced by [INSERT SERVICE PROVIDER]. ' +
            'Rail is active, letter to be provided.',
          value: models.RailTermType.RailProvidedBy,
        },
        {
          name:
            'If required, Landlord will execute an industry track agreement with Rail line provider and be responsible ' +
            'for any associated fees. In addition, Landlord, Tenant, and Rail line provider will execute a joint use agreement.',
          value: models.RailTermType.IndustryTrackAgreementPossible,
        },
        {
          name: 'Rail is inactive. Any work required to make Rail operational shall be outlined in the Tenant Improvements section.',
          value: models.RailTermType.Inactive,
        },
        {
          name: 'Custom',
          value: models.RailTermType.Custom,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.RailTermType.PerCurrentLease,
      },
      {
        name:
          'Landlord shall provide evidence that the switch and track is operational. ' +
          'If not operational, Landlord will be responsible for any repairs, maintenance or replacement.',
        value: models.RailTermType.LandlordResponsibility,
      },
      {
        name: 'Tenant shall be responsible for any repairs, maintenance or replacement necessary to make Rail active.',
        value: models.RailTermType.TenantResponsibility,
      },
      {
        name:
          'Rail is provided by [INSERT RAIL PROVIDER] and is serviced by [INSERT SERVICE PROVIDER]. ' +
          'Rail is active, letter to be provided.',
        value: models.RailTermType.RailProvidedBy,
      },
      {
        name:
          'If required, Landlord will execute an industry track agreement with Rail line provider and be responsible ' +
          'for any associated fees. In addition, Landlord, Tenant, and Rail line provider will execute a joint use agreement.',
        value: models.RailTermType.IndustryTrackAgreementPossible,
      },
      {
        name: 'Rail is inactive. Any work required to make Rail operational shall be outlined in the Tenant Improvements section.',
        value: models.RailTermType.Inactive,
      },
      {
        name: 'Custom',
        value: models.RailTermType.Custom,
      },
    ];
  }
}
