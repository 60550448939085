<a
  [routerLink]="['../branch']">
  Branches
</a>
<br/>
<br/>

<h6>Tenant</h6>
<ul>
  <li>
    <a
      [routerLink]="['../tenant/dashboard']">
      Dashboard
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../tenant/onboarding']">
      Onboarding
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../tenant/upload-lease']">
      Upload Lease
    </a>
  </li>
</ul>

<h6>Landlord</h6>
<ul>
  <li>
    <a
      [routerLink]="['../landlord/dashboard']">
      Dashboard
    </a>
  </li>
</ul>

<h6>User</h6>
<ul>
  <li>
    <a [routerLink]="['../profile']">
      Profile
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../profile/documents']">
      Documents
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../profile/notification-settings']">
      Notification Settings
    </a>
  </li>
</ul>

<h6>Colabo</h6>
<ul>
  <li>
    <a
      [routerLink]="['../colabo/main']">
      Colabo
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../colabo/terms']">
      Terms
    </a>
  </li>
  <li>
    <a
      [routerLink]="['../colabo/messages']">
      Messages
    </a>
  </li>
</ul>

<h6>Plan</h6>
<ul>
  <li>
    <a href="javascript:;"
       (click)="planPopupVisible=true">
      Plan - development version
    </a>
  </li>
</ul>

<h6>Comparison Summary Report - Renewal</h6>
<ul>
  <li>
    <a [routerLink]="['../cash-comparison']">
      Comparison Summary Report - Renewal
    </a>
  </li>
</ul>

<h6>Test notifications</h6>
<ul>
  <li>
    <a href="javascript:;"
       (click)="testNotifications('all')">
      Test all notifications
    </a>
  </li>
</ul>
or
<ul>
  <li><a href="javascript:;"
         (click)="testNotifications('SendPasswordResetRequest')">SendPasswordResetRequest</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('SendPasswordChangeNotification')">SendPasswordChangeNotification</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('EmailVerifyRequest')">EmailVerifyRequest</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('TenantManagerNotify')">TenantManagerNotify</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('IntentToRenewNotify')">IntentToRenewNotify</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('IntentToNewDealNotify')">IntentToNewDealNotify</a></li>
  <li><a href="javascript:;"
        (click)="testNotifications('IntentToRestructureNotify')">IntentToRestructureNotify</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('UpcomingContractorVisitReminder')">UpcomingContractorVisitReminder</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('CompletedContractorVisitReminder')">CompletedContractorVisitReminder</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('TenantRenewalRequest')">TenantRenewalRequest</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('AlertEmail')">AlertEmail</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('CashFlowReportAvailability')">CashFlowReportAvailability</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('Universal')">Universal</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('FloorPlanRequestEventNotification')">FloorPlanRequestEventNotification</a></li>
  <li><a href="javascript:;"
         (click)="testNotifications('LeaseDocuments')">LeaseDocuments</a></li>
</ul>

<h6>Test Jobs</h6>
<ul>
  <li>
    <a href="javascript:;"
       (click)="testJobs('SendExpirationNotifications')">SendExpirationNotifications</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('SendCommencementNotifications')">SendCommencementNotifications</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('SendRenewalOptionsNotifications')">SendRenewalOptionsNotifications</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('SendRentEscalationNotifications')">SendRentEscalationNotifications</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('SendTerminationOptionsNotifications')">SendTerminationOptionsNotifications</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('CheckUnsolicitedOfferJob')">CheckUnsolicitedOfferJob</a></li>
  <li><a href="javascript:;"
         (click)="testJobs('SendRevisitNotificationJob')">SendRevisitNotificationJob</a></li>

</ul>

<dx-popup
  width="95%"
  height="95%"
  [maxWidth]="2500"
  [showTitle]="true"
  [showCloseButton]="true"
  [title]="'Floor plan'"
  [dragEnabled]="true"
  [(visible)]="planPopupVisible">
  <app-floor-plan *ngIf="planPopupVisible" [autoInitialize]="true"></app-floor-plan>
</dx-popup>
