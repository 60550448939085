import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';
import {Lease, LeaseSettings} from './lease.model';

import * as models from './lease.model';

@Injectable()
export class LeaseRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getElectedTermsForNegotiation(leaseId: number): Observable<Array<models.ElectedTermForNegotiation>> {
    return this._httpClient
      .get<Array<models.ElectedTermForNegotiation>>(
        `${this._configuration.apiEndpoint}/lease/${leaseId}/elected-terms-for-negotiation`,
      );
  }

  electTermsForNegotiation(leaseId: number, electedTerms: Array<models.ElectedTermForNegotiation>): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/lease/${leaseId}/elect-terms-for-negotiation`,
        electedTerms
      );
  }

  createNewDeal(model: models.BuildingUnitViewModel): Observable<models.Lease> {
    return this._httpClient.post<models.Lease>(
      `${this._configuration.apiEndpoint}/lease/newDealLease`,
      model,
    );
  }

  createNewDealInquiryByBuildingUnit(model: models.BuildingUnitViewModel): Observable<models.Lease> {
    return this._httpClient.post<models.Lease>(
      `${this._configuration.apiEndpoint}/lease/newDealInquiryLease`,
      model,
    );
  }

  getLease(leaseId: number): Observable<models.Lease> {
    return this._httpClient
      .get<models.Lease>(`${this._configuration.apiEndpoint}/lease/get?leaseId=${leaseId}`);
  }

  cancelRequest(leaseId: number): Observable<models.CancelledRequest> {
    const cancelledRequest = <models.CancelledRequest>{ leaseId: leaseId };

    return this._httpClient
      .post<models.CancelledRequest>(`${this._configuration.apiEndpoint}/lease/CancelRequest`, cancelledRequest);
  }

  getLetterOfIntent(leaseId: number): Observable<models.File> {
    return this._httpClient
      .get<models.File>(
        `${this._configuration.apiEndpoint}/leases/${leaseId}/letter-of-intent`,
      );
  }

  getLeaseActivities(leaseId: number, projectTemplateItemId: number): Observable<Array<models.LeaseActivity>> {
    const params = new HttpParams().set('projectTemplateItemId', projectTemplateItemId.toString());
    return this._httpClient
      .get<Array<models.LeaseActivity>>(
        `${this._configuration.apiEndpoint}/leases/${leaseId}/activities`, { params }
      );
  }

  saveLeaseSettings(leaseId: number, leaseSettings: models.LeaseSettings): Observable<models.LeaseSettings> {
    return this._httpClient
      .post<models.LeaseSettings>(`${this._configuration.apiEndpoint}/leases/${leaseId}/lease-settings`, leaseSettings);
  }

  getLeaseTeam(leaseId: number): Observable<models.LeaseTeamInfo> {
    return this._httpClient
      .get<models.LeaseTeamInfo>(`${this._configuration.apiEndpoint}/leases/${leaseId}/team`);
  }
}
