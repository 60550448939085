import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FeatureCollection, Point } from 'geojson';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { BuildingStore } from './building.store';

import * as models from './building.model';

@Injectable()
export class BuildingRepository {
  private readonly _configuration: Configuration;
  private readonly _buildingStore: BuildingStore;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, buildingStore: BuildingStore, httpClient: HttpClient) {
    this._configuration = configuration;
    this._buildingStore = buildingStore;
    this._httpClient = httpClient;
  }

  /**
   * Requests repository
   */

  getBuilding(buildingId: number): Observable<models.BuildingViewModel> {
    return this._httpClient
      .get<models.BuildingViewModel>(
        `${this._configuration.apiEndpoint}/building/${buildingId.toString(10)}`,
      );
  }

  getAvailableBuildingUnits(buildingId: number, exclude?: Array<number>): Observable<Array<models.BuildingUnitViewModel>> {
    let params = new HttpParams();

    if (exclude) {
      exclude.forEach((x) => {
        if (x) {
          params = params.append('exclude', x.toString(10));
        }
      });
    }

    return this._httpClient.get<Array<models.BuildingUnitViewModel>>(
      `${this._configuration.apiEndpoint}/building/${buildingId}/available-units`,
      {
        params,
      }
    );
  }

  getBuildingSitePlans(buildingId: number): Observable<Array<models.PlanViewModel>> {
    return this._httpClient.get<Array<models.PlanViewModel>>(
      `${this._configuration.apiEndpoint}/building/${buildingId.toString(10)}/site-plans`,
    );
  }

  getBuildingPointsOfInterest(buildingId: number): Observable<FeatureCollection<Point>> {
    return this._httpClient.get<FeatureCollection<Point>>(
      `${this._configuration.apiEndpoint}/building/${buildingId.toString(10)}/points-of-interest`,
    );
  }

  getDemographicsReports(buildingId: number): Observable<Array<models.DemographicsReport>> {
    return this._httpClient.get<any>(
      `${this._configuration.apiEndpoint}/building/${buildingId.toString(10)}/demographics-reports`,
    );
  }

  getBuildingsFunds(): Observable<Array<string>> {
    return this._httpClient
      .get<Array<string>>(
        `${this._configuration.apiEndpoint}/landlord/buildings-funds`
      );
  }

  getBuildingsCities(companyId?: number): Observable<Array<string>> {
    let params = new HttpParams();


    if (companyId) {
      params = params.set('companyId', companyId.toString(10));
    }

    return this._httpClient
      .get<Array<string>>(
        `${this._configuration.apiEndpoint}/buildings-cities`,
        {
          params: params,
        },
      );
  }

  /**
   * Store repository
   */

  getStoredBuilding(): Observable<models.BuildingViewModel> {
    return this._buildingStore.building;
  }

  setStoredBuilding(building: models.BuildingViewModel): void {
    this._buildingStore.building.next(building);
  }

  setStoredBuildingError(error: Error): void {
    this._buildingStore.building.error(error);
  }

  getStoredBuildingAvailableUnits(): Observable<Array<models.BuildingUnitViewModel>> {
    return this._buildingStore.buildingAvailableUnits;
  }

  setStoredBuildingAvailableUnits(availableUnits: Array<models.BuildingUnitViewModel>): void {
    this._buildingStore.buildingAvailableUnits.next(availableUnits);
  }

  setStoredBuildingAvailableUnitsError(error: Error): void {
    this._buildingStore.buildingAvailableUnits.error(error);
  }

  getStoredBuildingSitePlans(): Observable<Array<models.PlanViewModel>> {
    return this._buildingStore.buildingSitePlans;
  }

  setStoredBuildingSitePlans(sitePlans: Array<models.PlanViewModel>): void {
    this._buildingStore.buildingSitePlans.next(sitePlans);
  }

  setStoredBuildingSitePlansError(error: Error): void {
    this._buildingStore.buildingSitePlans.error(error);
  }

  getStoredBuildingPointsOfInterest(): Observable<FeatureCollection<Point>> {
    return this._buildingStore.buildingPointsOfInterest;
  }

  setStoredBuildingPointsOfInterest(pointsOfInterest: FeatureCollection<Point>): void {
    this._buildingStore.buildingPointsOfInterest.next(pointsOfInterest);
  }

  setStoredBuildingPointsOfInterestError(error: Error): void {
    this._buildingStore.buildingPointsOfInterest.error(error);
  }

  getStoredBuildingDemographicsReports(): Observable<Array<models.DemographicsReport>> {
    return this._buildingStore.buildingDemographicsReports;
  }

  setStoredBuildingDemographicsReports(demographicsReports: Array<models.DemographicsReport>): void {
    this._buildingStore.buildingDemographicsReports.next(demographicsReports);
  }

  setStoredBuildingDemographicsReportsError(error: Error): void {
    this._buildingStore.buildingDemographicsReports.error(error);
  }

  getStoredBuildingDemographicsReportsSelectedReportIndex(): Observable<number> {
    return this._buildingStore.buildingDemographicsReportsSelectedReportIndex;
  }

  setStoredBuildingDemographicsReportsSelectedReportIndex(index: number): void {
    this._buildingStore.buildingDemographicsReportsSelectedReportIndex.next(index);
  }

  getStoredBuildingDemographicsReportsSelectedAreaIndex(): Observable<number> {
    return this._buildingStore.buildingDemographicsReportsSelectedAreaIndex;
  }

  setStoredBuildingDemographicsReportsSelectedAreaIndex(index: number): void {
    this._buildingStore.buildingDemographicsReportsSelectedAreaIndex.next(index);
  }

  getStoredBuildingDemographicsReportsSelectedReportShouldUseLargerScope(): Observable<boolean> {
    return this._buildingStore.buildingDemographicsReportsSelectedReportShouldUseLargerScope;
  }

  setStoredBuildingDemographicsReportsSelectedReportShouldUseLargerScope(shouldUseLargerScope: boolean): void {
    this._buildingStore.buildingDemographicsReportsSelectedReportShouldUseLargerScope.next(shouldUseLargerScope);
  }

  getStoredBuildingPointsOfInterestSelectedPointIndex(): Observable<number> {
    return this._buildingStore.buildingPointsOfInterestSelectedPointIndex;
  }

  setStoredBuildingPointsOfInterestSelectedPointIndex(index: number): void {
    this._buildingStore.buildingPointsOfInterestSelectedPointIndex.next(index);
  }
}
