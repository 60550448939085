<ng-container *ngIf="showPopoverContainer()">
  <span class="popover-trigger-wrapper">
    <a role="button" class="eye-icon cursor-pointer"
       (click)="showPopover(previewPopover, popoverContainer)" inlineSVG="assets/img/eye.svg">
    </a>
  </span>
  <dx-popover class="dx-custom-gray-popover"
              [target]="popoverContainer" [width]="popoverSize?.width" [minWidth]="600"
              [position]="{of: popoverContainer, my: 'left top', at: 'left bottom', collision: 'flipfit'}"
              #previewPopover>
    <div *dxTemplate="let data of 'content'">
      <div class="popover-menu">
        <div class="popover-header">
          <span>{{leaseTermCustomTable.tableName}}</span>
          <button type="button"
                  (click)="previewPopover.visible = false;">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="popover-inner"
             [style.height.px]="contentHeight">
          <dx-scroll-view width="100%" height="100%" direction="both">
            <div #previewWrapperElement>
              <table>
                <tr *ngIf="leaseTermCustomTable.headerRow">
                  <th *ngFor="let cell of leaseTermCustomTable.headerRow.cells">
                    {{cell.displayValue}}
                  </th>
                </tr>
                <tr *ngFor="let row of leaseTermCustomTable.rows"
                    [class.current-row]="row.current"
                    [class.total-row]="row.total">
                  <td *ngFor="let cell of row.cells; let i = index;">
                    <div class="d-flex flex-column align-items-start">
                      <div class="current"
                           *ngIf="row.current && i == 0">
                        Current
                      </div>
                      <span>{{cell.displayValue}}</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </dx-scroll-view>
        </div>
      </div>
    </div>
  </dx-popover>
</ng-container>

