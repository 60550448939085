import { Component, Input, OnInit } from '@angular/core';
import { BuildingType, BuildingClass, IBuildingViewModel, IBuildingUnitViewModel} from '../../../infrastructure/models/generated';
import {SafeNumberPipe} from '../../../infrastructure/pipes/safe-number.pipe';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
})
export class BuildingComponent implements OnInit {
  @Input() building: IBuildingViewModel;
  @Input() buildingUnit: IBuildingUnitViewModel;

  BuildingClass: typeof BuildingClass = BuildingClass;

  private readonly _safeNumberPipe: SafeNumberPipe;

  constructor(safeNumberPipe: SafeNumberPipe) {
    this._safeNumberPipe = safeNumberPipe;
  }

  ngOnInit() {
  }

  getBuildingTypeName(buildingType: BuildingType): string {
    switch (buildingType) {
      case BuildingType.Industrial:
        return 'Industrial';
      case BuildingType.Land:
        return 'Land';
      case BuildingType.Office:
        return 'Office';
      case BuildingType.Retail:
        return 'Retail';
      default:
        return 'Industrial';
    }
  }

  getBuildingClass(buildingClass: BuildingClass): string {
    switch (buildingClass) {
      case BuildingClass.A:
        return 'A';
      case BuildingClass.B:
        return 'B';
      case BuildingClass.C:
        return 'C';
      default:
        return '';
    }
  }

  getClearHeight(clearHeight: number): string {
    if (!clearHeight) {
      return '—';
    }
    if (clearHeight % 1 === 0) {
      return `${clearHeight}'`;
    } else {
      const number = Math.floor(clearHeight);
      const decimalPart = ((clearHeight - number) * 10).toFixed(0);

      return decimalPart === '0' ? `${number}'` : `${number}'${decimalPart}`;
    }
  }

  getDocks(totalDocksInterior: number, totalDocksExterior: number) {
    if (totalDocksInterior && totalDocksExterior) {
      return `${this._safeNumberPipe.transform(totalDocksInterior)} Int / ${this._safeNumberPipe.transform(totalDocksExterior)} Ext`;
    }

    if (totalDocksInterior) {
      return `${this._safeNumberPipe.transform(totalDocksInterior)} Int`;
    }

    if (totalDocksExterior) {
      return `${this._safeNumberPipe.transform(totalDocksExterior)} Ext`;
    }

    return '—';
  }

  getPower(amps: number, volt: string, phase: number): string {
    if (amps && volt && phase) {
      return `${this._safeNumberPipe.transform(amps)} Amps, ${volt} Volts, ${phase} Phase`;
    }
    return '—';
  }

  getDriveInDoors(driveInDoors: number, totalDriveInDoorsSize: string) {
    if (driveInDoors && totalDriveInDoorsSize) {
      return `${driveInDoors} (${totalDriveInDoorsSize})`;
    }

    if (driveInDoors) {
      return driveInDoors;
    }
    return '—';
  }

}
