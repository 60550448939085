import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CanActivateGuardFactory } from './access/guards/factory.guard';

import { AuthGuard } from './auth/guards/auth.guard';
import { AuthService } from './auth/services/auth.service';

import { LayoutComponent } from './infrastructure/components/layout/layout.component';
import { ExistingLeasesGuard } from './infrastructure/guards/existing-leases.guard';
import { FillingProfileGuard } from './infrastructure/guards/filling-profile.guard';
import { NoActiveUserStatusGuard } from './infrastructure/guards/no-active-user-status.guard';
import { UserStatus } from './infrastructure/models/generated';
import { FloorPlanComponent } from './plan/components/floor-plan/floor-plan.component';
import { ComparisonReportComponent } from './shared/components/comparison-report/comparison-report.component';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';
import { LeaseAnalysisComponent } from './shared/components/lease-analysis/lease-analysis.component';
import { ServerErrorComponent } from './shared/components/server-error/server-error.component';

const [dashboardGuardToken, dashboardGuardProvider] = CanActivateGuardFactory
  .make((injector): Observable<UrlTree | boolean> => {
    const authService = injector.get<AuthService>(AuthService);
    const router = injector.get<Router>(Router);

    return of(
      router
        .createUrlTree(
          [authService.startupInfo.role.toLowerCase(), 'dashboard'],
        )
    );
  });

const [userRoleRestrictedRouteGuardToken, userRoleRestrictedRouteGuardProvider] = CanActivateGuardFactory
  .make((injector, route): Observable<UrlTree | boolean> => {
    const authService = injector.get<AuthService>(AuthService);
    const router = injector.get<Router>(Router);

    const roleString = authService.startupInfo.role.toLowerCase();
    const roleRestrictedRoutePath = route.routeConfig.path;

    if (roleString !== roleRestrictedRoutePath) {
      return of(
        router
          .createUrlTree(
            [roleString, 'dashboard'],
          )
      );
    }

    return of(true);
  });

const [deactivatedUserGuardToken, deactivatedUserGuardProvider] = CanActivateGuardFactory
  .make((injector): Observable<UrlTree | boolean> => {
    const authService = injector.get<AuthService>(AuthService);
    const router = injector.get<Router>(Router);

    return authService
      .infoLoadComplete
      .pipe(
        map(info => {
          if (info && info.userStatus !== UserStatus.Activated) {
            return router.createUrlTree(
              ['profile', 'no-active-user-status'],
            );
          }

          return true;
        }),
      );
  });

const applicationRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [
      AuthGuard,
      FillingProfileGuard,
      NoActiveUserStatusGuard,
    ],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: ServerErrorComponent,
        canActivate: [
          dashboardGuardToken,
          deactivatedUserGuardToken,
        ],
      },
      {
        path: 'landing',
        canActivate: [
          deactivatedUserGuardToken,
        ],
        loadChildren: () => import('./landing/landing.module').then(mod => mod.LandingModule),
      },
      {
        path: 'landlord',
        canActivate: [
          // userRoleRestrictedRouteGuardToken,
          deactivatedUserGuardToken,
        ],
        loadChildren: () => import('./landlord/landlord.module').then(mod => mod.LandlordModule),
      },
      {
        path: 'tenant',
        canActivate: [
          userRoleRestrictedRouteGuardToken,
          deactivatedUserGuardToken,
        ],
        loadChildren: () => import('./tenant/tenant.module').then(mod => mod.TenantModule),
      },
      {
        path: 'broker',
        canActivate: [
          userRoleRestrictedRouteGuardToken,
          deactivatedUserGuardToken,
        ],
        loadChildren: () => import('./broker/broker.module').then(mod => mod.BrokerModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./user/user.module').then(mod => mod.UserModule),
      },
      {
        path: 'lease-analysis',
        canActivate: [
          deactivatedUserGuardToken,
        ],
        component: LeaseAnalysisComponent,
      },
      {
        // for development purpose only
        path: 'plan',
        canActivate: [
          deactivatedUserGuardToken,
        ],
        component: FloorPlanComponent,
      },
      {
        path: 'cash-comparison/:id',
        canActivate: [
          deactivatedUserGuardToken,
        ],
        component: ComparisonReportComponent,
      },
    ],
  },
  {
    path: 'colabo',
    canActivate: [
      FillingProfileGuard,
      AuthGuard,
      ExistingLeasesGuard,
      deactivatedUserGuardToken,
    ],
    loadChildren: () => import('./colabo/colabo.module').then(mod => mod.ColaboModule),
  },
  {
    path: 'signatures',
    canActivate: [
      deactivatedUserGuardToken,
    ],
    loadChildren: () => import('./signature/signature.module').then(mod => mod.SignatureModule),
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'server-error',
    component: ServerErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      applicationRoutes,
      {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy',
      },
    ),
  ],
  providers: [
    dashboardGuardProvider,
    userRoleRestrictedRouteGuardProvider,
    deactivatedUserGuardProvider,
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRouterModule {
}
