import * as models from '@statera/sdk/common';

export type LeaseViewModel = models.ILeaseViewModel;
export type ProjectViewModel = models.IProjectViewModel;
export type ApprovingAppointment = models.IApprovingAppointmentViewModel;
export type RejectionLease = models.IRejectionLeaseViewModel;
export type RenewalProjectParams = models.IRenewalProjectParamsViewModel;
export type TeamAlignmentRoleViewModel = models.ITeamAlignmentRoleViewModel;
export type TenantDashboardViewModel = models.ITenantDashboardViewModel;
export type ProjectRequestBriefViewModel = models.ITenantRequestBriefViewModel;
export type ProjectRequestViewModel = models.ITenantRequestViewModel;
export type TourViewModel = models.ITourViewModel;
export type CreateTourViewModel = models.ICreateTourViewModel;
export type DateTimeSlotEntry = models.IDateTimeSlotEntry;

export type ProjectTemplateItem = models.IProjectTemplateItemViewModel;

export type RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;
export const RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;

export type RenewalProjectTriggerType = models.RenewalProjectTriggerType;
export const RenewalProjectTriggerType = models.RenewalProjectTriggerType;

export type LeaseStatus = models.LeaseStatus;
export const LeaseStatus = models.LeaseStatus;

export type ProjectTypeEnum = models.ProjectTypeEnum;
export const ProjectTypeEnum = models.ProjectTypeEnum;

export type ProjectStatisticsInfo = models.IProjectStatisticInfoViewModel;

export type ProjectStatistics = models.IProjectStatisticViewModel;

export type ProjectMilestone = models.IProjectMilestoneViewModel;
