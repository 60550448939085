import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import * as models from '@statera/sdk/common';

export type Alert = models.IAlertViewModel & { title?: string };
export type VersionedFile = models.IVersionedFileViewModel;

export interface AlertGroup extends models.IAlertGroupViewModel {
  lastAlert: Alert;
}

export type AlertType = models.AlertType & { WebSocketConnectionTerminated: -1 };
export const AlertType = {
  ...models.AlertType,
  WebSocketConnectionTerminated: -1,
};

export type LeaseTermType = models.LeaseTermType;
export const LeaseTermType = models.LeaseTermType;

export type ProjectType = models.ProjectTypeEnum;
export const ProjectType = models.ProjectTypeEnum;

export enum AlertNotificationKind {
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Confirm = 'confirm',
}

export class AlertNotificationReference {
  private readonly _showingObservers: Set<Subject<void>>;
  private readonly _shownObservers: Set<Subject<void>>;
  private readonly _hidingObservers: Set<Subject<void>>;
  private readonly _hiddenObservers: Set<Subject<void>>;
  private readonly _confirmedObservers: Set<Subject<void>>;
  private readonly _declinedObservers: Set<Subject<void>>;

  private readonly _showing: ReplaySubject<void>;
  private readonly _shown: ReplaySubject<void>;
  private readonly _hiding: ReplaySubject<void>;
  private readonly _hidden: ReplaySubject<void>;
  private readonly _confirmed: ReplaySubject<void>;
  private readonly _declined: ReplaySubject<void>;

  constructor() {
    this._showingObservers = new Set<Subject<void>>();
    this._shownObservers = new Set<Subject<void>>();
    this._hidingObservers = new Set<Subject<void>>();
    this._hiddenObservers = new Set<Subject<void>>();
    this._confirmedObservers = new Set<Subject<void>>();
    this._declinedObservers = new Set<Subject<void>>();

    this._showing = new ReplaySubject<void>(1);
    this._shown = new ReplaySubject<void>(1);
    this._hiding = new ReplaySubject<void>(1);
    this._hidden = new ReplaySubject<void>(1);
    this._confirmed = new ReplaySubject<void>(1);
    this._declined = new ReplaySubject<void>(1);
  }

  get showing(): Observable<void> {
    return this._createObserver(this._showingObservers, this._shown);
  }

  get shown(): Observable<void> {
    return this._createObserver(this._shownObservers, this._shown);
  }

  get hiding(): Observable<void> {
    return this._createObserver(this._hidingObservers, this._hiding);
  }

  get hidden(): Observable<void> {
    return this._createObserver(this._hiddenObservers, this._hidden);
  }

  get confirmed(): Observable<void> {
    return this._createObserver(this._confirmedObservers, this._confirmed);
  }

  get declined(): Observable<void> {
    return this._createObserver(this._declinedObservers, this._declined);
  }

  show(): Observable<void> {
    return this.fireShowing()
      .pipe(
        switchMap(() => this._shown),
      );
  }

  hide(): Observable<void> {
    return this.fireHiding()
      .pipe(
        switchMap(() => this._hidden),
      );
  }

  confirm(): Observable<void> {
    return this.fireConfirmed();
  }

  decline(): Observable<void> {
    return this.fireDeclined();
  }

  fireShowing(): Observable<void> {
    return this._fireObservers(this._showingObservers, this._showing);
  }

  fireShown(): Observable<void> {
    return this._fireObservers(this._shownObservers, this._shown);
  }

  fireHiding(): Observable<void> {
    return this._fireObservers(this._hidingObservers, this._hiding);
  }

  fireHidden(): Observable<void> {
    return this._fireObservers(this._hiddenObservers, this._hidden);
  }

  fireConfirmed(): Observable<void> {
    return this._fireObservers(this._confirmedObservers, this._confirmed);
  }

  fireDeclined(): Observable<void> {
    return this._fireObservers(this._declinedObservers, this._declined);
  }

  private _createObserver(observers: Set<Subject<void>>, finalObserver: ReplaySubject<void>): Observable<void> {
    const observer = new Subject<void>();

    observers.add(observer);

    return observer
      .pipe(
        finalize(() => {
          if (!observer) {
            return;
          }

          observer.complete();
          observers.delete(observer);

          if (observers.size === 0) {
            finalObserver.next();
          }
        }),
      );
  }

  private _fireObservers(observers: Set<Subject<void>>, finalObserver: ReplaySubject<void>): Observable<void> {
    if (!observers || !observers.size) {
      return of(null);
    }

    observers.forEach(x => x.next());

    return finalObserver;
  }
}

export interface AlertNotification {
  id?: number | string;
  kind?: AlertNotificationKind;
  reference?: AlertNotificationReference;

  title?: string;
  buildingAddress?: string;
  message?: string | any;
  warning?: string;
  senderName?: string;
  senderCompany?: string;
  senderRole?: string;

  leaseId?: number;
  projectId?: number;
  projectType?: ProjectType;
  buildingId?: number;
  leaseRequestId?: number;
  termName?: string;
  chatType?: string; // Internal/External

  alertType?: AlertType;

  closable?: boolean;

  autoclose?: boolean;
  autocloseTimeout?: number;

  confirmButtonText?: string;
  declineButtonText?: string;

  shouldShowConfirmButton?: boolean;
  shouldShowDeclineButton?: boolean;
  shouldDisableButtons?: boolean;

  closeOnButtonClick?: boolean;

  navigation?: { path: string, queryParams: { [key: string]: any }, isRefresh: boolean };
  navigationButtonText?: string;
  shouldShowNavigationButton?: boolean;
}
