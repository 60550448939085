import { Directive, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: 'input[appTrimOnBlur]',
})
export class TrimOnBlurDirective {
  private readonly _ngModel: NgModel;

  constructor(ngModel: NgModel) {
    this._ngModel = ngModel;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    if (typeof value !== 'string' || !this._ngModel) {
      return;
    }

    const trimmedValue = value.trim();

    this._ngModel
      .update
      .emit(trimmedValue);
  }
}
