import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Feature } from '@statera/sdk/feature-toggling';

import { ColaboRootComponent } from './components/colabo-root/colabo-root.component';
import { ColaboComponent } from './components/colabo/colabo.component';
import { TermsComponent } from './components/terms/terms.component';

import { FeatureTogglingCanActivateFactoryGuard } from '../feature-toggling/guards/factory.guard';
import { ColaboGuard } from './guards/colabo.guard';

const [termsGuardToken, termsGuardProvider] = FeatureTogglingCanActivateFactoryGuard.make(Feature.ColaboFeature);

const routes: Routes = [
  {
    path: '',
    component: ColaboRootComponent,
    children: [
      {
        path: 'terms',
        component: TermsComponent,
        canActivate: [
          ColaboGuard,
          termsGuardToken,
        ],
        canActivateChild: [
          ColaboGuard,
          termsGuardToken,
        ],
      },
      {
        path: 'main/:id',
        component: ColaboComponent,
        canActivate: [
          ColaboGuard,
        ],
        canActivateChild: [
          ColaboGuard,
        ],
      },
      {
        path: 'main',
        component: ColaboComponent,
      },
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
];

// @see https://github.com/angular/angular/issues/12648
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    termsGuardProvider,
  ],
})
export class ColaboRouter {
}
