<div class="view-container">
  <div class="view-container-chart">
    <div class="view-container-chart-arrows">
      <button class="left" title="Previous period" (click)="goToPreviousPeriod()"><span></span></button>
      <span class="view-container-chart-arrows-caption">
        {{ expirationsReportSinglePeriodDialogViewModel.periods[activePeriodId].startDate | date: 'yyyy'}}
      </span>
      <button class="right" title="Next period" (click)="goToNextPeriod()"><span></span></button>
    </div>

    <app-analytics-expirations-report-single-period
      [expirationsReportPeriod]="expirationsReportSinglePeriodDialogViewModel.periods[activePeriodId]">
    </app-analytics-expirations-report-single-period>
  </div>

  <div class="view-container-table">
    <app-analytics-expirations-report-single-period-table
      [expirationsReportPeriod]="expirationsReportSinglePeriodDialogViewModel.periods[activePeriodId]">
    </app-analytics-expirations-report-single-period-table>
  </div>
</div>
