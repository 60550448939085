import { FactoryProvider, InjectionToken, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Feature, FeatureTogglingManager } from '@statera/sdk/feature-toggling';

export type CanActivateResult = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface Guard extends CanActivate, CanActivateChild {}

export class FeatureTogglingCanActivateFactoryGuard {
  private static _tokenPrefix = `FEATURE_TOGGLING_CAN_ACTIVATE_FACTORY_GUARD`;
  private static _guardCounter = 0;

  static make(feature: Feature): [InjectionToken<CanActivate>, FactoryProvider] {
    const tokenName = `${FeatureTogglingCanActivateFactoryGuard._tokenPrefix}_${++FeatureTogglingCanActivateFactoryGuard._guardCounter}`;
    const injectionToken = new InjectionToken<CanActivate>(tokenName);
    const provider = {
      provide: injectionToken,
      deps: [Injector],
      useFactory: FeatureTogglingCanActivateFactoryGuard._makeGuard(feature),
    };

    return [injectionToken, provider];
  }

  private static _makeGuard(feature: Feature): (injector: Injector) => Guard {
    return (injector: Injector): Guard => new class implements CanActivate, CanActivateChild {
      private readonly _router: Router;
      private readonly _featureTogglingManager: FeatureTogglingManager;

      constructor() {
        this._router = injector.get(Router);
        this._featureTogglingManager = injector.get(FeatureTogglingManager);
      }

      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResult {
        return this._featureTogglingManager
          .getOrRequestCompanyFeatureToggle(feature)
          .pipe(
            map(isFeatureEnabled => {
              if (isFeatureEnabled) {
                return true;
              }

              return this._router
                .createUrlTree(['forbidden']);
            }),
          );
      }

      canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResult {
        return this.canActivate(route, state);
      }
    };
  }
}
