import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { PortfolioInvitationRepository } from './portfolio-invitation.repository';
import { PortfolioInvitationManager } from './portfolio-invitation.manager';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    PortfolioInvitationRepository,
    PortfolioInvitationManager,
  ],
})
export class PortfolioInvitationModule {
  static forChild(configuration: Configuration): ModuleWithProviders<PortfolioInvitationModule> {
    return {
      ngModule: PortfolioInvitationModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        PortfolioInvitationRepository,
        PortfolioInvitationManager,
      ],
    };
  }
}
