import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'separatedList'
})
export class SeparatedListPipe implements PipeTransform {
  transform(value: Array<any>, separator: string = ', '): string | any {
    if (!(value instanceof Array)) {
      return value;
    }

    return value.filter(x => !!x).join(separator);
  }
}
