import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager, AlertManager, AlertNotification, AlertType } from '@statera/sdk/alert';
import { FinancialsRequestManager } from '@statera/sdk/financials-request';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

import { AlertService } from '../services/alert.service';

@Injectable()
export class FinancialRequestInterceptor implements NotificationInterceptor<AlertNotification> {
  private readonly _renderer: Renderer2;
  private readonly _alertService: AlertService;
  private readonly _alertManager: AlertManager;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _financialsRequestManager: FinancialsRequestManager;
  private readonly _loggerService: LoggerService;

  constructor(
    rendererFactory: RendererFactory2,
    alertService: AlertService,
    alertManager: AlertManager,
    alertMessagesManager: AlertMessagesManager,
    financialsRequestManager: FinancialsRequestManager,
    loggerService: LoggerService
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._alertService = alertService;
    this._alertManager = alertManager;
    this._alertMessagesManager = alertMessagesManager;
    this._financialsRequestManager = financialsRequestManager;
    this._loggerService = loggerService;
  }

  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert) {
      return notification;
    }

    if (alert.alertType !== AlertType.FinancialsRequest) {
      return notification;
    }

    const inputElement = this._renderer.createElement('input');
    if (!inputElement) {
      this._loggerService
        .warning(LoggerTopic.Alert, 'Unable to create the input element', alert);

      return;
    }

    this._renderer.setAttribute(inputElement, 'type', 'file');
    this._renderer.setAttribute(inputElement, 'multiple', '');
    this._renderer.setAttribute(inputElement, 'accept', '.pdf,.xls,.xlsx,.doc,.docx');

    inputElement.onchange = (event) => {
      if (!event || !inputElement.files || !inputElement.files.length) {
        this._loggerService
          .warning(LoggerTopic.Alert, 'Unable to get files from the input element', alert);

        return;
      }

      this._financialsRequestManager
        .uploadFinancialsFiles(<number>alert.id, alert.leaseRequestId, inputElement.files)
        .pipe(
          tap(() => {
            this._alertService
              .pushSuccessAlert({
                message: this._alertMessagesManager.getFinancialsUploadedAlertText(),
              });

            this._loggerService
              .info(LoggerTopic.Alert, 'Financials uploaded', inputElement.files);
          }),
          switchMap(() => this._alertManager.changeIsRequiresAction(<number>alert.id, false)),
          tap(() => alert.reference.hide()),
          catchError(err => {
            this._alertService
              .pushErrorAlert({
                message: this._alertMessagesManager.getFinancialsUploadErrorAlertText(),
              });

            this._loggerService
              .error(LoggerTopic.Alert, 'Unable to upload financials file', err);

            return throwError(err);
          }),
          take(1),
          takeUntil(notification.dequeued),
        )
        .subscribe();
    };

    alert.autoclose = false;
    alert.closeOnButtonClick = false;
    alert.shouldDisableButtons = false;

    alert
      .reference
      .confirmed
      .pipe(
        tap(() => inputElement.click()),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alert
      .reference
      .declined
      .pipe(
        tap(() => alert.reference.hide()),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    return notification;
  }
}
