<dx-scroll-view width="100%" [showScrollbar]="'always'">
  <table class="table">
    <thead>
    <tr>
      <th [class.order-desc]="orderByConfig === '-tenantCompanyName'"
          [class.order-asc]="orderByConfig === 'tenantCompanyName'"
          (click)="applyColumnOrder('tenantCompanyName')">
        Tenant
      </th>
      <th [class.order-desc]="orderByConfig === '-buildingAddress'"
          [class.order-asc]="orderByConfig === 'buildingAddress'"
          (click)="applyColumnOrder('buildingAddress')">
        Building Address
      </th>
      <th [class.order-desc]="orderByConfig === '-unit'"
          [class.order-asc]="orderByConfig === 'unit'"
          (click)="applyColumnOrder('unit')">
        Unit
      </th>
      <th [class.order-desc]="orderByConfig === '-squareFeet'"
          [class.order-asc]="orderByConfig === 'squareFeet'"
          (click)="applyColumnOrder('squareFeet')">
        Size
      </th>
      <th [class.order-desc]="orderByConfig === '-leaseExpirationDate'"
          [class.order-asc]="orderByConfig === 'leaseExpirationDate'"
          (click)="applyColumnOrder('leaseExpirationDate')">
        LXP
      </th>
      <th [class.order-desc]="orderByConfig === '-leaseStatus'"
          [class.order-asc]="orderByConfig === 'leaseStatus'"
          (click)="applyColumnOrder('leaseStatus')">
        Lease Status
      </th>
      <th [class.order-desc]="orderByConfig === '-renewalIndex'"
          [class.order-asc]="orderByConfig === 'renewalIndex'"
          (click)="applyColumnOrder('renewalIndex')">
        Renewal Index
      </th>
    </tr>
    </thead>
    <tbody>
    <tr title="Go to deal"
        *ngFor="let tableRow of tableData;"
        (click)="navigate(tableRow)">
      <!-- Start: Tenant Company Name -->
      <td>
        {{tableRow.tenantCompanyName || 'Vacant'}}
      </td>
      <!-- End: Tenant Company Name -->
      <!-- Start: Building Address -->
      <td>
        {{tableRow.buildingAddress}}
      </td>
      <!-- End: Building Address -->
      <!-- Start: Unit -->
      <td>
        {{tableRow.unit}}
      </td>
      <!-- End: Unit -->
      <!-- Start: Square Feet -->
      <td>
        {{(tableRow.squareFeet || 0) | safeNumber }} SF
      </td>
      <!-- End: Square Feet -->
      <!-- Start: LXP -->
      <td>
        <ng-container *ngIf="tableRow.leaseExpirationDate; else noExpirationDate">
          {{tableRow.leaseExpirationDate | safeDate: 'MMMM d, y'}}
        </ng-container>
        <ng-template #noExpirationDate>
          N/A
        </ng-template>
      </td>
      <!-- End: LXP -->
      <!-- Start: Lease Status -->
      <td>
        <div class="d-flex">
          <span>{{tableRow.leaseStatusDisplay || 'Vacant'}}</span>
          <!-- Start: Badge -->
          <div *ngIf="tableRow.nextLeaseStatus">
            <span class="lease-status-badge"
                  [class.badge-success]="LeaseStatus.Renewed === tableRow.nextLeaseStatus || LeaseStatus.Restructured === tableRow.nextLeaseStatus"
                  [class.badge-warning]="LeaseStatus.Renewal === tableRow.nextLeaseStatus || LeaseStatus.Restructure === tableRow.nextLeaseStatus"
                  [class.badge-renewed]="LeaseStatus.Renewed === tableRow.nextLeaseStatus"
                  #leaseStatusBadgeElementRef>
              {{tableRow.nextLeaseStatusDisplay}}
            </span>

            <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend" position="top" contentTemplate="content"
                        *ngIf="LeaseStatus.Renewed === tableRow.nextLeaseStatus || LeaseStatus.Restructured === tableRow.nextLeaseStatus"
                        [target]="leaseStatusBadgeElementRef">
              <div *dxTemplate="let data of 'content';">
                Next amendment will be active in {{tableRow.nextLeaseCommencement | date: 'MMMM d, y'}}
              </div>
            </dx-tooltip>
          </div>
          <!-- End: Badge -->
        </div>
      </td>
      <!-- End: Lease Status -->
      <!-- Start: Renewal Index -->
      <td>
        {{tableRow.renewalIndex ? (tableRow.renewalIndex | safeNumber: '1.0-0') + '%' : 'N/A'}}
        <span class="arrow"></span>
      </td>
      <!-- End: Renewal Index -->
    </tr>
    </tbody>
  </table>
</dx-scroll-view>
