import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DxCalendarComponent, DxFormComponent } from 'devextreme-angular';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';

import { AuthService } from '../../../auth/services/auth.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { AlertService } from '../../../alert/services/alert.service';
import { TermsPageService } from '../../../colabo/services/terms-page.service';
import { AppointmentService } from '../../services/appointment.service';
import { ProjectAccessService } from '../../services/project-access.service';
import { ProjectService } from '../../services/project.service';

import { SafeDatePipe } from '../../../infrastructure/pipes/safe-date.pipe';

import * as models from '../../../infrastructure/models/generated';

import { AppointmentEditDialogComponent } from '../appointment-edit-dialog/appointment-edit-dialog.component';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnDestroy {
  @Input() lease: models.ILeaseViewModel;
  @Input() appointments: Array<models.IAppointmentViewModel>;
  @Input() currentProjectState: models.IProjectTemplateItemViewModel;
  @Input() project: models.IProjectViewModel;

  @ViewChild('calendar') calendar: DxCalendarComponent;
  @ViewChild('dxAddAppointmentForm') dxAddAppointmentForm: DxFormComponent;

  @Output() appointmentsChanged = new EventEmitter();
  @Output() approvingAppointments = new EventEmitter();

  AppointmentType = models.AppointmentType;
  holydays: any = [[1, 0], [4, 6], [25, 11]];
  selectedDate = new Date();
  selectedItem: models.IAppointmentViewModel;
  selectedItemKeys = [];

  get isLandlord() {
    return this.authService.role.toLowerCase() === 'landlord';
  }

  authService: AuthService;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _appointmentService: AppointmentService;
  private readonly _cdRef: ChangeDetectorRef;
  private readonly _termsPageService: TermsPageService;
  private readonly _projectService: ProjectService;
  private readonly _safeDatePipe: SafeDatePipe;
  private readonly _dialogService: DialogService;
  private readonly _destroy$: Subject<void>;
  private readonly _projectAccessService: ProjectAccessService;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    appointmentService: AppointmentService,
    cdRef: ChangeDetectorRef,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    safeDatePipe: SafeDatePipe,
    dialogService: DialogService,
    projectAccessService: ProjectAccessService,
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this.authService = authService;
    this._appointmentService = appointmentService;
    this._cdRef = cdRef;
    this._termsPageService = termsPageService;
    this._projectService = projectService;
    this._safeDatePipe = safeDatePipe;
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
    this._projectAccessService = projectAccessService;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getTimeIntervalString(startDate: Date, endDate: Date): string {
    if (!startDate || !endDate) {
      return;
    }

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    // Workaround: Special case
    if (startDate.getHours() === 11 && startDate.getMinutes() === 30 && endDate.getHours() === 12) {
      return '11:30 AM - 12:00 AM';
    }

    const from = this._safeDatePipe.transform(startDate.toString(), 'hh:mm a');
    const until = this._safeDatePipe.transform(endDate.toString(), 'hh:mm a');

    return `${from} - ${until}`;
  }

  renderDateTime(item: models.IAppointmentViewModel) {
    const date = this._safeDatePipe.transform(item.startDate.toString(), 'M/d/yy');
    const timeInterval = this.getTimeIntervalString(item.startDate, item.endDate);

    return `${date}, ${timeInterval}`;
  }

  getCellCssClass(date) {
    let cssClass = '';
    if (this.isWeekend(date)) {
      cssClass = 'weekend';
    }
    this.holydays.forEach((item) => {
      if (date.getDate() === item[0] && date.getMonth() === item[1]) {
        cssClass = 'holyday';
        return false;
      }
    });
    this.appointments.forEach(item => {
      if (this._isSameDay(date, new Date(item.startDate))) {
        cssClass = item.approved ? 'appointment-approved' : 'appointment';
        return false;
      }
    });
    return cssClass;
  }

  isWeekend(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  addVisit() {
    if (!this.lease) {
      return;
    }

    const dialogRef = this._showAppointmentEditDialog('Site Visit');

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => {
          if (!dialogRef.outputData || !dialogRef.outputData.appointmentViewModel) {
            return of(null);
          }

          return this._appointmentService
            .addAppointment(dialogRef.outputData.appointmentViewModel)
            .pipe(
              tap((appointment) => {
                if (!appointment) {
                  return;
                }

                this.appointments.push(appointment);

                this.selectedItem = appointment;
                this.selectedItemKeys = [appointment.id];
                this.selectedDate = new Date(appointment.startDate);

                this.appointmentsChanged.emit();
              }),
            );
        }),
      )
      .subscribe();
  }

  editVisit() {
    if (!this.selectedItem || !this.lease) {
      return;
    }

    const dialogRef = this._showAppointmentEditDialog('Change Visit', {appointmentViewModel: this.selectedItem});

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => {
          if (!dialogRef.outputData || !dialogRef.outputData.appointmentViewModel) {
            return of(null);
          }

          return this._appointmentService
            .updateAppointment(dialogRef.outputData.appointmentViewModel)
            .pipe(
              tap(() => {
                this.selectedItem.text = dialogRef.outputData.appointmentViewModel.text;
                this.selectedItem.startDate = dialogRef.outputData.appointmentViewModel.startDate;
                this.selectedItem.endDate = dialogRef.outputData.appointmentViewModel.endDate;

                this.selectedDate = new Date(this.selectedItem.startDate);

                this.appointmentsChanged.emit();
              }),
            );
        }),
      )
      .subscribe();
  }

  rescheduleVisit(): void {
    const dialogRef = this._showAppointmentEditDialog('Reschedule Contractor', {
      isReschedule: true,
      appointmentViewModel: this.selectedItem,
    });

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => {
          if (!dialogRef.outputData || !dialogRef.outputData.appointmentViewModel || !this.lease || !this.project) {
            return of(null);
          }

          const appointment = dialogRef.outputData.appointmentViewModel;

          // Do nothing if the appointment is not changed.
          if (
            moment(appointment.startDate).isSame(this.selectedItem.startDate) &&
            moment(appointment.endDate).isSame(this.selectedItem.endDate)
          ) {
            return of(null);
          }

          const approvingAppointmentViewModel: models.IApprovingAppointmentViewModel = {
            leaseViewModel: this.lease,
            appointmentViewModel: appointment,
          };

          return this._projectService
            .renewalProject(
              this.lease,
              models.RenewalProjectTriggerType.RescheduleContractorVisit,
              this.project,
              approvingAppointmentViewModel,
            )
            .pipe(
              tap(() => {
                this.selectedItem.text = appointment.text;
                this.selectedItem.startDate = appointment.startDate;
                this.selectedItem.endDate = appointment.endDate;
                this.selectedItem.approved = false;

                this.selectedDate = new Date(this.selectedItem.startDate);

                this.appointmentsChanged.emit();
                this.approvingAppointments.emit();
              }),
            );
        }),
      )
      .subscribe();
  }

  private _showAppointmentEditDialog(title: string, injectableDate: object = {}): DialogRefService {
    return this._dialogService.show(AppointmentEditDialogComponent, {
      title: title,
      showCloseButton: true,
      width: 500,
      height: 'auto',
      injectableData: {
        ...injectableDate,
        leaseId: this.lease.id,
      },
    });
  }

  acceptVisit() {
    const alertReference = this._alertService.pushConfirmAlert({
      title: 'Please confirm',
      message: this._alertMessagesManager.getConfirmAcceptVisitDateAlertText(),
      confirmButtonText: 'OK',
      declineButtonText: 'Cancel',
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const model = {...this.selectedItem};

          model.approved = true;

          const approvingAppointmentViewModel: models.IApprovingAppointmentViewModel = {
            leaseViewModel: this.lease,
            appointmentViewModel: model,
          };

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.ApproveAppointment, this.project, approvingAppointmentViewModel)
            .pipe(
              tap(() => {
                this.selectedItem.approved = true;
                this.approvingAppointments.emit();
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  deleteVisit() {
    const alertReference = this._alertService.pushConfirmAlert({
      title: 'Please confirm',
      message: this._alertMessagesManager.getConfirmDeleteContractorVisitAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._appointmentService
          .deleteAppointment(this.selectedItem)
          .pipe(
            tap(() => {
              this._cdRef.checkNoChanges();
              this.appointments.splice(this.appointments.indexOf(this.selectedItem), 1);
              this.selectedItem = null;
              this.selectedDate = new Date();
              this.appointmentsChanged.emit();
              this._cdRef.detectChanges();
              this.appointmentsChanged.emit();
            }),
          ),
        ),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onVisitListSelectionChanged(e) {
    if (!e || !e.value) {
      return;
    }

    this.selectedItem = e.value[0];
    if (e.value[0]) {
      this.selectedItem = this.appointments.find(a => a.id === e.value[0]);
      if (this.selectedItem) {
        this.selectedDate = new Date(this.selectedItem.startDate);
      }
    }
  }

  get isShowCancelVisitButton(): boolean {
    return (
      this.appointments && this.appointments.length === 0
    );
  }

  onCancelVisitButtonClicked($event) {
    this.appointmentsChanged.emit();
  }

  get isAllowEditVisit(): boolean {
    return true;
  }

  get isAllowApproveVisit(): boolean {
    return true;
  }

  isAppointmentRescheduleAvailable(): boolean {
    if (!this.currentProjectState) {
      return false;
    }

    const templateItemType = this.currentProjectState.renewalProjectTemplateItemType;

    return true;
  }

  isRescheduleAcceptanceAvailable(): boolean {
    if (!this.currentProjectState) {
      return false;
    }

    return true;
  }

  acceptReschedule(): void {
    const alertReference = this._alertService.pushConfirmAlert({
      title: 'Please confirm',
      message: this._alertMessagesManager.getConfirmAcceptContractorVisitRescheduleAlertText(),
      confirmButtonText: 'OK',
      declineButtonText: 'Cancel',
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const model = {...this.selectedItem};

          model.approved = true;

          const approvingAppointmentViewModel: models.IApprovingAppointmentViewModel = {
            leaseViewModel: this.lease,
            appointmentViewModel: model,
          };

          return this._projectService
            .renewalProject(
              this.lease,
              models.RenewalProjectTriggerType.ApproveAppointment,
              this.project,
              approvingAppointmentViewModel,
            )
            .pipe(
              tap(() => {
                this.selectedItem.approved = true;
                this.approvingAppointments.emit();
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _isSameDay(beginDate, endDate): boolean {
    return beginDate.getFullYear() === endDate.getFullYear()
      && beginDate.getDate() === endDate.getDate()
      && beginDate.getMonth() === endDate.getMonth();
  }
}
