import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '@statera/sdk/common';

import { LoggerModule } from '@statera/sdk/logger';
import { WebsocketModule } from '@statera/sdk/websocket';

import { AlertMessagesManager } from './alert-messages.manager';
import { AlertManager } from './alert.manager';
import { AlertRepository } from './alert.repository';
import { AlertTransformer } from './alert.transformer';

@NgModule({
  imports: [
    HttpClientModule,
    LoggerModule,
    WebsocketModule,
  ],
  providers: [
    AlertMessagesManager,
    AlertManager,
    AlertRepository,
    AlertTransformer,
  ],
})
export class AlertModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AlertModule> {
    return {
      ngModule: AlertModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AlertMessagesManager,
        AlertManager,
        AlertRepository,
        AlertTransformer,
      ],
    };
  }
}
