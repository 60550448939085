<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">

    <div class="dx-fieldset"
         *ngIf="!isReschedule">
      <p class="mb-0">
        We suggest providing multiple site visit options for Tenant to choose from.
      </p>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field"
           *ngIf="!isReschedule">
        <div class="dx-field-label">
          Title
        </div>
        <div class="dx-field-value">
          <dx-text-box name="title" id="title" required
                       [isValid]="form.submitted ? title.valid : true"
                       [(ngModel)]="appointment.title"
                       #title="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && title.hasError('required')">
            Title is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">
          Date
        </div>
        <div class="dx-field-value">
          <dx-date-box name="date" id="date" required
                       [isValid]="form.submitted ? date.valid : true" [disabledDates]="this.isDateDisabled"
                       [calendarOptions]="{firstDayOfWeek: 1}"
                       [(ngModel)]="appointment.date"
                       #date="ngModel">
          </dx-date-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && date.hasError('required')">
            Date is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">
          Time
        </div>
        <div class="dx-field-value">
          <dx-select-box name="time" id="time" displayExpr="label" valueExpr="value" required
                         [items]="timeSelectors" [isValid]="form.submitted ? time.valid : true"
                         [(ngModel)]="appointment.time"
                         #time="ngModel">
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && time.hasError('required')">
            Time is required
          </div>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%"
                     text="Confirm" [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>
