import * as models from '@statera/sdk/common';

export enum FilterType {
  Portfolio = 'Portfolio',
  Building = 'Building',
  LocationState = 'LocationState',
  LocationCity = 'LocationCity',
  LocationMarket = 'LocationMarket',
  LocationSubMarket = 'LocationSubMarket',
  LocationZipCode = 'LocationZipCode',
  LocationAddress = 'LocationAddress',
  DealType = 'DealType',
  Size = 'Size',
  LeaseExpiration = 'LeaseExpiration',
  TenantCompany = 'TenantCompany',
  Industry = 'Industry',
  SpaceUse = 'SpaceUse',
}

export interface AnalyticsFilter {
  id: string;
  type: FilterType;
}

export interface AnalyticsFilterWithState extends AnalyticsFilter {
  isSelected: boolean;
  isVisible: boolean;
}

export interface AnalyticsFilterWithStateAndPortfolioIds extends AnalyticsFilterWithState {
  portfolioIds?: Array<number>;
  portfolioId?: number;
}

export interface AnalyticsFilterWithStateAndBuildingIds extends AnalyticsFilterWithState {
  buildingIds?: Array<number>;
  buildingId?: number;
}

export interface AnalyticsFilterWithStateAndBoundaries extends AnalyticsFilterWithState {
  boundaries: {
    min: number;
    max: number;
  };
}

export type PortfolioFilter = AnalyticsFilterWithState & models.IAnalyticsFilterPortfolioViewModel;
export type BuildingFilter = AnalyticsFilterWithState & models.IAnalyticsFilterBuildingViewModel;
export type LocationStateFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationStateViewModel;
export type LocationCityFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationCityViewModel;
export type LocationMarketFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationMarketViewModel;
export type LocationSubMarketFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationSubMarketViewModel;
export type LocationZipCodeFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationZipCodeViewModel;
export type LocationAddressFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLocationAddressViewModel;
export type DealTypeFilter = AnalyticsFilterWithState & models.IAnalyticsFilterDealTypeViewModel;
export type TenantCompanyFilter = AnalyticsFilterWithState & models.IAnalyticsFilterTenantCompanyViewModel;
export type IndustryFilter = AnalyticsFilterWithState & models.IAnalyticsFilterIndustryViewModel;
export type SpaceUseFilter = AnalyticsFilterWithState & models.IAnalyticsFilterSpaceUseViewModel;
export type LeaseExpirationFilter = AnalyticsFilterWithState & models.IAnalyticsFilterLeaseExpirationViewModel;
export type SquareFeetRangeFilter = AnalyticsFilterWithStateAndBoundaries & models.IAnalyticsFilterSquareFeetRangeViewModel;

export type LocationFilter =
  LocationStateFilter |
  LocationCityFilter |
  LocationMarketFilter |
  LocationSubMarketFilter |
  LocationZipCodeFilter |
  LocationAddressFilter;
export interface LocationFilters {
  locationStates: Array<LocationStateFilter>;
  locationCities: Array<LocationCityFilter>;
  locationMarkets: Array<LocationMarketFilter>;
  locationSubMarkets: Array<LocationSubMarketFilter>;
  locationZipCodes: Array<LocationZipCodeFilter>;
  locationAddresses: Array<LocationAddressFilter>;
}

export type AnyFilter =
  PortfolioFilter |
  BuildingFilter |
  LocationFilter |
  DealTypeFilter |
  TenantCompanyFilter |
  IndustryFilter |
  SpaceUseFilter |
  LeaseExpirationFilter |
  SquareFeetRangeFilter;

export interface AnalyticsFilters extends models.IAnalyticsFiltersViewModel, LocationFilters {
  portfolios: Array<PortfolioFilter>;
  buildings: Array<BuildingFilter>;
  locationStates: Array<LocationStateFilter>;
  locationCities: Array<LocationCityFilter>;
  locationMarkets: Array<LocationMarketFilter>;
  locationSubMarkets: Array<LocationSubMarketFilter>;
  locationZipCodes: Array<LocationZipCodeFilter>;
  locationAddresses: Array<LocationAddressFilter>;
  dealTypes: Array<DealTypeFilter>;
  tenantCompanies: Array<TenantCompanyFilter>;
  industries: Array<IndustryFilter>;
  spaceUses: Array<SpaceUseFilter>;
  leaseExpirations: Array<LeaseExpirationFilter>;
  squareFeetRange: SquareFeetRangeFilter;
}
