<dx-scroll-view width="100%" [showScrollbar]="'always'">
  <table class="table">
    <thead>
    <tr>
      <th [class.order-desc]="orderByConfig === '-tenantCompanyName'"
          [class.order-asc]="orderByConfig === 'tenantCompanyName'"
          (click)="applyColumnOrder('tenantCompanyName')">
        Tenant
      </th>
      <th [class.order-desc]="orderByConfig === '-buildingAddress'"
          [class.order-asc]="orderByConfig === 'buildingAddress'"
          (click)="applyColumnOrder('buildingAddress')">
        Building Address
      </th>
      <th [class.order-desc]="orderByConfig === '-unit'"
          [class.order-asc]="orderByConfig === 'unit'"
          (click)="applyColumnOrder('unit')">
        Unit
      </th>
      <th [class.order-desc]="orderByConfig === '-squareFeet'"
          [class.order-asc]="orderByConfig === 'squareFeet'"
          (click)="applyColumnOrder('squareFeet')">
        Size
      </th>
      <th [class.order-desc]="orderByConfig === '-stageName'"
          [class.order-asc]="orderByConfig === 'stageName'"
          (click)="applyColumnOrder('stageName')">
        Stage
      </th>
      <th [class.order-desc]="orderByConfig === '-stageStartDate'"
          [class.order-asc]="orderByConfig === 'stageStartDate'"
          (click)="applyColumnOrder('stageStartDate')">
        Stage Start Date
      </th>
    </tr>
    </thead>
    <tbody>
    <tr title="Go to deal"
        *ngFor="let tableRow of tableData;"
        (click)="navigateTo3PanelView(tableRow)">
      <!-- Start: Tenant Company Name -->
      <td>
        {{tableRow.tenantCompanyName}}
      </td>
      <!-- End: Tenant Company Name -->
      <!-- Start: Building Address -->
      <td>
        {{tableRow.buildingAddress}}
      </td>
      <!-- End: Building Address -->
      <!-- Start: Unit -->
      <td>
        {{tableRow.unit}}
      </td>
      <!-- End: Unit -->
      <!-- Start: Square Feet -->
      <td>
        {{tableRow.squareFeet}}
      </td>
      <!-- End: Square Feet -->
      <!-- Start: Stage Name -->
      <td>
        {{tableRow.stageName}}
      </td>
      <!-- End: Stage Name -->
      <!-- Start: Stage Start Date -->
      <td>
        {{tableRow.stageStartDate | safeDate: 'MMMM d, y'}}
        <span class="arrow"></span>
      </td>
      <!-- End: Stage Start Date -->
    </tr>
    </tbody>
  </table>
</dx-scroll-view>
