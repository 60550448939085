import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { SharedModule } from '../shared/shared.module';

import { InviteTeamMemberDialogComponent } from './components/invite-team-member-dialog/invite-team-member-dialog.component';
import { ManageTeamDialogComponent } from './components/manage-team-dialog/manage-team-dialog.component';
import {InlineSVGModule} from 'ng-inline-svg';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InfrastructureModule,
        SharedModule,

        dx.DxScrollViewModule,
        dx.DxTooltipModule,
        dx.DxTextBoxModule,
        dx.DxLookupModule,
        dx.DxSelectBoxModule,
        InlineSVGModule,
    ],
  declarations: [
    InviteTeamMemberDialogComponent,
    ManageTeamDialogComponent,
  ],
  entryComponents: [
    InviteTeamMemberDialogComponent,
    ManageTeamDialogComponent,
  ],
})
export class TeamModule {
  static forRoot(): ModuleWithProviders<TeamModule> {
    return {
      ngModule: TeamModule,
    };
  }
}
