import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { DxValidationGroupComponent } from 'devextreme-angular';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-termination-option',
  templateUrl: './termination-option.component.html',
  styleUrls: ['./termination-option.component.scss'],
})
export class TerminationOptionComponent extends BaseTermDirective<models.ITerminationOptionTermViewModel>
  implements OnInit, OnChanges, OnDestroy {

  @ViewChildren(TerminationOptionComponent) optionComponents: QueryList<TerminationOptionComponent>;
  @ViewChild(DxValidationGroupComponent) validationGroup: DxValidationGroupComponent;

  TerminationOptionType = models.TerminationOptionType;

  TerminationOptionFeeType = models.TerminationOptionFeeType;

  options: any = [];

  feeOptions: any = [
    {
      name: 'Unamortized costs of the transaction at (INSERT AMORTIZATION RATE)% over the remaining term of the lease after termination',
      value: models.TerminationOptionFeeType.AmortizationRate,
    },
    {
      name: 'A flat fee of $ (INSERT DOLLAR VALUE)',
      value: models.TerminationOptionFeeType.DollarValue,
    },
  ];
  isVisibleRadioGroup = true;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initializeComponent();
    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this.initializeComponent();
          }),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseTerm &&
      changes.leaseTerm.previousValue &&
      changes.leaseTerm.currentValue &&
      !this.termManager.isSameTerms(
        <models.ILeaseTermViewModel>changes.leaseTerm.previousValue,
        <models.ILeaseTermViewModel>changes.leaseTerm.currentValue,
      )
    ) {
      this.initializeComponent();
    }
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
        this.lease.term.hasMultiplyOptions
      ) ||
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      )
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.leaseTermOptions &&
      !this.leaseTerm.leaseTermOptions.selectedLeaseTerm &&
      (
        this.leaseTerm.terminationOptionType === null ||
        this.leaseTerm.terminationOptionType === models.TerminationOptionType.MultiplyOptions
      ) &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isCounterButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      ) &&
      this.leaseTerm.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready
    ) {
      return false;
    }

    return super.isCounterButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.termStatus !== models.TermStatus.Pending &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    const isTenantCounterOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease,
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        )
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick(): void {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    this.handleAcceptButtonClick();
  }

  validateOptions(): boolean {
    return this.optionComponents.reduce((isValid: boolean, next) => {
      return isValid && next.validationGroup.instance.validate().isValid;
    }, true);
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    const {terminationOptionType} = this.leaseTerm;
    if (terminationOptionType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    const isValid = this.validateOptions();
    if (!isValid) {
      return;
    }

    super.saveLeaseTerm(this.leaseTerm);

    $event.preventDefault();
  }

  canInsertNewOption(): boolean {
    return this.termManager.canInsertNewOption(this.lease.term, this.project);
  }

  initializeComponent() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ITerminationOptionTermViewModel>{};
    }

    if (!this.isOption) {
      this.leaseTerm = {...this.leaseTerm};
    }

    this.isVisibleRadioGroup = true;
    this.options = this._getOptions();

    if (this.project && this.project.projectState
      && this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp) {
      if (this.lease.term && this.lease.term.termType === models.TermTypeEnum.MultiplyOptions) {
        this.options.forEach(value => {
          value.disabled = true;
        });
      }
    }

    if (
      this.leaseTerm.terminationOptionType === models.TerminationOptionType.MultiplyOptions &&
      !this.isTenantCounterUnsolicitedOfferStage()
    ) {
      this.isVisibleRadioGroup = false;
      this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getOptions() {
    return [
      {
        name: 'No Termination option',
        value: models.TerminationOptionType.NoTerminationOption,
      },
      {
        name: (
          'Tenant shall have the right to terminate the lease effective after the end of month (INSERT MONTH OF LEASE) ' +
          'by providing the Landlord a minimum of (INSERT NUMBER OF MONTHS) months prior written notice'
        ),
        value: models.TerminationOptionType.RightToTerminateLease,
      },
      {
        name: 'Custom',
        value: models.TerminationOptionType.Custom,
      },
      {
        name: 'Please state whether Landlord is willing to provide any Termination Option rights for either Lease Term options.',
        value: models.TerminationOptionType.RequestMultiplyOptions,
        disabled: true
      },
    ];
  }
}
