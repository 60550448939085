import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { Alert, AlertGroup } from './alert.model';
import { AlertStore } from './alert.store';

@Injectable()
export class AlertRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;
  private readonly _alertStore: AlertStore;

  constructor(configuration: Configuration, httpClient: HttpClient, alertStore: AlertStore) {
    this._configuration = configuration;
    this._httpClient = httpClient;
    this._alertStore = alertStore;
  }

  markAlertRead(alert: Alert): Observable<void> {
    return this._httpClient
      .post<void>(`${this._configuration.apiEndpoint}/Alert/MarkReadAlert`, alert);
  }

  getAlertGroup(): Observable<AlertGroup> {
    return this._httpClient
      .get<AlertGroup>(`${this._configuration.apiEndpoint}/Alert/GetGroupAlerts`);
  }

  getAlertsThatRequireAction(): Observable<Array<Alert>> {
    return this._httpClient
      .get<Array<Alert>>(`${this._configuration.apiEndpoint}/Alert/GetAlertsRequiresAction`);
  }

  changeIsRequiresAction(alertId: number, isRequiresAction: boolean): Observable<Alert> {
    let params = new HttpParams().set('isRequiresAction', isRequiresAction ? 'true' : 'false');

    if (alertId) {
      params = params.set('alertId', alertId.toString(10));
    }

    return this._httpClient
      .post<Alert>(`${this._configuration.apiEndpoint}/Alert/ChangeIsRequiresAction`, null, {params});
  }

  setLatestRealtimeAlertGroup(alertGroup: AlertGroup): void {
    this._alertStore
      .latestRealtimeAlertGroup
      .next(alertGroup);
  }

  getLatestRealtimeAlertGroup(): Observable<AlertGroup> {
    return this._alertStore
      .latestRealtimeAlertGroup;
  }
}
