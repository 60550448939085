import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { TermManager } from './term.manager';

import { TermRepository } from './term.repository';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    TermRepository,
    TermManager,
  ],
})
export class TermModule {
  static forChild(configuration: Configuration): ModuleWithProviders<TermModule> {
    return {
      ngModule: TermModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        TermRepository,
        TermManager
      ],
    };
  }
}
