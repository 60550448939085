import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import * as models from '../../../infrastructure/models/generated';

export interface ProjectState extends EntityState<models.IProjectViewModel> {}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'project',
  cache: {
    ttl: 3600000,
  },
  idKey: 'id',
})
export class ProjectStore extends EntityStore<ProjectState> {
  constructor() {
    super();
  }
}

