import { Injectable } from '@angular/core';

import * as models from '../../infrastructure/models/generated';

export enum RequestStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

@Injectable()
export class RequestService {
  getRequestStatus(project: models.IProjectViewModel): RequestStatus {
    if (!project) {
      return RequestStatus.Unknown;
    }

    if (project.projectStatus === models.ProjectStatus.Rejected) {
      return RequestStatus.Rejected;
    }

    const {
      EstablishCriteriaByTenant,
      EstablishCriteriaByLandlord,
      UnsolicitedOfferLandlordReviewingFinancialDocuments,
      Restructure_EstablishCriteriaByLandlord,
      Restructure_EstablishCriteriaByTenant
    } = models.RenewalProjectTemplateItemType;

    if (
      project.projectType && project.projectState &&
      (
        (
          (project.projectType.projectTypeEnum === models.ProjectTypeEnum.Renewal ||
            project.projectType.projectTypeEnum === models.ProjectTypeEnum.NewDeal
          ) &&
          (
            EstablishCriteriaByTenant !== project.projectState.renewalProjectTemplateItemType &&
            EstablishCriteriaByLandlord !== project.projectState.renewalProjectTemplateItemType
          )
        ) ||
        (
          project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord &&
          (
            UnsolicitedOfferLandlordReviewingFinancialDocuments !== project.projectState.renewalProjectTemplateItemType
          )
        ) ||
        (
          project.projectType.projectTypeEnum === models.ProjectTypeEnum.Restructure &&
          (
            Restructure_EstablishCriteriaByLandlord !== project.projectState.renewalProjectTemplateItemType &&
            Restructure_EstablishCriteriaByTenant !== project.projectState.renewalProjectTemplateItemType
          )
        )
      )
    ) {
      return RequestStatus.Approved;
    }

    return RequestStatus.Pending;
  }

  getRequestAcceptanceDate(project: models.IProjectViewModel): Date {
    if (!project || !project.projectType) {
      return null;
    }

    let milestone: models.IProjectMilestoneViewModel;
    switch (project.projectType.projectTypeEnum) {
      case models.ProjectTypeEnum.Renewal:
        milestone = project.milestones
          .find(x =>
            x.templateItem &&
            x.templateItem.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp
          );

        break;

      case models.ProjectTypeEnum.NewDeal:
        milestone = project.milestones
          .find(x =>
            x.templateItem &&
            x.templateItem.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp
          );

        break;

      case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
        milestone = project.milestones
          .find(x =>
            x.templateItem &&
            x.templateItem.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
          );

        break;
    }

    if (!milestone) {
      return null;
    }

    return milestone.actualStartDate;
  }

  getRequestRejectionDate(project: models.IProjectViewModel): Date {
    if (!project) {
      return;
    }

    return project.rejectedDate;
  }
}
