import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';

import { Role } from '@statera/sdk/auth';

import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  private readonly _authService: AuthService;
  private readonly _router: Router;

  constructor(
    authService: AuthService,
    router: Router,
  ) {
    this._authService = authService;
    this._router = router;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService
      .infoLoadComplete
      .pipe(
        map((startupInfo) =>
          !this._authService.isAuthenticated ||
          state.url === '/reset-password'
        ),
        catchError((err) => {
          const paths = [
            '/',
            '/signin-user',
            '/signup',
            '/forgot-password',
            '/server-error',
            '/reset-password',
            '/2fa'
          ];

          if (!paths.find(x => state.url.startsWith(x))) {
            this._router.navigate(['/signin-user']);

            return of(false);
          }

          return of(true);
        }),
      );
  }
}
