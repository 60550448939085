import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LeaseActivity, LeaseManager } from '@statera/sdk/lease';
import { Subject } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import * as models from '../../../infrastructure/models/generated';
import { ProjectAccessService } from '../../services/project-access.service';
import { ProjectService } from '../../services/project.service';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-colabo-badges',
  templateUrl: './colabo-badges.component.html',
  styleUrls: ['./colabo-badges.component.scss']
})
export class ColaboBadgesComponent implements OnChanges {

  @Input()
  project: models.IProjectViewModel;
  className = '';
  badges: Array<string>;
  ProjectStatus = models.ProjectStatus;
  @Input()
  lease: models.ILeaseViewModel;

  private readonly _projectService: ProjectService;
  private readonly _quizService: QuizService;
  private readonly _destroy$: Subject<void>;
  constructor(
    projectService: ProjectService,
    quizService: QuizService
  ) {
    this._projectService = projectService;
    this._quizService = quizService;
    this._destroy$ = new Subject<void>();
  }

  getProjectName(project: models.IProjectViewModel) {
    return this._projectService.getProjectName(project);
  }

  getCompletedProjectName(project: models.IProjectViewModel) {
    if (!project) {
      return;
    }

    switch (project.projectTypeId) {
      case models.ProjectTypeEnum.NewDeal:
        return 'New Lease';
      case models.ProjectTypeEnum.Restructure:
        return 'Restructured';
      case models.ProjectTypeEnum.Renewal:
      case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
        return 'Renewed';
      case models.ProjectTypeEnum.NewDealInquiry:
        return 'Inquiry';
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    let project = null;
    if (
      changes.project &&
      (
        changes.project.isFirstChange() ||
        (
          changes.project.previousValue !== changes.project.currentValue
        )
      )
    ) {
      project = changes.project.currentValue;
    }
    this._setProject(project);
  }

  private _setProject(project: models.IProjectViewModel) {
    this.badges = [];
    this._getBadges(project);

    const projectName = this.getProjectName(project);
    this.className = projectName && projectName.split(' ').join('-').toLowerCase();
  }

  private _getBadgesForRestructure(answers: Array<models.IQuizAnswerViewModel>): Array<string> {
    const result = [];
    const needLessSpaceBadge = this._getNeedLessSpaceBadge(answers);
    if (needLessSpaceBadge && this.badges.indexOf(needLessSpaceBadge) === -1) {
      result.push(needLessSpaceBadge);
    }
    const needMoreSpaceBadge = this._getNeedMoreSpaceBadge(answers);
    if (needMoreSpaceBadge && this.badges.indexOf(needMoreSpaceBadge) === -1 && result.indexOf(needMoreSpaceBadge) === -1) {
      result.push(needMoreSpaceBadge);
    }
    const needRentReliefOrDeferredRentBadge = this._getRentReliefOrDeferredRentBadge(answers);
    if (needRentReliefOrDeferredRentBadge &&
      this.badges.indexOf(needRentReliefOrDeferredRentBadge) === -1 &&
      result.indexOf(needRentReliefOrDeferredRentBadge) === -1
    ) {
      result.push(needRentReliefOrDeferredRentBadge);
    }

    const blendExtendBadge = this._getBlendExtendBadge(answers);
    if (blendExtendBadge &&
      this.badges.indexOf(blendExtendBadge) === -1 &&
      result.indexOf(blendExtendBadge) === -1
    ) {
      result.push(blendExtendBadge);
    }

    return result;
  }

  private _getBadgesForRenewal(answers: Array<models.IQuizAnswerViewModel>): Array<string> {
    const result = [];
    const needLessSpaceBadge = this._getNeedLessSpaceBadge(answers);
    if (needLessSpaceBadge && this.badges.indexOf(needLessSpaceBadge) === -1) {
      result.push(needLessSpaceBadge);
    }
    const needMoreSpaceBadge = this._getNeedMoreSpaceBadge(answers);
    if (needMoreSpaceBadge && this.badges.indexOf(needMoreSpaceBadge) === -1 && result.indexOf(needMoreSpaceBadge) === -1) {
      result.push(needMoreSpaceBadge);
    }
    const needRentReliefOrDeferredRentBadge = this._getRentReliefOrDeferredRentBadge(answers);
    if (needRentReliefOrDeferredRentBadge &&
        this.badges.indexOf(needRentReliefOrDeferredRentBadge) === -1 &&
        result.indexOf(needRentReliefOrDeferredRentBadge) === -1
    ) {
      result.push(needRentReliefOrDeferredRentBadge);
    }

    const blendExtendBadge = this._getBlendExtendBadge(answers);
    if (blendExtendBadge &&
        this.badges.indexOf(blendExtendBadge) === -1 &&
        result.indexOf(blendExtendBadge) === -1
    ) {
      result.push(blendExtendBadge);
    }

    return result;
  }

  private _getNeedLessSpaceBadge(answers: Array<models.IQuizAnswerViewModel>) {
    let answer = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.NeedLessSpace);
    if (answer && answer.selectedOptionText === 'Yes') {
      return 'Downsize';
    }

    answer = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.HowMuchSpaceNeeded);
    if (answer) {
      if (answer.selectedOptionText === 'More') {
        return 'Expansion';
      }
      if (answer.selectedOptionText === 'Less') {
        return 'Downsize';
      }
    }
  }

  private _getNeedMoreSpaceBadge(answers: Array<models.IQuizAnswerViewModel>) {
    let answer = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.NeedMoreSpace);
    if (answer && answer.selectedOptionText === 'Yes') {
      return 'Expansion';
    }

    answer = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.HowMuchSpaceNeeded);
    if (answer) {
      if (answer.selectedOptionText === 'More') {
        return 'Expansion';
      }
      if (answer.selectedOptionText === 'Less') {
        return 'Downsize';
      }
    }
  }

  private _getRentReliefOrDeferredRentBadge(answers: Array<models.IQuizAnswerViewModel>) {
    const answer = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.RentReliefOrDeferredRent);
    if (answer && answer.selectedOptionText === 'Yes') {
      return 'Rent Relief';
    }
  }

  private _getBlendExtendBadge(answers: Array<models.IQuizAnswerViewModel>) {
    const extendLease = answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.ExtendLease);
    if (extendLease && extendLease.selectedOptionText === 'Yes') {
      const howLongWillingToExtend =
        answers.find(x => x.quizQuestion.quizQuestionBadgeType === models.QuizQuestionBadgeType.HowLongWillingToExtend);
      if (howLongWillingToExtend && howLongWillingToExtend.answerNumber > 12) {
        return 'Blend / Extend';
      }
    }
  }

  private _getExpansionOrDownSize(badges: Array<string>): Array<string> {
    if (this.lease && this.lease.abstractLease && this._tenantSquareTermWasSetOnStage() &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageValue &&
      (this.lease.tenantSquareFootageTerm.tenantSquareFootageValue
        > this.lease.abstractLease.tenantSquareFootageTerm.tenantSquareFootageValue)) {
      badges = badges.filter(badge => badge !== 'Expansion' && badge !== 'Downsize');
      badges.push('Expansion');
    }

    if (this.lease && this.lease.abstractLease && this._tenantSquareTermWasSetOnStage() &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageValue &&
      (this.lease.tenantSquareFootageTerm.tenantSquareFootageValue
        < this.lease.abstractLease.tenantSquareFootageTerm.tenantSquareFootageValue)) {
      badges = this.badges.filter(badge => badge !== 'Expansion' && badge !== 'Downsize');
      badges.push('Downsize');
    }
    return badges;
  }

  private _getBadges(project: models.IProjectViewModel) {
    if (project?.projectTypeId === models.ProjectTypeEnum.Restructure) {
      this._quizService
        .getOnboardingAnswers(this.project.leaseId, environment.restructureOnboardingQuizId)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(answers => {
          this.badges = this._getExpansionOrDownSize([...this.badges, ...this._getBadgesForRestructure(answers)]);
        });
      return;
    }

    if (project?.projectTypeId === models.ProjectTypeEnum.Renewal) {
      this._quizService
        .getOnboardingAnswers(this.project.leaseId, environment.renewalOnboardingQuizId)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(answers => {
          this.badges = this._getExpansionOrDownSize([...this.badges, ...this._getBadgesForRenewal(answers)]);
        });
      return;
    }

    this.badges = this._getExpansionOrDownSize(this.badges);
  }


  private _tenantSquareTermWasSetOnStage() {
    return this.lease && this.lease.tenantSquareFootageTerm && this.lease.tenantSquareFootageTerm.updatedById;
  }
}
