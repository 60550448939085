<label class="analytics-filters-checkbox">
  <input type="checkbox"
         [name]="name"
         [(ngModel)]="value"
         (ngModelChange)="handleValueChange($event)" />
  <span class="analytics-filters-checkbox-icon">
  </span>
  <span>
    <ng-content>
    </ng-content>
  </span>
</label>
