import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-analytics-filters-checkbox',
  templateUrl: 'analytics-filters-checkbox.component.html',
  styleUrls: ['analytics-filters-checkbox.component.scss'],
})
export class AnalyticsFiltersCheckboxComponent {
  @Input() name: string;
  @Input() value: boolean;
  @Output() valueChange: EventEmitter<boolean>;

  constructor() {
    this.valueChange = new EventEmitter<boolean>();
  }

  handleValueChange(value: boolean): void {
    this.valueChange.next(value);
  }
}
