import { DocumentViewerService } from '../services/document-viewer.service';

export class DocumentViewerOptions {
  title?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  fullScreen?: boolean;
  dragEnabled?: boolean;

  activeIndex?: number;
  documentViewerService?: DocumentViewerService;
}

export enum DocumentViewerViewerType {
  Unknown,
  Image,
  Video,
  Pdf,
  OfficeDocument,
}

export class DocumentViewerDocument {
  name?: string;
  url: string;
}

export class DocumentViewerDocumentRef {
  url: string;
  name?: string;
  viewerType: DocumentViewerViewerType;
}
