import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-city',
  templateUrl: 'analytics-filters-location-city.component.html',
  styleUrls: ['analytics-filters-location-city.component.scss'],
})
export class AnalyticsFiltersLocationCityComponent {
  @Input() locationCities: Array<analyticsFiltersModels.LocationCityFilter>;
  @Output() locationCitiesChange: EventEmitter<Array<analyticsFiltersModels.LocationCityFilter>>;

  constructor() {
    this.locationCitiesChange = new EventEmitter<Array<analyticsFiltersModels.LocationCityFilter>>();
  }

  handleCitySelection(): void {
    this.locationCitiesChange.next([...this.locationCities]);
  }
}
