import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';

import { AuthService } from '../../../auth/services/auth.service';

import { IAddressViewModel, IState, ICountry, ICounty } from '../../models/generated';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  _address: IAddressViewModel = <IAddressViewModel>{};
  @Input('address')
  set address(value: IAddressViewModel) {
    this._address = value;
    this.updateComboBoxes();
  }
  get address(): IAddressViewModel {
    return this._address;
  }

  @ViewChild('addressLine1') addressLine1: DxTextBoxComponent;
  @ViewChild('zipCode') zipCode: DxTextBoxComponent;
  @ViewChild('city') city: DxTextBoxComponent;
  @ViewChild('country') country: DxSelectBoxComponent;
  @ViewChild('state') state: DxSelectBoxComponent;

  states: Array<IState> = [];
  countries: Array<ICountry> = [];
  counties: Array<ICounty> = [];
  allCounties: Array<ICounty> = [];
  initialized = false;

  constructor(private authService: AuthService) {
    this.authService.infoLoadComplete.subscribe(info => {
      if (info) {
        this.updateComboBoxes();
      }
    });
  }

  ngOnInit() {
    setTimeout(() => (this.initialized = true), 50);
  }

  onStateChange(e) {
    this.counties = this.allCounties.filter(x => x.stateCode === e.value);
  }

  reset() {
    this.initialized = false;
    setTimeout(() => {
      // this.addressLine1.validator.instance.reset();
      // this.zipCode.validator.instance.reset();
      // this.city.validator.instance.reset();
      // this.country.validator.instance.reset();
      // this.state.validator.instance.reset();
      this.initialized = true;
    }, 50);
  }

  updateComboBoxes() {
    if (this.address) {
      if (this.address.stateCode) {
        this.onStateChange({ value: this.address.stateCode });
      }
    }
  }
}
