import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import { DxPopoverComponent } from 'devextreme-angular';

import { Role } from '@statera/sdk/auth';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-lease-term-custom-table-popover',
  templateUrl: './lease-term-custom-table-popover.component.html',
  styleUrls: ['./lease-term-custom-table-popover.component.scss']
})
export class LeaseTermCustomTablePopoverComponent implements OnInit, OnDestroy {
  @ViewChild('previewWrapperElement') previewWrapperElementRef: ElementRef;

  @Input() popoverContainer: HTMLElement;
  @Input() leaseTermCustomTable: models.ILeaseTermCustomTableViewModel;

  @Input() rentSchedulePreviewPopoverSize: { width?: number };

  @Input() role: string;
  @Input() isAccepted: boolean;

  @Input() resizeByContent: boolean;

  popoverSize: { width?: number };

  contentHeight: number;

  Role: typeof Role;

  private readonly _changeDetectorRef: ChangeDetectorRef;

  constructor(changeDetectorRef: ChangeDetectorRef) {
    this._changeDetectorRef = changeDetectorRef;
    this.Role = Role;
  }

  ngOnInit(): void {
    this.contentHeight = 246;
  }

  showPopover(popoverComponent: DxPopoverComponent, popoverContainerElement: HTMLElement): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    const popoverInstance = popoverComponent.instance;
    popoverInstance.option('closeOnTargetScroll', () => {
      popoverInstance.repaint();
      return true;
    });

    popoverComponent.visible = true;

    // Detect changes so popover would be rendered.
    this._detectChanges();

    this.contentHeight = this.previewWrapperElementRef.nativeElement.offsetHeight < 246 ?
      this.previewWrapperElementRef.nativeElement.offsetHeight :
      246;

    // Detect changes once again so popover content could render with a correct height.
    // We need a fixed numeric height so dx-scrollview could work properly.
    this._detectChanges();

    if (popoverContainerElement) {
      this.resizePopover(popoverContainerElement);

      const windowResizeHandler = () => {
        this.resizePopover(popoverContainerElement);
      };

      window.addEventListener('resize', windowResizeHandler, false);

      popoverInstance.on('hidden', () => {
        window.removeEventListener('resize', windowResizeHandler, false);
      });
    }
  }

  resizePopover(popoverContainerElement: HTMLElement): void {
    if (!popoverContainerElement) {
      return;
    }

    this.popoverSize = {
      width: popoverContainerElement.offsetWidth / 2,
    };

    if (this.resizeByContent) {
      delete this.popoverSize.width;
    }

    this._detectChanges();
  }

  ngOnDestroy(): void {
    this._changeDetectorRef.detach();
  }

  showPopoverContainer(): boolean {
    return this.leaseTermCustomTable && this.leaseTermCustomTable.rows && this.leaseTermCustomTable.rows.length > 0;
  }

  private _detectChanges(): void {
    if ((<ViewRef>this._changeDetectorRef).destroyed) {
      return;
    }


    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
}
