import { Injectable } from '@angular/core';
import { Observable, of, race, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ChatChannelType, Role, TermCommentType } from '@statera/sdk/common';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { Notification, NotificationRepository, NotificationTopic } from '@statera/sdk/notification';
import { WebsocketClient, WebsocketRepository } from '@statera/sdk/websocket';

import {
  Alert,
  AlertGroup,
  AlertNotification,
  AlertNotificationKind,
  AlertNotificationReference,
  AlertType,
  LeaseTermType
} from './alert.model';

import { AlertRepository } from './alert.repository';
import { AlertTransformer } from './alert.transformer';

const LEASE_TERM_NAMES: {} = {
  [LeaseTermType.ExpansionOption]: 'ExpansionOption',
  [LeaseTermType.Commencement]: 'Commencement',
  [LeaseTermType.Term]: 'Term',
  [LeaseTermType.TenantSquareFootage]: 'TenantSquareFootage',
  [LeaseTermType.FreeRent]: 'FreeRent',
  [LeaseTermType.BaseRentalRate]: 'RentalRate',
  [LeaseTermType.SecurityDeposit]: 'SecurityDeposit',
  [LeaseTermType.RealEstateTaxesCamExpenses]: 'RealEstateTaxesCamExpenses',
  [LeaseTermType.RentalRateAnnualEscalation]: 'RentalRateAnnualEscalation',
  [LeaseTermType.TenantImprovements]: 'TenantImprovements',
  [LeaseTermType.LandlordMaintenance]: 'LandlordMaintenance',
  [LeaseTermType.TenantMaintenance]: 'TenantMaintenance',
  [LeaseTermType.SelfHelp]: 'SelfHelp',
  [LeaseTermType.Assignment]: 'Assignment',
  [LeaseTermType.RenewalOption]: 'RenewalOption',
  [LeaseTermType.EstoppelCertificate]: 'EstoppelCertificate',
  [LeaseTermType.TerminationOption]: 'TerminationOption',
  [LeaseTermType.Hvac]: 'Hvac',
  [LeaseTermType.Insurance]: 'Insurance',
  [LeaseTermType.HoldoverProvision]: 'HoldoverProvision',
  [LeaseTermType.Parking]: 'Parking',
  [LeaseTermType.Utilities]: 'Utilities',
  [LeaseTermType.SpaceUse]: 'SpaceUse',
  [LeaseTermType.CodeCompliance]: 'CodeCompliance',
  [LeaseTermType.ConditionOfPremises]: 'ConditionOfPremises',
  [LeaseTermType.Signage]: 'Signage',
};

@Injectable()
export class AlertManager {
  private readonly _loggerService: LoggerService;
  private readonly _alertRepository: AlertRepository;
  private readonly _alertTransformer: AlertTransformer;
  private readonly _websocketRepository: WebsocketRepository;
  private readonly _websocketClient: WebsocketClient;
  private readonly _notificationRepository: NotificationRepository;

  constructor(
    loggerService: LoggerService,
    alertRepository: AlertRepository,
    alertTransformer: AlertTransformer,
    websocketRepository: WebsocketRepository,
    websocketClient: WebsocketClient,
    notificationRepository: NotificationRepository
  ) {
    this._loggerService = loggerService;
    this._alertRepository = alertRepository;
    this._alertTransformer = alertTransformer;
    this._websocketRepository = websocketRepository;
    this._websocketClient = websocketClient;
    this._notificationRepository = notificationRepository;
  }

  markAlertRead(alert: Alert): Observable<void> {
    return this._alertRepository
      .markAlertRead(alert);
  }

  getRealtimeAlertGroup(): Observable<AlertGroup> {
    return this._websocketRepository
      .getRealtimeAlertGroup()
      .pipe(
        switchMap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Pulling alert group`);

          return this.getAlertGroup();
        }),
        tap(alertGroup => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert group pulled successfully`, alertGroup);
        }),
        catchError(error => {
          this._loggerService
            .error(LoggerTopic.Alert, `An error occurred while pulling alert group`, error);

          return throwError(error);
        }),
      );
  }

  getRealtimeAlertsThatRequireAction(): Observable<Array<Alert>> {
    return this._websocketRepository
      .getRealtimeAlertGroup()
      .pipe(
        switchMap(response => {
          if (!response || !response.isPulling && (!response.model || 0 === response.model.countOfAlertsRequiresAction)) {
            return of([]);
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Pulling alerts that require action`);

          return this.getAlertsThatRequireAction();
        }),
        tap(alertsRequiredAction => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alerts that require action pulled successfully`, alertsRequiredAction);
        }),
        catchError(error => {
          this._loggerService
            .error(LoggerTopic.Alert, `An error occurred while pulling alerts that require action`, error);

          return throwError(error);
        }),
      );
  }

  getAlertGroup(): Observable<AlertGroup> {
    return this._alertRepository
      .getAlertGroup();
  }

  getAlertsThatRequireAction(): Observable<Array<Alert>> {
    return this._alertRepository
      .getAlertsThatRequireAction()
      .pipe(
        map(alerts => alerts
          .map(alert => this._alertTransformer
            .transformAlertText(alert)
          )
        )
      );
  }

  changeIsRequiresAction(alertId: number, isRequiresAction: boolean): Observable<Alert> {
    return this._alertRepository
      .changeIsRequiresAction(alertId, isRequiresAction)
      .pipe(
        map(alert => this._alertTransformer
          .transformAlertText(alert)
        )
      );
  }

  getLatestRealtimeAlertGroup(): Observable<AlertGroup> {
    return this._alertRepository
      .getLatestRealtimeAlertGroup();
  }

  queueAlertNotification(alertNotification: AlertNotification): AlertNotificationReference {
    alertNotification = this._fillAlertNotificationWithDefaultValues(alertNotification);

    const notification = new Notification(alertNotification.id, alertNotification);

    this._notificationRepository
      .pushNotification(NotificationTopic.Alert, notification);

    return alertNotification.reference;
  }

  dequeueAlertNotification(alertNotification: AlertNotification): void {
    this._notificationRepository
      .removeNotification(NotificationTopic.Alert, alertNotification.id);
  }

  processAlertNotifications(): Observable<Array<Notification<AlertNotification>>> {
    return this._notificationRepository
      .getNotifications<AlertNotification>(NotificationTopic.Alert);
  }

  processRealtimeAlertNotifications(userRole: Role | string): Observable<Array<Notification<AlertNotification>>> {
    const destroy = new Subject<void>();

    this._processRealtimeAlertGroupNotifications(userRole, destroy);
    this._processRealtimeRequiresActionAlertNotifications(userRole, destroy);

    this._processWebSocketTermination(destroy);

    return this.processAlertNotifications()
      .pipe(
        finalize(() => {
          destroy.next();
          destroy.complete();
        }),
      );
  }

  private _fillAlertNotificationWithDefaultValues(alertNotification: AlertNotification): AlertNotification {
    let defaultAlertNotification = <AlertNotification>{
      id: alertNotification.id || Date.now(),
      kind: alertNotification.kind || AlertNotificationKind.Info,
      reference: alertNotification.reference || new AlertNotificationReference(),
      closable: true,
      autoclose: true,
    };

    if (alertNotification.kind === AlertNotificationKind.Confirm) {
      defaultAlertNotification = {
        ...defaultAlertNotification,
        closable: false,
        autoclose: false,
        confirmButtonText: 'Yes',
        declineButtonText: 'No',
        shouldShowConfirmButton: true,
        shouldShowDeclineButton: true,
        closeOnButtonClick: true,
        shouldDisableButtons: true,
      };
    }

    return {
      ...defaultAlertNotification,
      ...alertNotification,
    };
  }

  private _getAlertNotification(
    alertNotificationKind: AlertNotificationKind,
    alert: Alert,
    reference: AlertNotificationReference,
    options: AlertNotification = <AlertNotification>{},
  ): AlertNotification {
    return <AlertNotification>{
      id: alert.id,
      senderName: alert.createdBy ? alert.createdBy.displayName : null,
      senderCompany: alert.createdBy ? alert.createdBy.company ? alert.createdBy.company.name : null : null,
      senderRole: alert.leaseUserRole,
      leaseId: alert.leaseId,
      projectId: alert.projectId,
      projectType: alert.projectType,
      buildingId: alert.buildingId,
      buildingAddress: alert.leaseBuilding?.address?.displayString,
      leaseRequestId: alert.leaseRequestId,
      termName: LEASE_TERM_NAMES[alert.leaseTermType],
      kind: alertNotificationKind || AlertNotificationKind.Info,
      reference: reference,
      alertType: alert.alertSettingTemplate && alert.alertSettingTemplate.alertType,
      title: alert.title,
      message: alert.text,
      closable: true,
      autoclose: true,
      shouldDisableButtons: true,
      chatType: this._getMessageChatType(alert),
      ...options,
    };
  }

  private _getConfirmAlertNotification(
    alert: Alert,
    reference: AlertNotificationReference,
    options: AlertNotification = <AlertNotification>{},
  ): AlertNotification {
    return <AlertNotification>{
      id: alert.id,
      senderName: alert.createdBy ? alert.createdBy.displayName : null,
      senderCompany: alert.createdBy ? alert.createdBy.company ? alert.createdBy.company.name : null : null,
      senderRole: alert.leaseUserRole,
      leaseId: alert.leaseId,
      projectId: alert.projectId,
      projectType: alert.projectType,
      buildingId: alert.buildingId,
      buildingAddress: alert.leaseBuilding?.address?.displayString,
      leaseRequestId: alert.leaseRequestId,
      termName: LEASE_TERM_NAMES[alert.leaseTermType],
      kind: AlertNotificationKind.Confirm,
      reference: reference,
      alertType: alert.alertSettingTemplate && alert.alertSettingTemplate.alertType,
      title: alert.title,
      message: alert.text,
      closable: false,
      autoclose: false,
      shouldShowConfirmButton: true,
      shouldShowDeclineButton: true,
      closeOnButtonClick: true,
      shouldDisableButtons: true,
      chatType: this._getMessageChatType(alert),
      ...options,
    };
  }

  private _processWebSocketTermination(destroyObserver: Observable<void>): void {
    this._websocketClient
      .connectionTerminated()
      .pipe(
        tap(() => {
          const alertNotificationReference = new AlertNotificationReference();

          const alertNotification = this._fillAlertNotificationWithDefaultValues(<AlertNotification>{
            id: 'connection-lost',
            kind: AlertNotificationKind.Confirm,
            title: 'Whoops! Connection Lost',
            message: (
              'Slow or no connection. ' +
              '<br />' +
              'Please reload page. '
            ),
            alertType: AlertType.WebSocketConnectionTerminated,
            closable: false,
            autoclose: false,
            confirmButtonText: 'Reload',
            shouldShowDeclineButton: false,
            reference: alertNotificationReference,
          });

          this.queueAlertNotification(alertNotification);
        }),
        distinctUntilChanged(),
        takeUntil(destroyObserver),
      )
      .subscribe();
  }

  private _getMessageChatType(alert: Alert): string | null {
    if (alert.chatChannelType != null) {
      switch (alert.chatChannelType) {
        case ChatChannelType.LeaseLandlordTeam:
        case ChatChannelType.LeaseTenantTeam: return 'Internal';
        case ChatChannelType.Lease: return 'External';
      }
      return null;
    }

    if (alert.leaseTermType != null) {
      switch (alert.termCommentType) {
        case TermCommentType.LandlordTeam:
        case TermCommentType.TenantTeam: return 'Internal';
        case TermCommentType.BothTeams: return 'External';
      }
    }

    return null;
  }

  private _processRealtimeAlertGroupNotifications(userRole: Role | string, destroyObserver: Observable<void>): void {
    this.getAlertGroup()
      .pipe(
        tap(alertGroup => {
          this._alertRepository.setLatestRealtimeAlertGroup(alertGroup);

          this._notifyAboutNewAlerts(userRole, alertGroup, destroyObserver);
        }),
        take(1),
        takeUntil(destroyObserver),
        finalize(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Sync alert notifying stopped`);
        }),
      )
      .subscribe();

    this.getRealtimeAlertGroup()
      .pipe(
        tap(alertGroup => {
          this._alertRepository.setLatestRealtimeAlertGroup(alertGroup);

          this._notifyAboutNewAlerts(userRole, alertGroup, destroyObserver);
        }),
        takeUntil(destroyObserver),
        finalize(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Async alert notifying stopped`);
        }),
      )
      .subscribe();
  }

  private _processRealtimeRequiresActionAlertNotifications(userRole: Role | string, destroyObserver: Observable<void>): void {
    this.getAlertsThatRequireAction()
      .pipe(
        tap(alerts => this._notifyUserAboutNewRequiresActionAlerts(alerts, destroyObserver)),
        take(1),
        takeUntil(destroyObserver),
        finalize(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Sync requires action alert notifying stopped`);
        }),
      )
      .subscribe();

    this.getRealtimeAlertsThatRequireAction()
      .pipe(
        tap(alerts => this._notifyUserAboutNewRequiresActionAlerts(alerts, destroyObserver)),
        takeUntil(destroyObserver),
        finalize(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Async requires action alert notifying stopped`);
        }),
      )
      .subscribe();
  }

  private _notifyAboutNewAlerts(userRole: Role | string, alertGroup: AlertGroup, destroyObserver: Observable<void>): void {
    if (!alertGroup) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert group is null`);

      return;
    }

    let alert = alertGroup.lastAlert;
    if (!alert) {
      this._loggerService
        .info(LoggerTopic.Alert, `Alerts not listed`);

      return;
    }

    if (alert.isRead) {
      this._loggerService
        .info(LoggerTopic.Alert, `Alert already read`, { alert });

      return;
    }

    alert = this._alertTransformer
      .transformAlert(alert, userRole);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getAlertNotification(AlertNotificationKind.Info, alert, alertNotificationReference);

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .shown
      .pipe(
        tap(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Alert shown`, { alert });
        }),
        switchMap(() => this.markAlertRead(alert)),
        tap(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Alert marked as read`, { alert });
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .debug(LoggerTopic.Alert, `Alert hidden`, { alert });
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyUserAboutNewRequiresActionAlerts(alerts: Array<Alert>, destroyObserver: Observable<void>): void {
    if (!alerts || !alerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Alerts not listed`);

      return;
    }

    const buildingUnitListingUpdatePricePromptAlerts = alerts
      .filter(x => x.alertSettingTemplate && x.alertSettingTemplate.alertType === AlertType.BuildingUnitListingUpdatePricePrompt);

    if (buildingUnitListingUpdatePricePromptAlerts && buildingUnitListingUpdatePricePromptAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Building unit listing update price prompt alerts received`, alerts);

      this._notifyUserAboutBuildingUnitListingUpdatePricePromptAlerts(
        buildingUnitListingUpdatePricePromptAlerts,
        destroyObserver
      );
    }

    const financialsRequestAlerts = alerts
      .filter(x => x.alertSettingTemplate && x.alertSettingTemplate.alertType === AlertType.FinancialsRequest);

    if (financialsRequestAlerts && financialsRequestAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Financials request alerts received`, alerts);

      this._notifyUserAboutNewFinancialsRequestAlerts(financialsRequestAlerts, destroyObserver);
    }

    const financialsReviewRequestAlerts = alerts
      .filter(x => x.alertSettingTemplate && x.alertSettingTemplate.alertType === AlertType.FinancialsReviewRequest);

    if (financialsReviewRequestAlerts && financialsReviewRequestAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Financials review request alerts received`, alerts);

      this._notifyUserAboutNewFinancialsReviewRequestAlerts(financialsReviewRequestAlerts, destroyObserver);
    }

    const renewalOrRestructureRequestAlerts = alerts
      .filter(x => x.alertSettingTemplate && x.alertSettingTemplate.alertType === AlertType.NewRenewalOrRestructureRequest);

    if (renewalOrRestructureRequestAlerts && renewalOrRestructureRequestAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Renewal/Restructure request alerts received`, alerts);

      this._notifyUserAboutNewRenewalOrRestructureRequestAlerts(renewalOrRestructureRequestAlerts, destroyObserver);
    }

    const coBrokerInvitationByBrokerAlerts = alerts
      .filter(x => x.alertSettingTemplate && x.alertSettingTemplate.alertType === AlertType.CoBrokerInvitationByBroker);

    if (coBrokerInvitationByBrokerAlerts && coBrokerInvitationByBrokerAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `CoBrokerInvitationByBrokerAlerts request alerts received`, alerts);

      this._notifyCoBrokerInvitationAlerts(coBrokerInvitationByBrokerAlerts, destroyObserver);
    }
  }

  private _notifyUserAboutBuildingUnitListingUpdatePricePromptAlerts(
    alerts: Array<Alert>,
    destroyObserver: Observable<void>
  ): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);

      return;
    }

    const buildingId = alert.buildingId;
    if (!buildingId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open confirm alert because it doesn't contain building id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Modify',
        declineButtonText: 'Keep',
        closeOnButtonClick: true,
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next building unit listing update price prompt alert`);

          this._notifyUserAboutBuildingUnitListingUpdatePricePromptAlerts(alerts, destroyObserver);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyUserAboutNewFinancialsRequestAlerts(alerts: Array<Alert>, destroyObserver: Observable<void>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);

      return;
    }

    const leaseRequestId = alert.leaseRequestId;
    if (!leaseRequestId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open alert because it doesn't contain lease request id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Upload',
        declineButtonText: 'Cancel',
        closeOnButtonClick: false,
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next financial request alert`);

          this._notifyUserAboutNewFinancialsRequestAlerts(alerts, destroyObserver);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyUserAboutNewFinancialsReviewRequestAlerts(alerts: Array<Alert>, destroyObserver: Observable<void>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Review',
        declineButtonText: 'Cancel',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next financial review request alert`);

          this._notifyUserAboutNewFinancialsReviewRequestAlerts(alerts, destroyObserver);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyUserAboutNewRenewalOrRestructureRequestAlerts(alerts: Array<Alert>, destroyObserver: Observable<void>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);

      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Review',
        declineButtonText: 'Cancel',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next renewal/restructure request alert`);

          this._notifyUserAboutNewRenewalOrRestructureRequestAlerts(alerts, destroyObserver);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyCoBrokerInvitationAlerts(alerts: Array<Alert>, destroyObserver: Observable<void>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);

      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Yes',
        declineButtonText: 'No',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next co-broker invitation alert`);

          this._notifyCoBrokerInvitationAlerts(alerts, destroyObserver);
        }),
        take(1),
        takeUntil(
          race(destroyObserver, notification.dequeued)
        ),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }
}
