import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './lease-document.model';

@Injectable()
export class LeaseDocumentRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getLeaseAmendments(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._httpClient
      .get<models.LeaseAmendmentFile>(
      `${this._configuration.apiEndpoint}/LeaseDocuments/GetLeaseAmendment?leaseId=${leaseId}`,
      );
  }

  getVersionedFiles(leaseId: number): Observable<Array<models.VersionedFile>> {
    return this._httpClient
      .get<Array<models.VersionedFile>>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/GetVersionedFiles?leaseId=${leaseId}`,
      );
  }

  getCommentProfiles(
    leaseId: number,
    versionedFileCommentType: models.VersionedFileCommentType,
  ): Observable<Array<models.Profile>> {
    let params = new HttpParams();

    params = params.set('leaseId', leaseId.toString());
    params = params.set('versionedFileCommentType', versionedFileCommentType.toString());

    return this._httpClient
      .get<Array<models.Profile>>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/GetCommentProfiles`,
        {
          params,
        },
      );
  }

  acceptLeaseDocument(leaseId: number): Observable<models.VersionedFile> {
    return this._httpClient
      .post<models.VersionedFile>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/accept?leaseId=${leaseId}`,
        null,
      );
  }

  getSignDocument(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._httpClient
      .get<models.LeaseAmendmentFile>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/GetSignDocument?leaseId=${leaseId}`,
      );
  }

  setAsSigned(leaseId: number): Observable<models.LeaseAmendmentFile> {
    return this._httpClient
      .post<models.LeaseAmendmentFile>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/SetAsSigned?leaseId=${leaseId}`,
        null,
      );
  }

  setLeaseAmendmentSigner(
    whoSignLeaseAmendment: models.WhoSignLeaseAmendment,
  ): Observable<models.WhoSignLeaseAmendment> {
    return this._httpClient
      .post<models.WhoSignLeaseAmendment>(
        `${this._configuration.apiEndpoint}/LeaseDocuments/SetLeaseAmendmentSigner`,
        whoSignLeaseAmendment,
      );
  }
}
