import { Component } from '@angular/core';

import { DialogRefService } from '../../../../dialog/services/dialog-ref.service';

@Component({
  selector: 'app-term-custom-value-template-delete-dialog',
  templateUrl: './term-custom-value-template-delete-dialog.component.html',
  styleUrls: ['./term-custom-value-template-delete-dialog.component.scss']
})
export class TermCustomValueTemplateDeleteDialogComponent {
  private readonly _dialogRefService: DialogRefService;

  constructor(
    dialogRefService: DialogRefService,
  ) {
    this._dialogRefService = dialogRefService;
  }

  handleCancelClick(): void {
    this.close();
  }

  handleDeleteClick(): void {
    this._dialogRefService.outputData = {
      isConfirmed: true,
    };

    this.close();
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
