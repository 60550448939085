import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { DxSelectBoxComponent, DxTextBoxComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { take } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { CommonTools } from '@statera/sdk/common';
import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { AlertService } from '../../../alert/services/alert.service';
import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

import { AddressComponent } from '../../../infrastructure/components/address/address.component';

import { environment } from '../../../../environments/environment';

import ArrayStore from 'devextreme/data/array_store';

const GREEN_CERTIFICATIONS = [
  { id: 1, text: 'Leed Certified', type: models.GreenCertification.LeedCertified },
  { id: 2, text: 'Leed Silver', type: models.GreenCertification.LeedSilver },
  { id: 3, text: 'Leed Gold', type: models.GreenCertification.LeedGold },
  { id: 4, text: 'Leed Platinum', type: models.GreenCertification.LeedPlatinum },
  { id: 5, text: 'Energy Star', type: models.GreenCertification.EnergyStar },
  { id: 6, text: 'Breeam', type: models.GreenCertification.Breeam },
  { id: 7, text: 'Green Globe', type: models.GreenCertification.GreenGlobe },
  { id: 14, text: 'Green Globe Gold', type: models.GreenCertification.GreenGlobeGold },
  { id: 15, text: 'Green Globe Platinum', type: models.GreenCertification.GreenGlobePlatinum },
  { id: 8, text: 'Living Building Challenge', type: models.GreenCertification.LivingBuildingChallenge },
  { id: 9, text: 'National Green Building Standard', type: models.GreenCertification.NationalGreenBuildingStandard },
  { id: 10, text: 'Green Guard', type: models.GreenCertification.GreenGuard },
  { id: 11, text: 'Well Building Standard', type: models.GreenCertification.WellBuildingStandard },
  { id: 12, text: 'Fitwell', type: models.GreenCertification.Fitwell },
];

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.scss']
})
export class BuildingFormComponent implements OnInit, AfterViewInit {
  private readonly _buildingManager: BuildingManager;
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _router: Router;

  private _building: models.IBuildingViewModel;

  @Input() set building(val: models.IBuildingViewModel) {
    this._building = val;

    if (val && val.greenCertificates) {
      let minId = 0;
      const otherCertificates = [];
      const selectedCertificates = [];

      val.greenCertificates.forEach((cert) => {
        if (cert.certificationType === models.GreenCertification.Other) {
          otherCertificates.push({ id: minId - 1, type: cert.certificationType, text: cert.customValue });
          selectedCertificates.push(minId - 1);
          minId--;
          return;
        }

        const mappedCert = GREEN_CERTIFICATIONS.find(x => x.type === cert.certificationType);
        if (!mappedCert) {
          return;
        }
        selectedCertificates.push(mappedCert.id);
      });

      this.greenCertifications = [...GREEN_CERTIFICATIONS, ...otherCertificates];
    }
  }
  get building() {
    return this._building;
  }

  @Input() isMobile = false;
  @ViewChild('portfolioId') portfolioField: DxSelectBoxComponent;
  @ViewChild('name') nameField: DxTextBoxComponent;
  @ViewChild('address') address: AddressComponent;
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;
  @ViewChild('pinNumber') pinNumber: DxTextBoxComponent;
  @ViewChild('columnSpacing') columnSpacing: DxTextBoxComponent;
  @ViewChild('buildingType') buildingType: DxSelectBoxComponent;
  @ViewChild('market') market: DxSelectBoxComponent;
  @ViewChild('subMarket') subMarket: DxSelectBoxComponent;

  BuildingClass = models.BuildingClass;
  BuildingType = models.BuildingType;
  fields: Array<any> = null;
  buildingClasses = CommonTools.EnumToArray(models.BuildingClass);
  buildingTypes = CommonTools.EnumToArray(models.BuildingType);
  leedCertifications = CommonTools.EnumToArray(models.LeedCertification);

  markets: Array<models.IMarket> = [];
  allMarkets: Array<models.IMarket> = [];
  subMarkets: Array<models.IMarket> = [];
  allSubMarkets: Array<models.IMarket> = [];

  buildingCertificates = [];
  greenCertifications = [...GREEN_CERTIFICATIONS];
  certificationsDataSource: any = new ArrayStore({
    data: this.greenCertifications,
    key: 'id',
  });
  sprinklerSystems = CommonTools.EnumToArray(models.SprinklerSystem);

  portfolios: Array<models.IPortfolioViewModel> = [];
  submitted = false;
  initialized = false;
  uploading = false;
  uploadUrl = environment.webApiUrl + '/storage/Upload/810';

  constructor(
    buildingManager: BuildingManager,
    landlordStore: LandlordStore,
    landlordManager: LandlordManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    router: Router,
  ) {
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._buildingManager = buildingManager;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
  }

  ngAfterViewInit(): void {
    this._landlordStore
      .portfolios$
      .subscribe((portfolios) => {
        this.portfolios = portfolios;
      });

    setTimeout(() => {
      this.initialized = true;
    }, 500);
  }

  ngOnInit() {
    this._landlordStore
      .portfolios$
      .pipe(
        take(1)
      )
      .subscribe((portfolios) => {
        this.portfolios = portfolios;
        this.building = this._buildingManager
          .findInPortfolios(null, portfolios);
      });

    this._landlordStore
      .preSelectedPortfolio$
      .pipe(
        take(1)
      )
      .subscribe((portfolio) => {
        if (portfolio) {
          this.building.portfolioId = portfolio.id;
        }
      });
  }

  onMarketChange(e) {
    this.building.market = this.markets.find(x => x.id === e.value);

    this.markets = this.allMarkets;
    this.subMarkets = this.allSubMarkets.filter(x => x.parentMarketId === this.building.marketId);

    if (
      this.allMarkets.length > 0 &&
      (this.markets.length === 0 || this.markets.filter(x => x.id === this.market.value).length === 0)
    ) {
      this.market.value = null;
    }
  }

  onSubMarketChange(e) {
    this.building.subMarket = this.subMarkets.find(x => x.id === e.value);

    if (
      this.allSubMarkets.length > 0 &&
      (this.subMarkets.length === 0 || this.subMarkets.filter(x => x.id === this.subMarket.value).length === 0)
    ) {
      this.subMarket.value = null;
    }
  }

  updateComboBoxes() {
    if (this.building) {
      if (this.building.marketId) {
        this.onMarketChange({ value: this.building.marketId });
      }
      if (this.building.subMarketId) {
        this.onSubMarketChange({ value: this.building.subMarketId });
      }
    }
  }

  submit() {
    if (this.submitted || !this.validate()) {
      return;
    }
    this.submitted = true;

    this._landlordManager
      .saveBuilding({...this.building, greenCertificates: this.getCertificates()})
      .subscribe(x => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getBuildingCreatedAlertText(),
        });

        if (this.building.id > 0) {
          this._router.navigate([`/landlord/buildings/building-desktop/${this.building.id}`]);
        } else {
          window.scrollTo(0, 0);
        }

        this._landlordStore
          .portfolios$
          .subscribe((portfolios) => {
            this.building = this._buildingManager
              .findInPortfolios(null, portfolios);

            // tslint:disable-next-line:forin
            for (const i in this.fields) {
              this.fields[i].validator.instance.reset();
            }
            this.address.reset();
            this.columnSpacing.instance.reset();
            this.columnSpacing.instance.option({isValid: true});
            this.pinNumber.instance.reset();
            this.pinNumber.instance.option({isValid: true});
          });
      })
      .add(() => (this.submitted = false));
  }

  validate(): boolean {
    let valid = true;
    if (!this.fields) {
      this.fields = [this.portfolioField, this.nameField];
    }
    // tslint:disable-next-line:forin
    for (const i in this.fields) {
      if (!this.fields[i].validator || !this.fields[i].validitor.instance) {
        continue;
      }

      valid = valid && this.fields[i].validator.instance.validate().isValid;
    }
    return valid;
  }


  onUploadAborted() {
    this.uploading = false;
  }

  typeOfBuildingSelected() {
    if (this.initialized) {
      this._alertService.pushWarningAlert({
        message: this._alertMessagesManager.getOnlyIndustrialBuildingTypeCanBeSelectedAlertText(),
      });

      this.buildingType.instance.option('value', models.BuildingType.Industrial);
    }
  }

  onCustomItemCreating(args) {
    let minId = 0;
    this.greenCertifications.forEach((val) => val.id < minId ? minId = val.id : null);

    const newVal = { id: minId - 1, text: args.text, type: models.GreenCertification.Other };
    this.greenCertifications.push(newVal);
    this.certificationsDataSource.insert(newVal);
    args.customItem = newVal;
  }

  getCertificates(): Array<models.IBuildingCertificateViewModel> {
    const existingValues = new Set<string>();
    const result: Array<models.IBuildingCertificateViewModel> = [];

    this.buildingCertificates.forEach((certId) => {
      const cert = this.greenCertifications.find(x => x.id === certId);

      if (existingValues.has(cert.text)) {
        return;
      }

      result.push({
        certificationType: cert.type,
        customValue: cert.type === models.GreenCertification.Other
          ? cert.text
          : null
      });
      existingValues.add(cert.text);
    });

    return result;
  }
}
