<ng-container [ngSwitch]="quizItem.quizItemType">
  <ng-container *ngSwitchCase="QuizItemType.File">
    <a *ngIf="quizItem.quizQuestion.questionType === QuestionType.File && quizItem.answer.fileUrl"
       [href]="quizItem.answer.fileUrl">
      File Uploaded
    </a>

    <span *ngIf="quizItem.quizQuestion.questionType === QuestionType.File && !quizItem.answer.fileUrl">
          No File
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="QuizItemType.Files">
    <ng-container *ngIf="quizItem.quizQuestion.questionType === QuestionType.FinancialDocuments">
      <ng-container *ngIf="quizItem.quizAnswer && quizItem.quizAnswer.quizAnswerFiles?.length > 0">
        <a (click)="previewFile(quizItem.answers)" role="button">
          View Financials
        </a>

      </ng-container>


      <span *ngIf="(!quizItem.quizAnswer.quizAnswerFiles || quizItem.quizAnswer.quizAnswerFiles.length == 0)">
            Financials have not been uploaded
      </span>
    </ng-container>

    <ng-container *ngIf="quizItem.quizQuestion.questionType !== QuestionType.FinancialDocuments">
      <a (click)="previewFile(quizItem.answers)" role="button">
        View File
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="QuizItemType.List">
    <ul *ngIf="quizItem.answers">
      <li  *ngFor="let answer of quizItem.answers">
        {{ answer.answer }}
      </li>
    </ul>
  </ng-container>

  <ng-container *ngSwitchCase="QuizItemType.Text">
    <ng-container *ngIf="quizItem.quizQuestion.questionType === QuestionType.BrokerInvitation">
      <ng-container *ngIf="quizItem.quizAnswer?.questionOptionViewModel?.answerType === AnswerType.ProceedWithoutBroker">
        <span>
          Proceed Direct Without a Broker
        </span>
      </ng-container>

      <ng-container *ngIf="quizItem.quizAnswer?.questionOptionViewModel?.answerType === AnswerType.InviteBroker;">
        <ng-container *ngIf="quizItem.quizAnswer?.userInvitationRequests; let brokerInvitations; else unableToShowBrokerInvitation">
          <span>Broker info:</span> <br/>
          <ng-container *ngFor="let brokerInvitation of brokerInvitations; let i = index">
            <span>
              {{ brokerInvitation.firstName }} {{ brokerInvitation.lastName }},
                {{ brokerInvitation.companyName }},
            </span>

            <span class="text-nowrap">
              <a [href]="'mailto:' + brokerInvitation.emailAddress">{{ brokerInvitation.emailAddress }}</a>
            </span>
            <ng-container *ngIf="i !== brokerInvitations.length - 1">
              <br/>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #unableToShowBrokerInvitation>
          <span>
            Unable to show broker invitation
          </span>
        </ng-template>
      </ng-container>
    </ng-container>

    <span *ngIf="renderAsHtml(quizItem.quizAnswer)"
          [innerHtml]="quizItem.answer?.answer">
    </span>

    <ng-container *ngIf="renderAsPreWrap(quizItem.quizAnswer)">
          <span class="pre-wrap">{{quizItem.answer?.answer}}</span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="QuizItemType.Table">
    <table class="table">
      <tbody>
      <tr *ngFor="let row of quizItem.quizItemTable?.quizItemRows; let index = index;">
        <td class="align-middle w-25" *ngFor="let cell of row.quizItemColumns; let i = index;">
          <div class="d-flex flex-column align-items-start">
            <span>{{cell.value}}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>

