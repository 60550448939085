import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as models from '../../models/occupancy-and-vacancy-report-tooltip.model';

import { AnalyticsTooltipService, Context } from '../../services/analytics-tooltip.service';

@Component({
  templateUrl: 'occupancy-and-vacancy-report-tooltip.component.html',
  styleUrls: ['occupancy-and-vacancy-report-tooltip.component.scss'],
})
export class OccupancyAndVacancyReportTooltipComponent implements OnInit, AfterViewInit {
  @ViewChild('occupancyAndVacancyTooltipElementRef') occupancyAndVacancyTooltipElementRef: ElementRef;

  readonly injectableData: {
    analyticsTooltipService: AnalyticsTooltipService<OccupancyAndVacancyReportTooltipComponent, models.OccupancyAndVacancyReportTooltip>,
    model: models.OccupancyAndVacancyReportTooltip,
    context: Context,
  };

  TooltipType: typeof models.OccupancyAndVacancyReportTooltipType = models.OccupancyAndVacancyReportTooltipType;
  ChartType: typeof models.OccupancyAndVacancyReportTooltipChartType = models.OccupancyAndVacancyReportTooltipChartType;

  occupancyAndVacancyTooltip: models.OccupancyAndVacancyReportTooltip;

  ngOnInit(): void {
    if (!this.injectableData || !this.injectableData.model) {
      return;
    }

    this.occupancyAndVacancyTooltip = this.injectableData.model;
  }

  ngAfterViewInit(): void {
    if (
      !this.injectableData || !this.injectableData.context ||
      !this.occupancyAndVacancyTooltipElementRef || !this.occupancyAndVacancyTooltipElementRef.nativeElement
    ) {
      return;
    }

    const {canvasElement, tooltipModel} = this.injectableData.context;
    if (!canvasElement || !tooltipModel) {
      return;
    }

    const canvasBoundingClientRect = canvasElement.getBoundingClientRect();

    const tooltipElement = this.occupancyAndVacancyTooltipElementRef.nativeElement;

    tooltipElement.style.left = `${canvasBoundingClientRect.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipElement.style.top = `${canvasBoundingClientRect.top + window.pageYOffset + tooltipModel.caretY}px`;
  }
}
