import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager } from '@statera/sdk/auth';

import { AlertService } from '../../../alert/services/alert.service';

import { IPasswordResetRequestViewModel } from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent {
  vm = <IPasswordResetRequestViewModel>{};
  submitted = false;

  private readonly _authManager: AuthManager;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  constructor(
    authManager: AuthManager,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
  ) {
    this._authManager = authManager;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
  }

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.submitted = true;

    this._authManager
      .resetPasswordRequest({...this.vm})
      .subscribe(
        () => {
          this._router.navigate(['signin-user']);

          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getResetPasswordLinkSentAlertText(),
          });
        }
      )
      .add(() => {
        this.submitted = false;
      });
  }
}
