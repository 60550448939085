import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core';
import { DxButtonComponent, DxComponent } from 'devextreme-angular';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs/internal/observable/of';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../alert/services/alert.service';
import { LeaseService } from '../../../../shared/services/lease.service';
import { ProjectService } from '../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../services/terms-page.service';

import * as models from '../../../../infrastructure/models/generated';

import { LeaseTermEventModel } from './lease-term-event-model';
import {CommonTools} from '@statera/sdk/common';

@Directive()
@Injectable()
export class BaseTermDirective<T extends models.ILeaseTermViewModel> implements OnInit, OnDestroy {
  @ViewChild('submitButton') submitButton: DxButtonComponent;

  @Input() startupInfo: models.IStartupInfoViewModel;

  @Input() project: models.IProjectViewModel;
  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTerm: T;
  @Input() leaseTermConfiguration: models.ILeaseTermConfiguration;
  @Input() leaseHistoryRecord: models.ILeaseHistoryRecordViewModel;
  @Input() isOption: boolean;

  @Input() accordionOpened$: Observable<void>;
  @Input() errorOccured$: Observable<HttpErrorResponse>;

  @Input() isRejectFormVisible: boolean;
  @Input() rejectButtonClicked: boolean;
  @Input() acceptButtonClicked: boolean;
  @Output() acceptClicked = new EventEmitter();
  @Output() rejectClicked = new EventEmitter();
  @Output() isRejectFormVisibleChange = new EventEmitter<boolean>();

  @Output() leaseTermChange = new EventEmitter<T>();
  @Output() leaseTermSaved = new EventEmitter<LeaseTermEventModel>();
  @Output() leaseTermCanceled = new EventEmitter();
  @Output() stageChanged = new EventEmitter();
  @Output() setPreviousValue = new EventEmitter<models.IPreviousLeaseTermViewModel>();

  errors: Array<string>;

  isFirstOffer = false;
  termOptions: Array<T>;
  radioGroupSelectedIndex: number;
  initialLeaseTermValue: T;
  protected alertService: AlertService;
  protected alertMessagesManager: AlertMessagesManager;
  protected termsPageService: TermsPageService;
  protected projectService: ProjectService;
  protected projectAccessService: ProjectAccessService;
  protected leaseService: LeaseService;
  protected leaseManager: LeaseManager;
  protected termManager: TermManager;
  protected projectManager: ProjectManager;

  private _errorOccuredSubscription: Subscription;

  protected destroy: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    this.alertService = alertService;
    this.alertMessagesManager = alertMessagesManager;
    this.termsPageService = termsPageService;
    this.projectService = projectService;
    this.projectAccessService = projectAccessService;
    this.leaseService = leaseService;
    this.leaseManager = leaseManager;
    this.termManager = termManager;
    this.projectManager = projectManager;

    this.destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.errors = [];
    this.isFirstOffer = this.termsPageService.isFirstOffer(this.project, this.lease);
    this.initialLeaseTermValue = CommonTools.deepCopy(this.leaseTerm);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  isButtonsVisible(): boolean {
    if (this.isRejectFormVisible) {
      return false;
    }

    if (this.rejectButtonClicked) {
      return false;
    }

    if (this.isOption) {
      return false;
    }

    const isCurrentRoleTurnOnTerms = this.projectManager
      .isCurrentRoleTurnOnTerms(this.startupInfo.role, this.startupInfo.id, this.project, this.lease);

    if (!isCurrentRoleTurnOnTerms) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus === models.TermStatus.Draft
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.leaseTermType !== models.LeaseTermType.TenantImprovements
    ) {
      return (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease
        )
        ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterOffer,
          this.project,
          this.lease
        )
        ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.LandlordCounterOffer,
          this.project,
          this.lease
        )
      );
    }

    return true;
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready ||
        this.leaseTerm.termStatus === models.TermStatus.Accepted ||
        this.leaseTerm.termStatus === models.TermStatus.Rejected
      )
    ) {
      return false;
    }

    return true;
  }

  isCounterButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready ||
        this.leaseTerm.termStatus === models.TermStatus.Rejected
      )
    ) {
      return false;
    }

    if (this.isPreviousButtonVisible() || this.isModifyButtonVisible()) {
      return false;
    }

    return true;
  }

  isModifyButtonVisible(): boolean {
    if (this.isPreviousButtonVisible() || this.isChooseOptionButtonVisible()) {
      return false;
    }

    const leaseTermHistoryRecord = this.leaseHistoryRecord
      .leaseTermHistoryRecords
      .find((record) => (
        record.leaseTermType === this.leaseTerm.leaseTermType
      ));

    if (
      !leaseTermHistoryRecord ||
      !leaseTermHistoryRecord.termHistoryModels ||
      !leaseTermHistoryRecord.termHistoryModels.length
    ) {
      return false;
    }

    const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
      leaseTermHistoryRecord.termHistoryModels.length - 1
    ];

    const userLeaseTeam = this.leaseManager.getUserLeaseTeam(this.lease, this.startupInfo.id, this.startupInfo.role);

    return (
      this.leaseTerm &&
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      currentTermHistoryModel.leaseTeam === userLeaseTeam
    );
  }

  // Note: The "Choose option" is only available for the term and remains visible on the stage when the tenant selects the option
  //       This implementation is overridden by the term component
  isChooseOptionButtonVisible(): boolean {
    return false;
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Accepted &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus === models.TermStatus.Ready &&
      !this._isElectedForNegotiationAtActualStage() &&
      !(
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterOffer,
          this.project,
          this.lease
        )
      )
    ) {
      return false;
    }

    if (
      !this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.SendRfp,
        this.project,
        this.lease
      ) &&
      !this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
        this.project,
        this.lease
      )
    ) {
      if (
        !this.leaseHistoryRecord ||
        !this.leaseHistoryRecord.leaseTermHistoryRecords
      ) {
        return false;
      }

      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      if (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) &&
        this.leaseTerm.hasMultiplyOptions &&
        !leaseTermHistoryRecord.valueOrStatusChangedSincePreviousStage &&
        this.leaseTerm.termStatus === models.TermStatus.Ready
      ) {
        return true;
      }

      return leaseTermHistoryRecord.valueOrStatusChangedSincePreviousStage;
    }

    return true;
  }

  isOkButtonVisible(): boolean {
    return false;
  }

  handleAcceptButtonClick(): void {
    this.leaseTerm.termStatus = models.TermStatus.Accepted;

    const leaseTermEventModel = new LeaseTermEventModel(this.leaseTerm);

    this.leaseTermSaved.emit(leaseTermEventModel);
    this.acceptClicked.emit();
  }

  handleCounterButtonClick(): void {
    if (this.leaseTerm.termStatus === models.TermStatus.Accepted) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmAcceptedTermChangeAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => this.rejectTerm()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    this.rejectTerm();
  }

  handleModifyButtonClick(): void {
    this.rejectTerm();
  }

  handleChooseOptionButtonClick(): void {
    this.rejectTerm();
  }

  handlePreviousButtonClick(): void {
    this.setPreviousValue.emit(<models.IPreviousLeaseTermViewModel>{
      leaseTermConfiguration: this.leaseTermConfiguration,
      leaseId: this.lease.id,
    });
  }

  handleOkButtonClick(): void {
    this.leaseTerm.termStatus = models.TermStatus.Ready;
    this.leaseTermSaved.emit(
      new LeaseTermEventModel(this.leaseTerm),
    );
  }

  rejectTerm(): void {
    this.isRejectFormVisible = true;
    this.isRejectFormVisibleChange.emit(this.isRejectFormVisible);

    this.rejectClicked.emit();
  }

  clearErrors(): void {
    this.errors = [];
  }

  addError(message: string): void {
    // Hack to get rid of duplicate error messages in Parking term, but it might be useful to not have a duplicated
    // validation errors in other situations.
    if (this.errors.includes(message)) {
      return;
    }

    this.errors.push(message);
  }

  saveLeaseTerm(
    leaseTerm: T,
    termStatus: models.TermStatus = null,
    onSavedFinished: () => void = null,
    onSavedError: () => void = null,
  ) {
    this.clearErrors();

    if (this.submitButton) {
      this.submitButton.disabled = true;
    }

    const previousTermStatus = leaseTerm.termStatus;

    const leaseTermEventModel = new LeaseTermEventModel(
      this.leaseTerm,
      () => {
        if (this.submitButton) {
          this.submitButton.disabled = false;
        }

        if (onSavedFinished) {
          onSavedFinished();
        }
      },
      true,
      true,
      () => {
        if (this.submitButton) {
          this.submitButton.disabled = false;
        }

        this.leaseTerm.termStatus = previousTermStatus;

        if (onSavedError) {
          onSavedError();
        }
      }
    );

    leaseTerm.termStatus = this._getCorrectTermStatus(termStatus, leaseTerm);

    let validationObserver: Observable<void> = of(null);
    if (!this.isOption && previousTermStatus !== models.TermStatus.Draft) {
      validationObserver = fromPromise(
        this.termsPageService
          .validateDuplicateTermValue(
            this.project,
            this.termManager,
            this.leaseTerm,
            this.lease.id,
          )
      );
    }

    validationObserver
      .pipe(
        tap(() => {
          if (this.errorOccured$) {
            if (this._errorOccuredSubscription) {
              this._errorOccuredSubscription.unsubscribe();
              this._errorOccuredSubscription = null;
            }

            this._errorOccuredSubscription = this.errorOccured$
              .pipe(
                tap(err => {
                  if (this.submitButton && this.submitButton.disabled) {
                    this.submitButton.disabled = false;
                  }

                  leaseTerm.termStatus = previousTermStatus;

                  this.addError(err.error || err.message);
                }),
                take(1),
                takeUntil(this.destroy),
              )
              .subscribe();
          }

          this.leaseTermSaved.emit(leaseTermEventModel);
        }),
        catchError(errorMessage => {
          if (this.submitButton && this.submitButton.disabled) {
            this.submitButton.disabled = false;
          }

          leaseTerm.termStatus = previousTermStatus;

          this.addError(errorMessage);

          return throwError(errorMessage);
        }),
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe();
  }

  private _getCorrectTermStatus(termStatus: models.TermStatus, leaseTerm: models.ILeaseTermViewModel): models.TermStatus {
    if (!leaseTerm) {
      return termStatus;
    }

    if (this.isOption) {
      termStatus = models.TermStatus.Draft;
    }

    if (!termStatus) {
      termStatus = models.TermStatus.Ready;

      // when rejected
      if (this.rejectButtonClicked) {
        termStatus = models.TermStatus.Rejected;
        return termStatus;
      }

      // when is a first offer
      if (this.isFirstOffer) {
        termStatus = models.TermStatus.Ready;
        return termStatus;
      }

      // when changed during negotiation but not elected at actual stage
      if (this.leaseHistoryRecord && this.leaseHistoryRecord.leaseTermHistoryRecords) {
        const { leaseTermHistoryRecords } = this.leaseHistoryRecord;
        const termHistory = leaseTermHistoryRecords.find(x => x.leaseTermType === leaseTerm.leaseTermType);
        if (
          termHistory &&
          termHistory.termHistoryModels &&
          termHistory.termHistoryModels.length &&
          !termHistory.isElectedForNegotiationAtActualStage
        ) {
          termStatus = models.TermStatus.Rejected;
          return termStatus;
        }
      }

      // when is a multiple option choice
      if (this.lease && this.lease.term && this.lease.term.termType) {
        switch (this.lease.term.termType) {
          case models.TermTypeEnum.MultipleOptionsWithCustomValue: {
            termStatus = models.TermStatus.Rejected;
            break;
          }

          case models.TermTypeEnum.Custom: {
            if (leaseTerm && leaseTerm.termStatus === models.TermStatus.Draft) {
              termStatus = models.TermStatus.Ready;
            }

            break;
          }
        }

        return termStatus;
      }
    }

    return termStatus;
  }

  onCancelLeaseTerm() {
    this.acceptButtonClicked = false;
    this.rejectButtonClicked = false;
    this.leaseTerm = CommonTools.deepCopy(this.initialLeaseTermValue);
    this.leaseTermCanceled.emit();
  }

  isVisibleTermForm(): boolean {
    if (!this.project || !this.project.projectState) {
      return false;
    }

    if (this.project.projectStatus !== models.ProjectStatus.Active) {
      return false;
    }

    if (!this.leaseTerm) {
      return false;
    }

    const isVisible = this.termsPageService
      .isVisibleEditingTerm(this.project, this.lease, this.leaseTerm.leaseTermType);

    // Always show form for terms in draft status.
    if (isVisible && this.leaseTerm.termStatus === models.TermStatus.Draft) {
      return true;
    }

    if (
      this.leaseTerm.leaseTermType !== models.LeaseTermType.Term &&
      this.leaseTerm.hasMultiplyOptions &&
      this.isShowMultiplyOptionsTenantStepExceptTenantCounterUnsolicitedOffer()
    ) {
      return false;
    }

    return (
      isVisible /* access to editing term in stage */ && (
        this.rejectButtonClicked /* if user click on reject button */ ||
        (this.isFirstOffer && this.leaseTerm.termStatus === models.TermStatus.Draft) /* if it is stage2 for tenant */ ||
        this.isOption /* if it additional option */
      )
    );
  }

  getOptionName(index: number): string {
    return this.termManager
      .getLeaseTermOptionName(index);
  }

  isShowMultiplyOptionsTenantStep(): boolean {
    return this.termManager
      .isShowMultiplyOptionsForTenant(this.lease.term, this.project);
  }

  isShowMultiplyOptionsTenantStepExceptTenantCounterUnsolicitedOffer(): boolean {
    return this.termManager
      .isShowMultiplyOptionsForTenantStepExceptTenantCounterUnsolicitedOffer(this.lease.term, this.project);
  }

  isTenantCounterUnsolicitedOfferStage(): boolean {
    return (
      this.project && this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );
  }

  fillLeaseTermOptions(leaseTermOptions: models.ILeaseTermOptionsViewModel<T>) {
    if (!leaseTermOptions) {
      (<any>this.leaseTerm).leaseTermOptions = leaseTermOptions = <models.ILeaseTermOptionsViewModel<T>> {};
    }
    if (!leaseTermOptions || !leaseTermOptions.options) {
      const termOptions = [];
      if (this.lease.term.leaseTermOptions && this.lease.term.leaseTermOptions.options) {
        const multiplyOptionsCount = this.lease.term.leaseTermOptions.options.length;

        for (let i = 0; i < multiplyOptionsCount; i++) {
          termOptions.push(<T>{leaseTermType: this.leaseTerm.leaseTermType});
        }
      } else {
        termOptions.push(<T>{leaseTermType: this.leaseTerm.leaseTermType});
        termOptions.push(<T>{leaseTermType: this.leaseTerm.leaseTermType});
      }
      leaseTermOptions.options = termOptions;
    }
  }

  leaseTermPropertyChange(): void {
    this.leaseTermChange.emit(this.leaseTerm);
  }

  isMultipleOptions(): boolean {
    return this.lease && this.lease.term && this.lease.term.termType === models.TermTypeEnum.MultiplyOptions;
  }

  areSameMultipleOptions<O>(options: Array<O>, extractValue: (O) => any): boolean {
    if (!options || !extractValue) {
      return false;
    }

    const seen = new Set();
    return options.some(option => seen.size === seen.add(extractValue(option)).size);
  }

  private _isElectedForNegotiationAtActualStage(): boolean {
    const leaseTermHistoryRecords = this.leaseHistoryRecord?.leaseTermHistoryRecords
      .find(x => x.leaseTermType === this.leaseTerm.leaseTermType);

    if (!leaseTermHistoryRecords) {
      return false;
    }

    return leaseTermHistoryRecords.isElectedForNegotiationAtActualStage;
  }

  handleFieldDxFieldFocus(event: { component: DxComponent }, nilValue = 0): void {
    const instance = event?.component?.instance();
    if (!instance || typeof instance.option !== 'function') {
      return;
    }

    const currentValue = instance.option('value');
    if (currentValue) {
      return;
    }

    // Automatically fills to show the mask when the control is in focus
    instance.option('value', nilValue);
  }

  handleFieldDxFieldBlur(event: { component: DxComponent }): void {
    const instance = event?.component?.instance();
    if (!instance || typeof instance.option !== 'function') {
      return;
    }

    const currentValue = instance.option('value');
    if (currentValue) {
      return;
    }

    // Automatically clears to hide the mask when the control is not in focus
    instance.option('value', null);
  }
}
