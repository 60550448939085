import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getAppointments(type: models.AppointmentType, entityId: number): Observable<Array<models.IAppointmentViewModel>> {
    return this._httpClient
      .get<Array<models.IAppointmentViewModel>>(`${environment.webApiUrl}/appointment/GetByEntityId?type=${type}&entityId=${entityId}`);
  }

  addAppointment(model: models.IAppointmentViewModel) {
    return this._httpClient.post<models.IAppointmentViewModel>(
      `${environment.webApiUrl}/appointment/CreateAppointment`,
      model,
    ).pipe(share());
  }

  updateAppointment(model: models.IAppointmentViewModel): Observable<void> {
    return this._httpClient.post<void>(
      `${environment.webApiUrl}/appointment/UpdateAppointment`,
      model,
    ).pipe(share());
  }

  deleteAppointment(model: models.IAppointmentViewModel): Observable<void> {
    return this._httpClient.post<void>(
      `${environment.webApiUrl}/appointment/DeleteAppointment`,
      model,
    );
  }
}
