import { Component, Input, OnInit } from '@angular/core';

import { Role } from '@statera/sdk/auth';
import { TermManager } from '@statera/sdk/term';

import { AuthService } from '../../../../../auth/services/auth.service';
import { TermsPageService } from '../../../../services/terms-page.service';
import { ProjectService } from '../../../../../shared/services/project.service';

import * as models from '../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-terms-accordion-header',
  templateUrl: './terms-accordion-header.component.html',
  styleUrls: ['./terms-accordion-header.component.scss'],
})
export class TermsAccordionHeaderComponent implements OnInit {
  @Input()
  set projects(projects) {
    this._projects = projects;
    this.currentProject = this._projectService.getActiveProject(this._projects);
  }

  @Input()
  lease: models.ILeaseViewModel;

  leaseFloorPlans: Array<models.ILeaseFloorPlanViewModel>;
  currentProject: models.IProjectViewModel;

  private _startupInfo: models.IStartupInfoViewModel;

  private _authService: AuthService;
  private _termsPageService: TermsPageService;
  private _termManager: TermManager;
  private _projectService: ProjectService;
  private _projects: Array<models.IProjectViewModel>;

  constructor(
    authService: AuthService,
    termsPageService: TermsPageService,
    termManager: TermManager,
    projectService: ProjectService,
  ) {
    this._authService = authService;
    this._termsPageService = termsPageService;
    this._termManager = termManager;
    this._projectService = projectService;
  }

  ngOnInit() {
    this._authService.infoLoadComplete.subscribe(x => this._startupInfo = x);
  }

  getTermStatusPage(): string {
    if (this.currentProject && this._projectService.isClosed(this.currentProject)) {
      return 'In Lease';
    }

    let stageRole;
    if (this.currentProject && this.currentProject.projectState) {
      stageRole = this.currentProject.projectState.writeAccessToTermsByRole;

    }

    if (!stageRole) {
      return '';
    }
    if (this.currentProject && this.currentProject.projectTypeId === models.ProjectTypeEnum.InsuranceCertificate) {
      return this._startupInfo.role !== stageRole ? 'Pending COI Information' : 'COI Action Required';
    }

    if (this._startupInfo.role === Role.Broker && this.lease && this.lease.leaseUsers) {
      const userId = this._authService.userId;
      const leaseUser = this.lease.leaseUsers.find(x => x.userId === userId);
      if (!leaseUser) {
        return 'Pending Response';
      }
      let role = null;

      if (this.lease.landlordCompany &&
        leaseUser.relatedToCompanyId === this.lease.landlordCompany.id) {
        role = Role.Landlord;
      }

      if (this.lease.tenantCompany &&
        leaseUser.relatedToCompanyId === this.lease.tenantCompany.id) {
        role = Role.Tenant;
      }

      return role === stageRole ? 'Action Required' : 'Pending Response';
    }

    return this._startupInfo.role !== stageRole ? 'Pending Response' : 'Action Required';
  }

  getProjectIndicatorClass(): string {
    if (this.currentProject) {
      if (this._projectService.isClosed(this.currentProject)) {
        return 'closed';
      } else {
        if (this._projectService.hasActionRequired(this.currentProject, this.lease)) {
          return 'error';
        } else {
          return 'success';
        }
      }
    }

    return 'closed';
  }
}
