import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-hvac',
  templateUrl: './hvac.component.html',
  styleUrls: ['./hvac.component.scss'],
})
export class HvacComponent extends BaseTermDirective<models.IHvacTermViewModel> implements OnInit {
  options: any = [
    {
      name: 'Per current lease', value: models.HvacTermType.PerCurrentLease,
    },
    {
      name:
        'Tenant to be responsible for all routine maintenance and repairs. Landlord, at its sole cost and expense, ' +
        'shall be responsible for replacements',
      value: models.HvacTermType.ResponsibilityByTenant,
    },
    {
      name: 'Tenant to have an annual cap of $(INSERT DOLLAR VALUE) per occurrence on any HVAC repair',
      value: models.HvacTermType.MoneyValue,
    },
    {
      name:
        'Landlord shall be responsible for replacement and amortize at (INSERT PERCENTAGE VALUE)% over the useful ' +
        'life of the unit over the remaining term at time of replacement',
      value: models.HvacTermType.PercentValue,
    },
    {
      name: 'Custom',
      value: models.HvacTermType.CustomValue,
    },
  ];

  HvacTermType = models.HvacTermType;
  isBaseRentalRateGrossType = false;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  protected alertService: AlertService;

  protected termsPageService: TermsPageService;

  protected termManager: TermManager;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.leaseTerm) {
      this.leaseTerm = <models.IHvacTermViewModel>{};
    }
    this.initializePage();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    const {hvacTermType} = this.leaseTerm;
    if (hvacTermType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  initializePage() {
    if (!this.lease || !this.lease.baseRentalRateTerm) {
      return;
    }

    this.isBaseRentalRateGrossType = this.lease.baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.Gross;

    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Tenant to be responsible for all routine maintenance and repairs. Landlord, at its sole cost and expense, ' +
            'shall be responsible for replacements',
          value: models.HvacTermType.ResponsibilityByTenant,
        },
        {
          name: 'Tenant to have an annual cap of $(INSERT DOLLAR VALUE) per occurrence on any HVAC repair',
          value: models.HvacTermType.MoneyValue,
        },
        {
          name:
            'Landlord shall be responsible for replacement and amortize at (INSERT PERCENTAGE VALUE)% over the useful ' +
            'life of the unit over the remaining term at time of replacement',
          value: models.HvacTermType.PercentValue,
        },
        {
          name: 'Custom',
          value: models.HvacTermType.CustomValue,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease', value: models.HvacTermType.PerCurrentLease,
      },
      {
        name:
          'Tenant to be responsible for all routine maintenance and repairs. Landlord, at its sole cost and expense, ' +
          'shall be responsible for replacements',
        value: models.HvacTermType.ResponsibilityByTenant,
      },
      {
        name: 'Tenant to have an annual cap of $(INSERT DOLLAR VALUE) per occurrence on any HVAC repair',
        value: models.HvacTermType.MoneyValue,
      },
      {
        name:
          'Landlord shall be responsible for replacement and amortize at (INSERT PERCENTAGE VALUE)% over the useful ' +
          'life of the unit over the remaining term at time of replacement',
        value: models.HvacTermType.PercentValue,
      },
      {
        name: 'Custom',
        value: models.HvacTermType.CustomValue,
      },
    ];
  }
}
