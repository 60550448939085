<div class="occupancy-and-vacancy-report-single-period">
  <ul class="occupancy-and-vacancy-report-single-period-legends">
    <li>
      <a (click)="onLegendItemClick()">
            <span class="occupancy-and-vacancy-report-single-period-legends-bg"
                  [style.background-color]="'rgba(118, 129, 243, 1)'">
            </span>
        <span>Occupied SF</span>
      </a>
    </li>
    <li>
      <a (click)="onLegendItemClick()">
            <span class="occupancy-and-vacancy-report-single-period-legends-bg"
                  [style.background-color]="'rgba(161, 168, 247, 1)'">
            </span>
        <span>Vacant SF</span>
      </a>
    </li>
  </ul>

  <div class="occupancy-and-vacancy-report-single-period-chart">
    <canvas baseChart
            [data]="barChartData"
            [options]="barChartOptions"
            [legend]="false"
            [type]="'bar'"
            #canvasElementRef>
    </canvas>
  </div>
</div>
