<form name="form" class="pt-4" (submit)="saveLeaseTerm($event)" *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-11 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value"
                          [dataSource]="options | filter : 'disabled'"
                          [(value)]="leaseTerm.tenantMaintenanceTermType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <!--                (onContentReady)="radioGroupInitialized(termConfiguration)"-->
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <div *ngIf="leaseTerm.tenantMaintenanceTermType === TenantMaintenanceTermType.Custom"
         class="col-8 pl-0">
      <app-term-custom-value-template-textarea placeholder="Insert custom tenant maintenance or responsibilities clause"
                                               required requiredMessage="Custom Tenant Maintenance or Responsibilities clause is required"
                                               className="real-estate-input"
                                               [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                               [(value)]="leaseTerm.tenantMaintenance"
                                               (valueChange)="clearErrors()">
      </app-term-custom-value-template-textarea>
    </div>

    <!-- Start: Validation summary -->
    <div>
        <dx-validation-summary ngForm="form">
        </dx-validation-summary>
        <div class="term-toast term-toast-error"
             *ngFor="let msg of errors">
          <i inlineSVG="assets/img/x.svg"></i>
          {{msg}}
        </div>
    </div>
    <!-- End: Validation summary -->

    <div class="row main-actions">
      <div class="col-sm-3 col-6">
        <div class="dx-fieldset">
          <dx-button #submitButton id="button" text="Save" type="success" [useSubmitBehavior]="true" class="b-btn b-btn--small b-btn-primary btn">
          </dx-button>
        </div>
      </div>

      <div class="col-sm-3 col-6" *ngIf="rejectButtonClicked">
        <div class="dx-fieldset">
          <dx-button id="cancelButton" text="Cancel" type="cancel" [useSubmitBehavior]="false" (onClick)="onCancelLeaseTerm()" class="b-btn b-btn--small b-btn-primary btn">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>

