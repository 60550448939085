<div class="emoji-picker">
  <emoji-mart title="Pick an emoji…"
              emoji="point_up"
              color="#59B680"
              [isNative]="true"
              [darkMode]="false"
              [showPreview]="true"
              [emojisToShowFilter]="this.emojisToShowFilter"
              (emojiClick)="handleEmojiClick($event)">
  </emoji-mart>
</div>
