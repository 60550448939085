import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import {LeaseAnalysisRepository} from './lease-analysis.repository';
import {LeaseAnalysisManager} from './lease-analysis.manager';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    LeaseAnalysisRepository,
    LeaseAnalysisManager,
  ],
})
export class LeaseAnalysisModule {
  static forChild(configuration: Configuration): ModuleWithProviders<LeaseAnalysisModule> {
    return {
      ngModule: LeaseAnalysisModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        LeaseAnalysisRepository,
        LeaseAnalysisManager,
      ],
    };
  }
}
