import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DocumentAttachmentPlaces, LandlordManager } from '@statera/sdk/landlord';
import { DxAccordionComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { alert, confirm } from 'devextreme/ui/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { StateraUserClaimManager } from '@statera/sdk/statera-user-claim';

import { environment } from '../../../../environments/environment';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService, AlertService as UIAlertService } from '../../../alert/services/alert.service';
import { DocumentViewerService } from '../../../document-viewer/services/document-viewer.service';
import { DocumentsService } from '../../../shared/services/documents.service';

import * as models from '../../../infrastructure/models/generated';

class FolderWithFiles {
  folder: models.IAttachedFolderViewModel;
  files: Array<models.IAttachedFileViewModel>;
  selectedFiles: Array<models.IAttachedFileViewModel>;
}

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('fileUploaderForRequestedDocument') fileUploaderForRequestedDocument: DxFileUploaderComponent;
  @ViewChild('dxAccordionComponent') dxAccordionComponent: DxAccordionComponent;

  @Input()
  set files(files: Array<models.IAttachedFileViewModel>) {
    if (files) {
      this._files = files;
      this._groupFilesByFolders();
    }
  }

  get files() {
    return this._files;
  }

  @Input() mode: DocumentAttachmentPlaces;
  @Input() entityId: number;

  folders: Array<models.IAttachedFolderViewModel>;
  searchAttachmentText: string;
  foldersWithFiles: Array<FolderWithFiles>;
  isMoveFilesPopupVisible: boolean;
  isMoveFilesCheckPopupVisible: boolean;
  moveFilesDestinationFolderId: number | null;
  moveFilesFromFolder: FolderWithFiles;
  moveFilesDestinationFolders: Array<models.IAttachedFolderViewModel>;
  uploadUrlForRequestedDocument: string;
  summaryOfTermsFolderId = models.TenantFileFolderType.SummaryOfTerms;
  TenantFileFolderType = models.TenantFileFolderType;
  BuildingFileFolderType = models.BuildingFileFolderType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  private _files: Array<models.IAttachedFileViewModel>;

  private readonly _uiAlertService: UIAlertService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _landlordManager: LandlordManager;
  private readonly _documentViewerService: DocumentViewerService;
  private readonly _documentService: DocumentsService;
  private readonly _authService: AuthService;
  private readonly _stateraUserClaimManager: StateraUserClaimManager;

  private readonly _destroy$: Subject<void>;

  constructor(
    uiAlertService: UIAlertService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    landlordManager: LandlordManager,
    documentViewerService: DocumentViewerService,
    documentService: DocumentsService,
    stateraUserClaimManager: StateraUserClaimManager,
    authService: AuthService,
  ) {
    this._uiAlertService = uiAlertService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._landlordManager = landlordManager;
    this._documentViewerService = documentViewerService;
    this._documentService = documentService;
    this._authService = authService;
    this._stateraUserClaimManager = stateraUserClaimManager;

    this._destroy$ = new Subject<void>();

    this._files = [];

    this.folders = [];
    this.foldersWithFiles = [];
    this.moveFilesDestinationFolders = [];
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    let entityId = null;
    if (
      changes.entityId &&
      (
        changes.entityId.isFirstChange() ||
        (
          changes.entityId.previousValue !== changes.entityId.currentValue
        )
      )
    ) {
      entityId = changes.entityId.currentValue;
    }

    let mode = null;
    if (
      changes.mode &&
      (
        changes.mode.isFirstChange() ||
        (
          changes.mode.previousValue !== changes.mode.currentValue
        )
      )
    ) {
      mode = changes.mode.currentValue;
    }

    // Drop search state when building is changed
    if (mode || entityId) {
      this._getFileFolders();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isSelectedFilesShareable(selectedFiles: Array<models.IAttachedFileViewModel>): boolean {
    if (!selectedFiles || !selectedFiles.length) {
      return false;
    }

    if (selectedFiles.some(selectedFile => selectedFile.file.createdById !== this._authService.userId)) {
      return false;
    }

    return true;
  }

  buildUploadUrl(folderId: number): string {
    let actionName: string;
    let parameterName: string;
    switch (this.mode) {
      case DocumentAttachmentPlaces.Building:
        actionName = 'AttachBuildingFile';
        parameterName = 'buildingFileFolderId';
        break;

      case DocumentAttachmentPlaces.BuildingTenant:
        actionName = 'AttachLeaseFile';
        parameterName = 'leaseFileFolderId';
        break;

      case DocumentAttachmentPlaces.TenantFiles:
        return `${environment.webApiUrl}/documents/Attach?leaseId=${this.entityId}&tenantFileFolderId=${folderId}`;

      default:
        this._throwUnexpectedMode();
        break;
    }

    return `${environment.webApiUrl}/landlord/${actionName}/${this.entityId}?${parameterName}=${folderId}`;
  }

  onUploaded(e): void {
    const uploadedFile: models.IAttachedFileViewModel = JSON.parse(e.request.responseText);

    this.files.push(uploadedFile);

    const folder = this.foldersWithFiles.find(f => f.folder.id === uploadedFile.folderId);
    if (folder) {
      folder.files.push(uploadedFile);
    }

    e.component.reset();
  }

  onUploadError(e) {
    this._uiAlertService.pushErrorAlert({
      message: e.error.responseText,
    });

    e.component.reset();
  }

  onUploadAborted() {
    this._uiAlertService.pushWarningAlert({
      message: this._alertMessagesManager.getUploadingAbortedAlertText(),
    });
  }

  removeFiles(folderWithFiles: FolderWithFiles) {
    if (folderWithFiles.selectedFiles && folderWithFiles.selectedFiles.length) {
      const userId = this._authService.userId;
      if (folderWithFiles.selectedFiles.some(sf => sf.file.createdById !== userId)) {
        alert('You can\'t delete file that was uploaded by another user', 'Error');
        return;
      }
      confirm('Are you sure?', 'Confirm delete').done(confirmed => {
        if (confirmed) {
          const nextAction = () => {
            for (const selectedFile of folderWithFiles.selectedFiles) {
              this.files.splice(this.files.indexOf(selectedFile), 1);
              folderWithFiles.files.splice(folderWithFiles.files.indexOf(selectedFile), 1);
            }
          };

          switch (this.mode) {
            case DocumentAttachmentPlaces.Building:
              this._landlordManager
                .deleteBuildingFiles(folderWithFiles.selectedFiles)
                .pipe(
                  takeUntil(this._destroy$),
                )
                .subscribe(nextAction);

              break;

            case DocumentAttachmentPlaces.BuildingTenant:
              this._landlordManager
                .deleteLeaseFiles(folderWithFiles.selectedFiles)
                .pipe(
                  takeUntil(this._destroy$),
                )
                .subscribe(nextAction);

              break;

            case DocumentAttachmentPlaces.TenantFiles:
              this._documentService
                .deleteTenantFilesByFiles(folderWithFiles.selectedFiles)
                .pipe(
                  takeUntil(this._destroy$),
                )
                .subscribe(nextAction);
              break;

            default:
              this._throwUnexpectedMode();
          }
        }
      });
    } else {
      alert('Please select files', 'Select files');
    }
  }

  onAttachmentsAccordionContentReady(e) {
    // uncomment if needed
    // for (let i = 0; i < this.folders.length; i++) {
    //   e.component.expandItem(i);
    // }
  }

  onItemRendered(e: any) {
    if (e.itemData.isReadOnly) {
      e.itemElement.classList.add('readOnlyItem');
    }
  }

  onItemTitleClick(e) {
    if (
      e && e.event && e.event.target &&
      ($(e.event.target).closest('button').length > 0 || $(e.event.target).closest('.dx-fileuploader-button').length > 0)
    ) {
      e.event.preventDefault();
      e.event.stopPropagation();
    }
  }

  moveFiles(folderWithFiles: FolderWithFiles): void {
    if (folderWithFiles.selectedFiles && folderWithFiles.selectedFiles.length) {
      this.moveFilesFromFolder = folderWithFiles;
      this.moveFilesDestinationFolders = this.folders.filter(f => f.id !== folderWithFiles.folder.id && !f.isShared);
      this.isMoveFilesPopupVisible = true;
    } else {
      alert('Please select files', 'Select files');
    }
  }

  onMoveFilesPopupHidden(): void {
    this.moveFilesDestinationFolderId = null;
  }

  movePupupCancelClick(): void {
    this.moveFilesDestinationFolderId = null;
    this.isMoveFilesPopupVisible = false;
  }

  movePupupOkClick(): void {
    if (!this.moveFilesDestinationFolderId) {
      alert('Please select a folder', 'Select a folder');
      return;
    }

    this.isMoveFilesCheckPopupVisible = true;
  }

  moveCheckPupupCancelClick(): void {
    this.isMoveFilesCheckPopupVisible = false;
  }

  moveCheckPupupOkClick(): void {
    if (this.moveFilesFromFolder.selectedFiles && this.moveFilesFromFolder.selectedFiles.length) {
      for (const selectedFile of this.moveFilesFromFolder.selectedFiles) {
        selectedFile.folderId = this.moveFilesDestinationFolderId;
        selectedFile.folder = this.folders.find(f => f.id === this.moveFilesDestinationFolderId);
      }

      const nextAction = () => {
        // move files in the UI
        const destinationFolderWithFiles = this.foldersWithFiles.find(f => f.folder.id === this.moveFilesDestinationFolderId);
        for (const selectedFile of this.moveFilesFromFolder.selectedFiles) {
          this.moveFilesFromFolder.files.splice(this.moveFilesFromFolder.files.indexOf(selectedFile), 1);
          destinationFolderWithFiles.files.push(selectedFile);
        }
        this.moveFilesFromFolder.selectedFiles.length = 0;
        this.isMoveFilesCheckPopupVisible = false;
        this.isMoveFilesPopupVisible = false;
      };

      switch (this.mode) {
        case DocumentAttachmentPlaces.Building:
          this._landlordManager
            .updateBuildingFiles(this.moveFilesFromFolder.selectedFiles)
            .pipe(
              takeUntil(this._destroy$),
            )
            .subscribe(nextAction);

          break;

        case DocumentAttachmentPlaces.BuildingTenant:
          this._landlordManager
            .updateLeaseFiles(this.moveFilesFromFolder.selectedFiles)
            .pipe(
              takeUntil(this._destroy$),
            )
            .subscribe(nextAction);

          break;
        case DocumentAttachmentPlaces.TenantFiles:
          this._documentService
            .updateTenantFiles(this.moveFilesFromFolder.selectedFiles)
            .pipe(
              takeUntil(this._destroy$),
            )
            .subscribe(nextAction);
          break;

        default:
          this._throwUnexpectedMode();
          break;
      }
    } else {
      this.isMoveFilesCheckPopupVisible = false;
    }
  }

  shareFiles(folderWithFiles: FolderWithFiles): void {
    if (folderWithFiles.selectedFiles && folderWithFiles.selectedFiles.length) {
      confirm('Are you sure?', 'Confirm Share').done(confirmed => {
        if (confirmed) {
          const nextAction = (newBuildingFiles: Array<models.IAttachedFileViewModel>) => {
            this.files.push(...newBuildingFiles);
            const sharedFolder: FolderWithFiles = this.foldersWithFiles.find(f => f.folder.isShared);
            sharedFolder.files = sharedFolder.files.concat(newBuildingFiles);
            folderWithFiles.selectedFiles.length = 0;
          };

          switch (this.mode) {
            case DocumentAttachmentPlaces.Building:
              this._landlordManager.shareBuildingFiles(folderWithFiles.selectedFiles).subscribe(nextAction);
              break;

            case DocumentAttachmentPlaces.BuildingTenant:
              this._landlordManager.shareLeaseFiles(folderWithFiles.selectedFiles).subscribe(nextAction);
              break;

            case DocumentAttachmentPlaces.TenantFiles:
              this._documentService
                .shareLeaseFiles(folderWithFiles.selectedFiles)
                .pipe(
                  takeUntil(this._destroy$),
                )
                .subscribe(nextAction);
              break;

            default:
              this._throwUnexpectedMode();
          }
        }
      });
    } else {
      alert('Please select files', 'Select files');
    }
  }

  handleSearchValueChange({value}): void {
    if (!this.foldersWithFiles || !value || !this.dxAccordionComponent || !this.dxAccordionComponent.instance) {
      return;
    }

    this.foldersWithFiles.forEach((_, i) => this.dxAccordionComponent.instance.collapseItem(i));

    const folderWithFoundFilesIndexes = [];
    for (let i = 0, num = this.foldersWithFiles.length; i < num; i++) {
      const folder = this.foldersWithFiles[i];
      if (!folder.files || !folder.files.length) {
        continue;
      }

      if (folder.files.find(x => x.file.name.includes(value))) {
        folderWithFoundFilesIndexes.push(i);
      }
    }

    if (folderWithFoundFilesIndexes.length) {
      folderWithFoundFilesIndexes.forEach(x => this.dxAccordionComponent.instance.expandItem(x));
    }
  }

  previewFile(event: MouseEvent, file: models.IFileViewModel): void {
    event?.stopPropagation();

    if (!file || !file.url) {
      return;
    }

    const documents = [
      {
        url: file.url,
        name: file.name,
      },
    ];

    this._documentViewerService.show(documents, {
      width: '95%',
      height: '95%',
      maxWidth: 1800,
      closeOnOutsideClick: false,
      showCloseButton: true,
      title: file.name || 'Preview',
      activeIndex: 0,
    });
  }

  hasAccess(folderWithFiles: FolderWithFiles, stateraClaimValue: models.StateraClaimValueAsEnum): boolean {
    if (folderWithFiles && folderWithFiles.folder) {
      switch (this.mode) {
        case DocumentAttachmentPlaces.TenantFiles:
          const tenantFileFolder = <models.ITenantFileFolderViewModel>folderWithFiles.folder;
          if (tenantFileFolder.tenantFileFolderType === models.TenantFileFolderType.Financials ||
            tenantFileFolder.tenantFileFolderType === models.TenantFileFolderType.Insurance) {
            return this._stateraUserClaimManager
              .checkUserAccess(models.StateraClaimTypeAsEnum.Document_Financial, stateraClaimValue,
                null, null, null, this.entityId);
          }

          return this._stateraUserClaimManager
            .checkUserAccess(models.StateraClaimTypeAsEnum.Documents, stateraClaimValue,
              null, null, null, this.entityId);
        case DocumentAttachmentPlaces.BuildingTenant:
          const leaseFileFolder = <models.ILeaseFileFolderViewModel>folderWithFiles.folder;
          if (leaseFileFolder.leaseFileFolderType === models.LeaseFileFolderType.Financials) {
            return this._stateraUserClaimManager
              .checkUserAccess(models.StateraClaimTypeAsEnum.Document_Financial, stateraClaimValue,
                null, null, null, this.entityId);
          }

          return this._stateraUserClaimManager
            .checkUserAccess(models.StateraClaimTypeAsEnum.Documents, stateraClaimValue,
              null, null, null, this.entityId);
        case DocumentAttachmentPlaces.Building:
          return this._stateraUserClaimManager
            .checkUserAccess(models.StateraClaimTypeAsEnum.Documents, stateraClaimValue,
              null, null, this.entityId, null);
      }
    }
    return false;
  }

  getHeaderText() {
    switch (this.mode) {
      case DocumentAttachmentPlaces.Building:
        return 'General Building Documents';
      case DocumentAttachmentPlaces.BuildingTenant:
        return 'Tenant Documents';
      default:
        return '';
    }
  }

  private _groupFilesByFolders() {
    const foldersWithFiles: Array<FolderWithFiles> = [];
    if (this._files) {
      for (const folder of this.folders) {
        const attachmentFolder = new FolderWithFiles();
        attachmentFolder.folder = folder;
        attachmentFolder.files = this._files.filter(x => x.folderId === folder.id);
        attachmentFolder.selectedFiles = [];

        foldersWithFiles.push(attachmentFolder);
      }
    }

    this.foldersWithFiles = foldersWithFiles;
  }

  private _getFileFolders() {
    const nextAction = (folders) => {
      this.folders = folders;
      this._groupFilesByFolders();
    };
    switch (this.mode) {
      case DocumentAttachmentPlaces.Building:
        this._landlordManager
          .getBuildingFileFolders()
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(nextAction);

        break;

      case DocumentAttachmentPlaces.BuildingTenant:
        this._landlordManager
          .getLeaseFileFolders()
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(nextAction);

        break;

      case DocumentAttachmentPlaces.TenantFiles:
        this._documentService
          .getFileFolders()
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(nextAction);
        break;

      default:
        this._throwUnexpectedMode();
        break;
    }
  }

  private _throwUnexpectedMode() {
    throw new Error(`Unexpected document attachment place: ${this.mode}`);
  }

}
