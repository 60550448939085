import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import { BaseTermDirective } from '../base-term.directive';

import * as models from '../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-estoppel-certificate',
  templateUrl: './estoppel-certificate.component.html',
  styleUrls: ['./estoppel-certificate.component.scss'],
})
export class EstoppelCertificateComponent extends BaseTermDirective<models.IEstoppelCertificateTermViewModel> implements OnInit {
  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  options: any = [];

  EstoppelCertificateTermType = models.EstoppelCertificateTermType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  ngOnInit() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.IEstoppelCertificateTermViewModel>{};
    }
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.estoppelCertificate && this.leaseTerm.estoppelCertificateTermType === models.EstoppelCertificateTermType.Custom) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.leaseTerm.estoppelCertificateTermType === models.EstoppelCertificateTermType.PerCurrentLease) {
      this.leaseTerm.estoppelCertificate = this.leaseTerm.abstractValue;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: (
            'Landlord and Tenant shall each execute commercially reasonable estoppel ' +
            'certificates as requested from time to time by the other'
          ),
          value: models.EstoppelCertificateTermType.TenantAndLandlordExecuteEstoppelCertificate,
        },
        {
          name: (
            'Within (INSERT DAYS) of Landlord\'s request, Tenant shall execute and deliver ' +
            'to Landlord an Estoppel Certificate containing customary provisions.'
          ),
          value: models.EstoppelCertificateTermType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest,
        },
        {
          name: 'Custom',
          value: models.EstoppelCertificateTermType.Custom,
        },
      ];
      return;
    }
    this.options = [
      {
        name: 'Per current lease',
        value: models.EstoppelCertificateTermType.PerCurrentLease,
      },
      {
        name: (
          'Landlord and Tenant shall each execute commercially reasonable estoppel ' +
          'certificates as requested from time to time by the other'
        ),
        value: models.EstoppelCertificateTermType.TenantAndLandlordExecuteEstoppelCertificate,
      },
      {
        name: (
          'Within (INSERT DAYS) of Landlord\'s request, Tenant shall execute and deliver ' +
          'to Landlord an Estoppel Certificate containing customary provisions.'
        ),
        value: models.EstoppelCertificateTermType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest,
      },
      {
        name: 'Custom',
        value: models.EstoppelCertificateTermType.Custom,
      },
    ];
  }
}
