import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import { Role } from '@statera/sdk/common';
import { LeaseManager } from '@statera/sdk/lease';
import { TermManager } from '@statera/sdk/term';
import { TermHistoryManager, TermHistoryRecord, TermSettingDiff } from '@statera/sdk/term-history';

import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-term-history',
  templateUrl: './term-history.component.html',
  styleUrls: ['./term-history.component.scss'],
})
export class TermHistoryComponent implements OnChanges, OnDestroy {
  @Output() isRecord: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() project: models.IProjectViewModel;
  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTermConfiguration: models.ILeaseTermConfiguration;
  @Input() popoverContainer: Element;
  @Input() demoRentalRateTerm = false;
  @Input () hideTitle = false;
  @Input() leaseHistory: models.ILeaseHistoryRecordViewModel;

  get isBaseRentalRate(): boolean {
    return (
      this.leaseTermConfiguration &&
      this.leaseTermConfiguration.leaseTermType === models.LeaseTermType.BaseRentalRate
    );
  }

  showAllRecords = false;
  slicedRecords = 0;
  acceptedValue: TermHistoryRecord;
  lastValue: TermHistoryRecord;

  TermType = models.TermType;
  records: Array<TermHistoryRecord> = [];

  Role: typeof Role = Role;
  LeaseTeam = models.LeaseTeam;

  private readonly _authService: AuthService;
  private readonly _leaseManager: LeaseManager;
  private readonly _termManager: TermManager;
  private readonly _termHistoryManager: TermHistoryManager;
  private readonly _changeDetectorRef: ChangeDetectorRef;

  constructor(
    authService: AuthService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    termHistoryManager: TermHistoryManager,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this._authService = authService;
    this._leaseManager = leaseManager;
    this._termManager = termManager;
    this._termHistoryManager = termHistoryManager;
    this._changeDetectorRef = changeDetectorRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let leaseTermConfiguration = this.leaseTermConfiguration;
    let leaseHistoryRecord = this.leaseHistory;

    if (
      changes.leaseHistoryRecord &&
      (
        changes.leaseHistoryRecord.isFirstChange() ||
        (
          changes.leaseHistoryRecord.previousValue !== changes.leaseHistoryRecord.currentValue
        )
      )
    ) {
      leaseHistoryRecord = changes.leaseHistoryRecord.currentValue;
    }

    if (
      changes.leaseTermConfiguration &&
      (
        changes.leaseTermConfiguration.isFirstChange() ||
        (
          changes.leaseTermConfiguration.previousValue !== changes.leaseTermConfiguration.currentValue
        )
      )
    ) {
      leaseTermConfiguration = changes.leaseTermConfiguration.currentValue;
    }

    if (leaseHistoryRecord || leaseTermConfiguration) {
      this._displayChangeHistoryRecords(leaseHistoryRecord, leaseTermConfiguration);
    }
  }

  ngOnDestroy(): void {
    this._changeDetectorRef.detach();
  }

  getDiffClassName(termSettingDiff?: TermSettingDiff): string | null {
    return this._termHistoryManager.getDiffClassName(termSettingDiff);
  }

  isTenantLeaseTeam(): boolean {
    const userLeaseTeam = this._leaseManager
      .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);

    return userLeaseTeam === models.LeaseTeam.TenantTeam;
  }

  isMultipleOptionsTerm(): boolean {
    return this._termManager
      .isMultipleOptionsTerm(this.leaseTermConfiguration);
  }

  shouldShowMultipleOptionsCustomTable(): boolean {
    return (
      this._termManager.isShowMultiplyOptionsForTenant(this.lease.term, this.project) &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.lease.term.termStatus === models.TermStatus.Pending
    );
  }

  isCustomTable(termHistoryRecord: TermHistoryRecord): boolean {
    return termHistoryRecord.hasLeaseTermCustomTable;
  }

  canGetMultipleOptionsCustomTable(): boolean {
    return this._termManager
      .canGetMultipleOptionsCustomTable(this.lease);
  }

  getRoleByLeaseTeam(leaseTeam: models.LeaseTeam): Role {
    switch (leaseTeam) {
      case models.LeaseTeam.LandlordTeam:
        return Role.Landlord;
      case models.LeaseTeam.TenantTeam:
        return Role.Tenant;
    }
  }

  getMultipleOptionsCustomTable(): models.ILeaseTermCustomTableViewModel {
    return this._termManager
      .getMultipleOptionsCustomTable(this.lease);
  }

  getAdditionalValueTable(): models.ILeaseTermCustomTableViewModel {
    return this.leaseHistory.leaseTermHistoryRecords.find(x => x.leaseTermType ===
      this.leaseTermConfiguration.leaseTermType)?.additionalValueTable;
  }

  private _displayChangeHistoryRecords(
    leaseHistory: models.ILeaseHistoryRecordViewModel,
    leaseTermConfiguration: models.ILeaseTermConfiguration
  ): void {
    const termHistoryResult = this._termHistoryManager.getChangeHistoryRecords(leaseHistory, leaseTermConfiguration);

    this.records = termHistoryResult.previousChanges;
    this.records.reverse();
    this.lastValue = termHistoryResult.lastNotAcceptedValue;
    this.acceptedValue = termHistoryResult.acceptedValue;
    this.slicedRecords = 2 - +(!!this.lastValue) - +(!!this.acceptedValue);
    this.isRecord.emit(!!(this.records?.length || this.lastValue || this.acceptedValue));
  }
}
