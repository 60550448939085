import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTabPanelComponent } from 'devextreme-angular';
import { Subject, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { ColaboManager } from '@statera/sdk/colabo';
import { ProjectManager } from '@statera/sdk/project';
import { TenantRequestManager } from '@statera/sdk/tenant-request';

import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';
import { LeaseTeamUserRole } from '../../../infrastructure/models/generated';
import { AlertService as UIAlertService } from '../../../alert/services/alert.service';
import { ActivityTrackerService } from '../../../infrastructure/services/activity-tracker.service';

import { MessagesComponent } from '../../../shared/components/messages/messages.component';
import { AlertService } from '../../../shared/services/alert.service';
import { AppService } from '../../../shared/services/app.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { ProjectService } from '../../../shared/services/project.service';

import { AlertListComponent } from '../alert-list/alert-list.component';
import { ColaboDemoComponent } from '../colabo-demo/colabo-demo.component';
import { DialogService } from '../../../dialog/services/dialog.service';
import {
  LeaseLandlordCancellationComponent
} from '../../../landlord/components/lease-cancellation/lease-landlord-cancellation.component';
import {
  LeaseTenantCancellationComponent
} from '../../../tenant/components/lease-cancellation/lease-tenant-cancellation.component';
import { LeaseManager } from '@statera/sdk/lease';

@Component({
  selector: 'app-colabo',
  templateUrl: './colabo.component.html',
  styleUrls: ['./colabo.component.scss']
})
export class ColaboComponent implements OnInit, OnDestroy {
  @ViewChild('termList') termList: any;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @ViewChild('chatMessages') chatMessages: MessagesComponent;
  @ViewChild('termListContainer') termListContainer: any;
  @ViewChild('alertList') alertList: AlertListComponent;
  @ViewChild('colaboDemo') colaboDemo: ColaboDemoComponent;

  chatChannels: Array<models.IColaboTreeItemViewModel>;
  tabs = [{text: 'Internal Chat'}, {text: 'External Chat'}, {text: 'Activity'}];
  lease: models.ILeaseViewModel;
  selectedTabIndex = 0;
  alertGroupViewModel: models.IAlertGroupViewModel;
  tabHeight: number;
  project: models.IProjectViewModel;
  activeStep: models.IProjectTemplateItemViewModel;
  notShownAlerts: Array<models.IAlertViewModel>;
  leaseTeam?: models.ILeaseTeamViewModel;
  LeaseTeamUserRole = LeaseTeamUserRole;

  contentTabs = [{'title': 'Tab1', 'template': 'templ1'}, {'title': 'Tab2', 'template': 'templ2'}];

  get isMobile(): boolean {
    return AppService.isMobile;
  }

  channelListCollapsed = false;
  authService: AuthService;

  private readonly _colaboManager: ColaboManager;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;
  private readonly _leaseService: LeaseService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _projectManager: ProjectManager;
  private readonly _tenantRequestManager: TenantRequestManager;
  private readonly _projectService: ProjectService;
  private readonly _uiAlertService: UIAlertService;
  private readonly _dialogService: DialogService;
  private readonly _activityTrackerService: ActivityTrackerService;
  private readonly _leaseManager: LeaseManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    colaboManager: ColaboManager,
    activatedRoute: ActivatedRoute,
    router: Router,
    leaseService: LeaseService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    projectManager: ProjectManager,
    tenantRequestManager: TenantRequestManager,
    projectService: ProjectService,
    uiAlertService: UIAlertService,
    dialogService: DialogService,
    leaseManager: LeaseManager,
    activityTrackerService: ActivityTrackerService,
  ) {
    this.authService = authService;
    this._colaboManager = colaboManager;
    this._activatedRoute = activatedRoute;
    this._router = router;
    this._leaseService = leaseService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._projectManager = projectManager;
    this._tenantRequestManager = tenantRequestManager;
    this._projectService = projectService;
    this._uiAlertService = uiAlertService;
    this._dialogService = dialogService;
    this._activityTrackerService = activityTrackerService;
    this._leaseManager = leaseManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this._activatedRoute
      .params
      .pipe(
        switchMap(params => {
          this.leaseTeam = null;
          const leaseId = parseInt(params['id'], 10);
          if (!leaseId) {
            return of(null);
          }

          return this._leaseService
            .getLeaseWithAbstracts(leaseId);
        }),
        switchMap(lease => {
          this.lease = lease;

          if (!this.lease) {
            return of(<Array<models.IProjectViewModel>>[]);
          }

          return this._projectService
            .getProjectsByLeaseId(lease.id);
        }),
        map((projects: Array<models.IProjectViewModel>) => {
          if (!this.lease || !projects || !projects.length) {
            return null;
          }

          return projects.find(x =>
            x.leaseId === this.lease.id &&
            (
              x.projectTypeId === models.ProjectTypeEnum.Renewal ||
              x.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord ||
              x.projectTypeId === models.ProjectTypeEnum.Restructure ||
              x.projectTypeId === models.ProjectTypeEnum.NewDeal ||
              x.projectTypeId === models.ProjectTypeEnum.NewDealInquiry
            )
          );
        }),
        switchMap(project => {
          if (!this.lease) {
            return of(null);
          }

          this.project = project;

          if (this.project && this.project.projectState) {

            if (this.project.projectState.parentProjectTemplateItem !== null) {
              this.activeStep = this.project.projectState.parentProjectTemplateItem;
            } else {
              this.activeStep = this.project.projectState;
            }
          } else {
            this.activeStep = null;
          }

          return this._alertService
            .getLastStateChangedAlertsByLeaseId(this.lease.id);
        }),
        tap(alerts => {
          if (!alerts) {
            return;
          }

          if (this.colaboDemo.isShowDemo()) {
            this.notShownAlerts = alerts;
            this._showDemo();
            return;
          }

          this._showAlerts(alerts);
        }),
        switchMap(() => {
          if (!this.lease) {
            return of(null);
          }

          return this._leaseManager.getLeaseTeam(this.lease.id);
        }),
        tap((team) => {
          this.leaseTeam = team;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._activatedRoute
      .queryParams
      .pipe(
        tap(params => {
          const chatTabName = params['chatTab'] === 'External'
            ? 'External Chat'
            : 'Internal Chat';
          this._setChatTabIndex(chatTabName);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._requestColaboTreeItems();
    this._trackRealtimeEvents();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _requestColaboTreeItems(): void {
    this._colaboManager
      .requestColaboTreeItems()
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _trackRealtimeEvents(): void {
    let realtimeRefreshSubscription: Subscription;

    const unsubscribe = () => {
      if (realtimeRefreshSubscription) {
        realtimeRefreshSubscription.unsubscribe();
        realtimeRefreshSubscription = null;
      }
    };

    const subscribe = () => {
      unsubscribe();

      realtimeRefreshSubscription = this._colaboManager
        .getRealtimeRefreshColaboMessage()
        .pipe(
          filter(message => !!message),
          tap(() => this._requestColaboTreeItems()),
          takeUntil(this._destroy$),
        )
        .subscribe();
    };

    let reInitPage = false;

    this._activityTrackerService
      .track()
      .pipe(
        tap(isUserActive => {
          if (isUserActive) {
            subscribe();

            if (reInitPage) {
              this._requestColaboTreeItems();
            }

            reInitPage = true;

            return;
          }

          unsubscribe();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onResize() {
    if (this.termListContainer && this.termListContainer.nativeElement && this.termListContainer.nativeElement.clientHeight) {
      const clientHeight = this.termListContainer.nativeElement.clientHeight - 40;
      this.tabHeight = clientHeight;
      if (this.termList) {
        this.termList.height = clientHeight;
      }
      if (this.chatMessages) {
        this.chatMessages.height = clientHeight / 4;
      }
      if (this.alertList) {
        this.alertList.height = clientHeight;
      }
    }
  }

  getNotificationCount(text: string): number {
    let countNotifications: number = null;

    if (this.lease) {
      if (this.alertGroupViewModel && this.alertGroupViewModel.leaseAlerts) {
        const leaseNotification = this.alertGroupViewModel.leaseAlerts.find(x => x.leaseId === this.lease.id);
        if (leaseNotification) {
          if (text === 'External Chat') {
            countNotifications = leaseNotification.unreadExternalMessagesCount;
          }

          if (text === 'Internal Chat') {
            countNotifications = leaseNotification.unreadInternalMessagesCount;
          }

          if (text === 'Alerts') {
            countNotifications = leaseNotification.totalUnreadCount - leaseNotification.unreadExternalMessagesCount
              - leaseNotification.unreadInternalMessagesCount;
          }
        }
      }
    }
    return countNotifications;
  }

  closeChat() {
    // TODO: [FIXME] We must stop use DOM API, use renderer instead
    const el = document.querySelector('#colabo-chat');
    if (el) {
      el.classList.remove('full--screen');
    }
    if (AppService.isMobile) {
      this.tabPanel.selectedIndex = 0;
    }
  }

  openChat() {
    if (AppService.isMobile) {
      this.tabPanel.selectedIndex = 1;
    }
  }

  closeChannel() {
    // TODO: [FIXME] We must stop use DOM API, use renderer instead
    const el = document.querySelector('#channels');
    if (el) {
      el.classList.remove('active');
    }
  }

  private _showAlerts(alerts: Array<models.IAlertViewModel>): void {
    if (!alerts) {
      return;
    }

    for (let i = 0, num = alerts.length; i < num; i++) {
      const alert = alerts[i];

      const alertReference = this._uiAlertService
        .pushInfoAlert({
          id: alert.id,
          message: alert.text,
          closable: true,
          autoclose: false,
        });

      alertReference
        .shown
        .pipe(
          switchMap(() => this._alertService
            .markReadAlert(alert)
          ),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  onSkippedDemo() {
    this._showAlerts(this.notShownAlerts);
  }

  private _showDemo() {
    setTimeout(() => {
      this.colaboDemo.show(this);
    }, 500);
  }

  onCancelNegotiationByTenant() {
    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCancelDealAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        const dialogRef = this._dialogService.show(LeaseTenantCancellationComponent, {
          showCloseButton: true,
          closeOnOutsideClick: false,
          width: 768,
          height: 'auto',
          injectableData: {
            leaseId: this.lease.id,
            project: this.project,
            buildingAddress: this.lease.building.address.displayString,
          },
        });

        dialogRef
          .onHiding
          .pipe(
            switchMap(() => this._projectService
              .getProjectsByLeaseId(this.lease.id)
            ),
            takeUntil(this._destroy$),
          )
          .subscribe(() => {
            if (dialogRef.outputData) {
              this._router.navigate(['tenant', 'dashboard']);
            }
          });
      });
  }

  onCancelNegotiationByLandlord() {
    if (this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDealInquiry) {
      const alertReference = this._uiAlertService
        .pushConfirmAlert({
          message: this._alertMessagesManager.getInquiryConfirmArchiveMessage(),
        });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => (
            this._projectManager.getProjectRequestByLeaseId(this.lease.id)
          )),
          switchMap((tenantRequest) => (
            this._tenantRequestManager.putStatusById(
              tenantRequest.id,
              models.TenantRequestStatus.Archived,
            )
          )),
          switchMap(() => (
            this._projectService.getProjectsByLeaseId(this.lease.id)
          )),
          tap(() => {
            this._router.navigate(['landlord', 'dashboard']);
          }),
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe();
    } else {
      const alertReference = this._uiAlertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmCancelDealAlertText(),
      });

      let dialogRef;

      alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            alertReference.hide();
            dialogRef = this._dialogService
              .show(LeaseLandlordCancellationComponent, {
                title: 'Cancel Negotiation',
                showCloseButton: true,
                closeOnOutsideClick: false,
                containerClassNames: ['landlord-cancel-lease'],
                width: 450,
                height: 'auto',
                maxHeight: 700,
                injectableData: {
                  leaseId: this.lease.id,
                  buildingAddress: this.lease.building.address.displayString,
                },
              });

            return dialogRef.onHiding;
          }),
          switchMap(() => (
            this._projectService.getProjectsByLeaseId(this.lease.id)
          )),
          tap(() => {
            if (dialogRef.outputData) {
              this._router.navigate(['landlord', 'dashboard']);
            }
          }),
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe();
    }
  }

  isShowTimeline(): boolean {
    return this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.InsuranceCertificate
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Renewal
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Restructure
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Maintenance;
  }

  getLandlordCompanyLogoUrl(): string {
    return this.lease?.landlordCompany?.logo?.thumbnailUrl ?? 'assets/img/nofoto.png';
  }

  getTenantCompanyLogoUrl(): string {
    return this.lease?.tenantCompany?.logo?.thumbnailUrl ?? 'assets/img/nofoto.png';
  }

  getTenantCompanyName(): string {
    return this.lease?.tenantCompany?.name;
  }

  getLandlordCompanyName(): string {
    return this.lease.landlordCompany.name;
  }

  isShowLeaseTeam(): boolean {
    return !!this.leaseTeam;
  }

  private _setChatTabIndex(tabName: string) {
    this.selectedTabIndex = this.tabs.findIndex(t => t.text === tabName);
  }
}
