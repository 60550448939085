import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { CommonTools } from '@statera/sdk/common';
import { TermManager } from '@statera/sdk/term';
import { DxComponent } from 'devextreme-angular';

import { AlertService } from '../../../../../../alert/services/alert.service';

import { SafeCurrencyPipe } from '../../../../../../infrastructure/pipes/safe-currency.pipe';
import { SafeNumberPipe } from '../../../../../../infrastructure/pipes/safe-number.pipe';

import * as models from '../../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-real-estate-taxes-cam-expenses-breakdown-table',
  templateUrl: './real-estate-taxes-cam-expenses-breakdown-table.component.html',
  styleUrls: ['./real-estate-taxes-cam-expenses-breakdown-table.component.scss']
})
export class RealEstateTaxesCamExpensesBreakdownTableComponent implements OnChanges {
  private static readonly _totalComparisonPrecision = 0.001;

  @Input() lease: models.ILeaseViewModel;

  @Input() realEstateTaxesCamExpensesViewModel: models.IRealEstateTaxesCamExpensesViewModel;
  @Output() realEstateTaxesCamExpensesViewModelChange: EventEmitter<models.IRealEstateTaxesCamExpensesViewModel>;

  realEstateTaxesCamExpensesBreakdownViewModel: models.IRealEstateTaxesCamExpensesBreakdownViewModel;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _termManager: TermManager;
  private readonly _safeCurrencyPipe: SafeCurrencyPipe;
  private readonly _safeNumberPipe: SafeNumberPipe;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termManager: TermManager,
    safeCurrencyPipe: SafeCurrencyPipe,
    safeNumberPipe: SafeNumberPipe,
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._termManager = termManager;
    this._safeCurrencyPipe = safeCurrencyPipe;
    this._safeNumberPipe = safeNumberPipe;

    this.realEstateTaxesCamExpensesViewModelChange = new EventEmitter<models.IRealEstateTaxesCamExpensesViewModel>();

    this.getDevExpressPSFMetricsFormat = this.getDevExpressPSFMetricsFormat.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      (changes.realEstateTaxesCamExpensesViewModel || changes.lease) &&
      (this.realEstateTaxesCamExpensesViewModel && this.lease)
    ) {
      let { realEstateTaxesCamExpensesBreakdown } = this.realEstateTaxesCamExpensesViewModel;
      if (!realEstateTaxesCamExpensesBreakdown) {
        realEstateTaxesCamExpensesBreakdown = <models.IRealEstateTaxesCamExpensesBreakdownViewModel>{};
      }

      this.realEstateTaxesCamExpensesBreakdownViewModel = <models.IRealEstateTaxesCamExpensesBreakdownViewModel> {
        ...realEstateTaxesCamExpensesBreakdown,
        insurance: this.realEstateTaxesCamExpensesViewModel?.insurance,
        managementFee: this._termManager.getPropertyManagementFee(this.realEstateTaxesCamExpensesViewModel, this.lease) || null,
      };
    }
  }

  submit() {
    const total = this._termManager
      .getRealEstateTaxesCamExpensesBreakdownTotalValue(this.realEstateTaxesCamExpensesBreakdownViewModel);

    if (!total) {
      this._alertService.pushErrorAlert({
        message: this._alertMessagesManager.getRealEstateTaxesOpExNotMatchAlertText(),
      });

      return;
    }

    if (!CommonTools.compareTwoFloatNumber(total, this.getEstimateTotal())) {
      this._alertService.pushErrorAlert({
        message: this._alertMessagesManager.getRealEstateTaxesOpExNotMatchAlertText(),
      });

      return;
    }
  }

  getTotal(): string {
    const result = this._termManager
      .getRealEstateTaxesCamExpensesBreakdownTotalValue(this.realEstateTaxesCamExpensesBreakdownViewModel);

    if (result) {
      return this._safeCurrencyPipe.transform(result);
    }

    return 'N/A';
  }

  getEstimateTotal(): number {
    return this._termManager.getTotalEstimatedRealEstateTaxesValue(this.realEstateTaxesCamExpensesViewModel, this.lease);
  }

  isTotalMatchesEstimatedOpEx(): boolean {
    if (!this.realEstateTaxesCamExpensesViewModel) {
      return false;
    }

    const total = this._termManager
      .getRealEstateTaxesCamExpensesBreakdownTotalValue(this.realEstateTaxesCamExpensesBreakdownViewModel);
    const estimateTotal = this.getEstimateTotal();

    const absoluteError = Math.abs(total - estimateTotal);
    const relativeError = absoluteError / estimateTotal;

    return relativeError <= RealEstateTaxesCamExpensesBreakdownTableComponent._totalComparisonPrecision;
  }

  getDevExpressPSFMetricsFormat<T>(value: T): string {
    const format = `$ %v`;

    if (value === null || value === undefined) {
      return format.replace('%v', '0.00');
    }

    return format.replace('%v', this._safeNumberPipe.transform(value, '1.2-2'));
  }

  handleFieldDxFieldFocus(event: { component: DxComponent }, nilValue = 0): void {
    const instance = event?.component?.instance();
    if (!instance || typeof instance.option !== 'function') {
      return;
    }

    const currentValue = instance.option('value');
    if (currentValue) {
      return;
    }

    // Automatically fills to show the mask when the control is in focus
    instance.option('value', nilValue);
  }

  handleFieldDxFieldBlur(event: { component: DxComponent }): void {
    const instance = event?.component?.instance();
    if (!instance || typeof instance.option !== 'function') {
      return;
    }

    const currentValue = instance.option('value');
    if (currentValue) {
      return;
    }

    // Automatically clears to hide the mask when the control is not in focus
    instance.option('value', null);
  }

  handleInsuranceChange(value: number): void {
    if (!this.realEstateTaxesCamExpensesViewModel) {
      return;
    }

    this.realEstateTaxesCamExpensesViewModel = {
      ...this.realEstateTaxesCamExpensesViewModel,
      insurance: value,
      realEstateTaxesCamExpensesBreakdown: {
        ...this.realEstateTaxesCamExpensesBreakdownViewModel,
      },
    };

    this.realEstateTaxesCamExpensesViewModelChange.next(this.realEstateTaxesCamExpensesViewModel);
  }

  handleManagementFeeChange(value: number): void {
    if (!this.realEstateTaxesCamExpensesViewModel) {
      return;
    }

    this.realEstateTaxesCamExpensesViewModel = {
      ...this._termManager.convertPropertyManagementFeeToCorrectValue(
        value,
        this.realEstateTaxesCamExpensesViewModel,
        this.lease
      ),
      realEstateTaxesCamExpensesBreakdown: {
        ...this.realEstateTaxesCamExpensesBreakdownViewModel,
      },
    };

    this.realEstateTaxesCamExpensesViewModelChange.next(this.realEstateTaxesCamExpensesViewModel);
  }
}
