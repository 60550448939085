import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureCollection, Point } from 'geojson';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { PointOfInterestListOptionsViewModel } from './point-of-interest.model';

@Injectable()
export class PointOfInterestRepository {
  private static readonly _controllerURI: string = 'points-of-interest';

  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getPointsOfInterest(listOptions: PointOfInterestListOptionsViewModel): Observable<FeatureCollection<Point>> {
    return this._httpClient
      .post<FeatureCollection<Point>>(
        `${this._configuration.apiEndpoint}/${PointOfInterestRepository._controllerURI}/feature-collection`,
        listOptions,
      );
  }
}
