<ng-progress [spinner]="false" [color]="'#59b680'">
</ng-progress>

<router-outlet>
</router-outlet>

<app-loader>
</app-loader>

<app-onboadring-popup>
</app-onboadring-popup>

<app-alert-container>
</app-alert-container>
