import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import { ColaboStore } from './colabo.store';

import * as models from './colabo.model';

@Injectable()
export class ColaboRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;
  private readonly _colaboStore: ColaboStore;

  constructor(
    configuration: Configuration,
    httpClient: HttpClient,
    colaboStore: ColaboStore
  ) {
    this._configuration = configuration;
    this._httpClient = httpClient;
    this._colaboStore = colaboStore;
  }

  getColaboTreeItems(): Observable<Array<models.ColaboTreeItem>> {
    return this._httpClient
      .get<Array<models.ColaboTreeItem>>(`${this._configuration.apiEndpoint}/leases/tree`);
  }

  setStoredColaboTreeItems(colaboTreeItems: Array<models.ColaboTreeItem>): void {
    this._colaboStore.chatTreeItems.next(colaboTreeItems);
  }

  getStoredColaboTreeItems(): Observable<Array<models.ColaboTreeItem>> {
    return this._colaboStore.chatTreeItems;
  }

  getStoredColaboTreeItemsSync(): Array<models.ColaboTreeItem> {
    return this._colaboStore.chatTreeItems.getValue();
  }

  setStoredSelectedColaboTreeItem(chatTreeItem: models.ColaboTreeItem): void {
    this._colaboStore.selectedChatTreeItem.next(chatTreeItem);
  }

  getStoredSelectedColaboTreeItem(): Observable<models.ColaboTreeItem> {
    return this._colaboStore.selectedChatTreeItem;
  }
}
