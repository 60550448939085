<section class="w-auth w-auth--sing-up">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt="">
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">Automating the process, in order to eliminate any bias, as well as empowering tenants and landlords to make knowledgeable real estate decisions</p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <h1 class="b-auth-title">
          Welcome to Statera!
        </h1><!--b-auth-title -->

        <form class="w-auth-form" name="form" novalidate
              #form="ngForm" (ngSubmit)="submit(form)">
          <fieldset [disabled]="submitted ? 'disabled' : null">
            <div class="d-flex flex-column">
              <p class="b-label">
                Work Email
              </p>
              <input class="b-input" type="email" name="emailAddress" id="emailAddress" email required
                     [class.is-invalid]="form.submitted && emailAddress.invalid"
                     [(ngModel)]="vm.emailAddress"
                     #emailAddress="ngModel">
              <span class="error-msg"
                    *ngIf="form.submitted && emailAddress.hasError('required')">
                Required
              </span>
              <span class="error-msg"
                    *ngIf="form.submitted && emailAddress.hasError('email')">
                Enter a valid email address
              </span>
            </div><!--d-flex-->

            <div class="d-flex flex-column pt-4">
              <p class="b-label">
                I am a
              </p>
              <dx-radio-group
                #role="ngModel"
                id="role"
                name="role"
                [items]="roles"
                [(ngModel)]="vm.role"
                layout="horizontal">
              </dx-radio-group>
            </div>

            <div class="d-flex flex-column pt-4">
              <span class="error-msg"
                    *ngIf="form.submitted && firstName.hasError('required')">
                Required
              </span>

              <p class="b-label">
                First Name
              </p>
              <input class="b-input" type="text" name="firstName" id="firstName"
                     required [(ngModel)]="vm.firstName" #firstName="ngModel"
                     [class.is-invalid]="form.submitted && firstName.hasError('required')">
            </div><!--d-flex-->

            <div class="d-flex flex-column">
              <span class="error-msg"
                    *ngIf="form.submitted && lastName.hasError('required')">
                Required
              </span>

              <p class="b-label">
                Last Name
              </p>
              <input class="b-input" type="text" name="lastName" id="lastName"
                     required [(ngModel)]="vm.lastName" #lastName="ngModel"
                     [class.is-invalid]="form.submitted && lastName.hasError('required')">
            </div><!--d-flex-->

            <div class="d-flex flex-column">
              <span class="error-msg"
                    *ngIf="form.submitted && companyName.hasError('required')">
                Required
              </span>

              <p class="b-label">
                Company Name
              </p>
              <input class="b-input" type="text" name="companyName" id="companyName"
                     required [(ngModel)]="vm.companyName" #companyName="ngModel" (change)="companyChange($event, vm.companyName)"
                     [class.is-invalid]="form.submitted && companyName.hasError('required')">
              <p class="b-label" *ngIf="companyPrediction">
                Did you mean to enter: {{companyPrediction.name}}?
                <a class="b-fancy-link company-prediction" role="button" (click)="chooseYesPrediction()">
                  Y
                </a>
                /
                <a class="b-fancy-link company-prediction" role="button" (click)="chooseNoPrediction()">
                  N
                </a>
              </p>
            </div><!--d-flex-->

            <div class="d-flex flex-column pt-4">
              <p class="b-label">
                Phone Number(optional)
              </p>
              <dx-text-box
                id="mobilePhone"
                name="mobilePhone"
                #mobilePhone="ngModel"
                [(ngModel)]="vm.mobilePhone"
                mask="+1 (X00) 000-0000"
                [useMaskedValue]="true"
                [maskRules]="phoneRules"
                [required]="false"
                (onInitialized)="maskedPhoneNumberInitialized($event)"
              >
              </dx-text-box>
            </div>

            <div class="d-flex flex-column pt-4">
              <span class="error-msg"
                    *ngIf="form.submitted && password.hasError('required')">
                Required
              </span>
              <span class="error-msg"
                    *ngIf="form.submitted && (vm.password !== vm.confirmPassword)">
                The password and confirmation password do not match
              </span>

              <p class="b-label">
                Password
              </p>
              <input class="b-input" type="password" name="password" id="password"
                     required [(ngModel)]="vm.password" #password="ngModel"
                     [class.is-invalid]="form.submitted && password.hasError('required') && (vm.password !== vm.confirmPassword)">
            </div><!--d-flex-->

            <div class="d-flex flex-column pt-4">
              <span class="error-msg"
                    *ngIf="form.submitted && confirmPassword.hasError('required')">
                Required
              </span>
              <span class="error-msg"
                    *ngIf="form.submitted && (vm.password !== vm.confirmPassword)">
                The password and confirmation password do not match
              </span>

              <p class="b-label">
                Confirm Password
              </p>
              <input class="b-input" type="password" name="confirmPassword" id="confirmPassword"
                     required [(ngModel)]="vm.confirmPassword" #confirmPassword="ngModel"
                     [class.is-invalid]="form.submitted && confirmPassword.hasError('required') && (vm.password !== vm.confirmPassword)">
            </div><!--d-flex-->

            <div class="d-flex flex-column align-items-center pt-4 w-100">
              <button class="btn btn-sign-in">
                <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                <span class="text-uppercase">
                sign up
              </span>
              </button>
            </div><!--d-flex-->
          </fieldset>
        </form><!--w-auth-form-->

        <div class="w-auth-help-text">
          If you have an account.

          <a [routerLink]="['../signin-user']" class="b-fancy-link">
            sign in
          </a>
        </div><!--w-auth-help-text-->
      </div><!--login-content-->
    </section><!--login-->
  </div><!--w-auth-content-->
</section><!--w-auth-->

<app-footer></app-footer>
