import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './building-unit-listing.model';

@Injectable()
export class BuildingUnitListingRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  createBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._httpClient
      .post<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitListing.buildingUnitId}/listing`,
        buildingUnitListing
      );
  }

  updateBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._httpClient
      .put<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitListing.buildingUnitId}/listing`,
        buildingUnitListing
      );
  }

  deleteBuildingUnitListing(buildingUnitListing: models.BuildingUnitListingViewModel): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${this._configuration.apiEndpoint}/building-unit/${buildingUnitListing.buildingUnitId}/listing`
      );
  }
}
