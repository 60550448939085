import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class AccessModule {
  static forRoot(): ModuleWithProviders<AccessModule> {
    return {
      ngModule: AccessModule,
      providers: [],
    };
  }
}
