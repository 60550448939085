import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-deal-type',
  templateUrl: 'analytics-filters-deal-type.component.html',
  styleUrls: ['analytics-filters-deal-type.component.scss'],
})
export class AnalyticsFiltersDealTypeComponent {
  @Input() dealTypes: Array<analyticsFiltersModels.DealTypeFilter>;
  @Output() dealTypesChange: EventEmitter<Array<analyticsFiltersModels.DealTypeFilter>>;

  constructor() {
    this.dealTypesChange = new EventEmitter<Array<analyticsFiltersModels.DealTypeFilter>>();
  }

  handleDealTypeSelection(): void {
    this.dealTypesChange.next([...this.dealTypes]);
  }
}
