import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlertType, AlertNotification, AlertNotificationKind, ProjectType } from '@statera/sdk/alert';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

@Injectable()
export class RefreshColaboInterceptor implements NotificationInterceptor<AlertNotification> {
  private readonly _router: Router;

  constructor(router: Router) {
    this._router = router;
  }

  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert || !alert.leaseId || alert.kind === AlertNotificationKind.Confirm) {
      alert.shouldShowNavigationButton = false;

      return notification;
    }

    if (alert.leaseId) {
      alert.shouldShowNavigationButton = true;

      let queryParams;
      switch (alert.alertType) {
        case AlertType.Message:
          queryParams = alert.chatType === 'External'
            ? { 'chatTab': 'External' }
            : {};
          alert.navigation = {
            path: `/colabo/main/${alert.leaseId}`,
            isRefresh: false,
            queryParams: queryParams,
          };

          break;

        case AlertType.TermComment:
          queryParams = alert.chatType === 'External'
            ? { 'chatTab': 'External', leaseId: alert.leaseId, termName: alert.termName }
            : { leaseId: alert.leaseId, termName: alert.termName };
          alert.navigation = {
            path: '/colabo/terms',
            isRefresh: false,
            queryParams: queryParams,
          };

          break;

        default:
          if (alert.projectType === ProjectType.NewDealInquiry) {
            alert.navigation = {
              path: `/colabo/main/${alert.leaseId}`,
              isRefresh: false,
              queryParams: {},
            };
            break;
          }
          alert.navigation = {
            path: '/colabo/terms',
            isRefresh: false,
            queryParams: {
              leaseId: alert.leaseId,
              termName: alert.termName,
            },
          };

          break;
      }

      alert.navigationButtonText = 'Navigate to Colábo';
      if (this._isCurrentUrlMatchesNavigationPath(alert.navigation.path)) {
        alert.navigationButtonText = 'Refresh';
        alert.navigation.isRefresh = true;
      }
    }

    return notification;
  }

  private _isCurrentUrlMatchesNavigationPath(path: string): boolean {
    const currUrl = this._router.url || '';
    const currUrlWithoutQueryParams = currUrl.split('?')[0];

    return currUrlWithoutQueryParams === path;
  }
}
