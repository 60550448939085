import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {CommonTransformer} from '@statera/sdk/common';
@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private _timer: number;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _ngZone: NgZone;
  private readonly _commonTransformer: CommonTransformer;

  constructor(changeDetectorRef: ChangeDetectorRef, ngZone: NgZone, commonTransformer: CommonTransformer) {
    this._changeDetectorRef = changeDetectorRef;
    this._ngZone = ngZone;
    this._commonTransformer = commonTransformer;
  }
  transform(date: Date) {
    const now = new Date();
    this._removeTimer();
    const seconds = Math.round(Math.abs((now.getTime() - date.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this._getSecondsUntilUpdate(seconds) * 1000;
    this._timer = this._ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this._ngZone.run(() => this._changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    return this._commonTransformer.transformTimeAgo(date);
  }
  ngOnDestroy(): void {
    this._removeTimer();
  }
  private _removeTimer() {
    if (this._timer) {
      window.clearTimeout(this._timer);
      this._timer = null;
    }
  }
  private _getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }
}
