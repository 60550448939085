export enum DealsByStageReportChartType {
  AverageDaysTakenByTenant = 0,
  AverageDaysTakenByLandlord = 1,
}

export class DealsByStageReportTooltip {
  chartType: DealsByStageReportChartType;
  stageName: string;
  averageDaysTakenByTenant: number;
  averageDaysTakenByLandlord: number;
}
