import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appIgnoreScrollwheel]'
})
export class IgnoreScrollwheelDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    event.preventDefault();
  }
}
