import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration, IStateraUserClaimsSimplifiedViewModel } from '@statera/sdk/common';

@Injectable()
export class StateraUserClaimRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  requestStateraUserClaims$(): Observable<IStateraUserClaimsSimplifiedViewModel> {
    return this._httpClient
      .get<IStateraUserClaimsSimplifiedViewModel>(`${this._configuration.apiEndpoint}/statera-user-claims`);
  }
}
