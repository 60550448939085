// Angular Core modules
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Third-party modules (External libraries)
import { NgProgressHttpModule } from '@ngx-progressbar/http'; // Progress bar for HTTP requests
import { NgProgressModule } from '@ngx-progressbar/core'; // Core progress bar functionality
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools'; // Akita devtools for state management
import { CollapseModule } from 'ngx-bootstrap/collapse'; // Bootstrap collapse component
import { InlineSVGModule } from 'ng-inline-svg'; // Inline SVG rendering
import { NgxMaskModule } from 'ngx-mask'; // Input masking
import { NgxSmartModalModule } from 'ngx-smart-modal'; // Modal service
import { PopoverModule } from 'ngx-bootstrap/popover'; // Bootstrap popover component
import { TooltipModule } from 'ngx-bootstrap/tooltip'; // Bootstrap tooltip component
import { DxChartModule, DxFileUploaderModule, DxSelectBoxModule } from 'devextreme-angular'; // DevExtreme UI components

import { SDKModule } from '@statera/sdk'; // Custom SDK module for API interaction

// Environment configuration
import { environment } from '../environments/environment'; // Environment variables

// Application Modules (Internal modules)
import { AccessModule } from './access/access.module'; // Access control and permissions
import { AlertModule } from './alert/alert.module'; // Alert and notification system
import { AnalyticsModule } from './analytics/analytics.module'; // Analytics and tracking
import { AuthModule } from './auth/auth.module'; // Authentication and authorization
import { BrokerModule } from './broker/broker.module'; // Broker-related functionality
import { DashboardModule } from './dashboard/dashboard.module'; // Dashboard and main overview
import { DialogModule } from './dialog/dialog.module'; // Custom dialogs and modals
import { FeatureTogglingModule } from './feature-toggling/feature-toggling.module'; // Feature toggling and management
import { InfrastructureModule } from './infrastructure/infrastructure.module'; // Core infrastructure services and utilities
import { InvitationModule } from './invitation/invitation.module'; // Invitation management
import { LeaseRequestModule } from './lease-request/lease-request.module'; // Lease request processing
import { SharedModule } from './shared/shared.module'; // Shared components and services
import { TeamModule } from './team/team.module'; // Team and collaboration features

// Application Router
import { AppRouterModule } from './app-router.module'; // Routing configuration

// Application Components
import { AppComponent } from './app.component'; // Root component of the application

// Application Guards
import { AuthGuard } from './auth/guards/auth.guard'; // Guard to protect routes
import { ExistingLeasesGuard } from './infrastructure/guards/existing-leases.guard'; // Guard for existing leases
import { FillingProfileGuard } from './infrastructure/guards/filling-profile.guard'; // Guard for profile completion

// Application Interceptors
import { HttpClientHeadersInterceptor } from './infrastructure/interceptors/http-client-headers-interceptor'; // Custom HTTP headers interceptor
import { HttpClientInterceptor } from './infrastructure/interceptors/http-client-interceptor'; // General HTTP request interceptor

// Application Services
import { MonitoringExceptionHandlerService } from './infrastructure/services/monitoring-exception-handler.service'; // Global error handling service
import { ProgressBarService } from './infrastructure/services/progress-bar.service'; // Progress bar service

// Application Pipes
import { SanitizeHtmlPipe } from './infrastructure/pipes/sanitize-html.pipe'; // Pipe for sanitizing HTML


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgProgressHttpModule,
    NgProgressModule,
    CollapseModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),

    DxChartModule,
    DxFileUploaderModule,
    DxSelectBoxModule,

    environment.production ? [] : AkitaNgDevtools.forRoot(),

    SDKModule.forRoot({
      apiEndpoint: environment.webApiUrl,
      websocketEndpoint: environment.hubUrl,
      loggingEnabled: environment.loggingEnabled,
      renewalOnboardingQuizId: environment.renewalOnboardingQuizId,
      restructureOnboardingQuizId: environment.restructureOnboardingQuizId,
      tenantCancellationQuizId: environment.tenantCancellationQuizId,
      newDealQuizId: environment.newDealQuizId,
      newDealInquiryQuizId: environment.newDealInquiryQuizId,
    }),

    InfrastructureModule,
    SharedModule,

    AccessModule.forRoot(),
    AlertModule.forRoot(),
    AnalyticsModule.forRoot(),
    AuthModule.forRoot(),
    BrokerModule.forRoot(),
    DashboardModule,
    DialogModule,
    FeatureTogglingModule.forRoot(),
    InvitationModule.forRoot(),
    LeaseRequestModule.forRoot(),
    TeamModule.forRoot(),

    AppRouterModule,
  ],
  declarations: [
    AppComponent,
    SanitizeHtmlPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientHeadersInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: MonitoringExceptionHandlerService,
    },
    AuthGuard,
    ExistingLeasesGuard,
    FillingProfileGuard,
    ProgressBarService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
