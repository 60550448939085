<div class="site-plan">
  <!-- Start: Header -->
  <div class="site-plan-header">
    <!-- Start: Building properties -->
    <div class="building">
      <!-- Start: Building address & name -->
      <div class="building-address">
        <i class="icon building-icon"></i>
        <span>{{building.address.displayString}} — {{building.name}}</span>
      </div>
      <!-- End: Building address & name -->
      <!-- Start: Building properties -->
      <div class="building-properties">
        <ul>
          <!-- Start: Building size -->
          <li *ngIf="building.totalBuildingSize !== null">
            <i class="icon building-size-icon"></i>
            <span>{{building.totalBuildingSize | safeNumber}} SF</span>
          </li>
          <!-- End: Building size -->
          <!-- Start: Building construction year -->
          <li *ngIf="building.builtYear !== null">
            <i class="icon building-year-built-icon"></i>
            <span>Built in {{building.builtYear}}</span>
          </li>
          <!-- End: Building construction year -->
          <!-- Start: Building type -->
          <li *ngIf="building.buildingType !== null">
            <i class="icon building-type-icon"></i>
            <span>{{buildingTypes[building.buildingType]}}</span>
          </li>
          <!-- End: Building type -->
          <!-- Start: Building class -->
          <li *ngIf="building.buildingClass !== null">
            <i class="icon building-class-icon"></i>
            <span>Class {{buildingClasses[building.buildingClass]}}</span>
          </li>
          <!-- End: Building class -->
        </ul>
      </div>
      <!-- End: Building properties -->
    </div>
    <!-- End: Building properties -->
    <!-- Start: Close button -->
    <div class="close">
      <a role="button" href="javascript:void(0)"
         (click)="close()">
        <i class="dx-icon dx-icon-close"></i>
      </a>
    </div>
    <!-- End: Close button -->
  </div>
  <!-- End: Header -->
  <!-- Start: Body -->
  <div class="site-plan-body">
    <!-- Start: Plan viewer -->
    <div class="plan"
         #planViewerContainerElement>
      <!-- Start: Plan loading indicator -->
      <div class="preloader"
           *ngIf="!plans">
        <span></span>
      </div>
      <!-- End: Plan loading indicator -->
      <!-- Start: Plan viewer & select -->
      <ng-container *ngIf="plans">
        <!-- Start: No plans placeholder -->
        <div *ngIf="!plans.length">
          No plans available yet.
        </div>
        <!-- End: No plans placeholder -->
        <!-- Start: Plan viewer -->
        <app-plan-viewer *ngIf="plans.length"
                         [image]="sitePlanImage"
                         [allowChangeMarkers]="false" [isDragEnabled]="true"
                         [width]="planViewerContainerElement.offsetWidth"
                         [height]="planViewerContainerElement.offsetHeight"
                         (selectedMarkerIdChange)="handlePlanMarkerSelect($event)">
        </app-plan-viewer>
        <!-- End: Plan viewer -->
        <!-- Start: Plan select -->
        <div class="plan-select"
             *ngIf="plans.length">
          <dx-select-box name="plan"
                         displayExpr="name"
                         [items]="plans" [(ngModel)]="selectedPlan"
                         (onValueChanged)="handlePlanChange($event)"
                         #planSelect="ngModel">
          </dx-select-box>
        </div>
        <!-- End: Plan select -->
      </ng-container>
      <!-- End: Plan viewer & select -->
    </div>
    <!-- End: Plan viewer -->
    <!-- Start: Plan sidebar -->
    <div class="plan-sidebar">
      <dx-scroll-view width="100%" height="100%"
                      #planSidebarScrollviewElementRef>
        <!-- Start: No selected unit placeholder -->
        <div class="unit-details"
             *ngIf="!selectedBuildingUnit">
          No unit selected
        </div>
        <!-- End: No selected unit placeholder -->
        <!-- Start: Selected unit details -->
        <div class="unit-details"
             *ngIf="selectedBuildingUnit">
          <!-- Start: Unit name -->
          <h3 class="unit-name">
            {{selectedBuildingUnit.name}}
          </h3>
          <!-- End: Unit name -->
          <!-- Start: Unit status -->
          <div class="unit-status">
            <!-- Start: Occupying company -->
            <div class="company"
                 *ngIf="!selectedBuildingUnit.lease ||
                        (selectedBuildingUnit.lease &&
                         selectedBuildingUnit.lease.tenantCompany.id !== startupInfo.companyId)">
              <ng-container *ngIf="selectedBuildingUnit.lease; else vacantTemplate">
                <img [src]="selectedBuildingUnit.lease.tenantCompany.logo ?
                              selectedBuildingUnit.lease.tenantCompany.logo.url :
                              'assets/img/nofoto.png'"
                     class="company-picture" />
                <div class="company-name">
                  {{selectedBuildingUnit.lease.tenantCompany.name}}
                </div>
              </ng-container>
              <ng-template #vacantTemplate>
                <img src="assets/img/vacant-unit.svg"
                     class="company-picture" />
                <div class="company-name">
                  Vacant
                </div>
              </ng-template>
            </div>
            <!-- End: Occupying company -->
            <!-- Start: Badges -->
            <div class="badges"
                 *ngIf="!selectedBuildingUnit.lease ||
                        (selectedBuildingUnit.lease &&
                        selectedBuildingUnit.lease.tenantCompany.id === startupInfo.companyId)">
              <div class="status-badge"
                   *ngIf="selectedBuildingUnit.lease &&
                          selectedBuildingUnit.lease.tenantCompany.id === startupInfo.companyId; else notOwnSpace">
                Your current space
              </div>
              <ng-template #notOwnSpace>
                <div class="status-badge"
                     *ngIf="selectedBuildingUnit.listing">
                  Available Space
                </div>
                <div class="status-badge"
                     *ngIf="!selectedBuildingUnit.listing && !selectedBuildingUnit.lease">
                  Unavailable Space
                </div>
              </ng-template>
            </div>
            <!-- End: Badges -->
          </div>
          <!-- End: Unit status -->
          <!-- Start: Unit availability date -->
          <div class="unit-availability-date"
               *ngIf="selectedBuildingUnit.listing && selectedBuildingUnit.listing.availableOn">
            <span>Available On</span><br/>
            {{selectedBuildingUnit.listing.availableOn | safeDate}}
          </div>
          <!-- End: Unit availability date -->
          <!-- Start: Unit size -->
          <h2 class="unit-size">
            <ng-container *ngIf="!selectedBuildingUnit.listing; else listingSizeTemplate">
              {{selectedBuildingUnit.size | safeNumber}} SF
            </ng-container>
            <ng-template #listingSizeTemplate>
              <ng-container *ngIf="selectedBuildingUnit.listing.sizeMin">
                {{selectedBuildingUnit.listing.sizeMin | safeNumber}} -
              </ng-container>
              {{selectedBuildingUnit.listing.sizeMax | safeNumber}} SF
            </ng-template>
          </h2>
          <!-- End: Unit size -->
          <!-- Start: Other unit properties -->
          <div class="unit-properties">
            <ul>
              <!-- Start: Unit office size -->
              <li *ngIf="selectedBuildingUnit.totalOfficeArea !== null">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Office Size
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.totalOfficeArea | safeNumber}} SF
                  </div>
                </div>
              </li>
              <!-- End: Unit office size -->
              <!-- Start: Unit loading -->
              <li *ngIf="selectedBuildingUnit.totalDocksInterior !== null ||
                         selectedBuildingUnit.totalDocksExterior !== null">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Loading
                  </div>
                  <div class="b-fancy-item-body">
                    <ng-container *ngIf="selectedBuildingUnit.totalDocksInterior &&
                                         !selectedBuildingUnit.totalDocksExterior">
                      {{ selectedBuildingUnit.totalDocksInterior | safeNumber }} Interior
                    </ng-container>
                    <ng-container *ngIf="!selectedBuildingUnit.totalDocksInterior &&
                                         selectedBuildingUnit.totalDocksExterior">
                      {{ selectedBuildingUnit.totalDocksExterior | safeNumber }} Exterior
                    </ng-container>
                    <ng-container *ngIf="selectedBuildingUnit.totalDocksInterior &&
                                         selectedBuildingUnit.totalDocksExterior">
                      {{ selectedBuildingUnit.totalDocksInterior | safeNumber }} Interior /
                      {{ selectedBuildingUnit.totalDocksExterior | safeNumber }} Exterior
                    </ng-container>
                  </div>
                </div>
              </li>
              <!-- End: Unit loading -->
              <!-- Start: Unit power -->
              <li *ngIf="selectedBuildingUnit.amps !== null ||
                         selectedBuildingUnit.volts !== null ||
                         selectedBuildingUnit.phase !== null">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Power
                  </div>
                  <div class="b-fancy-item-body">
                    <ng-container *ngIf="selectedBuildingUnit.amps !== null">
                      {{selectedBuildingUnit.amps}} A,
                    </ng-container>
                    <ng-container *ngIf="selectedBuildingUnit.volts !== null">
                      {{selectedBuildingUnit.volts}} V,
                    </ng-container>
                    <ng-container *ngIf="selectedBuildingUnit.phase !== null">
                      {{selectedBuildingUnit.phase}} P
                    </ng-container>
                  </div>
                </div>
              </li>
              <!-- End: Unit power -->
              <!-- Start: Unit columns -->
              <li *ngIf="selectedBuildingUnit.columnSpacing !== null">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Columns
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.columnSpacing}}
                  </div>
                </div>
              </li>
              <!-- End: Unit columns -->
              <!-- Start: Unit clear height -->
              <li *ngIf="selectedBuildingUnit.clearHeight !== null">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Clear Height
                  </div>
                  <div class="b-fancy-item-body">
                    {{ selectedBuildingUnit.clearHeight }}' clear
                  </div>
                </div>
              </li>
              <!-- End: Unit clear height -->
              <!-- Start: Unit asking rent -->
              <li *ngIf="selectedBuildingUnit.listing?.askingPriceExpression">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Asking Rent
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.listing?.askingPriceExpression}}
                  </div>
                </div>
              </li>
              <!-- End: Unit asking rent -->
            </ul>
          </div>
          <!-- End: Other unit properties -->
          <!-- Start: Unit lease properties -->
          <div class="lease-properties"
               *ngIf="selectedBuildingUnit.lease">
            <ul>
              <!-- Start: Lease expiration -->
              <li>
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Lease Expiration
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.lease.expiration | safeDate}}
                  </div>
                </div>
              </li>
              <!-- End: Lease expiration -->
              <!-- Start: Own lease - Renewal Notice -->
              <li *ngIf="selectedBuildingUnit.lease.tenantCompany.id === startupInfo.companyId &&
                         isLeaseRenewalOptionAvailable(selectedBuildingUnit.lease)">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Renewal Notice
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.lease.renewalOptionTerm.renewalDate | safeDate}}
                  </div>
                </div>
              </li>
              <!-- End: Own lease - Renewal Notice -->
              <!-- Start: Own lease - Expansion Notice -->
              <li *ngIf="selectedBuildingUnit.lease.tenantCompany.id === startupInfo.companyId &&
                         isLeaseExpansionOptionAvailable(selectedBuildingUnit.lease)">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Expansion Notice
                  </div>
                  <div class="b-fancy-item-body">
                    {{selectedBuildingUnit.lease.expansionOptionTerm.expansionDate | safeDate}}
                  </div>
                </div>
              </li>
              <!-- End: Own lease - Expansion Notice -->
              <!-- Start: Foreign lease - Renewal Option -->
              <li *ngIf="selectedBuildingUnit.lease.tenantCompany.id !== startupInfo.companyId">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Renewal Option
                  </div>
                  <div class="b-fancy-item-body">
                    <i class="fa fa-check color-green"
                       *ngIf="isLeaseRenewalOptionAvailable(selectedBuildingUnit.lease)">
                    </i>
                    <i class="fa fa-times color-red"
                       *ngIf="!isLeaseRenewalOptionAvailable(selectedBuildingUnit.lease)">
                    </i>
                  </div>
                </div>
              </li>
              <!-- End: Foreign lease - Renewal Option -->
              <!-- Start: Foreign lease - Expansion Option -->
              <li *ngIf="selectedBuildingUnit.lease.tenantCompany.id !== startupInfo.companyId">
                <div class="b-fancy-item">
                  <div class="b-fancy-item-title">
                    Expansion Option
                  </div>
                  <div class="b-fancy-item-body">
                    <i class="fa fa-check color-green"
                       *ngIf="isLeaseExpansionOptionAvailable(selectedBuildingUnit.lease)">
                    </i>
                    <i class="fa fa-times color-red"
                       *ngIf="!isLeaseExpansionOptionAvailable(selectedBuildingUnit.lease)">
                    </i>
                  </div>
                </div>
              </li>
              <!-- End: Foreign lease - Expansion Option -->
            </ul>
          </div>
          <!-- End: Unit lease properties -->
          <!-- Start: Unit description -->
          <div class="description"
               *ngIf="selectedBuildingUnit.description">
            {{selectedBuildingUnit.description}}
          </div>
          <!-- End: Unit description -->
          <!-- Start: RFP block -->
          <div class="rfp"
               *ngIf="selectedBuildingUnit.listing">
            <div class="rfp-label"
                 *ngIf="!selectedBuildingUnit.lease ||
                        (selectedBuildingUnit.lease &&
                         selectedBuildingUnit.lease.tenantCompany.id !== startupInfo.companyId &&
                         !isLeaseRenewalOptionAvailable(selectedBuildingUnit.lease) &&
                         isLeaseExpirationInLessThanAYear(selectedBuildingUnit.lease))">
              <ng-container *ngIf="!selectedBuildingUnit.lease">
                You are welcome to submit an RFP on this available space.
              </ng-container>
              <ng-container *ngIf="selectedBuildingUnit.lease &&
                                   selectedBuildingUnit.lease.tenantCompany.id !== startupInfo.companyId &&
                                   !isLeaseRenewalOptionAvailable(selectedBuildingUnit.lease) &&
                                   isLeaseExpirationInLessThanAYear(selectedBuildingUnit.lease)">
                Current Tenant does not have any existing options.  You are welcome to submit an RFP.
              </ng-container>
            </div>
            <button class="btn b-btn b-btn-solid"
                    *appIsFeatureEnabled="Feature.ColaboFeature"
                    [disabled]="isSubmitRfpButtonDisabled()"
                    (click)="submitRfp(submitRfpButton, selectedBuildingUnit)"
                    #submitRfpButton>
              Submit an RFP
            </button>
            <button class="btn b-btn b-btn-solid"
                    (click)="sendInquiry(sendInquiryButton, selectedBuildingUnit)"
                    #sendInquiryButton>
              Send Inquiry
            </button>
          </div>
          <!-- End: RFP block -->
          <!-- Start: Floor plan button -->
          <div class="floor-plan"
               *ngIf="selectedBuildingUnit.floorPlans?.length">
            <button class="btn b-btn b-btn-light"
                    (click)="openFloorPlanViewer(floorPlanButton, selectedBuildingUnit)"
                    #floorPlanButton>
              Floor Plan
            </button>
          </div>
          <!-- End: Floor plan button -->
        </div>
        <!-- End: Selected unit details -->
      </dx-scroll-view>
    </div>
    <!-- End: Plan sidebar -->
  </div>
  <!-- End: Body -->
</div>
