<form name="form" class="renewal-option-form"
      (submit)="onSaveTerm($event)" *ngIf="isVisibleTermForm()">
  <div class="row">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container">
      <div class="col-sm-11 mb-1 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" itemTemplate="radio"
                          [dataSource]="options"
                          [(value)]="leaseTerm.renewalOptionTermType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Please select one of the provided options before saving"></dxi-validation-rule>
            </dx-validator>

            <div *dxTemplate="let data of 'radio'; let index = index">
              <ng-container *ngIf="data.value === RenewalOptionTermType.PerCurrentLease || data.value === RenewalOptionTermType.None || data.value === RenewalOptionTermType.Custom">
                <div class="d-flex flex-column">
                  <p>{{ data.name }}</p>
                </div>
              </ng-container>

              <ng-container *ngIf="data.value === RenewalOptionTermType.RenewalOptionValues">
                <div class="d-flex flex-column"
                     *ngIf="getOptionForTermType(RenewalOptionTermType.RenewalOptionValues) as leaseTermOption">
                  <div class="insert-row">
                    <span>Tenant shall have</span>
                    <dx-number-box appIgnoreScrollwheel id="RenewalOptionValues_renewalOptionsCount"
                                   name="RenewalOptionValues_renewalOptionsCount" class="real-estate-input py-0 rounded-10"
                                   placeholder="(e.g. 1)" format="#0" min="0" width="75px"
                                   [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValues"
                                   [(value)]="leaseTermOption.leaseTerm.renewalOptionsCount"
                                   (valueChange)="clearErrors()">
                      <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                    <span>, </span>
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValues_renewalOptionYearsCount'" [placeholder]="'(e.g. 3)'"
                                     [className]="'mb-0 py-0'" [rounded]="true" [name]="'RenewalOptionValues_renewalOptionYearsCount'"
                                     [format]="'#0'" [width]="'67px'" [min]="0"
                                     [errorMessage]="'How many years for options is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValues"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionYearsCount" [suffix]="'year(s)'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionYearsCount = $event; clearErrors()"
                    ></app-input-field>

                    <span>renewal option(s), upon</span>
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValues_renewalOptionHowManyMonthsNotice'" [placeholder]="'(e.g. 6)'"
                                     [className]="'real-estate-input mb-0 py-0'" [name]="'RenewalOptionValues_renewalOptionHowManyMonthsNotice'"
                                     [min]="0" [format]="'#0'" [width]="'67px'"
                                     [errorMessage]="'How many months notice is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValues"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice" [suffix]="'month(s)'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice = $event; clearErrors()"
                    ></app-input-field>
                  </div>
                  <span>notice at the then Fair Market Value</span>
                </div>
              </ng-container>

              <ng-container *ngIf="data.value === RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent">
                <div class="d-flex flex-column"
                     *ngIf="getOptionForTermType(RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent) as leaseTermOption">
                  <div class="insert-row">
                    <span>Tenant shall have</span>
                    <dx-number-box appIgnoreScrollwheel id="RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionsCount"
                                   name="RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionsCount"
                                   placeholder="(e.g. 1)" format="#0" min="0" class="real-estate-input py-0" width="75px"
                                   [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent"
                                   [(value)]="leaseTermOption.leaseTerm.renewalOptionsCount"
                                   (valueChange)="clearErrors()">
                      <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                    <span>, </span>
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionYearsCount'" [placeholder]="'(e.g. 3)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionYearsCount'"
                                     [format]="'#0'" [width]="'67px'" [min]="0"
                                     [errorMessage]="'How many years for options is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionYearsCount" [suffix]="'year'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionYearsCount = $event; clearErrors()"
                    ></app-input-field>
                    <span>year renewal option(s), upon</span>
                  </div>
                  <div class="insert-row my-1">
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionHowManyMonthsNotice'" [placeholder]="'(e.g 6)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionHowManyMonthsNotice'"
                                     [format]="'#0'" [width]="'67px'" [min]="0"
                                     [errorMessage]="'How many months notice is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice" [suffix]="'month(s)'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice = $event; clearErrors()"
                    ></app-input-field>
                    <span>notice at the then Fair Market Value but in no event greater than</span>
                  </div>
                  <div class="insert-row mb-1">
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionMaxRentPercentage'" [placeholder]="'(e.g. 6)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent_renewalOptionMaxRentPercentage'"
                                     [format]="'#0.##'" [width]="'67px'" [min]="0"
                                     [errorMessage]="'Max rent percentage is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionMaxRentPercentage" [suffix]="'%'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionMaxRentPercentage = $event; clearErrors()"
                    ></app-input-field>
                    <span>of the rent in the expiration year.</span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="data.value === RenewalOptionTermType.RenewalOptionValuesExtended">
                <div class="d-flex flex-column"
                     *ngIf="getOptionForTermType(RenewalOptionTermType.RenewalOptionValuesExtended) as leaseTermOption">
                  <div class="insert-row mb-1">
                    <span>Tenant shall have</span>
                    <dx-number-box appIgnoreScrollwheel id="RenewalOptionValuesExtended_renewalOptionsCount"
                                   name="RenewalOptionValuesExtended_renewalOptionsCount" class="real-estate-input py-0"
                                   placeholder="(e.g. 1)" format="#0" min="0" width="75px"
                                   [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtended"
                                   [(value)]="leaseTermOption.leaseTerm.renewalOptionsCount"
                                   (valueChange)="clearErrors()">
                      <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                    <span>, </span>
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtended_renewalOptionYearsCount'" [placeholder]="'(e.g. 3)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtended_renewalOptionYearsCount'"
                                     [format]="'#0'" [width]="'67px'" [min]="0"
                                     [errorMessage]="'How many years for options is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtended"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionYearsCount" [suffix]="'year'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionYearsCount = $event; clearErrors()"
                    ></app-input-field>
                    <span>year renewal option(s),  no sooner than</span>
                  </div>

                  <div class="insert-row">
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtended_renewalOptionAvailableMonths'" [placeholder]="'(e.g. 6)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtended_renewalOptionAvailableMonths'"
                                     [format]="'#0'" [width]="'67px'" [min]="getMinRenewalOptionAvailableMonths()"
                                     [errorMessage]="'How many months for renewal option availability is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtended"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionAvailableMonths" [suffix]="'month(s)'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionAvailableMonths = $event; clearErrors()"
                    ></app-input-field>
                    <span>and no less than</span>
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtended_renewalOptionHowManyMonthsNotice'" [placeholder]="'(e.g. 6)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtended_renewalOptionHowManyMonthsNotice'"
                                     [format]="'#0'" [width]="'67px'"
                                     [min]="0" [max]="getMaxRenewalOptionHowManyMonthsNotice(leaseTermOption.leaseTerm)"
                                     [errorMessage]="'How many months notice is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtended"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice" [suffix]="'month(s)'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionHowManyMonthsNotice = $event; clearErrors()"
                    ></app-input-field>
                  </div>
                  <span>prior written notice. The rent shall be at the then Fair Market Value but in no event less than</span>
                  <div class="insert-row my-1">
                    <app-input-field appIgnoreScrollwheel
                                     [id]="'RenewalOptionValuesExtended_renewalOptionMinRentPercentage'" [placeholder]="'(e.g. 6)'"
                                     [className]="'mb-0 py-0'" [name]="'RenewalOptionValuesExtended_renewalOptionMinRentPercentage'"
                                     [format]="'#0.##'" [width]="'67px'" min="0"
                                     [errorMessage]="'Min rent percentage is required'"
                                     [disabled]="leaseTerm.renewalOptionTermType !== RenewalOptionTermType.RenewalOptionValuesExtended"
                                     [value]="leaseTermOption.leaseTerm.renewalOptionMinRentPercentage" [suffix]="'%'"
                                     (valueChange)="leaseTermOption.leaseTerm.renewalOptionMinRentPercentage = $event; clearErrors()"
                    ></app-input-field>

                    <span>of the rent in the expiration year.</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </dx-radio-group>
        </div>
      </div>
      <!--col-->
      <div class="col-sm-6"></div>
    </div>
    <!--row-->

    <ng-container *ngIf="leaseTerm?.renewalOptionTermType === RenewalOptionTermType.Custom">
      <div class="row"
           *ngIf="getOptionForTermType(RenewalOptionTermType.Custom) as leaseTermOption">
        <div class="col-6 pl-0 my-2">
          <app-term-custom-value-template-textarea placeholder="Insert custom renewal option clause"
                                                   required
                                                   requiredMessage="Custom Renewal Option clause is required"
                                                   [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                   [(value)]="leaseTermOption.leaseTerm.renewalOptionCustomText"
                                                   (valueChange)="clearErrors()">
          </app-term-custom-value-template-textarea>
        </div>
      </div>
    </ng-container>

      <!-- Start: Validation summary -->

          <div>
            <dx-validation-summary ngForm="form">
            </dx-validation-summary>
              <div class="term-toast term-toast-error"
                   *ngFor="let msg of errors">
                  <i inlineSVG="assets/img/x.svg"></i>
                  {{msg}}
              </div>
          </div>

      <!-- End: Validation summary -->


      <app-term-secondary-action-buttons
      [rejectButtonClicked]="rejectButtonClicked"
      (cancelLeaseTerm)="onCancelLeaseTerm()"
    ></app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
