import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable()
export class DocumentsService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getDocumentByLeaseId(leaseId: number): Observable<Array<models.IAttachedFileViewModel>> {
    return this._httpClient.get<Array<models.IAttachedFileViewModel>>(
      `${environment.webApiUrl}/documents?leaseId=${leaseId}`,
    );
  }

  deleteTenantFilesByFiles(files: Array<models.IAttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${environment.webApiUrl}/documents/Delete`,
        files,
      );
  }

  updateTenantFiles(files: Array<models.IAttachedFileViewModel>): Observable<any> {
    return this._httpClient
      .post(
        `${environment.webApiUrl}/documents/UpdateLeaseFiles`,
        files,
      );
  }

  shareLeaseFiles(files: Array<models.IAttachedFileViewModel>): Observable<Array<models.IAttachedFileViewModel>> {
    return this._httpClient
      .post<Array<models.IAttachedFileViewModel>>(
        `${environment.webApiUrl}/documents/ShareLeaseFiles`,
        files,
      );
  }

  getFileFolders(): Observable<Array<models.ITenantFileFolderViewModel>> {
    return this._httpClient.get<Array<models.ITenantFileFolderViewModel>>(
      `${environment.webApiUrl}/documents/fileFolders`
    );
  }

  getSummaryOfTerms(leaseId: number) {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    const clientUtcOffset = ((new Date()).getTimezoneOffset() / 60) * -1;

    params = params.set('clientUtcOffset', clientUtcOffset.toString(10));

    return this._httpClient
      .get(`${environment.webApiUrl}/documents/GetSummaryOfTerms`, {params: params, responseType: 'blob'});
  }
}
