import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsManager } from '@statera/sdk/analytics';
import { CommonTools } from '@statera/sdk/common';

import * as models from '../../../infrastructure/models/generated';
import {
  KeyStatisticsReportExportDialogComponent,
  KeyStatisticsReportExportType
} from '../key-statistics-report-export-dialog/key-statistics-report-export-dialog.component';
import { DialogService } from '../../../dialog/services/dialog.service';

@Component({
  selector: 'app-analytics-key-statistics-report',
  templateUrl: 'key-statistics-report.component.html',
  styleUrls: ['key-statistics-report.component.scss'],
})
export class KeyStatisticsReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() analyticsFilters: models.IAnalyticsFiltersViewModel;

  isLoaded: boolean;

  keyStatisticsReport: models.IKeyStatisticsReportViewModel;

  private readonly _analyticsManager: AnalyticsManager;
  private readonly _dialogService: DialogService;
  private readonly _destroy$: Subject<void>;

  KeyStatisticsReportExportType = KeyStatisticsReportExportType;

  constructor(analyticsManager: AnalyticsManager, dialogService: DialogService) {
    this._analyticsManager = analyticsManager;
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.analyticsFilters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.analyticsFilters &&
      (
        changes.analyticsFilters.isFirstChange() ||
        (
          (changes.analyticsFilters.previousValue && changes.analyticsFilters.currentValue) &&
          !CommonTools.isEqual(changes.analyticsFilters.previousValue, changes.analyticsFilters.currentValue)
        )
      )
    ) {
      this._configureReport();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _configureReport(): void {
    this.isLoaded = false;

    this._requestKeyStatisticsReport()
      .pipe(
        tap(keyStatisticsReport => {
          this.keyStatisticsReport = keyStatisticsReport;
        }),
        tap(() => this.isLoaded = true),
        catchError(err => {
          this.isLoaded = true;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

   openExportDialog(reportType: KeyStatisticsReportExportType): void {
    const width = 632;
    let height = 700;
    if (reportType === KeyStatisticsReportExportType.Inquiries) {
      height = 585;
    }
    if (reportType === KeyStatisticsReportExportType.Basic) {
      height = 305;
    }

    this._dialogService.show(KeyStatisticsReportExportDialogComponent, {
      width: width,
      height: height,
      injectableData: {
        reportType: reportType,
      },
    });
  }

  private _requestKeyStatisticsReport(): Observable<models.IKeyStatisticsReportViewModel> {
    const filters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    return this._analyticsManager
      .getKeyStatisticsReport(filters);
  }
}
