import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseService } from '../../../shared/services/lease.service';
import { QuizService } from '../../../shared/services/quiz.service';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import { QuestionType, ProjectRenewalRejectionStatus } from '../../../infrastructure/models/generated';

enum LeaseCancellationReasons {
  DealEconomics = 'Deal economics',
  TooEarly = 'Push back',
  ProceedingWithAnotherTenant = 'Proceeding with another tenant',
}

@Component({
  selector: 'app-landlord-lease-cancellation',
  templateUrl: './lease-landlord-cancellation.component.html',
  styleUrls: ['./lease-landlord-cancellation.component.scss']
})
export class LeaseLandlordCancellationComponent implements OnDestroy {
  @Input()
  leaseId: number;
  @Input()
  buildingAddress: string;
  reasons = ProjectRenewalRejectionStatus;

  comment = '';
  dateToRevisit = null;

  private readonly _formBuilder: FormBuilder;

  destroy$ = new Subject();
  questionTypes = QuestionType;
  options = [
    {id: ProjectRenewalRejectionStatus.Economics, text: LeaseCancellationReasons.DealEconomics},
    {id: ProjectRenewalRejectionStatus.TooEarly, text: LeaseCancellationReasons.TooEarly},
    {
      id: ProjectRenewalRejectionStatus.OtherTenantInterestedIn,
      text: LeaseCancellationReasons.ProceedingWithAnotherTenant
    }
  ];
  reasonId = this.options[0].id;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _quizService: QuizService;
  private readonly _leaseService: LeaseService;
  private readonly _destroy$ = new Subject<void>();
  private readonly _dialogRefService: DialogRefService;

  constructor(
    quizService: QuizService,
    formBuilder: FormBuilder,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    leaseService: LeaseService,
    dialogRefService: DialogRefService,
  ) {
    this._quizService = quizService;
    this._formBuilder = formBuilder;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._leaseService = leaseService;
    this._destroy$ = new Subject<void>();
    this._dialogRefService = dialogRefService;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isDateDisabled(model: {date: Date}): boolean {
    if (!model || !model.date) {
      return true;
    }

    const today = moment().startOf('day');
    const date = moment(model.date).startOf('day');

    if (today.isSame(date)) {
      return false;
    }

    return today.isAfter(date);
  }

  submitForm(form) {
    if (form.invalid) {
      return;
    }

    this._dialogRefService.outputData = true;
    this._dialogRefService.hide();

    this._leaseService.cancelRequestViaLandlordWizard({
      leaseId: this.leaseId,
      comment: this.comment,
      projectRenewalRejectionStatus: this.reasonId,
      dateToRevisit: this.dateToRevisit
        ? moment(this.dateToRevisit).startOf('day').toDate()
        : undefined
    }).subscribe(() => {
      this._alertService.pushSuccessAlert({
        message: this._alertMessagesManager.getLandlordCancelSurveyAdditionalInfoProvidedAlertText(),
      });
    });
  }

  close() {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCancelDeadByLandlordAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this._dialogRefService.outputData = false;
        this._dialogRefService.hide();
      });
  }
}
