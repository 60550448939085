import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap} from 'rxjs/operators';
import { StateraUserClaimService } from '../../auth/services/statera-user-claim.service';


@Injectable()

export class HttpClientHeadersInterceptor  implements HttpInterceptor {

  private readonly _stateraUserClaimService: StateraUserClaimService;

  constructor(private stateraUserClaimService: StateraUserClaimService) {
    this._stateraUserClaimService = stateraUserClaimService;
  }

  // intercept request and read headers
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse && event.ok) {
            this._stateraUserClaimService.checkIfStateraUserClaimsWasUpdated(event);
          }
        })
      );
  }
}
