import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
  async: boolean;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private _scripts: Array<ScriptModel> = [];

  load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this._scripts.find(s => s.name === script.name);

      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        this._scripts = [...this._scripts, script];

        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;

        if (script.id) {
          scriptElement.id = script.id;
        }

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error('Couldn\'t load script ' + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}
