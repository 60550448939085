import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SquareFeetRangeFilter } from '@statera/sdk/analytics-filters';

import { SafeNumberPipe } from '../../../infrastructure/pipes/safe-number.pipe';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-square-feet',
  templateUrl: 'analytics-filters-square-feet.component.html',
  styleUrls: ['analytics-filters-square-feet.component.scss'],
})
export class AnalyticsFiltersSquareFeetComponent implements OnChanges {
  @Input() squareFeetRange: analyticsFiltersModels.SquareFeetRangeFilter;

  @Output() squareFeetRangeChange: EventEmitter<analyticsFiltersModels.SquareFeetRangeFilter>;

  squareFeetRangeValue: [number, number];

  rangeSelectorFontProperties = {
    size: 12,
    weight: 600,
    family: '"Avenir Next Cyr", sans-serif',
  };

  private readonly _safeNumberPipe: SafeNumberPipe;

  constructor(safeNumberPipe: SafeNumberPipe) {
    this._safeNumberPipe = safeNumberPipe;

    this.squareFeetRangeChange = new EventEmitter<analyticsFiltersModels.SquareFeetRangeFilter>();

    this.customizeSquareFeetText = this.customizeSquareFeetText.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.squareFeetRange &&
      (
        changes.squareFeetRange.isFirstChange() ||
        (
          !changes.squareFeetRange.previousValue ||
          !changes.squareFeetRange.currentValue
        ) ||
        (
          changes.squareFeetRange.previousValue.min !== changes.squareFeetRange.currentValue.min ||
          changes.squareFeetRange.previousValue.max !== changes.squareFeetRange.currentValue.max
        )
      )
    ) {
      this.squareFeetRangeValue = [this.squareFeetRange.min, this.squareFeetRange.max];
    }
  }

  handleSquareFeetSelection(squareFeetRangeValue: [number, number]): void {
    if (!squareFeetRangeValue) {
      return;
    }

    const boundaries = this.squareFeetRange.boundaries;
    const min = this._applySquareFeetRangeBoundaries(squareFeetRangeValue[0] || boundaries.min);
    const max = this._applySquareFeetRangeBoundaries(squareFeetRangeValue[1] || boundaries.max);

    this.squareFeetRangeChange
      .next({
        ...this.squareFeetRange,
        min,
        max,
      });
  }

  handleSquareFeetMinChange(value: number, element: HTMLInputElement): void {
    if (value < this.squareFeetRange.boundaries.min) {
      return;
    }

    this.squareFeetRangeValue[0] = this._applySquareFeetRangeBoundaries(value || this.squareFeetRange.boundaries.min);

    let [min, max] = this.squareFeetRangeValue;
    if (max < min) {
      [min, max] = [max, min];
    }

    this.squareFeetRangeValue = [min, max];

    element.value = min.toString(10);

    this.handleSquareFeetSelection(this.squareFeetRangeValue);
  }

  handleSquareFeetMaxChange(value: number, element: HTMLInputElement): void {
    if (value < this.squareFeetRange.boundaries.min) {
      return;
    }

    this.squareFeetRangeValue[1] = this._applySquareFeetRangeBoundaries(value || this.squareFeetRange.boundaries.max);

    let [min, max] = this.squareFeetRangeValue;
    if (max < min) {
      [min, max] = [max, min];
    }

    this.squareFeetRangeValue = [min, max];

    element.value = max.toString(10);

    this.handleSquareFeetSelection(this.squareFeetRangeValue);
  }

  customizeSquareFeetText(event: { value: number }): string {
    if (!event || !event.value) {
      return '0 SF';
    }

    return `${this._safeNumberPipe.transform(event.value, '1.0-2')} SF`;
  }

  private _applySquareFeetRangeBoundaries(value: number): number {
    if (!value) {
      return 0;
    }

    if (value <= this.squareFeetRange.boundaries.min) {
      value = this.squareFeetRange.boundaries.min;
    }

    if (this.squareFeetRange.boundaries.max <= value) {
      value = this.squareFeetRange.boundaries.max;
    }

    return value;
  }
}
