<div class="title">
  <h3 class="title-text">
    <span>Cancelled deal</span>
    <img src="assets/img/survey.png" alt="Survey"/>
  </h3>

  <a role="button" class="close-button"
     (click)="close()">
    <img src="assets/img/close.svg" alt="Close"/>
  </a>
</div>

<dx-scroll-view class="scroll-window"
                #scrollView>
  <div class="body">
    <p>
      Sorry to hear that you {{getSorryMessageText()}} at {{buildingAddress}} 😔 In order to finalize this
      request, please let us know the reason for cancelling and
      know what you require, as we may have other options that fit your needs.
    </p>
    <p>
      Check all boxes that apply and comment, if necessary
    </p>

    <form class="cancel-survey"
          [formGroup]="form"
          (ngSubmit)="submitForm(submitBtn)"
          #ngForm="ngForm">

      <ng-container *ngFor="let group of getQuestionGroups(); let i = index">
        <ng-container [ngSwitch]="i">
          <h4 *ngSwitchCase="0">Space Challenges</h4>
          <h4 *ngSwitchCase="1">Location Challenges</h4>
        </ng-container>

        <ng-container *ngFor="let question of group.questions">
          <div class="cancel-survey-field">
            <div class="cancel-survey-field-label">
              <app-radio-button [checked]="groupIdValue[group.id] === question.id.toString()"
                                (clicked)="onQuestionGroupValueChanged(group.id, question)">
              </app-radio-button>

              <span class="cancel-survey-field-label-caption">{{question.text}}</span>

              <ng-container *ngIf="question.tooltip">
                <span class="icon-info ml-1" #tooltipAnchor></span>
                <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend"
                            position="top" contentTemplate="content"
                            [target]="tooltipAnchor">
                  {{question.tooltip}}
                </dx-tooltip>
              </ng-container>
            </div>`
            <ng-container *ngTemplateOutlet="questionBody; context: { $implicit: question }">
            </ng-container>`
          </div>
        </ng-container>
      </ng-container>

      <h4 *ngIf="getIndependentQuestionsInForm()">Other</h4>

      <div class="cancel-survey-field"
           *ngFor="let question of getIndependentQuestionsInForm()">
        <div class="cancel-survey-field-label"
             [class.flex-100]="question.text == 'Comment'">
          <dx-check-box *ngIf="question.text != 'Comment'"
                        [formControl]="form.controls[question.id.toString()] | formControl"
                        (onValueChanged)="onChecked($event.value, question.id.toString())">
          </dx-check-box>

          <span class="cancel-survey-field-label-caption">{{question.text}}</span>

          <!--<ng-container *ngIf="question.tooltip">
            <span class="icon-info ml-1" #tooltipAnchor></span>
            <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend"
                        position="top" contentTemplate="content"
                        [target]="tooltipAnchor">
              {{question.tooltip}}
            </dx-tooltip>
          </ng-container>-->
        </div>
        <ng-container *ngTemplateOutlet="questionBody; context: { $implicit: question }">
        </ng-container>
      </div>

      <div class="my-1 invalid-feedback d-block" *ngIf="ngForm.submitted && ngForm.hasError('isEmpty')">
        Please complete the form.
      </div>

      <div class="cancel-survey-button-block">
        <dx-button class="btn btn-primary cancel-survey-button" text="Submit"
                   [useSubmitBehavior]="true"
                   #submitBtn>
        </dx-button>
      </div>
    </form>
  </div>
</dx-scroll-view>

<ng-template #questionBody let-question>
  <div class="cancel-survey-field-value"
       [class.flex-100]="question.text == 'Comment'">
    <ng-container *ngIf="question.tooltip">
      <span class="icon-info my-auto" #tooltipAnchor></span>
      <dx-tooltip showEvent="dxhoverstart" hideEvent="dxhoverend"
                  position="top" contentTemplate="content"
                  [target]="tooltipAnchor">
        {{question.tooltip}}
      </dx-tooltip>
    </ng-container>
    <ng-container *ngFor="let childQuestion of getChildrenQuestionsInForm(question.id.toString())">
      <div>
        <ng-container [ngSwitch]="childQuestion.questionType">
          <dx-number-box format="#,##0.## SF"
                         *ngSwitchCase="questionTypes.SqftNumber"
                         min="0" step="0"
                         [formControl]="form.controls[childQuestion.id.toString()] | formControl"
                         [placeholder]="childQuestion.text"
                         [isValid]="isControlValid(ngForm, childQuestion.id.toString())">
          </dx-number-box>

          <dx-number-box appIgnoreScrollwheel format="#,##0.##"
                         *ngSwitchCase="questionTypes.Number"
                         min="0" step="1"
                         [formControl]="form.controls[childQuestion.id.toString()] | formControl"
                         [placeholder]="childQuestion.text"
                         [isValid]="isControlValid(ngForm, childQuestion.id.toString())">
          </dx-number-box>

          <dx-number-box format="#,##0.## $"
                         *ngSwitchCase="questionTypes.DollarNumber"
                         min="0" step="0"
                         [formControl]="form.controls[childQuestion.id.toString()] | formControl"
                         [placeholder]="childQuestion.text"
                         [isValid]="isControlValid(ngForm, childQuestion.id.toString())">
          </dx-number-box>

          <dx-text-box *ngSwitchCase="questionTypes.Text"
                       [formControl]="form.controls[childQuestion.id.toString()] | formControl"
                       [placeholder]="childQuestion.text"
                       [isValid]="isControlValid(ngForm, childQuestion.id.toString())">
          </dx-text-box>

          <dx-select-box displayExpr="text" valueExpr="id"
                         *ngSwitchCase="questionTypes.Choice"
                         [formControl]="form.controls[childQuestion.id.toString()] | formControl"
                         [dataSource]="childQuestion.options"
                         [isValid]="isControlValid(ngForm, childQuestion.id.toString())">
          </dx-select-box>
        </ng-container>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="!isControlValid(ngForm, childQuestion.id.toString()) && form.controls[childQuestion.id.toString()].hasError('required')">
          {{childQuestion.text || question.text}} is required
        </div>
      </div>
    </ng-container>

    <dx-text-area width="100%" height="90px"
                  *ngIf="question.text == 'Comment'"
                  [placeholder]="question.text"
                  [formControl]="form.controls[question.id.toString()] | formControl">
    </dx-text-area>
  </div>
</ng-template>
