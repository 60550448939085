<div id="floorPlan" class="floor-plan-demo">
  <div class="site-plan-header">
    <div class="left-side">
      <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#floorPlaneScheme"></i>
      <div class="site-plan-header-title">
        Floor Plan: Add Tenant Improvements
      </div>
    </div>
    <!-- Start: Close button -->
    <div class="close-floor-plan">
      <a role="button" href="javascript:void(0)"
         (click)="close()">
        <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#closeFloorPlan"></i>
      </a>
    </div>

    <!-- End: Close button -->
  </div>
  <div class="plan-container">
    <div class="canvas"
         #floorPlanContainerElement>
      <dx-tabs class="col-chat-tabs" itemTemplate="tabTemplate"
               [items]="tabs" [(selectedIndex)]="selectedTabIndex"
               (onSelectionChanged)="tabsChanged()"
      >
        <div *dxTemplate="let itemData of 'tabTemplate'">
          <span class="tab-title">{{ itemData.text }}</span>
        </div>
      </dx-tabs>

      <!-- Start: Floor Plan -->
      <ng-container *ngIf="selectedTabIndex === 0">
        <!-- Start: Tutorial -->
        <ng-container *ngIf="floorPlans.length">
          <button type="button" class="reset-intro"
                  (click)="resetDemo()"
                  #resetIntroElementRef>
            <span>
              Play Tutorial
            </span>
          </button>

          <dx-tooltip position="top" width="250px" showEvent="dxhoverstart" hideEvent="dxhoverend"
                      [target]="resetIntroElementRef">
            <p>Click here to replay Floor Plan Tutorial.</p>
          </dx-tooltip>
        </ng-container>
        <!-- End: Tutorial -->

        <!-- Start: Select Plan -->
        <div class="plan-select"
             *ngIf="floorPlans.length">
          <dx-select-box id="id"
                         displayExpr="plan.name"
                         [items]="floorPlans" [value]="selectedFloorPlan"
                         (onSelectionChanged)="onLeasePlanSelected($event)">
          </dx-select-box>
        </div>
        <!-- End: Select Plan -->

        <ng-container *ngIf="getFloorPlanImage() as floorPlanImage">
          <app-plan-viewer [image]="floorPlanImage" [scale]="floorPlanImageScale" [isDragEnabled]="true"
                           [allowChangeMarkers]="allowChangeMarkers()"
                           [width]="floorPlanContainerElement.offsetWidth"
                           [height]="floorPlanContainerElement.offsetHeight"
                           [markerColorChange]="markerColorChange$"
                           [markerCreationEnd]="markerCreationEnd$" [markerDeletionEnd]="markerDeletionEnd$" [markerChangingEnd]="markerChangingEnd$"
                           [selectedMarkerId]="selectedAnchorId" [suggestions]="getSuggestions()"
                           (scaleChange)="handleScaleChange($event)"
                           (selectedMarkerIdChange)="handlePlanMarkerSelect($event)"
                           (markerCreated)="handlePlanMarkerCreate($event)"
                           (markerChanged)="handlePlanMarkerChange($event)"
                           (markerDeleted)="handlePlanMarkerDelete($event)"
                           (imageMarkerCreated)="handleImageMarkerCreate($event)"
                           (imageMarkerChanged)="handleImageMarkerChange($event)"
                           (imageMarkerDeleted)="handleImageMarkerDelete($event)"
                           (planChanged)="handlePlanChange($event)"
                           (markerCommentAdded)="handleAnchorCommentAdded($event)"
                           (markerCommentChanged)="handleAnchorCommentChanged($event)"
                           (costChanged)="onAmountChanged($event)"
                           (statusChanged)="changeStatus($event)"
                           [mode]="PlanViewerMode.Marker"
          >
          </app-plan-viewer>
        </ng-container>
      </ng-container>
      <!-- End: Floor Plan -->


      <!-- Start: Plan viewer & select -->
      <ng-container *ngIf="selectedTabIndex === 1">
        <!-- Start: No plans placeholder -->
        <div *ngIf="!sitePlans.length">
          No plans available yet.
        </div>
        <!-- End: No plans placeholder -->
        <!-- Start: Plan viewer -->
        <app-plan-viewer *ngIf="sitePlans.length"
                         [image]="sitePlanImage"
                         [allowChangeMarkers]="false" [isDragEnabled]="true"
                         [width]="floorPlanContainerElement.offsetWidth"
                         [height]="floorPlanContainerElement.offsetHeight"
                         [mode]="PlanViewerMode.Unit"
        >
        </app-plan-viewer>
        <!-- End: Plan viewer -->
        <!-- Start: Plan select -->
        <div class="plan-select"
             *ngIf="sitePlans.length">
          <dx-select-box name="plan"
                         displayExpr="name"
                         [items]="sitePlans" [(ngModel)]="selectedSitePlan"
                         #planSelect="ngModel">
          </dx-select-box>
        </div>
        <!-- End: Plan select -->
      </ng-container>
      <!-- End: Plan viewer & select -->

    </div>

    <div *ngIf="selectedTabIndex === 0" class="comments-container">
      <div class="b-card plan-demo-tenant-improvements">

        <div class="improvements_header">
          <h3 class="tenant-improvements_title">
            Tenant Improvements
          </h3>
          <span class="tenant-improvements_text">
            In this module you can mark up the Improvements, add detailed descriptions and costs for reference.
          </span>
        </div>

        <!-- Start: TI tab contents -->
        <div *ngIf="anchors.length; else emptyAnchorList" class="tab-pad">
          <div class="anchor-list-container">
            <ng-container *ngTemplateOutlet="anchorList; context: {templateAnchors: notRejectedAnchors}"></ng-container>
            <ng-container *ngIf="rejectedAnchors && rejectedAnchors.length">
              <div class="rejected-improvements_text">
              <span>
                  Rejected Improvements
              </span>
              </div>
              <ng-container *ngTemplateOutlet="anchorList; context: { templateAnchors: rejectedAnchors, anchorStatus: AnchorStatus.Rejected }"></ng-container>
            </ng-container>
          </div>
          <div *ngIf="getTotalAmountCosts() as totalCost;" class="total-cost-amount">
            <span class="total-cost_title">Total cost of improvements:</span>
            <span *ngIf="totalCost.sum; else emptyTotalCost" class="total-sum-cost">{{totalCost.sum | currency}}</span>
            <ng-template #emptyTotalCost>
              <span>???</span>
            </ng-template>
          </div>
        </div>
        <ng-template #emptyAnchorList>
          <div class="no-improvements">
            <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#no-improvements"></i>
            <span class="no-improvements_title">No Improvements Added</span>
            <span class="no-improvements_text">
            Add the proposed Tenant Improvements to the Floor Plan using one of the tools in the Tool Panel.
            </span>
            <button type="button" class="reset-intro"
                    (click)="resetDemo()">

              <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#tutorial"></i>
            <span>
              Tutorial
            </span>
            </button>
          </div>
        </ng-template>
        <ng-template #anchorList let-templateAnchors="templateAnchors" let-anchorStatus="anchorStatus">
          <dx-list class="anchor-list"
                   [items]="templateAnchors" [focusStateEnabled]="false" [activeStateEnabled]="false">
            <div *dxTemplate="let item of 'item'; let index=index;">
              <div class="anchor-list-item" [ngClass]="{ 'anchor-list-item-selected': item.id === selectedAnchorId }" (click)="handleSelectedAnchorChange(item.id)">
                <div class="anchor-list-item_header">
                  <div class="anchor-list-item-index" [ngClass]="{ 'anchor-list-item-index-rejected': anchorStatus === AnchorStatus.Rejected }">{{index + 1}}</div>
                  <div *ngIf="item.amount; else emptyAmount" class="anchor-list-item-cost" [ngClass]="{ 'anchor-list-item-cost-rejected': anchorStatus === AnchorStatus.Rejected }">
                    {{item.amount | currency}}
                  </div>
                  <ng-template #emptyAmount>
                    <div class="anchor-list-item-cost">
                      ???
                    </div>
                  </ng-template>
                </div>

                <div>
                  <div class="anchor-list-item-title" [ngClass]="{ 'anchor-list-item-title-rejected': anchorStatus === AnchorStatus.Rejected }">
                    {{item.name}}
                  </div>
                </div>

                <div class="anchor-list-item_footer">
                  <div *ngIf="getBuildingArea(item.buildingArea) as buildingArea" class="anchor-list-item-area">
                    <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#interior-office"></i>
                    {{buildingArea}}
                  </div>
                  <div class="anchor-list-item-comments">
                    {{item.comments.length}} comments
                  </div>
                  <div *ngIf="getLastCommentDate(item) as lastCommentDate" class="anchor-list-item-commentdate">
                    {{lastCommentDate | timeAgo}}
                  </div>
                </div>
              </div>
            </div>
          </dx-list>
        </ng-template>
        <!-- End: TI tab contents -->
      </div>
    </div>
  </div>
</div>
