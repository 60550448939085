import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { FileUploadManager } from './file-upload.manager';

@NgModule({
  imports: [
    AngularCommonModule,
  ],
  providers: [
    FileUploadManager,
  ],
})
export class InfrastructureModule {
  static forChild(): ModuleWithProviders<InfrastructureModule> {
    return {
      ngModule: InfrastructureModule,
      providers: [
        FileUploadManager,
      ],
    };
  }
}
