import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { AnalyticsFiltersRepository } from './analytics-filters.repository';
import { AnalyticsFiltersManager } from './analytics-filters.manager';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AnalyticsFiltersRepository,
    AnalyticsFiltersManager,
  ],
})
export class AnalyticsFiltersModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AnalyticsFiltersModule> {
    return {
      ngModule: AnalyticsFiltersModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AnalyticsFiltersRepository,
        AnalyticsFiltersManager,
      ],
    };
  }
}
