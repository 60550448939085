import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  IComparisonReportOptionViewModel,
  IComparisonReportSettingViewModel,
  IComparisonReportViewModel,
  ILeaseAnalysisLandlordPerspectiveResult,
  ILeaseAnalysisOptionsViewModel,
  ILeaseAnalysisTenantPerspectiveResult
} from '@statera/sdk/common';
import {Observable} from 'rxjs';

@Injectable()
export class LeaseAnalysisRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getComparisonReport(model: IComparisonReportOptionViewModel): Observable<IComparisonReportViewModel> {
    return this._httpClient.post<IComparisonReportViewModel>(
      `${this._configuration.apiEndpoint}/lease-analysis/comparison-report`,
      model,
    );
  }

  changeComparisonReportSettings(model: IComparisonReportSettingViewModel) {
    return this._httpClient.post<IComparisonReportSettingViewModel>(
      `${this._configuration.apiEndpoint}/lease-analysis/comparison-report-settings`,
      model,
    );
  }

  getComparisonReportSettings(leaseId: number): Observable<IComparisonReportSettingViewModel> {
    let params = new HttpParams();
    params = params.set('leaseId', leaseId.toString());
    return this._httpClient.get<IComparisonReportSettingViewModel>(
      `${this._configuration.apiEndpoint}/lease-analysis/comparison-report-settings`, {
        params
      });
  }


  getTenantLeaseAnalysisData(model: ILeaseAnalysisOptionsViewModel): Observable<ILeaseAnalysisTenantPerspectiveResult> {
    return this._httpClient.post<ILeaseAnalysisTenantPerspectiveResult>(
      `${this._configuration.apiEndpoint}/lease-analysis/tenant-report`,
      model,
    );
  }

  getLandlordLeaseAnalysisData(model: ILeaseAnalysisOptionsViewModel): Observable<ILeaseAnalysisLandlordPerspectiveResult> {
    return this._httpClient.post<ILeaseAnalysisLandlordPerspectiveResult>(
      `${this._configuration.apiEndpoint}/lease-analysis/landlord-report`,
      model,
    );
  }


}
