import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Role } from '@statera/sdk/common';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { CertificateOfInsuranceManager } from '@statera/sdk/certificate-of-insurance';

import { environment } from '../../../../environments/environment';

import * as models from '../../../infrastructure/models/generated';

import { InsuranceCertificateProjectTemplateItemType } from '../../../infrastructure/models/generated';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { DocumentViewerService } from '../../../document-viewer/services/document-viewer.service';
import { ProjectAccessService } from '../../../shared/services/project-access.service';
import { ProjectService } from '../../../shared/services/project.service';
import { TermsPageService } from '../../services/terms-page.service';

@Component({
  selector: 'app-certificate-of-insurance',
  templateUrl: './certificate-of-insurance.component.html',
  styleUrls: ['./certificate-of-insurance.component.scss']
})
export class CertificateOfInsuranceComponent implements OnInit, AfterViewInit, OnDestroy {
  private _currentProjectState: models.IProjectTemplateItemViewModel;
  private _lastUploadedFile: models.IInsuranceCertificateFileViewModel;

  @Input() project: models.IProjectViewModel;
  @Input() lease: models.ILeaseViewModel;
  @Input() isNewDealProject: boolean;

  insuranceCertificate: models.IInsuranceCertificateViewModel;
  uploaderOptions: any;
  insuranceCertificateFiles: Array<models.IInsuranceCertificateFileViewModel>;
  isNonCompliant = false;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  sendForReviewButtonDisabled = false;

  get isGeneralLiabilityTotalsRequired(): boolean {
    return this._certificateOfInsuranceManager.isGeneralLiabilityTotalsRequired(this.insuranceCertificate);
  }

  get isAutoLiabilityTotalsRequired(): boolean {
    return this._certificateOfInsuranceManager.isAutoLiabilityTotalsRequired(this.insuranceCertificate);
  }

  get isUmbrellaLiabilityTotalsRequired(): boolean {
    return this._certificateOfInsuranceManager.isUmbrellaLiabilityTotalsRequired(this.insuranceCertificate);
  }

  get isWorkersCompensationTotalsRequired(): boolean {
    return this._certificateOfInsuranceManager.isWorkersCompensationTotalsRequired(this.insuranceCertificate);
  }

  private readonly _authService: AuthService;
  private readonly _projectService: ProjectService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _documentViewerService: DocumentViewerService;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _certificateOfInsuranceManager: CertificateOfInsuranceManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    projectService: ProjectService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
    documentViewerService: DocumentViewerService,
    projectAccessService: ProjectAccessService,
    certificateOfInsuranceManager: CertificateOfInsuranceManager,
  ) {
    this._authService = authService;
    this._projectService = projectService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._documentViewerService = documentViewerService;
    this._projectAccessService = projectAccessService;
    this._certificateOfInsuranceManager = certificateOfInsuranceManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    if (this.lease) {
      this._certificateOfInsuranceManager
        .getCertificateOfInsuranceByLeaseId(this.lease.id)
        .pipe(
          tap(insuranceCertificate => {
            if (insuranceCertificate) {
              this.insuranceCertificate = insuranceCertificate;

              if (insuranceCertificate.insuranceCertificateFiles) {
                this.insuranceCertificateFiles = insuranceCertificate.insuranceCertificateFiles;
              }
            } else {
              this.insuranceCertificate = <models.IInsuranceCertificateViewModel> {
                hasGeneralLiability: false,
                hasUmbrellaLiability: false,
                hasAutoLiability: false,
                hasWorkersCompensation: false,
                descriptionOfOperations: null,
                certificateHolder: null
              };
            }
          }),
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe();
    }

    const me = this;
    this.uploaderOptions = {
      name: 'file',
      uploadUrl: environment.webApiUrl + `/insuranceCertificate/file?leaseId=${this.lease.id}`,
      onUploadStarted: function () {
      },
      onUploaded: function (e) {
        me._lastUploadedFile = JSON.parse(e.request.responseText);

        if (!me.insuranceCertificateFiles) {
          me.insuranceCertificateFiles = [];
        }

        if (me._lastUploadedFile) {
          me.insuranceCertificateFiles.push(me._lastUploadedFile);
        }
        // hack for devexpress. Devexpress doesn't support delete uploaded document
        setTimeout(() => {
          const elements = document.getElementsByClassName('dx-fileuploader-files-container');

          if (elements) {
            for (let i = 0; i < elements.length; i++) {
              const element = elements[i];
              element.classList.add('dx-state-invisible');
            }
          }
        }, 500);
      },
      onUploadError: function (e) {
        me._alertService.pushErrorAlert({
          message: e.error.responseText,
        });

        e.component.reset();
      },
      onUploadAborted: function (e) {
      },
      clearUploader: function (e) {
        this.uploader.instance.reset();
      },
      onValueChanged: function (e) {
        if (e && e.value && e.value.length === 0 && e.previousValue && e.previousValue.length > 0) {
          me._projectService.deleteCertificateOfInsuranceFile(me._lastUploadedFile.id, me.lease.id).subscribe();
        }
      }
    };
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  shouldShowPleaseProvideAnyDetailsText(): boolean {
    const projectTemplateItemType = this._currentProjectState.insuranceCertificateProjectTemplateItemType;

    return (
      this._authService.role === Role.Landlord &&
      (
        projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep ||
        projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.LandlordStep
      )
    );
  }

  shouldShowSubmittedForReviewText(): boolean {
    const projectTemplateItemType = this._currentProjectState.insuranceCertificateProjectTemplateItemType;

    return (
      this._authService.role === Role.Landlord &&
      projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.TenantStep
    );
  }

  shouldShowPleaseUploadCOIText(): boolean {
    const projectTemplateItemType = this._currentProjectState.insuranceCertificateProjectTemplateItemType;

    return (
      this._authService.role === Role.Tenant &&
      projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.TenantStep
    );
  }

  submit(form: NgForm): void {
    if (!form || form.invalid) {
      return;
    }

    if (!this._currentProjectState) {
      return;
    }
    const commandParam = <models.IInsuranceCertificateParamsViewModel> {
      insuranceCertificateViewModel: this.insuranceCertificate,
      leaseViewModel: this.lease
    };
    let insuranceCertificateTriggerType: models.InsuranceCertificateTriggerType = null;
    const projectTemplateItemType = this._currentProjectState.insuranceCertificateProjectTemplateItemType;

    if (projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.LandlordStep) {
      if (!this._certificateOfInsuranceManager.isValidInsuranceCertificate(this.insuranceCertificate)) {
        return;
      }

      insuranceCertificateTriggerType = models.InsuranceCertificateTriggerType.DoLandlordStep;
    } else if (projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.TenantStep) {
      if (!this.insuranceCertificateFiles || this.insuranceCertificateFiles.length === 0) {
        this._alertService.pushErrorAlert({
          message: this._alertMessagesManager.getCertificateOfInsuranceFormNotFilledAlertText(),
        });

        return;
      }

      if (!this.insuranceCertificateFiles.find(x => x.availableForDeletingByTenant)) {
        this._alertService.pushErrorAlert({
          message: this._alertMessagesManager.getCertificateOfInsuranceFormNotFilledAlertText(),
        });

        return;
      }

      insuranceCertificateTriggerType = models.InsuranceCertificateTriggerType.DoTenantStep;
    } else if (projectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep) {
      if (!this._certificateOfInsuranceManager.isValidInsuranceCertificate(this.insuranceCertificate)) {
        return;
      }
      insuranceCertificateTriggerType = models.InsuranceCertificateTriggerType.DoLandlordFirstStep;
    }

    if (insuranceCertificateTriggerType === null) {
      return;
    }

    this.sendForReviewButtonDisabled = true;

    this._projectService
      .insuranceCertificateProject(commandParam, insuranceCertificateTriggerType)
      .pipe(
        tap(() => {
          this._dialogRefService.outputData = {isSaved: true};
          this._dialogRefService.hide();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.sendForReviewButtonDisabled = false);
  }

  isDisabledCertificateOfInsuranceValues(): boolean {
    return (
      !this._projectAccessService.checkAccessToCertificateOfInsurance(
        models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
        this.project,
        this.lease
      ) &&
      !this._projectAccessService.checkAccessToCertificateOfInsurance(
        models.InsuranceCertificateProjectTemplateItemType.LandlordStep,
        this.project,
        this.lease
      )
    );
  }

  checkAccessToWriteToInsuranceCertificateProject(): boolean {
    if (!this._currentProjectState) {
      return false;
    }

    if (
      this._projectAccessService.checkAccessToCertificateOfInsurance(
        models.InsuranceCertificateProjectTemplateItemType.LandlordStep,
        this.project,
        this.lease
      )
    ) {
      return this.isNonCompliant;
    }

    return this._projectAccessService.checkAccessToCertificateOfInsurance(
      this._currentProjectState.insuranceCertificateProjectTemplateItemType,
      this.project,
      this.lease
    );
  }

  isDisableCertificateOfInsuranceFile(): boolean {
    return !this._projectAccessService.checkAccessToCertificateOfInsurance(
      models.InsuranceCertificateProjectTemplateItemType.TenantStep,
      this.project,
      this.lease
    );
  }

  isShowCompliantAndNonCompliantButtons(): boolean {
    return this._projectAccessService.checkAccessToCertificateOfInsurance(
      models.InsuranceCertificateProjectTemplateItemType.LandlordStep,
      this.project,
      this.lease
    );
  }

  private _handleLandlordFirstStep() {
    this._alertService.pushInfoAlert({
      title: 'COI Info Request.',
      message: this._alertMessagesManager.getCertificateOfInsuranceRequestAlertText(),
    });
  }

  private _handleLandlordStep() {
  }

  cancel($event, button: HTMLButtonElement) {
    button.disabled = true;
    if (
      this._projectAccessService.checkAccessToCertificateOfInsurance(
        InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToCertificateOfInsurance(
        InsuranceCertificateProjectTemplateItemType.LandlordStep,
        this.project,
        this.lease
      )
    ) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmRejectUpdatedCertificateOfInsuranceAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            const commandParam = <models.IInsuranceCertificateParamsViewModel> {
              insuranceCertificateViewModel: null,
              leaseViewModel: this.lease
            };

            return this._projectService
              .insuranceCertificateProject(commandParam, models.InsuranceCertificateTriggerType.RejectUpdatedInsuranceCertificate)
              .pipe(
                tap(() => {
                  this._dialogRefService.outputData = {
                    isSaved: true
                  };

                  this._dialogRefService.hide();
                }),
              );
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe()
        .add(() => button.disabled = false);

      alertReference
        .declined
        .pipe(
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe()
        .add(() => button.disabled = false);
    } else {
      this._dialogRefService.hide();
      button.disabled = false;
    }
  }

  clickCompliantButton($event) {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCertificateOfInsuranceComplianceAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const commandParam = <models.IInsuranceCertificateParamsViewModel>{
            insuranceCertificateViewModel: null,
            leaseViewModel: this.lease
          };

          return this._projectService
            .insuranceCertificateProject(commandParam, models.InsuranceCertificateTriggerType.Finish)
            .pipe(
              tap(() => {
                this._dialogRefService.outputData = {
                  isSaved: true
                };

                this._dialogRefService.hide();
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .declined
      .pipe(
        tap(() => {
          this.isNonCompliant = true;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  clickNonCompliantButton($event) {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmCertificateOfInsuranceNonComplianceAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.isNonCompliant = true;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.project) {
      this._currentProjectState = this.project.projectState;

      if (
        this._projectAccessService.checkAccessToCertificateOfInsurance(
          InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
          this.project,
          this.lease
        )
      ) {
        this._handleLandlordFirstStep();
      }

      if (
        this._projectAccessService.checkAccessToCertificateOfInsurance(
          InsuranceCertificateProjectTemplateItemType.LandlordStep,
          this.project,
          this.lease
        )
      ) {
        this._handleLandlordStep();
      }
    }
  }

  deleteFile(insuranceCertificateFile: models.IInsuranceCertificateFileViewModel) {
    this._projectService
      .deleteCertificateOfInsuranceFile(insuranceCertificateFile.id, this.lease.id)
      .pipe(
        tap(() => this.insuranceCertificateFiles = this.insuranceCertificateFiles.filter(t => t.id !== insuranceCertificateFile.id)),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  previewCOIFile(file: models.IFileViewModel): void {
    if (!file) {
      return;
    }

    const documents = [
      {
        url: file.url,
        name: file.name,
      }
    ];

    this._documentViewerService.show(documents, {
      width: '95%',
      height: '95%',
      maxWidth: 1800,
      closeOnOutsideClick: false,
      showCloseButton: true,
      title: file.name || 'Preview',
      activeIndex: 0,
    });
  }

  showHighlightedInput(value: any): boolean {
    return (
      value &&
      this._projectAccessService.checkAccessToCertificateOfInsurance(
        models.InsuranceCertificateProjectTemplateItemType.TenantStep,
        this.project,
        this.lease
      )
    );
  }

  close(): void {
    this._dialogRefService.hide();
  }
}
