import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Configuration } from '@statera/sdk/common';

import { ColaboRepository } from './colabo.repository';
import { ColaboManager } from './colabo.manager';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ColaboRepository,
    ColaboManager,
  ],
})
export class ColaboModule {
  static forChild(configuration: Configuration): ModuleWithProviders<ColaboModule> {
    return {
      ngModule: ColaboModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        ColaboRepository,
        ColaboManager,
      ],
    };
  }
}
