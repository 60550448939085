import * as models from '@statera/sdk/common';

export type AnalyticsFilters = models.IAnalyticsFiltersViewModel;
export type KeyStatisticsReport = models.IKeyStatisticsReportViewModel;
export type ExpirationsReport = models.IExpirationsReportViewModel;
export type OccupancyAndVacancyReport = models.IOccupancyAndVacancyReportViewModel;
export type DealsByStageReport = models.IDealsByStageReportViewModel;
export type ActiveDealsByStageReport = models.IActiveDealsByStageReportViewModel;
export type InquiryInsightsKeyStatisticsReport = models.IInquiryInsightsKeyStatisticsReportViewModel;
export type InquiryInsightsInquiryDynamicsReport = models.IInquiryInsightsInquiryDynamicsReportViewModel;
export type InquiryInsightsInquiriesSubmittedByReport = models.IInquiryInsightsInquiriesSubmittedByReportViewModel;
export type InquiryInsightsInquiriesActivityReport = models.IInquiryInsightsInquiriesActivityReportViewModel;
export type InquiryInsightsAverageSizeRequestedReport = models.IInquiryInsightsAverageSizeRequestedReportViewModel;
export type InquiryInsightsInquiriesWithToursConvertedToDealReport =
  models.IInquiryInsightsInquiriesWithToursConvertedToDealReportViewModel;
export type InquiryInsightsInquiryDeleteTopReasonsReport =
  models.IInquiryInsightsInquiryDeleteTopReasonsReportViewModel;
export type InquiryInsightsTopBuildingsReport = models.IInquiryInsightsTopBuildingsReportViewModel;
export type InquiryInsightsTopLocationsReport = models.IInquiryInsightsTopLocationsReportViewModel;
export type ListingSharesAnsViewsReportViewModel = models.IListingSharesAndViewsReportViewModel;
export type ListingViewsEngagementReportViewModel = models.IListingViewsEngagementReportViewModel;
export type EngagementWithEmailsViewModel = models.IEngagementWithEmailsViewModel;
export type EngagementWithEmailsPeriodViewModel = models.IEngagementWithEmailsPeriodViewModel;


export type ExpirationsReportGroupingOption = models.ExpirationsReportGroupingOption;
export const ExpirationsReportGroupingOption = models.ExpirationsReportGroupingOption;

export type DealsByStageReportDealType = models.DealsByStageReportDealType;
export const DealsByStageReportDealType = models.DealsByStageReportDealType;

export type OccupancyAndVacancyReportGroupingOption = models.OccupancyAndVacancyReportGroupingOption;
export const OccupancyAndVacancyReportGroupingOption = models.OccupancyAndVacancyReportGroupingOption;

export enum AnalyticsReportGroupingOption {
  Week = 'Week',
  Month = 'Month',
  Year = 'Year'
}
