import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { CommonTools } from '@statera/sdk/common';
import { ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import * as models from '../../../infrastructure/models/generated';

import { DealsByStageReportChartType, DealsByStageReportTooltip } from '../../models/deals-by-stage-report-tooltip.model';

import { AnalyticsTooltipFactoryService } from '../../services/analytics-tooltip-factory.service';
import { AnalyticsTooltipService } from '../../services/analytics-tooltip.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { AnalyticsManager } from '@statera/sdk/analytics';

import { DealsByStageReportSingleStageDialogComponent } from '../deals-by-stage-report-single-stage-dialog/deals-by-stage-report-single-stage-dialog.component';
import { DealsByStageReportTooltipComponent } from '../deals-by-stage-report-tooltip/deals-by-stage-report-tooltip.component';

type TooltipComponentType = DealsByStageReportTooltipComponent;
type TooltipModelType = DealsByStageReportTooltip;

@Component({
  selector: 'app-analytics-deals-by-stage-report',
  templateUrl: 'deals-by-stage-report.component.html',
  styleUrls: ['deals-by-stage-report.component.scss'],
})
export class DealsByStageReportComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('canvasElementRef') canvasElementRef: ElementRef;

  @Input() numberOfYears: number;
  @Input() dealType: models.DealsByStageReportDealType;
  @Input() analyticsFilters: models.IAnalyticsFiltersViewModel;

  isLoaded: boolean;

  barChartOptions: ChartOptions<'bar'>;
  barChartData: ChartConfiguration<'bar'>['data'];

  private _dealsByStageReport: models.IDealsByStageReportViewModel;
  private _tooltipComponent: TooltipComponentType;

  private readonly _analyticsManager: AnalyticsManager;
  private readonly _analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>;
  private readonly _dialogService: DialogService;
  private readonly _destroy$: Subject<void>;

  constructor(
    analyticsManager: AnalyticsManager,
    analyticsTooltipFactoryService: AnalyticsTooltipFactoryService,
    dialogService: DialogService,
  ) {
    this._analyticsManager = analyticsManager;
    this._analyticsTooltipService = analyticsTooltipFactoryService.create<TooltipComponentType, TooltipModelType>();
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.numberOfYears = this.numberOfYears || 5;
    this.dealType = this.dealType || models.DealsByStageReportDealType.RenewalInitiatedByTenant;
    this.analyticsFilters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    this._configureChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    const isChanged = (change: SimpleChange) => {
      return (
        change &&
        (
          change.isFirstChange() ||
          (
            (change.previousValue && change.currentValue) &&
            !CommonTools.isEqual(change.previousValue, change.currentValue)
          )
        )
      );
    };

    if (
      isChanged(changes.dealType) ||
      isChanged(changes.numberOfYears) ||
      isChanged(changes.analyticsFilters)
    ) {
      const isFirstChange = (change: SimpleChange) => change && change.isFirstChange();

      // Configure chart at first run
      if (
        isFirstChange(changes.dealType) ||
        isFirstChange(changes.numberOfYears) ||
        isFirstChange(changes.analyticsFilters)
      ) {
        this._configureChart();
      }

      this._setupChartData();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleChartClickEvent(event): void {
    if (
      !event || !event.active || !event.active[0] ||
      !this._dealsByStageReport || !this._dealsByStageReport.stages || !this._dealsByStageReport.stages.length
    ) {
      return;
    }

    const activeItemIndex = event.active[0].index;

    const dealsByStageReportLeaseStage = this._dealsByStageReport.stages[activeItemIndex];
    if (!dealsByStageReportLeaseStage) {
      return;
    }

    this._dialogService.show(DealsByStageReportSingleStageDialogComponent, {
      title: dealsByStageReportLeaseStage.stageName,
      showCloseButton: true,
      width: 1024,
      height: '95%',
      containerClassNames: ['overflow-hidden'],
      injectableData: {
        dealsByStageReportLeaseStages: this._dealsByStageReport.stages,
        activeIndex: activeItemIndex,
      },
    });
  }

  showChart(activeItemIndex = 0): void {
    if (!this._dealsByStageReport.stages[activeItemIndex]) {
      return;
    }

    const dealsByStageReportLeaseStage = this._dealsByStageReport.stages[activeItemIndex];
    if (!dealsByStageReportLeaseStage) {
      return;
    }

    this._dialogService.show(DealsByStageReportSingleStageDialogComponent, {
      title: dealsByStageReportLeaseStage.stageName,
      showCloseButton: true,
      width: 1024,
      height: '95%',
      containerClassNames: ['overflow-hidden'],
      injectableData: {
        dealsByStageReportLeaseStages: this._dealsByStageReport.stages,
        activeIndex: activeItemIndex,
      },
    });
  }

  private _configureChart(): void {
    this.barChartData = {
      labels: [],
      datasets: [],
    };

    this.barChartOptions = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          grid: {
            color: '#DCDFE5',
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 5,
          },
        },
        y: {
          stacked: true,
          suggestedMin: 0,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: '#B3B7BD',
            font: {
              family: '"Avenir",Arial,Helvetica,sans-serif',
              size: 12,
              weight: '500',
            },
            padding: 10,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          external: (x) => this._externalTooltipHandler(x.tooltip),
        },
      },
    };
  }

  private _setupChartData(): void {
    this.isLoaded = false;

    this._requestDealsByStageReport()
      .pipe(
        tap(dealsByStageReport => {
          if (!dealsByStageReport || !dealsByStageReport.stages) {
            return;
          }

          this._dealsByStageReport = dealsByStageReport;

          const { stages } = dealsByStageReport;

          this.isLoaded = true;
          this.barChartData.labels = stages.map(x => x.stageName);
          this.barChartData.datasets = [
            {
              data: stages.map(x => x.averageDaysTakenByTenant),
              label: 'Tenant',
              backgroundColor: 'rgba(244, 203, 141, 1)',
              hoverBackgroundColor: 'rgba(236, 167, 65, 1)',
              borderColor: 'rgba(255, 255, 255, 0)',
              hoverBorderColor: 'rgba(255, 255, 255, 0)',
              borderWidth: 3,
              borderRadius: [
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
              ],
              borderSkipped: false,
              barThickness: 15,
            },
            {
              data: stages.map(x => x.averageDaysTakenByLandlord),
              label: 'Landlord',
              backgroundColor: 'rgba(155, 211, 179, 1)',
              hoverBackgroundColor: 'rgba(89, 182, 128, 1)',
              borderColor: 'rgba(255, 255, 255, 0)',
              hoverBorderColor: 'rgba(255, 255, 255, 0)',
              borderWidth: 3,
              borderRadius: [
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
                { topLeft: 30, topRight: 30, bottomLeft: 30, bottomRight: 30 },
              ],
              borderSkipped: false,
              barThickness: 15,
            },
          ];

          // Remove stuck tooltip if data changed
          if (this._tooltipComponent) {
            this._analyticsTooltipService.hide();
            this._tooltipComponent = null;
          }
        }),
        tap(() => this.isLoaded = true),
        catchError(err => {
          this.isLoaded = true;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _requestDealsByStageReport(): Observable<models.IDealsByStageReportViewModel> {
    const today = moment().startOf('day');

    const period = {
      startDate: today.clone().startOf('year').startOf('day').add(-this.numberOfYears, 'years').format('YYYY-MM-DD'),
      endDate: today.clone().endOf('year').startOf('day').format('YYYY-MM-DD'),
    };

    const filters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    return this._analyticsManager
      .getDealsByStageReport(period, this.dealType, filters);
  }

  private _externalTooltipHandler(tooltipModel: TooltipModel<'bar'>): void {
    if (this.canvasElementRef && this.canvasElementRef.nativeElement) {
      this.canvasElementRef.nativeElement.classList.remove('cursor-pointer');
    }

    if (!tooltipModel.getActiveElements().length) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    if (this._tooltipComponent) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
    }

    if (
      !tooltipModel || !tooltipModel.dataPoints || !tooltipModel.dataPoints.length ||
      !this._dealsByStageReport || !this._dealsByStageReport.stages || !this._dealsByStageReport.stages.length ||
      !this.canvasElementRef || !this.canvasElementRef.nativeElement
    ) {
      return;
    }

    this.canvasElementRef.nativeElement.classList.add('cursor-pointer');

    const dataPoints = tooltipModel.dataPoints[0];
    if (!dataPoints) {
      return;
    }

    const activeItemIndex = dataPoints.dataIndex;
    const activeDataSetIndex = dataPoints.datasetIndex;

    const dealsByStageReportStage = this._dealsByStageReport.stages[activeItemIndex];
    if (!dealsByStageReportStage) {
      return;
    }

    const {x, y, height, base, horizontal} = <any>tooltipModel.getActiveElements()[0].element;

    if (horizontal) {
      tooltipModel.x = tooltipModel.caretX = <number>base + (<number>x - <number>base) / 2;
      tooltipModel.y = tooltipModel.caretY = <number>y + <number>height / 2;
    } else {
      tooltipModel.x = tooltipModel.caretX = <number>x;
      tooltipModel.y = tooltipModel.caretY = <number>base;
    }

    this._tooltipComponent = this._analyticsTooltipService.show(
      DealsByStageReportTooltipComponent,
      {
        ...dealsByStageReportStage,
        chartType: DealsByStageReportChartType[DealsByStageReportChartType[activeDataSetIndex]],
      },
      {
        tooltipModel: tooltipModel,
        canvasElement: this.canvasElementRef.nativeElement,
      },
    );
  }
}
