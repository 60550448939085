import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ImageViewerImage, ImageViewerImageKind } from '../../../image-viewer/models/image-viewer-image.model';
import { ImageViewerRefService } from '../../../image-viewer/services/image-viewer-ref.service';
import { ImageViewerService } from '../../../image-viewer/services/image-viewer.service';

export interface GalleryPicture {
  thumbImage: string;
  alt: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, OnDestroy {
  @Input() galleryPictures: Array<GalleryPicture>;

  @Input() minHeight: number;

  private _imageViewerRef: ImageViewerRefService;

  private readonly _imageViewerService: ImageViewerService;
  private readonly _destroy: Subject<void>;

  constructor(imageViewerService: ImageViewerService) {
    this._imageViewerService = imageViewerService;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.galleryPictures = this.galleryPictures || [];
    this.minHeight = this.minHeight || null;
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getImageViewerImages(): Array<ImageViewerImage> {
    return this.galleryPictures.map((picture, index) => {
      return <ImageViewerImage>{
        id: index,
        imageUrl: picture.thumbImage,
        imageKind: ImageViewerImageKind.Standard,
      };
    });
  }

  handleZoomClick(currentImageIdx: number): void {
    if (this._imageViewerRef) {
      return;
    }

    this._imageViewerRef = this._imageViewerService.show(
      this.getImageViewerImages(),
      {
        width: '95%',
        height: '95%',
        maxWidth: 1800,
        closeOnOutsideClick: false,
        showCloseButton: true,
        title: 'Building picture',
        activeIndex: currentImageIdx,
        allowChangeMarkers: false,
        enableArrowNavigation: true,
        enableZoom: false
      },
    );

    this._imageViewerRef.onHiding
      .pipe(
        tap(() => {
          this._imageViewerRef = null;
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
