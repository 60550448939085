import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { YouTubePlayer } from '@angular/youtube-player';

import * as urlParser from 'js-video-url-parser';
import { YouTubeParseResult } from 'js-video-url-parser';

@Component({
  selector: 'app-video-player-youtube',
  templateUrl: './video-player-youtube.component.html',
  styleUrls: ['./video-player-youtube.component.scss'],
})
export class VideoPlayerYoutubeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() source: string;
  @Input() muted: boolean;

  @Input() playObserver: Subject<void>;
  @Input() pauseObserver: Subject<void>;

  @Output() loaded: EventEmitter<void>;
  @Output() playing: EventEmitter<void>;
  @Output() played: EventEmitter<void>;
  @Output() paused: EventEmitter<void>;

  @ViewChild(YouTubePlayer, {read: YouTubePlayer, static: false}) youTubePlayer: YouTubePlayer;

  youtubeId: string;
  youtubePlayerVars: YT.PlayerVars;

  private readonly _destroy: Subject<void>;

  constructor() {
    this.loaded = new EventEmitter<void>();
    this.playing = new EventEmitter<void>();
    this.played = new EventEmitter<void>();
    this.paused = new EventEmitter<void>();

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    const urlParseResult = <YouTubeParseResult>((<any>urlParser).parse(this.source));
    if (urlParseResult) {
      this.youtubeId = urlParseResult.id;
    }

    this.youtubePlayerVars = {
      autohide: 1,
      autoplay: 0,
      disablekb: 1,
      hl: 'en',
      iv_load_policy: 3,
      modestbranding: 0,
      origin: location.origin,
      rel: 0,
      showinfo: 0,
      controls: 1,
    };

    this.playObserver
      .pipe(
        tap(() => this._play()),
        takeUntil(this._destroy),
      )
      .subscribe();

    this.pauseObserver
      .pipe(
        tap(() => this._pause()),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.muted) {
      this.youTubePlayer?.mute();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  handleReady() {
    this.loaded.next();
  }

  handleStateChange(event: YT.OnStateChangeEvent) {
    if (!event) {
      return;
    }

    switch (event.data) {
      case YT.PlayerState.PLAYING:
        this.playing.next();
        break;

      case YT.PlayerState.PAUSED:
        this.paused.next();
        break;

      case YT.PlayerState.ENDED:
        this.played.next();
        break;
    }
  }

  private _play(): void {
    this.youTubePlayer?.playVideo();
  }

  private _pause(): void {
    this.youTubePlayer?.pauseVideo();
  }
}
