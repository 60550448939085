<ng-container *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Read; leaseId: leaseId; else: noTeamAccessTpl">
  <ng-container *ngIf="teamMembers">
    <app-avatar-list [leaseId]="leaseId" [projects]="projects" [avatarList]="avatarItems"></app-avatar-list>
  </ng-container>
</ng-container>
<ng-template #noTeamAccessTpl>
  <div class="avatars">
    <div class="avatars-item avatars-item-1">
      <img *ngIf="info?.avatarUrl" class="object-fit-cover rounded-circle avatars-item-image"
           [src]="info?.avatarUrl || 'assets/img/user.png'" [alt]="info?.displayName"/>

      <div *ngIf="!info?.avatarUrl" class="avatars-item-initials">
        {{getCurrentUserInitials()}}
      </div>

      <ng-container *ngIf="getProjectIndicatorClass(leaseId) as indicatorClassName">
              <span class="indicator"
                    [ngClass]="indicatorClassName">
              </span>
      </ng-container>
    </div>
  </div>
</ng-template>
