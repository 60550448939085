import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-state',
  templateUrl: 'analytics-filters-location-state.component.html',
  styleUrls: ['analytics-filters-location-state.component.scss'],
})
export class AnalyticsFiltersLocationStateComponent {
  @Input() locationStates: Array<analyticsFiltersModels.LocationStateFilter>;
  @Output() locationStatesChange: EventEmitter<Array<analyticsFiltersModels.LocationStateFilter>>;

  constructor() {
    this.locationStatesChange = new EventEmitter<Array<analyticsFiltersModels.LocationStateFilter>>();
  }

  handleStateSelection(): void {
    this.locationStatesChange.next([...this.locationStates]);
  }
}
