<section class="w-auth w-auth--reset-email">
  <div class="w-auth-media">
    <div class="container">
      <div class="text-center">
        <a href="#" class="b-auth-logo">
          <img class="img-fluid" src="./assets/img/logos/statera_logo_white.svg" alt="">
        </a><!--b-auth-logo-->
      </div>

      <p class="b-auth-text">
        Statera's platform will shorten the timeline &amp; provide relevant information to the tenant and landlord in a
        more efficient manner
      </p><!--b-list-->
    </div><!--container-->
  </div><!--w-auth-media-->

  <div class="w-auth-content">
    <section class="login">
      <div class="login-content">
        <h1 class="b-auth-title">
          Enter new password
        </h1><!--b-auth-title -->

        <form name="form" novalidate
              #form="ngForm" (ngSubmit)="submit(form)">

          <fieldset [disabled]="submitted ? 'disabled' : null">
            <div class="d-flex flex-column pb-4">
              <p class="b-label">
                Password
              </p>

              <input type="password" name="password" id="password" class="b-input" required
                     [(ngModel)]="vm.password" #password="ngModel"
                     [class.is-invalid]="form.submitted && password.hasError('required') && (vm.password !== vm.confirmPassword)"/>

              <span class="error-msg"
                    *ngIf="form.submitted && password.hasError('required')">
                Required
              </span>
              <span class="error-msg"
                    *ngIf="form.submitted && (vm.password !== vm.confirmPassword)">
                The password and confirmation password do not match
              </span>
            </div>

            <div class="d-flex flex-column pb-4">
              <p class="b-label">
                Confirm Password
              </p>

              <input type="password" name="confirmPassword" id="confirmPassword" class="b-input" required
                     [(ngModel)]="vm.confirmPassword" #confirmPassword="ngModel"
                     [class.is-invalid]="form.submitted && confirmPassword.hasError('required') && (vm.password !== vm.confirmPassword)"/>

              <span class="error-msg"
                    *ngIf="form.submitted && confirmPassword.hasError('required')">
                Required
              </span>
              <span class="error-msg"
                    *ngIf="form.submitted && (vm.password !== vm.confirmPassword)">
                The password and confirmation password do not match
              </span>
            </div>

            <div class="d-flex flex-column align-items-center w-100">
              <button class="btn btn-sign-in">
                <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="text-uppercase">
                  reset password
                </span>
              </button>
            </div>

            <input type="hidden" name="id" id="id" required
                   [(ngModel)]="vm.id">

            <input type="hidden" name="token" id="token" required
                   [(ngModel)]="vm.token"/>
          </fieldset>
        </form>

      </div>
    </section>
  </div>
</section>

<app-footer></app-footer>
