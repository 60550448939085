import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as models from './portfolio-invitation.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioInvitationStore {
  readonly invitationInfo: BehaviorSubject<models.PortfolioInvitationInfo>;

  constructor() {
    this.invitationInfo = new BehaviorSubject<models.PortfolioInvitationInfo>(null);
  }
}
