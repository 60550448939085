import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxiMenuItemModule, DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { AttachmentsModule } from '../attachments/attachments.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { LeaseService } from '../shared/services/lease.service';
import { UserRouter } from './user.router';
import { ProfileComponent } from './components/profile/profile.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { NameEditComponent } from './components/name-edit/name-edit.component';
import { TabsComponent } from '../infrastructure/components/tabs/tabs.component';
import { ProfileService } from './services/profile.service';
import { DocumentsComponent } from './components/documents/documents.component';
import {
  DxTreeListModule,
  DxButtonGroupModule,
  DxButtonModule,
  DxToolbarModule,
  DxPopupModule,
  DxTreeViewModule,
  DxBoxModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxSelectBoxModule,
  DxFormModule,
  DxFileUploaderModule,
  DxMenuModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxValidationGroupModule, DxSwitchModule, DxScrollViewModule,
} from 'devextreme-angular';
import { CroppieModule } from 'angular-croppie-module';
import { TenantPagesService } from './services/tenant-pages.service';
import { AlertPreferencesComponent } from './components/alert-settings/alert-preferences.component';
import { AvatarEditDialogComponent } from './components/avatar-edit-dialog/avatar-edit-dialog.component';
import { VerifyPhoneNumberDialogComponent } from './components/verify-phone-number-dialog/verify-phone-number-dialog.component';
import {NoActiveUserStatusComponent} from './components/no-active-user-status/no-active-user-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    UserRouter,
    DxTreeListModule,
    DxButtonGroupModule,
    DxButtonModule,
    DxToolbarModule,
    DxPopupModule,
    DxTreeViewModule,
    DxBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxiValidationRuleModule,
    DxSelectBoxModule,
    DxFormModule,
    DxFileUploaderModule,
    CroppieModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxMenuModule,
    DxNumberBoxModule,
    DxiMenuItemModule,
    DxCheckBoxModule,
    DxValidationGroupModule,
    DxSwitchModule,
    InfrastructureModule,
    DxScrollViewModule,
    AttachmentsModule,
  ],
  declarations: [
    ProfileComponent,
    ProfileEditComponent,
    NameEditComponent,
    TabsComponent,
    DocumentsComponent,
    AlertPreferencesComponent,
    VerifyPhoneNumberDialogComponent,
    AvatarEditDialogComponent,
    NoActiveUserStatusComponent
  ],
  providers: [
    ProfileService,
    LeaseService,
    TenantPagesService,
  ],
  entryComponents: [
    AlertPreferencesComponent,
    VerifyPhoneNumberDialogComponent,
    AvatarEditDialogComponent,
  ],
})
export class UserModule {
}
