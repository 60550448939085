export enum TenantQuestions {
  RequireMoreSpace = 200,
  RequireMoreSpaceMinSF = 201,
  RequireMoreSpaceMaxSF = 202,
  RequireLessSpace = 203,
  RequireLessSpaceMinSF = 204,
  RequireLessSpaceMaxSF = 205,
  SpaceIsNoLongerRequired = 206,
  RelocatingLocally = 207,
  Location = 208,
  MovingOutOfState = 209,
  State = 210,
  City = 211,
  SpaceUseChange = 212,
  PleaseDescribeSpaceUseChange = 213,
  IncorrectConfiguration = 214,
  PleaseDescribeIncorrectConfigurationText = 215,
  Economics = 216,
  MinAmount = 217,
  MaxAmount = 218,
  Comment = 219,
}
