import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

export interface IOccupancyAndVacancyReportSinglePeriodDialogViewModel {
  periods: Array<models.IOccupancyAndVacancyReportPeriodViewModel>;
  initialPeriodId: number;
}

@Component({
  templateUrl: 'occupancy-and-vacancy-report-single-period-dialog.component.html',
  styleUrls: ['occupancy-and-vacancy-report-single-period-dialog.component.scss'],
})
export class OccupancyAndVacancyReportSinglePeriodDialogComponent implements OnInit {
  readonly occupancyAndVacancyReportSinglePeriodDialogViewModel: IOccupancyAndVacancyReportSinglePeriodDialogViewModel;

  private _activePeriodId = 0;

  get activePeriodId() {
    return this._activePeriodId;
  }

  set activePeriodId(value) {
    this._activePeriodId = value;
  }

  private readonly _dialogRefService: DialogRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;

  static prepareTitle(startDate: Date | string) {
    return `Projected Occupancy ${moment(startDate).format('YYYY')}`;
  }

  constructor(dialogRefService: DialogRefService, changeDetectorRef: ChangeDetectorRef) {
    this._dialogRefService = dialogRefService;
    this._changeDetectorRef = changeDetectorRef;
  }

  ngOnInit() {
    if (this.occupancyAndVacancyReportSinglePeriodDialogViewModel) {
      this.activePeriodId = this.occupancyAndVacancyReportSinglePeriodDialogViewModel.initialPeriodId;
    }
  }

  goToNextPeriod() {
    const vm = this.occupancyAndVacancyReportSinglePeriodDialogViewModel;
    const possibleNewId = this.activePeriodId + 1;

    let newId = possibleNewId;
    if (possibleNewId >= vm.periods.length) {
      newId = 0;
    }
    this.activePeriodId = newId;

    const title = OccupancyAndVacancyReportSinglePeriodDialogComponent.prepareTitle(vm.periods[newId].startDate);
    this._dialogRefService.setTitle(title);

    this._changeDetectorRef.markForCheck();
  }

  goToPreviousPeriod() {
    const vm = this.occupancyAndVacancyReportSinglePeriodDialogViewModel;
    const possibleNewId = this.activePeriodId - 1;

    let newId = possibleNewId;
    if (possibleNewId < 0) {
      newId = vm.periods.length - 1;
    }
    this.activePeriodId = newId;

    const title = OccupancyAndVacancyReportSinglePeriodDialogComponent.prepareTitle(vm.periods[newId].startDate);
    this._dialogRefService.setTitle(title);

    this._changeDetectorRef.markForCheck();
  }
}
