import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Configuration } from '@statera/sdk/common';

import { BuildingUnitListingRepository } from './building-unit-listing.repository';
import { BuildingUnitListingManager } from './building-unit-listing.manager';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    BuildingUnitListingRepository,
    BuildingUnitListingManager,
  ],
})
export class BuildingUnitListingModule {
  static forChild(configuration: Configuration): ModuleWithProviders<BuildingUnitListingModule> {
    return {
      ngModule: BuildingUnitListingModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        BuildingUnitListingRepository,
        BuildingUnitListingManager,
      ],
    };
  }
}
