<div class="active-deals-by-stage-report-tooltip"
     *ngIf="activeDealsByStageReportTooltip"
     #activeDealsByStageTooltipElementRef>
  <table>
    <thead>
    <tr>
      <th>
        {{activeDealsByStageReportTooltip.name}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        <div class="d-flex">
          <span class="value">{{activeDealsByStageReportTooltip.leaseIds.length}}</span>
          <span class="caption">deal(s)</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="d-flex">
          <span class="value">{{totalSF | safeNumber}}</span>
          <span class="caption">total SF</span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
