import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Role } from '@statera/sdk/auth';

import { AuthService } from '../../auth/services/auth.service';
import { StepsWithWizard } from '../../shared/constants/shared.constants';
import { ProjectService } from '../../shared/services/project.service';

import * as models from '../../infrastructure/models/generated';

import { QuizService } from '../../shared/services/quiz.service';
import { ProjectQuery } from '../../shared/states/project/project.query';

@Injectable()
export class ColaboGuard implements CanActivate, CanActivateChild {
  private readonly _authService: AuthService;
  private readonly _quizService: QuizService;
  private readonly _projectService: ProjectService;
  private readonly _router: Router;
  private readonly _projectQuery: ProjectQuery;

  constructor(
    authService: AuthService,
    quizService: QuizService,
    projectService: ProjectService,
    router: Router,
    projectQuery: ProjectQuery
  ) {
    this._authService = authService;
    this._quizService = quizService;
    this._projectService = projectService;
    this._router = router;
    this._projectQuery = projectQuery;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const leaseId = parseInt(route.paramMap.get('id') || '0', 10);
    if (!leaseId) {
      return of(true);
    }

    return this._authService
      .infoLoadComplete
      .pipe(
        switchMap((info: models.IStartupInfoViewModel): Observable<boolean | UrlTree> => {
          if (info.role !== Role.Tenant) {
            return of(true);
          }
          return this._projectQuery
            .getRenewalProject(leaseId)
            .pipe(
              switchMap((project: models.IProjectViewModel): Observable<boolean | UrlTree> => {
                if (!project || !project.projectState) {
                  return of(true);
                }

                if (StepsWithWizard.some(x => x === project.projectState.renewalProjectTemplateItemType)) {
                  const urlTree = this._router
                    .createUrlTree(
                      [
                        'v2',
                        'wizard',
                        project.leaseId
                      ],
                      {},
                    );

                  const url = this._router.serializeUrl(urlTree);

                  window.location.href = url;

                  return of(false);
                }

                return of(true);
              })
            );
        }),
      );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute);
  }
}
