<div class="image-viewer">
  <ng-container *ngIf="(getCachedImageRef$(activeImageIndex) | async) as imageRef; else loadingTemplate">
    <!-- Start: Image View -->
    <div class="image-viewer-image">
      <app-image-viewer-image [imageRef]="imageRef" [isPreviewMode]="isPreviewMode" [allowChangeMarkers]="allowChangeMarkers"
                              [width]="containerSize.width"
                              [height]="enablePreviewNavigation ? containerSize.height - 90 : containerSize.height"
                              [minWidth]="minWidth" [minHeight]="minHeight"
                              [markerCreated$]="markerCreated" [markerChanged$]="markerChanged"
                              [markerDeleted$]="markerDeleted"
                              [addNotePressed]="addNotePressed"
      >
      </app-image-viewer-image>
    </div>
    <!-- End: Image View -->
    <!-- Start: Arrow Navigation -->
    <div class="image-viewer-arrow-navigation"
         *ngIf="enableArrowNavigation">
      <a role="button" class="image-viewer-arrow-navigation-previous"
         [class.is-invisible]="!hasPreviousImage()"
         (click)="previousImage()">
        <i class="fa fa-chevron-left"></i>
      </a>
      <a role="button" class="image-viewer-arrow-navigation-zoom"
         *ngIf="enableZoom"
         (click)="zoomClicked.emit(imageRef)">
        <i class="fa fa-search-plus"></i>
      </a>
      <a role="button" class="image-viewer-arrow-navigation-next"
         [class.is-invisible]="!hasNextImage()"
         (click)="nextImage()">
        <i class="fa fa-chevron-right"></i>
      </a>
    </div>
    <!-- End: Arrow Navigation -->
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="image-viewer-loader"
         [style.width.px]="containerSize.width"
         [style.height.px]="enablePreviewNavigation ? containerSize.height - 90 : containerSize.height">
      <app-image-viewer-preloader>
      </app-image-viewer-preloader>
    </div>
  </ng-template>
  <!-- Start: Preview Navigation -->
  <div class="image-viewer-preview-navigation"
       *ngIf="enablePreviewNavigation">
    <a role="button" class="image-viewer-preview-navigation-image"
       *ngFor="let imageRef$ of getCachedImageRefs$(); let index = index"
       [class.is-active]="activeImageIndex === index"
       (click)="selectImage(index)"
       #imageViewerPreviewNavigationImageElement>
      <ng-container *ngIf="imageRef$ | async as imageRef">
        <app-image-viewer-image [imageRef]="imageRef" [isPreviewMode]="true"
                                [width]="imageViewerPreviewNavigationImageElement.clientWidth"
                                [height]="imageViewerPreviewNavigationImageElement.clientHeight"
                                [minWidth]="imageViewerPreviewNavigationImageElement.clientWidth"
                                [minHeight]="imageViewerPreviewNavigationImageElement.clientHeight">
        </app-image-viewer-image>
      </ng-container>
    </a>
  </div>
  <!-- End: Preview Navigation -->
</div>
