import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import moment from 'moment';

import { MessageManager } from '../message.manager';

import { TermCommentRepository } from './term-comment.repository';

import * as models from './term-comment.model';

export class TermCommentManager extends MessageManager<models.LeaseTermComment> {
  private readonly _startupInfo: models.StartupInfo;
  private readonly _termCommentType: models.TermCommentType;
  private readonly _leaseTermType: models.LeaseTermType;
  private readonly _lease: models.Lease;

  private readonly _termCommentRepository: TermCommentRepository;

  constructor(
    termCommentRepository: TermCommentRepository,
    startupInfo: models.StartupInfo,
    termCommentType: models.TermCommentType,
    leaseTermType: models.LeaseTermType,
    lease?: models.Lease,
  ) {
    super();

    this._termCommentRepository = termCommentRepository;

    this._startupInfo = startupInfo;
    this._termCommentType = termCommentType;
    this._leaseTermType = leaseTermType;
    this._lease = lease;
  }

  addMessage(message: models.Message): Observable<models.Message> {
    return this._termCommentRepository
      .addMessage(
        {
          ...this.fromMessage(message),
          profile: <models.ChatProfile>{
            id: this._startupInfo.id,
            avatarUrl: this._startupInfo.avatarUrl,
            displayName: this._startupInfo.displayName,
          },
          termCommentType: this._termCommentType,
        }
      )
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  getMessages(skip: number, take: number): Observable<Array<models.Message>> {
    return this._termCommentRepository
      .getMessages(
        this._termCommentType,
        this._leaseTermType,
        this._lease ? this._lease.id : null,
        skip,
        take,
      )
      .pipe(
        map(response => response.map(item => this.toMessage(item))),
        share(),
      );
  }

  updateMessage(message: models.Message): Observable<models.Message> {
    return this._termCommentRepository
      .updateMessage(
        {
          ...this.fromMessage(message),
          profile: <models.ChatProfile>{
            id: this._startupInfo.id,
            avatarUrl: this._startupInfo.avatarUrl,
            displayName: this._startupInfo.displayName,
          },
          termCommentType: this._termCommentType,
        }
      )
      .pipe(
        map(item => this.toMessage(item)),
        share(),
      );
  }

  deleteMessage(message: models.Message): Observable<void> {
    return this._termCommentRepository
      .deleteMessage(
        {
          ...this.fromMessage(message),
          profile: <models.ChatProfile>{
            id: this._startupInfo.id,
            avatarUrl: this._startupInfo.avatarUrl,
            displayName: this._startupInfo.displayName,
          },
          termCommentType: this._termCommentType,
        }
      );
  }

  toMessage(x: models.LeaseTermComment): models.Message {
    const result = <models.Message>{
      id: x.id,
      content: x.message,
      createdOn: x.createdOn,
      isMy: this._startupInfo.id === x.profile.id,
      isRead: x.isRead,
      authorDisplayName: x.profile ? x.profile.displayName : '',
      edited: this.isEdited(x),
      data: {
        authorId: x.profile.id,
      },
      badge: this.getChatBadgeText(this._lease, x.profile.id),
      isBrokerMessage: this.isBrokerMessage(this._lease, x.profile.id),
    };

    let avatarUrl = 'assets/img/avatar.png';
    if (x.profile && x.profile.avatarUrl) {
      avatarUrl = x.profile.avatarUrl;
    }
    result.authorAvatarUrl = avatarUrl;
    return result;
  }

  fromMessage(x: models.Message): models.LeaseTermComment {
    return <models.LeaseTermComment>{
      id: x.id,
      message: x.content,
      leaseId: this._lease ? this._lease.id : null,
      leaseTermType: this._leaseTermType,
      createdOn: x.createdOn,
    };
  }

  isEdited(x: models.LeaseTermComment) {
    try {
      return (
        x.createdOn &&
        x.updatedOn &&
        moment(x.updatedOn).isAfter(moment(x.createdOn))
      );
    } catch (e) {
      return false;
    }
  }
}
