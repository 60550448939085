import { Injectable } from '@angular/core';

import * as models from '../../infrastructure/models/generated';

@Injectable()
export class TenantPagesService {
  getLeaseDisplayName(tenantDashboardViewModel: models.ITenantDashboardViewModel | models.ILeaseViewModel): string {
    if (tenantDashboardViewModel.building && tenantDashboardViewModel.building.address &&
      tenantDashboardViewModel.building.address.addressLine1) {
      if (tenantDashboardViewModel.buildingUnit && tenantDashboardViewModel.buildingUnit.name) {
        return tenantDashboardViewModel.building.address.addressLine1 + ', ' + tenantDashboardViewModel.buildingUnit.name;
      } else {
        return tenantDashboardViewModel.building.address.addressLine1;
      }
    } else {
      return `Lease Abstract ${tenantDashboardViewModel.id}`;
    }
  }
}
