import { Component, OnInit } from '@angular/core';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

@Component({
  selector: 'app-landlord-maintenance',
  templateUrl: './landlord-maintenance.component.html',
  styleUrls: ['./landlord-maintenance.component.scss'],
})
export class LandlordMaintenanceComponent extends BaseTermDirective<models.ILandlordMaintenanceTermViewModel> implements OnInit {
  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
  }

  options: any = [];

  LandlordMaintenanceTermType = models.LandlordMaintenanceTermType;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  ngOnInit() {
    if (!this.leaseTerm) {
      this.leaseTerm = <models.ILandlordMaintenanceTermViewModel>{};
    }
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (!this.leaseTerm.landlordMaintenanceTermType) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this.leaseTerm.hasOperationCondition && typeof this.leaseTerm.warrantDaysCount !== 'number') {
      this.addError('You should fill how much days Landlord shall warrant all systems for.');
      return;
    }

    if (this.leaseTerm.landlordMaintenanceTermType === models.LandlordMaintenanceTermType.PerCurrentLease) {
      this.leaseTerm.landlordMaintenance = this.leaseTerm.abstractValue;
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    this.options = [];

    if (this.project?.projectTypeId !== models.ProjectTypeEnum.NewDeal) {
      this.options = [
        ...this.options,
        {
          name: 'Per current lease',
          value: models.LandlordMaintenanceTermType.PerCurrentLease,
        },
      ];
    }

    this.options = [
      ...this.options,
      {
        name: '',
        value: models.LandlordMaintenanceTermType.SoleMaintenanceByLandlord,
        insertData: true,
      },
      {
        name: 'Custom',
        value: models.LandlordMaintenanceTermType.Custom
      },
    ];
  }
}
