<div class="document-viewer">
  <ng-container *ngIf="getActiveDocumentRef() as documentRef">
    <!-- End: Document View -->
    <div class="document-viewer-document">
      <app-document-viewer-document [documentRef]="documentRef"
                                    [width]="containerSize.width" [height]="containerSize.height - 83"
                                    (loaded)="handleDocumentLoaded($event)">
      </app-document-viewer-document>
    </div>
    <!-- End: Document View -->
    <!-- Start: Loader -->
    <div class="document-viewer-loader"
         *ngIf="!isDocumentLoaded"
         [style.width.px]="containerSize.width" [style.height.px]="containerSize.height - 83">
      <app-document-viewer-preloader>
      </app-document-viewer-preloader>
    </div>
    <!-- End: Loaded -->

    <div class="document-viewer-navigation">
      <ng-container *ngIf="documents && documents.length">
        <div class="document-viewer-navigation-left"
             [class.invisible]="!hasPreviousDocument()">
          <button type="button" class="btn btn-primary"
                  (click)="previousDocument()">
            <i class="fa fa-chevron-left"></i>
            Previous
          </button>
        </div>
        <div class="document-viewer-navigation-center">
          <ng-container *ngIf="!isInFullscreenMode(); else exitFullscreenTpl">
            <button type="button" class="btn btn-primary"
                    *ngIf="isFullscreenSupported(documentRef)"
                    (click)="enterFullscreenMode()">
              <i class="fa fa-arrows-alt"></i>
              Enter fullscreen
            </button>
          </ng-container>

          <ng-template #exitFullscreenTpl>
            <button type="button" class="btn btn-primary"
                    (click)="exitFullscreenMode()">
              <i class="fa fa-arrows-alt"></i>
              Exit fullscreen
            </button>
          </ng-template>

          <button type="button" class="btn btn-primary"
                  (click)="download(documentRef)">
            <i class="fa fa-download"></i>
            Download
          </button>
        </div>
        <div class="document-viewer-navigation-right"
             [class.invisible]="!hasNextDocument()">
          <button type="button" class="btn btn-primary"
                  (click)="nextDocument()">
            Next
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
