export enum DealsByStageReportSingleStageChartType {
  DaysTakenByTenant = 0,
  DaysTakenByLandlord = 1,
}

export class DealsByStageReportSingleStageTooltip {
  chartType: DealsByStageReportSingleStageChartType;
  dealGroupName: string;
  daysTakenByTenant: number;
  daysTakenByLandlord: number;
}
