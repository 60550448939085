import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { QuizManager } from '@statera/sdk/quiz';

import { DialogService } from '../../../dialog/services/dialog.service';
import { DocumentViewerService } from '../../../document-viewer/services/document-viewer.service';

import {
  RequestFinancialsDialogComponent
} from '../../../lease-request/components/request-financials-dialog/request-financials-dialog.component';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-project-request-summary',
  templateUrl: './project-request-summary.component.html',
  styleUrls: ['./project-request-summary.component.scss']
})
export class ProjectRequestSummaryComponent implements OnDestroy {
  @Input() lease: models.ILeaseViewModel;
  @Input() project?: models.IProjectViewModel;
  @Input() projectRequest: models.ITenantRequestViewModel;
  @Input() financialRequests: Array<models.ILeaseRequestViewModel> = [];

  @Output() financialRequestsChange: EventEmitter<Array<models.ILeaseRequestViewModel>>;

  QuestionType: typeof models.QuestionType = models.QuestionType;
  AnswerType: typeof models.AnswerType = models.AnswerType;

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  private readonly _quizManager: QuizManager;
  private readonly _documentViewerService: DocumentViewerService;
  private readonly _dialogService: DialogService;

  private readonly _destroy: Subject<void>;

  constructor(
    quizManager: QuizManager,
    documentViewerService: DocumentViewerService,
    dialogService: DialogService
  ) {
    this._quizManager = quizManager;
    this._documentViewerService = documentViewerService;
    this._dialogService = dialogService;

    this.financialRequestsChange = new EventEmitter<Array<models.ILeaseRequestViewModel>>();

    this._destroy = new Subject<void>();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  getQuizQuestionText(quizQuestion: models.IQuizQuestionViewModel) {
    return this._quizManager.getQuestionText(quizQuestion, this.lease, true);
  }

  renderAnswer(quizAnswer: models.IQuizAnswerViewModel): string | Array<string> | number | Date | models.IUserInvitationRequestViewModel
    | Array<models.IQuizQuestionOptionViewModel> | { [index: string]: number } {
    if (!quizAnswer || !quizAnswer.quizQuestion) {
      return;
    }

    const answer = this._quizManager.renderAnswer(quizAnswer, this.lease);

    if (Array.isArray(answer)) {
      return answer.join('\n');
    }

    return answer;
  }

  hasQuizAnswers(): boolean {
    return this.projectRequest?.quizAnswers?.length > 0;
  }

  isProjectActive(): boolean {
    return this.project && this.project.projectStatus === models.ProjectStatus.Active;
  }

  isThereAnyFinancials(): boolean {
    const financialRequestsFiles = this.financialRequests
      .reduce((acc, request) => {
        acc.push(...request.requestFiles);
        return acc;
      }, []);

    const quizAnswerFiles = this.projectRequest
      ?.quizAnswers
      ?.find(quizAnswer =>
        quizAnswer.quizQuestion?.questionType === models.QuestionType.File ||
        quizAnswer.quizQuestion?.questionType === models.QuestionType.FinancialDocuments
      )
      ?.quizAnswerFiles;

    return financialRequestsFiles?.length > 0 || quizAnswerFiles?.length > 0;
  }

  isThereFinancialRequestWithRequestedStatus(): boolean {
    if (!this.financialRequests || !this.financialRequests.length) {
      return false;
    }

    const financialRequestWithRequestedStatusIndex = this.financialRequests
      .findIndex(x =>
        x.requestStatus === models.LeaseRequestStatus.Requested
      );
    if (financialRequestWithRequestedStatusIndex < 0) {
      return false;
    }

    return true;
  }

  previewFinancials(): void {
    let financialRequestsFiles: Array<models.IFileViewModel> = [];
    let quizAnswerFiles: Array<models.IFileViewModel> = [];

    const financialRequestsLeaseRequestFiles: Array<models.ILeaseRequestFileViewModel> = this.financialRequests
      ?.reduce(
        (acc, request) => {
          acc.push(...request.requestFiles);
          return acc;
        },
        [],
      );
    if (financialRequestsLeaseRequestFiles && financialRequestsLeaseRequestFiles.length) {
      financialRequestsFiles = [
        ...financialRequestsFiles,
        ...financialRequestsLeaseRequestFiles.map(x => x.file),
      ];
    }

    const financialsQuizAnswer: models.IQuizAnswerViewModel = this.projectRequest
      ?.quizAnswers
      ?.find(quizAnswer =>
        quizAnswer.quizQuestion?.questionType === models.QuestionType.FinancialDocuments
      );
    if (financialsQuizAnswer && financialsQuizAnswer.quizAnswerFiles?.length) {
      quizAnswerFiles = [
        ...quizAnswerFiles,
        ...financialsQuizAnswer.quizAnswerFiles,
      ];
    }

    if (!financialRequestsFiles.length && !quizAnswerFiles.length) {
      return;
    }

    const files = [
      ...financialRequestsFiles,
      ...quizAnswerFiles,
    ];

    const documents = files
      .map(file => ({
        url: file.url,
        name: file.name,
      }));

    this._documentViewerService
      .show(documents, {
        width: '95%',
        height: '95%',
        maxWidth: 1800,
        closeOnOutsideClick: false,
        showCloseButton: true,
        title: documents[0]?.name || 'Preview',
        activeIndex: 0,
      });
  }

  openRequestFinancialsDialog(): void {
    const dialogRef = this._dialogService
      .show(RequestFinancialsDialogComponent, {
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 600,
        height: 'auto',
        maxHeight: 700,
        injectableData: {
          leaseId: this.lease.id,
          lease: this.lease
        },
      });

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy),
        tap(() => {
          if (dialogRef.outputData && dialogRef.outputData.createdRequest) {
            this.financialRequestsChange.emit([
              ...this.financialRequests,
              dialogRef.outputData.createdRequest,
            ]);
          }
        }),
      )
      .subscribe();
  }
}
