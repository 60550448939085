<form name="form"
      *ngIf="isVisibleTermForm()"
      (submit)="saveLeaseTerm($event)">
  <div class="row pt-2">
    <h6>Please choose from the following:</h6>
  </div>
  <dx-validation-group>
    <div class="row term-container" *ngIf="!isFirstRadioGroupHidden()">
      <div class="col-sm-12 mb-4 radio-group-container">
        <div class="dx-field-value">
          <dx-radio-group displayExpr="name" valueExpr="value" required
                          [dataSource]="options | filter : 'disabled'" [(value)]="leaseTerm.spaceUseType"
                          (valueChange)="clearErrors()">
            <dx-validator>
              <dxi-validation-rule type="required" message="Select appropriate option">
              </dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>

    <ng-container *ngIf="leaseTerm.spaceUseType === spaceUseTypes.Custom && !isLandlordUser()">
      <div class="row term-container">
        <div class="col-sm-6 dx-field-value pl-0 mb-2">
          <label for="spaceUseSelect">Please pick all that apply:</label>
          <dx-tag-box id="spaceUseSelect" name="space-use-types" displayExpr="text" applyValueMode="useButtons" required
                      [(dataSource)]="spaceUseOptions" [showClearButton]="true" [acceptCustomValue]="true"
                      [(ngModel)]="spaceUseItems"
                      (ngModelChange)="clearErrors()"
                      (onCustomItemCreating)="addCustomUse($event)"
                      #spaceUseValues="ngModel">
            <dx-validator>
              <dxi-validation-rule type="required"
                                   message="Please select at least one of the provided options before saving"
                                   *ngIf="form?.submitted">
              </dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </div>
      </div>

      <div class="row" *ngIf="isProductFieldAvailable()">
        <div class="col-sm-6 pl-0">
          <label for="productType">Insert type of product stored or manufactured in facility (i.e. plastic packaging,
            houseware products etc.):</label>
          <dx-text-box id="productType" name="space-use-product"
                       [(ngModel)]="spaceUseProduct"
                       (ngModelChange)="clearErrors()"
                       #spaceUseProductEl="ngModel">
          </dx-text-box>
        </div>
      </div>

      <div class="row pb-3 additional-info" *ngIf="!isLandlordUser()">
        <div class="additional-info__checkbox">
          <dx-check-box id="additionalInfo" [(value)]="leaseTerm.hasAdditionalInfo"></dx-check-box>
        </div>

        <div>
          <label for="additionalInfo" class="additional-info__title">Click here to provide more details
            (Optional)</label>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="leaseTerm.spaceUseType === spaceUseTypes.Custom && ((!isLandlordUser() && leaseTerm.hasAdditionalInfo) || isLandlordUser())">
      <div class="row mb-4">
        <dx-radio-group displayExpr="name" valueExpr="value" required
                        [dataSource]="additionalInfoOptions" [(value)]="additionalInfoOption"
                        (onValueChanged)="clearErrors(); handleAdditionalInfoOptionChange($event)">
          <dx-validator>
            <dxi-validation-rule type="required" message="Select appropriate option">
            </dxi-validation-rule>
          </dx-validator>
        </dx-radio-group>
      </div>

      <div class="row" *ngIf="additionalInfoOption === AdditionalInfo.Custom">
        <div class="col-8 pl-0">
          <app-term-custom-value-template-textarea id="additionalInfoText" placeholder="Insert additional info text"
                                                   required requiredMessage="Additional info text is required"
                                                   [leaseTermType]="leaseTermConfiguration.leaseTermType"
                                                   [(value)]="leaseTerm.additionalInfo"
                                                   (valueChange)="clearErrors()">
          </app-term-custom-value-template-textarea>
        </div>
      </div>
    </ng-container>

    <!-- Start: Validation summary -->

    <div>
      <dx-validation-summary ngForm="form">
      </dx-validation-summary>
      <div class="term-toast term-toast-error"
           *ngFor="let msg of errors">
        <i inlineSVG="assets/img/x.svg"></i>
        {{ msg }}
      </div>
    </div>

    <!-- End: Validation summary -->

    <app-term-secondary-action-buttons [rejectButtonClicked]="rejectButtonClicked"
                                       (cancelLeaseTerm)="onCancelLeaseTerm()">
    </app-term-secondary-action-buttons>

  </dx-validation-group>
</form>

<app-term-action-buttons *ngIf="isButtonsVisible()"
                         [isAcceptButtonVisible]="isAcceptButtonVisible()"
                         [isCounterButtonVisible]="isCounterButtonVisible()"
                         [isModifyButtonVisible]="isModifyButtonVisible()"
                         [isPreviousButtonVisible]="isPreviousButtonVisible()"
                         [isOkButtonVisible]="isOkButtonVisible()"
                         (acceptButtonClicked)="handleAcceptButtonClick()"
                         (counterButtonClicked)="handleCounterButtonClick()"
                         (modifyButtonClicked)="handleModifyButtonClick()"
                         (previousButtonClicked)="handlePreviousButtonClick()"
                         (okButtonClicked)="handleOkButtonClick()">
</app-term-action-buttons>
