<div class="building-budget">
  <div class="building-budget-list">
    <div class="building-budget-list-current">
      <div class="building-budget-list-current-header">
        <span>CURRENT BUDGET:</span>
        <button class="btn d-flex p-0" inlineSVG="assets/img/signs-icons.svg#icon-plus-100"
                (click)="saveBuildingBudget()"></button>
      </div>
      <div *ngIf="currentBudgetVersion" [ngClass]="{'selected-building-budget': currentBudgetVersion?.buildingBudget?.id === selectedBudgetVersion?.buildingBudget?.id}">
        <ng-container *ngTemplateOutlet="budgetListItemTemplate; context: { buildingBudgetVersion: currentBudgetVersion }">
        </ng-container>
      </div>
    </div>

    <dx-accordion class="building-budget-list-accordion"
                  [collapsible]="true" [animationDuration]="300" [focusStateEnabled]="false">
      <dxi-item title="Budget History">
        <dx-scroll-view width="100%" height="100%">
          <ng-container *ngFor="let budgetListItem of getHistoryBuildingBudgets()">
            <ng-container *ngTemplateOutlet="budgetListItemTemplate; context: { buildingBudgetVersion: budgetListItem }">
            </ng-container>
          </ng-container>
        </dx-scroll-view>
      </dxi-item>
    </dx-accordion>

    <ng-template let-buildingBudgetVersion="buildingBudgetVersion"
                 #budgetListItemTemplate >
      <div class="building-budget-list-item"
           [ngClass]="{'selected-building-budget': selectedBudgetVersion?.buildingBudget?.id === buildingBudgetVersion?.buildingBudget?.id}"
           (click)="selectBuildingBudget(buildingBudgetVersion)"
      >
        <div class="building-budget-list-item-date">As of {{buildingBudgetVersion?.versionAsDate | safeDate: 'MMM d, y, h:mm a' }}</div>
        <div class="building-budget-list-item-name">
          Added By {{ buildingBudgetVersion?.buildingBudget?.createdBy?.displayName }}
        </div>
      </div>
    </ng-template>
  </div>
  <div class="building-budget-version">
    <div class="building-budget-version-header d-flex align-items-center">
      <div class="building-budget-version-header-title">
        <div class="building-budget-version-header-title-icon">
          <i inlineSVG="assets/img/budget.svg"></i>
        </div>
        <span>Current Building Budget</span>
      </div>
      <div class="building-budget-version-header-title-add">
        <button type="button" class="btn btn-primary" (click)="saveBuildingBudget()">
          <i inlineSVG="assets/img/signs-icons.svg#icon-plus-100"></i>
          <span>create a new budget</span>
        </button>
      </div>
    </div>

    <div class="building-budget-version-content">
      <div class="building-budget-version-content-group">
        <h3>INVESTMENT/RETURN</h3>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Investment Basis:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.investmentBasis">{{ selectedBudgetVersion.buildingBudget.investmentBasis | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Investment Date:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.investmentDate">{{ selectedBudgetVersion.buildingBudget.investmentDate | safeDate }}</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Budgeted CapEx:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.budgetedCapex">{{ selectedBudgetVersion.buildingBudget.budgetedCapex | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Total Cost:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.budgetedCapex && selectedBudgetVersion.buildingBudget.investmentBasis">{{ (selectedBudgetVersion.buildingBudget.budgetedCapex + selectedBudgetVersion.buildingBudget.investmentBasis) | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Going In Cap Rate:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.goingInCapRate">{{ selectedBudgetVersion.buildingBudget.goingInCapRate | safeNumber }}%</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Exit Cap Rate:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.exitCapRate">{{ selectedBudgetVersion.buildingBudget.exitCapRate | safeNumber }}%</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Yield-On-Cash:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.yieldOnCash">{{ selectedBudgetVersion.buildingBudget.yieldOnCash | safeNumber }}%</span>
        </div>
      </div>
      <div class="building-budget-version-content-group">
        <h3>MLA</h3>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Market Rent:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.marketRate">{{ selectedBudgetVersion.buildingBudget.marketRate | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item d-flex align-items-center">
          <span class="building-budget-version-content-group-item-header">Market Rent Growths:</span>
          <div *ngIf="selectedBudgetVersion?.buildingBudget?.marketRateGrowths"
               class="d-flex align-items-center">
            <span>{{ getAverageMarketRateGrowth(selectedBudgetVersion.buildingBudget) | safeNumber }}% on Avg.</span>
            <i inlineSVG="assets/img/sign-icons.svg#eye-icon" class="eye-icon"></i>
          </div>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Lease Term:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.leaseTerm">{{ selectedBudgetVersion.buildingBudget.leaseTerm }} months</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Escalations:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.escalations">{{ selectedBudgetVersion?.buildingBudget?.escalations | safeNumber }}%</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Free Rent(Renewal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.freeRentRenewal">{{ selectedBudgetVersion?.buildingBudget?.freeRentRenewal }} months</span>
        </div>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Free Rent(New Deal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.freeRentNewDeal">{{ selectedBudgetVersion?.buildingBudget?.freeRentNewDeal }} months</span>
        </div>
      </div>
      <div class="building-budget-version-content-group">
        <h3>LEASING COMISSIONS</h3>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Tenant Improvements (Renewal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.tenantImprovementsRenewal">{{ selectedBudgetVersion?.buildingBudget?.tenantImprovementsRenewal | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Tenant Improvements (New Deal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.tenantImprovementsNewDeal">{{ selectedBudgetVersion?.buildingBudget?.tenantImprovementsNewDeal | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Downtime:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.downtime">{{ selectedBudgetVersion?.buildingBudget?.downtime }} months</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Renewal Probability:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.renewalProbability">{{ selectedBudgetVersion?.buildingBudget?.renewalProbability | safeNumber }}%</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">OpEx:</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.opEx">{{ selectedBudgetVersion?.buildingBudget?.opEx | safeCurrency }} PSF</span>
        </div>

        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Landlord Rep(Renewal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.landlordRepRenewal">{{ selectedBudgetVersion?.buildingBudget?.landlordRepRenewal | safeNumber }}%</span>
        </div>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Landlord Rep(New Deal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.landlordRepNewDeal">{{ selectedBudgetVersion?.buildingBudget?.landlordRepNewDeal | safeNumber }}%</span>
        </div>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Tenant Rep(Renewal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.tenantRepRenewal">{{ selectedBudgetVersion?.buildingBudget?.tenantRepRenewal | safeNumber }}%</span>
        </div>
        <div class="building-budget-version-content-group-item">
          <span class="building-budget-version-content-group-item-header">Tenant Rep(New Deal):</span>
          <span *ngIf="selectedBudgetVersion?.buildingBudget?.tenantRepNewDeal">{{ selectedBudgetVersion?.buildingBudget?.tenantRepNewDeal | safeNumber }}%</span>
        </div>
      </div>
    </div>
    
    <div *ngIf="selectedBudgetVersion && selectedBudgetVersion.buildingBudget" class="building-budget-version-footer d-flex justify-content-end">
      <button type="button" class="b-btn b-btn-outline-primary btn" (click)="adjustBuildingUnitBudget()" [disabled]="!buildingUnits || buildingUnits.length === 0">
        <i inlineSVG="assets/img/sign-icons.svg#unit-icon"></i>
        <span>Adjust Unit Budgets</span>
      </button>
      <button type="button" class="b-btn b-btn-outline-primary btn" (click)="saveBuildingBudget(selectedBudgetVersion?.buildingBudget)">
        <i inlineSVG="assets/img/sign-icons.svg#building-icon"></i>
        <span>Edit Building Budget</span>
      </button>
    </div>
  </div>
</div>
