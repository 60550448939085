<div class="project-schedule">
<!--  dont delete project-schedule-time-demo class. It uses for tutorial-->
  <div class="project-schedule-time project-schedule-time-demo" *ngIf="isStarted()">
    <ng-container *ngIf="!isFinished()">
      <div class="project-schedule-popover" *ngIf="projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay > 0">
        Good News, your project is moving <span class="highlight highlight-green">{{projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay}} days</span> faster than suggested
      </div>

      <div class="project-schedule-popover" *ngIf="projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay < 0">
        Attention, your project is moving <span class="highlight highlight-red">{{projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay * -1}} days</span> slower than suggested
      </div>
    </ng-container>

    <ng-container *ngIf="isFinished()">
      <div class="project-schedule-popover" *ngIf="projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay > 0">
        Congrats, you're done!  The project finished <span class="highlight highlight-green">{{projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay}} days</span> faster than suggested
      </div>

      <div class="project-schedule-popover" *ngIf="projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay < 0">
        Congrats, you're done!  The project finished <span class="highlight highlight-red">{{projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay * -1}} days</span> slower than suggested
      </div>

      <div class="project-schedule-popover" *ngIf="projectStatistic?.differenceBetweenSuggestedTimeAndCurrentDay === 0">
        Congrats, you're done!  The project finished <span class="highlight highlight-green">On Schedule</span>
      </div>
    </ng-container>

    <p>Current Time<br><span class="highlight highlight-green">{{projectStatistic?.currentStatus}} Day</span></p>
    <div class="circle">VS</div>
    <p>Suggested Time<br><span class="highlight highlight-blue">{{projectStatistic?.suggestedTime}} Day</span></p>

    <div class="gantt-chart-view-info">
      <a role="button" class="cursor-pointer" #ganttChartViewInfo>
        <span class="icon-info mx-2"></span>

        <dx-tooltip [target]="ganttChartViewInfo"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    contentTemplate="content"
                    position="top">
          <div class="gantt-chart-tooltip" *dxTemplate="let data of 'content'">
            <p class="mb-0">
              This is the suggested time frame for the lease negotiation process.  Any relevant notice dates will be reflected in Key Statistics or in the chart.
            </p>
            <p class="mb-0">
              As a default for deals without notice dates, a 6 month marker will be displayed prior to any lease expiration dates provided.
            </p>
          </div>
        </dx-tooltip>
      </a>
    </div>
  </div>

  <ng-container *ngIf="project && projectStatistic">
    <app-gantt-chart #ganttChart [project]="project" [renewalNoticeDate]="projectStatistic.renewalNoticeDate" [lease]="lease">
    </app-gantt-chart>
  </ng-container>

  <ng-container *ngIf="!project">
    <p class="text-center text-muted">Sorry, no data to display, since there is no current deal</p>
  </ng-container>

  <ng-container *ngIf="isStarted()">
    <h2 class="h4">
      <hr />
      <span>Key Statistics</span>
      <hr />
    </h2>
    <!--  Don't delete project-schedule-statistics-demo class. It uses for tutorial-->
    <ul class="project-schedule-statistics project-schedule-statistics-demo">
      <li>
        <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
          <div class="px-2">
            <span class="circle">{{projectStatistic?.daysActive}}</span>
          </div>
          <div class="px-2">
            <span>Days active</span>
          </div>
        </div>
      </li>
      <li>
        <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
          <div class="px-2">
            <span class="circle">{{projectStatistic?.daysSinceLastActivity}}</span>
          </div>
          <div class="px-2">
            <span>Days since last activity</span>
          </div>
        </div>
      </li>
      <li>
        <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
          <div class="px-2">
            <span class="circle">{{projectStatistic?.complete | safePercent}}</span>
          </div>
          <div class="px-2">
            <span>Complete</span>
          </div>
        </div>
      </li>
      <li [ngClass]="{'red' : projectStatistic?.daysLeftForRenewal <= 10}">
        <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
          <div class="px-2">
            <span class="circle">
              <ng-container *ngIf="isRenewalOptionNoticeDateSet(); else noRenewalOptionNoticeDate">
                {{projectStatistic?.daysLeftForRenewal}}
              </ng-container>
              <ng-template #noRenewalOptionNoticeDate>
                N/A
              </ng-template>
            </span>
          </div>
          <div class="px-2">
            <span>Days left prior to renewal option notice</span>
          </div>
          <div class="px-2 ml-auto align-self-start">
            <span id="renewalTooltip" class="icon-helpdesk cursor-pointer"></span>
            <dx-tooltip target="#renewalTooltip" showEvent="dxhoverstart" hideEvent="dxhoverend" maxWidth="350">
              <div *dxTemplate="let data of 'content'">
                This displays any remaining time prior to a Renewal Option notice date, otherwise, 'N/A' will be shown here if none exist or not provided.
              </div>
            </dx-tooltip>
          </div>
        </div>
      </li>
      <li [ngClass]="{'red' : projectStatistic?.daysLeftForLeaseExpiration <= 90}">
        <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
          <div class="px-2">
            <span class="circle">{{projectStatistic?.daysLeftForLeaseExpiration}}</span>
          </div>
          <div class="px-2">
            <span>Days left before lease expiration</span>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
</div>

<app-project-schedule-demo #projectScheduleDemoComponent></app-project-schedule-demo>
