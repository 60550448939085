import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopDevexpressScrollTampering]',
})
export class StopDevexpressScrollTamperingDirective {
  constructor() {}

  @HostListener('wheel', ['$event'])
  handleWheel(event: MouseEvent) {
    event.stopPropagation();
  }
}
