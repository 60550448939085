import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as analyticsFiltersModels from '@statera/sdk/analytics-filters';

@Component({
  selector: 'app-analytics-filters-location-address',
  templateUrl: 'analytics-filters-location-address.component.html',
  styleUrls: ['analytics-filters-location-address.component.scss'],
})
export class AnalyticsFiltersLocationAddressComponent {
  @Input() locationAddresses: Array<analyticsFiltersModels.LocationAddressFilter>;
  @Output() locationAddressesChange: EventEmitter<Array<analyticsFiltersModels.LocationAddressFilter>>;

  constructor() {
    this.locationAddressesChange = new EventEmitter<Array<analyticsFiltersModels.LocationAddressFilter>>();
  }

  handleAddressSelection(): void {
    this.locationAddressesChange.next([...this.locationAddresses]);
  }
}
