import { Component, EventEmitter, Output } from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import { EmojiSupportCacheService } from '../../services/emoji-support-cache.service';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent {
  @Output() picked: EventEmitter<EmojiData>;

  private readonly _emojiSupportCacheService: EmojiSupportCacheService;

  constructor(emojiSupportCacheService: EmojiSupportCacheService) {
    this.picked = new EventEmitter<EmojiData>();

    this.emojisToShowFilter = this.emojisToShowFilter.bind(this);

    this._emojiSupportCacheService = emojiSupportCacheService;
  }

  handleEmojiClick(event: EmojiEvent): void {
    event.$event.preventDefault();
    event.$event.stopPropagation();
    event.$event.stopImmediatePropagation();
    this.picked.emit(event.emoji);
  }

  // ngx-emoji-mart library uses a set of native emojis
  // several of which might not be supported on some OS versions.
  // We have to filter them out as the library itself doesn't handle that.
  emojisToShowFilter(data: string | EmojiData): boolean {
    const unified = typeof data === 'string' ? data : data.unified;
    return this._emojiSupportCacheService.isEmojiSupported(unified);
  }
}
