import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TermManager } from '@statera/sdk/term';

import { AnalyticsTooltipService, Context } from '../../services/analytics-tooltip.service';

import { ActiveDealsByStageReportTooltip } from '../../models/active-deals-by-stage-report-tooltip.model';
import { IActiveDealsByStageReportStageViewModel } from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'active-deals-by-stage-report-tooltip.component.html',
  styleUrls: ['active-deals-by-stage-report-tooltip.component.scss'],
})
export class ActiveDealsByStageReportTooltipComponent implements OnInit, AfterViewInit {
  @ViewChild('activeDealsByStageTooltipElementRef') activeDealsByStageTooltipElementRef: ElementRef;

  readonly injectableData: {
    analyticsTooltipService: AnalyticsTooltipService<ActiveDealsByStageReportTooltipComponent, ActiveDealsByStageReportTooltip>,
    model: ActiveDealsByStageReportTooltip,
    context: Context,
  };

  activeDealsByStageReportTooltip: ActiveDealsByStageReportTooltip;
  totalSF: number;

  private readonly _termManager: TermManager;

  constructor(termManager: TermManager) {
    this._termManager = termManager;
  }

  ngOnInit(): void {
    if (!this.injectableData || !this.injectableData.model) {
      return;
    }

    this.activeDealsByStageReportTooltip = this.injectableData.model;
    this.totalSF = this.activeDealsByStageReportTooltip.stages.reduce(
      (totalSF: number, stage: IActiveDealsByStageReportStageViewModel) => {
        return this._termManager.getCurrentTenantSquareFootageNumber(stage.tenantSquareFootageTerm) + totalSF;
      },
      0,
    );
  }

  ngAfterViewInit(): void {
    if (
      !this.injectableData || !this.injectableData.context ||
      !this.activeDealsByStageTooltipElementRef || !this.activeDealsByStageTooltipElementRef.nativeElement
    ) {
      return;
    }

    const {canvasElement, tooltipModel} = this.injectableData.context;
    if (!canvasElement || !tooltipModel) {
      return;
    }

    const canvasBoundingClientRect = canvasElement.getBoundingClientRect();

    const tooltipElement = this.activeDealsByStageTooltipElementRef.nativeElement;

    tooltipElement.style.left = `${canvasBoundingClientRect.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipElement.style.top = `${canvasBoundingClientRect.top + window.pageYOffset + tooltipModel.caretY}px`;
  }
}
