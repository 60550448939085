import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { MonitoringService } from './monitoring.service';

@Injectable()
export class MonitoringExceptionHandlerService extends ErrorHandler {
  private readonly _monitoringService: MonitoringService;
  private readonly _loggerService: LoggerService;

  constructor(monitoringService: MonitoringService, loggerService: LoggerService) {
    super();

    this._monitoringService = monitoringService;
    this._loggerService = loggerService;
  }

  handleError(error: any): void {
    this._monitoringService.trackException(error);
    this._loggerService.error(LoggerTopic.General, 'Error handled via monitoring exception handler:', error);
  }
}
