import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, delay, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DxTextBoxComponent, DxFileUploaderComponent } from 'devextreme-angular';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager, Role } from '@statera/sdk/auth';
import { CommonTools } from '@statera/sdk/common';
import { UserManager } from '@statera/sdk/user';

import { ComponentCanDeactivate } from '../../../infrastructure/guards/unsaved-changes.guard';

import { AlertService } from '../../../alert/services/alert.service';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../../auth/services/auth.service';
import { DialogService } from '../../../dialog/services/dialog.service';

import { AvatarEditDialogComponent } from '../avatar-edit-dialog/avatar-edit-dialog.component';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild('companyPhone', { read: DxTextBoxComponent }) companyPhone: DxTextBoxComponent;
  @ViewChild('mobilePhone', { read: DxTextBoxComponent }) mobilePhone: DxTextBoxComponent;
  @ViewChild('imageUploader', { read: DxFileUploaderComponent }) imageUploader: DxFileUploaderComponent;
  @ViewChild('form', { read: NgForm }) form: NgForm;

  vm: models.IProfileViewModel;

  primaryEmail: string;
  InvestorType = models.InvestorType;
  investorTypes = CommonTools.EnumToArray(models.InvestorType);
  industries = <Array<models.IIndustry>>[];
  submitted = false;
  states: Array<models.IState> = [];
  countries: Array<models.ICountry> = [];
  phoneRules = {X: /[02-9]/};
  avatarUrl: string;
  displayName: string;
  role: string;
  logoSettings: models.ILogotypeSettings;
  uploadedFile: models.IFileViewModel;

  isProfileCompleted$: Observable<boolean>;

  avatarDeletingState: 'delete' | 'deleting...' | 'deleted' = 'delete';

  Roles: typeof Role = Role;

  isDirty$: Observable<boolean>;

  private readonly _authService: AuthService;
  private readonly _profileService: ProfileService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogService: DialogService;
  private readonly _userManager: UserManager;
  private readonly _authManager: AuthManager;
  private readonly _router: Router;

  private readonly _destroy$ = new Subject();

  constructor(
    profileService: ProfileService,
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogService: DialogService,
    userManager: UserManager,
    authManager: AuthManager,
    router: Router,
  ) {
    this._authService = authService;
    this._profileService = profileService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogService = dialogService;
    this._userManager = userManager;
    this._authManager = authManager;
    this._router = router;
  }

  ngOnInit() {
    this.vm = { ...this._profileService.emptyProfile };

    this.isProfileCompleted$ = this._authService
      .infoLoadComplete
      .pipe(
        map(info => !info.emptyProfile),
      );

    this._userManager
      .userChange$
      .pipe(takeUntil(this._destroy$))
      .subscribe((profile) => {
        this.formLoad(profile);
      });

    this._authService
      .infoLoadComplete
      .pipe(takeUntil(this._destroy$))
      .subscribe((x) => {
        if (x) {
          this.primaryEmail = x.primaryEmail;
        }

        this.avatarUrl = this._authService.avatarUrl;
        this.displayName = this._authService.displayName;
        this.role = this._authService.role;
        this.industries = x.lists.industries;
        this.logoSettings = x.logotypeSettings;
      });

    this._userManager.loadUser();
  }

  ngAfterViewInit() {
    this.isDirty$ = combineLatest([
      this._userManager.userChange$,
      this.form.valueChanges,
    ])
      .pipe(
        map(([profile, formValues]: [models.IProfileViewModel, Partial<models.IProfileViewModel>]) => {
          if (formValues.mobilePhone === '') { formValues.mobilePhone = null; }
          return (
            formValues.firstName !== profile.firstName ||
            formValues.lastName !== profile.lastName ||
            formValues.mobilePhone !== profile.mobilePhone ||
            formValues.additionalEmailAddress !== profile.additionalEmailAddress ||
            formValues.title !== profile.title
          );
        }),
        startWith(false),
        shareReplay({ bufferSize: 1, refCount: true }),
        takeUntil(this._destroy$),
      );
    this.isDirty$.subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> {
    return this.isDirty$
      .pipe(
        switchMap((isDirty) => of(!isDirty)),
        take(1)
      );
  }

  onMobilePhoneChange(value: string) {
    if (!value || value === '+1 (   )    -') {
      this.vm.mobilePhone = null;
    } else {
      this.vm.mobilePhone = value;
    }
  }

  formLoad(model: models.IProfileViewModel) {
    model = model ? model : this._profileService.emptyProfile;

    if (!model.company.addresses) {
      model.company.addresses = this._profileService.emptyAddresses;
    } else if (model.company.addresses.length < this._profileService.emptyAddresses.length) {
      model.company.addresses = [...model.company.addresses, ...this._profileService.emptyAddresses].slice(
        0,
        this._profileService.emptyAddresses.length,
      );
    }
    model.company.addresses.forEach(
      a => (a.name = a.isPrimary ? this._profileService.primaryAddressName : this._profileService.otherAddressName),
    );
    this.vm = { ...model };
    if (!this.vm.mobilePhone && this.mobilePhone.instance) {
      this.mobilePhone.instance.reset();
      this.mobilePhone.instance.option({isValid: true});
      this.vm.mobilePhone = null;
    }
  }

  submit(form: NgForm) {
    if (form.invalid || !this.mobilePhone.isValid) {
      return;
    }

    this._profileService
      .save({...this.vm})
      .pipe(
        take(1),
        tap(() => {
          this._userManager.loadUser();
          this._userManager
            .userChange$
            .pipe(
              take(1),
              tap(() => {
                this._authService.getUpdatedStartupInfo();
                this._authService
                  .infoLoadComplete
                  .pipe(
                    take(1),
                    tap(() => {
                      window.scrollTo(0, 0);

                      let navigationUrl = '';
                      switch (this.role) {
                        case Role.Landlord:
                          navigationUrl = '/landlord/dashboard';
                          break;

                        case Role.Tenant:
                          navigationUrl = '/tenant/dashboard';
                          break;

                        case Role.Broker:
                          navigationUrl = '/broker/dashboard';
                          break;

                        default:
                          return;
                      }

                      this._router
                        .navigate([navigationUrl])
                        .finally(() => {
                          this._alertService.pushSuccessAlert({
                            message: this._alertMessagesManager.getProfileSavedAlertText(),
                          });
                        });
                    })
                  )
                  .subscribe();
              })
            )
            .subscribe();
        }),
      )
      .subscribe({
        error: (error) => {
          if (error && error.error) {
            this._alertService.pushErrorAlert({
              message: error.error,
            });
          }
        },
      });
  }

  onUploadPictureButtonClicked() {
    this._dialogService.show(AvatarEditDialogComponent, {
      title: 'Edit Avatar',
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 450,
      maxHeight: '100%',
      injectableData: {
        profile: this.vm,
      },
    });
  }

  deleteAvatar(): void {
    this.avatarDeletingState = 'deleting...';

    const subscription = this._userManager
      .handleAvatarDelete()
      .pipe(
        delay(500),
        switchMap(() => this._authManager.getIndependentStartupInfo()),
        catchError(err => {
          this._alertService.pushErrorAlert({
            message: err.message || err.error || err,
          });
          return of(err);
        }),
      )
      .subscribe(
        (startupInfo) => {
          this._authService.setStartupInfo(startupInfo);

          this.avatarDeletingState = 'deleted';
          setTimeout(() => this.avatarDeletingState = 'delete', 1500);

          subscription.unsubscribe();
        },
        () => {
          this.avatarDeletingState = 'delete';
          subscription.unsubscribe();
        },
      );
  }

  filterEmptyAddresses(addresses: Array<models.IAddressViewModel>): Array<models.IAddressViewModel> {
    return addresses.filter(x =>
      x.addressLine1 &&
      x.addressLine2 &&
      x.city &&
      x.stateCode &&
      x.zipCode &&
      x.countryCode
    );
  }
}
