import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { take } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { AlertService } from '../../../alert/services/alert.service';

import { IPortfolioViewModel } from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-add-portfolio',
  templateUrl: './add-portfolio.component.html',
  styleUrls: ['./add-portfolio.component.scss']
})
export class AddPortfolioComponent implements OnInit {
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _router: Router;

  @ViewChild('form') form: any;
  portfolios: Array<IPortfolioViewModel> = [];
  vm = <IPortfolioViewModel>{};
  submitted = false;

  constructor(
    landlordStore: LandlordStore,
    landlordManager: LandlordManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    router: Router,
  ) {
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
  }

  ngOnInit() {
    this._landlordStore
      .portfolios$
      .pipe(
        take(1)
      )
      .subscribe((portfolios) => {
        this.portfolios = portfolios;
      });
  }

  submit(form: NgForm) {
    if (this.submitted || form.invalid) {
      return;
    }
    this.submitted = true;

    this._landlordManager
      .addPortfolio({...this.vm})
      .subscribe((portfolios: Array<IPortfolioViewModel>) => {
        this._alertService.pushSuccessAlert({
          message: this._alertMessagesManager.getPortfolioCreatedAlertText(),
        });

        this.form.reset();
        this.form.submitted = false;
        this.vm = <IPortfolioViewModel>{};
        if (portfolios && portfolios.length > 0) {
          const portfolio = portfolios.sort((a, b) => b.id - a.id)[0];
          if (portfolio) {
            confirm(`Portfolio '${portfolio.name}' was created, do you want to add a building to it?`,
              'Suggestion').done(confirmed => {
              if (confirmed) {
                this._landlordStore.preSelectedPortfolio$.next(portfolio);
                this._router.navigate(['/landlord/buildings/add-building']);
              }
            });
          }
        }
      })
      .add(() => this.submitted = false);
  }
}
