import { Component, Input } from '@angular/core';

import { SafeDatePipe } from '../../../../../../infrastructure/pipes/safe-date.pipe';

import * as models from '../../../../../../infrastructure/models/generated';

@Component({
  selector: 'app-rental-rate-escalation-result-table',
  templateUrl: './rental-rate-escalation-result-table.component.html',
  styleUrls: ['./rental-rate-escalation-result-table.component.scss']
})
export class RentalRateEscalationResultTableComponent {
  @Input() customRentalRateEscalationCustomResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>;
  @Input() fullTable: boolean;
  @Input() isLineThrough = false;
  @Input() isTenant = false;
  @Input() isLandlord = false;
  @Input() isAccepted = false;
  @Input() unitMetrics: models.BaseRentalRateUnitMetrics;

  private readonly _safeDatePipe: SafeDatePipe;

  constructor(safeDatePipe: SafeDatePipe) {
    this._safeDatePipe = safeDatePipe;
  }

  showPercentColumn(): boolean {
    if (this.fullTable) {
      return true;
    }

    if (this.customRentalRateEscalationCustomResults) {
      const result = this.customRentalRateEscalationCustomResults
        .find(x => (
          x.rentalRateAnnualEscalationTermCustomValueType === models.RentalRateAnnualEscalationTermCustomValueType.FixedPercentagePerYear
        ));

      if (result) {
        return true;
      }
    }

    return false;
  }

  showPsfValueColumn(): boolean {
    if (this.fullTable) {
      return true;
    }

    if (this.customRentalRateEscalationCustomResults) {
      const result = this.customRentalRateEscalationCustomResults
        .find(x => (
          x.rentalRateAnnualEscalationTermCustomValueType === models.RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear
        ));

      if (result) {
        return true;
      }
    }

    return false;
  }

  getUnitMetricsDisplayValue(): string {
    let unitMetrics = '$ / PSF / Yr';
    if (!this.unitMetrics) {
      return unitMetrics;
    }

    if (this.unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = '$ / PSF / Mo';
    }

    return unitMetrics;
  }
}
