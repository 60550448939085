import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxAccordionModule, DxPopupModule, DxRangeSelectorModule, DxScrollViewModule, DxTooltipModule } from 'devextreme-angular';
import { NgChartsModule, ThemeService } from 'ng2-charts';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { ExpirationsReportComponent } from './components/expirations-report/expirations-report.component';
import { ExpirationsReportSinglePeriodComponent } from './components/expirations-report-single-period/expirations-report-single-period.component';
import { ExpirationsReportSinglePeriodDialogComponent } from './components/expirations-report-single-period-dialog/expirations-report-single-period-dialog.component';
import { ExpirationsReportSinglePeriodTableComponent } from './components/expirations-report-single-period-table/expirations-report-single-period-table.component';
import { ExpirationsReportTooltipComponent } from './components/expirations-report-tooltip/expirations-report-tooltip.component';
import { OccupancyAndVacancyReportComponent } from './components/occupancy-and-vacancy-report/occupancy-and-vacancy-report.component';
import { OccupancyAndVacancyReportSinglePeriodComponent } from './components/occupancy-and-vacancy-report-single-period/occupancy-and-vacancy-report-single-period.component';
import { OccupancyAndVacancyReportSinglePeriodDialogComponent } from './components/occupancy-and-vacancy-report-single-period-dialog/occupancy-and-vacancy-report-single-period-dialog.component';
import { OccupancyAndVacancyReportSinglePeriodTableComponent } from './components/occupancy-and-vacancy-report-single-period-table/occupancy-and-vacancy-report-single-period-table.component';
import { OccupancyAndVacancyReportTooltipComponent } from './components/occupancy-and-vacancy-report-tooltip/occupancy-and-vacancy-report-tooltip.component';
import { DealsByStageReportComponent } from './components/deals-by-stage-report/deals-by-stage-report.component';
import { DealsByStageReportSingleStageComponent } from './components/deals-by-stage-report-single-stage/deals-by-stage-report-single-stage.component';
import { DealsByStageReportSingleStageTooltipComponent } from './components/deals-by-stage-report-single-stage-tooltip/deals-by-stage-report-single-stage-tooltip.component';
import { DealsByStageReportSingleStageDialogComponent } from './components/deals-by-stage-report-single-stage-dialog/deals-by-stage-report-single-stage-dialog.component';
import { DealsByStageReportSingleStageTableComponent } from './components/deals-by-stage-report-single-stage-table/deals-by-stage-report-single-stage-table.component';
import { DealsByStageReportTooltipComponent } from './components/deals-by-stage-report-tooltip/deals-by-stage-report-tooltip.component';
import { ActiveDealsByStageReportComponent } from './components/active-deals-by-stage-report/active-deals-by-stage-report.component';
import { ActiveDealsByStageReportSingleStageDialogComponent } from './components/active-deals-by-stage-report-single-stage-dialog/active-deals-by-stage-report-single-stage-dialog.component';
import { ActiveDealsByStageReportSingleStageTableComponent } from './components/active-deals-by-stage-report-single-stage-table/active-deals-by-stage-report-single-stage-table.component';
import { ActiveDealsByStageReportTooltipComponent } from './components/active-deals-by-stage-report-tooltip/active-deals-by-stage-report-tooltip.component';
import { AnalyticsFiltersComponent } from './components/analytics-filters/analytics-filters.component';
import { AnalyticsFiltersCheckboxComponent } from './components/analytics-filters-checkbox/analytics-filters-checkbox.component';
import { AnalyticsFiltersPortfolioComponent } from './components/analytics-filters-portfolio/analytics-filters-portfolio.component';
import { AnalyticsFiltersLocationMarketComponent } from './components/analytics-filters-location-market/analytics-filters-location-market.component';
import { AnalyticsFiltersLocationStateComponent } from './components/analytics-filters-location-state/analytics-filters-location-state.component';
import { AnalyticsFiltersLocationSubMarketComponent } from './components/analytics-filters-location-submarket/analytics-filters-location-submarket.component';
import { AnalyticsFiltersLocationCityComponent } from './components/analytics-filters-location-city/analytics-filters-location-city.component';
import { AnalyticsFiltersLocationZipCodeComponent } from './components/analytics-filters-location-zip-code/analytics-filters-location-zip-code.component';
import { AnalyticsFiltersLocationAddressComponent } from './components/analytics-filters-location-address/analytics-filters-location-address.component';
import { AnalyticsFiltersDealTypeComponent } from './components/analytics-filters-deal-type/analytics-filters-deal-type.component';
import { AnalyticsFiltersTenantCompanyComponent } from './components/analytics-filters-tenant-company/analytics-filters-tenant-company.component';
import { AnalyticsFiltersIndustryComponent } from './components/analytics-filters-industry/analytics-filters-industry.component';
import { AnalyticsFiltersSpaceUseComponent } from './components/analytics-filters-space-use/analytics-filters-space-use.component';
import { AnalyticsFiltersLeaseExpirationComponent } from './components/analytics-filters-lease-expiration/analytics-filters-lease-expiration.component';
import { AnalyticsFiltersSquareFeetComponent } from './components/analytics-filters-square-feet/analytics-filters-square-feet.component';
import { KeyStatisticsReportComponent } from './components/key-statistics-report/key-statistics-report.component';
import { KeyStatisticsReportExportDialogComponent } from './components/key-statistics-report-export-dialog/key-statistics-report-export-dialog.component';

import { AnalyticsManager } from '@statera/sdk/analytics';
import { AnalyticsTooltipService } from './services/analytics-tooltip.service';
import { AnalyticsTooltipFactoryService } from './services/analytics-tooltip-factory.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    FormsModule,
    DxAccordionModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTooltipModule,
    DxRangeSelectorModule,
    InfrastructureModule,
    SharedModule,
  ],
  declarations: [
    ExpirationsReportComponent,
    ExpirationsReportSinglePeriodComponent,
    ExpirationsReportSinglePeriodDialogComponent,
    ExpirationsReportSinglePeriodTableComponent,
    ExpirationsReportTooltipComponent,
    OccupancyAndVacancyReportComponent,
    OccupancyAndVacancyReportSinglePeriodComponent,
    OccupancyAndVacancyReportSinglePeriodDialogComponent,
    OccupancyAndVacancyReportSinglePeriodTableComponent,
    OccupancyAndVacancyReportTooltipComponent,
    DealsByStageReportComponent,
    DealsByStageReportSingleStageComponent,
    DealsByStageReportSingleStageDialogComponent,
    DealsByStageReportSingleStageTableComponent,
    DealsByStageReportSingleStageTooltipComponent,
    DealsByStageReportTooltipComponent,
    ActiveDealsByStageReportComponent,
    ActiveDealsByStageReportSingleStageDialogComponent,
    ActiveDealsByStageReportSingleStageTableComponent,
    ActiveDealsByStageReportTooltipComponent,
    AnalyticsFiltersComponent,
    AnalyticsFiltersCheckboxComponent,
    AnalyticsFiltersPortfolioComponent,
    AnalyticsFiltersLocationMarketComponent,
    AnalyticsFiltersLocationStateComponent,
    AnalyticsFiltersLocationSubMarketComponent,
    AnalyticsFiltersLocationCityComponent,
    AnalyticsFiltersLocationZipCodeComponent,
    AnalyticsFiltersLocationAddressComponent,
    AnalyticsFiltersDealTypeComponent,
    AnalyticsFiltersTenantCompanyComponent,
    AnalyticsFiltersIndustryComponent,
    AnalyticsFiltersSpaceUseComponent,
    AnalyticsFiltersLeaseExpirationComponent,
    AnalyticsFiltersSquareFeetComponent,
    KeyStatisticsReportComponent,
    KeyStatisticsReportExportDialogComponent,
  ],
  providers: [
    ThemeService,
    AnalyticsManager,
    AnalyticsTooltipService,
    AnalyticsTooltipFactoryService,
  ],
  exports: [
    ExpirationsReportComponent,
    OccupancyAndVacancyReportComponent,
    DealsByStageReportComponent,
    ActiveDealsByStageReportComponent,
    AnalyticsFiltersComponent,
    KeyStatisticsReportComponent,
  ],
  entryComponents: [
    ExpirationsReportSinglePeriodDialogComponent,
    ExpirationsReportTooltipComponent,
    OccupancyAndVacancyReportSinglePeriodDialogComponent,
    OccupancyAndVacancyReportTooltipComponent,
    DealsByStageReportSingleStageDialogComponent,
    DealsByStageReportSingleStageComponent,
    DealsByStageReportSingleStageTooltipComponent,
    DealsByStageReportTooltipComponent,
    ActiveDealsByStageReportSingleStageDialogComponent,
    ActiveDealsByStageReportTooltipComponent,
  ],
})
export class AnalyticsModule {
  static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        AnalyticsManager,
        AnalyticsTooltipService,
        AnalyticsTooltipFactoryService,
      ],
    };
  }
}
