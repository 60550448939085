import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxLoadPanelModule } from 'devextreme-angular';
import { SignatureComponent } from './components/signature/signature.component';
import { SignatureByTokenComponent } from './components/signature-by-token/signature-by-token.component';
import { SignatureRouter } from './signature.router';

@NgModule({
  declarations: [SignatureComponent, SignatureByTokenComponent],
  imports: [
    CommonModule,
    DxLoadPanelModule,
    SignatureRouter
  ]
})
export class SignatureModule { }
