import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class DialogRefService {
  id: number;

  hide: () => void;
  hideAll: () => void;
  repaint: () => void;
  setTitle: (title: string) => void;

  onShowing: EventEmitter<any>;
  onShown: EventEmitter<any>;
  onHiding: EventEmitter<any>;
  onHidden: EventEmitter<any>;

  onContentReady: EventEmitter<any>;

  outputData: any;

  constructor() {
    this.hide = Function;
    this.setTitle = Function;

    this.onShowing = new EventEmitter<any>(true);
    this.onShown = new EventEmitter<any>(true);
    this.onHiding = new EventEmitter<any>(true);
    this.onHidden = new EventEmitter<any>(true);

    this.onContentReady = new EventEmitter<any>(true);
  }
}
