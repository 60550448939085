import { Component, HostListener, Input, ViewChild, ViewChildren } from '@angular/core';
import { I18nPluralPipe, Location } from '@angular/common';
import { DxTooltipComponent } from 'devextreme-angular';

import * as ng from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../infrastructure/models/generated';
import { ProjectService } from '../../services/project.service';

export interface GanttChartPoint {
  index: number;
  daysCount: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  group: string;
  milestone: models.IProjectMilestoneViewModel;
  style?: {
    width: number;
    left: number;
  };
}

export interface GanttChartDatePoint {
  month: number;
  year: number;
  days: Array<number>;
}

enum GanttChartPointStatus {
  Undefined,
  Complete,
  InProgress,
  Open,
}

@Component({
  selector: 'app-gantt-chart',
  templateUrl: 'gantt-chart.component.html',
  styleUrls: ['gantt-chart.component.scss'],
})
export class GanttChartComponent implements ng.OnInit, ng.AfterViewInit, ng.OnDestroy {
  private static readonly _minDayColumnWidth = 24;

  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _renderer: ng.Renderer2;
  private readonly _location: Location;
  private readonly _projectService: ProjectService;

  @ViewChild('ganttChartWrapper', { read: ng.ElementRef }) wrapper: ng.ElementRef;
  @ViewChild('ganttChartAside', { read: ng.ElementRef }) aside: ng.ElementRef;
  @ViewChild('ganttChartScroller', { read: ng.ElementRef }) scroller: ng.ElementRef;
  @ViewChild('ganttChartColumns', { read: ng.ElementRef }) columnsContainer: ng.ElementRef;
  @ViewChildren('ganttChartColumn') columns: ng.QueryList<ng.ElementRef>;
  @ViewChildren('ganttChartPoint') points: ng.QueryList<ng.ElementRef>;
  @ViewChildren('ganttChartScheduleLabel') scheduleLabels: ng.QueryList<ng.ElementRef>;

  @Input() project: models.IProjectViewModel;
  @Input() renewalNoticeDate: Date | string;
  @Input() columnsBefore: number;
  @Input() columnsAfter: number;
  @Input() scrollToTodayColumn: boolean;
  @Input() blinkTimeout: number;
  @Input() blinksCount: number;
  @Input() lease: models.ILeaseViewModel;

  datePoints: Array<GanttChartDatePoint>;

  chartPointStatuses: typeof GanttChartPointStatus;

  isStatusesVisible: boolean;

  private _chartPointsCache: Array<GanttChartPoint>;
  private _projectMilestones: Array<models.IProjectMilestoneViewModel>;

  private readonly _todayDate: moment.Moment;
  private _columnWidth: number;

  private _firstMilestone: models.IProjectMilestoneViewModel;
  private _lastMilestone: models.IProjectMilestoneViewModel;

  private _renderedDaysCount: number;
  private _shouldRerender: boolean;

  private _sixMonthsPriorToLXPDateValue?: moment.Moment;
  private get _sixMonthsPriorToLXPDate() {
    if (!this._sixMonthsPriorToLXPDateValue && this.lease) {
      const leaseExpirationDate = moment(this.lease.expiration).startOf('day');
      this._sixMonthsPriorToLXPDateValue = leaseExpirationDate.subtract(6, 'months');
    }

    return this._sixMonthsPriorToLXPDateValue;
  }

  private readonly _destroy$: Subject<void>;
  private readonly _i18PluralPipe: I18nPluralPipe;

  constructor(
    changeDetectorRef: ng.ChangeDetectorRef,
    renderer: ng.Renderer2, location: Location,
    projectService: ProjectService,
    i18PluralPipe: I18nPluralPipe
  ) {
    this._changeDetectorRef = changeDetectorRef;
    this._renderer = renderer;
    this._location = location;
    this._projectService = projectService;
    this._i18PluralPipe = i18PluralPipe;

    this.datePoints = [];
    this._todayDate = moment().startOf('day');
    this._columnWidth = GanttChartComponent._minDayColumnWidth;
    this.chartPointStatuses = GanttChartPointStatus;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.project = this.project || <models.IProjectViewModel>{};
    this.columnsBefore = typeof this.columnsBefore === 'number' ? this.columnsBefore : 1;
    this.columnsAfter = typeof this.columnsAfter === 'number' ? this.columnsAfter : 1;
    this.scrollToTodayColumn = typeof this.scrollToTodayColumn === 'boolean' ? this.scrollToTodayColumn : true;
    this.blinkTimeout = typeof this.blinkTimeout === 'number' ? this.blinkTimeout : 250;
    this.blinksCount = typeof this.blinksCount === 'number' ? this.blinksCount : 2;
    this.isStatusesVisible = false;

    this._configureGanttChart();
  }

  ngAfterViewInit(): void {
    if (!this.columns || !this.columns.length) {
      return;
    }

    this._setOptimalDayColumnWidth();
    this._fixupScheduleLabels();

    if (this.scrollToTodayColumn) {
      const todayColumn = this.columns.find(x => x.nativeElement && x.nativeElement.classList.contains('today'));
      if (todayColumn && todayColumn.nativeElement) {
        todayColumn.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
      }
    }

    this.scheduleLabels
      .changes
      .pipe(
        takeUntil(this._destroy$),
        tap((elementRefs) => {
          this._fixupScheduleLabels(elementRefs);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize(): void {
    this._setOptimalDayColumnWidth();
    this._fixupScheduleLabels();
  }

  getChartPoints(): Array<GanttChartPoint> {
    if (!this._shouldRerender && this._chartPointsCache && this._chartPointsCache.length) {
      return this._chartPointsCache;
    }

    const chartPoints = [];

    for (let i = 0, num = this._projectMilestones.length; i < num; i++) {
      const milestone = this._projectMilestones[i];

      const chartPointStartDate = moment(milestone.startDate).startOf('day');
      const chartPointEndDate = moment(milestone.endDate).startOf('day');
      const daysCount = chartPointEndDate.diff(chartPointStartDate, 'days') + 1 /* include start day */;

      const chartPoint: GanttChartPoint = {
        index: i,
        startDate: chartPointStartDate,
        endDate: chartPointEndDate,
        group: milestone.templateItem.name,
        daysCount: daysCount,
        milestone: milestone,
      };

      chartPoint.style = {
        width: this._getChartPointWidth(chartPoint),
        left: this._getChartPointOffset(chartPoint),
      };

      chartPoints.push(chartPoint);
    }

    this._chartPointsCache = chartPoints;
    this._shouldRerender = false;

    return chartPoints;
  }

  getDatePointMonth(datePoint: GanttChartDatePoint): string {
    return `${moment.months(datePoint.month)} ${datePoint.year}`;
  }

  isTodayDatePoint(datePoint: GanttChartDatePoint, day: number): boolean {
    const datePointDate = this.getDatePointDate(datePoint, day);
    const todayDate = this._todayDate.clone().startOf('day');

    return datePointDate.isSame(todayDate);
  }

  isEndDatePoint(datePoint: GanttChartDatePoint, day: number): boolean {
    if (!this._projectMilestones || !this._projectMilestones.length) {
      return false;
    }

    const datePointDate = this.getDatePointDate(datePoint, day);

    const lastMilestone = this._getLastMilestone();
    const endProjectDate = moment(lastMilestone.endDate).startOf('day');

    return datePointDate.isSame(endProjectDate);
  }

  isSixMonthPriorToLXP(datePoint: GanttChartDatePoint, day: number): boolean {
    if (!this.lease || !this.lease.expiration || this.lease.renewalOptionTerm.value != null) {
      return false;
    }

    return this.getDatePointDate(datePoint, day).isSame(this._sixMonthsPriorToLXPDate);
  }

  isLeaseExpirationDatePoint(datePoint: GanttChartDatePoint, day: number): boolean {
    if (!this.lease || !this.lease.expiration) {
      return false;
    }

    const leaseExpirationDate = moment(this.lease.expiration).startOf('day');
    return this.getDatePointDate(datePoint, day).isSame(leaseExpirationDate);
  }

  getDatePointDate(datePoint: GanttChartDatePoint, day: number): moment.Moment {
    return moment([datePoint.year, datePoint.month, day]).startOf('day');
  }

  scrollToChartPoint(point: GanttChartPoint): void {
    if (!point) {
      return;
    }

    const pointElement = this.points.find(x => x.nativeElement && (x.nativeElement.id === `gantt-chart-point-${point.index}`));
    if (!pointElement || !pointElement.nativeElement) {
      return;
    }

    let blinksCount = 0;
    const blink = () => {
      pointElement.nativeElement.classList.add('blink');

      setTimeout(
        () => {
          pointElement.nativeElement.classList.remove('blink');
          blinksCount++;

          setTimeout(
            () => {
              if (blinksCount < this.blinksCount) {
                blink();
              }
            },
            this.blinkTimeout / (2 *  blinksCount),
          );
        },
        this.blinkTimeout,
      );
    };

    const intersectionObserver = new IntersectionObserver((entries: Array<IntersectionObserverEntry>): void => {
      const [ entry ] = entries;
      if (!entry.isIntersecting) {
        return;
      }

      blink();
      intersectionObserver.disconnect();
      intersectionObserver.unobserve(pointElement.nativeElement);
    });

    intersectionObserver.observe(pointElement.nativeElement);
    pointElement.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  }

  getChartPointStatus(point: GanttChartPoint): GanttChartPointStatus {
    if (!point) {
      return GanttChartPointStatus.Undefined;
    }

    if (this._isCompletedProject()) {
      return GanttChartPointStatus.Complete;
    }

    const activePoint = this._getActiveChartPoint();
    if (!activePoint) {
      return GanttChartPointStatus.Undefined;
    }

    if (point.index < activePoint.index) {
      return GanttChartPointStatus.Complete;
    }

    if (point.index === activePoint.index) {
      return GanttChartPointStatus.InProgress;
    }

    if (activePoint.index < point.index) {
      return GanttChartPointStatus.Open;
    }

    return GanttChartPointStatus.Undefined;
  }

  getChartPointStatusText(point: GanttChartPoint): string {
    if (!point) {
      return 'Undefined';
    }

    const chartPointStatus = this.getChartPointStatus(point);

    switch (chartPointStatus) {
      case GanttChartPointStatus.Open:
        return 'Open';

      case GanttChartPointStatus.InProgress:
        return 'In Progress';

      case GanttChartPointStatus.Complete:
        return 'Complete';

      case GanttChartPointStatus.Undefined:
      default:
        return 'Undefined';
    }
  }

  isActiveChartPoint(point: GanttChartPoint): boolean {
    if (!point) {
      return false;
    }

    const activePoint = this._getActiveChartPoint();
    if (!activePoint) {
      return false;
    }

    return point.index === activePoint.index;
  }

  isDelayedProject(): boolean {
    if (!this.project || !this._projectMilestones || !this._projectMilestones.length) {
      return false;
    }

    const lastMilestone = this._getLastMilestone();
    if (!lastMilestone) {
      return false;
    }

    const projectEndDate = moment(lastMilestone.endDate).startOf('day');
    return !projectEndDate.isSame(this._todayDate) && projectEndDate.isBefore(this._todayDate);
  }

  getChartPointRenewalDateDaysCount(point: GanttChartPoint): ({value: string, isExpired: boolean }) {
    if (!point || !this.renewalNoticeDate) {
      return { value: 'N/A - No Renewal Option or Not Provided.', isExpired: false };
    }

    const renewalDate = moment(this.renewalNoticeDate).startOf('day');
    if (renewalDate && renewalDate.isValid()) {
      const renewalDateDaysCount = renewalDate.diff(point.startDate, 'days');
      if (renewalDateDaysCount <= 0) {
        return {
          value: `Renewal Option has expired - ${moment(this.renewalNoticeDate).format('MM/DD/YYYY')}`,
          isExpired: true
        };
      }

      return {
        value: `${this._i18PluralPipe.transform(renewalDateDaysCount,
          {'=1': '# day', 'other': '# days'})} remain before renewal notice period`,
        isExpired: false
      };
    }

    return { value: 'N/A - No Renewal Option or Not Provided.', isExpired: false};
  }

  getScheduleOffset(point: GanttChartPoint): number {
    if (!point || !point.milestone || !point.milestone.actualEndDate) {
      return 0;
    }

    const actualEndDate = moment(point.milestone.actualEndDate).startOf('day');
    return point.endDate.diff(actualEndDate, 'days');
  }

  isValid(): boolean {
    if (!this._projectMilestones || !this._projectMilestones.length) {
      return false;
    }

    const firstMilestone = this._getFirstMilestone();
    const lastMilestone = this._getLastMilestone();

    if (!firstMilestone || !lastMilestone) {
      return false;
    }

    const originFirstMilestone = this._projectMilestones.slice().shift();
    const originLastMilestone = this._projectMilestones.slice().pop();

    if (!originFirstMilestone || !originLastMilestone) {
      return false;
    }

    return firstMilestone.id === originFirstMilestone.id && lastMilestone.id === originLastMilestone.id;
  }

  isProjectStarted(): boolean {
    const firstMilestone = this._getFirstMilestone();
    return firstMilestone && moment(firstMilestone.startDate).isValid();
  }

  back() {
    this._location.back();
  }

  handleMouseOver(event: MouseEvent, originalTarget: HTMLDivElement, tooltip: DxTooltipComponent): Promise<void> {
    if (!originalTarget || !this.scroller || !this.scroller.nativeElement) {
      return;
    }

    const scrollerElement = this.scroller.nativeElement;

    const scrollerBoundingClientRect = scrollerElement.getBoundingClientRect();
    const targetBoundingClientRect = originalTarget.getBoundingClientRect();

    const leftBoundingRectIntersect = scrollerBoundingClientRect.left - targetBoundingClientRect.left;
    const rightBoundingRectIntersect = targetBoundingClientRect.right - scrollerBoundingClientRect.right;

    const targetHalfWidth = Math.ceil(targetBoundingClientRect.width / 2);
    if (targetHalfWidth <= leftBoundingRectIntersect || targetHalfWidth <= rightBoundingRectIntersect) {
      event.stopImmediatePropagation();

      const scrollEventCallback = () => {
        const scrollerBoundingRect = scrollerElement.getBoundingClientRect();
        const targetBoundingRect = originalTarget.getBoundingClientRect();
        if (scrollerBoundingRect.left - targetBoundingRect.left === 0 ||
          targetBoundingRect.right - scrollerBoundingRect.right === 0) {
          tooltip.visible = true;
          scrollerElement.removeEventListener('scroll', scrollEventCallback);
        }
      };

      scrollerElement.addEventListener('scroll', scrollEventCallback);

      originalTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: targetHalfWidth <= leftBoundingRectIntersect ? 'start' : 'end',
      });
    }
  }

  getHorizontalScrollbarHeight(element: HTMLElement): number {
    if (!element) {
      return 0;
    }

    const offsetHeight = element.offsetHeight;
    const elementHeight = element.clientHeight;

    return Math.max(offsetHeight - elementHeight, 0);
  }

  private _configureGanttChart(): void {
    if (!this.project.milestones || !this.project.milestones.length) {
      return;
    }

    this._projectMilestones = this
      .project
      .milestones
      .slice()
      .sort((x, y) => {
        if (!x.templateItem || !y.templateItem) {
          return 0;
        }

        return x.templateItem.sortOrder - y.templateItem.sortOrder;
      });

    this._createDatePoints();
  }

  private _getActiveChartPoint(): GanttChartPoint {
    if (!this.project || (!this._chartPointsCache || !this._chartPointsCache.length)) {
      return null;
    }

    let projectTemplateItem = this.project.projectState;
    if (projectTemplateItem && projectTemplateItem.parentProjectTemplateItem) {
      projectTemplateItem = projectTemplateItem.parentProjectTemplateItem;
    }

    if (!projectTemplateItem) {
      return null;
    }

    return this._chartPointsCache.find(point => point.milestone.templateItemId === projectTemplateItem.id);
  }

  private _getFirstMilestone(): models.IProjectMilestoneViewModel {
    if (this._firstMilestone) {
      return this._firstMilestone;
    }

    if (!this._projectMilestones || !this._projectMilestones.length) {
      return null;
    }

    let firstMilestone = this._projectMilestones.slice().shift();
    for (let i = 0, len = this._projectMilestones.length; i < len; i++) {
      const milestone = this._projectMilestones[i];
      if (moment(milestone.startDate).isBefore(firstMilestone.startDate)) {
        firstMilestone = milestone;
      }
    }

    this._firstMilestone = firstMilestone;
    return this._firstMilestone;
  }

  private _getLastMilestone(): models.IProjectMilestoneViewModel {
    if (this._lastMilestone) {
      return this._lastMilestone;
    }

    if (!this._projectMilestones || !this._projectMilestones.length) {
      return null;
    }

    let lastMilestone = this._projectMilestones.slice().pop();
    for (let i = 0, len = this._projectMilestones.length; i < len; i++) {
      const milestone = this._projectMilestones[i];
      if (moment(milestone.startDate).isAfter(lastMilestone.endDate)) {
        lastMilestone = milestone;
      }
    }

    this._lastMilestone = lastMilestone;
    return this._lastMilestone;
  }

  private _createDatePoints(): void {
    const firstMilestone = this._getFirstMilestone();
    const lastMilestone = this._getLastMilestone();

    let startDate = moment(firstMilestone.startDate).startOf('day');
    let endDate = moment(lastMilestone.endDate).startOf('day');

    if (0 < this._todayDate.diff(endDate, 'days')) {
      endDate = this._todayDate.clone();
    }

    startDate = startDate.clone().add(-this.columnsBefore, 'day');
    endDate = endDate.clone().add(this.columnsAfter, 'day');

    const daysDiff = endDate.diff(startDate, 'days');

    const datesMap: { [year: number]: { [month: number]: Array<number> } } = {};
    for (let i = 0; i <= daysDiff; i++) {
      const date = startDate.clone().add(i, 'days');

      const dateYear = date.year();
      const dateMonth = date.month();

      datesMap[dateYear] = {...datesMap[dateYear]};

      if (!datesMap[dateYear][dateMonth]) {
        datesMap[dateYear][dateMonth] = [];
      }

      datesMap[dateYear][dateMonth].push(date.get('date'));
    }

    const datePoints: Array<GanttChartDatePoint> = [];

    const years = Object.keys(datesMap);
    for (let i = 0, len = years.length; i < len; i++) {
      const year = parseInt(years[i], 10);
      const months = Object.keys(datesMap[year]);
      for (let j = 0, num = months.length; j < num; j++) {
        const month = parseInt(months[j], 10);

        datePoints.push({
          year: year,
          month: month,
          days: datesMap[year][month],
        });
      }
    }

    this._renderedDaysCount = daysDiff + 1 /* include today */;
    this.datePoints = datePoints;
  }

  private _getChartPointWidth(point: GanttChartPoint): number {
    if (!point) {
      return 0;
    }

    return point.daysCount * this._columnWidth + 1 /* pixel perfect */;
  }

  private _getChartPointOffset(point: GanttChartPoint): number {
    if (!point) {
      return 0;
    }

    if (!this._projectMilestones || !this._projectMilestones.length) {
      return 0;
    }

    const firstMilestone = this._getFirstMilestone();
    if (!firstMilestone) {
      return 0;
    }

    const startProjectDate = moment(firstMilestone.startDate).add(-this.columnsBefore, 'days').startOf('day');
    const pointStartDate = moment(point.startDate).startOf('day');

    const datesDiff = pointStartDate.diff(startProjectDate, 'days');
    return datesDiff * this._columnWidth;
  }

  private _fixupScheduleLabels(labels: Array<ng.ElementRef> | ng.QueryList<ng.ElementRef> = this.scheduleLabels): void {
    if (!labels || !labels.length || !this.columnsContainer || !this.columnsContainer.nativeElement) {
      return;
    }

    const columnsContainerBoundingClientRect = this.columnsContainer.nativeElement.getBoundingClientRect();

    this.scheduleLabels.forEach((elementRef: ng.ElementRef): void => {
      if (!elementRef || !elementRef.nativeElement) {
        return;
      }

      const elementBoundingClientRect = elementRef.nativeElement.getBoundingClientRect();
      if (columnsContainerBoundingClientRect.width <= elementBoundingClientRect.right - columnsContainerBoundingClientRect.left) {
        this._renderer.removeClass(elementRef.nativeElement, 'right');
        this._renderer.addClass(elementRef.nativeElement, 'left');
      }
    });
  }

  private _setOptimalDayColumnWidth(): void {
    if (!this.wrapper || !this.wrapper.nativeElement || !this.aside || !this.aside.nativeElement ||
      !this.columns || !this.columns.length) {
      return;
    }

    const wrapperClientRect = this.wrapper.nativeElement.getBoundingClientRect();
    const asideClientRect = this.aside.nativeElement.getBoundingClientRect();

    const maxAvailableViewWidth = wrapperClientRect.width - asideClientRect.width;
    let oneDayWidth = Math.round(maxAvailableViewWidth / this._renderedDaysCount);
    if (oneDayWidth < GanttChartComponent._minDayColumnWidth) {
      oneDayWidth = GanttChartComponent._minDayColumnWidth;
    }

    this.columns.forEach((columnRef) => {
      if (columnRef.nativeElement) {
        this._renderer.setStyle(columnRef.nativeElement, 'width', `${oneDayWidth}px`);
      }
    });

    this._shouldRerender = true;
    this._columnWidth = oneDayWidth;
  }

  private _isCompletedProject(): boolean {
    if (!this.project || !this._projectMilestones || !this._projectMilestones.length || !this.project.projectState) {
      return false;
    }

    return this._projectService.isClosed(this.project);
  }
}
