import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InlineSVGModule } from 'ng-inline-svg';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoPlayerHTML5Component } from './components/video-player-html5/video-player-html5.component';
import { VideoPlayerYoutubeComponent } from './components/video-player-youtube/video-player-youtube.component';
import { VideoPlayerVimeoComponent } from './components/video-player-vimeo/video-player-vimeo.component';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    InlineSVGModule,

    PopoverModule,
    TooltipModule,

    InfrastructureModule,
    YouTubePlayerModule,
  ],
  declarations: [
    VideoPlayerComponent,
    VideoPlayerHTML5Component,
    VideoPlayerYoutubeComponent,
    VideoPlayerVimeoComponent,
  ],
  exports: [
    VideoPlayerComponent,
  ],
})
export class VideoPlayerModule {}
