<dx-scroll-view class="plan-viewer-marker-dialog-demo" width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ mode === modes.Unit ? 'Unit' : 'Improvement' }}
        </div>
        <div class="dx-field-value">
          <ng-container *ngIf="!isCustomTitleControlVisible">
            <dx-select-box name="title" id="title" required
                           [dataSource]="suggestions" [displayExpr]="suggestionsDisplayExpr"
                           [isValid]="form.submitted ? title.valid : true"
                           [(ngModel)]="selectedSuggestion" #title="ngModel"
                           (ngModelChange)="handleTitleSelectBoxChange($event)">
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && title.hasError('required')">
              Title is required
            </div>
          </ng-container>
          <ng-container *ngIf="isCustomTitleControlVisible">
            <dx-text-area name="title" id="title" required
                          placeholder="Start typing..."
                          [isValid]="form.submitted ? title.valid : true"
                          [(ngModel)]="marker.title" [autoResizeEnabled]="true"
                          #title="ngModel">
            </dx-text-area>

            <ng-container *ngIf="1 < suggestions.length">
              <div class="my-1 text-right">
                <a role="button" class="cursor-pointer text-primary text-underline d-inline-block"
                   (click)="isCustomTitleControlVisible = false">
                  ← Back to the list
                </a>
              </div>
            </ng-container>

            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && title.hasError('required')">
              Title is required
            </div>
          </ng-container>
        </div>
      </div>

      <div class="dx-field"
           *ngIf="mode === modes.Marker">
        <div class="dx-field-label">
          Area
        </div>
        <div class="dx-field-value">
          <dx-select-box name="area" id="area" valueExpr="id" displayExpr="text" required
                         [dataSource]="areas" [isValid]="form.submitted ? area.valid : true"
                         [(ngModel)]="marker.area" #area="ngModel">
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && area.hasError('required')">
            Area is required
          </div>
        </div>
      </div>

      <div class="dx-field"
           *ngIf="mode === modes.Marker">
        <div class="dx-field-label">
          Description
        </div>
        <div class="dx-field-value">
          <dx-text-area name="description" id="description" required maxLength="2500"
                        [isValid]="form.submitted ? description.valid : true"
                        placeholder="Describe the improvement"
                        height="100px"
                        [(ngModel)]="marker.description" [autoResizeEnabled]="false"
                        #description="ngModel">
          </dx-text-area>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && description.hasError('required')">
            Description is required
          </div>
        </div>
      </div>


      <div class="dx-field"
           *ngIf="mode === modes.Marker">
        <div class="dx-field-label">
          Improvement Cost (Optional)
        </div>
        <div class="dx-field-value">
          <dx-number-box name="Improvement cost" id="improvementCost" placeholder="$ e.g. 100" format="$ #,###.##"
                        [isValid]="form.submitted ? description.valid : true"
                        [(ngModel)]="marker.amount"
                        #description="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && description.hasError('required')">
            Improvement Cost is required
          </div>
        </div>
      </div>

      <ng-container *ngIf="mode === modes.Marker && !marker.id">
        <div class="dx-field">
          <div class="dx-field-label">
            Panoramic or Standard Images
          </div>

          <div class="dx-field-value">
            <input type="file" name="images" accept="image/*,image/heic"
                   multiple appImageUploader
                   [(ngModel)]="images"/>
          </div>
        </div>
      </ng-container>

      <div class="d-flex flex-row">

          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>

          <dx-button type="success" width="100%" class="dx-button-success"
                     [text]="marker?.id ? 'Update' : 'Add'" [useSubmitBehavior]="true">
          </dx-button>

      </div>
    </div>
  </form>
</dx-scroll-view>
