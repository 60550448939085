import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Role } from '@statera/sdk/auth';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

interface UnitMetrics {
  label: string;
  value: models.BaseRentalRateUnitMetrics;
}

@Component({
  selector: 'app-base-rental-rate',
  templateUrl: './base-rental-rate.component.html',
  styleUrls: ['./base-rental-rate.component.scss'],
})
export class BaseRentalRateComponent extends BaseTermDirective<models.IBaseRentalRateViewModel> implements OnInit, OnChanges, OnDestroy {
  private _pleaseProvideInclusions = 'Please provide inclusions';
  private _custom = 'Custom';
  private _previousValueType: models.BaseRentalRateType;

  BaseRentalRateType = models.BaseRentalRateType;
  isVisibleRadioGroup = true;
  isLandlordTurn = false;
  pleaseProvideInclusionsForMultiplyOptions = 'Please provide inclusions for multiply options';
  radioGroup: any = [];

  selectedRadioGroup: string;
  net = 'Net';
  gross = 'Gross';
  baseYear = 'Modified Gross';

  taxesRadioGroup: Array<{ name: string, value: string, disabled: boolean }> = [
    {
      name: this.net,
      value: this.net,
      disabled: true,
    },
    {
      name: this.gross,
      value: this.gross,
      disabled: true,
    },
    {
      name: this.baseYear,
      value: this.baseYear,
      disabled: true,
    },
  ];

  selectedTax: string;
  tooltipActualYearRateVisible: boolean;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  baseRentalRateUnitMetricsDataSource: Array<UnitMetrics> = [
    {
      label: 'PSF/Yr',
      value: models.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      label: 'PSF/Mo',
      value: models.BaseRentalRateUnitMetrics.PsfPerMonth,
    },
  ];

  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private readonly _projectAccessService: ProjectAccessService;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this._destroy$ = new Subject<void>();
    this._projectAccessService = projectAccessService;
  }

  ngOnInit() {
    super.ngOnInit();
    this.isLandlordTurn = this._projectAccessService.getTurnRole(this.lease) === Role.Landlord;

    this._initializeBaseRentalRatePage();

    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          tap(() => this._initializeBaseRentalRatePage()),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseTerm &&
      changes.leaseTerm.previousValue &&
      changes.leaseTerm.currentValue &&
      !this.termManager.isSameTerms(
        <models.ILeaseTermViewModel>changes.leaseTerm.previousValue,
        <models.ILeaseTermViewModel>changes.leaseTerm.currentValue,
      )
    ) {
      this._initializeBaseRentalRatePage();
    }
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
        this.lease.term.hasMultiplyOptions
      ) ||
      (
        this.leaseTerm.termStatus === models.TermStatus.Ready &&
        this.project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
      )
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.baseRentalRateType === models.BaseRentalRateType.None &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.leaseTermOptions &&
      !this.leaseTerm.leaseTermOptions.selectedLeaseTerm &&
      (
        this.leaseTerm.baseRentalRateType === null ||
        this.leaseTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions
      ) &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (
      this.leaseHistoryRecord &&
      this.leaseHistoryRecord.leaseTermHistoryRecords &&
      this.leaseHistoryRecord.leaseTermHistoryRecords.length
    ) {
      const baseRentalRateHistory = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find(x => x.leaseTermType === models.LeaseTermType.BaseRentalRate);

      if (baseRentalRateHistory && baseRentalRateHistory.termHistoryModels) {
        const indexOfLatestNoneOption = baseRentalRateHistory
          .termHistoryModels
          .findIndex(x => x.optionType === models.BaseRentalRateType.None);

        if (indexOfLatestNoneOption === baseRentalRateHistory.termHistoryModels.length - 1) {
          return false;
        }
      }
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isCounterButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      ) &&
      this.leaseTerm.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready
    ) {
      return false;
    }

    return super.isCounterButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Draft &&
      this.leaseTerm.termStatus !== models.TermStatus.Pending &&
      this.leaseHistoryRecord &&
      this.leaseHistoryRecord.leaseTermHistoryRecords &&
      this.leaseHistoryRecord.leaseTermHistoryRecords.length
    ) {
      const baseRentalRateHistory = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find(x => x.leaseTermType === models.LeaseTermType.BaseRentalRate);

      if (baseRentalRateHistory && baseRentalRateHistory.termHistoryModels) {
        const indexOfLatestNoneOption = baseRentalRateHistory
          .termHistoryModels
          .findIndex(x => x.optionType === models.BaseRentalRateType.None);

        const lastTermHistoryRecord = baseRentalRateHistory.termHistoryModels[baseRentalRateHistory.termHistoryModels.length - 1];

        if (
          indexOfLatestNoneOption === baseRentalRateHistory.termHistoryModels.length - 2 &&
          (
            this.project &&
            this.project.projectState &&
            lastTermHistoryRecord.projectTemplateItemType === this.project.projectState.renewalProjectTemplateItemType
          )
        ) {
          return true;
        }
      }
    }

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.termStatus !== models.TermStatus.Pending &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    const isTenantCounterOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.SendRfp,
          this.project,
          this.lease,
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        )
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick(): void {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    this.handleAcceptButtonClick();
  }

  saveLeaseTerm($event) {
    this.clearErrors();

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.leaseTerm.leaseTermOptions &&
      this.leaseTerm.leaseTermOptions.options &&
      this.leaseTerm.leaseTermOptions.options.length &&
      this.isShowMultiplyOptionsTenantStep()
    ) {
      this.leaseTerm.baseRentalRateType = this.leaseTerm.leaseTermOptions.options[0].baseRentalRateType;
      if (this.leaseTerm.leaseTermOptions.selectedLeaseTerm) {
        this.leaseTerm.baseRentalRateType = this.leaseTerm.leaseTermOptions.selectedLeaseTerm.baseRentalRateType;
      }
    }

    const {baseRentalRateType} = this.leaseTerm;
    if (baseRentalRateType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (
      baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      this.leaseTerm.leaseTermOptions?.options?.some(x => !x.baseRentalRateType)
    ) {
      this.addError('You should choose appropriate options.');
      return;
    }

    const templateItem = this.project.projectState.renewalProjectTemplateItemType;
    if (
      templateItem === models.RenewalProjectTemplateItemType.SendRfp &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions
    ) {
      this.addError('You can\'t select this option in this Stage because term has multiply options.');
      return;
    }

    if (
      baseRentalRateType === models.BaseRentalRateType.None &&
      (
        templateItem !== models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord &&
        templateItem !== models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant &&
        templateItem !== models.RenewalProjectTemplateItemType.SendRfp
      )
    ) {
      this.addError('You can\'t select this option.');
      return;
    }

    if (!this.isValidUnitMetrics()) {
      this.addError('Please make sure both options have the same PSF structure. (PSF/Yr or PSF/Mo).');
      return;
    }

    if (!this.isValidStructure()) {
      this.addError('Please make sure both options have the same structure. (Net, Full Gross or Modified Gross).');
      return;
    }

    if (this.termManager.isRateLessForRestructureProject(this.project, this.lease, this.leaseTerm)) {
      this.addError('Please note that lowering the Base Rental Rate may cause the Landlord to modify other Lease Terms.');
    }

    if (
      baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      this.leaseTerm.leaseTermOptions?.options?.some(x => (
        (x.baseRentalRateType === models.BaseRentalRateType.Net && !x.baseRentalRateNetValue) ||
        (x.baseRentalRateType === models.BaseRentalRateType.Gross && !x.baseRentalRateGrossValue) ||
        (x.baseRentalRateType === models.BaseRentalRateType.BaseYear && !x.actualYearRate)
      ))
    ) {
      this.addError('You should fill appropriate value for every option.');
      return;
    }

    let termStatus: models.TermStatus;

    if (
      templateItem === models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord &&
      this._previousValueType === models.BaseRentalRateType.None
    ) {
      termStatus = models.TermStatus.Ready;
    } else if (templateItem === models.RenewalProjectTemplateItemType.SendRfp) {
      termStatus = models.TermStatus.Ready;
    } else if (templateItem === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord) {
      termStatus = models.TermStatus.Ready;
    } else {
      termStatus = models.TermStatus.Rejected;
    }

    super.saveLeaseTerm(this.leaseTerm, termStatus);

    $event.preventDefault();
  }

  radioGroupChanged(event: { value: string }): void {
    if (!event || !event.value) {
      return;
    }

    switch (event.value) {
      case this._pleaseProvideInclusions:
        this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.None;
        break;

      case this.pleaseProvideInclusionsForMultiplyOptions:
        this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.RequestMultiplyOptions;
        break;

      case this._custom:
        this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.Net;
        this.selectedTax = this.net;
        break;

      default:
        return;
    }

    this.leaseTermChange.emit(this.leaseTerm);
  }

  taxesRadioGroupChanged(event: { value: string }): void {
    if (!event || !event.value) {
      return;
    }

    const currentValue = (
      (this.leaseTerm.baseRentalRateType === models.BaseRentalRateType.Net && this.leaseTerm.baseRentalRateNetValue) ||
      (this.leaseTerm.baseRentalRateType === models.BaseRentalRateType.Gross && this.leaseTerm.baseRentalRateGrossValue) ||
      (this.leaseTerm.baseRentalRateType === models.BaseRentalRateType.BaseYear && this.leaseTerm.actualYearRate) ||
      null
    );

    const setCorrectValue = (field: 'baseRentalRateNetValue' | 'baseRentalRateGrossValue' | 'actualYearRate') => {
      this.leaseTerm.baseRentalRateNetValue = null;
      this.leaseTerm.baseRentalRateGrossValue = null;
      this.leaseTerm.actualYearRate = null;

      this.leaseTerm[field] = currentValue;
    };

    let baseRentalRateType: models.BaseRentalRateType;
    switch (event.value) {
      case this.net:
        baseRentalRateType = models.BaseRentalRateType.Net;
        setCorrectValue('baseRentalRateNetValue');
        break;

      case this.gross:
        baseRentalRateType = models.BaseRentalRateType.Gross;
        setCorrectValue('baseRentalRateGrossValue');
        break;

      case this.baseYear:
        baseRentalRateType = models.BaseRentalRateType.BaseYear;
        setCorrectValue('actualYearRate');
        break;

      default:
        return;
    }

    if (this.canInsertNewOption() && !this.isOption) {
      for (let i = 0; i < this.leaseTerm.leaseTermOptions.options.length; i++) {
        this.leaseTerm.leaseTermOptions.options[i] = {
          ...this.leaseTerm.leaseTermOptions.options[i],
          baseRentalRateType: baseRentalRateType,
        };
      }

      this.leaseTermChange.emit(this.leaseTerm);

      return;
    }

    this.leaseTerm.baseRentalRateType = baseRentalRateType;

    this.leaseTermChange.emit(this.leaseTerm);
  }

  private _initializeBaseRentalRatePage() {
    if (!this.isOption) {
      this.leaseTerm = {...this.leaseTerm};
    }

    this.radioGroup = this._getRadioGroupOptions();
    this.isVisibleRadioGroup = true;
    if (this.isOption) {
      const indexOfPleaseProvideInclusions = this.radioGroup
        .findIndex(x => x.value === this._pleaseProvideInclusions);

      if (0 <= indexOfPleaseProvideInclusions) {
        if (
          !this._projectAccessService
            .checkAccessToRenewalProject(models.RenewalProjectTemplateItemType.SendRfp, this.project, this.lease)
        ) {
          this.radioGroup[indexOfPleaseProvideInclusions].disabled = true;
        }
      }

      const indexOfPleaseProvideInclusionsForMultipleOptions = this.radioGroup
        .findIndex(x => x.value === this.pleaseProvideInclusionsForMultiplyOptions);

      if (0 <= indexOfPleaseProvideInclusionsForMultipleOptions) {
        this.radioGroup.splice(indexOfPleaseProvideInclusionsForMultipleOptions, 1);
      }
    }

    if (!this.leaseTerm) {
      this.leaseTerm = <models.IBaseRentalRateViewModel>{};
    }

    let baseRentalRateType = this.leaseTerm.baseRentalRateType;
    if (!baseRentalRateType && this.leaseTerm?.leaseTermOptions?.options?.length) {
      const {leaseTermOptions} = this.leaseTerm;

      baseRentalRateType = leaseTermOptions.options[0].baseRentalRateType;
      if (leaseTermOptions.selectedLeaseTerm) {
        baseRentalRateType = leaseTermOptions.selectedLeaseTerm.baseRentalRateType;
      }
    }

    this._previousValueType = baseRentalRateType;

    if (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp
    ) {
      if (this.lease.term && this.lease.term.termType === models.TermTypeEnum.MultiplyOptions) {
        this.radioGroup.forEach(value => {
          value.disabled = true;
        });
      }

      this.isVisibleRadioGroup = true;
    } else {
      const pleaseProvideInclusions = this.radioGroup.find(x => x.value === this._pleaseProvideInclusions);
      if (pleaseProvideInclusions) {
        pleaseProvideInclusions.disabled = true;
      }
    }

    switch (baseRentalRateType) {
      case models.BaseRentalRateType.Net: {
        this.selectedTax = this.net;
        this.selectedRadioGroup = this._custom;
        this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.Net;

        break;
      }

      case models.BaseRentalRateType.Gross: {
        this.selectedTax = this.gross;
        this.selectedRadioGroup = this._custom;

        break;
      }

      case models.BaseRentalRateType.BaseYear: {
        this.selectedTax = this.baseYear;
        this.selectedRadioGroup = this._custom;

        break;
      }

      case models.BaseRentalRateType.RequestMultiplyOptions: {
        this.selectedRadioGroup = this.pleaseProvideInclusionsForMultiplyOptions;

        break;
      }

      case models.BaseRentalRateType.MultiplyOptions: {
        this.isVisibleRadioGroup = false;
        this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);

        for (let i = 0; i < this.leaseTerm.leaseTermOptions?.options?.length; i++) {
          if (!this.leaseTerm.leaseTermOptions?.options[i]?.baseRentalRateUnitMetrics) {
            this.leaseTerm.leaseTermOptions.options[i].baseRentalRateUnitMetrics =
              models.BaseRentalRateUnitMetrics.PsfPerYear;
          }
        }

        break;
      }

      case models.BaseRentalRateType.None: {
        this.selectedRadioGroup = this._pleaseProvideInclusions;

        if (this.isLandlordTurn) {
          this.selectedTax = this.net;
          this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.Net;
        }

        break;
      }
    }

    const {SendRfp, ReceiveAndAnalyzeResponse, UnsolicitedOffer} = models.RenewalProjectTemplateItemType;

    if (
      this._projectAccessService.checkAccessToRenewalProject(SendRfp, this.project, this.lease) ||
      this._projectAccessService.checkAccessToRenewalProject(ReceiveAndAnalyzeResponse, this.project, this.lease) ||
      this._projectAccessService.checkAccessToRenewalProject(UnsolicitedOffer, this.project, this.lease)
    ) {
      this.taxesRadioGroup = this.taxesRadioGroup.map((option) => {
        option.disabled = false;

        return option;
      });
    }

    // Make available the selectedTax with multiple options
    if (
      this.isOption &&
      this.leaseTerm &&
      this.leaseTerm.leaseTermOptions &&
      this.leaseTerm.leaseTermOptions.options &&
      this.leaseTerm.leaseTermOptions.options.length &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      switch (this.leaseTerm.baseRentalRateType) {
        case models.BaseRentalRateType.Net:
          this.selectedTax = this.net;
          break;

        case models.BaseRentalRateType.Gross:
          this.selectedTax = this.gross;
          break;

        case models.BaseRentalRateType.BaseYear:
          this.selectedTax = this.baseYear;
          break;
      }
    }

    // Make available the selectedTax option selected with multiple options
    if (
      !this.selectedTax &&
      this.leaseTerm &&
      this.leaseTerm.leaseTermOptions &&
      this.leaseTerm.leaseTermOptions.options &&
      this.leaseTerm.leaseTermOptions.options.length &&
      !this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterOffer,
        this.project,
        this.lease
      )
    ) {
      const { leaseTermOptions } = this.leaseTerm;

      let selectedOption = leaseTermOptions.selectedLeaseTerm;
      if (!selectedOption) {
        selectedOption = leaseTermOptions.options[0];
      }

      if (selectedOption.baseRentalRateType && this.leaseTerm.baseRentalRateType !== models.BaseRentalRateType.MultiplyOptions) {
        this.leaseTerm.baseRentalRateType = selectedOption.baseRentalRateType;

        switch (this.leaseTerm.baseRentalRateType) {
          case models.BaseRentalRateType.Net:
            this.selectedTax = this.net;
            this.selectedRadioGroup = this._custom;
            break;

          case models.BaseRentalRateType.Gross:
            this.selectedTax = this.gross;
            this.selectedRadioGroup = this._custom;
            break;

          case models.BaseRentalRateType.BaseYear:
            this.selectedTax = this.baseYear;
            this.selectedRadioGroup = this._custom;
            break;
        }
      }
    }

    // Make all options available if we can't restore selectedTax or when it’s the landlord’s turn
    if (!this.selectedTax || this.isLandlordTurn) {
      this.taxesRadioGroup = this.taxesRadioGroup.map(option => {
        option.disabled = false;

        return option;
      });

      return;
    }

    const availableTaxOptionIndex = this.taxesRadioGroup.findIndex(x => x.value === this.selectedTax);
    if (0 <= availableTaxOptionIndex) {
      this.taxesRadioGroup[availableTaxOptionIndex].disabled = false;
    }

    if (!this.leaseTerm.baseRentalRateType && this.selectedRadioGroup === this._custom) {
      switch (this.selectedTax) {
        case this.net:
          this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.Net;
          break;

        case this.gross:
          this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.Gross;
          break;

        case this.baseYear:
          this.leaseTerm.baseRentalRateType = models.BaseRentalRateType.BaseYear;
          break;
      }
    }
  }

  isTypeDifferentFromOriginalOne(leaseTerm: models.IBaseRentalRateViewModel = this.leaseTerm): boolean {
    const {baseRentalRateType, leaseTermOptions, baseRentalRateUnitMetrics} = leaseTerm;

    let baseRentalRateTypeOriginal = null;
    let baseRentalRateUnitMetricsOriginal = null;
    if (this.leaseTerm.abstract != null) {
      baseRentalRateTypeOriginal = this.leaseTerm.abstract.baseRentalRateType;
      baseRentalRateUnitMetricsOriginal = this.leaseTerm.abstract.baseRentalRateUnitMetrics;
    }

    if (
      baseRentalRateType &&
      baseRentalRateUnitMetrics &&
      baseRentalRateTypeOriginal &&
      baseRentalRateUnitMetricsOriginal
    ) {
      if (
        baseRentalRateType === models.BaseRentalRateType.MultiplyOptions ||
        baseRentalRateType === models.BaseRentalRateType.RequestMultiplyOptions
      ) {
        if (!leaseTermOptions || !leaseTermOptions.options) {
          return false;
        }

        return leaseTermOptions.options.reduce(
          (acc, option) => acc || this.isTypeDifferentFromOriginalOne(option),
          false, /** acc */
        );
      }

      if (this.project && this.project.projectState) {
        const templateItem = this.project.projectState.renewalProjectTemplateItemType;
        if (
          (
            templateItem === models.RenewalProjectTemplateItemType.SendRfp ||
            templateItem === models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord
          ) &&
          baseRentalRateType === models.BaseRentalRateType.None
        ) {
          return false;
        }
      }

      return baseRentalRateType !== baseRentalRateTypeOriginal
        || baseRentalRateUnitMetrics !== baseRentalRateUnitMetricsOriginal;
    }

    return false;
  }

  getMessageAboutDifferentTypesOfBaseRentalRate(): string {
    let result =
      'This structure is different from the current lease, please adjust Real Estate Taxes ' +
      'and CAM Expenses term before sending Offer.';

    if (this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp) {
      result = 'This is a different structure from the current lease.';
    }

    return result;
  }

  isUnitMetricsDifferentFromOriginal(leaseTerm: models.IBaseRentalRateViewModel = this.leaseTerm): boolean {
    if (!leaseTerm || this.isTypeDifferentFromOriginalOne(leaseTerm)) {
      return false;
    }

    const {baseRentalRateUnitMetrics, baseRentalRateType, leaseTermOptions} = leaseTerm;

    let originalUnitMetrics = models.BaseRentalRateUnitMetrics.PsfPerYear;
    if (leaseTerm && leaseTerm.abstract) {
      originalUnitMetrics = this.leaseTerm.abstract.baseRentalRateUnitMetrics;
    }

    if (!baseRentalRateType || !baseRentalRateUnitMetrics || !originalUnitMetrics) {
      return false;
    }

    if (baseRentalRateType === models.BaseRentalRateType.None) {
      return false;
    }

    if (
      baseRentalRateType &&
      (
        baseRentalRateType === models.BaseRentalRateType.MultiplyOptions ||
        baseRentalRateType === models.BaseRentalRateType.RequestMultiplyOptions
      )
    ) {
      if (!leaseTermOptions || !leaseTermOptions.options) {
        return false;
      }

      return leaseTermOptions.options.reduce(
        (acc, option) => acc || this.isUnitMetricsDifferentFromOriginal(option),
        false, /** acc */
      );
    }

    return baseRentalRateUnitMetrics !== originalUnitMetrics;
  }

  canInsertNewOption(): boolean {
    return this.termManager.canInsertNewOption(this.lease.term, this.project);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getRadioGroupOptions() {
    return [
      {
        name: (
          'Please provide an aggressive, market sensitive rate.  Please indicate the precise structure of the rental rate ' +
          '(net, gross or full service).  If the structure is not net, please clearly describe the additional rent inclusions'
        ),
        value: this._pleaseProvideInclusions,
      },
      {
        name: this._custom,
        value: this._custom,
      },
      {
        name: (
          'Please provide an aggressive, market sensitive rate for both requested Lease Terms. ' +
          'Please indicate the precise structure of the rental rate for both options (Net, Gross or with Base Year).'
        ),
        value: this.pleaseProvideInclusionsForMultiplyOptions,
        disabled: true,
      },
    ];
  }

  pleaseProvideInclusionsInMultiplyOptionSelectedInChild() {
    if (this.leaseTerm && this.leaseTerm.leaseTermOptions && this.leaseTerm.leaseTermOptions.options) {
      this.leaseTerm.leaseTermOptions.options.forEach(option => {
        option.baseRentalRateType = models.BaseRentalRateType.None;
      });
    }
  }

  isValidUnitMetrics(): boolean {
    if (
      !this.leaseTerm ||
      !this.leaseTerm.leaseTermOptions ||
      !this.leaseTerm.leaseTermOptions.options
    ) {
      return true;
    }

    const options = this.leaseTerm.leaseTermOptions.options;
    if (!options.length) {
      return true;
    }

    let prevUnitMetrics: models.BaseRentalRateUnitMetrics = options[0].baseRentalRateUnitMetrics;
    for (let i = 1, num = options.length; i < num; i++) {
      const nextUnitMetrics = options[i].baseRentalRateUnitMetrics;
      if (prevUnitMetrics !== nextUnitMetrics) {
        return false;
      }

      prevUnitMetrics = nextUnitMetrics;
    }

    return true;
  }

  isValidStructure(): boolean {
    if (
      !this.leaseTerm ||
      !this.leaseTerm.leaseTermOptions ||
      !this.leaseTerm.leaseTermOptions.options
    ) {
      return true;
    }

    const options = this.leaseTerm.leaseTermOptions.options;
    if (!options.length) {
      return true;
    }

    let prevStructure: models.BaseRentalRateType = options[0].baseRentalRateType;
    for (let i = 1, num = options.length; i < num; i++) {
      const nextStructure = options[i].baseRentalRateType;
      if (prevStructure !== nextStructure) {
        return false;
      }

      prevStructure = nextStructure;
    }

    return true;
  }

  isVisibleTaxesRadioGroup(): boolean {
    return this.selectedRadioGroup === this._custom;
  }

  validateZeroValues(event): boolean {
    if (!event) {
      return true;
    }

    return event.value !== 0;
  }

  getUnitMetricsDisplayValue(): string {
    return this.termManager.getBaseRentalRateUnitMetricsDisplayValue(this.leaseTerm);
  }
}
