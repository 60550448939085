import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';


@Component({
  selector: 'app-hazardous-materials',
  templateUrl: './hazardous-materials.component.html',
  styleUrls: ['./hazardous-materials.component.scss']
})
export class HazardousMaterialsComponent extends BaseTermDirective<models.IHazardousMaterialsTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  HazardousMaterialsTermType: typeof models.HazardousMaterialsTermType = models.HazardousMaterialsTermType;

  private readonly _destroy$: Subject<void>;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project?.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Landlord represents and warrants that the Building and Premises are free of all Hazardous Materials, ' +
            'including but not limited to PCBs and asbestos, and that Building and Premise shall be maintained in ' +
            'compliance with all environmental laws, rules and regulations. Landlord will indemnify and hold ' +
            'harmless Tenant against any pre-existing hazardous materials contained in the building or property ' +
            'that were introduced prior to Tenant’s occupancy.',
          value: models.HazardousMaterialsTermType.LandlordResponsibilityExtended,
        },
        {
          name:
            'Landlord will provide warranties concerning the absence of Hazardous Materials and any other ' +
            'Environmental defects and will be responsible for all costs associated with the removal of any that ' +
            'are present prior to the commencement of the Lease.  Landlord shall agree to indemnify and hold ' +
            'Tenant harmless for any existing Hazardous or Environmental conditions.',
          value: models.HazardousMaterialsTermType.LandlordResponsibility,
        },
        {
          name:
            'Landlord shall indemnify and hold Tenant harmless for any pre-existing Hazardous or Environmental ' +
            'conditions only. Environmental Phase I, if one exists on file, can be made available to Tenant upon ' +
            'execution of Letter of Intent.',
          value: models.HazardousMaterialsTermType.LandlordResponsibilityWithEnvironmentalPhaseOne,
        },
        {
          name: 'To be addressed in the Lease document.',
          value: models.HazardousMaterialsTermType.ToBeAddressedInLease,
        },
        {
          name: 'Custom',
          value: models.HazardousMaterialsTermType.Custom,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.HazardousMaterialsTermType.PerCurrentLease,
      },
      {
        name:
          'Landlord represents and warrants that the Building and Premises are free of all Hazardous Materials, ' +
          'including but not limited to PCBs and asbestos, and that Building and Premise shall be maintained in ' +
          'compliance with all environmental laws, rules and regulations. Landlord will indemnify and hold ' +
          'harmless Tenant against any pre-existing hazardous materials contained in the building or property ' +
          'that were introduced prior to Tenant’s occupancy.',
        value: models.HazardousMaterialsTermType.LandlordResponsibilityExtended,
      },
      {
        name:
          'Landlord will provide warranties concerning the absence of Hazardous Materials and any other ' +
          'Environmental defects and will be responsible for all costs associated with the removal of any that ' +
          'are present prior to the commencement of the Lease.  Landlord shall agree to indemnify and hold ' +
          'Tenant harmless for any existing Hazardous or Environmental conditions.',
        value: models.HazardousMaterialsTermType.LandlordResponsibility,
      },
      {
        name:
          'Landlord shall indemnify and hold Tenant harmless for any pre-existing Hazardous or Environmental ' +
          'conditions only. Environmental Phase I, if one exists on file, can be made available to Tenant upon ' +
          'execution of Letter of Intent.',
        value: models.HazardousMaterialsTermType.LandlordResponsibilityWithEnvironmentalPhaseOne,
      },
      {
        name: 'To be addressed in the Lease document.',
        value: models.HazardousMaterialsTermType.ToBeAddressedInLease,
      },
      {
        name: 'Custom',
        value: models.HazardousMaterialsTermType.Custom,
      },
    ];
  }
}
