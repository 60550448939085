import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AlertGroup } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertStore {
  readonly latestRealtimeAlertGroup: BehaviorSubject<AlertGroup>;

  constructor() {
    this.latestRealtimeAlertGroup = new BehaviorSubject<AlertGroup>(null);
  }
}
