import { Component, OnDestroy, OnInit } from '@angular/core';

import { AlertService } from '../../../alert/services/alert.service';
import { ProfileService } from '../../../user/services/profile.service';
import { AuthService } from '../../../auth/services/auth.service';

import { TermHeaderComponent } from '../terms/term-header/term-header.component';
import { BaseDemoComponent } from '../../../shared/components/base-demo/base-demo.component';

import * as models from '../../../infrastructure/models/generated';

declare let Driver: any;

@Component({
  selector: 'app-colabo-demo-terms',
  templateUrl: '../../../shared/components/base-demo/base-demo.component.html',
  styleUrls: ['./colabo-demo-terms.component.scss']
})
export class ColaboDemoTermsComponent extends BaseDemoComponent implements OnInit, OnDestroy {
  rentalRateHeader: TermHeaderComponent;
  MAX_HEIGHT_TO_ENABLE_SCROLL = 1000;

  steps = [
    {
      element: '.demo-status',
      popover: {
        title: ' ',
        description: this.getMessage(
          'This will show the current status of each term and whether they are in ready, draft, rejected or accepted mode.'
        ),
        position: window.innerHeight > this.MAX_HEIGHT_TO_ENABLE_SCROLL ? 'right' : 'top'
      }
    },
    {
      element: '.w-columns-comments',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Comments section allows users to write a specific internal or external comment for ' +
          'each respective term while in negotiations.'
        ),
        position: 'left'
      }
    },
    {
      element: '.dx-accordion-item-opened',
      popover: {
        title: ' ',
        description: this.getMessage(
          'As in 3-pane Collabo view, users will see current lease language ' +
          'if available but will have a more detailed view of ' +
          'multiple choice options to choose from for each individual term. Pay attention to any additional text boxes ' +
          'that are necessary to fill out.'
        ),
        position: 'right'
      }
    },
    {
      element: '.demo-tooltip .dx-overlay-content.dx-popup-normal.dx-resizable',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Users will find tool tips in multiple spots during negotiations. In particular, please note of the tool ' +
          'tips within certain terms as an additional guide.'
        ),
        position: 'right'
      }
    },
    {
      element: '.dx-accordion-item-opened',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Track changes section will show what each user has offered as their responses during the negotiations. ' +
          'Responses are live but can only be modified by one user at a time.'
        ),
        position: window.innerHeight > this.MAX_HEIGHT_TO_ENABLE_SCROLL ? 'bottom' : 'right'
      }
    }
  ];

  constructor(profileService: ProfileService, authService: AuthService, alertService: AlertService) {
    super(profileService, authService, alertService);
  }

  ngOnInit() {
    this.introType = models.IntroType.ColaboPage2;
  }

  show(component: any): void {
    this.component = component;

    if (!this.isShowDemo() || !this.component) {
      return;
    }

    this.component.setDemoRentalRate(true);

    this.checkElements([
      '.demo-term-status',
      '.demo-tooltip .dx-overlay-content.dx-popup-normal'
    ]).then(() => {
      this.visible = true;
      this.driver = new Driver({
        showButtons: false,
        allowClose: false
      });
      this.driver.defineSteps(this.steps);
      this.scrollToNextDemoStepIfNeeded();
      this.driver.start();
    });
  }

  scrollToNextDemoStepIfNeeded() {
    const withScroll = window.innerHeight < this.MAX_HEIGHT_TO_ENABLE_SCROLL;
    if (withScroll) {
      if ([0, 2, 4].includes(this.current)) {
        window.document.body.querySelector(this.steps[this.current].element).scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    }
  }

  goto(stepNumber: number) {
    this.current = stepNumber;
    const withScroll = window.innerHeight < this.MAX_HEIGHT_TO_ENABLE_SCROLL;

    if (this.current === 3) {
      this.rentalRateHeader = this.component.itemHeaders.find(
        x => x.termConfiguration.termName === 'RentalRate'
      );
      if (this.rentalRateHeader) {
        this.rentalRateHeader.showDemo(withScroll);
      }
    } else {
      if (this.rentalRateHeader) {
        this.rentalRateHeader.hideDemo();
      }
    }

    this.scrollToNextDemoStepIfNeeded();
    this.driver.highlight(this.steps[stepNumber]);
  }

  skip() {
    this.profileService.skipIntro(this.introType).subscribe(() => {
      this.visible = false;
      this.driver.reset();
      this.component.demoRentalRateTerm = false;

      this.authService.startupInfo.introsShown.push(<models.IUserIntroViewModel>{
        userId: this.authService.startupInfo.id,
        introType: this.introType
      });

      if (this.rentalRateHeader) {
        this.rentalRateHeader.demoTooltip.instance.hide();
      }

      this.skipped.emit();
    });
  }

  onResize() {
    if (!this.driver) {
      return;
    }

    this.driver.onResize();
  }
}
