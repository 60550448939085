import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { finalize, takeUntil, tap, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class ActivityTrackerService {
  private static readonly _trackFocusInterval: number = 250;

  private readonly _ngZone: NgZone;

  constructor(ngZone: NgZone) {
    this._ngZone = ngZone;
  }

  track(): Observable<boolean> {
    const activityObserver = new Subject<boolean>();
    const destroy = new Subject<void>();

    // Tracks document focus every {_trackFocusInterval} milliseconds
    this._ngZone.runOutsideAngular(() => {
      interval(ActivityTrackerService._trackFocusInterval)
        .pipe(
          map(() => document.hidden),
          distinctUntilChanged(),
          tap(hidden => {
            this._ngZone.runTask(() => activityObserver.next(!hidden));
          }),
          takeUntil(destroy),
        )
        .subscribe();
    });

    return activityObserver
      .pipe(
        finalize(() => {
          destroy.next();
          destroy.complete();
        }),
      );
  }
}
