<div class="deals-by-stage-single-report">
  <ul class="deals-by-stage-single-report-legends">
    <li>
      <a>
        <span class="deals-by-stage-single-report-legends-bg deals-by-stage-single-report-legends-bg--tenant"></span>
        <span>Tenant</span>
      </a>
    </li>
    <li>
      <a>
        <span class="deals-by-stage-single-report-legends-bg deals-by-stage-single-report-legends-bg--landlord"></span>
        <span>Landlord</span>
      </a>
    </li>
  </ul>

  <div class="deals-by-stage-single-report-chart">
    <canvas baseChart
            [data]="barChartData"
            [options]="barChartOptions"
            [legend]="false"
            [type]="'bar'"
            #canvasElementRef>
    </canvas>
  </div>
</div>
