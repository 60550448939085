import { Directive, ElementRef, HostListener, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import heic2any from 'heic2any';

//
// NOTE: If you want to copy this element, don't forget to also copy /scss/components/_image-uploader.scss
//

export interface UploadableImage {
  id?: number | string;
  file?: File;
  previewUrl?: string;
}

@Directive({
  selector: 'input[type=file][appImageUploader]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageUploaderDirective,
      multi: true,
    },
  ],
  exportAs: 'appImageUploader',
})
export class ImageUploaderDirective implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChange: (images: Array<UploadableImage>) => void;
  private _onTouched: (images: Array<UploadableImage>) => void;

  private readonly _elementRef: ElementRef;
  private readonly _renderer: Renderer2;
  private readonly _ngZone: NgZone;

  private _images: Array<UploadableImage>;

  private readonly _imageAdded: Subject<UploadableImage>;
  private readonly _imageRemoved: Subject<UploadableImage>;

  private readonly _mouseEnterObserver: Subject<void>;
  private readonly _mouseLeaveObserver: Subject<void>;
  private readonly _dragEnterObserver: Subject<void>;
  private readonly _dragLeaveObserver: Subject<void>;

  private readonly _renderObserver: BehaviorSubject<void>;

  private readonly _destroy: Subject<void>;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    ngZone: NgZone,
  ) {
    this._elementRef = elementRef;
    this._renderer = renderer;
    this._ngZone = ngZone;

    const noop = () => void(0);

    this._onChange = noop;
    this._onTouched = noop;

    this._images = new Array<UploadableImage>();

    this._imageAdded = new Subject<UploadableImage>();
    this._imageRemoved = new Subject<UploadableImage>();

    this._mouseEnterObserver = new Subject<void>();
    this._mouseLeaveObserver = new Subject<void>();
    this._dragEnterObserver = new Subject<void>();
    this._dragLeaveObserver = new Subject<void>();

    this._renderObserver = new BehaviorSubject<void>(null);

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    const containerElement = this._makeContainerElement();

    this._placeDragArea(containerElement);

    const previewContainerElement = this._makePreviewContainerElement(containerElement);

    this._makeEmptyStateElement(containerElement);

    const renderedImages = new Array<{image: UploadableImage, previewElement: HTMLDivElement}>();

    this._imageAdded
      .pipe(
        tap(image => {
          this._images.push(image);

          this._commitValue();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._imageRemoved
      .pipe(
        tap(image => {
          const indexOfImage = this._images.findIndex(x => x.id === image.id);
          if (indexOfImage < 0) {
            return;
          }

          const indexOfRenderedImage = renderedImages.findIndex(x => x.image.id === image.id);
          if (indexOfRenderedImage >= 0) {
            const renderedImage = renderedImages[indexOfRenderedImage];

            this._renderer.removeChild(previewContainerElement, renderedImage.previewElement);

            renderedImages.splice(indexOfRenderedImage, 1);
          }

          this._images.splice(indexOfImage, 1);

          this._commitValue();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._renderObserver
      .pipe(
        tap(() => {
          for (let i = 0, num = this._images.length; i < num; i++) {
            const image = this._images[i];

            const indexOfRenderedImage = renderedImages.findIndex(x => x.image.id === image.id);
            if (indexOfRenderedImage < 0) {
              renderedImages.push({
                image: image,
                previewElement: this._makeImagePreview(previewContainerElement, image),
              });
            }
          }

          // Perform calculation after resizing a flexible element
          this._ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              renderedImages.forEach(({previewElement}) => {
                if (renderedImages.length <= 2) {
                  this._renderer.setStyle(previewElement, 'height', '150px');
                } else if (renderedImages.length <= 4) {
                  this._renderer.setStyle(previewElement, 'height', '75px');
                } else {
                  this._renderer.setStyle(previewElement, 'height', '50px');
                }
              });

              renderedImages.forEach(({previewElement}) => {
                const removeElement = previewElement.querySelector('.image-uploader-image-remove');

                const removeElementRect = removeElement.getBoundingClientRect();
                const containerRect = containerElement.getBoundingClientRect();
                const previewRect = previewElement.getBoundingClientRect();

                const imageRight = previewRect.right - containerRect.left;
                const imageBottom = previewRect.bottom - containerRect.top;

                const offset = 10;

                const left = imageRight - (removeElementRect.width + offset);
                const top = imageBottom - (removeElementRect.height + offset);

                this._renderer.setStyle(removeElement, 'left', `${left}px`);
                this._renderer.setStyle(removeElement, 'top', `${top}px`);
              });
            });
          });
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  //
  // Control value accessor
  //

  writeValue(images: Array<UploadableImage>): void {
    if (!images || !images.length) {
      return;
    }

    this._images = [...images];

    this._renderObserver.next();
  }

  registerOnChange(fn: (images: Array<UploadableImage>) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (images: Array<UploadableImage>) => void): void {
    this._onTouched = fn;
  }

  private _commitValue(): void {
    const images = this._images.map(image => {
      return {
        ...image,
        id: image.id && image.id.toString().startsWith('dummy') ? null : image.id,
      };
    });

    this._onChange(images);
    this._onTouched(images);

    this._renderObserver.next();
  }

  //
  // State
  //

  @HostListener('change', ['$event.target.files'])
  private _handleFilesChange(fileList: FileList): void {
    this._handleFileList(fileList);
  }

  private _handleFileList(fileList: FileList): void {
    const files = new Array<File>();

    for (let i = 0, len = fileList.length; i < len; i++) {
      const file = fileList.item(i);

      // Skip non-image files
      if (!file || !this._isImage(file)) {
        continue;
      }

      files.push(file);

      const now = Date.now();

      let name = file.name;
      if (!name) {
        name = now.toString(10);
      }

      const fileDummyId = `dummy-${name}-${now}`;

      this._imageAdded.next({id: fileDummyId, file});
    }
  }

  private _removeImage(image: UploadableImage): void {
    this._imageRemoved.next(image);
  }

  //
  // Rendering
  //

  private _makeContainerElement(): HTMLDivElement {
    const inputElement = this._elementRef?.nativeElement;
    if (!inputElement) {
      return;
    }

    const containerElement = this._renderer.createElement('div');

    this._renderer.addClass(containerElement, 'image-uploader');

    const parentElement = this._renderer.parentNode(inputElement);

    this._renderer.insertBefore(parentElement, containerElement, inputElement);

    this._renderer.appendChild(containerElement, inputElement);

    return containerElement;
  }

  private _makePreviewContainerElement(containerElement: HTMLDivElement): HTMLDivElement {
    const previewContainerElement = this._renderer.createElement('div');

    this._renderer.addClass(previewContainerElement, 'image-uploader-images');

    this._renderer.appendChild(containerElement, previewContainerElement);

    return previewContainerElement;
  }

  private _makeEmptyStateElement(containerElement: HTMLDivElement): HTMLDivElement {
    const emptyStateElement = this._renderer.createElement('div');

    this._renderer.addClass(emptyStateElement, 'image-uploader-empty-state');

    const imageElement = this._renderer.createElement('div');

    this._renderer.addClass(imageElement, 'image-uploader-empty-state-image');

    const actionElement = this._renderer.createElement('p');

    this._renderer.addClass(actionElement, 'image-uploader-empty-state-action');

    const chooseFileElement = this._renderer.createElement('span');
    const chooseFileText = this._renderer.createText('Choose a file');

    this._renderer.appendChild(chooseFileElement, chooseFileText);

    const orDragItHereText = this._renderer.createText('or drag it here');

    this._renderer.appendChild(actionElement, chooseFileElement);
    this._renderer.appendChild(actionElement, orDragItHereText);

    const descriptionElement = this._renderer.createElement('p');

    this._renderer.addClass(descriptionElement, 'image-uploader-empty-state-description');

    const extensionElement = this._renderer.createElement('span');
    const extensionText = this._renderer.createText('jpg, png, webp or heic');

    this._renderer.appendChild(extensionElement, extensionText);

    const dotElement = this._renderer.createElement('span');

    this._renderer.addClass(dotElement, 'image-uploader-empty-state-description-dot');

    const sizeElement = this._renderer.createElement('span');
    const sizeText = this._renderer.createText('20mb max');

    this._renderer.appendChild(sizeElement, sizeText);

    this._renderer.appendChild(descriptionElement, extensionElement);
    this._renderer.appendChild(descriptionElement, dotElement);
    this._renderer.appendChild(descriptionElement, sizeElement);

    this._renderer.appendChild(emptyStateElement, imageElement);
    this._renderer.appendChild(emptyStateElement, actionElement);
    this._renderer.appendChild(emptyStateElement, descriptionElement);

    this._mouseEnterObserver
      .pipe(
        tap(() => {
          this._renderer.addClass(emptyStateElement, 'hovered');
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._mouseLeaveObserver
      .pipe(
        tap(() => {
          this._renderer.removeClass(emptyStateElement, 'hovered');
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._dragEnterObserver
      .pipe(
        tap(() => {
          this._renderer.addClass(emptyStateElement, 'dragged-over');
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._dragLeaveObserver
      .pipe(
        tap(() => {
          this._renderer.removeClass(emptyStateElement, 'dragged-over');
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._renderObserver
      .pipe(
        tap(() => {
          if (this._images && this._images.length) {
            this._renderer.removeChild(containerElement, emptyStateElement);
            return;
          }

          this._renderer.appendChild(containerElement, emptyStateElement);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    return emptyStateElement;
  }

  private _makeImagePreview(containerElement: HTMLDivElement, image: UploadableImage): HTMLDivElement {
    const imageContainerElement = this._renderer.createElement('div');

    this._renderer.addClass(imageContainerElement, 'image-uploader-image');

    const removeElement = this._renderer.createElement('div');

    this._renderer.addClass(removeElement, 'image-uploader-image-remove');

    this._renderer.listen(removeElement, 'click', () => this._removeImage(image));

    const skeletonElement = this._renderer.createElement('div');

    this._renderer.addClass(skeletonElement, 'image-uploader-image-skeleton');

    this._renderer.appendChild(imageContainerElement, removeElement);
    this._renderer.appendChild(imageContainerElement, skeletonElement);

    this._renderer.appendChild(containerElement, imageContainerElement);

    let previewUrlObserver: Observable<string>;

    if (image.file) {
      previewUrlObserver = this._getFilePreviewUrl(image.file);
    } else if (image.previewUrl) {
      previewUrlObserver = of(image.previewUrl);
    } else {
      return imageContainerElement;
    }

    previewUrlObserver
      .pipe(
        switchMap(previewUrl => {
          return new Observable(subscriber => {
            const imageElement = this._renderer.createElement('img');

            this._renderer.listen(imageElement, 'load', () => {
              this._renderer.removeChild(imageContainerElement, skeletonElement);

              this._renderer.appendChild(imageContainerElement, imageElement);

              subscriber.next();
              subscriber.complete();
            });

            this._renderer.listen(imageElement, 'error', err => {
              subscriber.error(err);
            });

            this._renderer.setAttribute(imageElement, 'src', previewUrl);
          });
        }),
        takeUntil(this._destroy),
        catchError(err => {
          this._renderer.removeChild(imageContainerElement, skeletonElement);

          const brokenImageElement = this._renderer.createElement('div');

          this._renderer.addClass(brokenImageElement, 'image-uploader-image-broken');

          this._renderer.appendChild(imageContainerElement, brokenImageElement);

          return throwError(err);
        }),
      )
      .subscribe();

    return imageContainerElement;
  }

  private _placeDragArea(containerElement: HTMLDivElement): void {
    const dragAreaElement = this._renderer.createElement('div');

    this._renderer.addClass(dragAreaElement, 'image-uploader-drag-area');

    this._renderer.listen(dragAreaElement, 'mouseenter', () => {
      this._mouseEnterObserver.next();
    });

    this._renderer.listen(dragAreaElement, 'mouseleave', () => {
      this._mouseLeaveObserver.next();
    });

    this._renderer.listen(dragAreaElement, 'click', () => {
      const inputElement = this._elementRef?.nativeElement;
      if (!inputElement) {
        return;
      }

      inputElement.click();
    });

    const dropHandler = (event: Event & {dataTransfer: DataTransfer}) => {
      this._dragLeaveObserver.next(); // emit dragleave

      if (!event || !event.dataTransfer) {
        return;
      }

      event.preventDefault();

      const droppedFiles = event.dataTransfer.files;
      if (!droppedFiles || !droppedFiles.length) {
        return;
      }

      this._handleFileList(droppedFiles);
    };

    this._renderer.listen(dragAreaElement, 'drop', dropHandler);
    this._renderer.listen(dragAreaElement, 'dragdrop', dropHandler);

    this._renderer.listen(dragAreaElement, 'dragenter', event => {
      if (!event) {
        return;
      }

      event.preventDefault();

      this._dragEnterObserver.next();
    });

    this._renderer.listen(dragAreaElement, 'dragleave', event => {
      if (!event) {
        return;
      }

      event.preventDefault();

      this._dragLeaveObserver.next();
    });

    this._renderer.listen(dragAreaElement, 'dragover', event => {
      if (!event) {
        return;
      }

      event.preventDefault();
    });

    const plusElement = this._renderer.createElement('div');

    this._renderer.addClass(plusElement, 'image-uploader-drag-area-plus');

    this._renderObserver
      .pipe(
        tap(() => {
          if (this._images && this._images.length) {
            this._renderer.appendChild(dragAreaElement, plusElement);
            return;
          }

          this._renderer.removeChild(dragAreaElement, plusElement);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._renderer.appendChild(containerElement, dragAreaElement);
  }

  //
  // File utilities
  //

  private _isImage(file: File): boolean {
    const fileType = this._getFileType(file);
    if (!fileType) {
      return false;
    }

    return fileType.startsWith('image/');
  }

  private _getFileType(file: File): string {
    if (!file || !file.type) {
      return null;
    }

    return file.type;
  }

  private _getFilePreviewUrl(file: File): Observable<string> {
    if (!file) {
      return of(null);
    }

    const fileType = this._getFileType(file);
    if (!fileType) {
      return of(null);
    }

    if (fileType.includes('heic')) {
      return this._getHeicFilePreviewUrl(file);
    }

    return this._getImageFilePreviewUrl(file);
  }

  private _getImageFilePreviewUrl(file: File): Observable<string> {
    return new Observable<string>(subscriber => {
      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent) => {
        if (event.loaded !== event.total) {
          return;
        }

        subscriber.next(<string>fileReader.result);
        subscriber.complete();
      };

      fileReader.onerror = err => subscriber.error(err);

      fileReader.readAsDataURL(file);
    });
  }

  private _getHeicFilePreviewUrl(file: File): Observable<string> {
    return new Observable<string>(subscriber => {
      heic2any({blob: file, quality: 0.5, toType: 'image/jpeg'})
        .then(result => {
          const previewUrl = URL.createObjectURL(result);

          subscriber.next(previewUrl);
          subscriber.complete();
        })
        .catch(err => subscriber.error(err));
    });
  }
}
