<header>
  <nav class="w-navbar navbar navbar-light navbar-expand-lg bg-white">
    <div class="b-navbar">
      <a class="b-logo navbar-brand"
         [routerLink]="['/']">
        <img src="assets/img/logo-color.svg" alt="logo" srcset="" />
      </a>

      <button class="b-logo-toggle" (click)="openChannels()">
        <img src="assets/img/logos/logo-mob-color.svg" alt="logo" srcset="" />
      </button>

      <ul class="navbar-nav" *ngIf="isAuthenticated">
        <ng-container *ngIf="(profileInfo$ | async) as profileInfo">
          <ng-container *ngIf="info !== null && info.userStatus === UserStatus.Activated && profileInfo.isProfileCompleted">
            <li class="nav-item hidden-for-mob"
                *ngIf="!info.isPending"
                [routerLinkActive]="['active']">
              <a class="nav-link text-13 header-text"
                 [routerLink]="['/' + profileInfo.role + '/dashboard']">
                <span class="icon-dashboard"></span>
                Dashboard
              </a>
            </li>

            <li class="nav-item hidden-for-mob"
                *ngIf="hasAccessToInquiresAndRequests | async">
              <a role="button"
                 class="nav-link my-nav-link text-13 header-text"
                 (click)="openInquiriesAndRequests()">
                <span class="icon-inquiries"></span>
                Inquiries & Requests
              </a>
            </li>

            <li class="nav-item hidden-for-mob"
                *ngIf="hasAccessToBuildings | async"
                [routerLinkActive]="['active']">
              <a class="nav-link my-nav-link text-13 header-text"
                 [routerLink]="['/landlord/buildings']">
                <span class="icon-buildings"></span>
                Buildings
              </a>
            </li>

            <ng-container *ngIf="profileInfo.hasAnyLease && info?.userStatus === UserStatus.Activated">
              <li class="nav-item hidden-for-mob"
                  [routerLinkActive]="['active']">
                <a class="nav-link my-nav-link text-13 header-text"
                   [routerLink]="['/colabo/main']">
                  <span class="icon-Collabo"></span>
                  Colábo&reg;
                  <span class="badge badge-danger badge-right"
                        *ngIf="alertCount">
                    {{alertCount}}
                  </span>
                </a>
              </li>
            </ng-container>
            <li class="nav-item hidden-for-mob"
                *ngIf="!info.isPending && (role === Role.Tenant || role === Role.Broker)">
              <a role="button"
                 class="nav-link my-nav-link text-13 header-text"
                 (click)="openAvailabilities()">
                <span class="icon-Availabilities"></span>
                Availabilities
              </a>
            </li>
          </ng-container>
        </ng-container>

        <li class="nav-item dropdown">
          <a href="#" class="user-info-container d-flex align-items-center" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="user-info-indicator">
              <img class="rounded-circle mw-100 mh-100 avatar" [src]="avatarUrl" alt="avatar" srcset="" />
              <span [ngClass]="projectsIndicatorClasses" ></span>
            </div>

            <div class="user-info d-flex flex-column">
              <div class="d-flex">
                <span class="user-info-name">
                  {{ displayName }}
                </span>
              </div>
              <span class="user-info-role">
                {{ role }}
              </span>
            </div>
            <!--user-info-->

            <span class="icon-down"></span>
          </a>

          <div class="dropdown-menu">
            <ng-container *ngIf="(profileInfo$ | async) as profileInfo">
              <ng-container *ngIf="profileInfo.isProfileCompleted">
                <div class="dropdown-menu-flex">
                  <img src="assets/img/Profile.svg" alt="">
                  <a class="dropdown-item text-uppercase text-13"
                     [routerLink]="['/profile']" [routerLinkActive]="['active']">
                    Profile
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="profileInfo.hasAnyLease && info.userStatus === UserStatus.Activated">
                <div class="dropdown-menu-flex"
                     *ngIf="shouldShowAvailabilitiesLink()">
                  <img src="assets/img/Availabilities.svg" alt="">
                  <a role="button"
                     class="dropdown-item text-uppercase text-13"
                     (click)="openOwnCompanyAvailabilities()">
                    Availabilities
                  </a>
                </div>

                <div class="dropdown-menu-flex"
                     *ngIf="showDocumentLink()">
                  <img src="assets/img/Document.svg" alt="">
                  <a class="dropdown-item text-uppercase text-13"
                     [routerLink]="['/profile/documents']">
                    Documents
                  </a>
                </div>
              </ng-container>
            </ng-container>

            <div class="dropdown-menu-flex">
              <img src="assets/img/Contact.svg" alt="">
              <a class="dropdown-item text-uppercase text-13"
                 href="https://statera.re/" target="_blank">
                Contact Us
              </a>
            </div>
            <div class="dropdown-menu-flex">
              <img src="assets/img/SignOut.svg" alt="">
              <a class="dropdown-item text-uppercase text-13 sing-out-link" (click)="signOut()">
                Signout
              </a>
            </div>
          </div>
        </li>
      </ul>
      <!--navbar-nav-->
    </div>
  </nav>
  <!--w-navbar-->
</header>

<form #signOutForm ngNoForm action="/signout" method="POST"></form>
