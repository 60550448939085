import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DxButtonModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';

import { BrokerRouterModule } from './broker.router';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { DashboardModule } from '../dashboard/dashboard.module';

import { TenantPagesService } from '../user/services/tenant-pages.service';

import { GreetingDialogComponent } from './components/greeting-dialog/greeting-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrokerRouterModule,
    InfrastructureModule,
    DashboardModule,

    DxButtonModule,
    DxTextBoxModule,
    DxScrollViewModule,
    DxSelectBoxModule,
  ],
  declarations: [
    GreetingDialogComponent,
  ],
  providers: [
    TenantPagesService,
  ],
  entryComponents: [
    GreetingDialogComponent,
  ],
})
export class BrokerModule {
  static forRoot(): ModuleWithProviders<BrokerModule> {
    return {
      ngModule: BrokerModule,
      providers: [
        TenantPagesService,
      ],
    };
  }
}
