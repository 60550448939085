<dx-scroll-view width="100%" [showScrollbar]="'always'">
  <table class="table">
    <thead>
    <tr>
      <th [class.order-desc]="orderByConfig === '-tenantCompanyName'"
          [class.order-asc]="orderByConfig === 'tenantCompanyName'"
          (click)="applyColumnOrder('tenantCompanyName')">
        Tenant
      </th>
      <th [class.order-desc]="orderByConfig === '-buildingAddress'"
          [class.order-asc]="orderByConfig === 'buildingAddress'"
          (click)="applyColumnOrder('buildingAddress')">
        Building Address
      </th>
      <th [class.order-desc]="orderByConfig === '-unit'"
          [class.order-asc]="orderByConfig === 'unit'"
          (click)="applyColumnOrder('unit')">
        Unit
      </th>
      <th [class.order-desc]="orderByConfig === '-squareFeet'"
          [class.order-asc]="orderByConfig === 'squareFeet'"
          (click)="applyColumnOrder('squareFeet')">
        Size
        <span class="badge badge-danger">
          Total: {{getTotalSquareFeet() | safeNumber: '1.0-0'}} SF
        </span>
      </th>
      <th [class.order-desc]="orderByConfig === '-leaseExpirationDate'"
          [class.order-asc]="orderByConfig === 'leaseExpirationDate'"
          (click)="applyColumnOrder('leaseExpirationDate')">
        LXP
      </th>
    </tr>
    </thead>
    <tbody>
    <tr title="Go to deal"
        *ngFor="let tableRow of tableData;"
        (click)="navigateTo3PanelView(tableRow)">
      <td>
        {{tableRow.tenantCompanyName}}
      </td>
      <td>
        {{tableRow.buildingAddress}}
      </td>
      <td>
        {{tableRow.unit}}
      </td>
      <td>
        {{tableRow.squareFeet | safeNumber }} SF
      </td>
      <td>
        {{tableRow.leaseExpirationDate | safeDate: 'MMMM d, y'}}
        <span class="arrow"></span>
      </td>
    </tr>
    </tbody>
  </table>
</dx-scroll-view>
