import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { StateraUserClaimRepository } from './statera-user-claim.repository';

import * as models from '@statera/sdk/common';

@Injectable()
export class StateraUserClaimManager {
  stateraUserClaims$: BehaviorSubject<models.IStateraUserClaimsSimplifiedViewModel>;
  stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel;

  private readonly _lastTimeUserClaimsUpdatedHeaderKey = 'Last-Time-User-Claims-Updated';
  private _getStateraUserClaimsRequestInProcess = false;
  private readonly _stateraUserClaimRepository: StateraUserClaimRepository;

  constructor(
    stateraUserClaimRepository: StateraUserClaimRepository,
  ) {
    this._stateraUserClaimRepository = stateraUserClaimRepository;

    this.stateraUserClaims$ = new BehaviorSubject<models.IStateraUserClaimsSimplifiedViewModel>(null);
  }

  checkIfStateraUserClaimsWasUpdated(httpResponse: HttpResponse<any>): void {
    const lastTimeUserClaimsUpdatedHeaderValue = httpResponse.headers.get(this._lastTimeUserClaimsUpdatedHeaderKey);
    if (this._wasStateraUserClaimsUpdated(lastTimeUserClaimsUpdatedHeaderValue) &&
      !this._getStateraUserClaimsRequestInProcess) {
      this._getStateraUserClaimsRequestInProcess = true;
      const _getStateraUserClaims$Subscription =
        this.requestStateraUserClaims$()
          .subscribe(x => {
              _getStateraUserClaims$Subscription.unsubscribe();
              this._getStateraUserClaimsRequestInProcess = false;
            }
          );
    }
  }

  requestStateraUserClaims$(): Observable<models.IStateraUserClaimsSimplifiedViewModel> {
    return this._stateraUserClaimRepository
      .requestStateraUserClaims$()
      .pipe(
        tap(stateraUserClaims => {
          this.stateraUserClaims$.next(stateraUserClaims);
          this.stateraUserClaims = stateraUserClaims;
        }),
      );
  }

  checkUserAccess(
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId: number = null,
    portfolioId: number = null,
    buildingId: number = null,
    leaseId: number = null,
  ): boolean {
    const stateraUserClaims = this.stateraUserClaims;
    return this.checkAccess(
      stateraUserClaims,
      stateraClaimType,
      stateraClaimValue,
      companyId,
      portfolioId,
      buildingId,
      leaseId,
    );
  }

  checkAccess(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId?: number,
    portfolioId?: number,
    buildingId?: number,
    leaseId?: number,
  ): boolean {
    if (!stateraUserClaims) {
      return false;
    }

    if (!companyId && !portfolioId && !buildingId && !leaseId) {
      return false;
    }

    let hasAccess = true;

    if (companyId) {
      hasAccess = hasAccess && this.checkAccessToCompany(stateraUserClaims, stateraClaimType, stateraClaimValue, companyId);
    }

    if (portfolioId) {
      hasAccess = hasAccess && this.checkAccessToPortfolio(stateraUserClaims, stateraClaimType, stateraClaimValue, portfolioId);
    }

    if (buildingId) {
      hasAccess = hasAccess && this.checkAccessToBuilding(stateraUserClaims, stateraClaimType, stateraClaimValue, buildingId);
    }

    if (leaseId) {
      hasAccess = hasAccess && this.checkAccessToLease(stateraUserClaims, stateraClaimType, stateraClaimValue, leaseId);
    }

    return hasAccess;
  }

  checkAccessToCompany(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.CompanyGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.CompanyGroup)].includes(companyId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToPortfolio(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    portfolioId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PortfolioGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PortfolioGroup)].includes(portfolioId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToBuilding(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    buildingId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.BuildingGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.BuildingGroup)].includes(buildingId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToLease(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    leaseId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.LeaseGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.LeaseGroup)].includes(leaseId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  private _wasStateraUserClaimsUpdated(lastTimeUserClaimsUpdatedHeaderValue: string): boolean {
    const stateraUserClaims = this.stateraUserClaims;
    return (
      lastTimeUserClaimsUpdatedHeaderValue && stateraUserClaims &&
      stateraUserClaims.lastUpdatedTicksAsString !== lastTimeUserClaimsUpdatedHeaderValue
    );
  }

  getGroupName(group: models.StateraClaimsGroupKind): string {
    const groupName = models.StateraClaimsGroupKind[group];
    return `${groupName.charAt(0).toLowerCase()}${groupName.substring(1)}`;
  }

  getClaimTypeName(stateraClaimType: models.StateraClaimTypeAsEnum): string {
    const claimType = models.StateraClaimTypeAsEnum[stateraClaimType];
    return `${claimType.charAt(0).toLowerCase()}${claimType.substring(1)}`;
  }
}
