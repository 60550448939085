<div class="view-container">
  <div class="view-container-chart">
    <div class="view-container-chart-arrows">
      <button class="left" title="Previous period"
              (click)="prevGroup()">
        <span></span>
      </button>
      <span class="view-container-chart-arrows-caption">
        {{ dealsByStageReportLeaseStages[activeIndex]?.stageName }}
      </span>
      <button class="right" title="Next period"
              (click)="nextGroup()">
        <span></span>
      </button>
    </div>

    <app-analytics-deals-by-stage-report-single-stage [dealsByStageReportLeaseStage]="dealsByStageReportLeaseStages[activeIndex]">
    </app-analytics-deals-by-stage-report-single-stage>
  </div>

  <div class="view-container-table">
    <app-analytics-deals-by-stage-report-single-stage-table [dealsByStageLeaseStage]="dealsByStageReportLeaseStages[activeIndex]">
    </app-analytics-deals-by-stage-report-single-stage-table>
  </div>
</div>
