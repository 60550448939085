import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AcceptInvitationComponent } from './components/accept-invitation/accept-invitation.component';

import { AcceptInvitationGuard } from './guards/accept-invitation.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'accept-invitation',
        canActivate: [AcceptInvitationGuard],
        component: AcceptInvitationComponent,
      },
    ]),
  ],
  exports: [
    RouterModule,
  ],
})
export class InvitationRouter {}
