<footer class="bg-dark">

  <div class="footer-full">
    <div class="container d-flex p-5 w-100 align-items-center justify-content-between flex-wrap">
      <div class="d-flex flex-column">
        <div>
          <img src="assets/img/logo-white.png" alt="logo-white" />
        </div>
        <div class="d-flex mt-4">
          <p class=" text-secondary">
              &copy; {{currentDate | date: 'yyyy' }} Statera RE, Inc. All rights reserved. | <a href="http://www.statera.re/privacy" class="text-secondary">Privacy Policy</a>
          </p>
        </div>
      </div>

      <div class="social-media d-flex mw-100 justify-content-between">
        <a href="https://www.facebook.com/Staterare-745920955759002/" target="_blank">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/StateraR" target="_blank">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="https://linkedin.com/in/statera-cre-4a93a016b" target="_blank">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <!--<a href="#" target="_blank">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>-->
      </div>
    </div>
  </div>


  <div class="footer-mobile p-2">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="text-capitalize text-secondary text-14 ">
            &copy; statera,{{currentDate | date: 'yyyy' }} all rights reserved
        </span>
      <div class="d-flex">
        <button class="btn bg-transparent">
          <span class="text-capitalize border-bottom text-14 border-secondary text-secondary">
            privacy
          </span>
        </button>
        <button class="btn bg-transparent">
          <span class="text-capitalize border-bottom text-14  border-secondary text-secondary">
            terms
          </span>
        </button>
      </div>
    </div>
  </div>

</footer>
