import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';


@Component({
  selector: 'app-non-disturbance',
  templateUrl: './non-disturbance.component.html',
  styleUrls: ['./non-disturbance.component.scss']
})
export class NonDisturbanceComponent extends BaseTermDirective<models.INonDisturbanceTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  NonDisturbanceTermType: typeof models.NonDisturbanceTermType = models.NonDisturbanceTermType;

  private readonly _destroy$: Subject<void>;

  options = [];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();
  }

  saveLeaseTerm($event) {
    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Landlord will provide Tenant with an acceptable Non-Disturbance Agreement (SNDA) from the lender of the building.',
          value: models.NonDisturbanceTermType.LandlordProvidesSnda,
        },
        {
          name: 'To be addressed in the lease document.',
          value: models.NonDisturbanceTermType.ToBeAddressedInLease,
        },
        {
          name: 'Custom',
          value: models.NonDisturbanceTermType.Custom,
        },
      ];
      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.NonDisturbanceTermType.PerCurrentLease,
      },
      {
        name:
          'Landlord will provide Tenant with an acceptable Non-Disturbance Agreement (SNDA) from the lender of the building.',
        value: models.NonDisturbanceTermType.LandlordProvidesSnda,
      },
      {
        name: 'To be addressed in the lease document.',
        value: models.NonDisturbanceTermType.ToBeAddressedInLease,
      },
      {
        name: 'Custom',
        value: models.NonDisturbanceTermType.Custom,
      },
    ];
  }
}
