import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ArcElement, Chart, ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsManager } from '@statera/sdk/analytics';
import { CommonTools } from '@statera/sdk/common';

import { AnalyticsTooltipFactoryService } from '../../services/analytics-tooltip-factory.service';
import { AnalyticsTooltipService } from '../../services/analytics-tooltip.service';
import { DialogService } from '../../../dialog/services/dialog.service';

import {
  ActiveDealsByStageReportSingleStageDialogComponent,
  IActiveDealsByStageReportSingleStageDialogViewModel
} from '../active-deals-by-stage-report-single-stage-dialog/active-deals-by-stage-report-single-stage-dialog.component';

import { ActiveDealsByStageReportTooltipComponent } from '../active-deals-by-stage-report-tooltip/active-deals-by-stage-report-tooltip.component';

import { ActiveDealsByStageReportTooltip } from '../../models/active-deals-by-stage-report-tooltip.model';

import * as models from '../../../infrastructure/models/generated';

type TooltipComponentType = ActiveDealsByStageReportTooltipComponent;
type TooltipModelType = ActiveDealsByStageReportTooltip;

interface StageGroupTheme {
  baseColor: string;
  accentColor: string;
}

interface StageGroupLegend {
  group: models.IActiveDealsByStageReportStageGroupViewModel;
  theme: StageGroupTheme;
}

@Component({
  selector: 'app-analytics-active-deals-by-stage-report',
  templateUrl: 'active-deals-by-stage-report.component.html',
  styleUrls: ['active-deals-by-stage-report.component.scss'],
})
export class ActiveDealsByStageReportComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly _stageGroupThemes: Array<StageGroupTheme> = [
    {
      // green
      baseColor: 'rgba(155, 211, 179, 1)',
      accentColor: 'rgba(89, 182, 128, 1)',
    },
    {
      // red
      baseColor: 'rgba(240, 156, 148, 1)',
      accentColor: 'rgba(231, 90, 77, 1)',
    },
    {
      // blue
      baseColor: 'rgba(161, 168, 247, 1)',
      accentColor: 'rgba(84, 97, 240, 1)',
    },
    {
      // sea
      baseColor: 'rgba(179, 227, 212, 1)',
      accentColor: 'rgba(129, 208, 184, 1)',
    },
    {
      // yellow
      baseColor: 'rgba(244, 203, 141, 1)',
      accentColor: 'rgba(236, 167, 65, 1)',
    },
    {
      // pink
      baseColor: 'rgba(239, 185, 230, 1)',
      accentColor: 'rgba(229, 139, 214, 1)',
    },
    {
      // cyan
      baseColor: 'rgba(203, 242, 246, 1)',
      accentColor: 'rgba(168, 234, 240, 1)',
    },
    {
      // peach
      baseColor: 'rgba(242, 179, 145, 1)',
      accentColor: 'rgba(234, 129, 71, 1)',
    },
    {
      // purple
      baseColor: 'rgba(200, 177, 239, 1)',
      accentColor: 'rgba(157, 118, 227, 1)',
    },
  ];

  @ViewChild('canvasElementRef') canvasElementRef: ElementRef;
  @ViewChild('doughnutChart') doughnutChart: BaseChartDirective;

  @Input() analyticsFilters: models.IAnalyticsFiltersViewModel;

  isLoaded: boolean;

  doughnutChartOptions: ChartOptions<'doughnut'>;
  doughnutChartData: ChartConfiguration<'doughnut'>['data'];
  doughnutChartPlugins: ChartConfiguration<'doughnut'>['plugins'];
  doughnutChartLegend: Array<StageGroupLegend>;

  private _activeDealsByStageReport: models.IActiveDealsByStageReportViewModel;
  private _tooltipComponent: TooltipComponentType;

  private readonly _analyticsManager: AnalyticsManager;
  private readonly _analyticsTooltipService: AnalyticsTooltipService<TooltipComponentType, TooltipModelType>;
  private readonly _dialogService: DialogService;
  private readonly _destroy$: Subject<void>;

  constructor(
    analyticsManager: AnalyticsManager,
    analyticsTooltipFactoryService: AnalyticsTooltipFactoryService,
    dialogService: DialogService,
  ) {
    this._analyticsManager = analyticsManager;
    this._analyticsTooltipService = analyticsTooltipFactoryService.create<TooltipComponentType, TooltipModelType>();
    this._dialogService = dialogService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.analyticsFilters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    this._configureChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    const isChanged = (change: SimpleChange) => {
      return (
        change &&
        (
          change.isFirstChange() ||
          (
            (change.previousValue && change.currentValue) &&
            !CommonTools.isEqual(change.previousValue, change.currentValue)
          )
        )
      );
    };

    if (
      isChanged(changes.analyticsFilters)
    ) {
      const isFirstChange = (change: SimpleChange) => change && change.isFirstChange();

      // Configure chart at first run
      if (
        isFirstChange(changes.analyticsFilters)
      ) {
        this._configureChart();
      }

      this._setupChartData();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get maxStage(): { percents: number, name: string } {
    if (!this._activeDealsByStageReport || !this._activeDealsByStageReport.groups) {
      return {
        percents: 0,
        name: 'Stage 1'
      };
    }

    let amount = 0;
    const nameCount = this._activeDealsByStageReport.groups.map(g => {
      const deals = g.stages && g.stages.length || 0;
      amount += deals;
      return {
        count: deals,
        name: g.name
      };
    });

    const maxStage = nameCount.sort((left, right) => right.count - left.count)[0];

    if (!maxStage || maxStage.count === 0) {
      return {
        percents: 0,
        name: 'Stage 1'
      };
    }

    return {percents: Math.round(maxStage.count / (amount / 100)), name: maxStage.name};
  }

  handleChartClickEvent(event): void {
    if (
      !event || !event.active || !event.active[0] ||
      !this._activeDealsByStageReport || !this._activeDealsByStageReport.groups || !this._activeDealsByStageReport.groups.length
    ) {
      return;
    }

    const activeItemIndex = event.active[0]._index;
    this.showChart(activeItemIndex);
  }

  showChart(activeItemIndex = 0) {
    if (!this._activeDealsByStageReport.groups[activeItemIndex]) {
      return;
    }

    const activeDealsByStageReportSingleStageDialogViewModel: IActiveDealsByStageReportSingleStageDialogViewModel = {
      groups: this._activeDealsByStageReport.groups,
      initialGroupId: activeItemIndex
    };

    this._dialogService.show(ActiveDealsByStageReportSingleStageDialogComponent, {
      title: this._activeDealsByStageReport.groups[activeItemIndex].name,
      showCloseButton: true,
      width: 1024,
      height: '95%',
      containerClassNames: ['overflow-hidden'],
      injectableData: {
        activeDealsByStageReportSingleStageDialogViewModel: activeDealsByStageReportSingleStageDialogViewModel,
      },
    });
  }

  private _configureChart(): void {
    this.doughnutChartLegend = [];
    this.doughnutChartData = {
      labels: [],
      datasets: [],
    };
    this.doughnutChartOptions = {
      maintainAspectRatio: false,
      cutout: '93%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (x) => this._externalTooltipHandler(x.tooltip),
        },
      },
    };
    this.doughnutChartPlugins = [
      {
        id: 'statera-custom-arc-doughnut-plugin',

        afterUpdate: (chart: Chart) => {
          const meta = chart.getDatasetMeta(0);
          const arcs = meta.data;

          arcs.forEach((arc: any) => {
            if (!(arc instanceof ArcElement)) {
              return;
            }

            arc.round = {
              x: (chart.chartArea.left + chart.chartArea.right) / 2,
              y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
              radius: (<number>arc.outerRadius + <number>arc.innerRadius) / 2,
              thickness: (arc.outerRadius - arc.innerRadius) / 2,
              backgroundColor: arc.options.backgroundColor,
            };
          });
        },

        afterDraw: (chart: Chart) => {
          const {ctx} = chart;
          const meta = chart.getDatasetMeta(0);

          meta.data.forEach((arc: any) => {
            if (!(arc instanceof ArcElement) || arc.circumference <= 0) {
              return;
            }

            const angle = Math.PI / 2 - arc.endAngle;
            const radius = arc.round.radius;

            ctx.save();
            ctx.translate(arc.round.x, arc.round.y);
            ctx.fillStyle = arc.options.backgroundColor;
            ctx.beginPath();
            ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), arc.round.thickness, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.restore();
          });
        }
      }
    ];
  }

  private _setupChartData(): void {
    this.isLoaded = false;

    this._requestActiveDealsByStageReport()
      .pipe(
        tap(activeDealsByStageReport => {
          if (!activeDealsByStageReport || !activeDealsByStageReport.groups) {
            return;
          }

          this._activeDealsByStageReport = activeDealsByStageReport;

          const {groups} = activeDealsByStageReport;

          const {_stageGroupThemes: themes} = ActiveDealsByStageReportComponent;

          this.doughnutChartData.labels = groups.map(x => x.name);
          this.doughnutChartLegend = groups.map((x, i) => ({group: x, theme: themes[i]}));
          this.doughnutChartData.datasets = [
            {
              data: groups.map(x => x.leaseIds.length),
              backgroundColor: groups.map((_, i) => themes[i].baseColor),
              hoverBackgroundColor: groups.map((_, i) => themes[i].accentColor),
              borderWidth: 0,
            },
          ];
        }),
        tap(() => this.isLoaded = true),
        catchError(err => {
          this.isLoaded = true;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _requestActiveDealsByStageReport(): Observable<models.IActiveDealsByStageReportViewModel> {
    const filters = this.analyticsFilters || <models.IAnalyticsFiltersViewModel>{};

    return this._analyticsManager
      .getActiveDealsByStageReport(
        filters,
      );
  }

  private _externalTooltipHandler(tooltipModel: TooltipModel<'doughnut'>): void {
    if (this.canvasElementRef && this.canvasElementRef.nativeElement) {
      this.canvasElementRef.nativeElement.classList.remove('cursor-pointer');
    }

    if (!tooltipModel.getActiveElements().length) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    if (this._tooltipComponent) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
    }

    if (
      !tooltipModel || !tooltipModel.dataPoints || !tooltipModel.dataPoints.length ||
      !this._activeDealsByStageReport || !this._activeDealsByStageReport.groups || !this._activeDealsByStageReport.groups.length ||
      !this.canvasElementRef || !this.canvasElementRef.nativeElement
    ) {
      this._analyticsTooltipService.hide();
      this._tooltipComponent = null;
      return;
    }

    this.canvasElementRef.nativeElement.classList.add('cursor-pointer');

    const activeItemIndex = tooltipModel.dataPoints[0].dataIndex;

    const activeDealsByStageReportStageGroup = this._activeDealsByStageReport.groups[activeItemIndex];
    if (!activeDealsByStageReportStageGroup) {
      return;
    }

    this._tooltipComponent = this._analyticsTooltipService.show(
      ActiveDealsByStageReportTooltipComponent,
      activeDealsByStageReportStageGroup,
      {
        tooltipModel: tooltipModel,
        canvasElement: this.canvasElementRef.nativeElement,
      },
    );
  }
}
