import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';

import { BuildingFormComponent } from '../building-form/building-form.component';

@Component({
  selector: 'app-edit-building',
  templateUrl: './edit-building.component.html',
  styleUrls: ['./edit-building.component.scss']
})
export class EditBuildingComponent implements AfterViewInit {
  @ViewChild('buildingForm') buildingForm: BuildingFormComponent;

  id = 0;

  private readonly _route: ActivatedRoute;
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _buildingManager: BuildingManager;

  constructor(
    route: ActivatedRoute,
    buildingManager: BuildingManager,
    landlordStore: LandlordStore,
    landlordManager: LandlordManager
  ) {
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
    this._buildingManager = buildingManager;
    this._route = route;

    this._landlordStore
      .portfolios$
      .subscribe((portfolios) => {
        if (portfolios && this.buildingForm) {
          this.buildingForm.building = this._buildingManager
            .findInPortfolios(this.id, portfolios);
        }
      });
  }

  ngAfterViewInit() {
    this._route.params.subscribe(params => {
      this.id = parseInt(params['id'], 10);
      this._landlordStore
        .portfolios$
        .pipe(
          take(1),
        )
        .subscribe((portfolios) => {
          this.buildingForm.building = this._buildingManager
            .findInPortfolios(this.id, portfolios);
        });
    });
  }
}
