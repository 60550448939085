import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';
import * as models from '@statera/sdk/common';

@Injectable()
export class FinancialsRequestRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  uploadFinancialsFiles(alertId: number, leaseRequestId: number, files: FileList): Observable<void> {
    let params = new HttpParams();

    if (alertId) {
      params = params.set('alertId', alertId.toString(10));
    }

    const formData = new FormData();

    if (files) {
      for (let i = 0, num = files.length; i < num; i++) {
        const file = files[i];
        if (!file) {
          continue;
        }

        formData.append('financialsRequestFiles', file, file.name);
      }
    }

    return this._httpClient
      .post<void>(`${this._configuration.apiEndpoint}/lease-requests/financial-requests/${leaseRequestId}/documents`, formData, {params});
  }

  uploadFinancialsFilesByLeaseId(leaseId: number, files: FileList): Observable<void> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    const formData = new FormData();

    if (files) {
      for (let i = 0, num = files.length; i < num; i++) {
        const file = files[i];
        if (!file) {
          continue;
        }

        formData.append('financialsRequestFiles', file, file.name);
      }
    }

    return this._httpClient
      .post<void>(`${this._configuration.apiEndpoint}/lease-requests/financial-requests/documents`, formData, {params});
  }

  getFinancialRequests(leaseId: number): Observable<Array<models.ILeaseRequestViewModel>> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .get<Array<models.ILeaseRequestViewModel>>(
        `${this._configuration.apiEndpoint}/lease-requests/financial-requests`,
        { params }
      );
  }

  approveFinancialRequest(requestId: number): Observable<models.ILeaseRequestViewModel> {
    return this._httpClient
      .post<models.ILeaseRequestViewModel>(
        `${this._configuration.apiEndpoint}/lease-requests/financial-requests/${requestId}/approve`,
        null
      );
  }

  createFinancialRequest(leaseId: number, request: models.IFinancialRequestViewModel): Observable<models.ILeaseRequestViewModel> {
    let params = new HttpParams();

    if (leaseId) {
      params = params.set('leaseId', leaseId.toString(10));
    }

    return this._httpClient
      .post<models.ILeaseRequestViewModel>(
        `${this._configuration.apiEndpoint}/lease-requests/financial-requests`,
        request,
        { params }
      );
  }
}
