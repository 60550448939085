import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  static anonymousPaths = [
    '/signin-user',
    '/signup',
    '/forgot-password',
    '/server-error',
    '/reset-password',
    '/2fa'
  ];

  private readonly _authService: AuthService;
  private readonly _router: Router;

  constructor(authService: AuthService, router: Router) {
    this._authService = authService;
    this._router = router;
  }

  canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this._authService
      .isAuthenticatedUser
      .pipe(
        map((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            if (AuthGuard.anonymousPaths.find(x => state.url.startsWith(x))) {
              return this._router
                .createUrlTree(
                  [''],
                );
            }

            return true;
          }

          if (AuthGuard.anonymousPaths.find(x => state.url.startsWith(x))) {
            return true;
          }

          return this._router
            .createUrlTree(
              ['/signin-user'],
              {
                queryParams: {
                  redirectUrl: state.url,
                },
              },
            );
        }),
      );
  }
}
