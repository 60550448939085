<div class="row"
     *ngIf="!fullTable && customRentalRateEscalationCustomResults && customRentalRateEscalationCustomResults.length > 1" >
  <p class="font-weight-bold">
    Custom Escalation:
  </p>
</div>

<div class="table-responsive"
     [class.with-margin]="!fullTable">
  <table class="table">
    <thead>
    <tr>
      <th class="align-middle w-25" colspan="5">
        preview:
      </th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td class="align-middle" [class.w-20]="fullTable" [class.w-50]="!fullTable">Lease Month</td>
        <td class="align-middle w-20" *ngIf="fullTable">Date</td>
        <td class="align-middle" [class.w-20]="fullTable" [class.w-50]="!fullTable" *ngIf="showPsfValueColumn()">{{getUnitMetricsDisplayValue()}}</td>
        <td class="align-middle w-20" *ngIf="showPercentColumn()">Escalation (%)</td>
        <td class="align-middle w-20" *ngIf="fullTable">Monthly Amount</td>
      </tr>
      <tr *ngFor="let customRentalRateEscalationCustomResult of customRentalRateEscalationCustomResults | orderBy: 'leaseMonth'; let index = index;"
          [class.changes-accepted]="isAccepted" [class.line-through]="isLineThrough"
          [class.landlord-changes]="isLandlord" [class.tenant-changes]="isTenant">
        <!-- Start: Lease Month -->
        <td class="align-middle"
            [class.w-20]="fullTable" [class.w-50]="!fullTable">
          <p class="mb-0">
            {{customRentalRateEscalationCustomResult.leaseMonth}}
          </p>
        </td>
        <!-- End: Lease Month -->
        <!-- Start: Date -->
        <td class="align-middle w-20"
            *ngIf="fullTable">
          <p class="mb-0">
            {{customRentalRateEscalationCustomResult.date | safeDate: 'MM.dd.yy'}}
          </p>
        </td>
        <!-- End: Date -->
        <!-- Start: $/PSF/Yr -->
        <td class="align-middle"
            [class.w-20]="fullTable" [class.w-50]="!fullTable"
            *ngIf="showPsfValueColumn()">
          <p class="mb-0">
            {{customRentalRateEscalationCustomResult.rsfValue | safeNumber : '1.2-2'}}
          </p>
        </td>
        <!-- End: $/PSF/Yr -->
        <!-- Start: Escalation (%) -->
        <td class="align-middle w-20"
            *ngIf="showPercentColumn()">
          <p class="mb-0">
            {{customRentalRateEscalationCustomResult.percentValue | safePercent: '1.0-2'}}
          </p>
        </td>
        <!-- End: Escalation (%) -->
        <!-- Start: Monthly Amount -->
        <td class="align-middle w-20"
            *ngIf="fullTable">
          <p class="mb-0">
            {{customRentalRateEscalationCustomResult.monthlyAmount | safeCurrency}}
          </p>
        </td>
        <!-- End: Monthly Amount -->
      </tr>
    </tbody>
  </table>
</div>
