import { Injectable } from '@angular/core';
import { EMPTY, Observable, race } from 'rxjs';

import { Configuration } from '@statera/sdk/common';
import { LoggerService } from '@statera/sdk/logger';

import { WebsocketConnection } from './websocket.connection';
import { WebsocketResponse } from './websocket.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketClient {
  private readonly _configuration: Configuration;
  private readonly _loggerService: LoggerService;
  private readonly _connections: {[endpoint: string]: WebsocketConnection<any>};

  constructor(configuration: Configuration, loggerService: LoggerService) {
    this._configuration = configuration;
    this._loggerService = loggerService;
    this._connections = {};
  }

  connectionTerminated(): Observable<Error> {
    if (!this._connections) {
      return EMPTY;
    }

    const connections = Object.values(this._connections);
    if (!connections || !connections.length) {
      return EMPTY;
    }

    return race(connections.map(x => x.connectionTerminated()));
  }

  listen<T>(endpoint: string, method: string): Observable<WebsocketResponse<T>> {
    let connection = this._connections[endpoint];

    // Keeps one connection per endpoint
    if (!connection) {
      const attemptsInterval = this._configuration.websocketReconnectionAttemptsInterval;
      const attemptsLimit = this._configuration.websocketReconnectionAttemptsLimit;

      connection = new WebsocketConnection<T>(
        endpoint,
        attemptsInterval,
        attemptsLimit,
        this._loggerService
      );

      this._connections[endpoint] = connection;
    }

    return connection.listen(method);
  }
}
