import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { FinancialsRequestManager } from '@statera/sdk/financials-request';
import { LeaseRequestManager } from '@statera/sdk/lease-request';
import { ProjectManager } from '@statera/sdk/project';
import { QuizManager } from '@statera/sdk/quiz';
import { QuizAnswer } from '@statera/sdk/tenant';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { TenantQuestions } from '../../../infrastructure/enums/questions';

import * as models from '../../../infrastructure/models/generated';
import {
  LeaseStatus,
  ProjectStatus,
  StateraClaimTypeAsEnum,
  StateraClaimValueAsEnum
} from '../../../infrastructure/models/generated';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { DocumentViewerService } from '../../../document-viewer/services/document-viewer.service';
import {
  InviteBrokerByLandlordDialogComponent
} from '../../../invitation/components/invite-broker-by-landlord-dialog/invite-broker-by-landlord-dialog.component';
import { BrokerInvitationType } from '../../../invitation/models/broker-invitation-type';

import {
  RequestFinancialsDialogComponent
} from '../../../lease-request/components/request-financials-dialog/request-financials-dialog.component';
import { LeaseService } from '../../../shared/services/lease.service';
import { ProjectService } from '../../../shared/services/project.service';
import { QuizService } from '../../../shared/services/quiz.service';
import { RequestService, RequestStatus } from '../../../shared/services/request.service';
import { RejectionReasonDialogComponent } from '../rejection-reason-dialog/rejection-reason-dialog.component';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

interface Request {
  date: string;
  type: string;
  quizAnswers: Array<models.IQuizAnswerViewModel>;

  quizItems: Array<models.IQuizItemViewModel>;
}


@Component({
  selector: 'app-tenant-requests',
  templateUrl: './tenant-requests.component.html',
  styleUrls: ['./tenant-requests.component.scss'],
})
export class TenantRequestsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() leaseId: number;

  @Input() financialRequests: Array<models.ILeaseRequestViewModel>;
  @Output() financialRequestsChange: EventEmitter<Array<models.ILeaseRequestViewModel>>;

  @Output() requestStatusChanged: EventEmitter<models.IProjectViewModel>;

  showApproveAndRejectBlocks = false;

  questions = TenantQuestions;

  customRender = [
    TenantQuestions.RequireLessSpace,
    TenantQuestions.RequireLessSpaceMinSF,
    TenantQuestions.RequireLessSpaceMaxSF,
    TenantQuestions.RequireMoreSpace,
    TenantQuestions.RequireMoreSpaceMinSF,
    TenantQuestions.RequireMoreSpaceMaxSF,
    TenantQuestions.SpaceIsNoLongerRequired,
    TenantQuestions.MovingOutOfState,
    TenantQuestions.State,
    TenantQuestions.City,
    TenantQuestions.SpaceUseChange,
    TenantQuestions.PleaseDescribeSpaceUseChange,
    TenantQuestions.IncorrectConfiguration,
    TenantQuestions.PleaseDescribeIncorrectConfigurationText,
    TenantQuestions.Economics,
    TenantQuestions.MinAmount,
    TenantQuestions.MaxAmount,
    TenantQuestions.Comment
  ];

  ProjectRenewalRejectionStatus = models.ProjectRenewalRejectionStatus;

  requests: Array<Request>;
  openedRequests: Array<Request> = [];

  QuestionType: typeof models.QuestionType;
  AnswerType: typeof models.AnswerType;
  RequestStatus: typeof RequestStatus;

  project: models.IProjectViewModel;

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  searchRequest: string;

  private _lease: models.ILeaseViewModel;
  private _quizId: number = environment.renewalOnboardingQuizId;
  private _tenantCancellationQuizId: number = environment.tenantCancellationQuizId;

  private readonly _projectManager: ProjectManager;
  private readonly _projectService: ProjectService;
  private readonly _requestService: RequestService;
  private readonly _leaseService: LeaseService;
  private readonly _leaseRequestManager: LeaseRequestManager;
  private readonly _dialogService: DialogService;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _documentViewerService: DocumentViewerService;
  private readonly _destroy$: Subject<void>;
  private readonly _quizService: QuizService;
  private readonly _financialRequestManager: FinancialsRequestManager;
  private readonly _authService: AuthService;
  private readonly _quizManager: QuizManager;
  private readonly _claimService: StateraUserClaimManager;

  constructor(
    projectManager: ProjectManager,
    projectService: ProjectService,
    requestService: RequestService,
    leaseService: LeaseService,
    leaseRequestManager: LeaseRequestManager,
    dialogService: DialogService,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    documentViewerService: DocumentViewerService,
    quizService: QuizService,
    financialRequestManager: FinancialsRequestManager,
    authService: AuthService,
    quizManager: QuizManager,
    claimService: StateraUserClaimManager,
  ) {
    this.financialRequestsChange = new EventEmitter<Array<models.ILeaseRequestViewModel>>();
    this.requestStatusChanged = new EventEmitter<models.IProjectViewModel>();

    this._projectManager = projectManager;
    this._projectService = projectService;
    this._requestService = requestService;
    this._leaseService = leaseService;
    this._leaseRequestManager = leaseRequestManager;
    this._dialogService = dialogService;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._documentViewerService = documentViewerService;
    this._quizService = quizService;
    this._financialRequestManager = financialRequestManager;
    this._authService = authService;
    this._quizManager = quizManager;
    this._claimService = claimService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.requests = [];

    this.QuestionType = models.QuestionType;
    this.AnswerType = models.AnswerType;
    this.RequestStatus = RequestStatus;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseId &&
      (
        changes.leaseId.isFirstChange() ||
        changes.leaseId.previousValue !== changes.leaseId.currentValue
      )
    ) {
      this._projectService
        .getProjectsByLeaseId(this.leaseId)
        .pipe(
          switchMap((projects) => {
            this.project = projects.find(x => x);

            if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.Restructure) {
              this._quizId = environment.restructureOnboardingQuizId;
            }

            if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
              this._quizId = environment.newDealQuizId;
            }

            const observable = forkJoin([
              this._leaseService.getLease(this.leaseId),
              this._quizService.getOnboardingAnswers(this.leaseId, this._tenantCancellationQuizId),
              this._quizManager.getSummaryQuizAnswers(this.leaseId, this._quizId)
            ]);

            return observable
              .pipe(
                tap(([lease, cancellationAnswers, quizItems]) => {
                  this._lease = lease;

                  if (this.project) {
                    let requestType = 'Renewal request';
                    if (this.project.projectTypeId === models.ProjectTypeEnum.Restructure) {
                      requestType = 'Restructure request';
                    }

                    if (this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
                      requestType = 'New Deal';
                    }

                    const milestones = [...this.project.milestones]
                      .sort((x, y) => x.templateItemId - y.templateItemId);

                    let requestDate = new Date().toString();
                    if (milestones && milestones.length && milestones[0].startDate) {
                      requestDate = milestones[0].startDate.toString();
                    }

                    this.requests = [
                      {
                        type: requestType,
                        date: requestDate,
                        quizAnswers: [],
                        quizItems: quizItems
                      }
                    ];

                    if (cancellationAnswers.length) {
                      this.requests.push({
                        type: 'Tenant Cancellation Survey',
                        date: this.project.rejectedDate.toString(),
                        quizAnswers: cancellationAnswers.sort((left, right) => left.quizQuestionId - right.quizQuestionId),
                        quizItems: []
                      });
                    }

                    this.openedRequests = [...this.requests];
                  }
                }),
                take(1),
                takeUntil(this._destroy$),
              );
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  isCustomRender(qId: number): boolean {
    return this.customRender.some(questionId => questionId === qId);
  }

  getQuestionAnswer(questionId: number, questions: Array<QuizAnswer>): QuizAnswer {
    return questions.find(q => q.quizQuestion.id === questionId);
  }

  approveRequest() {
    const request = this.financialRequests.find(fr => fr.requestStatus === models.LeaseRequestStatus.ReviewRequired
      && fr.requestType === models.LeaseRequestType.FinancialRequest);
    if (request) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmApproveFinancialsAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => this._financialRequestManager
            .approveFinancialRequest(request.id)
          ),
          tap((x) => {
            const financialRequest =  this.financialRequests.find(fr => fr.id === request.id);
            financialRequest.requestStatus = models.LeaseRequestStatus.Accepted;
            this.financialRequestsChange.emit(this.financialRequests);

            this._goToNextStage();
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    } else {
      this._goToNextStage();
    }
  }

  private _goToNextStage() {
    if (!this.project || !this.project.projectState) {
      return;
    }

    if (!this._projectManager.isEstablishCriteriaByLandlord(this.project) &&
        !this._projectManager.isUnsolicitedOfferLandlordReviewingFinancialDocuments(this.project)) {
      return;
    }

    if (this.project.projectTypeId === models.TenantRequestType.Inquiry) {
      this
        ._renewProject(models.RenewalProjectTriggerType.EstablishCriteriaByLandlord)
        .pipe(
          tap((project: models.IProjectViewModel): void => {
            if (!project) {
              return;
            }

            this.project = project;
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }

    let projectTriggerType: models.RenewalProjectTriggerType;
    switch (this.project.projectTypeId) {
      case models.ProjectTypeEnum.Renewal:
        projectTriggerType = models.RenewalProjectTriggerType.EstablishCriteriaByLandlord;
        break;
      case models.ProjectTypeEnum.NewDeal:
        projectTriggerType = models.RenewalProjectTriggerType.EstablishCriteriaByLandlord;
        break;

      case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
        projectTriggerType = models.RenewalProjectTriggerType.UnsolicitedOfferLandlordReviewedFinancialDocuments;
        break;

      case models.ProjectTypeEnum.Restructure:
        projectTriggerType = models.RenewalProjectTriggerType.Restructure_EstablishCriteriaByLandlord;
        break;

      default:
        return;
    }

    if (projectTriggerType === models.RenewalProjectTriggerType.UnsolicitedOfferLandlordReviewedFinancialDocuments) {
      this
        ._renewProject(projectTriggerType)
        .pipe(
          tap((project: models.IProjectViewModel): void => {
            if (!project) {
              return;
            }

            this.project = project;

            this.requestStatusChanged.emit(this.project);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();
    } else {
      this._showInviteBrokerDialog()
        .pipe(
          switchMap((invitationRequest) => this
            ._renewProject(projectTriggerType)
            .pipe(
              tap((project: models.IProjectViewModel): void => {
                if (!project) {
                  return;
                }

                this.project = project;

                this.requestStatusChanged.emit(this.project);

                if (invitationRequest) {
                  const brokerName = [invitationRequest.firstName, invitationRequest.lastName]
                    .filter(Boolean)
                    .join(' ');

                  this._alertService.pushSuccessAlert({
                    closable: true,
                    message: this._alertMessagesManager.getBrokerInvitedByTenantAlertText(brokerName),
                  });
                }

                this._router.navigate(['/colabo/terms'], {queryParams: {leaseId: project.leaseId}});
              }),
            )
          ),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  private _showInviteBrokerDialog(): Observable<models.IUserInvitationRequestViewModel> {
    const brokerInvited$ = new Subject<models.IUserInvitationRequestViewModel>();

    let isTenantInvitesBroker = false;

    const request = this.requests[0];
    if (request && request.quizAnswers) {
      isTenantInvitesBroker = request.quizAnswers
        .some(x =>
          x.quizQuestion &&
          x.questionOptionViewModel &&
          x.quizQuestion.questionType === models.QuestionType.BrokerInvitation &&
          x.questionOptionViewModel.answerType === models.AnswerType.InviteBroker
        );
    }

    this._dialogService
      .show(InviteBrokerByLandlordDialogComponent, {
        title: null,
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 700,
        height: 'auto',
        maxWidth: 700,
        injectableData: {
          leaseId: this.leaseId,
          projectType: this.project.projectTypeId,
          tenantCompany: this._lease.tenantCompany,
          isTenantInvitesBroker: isTenantInvitesBroker,
          brokerInvited$: brokerInvited$,
          brokerInvitationType: BrokerInvitationType.RenewalInitiatedByTenant
        },
      });

    return brokerInvited$;
  }

  private _renewProject(projectTrigger: models.RenewalProjectTriggerType): Observable<models.IProjectViewModel> {
    return this._projectService
      .renewalProject(this._lease, projectTrigger, this.project)
      .pipe(
        switchMap(() => this
          ._projectService
          .getProjectsByLeaseId(this.leaseId)
        ),
        map(projects => projects.find(x => x)),
        takeUntil(this._destroy$),
      );
  }

  rejectRequest() {
    let rejectTrigger = null;
    if (this.project.projectTypeId === models.ProjectTypeEnum.Renewal) {
      rejectTrigger = models.RenewalProjectTriggerType.RejectEstablishCriteriaByLandlord;
    }
    if (this.project.projectTypeId === models.ProjectTypeEnum.Restructure) {
      rejectTrigger = models.RenewalProjectTriggerType.Restructure_RejectEstablishCriteriaByLandlord;
    }

    if (this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      rejectTrigger = models.RenewalProjectTriggerType.RejectEstablishCriteriaByLandlord;
    }

    if (rejectTrigger) {
      const dialogRef = this._dialogService.show(RejectionReasonDialogComponent, {
        title: 'Reason for Decline',
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 450,
        height: 'auto',
        maxHeight: 700,
        injectableData: {
          rejectionLease: <models.IRejectionLeaseViewModel>{
            leaseId: this.leaseId,
          },
        },
      });

      dialogRef.onHiding
        .pipe(
          takeUntil(this._destroy$),
          switchMap(() => {
            if (!dialogRef.outputData || !dialogRef.outputData.rejectionLease) {
              return of(null);
            }

            return this._projectService
              .renewalProject(this._lease,
                rejectTrigger,
                this.project, null, null, null,
                dialogRef.outputData.rejectionLease)
              .pipe(
                takeUntil(this._destroy$),
                switchMap(() => this._projectService
                  .getProjectsByLeaseId(this.leaseId)
                  .pipe(
                    takeUntil(this._destroy$),
                    tap((projects) => {
                      const project = projects.find(x => x);
                      if (!project) {
                        return;
                      }

                      this.project = project;

                      this.requestStatusChanged.emit(this.project);
                    }),
                  ),
                ),
              );
          }),
        )
        .subscribe();
    }
  }

  renderAnswer(quizAnswer: models.IQuizAnswerViewModel): (
    string |
    Array<string> |
    number |
    Date |
    Array<models.IUserInvitationRequestViewModel> |
    Array<models.IQuizQuestionOptionViewModel> |
    { [index: string]: number }
  ) {
    if (!quizAnswer || !quizAnswer.quizQuestion) {
      return;
    }

    return this._quizManager.renderAnswer(quizAnswer, this._lease);
  }

  previewFinancialFiles(quizAnswer: models.IQuizAnswerViewModel): void {
    if (!quizAnswer || !quizAnswer.quizAnswerFiles) {
      return;
    }

    const documents = quizAnswer.quizAnswerFiles.map(quizFile => {
      return {
        url: quizFile.url,
        name: quizFile.name,
      };
    });

    this._documentViewerService.show(documents, {
      width: '95%',
      height: '95%',
      maxWidth: 1800,
      closeOnOutsideClick: false,
      showCloseButton: true,
      title: (documents && documents[0] && documents[0].name) || 'Preview',
      activeIndex: 0,
    });
  }

  previewRequestedDocument(requestedDocument: models.ILeaseRequestFileViewModel): void {
    if (!requestedDocument || !requestedDocument.file) {
      return;
    }

    const documents = [
      {
        url: requestedDocument.file.url,
        name: requestedDocument.file.name,
      }
    ];

    this._documentViewerService.show(documents, {
      width: '95%',
      height: '95%',
      maxWidth: 1800,
      closeOnOutsideClick: false,
      showCloseButton: true,
      title: requestedDocument.file.name || 'Preview',
      activeIndex: 0,
    });
  }

  isFinancialsRequested(): boolean {
    if (!this.financialRequests) {
      return false;
    }

    return (0 <= this.financialRequests.findIndex(x => x.requestStatus === models.LeaseRequestStatus.Requested));
  }

  isRequestMoreInfoButtonVisible(): boolean {
    return this.project && this.project.projectStatus === models.ProjectStatus.Active;
  }

  isLeaseCancelled(): boolean {
    return this._lease.leaseStatus === LeaseStatus.Cancelled
      || this._lease.leaseStatus === LeaseStatus.Rejected
      || this.project.projectStatus === ProjectStatus.Rejected
      || this.project.projectStatus === ProjectStatus.Cancelled;
  }

  requestAdditionalFinancialDocuments(): void {
    const dialogRef = this._dialogService.show(RequestFinancialsDialogComponent, {
      showCloseButton: false,
      closeOnOutsideClick: false,
      width: 600,
      height: 'auto',
      maxHeight: 700,
      injectableData: {
        leaseId: this.leaseId,
        lease: this._lease
      },
    });

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          if (dialogRef.outputData && dialogRef.outputData.createdRequest) {
            this.financialRequests.push(dialogRef.outputData.createdRequest);
            this.financialRequestsChange.emit(this.financialRequests);
          }
        }),
      )
      .subscribe();
  }

  getRequestedFinancialDocuments(): Array<models.ILeaseRequestFileViewModel> {
    if (!this.financialRequests || !this.financialRequests.length) {
      return [];
    }

    return this.financialRequests.reduce(
      (acc, request) => {
        acc.push(...request.requestFiles);
        return acc;
      },
      [],
    );
  }

  getRequestStatus(project: models.IProjectViewModel): RequestStatus {
    return this._requestService.getRequestStatus(project);
  }

  getRequestStatusChangeDate(project: models.IProjectViewModel): Date {
    if (!project) {
      return null;
    }

    const requestStatus = this.getRequestStatus(project);
    if (!requestStatus) {
      return null;
    }

    switch (requestStatus) {
      case RequestStatus.Approved:
        return this._requestService.getRequestAcceptanceDate(project);

      case RequestStatus.Rejected:
        return this._requestService.getRequestRejectionDate(project);

      default:
        return null;
    }
  }

  shouldApproveOrRejectRequest(request: Request) {
    if (!this.project || this.project.projectStatus !== models.ProjectStatus.Active) {
      return false;
    }
    const hasAccessToFinancialDocuments = this._claimService
      .checkUserAccess(StateraClaimTypeAsEnum.Financial_Requests, StateraClaimValueAsEnum.Write, null, null, null, this.leaseId);
    return this._projectManager.isEstablishCriteriaByLandlord(this.project) ||
      (this._financialRequestManager
        .shouldApproveByLandlordTeam(this.project, this.financialRequests) &&
        hasAccessToFinancialDocuments
      );
  }

  showRejectButton() {
    return this._projectManager.isEstablishCriteriaByLandlord(this.project);
  }
}
