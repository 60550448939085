<div class="header">
  <div class="cancellation-title">
    Sorry to hear that you would like to cancel this negotiation 😔
    In order to finalize this request, please provide some info as to why you're cancelling,
    so we can relay to the Tenant.
  </div>
</div>
<form class="form w-form" (ngSubmit)="submitForm(form)" #form="ngForm">
  <div class="dx-fieldset">

    <div class="dx-field">
      <div class="dx-field-label">
        Reason for deleting
      </div>
      <div class="dx-field-value">
        <dx-radio-group
          [dataSource]="options"
          displayExpr="text"
          valueExpr="id"
          [(ngModel)]="reasonId"
          name="reason"
        ></dx-radio-group>
      </div>
    </div>

    <ng-container *ngIf="reasonId === reasons.TooEarly">
      <div class="dx-field dx-field-direction-column">
        <div>
          Let's revisit deal
        </div>
        <div class="dx-field-value">
          <dx-date-box type="date" name="dateToRevisit" required
                       [(ngModel)]="dateToRevisit"
                       [disabledDates]="this.isDateDisabled"
                       [isValid]="form.submitted ? dateToRevisitInput.valid : true"
                       #dateToRevisitInput="ngModel"
          >
          </dx-date-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && dateToRevisitInput.hasError('required')">
            This field is required
          </div>
        </div>
      </div>
    </ng-container>

    <div class="dx-field dx-field-direction-column">
      <div class="dx-field-label">
        Inform the tenant about your decision
      </div>
      <div class="dx-field-value">
        <dx-text-box [(ngModel)]="comment" name="comment">
        </dx-text-box>
      </div>
    </div>

  </div>
  <div class="dx-fieldset pt-2 main-actions">
    <div class="d-flex flex-row mx-n2">
      <div class="px-2 w-50">
        <dx-button type="normal" text="Cancel" width="100%"
                   (onClick)="close()">
        </dx-button>
      </div>
      <div class="px-2 w-50">
        <dx-button type="success" width="100%" text="Submit"
                   [useSubmitBehavior]="true">
        </dx-button>
      </div>
    </div>
  </div>
</form>
