<dx-popup height="95%"
          width="80%"
          [minWidth]="800"
          [showTitle]="true"
          [showCloseButton]="true"
          [dragEnabled]="false"
          [(visible)]="popupVisible"
          [title]="signedDocUrl ? 'Lease Amendment Execution' : 'Signing a Lease Document'"
          (onShown)="onPopupShown()"
          (onHidden)="onPopupHidden($event)">
  <div class="popup-content">
    <span class="status" [ngClass]="getProjectIndicatorClass()">
      <span *ngIf="statusText">{{statusText}}</span>
      <span class="indicator"></span>
    </span>

    <div id="eversignContainer" class="popup-body"
         *ngIf="docExists && !signedDocUrl">
    </div>

    <div *ngIf="signedDocUrl" class="popup-body">
      <iframe [src]="signedDocUrl">
      </iframe>
    </div>
  </div>
</dx-popup>
