import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';
import { ProjectManager } from './project.manager';
import { ProjectRepository } from './project.repository';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    ProjectRepository,
    ProjectManager,
  ],
})
export class ProjectModule {
  static forChild(configuration: Configuration): ModuleWithProviders<ProjectModule> {
    return {
      ngModule: ProjectModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        ProjectRepository,
        ProjectManager,
      ],
    };
  }
}
