<section class="sign-document">
  <ng-container *ngIf="isShowSigningBlock">
    <div class="status" [ngClass]="{ 'error': hasActionRequired()}">
      <span *ngIf="hasActionRequired()">Action Required</span>
      <span class="indicator"></span>
    </div>
  </ng-container>

  <div id="eversignContainerId"
       [class.d-none]="!isShowSigningBlock"
       #eversignContainerElementRef>
  </div>

  <ng-container *ngIf="!isShowSigningBlock">
    <div class="sign-document-oxana">
      <figure class="sign-document-oxana-image">
        <img src="assets/img/oxana2.png" class="img-fluid"/>
      </figure>
      <!--b-onboarding-image-->
    </div>

    <div class="modal-caption">
      <div *ngIf="!hasActionRequired()">
        <span class="status">
          <span class="success">Awaiting LOI signature</span>
        </span>
      </div>

      <button class="btn btn-primary btn-lg view-loi"
              *ngIf="!hasActionRequired() && isTenant()"
              (click)="previewLOI.emit()">View LOI
      </button>

      <div *ngIf="hasActionRequired() && !isShowExternalSigningBlock">
        <button
          *appCheckAccess="StateraClaimType.Signature_Sign; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id; else: noAccess"
          class="btn btn-primary btn-lg" type="submit" (click)="openSignatureFrame()">
          Press here to continue
        </button>

        <ng-template #noAccess>
          <span class="status">
            <span class="success">
              Waiting for your Team Member to sign the document.
            </span>
          </span>
        </ng-template>
      </div>

      <div *ngIf="isShowExternalSigningBlock">
        <span class="status">
          <span class="success">
            Waiting for your Team Member to sign the document.
          </span>
        </span>
      </div>
    </div>
  </ng-container>
</section>


