import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseTermType } from '@statera/sdk/common';
import { ElectedTermForNegotiation, LeaseManager } from '@statera/sdk/lease';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { ProjectAccessService } from '../../../shared/services/project-access.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'elect-terms-for-negotiation.component.html',
  styleUrls: ['elect-terms-for-negotiation.component.scss'],
})
export class ElectTermsForNegotiationComponent implements OnInit, OnDestroy {
  readonly lease: models.ILeaseViewModel;
  readonly project: models.IProjectViewModel;
  readonly refreshColabo$: Subject<void>;

  search: string;

  electedTerms: Array<ElectedTermForNegotiation & {isVisible: boolean}>;

  private readonly _leaseManager: LeaseManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseManager: LeaseManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    projectAccessService: ProjectAccessService,
    dialogRefService: DialogRefService
  ) {
    this._leaseManager = leaseManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._projectAccessService = projectAccessService;
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    if (!this.lease) {
      return;
    }

    this._dialogRefService
      .onContentReady
      .pipe(
        tap((event) => {
          if (!event || !event.component) {
            return;
          }

          const contentElement = event.component.content();
          if (contentElement) {
            const parent = contentElement.parentElement;
            if (parent) {
              parent.style.setProperty('border-radius', '6px', 'important');
            }
          }

          this._dialogRefService.repaint();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._leaseManager
      .getElectedTermsForNegotiation(this.lease.id)
      .pipe(
        tap(electedTerms => {
          if (!electedTerms) {
            return;
          }

          this.electedTerms = electedTerms
            .map(electedTerm => {
              return {
                ...electedTerm,
                isVisible: true,
              };
            });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  applySearchFilter(search: string): void {
    this.electedTerms = this
      .electedTerms
      .map(electedTerm => {
        if (!electedTerm) {
          return electedTerm;
        }

        electedTerm.isVisible = (
          !search ||
          !electedTerm.leaseTermConfiguration ||
          !electedTerm.leaseTermConfiguration.description ||
          0 <= electedTerm.leaseTermConfiguration.description.toLowerCase().search(search.toLowerCase())
        );

        return electedTerm;
      });
  }

  submit(form: NgForm): void {
    if (form.invalid || !this.lease) {
      return;
    }

    const tenantImprovementsElectedTerm = this.electedTerms
      .find((x) =>
        x.leaseTermConfiguration.leaseTermType === LeaseTermType.TenantImprovements
      );

    if (
      (
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
          this.project,
          this.lease
        ) ||
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) ||
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
          this.project,
          this.lease
        ) ||
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReceiveAndAnalyzeResponse,
          this.project,
          this.lease
        )
      ) &&
      (
        tenantImprovementsElectedTerm.isElected &&
        !tenantImprovementsElectedTerm.isElectedByDefault &&
        tenantImprovementsElectedTerm.canBeDeleted
      )
    ) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmAddTenantImprovementsTermWithFloorPlanBypassAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => {
            this.save();
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();

      return;
    }

    this.save();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  save(): void {
    this._leaseManager
      .electTermsForNegotiation(this.lease.id, [...this.electedTerms])
      .pipe(
        tap(() => {
          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getElectedTermsSavedAlertText(),
          });

          this.refreshColabo$.next();

          this.close();
        }),
        catchError(err => {
          this._alertService.pushErrorAlert({
            message: this._alertMessagesManager.getSaveElectedTermsErrorAlertText(),
          });

          return of(err);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
