import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Role } from '@statera/sdk/auth';

import { AuthService } from '../../../../auth/services/auth.service';
import { DialogRefService } from '../../../../dialog/services/dialog-ref.service';
import { PlanViewerMarkerDialogComponent } from '../../../../plan-viewer/components/plan-viewer-marker-dialog/plan-viewer-marker-dialog.component';
import { AlertService } from '../../../../alert/services/alert.service';
import { DialogService } from '../../../../dialog/services/dialog.service';
import { ProjectAccessService } from '../../../../shared/services/project-access.service';
import { ProfileService } from '../../../../user/services/profile.service';

import * as models from '../../../../infrastructure/models/generated';

import { BaseDemoComponent } from '../../../../shared/components/base-demo/base-demo.component';
import { FloorPlanComponent } from '../../../../plan/components/floor-plan/floor-plan.component';

declare let Driver: any;

@Component({
  selector: 'app-floorplan-demo',
  templateUrl: '../../../../shared/components/base-demo/base-demo.component.html',
  styleUrls: ['./floorplan-demo.component.scss']
})
export class FloorplanDemoComponent extends BaseDemoComponent implements OnInit, OnDestroy {
  @Input() lease: models.ILeaseViewModel;
  addAnchorDialogVisible = false;
  planComponent: FloorPlanComponent;

  // TODO: [FIXME] We must stop template declaration via string literals,
  //       use component instead and refer to ComponentLoaderService
  tpl = `<div class="demo">
  <div class="oxana">&nbsp;</div>
  <div class="content">
    <p>{message}</p>
  </div>
</div>`;

  tenantSteps = [
    {
      element: '.plan-viewer-editor',
      popover: {
        title: ' ',
        description: this.getMessage(
          'Toolbar provides ability to rotate and adjust the size of the floor plan, ' +
          'as well as choose a type of marker to plot on to the plan.'
        ),
        position: 'bottom'
      },
    },
    {
      element: '.plan-viewer-marker-dialog-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'When a marker is added, users have the ability to drop in a picture, ' +
          'designate specific area for marker and provide improvement specific comments.'
        ),
        position: 'left'
      },
    },
    {
      element: '.plan-demo-tenant-improvements',
      popover: {
        title: ' ',
        description: this.getMessage(
          'This section will list the markers that were plotted on the plan, as well as any comments, ' +
          'and allows for both users to negotiate on improvements and pricing until an agreement is reached.'
        ),
        position: 'left'
      },
    },
  ];


  landlordSteps = [
    {
      element: '.plan-viewer',
      popover: {
        title: ' ',
        description: this.getMessage(
          'As a user you can hover each plotted marker, which represents a requested improvement by the tenant.'
        ),
        position: 'right'
      },
    },
    {
      element: '.plan-demo-tenant-improvements',
      popover: {
        title: ' ',
        description: this.getMessage(
          'This section will list the markers that were plotted on the plan, as well as any comments, ' +
          'and allows for both users to negotiate on improvements and pricing until an agreement is reached.'
        ),
        position: 'left'
      },
    },
    {
      element: '.total-cost-demo',
      popover: {
        title: ' ',
        description: this.getMessage(
          'In the case of a contractor visit, user will be required to insert dollar values for each respective improvement, ' +
          'which will be used as a point of reference to finalize an agreement on the term.'
        ),
        position: 'left'
      },
    }

  ];

  private _role: string;
  private readonly _dialogService: DialogService;
  private readonly _ngZone: NgZone;
  private _dialogRefId: number = null;
  private _dialogRef: DialogRefService;
  private _projectAccessService: ProjectAccessService;

  constructor(
    profileService: ProfileService,
    authService: AuthService,
    alertService: AlertService,
    dialogService: DialogService,
    ngZone: NgZone,
    projectAccessService: ProjectAccessService
  ) {
    super(profileService, authService, alertService);
    this._dialogService = dialogService;
    this._ngZone = ngZone;
    this._projectAccessService = projectAccessService;
  }

  ngOnInit() {
    this.introType = models.IntroType.FloorPlan;
  }

  reset(): void {
    this._role = this.authService.role;
    this.steps = this.getSteps();
    super.reset();
  }

  show(component: FloorPlanComponent): void {
    this.planComponent = component;
    if (!this.authService && !this.authService.startupInfo && !this.authService.startupInfo.introsShown) {
      return;
    }

    if (
      this.isMobile ||
      (this.authService.startupInfo.introsShown && this.authService.startupInfo.introsShown.find(x => x.introType === this.introType))
    ) {
      return;
    }
    const startupInfo = this.authService.startupInfo;
    this._role = startupInfo.role;
    this.steps = this.getSteps();

    this.component = component;

    const elementWhoNeedsToBeChecked = this._role === Role.Tenant ? '.plan-viewer-editor' : '.plan-viewer';

    this.checkElements([elementWhoNeedsToBeChecked])
      .then(() => {
        this.visible = true;
        if (this.driver) {
          this.driver.reset();
          this.driver = null;
        }
        this.driver = new Driver({
          showButtons: false,
          allowClose: false,
          animate: false,
          // hack for devexpress popups
          onHighlighted: () => {
            this.removeDriverFixStackingClass();
          }
        });
        this.driver.defineSteps(this.getSteps());
        this.driver.start();
      });
  }

  goto(stepNumber: number) {
    this.current = stepNumber;

    if (this._role === Role.Tenant) {
      this._showTenantStep();
    } else {
      this._showLandlordStep();
    }
  }

  getSteps() {
    const role = this._projectAccessService.getTurnRole(this.lease);

    if (role === Role.Landlord) {
      return this.landlordSteps;
    }
    if (role === Role.Tenant) {
      return this.tenantSteps;
    }

    return [];
  }

  skip() {
    if (this._dialogRef) {
      this._dialogRef.hide();
    }

    this.driver.reset();
    delete this.driver;
    this.profileService.skipIntro(this.introType).subscribe(() => {
      this.visible = false;
      this.authService.startupInfo.introsShown.push(<models.IUserIntroViewModel>{
        userId: this.authService.startupInfo.id,
        introType: this.introType
      });
    });

    if (this.planComponent && this.planComponent.isDemo) {
      this.planComponent.isDemo = false;
    }
  }

  ngOnDestroy() {
    if (this.driver) {
      this.driver.reset();
    }

    if (this.component && this.component.addAnchorDialogVisible) {
      this.component.addAnchorDialogVisible = false;
    }

    if (this._dialogRefId) {
      this._dialogService.hide(this._dialogRefId);
    }
  }

  private _showTenantStep() {
    if (this.current === 1) {
      this.cssClassesForSkipButton = ['skip-button-add-anchor'];
      this.cssClassesForSteps = ['steps-add-anchor'];

      const dialogTitle = 'Create Marker';
      const dialogRefId = 1001;

      this._dialogRef = this._dialogService.show(PlanViewerMarkerDialogComponent, {
        id: dialogRefId,
        title: dialogTitle,
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 400,
        height: 'auto',
        maxHeight: 600,
        injectableData: {
          marker: null
        },
      });
      this._dialogRef.onShown.subscribe(x => {
        this.driver.highlight(this.steps[this.current]);
      });
    } else {
      this.cssClassesForSkipButton = [];
      this.cssClassesForSteps = [];
      if (this._dialogRef) {
        this._dialogRef.hide();
        this._dialogRef = null;
      }
      this.driver.highlight(this.steps[this.current]);
    }
  }

  private _showLandlordStep() {
    if (!this.planComponent) {
      return;
    }

    if (this.current === 1) {
      this._ngZone.runOutsideAngular(() => {
        const timeoutFn = () => this.driver.highlight(this.steps[this.current]);

        setTimeout(timeoutFn, 0);
      });

      return;
    }

    if (this.current === 2) {
      this._ngZone.runOutsideAngular(() => {
        const timeoutFn = () => this.driver.highlight(this.steps[this.current]);

        setTimeout(timeoutFn, 0);
      });

      return;
    }

    if (this.current === 3) {
      this.planComponent.isDemo = true;

      this._ngZone.runOutsideAngular(() => {
        const timeoutFn = () => this.driver.highlight(this.steps[this.current]);

        setTimeout(timeoutFn, 0);
      });

      return;
    }

    this.driver.highlight(this.steps[this.current]);
  }
}
