import {
  Directive, EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { defaultIfEmpty, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import * as models from '../../infrastructure/models/generated';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

class StateraCheckAccessParam {
  companyId?: number;
  portfolioId?: number;
  buildingId?: number;
  leaseId?: number;
}

@Directive({
  selector: '[appCheckAccess]'
})
export class CheckAccessDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  appCheckAccess: models.StateraClaimTypeAsEnum;
  @Input()
  appCheckAccessStateraClaimValue: models.StateraClaimValueAsEnum;
  @Input()
  appCheckAccessCompanyId?: number;
  @Input()
  appCheckAccessPortfolioId?: number;
  @Input()
  appCheckAccessBuildingId?: number;
  @Input()
  appCheckAccessLeaseId?: number;
  @Input()
  appCheckAccessElse?: TemplateRef<any>;


  private readonly _destroy$: Subject<void>;
  private readonly _viewContainer: ViewContainerRef;
  private readonly _templateRef: TemplateRef<any>;
  private readonly _authService: AuthService;
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private _currentUserId: number;
  private _checkAccessSubject: BehaviorSubject<StateraCheckAccessParam>;
  private _thenViewRef?: EmbeddedViewRef<any> = null;
  private _elseViewRef?: EmbeddedViewRef<any> = null;

  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    authService: AuthService,
    stateraUserClaimManager: StateraUserClaimManager
  ) {
    this._destroy$ = new Subject<void>();
    this._templateRef = templateRef;
    this._viewContainer = viewContainer;
    this._authService = authService;
    this._stateraUserClaimManager = stateraUserClaimManager;
    this._checkAccessSubject = new BehaviorSubject<StateraCheckAccessParam>(null);
  }

  ngOnInit(): void {
    if (this._authService.startupInfo) {
      this._currentUserId = this._authService.startupInfo.id;
    }
    const checkAccessSubject$ = this._checkAccessSubject
      .pipe(
        takeUntil(this._destroy$)
      );

    const stateraUserClaims$ = this._stateraUserClaimManager
      .stateraUserClaims$
      .pipe(
        takeUntil(this._destroy$),
        defaultIfEmpty(null)
      );

    combineLatest([stateraUserClaims$, checkAccessSubject$])
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(param => {
        this._checkAccess(param[0], param[1]);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _checkAccess(stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel, param: StateraCheckAccessParam): void {
    const hasAccess = this._currentUserId &&
      stateraUserClaims &&
      param &&
      this._stateraUserClaimManager.checkAccess(stateraUserClaims,
        this.appCheckAccess,
        this.appCheckAccessStateraClaimValue,
        param.companyId,
        param.portfolioId,
        param.buildingId,
        param.leaseId
      );

    if (hasAccess && !this._thenViewRef) {
      this._viewContainer.clear();
      this._elseViewRef = null;
      if (this._templateRef) {
        this._thenViewRef = this._viewContainer.createEmbeddedView(this._templateRef);
      }
    }

    if (!hasAccess && !this._elseViewRef) {
      this._viewContainer.clear();
      this._thenViewRef = null;
      if (this.appCheckAccessElse) {
        this._elseViewRef = this._viewContainer.createEmbeddedView(this.appCheckAccessElse);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    let companyId = null;
    if (
      changes.appCheckAccessCompanyId &&
      (
        changes.appCheckAccessCompanyId.isFirstChange() ||
        (
          changes.appCheckAccessCompanyId.previousValue !== changes.appCheckAccessCompanyId.currentValue
        )
      )
    ) {
      companyId = changes.appCheckAccessCompanyId.currentValue;
    }

    let portfolioId = null;
    if (
      changes.appCheckAccessPortfolioId &&
      (
        changes.appCheckAccessPortfolioId.isFirstChange() ||
        (
          changes.appCheckAccessPortfolioId.previousValue !== changes.appCheckAccessPortfolioId.currentValue
        )
      )
    ) {
      portfolioId = changes.appCheckAccessPortfolioId.currentValue;
    }

    let buildingId = null;
    if (
      changes.appCheckAccessBuildingId &&
      (
        changes.appCheckAccessBuildingId.isFirstChange() ||
        (
          changes.appCheckAccessBuildingId.previousValue !== changes.appCheckAccessBuildingId.currentValue
        )
      )
    ) {
      buildingId = changes.appCheckAccessBuildingId.currentValue;
    }

    let leaseId = null;
    if (
      changes.appCheckAccessLeaseId &&
      (
        changes.appCheckAccessLeaseId.isFirstChange() ||
        (
          changes.appCheckAccessLeaseId.previousValue !== changes.appCheckAccessLeaseId.currentValue
        )
      )
    ) {
      leaseId = changes.appCheckAccessLeaseId.currentValue;
    }

    if (companyId || portfolioId || buildingId || leaseId) {
      this._checkAccessSubject.next({
        companyId,
        portfolioId,
        buildingId,
        leaseId
      });
    }
  }
}
