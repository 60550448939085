import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../alert/services/alert.service';

import { LeaseService } from '../../services/lease.service';

import { SafeNumberPipe } from '../../../infrastructure/pipes/safe-number.pipe';

import * as models from '../../../infrastructure/models/generated';
import { BuildingHelper } from '../../helpers/building.helper';

enum LeaseAnalyticsType {
  None = 0,
  AbstractLease = 1,
  ThirdPartyLease = 2
}
@Component({
  selector: 'app-comparison-report',
  templateUrl: './comparison-report.component.html',
  styleUrls: ['./comparison-report.component.scss'],
})
export class ComparisonReportComponent implements OnInit, OnDestroy {
  private static readonly _alphabet = 'abcdefghijklmnopqrstuvwxyz';

  RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;

  leases: Array<models.ILeaseViewModel> = [];
  selectedLease: models.ILeaseViewModel = null;
  leaseId: number;

  items: Array<models.IComparisonSummaryReportItemsViewModel> = [];
  numberFormat = '1.0-0';
  psfNumberFormat = '1.2-2';

  buildingHelper = BuildingHelper;
  LeaseAnalyticsType = LeaseAnalyticsType;

  private readonly _leaseService: LeaseService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _route: ActivatedRoute;
  private readonly _termManager: TermManager;
  private readonly _safeNumber: SafeNumberPipe;
  private readonly _destroy: Subject<void>;
  private _abstractLeaseAnalysisResult: models.IComparisonSummaryReportAbstractLease;
  private _thirdPartyLeaseAnalysisReport: models.IComparisonSummaryReportAbstractLease;

  constructor(
    leaseService: LeaseService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    route: ActivatedRoute,
    termManager: TermManager,
    safeNumber: SafeNumberPipe
  ) {
    this._leaseService = leaseService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._route = route;
    this._termManager = termManager;
    this._safeNumber = safeNumber;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this._route
      .params
      .pipe(
        map(params => {
          let leaseId = null;

          try {
            leaseId = parseInt(params['id'], 10);
          } catch (e) {}

          return leaseId;
        }),
        switchMap(leaseId => {
          if (!leaseId) {
            return of(null);
          }

          return this._leaseService.getLease(leaseId);
        }),
        switchMap(lease => {
          if (!lease) {
            return of(null);
          }

          this.selectedLease = lease;

          return forkJoin([
            this._leaseService
              .getLeaseComparisonSummaryReport(<models.ILeaseAnalysisOptionsViewModel>{
                lease: {...this.selectedLease},
                leaseYearType: models.LeaseAnalysisYearType.LeaseYear,
              })
              .pipe(
                tap(comparisonReport => {
                  this.items = comparisonReport.items;
                  this._abstractLeaseAnalysisResult = comparisonReport.currentLeaseResults;
                  this._thirdPartyLeaseAnalysisReport = comparisonReport.thirdPartyLeaseResults;
                }),
              ),
          ]);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  exportToPdfClick() {
    this._alertService.pushInfoAlert({
      message: this._alertMessagesManager.getFunctionNotImplementedAlertText(),
    });
  }

  getProjectTemplateName(projectTemplateItem: models.IProjectTemplateItemViewModel) {
    if (projectTemplateItem) {
      if (projectTemplateItem.parentProjectTemplateItem) {
        return projectTemplateItem.parentProjectTemplateItem.name;
      }

      return projectTemplateItem.name;
    }

    return '';
  }

  getProjectTemplateNumber(projectTemplateItem: models.IProjectTemplateItemViewModel) {
    if (projectTemplateItem) {
      if (projectTemplateItem.parentProjectTemplateItem) {
        return projectTemplateItem.parentProjectTemplateItem.stageNumber;
      }

      return projectTemplateItem.stageNumber;
    }

    return '';
  }

  getServiceType(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getServiceType(leaseTerms);
  }

  getStartingRate(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getStartingRate(leaseTerms);
  }

  getEndingRate(object: {lease: models.ILeaseViewModel, endingBaseRent: number}): string {
    if (!object || !object.lease) {
      return;
    }

    const serviceType = this._leaseService.getServiceType(object.lease);
    const unitMetrics = this._termManager.getBaseRentalRateUnitMetricsDisplayValue(object.lease.baseRentalRateTerm);

    return [`$${this._safeNumber.transform(object.endingBaseRent, this.psfNumberFormat) || 0}`, unitMetrics, serviceType]
      .filter(Boolean)
      .join(' ');
  }

  getFreeRent(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getFreeRent(leaseTerms);
  }

  getImprovements(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getImprovements(leaseTerms);
  }

  getOperationExp(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getOperationExp(leaseTerms);
  }

  getRentIncreases(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getRentIncreases(leaseTerms);
  }

  getTenantSquareFootage(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getTenantSquareFootage(leaseTerms.tenantSquareFootageTerm);
  }

  getReTaxesAndOpExpensesMessage(abstractLeaseAnalysisResult: models.IComparisonSummaryReportAbstractLease): string {
    if (abstractLeaseAnalysisResult.isReTaxesSet && abstractLeaseAnalysisResult.isOpExpensesSet) {
      return null;
    }

    return [!abstractLeaseAnalysisResult.isReTaxesSet && 'RE Taxes', !abstractLeaseAnalysisResult.isOpExpensesSet && 'Op Expenses']
      .filter(Boolean)
      .join(' and ');
  }

  getBaseYearStopInfo(leaseTerms: models.ILeaseViewModel): string {
    return this._leaseService.getBaseYearStopInfo(leaseTerms);
  }

  getFormattedValue(value: number, numberFormat: string = this.numberFormat) {
    if (value) {
      const roundingValue = Math.round(value);
      return this._safeNumber.transform(roundingValue, numberFormat);
    }

    return 0;
  }

  containsMultipleOptions(): boolean {
    return 0 < this.items.filter(x => 1 < x.items.length).length;
  }

  getNumberOfMultipleOptions(): number {
    if (!this.containsMultipleOptions()) {
      return 0;
    }

    const item = this.items.find(x => 1 < x.items.length);
    if (!item) {
      return 0;
    }

    return item.items.length;
  }

  indexToOptionValue(index: number): string {
    return ComparisonReportComponent._alphabet.substr(index, 1).toUpperCase();
  }

  getUnitMetricsDisplayValue(unitMetrics: models.BaseRentalRateUnitMetrics): string {
    let unitMetricsDisplayValue = '$ / PSF / Yr';
    if (!unitMetrics) {
      return unitMetricsDisplayValue;
    }

    if (unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetricsDisplayValue = '$ / PSF / Mo';
    }

    return unitMetricsDisplayValue;
  }

  getLeaseWhichWeCompareWith(): models.IComparisonSummaryReportAbstractLease {
    if (this._thirdPartyLeaseAnalysisReport) {
      return this._thirdPartyLeaseAnalysisReport;
    }
    return this._abstractLeaseAnalysisResult;
  }

  getLeaseAnalyticsTypeCompareWith (): LeaseAnalyticsType {
    if (this._thirdPartyLeaseAnalysisReport) {
      return LeaseAnalyticsType.ThirdPartyLease;
    }
    return LeaseAnalyticsType.AbstractLease;
  }
}
