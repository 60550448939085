import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InlineSVGModule } from 'ng-inline-svg';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { VideoPlayerModule } from '../video-player/video-player.module';

import { BuildingAttachmentsComponent } from './components/building-attachments/building-attachments.component';

@NgModule({
  imports: [
    CommonModule,

    BsDropdownModule,
    CarouselModule,
    NgChartsModule,
    CollapseModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    InlineSVGModule,

    InfrastructureModule,
    VideoPlayerModule,
  ],
  declarations: [
    BuildingAttachmentsComponent,
  ],
  providers: [
    ThemeService
  ],
  exports: [
    BuildingAttachmentsComponent,
  ],
})
export class BuildingModule { }
