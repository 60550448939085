<div class="b-lease-term-value"
     *ngIf="leaseTerm" #termValueElementRef>
  <div class="term-value"
       [class.single]="numberOfLines == 1"
       [class.active]="isExpanded">
    <span class="term-amendment-number"
          *ngIf="showAmendmentNumber">
      <span *ngIf="getAmendmentBadgeText() as amendmentBadgeText" class="term-amendment-number-badge">
        {{amendmentBadgeText}}
      </span>

      <span class="term-amendment-number-badge term-amendment-number-badge-current"
            *ngIf="shouldShowCurrentBadge()">
        Current
      </span>

      <span class="term-amendment-number-badge term-amendment-number-badge-renewed"
            *ngIf="shouldShowRenewedBadge()">
        <ng-container *ngIf="project?.projectTypeId === ProjectType.NewDeal; else renewed">
          New Deal
        </ng-container>
        <ng-template #renewed>
          Renewed
        </ng-template>
      </span>

      <span class="term-amendment-number-badge term-amendment-number-badge-section"
            *ngIf="documentSection">
        {{documentSection}}
      </span>
    </span>

    <span class="term-amendment-number"
          *ngIf="showThirdPartyLease">
      <span class="term-amendment-number-badge">
        3rd Party Lease
      </span>
    </span>

    <span class="term-text"
          #termTextElementRef>
      <span>
        {{leaseTerm}}

        <ng-content>
        </ng-content>
      </span>
    </span>
  </div>

  <a role="button" class="term-expand"
     *ngIf="isOverflow"
     [class.active]="isExpanded"
     (click)="isExpanded = !isExpanded">
    {{isExpanded ? 'Less' : 'Read more'}}
  </a>
</div>
