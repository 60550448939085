<ng-container *ngIf="insuranceCertificate">
  <div class="wrapper">
    <div class="title">
      <div class="container">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h1 class="text-uppercase">
              <ng-container *ngIf="isNewDealProject; else nonComplianceRequestTemplate">
                Certificate of Insurance: Request
              </ng-container>
              <ng-template #nonComplianceRequestTemplate>
                Certificate of Insurance: Non-Compliance Request
              </ng-template>
            </h1>

            <ng-container *ngIf="shouldShowPleaseProvideAnyDetailsText()">
              <p class="error-message">
                Please provide any details, as needed
              </p>
            </ng-container>

            <ng-container *ngIf="shouldShowSubmittedForReviewText()">
              <p class="error-message">
                Submitted for Review
              </p>
            </ng-container>

            <ng-container *ngIf="shouldShowPleaseUploadCOIText()">
              <p class="error-message">
                Please upload COI per notes in the form
              </p>
            </ng-container>
          </div>
          <div>
            <button type="button" class="close"
                    (click)="close()">
              <i class="dx-icon dx-icon-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <dx-scroll-view width="100%" height="100%">
        <div class="container">
          <form ngForm name="form" class="form" novalidate #form="ngForm" (ngSubmit)="submit(form)">
            <table class="table table-insurance">
              <tr>
                <th></th>
                <th>Type of Insurance</th>
                <th>
                  <ng-container *ngIf="insuranceCertificate.hasGeneralLiability || insuranceCertificate.hasAutoLiability || insuranceCertificate.hasUmbrellaLiability || insuranceCertificate.hasWorkersCompensation">
                    Limits
                  </ng-container>
                </th>
              </tr>
              <!-- Start: General Liability -->
              <tr>
                <td>
                  <dx-check-box id="generalLiability" class="mb-2"
                                [(value)]="insuranceCertificate.hasGeneralLiability"
                                [disabled]="isDisabledCertificateOfInsuranceValues()">
                  </dx-check-box>
                </td>
                <td colspan="2">
                  <label for="generalLiability" class="mb-2">
                    General Liability
                  </label>
                </td>
              </tr>
              <ng-container *ngIf="insuranceCertificate.hasGeneralLiability">
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="generalLiabilityCoverageLevelEachOccurrence">
                      Each Occurrence
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="generalLiabilityCoverageLevelEachOccurrence" name="generalLiabilityCoverageLevelEachOccurrence"
                             type="text" maxlength="14" class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && generalLiabilityCoverageLevelEachOccurrence.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.generalLiabilityCoverageLevelEachOccurrence)"
                             [(ngModel)]="insuranceCertificate.generalLiabilityCoverageLevelEachOccurrence" #generalLiabilityCoverageLevelEachOccurrence="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="damageToRentedPremises">
                      Damage to Rented Premises
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="damageToRentedPremises" name="damageToRentedPremises" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && damageToRentedPremises.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.damageToRentedPremises)"
                             [(ngModel)]="insuranceCertificate.damageToRentedPremises" #damageToRentedPremises="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="medExp">
                      Med Exp
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="medExp" name="medExp" type="text" maxlength="14" class="w-100 form-control rounded"
                             mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && medExp.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.medExp)"
                             [(ngModel)]="insuranceCertificate.medExp" #medExp="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="personalAndAdvInjury">
                      Personal & Adv Injury
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="personalAndAdvInjury" name="personalAndAdvInjury" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && personalAndAdvInjury.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.personalAndAdvInjury)"
                             [(ngModel)]="insuranceCertificate.personalAndAdvInjury" #personalAndAdvInjury="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()"
                      />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="generalAggregate">
                      General Aggregate
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="generalAggregate" name="generalAggregate" type="text" maxlength="14" class="w-100 form-control rounded"
                             mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && generalAggregate.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.generalAggregate)"
                             [(ngModel)]="insuranceCertificate.generalAggregate" #generalAggregate="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasGeneralLiability">
                    <label for="productsCompOpAgg">
                      Products - Comp/Op Agg
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="productsCompOpAgg" name="productsCompOpAgg" type="text" maxlength="14" class="w-100 form-control rounded"
                             mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && productsCompOpAgg.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.productsCompOpAgg)"
                             [(ngModel)]="insuranceCertificate.productsCompOpAgg" #productsCompOpAgg="ngModel"
                             [required]="isGeneralLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="3" class="pl-xl">
                    <div class="form-group my-2">
                      <textarea name="generalLiabilityCoverageLevelComments" class="form-control rounded"
                                placeholder="INSERT ADDITIONAL COMMENTS HERE"
                                [(ngModel)]="insuranceCertificate.generalLiabilityCoverageLevelComments" #generalLiabilityCoverageLevelComments="ngModel"
                                [disabled]="!insuranceCertificate.hasGeneralLiability || isDisabledCertificateOfInsuranceValues()">
                      </textarea>
                    </div>
                  </td>
                </tr>
                <tr class="subrow"
                    *ngIf="form.submitted && isGeneralLiabilityTotalsRequired">
                  <td colspan="3" class="pl-xl">
                    <p class="my-2 at-least-one-required-error">
                      Please input at least one total if selecting a category.
                    </p>
                  </td>
                </tr>
              </ng-container>
              <!-- End: General Liability -->
              <!-- Start: Auto Liability -->
              <tr>
                <td>
                  <dx-check-box id="autoLiability" class="mb-2"
                                [(value)]="insuranceCertificate.hasAutoLiability"
                                [disabled]="isDisabledCertificateOfInsuranceValues()">
                  </dx-check-box>
                </td>
                <td colspan="2">
                  <label for="autoLiability" class="mb-2">
                    Auto Liability
                  </label>
                </td>
              </tr>
              <ng-container *ngIf="insuranceCertificate.hasAutoLiability">
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasAutoLiability">
                    <label for="combinedSingleLimit">
                      Combined Single Limit
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="combinedSingleLimit" name="combinedSingleLimit" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && combinedSingleLimit.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.combinedSingleLimit)"
                             [(ngModel)]="insuranceCertificate.combinedSingleLimit" #combinedSingleLimit="ngModel"
                             [required]="isAutoLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasAutoLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasAutoLiability">
                    <label for="bodilyInjuryPerPerson">
                      Bodily Injury (per person)
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="bodilyInjuryPerPerson" name="bodilyInjuryPerPerson" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && bodilyInjuryPerPerson.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.bodilyInjuryPerPerson)"
                             [(ngModel)]="insuranceCertificate.bodilyInjuryPerPerson" #bodilyInjuryPerPerson="ngModel"
                             [required]="isAutoLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasAutoLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasAutoLiability">
                    <label for="bodilyInjuryPerAccident">
                      Bodily Injury (per accident)
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="bodilyInjuryPerAccident" name="bodilyInjuryPerAccident" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && bodilyInjuryPerAccident.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.bodilyInjuryPerAccident)"
                             [(ngModel)]="insuranceCertificate.bodilyInjuryPerAccident" #bodilyInjuryPerAccident="ngModel"
                             [required]="isAutoLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasAutoLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasAutoLiability">
                    <label for="propertyDamage">
                      Property Damage
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="propertyDamage" name="propertyDamage" type="text" maxlength="14" class="w-100 form-control rounded"
                             mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && propertyDamage.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.propertyDamage)"
                             [(ngModel)]="insuranceCertificate.propertyDamage" #propertyDamage="ngModel"
                             [required]="isAutoLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasAutoLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="3" class="pl-xl">
                    <div class="form-group my-2">
                      <textarea name="autoLiabilityCoverageLevelComments" class="form-control rounded"
                                placeholder="INSERT ADDITIONAL COMMENTS HERE"
                                [(ngModel)]="insuranceCertificate.autoLiabilityCoverageLevelComments" #autoLiabilityCoverageLevelComments="ngModel"
                                [disabled]="!insuranceCertificate.hasAutoLiability || isDisabledCertificateOfInsuranceValues()">
                      </textarea>
                    </div>
                  </td>
                </tr>
                <tr class="subrow"
                    *ngIf="form.submitted && isAutoLiabilityTotalsRequired">
                  <td colspan="3" class="pl-xl">
                    <p class="my-2 at-least-one-required-error">
                      Please input at least one total if selecting a category.
                    </p>
                  </td>
                </tr>
              </ng-container>
              <!-- End: Auto Liability -->
              <!-- Start: Umbrella Liability -->
              <tr>
                <td>
                  <dx-check-box id="umbrellaLiability" class="mb-2"
                                [(value)]="insuranceCertificate.hasUmbrellaLiability"
                                [disabled]="isDisabledCertificateOfInsuranceValues()">
                  </dx-check-box>
                </td>
                <td colspan="2">
                  <label for="umbrellaLiability" class="mb-2">
                    Umbrella Liability
                  </label>
                </td>
              </tr>
              <ng-container *ngIf="insuranceCertificate.hasUmbrellaLiability">
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasUmbrellaLiability">
                    <label for="umbrellaLiabilityCoverageLevelEachOccurrence">
                      Each Occurrence
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="umbrellaLiabilityCoverageLevelEachOccurrence" name="umbrellaLiabilityCoverageLevelEachOccurrence"
                             type="text" maxlength="14" class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && umbrellaLiabilityCoverageLevelEachOccurrence.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.umbrellaLiabilityCoverageLevelEachOccurrence)"
                             [(ngModel)]="insuranceCertificate.umbrellaLiabilityCoverageLevelEachOccurrence" #umbrellaLiabilityCoverageLevelEachOccurrence="ngModel"
                             [required]="isUmbrellaLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasUmbrellaLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasUmbrellaLiability">
                    <label for="umbrellaLiabilityCoverageLevelAggregate">
                      Aggregate
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="umbrellaLiabilityCoverageLevelAggregate" name="umbrellaLiabilityCoverageLevelAggregate"
                             type="text" maxlength="14" class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && umbrellaLiabilityCoverageLevelAggregate.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.umbrellaLiabilityCoverageLevelAggregate)"
                             [(ngModel)]="insuranceCertificate.umbrellaLiabilityCoverageLevelAggregate" #umbrellaLiabilityCoverageLevelAggregate="ngModel"
                             [required]="isUmbrellaLiabilityTotalsRequired"
                             [disabled]="!insuranceCertificate.hasUmbrellaLiability || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="3" class="pl-xl">
                    <div class="form-group my-2">
                      <textarea name="umbrellaLiabilityCoverageLevelComments" class="form-control rounded"
                                placeholder="INSERT ADDITIONAL COMMENTS HERE"
                                [(ngModel)]="insuranceCertificate.umbrellaLiabilityCoverageLevelComments" #umbrellaLiabilityCoverageLevelComments="ngModel"
                                [disabled]="!insuranceCertificate.hasUmbrellaLiability || isDisabledCertificateOfInsuranceValues()">
                      </textarea>
                    </div>
                  </td>
                </tr>
                <tr class="subrow"
                    *ngIf="form.submitted && isUmbrellaLiabilityTotalsRequired">
                  <td colspan="3" class="pl-xl">
                    <p class="my-2 at-least-one-required-error">
                      Please input at least one total if selecting a category.
                    </p>
                  </td>
                </tr>
              </ng-container>
              <!-- End: Umbrella Liability -->
              <!-- Start: Workers' Compensation -->
              <tr>
                <td>
                  <dx-check-box id="workersCompensation" class="mb-2"
                                [(value)]="insuranceCertificate.hasWorkersCompensation"
                                [disabled]="isDisabledCertificateOfInsuranceValues()">
                  </dx-check-box>
                </td>
                <td colspan="2">
                  <label for="workersCompensation" class="mb-2">
                    Workers' Compensation
                  </label>
                </td>
              </tr>
              <ng-container *ngIf="insuranceCertificate.hasWorkersCompensation">
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasWorkersCompensation">
                    <label for="diseaseValueForEachAccident">
                      E.L. Disease (each accident)
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="diseaseValueForEachAccident" name="diseaseValueForEachAccident" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && diseaseValueForEachAccident.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.diseaseValueForEachAccident)"
                             [(ngModel)]="insuranceCertificate.diseaseValueForEachAccident" #diseaseValueForEachAccident="ngModel"
                             [required]="isWorkersCompensationTotalsRequired"
                             [disabled]="!insuranceCertificate.hasWorkersCompensation || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasWorkersCompensation">
                    <label for="diseaseValueForEachEmployee">
                      E.L. Disease (each employee)
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="diseaseValueForEachEmployee" name="diseaseValueForEachEmployee" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && diseaseValueForEachEmployee.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.diseaseValueForEachEmployee)"
                             [(ngModel)]="insuranceCertificate.diseaseValueForEachEmployee" #diseaseValueForEachEmployee="ngModel"
                             [required]="isWorkersCompensationTotalsRequired"
                             [disabled]="!insuranceCertificate.hasWorkersCompensation || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="2" class="pl-xl"
                      [class.disabled]="!insuranceCertificate.hasWorkersCompensation">
                    <label for="diseasePolicyLimit">
                      E.L. Disease (epolicy limit)
                    </label>
                  </td>
                  <td>
                    <div class="cell-coverage">
                      <span>$</span>
                      <input id="diseasePolicyLimit" name="diseasePolicyLimit" type="text" maxlength="14"
                             class="w-100 form-control rounded" mask="separator" thousandSeparator=","
                             [class.is-invalid]="form.submitted && diseasePolicyLimit.invalid"
                             [class.highlight]="showHighlightedInput(insuranceCertificate.diseasePolicyLimit)"
                             [(ngModel)]="insuranceCertificate.diseasePolicyLimit" #diseasePolicyLimit="ngModel"
                             [required]="isWorkersCompensationTotalsRequired"
                             [disabled]="!insuranceCertificate.hasWorkersCompensation || isDisabledCertificateOfInsuranceValues()" />
                    </div>
                  </td>
                </tr>
                <tr class="subrow">
                  <td colspan="3" class="pl-xl">
                    <div class="form-group my-2">
                      <textarea name="workersCompensationComments" class="form-control rounded"
                                placeholder="INSERT ADDITIONAL COMMENTS HERE"
                                [(ngModel)]="insuranceCertificate.workersCompensationComments" #workersCompensationComments="ngModel"
                                [disabled]="!insuranceCertificate.hasWorkersCompensation || isDisabledCertificateOfInsuranceValues()">
                      </textarea>
                    </div>
                  </td>
                </tr>
                <tr class="subrow"
                    *ngIf="form.submitted && isWorkersCompensationTotalsRequired">
                  <td colspan="3" class="pl-xl">
                    <p class="my-2 at-least-one-required-error">
                      Please input at least one total if selecting a category.
                    </p>
                  </td>
                </tr>
              </ng-container>
              <!-- End: Workers' Compensation -->
            </table>

            <div class="form-group">
              <label for="descriptionOfOperations">
                Description of Operations / Locations / Vehicles
              </label>
              <textarea id="descriptionOfOperations" name="descriptionOfOperations" class="form-control rounded"
                        placeholder="INSERT DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES"
                        [class.is-invalid]="form.submitted && descriptionOfOperations.invalid"
                        [(ngModel)]="insuranceCertificate.descriptionOfOperations" #descriptionOfOperations="ngModel"
                        [disabled]="isDisabledCertificateOfInsuranceValues()">
              </textarea>
            </div>

            <div class="form-group">
              <label [class.is-invalid]="form.submitted && certificateHolder.invalid">
                Certificate Holder
                <span class="text-danger">*</span>
              </label>
              <input name="certificateHolder" type="text" class="form-control rounded" required
                     [class.is-invalid]="form.submitted && certificateHolder.invalid"
                     [disabled]="isDisabledCertificateOfInsuranceValues()"
                     [(ngModel)]="insuranceCertificate.certificateHolder"
                     #certificateHolder="ngModel" />
              <p class="error-message"
                 *ngIf="form.submitted && certificateHolder.hasError('required')">
                Please fill out Certificate Holder
              </p>
            </div>

            <!--Editable by tenant-->

            <div class="form-group">
              <label>Additional Remarks or Instructions</label>
              <textarea name="additionalRemark" class="form-control rounded"
                        [class.is-invalid]="form.submitted && additionalRemark.invalid"
                        [disabled]="isDisabledCertificateOfInsuranceValues()"
                        [(ngModel)]="insuranceCertificate.additionalRemark"
                        #additionalRemark="ngModel">
              </textarea>
            </div>

            <!--Ediable by tenant-->
            <div class="form-group">
              <ng-container *ngIf="!isDisableCertificateOfInsuranceFile()">
                <div class="file-control">
                  <div class="file-control-icon">
                    <i class="icon-uploader"></i>
                  </div>
                  <div class="file-control-text">
                    Choose a
                    <strong>
                      .xlsx, .xls, .csv, .pdf, .docx, .doc
                    </strong>
                    file <br/>
                    or drag it here
                  </div>

                  <dx-file-uploader *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id"
                                    id="file" accept=".xlsx, .xls, .csv, .pdf, .docx, .doc"
                                    [name]="uploaderOptions.name" [uploadUrl]="uploaderOptions.uploadUrl"
                                    [disabled]="isDisableCertificateOfInsuranceFile()"
                                    [uploadHeaders]="uploaderOptions.uploadHeaders"
                                    (onUploadStarted)="uploaderOptions.onUploadStarted()"
                                    (onUploaded)="uploaderOptions.onUploaded($event)"
                                    (onUploadError)="uploaderOptions.onUploadError($event)"
                                    (onUploadAborted)="uploaderOptions.onUploadAborted($event)"
                                    (onValueChanged)="uploaderOptions.onValueChanged($event)"
                                    #fileUploader>
                  </dx-file-uploader>
                </div>

                <p class="error-message"
                   *ngIf="shouldShowPleaseUploadCOIText()">
                  Please upload COI per notes in the form
                </p>
              </ng-container>

              <div class="my-4"
                   *ngIf="insuranceCertificateFiles && insuranceCertificateFiles.length">
                <p class="fw-500 my-3">
                  COI Files
                </p>

                <div class="d-flex flex-row flex-nowrap align-items-center mx-n1 my-2"
                     *ngFor="let insuranceCertificateFile of insuranceCertificateFiles">
                  <div class="px-1">
                    <a role="button" class="text-primary cursor-pointer"
                       *ngIf="insuranceCertificateFile && insuranceCertificateFile.fileViewModel"
                       (click)="previewCOIFile(insuranceCertificateFile.fileViewModel)">
                      <i class="fa fa-file-o mr-1" aria-hidden="true"></i>
                      {{ insuranceCertificateFile.fileViewModel.name }}
                    </a>
                  </div>
                  <div class="px-1">
                    <a role="button" class="cursor-pointer" title="Remove"
                       *ngIf="insuranceCertificateFile.availableForDeletingByTenant && !isDisableCertificateOfInsuranceFile()"
                       (click)="deleteFile(insuranceCertificateFile)">
                      <i class="dx-icon dx-icon-close"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <ng-container *appCheckAccess="StateraClaimType.Collabo_Negotiation; stateraClaimValue: StateraClaimValue.Write; leaseId:lease?.id">
              <div class="mb-4"
                   *ngIf="isShowCompliantAndNonCompliantButtons() && !isNonCompliant">
                <span>Is the attached COI in compliance?</span>
                <button type="button" class="btn ml-5 btn-primary"
                        (click)="clickCompliantButton($event)">
                  Compliant
                </button>
                <button type="button" class="btn ml-2 btn-primary"
                        (click)="clickNonCompliantButton($event)">
                  Non-Compliant
                </button>
              </div>

              <div class="form-group"
                   *ngIf="isNonCompliant">
                <label class="error-message">
                  Please continue to request additional modifications to attached COI.
                </label>
              </div>

              <div class="form-group text-right mt-4"
                   *ngIf="checkAccessToWriteToInsuranceCertificateProject()">
                <button type="submit" class="btn btn-primary"
                        [disabled]="sendForReviewButtonDisabled">
                  Send for Review
                </button>
                <button type="button" class="btn"
                        (click)="cancel($event, cancelButton)"
                        #cancelButton>
                  Cancel
                </button>
              </div>
            </ng-container>
          </form>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</ng-container>
