import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Subject } from 'rxjs';
import { debounce, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore } from '@statera/sdk/landlord';
import { Role } from '@statera/sdk/auth';

import { environment } from '../../../../environments/environment';

import { ApplicationStore } from '../../../shared/states/application/application.store';
import { ApplicationQuery } from '../../../shared/states/application/application.query';
import { StorageService } from '../../../infrastructure/services/storage.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { AuthService } from '../../../auth/services/auth.service';

import { ManageTeamDialogComponent } from '../../../team/components/manage-team-dialog/manage-team-dialog.component';

import * as models from '../../../infrastructure/models/generated';
import { TeamManager } from '@statera/sdk/team';
import { AlertService } from '../../../alert/services/alert.service';
import { AlertMessagesManager } from '@statera/sdk/alert';

enum SortBuildings {
  NewestToOldest = 'Newest to oldest',
  Alphabet = 'Alphabet',
  Size = 'Size'
}

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
})
export class BuildingComponent implements OnInit, OnDestroy {
  buildings: Array<models.IPortfolioBuildingViewModel>;
  buildingPictures: Record<number, Array<models.IBuildingAttachmentViewModel>> = {};

  building: models.IBuildingViewModel;
  id = 0;
  tenants: Array<models.ICompactTenantViewModel> = [];
  uploadUrl = '';
  portfolio = <models.IPortfolioViewModel>{};
  currentPageSize = 8;
  page = 1;
  SortBuildings = SortBuildings;
  currentSorting: SortBuildings = SortBuildings.NewestToOldest;
  pageCount = 0;
  landlordDashboardFilter: models.ILandlordDashboardFilterViewModel;

  tooltipVisible = false;

  isSelectBuildingsModeEnabled: boolean;
  selectedBuildings: Array<models.IPortfolioBuildingViewModel>;

  userRole: Observable<Role>;

  Role: typeof Role = Role;

  private readonly _landlordDashboardUrl: string = '/landlord/buildings';
  private readonly _buildingManager: BuildingManager;
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _teamManager: TeamManager;
  private readonly _storageService: StorageService;
  private readonly _applicationStore: ApplicationStore;
  private readonly _applicationQuery: ApplicationQuery;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _dialogService: DialogService;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;
  private readonly _destroy$: Subject<void>;

  constructor(
    buildingManager: BuildingManager,
    landlordStore: LandlordStore,
    landlordManager: LandlordManager,
    teamManager: TeamManager,
    storageService: StorageService,
    applicationStore: ApplicationStore,
    applicationQuery: ApplicationQuery,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    dialogService: DialogService,
    activatedRoute: ActivatedRoute,
    router: Router,
  ) {
    this._storageService = storageService;
    this._buildingManager = buildingManager;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
    this._teamManager = teamManager;
    this._applicationStore = applicationStore;
    this._applicationQuery = applicationQuery;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._authService = authService;
    this._dialogService = dialogService;
    this._activatedRoute = activatedRoute;
    this._router = router;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.isSelectBuildingsModeEnabled = false;
    this.selectedBuildings = [];

    this._landlordStore
      .portfolios$
      .pipe(
        take(1)
      )
      .subscribe((portfolios) => {
        this.building = this._buildingManager
          .findInPortfolios(null, portfolios);
      });

    this._activatedRoute
      .params
      .subscribe(params => {
        this.id = parseInt(params['id'], 10);
        this._landlordStore
          .portfolios$
          .pipe(
            take(1)
          )
          .subscribe((portfolios) => {
            this.building = this._buildingManager
              .findInPortfolios(this.id, portfolios);
          });
        if (this.id) {
          this._landlordManager
            .getTenants(this.id)
            .subscribe(items => (this.tenants = items));
        }
        this.uploadUrl = `${environment.webApiUrl}/landlord/AttachFile/${this.id}`;
      });

    this._applicationQuery
      .getLandlordDashboardFilter$
      .pipe(
        debounce(_ => interval(500)),
        tap(landlordDashboardFilter => {
          if (this._router.url !== this._landlordDashboardUrl) {
            this._router.navigate([this._landlordDashboardUrl]);
          }
          this.landlordDashboardFilter = landlordDashboardFilter;
          this._applicationStore.disableLandlordDashboardFilter(true);
        }),
        switchMap(x => this._landlordManager.searchBuildingsByFilter(x)
          .pipe(
            map(pageResult => {
              const buildings = pageResult.results;
              this.pageCount = pageResult.pageCount;
              if (buildings) {
                const countEmptyBuildings = 4 - (buildings.length % 4);
                if (countEmptyBuildings !== 4) {
                  for (let i = 0; i < countEmptyBuildings; i++) {
                    buildings.push(<models.IPortfolioBuildingViewModel>{});
                  }
                }
              }
              return buildings;
            }),
            tap(_ => this._applicationStore.disableLandlordDashboardFilter(false)),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(buildings => {
        this.buildings = buildings;

        this.buildingPictures = {};
        buildings.forEach(b => {
          this.buildingPictures[b.id] = this.getBuildingAttachments(b);
        });
      }, error => {
        this._applicationStore.disableLandlordDashboardFilter(false);
      });

    this._applicationQuery.getSelectedPortfolio$.subscribe(selectedPortfolioId => {
      if (selectedPortfolioId) {
        this._landlordStore
          .portfolios$
          .pipe(
            take(1)
          )
          .subscribe((portfolios) => {
            this.portfolio = portfolios.find(x => x.id === selectedPortfolioId);
          });
      }
    });

    this.userRole = this._authService
      .infoLoadComplete
      .pipe(
        map(x => Role[Role[x.role]])
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isAssetsFilterUsed(): boolean {
    const assetFilter = this.landlordDashboardFilter?.assetFilter;
    if (!assetFilter) {
      return false;
    }

    return (
      (assetFilter.portfolioIds?.length || 0) > 1 ||
      (assetFilter.assetIds?.length || 0) > 0
    );
  }

  isBuildingSelected(building: models.IPortfolioBuildingViewModel): boolean {
    return this.selectedBuildings.findIndex(x => x.id === building.id) !== -1;
  }

  isSelectedBuildingsHaveAnyTeamMembers(): boolean {
    return this.selectedBuildings
      .map(x => x.teamMembers)
      .some(x => x.length > 0);
  }

  isSelectedBuildingsTeamMembersMatch(): boolean {
    const teams = this.selectedBuildings.map(x => x.teamMembers);
    return this._teamManager.isTeamsMembersMatch(teams);
  }

  getBuildingAttachments(building: models.IPortfolioBuildingViewModel): Array<models.IBuildingAttachmentViewModel> {
    if (!building?.attachments?.length) {
      return [];
    }

    return building.attachments;
  }

  onPageSizeChanged($event, value: number) {
    this.currentPageSize = value;
    this._applicationStore.updateLandlordDashboardPaginationOptions(this.page, this.currentPageSize);
  }

  onPageChanged($event, value: number) {
    this.page = value;
    this._applicationStore.updateLandlordDashboardPaginationOptions(this.page, this.currentPageSize);
  }

  onSortingChanged($event) {
    let sortBy = models.LandlordDashboardSortBy.NewestToOldest;
    switch (this.currentSorting) {
      case SortBuildings.Alphabet: {
        sortBy = models.LandlordDashboardSortBy.Alphabet;
        break;
      }
      case SortBuildings.NewestToOldest: {
        sortBy = models.LandlordDashboardSortBy.NewestToOldest;
        break;
      }
      case SortBuildings.Size: {
        sortBy = models.LandlordDashboardSortBy.Size;
        break;
      }
    }

    this._applicationStore.updateLandlordDashboardSortBy(sortBy);
  }

  handleSelectBuildingsButtonClick(): void {
    this.isSelectBuildingsModeEnabled = true;
    this.selectedBuildings = [];
  }

  handleSelectAllBuildingsButtonClick(): void {
    this.selectedBuildings = this.buildings.filter(x => !!x?.id);
  }

  handleDeselectAllBuildingsButtonClick(): void {
    this.selectedBuildings = [];
    this.isSelectBuildingsModeEnabled = false;
  }

  handleBuildingSelectClick(building: models.IPortfolioBuildingViewModel): void {
    if (this.isBuildingSelected(building)) {
      this.selectedBuildings = this.selectedBuildings.filter(x => x.id !== building.id);

      if (this.selectedBuildings.length === 0) {
        this.isSelectBuildingsModeEnabled = false;
      }

      return;
    }

    this.selectedBuildings.push(building);
  }

  handlePortfolioAssignLeasingTeamButtonClick(portfolio: models.IPortfolioViewModel): void {
    this.openManageTeamDialog({ portfolio });
  }

  handlePortfolioManageLeasingTeamButtonClick(portfolio: models.IPortfolioViewModel): void {
    this.openManageTeamDialog({ portfolio });
  }

  handleBuildingAssignLeasingTeamButtonClick(building: models.IPortfolioBuildingViewModel): void {
    this.openManageTeamDialog({ buildings: [building] });
  }

  handleBuildingManageLeasingTeamButtonClick(building: models.IPortfolioBuildingViewModel): void {
    this.openManageTeamDialog({ buildings: [building] });
  }

  handleSelectedBuildingAssignLeasingTeamButtonClick(): void {
    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmOverrideBuildingsExistingTeamMembers(),
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.openManageTeamDialog({ buildings: this.selectedBuildings });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handleSelectedBuildingManageLeasingTeamButtonClick(): void {
    this.openManageTeamDialog({ buildings: this.selectedBuildings });
  }

  openManageTeamDialog(
    data: {
      portfolio?: models.IPortfolioViewModel,
      buildings?: Array<models.IPortfolioBuildingViewModel>,
    },
  ): void {
    const dialogRef = this._dialogService
      .show(ManageTeamDialogComponent, {
        title: null,
        width: 1024,
        maxHeight: '90vh',
        injectableData: {
          ...data,
          role: this._authService.role,
        },
      });

    this._applicationStore.disableLandlordDashboardFilter(true);

    dialogRef
      .onHiding
      .pipe(
        tap(() => {
          this._applicationStore.disableLandlordDashboardFilter(false);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
