import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { CommonTransformer } from '@statera/sdk/common';

@Pipe({
  name: 'safePercent'
})
@Injectable()
export class SafePercentPipe implements PipeTransform {
  private readonly _commonTransformer: CommonTransformer;

  constructor(commonTransformer: CommonTransformer) {
    this._commonTransformer = commonTransformer;
  }

  transform(value: any, ...args: Array<any>): any {
    return this._commonTransformer.transformPercentSafe(value, ...args);
  }
}
