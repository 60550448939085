<div class="b-dropdown"
     [class.collapsed]="isCollapsed"
     #dropdownElementRef>
  <a class="b-dropdown-link" role="button"
     (click)="onCollapsed()"
     [attr.aria-expanded]="!isCollapsed"
     #dropdownLinkElementRef>
    <span>
      <span class="b-dropdown-link-title">
        {{ portfolio.name }}
      </span>
      <span class="b-dropdown-link-subtitle"
            *ngIf="portfolio.buildings.length">
        {{ portfolio.buildings.length }}
        {{ portfolio.buildings.length === 1 ? 'building' : 'buildings' }}
      </span>
    </span>

    <span class="b-dropdown-icon"></span>
  </a>

  <div class="b-dropdown-content"
       #dropdownContentElementRef>
    <div class="b-list">
      <a class="b-list-item" role="button"
         *ngFor="let item of portfolio.buildings"
         [routerLink]="['../buildings/building-desktop/' + item.id]"
         [routerLinkActive]="['active']">
        <h3 class="b-list-header">
          {{ item.name ? item.name : item.address ? item.address.addressLine1 : 'Building ' + (item.id + 1) }}
        </h3>
        <p class="b-list-content">
          {{ item.address ? item.address.displayString : '' }}
        </p>
      </a>
    </div>
  </div>
</div>
