import { Injectable } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe, I18nPluralPipe, PercentPipe } from '@angular/common';

import { CommonTools } from './common.tools';

@Injectable()
export class CommonTransformer {
  private readonly _angularCurrencyPipe: CurrencyPipe;
  private readonly _angularDatePipe: DatePipe;
  private readonly _angularDecimalPipe: DecimalPipe;
  private readonly _angularPercentPipe: PercentPipe;
  private readonly _i18nPluralPipe: I18nPluralPipe;

  constructor(
    angularCurrencyPipe: CurrencyPipe,
    angularDatePipe: DatePipe,
    angularDecimalPipe: DecimalPipe,
    angularPercentPipe: PercentPipe,
    i18nPluralPipe: I18nPluralPipe,
  ) {
    this._angularCurrencyPipe = angularCurrencyPipe;
    this._angularDatePipe = angularDatePipe;
    this._angularDecimalPipe = angularDecimalPipe;
    this._angularPercentPipe = angularPercentPipe;
    this._i18nPluralPipe = i18nPluralPipe;
  }

  transformCurrencySafe(value: any, ...rest: Array<any>): any {
    return CommonTools.isNumeric(value) ?
      this._angularCurrencyPipe.transform(value, ...rest) :
      value;
  }

  transformDateSafe(value: any, ...rest: Array<any>): any {
    const parsedDate = Date.parse(value);
    return value && !isNaN(parsedDate) ?
      this._angularDatePipe.transform(value, ...rest) :
      value;
  }

  transformNumberSafe(value: any, ...rest: Array<any>): any {
    return CommonTools.isNumeric(value) ?
      this._angularDecimalPipe.transform(value, ...rest) :
      value;
  }

  transformPercentSafe(value: any, ...rest: Array<any>): any {
    return CommonTools.isNumeric(value) ?
      this._angularPercentPipe.transform(value, ...rest) :
      value;
  }

  transformTimeAgo(date: Date): string {
    if (!date) {
      return '';
    }

    const now = new Date();

    const diff = now.getTime() - date.getTime();

    if (diff < 60000) {
      return 'less than 1 minute ago';
    } else if (diff < 3600000) {
      const minutes = Math.floor(diff / 60000);
      const unitsExpression = this._i18nPluralPipe.transform(minutes, { '=1': 'minute', 'other': 'minutes' });
      return `${minutes} ${unitsExpression} ago`;
    } else if (diff < 86400000) {
      const hours = Math.floor(diff / 3600000);
      const unitsExpression = this._i18nPluralPipe.transform(hours, { '=1': 'hour', 'other': 'hours' });
      return `${hours} ${unitsExpression} ago`;
    } else if (diff < 172800000 && date.getDate() === now.getDate() - 1) {
      return 'yesterday';
    } else if (diff < 604800000) {
      const days = Math.floor(diff / 86400000);
      const unitsExpression = this._i18nPluralPipe.transform(days, { '=1': 'day', 'other': 'days' });
      return `${days} ${unitsExpression} ago`;
    } else if (diff < 2592000000) {
      const weeks = Math.floor(diff / 604800000);
      const unitsExpression = this._i18nPluralPipe.transform(weeks, { '=1': 'week', 'other': 'weeks' });
      return `${weeks} ${unitsExpression} ago`;
    } else if (diff < 31536000000) {
      const months = Math.floor(diff / 2592000000);
      const unitsExpression = this._i18nPluralPipe.transform(months, { '=1': 'month', 'other': 'months' });
      return `${months} ${unitsExpression} ago`;
    } else {
      const years = Math.floor(diff / 31536000000);
      const unitsExpression = this._i18nPluralPipe.transform(years, { '=1': 'year', 'other': 'years' });
      return `${years} ${unitsExpression} ago`;
    }
  }
}
